import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';
import Pagination from '@components/global/Pagination/themed/default';
import Seo from '@components/global/Seo';
import GradientOverlay from '@components/global/design2023/GradientOverlay';
import Layout from '@components/layouts/Layout';
import BonusResources, { BonusResourcesArticle } from '@components/pages/blog/BonusResources';
import JsonLd from '@components/pages/blog/JsonLd';
import Navigation from '@components/pages/blog/Navigation';
import PostGroup from '@components/pages/blog/PostGroup';
import StartFreeBox from '@components/pages/blog/StartFreeBox';
import SinglePostBox, { SinglePostBoxTag } from '@components/pages/blog/redesign2023/SinglePostBox';
import SubscribeForm from '@components/pages/blog/redesign2023/SubscribeForm';
import Underline from '@components/ui/Underline';

import { getCanonical } from '@helpers/alternates';
import { getLatestResources, getPosts } from '@helpers/blog/helpers';
import getGlobalProps from '@helpers/getGlobalProps';
import getLocale from '@helpers/getLocale';
import getPageLocales from '@helpers/getPageLocales';
import { PropsWithSeoData } from '@helpers/seo';

import { getLocalizedUrl } from '@/lib/routing';
import type { BlogSupportedLocales } from '@customTypes/blog/types';
import useTranslation from '@hooks/useTranslation';
import { laravelRouteBlog, laravelRouteBlogSearch } from '@static_components/laravelLinks';
import type { GetServerSideProps } from 'next';

import styles from './main.module.css';

const POSTS_PER_PAGE = 12;
const POSTS_ON_HOMEPAGE = 9;

interface BlogPost {
    primary_tag?: { name: string; slug: string };
    image?: string;
    publish_date: string;
    post_modified_date: string;
    is_sticky: boolean;
    title: string;
    post_tags?: Array<string>;
    slug: string;
    excerpt: string;
    author?: {
        name?: string;
        url?: string;
        image?: string;
    };
    reading_time_minutes?: number;
    id: number;
}
interface Resource {
    image?: string;
    url: string;
    post_type: 'guides' | 'videos' | 'webinars' | 'collections' | 'upcoming_webinars' | 'infographics';
    title: string;
    slug: string;
}
interface PrimaryTag {
    name: string;
    slug: string;
}
interface HomepageProps {
    posts: Array<BlogPost>;
    totalPosts: number;
    topTags: Array<PrimaryTag>;
    latestResources: Array<Resource>;
    currentPageNumber: number;
    mostPopularPosts: Array<BlogPost>;
    selectedReadsPosts: Array<BlogPost>;
}

function Container({ children }: React.PropsWithChildren): JSX.Element {
    return <div className={styles.container}>{children}</div>;
}

export default function Homepage({
    posts,
    totalPosts,
    currentPageNumber,
    topTags,
    latestResources,
    mostPopularPosts,
    selectedReadsPosts,
    seo,
}: PropsWithSeoData<HomepageProps>) {
    const { t } = useTranslation('pages/blog/index');
    const { pathname, locale } = useRouter();
    const canonical = getCanonical(pathname) + (currentPageNumber !== 1 ? `?p=${currentPageNumber}` : '');

    function localizePostType(postType: string): string {
        if (postType === 'videos') {
            return t('pages/blog/bonus-resources:postTypeVideo');
        }
        if (postType === 'guides') {
            return t('pages/blog/bonus-resources:postTypeGuide');
        }
        if (postType === 'infographics') {
            return t('pages/blog/bonus-resources:postTypeInfographic');
        }
        if (postType === 'reports') {
            return t('pages/blog/bonus-resources:postTypeReport');
        }
        if (postType === 'courses') {
            return t('pages/blog/bonus-resources:postTypeCourse');
        }
        if (postType === 'collections') {
            return t('pages/blog/bonus-resources:postTypeCollection');
        }
        if (postType === 'webinars') {
            return t('pages/blog/bonus-resources:postTypeWebinar');
        }
        return postType;
    }

    return (
        <>
            <Seo
                title={t('seo_title')}
                description={t('seo_description')}
                languageAlternates={currentPageNumber !== 1 ? [] : undefined}
                noindex={!seo.robots.index}
                nofollow={!seo.robots.follow}
                canonical={canonical}
            />
            <JsonLd posts={posts} />
            <main className={styles.main}>
                <Container>
                    <div className={styles.grid}>
                        <div className={styles.navigation}>
                            <Navigation categories={topTags.map((tag) => ({ name: tag.name, href: tag.slug }))} />
                        </div>
                        {currentPageNumber === 1 && (
                            <div className={styles.topPosts}>
                                <h2>{t('featuredPost')}</h2>
                                {posts.slice(0, 3).map((post, i) => (
                                    <SinglePostBox
                                        priority="high"
                                        postId={post.id}
                                        key={post.slug}
                                        type={i === 0 ? 'featured' : undefined}
                                        customLink={<CustomLink href={{ pathname: `blog/${post.slug}` }} />}
                                        title={post.title}
                                        date={post.post_modified_date}
                                        tags={
                                            <>
                                                {post.primary_tag && (
                                                    <SinglePostBoxTag
                                                        featured={true}
                                                        href={
                                                            laravelRouteBlog(locale) + `/topic/${post.primary_tag.slug}`
                                                        }
                                                        title={post.primary_tag.name}
                                                    />
                                                )}
                                                {post.post_tags &&
                                                    post.post_tags.map((tag) => (
                                                        <SinglePostBoxTag
                                                            featured={true}
                                                            key={tag}
                                                            href={
                                                                laravelRouteBlogSearch(locale) +
                                                                `?q=${encodeURIComponent(tag)}`
                                                            }
                                                            title={tag}
                                                        />
                                                    ))}
                                            </>
                                        }
                                        author={post.author?.name}
                                        readTime={
                                            post.reading_time_minutes
                                                ? t('readingTimeValue', { minutes: post.reading_time_minutes })
                                                : undefined
                                        }
                                        image={post.image}
                                        authorImage={post.author?.image}
                                    />
                                ))}
                            </div>
                        )}

                        {currentPageNumber === 1 && (
                            <div className={styles.postGroups}>
                                {mostPopularPosts.length > 0 ? (
                                    <PostGroup title={t('mostPopular')}>
                                        {mostPopularPosts.map((post) => (
                                            <SinglePostBox
                                                postId={post.id}
                                                type="long"
                                                key={post.slug}
                                                customLink={<CustomLink href={{ pathname: `blog/${post.slug}` }} />}
                                                title={post.title}
                                                date={post.post_modified_date}
                                                tags={
                                                    <>
                                                        {post.primary_tag && (
                                                            <SinglePostBoxTag
                                                                featured={true}
                                                                href={
                                                                    laravelRouteBlog(locale) +
                                                                    `/topic/${post.primary_tag.slug}`
                                                                }
                                                                title={post.primary_tag.name}
                                                            />
                                                        )}
                                                    </>
                                                }
                                                author={post.author?.name}
                                                readTime={
                                                    post.reading_time_minutes
                                                        ? t('readingTimeValue', { minutes: post.reading_time_minutes })
                                                        : undefined
                                                }
                                                image={post.image}
                                                authorImage={post.author?.image}
                                            />
                                        ))}
                                    </PostGroup>
                                ) : null}
                                {selectedReadsPosts.length > 0 ? (
                                    <PostGroup title={t('selectedReads')}>
                                        {selectedReadsPosts.map((post) => (
                                            <SinglePostBox
                                                postId={post.id}
                                                type="long"
                                                key={post.slug}
                                                customLink={<CustomLink href={{ pathname: `blog/${post.slug}` }} />}
                                                title={post.title}
                                                date={post.post_modified_date}
                                                tags={
                                                    <>
                                                        {post.primary_tag && (
                                                            <SinglePostBoxTag
                                                                href={
                                                                    laravelRouteBlog(locale) +
                                                                    `/topic/${post.primary_tag.slug}`
                                                                }
                                                                title={post.primary_tag.name}
                                                            />
                                                        )}
                                                    </>
                                                }
                                                author={post.author?.name}
                                                readTime={
                                                    post.reading_time_minutes
                                                        ? t('readingTimeValue', { minutes: post.reading_time_minutes })
                                                        : undefined
                                                }
                                                image={post.image}
                                                authorImage={post.author?.image}
                                            />
                                        ))}
                                    </PostGroup>
                                ) : null}
                            </div>
                        )}

                        {currentPageNumber === 1 && (
                            <div className={styles.subscribeForm}>
                                <GradientOverlay>
                                    <SubscribeForm />
                                </GradientOverlay>
                            </div>
                        )}

                        <div className={styles.posts}>
                            {currentPageNumber === 1 && (
                                <h2>
                                    <Underline>{t('latestPosts')}</Underline>
                                </h2>
                            )}
                            {posts.slice(currentPageNumber === 1 ? 3 : 0).map((post, i) => (
                                <SinglePostBox
                                    priority={currentPageNumber !== 1 && i < 8 ? 'high' : undefined}
                                    postId={post.id}
                                    key={post.slug}
                                    customLink={<CustomLink href={{ pathname: `blog/${post.slug}` }} />}
                                    title={post.title}
                                    date={post.post_modified_date}
                                    tags={
                                        <>
                                            {post.primary_tag && (
                                                <SinglePostBoxTag
                                                    href={laravelRouteBlog(locale) + `/topic/${post.primary_tag.slug}`}
                                                    title={post.primary_tag.name}
                                                />
                                            )}
                                        </>
                                    }
                                    author={post.author?.name}
                                    readTime={
                                        post.reading_time_minutes
                                            ? t('readingTimeValue', { minutes: post.reading_time_minutes })
                                            : undefined
                                    }
                                    image={post.image}
                                    authorImage={post.author?.image}
                                />
                            ))}
                        </div>
                        {currentPageNumber === 1 && latestResources.length > 0 && (
                            <div className={styles.bonusResources}>
                                <BonusResources>
                                    {latestResources.map((resource) => (
                                        <BonusResourcesArticle
                                            key={resource.slug}
                                            title={resource.title}
                                            href={resource.url}
                                            post_type={localizePostType(resource.post_type)}
                                            coverSrc={resource.image}
                                        />
                                    ))}
                                </BonusResources>
                            </div>
                        )}

                        <div className={styles.pagination}>
                            <Pagination
                                currentPage={currentPageNumber}
                                totalCount={totalPosts}
                                pageSize={POSTS_PER_PAGE}
                                pageSizeOffset={POSTS_ON_HOMEPAGE - POSTS_PER_PAGE}
                                siblingCount={2}
                                pageLinkComponent={(p) => (
                                    <CustomLink
                                        prefetch={false}
                                        href={{
                                            pathname: '/blog',
                                            query: p === 1 ? undefined : { p },
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className={styles.startFreeBox}>
                            <GradientOverlay>
                                <StartFreeBox />
                            </GradientOverlay>
                        </div>
                    </div>
                </Container>
            </main>
        </>
    );
}

function getTopTagsStatic(locale: string) {
    return locale === 'es'
        ? [
            { slug: 'email-marketing', name: 'Email Marketing' },
            { slug: 'website-builder', name: 'Website Builder' },
            { slug: 'herramientas-de-marketing', name: 'Herramientas de marketing' },
            { slug: 'marketing-de-afiliados', name: 'Marketing de Afiliados' },
            { slug: 'ecommerce', name: 'Ecommerce' },
        ]
        : locale === 'pl'
            ? [
                { slug: 'email-marketing', name: 'Email Marketing' },
                { slug: 'afiliacja', name: 'Afiliacja' },
                { slug: 'kreator-stron', name: 'Kreator Stron' },
                { slug: 'marketing-automation', name: 'Marketing Automation' },
                { slug: 'e-commerce', name: 'Ecommerce' },
            ]
            : locale === 'de'
                ? [
                    { slug: 'email-marketing', name: 'E-Mail Marketing' },
                    { slug: 'affiliate-marketing', name: 'Affiliate Marketing' },
                    { slug: 'website-builder', name: 'Website Marketing' },
                    { slug: 'marketing-automation', name: 'Marketing Automation' },
                    { slug: 'ecommerce', name: 'E-Commerce' },
                ]
                : locale === 'pt'
                    ? [
                        { slug: 'email-marketing', name: 'Email Marketing' },
                        { slug: 'website-builder', name: 'Website Builder' },
                        { slug: 'ferramentas-de-marketing', name: 'Ferramentas de Marketing' },
                        { slug: 'marketing-de-afiliados', name: 'Marketing de Afiliados' },
                        { slug: 'ecommerce', name: 'Ecommerce' },
                    ]
                    : locale === 'it'
                        ? [
                            { slug: 'email-marketing', name: 'Email Marketing' },
                            { slug: 'creazione-siti-web', name: 'Creazione siti web' },
                            { slug: 'affiliate-marketing', name: 'Affiliate Marketing' },
                            { slug: 'landing-page', name: 'Landing Page' },
                            { slug: 'ecommerce', name: 'Ecommerce' },
                        ]
                        : locale === 'vn'
                            ? [
                                { slug: 'email-marketing', name: 'Email Marketing' },
                                { slug: 'tiep-thi-lien-ket', name: 'Tiếp thị liên kết' },
                                { slug: 'thiet-ke-website', name: 'Thiết Kế Website' },
                                { slug: 'marketing-automation', name: 'Marketing Automation' },
                                { slug: 'landing-page', name: 'Landing Page' },
                            ]
                            : locale === 'ru'
                                ? []
                                : [
                                    { slug: 'email-marketing', name: 'Email marketing' },
                                    { slug: 'affiliate-marketing', name: 'Affiliate marketing' },
                                    { slug: 'website-builder', name: 'Website builder' },
                                    { slug: 'marketing-automation', name: 'Marketing automation' },
                                    { slug: 'ecommerce', name: 'Ecommerce' },
                                ];
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const locale = getLocale(context);
    const locales = getPageLocales('/blog');
    if (!locale || !locales.includes(locale)) {
        return {
            notFound: true,
        };
    }

    const mainBlogPageUrl = getLocalizedUrl('/blog', locale);
    if (!mainBlogPageUrl) {
        throw new Error(`No valid /blog url for locale: ${locale}`);
    }

    const globalProps = await getGlobalProps(context, '/blog');

    const currentPageNumber = +(context.query?.p ?? 1);

    if (
        isNaN(currentPageNumber) ||
        !Number.isInteger(currentPageNumber) ||
        currentPageNumber <= 0 ||
        context.query?.p === '1'
    ) {
        return {
            redirect: {
                destination: mainBlogPageUrl,
                permanent: true,
            },
        };
    }

    const [{ totalPosts, posts }, topTags, latestResources, mostPopularPosts, selectedReadsPosts] = await Promise.all([
        getPosts(currentPageNumber, locale as BlogSupportedLocales, POSTS_PER_PAGE, POSTS_ON_HOMEPAGE),
        getTopTagsStatic(locale),
        currentPageNumber === 1 ? getLatestResources(locale as BlogSupportedLocales) : null,
        currentPageNumber === 1
            ? getPosts(currentPageNumber, locale as BlogSupportedLocales, 0, 3, ['blog_most_popular:asc'], {
                exists: {
                    field: 'blog_most_popular',
                },
            })
            : null,
        currentPageNumber === 1
            ? getPosts(currentPageNumber, locale as BlogSupportedLocales, 0, 3, ['blog_selected_reads:asc'], {
                exists: {
                    field: 'blog_selected_reads',
                },
            })
            : null,
    ]);

    if (posts.length === 0 && currentPageNumber !== 1) {
        return {
            redirect: {
                destination: mainBlogPageUrl,
                permanent: true,
            },
        };
    }

    return {
        props: {
            ...globalProps,
            posts,
            totalPosts,
            currentPageNumber,
            topTags,
            latestResources,
            mostPopularPosts: mostPopularPosts?.posts ?? null,
            selectedReadsPosts: selectedReadsPosts?.posts ?? null,
        },
    };
};

Homepage.getLayout = function getLayout(page: JSX.Element) {
    return <Layout headerProps={{ additionalHeaderAttribute: 'has-new-layout' }}>{page}</Layout>;
};
