import { useCallback, useState } from 'react';

import { createContext } from '@gr/ui-neo';

import { SeasonalPromoContext, SeasonalPromoProviderProps } from './interfaces';
import { getEnabledSeasonalPromotion } from './getEnabledSeasonalPromotion';

export const [SeasonalPromoCtxProvider, useSeasonalPromo] = createContext<SeasonalPromoContext>({
    defaultValue: null,
    name: 'SeasonalPromoCtx',
    strict: true,
});

export function SeasonalPromotionProvider({
    seasonalPromotionName,
    seasonalPromotionEnabled,
    seasonalPromotionCounterValue,
    children,
}: React.PropsWithChildren<SeasonalPromoProviderProps>) {
    const [seasonalPromotionData, setSeasonalPromotionData] = useState<SeasonalPromoProviderProps>({
        seasonalPromotionName,
        seasonalPromotionEnabled,
        seasonalPromotionCounterValue,
    });

    const updateSeasonalPromotionData = useCallback(
        ({
            seasonalPromotionName,
            seasonalPromotionEnabled,
            seasonalPromotionCounterValue,
        }: SeasonalPromoProviderProps) => {
            setSeasonalPromotionData({
                seasonalPromotionName,
                seasonalPromotionEnabled,
                seasonalPromotionCounterValue,
            });
        },
        [],
    );

    const { isBFCM24SeasonalPromotionEnabled } = getEnabledSeasonalPromotion({
        seasonalPromotionName: seasonalPromotionData.seasonalPromotionName,
        seasonalPromotionEnabled: seasonalPromotionData.seasonalPromotionEnabled,
    });

    const contextValues: SeasonalPromoContext = {
        seasonalPromotionData: {
            seasonalPromotionEnabled: seasonalPromotionData.seasonalPromotionEnabled,
            seasonalPromotionCounterValue: seasonalPromotionData.seasonalPromotionCounterValue,
            isBFCM24SeasonalPromotionEnabled,
        },
        api: {
            updateSeasonalPromotionData,
        },
    };

    return <SeasonalPromoCtxProvider value={contextValues}>{children}</SeasonalPromoCtxProvider>;
}
