import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './FooterSocial.module.css';
import { ReactComponent as SVGFacebook } from './assets/fb.svg';
import { ReactComponent as SVGLinkedin } from './assets/in.svg';
import { ReactComponent as SVGInstagram } from './assets/insta.svg';
import { ReactComponent as SVGLinktr } from './assets/linktr.svg';
import { ReactComponent as SVGPinterest } from './assets/pinterest.svg';
import { ReactComponent as SVGYoutube } from './assets/yt.svg';

interface FooterSocialProps {
    darkMode?: boolean;
}

function FooterSocial({ darkMode = false }: FooterSocialProps) {
    const { t } = useTranslation('base/_blocks/footer');
    return (
        <div className={clsx(styles['mfooter-social'], styles.social, darkMode ? styles.darkMode : '')}>
            <h3>{t('social_header')}</h3>
            <ul className={styles['social-list']}>
                <li>
                    <a
                        href="https://www.facebook.com/GetResponse"
                        data-test="footerSocialLink"
                        aria-label="Facebook"
                        rel="noopener noreferrer nofollow"
                    >
                        <SVGFacebook
                            width="18"
                            height="18"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.instagram.com/getresponse/"
                        data-test="footerSocialLink"
                        aria-label="Instagram"
                        rel="noopener noreferrer nofollow"
                    >
                        <SVGInstagram
                            width="18"
                            height="18"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.linkedin.com/company/getresponse"
                        data-test="footerSocialLink"
                        aria-label="Linkedin"
                        rel="noopener noreferrer nofollow"
                    >
                        <SVGLinkedin
                            width="18"
                            height="18"
                        />
                    </a>
                </li>

                <li>
                    <a
                        href="https://www.youtube.com/user/NewGetResponse"
                        data-test="footerSocialLink"
                        aria-label="YouTube"
                        rel="noopener noreferrer nofollow"
                    >
                        <SVGYoutube
                            width="22"
                            height="16"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.pinterest.com/getresponse/"
                        data-test="footerSocialLink"
                        aria-label="Pinterest"
                        rel="noopener noreferrer nofollow"
                    >
                        <SVGPinterest
                            width="18"
                            height="18"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="https://linktr.ee/OA_pod"
                        data-test="footerSocialLink"
                        aria-label="Linktr"
                        rel="noopener noreferrer nofollow"
                    >
                        <SVGLinktr
                            width="18"
                            height="18"
                        />
                    </a>
                </li>
                {/* Zakomentowane czasowo na prośbę Pauliny */}
                {/* <li>
                    <a
                        href="https://www.reddit.com/r/GetResponseOfficial/"
                        data-test="footerSocialLink"
                        aria-label="Reddit"
                        rel="noopener noreferrer nofollow"
                    >
                        <SVGReddit
                            width="20"
                            height="20"
                        />
                    </a>
                </li> */}
            </ul>
        </div>
    );
}

export default FooterSocial;
