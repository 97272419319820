import getConfig from 'next/config';
import { useRouter } from 'next/router';

import getPageLocales from '@helpers/getPageLocales';

import { locales } from '@/config/i18n';
import { getLocalizedPathname, getLocalizedUrl } from '@/lib/routing';

const { publicRuntimeConfig } = getConfig();

const getTranslatedPathWithoutPrefix = (pathname, locale) => {
    const preparedPathname = pathname.replace(/\[(.*?)\]/, ':$1');
    let chosenLocale = locale;
    if (!locales.includes(locale)) {
        // eslint-disable-next-line no-console
        console.log('Wrong locale selected. Fallback to EN');
        chosenLocale = 'en';
    }
    const translatedPath = getLocalizedPathname(preparedPathname, chosenLocale).replace(/^\//, '').replace(/\/$/, '');

    return translatedPath.replace(/\/$/, '').replace(/:([a-z]*)/, '[$1]');
};

export const getCanonical = (path) => {
    const { locale } = useRouter();
    return getLocalizedUrl(path, locale);
};

const alternatePaths = (path) => {
    const localizedPaths = {};
    publicRuntimeConfig.locales.forEach((queryLocale) => {
        localizedPaths[queryLocale] = path;
    });

    let locales = publicRuntimeConfig.locales;

    const localeAlternatePaths = locales.map((locale) => {
        const localizePathname = getTranslatedPathWithoutPrefix(localizedPaths[locale], locale);
        return { locale, pathname: localizePathname !== '' ? localizePathname : '/' };
    });
    return localeAlternatePaths;
};

export const alternatePath = (path, locale, alternateQuery) => {
    const allAlternatePaths = alternatePaths(path, alternateQuery);
    return allAlternatePaths.find((alternate) => alternate.locale === locale)?.pathname ?? null;
};

export const alternates = (pathname, params) => {
    let locales = getPageLocales(pathname);
    if (params) {
        locales = Object.keys(params);
    }

    const localeAlternates = locales
        .map((locale) => {
            const hasParamsLocale = !!params?.[locale];
            const href = hasParamsLocale
                ? getLocalizedUrl(pathname, locale, params[locale])
                : getLocalizedUrl(pathname, locale);

            if (!href) {
                return null;
            }

            return {
                hrefLang: locale.replace('vn', 'vi'),
                href,
            };
        })
        .filter((item) => item !== null);

    if (!localeAlternates.find((lA) => lA.hrefLang === 'en')) {
        return [...localeAlternates];
    }
    const alternates = [
        {
            hrefLang: 'x-default',
            href: localeAlternates.find((lA) => lA.hrefLang === 'en').href,
        },
        ...localeAlternates,
    ];

    return alternates;
};

export const canonical = (pathname, locale) => {
    const canonical = getLocalizedUrl(pathname, locale);
    if (canonical !== false) {
        return canonical;
    }
    if (locale === 'pl' && process.env.NEXT_PUBLIC_HOST_EN !== process.env.NEXT_PUBLIC_HOST_PL) {
        return `${process.env.NEXT_PUBLIC_PROTOCOL}//${process.env.NEXT_PUBLIC_HOST_PL}${getTranslatedPathWithoutPrefix(
            pathname,
            locale,
        )}`;
    } else if (locale === 'en') {
        return `${process.env.NEXT_PUBLIC_PROTOCOL}//${process.env.NEXT_PUBLIC_HOST_EN}${getTranslatedPathWithoutPrefix(
            pathname,
            locale,
        )}`;
    } else {
        return `${process.env.NEXT_PUBLIC_PROTOCOL}//${
            process.env.NEXT_PUBLIC_HOST_EN
        }/${locale}/${getTranslatedPathWithoutPrefix(pathname, locale)}`;
    }
};
