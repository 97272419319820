import React, { useId } from 'react';

import Trans from '@components/Trans';
import Underline from '@components/ui/Underline';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './StartFreeBox.module.css';
import imgMobileAVIF from './assets/image-mobile@1x.avif';
import imgMobilePNG from './assets/image-mobile@1x.png';
import imgMobileAVIF2x from './assets/image-mobile@2x.avif';
import imgMobilePNG2x from './assets/image-mobile@2x.png';
import imgAVIF from './assets/image@1x.avif';
import imgPNG from './assets/image@1x.png';
import imgAVIF2x from './assets/image@2x.avif';
import imgPNG2x from './assets/image@2x.png';

export default function StartFreeBox({
    heading,
    classHeading,
    button,
    image,
}: {
    heading?: JSX.Element | null;
    classHeading?: string | null;
    button?: JSX.Element | null;
    image?: JSX.Element | null;
}): JSX.Element {
    const { t } = useTranslation('pages/blog/start-free-section');

    const id = useId();

    const header = heading ? (
        heading
    ) : (
        <Trans
            i18nKey="header"
            components={{
                br: <br />,
                u: <Underline />,
            }}
            ns="pages/blog/start-free-section"
        />
    );

    const ctaButton = button ? (
        button
    ) : (
        <CTAStartFreeCreateAccount
            data-track="promo-btn-try-for-free"
            size="medium"
            label={t('button')}
        />
    );

    const img = image ? (
        image
    ) : (
        <figure className={styles.image}>
            <picture>
                <source
                    media="(max-width: 440px)"
                    type="image/avif"
                    srcSet={`${imgMobileAVIF.src} 1x, ${imgMobileAVIF2x.src} 2x`}
                />
                <source
                    type="image/avif"
                    srcSet={`${imgAVIF.src} 1x, ${imgAVIF2x.src} 2x`}
                />
                <source
                    media="(max-width: 440px)"
                    srcSet={`${imgMobilePNG.src} 1x, ${imgMobilePNG2x.src} 2x`}
                />
                <source srcSet={`${imgPNG.src} 1x, ${imgPNG2x.src} 2x`} />
                <img
                    width={imgPNG.width}
                    height={imgPNG.height}
                    decoding="async"
                    loading="lazy"
                    src={imgPNG.src}
                    alt=""
                />
            </picture>
        </figure>
    );

    return (
        <aside
            className={styles.startfree}
            aria-labelledby={id}
        >
            <div>
                <span
                    className={clsx(styles.heading, classHeading)}
                    id={id}
                >
                    {header}
                </span>
                {ctaButton}
            </div>
            {img}
        </aside>
    );
}
