import { useCallback } from 'react';

import useTranslationFromNextTranslate from 'next-translate/useTranslation';

import { usePreviewContext } from '@context/PreviewContext';

import { KEY_BEGIN, KEY_END, serializeToBinary } from '@components/LocalizationKeysPreview/helpers';

export default function useTranslation(defaultNamespace) {
    const previewContext = usePreviewContext();
    const { t, lang } = useTranslationFromNextTranslate(defaultNamespace);
    let trans = t;
    if (typeof window === 'undefined' || typeof previewContext === 'undefined') {
        return { t, lang };
    }
    // const translationPanelVersionName = `gr_www${previewContext.translationVersionName !== 'prod' ? '_' + previewContext.translationVersionName : ''}`;
    const translationPanelVersionName = `gr_www`;

    trans = useCallback(
        (key, ...args) => {
            const fullKeyName = defaultNamespace + '.' + key;
            if (previewContext.translationKeysDetailsVisible) {
                return (
                    KEY_BEGIN +
                    serializeToBinary({
                        name: key,
                        value: t(key),
                        url: `https://translation-panel.int.getresponse.com/translation/edit?projectIndex=translations-${encodeURIComponent(
                            translationPanelVersionName,
                        )}&key=${encodeURIComponent(fullKeyName)}`,
                    }) +
                    t(key, ...args) +
                    KEY_END
                );
            } else {
                return t(key, ...args);
            }
        },
        [defaultNamespace, previewContext.translationKeysDetailsVisible, t, translationPanelVersionName],
    );

    return { t: trans, lang };
}
