import React from 'react';

import defaultStyles from './Pagination.module.css';
import { usePagination } from './usePagination';

export interface PaginationProps {
    /**
     * All elements that can be accessed using paginator
     *
     * @remarks
     *
     * NOTE: this is number of elements, not pages, if you only have access to page count,
     * you need to set
     * ```
     * pageSize={1}
     * ```
     */
    totalCount: number;
    /**
     * How many page's indices are visible at each side of currently selected page.
     * @example
     * ```
     * siblingCount={2}
     * // Pagination could look like: 1 ... 3 4 5 6 7 ... 10
     * ```
     * @example
     * ```
     * siblingCount={1}
     * // Pagination could look like: 1 ... 4 5 6 ... 10
     * ```
     */
    siblingCount: number;
    /**
     * Page index that needs to be styled as active.
     */
    currentPage: number;
    /**
     * How many elements are shown per one paginator page
     */
    pageSize: number;
    pageLinkComponent: (pageNumber: number) => React.ReactElement<{ children: typeof pageNumber }>;
    pageSizeOffset: number;
    themeStyles?: typeof defaultStyles;
}

const Pagination = ({
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    pageLinkComponent,
    pageSizeOffset = 0,
    themeStyles,
}: PaginationProps) => {
    const paginationScope = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        pageSizeOffset,
    });

    if (paginationScope === null) {
        return false;
    }

    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce(
            (accumulator, className) =>
                Object.assign({}, accumulator, {
                    [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' '),
                }),
            {},
        );
    } else {
        styles = defaultStyles;
    }

    if (currentPage === 0 || paginationScope.length < 2) {
        return null;
    }

    const lastPage = paginationScope[paginationScope.length - 1] as number;

    return (
        <nav className={styles.pagination}>
            <ul
                itemScope
                itemType="http://schema.org/SiteNavigationElement"
            >
                <li className={[styles.element, styles.prev, currentPage === 1 ? styles.isDisabled : ''].join(' ')}>
                    {(currentPage !== 1 && pageLinkComponent(currentPage - 1)) || <span></span>}
                </li>

                {paginationScope.map((pageNumber, index) => {
                    if (typeof pageNumber !== 'number') {
                        return (
                            <li
                                key={index}
                                className={[styles.element, styles.dots].join(' ')}
                            >
                                <span>&#8230;</span>
                            </li>
                        );
                    }
                    if (
                        typeof pageLinkComponent === 'function' &&
                        pageLinkComponent(pageNumber) !== null &&
                        pageNumber !== currentPage
                    ) {
                        return (
                            <li
                                key={index}
                                className={styles.element}
                            >
                                {React.cloneElement(pageLinkComponent(pageNumber), {
                                    children: pageNumber,
                                })}
                            </li>
                        );
                    }
                    return (
                        <li
                            key={index}
                            className={[styles.element, styles.current].join(' ')}
                        >
                            <span aria-current="true">{pageNumber}</span>
                        </li>
                    );
                })}
                <li
                    className={[styles.element, styles.next, currentPage === lastPage ? styles.isDisabled : ''].join(
                        ' ',
                    )}
                >
                    {(currentPage !== lastPage && pageLinkComponent(currentPage + 1)) || <span></span>}
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
