import { useMemo } from 'react';

import { SeasonalPromotionData } from '../AppContext/interfaces';
import { SeasonalPromotionId } from './constants';

type GetEnabledSeasonalPromotionPayload = Pick<
    SeasonalPromotionData,
    'seasonalPromotionName' | 'seasonalPromotionEnabled'
>;

/**
 *
 * @description
 * This hook calculates and returns currently enabled seasonal promotion.
 * For every new promotion, new entry should be added and returned from this hook:
 *
 * @example
 * {
 *  isBFCM24SeasonalPromotionEnabled: seasonalPromotionEnabled && seasonalPromotionName === SeasonalPromotionId.BFCM24,
 *  isVDAY25SeasonalPromotionEnabled: seasonalPromotionEnabled && seasonalPromotionName === SeasonalPromotionId.VDAY25
 * },
 */
export function getEnabledSeasonalPromotion({
    seasonalPromotionName,
    seasonalPromotionEnabled,
}: GetEnabledSeasonalPromotionPayload) {
    const enabledSeasonalPromotions = useMemo(
        () => ({
            isBFCM24SeasonalPromotionEnabled:
                seasonalPromotionEnabled && seasonalPromotionName === SeasonalPromotionId.BFCM24,
        }),
        [seasonalPromotionEnabled, seasonalPromotionName],
    );

    return enabledSeasonalPromotions;
}
