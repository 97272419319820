import { createContext, useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as SVGChevronMobile } from '@public/global/header/chevron-mobile.svg';
import { ReactComponent as SVGChevron } from '@public/global/header/chevron.svg';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './MenuDialog.module.css';

export const MenuDialogContext = createContext();

export const MenuDialog = ({ label, children, darkMode = false }) => {
    const dialogRef = useRef();
    const buttonRef = useRef();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        const mql = window.matchMedia('(min-width: 900px)');
        setIsDesktop(mql.matches);
        const handleChange = (e) => {
            setIsDesktop(e.matches);
        };
        mql.addEventListener('change', handleChange);
        return () => {
            mql.removeEventListener('change', handleChange);
        };
    }, []);

    useEffect(() => {
        const header = document.querySelector('[data-additional-attribute="has-new-layout"]');
        if (header) {
            if (dialogVisible) {
                header.setAttribute('data-has-dialog', 'open');
            } else {
                header.setAttribute('data-has-dialog', 'closed');
            }
        }
    }, [dialogVisible]);

    const handleOnBlur = useCallback(() => {
        if (isDesktop) {
            setDialogVisible(false);
        }
    }, [isDesktop]);

    const handleOnMouseEnter = useCallback(() => {
        if (isDesktop) {
            setDialogVisible(true);
        }
    }, [isDesktop]);

    const handleOnMouseLeave = useCallback(
        (e) => {
            if (isDesktop) {
                if (!(e.relatedTarget instanceof HTMLElement) || !dialogRef.current.contains(e.relatedTarget)) {
                    setDialogVisible(false);
                }
            }
        },
        [isDesktop, dialogRef],
    );

    return (
        <>
            <button
                className={styles.button}
                ref={buttonRef}
                onMouseEnter={handleOnMouseEnter}
                onClick={() => setDialogVisible(!dialogVisible)}
                onBlur={handleOnBlur}
                onMouseLeave={handleOnMouseLeave}
            >
                <div className={styles.cloak}></div>
                <div className={styles.wayback}></div>
                <span>
                    {label}
                    <SVGChevron
                        width="4"
                        height="8"
                    />
                    <SVGChevronMobile
                        width="15"
                        height="9"
                    />
                </span>
            </button>
            <MenuDialogContext.Provider value={{ closeSubMenu: () => setDialogVisible(false) }}>
                <dialog
                    className={clsx(styles.dialog, darkMode ? styles.darkMode : '')}
                    ref={dialogRef}
                    open={dialogVisible}
                    onMouseLeave={handleOnMouseLeave}
                >
                    <div className={styles.dialogContainer}>{children}</div>
                </dialog>
            </MenuDialogContext.Provider>
        </>
    );
};

MenuDialog.propTypes = {
    darkMode: PropTypes.bool,
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
