export const getAllUsedKeys = (rootElement) => findAllTextKeys(rootElement).concat(findAllAttributeKeys(rootElement));

function getTextKeyRects(key, anchor) {
    const range = document.createRange();
    range.setStart(key.start.textNode, key.start.index);
    range.setEnd(key.end.textNode, key.end.index);
    return [...range.getClientRects()].map((rectObject) => ({
        x: rectObject.x - anchor.left,
        y: rectObject.y - anchor.top,
        left: rectObject.left - anchor.left,
        top: rectObject.top - anchor.top,
        width: rectObject.width,
        height: rectObject.height,
        rect: rectObject,
    }));
}

export const getRects = (key) => {
    if (key.element) {
        const { width, height } = key.element.getBoundingClientRect();
        return [
            {
                top: 0,
                left: 0,
                width,
                height,
                x: 0,
                y: 0,
                rect: key.element.getBoundingClientRect(),
            },
        ];
    }
    return getTextKeyRects(key, {
        left: key.ownerElement.getBoundingClientRect().left,
        top: key.ownerElement.getBoundingClientRect().top,
    });
};

export function binaryToWords(str) {
    if (str.match(/[10]{16}/g)) {
        const wordFromBinary = str
            .match(/([10]{16}|\s+)/g)
            .map((fromBinary) => String.fromCharCode(parseInt(fromBinary, 2)))
            .join('');
        return wordFromBinary;
    }
    return null;
}
export function stringToBinary(str) {
    return str
        .split('')
        .map((char) => {
            return char.charCodeAt(0).toString(2).padStart(16, '0');
        })
        .join('');
}

export const KEY_BEGIN = '\u{200d}\u{200c}';
export const KEY_END = '\u{200c}\u{200d}';
export const serializeToBinary = function (obj) {
    const binary = stringToBinary(JSON.stringify(obj));
    return binary.replaceAll('1', '\u{feff}').replaceAll('0', '\u{200b}');
};

function textNodesUnder(el) {
    let n;
    const a = [];
    const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false);
    while ((n = walk.nextNode())) a.push(n);
    return a;
}

function getTextKeyData(startPoint) {
    const regex = new RegExp('[\u{feff}\u{200b}]+', 'gu');
    const m = startPoint.textNode.textContent.slice(startPoint.index).match(regex);
    const keyInfo = JSON.parse(
        binaryToWords(
            m[0]
                .split('')
                .map((ch) => (ch.charCodeAt(0) === 8203 ? '0' : '1'))
                .join(''),
        ),
    );
    return keyInfo;
}
function getAttributeKeyData(value) {
    const regex = new RegExp('[\u{feff}\u{200b}]+', 'gu');
    const m = value.match(regex);
    const keyInfo = JSON.parse(
        binaryToWords(
            m[0]
                .split('')
                .map((ch) => (ch.charCodeAt(0) === 8203 ? '0' : '1'))
                .join(''),
        ),
    );
    return keyInfo;
}
function getTextKeyOwnerElement(key) {
    const range = document.createRange();
    range.setStart(key.start.textNode, key.start.index);
    range.setEnd(key.end.textNode, key.end.index);
    let relatedElement;
    if (range.commonAncestorContainer.nodeType === document.TEXT_NODE) {
        relatedElement = range.commonAncestorContainer.parentElement;
    } else {
        relatedElement = range.commonAncestorContainer;
    }
    return relatedElement;
}

function findAllTextKeys(rootElement) {
    const allTextNodes = textNodesUnder(rootElement || document.body);
    const keyStartPoints = [];
    const keyEndPoints = [];
    const keys = [];
    const range = document.createRange();

    allTextNodes.forEach((currentNode) => {
        const allStartMatches = currentNode.textContent.matchAll(/\u{200d}\u{200c}/gu);
        [...allStartMatches].forEach((match) => {
            keyStartPoints.push({
                textNode: currentNode,
                index: match.index,
            });
        });
        const allEndMatches = currentNode.textContent.matchAll(/\u{200c}\u{200d}/gu);
        [...allEndMatches].forEach((match) => {
            keyEndPoints.push({
                textNode: currentNode,
                index: match.index,
            });
        });
    });
    // eslint-disable-next-line no-console
    console.assert(keyStartPoints.length === keyEndPoints.length, {
        keyStartPoints,
        keyEndPoints,
        errorMsg: 'key points mismatch',
    });

    keyStartPoints.forEach((keyStartPoint, index) => {
        const ownerElement = getTextKeyOwnerElement({
            start: keyStartPoint,
            end: keyEndPoints[index],
        });
        range.setStart(keyStartPoint.textNode, keyStartPoint);
        range.setEnd(keyEndPoints[index].textNode, keyEndPoints[index]);
        keys.push({
            start: keyStartPoint,
            end: keyEndPoints[index],
            data: getTextKeyData(keyStartPoint),
            type: 'text',
            ownerElement,
            rects: [...range.getClientRects()],
            top: ownerElement.getBoundingClientRect().top + document.documentElement.scrollTop,
            left: ownerElement.getBoundingClientRect().left + document.documentElement.scrollLeft,
            visible: isElementVisible(ownerElement),
        });
    });
    return keys;
}

function findAllAttributeKeys(rootElement) {
    const elemsWithAttrsLoc = [].map
        .call(rootElement.querySelectorAll('*'), (ownerElement) => {
            let locatedAttrs = [].filter.call(
                ownerElement.attributes,
                (attr) => attr.value.search(/\u{200d}\u{200c}/gu) > -1,
            );
            locatedAttrs = locatedAttrs.map((attr) => ({
                attribute: attr.name,
                data: getAttributeKeyData(attr.value),
            }));
            return locatedAttrs.map((locAttr) => ({
                element: ownerElement,
                type: 'attribute',
                ownerElement,
                rects: [ownerElement.getBoundingClientRect()],
                data: locAttr.data,
                visible: isElementVisible(ownerElement),
            }));
        })
        .filter((o) => o.length > 0)
        .flat();
    return elemsWithAttrsLoc;
}

export function isElementVisible(el) {
    if (getComputedStyle(el).visibility === 'hidden') {
        return false;
    }
    let parent = el.parentElement;
    while (parent) {
        if (getComputedStyle(parent).opacity == '0') {
            return false;
        }
        parent = parent.parentElement;
    }
    return true;
}
