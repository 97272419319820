import PropTypes from 'prop-types';

import styles from './SubmenuList.module.css';

const SubmenuList = ({ children, label, darkMode = false }) => {
    return (
        <div
            className={[
                !label ? styles.noLabel : '',
                !children.length ? styles.custom : '',
                darkMode ? styles.darkMode : '',
            ]
                .filter(Boolean)
                .join(' ')}
        >
            {label && <div className={styles.label}>{label}</div>}

            {children.length ? (
                <ul className={styles.list}>
                    {children.map((child, i) => (
                        <li key={i}>{child}</li>
                    ))}
                </ul>
            ) : (
                children
            )}
        </div>
    );
};
SubmenuList.propTypes = {
    darkMode: PropTypes.bool,
    label: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default SubmenuList;
