import React, { cloneElement } from 'react';

import clsx from 'clsx';

import styles from './SubmenuListItem.module.css';

interface SubmenuListItemProps {
    label: JSX.Element;
    darkMode?: boolean;
    icon?: JSX.Element;
    badge?: JSX.Element;
}
const SubmenuListItem = ({ icon, label, badge, darkMode = false }: SubmenuListItemProps) => {
    const styledIcon = icon
        ? cloneElement(icon, {
              className: styles.icon,
              key: 1,
              color: '#00baff',
          })
        : null;

    return (
        <span className={clsx(styles.item, darkMode ? styles.darkMode : '')}>
            {styledIcon}
            {badge}
            {['span', 'div'].includes(label.type) ? label.props.children : label}
        </span>
    );
};

export default SubmenuListItem;
