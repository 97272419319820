import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteLegal } from '@static_components/laravelLinks';
import {
    multisiteRouteLegalPageAntispam,
    multisiteRouteLegalPageAntispamUs,
    multisiteRouteLegalPagePrivacy,
    multisiteRouteLegalPagePrivacyUs,
    multisiteRouteLegalPageTermsUs,
} from '@static_components/multisiteLinks';
import clsx from 'clsx';

import FooterManageCookie from '../FooterManageCookie';
import styles from './FooterLegal.module.css';

interface FooterLegalProps {
    darkMode?: boolean;
}

const FooterLegal = ({ darkMode = false }: FooterLegalProps) => {
    const { t } = useTranslation('base/_blocks/footer');
    const { geo } = useAppContext();
    const { locale } = useRouter();
    const geoIso = geo?.country_iso_code ?? '';
    return (
        <div className={clsx(styles['mfooter-legal'], darkMode ? styles.darkMode : '')}>
            <ul>
                <li>
                    {locale === 'en' && ['US', 'CA'].includes(geoIso) ? (
                        <a href={multisiteRouteLegalPagePrivacyUs(locale)}>{t('menu_privacy_policy')}</a>
                    ) : (
                        <a href={multisiteRouteLegalPagePrivacy(locale)}>{t('menu_privacy_policy')}</a>
                    )}
                </li>
                <li>
                    {locale === 'en' && ['US', 'CA'].includes(geoIso) ? (
                        <a href={multisiteRouteLegalPageAntispamUs(locale)}>{t('menu_spam_policy')}</a>
                    ) : (
                        <a href={multisiteRouteLegalPageAntispam(locale)}>{t('menu_spam_policy')}</a>
                    )}
                </li>
                <li>
                    {locale === 'pt' && ['BR'].includes(geoIso) ? (
                        <a href={laravelRouteLegal(locale)}>{t('menu_service_terms')}</a>
                    ) : locale === 'pt' ? (
                        <a href={laravelRouteLegal('en')}>{t('menu_service_terms')}</a>
                    ) : locale === 'en' && ['US', 'CA'].includes(geoIso) ? (
                        <a href={multisiteRouteLegalPageTermsUs(locale)}>{t('menu_service_terms')}</a>
                    ) : (
                        <a href={laravelRouteLegal(locale)}>{t('menu_service_terms')}</a>
                    )}
                </li>
                {geo?.continent_code === 'EU' && (
                    <li>
                        <FooterManageCookie />
                    </li>
                )}
                {['de'].includes(locale ?? '') && (
                    <li>
                        <a href="https://www.getresponse.com/de/impressum">{t('menu_impressum')}</a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default FooterLegal;
