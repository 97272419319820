import { useMemo } from 'react';

const DOTS = '...';

const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

interface UsePaginationProps {
    totalCount: number;
    pageSize: number;
    siblingCount: number;
    currentPage: number;
    pageSizeOffset: number;
}

/**
 * @description Function that calculates which indices should be currently visible in pagination.
 */
export const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage,
    pageSizeOffset = 0,
}: UsePaginationProps) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil((totalCount - pageSizeOffset) / pageSize);
        if (totalPageCount === 1) {
            return null;
        }
        const FIRST_PAGE_INDEX = 1;
        const LAST_PAGE_INDEX = totalPageCount;
        // if we are near the end the pagination range we want to offset middle point of pagination so paginator length doesn't change
        const leftRangeMiddlePoint = Math.min(currentPage, LAST_PAGE_INDEX - siblingCount * 2);
        // With big siblingCount value there could be possibility that we end with negative value, so we need safeguard
        const leftRangeEnd = Math.max(leftRangeMiddlePoint - siblingCount, 2);
        // the same as with leftRange, but when we are near beginning of the range
        const rightRangeMiddlePoint = Math.max(currentPage, FIRST_PAGE_INDEX + siblingCount * 2);
        // With big siblingCount value there could be possibility that we end with value bigger than totalPageCount, so we need safeguard
        const rightRangeEnd = Math.min(rightRangeMiddlePoint + siblingCount, totalPageCount - 1);

        /*
            We do not want to show dots if there is only one position left 
            after/before the left/right page count as that would lead to a change if our Pagination
            component size which we do not want
        */
        const leftEnd = leftRangeEnd > 2 ? [FIRST_PAGE_INDEX, DOTS] : [FIRST_PAGE_INDEX];
        const rightEnd = rightRangeEnd < totalPageCount - 1 ? [DOTS, LAST_PAGE_INDEX] : [LAST_PAGE_INDEX];

        return [...leftEnd, ...range(leftRangeEnd, rightRangeEnd), ...rightEnd];
    }, [totalCount, pageSizeOffset, pageSize, siblingCount, currentPage]);

    return paginationRange;
};
