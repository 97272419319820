import { useEffect, useId, useRef, useState } from 'react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import TransText from 'next-translate/TransText';

import Trans from '@components/Trans';

import { yupResolver } from '@hookform/resolvers/yup';
import useTranslation from '@hooks/useTranslation';
import { laravelRouteAboutContactUs } from '@static_components/laravelLinks';
import { multisiteRouteLegalPagePrivacy } from '@static_components/multisiteLinks';
import clsx from 'clsx';
import * as Yup from 'yup';

import styles from './SubscribeForm.module.css';

const { publicRuntimeConfig } = getConfig();
type FormFields = {
    name: string;
    email: string;
    consent: boolean;
    language: string;
    recaptcha?: string;
};
type ServerError = {
    field?: string;
    message: string;
};

const PictureEn = dynamic(() => import('./assets/default'), {
    ssr: true,
});
const PicturePl = dynamic(() => import('./assets/pl'), {
    ssr: true,
});

interface SubscribeFormProps {
    formTitle?: JSX.Element;
    formImage?: JSX.Element;
    singlePost?: boolean;
    formSubtitle?: JSX.Element;
    customClass?: string;
    thankyouRedirect?: boolean;
    tag?: string;
}

function SubscribeForm({
    formTitle,
    formSubtitle,
    formImage,
    singlePost = false,
    customClass,
    thankyouRedirect = true,
    tag,
}: SubscribeFormProps): JSX.Element {
    const { t } = useTranslation('pages/blog/_blocks/newsletter');
    const id = useId();
    const { locale } = useRouter();
    const localeForThp = locale ? locale : '';
    const thankyou_url = {
        default: 'https://lp.getresponse.com/blog-newsletter-thank-you',
        de: 'https://lp.getresponse.com/anmeldung-erfolgreich',
        pl: 'https://lp.getresponse.pl/blog-newsletter-podziekowanie-za-zapis',
    }[['de', 'pl'].includes(localeForThp) ? localeForThp : 'default'];

    const [isLoading, setLoading] = useState(false);
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
    const [serverErrors, setServerErrors] = useState<ServerError[]>([]);
    const [isRecaptchaOn, setIsRecaptchaOn] = useState(true);
    const { ref: refForm, inView: inViewForm } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [formValues, setFormValues] = useState({
        email: '',
        name: '',
    });
    const schema = Yup.object().shape({
        name: Yup.string().required(t('validation:required')).max(200, t('validation:invalid')),
        email: Yup.string()
            .required(t('validation:required'))
            .email(t('validation:email'))
            .max(200, t('validation:invalid')),
    });
    useEffect(() => {
        fetch('/api/dms_reCaptcha', {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.dms_reCaptcha === true) {
                    setIsRecaptchaOn(false);
                }
            });
    }, [setIsRecaptchaOn]);
    const GDPRInfo = () => (
        <small
            className={styles.terms}
            dangerouslySetInnerHTML={{
                __html: t('formTerms')
                    .replace(/:url2/gi, multisiteRouteLegalPagePrivacy(locale))
                    .replace(/:url/gi, laravelRouteAboutContactUs(locale)),
            }}
        />
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            language: 'en',
            consent: false,
        },
    });
    const onSubmit = async (form: FormFields) => {
        setServerErrors([]);
        setLoading(true);

        const formData = {
            name: form.name,
            email: form.email,
            marketing_consent: !!form.consent,
            language: locale,
            recaptcha: '',
            tag,
        };
        if (isRecaptchaOn && recaptchaRef.current) {
            const token = await recaptchaRef.current.executeAsync();
            if (typeof token === 'string') {
                formData.recaptcha = token;
            }
        }

        try {
            const api = '/api/blog_subscribe';
            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            setLoading(false);
            if (data?.errors) {
                setServerErrors(data.errors);
            }

            if (data?.success) {
                setIsSuccessfullySubmitted(true);
                if (thankyouRedirect && data.redirect) {
                    window.location = data.redirect;
                }
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    const Figure = () => {
        return (
            <figure className={clsx(styles.image, locale === 'pl' ? styles.pl : '')}>
                {locale === 'pl' ? <PicturePl /> : <PictureEn />}
            </figure>
        );
    };

    const formIMG = formImage ? formImage : <Figure />;

    return (
        <>
            <aside
                aria-labelledby={id}
                className={clsx(styles.newsletter, singlePost ? styles.singlePost : '')}
                data-test="blog-newsletter"
                id="newsletter-section"
            >
                {isSuccessfullySubmitted ? (
                    <div className={styles.wrapper}>
                        <div className={styles.thankyou}>
                            <h3>
                                <TransText
                                    text={t('thankYouTitle').replaceAll(' class="styled"', '')}
                                    components={{
                                        span: <span />,
                                        u: <span />,
                                        br: <br />,
                                        mark: <strong />,
                                    }}
                                />
                            </h3>
                            <p>{t('thankYouDesc')}</p>
                            <p className={styles.back}>
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        reset();
                                        if (isRecaptchaOn) recaptchaRef.current?.reset();
                                        setIsSuccessfullySubmitted(false);
                                    }}
                                >
                                    {t('thankYouBack')}
                                </a>
                            </p>
                        </div>
                        {formIMG}
                    </div>
                ) : (
                    <div className={clsx(styles.wrapper, customClass ?? customClass)}>
                        <form
                            className={styles.form}
                            ref={refForm}
                            name="newsletter"
                            method="post"
                            action="/blog#newsletter-section"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                        >
                            <h2 id={id}>
                                {formTitle ? (
                                    formTitle
                                ) : (
                                    <Trans
                                        i18nKey="formTitle"
                                        components={{
                                            br: <br />,
                                        }}
                                        ns="pages/blog/_blocks/newsletter"
                                    />
                                )}
                            </h2>
                            {formSubtitle ? <div className={styles.subTitle}>{formSubtitle}</div> : null}

                            {serverErrors.length > 0 && (
                                <ul className={styles.mainErrors}>
                                    {serverErrors.map((error, i) => (
                                        <li key={i}>{error.message}</li>
                                    ))}
                                </ul>
                            )}
                            <div className={styles.inner}>
                                <div
                                    className={
                                        styles.formGroup +
                                        ` ${(errors.name && styles.hasError) || ''}` +
                                        ` ${
                                            (serverErrors &&
                                                serverErrors.find((error) => error.field == 'name') &&
                                                styles.hasError) ||
                                            ''
                                        }`
                                    }
                                >
                                    <input
                                        {...register('name', { onChange: (e) => handleChange(e) })}
                                        aria-label={t('formInputName')}
                                        placeholder={t('formInputName')}
                                        type="text"
                                        name="name"
                                        required
                                        data-ats-blog-newsletter-form="name_input"
                                        data-track="newsletter_name_input"
                                        data-test="newsletter_name_input"
                                        className={clsx(
                                            styles.control,
                                            formValues.name !== '' ? styles.notempty : '',
                                            errors.name ? styles.hasError : '',
                                        )}
                                    />
                                    {errors.name && <div className={styles.error}>{errors.name?.message}</div>}
                                    {serverErrors && serverErrors.find((error) => error.field == 'name') && (
                                        <div className={styles.error}>
                                            {serverErrors.find((error) => error.field == 'name')?.message}
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={
                                        styles.formGroup +
                                        ` ${(errors.email && styles.hasError) || ''}` +
                                        ` ${
                                            (serverErrors &&
                                                serverErrors.find((error) => error.field == 'email') &&
                                                styles.hasError) ||
                                            ''
                                        }`
                                    }
                                >
                                    <input
                                        {...register('email', { onChange: (e) => handleChange(e) })}
                                        aria-label={t('formInputEmail')}
                                        placeholder={t('formInputEmail')}
                                        type="email"
                                        name="email"
                                        required
                                        data-ats-blog-newsletter-form="email_input"
                                        data-track="newsletter_email_input"
                                        data-test="newsletter_email_input"
                                        className={clsx(
                                            styles.control,
                                            formValues.email !== '' ? styles.notempty : '',
                                            errors.email ? styles.hasError : '',
                                        )}
                                    />
                                    {errors.email && <div className={styles.error}>{errors.email?.message}</div>}
                                    {serverErrors && serverErrors.find((error) => error.field == 'email') && (
                                        <div className={styles.error}>
                                            {serverErrors.find((error) => error.field == 'email')?.message}
                                        </div>
                                    )}
                                </div>
                                <input
                                    type="hidden"
                                    name="thanYouPage"
                                    value={thankyou_url}
                                />
                                <div className={`${styles.formGroup} ${styles.buttonGroup}`}>
                                    <button
                                        className={styles.button}
                                        disabled={isLoading}
                                        type="submit"
                                        name="submit"
                                        data-ats-blog-newsletter-form="button"
                                        data-test="newsletter_submit"
                                    >
                                        {t('formButton')}
                                    </button>
                                </div>
                            </div>
                            <GDPRInfo />
                        </form>
                        {formIMG}
                    </div>
                )}
            </aside>
            {inViewForm && isRecaptchaOn && (
                <div className={styles.captcha}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={publicRuntimeConfig.recaptcha_sitekey}
                    />
                </div>
            )}
        </>
    );
}

export default SubscribeForm;
