export const multisiteRouteResourcesGuides12739 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/12739",
"en": "https://www.getresponse.com/resources/guides/getresponse-e-commerce-tools",
"de": "https://www.getresponse.com/de/resources/guides/getresponse-e-commerce-tools",
"it": "https://www.getresponse.com/it/resources/guides/strumenti-di-e-commerce-di-getresponseproduct-brief",
"es": "https://www.getresponse.com/es/recursos/guides/getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides12765 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/12765",
"es": "https://www.getresponse.com/es/recursos/guides/plan-de-accion-de-afiliados",
"en": "https://www.getresponse.com/resources/guides/affiliate-action-plan",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/plan-dzialania-partnera"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesContentMonetizationResumo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/content-monetization-odprawa",
"en": "https://www.getresponse.com/resources/guides/content-monetization-brief",
"de": "https://www.getresponse.com/de/resources/guides/content-monetization-einweisung",
"it": "https://www.getresponse.com/it/resources/guides/content-monetization-briefing",
"es": "https://www.getresponse.com/es/recursos/guides/content-monetization-instrucciones"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesContentMonetizationInstrucciones = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/content-monetization-instrucciones",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/content-monetization-odprawa",
"en": "https://www.getresponse.com/resources/guides/content-monetization-brief",
"de": "https://www.getresponse.com/de/resources/guides/content-monetization-einweisung",
"it": "https://www.getresponse.com/it/resources/guides/content-monetization-briefing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesContentMonetizationBriefing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/content-monetization-briefing",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/content-monetization-odprawa",
"en": "https://www.getresponse.com/resources/guides/content-monetization-brief",
"de": "https://www.getresponse.com/de/resources/guides/content-monetization-einweisung",
"es": "https://www.getresponse.com/es/recursos/guides/content-monetization-instrucciones"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesContentMonetizationEinweisung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/content-monetization-einweisung",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/content-monetization-odprawa",
"en": "https://www.getresponse.com/resources/guides/content-monetization-brief",
"it": "https://www.getresponse.com/it/resources/guides/content-monetization-briefing",
"es": "https://www.getresponse.com/es/recursos/guides/content-monetization-instrucciones"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesContentMonetizationOdprawa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/content-monetization-odprawa",
"en": "https://www.getresponse.com/resources/guides/content-monetization-brief",
"de": "https://www.getresponse.com/de/resources/guides/content-monetization-einweisung",
"it": "https://www.getresponse.com/it/resources/guides/content-monetization-briefing",
"es": "https://www.getresponse.com/es/recursos/guides/content-monetization-instrucciones"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesContentMonetizationBrief = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/content-monetization-brief",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/content-monetization-odprawa",
"de": "https://www.getresponse.com/de/resources/guides/content-monetization-einweisung",
"it": "https://www.getresponse.com/it/resources/guides/content-monetization-briefing",
"es": "https://www.getresponse.com/es/recursos/guides/content-monetization-instrucciones"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakWypromowacKursZaPomocaEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-wypromowac-kurs-za-pomoca-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesNewsletterAPagamentoComeCrearla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/newsletter-a-pagamento-come-crearla"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSpotifySeoCheckliste = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/spotify-seo-checkliste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToBuildAThrivingPaidNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-build-a-thriving-paid-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides3VorlagenFuerNewsletterBegruessungstexte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/3-vorlagen-fuer-newsletter-begruessungstexte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEMailMarketingGlossar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/e-mail-marketing-glossar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides10TippsFuerEineHoheAbsenderReputation = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/10-tipps-fuer-eine-hohe-absender-reputation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLaunchYourCourseWithEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/launch-your-course-with-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeCrescereListaContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-crescere-lista-contatti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesKiRechtskonformerEinsatzImEMailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/ki-rechtskonformer-einsatz-im-e-mail-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesTestyAb = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/testy-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesChecklisteZurEMailZustellbarkeit = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/checkliste-zur-e-mail-zustellbarkeit",
"en": "https://www.getresponse.com/resources/guides/email-deliverability-checklist"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesYearInReview = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/year-in-review"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides21WaysToBuildYourContactList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/21-ways-to-build-your-contact-list",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-powiekszyc-baze-email-marketingowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesDieSiebenGewohnheitenErfolgreicherAffiliates = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/die-sieben-gewohnheiten-erfolgreicher-affiliates",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/siedem-nawykow-odnoszacych-sukcesy-partnerow",
"en": "https://www.getresponse.com/resources/guides/7-habits-of-successful-affiliates",
"es": "https://www.getresponse.com/es/recursos/guides/siete-habitos-de-los-afiliados-de-gran-exito",
"it": "https://www.getresponse.com/it/resources/guides/sette-abitudini-degli-affiliati-di-grande-successo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSetteAbitudiniDegliAffiliatiDiGrandeSuccesso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/sette-abitudini-degli-affiliati-di-grande-successo",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/siedem-nawykow-odnoszacych-sukcesy-partnerow",
"en": "https://www.getresponse.com/resources/guides/7-habits-of-successful-affiliates",
"es": "https://www.getresponse.com/es/recursos/guides/siete-habitos-de-los-afiliados-de-gran-exito",
"de": "https://www.getresponse.com/de/resources/guides/die-sieben-gewohnheiten-erfolgreicher-affiliates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSieteHabitosDeLosAfiliadosDeGranExito = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/siete-habitos-de-los-afiliados-de-gran-exito",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/siedem-nawykow-odnoszacych-sukcesy-partnerow",
"en": "https://www.getresponse.com/resources/guides/7-habits-of-successful-affiliates",
"it": "https://www.getresponse.com/it/resources/guides/sette-abitudini-degli-affiliati-di-grande-successo",
"de": "https://www.getresponse.com/de/resources/guides/die-sieben-gewohnheiten-erfolgreicher-affiliates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSiedemNawykowOdnoszacychSukcesyPartnerow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/siedem-nawykow-odnoszacych-sukcesy-partnerow",
"en": "https://www.getresponse.com/resources/guides/7-habits-of-successful-affiliates",
"es": "https://www.getresponse.com/es/recursos/guides/siete-habitos-de-los-afiliados-de-gran-exito",
"it": "https://www.getresponse.com/it/resources/guides/sette-abitudini-degli-affiliati-di-grande-successo",
"de": "https://www.getresponse.com/de/resources/guides/die-sieben-gewohnheiten-erfolgreicher-affiliates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPlanerMarketera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/planer-marketera",
"en": "https://www.getresponse.com/resources/guides/global-marketing-calendar",
"de": "https://www.getresponse.com/de/resources/guides/marketing-planer-2023"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeAdeguarsiAiNuoviStandardEmailPerIl2024 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-adeguarsi-ai-nuovi-standard-email-per-il-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAnpassungAnDieNeuenEMailStandards2024 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/anpassung-an-die-neuen-e-mail-standards-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesNoweStandardyWysylekEmaili2024 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/nowe-standardy-wysylek-emaili-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAdaptingToNewEmailStandardsIn2024 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/adapting-to-new-email-standards-in-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailDeliverabilityChecklist = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/email-deliverability-checklist",
"de": "https://www.getresponse.com/de/resources/guides/checkliste-zur-e-mail-zustellbarkeit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLandingPagePerfetteConLIa = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/landing-page-perfette-con-l-ia"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketingStrategieVincentiPerIlQ4 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/email-marketing-strategie-vincenti-per-il-q4"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeImpostareUnaStrategiaDiEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-impostare-una-strategia-di-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketingAutomationBestPracticePerLaPuliziaDelleListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/email-marketing-automation-best-practice-per-la-pulizia-delle-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGliUniciPromptDiChatgptDiCuiHaiBisognoSeSeiUnProfessionistaDelMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/gli-unici-prompt-di-chatgpt-di-cui-hai-bisogno-se-sei-un-professionista-del-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesBeyondPersonalization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/beyond-personalization"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketingGlossary = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/email-marketing-glossary"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides10TippsFuerRechtssicheresEMailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/10-tipps-fuer-rechtssicheres-e-mail-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesStarterPackPerLaTuaNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/starter-pack-per-la-tua-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesChatgptPrompts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/chatgpt-prompts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAffiliateActionPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/affiliate-action-plan",
"es": "https://www.getresponse.com/es/recursos/guides/plan-de-accion-de-afiliados",
"de": "https://www.getresponse.com/de/resources/guides/12765",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/plan-dzialania-partnera"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides7HabitsOfSuccessfulAffiliates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/7-habits-of-successful-affiliates",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/siedem-nawykow-odnoszacych-sukcesy-partnerow",
"es": "https://www.getresponse.com/es/recursos/guides/siete-habitos-de-los-afiliados-de-gran-exito",
"it": "https://www.getresponse.com/it/resources/guides/sette-abitudini-degli-affiliati-di-grande-successo",
"de": "https://www.getresponse.com/de/resources/guides/die-sieben-gewohnheiten-erfolgreicher-affiliates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGetresponseECommerceTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/getresponse-e-commerce-tools",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/12739",
"de": "https://www.getresponse.com/de/resources/guides/getresponse-e-commerce-tools",
"it": "https://www.getresponse.com/it/resources/guides/strumenti-di-e-commerce-di-getresponseproduct-brief",
"es": "https://www.getresponse.com/es/recursos/guides/getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAffiliateProgramDoSAndDonTs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/affiliate-program-do-s-and-don-ts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAgencyProgramGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/marketing-agency-program-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesNewAiFeatures = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/new-ai-features"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesOptimizationGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/optimization-guide",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-optymalizacji",
"de": "https://www.getresponse.com/de/resources/guides/optimierungsleitfaden",
"it": "https://www.getresponse.com/it/resources/guides/guida-all-ottimizzazione",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-optimizacion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAffiliateGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/affiliate-guide",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-dla-partnerow",
"de": "https://www.getresponse.com/de/resources/guides/affiliate-leitfaden",
"it": "https://www.getresponse.com/it/resources/guides/guida-di-getresponse-per-gli-affiliati",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-affiliados-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesIaAmpEmailMarketingScenariEApplicazioniPratiche = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/ia-amp-email-marketing-scenari-e-applicazioni-pratiche"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSaasPartnershipsForAgencies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/saas-partnerships-for-agencies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWhyMarketingAgenciesNeedMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/why-marketing-agencies-need-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides10TippsFuerABTests = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/10-tipps-fuer-a-b-tests"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWieNachhaltigSindVerschiedeneMarketingmedien = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/wie-nachhaltig-sind-verschiedene-marketingmedien"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesStrumentiDiECommerceDiGetresponseproductBrief = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/strumenti-di-e-commerce-di-getresponseproduct-brief",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/12739",
"en": "https://www.getresponse.com/resources/guides/getresponse-e-commerce-tools",
"de": "https://www.getresponse.com/de/resources/guides/getresponse-e-commerce-tools",
"es": "https://www.getresponse.com/es/recursos/guides/getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuidaDiGetresponsePerGliAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/guida-di-getresponse-per-gli-affiliati",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-dla-partnerow",
"en": "https://www.getresponse.com/resources/guides/affiliate-guide",
"de": "https://www.getresponse.com/de/resources/guides/affiliate-leitfaden",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-affiliados-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuidaAllOttimizzazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/guida-all-ottimizzazione",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-optymalizacji",
"en": "https://www.getresponse.com/resources/guides/optimization-guide",
"de": "https://www.getresponse.com/de/resources/guides/optimierungsleitfaden",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-optimizacion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPersonalisierungUndDatenschutz = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/personalisierung-und-datenschutz"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAffiliateLeitfaden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/affiliate-leitfaden",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-dla-partnerow",
"en": "https://www.getresponse.com/resources/guides/affiliate-guide",
"it": "https://www.getresponse.com/it/resources/guides/guida-di-getresponse-per-gli-affiliati",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-affiliados-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesOptimierungsleitfaden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/optimierungsleitfaden",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-optymalizacji",
"en": "https://www.getresponse.com/resources/guides/optimization-guide",
"it": "https://www.getresponse.com/it/resources/guides/guida-all-ottimizzazione",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-optimizacion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketing10BuonePratichePerGliABTest = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/email-marketing-10-buone-pratiche-per-gli-a-b-test"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeMigliorareLaDeliverabilityDelleTueEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-migliorare-la-deliverability-delle-tue-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeOttenereIlMassimoDalleTueCampagneDiEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-ottenere-il-massimo-dalle-tue-campagne-di-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingPlaner2023 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/marketing-planer-2023",
"en": "https://www.getresponse.com/resources/guides/global-marketing-calendar",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/planer-marketera"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWieKannstDuNochMehrAusDeinemEMailMarketingMachen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/wie-kannst-du-noch-mehr-aus-deinem-e-mail-marketing-machen",
"en": "https://www.getresponse.com/resources/guides/how-to-squeeze-even-more-out-of-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGlobalMarketingCalendar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/global-marketing-calendar",
"de": "https://www.getresponse.com/de/resources/guides/marketing-planer-2023",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/planer-marketera"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLeitfadenFuerEMailMarketingKpis = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/leitfaden-fuer-e-mail-marketing-kpis",
"en": "https://www.getresponse.com/resources/guides/email-marketing-kpis"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToSqueezeEvenMoreOutOfEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-squeeze-even-more-out-of-email-marketing",
"de": "https://www.getresponse.com/de/resources/guides/wie-kannst-du-noch-mehr-aus-deinem-e-mail-marketing-machen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComoPromocionarTuWebinarEn9SencillosPasos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/como-promocionar-tu-webinar-en-9-sencillos-pasos"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesUnFunnelDeEmailMarketingParaPlanificarElRecorridoDeTusUsuariosRegistrados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/un-funnel-de-email-marketing-para-planificar-el-recorrido-de-tus-usuarios-registrados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides10FormasProbadasDeAumentarLasConversionesDeTuEcommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/10-formas-probadas-de-aumentar-las-conversiones-de-tu-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides4EscenariosClaveParaElMarketingAutomationEnEcommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/4-escenarios-clave-para-el-marketing-automation-en-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComoMejorarElRendimientoDelMarketingYLosDatos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/como-mejorar-el-rendimiento-del-marketing-y-los-datos"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLeadNurturingParaPymes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/lead-nurturing-para-pymes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesCreaUnArgumentoComercialParaVenderElMarketingAutomation = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/crea-un-argumento-comercial-para-vender-el-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesRekomendacjeAiEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/rekomendacje-ai-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPlanDeAccionDeAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/plan-de-accion-de-afiliados",
"en": "https://www.getresponse.com/resources/guides/affiliate-action-plan",
"de": "https://www.getresponse.com/de/resources/guides/12765",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/plan-dzialania-partnera"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGetresponseEcommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/getresponse-ecommerce",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/12739",
"en": "https://www.getresponse.com/resources/guides/getresponse-e-commerce-tools",
"de": "https://www.getresponse.com/de/resources/guides/getresponse-e-commerce-tools",
"it": "https://www.getresponse.com/it/resources/guides/strumenti-di-e-commerce-di-getresponseproduct-brief"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuiaDeAffiliadosGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-affiliados-getresponse",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-dla-partnerow",
"en": "https://www.getresponse.com/resources/guides/affiliate-guide",
"de": "https://www.getresponse.com/de/resources/guides/affiliate-leitfaden",
"it": "https://www.getresponse.com/it/resources/guides/guida-di-getresponse-per-gli-affiliati"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuiaDeOptimizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-optimizacion",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-optymalizacji",
"en": "https://www.getresponse.com/resources/guides/optimization-guide",
"de": "https://www.getresponse.com/de/resources/guides/optimierungsleitfaden",
"it": "https://www.getresponse.com/it/resources/guides/guida-all-ottimizzazione"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesCopywritingPovNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/copywriting-pov-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSegmentyEmailMarketingInfografika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/segmenty-email-marketing-infografika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPlanDzialaniaPartnera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/plan-dzialania-partnera",
"es": "https://www.getresponse.com/es/recursos/guides/plan-de-accion-de-afiliados",
"en": "https://www.getresponse.com/resources/guides/affiliate-action-plan",
"de": "https://www.getresponse.com/de/resources/guides/12765"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPoradnikDlaPartnerow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-dla-partnerow",
"en": "https://www.getresponse.com/resources/guides/affiliate-guide",
"de": "https://www.getresponse.com/de/resources/guides/affiliate-leitfaden",
"it": "https://www.getresponse.com/it/resources/guides/guida-di-getresponse-per-gli-affiliati",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-affiliados-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPoradnikOptymalizacji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/poradnik-optymalizacji",
"en": "https://www.getresponse.com/resources/guides/optimization-guide",
"de": "https://www.getresponse.com/de/resources/guides/optimierungsleitfaden",
"it": "https://www.getresponse.com/it/resources/guides/guida-all-ottimizzazione",
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-optimizacion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuiaCompletaDeMarketingAutomationParaEcommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/guia-completa-de-marketing-automation-para-ecommerce",
"en": "https://www.getresponse.com/resources/guides/complete-guide-to-marketing-automation-for-ecommerce",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/kompleksowy-przewodnik-po-marketing-automation-w-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuiaRapidaParaAutomatizarTuMarketingEnGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/guia-rapida-para-automatizar-tu-marketing-en-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuiaDeEmailMarketingParaEcommerce10Plantillas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/guia-de-email-marketing-para-ecommerce-10-plantillas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuiaCompletaDeOptimizacionDeLandingPages = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/guia-completa-de-optimizacion-de-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLaBuyerPersonaNonEMorta = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/la-buyer-persona-non-e-morta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketingAutomationESegmentazioneAvanzata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/email-marketing-automation-e-segmentazione-avanzata"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesErsteSchritteMitDenLandingPageStatistiken = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/erste-schritte-mit-den-landing-page-statistiken",
"en": "https://www.getresponse.com/resources/guides/getting-started-with-landing-page-analytics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEcommerceMarketingAutomationScenarios = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/ecommerce-marketing-automation-scenarios"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesContentThatRocksPerECommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/content-that-rocks-per-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWieDuDenErfolgDeinerMarketingAutomationKampagneMisst = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/wie-du-den-erfolg-deiner-marketing-automation-kampagne-misst",
"en": "https://www.getresponse.com/resources/guides/how-to-measure-the-success-of-your-marketing-automation-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailDeliverabilityCheatSheet = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/email-deliverability-cheat-sheet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeUtilizzareIlContentMarketingPerGenerarePiuContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-utilizzare-il-content-marketing-per-generare-piu-contatti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesDiscountStrategyQuickCheatSheet = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/discount-strategy-quick-cheat-sheet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomationBasics17TippsFuerDenEinstieg = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/marketing-automation-basics-17-tipps-fuer-den-einstieg",
"en": "https://www.getresponse.com/resources/guides/marketing-automation-basics-17-tips-for-getting-started-by-barry-feldman"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomationBestPracticeDiTaggingEScoring = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/marketing-automation-best-practice-di-tagging-e-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComoCrearUnaListaDeEmailMarketingYConseguirTusPrimeros50Suscriptores = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/como-crear-una-lista-de-email-marketing-y-conseguir-tus-primeros-50-suscriptores",
"en": "https://www.getresponse.com/resources/guides/how-to-create-an-email-list-the-right-way",
"de": "https://www.getresponse.com/de/resources/guides/wie-man-eine-e-mail-liste-richtig-erstellt-und-die-ersten-50-abonnenten-gewinnt"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLandingPagePrepararsiAllAtterraggioVincente = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/landing-page-prepararsi-all-atterraggio-vincente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComoPlanearYEnviarTuPrimeraCampanaDeEmailMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/recursos/guides/como-planear-y-enviar-tu-primera-campana-de-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides3TecnichePostVenditaPerAumentareIRicaviNelSettoreAutomotive = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/3-tecniche-post-vendita-per-aumentare-i-ricavi-nel-settore-automotive"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLOttimizzazioneDelCustomerJourneyPerLeGrandiAziendeEPerIProgettiComplessiDiMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/l-ottimizzazione-del-customer-journey-per-le-grandi-aziende-e-per-i-progetti-complessi-di-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesUnaPanoramicaSulleTecnologieDiMarketingPerLeAziendeDelMidMarket = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/una-panoramica-sulle-tecnologie-di-marketing-per-le-aziende-del-mid-market"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuidaEssenzialeAllaMarketingAutomationPerECommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/guida-essenziale-alla-marketing-automation-per-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesKakProdavatOnlajnKursPoshagovoeRukovodstvo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/kak-prodavat-onlajn-kurs-poshagovoe-rukovodstvo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingPlaner2022 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/marketing-planer-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWieManEineEMailListeRichtigErstelltUndDieErsten50AbonnentenGewinnt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/wie-man-eine-e-mail-liste-richtig-erstellt-und-die-ersten-50-abonnenten-gewinnt",
"en": "https://www.getresponse.com/resources/guides/how-to-create-an-email-list-the-right-way",
"es": "https://www.getresponse.com/es/recursos/guides/como-crear-una-lista-de-email-marketing-y-conseguir-tus-primeros-50-suscriptores"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides7IdeePerCollegareSmsEMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/7-idee-per-collegare-sms-e-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides7IdeenFuerSmsInDerMarketingAutomation = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/7-ideen-fuer-sms-in-der-marketing-automation",
"en": "https://www.getresponse.com/resources/guides/sms-marketing-automation-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLoStatoDelLeadNurturingPerLeImpreseDiMedieEGrandiDimensioni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/lo-stato-del-lead-nurturing-per-le-imprese-di-medie-e-grandi-dimensioni"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides30BestPracticesPerLEMailMarketingNel2022 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/30-best-practices-per-l-e-mail-marketing-nel-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesIlCalendarioDiMarketing2022 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/il-calendario-di-marketing-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLidogeneraciya101 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/lidogeneraciya-101"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesProcessyVovlecheniyaLidovVSrednemBiznese = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/processy-vovlecheniya-lidov-v-srednem-biznese"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakPrzejscZMailchimpaDoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-przejsc-z-mailchimpa-do-getresponse",
"en": "https://www.getresponse.com/resources/guides/how-to-move-from-mailchimp-to-getresponse-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomationAusDerAgenturperspektive = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/marketing-automation-aus-der-agenturperspektive"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesImpreseItalianeEDigitalizzazioneLeOpportunitaDellePiattaformeMartechPerIlBusiness = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/imprese-italiane-e-digitalizzazione-le-opportunita-delle-piattaforme-martech-per-il-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesRukovodstvoPoPlanirovanijuIVnedrenijuAvtomatizaciiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/rukovodstvo-po-planirovaniju-i-vnedreniju-avtomatizacii-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesChekListPoNastrojkeVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/chek-list-po-nastrojke-vebinara"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSmsMarketingAutomationIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/sms-marketing-automation-ideas",
"de": "https://www.getresponse.com/de/resources/guides/7-ideen-fuer-sms-in-der-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAutomationEConversionFunnelPerCampagneStagionali = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/automation-e-conversion-funnel-per-campagne-stagionali"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesFaiCrescereLaTuaAttivitaOnlineConIFunnelDiMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/fai-crescere-la-tua-attivita-online-con-i-funnel-di-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMasshtabirujteSvojOnlajnBiznesSPomoshchyVoronokKonversii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/masshtabirujte-svoj-onlajn-biznes-s-pomoshchy-voronok-konversii"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLaChecklistPerLeTueCampagneDiMarketingStagionali = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/la-checklist-per-le-tue-campagne-di-marketing-stagionali"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesKakBystroSozdatSpisokKontaktovINabratPervye50Podpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/kak-bystro-sozdat-spisok-kontaktov-i-nabrat-pervye-50-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesKakSplanirovatIOtpravitSvoyuPervuyuEmailMarketingovuyuKampaniyu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/resources/guides/kak-splanirovat-i-otpravit-svoyu-pervuyu-email-marketingovuyu-kampaniyu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeScrivereNewsletterCheVengonoAperteLetteECliccate = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-scrivere-newsletter-che-vengono-aperte-lette-e-cliccate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComePassareDaMailchimpAGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-passare-da-mailchimp-a-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakZaPomocaLejkaKonwersjiWypromowacWebinar = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-za-pomoca-lejka-konwersji-wypromowac-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComeCreareUnaMailingListEfficace = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-creare-una-mailing-list-efficace"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketingDsgvoRatgeber = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/email-marketing-dsgvo-ratgeber"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWarmUpAssistitoIp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/warm-up-assistito-ip"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides9ModiTrafficoLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/9-modi-traffico-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides20ExpertenUeberDasOnlineBusiness = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/20-experten-ueber-das-online-business",
"en": "https://www.getresponse.com/resources/guides/20-experts-on-running-your-business-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesBestPracticesImEMailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/best-practices-im-e-mail-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides9EffektiveWegeTrafficZuLandingPagesZuBringen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/9-effektive-wege-traffic-zu-landing-pages-zu-bringen",
"en": "https://www.getresponse.com/resources/guides/drive-traffic-to-your-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWebinareImMarketingAllesWissenswerteFuerEinsteiger = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/webinare-im-marketing-alles-wissenswerte-fuer-einsteiger"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEMailKampagnenPlanenUndSenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/e-mail-kampagnen-planen-und-senden",
"en": "https://www.getresponse.com/resources/guides/how-to-plan-and-send-your-first-email-marketing-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSoBleibstDuMitDeinenKundenInKontakt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/so-bleibst-du-mit-deinen-kunden-in-kontakt",
"en": "https://www.getresponse.com/resources/guides/stay-in-touch-with-your-customers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAssistedIpWarmup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/assisted-ip-warmup"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSharedOderDedizierteIp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/guides/shared-oder-dedizierte-ip"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesComePianificareEdInviareLaTuaPrimaCampagnaDiEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/come-pianificare-ed-inviare-la-tua-prima-campagna-di-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGuidaCompletaAllaLeadGenerationComeIMarketerModerniTrovanoNuoviClienti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/guida-completa-alla-lead-generation-come-i-marketer-moderni-trovano-nuovi-clienti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLaChecklistPerCreareWebinarDiSuccesso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/guides/la-checklist-per-creare-webinar-di-successo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides20ExpertsOnRunningYourBusinessOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/20-experts-on-running-your-business-online",
"de": "https://www.getresponse.com/de/resources/guides/20-experten-ueber-das-online-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPersonalizeAndProtect = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/personalize-and-protect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakPromowacWebinary = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-promowac-webinary"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesDriveTrafficToYourLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/drive-traffic-to-your-landing-pages",
"de": "https://www.getresponse.com/de/resources/guides/9-effektive-wege-traffic-zu-landing-pages-zu-bringen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToMoveFromMailchimpToGetresponseGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-move-from-mailchimp-to-getresponse-guide",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-przejsc-z-mailchimpa-do-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketingDuringCovid19AndBeyond = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/email-marketing-during-covid-19-and-beyond"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToCreateAnEmailListTheRightWay = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-create-an-email-list-the-right-way",
"de": "https://www.getresponse.com/de/resources/guides/wie-man-eine-e-mail-liste-richtig-erstellt-und-die-ersten-50-abonnenten-gewinnt",
"es": "https://www.getresponse.com/es/recursos/guides/como-crear-una-lista-de-email-marketing-y-conseguir-tus-primeros-50-suscriptores"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWebinarSetupChecklist = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/webinar-setup-checklist"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToSellAnOnlineCourseAStepByStepGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-sell-an-online-course-a-step-by-step-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakPozostacWKontakcieZKlientami = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-pozostac-w-kontakcie-z-klientami"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesStayInTouchWithYourCustomers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/stay-in-touch-with-your-customers",
"de": "https://www.getresponse.com/de/resources/guides/so-bleibst-du-mit-deinen-kunden-in-kontakt"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToPromoteYourWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-promote-your-webinars"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToBuildYourOnlineBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-build-your-online-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesKompleksowyPrzewodnikPoMarketingAutomationWECommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/kompleksowy-przewodnik-po-marketing-automation-w-e-commerce",
"en": "https://www.getresponse.com/resources/guides/complete-guide-to-marketing-automation-for-ecommerce",
"es": "https://www.getresponse.com/es/recursos/guides/guia-completa-de-marketing-automation-para-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToPlanAndSendYourFirstEmailMarketingCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-plan-and-send-your-first-email-marketing-campaign",
"de": "https://www.getresponse.com/de/resources/guides/e-mail-kampagnen-planen-und-senden"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides7CykliMarketingAutomationDlaTwoichKampaniiSwiatecznych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/7-cykli-marketing-automation-dla-twoich-kampanii-swiatecznych",
"en": "https://www.getresponse.com/resources/guides/8-marketing-automation-workflows-for-your-holiday-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesBuildingABusinessCaseForMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/building-a-business-case-for-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakPolepszycWynikiFirmyDziekiMarketingAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-polepszyc-wyniki-firmy-dzieki-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWaysToImproveYourEmailMarketingMetrics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/ways-to-improve-your-email-marketing-metrics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesBuildYourEmailList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/build-your-email-list"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToUseDigitalAdsToGrowYourSalesAndRevenues = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-use-digital-ads-to-grow-your-sales-and-revenues"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides8MarketingAutomationWorkflowsForYourHolidayCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/8-marketing-automation-workflows-for-your-holiday-campaigns",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/7-cykli-marketing-automation-dla-twoich-kampanii-swiatecznych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesCompleteGuideToMarketingAutomationForEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/complete-guide-to-marketing-automation-for-ecommerce",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/kompleksowy-przewodnik-po-marketing-automation-w-e-commerce",
"es": "https://www.getresponse.com/es/recursos/guides/guia-completa-de-marketing-automation-para-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGetresponseRodoWszystkoCoPowinienesWiedziec = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/getresponse-rodo-wszystko-co-powinienes-wiedziec",
"en": "https://www.getresponse.com/resources/guides/general-data-protection-regulation-eu-gdpr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGeneralDataProtectionRegulationEuGdpr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/general-data-protection-regulation-eu-gdpr",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/getresponse-rodo-wszystko-co-powinienes-wiedziec"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesAutomatyzacjaSocialMediaDlaEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/automatyzacja-social-media-dla-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHolidayMarketingCampaigns35Tips = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/holiday-marketing-campaigns-35-tips"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomationPlanningAndImplementationGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/marketing-automation-planning-and-implementation-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToMeasureTheSuccessOfYourMarketingAutomationCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-measure-the-success-of-your-marketing-automation-campaign",
"de": "https://www.getresponse.com/de/resources/guides/wie-du-den-erfolg-deiner-marketing-automation-kampagne-misst"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToMoveFromAweberToGetresponseGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-move-from-aweber-to-getresponse-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesIsYourEmailListDirty = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/is-your-email-list-dirty"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomationBasics17TipsForGettingStartedByBarryFeldman = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/marketing-automation-basics-17-tips-for-getting-started-by-barry-feldman",
"de": "https://www.getresponse.com/de/resources/guides/marketing-automation-basics-17-tipps-fuer-den-einstieg"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakPrzejscZSalesmanagoDoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-przejsc-z-salesmanago-do-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakPrzejscZFreshmailaDoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-przejsc-z-freshmaila-do-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomation203TaktykiAbyWejscNaWyzszyPoziom = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/marketing-automation2-0-3-taktyki-aby-wejsc-na-wyzszy-poziom",
"en": "https://www.getresponse.com/resources/guides/marketing-automation2-0-3-tactics-to-go-next-level"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomation203TacticsToGoNextLevel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/marketing-automation2-0-3-tactics-to-go-next-level",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/marketing-automation2-0-3-taktyki-aby-wejsc-na-wyzszy-poziom"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides11SekretowZachowanKlientowB2BJamieTurner = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/11-sekretow-zachowan-klientow-b2b-jamie-turner",
"en": "https://www.getresponse.com/resources/guides/marketing-automation-11-b2b-consumer-behavior-secrets-jamie-turner"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMarketingAutomation11B2BConsumerBehaviorSecretsJamieTurner = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/marketing-automation-11-b2b-consumer-behavior-secrets-jamie-turner",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/11-sekretow-zachowan-klientow-b2b-jamie-turner"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesGettingStartedWithLandingPageAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/getting-started-with-landing-page-analytics",
"de": "https://www.getresponse.com/de/resources/guides/erste-schritte-mit-den-landing-page-statistiken"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesCompleteGuideToGeneratingLeadsNurturingProspectsAndDrivingConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/complete-guide-to-generating-leads-nurturing-prospects-and-driving-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSamodzielnyPrzedsiebiorcaNaDrodzeAutomatyzacjiMarketinguBarryFeldman = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/samodzielny-przedsiebiorca-na-drodze-automatyzacji-marketingu-barry-feldman",
"en": "https://www.getresponse.com/resources/guides/solopreneurs-journey-into-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSolopreneursJourneyIntoMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/solopreneurs-journey-into-marketing-automation",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/samodzielny-przedsiebiorca-na-drodze-automatyzacji-marketingu-barry-feldman"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPozyskiwanieKlientowZWykorzystaniemProfilowaniaProgresywnegoPoradnik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/pozyskiwanie-klientow-z-wykorzystaniem-profilowania-progresywnego-poradnik",
"en": "https://www.getresponse.com/resources/guides/how-to-generate-leads-through-progressive-profiling-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToGenerateLeadsThroughProgressiveProfilingGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-generate-leads-through-progressive-profiling-guide",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/pozyskiwanie-klientow-z-wykorzystaniem-profilowania-progresywnego-poradnik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPrzewodnikPoAutomatyzacjiMarketinguDlaSektoraECommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/przewodnik-po-automatyzacji-marketingu-dla-sektora-e-commerce",
"en": "https://www.getresponse.com/resources/guides/essential-guide-to-marketing-automation-for-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEssentialGuideToMarketingAutomationForECommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/essential-guide-to-marketing-automation-for-e-commerce",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/przewodnik-po-automatyzacji-marketingu-dla-sektora-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides7NajpopularniejszychMitowOMarketingAutomationJamieTurner = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/7-najpopularniejszych-mitow-o-marketing-automation-jamie-turner",
"en": "https://www.getresponse.com/resources/guides/7-most-common-marketing-automation-myths-jamie-turner"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides7MostCommonMarketingAutomationMythsJamieTurner = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/7-most-common-marketing-automation-myths-jamie-turner",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/7-najpopularniejszych-mitow-o-marketing-automation-jamie-turner"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides3MustsOfMarketingAutomationErikQualman = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/3-musts-of-marketing-automation-erik-qualman"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides15ReasonsYouShouldBeUsingDigitalMarketingAutomationJeffBullas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/15-reasons-you-should-be-using-digital-marketing-automation-jeff-bullas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides10ZabojcowKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/10-zabojcow-konwersji",
"en": "https://www.getresponse.com/resources/guides/10-conversion-killers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuides10ConversionKillers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/10-conversion-killers",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/10-zabojcow-konwersji"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesUsingMarketingAutomationToRetrieveAbandonedShoppingCarts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/using-marketing-automation-to-retrieve-abandoned-shopping-carts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHtmlEmailDesignDocumentation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/html-email-design-documentation",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/specyfikacja-tworzenia-wiadomosci-email-w-formacie-html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesDostarczalnoscOdADoZPoradnik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/dostarczalnosc-od-a-do-z-poradnik",
"en": "https://www.getresponse.com/resources/guides/email-deliverability-from-a-to-z"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailDeliverabilityFromAToZ = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/email-deliverability-from-a-to-z",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/dostarczalnosc-od-a-do-z-poradnik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLeadGeneration101HowModernMarketersDevelopNewCustomers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/lead-generation-101-how-modern-marketers-develop-new-customers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesBusinessIntegrationUsingEmailAndCrmsToPerfectMarketingAndSalesFunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/business-integration-using-email-and-crms-to-perfect-marketing-and-sales-funnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesPrzewodnikDlaAgencjiIMarek = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/przewodnik-dla-agencji-i-marek"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesIncreaseEngagementWithPersonalizedMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/increase-engagement-with-personalized-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesWebinarMarketingPoradnik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/webinar-marketing-poradnik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesKpiWEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/kpi-w-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailMarketingKpis = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/email-marketing-kpis",
"de": "https://www.getresponse.com/de/resources/guides/leitfaden-fuer-e-mail-marketing-kpis"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakZwiekszycSkutecznoscNewslettera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-zwiekszyc-skutecznosc-newslettera"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesMyslJakKlient9SposobowNaWyzszaKonwersjeZEmaili = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/mysl-jak-klient-9-sposobow-na-wyzsza-konwersje-z-emaili"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakProjektowacSkuteczneLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-projektowac-skuteczne-landing-pages",
"en": "https://www.getresponse.com/resources/guides/responsive-landing-page-design"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesResponsiveLandingPageDesign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/responsive-landing-page-design",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-projektowac-skuteczne-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesACrashCourseToEmailListBuildingThroughSeo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/a-crash-course-to-email-list-building-through-seo",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-wykorzystac-seo-do-budowania-bazy-adresowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakWykorzystacSeoDoBudowaniaBazyAdresowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-wykorzystac-seo-do-budowania-bazy-adresowej",
"en": "https://www.getresponse.com/resources/guides/a-crash-course-to-email-list-building-through-seo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesNiezbednikContentMarketera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/niezbednik-content-marketera"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesHowToWriteNewslettersThatGetOpenedReadAndClicked = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/how-to-write-newsletters-that-get-opened-read-and-clicked"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSpecyfikacjaTworzeniaWiadomosciEmailWFormacieHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/specyfikacja-tworzenia-wiadomosci-email-w-formacie-html",
"en": "https://www.getresponse.com/resources/guides/html-email-design-documentation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesSwiateczneStrategieEmailMarketingowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/swiateczne-strategie-email-marketingowe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesLandingPageOptimization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/landing-page-optimization"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesTestowanieIOptymalizacjaWiadomosciEmail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/testowanie-i-optymalizacja-wiadomosci-email",
"en": "https://www.getresponse.com/resources/guides/email-testing-and-optimization"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesEmailTestingAndOptimization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/email-testing-and-optimization",
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/testowanie-i-optymalizacja-wiadomosci-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesResponsiveEmailDesign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/guides/responsive-email-design"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakPowiekszycBazeEmailMarketingowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-powiekszyc-baze-email-marketingowa",
"en": "https://www.getresponse.com/resources/guides/21-ways-to-build-your-contact-list"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesGuidesJakZbudowacDochodowyProgramLojalnosciowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/poradniki/jak-zbudowac-dochodowy-program-lojalnosciowy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsLinkedinForBeginners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/linkedin-for-beginners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToGrowYourInfluence = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-grow-your-influence"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsBuildProfitableOnlineCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/build-profitable-online-courses"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsOnlineKursVerkaufeMitDerPerfektenLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/online-kurs-verkaufe-mit-der-perfekten-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinars10XYourMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/10x-your-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsInboxToIncome = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/inbox-to-income"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsCourseCreation101 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/course-creation-101"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsBuildingYourBrandAndHumanizingYourBusinessWithCarlosGil = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/building-your-brand-and-humanizing-your-business-with-carlos-gil"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHotSeatSteveToth = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/hot-seat-steve-toth"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsCyborgContentCombiningAiAndHumanCreativity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/cyborg-content-combining-ai-and-human-creativity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsComeCreareUnaLandingPageEfficace = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/come-creare-una-landing-page-efficace"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHotSeatWithDanOshinsky = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/hot-seat-with-dan-oshinsky"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsVerkaufenMitEMailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/verkaufen-mit-e-mail-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsWebinarBlueprint = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/webinar-blueprint"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHotSeatWithDustinHowes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/hot-seat-with-dustin-howes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailMarketingTrendyNajlepszePraktyki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/email-marketing-trendy-najlepsze-praktyki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsFromOpenRateToHeartRate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/from-open-rate-to-heart-rate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsNeueAnforderungenAnEMailAbsender2024 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/neue-anforderungen-an-e-mail-absender-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailMarketingTrendsFor2024 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/email-marketing-trends-for-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsDeepDiveOnAffiliateMarketingWithJohnLobrutto = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/deep-dive-on-affiliate-marketing-with-john-lobrutto"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsWysokaDostarczalnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/wysoka-dostarczalnosc",
"en": "https://www.getresponse.com/resources/webinars/email-authentication-explained"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailAuthenticationExplained = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/email-authentication-explained",
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/wysoka-dostarczalnosc"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsRobertBrandlInterview = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/robert-brandl-interview"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsPierwszeKrokiMarketingAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/pierwsze-kroki-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsDerArchetypenHackFuerBesseresEMailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/der-archetypen-hack-fuer-besseres-e-mail-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsPierwszeKrokiZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/pierwsze-kroki-z-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsKiTrifftNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/ki-trifft-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsSolopreneurAi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/solopreneur-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsAffiliateRoadmap = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/affiliate-roadmap"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsLandingPageMastery = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/landing-page-mastery"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsIaApplicataAllaContentCreation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/ia-applicata-alla-content-creation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsAutorespondersVsMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/autoresponders-vs-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsComeLIaPotenzieraIlTuoMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/come-l-ia-potenziera-il-tuo-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEbookAlchemyHowToGrowYourEmailListWithLeadMagnets = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/ebook-alchemy-how-to-grow-your-email-list-with-lead-magnets"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsThePowerOfAi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/the-power-of-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsKreatorEmailiAi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/kreator-emaili-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsDerHypeUmKiChancenAmpChallengesFuerMarketerInnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/der-hype-um-ki-chancen-amp-challenges-fuer-marketer-innen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsComeUsareLaNewsletterPerFareLeadGeneration = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/come-usare-la-newsletter-per-fare-lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGetInTheGame = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/get-in-the-game"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsAnalizaKlientaBezCookie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/analiza-klienta-bez-cookie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEMailMarketingRegelgerechtErfolgreich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/e-mail-marketing-regelgerecht-erfolgreich"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsIntroducingAiEmailGenerator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/introducing-ai-email-generator"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsSocialMediaEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/social-media-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsThePowerOfPersonalizationUnlockTheSecretToHigherConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/the-power-of-personalization-unlock-the-secret-to-higher-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinars4SequenzeEmailIndispensabiliPerVendereDiPiuConIlTuoEcommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/4-sequenze-email-indispensabili-per-vendere-di-piu-con-il-tuo-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsDreiHacksFuerMehrInteraktionBeiInstagram = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/drei-hacks-fuer-mehr-interaktion-bei-instagram"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailListCleaning = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/email-list-cleaning"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsSocialSelling = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/social-selling"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEMailVerteilerMitSocialMediaAufbauen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/webinars/e-mail-verteiler-mit-social-media-aufbauen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToBuildAndSegmentYourEmailListUsingInteractiveContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-build-and-segment-your-email-list-using-interactive-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsBetterEmailMarketingEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/better-email-marketing-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsLepszyEmailMarketingEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/lepszy-email-marketing-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsBlackFridayComeCreareLaTuaStrategiaSocial = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/black-friday-come-creare-la-tua-strategia-social"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsZarzadzanieBazaDlaZaawansowanych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/zarzadzanie-baza-dla-zaawansowanych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsComeLanciareUnProdottoConLEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/come-lanciare-un-prodotto-con-l-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsPmi5ModiPerVendereDiPiuEInAutomatico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/pmi-5-modi-per-vendere-di-piu-e-in-automatico"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinars5ModiPerVendereDiPiuEInAutomatico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/5-modi-per-vendere-di-piu-e-in-automatico"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsSavingAbandonedCartsWithMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/saving-abandoned-carts-with-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsLaBuyerPersonaNonEMorta = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/la-buyer-persona-non-e-morta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsBuildingALoyaltyProgramWithMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/building-a-loyalty-program-with-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailMarketingPerEcommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/email-marketing-per-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsLeadGenerationBestPracticeEdErroriComuni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/lead-generation-best-practice-ed-errori-comuni"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGetYourBusinessNoticedWithAGreatBrandIdentity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/get-your-business-noticed-with-a-great-brand-identity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsPytaniaOdpowiedziWebinaryLeadGeneration = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/pytania-odpowiedzi-webinary-lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsLeadNurturingPerIlB2BEPerIlB2C = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/lead-nurturing-per-il-b2b-e-per-il-b2c"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToScaleYourEcommerceBusinessWithSocialMediaAndEmailAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-scale-your-ecommerce-business-with-social-media-and-email-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsWebinaryLeadGeneration = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/webinary-lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsFacebookAdsPerLeadGenerationDa0A100 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/facebook-ads-per-lead-generation-da-0-a-100"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsThreeProcessesYouShouldAutomateToday = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/three-processes-you-should-automate-today"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGrowYourBloggerEmailList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/grow-your-blogger-email-list"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailMarketingComeComunicareNel2022 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/email-marketing-come-comunicare-nel-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsMarketingAutomationPerIlB2B = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/marketing-automation-per-il-b2b"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailMarketingForBloggers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/email-marketing-for-bloggers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToDesignAWebsiteThatMatchesYourBrand = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-design-a-website-that-matches-your-brand"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailListYourBiggestBusinessAsset = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/email-list-your-biggest-business-asset"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsSocialMediaSnappinessOrWebsiteStability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/social-media-snappiness-or-website-stability"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEp1GetYourBusinessOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/ep-1-get-your-business-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsComeVendereDiPiuConIlBlackFridayEAltriPeriodiDellAnno = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/come-vendere-di-piu-con-il-black-friday-e-altri-periodi-dell-anno"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsTurningCustomersIntoEvangelists = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/turning-customers-into-evangelists"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsOptimizeLandingPagesForHolidayCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/optimize-landing-pages-for-holiday-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsComeCreareUnSitoWebPerRaggiungereITuoiObiettivi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/come-creare-un-sito-web-per-raggiungere-i-tuoi-obiettivi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsWiecejKlientowDziekiGoogleAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/wiecej-klientow-dzieki-google-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsEmailMarketingComeRenderloPiuEfficaceGrazieAllAnalisiDeiDati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/email-marketing-come-renderlo-piu-efficace-grazie-all-analisi-dei-dati"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGetMoreLeadsWithGoogleAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/get-more-leads-with-google-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsPrzedstawiamyKreatorStron = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/przedstawiamy-kreator-stron"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsIntroducingWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/introducing-website-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinars10ConsigliPerScrivereLEmailPerfetta = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/webinars/10-consigli-per-scrivere-l-email-perfetta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsBudujRelacjeZwiekszajSprzedazLiveChat = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/buduj-relacje-zwiekszaj-sprzedaz-live-chat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsZwiekszycSprzedazECommerceDziekiDanym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/zwiekszyc-sprzedaz-e-commerce-dzieki-danym"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsWebinarJakTworzycMaile = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/webinar-jak-tworzyc-maile"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsDesignEmailsSubscribersLoveWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/design-emails-subscribers-love-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsJakZaplanowacDzialaniaMarketingowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/jak-zaplanowac-dzialania-marketingowe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToCreateReallyGoodAutomatedEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-create-really-good-automated-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsBoostCustomerEngagementWithEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/boost-customer-engagement-with-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsContentMarketingPozyskiwanieKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/content-marketing-pozyskiwanie-klientow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToBuildBeautifulEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-build-beautiful-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsIncreasingSalesWithConversionFunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/increasing-sales-with-conversion-funnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGrowWithLeadMagnetFunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/grow-with-lead-magnet-funnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsJakPrzeniescSwojBiznesDoInternetu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/jak-przeniesc-swoj-biznes-do-internetu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToBuildAnEmailListFasterWithLeadMagnetFunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-build-an-email-list-faster-with-lead-magnet-funnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsJakPrzepalicBudzetNaReklamy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/jak-przepalic-budzet-na-reklamy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsQAGrupaDocelowaOdbiorcowReklamNaFacebooku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/q-a-grupa-docelowa-odbiorcow-reklam-na-facebooku"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsWinningValentinesDayEmailCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/winning-valentines-day-email-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGrupaDocelowaOdbiorcowReklamNaFacebooku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/grupa-docelowa-odbiorcow-reklam-na-facebooku"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsQAFacebookAdsWebinar = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/q-a-facebook-ads-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsFacebookAdsWebinar = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/facebook-ads-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGettingReadyForCyberWeekFeverWebinarQa = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/getting-ready-for-cyber-week-fever-webinar-qa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGettingReadyForTheCyberWeekFever = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/getting-ready-for-the-cyber-week-fever"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsSezonSprzedazowyNarzedziaPraktyki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/sezon-sprzedazowy-narzedzia-praktyki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsSuccessfulOnlineCoachingBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/successful-online-coaching-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsThreeStepFunnelThatWillHelpYouSellMore = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/three-step-funnel-that-will-help-you-sell-more"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsJakSledzicKampanieEmailMarketingoweGoogleAnalytics = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/jak-sledzic-kampanie-email-marketingowe-google-analytics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToIncreaseTrafficConversionsSales = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-increase-traffic-conversions-sales"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsGetresponseZgodnoscRodo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/getresponse-zgodnosc-rodo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsWspolpracaSeoContent = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/wspolpraca-seo-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsJakPrzekucPasjeWBiznes = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/jak-przekuc-pasje-w-biznes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsJakSkutecznieRozbudowacBazeAdresowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/webinary/jak-skutecznie-rozbudowac-baze-adresowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToBuildLeadFunnelThatActuallyWorks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-build-lead-funnel-that-actually-works"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToSellOnlineCoursesWithAutofunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-sell-online-courses-with-autofunnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToCreateSalesFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-create-sales-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToBuildAnEmailListFastAndOnATightBudget = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-build-an-email-list-fast-and-on-a-tight-budget"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinarsHowToStayGdprCompliantUsingGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/how-to-stay-gdpr-compliant-using-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesWebinars6AutomationWorkflowsToJumpstartYourConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/webinars/6-automation-workflows-to-jumpstart-your-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsEmailMarketingBenchmark = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/reports/email-marketing-benchmark",
"en": "https://www.getresponse.com/resources/reports/email-marketing-benchmarks",
"ru": "https://www.getresponse.com/ru/resources/reports/email-marketing-benchmarks",
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/email-marketing-statystyki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsEmailMarketingBenchmarks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/reports/email-marketing-benchmarks",
"it": "https://www.getresponse.com/it/resources/reports/email-marketing-benchmark",
"ru": "https://www.getresponse.com/ru/resources/reports/email-marketing-benchmarks",
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/email-marketing-statystyki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsEmailMarketingBenchmark2022 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/reports/email-marketing-benchmark-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsStateOfLeadNurturingForMidSizeCompanies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/reports/state-of-lead-nurturing-for-mid-size-companies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsMarketingTechnologyImplementation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/reports/marketing-technology-implementation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsMarketingAutomationIntegrationAgencyPerspective = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/reports/marketing-automation-integration-agency-perspective"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsEmailMarketingStatystyki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/email-marketing-statystyki",
"en": "https://www.getresponse.com/resources/reports/email-marketing-benchmarks",
"it": "https://www.getresponse.com/it/resources/reports/email-marketing-benchmark",
"ru": "https://www.getresponse.com/ru/resources/reports/email-marketing-benchmarks"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsEmailMarketingWEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/email-marketing-w-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsWyborySamorzadowe2014 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/wybory-samorzadowe-2014"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsEmailWBiegu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/email-w-biegu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsEmailWidzianyOczamiKonsumentow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/email-widziany-oczami-konsumentow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsStanEmailMarketinguNaRynkuWydawniczym2014 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/stan-email-marketingu-na-rynku-wydawniczym-2014"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesReportsGlobalneMierzenieWynikowEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/raporty/globalne-mierzenie-wynikow-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosEmailMarketingBenchmarksKeyInsights = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/email-marketing-benchmarks-key-insights"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToStartAffiliateMarketingWithNoMoney = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-start-affiliate-marketing-with-no-money"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAffiliateMarketingBlueprintLesson7 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/affiliate-marketing-blueprint-lesson-7"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAffiliateMarketingBlueprintLesson6 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/affiliate-marketing-blueprint-lesson-6"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAffiliateMarketingBlueprintLesson5 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/affiliate-marketing-blueprint-lesson-5"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAffiliateMarketingBlueprintLesson4 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/affiliate-marketing-blueprint-lesson-4"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAffiliateMarketingBlueprintLesson3 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/affiliate-marketing-blueprint-lesson-3"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAffiliateMarketingBlueprintLesson2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/affiliate-marketing-blueprint-lesson-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAffiliateMarketingBlueprintLesson1 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/affiliate-marketing-blueprint-lesson-1"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToBuildAnEmailListForAffiliateMarketing5SimpleSteps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-build-an-email-list-for-affiliate-marketing-5-simple-steps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosTheAffiliateMarketingBlueprintHowToMake100Online = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/the-affiliate-marketing-blueprint-how-to-make-100-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosEmailMarketingForAffiliateMarketersTopStrategiesAndTechniques = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/email-marketing-for-affiliate-marketers-top-strategies-and-techniques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosWhyGetresponseIsThePerfectToolForAffiliateMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/why-getresponse-is-the-perfect-tool-for-affiliate-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosRecessionProofYourBusinessWithEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/recession-proof-your-business-with-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateALandingPageWithAi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-landing-page-with-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAnAutoresponderWithAi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-an-autoresponder-with-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToPickTheBestEmailServiceProvider = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-pick-the-best-email-service-provider"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateABusinessEmailAddress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-business-email-address"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseWebconnect = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-webconnect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAddAnEbookOrVideoToAMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-add-an-ebook-or-video-to-a-message"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToIntegrateZapierWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-integrate-zapier-with-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateRssToEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-rss-to-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosGettingStartedWithEmailMarketingGetresponseTutorial = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/getting-started-with-email-marketing-getresponse-tutorial"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosGettingStartedWithAutomationGetresponseTutorial = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/getting-started-with-automation-getresponse-tutorial"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToIntegratePaypalWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-integrate-paypal-with-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideos9WaysToOptimizeYourLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/9-ways-to-optimize-your-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-getresponse-chats"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosConfiguraIlTuoAccountGetresponseECreaLaTuaPrimaNewsletterInMenoDi10Minuti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/configura-il-tuo-account-getresponse-e-crea-la-tua-prima-newsletter-in-meno-di-10-minuti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosGettingStartedWithEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/getting-started-with-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToBuildAWebsiteFromScratch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-build-a-website-from-scratch"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateALandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAWebPushNotification = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-web-push-notification"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosWhereToFindYourEmailTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/where-to-find-your-email-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseEmailSegmentation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-email-segmentation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosCorsoGratuitoGetresponseLaSuiteDiAutomazioniPerPmiAmpProfessionisti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/corso-gratuito-getresponse-la-suite-di-automazioni-per-pmi-amp-professionisti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosComeCreareELanciareUnWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/come-creare-e-lanciare-un-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosMarketingAutomationAvanzata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/marketing-automation-avanzata"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosIniziaAVendereConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/inizia-a-vendere-con-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosPercheIniziareConLaMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/perche-iniziare-con-la-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosComeFarCrescereLaTuaListaContattiConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/come-far-crescere-la-tua-lista-contatti-con-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosComeScrivereLaTuaPrimaEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/come-scrivere-la-tua-prima-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowDoIAddTheTrackingJavascriptCodeToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-do-i-add-the-tracking-javascript-code-to-my-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosGettingStartedWithFree = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/getting-started-with-free"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToIntegratePrestashopAndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-integrate-prestashop-and-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToTrackMyWebsiteWithFacebookPixel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-track-my-website-with-facebook-pixel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToInstallPopupsInWordpress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-install-popups-in-wordpress"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseQuickTransactionalEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-quick-transactional-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAssignASubdomainToYourWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-assign-a-subdomain-to-your-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAssignACustomDomainToYourWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-assign-a-custom-domain-to-your-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToIntegrateShopifyAndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-integrate-shopify-and-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAddAMessageToAnAutoresponderCycle = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-add-a-message-to-an-autoresponder-cycle"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosWhereToFindTheApiKey = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/where-to-find-the-api-key"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseMyBlocksInTheNewMessageEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-my-blocks-in-the-new-message-editor"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseFilesAndImages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-files-and-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAWebsiteUsingTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-website-using-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToChangeWebsiteSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-change-website-settings"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToChangeTheSettingsOfMyPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-change-the-settings-of-my-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAddALinkToTheWebsiteMenu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-add-a-link-to-the-website-menu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToEditAnAutomationMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-edit-an-automation-message"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAndSendANewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-and-send-a-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAnAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-an-autoresponder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToStartBuildingAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-start-building-a-workflow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAddASignupFormToYourWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-add-a-signup-form-to-your-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAssignACustomDomainToYourLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-assign-a-custom-domain-to-your-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToChangePostalAddressMessageFooter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-change-postal-address-message-footer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosWhatIsDkimAndHowYouCanConfigureIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/what-is-dkim-and-how-you-can-configure-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAndUseConsentFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-and-use-consent-fields"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosWhereToSeeHowYourMessagesAreDoing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/where-to-see-how-your-messages-are-doing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAWebsiteUsingAiWizard = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-website-using-ai-wizard"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAddSeoDetailsToYourWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-add-seo-details-to-your-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToExportAListOfContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-export-a-list-of-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToManuallyAddAContactToTheAutoresponderCycle = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-manually-add-a-contact-to-the-autoresponder-cycle"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAndUseCustomFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-and-use-custom-fields"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToImportAListOfContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-import-a-list-of-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToAddAPromoCodeToYourEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-add-a-promo-code-to-your-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosAssignCustomSubdomainToLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/assign-custom-subdomain-to-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosComeCreareUnaStrategiaDiMarketingPerUnaStartup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/come-creare-una-strategia-di-marketing-per-una-startup"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosComeCreareUnSitoWebConIlWebsiteBuilder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/videos/come-creare-un-sito-web-con-il-website-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToGenerateLeadsWithContentMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-generate-leads-with-content-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosCreateEmailsGetresponseEmailCreator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/create-emails-getresponse-email-creator"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosSkuteczneDzialaniaEmailMarketingowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/video/skuteczne-dzialania-email-marketingowe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseWebinarFunnelsToBuildYourCoachingBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-webinar-funnels-to-build-your-coaching-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToSellOnlineCoursesUsingSalesFunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-sell-online-courses-using-sales-funnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosFiveWaysToBuildWebinarRegistrations = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/five-ways-to-build-webinar-registrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateAWebinarThatSellsYourProduct = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-webinar-that-sells-your-product"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToSellOnlineCoachingCoursesUsingASalesFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-sell-online-coaching-courses-using-a-sales-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToBuildASalesFunnelUsingFacebookAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-build-a-sales-funnel-using-facebook-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateASalesPageToSellYourProduct = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-sales-page-to-sell-your-product"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosSevenTipsForCreatingASalesFunnelThatWorks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/seven-tips-for-creating-a-sales-funnel-that-works"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateListBuildingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-list-building-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosCreateSalesFunnelFromScratch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/create-sales-funnel-from-scratch"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToMakeAnEffectiveOptInForm = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-make-an-effective-opt-in-form"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCraftThePerfectWelcomeEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-craft-the-perfect-welcome-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToUseTheGetresponseWordpressPluginToGetMoreLeads = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-use-the-getresponse-wordpress-plugin-to-get-more-leads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosHowToCreateACustomTemplateToMatchYourBlog = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/how-to-create-a-custom-template-to-match-your-blog"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosLandingPageLeadMagnet = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/landing-page-lead-magnet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosLandingPageAnatomy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/landing-page-anatomy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosLandingPageForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/landing-page-forms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosLandingPageCopywriting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/landing-page-copywriting"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosLandingPageImagesAndVideos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/landing-page-images-and-videos"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosKeyElementsOfAWinningLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/key-elements-of-a-winning-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesVideosDontLetAbandonedCartsSlipAway = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/videos/dont-let-abandoned-carts-slip-away"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsAffiliateMarketingForBeginners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/affiliate-marketing-for-beginners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsGoogleAdsForBeginners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/google-ads-for-beginners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsEmailMarketing101 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/email-marketing-101"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsLeadGeneration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsAdvancedEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/advanced-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsMarketingAutomation101 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/marketing-automation-101"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsFunnelMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/funnel-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsEcommerceMarketingFromAToZ = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/ecommerce-marketing-from-a-to-z"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsGettingStartedWithLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/getting-started-with-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsFacebookAds101 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/facebook-ads-101"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCollectionsWebinarMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/collections/webinar-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographics12BestPracticesFuerDieEMailListenverwaltung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/resources/infographics/12-best-practices-fuer-die-e-mail-listenverwaltung",
"it": "https://www.getresponse.com/it/resources/infographics/12-best-practice-per-gestire-le-tue-liste-e-mail"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsEmailAbTestingBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/email-ab-testing-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsEmailMarketingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/email-marketing-funnel",
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/lejek-email-marketingowy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographics7TipsForBoostingYourHalloweenEmailMarketingCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/7-tips-for-boosting-your-halloween-email-marketing-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsBoostEcommerceConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/boost-ecommerce-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsReasonsWhyYouNeedAWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/reasons-why-you-need-a-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsHowToBuildAWebsiteFromScratch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/how-to-build-a-website-from-scratch"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographics12BestPracticePerGestireLeTueListeEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/infographics/12-best-practice-per-gestire-le-tue-liste-e-mail",
"de": "https://www.getresponse.com/de/resources/infographics/12-best-practices-fuer-die-e-mail-listenverwaltung"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsEmailListManagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/email-list-management"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsHowToBuildAnEmailListFromScratch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/how-to-build-an-email-list-from-scratch"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsPercheScegliereGetresponseMaxIntegrazioneDynamics = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/infographics/perche-scegliere-getresponse-max-integrazione-dynamics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsUnificaLaPotenzaDelCrmMicrosoftDynamicsEDellaMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/resources/infographics/unifica-la-potenza-del-crm-microsoft-dynamics-e-della-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsMarketingAutomationForMicrosoftDynamics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/marketing-automation-for-microsoft-dynamics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsWhyChooseGetresponseDynamicsIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/infographics/why-choose-getresponse-dynamics-integration"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographics10SposobowNaNajwyzszaDostarczalnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/10-sposobow-na-najwyzsza-dostarczalnosc"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsNajskuteczniejszeSposobyBudowaniaBazyMailingowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/najskuteczniejsze-sposoby-budowania-bazy-mailingowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsLejekEmailMarketingowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/lejek-email-marketingowy",
"en": "https://www.getresponse.com/resources/infographics/email-marketing-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographics7PomyslowNaKampanieZOkazjiDniaMatki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/7-pomyslow-na-kampanie-z-okazji-dnia-matki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsProfesjonalnaKorespondencjaZKlientami = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/profesjonalna-korespondencja-z-klientami"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsJakStosowacVideoWWiadomosciachEmail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/jak-stosowac-video-w-wiadomosciach-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsEmailMarketingMaratonWLiczbach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/email-marketing-maraton-w-liczbach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsJakKorzystacZAutoresponderow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/jak-korzystac-z-autoresponderow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsGrafikiWNewsletterze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/grafiki-w-newsletterze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsJakSprzedawacZaPomocaEmaila = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/jak-sprzedawac-za-pomoca-emaila"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsJakSprawdzamyPoczteEmail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/jak-sprawdzamy-poczte-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsEmailMarketingOczamiInternautow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/email-marketing-oczami-internautow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsPromocjaSwiatecznaWESklepach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/promocja-swiateczna-w-e-sklepach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsKiedyWyslacNewsletterAbyUzyskacNajlepszeEfekty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/kiedy-wyslac-newsletter-aby-uzyskac-najlepsze-efekty"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsEmailWBiegu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/email-w-biegu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesInfographicsKartkiSwiateczneVsEmailSmsSocialMedia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/infografiki/kartki-swiateczne-vs-email-sms-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesGoogleSearchForBeginners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/courses/google-search-for-beginners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesMasteringMarketingAutomationCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/courses/mastering-marketing-automation-course"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesStrategieEmailMarketingoweKurs = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/strategie-email-marketingowe-kurs",
"en": "https://www.getresponse.com/resources/courses/strategic-email-marketing-course"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesStrategicEmailMarketingCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/courses/strategic-email-marketing-course",
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/strategie-email-marketingowe-kurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesAkademiaLandingPagesKurs = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/akademia-landing-pages-kurs",
"en": "https://www.getresponse.com/resources/courses/essential-landing-page-course"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesEssentialLandingPageCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/courses/essential-landing-page-course",
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/akademia-landing-pages-kurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesWebinarMarketingZGetresponseKurs = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/webinar-marketing-z-getresponse-kurs",
"en": "https://www.getresponse.com/resources/courses/webinar-marketing-bootcamp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesWebinarMarketingBootcamp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/courses/webinar-marketing-bootcamp",
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/webinar-marketing-z-getresponse-kurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesEmailMarketingForBloggersCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/courses/email-marketing-for-bloggers-course"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesKursOptymalizacjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/kurs-optymalizacji-landing-page",
"en": "https://www.getresponse.com/resources/courses/landing-page-optimization-course"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteResourcesCoursesLandingPageOptimizationCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/resources/courses/landing-page-optimization-course",
"pl": "https://www.getresponse.pl/baza-wiedzy/kursy/kurs-optymalizacji-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMax = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/termos-de-servico/max",
"de": "https://www.getresponse.com/de/legal/max-terms-of-service",
"en": "https://www.getresponse.com/legal/max-terms-of-service"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageTermosDeServico = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/termos-de-servico/termos-de-servico",
"ru": "https://www.getresponse.com/ru/legal/legal",
"de": "https://www.getresponse.com/de/legal/legal",
"pl": "https://www.getresponse.pl/informacje-prawne/informacje-prawne",
"en": "https://www.getresponse.com/legal/legal"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxContratoDeNivelDeServicoDaGetresponse = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/termos-de-servico/max-contrato-de-nivel-de-servico-da-getresponse",
"pl": "https://www.getresponse.pl/informacje-prawne/max-gwarancja-dostepnosci-uslugi",
"en": "https://www.getresponse.com/legal/max-service-level-agreement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxLegalPrivacyPolicyBr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-legal-privacy-policy-br"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageWzorFormularzaOdstapieniaUmowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/wzor-formularza-odstapienia-umowy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageStandardoweKlauzuleUmowne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/standardowe-klauzule-umowne",
"en": "https://www.getresponse.com/legal/standard-contractual-clauses",
"de": "https://www.getresponse.com/de/legal/standardvertragsklauseln"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageStandardvertragsklauseln = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/standardvertragsklauseln",
"en": "https://www.getresponse.com/legal/standard-contractual-clauses",
"pl": "https://www.getresponse.pl/informacje-prawne/standardowe-klauzule-umowne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageStandardContractualClauses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/standard-contractual-clauses",
"de": "https://www.getresponse.com/de/legal/standardvertragsklauseln",
"pl": "https://www.getresponse.pl/informacje-prawne/standardowe-klauzule-umowne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageTermosDoCooperacaoBrasil = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/termos-do-cooperacao-brasil"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageInstagramHolidayContest = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/instagram-holiday-contest"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPagePartnerProgramTermsAndConditionsBrazil = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/partner-program-terms-and-conditions-brazil"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageProgramPartnerskiRegulaminUslugiMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/program-partnerski-regulamin-uslugi-max",
"en": "https://www.getresponse.com/legal/max-partners-program"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxPartnersProgram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-partners-program",
"pl": "https://www.getresponse.pl/informacje-prawne/program-partnerski-regulamin-uslugi-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageGetresponseTrialPromotionTermsAndConditions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/getresponse-trial-promotion-terms-and-conditions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxDataProcessingAddendum = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/max-data-processing-addendum",
"en": "https://www.getresponse.com/legal/max-data-processing-addendum"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageSygnalisci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/sygnalisci",
"en": "https://www.getresponse.com/legal/whistleblowers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageWhistleblowers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/whistleblowers",
"pl": "https://www.getresponse.pl/informacje-prawne/sygnalisci"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxPartnerzy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-partnerzy",
"en": "https://www.getresponse.com/legal/max-partners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxGetresponseServiceLevelAgreementBrazilLtda = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-getresponse-service-level-agreement-brazil-ltda"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxPartners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-partners",
"pl": "https://www.getresponse.pl/informacje-prawne/max-partnerzy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMarketingAgencyAffiliateProgramTerms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/marketing-agency-affiliate-program-terms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageWebAccessibilityStatement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/web-accessibility-statement",
"pl": "https://www.getresponse.pl/informacje-prawne/web-accessibility-statement",
"de": "https://www.getresponse.com/de/legal/web-accessibility-statement",
"ru": "https://www.getresponse.com/ru/legal/web-accessibility-statement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageFr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageUmowaPowierzeniaPrzetwarzaniaDanychOsobowych2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/umowa-powierzenia-przetwarzania-danych-osobowych-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAnnexe3Fr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/annexe-3-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAnnexe2Fr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/annexe-2-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAnnexe1Fr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/annexe-1-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxGwarancjaDostepnosciUslugi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-gwarancja-dostepnosci-uslugi",
"en": "https://www.getresponse.com/legal/max-service-level-agreement",
"pt": "https://www.getresponse.com/pt/termos-de-servico/max-contrato-de-nivel-de-servico-da-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxServiceLevelAgreement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-service-level-agreement",
"pl": "https://www.getresponse.pl/informacje-prawne/max-gwarancja-dostepnosci-uslugi",
"pt": "https://www.getresponse.com/pt/termos-de-servico/max-contrato-de-nivel-de-servico-da-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageExhibit3Es = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/exhibit-3-es"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageExhibit2Es = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/exhibit-2-es"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageExhibit1Es = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/exhibit-1-es"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageEs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/es"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxTermsOfService = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/max-terms-of-service",
"en": "https://www.getresponse.com/legal/max-terms-of-service",
"pt": "https://www.getresponse.com/pt/termos-de-servico/max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxAntispamPolicy = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/max-antispam-policy",
"en": "https://www.getresponse.com/legal/max-antispam-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageSoglasieNaObrabotkuPersonalnyhDannyh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/legal/soglasie-na-obrabotku-personalnyh-dannyh"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxAvvAnnex3 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/max-avv-annex-3"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxAvvAnnex1 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/max-avv-annex-1"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxDpaDescriptionOfProcessing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-dpa-description-of-processing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxPrivacyPolicyUs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-privacy-policy-us"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxDpaTechnicalMeasures = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-dpa-technical-measures"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxDpaListOfSubprocessors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-dpa-list-of-subprocessors"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxPolitykaPrywatnosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-polityka-prywatnosci",
"en": "https://www.getresponse.com/legal/max-privacy-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxRegulaminUslugi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-regulamin-uslugi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxAvvAnnex2 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/max-avv-annex-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxPolitykaAntyspamowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-polityka-antyspamowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMyMaxPrivacyPolicy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/my-max-privacy-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxPrivacyPolicy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/max-privacy-policy",
"pl": "https://www.getresponse.pl/informacje-prawne/max-polityka-prywatnosci"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxUmowaPowierzeniaListaPodwykonawcow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-umowa-powierzenia-lista-podwykonawcow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxUmowaPowierzeniaOpisPrzetwarzania = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-umowa-powierzenia-opis-przetwarzania"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageMaxUmowaPowierzeniaOpisSrodkow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/max-umowa-powierzenia-opis-srodkow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageRegulaminProgramuPartnerskiegoGetresponsePolscyPrzedsiebiorcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/regulamin-programu-partnerskiego-getresponse-polscy-przedsiebiorcy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageRegulaminProgramuPartnerskiegoGetresponsePolscyKonsumenci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/regulamin-programu-partnerskiego-getresponse-polscy-konsumenci"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAntispamRu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/legal/antispam-ru"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPagePrivacyRu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/legal/privacy-ru"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageProgramPolecajacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/program-polecajacy",
"en": "https://www.getresponse.com/legal/referral-program",
"ru": "https://www.getresponse.com/ru/legal/referral-program",
"de": "https://www.getresponse.com/de/legal/referral-program"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/api",
"en": "https://www.getresponse.com/legal/api",
"de": "https://www.getresponse.com/de/legal/api",
"ru": "https://www.getresponse.com/ru/legal/api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageProgramPartnerski = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/program-partnerski",
"en": "https://www.getresponse.com/legal/affiliate-program"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageTermsUs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/terms-us",
"pl": "https://www.getresponse.pl/informacje-prawne/terms-us",
"de": "https://www.getresponse.com/de/legal/terms-us",
"ru": "https://www.getresponse.com/ru/legal/terms-us"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPagePrivacyUs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/privacy-us",
"ru": "https://www.getresponse.com/ru/legal/privacy-us",
"de": "https://www.getresponse.com/de/legal/privacy-us",
"pl": "https://www.getresponse.pl/informacje-prawne/privacy-us"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageDataProcessingAgreementUs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/data-processing-agreement-us",
"pl": "https://www.getresponse.pl/informacje-prawne/data-processing-agreement-us",
"de": "https://www.getresponse.com/de/legal/data-processing-agreement-us",
"ru": "https://www.getresponse.com/ru/legal/data-processing-agreement-us"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageCookiePolicyUs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/cookie-policy-us",
"pl": "https://www.getresponse.pl/informacje-prawne/cookie-policy-us",
"de": "https://www.getresponse.com/de/legal/cookie-policy-us",
"ru": "https://www.getresponse.com/ru/legal/cookie-policy-us"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAntispamUs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/antispam-us",
"pl": "https://www.getresponse.pl/informacje-prawne/antispam-us",
"de": "https://www.getresponse.com/de/legal/antispam-us",
"ru": "https://www.getresponse.com/ru/legal/antispam-us"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageDataProcessingAgreementEnterprise = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/data-processing-agreement-enterprise",
"pl": "https://www.getresponse.pl/informacje-prawne/data-processing-agreement-enterprise",
"de": "https://www.getresponse.com/de/legal/data-processing-agreement-enterprise",
"ru": "https://www.getresponse.com/ru/legal/data-processing-agreement-enterprise"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageReferralProgram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/referral-program",
"pl": "https://www.getresponse.pl/informacje-prawne/program-polecajacy",
"ru": "https://www.getresponse.com/ru/legal/referral-program",
"de": "https://www.getresponse.com/de/legal/referral-program"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAgencyProgram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/agency-program",
"pl": "https://www.getresponse.pl/informacje-prawne/agency-program",
"de": "https://www.getresponse.com/de/legal/agency-program",
"ru": "https://www.getresponse.com/ru/legal/agency-program"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAffiliateProgram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/affiliate-program",
"pl": "https://www.getresponse.pl/informacje-prawne/program-partnerski"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPagePrivacyPolicy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/privacy-policy",
"ru": "https://www.getresponse.com/ru/legal/privacy-policy",
"de": "https://www.getresponse.com/de/legal/privacy-policy",
"pl": "https://www.getresponse.pl/informacje-prawne/privacy-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageCookiesPolicy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/cookies-policy",
"pl": "https://www.getresponse.pl/informacje-prawne/cookies-policy",
"de": "https://www.getresponse.com/de/legal/cookies-policy",
"ru": "https://www.getresponse.com/ru/legal/cookies-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageTermsOfUse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/terms-of-use",
"ru": "https://www.getresponse.com/ru/legal/terms-of-use",
"de": "https://www.getresponse.com/de/legal/terms-of-use",
"pl": "https://www.getresponse.pl/informacje-prawne/terms-of-use"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageSocialAdsCreator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/social-ads-creator",
"ru": "https://www.getresponse.com/ru/legal/social-ads-creator",
"de": "https://www.getresponse.com/de/legal/social-ads-creator",
"pl": "https://www.getresponse.pl/informacje-prawne/social-ads-creator"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageEmailPrivacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/legal/email-privacy",
"pl": "https://www.getresponse.pl/informacje-prawne/ochrona-prywatnosci",
"en": "https://www.getresponse.com/legal/email-privacy",
"de": "https://www.getresponse.com/de/legal/email-datenschutz"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAntispam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/legal/antispam",
"pl": "https://www.getresponse.pl/informacje-prawne/anty-spam",
"en": "https://www.getresponse.com/legal/antispam",
"de": "https://www.getresponse.com/de/legal/antispam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPagePrivacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/legal/privacy",
"pl": "https://www.getresponse.pl/informacje-prawne/polityka-prywatnosci",
"en": "https://www.getresponse.com/legal/privacy",
"de": "https://www.getresponse.com/de/legal/datenschutz"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageDatenschutz = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/datenschutz",
"pl": "https://www.getresponse.pl/informacje-prawne/polityka-prywatnosci",
"en": "https://www.getresponse.com/legal/privacy",
"ru": "https://www.getresponse.com/ru/legal/privacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPagePolitykaPrywatnosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/polityka-prywatnosci",
"en": "https://www.getresponse.com/legal/privacy",
"de": "https://www.getresponse.com/de/legal/datenschutz",
"ru": "https://www.getresponse.com/ru/legal/privacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageLegal = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/legal/legal",
"de": "https://www.getresponse.com/de/legal/legal",
"pl": "https://www.getresponse.pl/informacje-prawne/informacje-prawne",
"en": "https://www.getresponse.com/legal/legal",
"pt": "https://www.getresponse.com/pt/termos-de-servico/termos-de-servico"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageInformacjePrawne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/informacje-prawne",
"ru": "https://www.getresponse.com/ru/legal/legal",
"de": "https://www.getresponse.com/de/legal/legal",
"en": "https://www.getresponse.com/legal/legal",
"pt": "https://www.getresponse.com/pt/termos-de-servico/termos-de-servico"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageAntySpam = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/anty-spam",
"en": "https://www.getresponse.com/legal/antispam",
"de": "https://www.getresponse.com/de/legal/antispam",
"ru": "https://www.getresponse.com/ru/legal/antispam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageUmowaPowierzeniaPrzetwarzaniaDanychOsobowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/umowa-powierzenia-przetwarzania-danych-osobowych",
"en": "https://www.getresponse.com/legal/data-processing-agreement",
"de": "https://www.getresponse.com/de/legal/data-processing-agreement",
"ru": "https://www.getresponse.com/ru/legal/data-processing-agreement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageDataProcessingAgreement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/legal/data-processing-agreement",
"pl": "https://www.getresponse.pl/informacje-prawne/umowa-powierzenia-przetwarzania-danych-osobowych",
"de": "https://www.getresponse.com/de/legal/data-processing-agreement",
"ru": "https://www.getresponse.com/ru/legal/data-processing-agreement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageEmailDatenschutz = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/email-datenschutz",
"pl": "https://www.getresponse.pl/informacje-prawne/ochrona-prywatnosci",
"en": "https://www.getresponse.com/legal/email-privacy",
"ru": "https://www.getresponse.com/ru/legal/email-privacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageOchronaPrywatnosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/ochrona-prywatnosci",
"en": "https://www.getresponse.com/legal/email-privacy",
"de": "https://www.getresponse.com/de/legal/email-datenschutz",
"ru": "https://www.getresponse.com/ru/legal/email-privacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPageCookiePolicy = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/legal/cookie-policy",
"en": "https://www.getresponse.com/legal/cookie-policy",
"pl": "https://www.getresponse.pl/informacje-prawne/polityka-cookies",
"ru": "https://www.getresponse.com/ru/legal/cookie-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteLegalPagePolitykaCookies = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/informacje-prawne/polityka-cookies",
"en": "https://www.getresponse.com/legal/cookie-policy",
"de": "https://www.getresponse.com/de/legal/cookie-policy",
"ru": "https://www.getresponse.com/ru/legal/cookie-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTrelloOpinieRecenzja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/trello-opinie-recenzja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreatorsTakeBackControl = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/creators-take-back-control"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToAutomatePersonalizeEmailCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-automate-personalize-email-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRobertoBlakeOnEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/roberto-blake-on-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDripKampagnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-drip-kampagnen",
"en": "https://www.getresponse.com/blog/email-drip-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLifeCoachMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/life-coach-marketing",
"en": "https://www.getresponse.com/blog/getresponse-for-life-coaches"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBrandAmbassadorVsInfluencer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/brand-ambassador-vs-influencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeVendereUnCorsoOnline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-vendere-un-corso-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineLehrerMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-lehrer-marketing",
"en": "https://www.getresponse.com/blog/getresponse-for-online-teachers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSpamTraps = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/spam-traps",
"it": "https://www.getresponse.com/it/blog/spam-trap-cosa-sono-come-evitarle",
"en": "https://www.getresponse.com/blog/spam-traps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBuildB2BLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-build-b2b-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkedinPersonalBrandingBlueprint = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/linkedin-personal-branding-blueprint"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriAlternativeAUdemy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-alternative-a-udemy",
"de": "https://www.getresponse.com/de/blog/udemy-alternativen",
"en": "https://www.getresponse.com/blog/udemy-alternatives"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNeilPatelOnEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/neil-patel-on-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestMassEmailSenderTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-mass-email-sender-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailAutomationTools = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-automation-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEigeneWebsiteErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/eigene-website-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostELearningResearch = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-learning-research",
"en": "https://www.getresponse.com/blog/e-learning-research"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBoostSalesDollarADayStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/boost-sales-dollar-a-day-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreatorSpotlightDerralEvesOnWhyCreatorsShouldntSleepOnEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/creator-spotlight-derral-eves-on-why-creators-shouldnt-sleep-on-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToMakeMoneySellingCoursesWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-make-money-selling-courses-with-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseForNonprofits = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-for-nonprofits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGaryVOnEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/gary-v-on-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAsanaRecenzjeOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/asana-recenzje-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKlaviyoRecenzja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/klaviyo-recenzja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDripEmailSoftware = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/drip-email-software"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineKursLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-kurs-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsKlaviyoQualeScegliere = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-klaviyo-quale-scegliere"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSpamTrapCosaSonoComeEvitarle = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/spam-trap-cosa-sono-come-evitarle",
"en": "https://www.getresponse.com/blog/spam-traps",
"de": "https://www.getresponse.com/de/blog/spam-traps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCampagnaDiRiconferma = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/campagna-di-riconferma",
"en": "https://www.getresponse.com/blog/reconfirmation-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestCrmsForYourEmailMarketingCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-crms-for-your-email-marketing-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsClickfunnels = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-clickfunnels",
"en": "https://www.getresponse.com/blog/getresponse-vs-clickfunnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCarlosGilJoinsGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/carlos-gil-joins-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCheapEmailMarketingTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cheap-email-marketing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPagesPerGoogleAds = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/landing-pages-per-google-ads",
"en": "https://www.getresponse.com/blog/google-ads-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseForOnlineTeachers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-for-online-teachers",
"de": "https://www.getresponse.com/de/blog/online-lehrer-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEsempiDiMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/esempi-di-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostActivecampaignOpinieRecenzja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/activecampaign-opinie-recenzja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSeoMusiker = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/seo-musiker"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailMarketingMusiker = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-marketing-musiker",
"en": "https://www.getresponse.com/blog/email-marketing-for-musicians"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSunsetPolicy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/sunset-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailingworkVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/mailingwork-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailchimpOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/mailchimp-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationPerEcommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/marketing-automation-per-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToSendAnEmailToMultipleRecipients = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-send-an-email-to-multiple-recipients"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSeptemberNewsletterIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/september-newsletter-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-marketing-automation",
"en": "https://www.getresponse.com/blog/20-automated-emails",
"es": "https://www.getresponse.com/es/blog/email-marketing-automatizado",
"pt": "https://www.getresponse.com/pt/blog/automacao-de-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReconfirmationCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/reconfirmation-campaign",
"it": "https://www.getresponse.com/it/blog/campagna-di-riconferma"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReisebloggerWerden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/reiseblogger-werden"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBesteDeutschenMarketingAutomationToolsVergleich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/beste-deutschen-marketing-automation-tools-vergleich"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingTrends = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-trends"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKiEMailStatistiken = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ki-e-mail-statistiken",
"en": "https://www.getresponse.com/blog/do-ai-generated-emails-work",
"pt": "https://www.getresponse.com/pt/blog/estatisticas-de-emails-com-ia"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailOeffnungsrateVerbessern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-oeffnungsrate-verbessern",
"en": "https://www.getresponse.com/blog/increase-email-open-rate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWieStarteIchPaidNewsletters = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/wie-starte-ich-paid-newsletters",
"en": "https://www.getresponse.com/blog/paid-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUdemyAlternativen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/udemy-alternativen",
"en": "https://www.getresponse.com/blog/udemy-alternatives",
"it": "https://www.getresponse.com/it/blog/migliori-alternative-a-udemy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoGanharDinheiroComIa = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-ganhar-dinheiro-com-ia",
"es": "https://www.getresponse.com/es/blog/como-ganar-dinero-con-ia"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoGanarDineroConIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/como-ganar-dinero-con-ia",
"pt": "https://www.getresponse.com/pt/blog/como-ganhar-dinheiro-com-ia"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingCost = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-cost"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBuildAB2BEmailList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-build-a-b2b-email-list"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationWilkommensmails = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/marketing-automation-wilkommensmails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBackToSchoolNewsletterIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/back-to-school-newsletter-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestEmailSubjectLinesSales = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-email-subject-lines-sales"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToWriteASalesEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-write-a-sales-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPaidNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/paid-newsletter",
"de": "https://www.getresponse.com/de/blog/wie-starte-ich-paid-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriPiattaformePerCreareEVendereCorsiOnline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-piattaforme-per-creare-e-vendere-corsi-online",
"de": "https://www.getresponse.com/de/blog/online-lehrplattformen",
"en": "https://www.getresponse.com/blog/best-online-teaching-platforms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerceEmailSegmentation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ecommerce-email-segmentation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingForHealthcare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-for-healthcare"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLifecycleMarketing8CustomerRetentionStrategiesForEcommerceSuccess = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lifecycle-marketing-8-customer-retention-strategies-for-ecommerce-success"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseForLifeCoaches = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-for-life-coaches",
"de": "https://www.getresponse.com/de/blog/life-coach-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTypesOfEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/types-of-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailerliteReview = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mailerlite-review"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailinglisteAufbauen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/mailingliste-aufbauen",
"it": "https://www.getresponse.com/it/blog/come-costruire-una-lista-email-da-zero"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingVsSpamUpdateGoogle = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/affiliate-marketing-vs-spam-update-google"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInxmailVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/inxmail-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsMailerlite = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-mailerlite",
"en": "https://www.getresponse.com/blog/getresponse-vs-mailerlite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToGrowYourEmailListWithInstagram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-grow-your-email-list-with-instagram"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEducationLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/education-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDesignEffectiveCourseLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/design-effective-course-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuildOnlineCommunity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/build-online-community"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUdemyAlternatives = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/udemy-alternatives",
"de": "https://www.getresponse.com/de/blog/udemy-alternativen",
"it": "https://www.getresponse.com/it/blog/migliori-alternative-a-udemy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAugustNewsletterIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/august-newsletter-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGlobalECommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/global-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSummerNewsletterIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/summer-newsletter-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDoAiGeneratedEmailsWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/do-ai-generated-emails-work",
"pt": "https://www.getresponse.com/pt/blog/estatisticas-de-emails-com-ia",
"de": "https://www.getresponse.com/de/blog/ki-e-mail-statistiken"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailchimpAlternativen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/mailchimp-alternativen",
"it": "https://www.getresponse.com/it/blog/migliori-alternative-a-mailchimp",
"en": "https://www.getresponse.com/blog/mailchimp-alternatives-and-competitors"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineLehrplattformen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-lehrplattformen",
"en": "https://www.getresponse.com/blog/best-online-teaching-platforms",
"it": "https://www.getresponse.com/it/blog/migliori-piattaforme-per-creare-e-vendere-corsi-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDirectEmailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/direct-email-marketing",
"it": "https://www.getresponse.com/it/blog/campegne-dem-significato"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNonProfitNewsletterIdeen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/non-profit-newsletter-ideen",
"en": "https://www.getresponse.com/blog/non-profit-newsletter-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailSchriftarten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-schriftarten",
"en": "https://www.getresponse.com/blog/best-font-for-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoDivulgarCursosOnline = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-divulgar-cursos-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailerliteRecenzjaOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/mailerlite-recenzja-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineKusVsMembershipWebsite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-kus-vs-membership-website",
"en": "https://www.getresponse.com/blog/online-course-vs-membership-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWieEffektivIstEmailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/wie-effektiv-ist-email-marketing",
"en": "https://www.getresponse.com/blog/email-importance"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEbooksVerkaufen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ebooks-verkaufen",
"en": "https://www.getresponse.com/blog/how-to-sell-ebooks"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStrategienZurContentMonetarisierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/strategien-zur-content-monetarisierung",
"en": "https://www.getresponse.com/blog/strategies-for-monetizing-your-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10ProfitableOnlineKursIdeen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/10-profitable-online-kurs-ideen",
"en": "https://www.getresponse.com/blog/profitable-online-course-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageAttributes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/landing-page-attributes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailImportance = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-importance",
"de": "https://www.getresponse.com/de/blog/wie-effektiv-ist-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJulyNewsletterIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/july-newsletter-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineLernplattformen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-lernplattformen",
"en": "https://www.getresponse.com/blog/best-online-learning-platforms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUseMobilePushNotifications = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-mobile-push-notifications"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMelhoresFerramentasParaCriarFormularios = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/melhores-ferramentas-para-criar-formularios"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiEmailWritingTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ai-email-writing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewslettersInternas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/newsletters-internas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNonProfitNewsletterIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/non-profit-newsletter-ideas",
"de": "https://www.getresponse.com/de/blog/non-profit-newsletter-ideen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFreshmailOpinieRecenzja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/freshmail-opinie-recenzja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTrendSeoContentMarketingWmf = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/trend-seo-content-marketing-wmf"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIosMailTabs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ios-mail-tabs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLegalPoliciesForDigitalCreators = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/legal-policies-for-digital-creators"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNonProfitLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/non-profit-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateLandingPageWithoutWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-landing-page-without-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailMarketingFuerKleineUnternehmen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-marketing-fuer-kleine-unternehmen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPrideMonthMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/pride-month-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVersandzeitpunktNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/versandzeitpunkt-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKiInDerArbeitswelt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ki-in-der-arbeitswelt"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutoRepairWebsites = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/auto-repair-websites"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRecurringSubscriptionBusinessIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/recurring-subscription-business-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEstatisticasDeEmailsComIa = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/estatisticas-de-emails-com-ia",
"en": "https://www.getresponse.com/blog/do-ai-generated-emails-work",
"de": "https://www.getresponse.com/de/blog/ki-e-mail-statistiken"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRealEstateLeadConversion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/real-estate-lead-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHealthcareMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/healthcare-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSecurityIncidentJune5 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/security-incident-june-5"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMakeMoneyFromHome = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/make-money-from-home"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMakeMoneyOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/make-money-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFeedbackByEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/feedback-by-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJuneNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/june-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLawyerEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lawyer-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToMakeMoneyWithAi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-make-money-with-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMenschVsAiNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/mensch-vs-ai-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmMarketingideen2024 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/em-marketingideen-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostColdMailing = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/cold-mailing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostListaMailingowaJakBudowacBazeKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/lista-mailingowa-jak-budowac-baze-kontaktow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOCzymPisacNewsletteryAbyBylyCzytane = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/o-czym-pisac-newslettery-aby-byly-czytane"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakPowinnaWygladacWiadomoscPowitalnaWTwoimESklepie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-powinna-wygladac-wiadomosc-powitalna-w-twoim-e-sklepie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadMagnetsPomysly = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/lead-magnets-pomysly"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoCrearUnaLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/como-crear-una-landing-page",
"en": "https://www.getresponse.com/blog/landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingPerFreelance = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-marketing-per-freelance"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeTrovareClientiDaLiberoProfessionista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-trovare-clienti-da-libero-professionista"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestOnlineLearningPlatforms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-online-learning-platforms",
"de": "https://www.getresponse.com/de/blog/online-lernplattformen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingPerLiberiProfessionisti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/marketing-per-liberi-professionisti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailsDeCarritoAbandonado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/emails-de-carrito-abandonado",
"en": "https://www.getresponse.com/blog/abandoned-cart-email-examples",
"de": "https://www.getresponse.com/de/blog/abgebrochene-warenkorb-emails",
"pt": "https://www.getresponse.com/pt/blog/exemplos-de-email-carrinho-abandonado"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestOnlineTeachingPlatforms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-online-teaching-platforms",
"de": "https://www.getresponse.com/de/blog/online-lehrplattformen",
"it": "https://www.getresponse.com/it/blog/migliori-piattaforme-per-creare-e-vendere-corsi-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProfitableOnlineCourseIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/profitable-online-course-ideas",
"de": "https://www.getresponse.com/de/blog/10-profitable-online-kurs-ideen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStrategiesForMonetizingYourContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/strategies-for-monetizing-your-content",
"de": "https://www.getresponse.com/de/blog/strategien-zur-content-monetarisierung"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostExemplosDeEmailCarrinhoAbandonado = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/exemplos-de-email-carrinho-abandonado",
"en": "https://www.getresponse.com/blog/abandoned-cart-email-examples",
"de": "https://www.getresponse.com/de/blog/abgebrochene-warenkorb-emails",
"es": "https://www.getresponse.com/es/blog/emails-de-carrito-abandonado"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineVonZuhauseAusGeldVerdienen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-von-zuhause-aus-geld-verdienen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoPromocionarCursosOnline = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/como-promocionar-cursos-online",
"en": "https://www.getresponse.com/blog/how-to-market-online-courses"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingToCollegeStudents = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-to-college-students"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFerramentasGratuitasParaAfiliados = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/ferramentas-gratuitas-para-afiliados",
"es": "https://www.getresponse.com/es/blog/herramientas-gratis-para-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailGamification = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-gamification",
"en": "https://www.getresponse.com/blog/email-gamification"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingAutomatizado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/email-marketing-automatizado",
"en": "https://www.getresponse.com/blog/20-automated-emails",
"pt": "https://www.getresponse.com/pt/blog/automacao-de-email-marketing",
"it": "https://www.getresponse.com/it/blog/email-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutomacaoDeEmailMarketing = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/automacao-de-email-marketing",
"en": "https://www.getresponse.com/blog/20-automated-emails",
"es": "https://www.getresponse.com/es/blog/email-marketing-automatizado",
"it": "https://www.getresponse.com/it/blog/email-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNavigatingTheTiktokBan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/navigating-the-tiktok-ban"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNuovoContentMonetizationHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/nuovo-content-monetization-hub",
"en": "https://www.getresponse.com/blog/content-monetization-hub"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostContentMonetizationHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/content-monetization-hub",
"it": "https://www.getresponse.com/it/blog/nuovo-content-monetization-hub"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMitKiGeldVerdienen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/mit-ki-geld-verdienen",
"it": "https://www.getresponse.com/it/blog/come-guadagnare-con-intelligenza-artificiale"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8TendenciasDeEcommerceMarketingQueDebesDominarParaOfrecerLaMejorExperienciaAlClienteEn2024 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/8-tendencias-de-ecommerce-marketing-que-debes-dominar-para-ofrecer-la-mejor-experiencia-al-cliente-en-2024",
"en": "https://www.getresponse.com/blog/ecommerce-trends-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutomacaoParaEcommerce = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/automacao-para-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseOmr2024 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-omr2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailMarketingESeo = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/e-mail-marketing-e-seo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPorQueNoComprarListasDeEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/por-que-no-comprar-listas-de-email",
"pt": "https://www.getresponse.com/pt/blog/por-que-nao-comprar-listas-de-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEpkTemplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/epk-template"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLejekSprzedazowyPrzyklad = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/lejek-sprzedazowy-przyklad"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToMarketOnlineCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-market-online-courses",
"es": "https://www.getresponse.com/es/blog/como-promocionar-cursos-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVirtualVoiceAudibleAudiobooks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/virtual-voice-audible-audiobooks"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineCourseVsMembershipSite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/online-course-vs-membership-site",
"de": "https://www.getresponse.com/de/blog/online-kus-vs-membership-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsConvertkitConfronto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-convertkit-confronto"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWasIstEinSmtpServer = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/was-ist-ein-smtp-server"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEntregabilidadeEmailMarketing = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/entregabilidade-email-marketing",
"it": "https://www.getresponse.com/it/blog/email-deliverability-come-migliorarla",
"en": "https://www.getresponse.com/blog/improve-email-deliverability",
"de": "https://www.getresponse.com/de/blog/email-zustellbarkeit-optimieren",
"es": "https://www.getresponse.com/es/blog/entregabilidad-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingSenzaFinireInSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-marketing-senza-finire-in-spam",
"en": "https://www.getresponse.com/blog/why-emails-go-to-spam",
"de": "https://www.getresponse.com/de/blog/warum-landen-deine-emails-im-spam-ordner"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostConsultantMarketingStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/consultant-marketing-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerceTrends2024 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ecommerce-trends-2024",
"es": "https://www.getresponse.com/es/blog/8-tendencias-de-ecommerce-marketing-que-debes-dominar-para-ofrecer-la-mejor-experiencia-al-cliente-en-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestAiWebsitesUsa = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-ai-websites-usa",
"pt": "https://www.getresponse.com/pt/blog/melhores-ferramentas-de-ia",
"it": "https://www.getresponse.com/it/blog/migliori-siti-intelligenza-artificiale",
"es": "https://www.getresponse.com/es/blog/mejores-herramientas-de-ia-para-marketing",
"de": "https://www.getresponse.com/de/blog/ai-websites-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeGuadagnareConIntelligenzaArtificiale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-guadagnare-con-intelligenza-artificiale",
"de": "https://www.getresponse.com/de/blog/mit-ki-geld-verdienen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKiDatenschutzUndUrheberrecht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ki-datenschutz-und-urheberrecht"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEntregabilidadEmailMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/entregabilidad-email-marketing",
"it": "https://www.getresponse.com/it/blog/email-deliverability-come-migliorarla",
"en": "https://www.getresponse.com/blog/improve-email-deliverability",
"de": "https://www.getresponse.com/de/blog/email-zustellbarkeit-optimieren",
"pt": "https://www.getresponse.com/pt/blog/entregabilidade-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutenticacaoDeEmail = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/autenticacao-de-email",
"it": "https://www.getresponse.com/it/blog/autenticazione-email",
"en": "https://www.getresponse.com/blog/email-authentication",
"de": "https://www.getresponse.com/de/blog/email-authentifizierung",
"es": "https://www.getresponse.com/es/blog/autenticacion-de-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHighTicketAffiliateMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/high-ticket-affiliate-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDaycareNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/daycare-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOsterNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/oster-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiWebsitesMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ai-websites-marketing",
"pt": "https://www.getresponse.com/pt/blog/melhores-ferramentas-de-ia",
"it": "https://www.getresponse.com/it/blog/migliori-siti-intelligenza-artificiale",
"es": "https://www.getresponse.com/es/blog/mejores-herramientas-de-ia-para-marketing",
"en": "https://www.getresponse.com/blog/best-ai-websites-usa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInfluencerAffiliateMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/influencer-affiliate-marketing",
"en": "https://www.getresponse.com/blog/best-influencer-affiliate-programs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterPasquaEsempiConsigli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/newsletter-pasqua-esempi-consigli"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTiktokBanUsa = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/tiktok-ban-usa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineCoachingBusinessAufbauen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-coaching-business-aufbauen",
"en": "https://www.getresponse.com/blog/how-to-start-coaching-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomDomainEmail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/custom-domain-email",
"it": "https://www.getresponse.com/it/blog/email-dominio-personalizzato-come-crearla",
"en": "https://www.getresponse.com/blog/custom-domain-for-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineKursPlattformen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-kurs-plattformen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCincoDeMayoEmailSubjectLines = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cinco-de-mayo-email-subject-lines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMemorialDayEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/memorial-day-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutenticacionDeEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/autenticacion-de-email",
"it": "https://www.getresponse.com/it/blog/autenticazione-email",
"en": "https://www.getresponse.com/blog/email-authentication",
"de": "https://www.getresponse.com/de/blog/email-authentifizierung",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-de-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmallBusinessWeek = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/small-business-week"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMemorialDayMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/memorial-day-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPorQueNaoComprarListasDeEmail = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/por-que-nao-comprar-listas-de-email",
"es": "https://www.getresponse.com/es/blog/por-que-no-comprar-listas-de-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAprilFoolsEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/april-fools-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiResearch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ai-research"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStartupLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/startup-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuchVermarktenOhneVerlag = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/buch-vermarkten-ohne-verlag"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseRaketerei = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-raketerei"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToSellEbooks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-sell-ebooks",
"de": "https://www.getresponse.com/de/blog/ebooks-verkaufen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingForRealEstateAgents = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-for-real-estate-agents"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingFestaDellaDonna = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/marketing-festa-della-donna",
"de": "https://www.getresponse.com/de/blog/frauentag-kampagne-tipps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMelhoresNichosParaAfiliados = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/melhores-nichos-para-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadMagnetLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-magnet-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatIsEmailCoaching = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-is-email-coaching"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntelligenzaArtificialeVantaggiPerAziende = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/intelligenza-artificiale-vantaggi-per-aziende"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMejoresHerramientasDeIaParaMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mejores-herramientas-de-ia-para-marketing",
"pt": "https://www.getresponse.com/pt/blog/melhores-ferramentas-de-ia",
"it": "https://www.getresponse.com/it/blog/migliori-siti-intelligenza-artificiale",
"de": "https://www.getresponse.com/de/blog/ai-websites-marketing",
"en": "https://www.getresponse.com/blog/best-ai-websites-usa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestUnbounceAlternatives = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-unbounce-alternatives"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailCaptureLadingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-capture-lading-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhitePaperLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/white-paper-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWaitlistLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/waitlist-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPpcLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ppc-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKpisDeEmailMarketing = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/kpis-de-email-marketing",
"es": "https://www.getresponse.com/es/blog/kpis-de-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiLandingPageBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ai-landing-page-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKiAffiliateProgramme = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ki-affiliate-programme",
"en": "https://www.getresponse.com/blog/best-ai-affiliate-programs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingWithoutWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/affiliate-marketing-without-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailCoachingTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-coaching-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostValentinstagNewsletterIdeen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/valentinstag-newsletter-ideen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZarabiacNaStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zarabiac-na-stronie-internetowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUdemyOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/udemy-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarchMadnessEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/march-madness-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailAuthentifizierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-authentifizierung",
"it": "https://www.getresponse.com/it/blog/autenticazione-email",
"en": "https://www.getresponse.com/blog/email-authentication",
"es": "https://www.getresponse.com/es/blog/autenticacion-de-email",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-de-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailZustellbarkeit = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-zustellbarkeit",
"en": "https://www.getresponse.com/blog/email-deliverability"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailZustellbarkeitOptimieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-zustellbarkeit-optimieren",
"it": "https://www.getresponse.com/it/blog/email-deliverability-come-migliorarla",
"en": "https://www.getresponse.com/blog/improve-email-deliverability",
"es": "https://www.getresponse.com/es/blog/entregabilidad-email-marketing",
"pt": "https://www.getresponse.com/pt/blog/entregabilidade-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVs4DemQualeScegliere = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-4dem-quale-scegliere"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterOptimieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-optimieren",
"en": "https://www.getresponse.com/blog/email-optimization-strategies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingNoCarnaval = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/email-marketing-no-carnaval"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStPatricksDayEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/st-patricks-day-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZarabiacNaSzkoleniachOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zarabiac-na-szkoleniach-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDominioPersonalizzatoComeCrearla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-dominio-personalizzato-come-crearla",
"en": "https://www.getresponse.com/blog/custom-domain-for-email-marketing",
"de": "https://www.getresponse.com/de/blog/custom-domain-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRoofingMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/roofing-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingParaSanValentin = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/email-marketing-para-san-valentin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMothersDayEmailIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mothers-day-email-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEasterEmailMarketingExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/easter-email-marketing-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsAweber = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-aweber",
"de": "https://www.getresponse.com/de/blog/getresponse-vs-aweber",
"en": "https://www.getresponse.com/blog/getresponse-vs-aweber"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDeliverabilityComeMigliorarla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-deliverability-come-migliorarla",
"en": "https://www.getresponse.com/blog/improve-email-deliverability",
"de": "https://www.getresponse.com/de/blog/email-zustellbarkeit-optimieren",
"es": "https://www.getresponse.com/es/blog/entregabilidad-email-marketing",
"pt": "https://www.getresponse.com/pt/blog/entregabilidade-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost14IdeePerLaNewsletterDiSanValentino = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/14-idee-per-la-newsletter-di-san-valentino"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOperationEmailDeliverability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/operation-email-deliverability"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutenticazioneEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/autenticazione-email",
"en": "https://www.getresponse.com/blog/email-authentication",
"de": "https://www.getresponse.com/de/blog/email-authentifizierung",
"es": "https://www.getresponse.com/es/blog/autenticacion-de-email",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-de-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMejoresProgramasDeAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mejores-programas-de-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWillkommensEMails = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/willkommens-e-mails",
"en": "https://www.getresponse.com/blog/7-welcome-emails-that-set-the-standard"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMejoresProgramasDePartners = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mejores-programas-de-partners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingNaPascoa = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/email-marketing-na-pascoa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingParaAfiliados = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/email-marketing-para-afiliados",
"es": "https://www.getresponse.com/es/blog/email-marketing-para-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMejoresFuentesParaEmails = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mejores-fuentes-para-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAlternativasAMailchimp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/alternativas-a-mailchimp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAlternativasAoMailchimp = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/alternativas-ao-mailchimp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMelhoresFerramentasDeIa = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/melhores-ferramentas-de-ia",
"it": "https://www.getresponse.com/it/blog/migliori-siti-intelligenza-artificiale",
"es": "https://www.getresponse.com/es/blog/mejores-herramientas-de-ia-para-marketing",
"de": "https://www.getresponse.com/de/blog/ai-websites-marketing",
"en": "https://www.getresponse.com/blog/best-ai-websites-usa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMelhoresProgramasDeAfiliados = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/melhores-programas-de-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGmailVaYahooThayDoiXacThucEmailTatCaNhungGiBanCanBiet = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/gmail-va-yahoo-thay-doi-xac-thuc-email-tat-ca-nhung-gi-ban-can-biet",
"pl": "https://www.getresponse.pl/blog/zmiany-w-google-i-yahoo-2024",
"en": "https://www.getresponse.com/blog/gmail-yahoo-authentication",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-gmail-e-yahoo",
"es": "https://www.getresponse.com/es/blog/autenticacion-yahoo-gmail",
"de": "https://www.getresponse.com/de/blog/gmail-und-yahoos-authentifizierung",
"it": "https://www.getresponse.com/it/blog/gmail-yahoo-autenticazione-nuovi-requisiti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIaNoEmailMarketing = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/ia-no-email-marketing",
"es": "https://www.getresponse.com/es/blog/ia-en-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMelhoresExemplosDeSites = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/melhores-exemplos-de-sites",
"en": "https://www.getresponse.com/blog/website-ideas",
"es": "https://www.getresponse.com/es/blog/mejores-ejemplos-de-paginas-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailMarketingTrends2024 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-marketing-trends-2024"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadGenerationForCoaches = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-generation-for-coaches"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriPiattaformeServiziEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-piattaforme-servizi-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMelhoresAutorespondersDeEMail = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/melhores-autoresponders-de-e-mail"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingDeAfiliadosParaIniciantes = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/marketing-de-afiliados-para-iniciantes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiProductivityReport = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ai-productivity-report"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoCriarUmaLandingPage = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-criar-uma-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUwierzytelnianieEmailiPrzewodnikDlaPoczatkujacych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/uwierzytelnianie-emaili-przewodnik-dla-poczatkujacych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoMontarUmECommerceDeSucesso = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-montar-um-e-commerce-de-sucesso"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBecomeASpiritualCoach = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-become-a-spiritual-coach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSalesFunnelForCoaches = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/sales-funnel-for-coaches"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAnalytics = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/marketing-analytics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRemarketingMitGoogleAds = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/remarketing-mit-google-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestLifeCoachingSoftware = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-life-coaching-software"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBecomeACareerCoach = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-become-a-career-coach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiMarketingTools = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ai-marketing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKostenloseNewsletterToolImVergleich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/kostenlose-newsletter-tool-im-vergleich"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinarSoftwareVergleich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/webinar-software-vergleich"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsLeadlovers = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/getresponse-vs-leadlovers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEvitandoErroresEnMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/evitando-errores-en-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKostenloseDeutscheMarketingSoftware = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/kostenlose-deutsche-marketing-software"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestAffiliateNiches = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-affiliate-niches",
"de": "https://www.getresponse.com/de/blog/beste-affiliate-nischen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsSendinblue = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/getresponse-vs-sendinblue",
"de": "https://www.getresponse.com/de/blog/getresponse-vs-sendinblue",
"it": "https://www.getresponse.com/it/blog/getresponse-vs-sendinblue",
"en": "https://www.getresponse.com/blog/getresponse-vs-sendinblue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingBeispiele = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-beispiele"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDieBestenDeutschenNewsletterTools = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/die-besten-deutschen-newsletter-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKlaviyoAlternativen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/klaviyo-alternativen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestCoachingWebsites = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-coaching-websites"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSendingPersonalizedTextMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/sending-personalized-text-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsAcumbamail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/getresponse-vs-acumbamail"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsActivecampaign = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/getresponse-vs-activecampaign",
"es": "https://www.getresponse.com/es/blog/getresponse-vs-activecampaign",
"en": "https://www.getresponse.com/blog/getresponse-vs-activecampaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRapidmailVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/rapidmail-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketoVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/marketo-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsLeadpages = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-vs-leadpages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmsMarketingAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/sms-marketing-analytics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalizedProductRecommendationsEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/personalized-product-recommendations-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGuideToOmnichannelPersonalization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/guide-to-omnichannel-personalization"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBecomeALifeCoach = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-become-a-life-coach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsUnbounce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-vs-unbounce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKlicktippVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/klicktipp-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsCleverreach = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-vs-cleverreach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleYahooAnforderungenEMailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/google-yahoo-anforderungen-e-mail-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalizedEcommerceExpierences = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/personalized-ecommerce-expierences"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomerSegmentationExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/customer-segmentation-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHubspotVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/hubspot-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterWeihnachten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-weihnachten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsRdStation = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/getresponse-vs-rd-station"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostClickfunnelsVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/clickfunnels-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostModernWebsieDesign = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/modern-websie-design"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAfiliacyjnyLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/marketing-afiliacyjny-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostActivecampaignVsGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/activecampaign-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsDrip = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-vs-drip",
"en": "https://www.getresponse.com/blog/getresponse-vs-drip"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGmailYahooAutenticazioneNuoviRequisiti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/gmail-yahoo-autenticazione-nuovi-requisiti",
"pl": "https://www.getresponse.pl/blog/zmiany-w-google-i-yahoo-2024",
"en": "https://www.getresponse.com/blog/gmail-yahoo-authentication",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-gmail-e-yahoo",
"es": "https://www.getresponse.com/es/blog/autenticacion-yahoo-gmail",
"de": "https://www.getresponse.com/de/blog/gmail-und-yahoos-authentifizierung",
"vn": "https://www.getresponse.com/vn/blog/gmail-va-yahoo-thay-doi-xac-thuc-email-tat-ca-nhung-gi-ban-can-biet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGmailUndYahoosAuthentifizierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/gmail-und-yahoos-authentifizierung",
"pl": "https://www.getresponse.pl/blog/zmiany-w-google-i-yahoo-2024",
"en": "https://www.getresponse.com/blog/gmail-yahoo-authentication",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-gmail-e-yahoo",
"es": "https://www.getresponse.com/es/blog/autenticacion-yahoo-gmail",
"it": "https://www.getresponse.com/it/blog/gmail-yahoo-autenticazione-nuovi-requisiti",
"vn": "https://www.getresponse.com/vn/blog/gmail-va-yahoo-thay-doi-xac-thuc-email-tat-ca-nhung-gi-ban-can-biet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPinterestAffiliateMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/pinterest-affiliate-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIncreaseAov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/increase-aov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIsAffiliateMarketingWorthIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/is-affiliate-marketing-worth-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZmianyWGoogleIYahoo2024 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/zmiany-w-google-i-yahoo-2024",
"en": "https://www.getresponse.com/blog/gmail-yahoo-authentication",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-gmail-e-yahoo",
"es": "https://www.getresponse.com/es/blog/autenticacion-yahoo-gmail",
"de": "https://www.getresponse.com/de/blog/gmail-und-yahoos-authentifizierung",
"it": "https://www.getresponse.com/it/blog/gmail-yahoo-autenticazione-nuovi-requisiti",
"vn": "https://www.getresponse.com/vn/blog/gmail-va-yahoo-thay-doi-xac-thuc-email-tat-ca-nhung-gi-ban-can-biet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInfluencerMarketingAfiliacja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/influencer-marketing-afiliacja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutenticacionYahooGmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/autenticacion-yahoo-gmail",
"pl": "https://www.getresponse.pl/blog/zmiany-w-google-i-yahoo-2024",
"en": "https://www.getresponse.com/blog/gmail-yahoo-authentication",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-gmail-e-yahoo",
"de": "https://www.getresponse.com/de/blog/gmail-und-yahoos-authentifizierung",
"it": "https://www.getresponse.com/it/blog/gmail-yahoo-autenticazione-nuovi-requisiti",
"vn": "https://www.getresponse.com/vn/blog/gmail-va-yahoo-thay-doi-xac-thuc-email-tat-ca-nhung-gi-ban-can-biet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutenticacaoGmailEYahoo = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/autenticacao-gmail-e-yahoo",
"pl": "https://www.getresponse.pl/blog/zmiany-w-google-i-yahoo-2024",
"en": "https://www.getresponse.com/blog/gmail-yahoo-authentication",
"es": "https://www.getresponse.com/es/blog/autenticacion-yahoo-gmail",
"de": "https://www.getresponse.com/de/blog/gmail-und-yahoos-authentifizierung",
"it": "https://www.getresponse.com/it/blog/gmail-yahoo-autenticazione-nuovi-requisiti",
"vn": "https://www.getresponse.com/vn/blog/gmail-va-yahoo-thay-doi-xac-thuc-email-tat-ca-nhung-gi-ban-can-biet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost25YearsGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/25-years-getresponse",
"pl": "https://www.getresponse.pl/blog/25-lat-getresponse",
"de": "https://www.getresponse.com/de/blog/getresponse-wird-25",
"it": "https://www.getresponse.com/it/blog/innovazione-email-marketing-25-anni-getresponse",
"es": "https://www.getresponse.com/es/blog/cumpleanos-de-getresponse",
"pt": "https://www.getresponse.com/pt/blog/aniversario-da-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAniversarioDaGetresponse = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/aniversario-da-getresponse",
"pl": "https://www.getresponse.pl/blog/25-lat-getresponse",
"en": "https://www.getresponse.com/blog/25-years-getresponse",
"de": "https://www.getresponse.com/de/blog/getresponse-wird-25",
"it": "https://www.getresponse.com/it/blog/innovazione-email-marketing-25-anni-getresponse",
"es": "https://www.getresponse.com/es/blog/cumpleanos-de-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInnovazioneEmailMarketing25AnniGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/innovazione-email-marketing-25-anni-getresponse",
"pl": "https://www.getresponse.pl/blog/25-lat-getresponse",
"en": "https://www.getresponse.com/blog/25-years-getresponse",
"de": "https://www.getresponse.com/de/blog/getresponse-wird-25",
"es": "https://www.getresponse.com/es/blog/cumpleanos-de-getresponse",
"pt": "https://www.getresponse.com/pt/blog/aniversario-da-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCumpleanosDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/cumpleanos-de-getresponse",
"pl": "https://www.getresponse.pl/blog/25-lat-getresponse",
"en": "https://www.getresponse.com/blog/25-years-getresponse",
"de": "https://www.getresponse.com/de/blog/getresponse-wird-25",
"it": "https://www.getresponse.com/it/blog/innovazione-email-marketing-25-anni-getresponse",
"pt": "https://www.getresponse.com/pt/blog/aniversario-da-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost25LatGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/25-lat-getresponse",
"en": "https://www.getresponse.com/blog/25-years-getresponse",
"de": "https://www.getresponse.com/de/blog/getresponse-wird-25",
"it": "https://www.getresponse.com/it/blog/innovazione-email-marketing-25-anni-getresponse",
"es": "https://www.getresponse.com/es/blog/cumpleanos-de-getresponse",
"pt": "https://www.getresponse.com/pt/blog/aniversario-da-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseWird25 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-wird-25",
"pl": "https://www.getresponse.pl/blog/25-lat-getresponse",
"en": "https://www.getresponse.com/blog/25-years-getresponse",
"it": "https://www.getresponse.com/it/blog/innovazione-email-marketing-25-anni-getresponse",
"es": "https://www.getresponse.com/es/blog/cumpleanos-de-getresponse",
"pt": "https://www.getresponse.com/pt/blog/aniversario-da-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGmailYahooAuthentication = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/gmail-yahoo-authentication",
"pl": "https://www.getresponse.pl/blog/zmiany-w-google-i-yahoo-2024",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-gmail-e-yahoo",
"es": "https://www.getresponse.com/es/blog/autenticacion-yahoo-gmail",
"de": "https://www.getresponse.com/de/blog/gmail-und-yahoos-authentifizierung",
"it": "https://www.getresponse.com/it/blog/gmail-yahoo-autenticazione-nuovi-requisiti",
"vn": "https://www.getresponse.com/vn/blog/gmail-va-yahoo-thay-doi-xac-thuc-email-tat-ca-nhung-gi-ban-can-biet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWalentynkiPomyslyNaKampanie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/walentynki-pomysly-na-kampanie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsMailchimp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-vs-mailchimp",
"it": "https://www.getresponse.com/it/blog/getresponse-vs-mailchimp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoPromocionarUnEbook = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/como-promocionar-un-ebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBlackFridayMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/black-friday-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20MeilleursProgrammesDeParrainagePourGagnerDeLargentEn2023 = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/blog/20-meilleurs-programmes-de-parrainage-pour-gagner-de-largent-en-2023"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostElementosEssenciaisDeUmSite = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/elementos-essenciais-de-um-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostScrivereEmailConIntelligenzaArtificialeEsempi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/scrivere-email-con-intelligenza-artificiale-esempi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomerServiceEmailTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/customer-service-email-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterBlackFriday = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/newsletter-black-friday"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8WaysToPromoteAffiliateLinks = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/blog/8-ways-to-promote-affiliate-links"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKiKampagnenErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ki-kampagnen-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailHalloweenEsempiIdeeTemplate = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-halloween-esempi-idee-template"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20MeilleursProgrammes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/blog/20-meilleurs-programmes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10MeilleursProgrammes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/blog/10-meilleurs-programmes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost19MeilleursProgrammes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/blog/19-meilleurs-programmes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingForCoaches = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-for-coaches"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReEngagementEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/re-engagement-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-automation-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCzarnyPiatekBlackFridayNaPolskimRynku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/czarny-piatek-black-friday-na-polskim-rynku"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBlackFridayCyberMondayNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/black-friday-cyber-monday-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKampanieEmailMarketingoweNaDzienOjca = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/kampanie-email-marketingowe-na-dzien-ojca"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToStartAnEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-start-an-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBouncesEmailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/bounces-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFunnelDeMarketingDeAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/funnel-de-marketing-de-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriSitiIntelligenzaArtificiale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-siti-intelligenza-artificiale",
"pt": "https://www.getresponse.com/pt/blog/melhores-ferramentas-de-ia",
"es": "https://www.getresponse.com/es/blog/mejores-herramientas-de-ia-para-marketing",
"de": "https://www.getresponse.com/de/blog/ai-websites-marketing",
"en": "https://www.getresponse.com/blog/best-ai-websites-usa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFunilDeMarketingDeAfiliados = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/funil-de-marketing-de-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestAffiliateProgramsForBeginners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-affiliate-programs-for-beginners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHalloweenNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/halloween-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoPromocionarEnlacesDeAfiliado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/como-promocionar-enlaces-de-afiliado"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIncreaseSalesWithAutomatedLeadQualification = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/increase-sales-with-automated-lead-qualification"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailingMasivo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mailing-masivo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/affiliate-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDigitalMarketingVsAffiliateMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/digital-marketing-vs-affiliate-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailEtiquette = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-etiquette"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDzisiajDzienDarmowejDostawy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dzisiaj-dzien-darmowej-dostawy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPopUp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/pop-up",
"en": "https://www.getresponse.com/blog/what-are-popups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnsiteMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/onsite-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDieBestenDeutschsprachigenLandingPages = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/die-besten-deutschsprachigen-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSaasEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/saas-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingWithNoMoney = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/affiliate-marketing-with-no-money"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingVsInfluencerMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/affiliate-marketing-vs-influencer-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterAutomation = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-automation",
"en": "https://www.getresponse.com/blog/autoresponders-vs-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEsempiLandingPageItalianeEfficaci = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/esempi-landing-page-italiane-efficaci",
"en": "https://www.getresponse.com/blog/landing-page-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriAlternativeAMailchimp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-alternative-a-mailchimp",
"en": "https://www.getresponse.com/blog/mailchimp-alternatives-and-competitors",
"de": "https://www.getresponse.com/de/blog/mailchimp-alternativen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTop15ChuongTrinhTiepThiLienKetHoaHongDinhKyTotNhatNenThamGiaNam = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/top-15-chuong-trinh-tiep-thi-lien-ket-hoa-hong-dinh-ky-tot-nhat-nen-tham-gia-nam",
"en": "https://www.getresponse.com/blog/recurring-affiliate-programs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGiaDichVuEmailMarketing = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/gia-dich-vu-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUdanaKampaniaEmailMarketingowa20Krokach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/udana-kampania-email-marketingowa-20-krokach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWiadomosciFollowUp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wiadomosci-follow-up"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailAntwortenMitKi = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-antworten-mit-ki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestInfluencerAffiliatePrograms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-influencer-affiliate-programs",
"de": "https://www.getresponse.com/de/blog/influencer-affiliate-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToStartCoachingBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-start-coaching-business",
"de": "https://www.getresponse.com/de/blog/online-coaching-business-aufbauen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSwiateczneKampanieEmailMarketingowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/swiateczne-kampanie-email-marketingowe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJasperRecenzja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jasper-recenzja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingPerPmiProfessionisti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/marketing-per-pmi-professionisti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoDivulgarLinkDeAfiliado = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-divulgar-link-de-afiliado"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyECommerceComeFunziona = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/shopify-e-commerce-come-funziona"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTheBestMarketingNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-best-marketing-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakPoprawicDostarczalnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-poprawic-dostarczalnosc"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPoCoCiLandingPageDlaczegoLepiejNieKierowacRuchuNaStroneGlowna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/po-co-ci-landing-page-dlaczego-lepiej-nie-kierowac-ruchu-na-strone-glowna"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFluxosDeNutricao = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/fluxos-de-nutricao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTestyAbEmailMarketing = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/testy-ab-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChatGptJakKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/chat-gpt-jak-korzystac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebsiteKosten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/website-kosten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestLandingPageBuilders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-landing-page-builders",
"it": "https://www.getresponse.com/it/blog/i-migliori-programmi-per-creare-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKostenloseAffiliateSoftware = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/kostenlose-affiliate-software"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseMidYearReview2023 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-mid-year-review-2023"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuildMagentoStore = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/build-magento-store"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMelhoresExemplosDePopUps = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/melhores-exemplos-de-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailMarketingKosten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-marketing-kosten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGuiaDeSmsMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/guia-de-sms-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAfiliacyjnyJakZaczac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/marketing-afiliacyjny-jak-zaczac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNajlepszeProgramyPartnerskie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/najlepsze-programy-partnerskie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHighTicketAffiliateMarketingComeFunziona = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/high-ticket-affiliate-marketing-come-funziona"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKiMarketingTexte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ki-marketing-texte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSztucznaInteligencjaWEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/sztuczna-inteligencja-w-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSztucznaInteligencjaWMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/sztuczna-inteligencja-w-marketingu",
"en": "https://www.getresponse.com/blog/ai-in-email-marketing",
"de": "https://www.getresponse.com/de/blog/ki-email-marketing",
"vn": "https://www.getresponse.com/vn/blog/ai-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostQuantoCostaGetresponsePrezzi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/quanto-costa-getresponse-prezzi",
"en": "https://www.getresponse.com/blog/free-or-paid-getresponse-plan"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLejekMarketingowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/lejek-marketingowy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseRecensioni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-recensioni",
"es": "https://www.getresponse.com/es/blog/opiniones-de-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterDsgvo = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-dsgvo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriNetworkAffiliazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-network-affiliazione"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCreateAnEmailNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-create-an-email-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeCreareLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-creare-landing-page",
"en": "https://www.getresponse.com/blog/create-a-landing-page",
"de": "https://www.getresponse.com/de/blog/landing-page-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBehavioralSegmentationExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/behavioral-segmentation-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIMiglioriProgrammiPerCreareLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/i-migliori-programmi-per-creare-landing-page",
"en": "https://www.getresponse.com/blog/best-landing-page-builders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageSignificatoConsigli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/landing-page-significato-consigli"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPlanerMarketeraCzyliJakWykorzystacKalendarzMarketingowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/planer-marketera-czyli-jak-wykorzystac-kalendarz-marketingowy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEssentialGuideToBuildingAnEmailMarketingAutomationStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/essential-guide-to-building-an-email-marketing-automation-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFunnelDeVentas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/funnel-de-ventas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiEmailMarketing = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/ai-email-marketing",
"en": "https://www.getresponse.com/blog/ai-in-email-marketing",
"de": "https://www.getresponse.com/de/blog/ki-email-marketing",
"pl": "https://www.getresponse.pl/blog/sztuczna-inteligencja-w-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTaoEmailAi = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/tao-email-ai",
"en": "https://www.getresponse.com/blog/ai-email-generator"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThankYouPage = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/thank-you-page",
"en": "https://www.getresponse.com/blog/thank-you-page",
"es": "https://www.getresponse.com/es/blog/thank-you-page",
"de": "https://www.getresponse.com/de/blog/thank-you-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4ThOfJulyEmailCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/4th-of-july-email-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakWykorzystacSztucznaInteligencjeDoTworzeniaMaili = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-wykorzystac-sztuczna-inteligencje-do-tworzenia-maili"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsMailup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-mailup"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRecurringAffiliatePrograms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/recurring-affiliate-programs",
"vn": "https://www.getresponse.com/vn/blog/top-15-chuong-trinh-tiep-thi-lien-ket-hoa-hong-dinh-ky-tot-nhat-nen-tham-gia-nam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuildingAffiliateMarketingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/building-affiliate-marketing-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChatgptPromptsMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/chatgpt-prompts-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostImproveEmailSenderReputation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/improve-email-sender-reputation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutoresponderWasIstDas = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/autoresponder-was-ist-das"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailLanguagePsychology = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-language-psychology"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWskaznikiWEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wskazniki-w-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailOptimizationStrategies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-optimization-strategies",
"de": "https://www.getresponse.com/de/blog/newsletter-optimieren"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeFunzionaLaMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-funziona-la-marketing-automation",
"en": "https://www.getresponse.com/blog/what-is-marketing-automation-guide",
"vn": "https://www.getresponse.com/vn/blog/marketing-automation-la-gi-huong-dan-su-dung-tu-dong-hoa-tiep-thi",
"de": "https://www.getresponse.com/de/blog/was-ist-marketing-automation",
"pl": "https://www.getresponse.pl/blog/co-to-jest-marketing-automation",
"es": "https://www.getresponse.com/es/blog/ejemplos-de-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAbTestEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/ab-test-email-marketing",
"de": "https://www.getresponse.com/de/blog/a-b-tests-fuer-e-mail-kampagnen",
"en": "https://www.getresponse.com/blog/carry-ab-test"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriProgrammiPerNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-programmi-per-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHerramientasGratisParaAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/herramientas-gratis-para-afiliados",
"pt": "https://www.getresponse.com/pt/blog/ferramentas-gratuitas-para-afiliados"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKiEmailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ki-email-marketing",
"en": "https://www.getresponse.com/blog/ai-in-email-marketing",
"vn": "https://www.getresponse.com/vn/blog/ai-email-marketing",
"pl": "https://www.getresponse.pl/blog/sztuczna-inteligencja-w-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseErfahrungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-erfahrungen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBirthdayEmailExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/birthday-email-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailCopywritingTips = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-copywriting-tips"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeFareEmailMarketingRisorseEConsigli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-fare-email-marketing-risorse-e-consigli"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIaEnEmailMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/ia-en-email-marketing",
"pt": "https://www.getresponse.com/pt/blog/ia-no-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostB2BEmailExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/b2b-email-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineSecurity101 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/online-security-101"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKreatorEmailiAi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/kreator-emaili-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutorespondersVsAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/autoresponders-vs-automation",
"de": "https://www.getresponse.com/de/blog/newsletter-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestSaasPartnerProgramsForAgencies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-saas-partner-programs-for-agencies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKundensegmentierungPersonalisierungEmailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/kundensegmentierung-personalisierung-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCrossSellingUpSelling = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/cross-selling-up-selling"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingLgpd = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/email-marketing-lgpd"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOmnichannelMarketingTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/omnichannel-marketing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCorsiEmailMarketingGratuitiConCertificazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/corsi-email-marketing-gratuiti-con-certificazione"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWzywajDoDzialaniaCzyliOCtaSlowKilka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wzywaj-do-dzialania-czyli-o-cta-slow-kilka"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeCreareFunnelDiVendita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-creare-funnel-di-vendita"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailAuthentication = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-authentication",
"it": "https://www.getresponse.com/it/blog/autenticazione-email",
"de": "https://www.getresponse.com/de/blog/email-authentifizierung",
"es": "https://www.getresponse.com/es/blog/autenticacion-de-email",
"pt": "https://www.getresponse.com/pt/blog/autenticacao-de-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingDesignTrends = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-design-trends"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9SuperEasyWaysToPersonalizeYourEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/9-super-easy-ways-to-personalize-your-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHypeUmKiMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/hype-um-ki-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMakeMoneyWithYourEmailList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/make-money-with-your-email-list"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostABTestsFuerEMailKampagnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/a-b-tests-fuer-e-mail-kampagnen",
"en": "https://www.getresponse.com/blog/carry-ab-test",
"it": "https://www.getresponse.com/it/blog/ab-test-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostB2BAndB2CMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/b2b-and-b2c-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToPromoteAffiliateLinks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-promote-affiliate-links"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoCrearUnaPaginaWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/como-crear-una-pagina-web",
"en": "https://www.getresponse.com/blog/how-to-build-a-website-from-scratch",
"de": "https://www.getresponse.com/de/blog/website-erstellen-in-9-schritten",
"vn": "https://www.getresponse.com/vn/blog/huong-dan-tao-website-trong-9-buoc-don-gian",
"it": "https://www.getresponse.com/it/blog/come-creare-un-sito-internet",
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-strone-internetowa",
"pt": "https://www.getresponse.com/pt/blog/como-criar-um-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingOhneWebsiteUndReichweite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-ohne-website-und-reichweite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost11KillerEmailMarketingTipsAffiliates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/11-killer-email-marketing-tips-affiliates",
"de": "https://www.getresponse.com/de/blog/affiliate-email-marketing-tipps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20BestEmailMarketers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/20-best-email-marketers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateEmailListBuilding = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/affiliate-email-list-building"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterDesignTipps = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-design-tipps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15EmailMarketingExpertsKnow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/15-email-marketing-experts-know"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPlanosDeSoftwareGetresponse = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/planos-de-software-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeScrivereNewsletterEfficace = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-scrivere-newsletter-efficace"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOpinioesSobreAGetresponse = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/opinioes-sobre-a-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestAiAffiliatePrograms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-ai-affiliate-programs",
"de": "https://www.getresponse.com/de/blog/ki-affiliate-programme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTargetedB2BEmailMarketingCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/targeted-b2b-email-marketing-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMuttertagMarketingNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/muttertag-marketing-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNarzedziaEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/narzedzia-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSingleOptInVsDoubleOptIn = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/single-opt-in-vs-double-opt-in"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntelligenzaArtificialeScritturaTestiToolBeneficiAiCopywriter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/intelligenza-artificiale-scrittura-testi-tool-benefici-ai-copywriter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSeoLandingpageOptimierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/seo-landingpage-optimierung"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageDesignTrends = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/landing-page-design-trends"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestenAffiliateMarketingProgramme = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/besten-affiliate-marketing-programme",
"en": "https://www.getresponse.com/blog/best-affiliate-marketing-programs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageKostenImVergleich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/landing-page-kosten-im-vergleich"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKostenlosesSalesFunnelTemplate = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/kostenloses-sales-funnel-template",
"en": "https://www.getresponse.com/blog/free-sales-funnel-template"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineKursErstellenUndVerkaufen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-kurs-erstellen-und-verkaufen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/landing-page-erstellen",
"en": "https://www.getresponse.com/blog/create-a-landing-page",
"it": "https://www.getresponse.com/it/blog/come-creare-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestFontForEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-font-for-emails",
"de": "https://www.getresponse.com/de/blog/e-mail-schriftarten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhyDoINeedAWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/why-do-i-need-a-website",
"vn": "https://www.getresponse.com/vn/blog/su-quan-trong-cua-website-ly-do-nen-co-trang-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestEnterpriseEcommerceSolutions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-enterprise-ecommerce-solutions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailchimpAlternativesAndCompetitors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mailchimp-alternatives-and-competitors",
"it": "https://www.getresponse.com/it/blog/migliori-alternative-a-mailchimp",
"de": "https://www.getresponse.com/de/blog/mailchimp-alternativen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToWriteEmailSubjectLines = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-write-email-subject-lines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEsempiNewsletterEfficaci = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/esempi-newsletter-efficaci"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEnterpriseEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/enterprise-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7WelcomeEmailsThatSetTheStandard = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-welcome-emails-that-set-the-standard",
"de": "https://www.getresponse.com/de/blog/willkommens-e-mails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostArtificialIntelligenceEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/artificial-intelligence-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOpinionesDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/opiniones-de-getresponse",
"it": "https://www.getresponse.com/it/blog/getresponse-recensioni"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToGetCoachingClients = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-get-coaching-clients"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestAweberAlternatives = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-aweber-alternatives"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGuideKeywordResearch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/guide-keyword-research"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPlanesDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/planes-de-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFollowUpEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/follow-up-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDesignWebinarInvitationEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/design-webinar-invitation-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChatgptAndSeo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/chatgpt-and-seo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestEmailMarketingAffiliatePrograms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-email-marketing-affiliate-programs",
"de": "https://www.getresponse.com/de/blog/affiliate-email-marketing-programme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAbandonedBrowseEmailExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/abandoned-browse-email-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEvergreenContentJakTworzycTresciKtorePokochajaTwoiOdbiorcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/evergreen-content-jak-tworzyc-tresci-ktore-pokochaja-twoi-odbiorcy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailchimpVsGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mailchimp-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWlasnaDomenaEmailMarketing = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wlasna-domena-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeInviareNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-inviare-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEventInvitationEmailTemplatesAndExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/event-invitation-email-templates-and-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntelligenzaArtificialeMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/intelligenza-artificiale-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingForNonProfits = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-for-non-profits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmallBusinessEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/small-business-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostConsigliPerEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/consigli-per-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomizeRssToEmailToSuitYourPurposes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/customize-rss-to-email-to-suit-your-purposes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDesignConsigliPerNewsletterPerfetta = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-design-consigli-per-newsletter-perfetta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoEvitarOSpam = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-evitar-o-spam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAreGoogleAdsWorthIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/are-google-ads-worth-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKlaviyoAlternatives = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/klaviyo-alternatives"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlinePortfolioExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/online-portfolio-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalWebsiteExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/personal-website-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalTrainerWebsites = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/personal-trainer-websites"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnePageWebsiteExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/one-page-website-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiEmailGenerator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ai-email-generator",
"vn": "https://www.getresponse.com/vn/blog/tao-email-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterTextTemplates = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-text-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAiInEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ai-in-email-marketing",
"de": "https://www.getresponse.com/de/blog/ki-email-marketing",
"vn": "https://www.getresponse.com/vn/blog/ai-email-marketing",
"pl": "https://www.getresponse.pl/blog/sztuczna-inteligencja-w-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterParaEcommerce = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/newsletter-para-ecommerce",
"es": "https://www.getresponse.com/es/blog/newsletter-para-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestReferralProgramsMakeMoney = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-referral-programs-make-money",
"vn": "https://www.getresponse.com/vn/blog/tiep-thi-lien-ket"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThankYouForYourPurchaseEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/thank-you-for-your-purchase-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateEmailMarketingConsigliProgrammi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/affiliate-email-marketing-consigli-programmi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMejoresHerramientasDeEmailMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mejores-herramientas-de-email-marketing",
"en": "https://www.getresponse.com/blog/best-email-marketing-services"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreareMailingListEfficacePerAffiliateMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/creare-mailing-list-efficace-per-affiliate-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutomobilNewsletterKfzBetriebe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/automobil-newsletter-kfz-betriebe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostImmobilienNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/immobilien-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerceContentMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ecommerce-content-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10EasyWaysIncreaseEmailClickRates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-easy-ways-increase-email-click-rates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPhanMemEmailMarketing = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/phan-mem-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCreateGoogleAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-create-google-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNoweBiuroGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/nowe-biuro-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMagentoMarketingStrategies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/magento-marketing-strategies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOutOfOffice = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/out-of-office"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToWriteCrossSellEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-write-cross-sell-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostExemplosDeAutomacaoDeMarketing = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/exemplos-de-automacao-de-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponsePromoCodes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-promo-codes",
"de": "https://www.getresponse.com/de/blog/rabatte-und-gutscheine-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestPopupSoftware = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-popup-software",
"de": "https://www.getresponse.com/de/blog/popup-creator-vergleich"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingToolGratuiti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/affiliate-marketing-tool-gratuiti",
"en": "https://www.getresponse.com/blog/27-best-free-marketing-tools-affiliates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseForShopify = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-for-shopify"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestEmailAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-email-autoresponders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestEmailMarketingServices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-email-marketing-services",
"es": "https://www.getresponse.com/es/blog/mejores-herramientas-de-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailListManagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-list-management"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-best-practices",
"de": "https://www.getresponse.com/de/blog/email-marketing-fuer-einsteiger"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingPlaner2023 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/marketing-planer-2023"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPhishingUndSpamEmailBeispiele = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/phishing-und-spam-email-beispiele"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWasIstEMailMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/was-ist-e-mail-marketing",
"en": "https://www.getresponse.com/blog/email-marketing-for-beginners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDesignBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-design-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostImproveEmailDeliverability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/improve-email-deliverability",
"it": "https://www.getresponse.com/it/blog/email-deliverability-come-migliorarla",
"de": "https://www.getresponse.com/de/blog/email-zustellbarkeit-optimieren",
"es": "https://www.getresponse.com/es/blog/entregabilidad-email-marketing",
"pt": "https://www.getresponse.com/pt/blog/entregabilidade-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIncreaseEmailOpenRate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/increase-email-open-rate",
"de": "https://www.getresponse.com/de/blog/e-mail-oeffnungsrate-verbessern"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingSoftwareForShopify = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-software-for-shopify",
"de": "https://www.getresponse.com/de/blog/email-marketing-apps-shopify"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyMarketingAutomationApps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/shopify-marketing-automation-apps",
"de": "https://www.getresponse.com/de/blog/shopify-marketing-automation-apps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFrauentagKampagneTipps = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/frauentag-kampagne-tipps",
"it": "https://www.getresponse.com/it/blog/marketing-festa-della-donna"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomDomainForEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/custom-domain-for-email-marketing",
"it": "https://www.getresponse.com/it/blog/email-dominio-personalizzato-come-crearla",
"de": "https://www.getresponse.com/de/blog/custom-domain-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutorenNewsletterFuerSelfpublisher = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/autoren-newsletter-fuer-selfpublisher"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCoachingNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/coaching-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSaasMarketingStrategyTips = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/saas-marketing-strategy-tips"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostQuaValentine = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/qua-valentine"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestAffiliateMarketingPrograms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-affiliate-marketing-programs",
"de": "https://www.getresponse.com/de/blog/besten-affiliate-marketing-programme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestEmailNewsletterSoftware = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-email-newsletter-software"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailSurveysGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-surveys-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingReport = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-marketing-report"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPrzegladRokuWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/przeglad-roku-w-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseReview2022 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-review-2022",
"de": "https://www.getresponse.com/de/blog/getresponse-jahresrueckblick-2022",
"en": "https://www.getresponse.com/blog/getresponses-year-in-review"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRetrospectivaGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/retrospectiva-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseJahresrueckblick2022 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-jahresrueckblick-2022",
"en": "https://www.getresponse.com/blog/getresponses-year-in-review",
"it": "https://www.getresponse.com/it/blog/getresponse-review-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponsesYearInReview = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponses-year-in-review",
"de": "https://www.getresponse.com/de/blog/getresponse-jahresrueckblick-2022",
"it": "https://www.getresponse.com/it/blog/getresponse-review-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAgencyPartnerPrograms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-agency-partner-programs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeFareEmailMarketingGratis = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-fare-email-marketing-gratis"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingDeAfiliadosParaPrincipiantes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/marketing-de-afiliados-para-principiantes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToMakeMoneyPhotographer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-make-money-photographer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowDoWebinarsWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-do-webinars-work",
"de": "https://www.getresponse.com/de/blog/was-ist-ein-webinar",
"pl": "https://www.getresponse.pl/blog/jak-dzialaja-webinary"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProductLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/product-landing-page",
"en": "https://www.getresponse.com/blog/product-landing-page-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEstructuraOrganizacionalDeServicioAlCliente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/estructura-organizacional-de-servicio-al-cliente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmsMarketing = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/sms-marketing",
"en": "https://www.getresponse.com/blog/sms-campaign-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmallBusinessWebsiteBuilders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/small-business-website-builders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSendVideoThroughEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/send-video-through-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingCaseStudies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-case-studies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalisierteProduktempfehlungenEcommerce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/personalisierte-produktempfehlungen-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMagento2Plugins = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/magento-2-plugins"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriPiattaformeEcommerceConfronto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-piattaforme-ecommerce-confronto"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeCreareUnSitoInternet = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-creare-un-sito-internet",
"en": "https://www.getresponse.com/blog/how-to-build-a-website-from-scratch",
"de": "https://www.getresponse.com/de/blog/website-erstellen-in-9-schritten",
"vn": "https://www.getresponse.com/vn/blog/huong-dan-tao-website-trong-9-buoc-don-gian",
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-strone-internetowa",
"es": "https://www.getresponse.com/es/blog/como-crear-una-pagina-web",
"pt": "https://www.getresponse.com/pt/blog/como-criar-um-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFeiertagsEMailKampagnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/feiertags-e-mail-kampagnen",
"en": "https://www.getresponse.com/blog/inspiring-holiday-email-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMejoresEjemplosDePaginasWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mejores-ejemplos-de-paginas-web",
"en": "https://www.getresponse.com/blog/website-ideas",
"pt": "https://www.getresponse.com/pt/blog/melhores-exemplos-de-sites"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommercePopupConsigliStrumentiCreazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/ecommerce-popup-consigli-strumenti-creazione"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDogfoodingHowGetresponseRunsWebinarCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/dogfooding-how-getresponse-runs-webinar-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterBetreffBeispiele = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-betreff-beispiele"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinareErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/webinare-erstellen",
"en": "https://www.getresponse.com/blog/how-to-create-a-webinar-presentation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailMarketingTrends2023 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-marketing-trends-2023"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTheWeirdestSpamEmailsFrom2022 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-weirdest-spam-emails-from-2022"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingNatal = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/email-marketing-natal"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingNavidad = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/email-marketing-navidad"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToUseMarketingCalendar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-use-marketing-calendar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostYoutubeLeadGenerationAds = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/youtube-lead-generation-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleAdsKosten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/google-ads-kosten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketYourselfAsRealtor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/market-yourself-as-realtor"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAssistantTheAiPoweredEmailMarketingSidekick = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/assistant-the-ai-powered-email-marketing-sidekick"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseFacebookAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-facebook-ads",
"pl": "https://www.getresponse.pl/blog/getresponse-facebook-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCachLamEmailMarketingHieuQuaNhat = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/cach-lam-email-marketing-hieu-qua-nhat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingLeadNurturing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-lead-nurturing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCampegneDemSignificato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/campegne-dem-significato",
"de": "https://www.getresponse.com/de/blog/direct-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLiveChatWebsite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/live-chat-website",
"en": "https://www.getresponse.com/blog/what-is-live-chat",
"pl": "https://www.getresponse.pl/blog/live-chat-czym-sa-i-jak-uzywac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmsMarketingTools = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/sms-marketing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeMisurareEmailMarketingKpi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-misurare-email-marketing-kpi",
"en": "https://www.getresponse.com/blog/email-marketing-metrics",
"de": "https://www.getresponse.com/de/blog/email-marketing-kpis"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHappyNewYearEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/happy-new-year-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailBlast = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-blast",
"de": "https://www.getresponse.com/de/blog/email-blast"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBlogErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/blog-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterBeispieleUndInspiration = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-beispiele-und-inspiration"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterNataleEsempiIdee = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/newsletter-natale-esempi-idee"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMakeInternalEmailNewsletterWinner = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/make-internal-email-newsletter-winner"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGettingStartedWithMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getting-started-with-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSeasonalMarketingCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/seasonal-marketing-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookPageVsWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-page-vs-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsConvertkit = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-vs-convertkit",
"de": "https://www.getresponse.com/de/blog/getresponse-vs-convertkit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCreateAServicesPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-create-a-services-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostValentinesDayEmailCampaignIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/valentines-day-email-campaign-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingForBeginners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-for-beginners",
"de": "https://www.getresponse.com/de/blog/was-ist-e-mail-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntroducingTransactionalEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/introducing-transactional-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIleKosztujeReklamaGoogle = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ile-kosztuje-reklama-google"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleAdsJakZaczac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/google-ads-jak-zaczac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWieSiehtEineAffiliateWebsiteAus = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/wie-sieht-eine-affiliate-website-aus"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBuildAWebsiteFromScratch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-build-a-website-from-scratch",
"de": "https://www.getresponse.com/de/blog/website-erstellen-in-9-schritten",
"vn": "https://www.getresponse.com/vn/blog/huong-dan-tao-website-trong-9-buoc-don-gian",
"it": "https://www.getresponse.com/it/blog/come-creare-un-sito-internet",
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-strone-internetowa",
"es": "https://www.getresponse.com/es/blog/como-crear-una-pagina-web",
"pt": "https://www.getresponse.com/pt/blog/como-criar-um-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestWebinarLandingPageExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-webinar-landing-page-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRemarketing = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFreeEbookTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/free-ebook-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCarryAbTest = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/carry-ab-test",
"de": "https://www.getresponse.com/de/blog/a-b-tests-fuer-e-mail-kampagnen",
"it": "https://www.getresponse.com/it/blog/ab-test-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingFuerEinsteiger = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-marketing-fuer-einsteiger",
"en": "https://www.getresponse.com/blog/email-marketing-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKampaniePpcDlaEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/kampanie-ppc-dla-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSaasBlackFridayDeals = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/saas-black-friday-deals"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFreeSalesFunnelTemplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/free-sales-funnel-template",
"de": "https://www.getresponse.com/de/blog/kostenloses-sales-funnel-template"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSqueezePagesBeginnersGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/squeeze-pages-beginners-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleAdsFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/google-ads-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostQuickTransactionalEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/quick-transactional-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleAdsLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/google-ads-landing-pages",
"it": "https://www.getresponse.com/it/blog/landing-pages-per-google-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6TemplateEmailMarketingHalloween = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/6-template-email-marketing-halloween",
"en": "https://www.getresponse.com/blog/halloween-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatArePopups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-are-popups",
"pl": "https://www.getresponse.pl/blog/pop-up"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToWriteGoogleAdsCopy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-write-google-ads-copy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinarKosten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/webinar-kosten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingBlackFriday = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/email-marketing-black-friday",
"pt": "https://www.getresponse.com/pt/blog/email-marketing-black-friday"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingBlackFridayStretegie = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/marketing-black-friday-stretegie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailVorlageErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-vorlage-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyCodiciScontoComeCrearli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/shopify-codici-sconto-come-crearli"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCyberWeekendEmailB2BSaas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cyber-weekend-email-b2b-saas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThanksgivingEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/thanksgiving-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCyberMondayEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cyber-monday-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRekomendacjeAiEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/rekomendacje-ai-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBlackFridayLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/black-friday-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingBuenFin = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/marketing-buen-fin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadGenerationLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-generation-landing-pages",
"de": "https://www.getresponse.com/de/blog/landing-page-lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBuildLeadFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-build-lead-funnel",
"de": "https://www.getresponse.com/de/blog/lead-generation-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSpectacularPopupDesigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/spectacular-popup-designs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTiepThiLienKet = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/tiep-thi-lien-ket",
"en": "https://www.getresponse.com/blog/best-referral-programs-make-money"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBlackFridayEmailCampaignTips = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/black-friday-email-campaign-tips"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingGeldVerdienen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-marketing-geld-verdienen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingGeldVerdienenRechtliches = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-geld-verdienen-rechtliches"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyPrestashopConfronto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/shopify-prestashop-confronto"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHalloweenEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/halloween-email",
"vn": "https://www.getresponse.com/vn/blog/6-template-email-marketing-halloween"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToMakeARestaurantWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-make-a-restaurant-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingItaliaStatisticheBenchmarkTrend = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-marketing-italia-statistiche-benchmark-trend"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChurchWebsiteBuilders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/church-website-builders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMiglioriProgrammiDiAffiliazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/migliori-programmi-di-affiliazione"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-chats",
"ru": "https://www.getresponse.com/ru/blog/getresponse-chats"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWickedlyEffectiveTemplatesForHalloween = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/wickedly-effective-templates-for-halloween"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVisualIdentity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/visual-identity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWomenInIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/women-in-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFunnelDeVentasEcommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/funnel-de-ventas-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseMobileApp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-mobile-app"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerceMerchandising = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ecommerce-merchandising"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBesteAffiliateNischen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/beste-affiliate-nischen",
"en": "https://www.getresponse.com/blog/best-affiliate-niches"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinarLandingPages = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/webinar-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingComeIniziare = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/affiliate-marketing-come-iniziare",
"en": "https://www.getresponse.com/blog/affiliate-marketing-for-beginners",
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-starten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFormularFuerWebsiteErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/formular-fuer-website-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKobietyWIt = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/kobiety-w-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInspiringHolidayEmailCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/inspiring-holiday-email-campaigns",
"de": "https://www.getresponse.com/de/blog/feiertags-e-mail-kampagnen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCancelCulturePurposeMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/cancel-culture-purpose-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterPopups = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-popups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEjemplosDeMarketingAutomation = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/ejemplos-de-marketing-automation",
"en": "https://www.getresponse.com/blog/what-is-marketing-automation-guide",
"vn": "https://www.getresponse.com/vn/blog/marketing-automation-la-gi-huong-dan-su-dung-tu-dong-hoa-tiep-thi",
"it": "https://www.getresponse.com/it/blog/come-funziona-la-marketing-automation",
"de": "https://www.getresponse.com/de/blog/was-ist-marketing-automation",
"pl": "https://www.getresponse.pl/blog/co-to-jest-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBoostYourEcommerceGrowthWithSmsMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-boost-your-ecommerce-growth-with-sms-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeveragingKeywordMappingOptimizeBuyersJourney = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/leveraging-keyword-mapping-optimize-buyers-journey"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingStarten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-starten",
"en": "https://www.getresponse.com/blog/affiliate-marketing-for-beginners",
"it": "https://www.getresponse.com/it/blog/affiliate-marketing-come-iniziare"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailCarrelloAbbandonato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-carrello-abbandonato"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMagentoOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/magento-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost27BestFreeMarketingToolsAffiliates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/27-best-free-marketing-tools-affiliates",
"it": "https://www.getresponse.com/it/blog/affiliate-marketing-tool-gratuiti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProductAiRecommendationsEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/product-ai-recommendations-ecommerce",
"pt": "https://www.getresponse.com/pt/blog/recomendacao-de-produtos-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnmeldeformularVorlage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/anmeldeformular-vorlage",
"en": "https://www.getresponse.com/blog/signup-form-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDeutscheAffiliateProgramme = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/deutsche-affiliate-programme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadCaptureForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-capture-forms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSegmentingYourList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/segmenting-your-list"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFerramentasDeAutomacaoDeMarketing = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/ferramentas-de-automacao-de-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCreateAPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-create-a-popup"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineFormulareErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-formulare-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPopupCreatorVergleich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/popup-creator-vergleich",
"en": "https://www.getresponse.com/blog/best-popup-software"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSindPopUpsGefaehrlich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/sind-pop-ups-gefaehrlich"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateEmailMarketingTipps = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-email-marketing-tipps",
"en": "https://www.getresponse.com/blog/11-killer-email-marketing-tips-affiliates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailListBuildingIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-list-building-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWasSindPopUps = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/was-sind-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHerramientasParaCrearFormulariosOnline = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/herramientas-para-crear-formularios-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterAnmeldeformularGestalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/newsletter-anmeldeformular-gestalten"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostImpactOfInflationRetailers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/impact-of-inflation-retailers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateTracking = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-tracking"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateEmailMarketingProgramme = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-email-marketing-programme",
"en": "https://www.getresponse.com/blog/best-email-marketing-affiliate-programs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingShopify = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-shopify"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRecomendacaoDeProdutosEcommerce = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/recomendacao-de-produtos-ecommerce",
"en": "https://www.getresponse.com/blog/product-ai-recommendations-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhyYouShouldNeverBuyAnEmailList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/why-you-should-never-buy-an-email-list"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPhotographyWebsiteBuilders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/photography-website-builders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerceMarketingStrategia = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/ecommerce-marketing-strategia"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailPopupExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-popup-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProductDescription = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/product-description"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFormBuilderKostenlos = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/form-builder-kostenlos"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingDlaNonProfit = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/email-marketing-dla-non-profit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingShopifyAppConsigli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-marketing-shopify-app-consigli"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIdosellOpinieCennik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/idosell-opinie-cennik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseForMagento2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-for-magento-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageShopifyEcommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/landing-page-shopify-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutomatischesPopupFensterErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/automatisches-popup-fenster-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostActivecampaignVsMailchimp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/activecampaign-vs-mailchimp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMailchimpVsCampaignMonitor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mailchimp-vs-campaign-monitor"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4WaysMakePopUpsLessAnnoyingEffective = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/4-ways-make-pop-ups-less-annoying-effective"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyNewsletterDoubleOptIn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/shopify-newsletter-double-opt-in"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEMailFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/e-mail-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHuongDanTaoWebsiteTrong9BuocDonGian = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/huong-dan-tao-website-trong-9-buoc-don-gian",
"en": "https://www.getresponse.com/blog/how-to-build-a-website-from-scratch",
"de": "https://www.getresponse.com/de/blog/website-erstellen-in-9-schritten",
"it": "https://www.getresponse.com/it/blog/come-creare-un-sito-internet",
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-strone-internetowa",
"es": "https://www.getresponse.com/es/blog/como-crear-una-pagina-web",
"pt": "https://www.getresponse.com/pt/blog/como-criar-um-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSuQuanTrongCuaWebsiteLyDoNenCoTrangWeb = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/su-quan-trong-cua-website-ly-do-nen-co-trang-web",
"en": "https://www.getresponse.com/blog/why-do-i-need-a-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKontaktformularDsgvo = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/kontaktformular-dsgvo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTransactionalEmailsShopify = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/transactional-emails-shopify"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProductLaunchEmail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/product-launch-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostElementosDeUnaPaginaWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/elementos-de-una-pagina-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTransaktionaleEMailsEcommerce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/transaktionale-e-mails-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineShopBekanntMachen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/online-shop-bekannt-machen",
"en": "https://www.getresponse.com/blog/how-to-promote-your-shopify-store"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNonProfitEmailMarketingOnAMissionAndA50LifetimeDiscount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/non-profit-email-marketing-on-a-mission-and-a-50-lifetime-discount"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoCriarUmSite = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-criar-um-site",
"en": "https://www.getresponse.com/blog/how-to-build-a-website-from-scratch",
"de": "https://www.getresponse.com/de/blog/website-erstellen-in-9-schritten",
"vn": "https://www.getresponse.com/vn/blog/huong-dan-tao-website-trong-9-buoc-don-gian",
"it": "https://www.getresponse.com/it/blog/come-creare-un-sito-internet",
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-strone-internetowa",
"es": "https://www.getresponse.com/es/blog/como-crear-una-pagina-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAutomatisierteBegruessungsEmail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/automatisierte-begruessungs-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVideoSalesFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/video-sales-funnel",
"en": "https://www.getresponse.com/blog/video-sales-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadGenerationFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/lead-generation-funnel",
"en": "https://www.getresponse.com/blog/how-to-build-lead-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestRestaurantWebsiteBuilders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-restaurant-website-builders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThietKeLandingPageTotNhat = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/thiet-ke-landing-page-tot-nhat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPorzuconyKoszykPrzyklady = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/porzucony-koszyk-przyklady"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateALandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-a-landing-page",
"de": "https://www.getresponse.com/de/blog/landing-page-erstellen",
"it": "https://www.getresponse.com/it/blog/come-creare-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSeoVsPpc = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/seo-vs-ppc"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWoocommerceOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/woocommerce-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailsZurRueckgewinnungVerlorenerKunden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/emails-zur-rueckgewinnung-verlorener-kunden"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationECommerce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/marketing-automation-e-commerce",
"en": "https://www.getresponse.com/blog/marketing-automation-workflows-to-skyrocket-ecommerce-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMejoresPracticasEngagementRedesSociales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/mejores-practicas-engagement-redes-sociales"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEjemplosDePromocionesDeVentas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/ejemplos-de-promociones-de-ventas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShoperOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/shoper-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSocialCommerceStrategie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/social-commerce-strategie",
"en": "https://www.getresponse.com/blog/optimize-social-commerce-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPrestashopOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/prestashop-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWasIstEinWebinar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/was-ist-ein-webinar",
"en": "https://www.getresponse.com/blog/how-do-webinars-work",
"pl": "https://www.getresponse.pl/blog/jak-dzialaja-webinary"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFunilDeVendasNoEcommerce = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/funil-de-vendas-no-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToPromoteYourPhotographyBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-promote-your-photography-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComoDivulgarUmEbook = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/como-divulgar-um-ebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyLandingPageEcommerce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/shopify-landing-page-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyOpinie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/shopify-opinie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChurn = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/churn"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCalculateYourHourlyRate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-calculate-your-hourly-rate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPlatformyEcommercePorownanie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/platformy-ecommerce-porownanie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToPromoteYourShopifyStore = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-promote-your-shopify-store",
"de": "https://www.getresponse.com/de/blog/online-shop-bekannt-machen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleAdsBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/google-ads-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInterviewSgreeningNachhaltigkeitsmarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/interview-sgreening-nachhaltigkeitsmarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRealEstateMarketingTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/real-estate-marketing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFreeOrPaidGetresponsePlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/free-or-paid-getresponse-plan",
"it": "https://www.getresponse.com/it/blog/quanto-costa-getresponse-prezzi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebsiteErstellenIn9Schritten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/website-erstellen-in-9-schritten",
"en": "https://www.getresponse.com/blog/how-to-build-a-website-from-scratch",
"vn": "https://www.getresponse.com/vn/blog/huong-dan-tao-website-trong-9-buoc-don-gian",
"it": "https://www.getresponse.com/it/blog/come-creare-un-sito-internet",
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-strone-internetowa",
"es": "https://www.getresponse.com/es/blog/como-crear-una-pagina-web",
"pt": "https://www.getresponse.com/pt/blog/como-criar-um-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSalesFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/sales-funnel",
"en": "https://www.getresponse.com/blog/sales-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShopifyEcommerceEmailTemplatesDeutsch = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/shopify-ecommerce-email-templates-deutsch"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFriendlyWorkplaceCertificationAwardWin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/friendly-workplace-certification-award-win"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGreenMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/green-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageLaGi = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/landing-page-la-gi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsActivecampaignPhanMemMarketingTotNhat = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/getresponse-vs-activecampaign-phan-mem-marketing-tot-nhat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationLaGiHuongDanSuDungTuDongHoaTiepThi = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/marketing-automation-la-gi-huong-dan-su-dung-tu-dong-hoa-tiep-thi",
"en": "https://www.getresponse.com/blog/what-is-marketing-automation-guide",
"it": "https://www.getresponse.com/it/blog/come-funziona-la-marketing-automation",
"de": "https://www.getresponse.com/de/blog/was-ist-marketing-automation",
"pl": "https://www.getresponse.pl/blog/co-to-jest-marketing-automation",
"es": "https://www.getresponse.com/es/blog/ejemplos-de-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTroChuyenTrucTuyenLaGiApDungWebsiteDoanhNghiep = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/tro-chuyen-truc-tuyen-la-gi-ap-dung-website-doanh-nghiep"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDiscountStrategyGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/discount-strategy-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostECommercePrzewodnik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/e-commerce-przewodnik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingEcommerce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-marketing-ecommerce",
"en": "https://www.getresponse.com/blog/email-marketing-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPotwierdzicCzyNiePotwierdzicDylematyDotyczaceZapisuDoListyMailingowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/potwierdzic-czy-nie-potwierdzic-dylematy-dotyczace-zapisu-do-listy-mailingowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseApoyaAUcrania = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/getresponse-apoya-a-ucrania"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKpisDeECommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/kpis-de-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNossaSolidariedadeAUcrania = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/blog/nossa-solidariedade-a-ucrania"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAbgebrocheneWarenkorbEmails = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/abgebrochene-warenkorb-emails",
"en": "https://www.getresponse.com/blog/abandoned-cart-email-examples",
"es": "https://www.getresponse.com/es/blog/emails-de-carrito-abandonado",
"pt": "https://www.getresponse.com/pt/blog/exemplos-de-email-carrinho-abandonado"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingVorteile = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-marketing-vorteile"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingBenchmarksReportKeyInsights = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-benchmarks-report-key-insights"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThietKeLandingPage = (locale) => {
    const alternates = {
"vn": "https://www.getresponse.com/vn/blog/thiet-ke-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntegracionGetresponseShopify = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/blog/integracion-getresponse-shopify"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEnterpriseMarketingTheBasics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/enterprise-marketing-the-basics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingAppsShopify = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-marketing-apps-shopify",
"en": "https://www.getresponse.com/blog/email-marketing-software-for-shopify"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationForEducation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-automation-for-education"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebPushNotificationExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/web-push-notification-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFollowUpEmailsShopify = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/follow-up-emails-shopify"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRabatteUndGutscheineErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/rabatte-und-gutscheine-erstellen",
"en": "https://www.getresponse.com/blog/getresponse-promo-codes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerceAdvertisingGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ecommerce-advertising-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageLeadGeneration = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/landing-page-lead-generation",
"en": "https://www.getresponse.com/blog/lead-generation-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookAdLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/facebook-ad-landing-page",
"en": "https://www.getresponse.com/blog/create-landing-pages-facebook-ad-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEbookLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ebook-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOptimizeSocialCommerceStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/optimize-social-commerce-strategy",
"de": "https://www.getresponse.com/de/blog/social-commerce-strategie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPreheaderBledy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/preheader-bledy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCostOfB2BContentWriting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cost-of-b2b-content-writing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWspolczynnikKonwersjiEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wspolczynnik-konwersji-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailListCleaningBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-list-cleaning-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBlogNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/blog-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateFreelanceWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-freelance-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAffiliateMarketingForBeginners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/affiliate-marketing-for-beginners",
"de": "https://www.getresponse.com/de/blog/affiliate-marketing-starten",
"it": "https://www.getresponse.com/it/blog/affiliate-marketing-come-iniziare"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntroducingGetresponseWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/introducing-getresponse-website-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseShopifyOnlineMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-shopify-online-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingKpis = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/email-marketing-kpis",
"en": "https://www.getresponse.com/blog/email-marketing-metrics",
"it": "https://www.getresponse.com/it/blog/come-misurare-email-marketing-kpi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDmarcCoToJestIJakUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dmarc-co-to-jest-i-jak-uzywac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAbandonedCartEmailExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/abandoned-cart-email-examples",
"de": "https://www.getresponse.com/de/blog/abgebrochene-warenkorb-emails",
"es": "https://www.getresponse.com/es/blog/emails-de-carrito-abandonado",
"pt": "https://www.getresponse.com/pt/blog/exemplos-de-email-carrinho-abandonado"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUkrainskieStartupyIFirmyIt = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ukrainskie-startupy-i-firmy-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUaWeSupportUkraine = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ua-we-support-ukraine"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostB2BMarketingAutomationGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/b2b-marketing-automation-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOswiadczenieGetresponseRosja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/oswiadczenie-getresponse-rosja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSupportUkrainianTechCompanies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/support-ukrainian-tech-companies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGenderMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/gender-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPozyskiwanieLeadow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/pozyskiwanie-leadow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost29PopulyarnyhOshibok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/29-populyarnyh-oshibok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseSolidarnieZUkraina = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/getresponse-solidarnie-z-ukraina"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsActivecampaignQualeSceglierePerLaTuaAzienda = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-activecampaign-quale-scegliere-per-la-tua-azienda"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseSolidaritaetUkraine = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/getresponse-solidaritaet-ukraine"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseSolidarityUkraine = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-solidarity-ukraine"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPushUvedomleniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/push-uvedomleniya"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWordpressEmailListPlugins = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/wordpress-email-list-plugins"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSajtDlyaYurista = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/sajt-dlya-yurista"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8AdvancedEmailMarketingTechniquesCantMiss2016 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/8-advanced-email-marketing-techniques-cant-miss-2016"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingProcessesAutomate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-processes-automate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRassylka8Marta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/rassylka-8-marta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingDlyaRitejla = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-marketing-dlya-ritejla"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/landing-page-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWinBackCustomerEmailTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/win-back-customer-email-templates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMobilnyeLendingiPrakticheskieRekomendacziiPoDizajnu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/mobilnye-lendingi-prakticheskie-rekomendaczii-po-dizajnu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThirdPartyCookiesPhaseOut = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/third-party-cookies-phase-out"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCzymJestDomena = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/czym-jest-domena"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSdelatSajtPortfolioPolnoeRukovodstvo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-sdelat-sajt-portfolio-polnoe-rukovodstvo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostResponsywnaStronaInternetowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/responsywna-strona-internetowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostValentinesDayMarketingIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/valentines-day-marketing-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIpWarmupWhatItIs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ip-warmup-what-it-is"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSozdatKontentPlan = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/sozdat-kontent-plan"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakTworzycHaslaReklamowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-tworzyc-hasla-reklamowe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPismoNaDenSvyatogoValentinaKakSozdatKapelnuyuRassylku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pismo-na-den-svyatogo-valentina-kak-sozdat-kapelnuyu-rassylku"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProdayuschieSajty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/prodayuschie-sajty"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRealEstateEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/real-estate-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIMigliori7StrumentiDiAutomazioneDiMarketingOnlinePerLeAziendeItaliane = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/i-migliori-7-strumenti-di-automazione-di-marketing-online-per-le-aziende-italiane"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGuidaEmailMarketingPerEcommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/guida-email-marketing-per-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeCostruireUnaListaEmailDaZero = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-costruire-una-lista-email-da-zero",
"de": "https://www.getresponse.com/de/blog/mailingliste-aufbauen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeVendereOnlineCreandoIlTuoSitoAziendale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-vendere-online-creando-il-tuo-sito-aziendale"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWasIstMarketingAutomation = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/was-ist-marketing-automation",
"en": "https://www.getresponse.com/blog/what-is-marketing-automation-guide",
"vn": "https://www.getresponse.com/vn/blog/marketing-automation-la-gi-huong-dan-su-dung-tu-dong-hoa-tiep-thi",
"it": "https://www.getresponse.com/it/blog/come-funziona-la-marketing-automation",
"pl": "https://www.getresponse.pl/blog/co-to-jest-marketing-automation",
"es": "https://www.getresponse.com/es/blog/ejemplos-de-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRealEstateCrms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/real-estate-crms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGenerateRealEstateLeads = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/generate-real-estate-leads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDizajnEmailRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/dizajn-email-rassylki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/landing-page-examples",
"it": "https://www.getresponse.com/it/blog/esempi-landing-page-italiane-efficaci"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostClickfunnelsAlternative = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/clickfunnels-alternative"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInstapageVsClickfunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/instapage-vs-clickfunnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost404Stranitsa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/404-stranitsa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebPushNotifications = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/web-push-notifications"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestSalesTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-sales-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmsCampaignGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/sms-campaign-guide",
"pt": "https://www.getresponse.com/pt/blog/sms-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLearnDigitalMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/learn-digital-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPinterest = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pinterest"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostConstantContactVsMailchimp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/constant-contact-vs-mailchimp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostActivecampaignAlternativesAndCompetitors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/activecampaign-alternatives-and-competitors"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostConstantContactAlternativesCompetitors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/constant-contact-alternatives-competitors"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsConstantContact = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-vs-constant-contact"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakProdavatNaVebinarah11SekretnyhPriemov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-prodavat-na-vebinarah-11-sekretnyh-priemov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsCampaignMonitor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-vs-campaign-monitor"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakZarabotat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-zarabotat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatIsMarketingAutomationGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-is-marketing-automation-guide",
"vn": "https://www.getresponse.com/vn/blog/marketing-automation-la-gi-huong-dan-su-dung-tu-dong-hoa-tiep-thi",
"it": "https://www.getresponse.com/it/blog/come-funziona-la-marketing-automation",
"de": "https://www.getresponse.com/de/blog/was-ist-marketing-automation",
"pl": "https://www.getresponse.pl/blog/co-to-jest-marketing-automation",
"es": "https://www.getresponse.com/es/blog/ejemplos-de-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRetailEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/retail-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingForMusicians = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-for-musicians",
"de": "https://www.getresponse.com/de/blog/e-mail-marketing-musiker"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAweberVsMailchimpVsGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/aweber-vs-mailchimp-vs-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZachemSait = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/zachem-sait"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/newsletter-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostConvertkitVsMailchimp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/convertkit-vs-mailchimp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatAreWebPushNotifications = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-are-web-push-notifications"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuildPhotographyPortfolio = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/build-photography-portfolio"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKonstruktorSajtovTilda = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/konstruktor-sajtov-tilda"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterSignupFormBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/newsletter-signup-form-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSdelatMobilnuyuVersiyuSajtaPoshagovayaInstrukcziya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-sdelat-mobilnuyu-versiyu-sajta-poshagovaya-instrukcziya"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakStworzycStroneInternetowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-strone-internetowa",
"en": "https://www.getresponse.com/blog/how-to-build-a-website-from-scratch",
"de": "https://www.getresponse.com/de/blog/website-erstellen-in-9-schritten",
"vn": "https://www.getresponse.com/vn/blog/huong-dan-tao-website-trong-9-buoc-don-gian",
"it": "https://www.getresponse.com/it/blog/come-creare-un-sito-internet",
"es": "https://www.getresponse.com/es/blog/como-crear-una-pagina-web",
"pt": "https://www.getresponse.com/pt/blog/como-criar-um-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingDlaMalychFirm = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/email-marketing-dla-malych-firm"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTildaVsWix2021 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/tilda-vs-wix-2021"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIleKosztujeStronaInternetowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ile-kosztuje-strona-internetowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDobrePraktykiWEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dobre-praktyki-w-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIleKosztujeWebinar = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ile-kosztuje-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLendingi2021 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/lendingi-2021"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLuchshieBesplatnyeKonstruktorySajtov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/luchshie-besplatnye-konstruktory-sajtov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFreeForeverEVeroPuoiCreareIlTuoSitoWebInUnAttimoEsattoAbbiamoDettoGratuitamente = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/free-forever-e-vero-puoi-creare-il-tuo-sito-web-in-un-attimo-esatto-abbiamo-detto-gratuitamente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSozdatSNulya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/sozdat-s-nulya"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestFreeWebsiteBuilders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-free-website-builders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinarEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/webinar-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFreeGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/free-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOtdyhSamostoyatelno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/otdyh-samostoyatelno"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLuchshieServisyDljaEmejlRassylok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/luchshie-servisy-dlja-emejl-rassylok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSozdatBlog = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-sozdat-blog"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPhotographerAboutMePage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/photographer-about-me-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZarzadzanieListaMailingowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/zarzadzanie-lista-mailingowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRestaurantBusiness = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/restaurant-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostB2BEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/b2b-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTherapistWebsiteDesign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/therapist-website-design"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadFormExtensions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-form-extensions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTwoFactorAuthenticationtime = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/two-factor-authenticationtime"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmployeeNewsletter = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/employee-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost404Pages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/404-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPhotographyPortfolioExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/photography-portfolio-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSiteRestaurant = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/site-restaurant"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGraphicDesignPortfolioExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/graphic-design-portfolio-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseShablony = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/getresponse-shablony"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPoCoCiStronaInternetowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/po-co-ci-strona-internetowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost12BestPracticePerGestireLeTueListeEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/12-best-practice-per-gestire-le-tue-liste-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTrialVersiyaGetresponseVozmozhnostiIOgranicheniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/trial-versiya-getresponse-vozmozhnosti-i-ogranicheniya"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSoloAdsRightBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/solo-ads-right-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHyperPersonalization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/hyper-personalization"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKartinki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kartinki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmtpErrorCodes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/smtp-error-codes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreatingAPhotographyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/creating-a-photography-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAboutPage = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/about-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAppleMacIosNewPrivacyPolicy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/apple-mac-ios-new-privacy-policy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFitnessWebsitesExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/fitness-websites-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDomeny = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/domeny"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmsMarketingGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/sms-marketing-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOptimizeFacebookLeadAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/optimize-facebook-lead-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEinIntuitiverWebsiteEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ein-intuitiver-website-editor"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInfluencerMarketingForEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/influencer-marketing-for-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkedinFormyLidogeneracii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/linkedin-formy-lidogeneracii"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAgenzieImmobiliariIlMomentoDiSfruttareIVantaggiDiUnProprioSitoEDellemailMarketingEAdesso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/agenzie-immobiliari-il-momento-di-sfruttare-i-vantaggi-di-un-proprio-sito-e-dellemail-marketing-e-adesso"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebsiteIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/website-ideas",
"es": "https://www.getresponse.com/es/blog/mejores-ejemplos-de-paginas-web",
"pt": "https://www.getresponse.com/pt/blog/melhores-exemplos-de-sites"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKonstruktorSajtov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/konstruktor-sajtov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStateOnlineHackingAmerica = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/state-online-hacking-america"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatIsLiveChat = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-is-live-chat",
"de": "https://www.getresponse.com/de/blog/live-chat-website",
"pl": "https://www.getresponse.pl/blog/live-chat-czym-sa-i-jak-uzywac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkedinLeadGenForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/linkedin-lead-gen-forms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost30PolzovatelskihFiltrovDlyaGoogleAnalitiki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/30-polzovatelskih-filtrov-dlya-google-analitiki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerce = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRestaurantWebsiteDesignIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/restaurant-website-design-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateUsPageMakesPeopleCare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-us-page-makes-people-care"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZaproszenieNaWebinarJakPisac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/zaproszenie-na-webinar-jak-pisac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGiveawayEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/giveaway-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFaqNaSajtahIVEmailRassylkahPrimeryIPraktika = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/faq-na-sajtah-i-v-email-rassylkah-primery-i-praktika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLiveChatCzymSaIJakUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/live-chat-czym-sa-i-jak-uzywac",
"en": "https://www.getresponse.com/blog/what-is-live-chat",
"de": "https://www.getresponse.com/de/blog/live-chat-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineTherapySurvey = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/online-therapy-survey"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLiveChatGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/live-chat-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInstrumentyVerifikaciiValidaciiEmail = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/instrumenty-verifikacii-validacii-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhyEmailsGoToSpam = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/why-emails-go-to-spam",
"de": "https://www.getresponse.com/de/blog/warum-landen-deine-emails-im-spam-ordner",
"it": "https://www.getresponse.com/it/blog/email-marketing-senza-finire-in-spam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSignupFormExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/signup-form-examples",
"de": "https://www.getresponse.com/de/blog/anmeldeformular-vorlage"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBecomeAnOnlineTeacher = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-become-an-online-teacher"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDkimSpfMailRu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/dkim-spf-mail-ru"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmsMarketingPrzewodnik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/sms-marketing-przewodnik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProductLandingPageTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/product-landing-page-templates",
"de": "https://www.getresponse.com/de/blog/product-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDigitalMarketingPoznajPodstawyMarketinguInternetowego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/digital-marketing-poznaj-podstawy-marketingu-internetowego"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestEmailValidationAndVerificationTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-email-validation-and-verification-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseVsVoxmailStrumentiDiEmailMarketingAConfronto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/getresponse-vs-voxmail-strumenti-di-email-marketing-a-confronto"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakDzialajaWebinary = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-dzialaja-webinary",
"en": "https://www.getresponse.com/blog/how-do-webinars-work",
"de": "https://www.getresponse.com/de/blog/was-ist-ein-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/landing-pages",
"es": "https://www.getresponse.com/es/blog/como-crear-una-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingGostinichnyjBiznes = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-marketing-gostinichnyj-biznes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEbookLandingPageDesigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ebook-landing-page-designs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToStartVlogging = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-start-vlogging"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToDoAnInstagramGiveaway = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-do-an-instagram-giveaway"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBoostOnlineSales = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/boost-online-sales"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingForHotels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-for-hotels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingTrendsAnalisiReport2021 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/email-marketing-trends-analisi-report-2021"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostObschijIliVydelennyjIpAdres = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/obschij-ili-vydelennyj-ip-adres"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCosaSonoLeEmailTransazionaliEComeUtilizzarle = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/cosa-sono-le-email-transazionali-e-come-utilizzarle"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRealEstateNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/real-estate-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMonetizatsiyaRassylok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/monetizatsiya-rassylok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIpsSharedVsDedicated = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ips-shared-vs-dedicated"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToOnboardNewEmployees = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-onboard-new-employees"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakWykorzystacKuponyRabatoweWEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-wykorzystac-kupony-rabatowe-w-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComeAumentareIlNumeroDeiTuoiIscrittiConLeNotifichePushDaWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/come-aumentare-il-numero-dei-tuoi-iscritti-con-le-notifiche-push-da-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkedinAdsCost = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/linkedin-ads-cost"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingKnigi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-marketing-knigi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGeneratingLeadsOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/generating-leads-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEcommerceFunnelErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/ecommerce-funnel-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOtdyhSamostojatelno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/otdyh-samostojatelno"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBiznes2021 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/biznes-2021"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDynamicContentCzyliSposobNaPersonalizacjeEmailiDarmowyPoradnik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dynamic-content-czyli-sposob-na-personalizacje-emaili-darmowy-poradnik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDataDrivenMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/data-driven-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEbookMarketingStrategies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ebook-marketing-strategies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPopUpOkna = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pop-up-okna"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostServisyAvtomatizatsiiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/servisy-avtomatizatsii-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalBrandExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/personal-brand-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMicrosoftDynamicsIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/microsoft-dynamics-integration"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateLandingPagesFacebookAdCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-landing-pages-facebook-ad-campaign",
"de": "https://www.getresponse.com/de/blog/facebook-ad-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebPush = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/web-push"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntervjuMihailShakin = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/intervju-mihail-shakin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToSellDigitalProducts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-sell-digital-products"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInstagramInfluencerSalaryByState = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/instagram-influencer-salary-by-state"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCoToJestMarketingAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/co-to-jest-marketing-automation",
"en": "https://www.getresponse.com/blog/what-is-marketing-automation-guide",
"vn": "https://www.getresponse.com/vn/blog/marketing-automation-la-gi-huong-dan-su-dung-tu-dong-hoa-tiep-thi",
"it": "https://www.getresponse.com/it/blog/come-funziona-la-marketing-automation",
"de": "https://www.getresponse.com/de/blog/was-ist-marketing-automation",
"es": "https://www.getresponse.com/es/blog/ejemplos-de-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKlassnyjKopirajting = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/klassnyj-kopirajting"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLorarioMigliorePerInviareNewsletterStudio2020 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/lorario-migliore-per-inviare-newsletter-studio-2020"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinarUnNuovaOpportunita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/blog/webinar-un-nuova-opportunita"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10BestEditingToolsBloggers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-best-editing-tools-bloggers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMaternityAndPaternityLeave = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/maternity-and-paternity-leave"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKonstruktoryLendingov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/konstruktory-lendingov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLuchsheeVremyaDlyaRassylkiV2020GoduMasshtabnoeIssledovanieGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/luchshee-vremya-dlya-rassylki-v-2020-godu-masshtabnoe-issledovanie-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPutKlientaITochkiKontakta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/put-klienta-i-tochki-kontakta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFuterPisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/futer-pisma"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingMetrics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-metrics",
"it": "https://www.getresponse.com/it/blog/come-misurare-email-marketing-kpi",
"de": "https://www.getresponse.com/de/blog/email-marketing-kpis"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakPerejtiSMailchimpNaGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-perejti-s-mailchimp-na-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateSocialMediaMarketingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-social-media-marketing-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSozdatReklamuGoogleVGetresponseNovayaFunkcziya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-sozdat-reklamu-google-v-getresponse-novaya-funkcziya"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGreatCopywritingExamples = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/great-copywriting-examples"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreativeInstagramStoriesIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/creative-instagram-stories-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTworzenieReklamGoogleAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/tworzenie-reklam-google-ads"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatIsAudienceDevelopment = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-is-audience-development"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkedinLeadGeneration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/linkedin-lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20AutomatedEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/20-automated-emails",
"es": "https://www.getresponse.com/es/blog/email-marketing-automatizado",
"pt": "https://www.getresponse.com/pt/blog/automacao-de-email-marketing",
"it": "https://www.getresponse.com/it/blog/email-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost11WelcomeEmails = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/11-welcome-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomerJourneyMap = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/customer-journey-map"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBuildAnEmailFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-build-an-email-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestTimeToSendEmailInfographic = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-time-to-send-email-infographic"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChernajaPjatnicaIdeiRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/chernaja-pjatnica-idei-rassylki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostServisyAvtopostinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/servisy-avtopostinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCopywritingLandingPageConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/copywriting-landing-page-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToPersonalizeYourEmailsWithDynamicContentFreebiemonday = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-personalize-your-emails-with-dynamic-content-freebiemonday"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCifrovojMarketing = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/cifrovoj-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVoronkiProdazhDarimBesplatnyjShablon = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/voronki-prodazh-darim-besplatnyj-shablon"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRealEstateLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/real-estate-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalDigitalMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/personal-digital-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWarumLandenDeineEmailsImSpamOrdner = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/blog/warum-landen-deine-emails-im-spam-ordner",
"en": "https://www.getresponse.com/blog/why-emails-go-to-spam",
"it": "https://www.getresponse.com/it/blog/email-marketing-senza-finire-in-spam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationSciezkaKlienta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/marketing-automation-sciezka-klienta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPokolenieZ = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pokolenie-z"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPromoteNewProductUsingEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/promote-new-product-using-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinarSkuteczneDzialaniaEmailMarketingowePytaniaOdpowiedzi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/webinar-skuteczne-dzialania-email-marketingowe-pytania-odpowiedzi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLidMagnitPredlozhenieRuki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/lid-magnit-predlozhenie-ruki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIstoriiVSocsetjah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/istorii-v-socsetjah"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNedovolnyeKlienty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/nedovolnye-klienty"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLidMagnityIssledovanieGetrespons = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/lid-magnity-issledovanie-getrespons"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLidogeneracija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/lidogeneracija"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestWorstCitiesEmploymentDuringCovid19 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-worst-cities-employment-during-covid-19"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookLeadAdsGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-lead-ads-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUdalennajaRabotaOrganizacija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/udalennaja-rabota-organizacija"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakDobavitVideoVPismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-dobavit-video-v-pismo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDlaczegoEmailTrafiaDoSpamu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dlaczego-email-trafia-do-spamu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIsFacebookAdvertisingReallyWorthIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/is-facebook-advertising-really-worth-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSertifikatSootvetstvijaGdpr = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/sertifikat-sootvetstvija-gdpr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestLeadMagnetsStudy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-lead-magnets-study"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPismoPodtverzhdenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pismo-podtverzhdenie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBeginnersGuideToLeadGeneration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/beginners-guide-to-lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLearnFromUnhappyCustomers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/learn-from-unhappy-customers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCreateAWebinarPresentation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-create-a-webinar-presentation",
"de": "https://www.getresponse.com/de/blog/webinare-erstellen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReklamaVFejsbukNaGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/reklama-v-fejsbuk-na-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseDzialaWZgodzieZRodo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/getresponse-dziala-w-zgodzie-z-rodo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizacijaDljaNovichkov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizacija-dlja-novichkov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShortStoriesSocialMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/short-stories-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseGdprCompliance = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-gdpr-compliance"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKvizMarketing = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kviz-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFirmaStawiajacaNaPraceZdalna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/firma-stawiajaca-na-prace-zdalna"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10OpportunitiesUseMarketingAutomationAcrossCustomerLifecycle = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-opportunities-use-marketing-automation-across-customer-lifecycle"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIdealnayaProdayushhayaStraniczaKakayaOna = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/idealnaya-prodayushhaya-stranicza-kakaya-ona"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRecordAWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/record-a-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPozycjonowanieStronySamemu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/pozycjonowanie-strony-samemu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostElektronnayaKniga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/elektronnaya-kniga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPostQuarantineWorkFromHomeSurvey = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/post-quarantine-work-from-home-survey"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWriteOutreachEmailsPeopleWontHate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/write-outreach-emails-people-wont-hate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseZapier = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/getresponse-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSdelatLogoKompanii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-sdelat-logo-kompanii"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostShinyNewTemplatesConfirmationEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/shiny-new-templates-confirmation-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStranicaBlagodarnosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/stranica-blagodarnosti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewEmailCreator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/new-email-creator"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUdalennayaRabotaPrimery = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/udalennaya-rabota-primery"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmenaPodrjadchikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/smena-podrjadchikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHigherEducationStudentEngagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/higher-education-student-engagement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSegmentEmailListQuizFunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/segment-email-list-quiz-funnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVoronkaProdazh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/voronka-prodazh"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookEcommerceAdsSocialMediaCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-ecommerce-ads-social-media-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateASalesPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-a-sales-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLokalizacziyaEmailRassylok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/lokalizacziya-email-rassylok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseCovid19 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/getresponse-covid-19"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRemoteBusinessCommunication = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/remote-business-communication"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReklamaVInstagram = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/reklama-v-instagram"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInThisTogether = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/in-this-together"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailRassylkaKakSdelatPravilno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-rassylka-kak-sdelat-pravilno"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationHigherEducation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-automation-higher-education"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJestesmyRazemGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jestesmy-razem-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailCampaignsLocalization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-campaigns-localization"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReklamaFejsbuk = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/reklama-fejsbuk"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSpotkanieZdalnePoradyWskazowki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/spotkanie-zdalne-porady-wskazowki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDripCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-drip-campaigns",
"de": "https://www.getresponse.com/de/blog/email-drip-kampagnen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUdalennayaRabota = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/udalennaya-rabota"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostServisnyeStranicy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/servisnye-stranicy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCtaKnopkaPrizyvaKDejstviju = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/cta-knopka-prizyva-k-dejstviju"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIntervjuNetpik = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/intervju-netpik"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDostavljaemost = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/dostavljaemost"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOptimizacijaFoto = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/optimizacija-foto"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRepurposeAndUpdateContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/repurpose-and-update-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookPixel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-pixel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKapelnyjMarketing = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kapelnyj-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInstagramAdExamplesDriveEcommerceSales = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/instagram-ad-examples-drive-ecommerce-sales"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookAdsCostsImproveRoi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-ads-costs-improve-roi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtovebinary = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtovebinary"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTiktok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/tiktok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseAutofunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-autofunnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDeliverability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-deliverability",
"de": "https://www.getresponse.com/de/blog/e-mail-zustellbarkeit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnDemandWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/on-demand-webinars"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStoitLiIspolzovatEmodzhiVStrokeTemyPisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/stoit-li-ispolzovat-emodzhi-v-stroke-temy-pisma"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSozdatSvojPervyjVebinar = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-sozdat-svoj-pervyj-vebinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingovyjSleng = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/marketingovyj-sleng"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4PismaKotoryePovysyatPoseschaemostVashihVebinarov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/4-pisma-kotorye-povysyat-poseschaemost-vashih-vebinarov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKomunikacjaWWosp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/komunikacja-w-wosp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost27VdohnovlyayushhihIdejInformaczionnyhRassylok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/27-vdohnovlyayushhih-idej-informaczionnyh-rassylok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPochemuNeStoitPokupatPodpisnuyuBazuRekomendacziiSpeczialista = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pochemu-ne-stoit-pokupat-podpisnuyu-bazu-rekomendaczii-speczialista"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineMarketingStrategyForYoutube = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/online-marketing-strategy-for-youtube"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakBlogMozhetPomochPrivlechEmailPodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-blog-mozhet-pomoch-privlech-email-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmojisEmailSubjectLineBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/emojis-email-subject-line-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTrendyVSfereDizajnaLendingov9InteresnyhIdej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/trendy-v-sfere-dizajna-lendingov-9-interesnyh-idej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHistoriaPewnegoZespolu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/historia-pewnego-zespolu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailOptinsFromBlog = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-optins-from-blog"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8VdohnovlyayushhihPrimerovLendingaDlyaVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/8-vdohnovlyayushhih-primerov-lendinga-dlya-vebinara"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost25LidMagnitovKotoryeRealnoRabotayut = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/25-lid-magnitov-kotorye-realno-rabotayut"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTajmeryObratnogoOtschetaNaLendingahGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/tajmery-obratnogo-otscheta-na-lendingah-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToBuildAnOnlineBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-build-an-online-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAverageInternshipSalariesPayForCollege = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/average-internship-salaries-pay-for-college"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFollowUpEmail = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/follow-up-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnatomiyaProdayuschegoLendinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/anatomiya-prodayuschego-lendinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWrzutaToNiePoruta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wrzuta-to-nie-poruta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPodpisnyeStranitsyInstruktsiiDlyaNovichkov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/podpisnye-stranitsy-instruktsii-dlya-novichkov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizatsiyaMarketingaVSotsialnyhSetyahSmmPlyusyIMinusy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizatsiya-marketinga-v-sotsialnyh-setyah-smm-plyusy-i-minusy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKontentDlyaVebinaraOsnovnyePravila = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kontent-dlya-vebinara-osnovnye-pravila"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInfluencerMarketing = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/influencer-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnaliziruemVovlechennostDlyaSegmentatsiiKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/analiziruem-vovlechennost-dlya-segmentatsii-kontaktov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMassovayaEmailRassylkaIEmailCompanyRaznitsa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/massovaya-email-rassylka-i-email-company-raznitsa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakKinoindustriyaIMarketingVzaimosvyazany = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-kinoindustriya-i-marketing-vzaimosvyazany"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost12PrichinPochemuPismaPopadayutVSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/12-prichin-pochemu-pisma-popadayut-v-spam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAsoOptimizacija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/aso-optimizacija"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEngagementListSegmentation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/engagement-list-segmentation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVyborPravilnogoInstrumentaAvtomatizatsiiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/vybor-pravilnogo-instrumenta-avtomatizatsii-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPageCountdownTimer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/landing-page-countdown-timer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLandingPagesECommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/landing-pages-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMierzenieCustomerExperience = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/mierzenie-customer-experience"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20SposobovPovysitVovlechennostChitatelejVashihPostov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/20-sposobov-povysit-vovlechennost-chitatelej-vashih-postov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSeoWithEmailMarketingRankings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/seo-with-email-marketing-rankings"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakOdinVoprosMozhetIzmenitBiznes = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-odin-vopros-mozhet-izmenit-biznes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIssledovanieGetresponsePoEmailMarketinguAktualnyeDannye = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/issledovanie-getresponse-po-email-marketingu-aktualnye-dannye"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTheMostHatedBusinessJargonCorporateBuzzWords = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-most-hated-business-jargon-corporate-buzz-words"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakAdvokatyBrendaMogutPomochVashemuBiznesu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-advokaty-brenda-mogut-pomoch-vashemu-biznesu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizatsiyaKontentMarketingaVozmozhnoLiEto = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizatsiya-kontent-marketinga-vozmozhno-li-eto"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHighConvertingEcommerceLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/high-converting-ecommerce-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProduktivnostVoVremyaPrazdnikovIOtpuskov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/produktivnost-vo-vremya-prazdnikov-i-otpuskov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMoralnyjOtdyh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/moralnyj-otdyh"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVebinarDlyaProdvizheniyaKontentaOsnovniyeOshibki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/vebinar-dlya-prodvizheniya-kontenta-osnovniye-oshibki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZaKulisamiVebinarovVseSekretyITonkosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/za-kulisami-vebinarov-vse-sekrety-i-tonkosti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakPovysitSvojuProduktivnost = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-povysit-svoju-produktivnost"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStoitLiVklyuchatPoleImyaVFormuPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/stoit-li-vklyuchat-pole-imya-v-formu-podpiski"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDojrzewanieLeadow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dojrzewanie-leadow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakUstranitUtechkuKlientovVVoronkeProdazh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-ustranit-utechku-klientov-v-voronke-prodazh"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailRassylkaKreativnyeIdei = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-rassylka-kreativnye-idei"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOttokKontaktovKakSNimSpravitsya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/ottok-kontaktov-kak-s-nim-spravitsya"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZaczacSprzedawacWInternecie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zaczac-sprzedawac-w-internecie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPodpiskaSPodtverzhdeniemIBezChemuOtdatPredpochtenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/podpiska-s-podtverzhdeniem-i-bez-chemu-otdat-predpochtenie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost11EssentialMarketingAppsForSmallBusinessOwners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/11-essential-marketing-apps-for-small-business-owners"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLichnyjBrendingBezOshibok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/lichnyj-brending-bez-oshibok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingVNedvizhimosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-marketing-v-nedvizhimosti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadMagnetIdeas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-magnet-ideas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingovyeVoronkiVseChtoVyHoteliZnat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/marketingovye-voronki-vse-chto-vy-hoteli-znat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRobotyIIskusstvennyjIntellekt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/roboty-i-iskusstvennyj-intellekt"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPredstavlyaemAutofunnelNovuyuFunktsiyuGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/predstavlyaem-autofunnel-novuyu-funktsiyu-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWlasciweMiejsceCtaLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wlasciwe-miejsce-cta-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizatsiyaMarketingaIStrategiyaOmnikanalnogoVzaimodejstviya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizatsiya-marketinga-i-strategiya-omnikanalnogo-vzaimodejstviya"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPrzewodnikPoKampaniachDrip = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/przewodnik-po-kampaniach-drip"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestUsCitiesProfessionalWomen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-us-cities-professional-women"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakChastoDelatRassylku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-chasto-delat-rassylku"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizatsiaMarketingaNaRazlichnyhEtapahZhiznennogoTsiklaKlienta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizatsia-marketinga-na-razlichnyh-etapah-zhiznennogo-tsikla-klienta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKalendarEmailMarketingaKotoryjEkonomitVasheVremya10PoleznyhSovetov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kalendar-email-marketinga-kotoryj-ekonomit-vashe-vremya-10-poleznyh-sovetov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTipsAndToolsToWriteSeoFriendlyContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/tips-and-tools-to-write-seo-friendly-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSobiraemBazuKontaktovDlyaInternetMagazina = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/sobiraem-bazu-kontaktov-dlya-internet-magazina"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakStworzycLogoMalejFirmyNiezaleznieOdBudzetu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-logo-malej-firmy-niezaleznie-od-budzetu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBloggingBenefitsBestPractices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/blogging-benefits-best-practices"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKontentMarketingIOshibki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kontent-marketing-i-oshibki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSimbiozAvtomatizatsiiMarketingaISotsialnyhSetejOsnovnyePrintsipy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/simbioz-avtomatizatsii-marketinga-i-sotsialnyh-setej-osnovnye-printsipy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHigherEducationEmailMarketingStrategies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/higher-education-email-marketing-strategies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9NevinnyhZabluzhdenijOPlatformahAvtomatizatsiiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/9-nevinnyh-zabluzhdenij-o-platformah-avtomatizatsii-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPpcCampaignsEnrollMoreStudents = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ppc-campaigns-enroll-more-students"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUserOnboardingEmailBestPracticesSaasCompanies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/user-onboarding-email-best-practices-saas-companies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGolubojOkeanStrategija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/goluboj-okean-strategija"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10RasprostranennyhOshibokVEmailMarketinge = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/10-rasprostranennyh-oshibok-v-email-marketinge"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost19UbojnyhLidMagnitovKotoryeZavoyuyutLyubovVashejAuditorii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/19-ubojnyh-lid-magnitov-kotorye-zavoyuyut-lyubov-vashej-auditorii"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPrzegladSprintu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/przeglad-sprintu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPreheaderMistakes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/preheader-mistakes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOprosyIFidbek = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/oprosy-i-fidbek"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost43SposobaSobratKachestvennuyuPodpisnuyuBazu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/43-sposoba-sobrat-kachestvennuyu-podpisnuyu-bazu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewsletterFrequency = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/newsletter-frequency"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingWECommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/email-marketing-w-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15AktualnychRekomendatsijDlyaEmailMarketingaNa2019God = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/15-aktualnych-rekomendatsij-dlya-email-marketinga-na-2019-god"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPuttingLandingPageCtasRightPlace = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/putting-landing-page-ctas-right-place"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10ProstyhSposobovPovysitKlikabelnost = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/10-prostyh-sposobov-povysit-klikabelnost"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingDlaPoczatkujacych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/email-marketing-dla-poczatkujacych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMetrikiInternetMagazinaKotoryeMyInterpretiruemNepravilno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/metriki-internet-magazina-kotorye-my-interpretiruem-nepravilno"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTrendyLandingPages2019 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/trendy-landing-pages-2019"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProdvizhenieMalogoBiznesaVInternete = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/prodvizhenie-malogo-biznesa-v-internete"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPovyshenieKonversiiEmailKampaniiSPomoshchyuIzobrazheniy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/povyshenie-konversii-email-kampanii-s-pomoshchyu-izobrazheniy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTechnikiSprzedazySlowem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/techniki-sprzedazy-slowem"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCcpaCaliforniaConsumerPrivacyAct = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ccpa-california-consumer-privacy-act"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakWykorzystacLicznikNaLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-wykorzystac-licznik-na-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakPovysitAktivnostPodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-povysit-aktivnost-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakVernutPokupatelej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-vernut-pokupatelej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost18KlyuchevyhSlovKotoryeMotiviruyutKontaktyOtkryvatPisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/18-klyuchevyh-slov-kotorye-motiviruyut-kontakty-otkryvat-pisma"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost11SposobovPovysitOtkryvaemostPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/11-sposobov-povysit-otkryvaemost-pisem"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingaKlientAdvokatBrenda = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-marketinga-klient-advokat-brenda"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingDlyaInternetMagazinov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-marketing-dlya-internet-magazinov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6PreimuschestvKachestvennojKampaniiEmailMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/6-preimuschestv-kachestvennoj-kampanii-email-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookRetargetingGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-retargeting-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostScrumProductOwnerCzIv = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/scrum-product-owner-cz-iv"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmejlMarketingOsnovy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/emejl-marketing-osnovy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5SposobovSohranitLico = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/5-sposobov-sohranit-lico"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSposobyZdobywaniaLinkow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/sposoby-zdobywania-linkow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMakeSocialMediaEmailMarketingWorkTogether = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/make-social-media-email-marketing-work-together"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost14GlavnyhMetrikEmailMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/14-glavnyh-metrik-email-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCheapskateEcommerceMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cheapskate-ecommerce-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEffectiveEventRegistrationLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/effective-event-registration-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailRassylkaLuchshiKanalInternetMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/email-rassylka-luchshi-kanal-internet-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10EkspertovAvtomatizatsiiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/10-ekspertov-avtomatizatsii-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5SposobowNaZdobycieKlientowNaLinkedin = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/5-sposobow-na-zdobycie-klientow-na-linkedin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6IdejPrazdnichnyhEmailKampanij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/6-idej-prazdnichnyh-email-kampanij"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOtkazyNaSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/otkazy-na-sajte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPovysitEffektivnostAvtomatizatsiiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/povysit-effektivnost-avtomatizatsii-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIdeiEmailRassylokDlyaChernojPyatnitsy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/idei-email-rassylok-dlya-chernoj-pyatnitsy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTranzakcionnyePisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/tranzakcionnye-pisma"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOtdelyProdazhAvtomatizatsiyaMarketingaPovysheniePribyli = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/otdely-prodazh-avtomatizatsiya-marketinga-povyshenie-pribyli"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostScrumProductOwnerCzIii = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/scrum-product-owner-cz-iii"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10PodcastowDlaMarketerow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/10-podcastow-dla-marketerow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10LajfhakovAvtomatizatsiiEmailMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/10-lajfhakov-avtomatizatsii-email-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJustLandedLandingPagesWordpress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/just-landed-landing-pages-wordpress"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakProdvigatVebinary = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-prodvigat-vebinary"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8GlavnyhOshibokAvtomatizatsiyaMarket = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/8-glavnyh-oshibok-avtomatizatsiya-market"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHolidayMarketingCampaignsGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/holiday-marketing-campaigns-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEffectiveTacticsEarningHighQualityLinks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/effective-tactics-earning-high-quality-links"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakTworzycSkuteczneNewsletteryWewnetrzne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-tworzyc-skuteczne-newslettery-wewnetrzne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseGdprPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-gdpr-plan"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCopywritingMistakesAffectReputation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/copywriting-mistakes-affect-reputation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10NajlepszychKsiazekOWywieraniuWplywu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/10-najlepszych-ksiazek-o-wywieraniu-wplywu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6RulesRecoveringLostSalesAbandonedCartEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-rules-recovering-lost-sales-abandoned-cart-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5BrandsEcommerceStrategiesRight = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-brands-ecommerce-strategies-right"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostColdEmailNurtureBuildMomentum = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cold-email-nurture-build-momentum"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAppStoreOptimizationCommonPitfalls = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/app-store-optimization-common-pitfalls"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3BezpieczneSposobyPrzekazywaniaHasla = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/3-bezpieczne-sposoby-przekazywania-hasla"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFutureContentMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/future-content-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostScrumProductOwnerCzI = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/scrum-product-owner-cz-i"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTweaksBoostEcommerceRoi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/tweaks-boost-ecommerce-roi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAskCustomersQuestionsGetFeedback = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ask-customers-questions-get-feedback"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBoostBrandsSocialSellingEmployeeAdvocacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/boost-brands-social-selling-employee-advocacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9WaysSabotageOutreach = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/9-ways-sabotage-outreach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnalitikaNaLandingah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/analitika-na-landingah"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKonversiyaLendinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/konversiya-lendinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnkietaItCzyIdziemyWDobraStroneCz2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ankieta-it-czy-idziemy-w-dobra-strone-cz-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProductManagerWZwinnychFirmachProduktowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/product-manager-w-zwinnych-firmach-produktowych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSegmentaciyaPoNastraivaemymPolyam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/segmentaciya-po-nastraivaemym-polyam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSegmentaciyaBazyPodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/segmentaciya-bazy-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTopQuestionsGdprEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-questions-gdpr-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMoreDoneLessEffort = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/more-done-less-effort"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEffectiveWhiteboardVideosProduction = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/effective-whiteboard-videos-production"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWelcomeSeriiVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/welcome-serii-v-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGdprEmails6LessonsCopywritersInbox = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/gdpr-emails-6-lessons-copywriters-inbox"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostExpertApprovedTemplatesAvailableMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/expert-approved-templates-available-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateTimeSavingEmailMarketingSchedule = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-time-saving-email-marketing-schedule"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDoesSeoMatter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/does-seo-matter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKachestvaEmailMarketologa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kachestva-email-marketologa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAgileWGetresponseTheGoodTheBadAndTheUglyMeetings = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/agile-w-getresponse-the-good-the-bad-and-the-ugly-meetings"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWritingEmailCopyAttractEngageConvert = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/writing-email-copy-attract-engage-convert"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCrmKalendar = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/crm-kalendar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBeginnersGuideGenZ = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/beginners-guide-gen-z"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRunInstagramAdCampaignsOnBudget = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/run-instagram-ad-campaigns-on-budget"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBroshennayaKorzina = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/broshennaya-korzina"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCheckReviseSeoStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/check-revise-seo-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReglamentPoZashhitePersonalnykhDannykh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/reglament-po-zashhite-personalnykh-dannykh"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateProductPagesConvert = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-product-pages-convert"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZorganizowacFirmowegoSlacka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zorganizowac-firmowego-slacka"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPokazateliEffektivnostiInternetMagazina = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pokazateli-effektivnosti-internet-magazina"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7WaysImagesImproveEmailCampaignConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-ways-images-improve-email-campaign-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDontGetFooled7EcommerceMetrics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/dont-get-fooled-7-ecommerce-metrics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRazrabotkaProgrammyLoyalnosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/razrabotka-programmy-loyalnosti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMistakesUsingWebinarsContentOffers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mistakes-using-webinars-content-offers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUderzhanieKlientovIEmeylRassylka = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/uderzhanie-klientov-i-emeyl-rassylka"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLocalSeoSuccessBeyondSnackPack = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/local-seo-success-beyond-snack-pack"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHrCommunicationToolGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/hr-communication-tool-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCzyIdziemyWDobraStrone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/czy-idziemy-w-dobra-strone"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5ScenarievAvtomatizaciiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/5-scenariev-avtomatizacii-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPriglasiteDruzeyStanteSertificirovannymMarketologom = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/priglasite-druzey-stante-sertificirovannym-marketologom"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLoyaltyRewardsProgramCustomersLove = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/loyalty-rewards-program-customers-love"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCroLiveChatChatbotsOptimizationTips = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cro-live-chat-chatbots-optimization-tips"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGrowthHacking10BystryhPriemovIInstrumentov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/growth-hacking-10-bystryh-priemov-i-instrumentov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTrackAffiliateMarketingCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/track-affiliate-marketing-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationWorkflowsToSkyrocketEcommerceConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-automation-workflows-to-skyrocket-ecommerce-conversions",
"de": "https://www.getresponse.com/de/blog/marketing-automation-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTop10KnigProBiznes = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/top-10-knig-pro-biznes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmartCallBackCzyliPomyslNaWyzszaKonwersje = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/smart-call-back-czyli-pomysl-na-wyzsza-konwersje"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhyRetentionEmailsAreEquallyImportantAsSalesEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/why-retention-emails-are-equally-important-as-sales-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVebinaryIInboundMarketing = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/vebinary-i-inbound-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPokazateliEffektivnostiEmailRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pokazateli-effektivnosti-email-rassylki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTriggeryEmociy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/triggery-emociy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAbTestirovanie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/ab-testirovanie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAktivirovatKlienta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/aktivirovat-klienta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostECommerceInsightsRodoCzyJestSieCzegoBacVideo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/e-commerce-insights-rodo-czy-jest-sie-czego-bac-video"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOCoChodziZTymAgileCz2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/o-co-chodzi-z-tym-agile-cz-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUvelichenieProdazhVBiznese = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/uvelichenie-prodazh-v-biznese"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakWykluczacKanalyDlaDzieciZKampaniiWideoIDisplay = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-wykluczac-kanaly-dla-dzieci-z-kampanii-wideo-i-display"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRuleOfOnePowerfulEmailCopy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/rule-of-one-powerful-email-copy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPrivlechVovlechIUderzhatStudentov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/privlech-vovlech-i-uderzhat-studentov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakWykorzystacPotencjalInfluencerowWECommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-wykorzystac-potencjal-influencerow-w-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVebFormaIDopolnitelnyeDannye = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/veb-forma-i-dopolnitelnye-dannye"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMillenialyICentenialy5Sovetov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/millenialy-i-centenialy-5-sovetov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPutKlienta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/put-klienta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostYoutubeMetrics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/youtube-metrics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakWykorzystacTresciDoPoprawyWskaznikaClv = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-wykorzystac-tresci-do-poprawy-wskaznika-clv"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGdeNajtiVdohnovenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/gde-najti-vdohnovenie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOCoChodziZTymAgileCz1 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/o-co-chodzi-z-tym-agile-cz-1"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStorytellingMetaTemplatePowerful = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/storytelling-meta-template-powerful"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20AvtomaticheskihPisemKotoryeVyDolzhnyOtravit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/20-avtomaticheskih-pisem-kotorye-vy-dolzhny-otravit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVideoMarketingIVideoKontent = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/video-marketing-i-video-kontent"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSocialEcommerceMultiplier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/social-ecommerce-multiplier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCzyMrowkojadPrzezyjeSprawdzWAgileZoo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/czy-mrowkojad-przezyje-sprawdz-w-agile-zoo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPochemuAnalizKonkurentovMozhetPrichinitVred = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pochemu-analiz-konkurentov-mozhet-prichinit-vred"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMentalHealthDay = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mental-health-day"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationCustomerJourney = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-automation-customer-journey"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakojGolosVashegoBrenda = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kakoj-golos-vashego-brenda"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTopSeoMarketingTactics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-seo-marketing-tactics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizacijaIUvelichenieObemaBronirovanija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizacija-i-uvelichenie-obema-bronirovanija"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPrivetstvennyePismaDljaNovyhKlientov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/privetstvennye-pisma-dlja-novyh-klientov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMissMarkMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/miss-mark-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMinimalismInEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/minimalism-in-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGrowthHackingSocialMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/growth-hacking-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDostavljaemostPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/dostavljaemost-pisem"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5ShagovKSozdanijuPoleznogoKontenta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/5-shagov-k-sozdaniju-poleznogo-kontenta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCompellingUserGeneratedContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/compelling-user-generated-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingMedicinskihUslug5Sovetov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/marketing-medicinskih-uslug-5-sovetov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6SuccessFactorsEcommerceBusinessStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-success-factors-ecommerce-business-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAdoptingMarketingAutomationChallengesSolutions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/adopting-marketing-automation-challenges-solutions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailsEverySmallBusinessNeeds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/emails-every-small-business-needs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBiznesVJaponii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/biznes-v-japonii"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChecklistaUdanegoDoskonaleniaBacklogu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/checklista-udanego-doskonalenia-backlogu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMobileAppRetargetingStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/mobile-app-retargeting-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingPersonaVsUxPersona = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-persona-vs-ux-persona"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChatbotsTransformingMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/chatbots-transforming-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHello911SocialMediaManager = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/hello-911-social-media-manager"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomersComeBack = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/customers-come-back"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKeepPeopleOnsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/keep-people-onsite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKeepUpEvolvingWorld = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/keep-up-evolving-world"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnboardNewCustomersWelcomeEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/onboard-new-customers-welcome-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6UntappedOpportunitiesInEcommerceThatWillBecomeHugeSoon = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-untapped-opportunities-in-ecommerce-that-will-become-huge-soon"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSocialMediaEtiquetteRules = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/social-media-etiquette-rules"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThreeLinkedinStrategies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/three-linkedin-strategies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFindTimeForEverything = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/find-time-for-everything"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEducationAsAMarketingTool = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/education-as-a-marketing-tool"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChecklistWordpressWebsiteMigration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/checklist-wordpress-website-migration"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFilozofiaMinimalnychZyskowPoprawProduktywnoscMalymiKrokami = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/filozofia-minimalnych-zyskow-popraw-produktywnosc-malymi-krokami"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailSubjectLinesSpecificShort = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-subject-lines-specific-short"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMicroMoments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/micro-moments"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreatingMagneticBlog = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/creating-magnetic-blog"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadScoringLessons = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-scoring-lessons"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNightmaresGraphicDesigners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/nightmares-graphic-designers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUsingGoogleAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/using-google-analytics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationGoals = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-automation-goals"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLeadGenerationTacticsSocialMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/lead-generation-tactics-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkedinAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/linkedin-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBusinessesLiveStream = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/businesses-live-stream"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnlineAdvertisingOpportunities = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/online-advertising-opportunities"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWordsDominateDigitalWorld = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/words-dominate-digital-world"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBrandVoice = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/brand-voice"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSocialMediaTakeover = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/social-media-takeover"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmotionInMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/emotion-in-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSegmentirovatSpisokPodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-segmentirovat-spisok-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuyerPersonalities = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/buyer-personalities"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHabitFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/habit-fields"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVideoContentCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/video-content-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSpringListHygiene = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/spring-list-hygiene"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCatchUpUsingMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/catch-up-using-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSocialMediaMistakes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/social-media-mistakes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCopeContentMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cope-content-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRadicalPyschologicalExperiments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/radical-pyschological-experiments"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWebinarPromotionRightWay = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/webinar-promotion-right-way"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFacebookAdsToPromoteYourBlog = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/facebook-ads-to-promote-your-blog"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewSubscriberSequence = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/new-subscriber-sequence"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLego4ScrumGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/lego4scrum-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostClintonEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/clinton-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTransformationOfEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/transformation-of-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostArtOfNegotiation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/art-of-negotiation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVideoBoostAwareness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/video-boost-awareness"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomationInfluencers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/marketing-automation-influencers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostContentMarketingAutomated = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/content-marketing-automated"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNasaSocialMediaTriumphs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/nasa-social-media-triumphs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20ZautomatyzowaneEmaile = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/20-zautomatyzowane-emaile"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUnexpectedBenefitsMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/unexpected-benefits-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCustomerServiceEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/customer-service-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThreeEmailsEveryBloggerNeeds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/three-emails-every-blogger-needs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGrowAudienceNativeAdvertising = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/grow-audience-native-advertising"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAbandoningShoppingCarts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/abandoning-shopping-carts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFloristsBoostOpenClickRates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/florists-boost-open-click-rates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAgileChwytamyWiatrWZagle = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/agile-chwytamy-wiatr-w-zagle"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPowerOfPromotion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/power-of-promotion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreatingViralLinkBait = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/creating-viral-link-bait"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostYoutubeHacks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/youtube-hacks"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStopProcrastinating = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/stop-procrastinating"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostExpendMentalEnergyWisely = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/expend-mental-energy-wisely"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingMyths = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-myths"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUserEngagementAffectsSeo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/user-engagement-affects-seo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInstagramAdCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/instagram-ad-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7SocialMediaMarketingTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-social-media-marketing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTopAppPushNotificationHacksHolidays = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-app-push-notification-hacks-holidays"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateValuableContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-valuable-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThreeLevelsMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/three-levels-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOfficeSettingsAffectProductivity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/office-settings-affect-productivity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5EntrepreneurialSkills = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-entrepreneurial-skills"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZglosicZbiorDanychOsobowychDoRejestracjiGiodo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zglosic-zbior-danych-osobowych-do-rejestracji-giodo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChoosePremiumWordpressTheme = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/choose-premium-wordpress-theme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6LeadScoringTechniques = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-lead-scoring-techniques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowNotContentMarketingCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-not-content-marketing-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUseMarketingAutomationRetargetLeadsHolidaySeason = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-marketing-automation-retarget-leads-holiday-season"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6EasyStepsToDoContentMarketingInECommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-easy-steps-to-do-content-marketing-in-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostClintonTrumpEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/clinton-trump-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5DeadMarketersBringEmailsBackLife = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-dead-marketers-bring-emails-back-life"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost13ToolsProofreadCopyImproveConversion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/13-tools-proofread-copy-improve-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBrandAdvocatesCanHelpBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/brand-advocates-can-help-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBusinessAppsCalledBots = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/business-apps-called-bots"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZintegrowacKampanieEmailowaZKampaniaWSocialMedia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zintegrowac-kampanie-emailowa-z-kampania-w-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSimpleFormulaWritingPerfectBlogPost = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/simple-formula-writing-perfect-blog-post"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThingsRememberStartingYoutubeChannel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/things-remember-starting-youtube-channel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDoingNothingSecretProductivity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/doing-nothing-secret-productivity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8MythBustingFactsBlogging = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/8-myth-busting-facts-blogging"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost11HabitsNeedDevelopBecomeBrilliantEmailMarketer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/11-habits-need-develop-become-brilliant-email-marketer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThreeEasiestWaysGetStartedMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/three-easiest-ways-get-started-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseIJegoZalozycielePrzeznaczyliJuz475000ZlNaWalkeZRakiem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/getresponse-i-jego-zalozyciele-przeznaczyli-juz-475-000-zl-na-walke-z-rakiem"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7MainCharacteristicsShareableContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-main-characteristics-shareable-content"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBoostBrandVisibilityYoutube = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/boost-brand-visibility-youtube"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3PsychologyTricksBoostSocialMediaCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/3-psychology-tricks-boost-social-media-campaign"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBoostB2BSalesViaLinkedin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/boost-b2b-sales-via-linkedin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRozpraszaczeUwagi7AplikacjiI6RozwiazanKtorePozwolaCiJePowstrzymac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/rozpraszacze-uwagi-7-aplikacji-i-6-rozwiazan-ktore-pozwola-ci-je-powstrzymac"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10ProvenWaysImproveFacebookMarketingStrategies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-proven-ways-improve-facebook-marketing-strategies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFourConsumerBehaviorSecretsWillTakeMarketingNextLevel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/four-consumer-behavior-secrets-will-take-marketing-next-level"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseMarketingAutomationOnlineBusinessWebinarRecapRecording = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/getresponse-marketing-automation-online-business-webinar-recap-recording"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakSdelatHoroshijLending5Sovetov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-sdelat-horoshij-lending-5-sovetov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3NewsjackingFailsCanLearn = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/3-newsjacking-fails-can-learn"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizacijaMarketingaDljaSmb = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizacija-marketinga-dlja-smb"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizacijaMarketinga6ShagovVnedrenija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizacija-marketinga-6-shagov-vnedrenija"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizacijaMarketinga15Prichin3 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizacija-marketinga-15-prichin-3"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCraftTheBestBusinessPlanForYourSpecificProductOrService = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-craft-the-best-business-plan-for-your-specific-product-or-service"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizacijaMarketinga15Prichin2 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizacija-marketinga-15-prichin-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAvtomatizacijaMarketinga15Prichin1 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/avtomatizacija-marketinga-15-prichin-1"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPurposeDrivenMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/purpose-driven-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDirectImpactRedirectionsDomainAuthorityWebTraffic = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/direct-impact-redirections-domain-authority-web-traffic"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingAutomation35Facts = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/marketing-automation-35-facts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleAppsLoginHelps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/google-apps-login-helps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakNePopastVSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-ne-popast-v-spam"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostScienceSaysTalk = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/science-says-talk"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOptimizeEmailMarketingGoogleAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/optimize-email-marketing-google-analytics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost18WordsSkyrocketEmailOpenRates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/18-words-skyrocket-email-open-rates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRightToolsCanMakeDataAnalysisEasy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/right-tools-can-make-data-analysis-easy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCzegoPokemonGoMozeNauczycMarketerow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/czego-pokemon-go-moze-nauczyc-marketerow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5ConditionsMarketingAutomationSuccess = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-conditions-marketing-automation-success"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUseLoyaltyProgramBoostNewsletterEngagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-loyalty-program-boost-newsletter-engagement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnnouncementEmailsSuck = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/announcement-emails-suck"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15SkutecznychReklamNaFacebookuPromujaInternetoweBiznesy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/15-skutecznych-reklam-na-facebooku-promuja-internetowe-biznesy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGuideImprovingConversionWordpressEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/guide-improving-conversion-wordpress-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKathPayWebinarRecapGetPersonalMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/kath-pay-webinar-recap-get-personal-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEspsAndEmailDeliverability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/esps-and-email-deliverability"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFundamentalsMarketingAutomationSmes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/fundamentals-marketing-automation-smes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRepelWrongCustomersAttractRightOnes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/repel-wrong-customers-attract-right-ones"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7CsOfEffectiveCommunication = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-cs-of-effective-communication"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDataVisualisationNeedsPartContentStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/data-visualisation-needs-part-content-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSingleImportantEmailMetricResponseRateNewClickRate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/single-important-email-metric-response-rate-new-click-rate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPsychologiczneTrikiStojaceZaDobrzeKonwertujacymiLandingPageami = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/psychologiczne-triki-stojace-za-dobrze-konwertujacymi-landing-pageami"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7AuthorityHackingToolsQuicklyGrowOnlineInfluence = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-authority-hacking-tools-quickly-grow-online-influence"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTakingFirstStepsEmailMarketingAutomationWebinarRecap = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/taking-first-steps-email-marketing-automation-webinar-recap"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTop7MistakesCausingFacebookAdsSink = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-7-mistakes-causing-facebook-ads-sink"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSmartSocialTrendsSmallBussinesses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/smart-social-trends-small-bussinesses"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15FacebookAdsWorkedOnlineBusinesses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/15-facebook-ads-worked-online-businesses"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThreeEmailCustomizationTacticsEcommerceDomination = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/three-email-customization-tactics-ecommerce-domination"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetTwitterShareCountsBack = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/get-twitter-share-counts-back"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMemyWMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/memy-w-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6WaysUseFacebookCreateBetterEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-ways-use-facebook-create-better-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPiecZwyczajowKtorePoprawiaTwojaWydajnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/piec-zwyczajow-ktore-poprawia-twoja-wydajnosc"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIncorporatePodcastsMarketingStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/incorporate-podcasts-marketing-strategy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkBuildingPrzewodnikDlaOstroznych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/link-building-przewodnik-dla-ostroznych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5RetentionEmailsMadeWantClick = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-retention-emails-made-want-click"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNiezbedneElementySklepuInternetowego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/niezbedne-elementy-sklepu-internetowego"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLowCostWaysStartAffiliateProgram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/low-cost-ways-start-affiliate-program"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9RzeczyKtorePowinienesZrobicZanimStworzyszKursOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/9-rzeczy-ktore-powinienes-zrobic-zanim-stworzysz-kurs-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStructureWebinarContentRightWay = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/structure-webinar-content-right-way"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3SecureWaysCommunicatePasswordsMatters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/3-secure-ways-communicate-passwords-matters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10ProvenWaysIncreaseFacebookEngagementRatesMayNotEnough = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-proven-ways-increase-facebook-engagement-rates-may-not-enough"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBoostingEmployeeMorale5HrTips = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/boosting-employee-morale-5-hr-tips"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4EmailsNeedGetPeopleShowingLiveWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/4-emails-need-get-people-showing-live-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9UnbeatableTacticsLaunchingProductUsingSocialMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/9-unbeatable-tactics-launching-product-using-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostComplainingSelfDeceptiveProductivityKiller = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/complaining-self-deceptive-productivity-killer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCanSellAdsEmailNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/can-sell-ads-email-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailCallsActionFiveGuidelinesConversions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-calls-action-five-guidelines-conversions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBringInstagramLoveEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/bring-instagram-love-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBigDatasInfluentialRockStars10MustFollowLeaders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/big-datas-influential-rock-stars-10-must-follow-leaders"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStartFacebookGroupSkyrocketVisibility = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/start-facebook-group-skyrocket-visibility"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4NarzedziaKtoreWypromujaTwojEbook = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/4-narzedzia-ktore-wypromuja-twoj-ebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWorkflowStiflingUnleashingProductivity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/workflow-stifling-unleashing-productivity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProkrastynacjaCzyliZrobicTrzebaSieChce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/prokrastynacja-czyli-zrobic-trzeba-sie-chce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPunchSocialMediaNoise5Steps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/punch-social-media-noise-5-steps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWorkingHomeReallyMakePeopleProductive = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/working-home-really-make-people-productive"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOvercomingLeadGenerationProblemsGrowBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/overcoming-lead-generation-problems-grow-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDontLetMessageGetLostTranslationKeyInsightsLocalization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/dont-let-message-get-lost-translation-key-insights-localization"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3IngredientsHighConvertingConfirmationPagesEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/3-ingredients-high-converting-confirmation-pages-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThreeSimpleWaysImproveSiteSpeed = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/three-simple-ways-improve-site-speed"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNeedManagePersonalReputationOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/need-manage-personal-reputation-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost11ThingsCanMakeVisibleOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/11-things-can-make-visible-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProcrastinationManagementNeedsDoneDontWant = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/procrastination-management-needs-done-dont-want"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5BlackHatSeoPracticesNeedStopNow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-black-hat-seo-practices-need-stop-now"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStronaGlownaInternetuCzyliRedditDlaPoczatkujacych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/strona-glowna-internetu-czyli-reddit-dla-poczatkujacych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailDeliverabilityZFreebie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-deliverability-z-freebie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestTipsDevelopTestWordpressWebsitesResponsiveDesign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-tips-develop-test-wordpress-websites-responsive-design"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSzukacPomyslowKursOnline8SprawdzonychSposobow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/szukac-pomyslow-kurs-online-8-sprawdzonych-sposobow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStopRuiningMorning6UnproductiveHabits = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/stop-ruining-morning-6-unproductive-habits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSeoCzyliJakPoprawicSwojaPozycjeWWyszukiwarkach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/seo-czyli-jak-poprawic-swoja-pozycje-w-wyszukiwarkach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUsePeachMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-peach-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSettingGoalsBlessingThreatProductivity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/setting-goals-blessing-threat-productivity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6ToolsMakeUxWritersDaysBearableTwoWillSurprise = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-tools-make-ux-writers-days-bearable-two-will-surprise"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUseOnlineCalculatorsLeadsHigherEngagementRates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-online-calculators-leads-higher-engagement-rates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuildProfitableNetworkLinkedin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/build-profitable-network-linkedin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10WaysHoneCreativityBetterProductivity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-ways-hone-creativity-better-productivity"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnatomyKillerLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/anatomy-killer-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMeetingsTimeManagementTimeWaste = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/meetings-time-management-time-waste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6HabitsMakeSuccessfulPeopleDifferent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-habits-make-successful-people-different"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZrobicAbyZawszeDotrzymywacTerminow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/zrobic-aby-zawsze-dotrzymywac-terminow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTheContentBehindSuccessfulTwitterCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-content-behind-successful-twitter-campaigns"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5HrInfographicsToStartYourDay = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-hr-infographics-to-start-your-day"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToSaveYourselfAFewHoursEveryWeekWithIfttt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-save-yourself-a-few-hours-every-week-with-ifttt"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToRunAFacebookAdAndBuildYourListFor10ADayPartIi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-run-a-facebook-ad-and-build-your-list-for-10-a-day-part-ii"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNewResearchOnHowToGetMoreFacebookEngagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/new-research-on-how-to-get-more-facebook-engagement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToRunAFacebookAdAndBuildYourListFor10ADayPartI = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-run-a-facebook-ad-and-build-your-list-for-10-a-day-part-i"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostABeginnersGuideToRedditTheFrontPageOfTheInternet = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/a-beginners-guide-to-reddit-the-front-page-of-the-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZnakiTowaroweWMaterialachMarketingowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/znaki-towarowe-w-materialach-marketingowych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTheStateOfEmailMarketingByIndustry = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-state-of-email-marketing-by-industry"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatIsContentCurationAndWhyDoYouNeedIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-is-content-curation-and-why-do-you-need-it"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMyBusinessCardSaysHydraKeeper = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/my-business-card-says-hydra-keeper"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStepStepGuideCreatingWebinarSells = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/step-step-guide-creating-webinar-sells"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLinkowanieAPrawo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/linkowanie-a-prawo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakStworzycSwojPierwszyWebinar = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-swoj-pierwszy-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7CommonLandingPageMistakes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-common-landing-page-mistakes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowSocialMediaCanDestroyYourImage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-social-media-can-destroy-your-image"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6TipsToMakeCustomerServiceManagersLivesABreeze = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-tips-to-make-customer-service-managers-lives-a-breeze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSlowaIFrazyKtoreWpedzaCieZTarapatyDostarczalnoscCz7 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/slowa-i-frazy-ktore-wpedza-cie-z-tarapaty-dostarczalnosc-cz-7"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEuipoIdzieNowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/euipo-idzie-nowe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostContentADostawcyUslugInternetowychDostarczalnoscCzescVi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/content-a-dostawcy-uslug-internetowych-dostarczalnosc-czesc-vi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3ImportantReasonsToSpyOnYourAdwordsCompetitors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/3-important-reasons-to-spy-on-your-adwords-competitors"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7WaysToBeExtraGoodToYourSubscribers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-ways-to-be-extra-good-to-your-subscribers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateYourFirstWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-your-first-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakIspolzovatVebinaryDljaRostaBiznesa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-ispolzovat-vebinary-dlja-rosta-biznesa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCookies = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/cookies"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSocialMediaContentEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/social-media-content-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToDeeplinkAndMeasureYourEmailCampaignsInAMobileFirstEra = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-deeplink-and-measure-your-email-campaigns-in-a-mobile-first-era"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15WaysToGetMoreLeadsFromSocialMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/15-ways-to-get-more-leads-from-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCrossSellingZaPomocaEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/cross-selling-za-pomoca-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhyAndHowToWriteABlogPostSeries = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/why-and-how-to-write-a-blog-post-series"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWideoMarketingSkroconyKursDlaMalychISrednichPrzedsiebiorcow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wideo-marketing-skrocony-kurs-dla-malych-i-srednich-przedsiebiorcow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15WaysToGetMoreBusinessFromLinkedin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/15-ways-to-get-more-business-from-linkedin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8TipsOnWritingContentThatPeopleWantToReadRightNow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/8-tips-on-writing-content-that-people-want-to-read-right-now"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhyCustomerTestimonialsAreTheUltimateMarketingTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/why-customer-testimonials-are-the-ultimate-marketing-tools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9PravilEmailMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/9-pravil-email-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToAchieveEmailMarketingExcellenceDeckRecording = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-achieve-email-marketing-excellence-deck-recording"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7MarketingPodcastsForAMoreProductiveCommute = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-marketing-podcasts-for-a-more-productive-commute"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3TricksToGetMoreClicksFromImagesInYourEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/3-tricks-to-get-more-clicks-from-images-in-your-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostACrashCourseInVideoMarketingForSmallAndMediumSizedBusinesses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/a-crash-course-in-video-marketing-for-small-and-medium-sized-businesses"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVoronkaEmailMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/voronka-email-marketinga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToReachYourAudienceWithTheNewAdwordsCustomerMatch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-reach-your-audience-with-the-new-adwords-customer-match"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakTworzycReklamyNaFacebookuKtorychTwoiFaniNieZnienawidza = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-tworzyc-reklamy-na-facebooku-ktorych-twoi-fani-nie-znienawidza"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMaszBazeAdresowEmailJestesAdministratoremDanychOsobowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/masz-baze-adresow-email-jestes-administratorem-danych-osobowych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostONeuromarketinguIKonwersjiWywiadZTimemAshem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/o-neuromarketingu-i-konwersji-wywiad-z-timem-ashem"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToGrowYourGetresponseListUsingQuizzes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-grow-your-getresponse-list-using-quizzes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4SocialnyeSetiKotoryeVamNuzhny = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/4-socialnye-seti-kotorye-vam-nuzhny"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnalizaDzialalnosciKonkurencji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/analiza-dzialalnosci-konkurencji"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPochemuEmailInstrumentNomerOdinPoUderzhaniuKlientov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pochemu-email-instrument-nomer-odin-po-uderzhaniu-klientov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10PointChecklistForOptimizingYourGoogleMyBusinessSite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-point-checklist-for-optimizing-your-google-my-business-site"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOnNeuromarketingAndConversionsInterviewWithTimAshWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/on-neuromarketing-and-conversions-interview-with-tim-ash-webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10OptInFormAbSplittestsWithResults = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-opt-in-form-ab-splittests-with-results"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7StrategiesToGiveYourEmailCampaignAHumanTouch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-strategies-to-give-your-email-campaign-a-human-touch"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10StepsToMakingSureYourEcourseIsASmashingSuccess = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-steps-to-making-sure-your-ecourse-is-a-smashing-success"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTheAbcsOfInstagram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-abcs-of-instagram"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKakReaktivirovatPodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/kak-reaktivirovat-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3SposobaOtblagodaritVashihPodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/3-sposoba-otblagodarit-vashih-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPochemuPodpischikiOtpisyvajutsja = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/pochemu-podpischiki-otpisyvajutsja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMakingYourEmailCouponsWorkHarder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/making-your-email-coupons-work-harder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCraftingDifferentiationHowToSeparateYourselfFromTheCompetition = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/crafting-differentiation-how-to-separate-yourself-from-the-competition"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZgodaNaOtrzymywanieEmailiCzySubskrybentUdzielaJejRazNaZawsze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/zgoda-na-otrzymywanie-emaili-czy-subskrybent-udziela-jej-raz-na-zawsze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJestesMarkaWszystkoCoPowinienesWiedziecOPersonalBrandingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jestes-marka-wszystko-co-powinienes-wiedziec-o-personal-brandingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostVremjaOtpravkiIOtkryvaemostRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/vremja-otpravki-i-otkryvaemost-rassylki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToGuestBlogForBusinessIn10Steps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-guest-blog-for-business-in-10-steps"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOptymalizacjaCzasuWysylkiZwiekszyOtwieralnoscTwoichWiadomosciO23 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/optymalizacja-czasu-wysylki-zwiekszy-otwieralnosc-twoich-wiadomosci-o-23"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingowyProgramLojalnosciowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/email-marketingowy-program-lojalnosciowy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToWriteMoreBlogPostsMoreEasilyInLessTime = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-write-more-blog-posts-more-easily-in-less-time"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakWykorzystacPeriscopeWCelachBiznesowych20NiezbednychPorad = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-wykorzystac-periscope-w-celach-biznesowych-20-niezbednych-porad"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9PravilSocialnyhSetej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/9-pravil-socialnyh-setej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7SposobovNapisatHoroshijZagolovok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/7-sposobov-napisat-horoshij-zagolovok"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailNajskuteczniejszeNarzedzieRetencyjne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/email-najskuteczniejsze-narzedzie-retencyjne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSignItRightTipsToCreateAnEffectiveEmailSignature = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/sign-it-right-tips-to-create-an-effective-email-signature"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPodtverzhdeniePodpiskiNaRassylku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/podtverzhdenie-podpiski-na-rassylku"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6PowodowDlaKtorychTransparentnoscWplywaNaZaangazowanieOdbiorcow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/6-powodow-dla-ktorych-transparentnosc-wplywa-na-zaangazowanie-odbiorcow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost40SposobovUvelichitSpisokEmailRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/40-sposobov-uvelichit-spisok-email-rassylki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTheHiddenPowerOfTheSurveyEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-hidden-power-of-the-survey-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhatHaveYouDoneForMeLatelyWhySubscribersUnsubscribe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/what-have-you-done-for-me-lately-why-subscribers-unsubscribe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakoscIKwestiePrawneWBudowaniuListyMailingowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jakosc-i-kwestie-prawne-w-budowaniu-listy-mailingowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9KreativnyhIdejTestirovanijaEmail = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/9-kreativnyh-idej-testirovanija-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9SocialMediaEtiquetteRulesYouNeedToStopBreaking = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/9-social-media-etiquette-rules-you-need-to-stop-breaking"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateAFathersDayEmailMarketingCampaignThatRocks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-a-fathers-day-email-marketing-campaign-that-rocks"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProjektowanieNewsletteraOCoChodziZAboveTheFold = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/projektowanie-newslettera-o-co-chodzi-z-above-the-fold"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakPersonyPomagajaWAutomatyzacjiMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-persony-pomagaja-w-automatyzacji-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostNajskuteczniejszeSposobyReaktywacjiKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/najskuteczniejsze-sposoby-reaktywacji-klientow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCzySwiadomieWykorzystujeszDaneWDzialaniachMarketingowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/czy-swiadomie-wykorzystujesz-dane-w-dzialaniach-marketingowych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10SimpleActionsToMakeYourDiscoveryCallsLeadToSales = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-simple-actions-to-make-your-discovery-calls-lead-to-sales"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3GreatWaysToRewardYourSocialFollowers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/3-great-ways-to-reward-your-social-followers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJeffektivnostEmailRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/jeffektivnost-email-rassylki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowEmailMarketingAndSeoGoHandInHandFreebiemonday = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-email-marketing-and-seo-go-hand-in-hand-freebiemonday"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9KwestiiKtoreWartoRozwazycPrzedUruchomieniemKolejnejStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/9-kwestii-ktore-warto-rozwazyc-przed-uruchomieniem-kolejnej-strony-internetowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10SimpleActionsToMakeYourOptInConvertLikeCrazy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-simple-actions-to-make-your-opt-in-convert-like-crazy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10ChallengesToTackleWhileOptimizingAnECommerceWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-challenges-to-tackle-while-optimizing-an-e-commerce-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOgraniczenieDostepnosciTowaruSposobNaZaangazowanieSubskrybenta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ograniczenie-dostepnosci-towaru-sposob-na-zaangazowanie-subskrybenta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost17AffiliateMarketingTwitterFeedsYouShouldFollow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/17-affiliate-marketing-twitter-feeds-you-should-follow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRukovodstvoPoVerstkePisemVHtml = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/rukovodstvo-po-verstke-pisem-v-html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20WaysToRepurposeABlogPost = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/20-ways-to-repurpose-a-blog-post"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9ExcusesThatKeepYouFromGettingAPaidClient = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/9-excuses-that-keep-you-from-getting-a-paid-client"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReaktivacijaPodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/reaktivacija-podpischikov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost9SocialMediaRulesYouShouldBreak = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/9-social-media-rules-you-should-break"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStrukturaTeksta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/struktura-teksta"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost12BledowPrzezKtoreTraciszKlientowISposobyIchEliminacji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/12-bledow-przez-ktore-tracisz-klientow-i-sposoby-ich-eliminacji"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEverythingYouNeedToKnowToSpyOnYourCompetition = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/everything-you-need-to-know-to-spy-on-your-competition"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostA20PointChecklistToMakingGuestBloggingABigSuccess = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/a-20-point-checklist-to-making-guest-blogging-a-big-success"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMarketingMalogoBiznesa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/marketing-malogo-biznesa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostExclusiveInterviewWithJoannaWiebeFromCopyhackers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/exclusive-interview-with-joanna-wiebe-from-copyhackers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10KsiazekPoswieconychDazeniomDoSukcesuPozycjeDedykowanePrzedsiebiorcom = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/10-ksiazek-poswieconych-dazeniom-do-sukcesu-pozycje-dedykowane-przedsiebiorcom"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTrendyEmailMarketinga2015 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/blog/trendy-email-marketinga-2015"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost12MistakesScareCustomersAwayUndo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/12-mistakes-scare-customers-away-undo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4ElementyOKtorychMusiszPamietacZakladajacESklep = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/4-elementy-o-ktorych-musisz-pamietac-zakladajac-e-sklep"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20PointChecklistMakeBlogPostsFeelLikeNotesFriend = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/20-point-checklist-make-blog-posts-feel-like-notes-friend"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakPodnosicKonwersjePomocaEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-podnosic-konwersje-pomoca-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRiseMobileMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/rise-mobile-marketing-automation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20PointChecklistOptimizeBlogLeadGeneration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/20-point-checklist-optimize-blog-lead-generation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBuildBrandYoutube = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/build-brand-youtube"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTop10BooksPersuasionEverySolopreneurRead = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-10-books-persuasion-every-solopreneur-read"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15SposobowWykorzystanieFacebookaRozbudowaniaListyMailingowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/15-sposobow-wykorzystanie-facebooka-rozbudowania-listy-mailingowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTop10BooksSuccessMindsetEverySolopreneurRead = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-10-books-success-mindset-every-solopreneur-read"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10NajlepszychNarzedziEdycjiTekstuBlogerow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/10-najlepszych-narzedzi-edycji-tekstu-blogerow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailListBuildingNemesisChurnRate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-list-building-nemesis-churn-rate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost15WaysBuildEmailListFacebook = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/15-ways-build-email-list-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6WaysPromoteContentLinkedin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-ways-promote-content-linkedin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPreheaderTextNeglectedPartEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/preheader-text-neglected-part-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestPracticesRecoveringSocialMediaMistake = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-practices-recovering-social-media-mistake"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOftenOverlookedImportantEmailNavigationBar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/often-overlooked-important-email-navigation-bar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStillSendPlainTextVersionsEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/still-send-plain-text-versions-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostFirmowyPodcastKolejnyElementStrategiiContentMarketingowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/firmowy-podcast-kolejny-element-strategii-content-marketingowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostContentMarketingMondayUsingCmB2BB2C = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/content-marketing-monday-using-cm-b2b-b2c"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZostacMistrzemPinterestaPoradyProfesjonalistow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zostac-mistrzem-pinteresta-porady-profesjonalistow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostContentMarketingMondayMoiRoiContentMarketingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/content-marketing-monday-moi-roi-content-marketing-funnel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost30ThingsFacebookSkyrocketBusinessNewYear = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/30-things-facebook-skyrocket-business-new-year"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakZaprojektowacSkutecznaMobilnaStroneProduktowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-zaprojektowac-skuteczna-mobilna-strone-produktowa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost21EmailMarketingTipsCoaches = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/21-email-marketing-tips-coaches"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMovemberZapuscicBujneMarketingoweWasy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/movember-zapuscic-bujne-marketingowe-wasy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSkutecznaStronaProduktowa5Krokach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/skuteczna-strona-produktowa-5-krokach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost41QuickSocialMediaMarketingTipsEverySmallBusinessOwnerKnow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/41-quick-social-media-marketing-tips-every-small-business-owner-know"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost8RadZakresuUxPopraweKonwersjiTwojejStronyProduktowejInfografika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/8-rad-zakresu-ux-poprawe-konwersji-twojej-strony-produktowej-infografika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMovemberCampaignsGrowSuccessfulMarketingStache = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/movember-campaigns-grow-successful-marketing-stache"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHandleOops5TypesEmailMistakesFix = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/handle-oops-5-types-email-mistakes-fix"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSetGoalGoogleAnalyticsImportantThingYoullMonth = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/set-goal-google-analytics-important-thing-youll-month"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6SposobowPozyskanieNajlepszychReferencjiKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/6-sposobow-pozyskanie-najlepszych-referencji-klientow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSlowaKluczoweDlugiegoOgonaSaSeoWageZlota = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/slowa-kluczowe-dlugiego-ogona-sa-seo-wage-zlota"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakTworzycDobryKontentKtoryZachwyciTwoichOdbiorcow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-tworzyc-dobry-kontent-ktory-zachwyci-twoich-odbiorcow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6EasyStepsCreateBusinessBloggingActionPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-easy-steps-create-business-blogging-action-plan"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGmailMowiOstroznieSymbolamiUnicode = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/gmail-mowi-ostroznie-symbolami-unicode"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostLongTailKeywordsSeoGold = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/long-tail-keywords-seo-gold"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProsConsIncludingNameFieldOptForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/pros-cons-including-name-field-opt-forms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost3NajbardziejEfektywneWysylkiMailingoweKtorychMarketerzyZapominaja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/3-najbardziej-efektywne-wysylki-mailingowe-ktorych-marketerzy-zapominaja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStrategiesIncreaseCustomerLoyaltyInfographic = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/strategies-increase-customer-loyalty-infographic"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateSimpleAutoresponderSeriesKeepLeadsHot = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-simple-autoresponder-series-keep-leads-hot"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakSkutecznieZbieracLeadyZaPomocaLandingPagesIInicjowacPierwszyKontaktSprzedazowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-skutecznie-zbierac-leady-za-pomoca-landing-pages-i-inicjowac-pierwszy-kontakt-sprzedazowy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPowinienesBudowacSilneRelacjeBlogerami = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/powinienes-budowac-silne-relacje-blogerami"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSposobWykorzystacTagiOpenGraphZwiekszycRuchStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/sposob-wykorzystac-tagi-open-graph-zwiekszyc-ruch-stronie-internetowej"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10WaysReduceBounceRateWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-ways-reduce-bounce-rate-website"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMaking10SillyBloggingMistakes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/making-10-silly-blogging-mistakes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingVips = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/email-marketing-vips"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostMaking15MistakesEbookProject = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/making-15-mistakes-ebook-project"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSposobZwiekszycLiczbeKlikniec30StosujacOpisyRozszerzoneRichSnippets = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/sposob-zwiekszyc-liczbe-klikniec-30-stosujac-opisy-rozszerzone-rich-snippets"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTwojOsobistySlownikSukces = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/twoj-osobisty-slownik-sukces"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostProfesjonalnaKorespondencjaKlientemInfografika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/profesjonalna-korespondencja-klientem-infografika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostRyzykowneBanalyCzymZapominaszPiszacTekst = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ryzykowne-banaly-czym-zapominasz-piszac-tekst"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostQuestionsAskUnlockSubscribersHearts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/questions-ask-unlock-subscribers-hearts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSkutecznieWykorzystacGoogleAnalytics = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/skutecznie-wykorzystac-google-analytics"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostStosowacVideoWiadomosciachEmailInfogafika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/stosowac-video-wiadomosciach-email-infogafika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUseGoogleAnalyticsEffectively = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-google-analytics-effectively"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20WaysSkyrocketReaderEngagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/20-ways-skyrocket-reader-engagement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost20WaysSuperchargeNextProductLaunch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/20-ways-supercharge-next-product-launch"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKonkursyFacebookuPozyskiwacDaneKlientach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/konkursy-facebooku-pozyskiwac-dane-klientach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleFirmaDajSieOdnalezcOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/google-firma-daj-sie-odnalezc-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTop38BooksEveryOnlineEntrepreneurMustRead = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-38-books-every-online-entrepreneur-must-read"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWstrzelSieWOdpowiedniMomentDziekiGoogleTrends = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wstrzel-sie-w-odpowiedni-moment-dzieki-google-trends"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGoogleBusinessGettingFoundOnlineEase = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/google-business-getting-found-online-ease"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostManageOnlineReputationSocialMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/manage-online-reputation-social-media"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUseTypographyMarketingDesign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-typography-marketing-design"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCanadasNewAntiSpamActNeedKnow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/canadas-new-anti-spam-act-need-know"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostOCzymWartoPamietacZakladajacKanalYoutube = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/o-czym-warto-pamietac-zakladajac-kanal-youtube"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5MinuteGuideLinkedinShowcasePages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/5-minute-guide-linkedin-showcase-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCreateHighConvertingWebinarsBuildListSell = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/create-high-converting-webinars-build-list-sell"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCopywriting101CreateWordsPersuadeSell = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/copywriting-101-create-words-persuade-sell"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostCzyWieszKimKlienciKtorzyBedaOdwiedzacTwojLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/czy-wiesz-kim-klienci-ktorzy-beda-odwiedzac-twoj-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostReactivatingSubscribersStepStepGuide = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/reactivating-subscribers-step-step-guide"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBestMarketingBlogsAddRssFeed = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/best-marketing-blogs-add-rss-feed"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGuestPosting101ReceiveMaxRoiPromotion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/guest-posting-101-receive-max-roi-promotion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakReagowacNaNegatywneKomentarzeWSocialMedia5Zasad = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-reagowac-na-negatywne-komentarze-w-social-media-5-zasad"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostTop15OnlineDirectoriesBusinessNeedsListed = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/top-15-online-directories-business-needs-listed"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWriteEbookSellsBuildsEmailListPt4 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/write-ebook-sells-builds-email-list-pt-4"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostColorPsychologyInNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/color-psychology-in-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWriteEbookSellsBuildsEmailListPt3 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/write-ebook-sells-builds-email-list-pt-3"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakTraficDoMlodychKonsumentowZaPomocaSnapchata = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-trafic-do-mlodych-konsumentow-za-pomoca-snapchata"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWriteEbookSellsBuildsEmailListPt2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/write-ebook-sells-builds-email-list-pt-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost4FreeToolsCreateInfographicsOnlineMarketersToolKit = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/4-free-tools-create-infographics-online-marketers-tool-kit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKnow3EssentialWritingFormulas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/know-3-essential-writing-formulas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWriteEbookSellsBuildsEmailListPart1 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/write-ebook-sells-builds-email-list-part-1"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostJakStworzycInfografikeBezPomocyGrafika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/jak-stworzyc-infografike-bez-pomocy-grafika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInactiveEmailListReactivateFacebookCustomAudiences = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/inactive-email-list-reactivate-facebook-custom-audiences"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmaileTransakcyjneSposobemNaZdobycieSercKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/emaile-transakcyjne-sposobem-na-zdobycie-serc-klientow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIncreaseEngagementUsingRatingsReviewsInfographic = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/increase-engagement-using-ratings-reviews-infographic"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGrywalizacjaWMalejFirmieCzyZadziala = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/grywalizacja-w-malej-firmie-czy-zadziala"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost7TipsOptimizeLandingPageSearchEnginesSeo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/7-tips-optimize-landing-page-search-engines-seo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5ZmianNaFacebookuKtorychNiePowinienesPrzeoczyc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/5-zmian-na-facebooku-ktorych-nie-powinienes-przeoczyc"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToPromoteYourBusinessOnLinkedin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-promote-your-business-on-linkedin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostChooseBest4VideoHostingSolutionsBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/choose-best-4-video-hosting-solutions-business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEmailMarketingWDzialaniuStartUpu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/email-marketing-w-dzialaniu-start-upu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUseEmailAutoresponderInfographic = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/use-email-autoresponder-infographic"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostZestawNarzedziOnlineMarketeraCz2DarmoweNarzedziaDlaSocialMediahtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/zestaw-narzedzi-online-marketera-cz-2-darmowe-narzedzia-dla-social-mediahtml"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPersonalVsProfessionalPt2SuccessfulBrandsCommunicateFacebook = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/personal-vs-professional-pt-2-successful-brands-communicate-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAlfabetOnlineMarketeraCz2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/alfabet-online-marketera-cz-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost6TedTalksEmailMarketers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/6-ted-talks-email-marketers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSkadBracDarmoweZdjeciaNaBloga = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/skad-brac-darmowe-zdjecia-na-bloga"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10BooksJumpstartEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/10-books-jumpstart-email-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostEzestawNarzedziOnlineMarketeraCz1DarmoweEdytoryGraficzne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/ezestaw-narzedzi-online-marketera-cz-1-darmowe-edytory-graficzne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostBloggingTipsFindSweetSpotPersonalProfessionalCommunication = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/blogging-tips-find-sweet-spot-personal-professional-communication"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPromocjaSwiatecznaWESklepachInfografika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/promocja-swiateczna-w-e-sklepach-infografika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSkutecznePodejscieDoRezygnacjiZSubskrypcji10Porad = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/skuteczne-podejscie-do-rezygnacji-z-subskrypcji-10-porad"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToCreateAnIdealReaderPersonaForYourSmallBusinessBlog = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-create-an-ideal-reader-persona-for-your-small-business-blog"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostACloserLookAtResponsiveEmailDesign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/a-closer-look-at-responsive-email-design"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWhyYouShouldntBeAfraidOfTheNewGmailTabs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/why-you-shouldnt-be-afraid-of-the-new-gmail-tabs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostHowToSetUpGoogleAnalyticsGoalsToTrackMailingListSignups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/how-to-set-up-google-analytics-goals-to-track-mailing-list-signups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostIdeasFromTheInboxToGifOrNotToGif = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/ideas-from-the-inbox-to-gif-or-not-to-gif"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost5RzeczyKtoreMusiszWiedziecOAdresachPulapkach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/5-rzeczy-ktore-musisz-wiedziec-o-adresach-pulapkach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostThe4PsPersuasiveEmailCopywriting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/the-4-ps-persuasive-email-copywriting"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSzkolenieJakZaczacZarabiacWInternecieRelacja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/szkolenie-jak-zaczac-zarabiac-w-internecie-relacja"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSocialSharingBoostsEmailCtr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/social-sharing-boosts-email-ctr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPost10WskazowekJakDacSieOdnalezcPrzyszlymOdbiorcom = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/10-wskazowek-jak-dac-sie-odnalezc-przyszlym-odbiorcom"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAplikacjaGetresponseNaIosIAndroida = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/aplikacja-getresponse-na-ios-i-androida"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDlaczegoEMailMarketingBoKlientJestTegoWart = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dlaczego-e-mail-marketing-bo-klient-jest-tego-wart"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostPodziekowanieZaSubskrypcjeKtoreSprzedaje = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/podziekowanie-za-subskrypcje-ktore-sprzedaje"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAMozeGifZamiastJpg = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/a-moze-gif-zamiast-jpg"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostAnimatedGifsInEmailsWhyNot = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/blog/animated-gifs-in-emails-why-not"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGetresponseDlaWosp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/getresponse-dla-wosp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostSwiateczneZyczeniaWysylamyJednakEmailemISmsEmInfografika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/swiateczne-zyczenia-wysylamy-jednak-emailem-i-sms-em-infografika"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostWirtualnemediaPlWsrodKlientowGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/wirtualnemedia-pl-wsrod-klientow-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostInnowacjeNaENnovation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/innowacje-na-e-nnovation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostDodawanieFormularzyNaFacebookuNajczestszePytania = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/dodawanie-formularzy-na-facebooku-najczestsze-pytania"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostGadajacaKartkaSwiatecznaOdMikolajaISpolki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/gadajaca-kartka-swiateczna-od-mikolaja-i-spolki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostUzywajPoprawnejFormyImieniaZwracajacSieDoOdbiorcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/uzywaj-poprawnej-formy-imienia-zwracajac-sie-do-odbiorcy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteBlogPostKrotkaOpowiescOEmailMarketingu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/blog/krotka-opowiesc-o-email-marketingu"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostAudytySklepowInternetowychIStronWww = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/audyty-sklepow-internetowych-i-stron-www"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostSocialMediaMarketing = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/social-media-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostStrategicznieSkutecznaKomunikacjaOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/strategicznie-skuteczna-komunikacja-online"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostProjektZgodaAgencjaKreatywna360IStudioGraficzne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/projekt-zgoda-agencja-kreatywna-360-i-studio-graficzne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostPlatformaECommerceStworzonaDoZadanEkstremalnych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/platforma-e-commerce-stworzona-do-zadan-ekstremalnych"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostZbudujZNamiMinimumAwesomeProduct = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/zbuduj-z-nami-minimum-awesome-product"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostMarketingKtoryDziala = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/marketing-ktory-dziala"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostScorise = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/scorise"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostIntegracjeDlaGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/integracje-dla-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostHiEnd360PerformanceMarketing = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/hi-end-360-performance-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostAgencjaInteraktywnaZGdanskaKoloWarszawy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/agencja-interaktywna-z-gdanska-kolo-warszawy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostMarketingSzytyNaMiarePotrzebTwojejMarki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/marketing-szyty-na-miare-potrzeb-twojej-marki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostOdpowiadamyNaWspolczesneWyzwaniaECommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/odpowiadamy-na-wspolczesne-wyzwania-e-commerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostGdzieDigitalMarketingSpotykaDataScience = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/gdzie-digital-marketing-spotyka-data-science"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostSkutecznaIPrzemyslanaPromocjaTwojejFirmy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/skuteczna-i-przemyslana-promocja-twojej-firmy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostPrzyspieszSwojBiznesDziekiPerformanceMarketingowi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/przyspiesz-swoj-biznes-dzieki-performance-marketingowi"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostDigitalowaTransformacjaTwojejMarki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/digitalowa-transformacja-twojej-marki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostGoogleAdsSeoISocialMediaWNajlepszychRekach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/google-ads-seo-i-social-media-w-najlepszych-rekach"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostProjektowanieBudowaOrazRozwojDedykowanegoOprogramowania = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/projektowanie-budowa-oraz-rozwoj-dedykowanego-oprogramowania"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostWpShopSklepyWoocommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/wp-shop-sklepy-woocommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostAutorskiSzablonWiadomosciEMailTwojejMarki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/autorski-szablon-wiadomosci-e-mail-twojej-marki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostSynergiaRozwiazanDlaTwojejFirmy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/synergia-rozwiazan-dla-twojej-firmy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostSkutecznyMarketingWTwoimBiznesie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/skuteczny-marketing-w-twoim-biznesie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteMarketplacePostEMailMarketingToNaszeTerytorium2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/marketplace/e-mail-marketing-to-nasze-terytorium-2"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoMonitorareIProgressiDelMioCorso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-i-progressi-del-mio-corso.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-progreso-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-track-my-course-progress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-kursfortschritt-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-svoj-progress-v-kurse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeReimpostareLaPasswordInGetresponseHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-reimpostare-la-password-in-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sbrosit-parol-v-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-reset-the-password-in-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-restablecer-la-contrasena-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-setzt-man-das-passwort-im-getresponse-hub-zurueck.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCambiareLaLinguaDelMioGetresponseHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-cambiare-la-lingua-del-mio-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-el-idioma-de-mi-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-change-the-language-of-my-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyk-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-meines-getresponse-hubs.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRiceveroUnCertificatoDopoAverCompletatoIlCorso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ricevero-un-certificato-dopo-aver-completato-il-corso.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-un-certificado-al-finalizar-el-curso.html",
"en": "https://www.getresponse.com/help/do-i-get-a-certificate-after-completing-the-course.html",
"pl": "https://www.getresponse.pl/pomoc/czy-po-ukonczeniu-kursu-otrzymam-certyfikat.html",
"de": "https://www.getresponse.com/de/hilfe/bekomme-ich-nach-abschluss-des-kurses-ein-zertifikat.html",
"br": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-poluchit-sertifikat-po-okonchanii-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeRichiedereUnaFatturaInGetresponseHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-richiedere-una-fattura-in-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-solicitar-una-factura-en-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-request-an-invoice-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprosic-o-fakture-w-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fordere-ich-eine-rechnung-in-getresponse-hub-an.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaprosit-schet-fakturu-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoValutareICorsi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-valutare-i-corsi.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-valorar-los-cursos.html",
"en": "https://www.getresponse.com/help/can-i-rate-courses.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kurse-bewerten.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-ocenivat-kursy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnAccountGetresponseHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-account-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-cuenta-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-create-a-getresponse-hub-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalozyc-konto-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-akkaunt-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-getresponse-hub-konto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAccedereAllAccountGetresponseHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-accedere-all-account-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-melde-ich-mich-bei-meinem-getresponse-hub-konto-an.html",
"en": "https://www.getresponse.com/help/how-to-log-in-to-getresponse-hub-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-iniciar-sesion-en-la-cuenta-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-konta-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vojti-v-akkaunt-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieFormatyUrokovDostupnyVKonstruktoreKursov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html",
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLesGensPeuventIlsEvaluerMesCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/les-gens-peuvent-ils-evaluer-mes-cours.html",
"en": "https://www.getresponse.com/help/can-people-rate-my-courses.html",
"it": "https://www.getresponse.com/it/assistenza/le-persone-possono-valutare-i-miei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-ludzie-moga-oceniac-moje-kursy.html",
"es": "https://www.getresponse.com/es/ayuda/puede-la-gente-valorar-mis-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-die-leute-meine-kurse-bewerten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtslezhivatSvojProgressVKurse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-svoj-progress-v-kurse.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-progreso-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-track-my-course-progress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-kursfortschritt-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-i-progressi-del-mio-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiPoluchitSertifikatPoOkonchaniiKursa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-poluchit-sertifikat-po-okonchanii-kursa.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-un-certificado-al-finalizar-el-curso.html",
"en": "https://www.getresponse.com/help/do-i-get-a-certificate-after-completing-the-course.html",
"pl": "https://www.getresponse.pl/pomoc/czy-po-ukonczeniu-kursu-otrzymam-certyfikat.html",
"de": "https://www.getresponse.com/de/hilfe/bekomme-ich-nach-abschluss-des-kurses-ein-zertifikat.html",
"br": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-un-certificato-dopo-aver-completato-il-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaOcenivatKursy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-ocenivat-kursy.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-valorar-los-cursos.html",
"en": "https://www.getresponse.com/help/can-i-rate-courses.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kurse-bewerten.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-valutare-i-corsi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatUslovieUrokNachatIliZavershenVRabochemProcesse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-urok-nachat-ili-zavershen-v-rabochem-processe.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-lektion-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-lesson-started-or-completed-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-leccion-iniciada-o-finalizada-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-lekcja-rozpoczeta-lub-ukonczona-w-cyklu-automation.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-lezione-iniziata-o-completata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-lecon-faite-ou-fini-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReceboUmCertificadoAposAConclusaoDoCurso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-un-certificado-al-finalizar-el-curso.html",
"en": "https://www.getresponse.com/help/do-i-get-a-certificate-after-completing-the-course.html",
"pl": "https://www.getresponse.pl/pomoc/czy-po-ukonczeniu-kursu-otrzymam-certyfikat.html",
"de": "https://www.getresponse.com/de/hilfe/bekomme-ich-nach-abschluss-des-kurses-ein-zertifikat.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-poluchit-sertifikat-po-okonchanii-kursa.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-un-certificato-dopo-aver-completato-il-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAvaliarCursos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-valorar-los-cursos.html",
"en": "https://www.getresponse.com/help/can-i-rate-courses.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kurse-bewerten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-ocenivat-kursy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-valutare-i-corsi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMudarOIdiomaDoMeuGetresponseHub = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-el-idioma-de-mi-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-change-the-language-of-my-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyk-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-meines-getresponse-hubs.html",
"it": "https://www.getresponse.com/it/assistenza/come-cambiare-la-lingua-del-mio-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPoprosicOFaktureWGetresponseHub = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-poprosic-o-fakture-w-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-solicitar-una-factura-en-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-request-an-invoice-in-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fordere-ich-eine-rechnung-in-getresponse-hub-an.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaprosit-schet-fakturu-v-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-richiedere-una-fattura-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheChatInTheWebinarRoomWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-chat-in-the-webinar-room-work.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatIOptimizirovatSvojuStranicuKursa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUpravlyatSvoimiPodpiskamiVGetresponseHub = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVojtiVAkkauntGetresponseHub = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vojti-v-akkaunt-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-melde-ich-mich-bei-meinem-getresponse-hub-konto-an.html",
"en": "https://www.getresponse.com/help/how-to-log-in-to-getresponse-hub-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-iniciar-sesion-en-la-cuenta-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-konta-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-accedere-all-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZaprositSchetFakturuVGetresponseHub = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaprosit-schet-fakturu-v-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-solicitar-una-factura-en-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-request-an-invoice-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprosic-o-fakture-w-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fordere-ich-eine-rechnung-in-getresponse-hub-an.html",
"it": "https://www.getresponse.com/it/assistenza/come-richiedere-una-fattura-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieSpracheMeinesGetresponseHubs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-meines-getresponse-hubs.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-el-idioma-de-mi-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-change-the-language-of-my-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyk-v-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-cambiare-la-lingua-del-mio-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSetztManDasPasswortImGetresponseHubZurueck = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-setzt-man-das-passwort-im-getresponse-hub-zurueck.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sbrosit-parol-v-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-reset-the-password-in-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-restablecer-la-contrasena-en-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-reimpostare-la-password-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchKurseBewerten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kurse-bewerten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-valorar-los-cursos.html",
"en": "https://www.getresponse.com/help/can-i-rate-courses.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-ocenivat-kursy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-valutare-i-corsi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenKursfortschrittVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-kursfortschritt-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-progreso-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-track-my-course-progress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-svoj-progress-v-kurse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-i-progressi-del-mio-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFordereIchEineRechnungInGetresponseHubAn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fordere-ich-eine-rechnung-in-getresponse-hub-an.html",
"es": "https://www.getresponse.com/es/ayuda/como-solicitar-una-factura-en-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-request-an-invoice-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprosic-o-fakture-w-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaprosit-schet-fakturu-v-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-richiedere-una-fattura-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRestablecerLaContrasenaEnGetresponseHub = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-restablecer-la-contrasena-en-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sbrosit-parol-v-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-reset-the-password-in-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-setzt-man-das-passwort-im-getresponse-hub-zurueck.html",
"it": "https://www.getresponse.com/it/assistenza/come-reimpostare-la-password-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBekommeIchNachAbschlussDesKursesEinZertifikat = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bekomme-ich-nach-abschluss-des-kurses-ein-zertifikat.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-un-certificado-al-finalizar-el-curso.html",
"en": "https://www.getresponse.com/help/do-i-get-a-certificate-after-completing-the-course.html",
"pl": "https://www.getresponse.pl/pomoc/czy-po-ukonczeniu-kursu-otrzymam-certyfikat.html",
"br": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-poluchit-sertifikat-po-okonchanii-kursa.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-un-certificato-dopo-aver-completato-il-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinGetresponseHubKonto = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-getresponse-hub-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-cuenta-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-create-a-getresponse-hub-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalozyc-konto-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-akkaunt-v-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoePremiumRassylkiIKakIhSozdat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatProfilAvtora = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatAkkauntVGetresponseHub = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-akkaunt-v-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-cuenta-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-create-a-getresponse-hub-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalozyc-konto-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-getresponse-hub-konto.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSbrositParolVGetresponseHub = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sbrosit-parol-v-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-reset-the-password-in-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-restablecer-la-contrasena-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-setzt-man-das-passwort-im-getresponse-hub-zurueck.html",
"it": "https://www.getresponse.com/it/assistenza/come-reimpostare-la-password-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitYazykVGetresponseHub = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyk-v-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-el-idioma-de-mi-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-change-the-language-of-my-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-meines-getresponse-hubs.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-cambiare-la-lingua-del-mio-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUpravlyatMoimAkkauntomVGetresponseHub = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatUslovieZapisalsyaNaKursVRabochemProcesse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZalogowacSieDoKontaGetresponseHub = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-konta-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-melde-ich-mich-bei-meinem-getresponse-hub-konto-an.html",
"en": "https://www.getresponse.com/help/how-to-log-in-to-getresponse-hub-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-iniciar-sesion-en-la-cuenta-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vojti-v-akkaunt-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-accedere-all-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZalozycKontoGetresponseHub = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zalozyc-konto-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-cuenta-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-create-a-getresponse-hub-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-akkaunt-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-getresponse-hub-konto.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyPoUkonczeniuKursuOtrzymamCertyfikat = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-po-ukonczeniu-kursu-otrzymam-certyfikat.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-un-certificado-al-finalizar-el-curso.html",
"en": "https://www.getresponse.com/help/do-i-get-a-certificate-after-completing-the-course.html",
"de": "https://www.getresponse.com/de/hilfe/bekomme-ich-nach-abschluss-des-kurses-ein-zertifikat.html",
"br": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-poluchit-sertifikat-po-okonchanii-kursa.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-un-certificato-dopo-aver-completato-il-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSolicitarUnaFacturaEnGetresponseHub = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-solicitar-una-factura-en-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-request-an-invoice-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprosic-o-fakture-w-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fordere-ich-eine-rechnung-in-getresponse-hub-an.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaprosit-schet-fakturu-v-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-richiedere-una-fattura-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambiarElIdiomaDeMiGetresponseHub = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-el-idioma-de-mi-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-change-the-language-of-my-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyk-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-meines-getresponse-hubs.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-cambiare-la-lingua-del-mio-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIniciarSesionEnLaCuentaGetresponseHub = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-iniciar-sesion-en-la-cuenta-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-melde-ich-mich-bei-meinem-getresponse-hub-konto-an.html",
"en": "https://www.getresponse.com/help/how-to-log-in-to-getresponse-hub-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-konta-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vojti-v-akkaunt-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-accedere-all-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnaCuentaGetresponseHub = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-cuenta-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-create-a-getresponse-hub-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalozyc-konto-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-akkaunt-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-getresponse-hub-konto.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostObtendreUnCertificadoAlFinalizarElCurso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/obtendre-un-certificado-al-finalizar-el-curso.html",
"en": "https://www.getresponse.com/help/do-i-get-a-certificate-after-completing-the-course.html",
"pl": "https://www.getresponse.pl/pomoc/czy-po-ukonczeniu-kursu-otrzymam-certyfikat.html",
"de": "https://www.getresponse.com/de/hilfe/bekomme-ich-nach-abschluss-des-kurses-ein-zertifikat.html",
"br": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-poluchit-sertifikat-po-okonchanii-kursa.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-un-certificato-dopo-aver-completato-il-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSeguirElProgresoDeMiCurso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-progreso-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-track-my-course-progress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-kursfortschritt-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-svoj-progress-v-kurse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-i-progressi-del-mio-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoValorarLosCursos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-valorar-los-cursos.html",
"en": "https://www.getresponse.com/help/can-i-rate-courses.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kurse-bewerten.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-ocenivat-kursy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-valutare-i-corsi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrazdnovanieZnamenatelnojDaty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html",
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKampaniyaPoVosstanovlenijuSvyazej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToChangeTheLanguageOfMyGetresponseHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-change-the-language-of-my-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-el-idioma-de-mi-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyk-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-meines-getresponse-hubs.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-idioma-do-meu-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-cambiare-la-lingua-del-mio-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToResetThePasswordInGetresponseHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-reset-the-password-in-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sbrosit-parol-v-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-restablecer-la-contrasena-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-setzt-man-das-passwort-im-getresponse-hub-zurueck.html",
"it": "https://www.getresponse.com/it/assistenza/come-reimpostare-la-password-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIRateCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-rate-courses.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-valorar-los-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kurse-bewerten.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-avaliar-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-ocenivat-kursy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-valutare-i-corsi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackMyCourseProgress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-my-course-progress.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-progreso-de-mi-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-kursfortschritt-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-svoj-progress-v-kurse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-i-progressi-del-mio-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmPerfilDeCriador = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieMeldeIchMichBeiMeinemGetresponseHubKontoAn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-melde-ich-mich-bei-meinem-getresponse-hub-konto-an.html",
"en": "https://www.getresponse.com/help/how-to-log-in-to-getresponse-hub-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-iniciar-sesion-en-la-cuenta-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-konta-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vojti-v-akkaunt-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-accedere-all-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsGetresponseHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToRequestAnInvoiceInGetresponseHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-request-an-invoice-in-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-solicitar-una-factura-en-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprosic-o-fakture-w-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fordere-ich-eine-rechnung-in-getresponse-hub-an.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaprosit-schet-fakturu-v-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-richiedere-una-fattura-in-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatUslovieKursNachatIliZavershenVRabochemProcesse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAGetresponseHubAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-getresponse-hub-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-cuenta-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalozyc-konto-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-akkaunt-v-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-getresponse-hub-konto.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoIGetACertificateAfterCompletingTheCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/do-i-get-a-certificate-after-completing-the-course.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-un-certificado-al-finalizar-el-curso.html",
"pl": "https://www.getresponse.pl/pomoc/czy-po-ukonczeniu-kursu-otrzymam-certyfikat.html",
"de": "https://www.getresponse.com/de/hilfe/bekomme-ich-nach-abschluss-des-kurses-ein-zertifikat.html",
"br": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebo-um-certificado-apos-a-conclusao-do-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-poluchit-sertifikat-po-okonchanii-kursa.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-un-certificato-dopo-aver-completato-il-corso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToLogInToGetresponseHubAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-log-in-to-getresponse-hub-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-melde-ich-mich-bei-meinem-getresponse-hub-konto-an.html",
"es": "https://www.getresponse.com/es/ayuda/como-iniciar-sesion-en-la-cuenta-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-konta-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vojti-v-akkaunt-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-accedere-all-account-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentActiverLesAbonnementsAuxCourrielsPourMonCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-abonnements-aux-courriels-pour-mon-cours.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-le-iscrizioni-via-email-per-il-mio-corso.html",
"en": "https://www.getresponse.com/help/how-to-enable-email-subscriptions-for-my-course.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-subskrypcje-email-mojego-kursu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-las-suscripciones-por-correo-electronico-para-mi-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-e-mail-abonnements-fuer-meinen-kurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaConditionLeconFaiteOuFiniDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-lecon-faite-ou-fini-dans-un-flux-de-travail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-lektion-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-lesson-started-or-completed-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-leccion-iniciada-o-finalizada-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-lekcja-rozpoczeta-lub-ukonczona-w-cyklu-automation.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-lezione-iniziata-o-completata-in-un-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-urok-nachat-ili-zavershen-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaConditionCoursFaitOuFiniDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaConditionInscritAUnCoursDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebDesign = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/web-design.html",
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web.html",
"en": "https://www.getresponse.com/help/web-design.html",
"es": "https://www.getresponse.com/es/ayuda/diseno-web.html",
"pl": "https://www.getresponse.pl/pomoc/projektowanie-stron-internetowych.html",
"de": "https://www.getresponse.com/de/hilfe/webgestaltung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLembreteDeProgressoDoCurso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-dei-progressi-del-corso.html",
"en": "https://www.getresponse.com/help/course-progress-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-zum-kursfortschritt.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-l-avancement-du-cours.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-del-progreso-en-el-curso.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-postepach-w-kursie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampanhaDeReengajamento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUpsellECrossSell = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"pt": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"it": "https://www.getresponse.com/it/assistenza/fai-upselling-e-cross-selling.html",
"en": "https://www.getresponse.com/help/upsell-and-cross-sell.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-upsell-et-cross-sell.html",
"de": "https://www.getresponse.com/de/hilfe/upselling-und-cross-selling.html",
"es": "https://www.getresponse.com/es/ayuda/venta-adicional-y-cruzada.html",
"pl": "https://www.getresponse.pl/pomoc/upsell-oraz-cross-sell.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCelebracaoDeConquista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtualizarOsDadosDaFatura = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueAconteceQuandoEuRedirecionoMeusServidoresDeNomesParaAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-redirige-mes-serveurs-de-noms-vers-getresponse.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-redirect-my-nameservers-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekieruje-moje-serwery-nazw-do-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-redirijo-mis-servidores-de-nombre-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-reindirizzo-i-miei-nameserver-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-meine-nameserver-auf-getresponse-umleite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGerenciarMinhasAssinaturasEmGetresponseHub = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoAsNewslettersPremiumEComoAsPodesCriar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGerenciarMeuPerfilEmGetresponseHub = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebsite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/website.html",
"fr": "https://www.getresponse.com/fr/aide/site-web.html",
"en": "https://www.getresponse.com/help/website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/strona-internetowa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebgestaltung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webgestaltung.html",
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web.html",
"en": "https://www.getresponse.com/help/web-design.html",
"es": "https://www.getresponse.com/es/ayuda/diseno-web.html",
"pl": "https://www.getresponse.pl/pomoc/projektowanie-stron-internetowych.html",
"it": "https://www.getresponse.com/it/assistenza/web-design.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebanalyse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webanalyse.html",
"fr": "https://www.getresponse.com/fr/aide/analyse-du-web.html",
"en": "https://www.getresponse.com/help/web-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/analitica-web.html",
"pl": "https://www.getresponse.pl/pomoc/analiza-internetowa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPromouvoirLesCoursSurMaLandingPageOuSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-sur-ma-landing-page-ou-site-web.html",
"en": "https://www.getresponse.com/help/how-to-promote-courses-on-my-landing-page-or-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-mi-landing-page-o-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-auf-meiner-landing-page-oder-website-fuer-kurse-werben.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAktiviereIchEMailAbonnementsFuerMeinenKurs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-e-mail-abonnements-fuer-meinen-kurs.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-le-iscrizioni-via-email-per-il-mio-corso.html",
"en": "https://www.getresponse.com/help/how-to-enable-email-subscriptions-for-my-course.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-abonnements-aux-courriels-pour-mon-cours.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-subskrypcje-email-mojego-kursu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-las-suscripciones-por-correo-electronico-para-mi-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKoennenDieLeuteMeineKurseBewerten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/koennen-die-leute-meine-kurse-bewerten.html",
"fr": "https://www.getresponse.com/fr/aide/les-gens-peuvent-ils-evaluer-mes-cours.html",
"en": "https://www.getresponse.com/help/can-people-rate-my-courses.html",
"it": "https://www.getresponse.com/it/assistenza/le-persone-possono-valutare-i-miei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-ludzie-moga-oceniac-moje-kursy.html",
"es": "https://www.getresponse.com/es/ayuda/puede-la-gente-valorar-mis-cursos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerKiGeneratorFuerWebinarTitelUndWieBenutztManIhn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerKiAssistentUndWieBenutztManIhn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasPassiertWennIchMeineNameserverAufGetresponseUmleite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-meine-nameserver-auf-getresponse-umleite.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-redirige-mes-serveurs-de-noms-vers-getresponse.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-redirect-my-nameservers-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekieruje-moje-serwery-nazw-do-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-redirijo-mis-servidores-de-nombre-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-reindirizzo-i-miei-nameserver-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacSubskrypcjamiWGetresponseHub = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWillkommensmail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/willkommensmail.html",
"fr": "https://www.getresponse.com/fr/aide/courriels-de-bienvenue.html",
"en": "https://www.getresponse.com/help/welcome-emails.html",
"it": "https://www.getresponse.com/it/assistenza/e-mail-di-benvenuto.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-powitalne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebhosting = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webhosting.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-web.html",
"en": "https://www.getresponse.com/help/web-hosting.html",
"es": "https://www.getresponse.com/es/ayuda/alojamiento-web.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-internetowy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebhook = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webhook.html",
"fr": "https://www.getresponse.com/fr/aide/webhook.html",
"en": "https://www.getresponse.com/help/webhook.html",
"es": "https://www.getresponse.com/es/ayuda/webhook.html",
"pl": "https://www.getresponse.pl/pomoc/webhook.html",
"it": "https://www.getresponse.com/it/assistenza/webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacSwoimProfilemWGetresponseHub = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchDieKursstatistikenEinsehen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-kursstatistiken-einsehen.html",
"it": "https://www.getresponse.com/it/assistenza/dove-controllare-le-statistiche-dei-corsi.html",
"en": "https://www.getresponse.com/help/where-to-check-course-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/__trashed-15.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-statistiques-des-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"es": "https://www.getresponse.com/es/ayuda/donde-consultar-las-estadisticas-de-los-cursos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchAufMeinerLandingPageOderWebsiteFuerKurseWerben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-auf-meiner-landing-page-oder-website-fuer-kurse-werben.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-sur-ma-landing-page-ou-site-web.html",
"en": "https://www.getresponse.com/help/how-to-promote-courses-on-my-landing-page-or-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-mi-landing-page-o-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueFormatosDeAulaEstaoDisponiveisNoCriadorDeCursos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindPremiumNewsletterUndWieErstelltManSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaPaginaDeConclusao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-abschluss-seite.html",
"en": "https://www.getresponse.com/help/how-to-create-a-completion-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-pagina-di-completamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-ukonczenia-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-d-achevement.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-pagina-de-finalizacion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeVerificarAsEstatisticasDoCurso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"it": "https://www.getresponse.com/it/assistenza/dove-controllare-le-statistiche-dei-corsi.html",
"en": "https://www.getresponse.com/help/where-to-check-course-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/__trashed-15.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-statistiques-des-cours.html",
"es": "https://www.getresponse.com/es/ayuda/donde-consultar-las-estadisticas-de-los-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-kursstatistiken-einsehen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchMeineAbonnementsInGetresponseHub = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelltManEinCreatorProfil = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarEOtimizarAPaginaDoMeuCurso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchMeinProfilInGetresponseHub = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchZertifikat = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-zertifikat.html",
"en": "https://www.getresponse.com/help/how-to-create-a-certificate.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-certificato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-certifikat.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-certificat.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-certificado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarACondicaoMatriculadoEmUmCursoNumFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAktualisiereIchRechnungsdetails = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html",
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineAbschlussSeite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-abschluss-seite.html",
"en": "https://www.getresponse.com/help/how-to-create-a-completion-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-pagina-di-completamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-ukonczenia-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-d-achevement.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-pagina-de-finalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarACondicaoCursoIniciadoOuConcluidoNumFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieBedingungLektionBegonnenOderAbgeschlossenInEinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-lektion-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-lesson-started-or-completed-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-leccion-iniciada-o-finalizada-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-lekcja-rozpoczeta-lub-ukonczona-w-cyklu-automation.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-lezione-iniziata-o-completata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-lecon-faite-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-urok-nachat-ili-zavershen-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleUndOptimiereIchMeineKursseite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAsPessoasPodemAvaliarOsMeusCursos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"fr": "https://www.getresponse.com/fr/aide/les-gens-peuvent-ils-evaluer-mes-cours.html",
"en": "https://www.getresponse.com/help/can-people-rate-my-courses.html",
"it": "https://www.getresponse.com/it/assistenza/le-persone-possono-valutare-i-miei-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/czy-ludzie-moga-oceniac-moje-kursy.html",
"es": "https://www.getresponse.com/es/ayuda/puede-la-gente-valorar-mis-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-die-leute-meine-kurse-bewerten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheLektionsformateSindImKurserstellerVerfuegbar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtivarAsSubscricoesDeEMailParaOMeuCurso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-le-iscrizioni-via-email-per-il-mio-corso.html",
"en": "https://www.getresponse.com/help/how-to-enable-email-subscriptions-for-my-course.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-abonnements-aux-courriels-pour-mon-cours.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-subskrypcje-email-mojego-kursu.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-las-suscripciones-por-correo-electronico-para-mi-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-e-mail-abonnements-fuer-meinen-kurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchDieZahlungsintegrationEinUmFuerMeinenKursZuBezahlen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-zahlungsintegration-ein-um-fuer-meinen-kurs-zu-bezahlen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-system-platnosci-aby-pobierac-oplaty-za-moj-kurs.html",
"en": "https://www.getresponse.com/help/how-to-set-up-payment-integration-to-charge-for-my-course.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-l-integrazione-dei-pagamenti-per-far-pagare-il-mio-corso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-l-integration-des-paiements-pour-facturer-mon-cours.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-integracion-de-pagos-para-cobrar-por-mi-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieBedingungKursBegonnenOderAbgeschlossenInEinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoOsModelosDePrecosDisponiveisNosCursos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/welche-preismodelle-gibt-es-in-den-kursen.html",
"en": "https://www.getresponse.com/help/what-are-the-available-pricing-models-in-courses.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-modelli-di-prezzo-disponibili-nei-corsi.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-tarification-disponibles-dans-les-cours.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-modelos-de-precios-disponibles-en-los-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-dostepne-modele-cenowe-w-kursach.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmCurso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-kurs.html",
"en": "https://www.getresponse.com/help/how-to-build-a-course.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-corso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kurs.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-cours.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaNewsletteryPremiumIJakJeStworzyc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZaktualizowacDaneNaFakturze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSuccedeQuandoReindirizzoIMieiNameserverAGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-reindirizzo-i-miei-nameserver-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-redirige-mes-serveurs-de-noms-vers-getresponse.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-redirect-my-nameservers-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekieruje-moje-serwery-nazw-do-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-redirijo-mis-servidores-de-nombre-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-meine-nameserver-auf-getresponse-umleite.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSwietowanieOsiagniec = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html",
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUpsellOrazCrossSell = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/upsell-oraz-cross-sell.html",
"it": "https://www.getresponse.com/it/assistenza/fai-upselling-e-cross-selling.html",
"en": "https://www.getresponse.com/help/upsell-and-cross-sell.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-upsell-et-cross-sell.html",
"de": "https://www.getresponse.com/de/hilfe/upselling-und-cross-selling.html",
"es": "https://www.getresponse.com/es/ayuda/venta-adicional-y-cruzada.html",
"br": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"pt": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrzypomnienieOPostepachWKursie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-postepach-w-kursie.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-dei-progressi-del-corso.html",
"en": "https://www.getresponse.com/help/course-progress-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-zum-kursfortschritt.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-l-avancement-du-cours.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-del-progreso-en-el-curso.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKampaniaReaktywacyjna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPromocionarCursosEnMiLandingPageOSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-mi-landing-page-o-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-sur-ma-landing-page-ou-site-web.html",
"en": "https://www.getresponse.com/help/how-to-promote-courses-on-my-landing-page-or-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-auf-meiner-landing-page-oder-website-fuer-kurse-werben.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGestionarMiPerfilEnGetresponseHub = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGestionarMisSuscripcionesEnGetresponseHub = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasNewslettersPremiumYComoCrearlas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSucedeCuandoRedirijoMisServidoresDeNombreAGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-redirijo-mis-servidores-de-nombre-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-redirige-mes-serveurs-de-noms-vers-getresponse.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-redirect-my-nameservers-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekieruje-moje-serwery-nazw-do-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-reindirizzo-i-miei-nameserver-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-meine-nameserver-auf-getresponse-umleite.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoSieStanieGdyPrzekierujeMojeSerweryNazwDoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekieruje-moje-serwery-nazw-do-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-redirige-mes-serveurs-de-noms-vers-getresponse.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-redirect-my-nameservers-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-redirijo-mis-servidores-de-nombre-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-reindirizzo-i-miei-nameserver-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-meine-nameserver-auf-getresponse-umleite.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneNewsletterPremiumEtCommentLaCreer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacDomeneWysylkowaDoTwojegoKontaMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSePasseTIlLorsqueJeRedirigeMesServeursDeNomsVersGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-redirige-mes-serveurs-de-noms-vers-getresponse.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-redirect-my-nameservers-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekieruje-moje-serwery-nazw-do-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-redirijo-mis-servidores-de-nombre-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-reindirizzo-i-miei-nameserver-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-meine-nameserver-auf-getresponse-umleite.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPromowacKursyWWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-kursy-w-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-corsi-nel-messaggio.html",
"en": "https://www.getresponse.com/help/how-to-promote-courses-in-the-message.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-dans-le-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-un-mensaje.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeNewsletterAPagamentoEComeCrearle = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareEOttimizzareLaPaginaDelMioCorso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePromuovereICorsiNelMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-corsi-nel-messaggio.html",
"en": "https://www.getresponse.com/help/how-to-promote-courses-in-the-message.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-dans-le-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-kursy-w-wiadomosci.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-un-mensaje.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererMesAbonnementsDansGetresponseHub = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererMonProfilDansGetresponseHub = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerEtOptimiserMaPageDeCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakObnovitDannyeDlyaSchetov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeGestireIMieiIscrizioniInGetresponseHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeGestireIlMioProfiloInGetresponseHub = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiornareIDettagliDellaFattura = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMettreAJourLesDetailsDUneFacture = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycIZoptymalizowacStroneKursu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAutomaticallyAuthenticateYourDomainInGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-automatically-authenticate-your-domain-in-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoActualizarLosDatosDeUnaFactura = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampanaDeReenganche = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html",
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToPromoteCoursesOnMyLandingPageOrWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-promote-courses-on-my-landing-page-or-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-sur-ma-landing-page-ou-site-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-mi-landing-page-o-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-auf-meiner-landing-page-oder-website-fuer-kurse-werben.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecordatorioDelProgresoEnElCurso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/recordatorio-del-progreso-en-el-curso.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-dei-progressi-del-corso.html",
"en": "https://www.getresponse.com/help/course-progress-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-zum-kursfortschritt.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-l-avancement-du-cours.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-postepach-w-kursie.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentaAdicionalYCruzada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/venta-adicional-y-cruzada.html",
"it": "https://www.getresponse.com/it/assistenza/fai-upselling-e-cross-selling.html",
"en": "https://www.getresponse.com/help/upsell-and-cross-sell.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-upsell-et-cross-sell.html",
"de": "https://www.getresponse.com/de/hilfe/upselling-und-cross-selling.html",
"pl": "https://www.getresponse.pl/pomoc/upsell-oraz-cross-sell.html",
"br": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"pt": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCelebracionDeLogros = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html",
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnCurso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-kurs.html",
"en": "https://www.getresponse.com/help/how-to-build-a-course.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-corso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kurs.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUpdateInvoiceDetails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-update-invoice-details.html",
"es": "https://www.getresponse.com/es/ayuda/como-actualizar-los-datos-de-una-factura.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-a-jour-les-details-d-une-facture.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiornare-i-dettagli-della-fattura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-obnovit-dannye-dlya-schetov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zaktualizowac-dane-na-fakturze.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktualisiere-ich-rechnungsdetails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atualizar-os-dados-da-fatura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueFormatosDeLeccionEstanDisponiblesEnElCreadorDeCursos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearYOptimizarLaPaginaDeMiCurso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSaDostepneModeleCenoweWKursach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-dostepne-modele-cenowe-w-kursach.html",
"de": "https://www.getresponse.com/de/hilfe/welche-preismodelle-gibt-es-in-den-kursen.html",
"en": "https://www.getresponse.com/help/what-are-the-available-pricing-models-in-courses.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-modelli-di-prezzo-disponibili-nei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-tarification-disponibles-dans-les-cours.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-modelos-de-precios-disponibles-en-los-cursos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWlaczycSubskrypcjeEmailMojegoKursu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-subskrypcje-email-mojego-kursu.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-le-iscrizioni-via-email-per-il-mio-corso.html",
"en": "https://www.getresponse.com/help/how-to-enable-email-subscriptions-for-my-course.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-abonnements-aux-courriels-pour-mon-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-las-suscripciones-por-correo-electronico-para-mi-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-e-mail-abonnements-fuer-meinen-kurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnaPaginaDeFinalizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-pagina-de-finalizacion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-abschluss-seite.html",
"en": "https://www.getresponse.com/help/how-to-create-a-completion-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-pagina-di-completamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-ukonczenia-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-d-achevement.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnPerfilDeCreador = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnCertificado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-certificado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-zertifikat.html",
"en": "https://www.getresponse.com/help/how-to-create-a-certificate.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-certificato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-certifikat.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-certificat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPromocionarCursosEnUnMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-un-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-corsi-nel-messaggio.html",
"en": "https://www.getresponse.com/help/how-to-promote-courses-in-the-message.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-dans-le-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-kursy-w-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaCondicionLeccionIniciadaOFinalizadaEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-leccion-iniciada-o-finalizada-en-un-flujo-de-trabajo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-lektion-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-lesson-started-or-completed-condition-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-lekcja-rozpoczeta-lub-ukonczona-w-cyklu-automation.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-lezione-iniziata-o-completata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-lecon-faite-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-urok-nachat-ili-zavershen-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaCondicionInscritoEnUnCursoEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaCondicionCursoIniciadoOFinalizadoEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedeLaGenteValorarMisCursos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puede-la-gente-valorar-mis-cursos.html",
"fr": "https://www.getresponse.com/fr/aide/les-gens-peuvent-ils-evaluer-mes-cours.html",
"en": "https://www.getresponse.com/help/can-people-rate-my-courses.html",
"it": "https://www.getresponse.com/it/assistenza/le-persone-possono-valutare-i-miei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-ludzie-moga-oceniac-moje-kursy.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-die-leute-meine-kurse-bewerten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatHappensWhenIRedirectMyNameserversToGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-happens-when-i-redirect-my-nameservers-to-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-redirige-mes-serveurs-de-noms-vers-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekieruje-moje-serwery-nazw-do-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-redirijo-mis-servidores-de-nombre-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-reindirizzo-i-miei-nameserver-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-meine-nameserver-auf-getresponse-umleite.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-redireciono-meus-servidores-de-nomes-para-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWarunkuZapisalSieNaKursWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUdostepnicMojEkranPodczasWebinaru = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-moj-ekran-podczas-webinaru.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-mi-pantalla-durante-un-webinar.html",
"en": "https://www.getresponse.com/help/how-to-share-my-screen-during-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-ekranom-vo-vremya-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-lo-schermo-durante-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-mon-ecran-pendant-un-webinaire.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-bildschirm-waehrend-eines-webinars-freigeben.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPromouvoirLesCoursDansLeMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-dans-le-message.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-corsi-nel-messaggio.html",
"en": "https://www.getresponse.com/help/how-to-promote-courses-in-the-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-kursy-w-wiadomosci.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-un-mensaje.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampagneDeReengagement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUpsellingUndCrossSelling = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/upselling-und-cross-selling.html",
"it": "https://www.getresponse.com/it/assistenza/fai-upselling-e-cross-selling.html",
"en": "https://www.getresponse.com/help/upsell-and-cross-sell.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-upsell-et-cross-sell.html",
"es": "https://www.getresponse.com/es/ayuda/venta-adicional-y-cruzada.html",
"pl": "https://www.getresponse.pl/pomoc/upsell-oraz-cross-sell.html",
"br": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"pt": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWuerdigungEinesMeilensteins = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html",
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKampagneZurWiedereingliederung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentesUpsellEtCrossSell = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ventes-upsell-et-cross-sell.html",
"it": "https://www.getresponse.com/it/assistenza/fai-upselling-e-cross-selling.html",
"en": "https://www.getresponse.com/help/upsell-and-cross-sell.html",
"de": "https://www.getresponse.com/de/hilfe/upselling-und-cross-selling.html",
"es": "https://www.getresponse.com/es/ayuda/venta-adicional-y-cruzada.html",
"pl": "https://www.getresponse.pl/pomoc/upsell-oraz-cross-sell.html",
"br": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"pt": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieFormatyLekcjiSaDostepneWKreatorzeKursow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCelebrationDUneEtapeImportante = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html",
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRappelDeLAvancementDuCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rappel-de-l-avancement-du-cours.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-dei-progressi-del-corso.html",
"en": "https://www.getresponse.com/help/course-progress-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-zum-kursfortschritt.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-del-progreso-en-el-curso.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-postepach-w-kursie.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFaiUpsellingECrossSelling = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/fai-upselling-e-cross-selling.html",
"en": "https://www.getresponse.com/help/upsell-and-cross-sell.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-upsell-et-cross-sell.html",
"de": "https://www.getresponse.com/de/hilfe/upselling-und-cross-selling.html",
"es": "https://www.getresponse.com/es/ayuda/venta-adicional-y-cruzada.html",
"pl": "https://www.getresponse.pl/pomoc/upsell-oraz-cross-sell.html",
"br": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"pt": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFesteggiamentoDeiTraguardi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html",
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampagnaDiRicoinvolgimento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToManageMySubscriptionsInGetresponseHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-manage-my-subscriptions-in-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-i-miei-iscrizioni-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-abonnements-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mis-suscripciones-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-abonnements-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-subskrypcjami-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-minhas-assinaturas-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-podpiskami-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToManageMyProfileInGetresponseHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-manage-my-profile-in-getresponse-hub.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-il-mio-profilo-in-getresponse-hub.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mon-profil-dans-getresponse-hub.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-mi-perfil-en-getresponse-hub.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-mein-profil-in-getresponse-hub.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-swoim-profilem-w-getresponse-hub.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-meu-perfil-em-getresponse-hub.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-moim-akkauntom-v-getresponse-hub.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostErinnerungZumKursfortschritt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/erinnerung-zum-kursfortschritt.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-dei-progressi-del-corso.html",
"en": "https://www.getresponse.com/help/course-progress-reminder.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-l-avancement-du-cours.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-del-progreso-en-el-curso.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-postepach-w-kursie.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWannWerdeIchBezahlt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wann-werde-ich-bezahlt.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-zostanie-wyplacona-moja-premia.html",
"en": "https://www.getresponse.com/help/when-will-i-get-paid.html",
"fr": "https://www.getresponse.com/fr/aide/quand-serai-je-paye.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-me-pagaran.html",
"it": "https://www.getresponse.com/it/assistenza/quando-verro-pagato.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-budet-vyplacheno-voznagrazhdenie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareLaCondizioneIscrittoAUnCorsoInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromemoriaDeiProgressiDelCorso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/promemoria-dei-progressi-del-corso.html",
"en": "https://www.getresponse.com/help/course-progress-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-zum-kursfortschritt.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-l-avancement-du-cours.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-del-progreso-en-el-curso.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-postepach-w-kursie.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWarunkuKursRozpoczetyLubUkonczonyWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelchePreismodelleGibtEsInDenKursen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-preismodelle-gibt-es-in-den-kursen.html",
"en": "https://www.getresponse.com/help/what-are-the-available-pricing-models-in-courses.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-modelli-di-prezzo-disponibili-nei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-tarification-disponibles-dans-les-cours.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-modelos-de-precios-disponibles-en-los-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-dostepne-modele-cenowe-w-kursach.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWarunkuLekcjaRozpoczetaLubUkonczonaWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-lekcja-rozpoczeta-lub-ukonczona-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-lektion-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-lesson-started-or-completed-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-leccion-iniciada-o-finalizada-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-lezione-iniziata-o-completata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-lecon-faite-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-urok-nachat-ili-zavershen-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacSystemPlatnosciAbyPobieracOplatyZaMojKurs = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-system-platnosci-aby-pobierac-oplaty-za-moj-kurs.html",
"en": "https://www.getresponse.com/help/how-to-set-up-payment-integration-to-charge-for-my-course.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-zahlungsintegration-ein-um-fuer-meinen-kurs-zu-bezahlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-l-integrazione-dei-pagamenti-per-far-pagare-il-mio-corso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-l-integration-des-paiements-pour-facturer-mon-cours.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-integracion-de-pagos-para-cobrar-por-mi-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelltManEineKiKampagne = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-eine-ki-kampagne.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-campana-de-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-ai-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kampanie-ai.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-campagna-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-kampaniju-ii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieBedingungInEinemKursAngemeldetInEinemWorkflowVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycStroneUkonczeniaKursu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-ukonczenia-kursu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-abschluss-seite.html",
"en": "https://www.getresponse.com/help/how-to-create-a-completion-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-pagina-di-completamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-d-achevement.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-pagina-de-finalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycCertifikat = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-certifikat.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-zertifikat.html",
"en": "https://www.getresponse.com/help/how-to-create-a-certificate.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-certificato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-certificat.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-certificado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzygotowacProfilTworcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarLaIntegracionDePagosParaCobrarPorMiCurso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-integracion-de-pagos-para-cobrar-por-mi-curso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-system-platnosci-aby-pobierac-oplaty-za-moj-kurs.html",
"en": "https://www.getresponse.com/help/how-to-set-up-payment-integration-to-charge-for-my-course.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-zahlungsintegration-ein-um-fuer-meinen-kurs-zu-bezahlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-l-integrazione-dei-pagamenti-per-far-pagare-il-mio-corso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-l-integration-des-paiements-pour-facturer-mon-cours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLosModelosDePreciosDisponiblesEnLosCursos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-modelos-de-precios-disponibles-en-los-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/welche-preismodelle-gibt-es-in-den-kursen.html",
"en": "https://www.getresponse.com/help/what-are-the-available-pricing-models-in-courses.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-modelli-di-prezzo-disponibili-nei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-tarification-disponibles-dans-les-cours.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-dostepne-modele-cenowe-w-kursach.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoActivarLasSuscripcionesPorCorreoElectronicoParaMiCurso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-activar-las-suscripciones-por-correo-electronico-para-mi-curso.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-le-iscrizioni-via-email-per-il-mio-corso.html",
"en": "https://www.getresponse.com/help/how-to-enable-email-subscriptions-for-my-course.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-abonnements-aux-courriels-pour-mon-cours.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-subskrypcje-email-mojego-kursu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-e-mail-abonnements-fuer-meinen-kurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondeConsultarLasEstadisticasDeLosCursos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-consultar-las-estadisticas-de-los-cursos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-controllare-le-statistiche-dei-corsi.html",
"en": "https://www.getresponse.com/help/where-to-check-course-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/__trashed-15.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-statistiques-des-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-kursstatistiken-einsehen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareLaCondizioneLezioneIniziataOCompletataInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-lezione-iniziata-o-completata-in-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-lektion-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-lesson-started-or-completed-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-leccion-iniciada-o-finalizada-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-lekcja-rozpoczeta-lub-ukonczona-w-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-lecon-faite-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-urok-nachat-ili-zavershen-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareLaCondizioneCorsoIniziatoOCompletatoInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoICorsi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-corsi.html",
"en": "https://www.getresponse.com/help/what-are-courses.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlleinstellungsmerkmal = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/alleinstellungsmerkmal.html",
"fr": "https://www.getresponse.com/fr/aide/proposition-de-vente-unique.html",
"en": "https://www.getresponse.com/help/unique-selling-proposition.html",
"es": "https://www.getresponse.com/es/ayuda/propuesta-unica-de-venta.html",
"pl": "https://www.getresponse.pl/pomoc/unikalna-propozycja-sprzedazy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTransaktionaleEMail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/transaktionale-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/courrier-electronique-transactionnel.html",
"en": "https://www.getresponse.com/help/transactional-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-electronico-transaccional.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-transakcyjne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTitleTag = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/title-tag.html",
"fr": "https://www.getresponse.com/fr/aide/balise-de-titre.html",
"en": "https://www.getresponse.com/help/title-tag.html",
"es": "https://www.getresponse.com/es/ayuda/etiqueta-de-titulo.html",
"pl": "https://www.getresponse.pl/pomoc/znacznik-tytulu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStartUps = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/start-ups.html",
"en": "https://www.getresponse.com/help/start-ups.html",
"fr": "https://www.getresponse.com/fr/aide/start-ups.html",
"it": "https://www.getresponse.com/it/assistenza/start-up.html",
"es": "https://www.getresponse.com/es/ayuda/start-ups.html",
"pl": "https://www.getresponse.pl/pomoc/start-upy.html",
"br": "https://www.getresponse.com/pt/ajuda/start-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/start-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSsl = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ssl.html",
"fr": "https://www.getresponse.com/fr/aide/ssl.html",
"en": "https://www.getresponse.com/help/ssl.html",
"it": "https://www.getresponse.com/it/assistenza/ssl.html",
"es": "https://www.getresponse.com/es/ayuda/ssl.html",
"pl": "https://www.getresponse.pl/pomoc/ssl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSponsoring = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/sponsoring.html",
"fr": "https://www.getresponse.com/fr/aide/parrainage.html",
"en": "https://www.getresponse.com/help/sponsorship.html",
"it": "https://www.getresponse.com/it/assistenza/sponsorizzazione.html",
"es": "https://www.getresponse.com/es/ayuda/patrocinio.html",
"pl": "https://www.getresponse.pl/pomoc/sponsoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSozialeMedienMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/soziale-medien-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-des-medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/social-media-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-mediach-spolecznosciowych.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-redes-sociales.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSozialeMedien = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/soziale-medien.html",
"fr": "https://www.getresponse.com/fr/aide/medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media.html",
"pl": "https://www.getresponse.pl/pomoc/media-spolecznosciowe.html",
"es": "https://www.getresponse.com/es/ayuda/redes-sociales.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyLudzieMogaOceniacMojeKursy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-ludzie-moga-oceniac-moje-kursy.html",
"fr": "https://www.getresponse.com/fr/aide/les-gens-peuvent-ils-evaluer-mes-cours.html",
"en": "https://www.getresponse.com/help/can-people-rate-my-courses.html",
"it": "https://www.getresponse.com/it/assistenza/le-persone-possono-valutare-i-miei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"es": "https://www.getresponse.com/es/ayuda/puede-la-gente-valorar-mis-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-die-leute-meine-kurse-bewerten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoveControllareLeStatisticheDeiCorsi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-controllare-le-statistiche-dei-corsi.html",
"en": "https://www.getresponse.com/help/where-to-check-course-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/__trashed-15.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-statistiques-des-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"es": "https://www.getresponse.com/es/ayuda/donde-consultar-las-estadisticas-de-los-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-kursstatistiken-einsehen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLePersonePossonoValutareIMieiCorsi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/le-persone-possono-valutare-i-miei-corsi.html",
"fr": "https://www.getresponse.com/fr/aide/les-gens-peuvent-ils-evaluer-mes-cours.html",
"en": "https://www.getresponse.com/help/can-people-rate-my-courses.html",
"br": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-ludzie-moga-oceniac-moje-kursy.html",
"es": "https://www.getresponse.com/es/ayuda/puede-la-gente-valorar-mis-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-die-leute-meine-kurse-bewerten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeImpostareLIntegrazioneDeiPagamentiPerFarPagareIlMioCorso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-impostare-l-integrazione-dei-pagamenti-per-far-pagare-il-mio-corso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-system-platnosci-aby-pobierac-oplaty-za-moj-kurs.html",
"en": "https://www.getresponse.com/help/how-to-set-up-payment-integration-to-charge-for-my-course.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-zahlungsintegration-ein-um-fuer-meinen-kurs-zu-bezahlen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-l-integration-des-paiements-pour-facturer-mon-cours.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-integracion-de-pagos-para-cobrar-por-mi-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoIModelliDiPrezzoDisponibiliNeiCorsi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-modelli-di-prezzo-disponibili-nei-corsi.html",
"de": "https://www.getresponse.com/de/hilfe/welche-preismodelle-gibt-es-in-den-kursen.html",
"en": "https://www.getresponse.com/help/what-are-the-available-pricing-models-in-courses.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-tarification-disponibles-dans-les-cours.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-modelos-de-precios-disponibles-en-los-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-dostepne-modele-cenowe-w-kursach.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAttivareLeIscrizioniViaEmailPerIlMioCorso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-attivare-le-iscrizioni-via-email-per-il-mio-corso.html",
"en": "https://www.getresponse.com/help/how-to-enable-email-subscriptions-for-my-course.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-abonnements-aux-courriels-pour-mon-cours.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-subskrypcje-email-mojego-kursu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-las-suscripciones-por-correo-electronico-para-mi-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-e-mail-abonnements-fuer-meinen-kurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoIFormatiDelleLezioniDisponibiliNelCreatoreDiCorsi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnaPaginaDiCompletamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-pagina-di-completamento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-abschluss-seite.html",
"en": "https://www.getresponse.com/help/how-to-create-a-completion-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-ukonczenia-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-d-achevement.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-pagina-de-finalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnePageDAchevement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-d-achevement.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-abschluss-seite.html",
"en": "https://www.getresponse.com/help/how-to-create-a-completion-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-pagina-di-completamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-ukonczenia-kursu.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-pagina-de-finalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuConsulterLesStatistiquesDesCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-statistiques-des-cours.html",
"it": "https://www.getresponse.com/it/assistenza/dove-controllare-le-statistiche-dei-corsi.html",
"en": "https://www.getresponse.com/help/where-to-check-course-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/__trashed-15.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"es": "https://www.getresponse.com/es/ayuda/donde-consultar-las-estadisticas-de-los-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-kursstatistiken-einsehen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnCertificat = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-certificat.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-zertifikat.html",
"en": "https://www.getresponse.com/help/how-to-create-a-certificate.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-certificato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-certifikat.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-certificado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-cours.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-kurs.html",
"en": "https://www.getresponse.com/help/how-to-build-a-course.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-corso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kurs.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsSontLesModelesDeTarificationDisponiblesDansLesCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-tarification-disponibles-dans-les-cours.html",
"de": "https://www.getresponse.com/de/hilfe/welche-preismodelle-gibt-es-in-den-kursen.html",
"en": "https://www.getresponse.com/help/what-are-the-available-pricing-models-in-courses.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-modelli-di-prezzo-disponibili-nei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-modelos-de-precios-disponibles-en-los-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-dostepne-modele-cenowe-w-kursach.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnProfilDeCreateur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycKurs = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kurs.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-kurs.html",
"en": "https://www.getresponse.com/help/how-to-build-a-course.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-corso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMettreEnPlaceLIntegrationDesPaiementsPourFacturerMonCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-l-integration-des-paiements-pour-facturer-mon-cours.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-system-platnosci-aby-pobierac-oplaty-za-moj-kurs.html",
"en": "https://www.getresponse.com/help/how-to-set-up-payment-integration-to-charge-for-my-course.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-zahlungsintegration-ein-um-fuer-meinen-kurs-zu-bezahlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-l-integrazione-dei-pagamenti-per-far-pagare-il-mio-corso.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-integracion-de-pagos-para-cobrar-por-mi-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsSontLesFormatsDeCoursDisponiblesDansLeCreateurDeCours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnCertificato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-certificato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-zertifikat.html",
"en": "https://www.getresponse.com/help/how-to-create-a-certificate.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-certifikat.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-certificat.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-certificado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnCorso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-corso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-kurs.html",
"en": "https://www.getresponse.com/help/how-to-build-a-course.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kurs.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnProfiloDiCreator = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSerp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/serp.html",
"fr": "https://www.getresponse.com/fr/aide/serp.html",
"en": "https://www.getresponse.com/help/serp.html",
"it": "https://www.getresponse.com/it/assistenza/serp.html",
"pl": "https://www.getresponse.pl/pomoc/serp.html",
"es": "https://www.getresponse.com/es/ayuda/serp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeoBeratung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/seo-beratung.html",
"fr": "https://www.getresponse.com/fr/aide/conseil-en-referencement.html",
"en": "https://www.getresponse.com/help/seo-consultancy.html",
"pl": "https://www.getresponse.pl/pomoc/doradztwo-seo.html",
"es": "https://www.getresponse.com/es/ayuda/consultoria-seo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeo = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/seo.html",
"fr": "https://www.getresponse.com/fr/aide/seo.html",
"en": "https://www.getresponse.com/help/seo.html",
"it": "https://www.getresponse.com/it/assistenza/seo.html",
"pl": "https://www.getresponse.pl/pomoc/seo.html",
"es": "https://www.getresponse.com/es/ayuda/seo.html",
"br": "https://www.getresponse.com/pt/ajuda/seo.html",
"pt": "https://www.getresponse.com/pt/ajuda/seo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuchmaschinenRetargeting = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/suchmaschinen-retargeting.html",
"fr": "https://www.getresponse.com/fr/aide/reciblage-de-la-recherche.html",
"en": "https://www.getresponse.com/help/search-retargeting.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-della-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/retargetowanie-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/retargeting-de-busqueda.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuchmaschinenmarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/suchmaschinenmarketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-sui-motori-di-ricerca.html",
"en": "https://www.getresponse.com/help/search-engine-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-moteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-buscadores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuchmaschine = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/suchmaschine.html",
"fr": "https://www.getresponse.com/fr/aide/moteur-de-recherche.html",
"en": "https://www.getresponse.com/help/search-engine.html",
"it": "https://www.getresponse.com/it/assistenza/motore-di-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/motor-de-busqueda.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSaas = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/saas.html",
"fr": "https://www.getresponse.com/fr/aide/saas.html",
"en": "https://www.getresponse.com/help/saas.html",
"pl": "https://www.getresponse.pl/pomoc/saas.html",
"es": "https://www.getresponse.com/es/ayuda/saas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostResponsivesWebdesign = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/responsives-webdesign.html",
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web-reactifs.html",
"en": "https://www.getresponse.com/help/responsive-web-design.html",
"es": "https://www.getresponse.com/es/ayuda/diseno-web-responsivo.html",
"pl": "https://www.getresponse.pl/pomoc/responsive-web-design.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreiskatalog = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/preiskatalog.html",
"it": "https://www.getresponse.com/it/assistenza/tariffario.html",
"en": "https://www.getresponse.com/help/rate-card.html",
"fr": "https://www.getresponse.com/fr/aide/grille-tarifaire.html",
"es": "https://www.getresponse.com/es/ayuda/tarjeta-de-tarifas.html",
"pl": "https://www.getresponse.pl/pomoc/cennik.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProduktdifferenzierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/produktdifferenzierung.html",
"it": "https://www.getresponse.com/it/assistenza/differenziazione-del-prodotto.html",
"en": "https://www.getresponse.com/help/product-differentiation.html",
"fr": "https://www.getresponse.com/fr/aide/differenciation-des-produits.html",
"es": "https://www.getresponse.com/es/ayuda/diferenciacion-de-productos.html",
"pl": "https://www.getresponse.pl/pomoc/roznicowanie-produktow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreisgestaltung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/preisgestaltung.html",
"fr": "https://www.getresponse.com/fr/aide/tarification.html",
"en": "https://www.getresponse.com/help/pricing.html",
"it": "https://www.getresponse.com/it/assistenza/prezzi.html",
"es": "https://www.getresponse.com/es/ayuda/fijacion-de-precios.html",
"pl": "https://www.getresponse.pl/pomoc/wycena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPpc = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ppc.html",
"fr": "https://www.getresponse.com/fr/aide/ppc.html",
"en": "https://www.getresponse.com/help/ppc.html",
"it": "https://www.getresponse.com/it/assistenza/ppc.html",
"es": "https://www.getresponse.com/es/ayuda/ppc.html",
"pl": "https://www.getresponse.pl/pomoc/ppc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodcast = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/podcast.html",
"fr": "https://www.getresponse.com/fr/aide/podcast.html",
"en": "https://www.getresponse.com/help/podcast.html",
"es": "https://www.getresponse.com/es/ayuda/podcast.html",
"pl": "https://www.getresponse.pl/pomoc/podcast.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalisierteMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/personalisierte-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-personalizzato.html",
"en": "https://www.getresponse.com/help/personalized-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-personnalise.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-spersonalizowany.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIstDeepLinkingVerfuegbar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ist-deep-linking-verfuegbar.html",
"fr": "https://www.getresponse.com/fr/aide/les-liens-profonds-sont-ils-disponibles.html",
"en": "https://www.getresponse.com/help/is-deep-linking-available.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dostepny-jest-deep-linking.html",
"es": "https://www.getresponse.com/es/ayuda/esta-disponible-el-enlace-de-profundidad.html",
"it": "https://www.getresponse.com/it/assistenza/il-deep-linking-e-disponibile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoePripisannyjDohodIKakOnRabotaet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToPromoteCoursesInTheMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-promote-courses-in-the-message.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-corsi-nel-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-les-cours-dans-le-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-kursy-w-wiadomosci.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-cursos-en-un-mensaje.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCourseProgressReminder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/course-progress-reminder.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-dei-progressi-del-corso.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-zum-kursfortschritt.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-l-avancement-du-cours.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-del-progreso-en-el-curso.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-postepach-w-kursie.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-progresso-do-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReengagementCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reengagement-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/kampagne-zur-wiedereingliederung.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-di-ricoinvolgimento.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-reengagement.html",
"ru": "https://www.getresponse.com/ru/help-ru/kampaniya-po-vosstanovleniju-svyazej.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-reaktywacyjna.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-reenganche.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-de-reengajamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTrashed15 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/__trashed-15.html",
"it": "https://www.getresponse.com/it/assistenza/dove-controllare-le-statistiche-dei-corsi.html",
"en": "https://www.getresponse.com/help/where-to-check-course-statistics.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-statistiques-des-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"es": "https://www.getresponse.com/es/ayuda/donde-consultar-las-estadisticas-de-los-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-kursstatistiken-einsehen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPermissionMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/permission-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-permission.html",
"en": "https://www.getresponse.com/help/permission-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/permission-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-permiso.html",
"pl": "https://www.getresponse.pl/pomoc/permission-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBezahlteMedien = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bezahlte-medien.html",
"fr": "https://www.getresponse.com/fr/aide/medias-payants.html",
"en": "https://www.getresponse.com/help/paid-media.html",
"pl": "https://www.getresponse.pl/pomoc/platne-media.html",
"es": "https://www.getresponse.com/es/ayuda/medios-de-pago.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeitenaufruf = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/seitenaufruf.html",
"fr": "https://www.getresponse.com/fr/aide/vue-de-la-page.html",
"en": "https://www.getresponse.com/help/page-view.html",
"it": "https://www.getresponse.com/it/assistenza/vista-della-paginapage-view.html",
"pl": "https://www.getresponse.pl/pomoc/odslony-strony.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-vista.html",
"br": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAusgehenderLink = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ausgehender-link.html",
"fr": "https://www.getresponse.com/fr/aide/lien-sortant.html",
"en": "https://www.getresponse.com/help/outbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-uscita.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-saliente.html",
"pl": "https://www.getresponse.pl/pomoc/link-wychodzacy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOrganischeSuche = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/organische-suche.html",
"it": "https://www.getresponse.com/it/assistenza/ricerca-organica.html",
"en": "https://www.getresponse.com/help/organic-search.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-organique.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-organica.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-organiczne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOmnichannelMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/omnichannel-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-omnicanale.html",
"en": "https://www.getresponse.com/help/omnichannel-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-omnicanal.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-omnikanalowy.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-omnicanal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMultivariateTests = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/multivariate-tests.html",
"it": "https://www.getresponse.com/it/assistenza/test-multivariato.html",
"en": "https://www.getresponse.com/help/multivariate-testing.html",
"fr": "https://www.getresponse.com/fr/aide/test-multivarie.html",
"pl": "https://www.getresponse.pl/pomoc/testy-wielowymiarowe.html",
"es": "https://www.getresponse.com/es/ayuda/test-multivariante.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMobileApp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/mobile-app.html",
"fr": "https://www.getresponse.com/fr/aide/application-mobile.html",
"en": "https://www.getresponse.com/help/mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/app-mobile.html",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna.html",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaTag = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/meta-tag.html",
"fr": "https://www.getresponse.com/fr/aide/metabalise.html",
"en": "https://www.getresponse.com/help/meta-tag.html",
"pl": "https://www.getresponse.pl/pomoc/meta-tag.html",
"es": "https://www.getresponse.com/es/ayuda/metaetiquetas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaSuchmaschine = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/meta-suchmaschine.html",
"it": "https://www.getresponse.com/it/assistenza/metamotore-di-ricerca.html",
"en": "https://www.getresponse.com/help/meta-search-engine.html",
"fr": "https://www.getresponse.com/fr/aide/metamoteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/metawyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/metabuscador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaBeschreibung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/meta-beschreibung.html",
"fr": "https://www.getresponse.com/fr/aide/meta-descriptions.html",
"en": "https://www.getresponse.com/help/meta-descriptions.html",
"it": "https://www.getresponse.com/it/assistenza/meta-descrizioni.html",
"pl": "https://www.getresponse.pl/pomoc/opisy-meta.html",
"es": "https://www.getresponse.com/es/ayuda/meta-descripciones.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMedienplanung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/medienplanung.html",
"fr": "https://www.getresponse.com/fr/aide/planification-des-medias.html",
"en": "https://www.getresponse.com/help/media-planning.html",
"pl": "https://www.getresponse.pl/pomoc/planowanie-mediow.html",
"es": "https://www.getresponse.com/es/ayuda/planificacion-de-medios.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingplan = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/marketingplan.html",
"fr": "https://www.getresponse.com/fr/aide/plan-de-marketing.html",
"en": "https://www.getresponse.com/help/marketing-plan.html",
"it": "https://www.getresponse.com/it/assistenza/piano-di-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/plan-marketingowy.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingAutomation = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-du-marketing.html",
"en": "https://www.getresponse.com/help/marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-marketing.html",
"br": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkbait = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/linkbait.html",
"it": "https://www.getresponse.com/it/assistenza/linkbait.html",
"en": "https://www.getresponse.com/help/linkbait.html",
"fr": "https://www.getresponse.com/fr/aide/linkbait.html",
"pl": "https://www.getresponse.pl/pomoc/linkbait.html",
"es": "https://www.getresponse.com/es/ayuda/linkbait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinktext = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/linktext.html",
"it": "https://www.getresponse.com/it/assistenza/testo-del-link.html",
"en": "https://www.getresponse.com/help/link-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-du-lien.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-linku.html",
"es": "https://www.getresponse.com/es/ayuda/texto-de-enlace.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkaufbau = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/linkaufbau.html",
"fr": "https://www.getresponse.com/fr/aide/creation-de-liens.html",
"en": "https://www.getresponse.com/help/link-building.html",
"it": "https://www.getresponse.com/it/assistenza/link-building.html",
"pl": "https://www.getresponse.pl/pomoc/link-building.html",
"es": "https://www.getresponse.com/es/ayuda/creacion-de-enlaces.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeadMagnet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/lead-magnet.html",
"fr": "https://www.getresponse.com/fr/aide/lead-magnet.html",
"en": "https://www.getresponse.com/help/lead-magnet.html",
"pl": "https://www.getresponse.pl/pomoc/lead-magnet.html",
"es": "https://www.getresponse.com/es/ayuda/lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHtmlEMail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/html-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/e-mail-html.html",
"en": "https://www.getresponse.com/help/html-email.html",
"fr": "https://www.getresponse.com/fr/aide/courriel-html.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHeatmap = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/heatmap.html",
"fr": "https://www.getresponse.com/fr/aide/heatmap.html",
"en": "https://www.getresponse.com/help/heatmap.html",
"pl": "https://www.getresponse.pl/pomoc/mapa-cieplna-heatmap.html",
"it": "https://www.getresponse.com/it/assistenza/mappa-di-calore.html",
"es": "https://www.getresponse.com/es/ayuda/mapa-de-calor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGastBlogging = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/gast-blogging.html",
"it": "https://www.getresponse.com/it/assistenza/guest-blogging.html",
"en": "https://www.getresponse.com/help/guest-blogging.html",
"fr": "https://www.getresponse.com/fr/aide/guest-blogging.html",
"pl": "https://www.getresponse.pl/pomoc/goscinne-pisanie-blogow.html",
"es": "https://www.getresponse.com/es/ayuda/guest-blogging.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGrauliste = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/grauliste.html",
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"en": "https://www.getresponse.com/help/greylist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoogleUnternehmensprofil = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/google-unternehmensprofil.html",
"pl": "https://www.getresponse.pl/pomoc/google-moja-firma.html",
"en": "https://www.getresponse.com/help/google-my-business.html",
"fr": "https://www.getresponse.com/fr/aide/google-my-business.html",
"es": "https://www.getresponse.com/es/ayuda/google-my-business.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmDominioDeEnvioASuaContaMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarOQuadroInterativoDuranteUmWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGpt3 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/gpt-3.html",
"fr": "https://www.getresponse.com/fr/aide/gpt-3.html",
"en": "https://www.getresponse.com/help/gpt-3.html",
"pl": "https://www.getresponse.pl/pomoc/gpt-3.html",
"es": "https://www.getresponse.com/es/ayuda/gpt-3.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoogleRemarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/google-remarketing.html",
"fr": "https://www.getresponse.com/fr/aide/remarketing-de-google.html",
"en": "https://www.getresponse.com/help/google-remarketing.html",
"it": "https://www.getresponse.com/it/assistenza/remarketing-di-google.html",
"pl": "https://www.getresponse.pl/pomoc/remarketing-google.html",
"es": "https://www.getresponse.com/es/ayuda/google-remarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoogleMarketingPlatform = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/google-marketing-platform.html",
"pl": "https://www.getresponse.pl/pomoc/google-marketing-platform.html",
"en": "https://www.getresponse.com/help/google-marketing-platform.html",
"fr": "https://www.getresponse.com/fr/aide/google-marketing-platform.html",
"es": "https://www.getresponse.com/es/ayuda/plataforma-de-marketing-de-google.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoogleAlgorithmusAktualisierungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/google-algorithmus-aktualisierungen.html",
"it": "https://www.getresponse.com/it/assistenza/aggiornamenti-dell-algoritmo-di-google.html",
"en": "https://www.getresponse.com/help/google-algorithm-updates.html",
"fr": "https://www.getresponse.com/fr/aide/mises-a-jour-de-l-algorithme-de-google.html",
"pl": "https://www.getresponse.pl/pomoc/aktualizacje-algorytmu-google.html",
"es": "https://www.getresponse.com/es/ayuda/actualizaciones-del-algoritmo-de-google.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoToMarketStrategie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/go-to-market-strategie.html",
"it": "https://www.getresponse.com/it/assistenza/strategia-go-to-market.html",
"en": "https://www.getresponse.com/help/go-to-market-strategy.html",
"fr": "https://www.getresponse.com/fr/aide/strategie-de-mise-sur-le-marche.html",
"pl": "https://www.getresponse.pl/pomoc/strategia-wejscia-na-rynek.html",
"es": "https://www.getresponse.com/es/ayuda/estrategia-gtm.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeoTargeting = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/geo-targeting.html",
"fr": "https://www.getresponse.com/fr/aide/geo-ciblage.html",
"en": "https://www.getresponse.com/help/geo-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-geografico.html",
"pl": "https://www.getresponse.pl/pomoc/geotargetowanie.html",
"es": "https://www.getresponse.com/es/ayuda/geolocalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKpi = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kpi.html",
"fr": "https://www.getresponse.com/fr/aide/icp.html",
"en": "https://www.getresponse.com/help/kpi.html",
"pl": "https://www.getresponse.pl/pomoc/kpi.html",
"es": "https://www.getresponse.com/es/ayuda/kpi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSchluesselwortStuffing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-stuffing.html",
"it": "https://www.getresponse.com/it/assistenza/keyword-stuffing.html",
"en": "https://www.getresponse.com/help/keyword-stuffing.html",
"fr": "https://www.getresponse.com/fr/aide/bourrage-de-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/upychanie-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/relleno-de-palabras-clave.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSchluesselwortMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-palabras-clave.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSchluesselwortDichte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-dichte.html",
"it": "https://www.getresponse.com/it/assistenza/densita-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-density.html",
"fr": "https://www.getresponse.com/fr/aide/densite-des-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/gestosc-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/densidad-de-palabras-clave.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSchluesselwort = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/schluesselwort.html",
"fr": "https://www.getresponse.com/fr/aide/mot-cle.html",
"en": "https://www.getresponse.com/help/keyword.html",
"it": "https://www.getresponse.com/it/assistenza/parola-chiave.html",
"pl": "https://www.getresponse.pl/pomoc/slowo-kluczowe.html",
"es": "https://www.getresponse.com/es/ayuda/palabra-clave.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInternationalesSeo = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/internationales-seo.html",
"it": "https://www.getresponse.com/it/assistenza/seo-internazionale.html",
"en": "https://www.getresponse.com/help/international-seo.html",
"fr": "https://www.getresponse.com/fr/aide/seo-international.html",
"pl": "https://www.getresponse.pl/pomoc/miedzynarodowe-seo.html",
"es": "https://www.getresponse.com/es/ayuda/seo-internacional.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIntegriertesMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/integriertes-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-integre.html",
"en": "https://www.getresponse.com/help/integrated-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-zintegrowany.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-integrado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEingehenderLink = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/eingehender-link.html",
"fr": "https://www.getresponse.com/fr/aide/lien-entrant.html",
"en": "https://www.getresponse.com/help/inbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-entrata.html",
"pl": "https://www.getresponse.pl/pomoc/link-przychodzacy.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-entrante.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/funnel.html",
"fr": "https://www.getresponse.com/fr/aide/funnel.html",
"en": "https://www.getresponse.com/help/funnel.html",
"it": "https://www.getresponse.com/it/assistenza/funnel.html",
"pl": "https://www.getresponse.pl/pomoc/lejek.html",
"es": "https://www.getresponse.com/es/ayuda/embudo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFrequenzbegrenzung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/frequenzbegrenzung.html",
"fr": "https://www.getresponse.com/fr/aide/plafond-de-frequence.html",
"en": "https://www.getresponse.com/help/frequency-cap.html",
"it": "https://www.getresponse.com/it/assistenza/limite-di-frequenzafrequency-cap.html",
"pl": "https://www.getresponse.pl/pomoc/limit-czestotliwosci.html",
"es": "https://www.getresponse.com/es/ayuda/limite-de-frecuencia.html",
"br": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFreemium = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/freemium.html",
"pl": "https://www.getresponse.pl/pomoc/freemium.html",
"en": "https://www.getresponse.com/help/freemium.html",
"fr": "https://www.getresponse.com/fr/aide/freemium.html",
"es": "https://www.getresponse.com/es/ayuda/freemium.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKostenloseMarketingTools = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kostenlose-marketing-tools.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-gratuits.html",
"en": "https://www.getresponse.com/help/free-marketing-tools.html",
"it": "https://www.getresponse.com/it/assistenza/strumenti-di-marketing-gratuiti.html",
"pl": "https://www.getresponse.pl/pomoc/bezplatne-narzedzia-marketingowe.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-gratuitas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFintech = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/fintech.html",
"pl": "https://www.getresponse.pl/pomoc/fintech.html",
"en": "https://www.getresponse.com/help/fintech.html",
"fr": "https://www.getresponse.com/fr/aide/fintech.html",
"es": "https://www.getresponse.com/es/ayuda/fintech.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFavicon = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/favicon.html",
"fr": "https://www.getresponse.com/fr/aide/favicon.html",
"en": "https://www.getresponse.com/help/favicon.html",
"pl": "https://www.getresponse.pl/pomoc/favicon.html",
"it": "https://www.getresponse.com/it/assistenza/favicon.html",
"es": "https://www.getresponse.com/es/ayuda/favicon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerfolgstDuMeinenUmsatzUndMeineLeistung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verfolgst-du-meinen-umsatz-und-meine-leistung.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivez-vous-mes-ventes-et-mes-performances.html",
"en": "https://www.getresponse.com/help/how-do-you-track-my-sales-and-performance.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzimy-twoja-sprzedaz-i-wyniki.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacen-seguimiento-de-mis-ventas-y-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-tiene-traccia-delle-mie-vendite-e-delle-mie-prestazioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDomainsUndSubdomainsFuerNeueLandingPagesNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindKiKampagnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-ki-kampagnen.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-campanas-de-ia.html",
"en": "https://www.getresponse.com/help/what-are-ai-campaigns.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-campagne-ai.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-kampanie-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchAktionscodesZuMeinemPopUpFormularOderInlineInhaltHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-aktionscodes-zu-meinem-pop-up-formular-oder-inline-inhalt-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-codes-promo-a-mon-formulaire-pop-up-ou-a-mon-contenu-en-ligne.html",
"en": "https://www.getresponse.com/help/how-to-add-promo-codes-to-my-popup-form-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-promokod-vo-vsplyvajushhuju-ili-vstroennuju-formu.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-codigos-promocionales-a-mi-formulario-emergente-o-fragmento-de-contenido-en-linea.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kody-promocyjne-do-pop-upow-lub-tresci-wbudowanej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerbindeIchMeineXTwitterUndGetresponseKonten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAccueillDesNouveauxContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostErweiterteKontaktaufteilung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEinfacheKontaktaufteilung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenBildschirmWaehrendEinesWebinarsFreigeben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-bildschirm-waehrend-eines-webinars-freigeben.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-mi-pantalla-durante-un-webinar.html",
"en": "https://www.getresponse.com/help/how-to-share-my-screen-during-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-ekranom-vo-vremya-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-lo-schermo-durante-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-mon-ecran-pendant-un-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-moj-ekran-podczas-webinaru.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMilestoneCelebration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/milestone-celebration.html",
"it": "https://www.getresponse.com/it/assistenza/festeggiamento-dei-traguardi.html",
"fr": "https://www.getresponse.com/fr/aide/celebration-d-une-etape-importante.html",
"de": "https://www.getresponse.com/de/hilfe/wuerdigung-eines-meilensteins.html",
"ru": "https://www.getresponse.com/ru/help-ru/prazdnovanie-znamenatelnoj-daty.html",
"es": "https://www.getresponse.com/es/ayuda/celebracion-de-logros.html",
"pl": "https://www.getresponse.pl/pomoc/swietowanie-osiagniec.html",
"br": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html",
"pt": "https://www.getresponse.com/pt/ajuda/celebracao-de-conquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLoeseIchEinPopUpAusWennIchEineBestellungAufgegebenOderDenWarenkorbAktualisiertHabe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-loese-ich-ein-pop-up-aus-wenn-ich-eine-bestellung-aufgegeben-oder-den-warenkorb-aktualisiert-habe.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-in-base-all-ordine-effettuato-o-al-carrello-aggiornato.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-placed-order-or-updated-cart.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-na-podstawie-zlozonego-zamowienia-lub-aktualizacji-koszyka.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-un-pedido-realizado-o-un-carrito-actualizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iniciirovat-vsplyvajushhee-okno-na-osnove-oformlennogo-zakaza-ili-obnovlennoj-korziny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineEMailFuerAbgebrocheneWarenkoerbeInWoocommerce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-e-mail-fuer-abgebrochene-warenkoerbe-in-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-per-il-carrello-abbandonato-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-abandoned-cart-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-carrito-abandonado-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoj-korziny-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-panier-abandonne-pour-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDerChatSupportFuerMaxMax2Konten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineBenutzerdefinierteProtokollverbindung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-benutzerdefinierte-protokollverbindung.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-collegamento-di-protocollo-personalizzato.html",
"en": "https://www.getresponse.com/help/what-is-a-custom-protocol-link.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-enlace-de-protocolo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-polzovatelskaya-ssylka-protokola.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-lien-protocolaire-personnalise.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-protokol-niestandardowy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEineBenutzerdefinierteBestaetigungsnachrichtenvorlageInMaxKontenHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-benutzerdefinierte-bestaetigungsnachrichtenvorlage-in-max-konten-hinzu.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-template-di-messaggio-di-conferma-personalizzato-negli-account-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-custom-confirmation-message-template-in-max-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-plantilla-de-mensaje-de-confirmacion-personalizada-en-las-cuentas-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelskij-shablon-pisma-podtverzhdeniya-v-max-akkauntah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wlasny-szablon-wiadomosci-potwierdzajacej-w-kontach-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-modele-de-message-de-confirmation-personnalise-dans-les-comptes-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchTeachableMitGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-teachable-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-teachable-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-teachable-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-teachable-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-teachable-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-teachable-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-teachable-s-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEMailVorlage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/e-mail-vorlage.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-e-mail.html",
"en": "https://www.getresponse.com/help/email-template.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-wiadomosci-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/plantillas-de-mensaje.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEMailSpam = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/e-mail-spam.html",
"it": "https://www.getresponse.com/it/assistenza/soam-via-e-mail.html",
"en": "https://www.getresponse.com/help/email-spam.html",
"fr": "https://www.getresponse.com/fr/aide/spam-par-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/spam-2.html",
"es": "https://www.getresponse.com/es/ayuda/correo-no-deseado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEMailFilterung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/e-mail-filterung.html",
"it": "https://www.getresponse.com/it/assistenza/email-filtering.html",
"en": "https://www.getresponse.com/help/email-filtering.html",
"fr": "https://www.getresponse.com/fr/aide/filtrage-du-courrier-electronique.html",
"pl": "https://www.getresponse.pl/pomoc/filtrowanie-wiadomosci-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/filtrado-de-correo-electronico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEMailAutomatisierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/e-mail-automatisierung.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-automation.html",
"it": "https://www.getresponse.com/it/assistenza/automazione-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-des-courriels.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-correo-electronico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEcpm = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ecpm.html",
"it": "https://www.getresponse.com/it/assistenza/ecpm.html",
"en": "https://www.getresponse.com/help/ecpm.html",
"fr": "https://www.getresponse.com/fr/aide/ecpm.html",
"pl": "https://www.getresponse.pl/pomoc/ecpm.html",
"es": "https://www.getresponse.com/es/ayuda/ecpm.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostECommerceWebsite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/e-commerce-website.html",
"pl": "https://www.getresponse.pl/pomoc/strona-e-commerce.html",
"en": "https://www.getresponse.com/help/e-commerce-website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/site-web-de-commerce-electronique.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web-de-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostECommerce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/e-commerce.html",
"en": "https://www.getresponse.com/help/e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/e-commerce.html",
"it": "https://www.getresponse.com/it/assistenza/e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/e-commerce.html",
"es": "https://www.getresponse.com/es/ayuda/e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDropshipping = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/dropshipping.html",
"pl": "https://www.getresponse.pl/pomoc/dropshipping.html",
"en": "https://www.getresponse.com/help/dropshipping.html",
"fr": "https://www.getresponse.com/fr/aide/dropshipping.html",
"es": "https://www.getresponse.com/es/ayuda/dropshipping.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDripKampagne = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/drip-kampagne.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-typu-drip.html",
"en": "https://www.getresponse.com/help/drip-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-goteo.html",
"fr": "https://www.getresponse.com/fr/aide/drip-campagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDomain = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/domain.html",
"fr": "https://www.getresponse.com/fr/aide/domaine.html",
"en": "https://www.getresponse.com/help/domain.html",
"it": "https://www.getresponse.com/it/assistenza/dominio.html",
"pl": "https://www.getresponse.pl/pomoc/domena.html",
"es": "https://www.getresponse.com/es/ayuda/dominio.html",
"br": "https://www.getresponse.com/pt/ajuda/dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDisplayanzeigen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/displayanzeigen.html",
"pl": "https://www.getresponse.pl/pomoc/reklamy-displayowe.html",
"en": "https://www.getresponse.com/help/display-ads.html",
"fr": "https://www.getresponse.com/fr/aide/display-ads.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-display.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVerzeichnis = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/verzeichnis.html",
"pl": "https://www.getresponse.pl/pomoc/katalog.html",
"en": "https://www.getresponse.com/help/directory.html",
"fr": "https://www.getresponse.com/fr/aide/annuaire.html",
"es": "https://www.getresponse.com/es/ayuda/directorio.html",
"it": "https://www.getresponse.com/it/assistenza/directory.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDirektmailingMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/direktmailing-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/bezposredni-marketing-pocztowy.html",
"en": "https://www.getresponse.com/help/direct-mail-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-direct.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-por-correo-directo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDigitalePr = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/digitale-pr.html",
"pl": "https://www.getresponse.pl/pomoc/digital-pr.html",
"en": "https://www.getresponse.com/help/digital-pr.html",
"it": "https://www.getresponse.com/it/assistenza/pr-digitale.html",
"fr": "https://www.getresponse.com/fr/aide/rp-numeriques.html",
"es": "https://www.getresponse.com/es/ayuda/rp-digitales.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDigitalesMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/digitales-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-numerique.html",
"en": "https://www.getresponse.com/help/digital-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/digital-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-cyfrowy.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-digital.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-digital.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDigitaleInhalte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/digitale-inhalte.html",
"pl": "https://www.getresponse.pl/pomoc/tresci-cyfrowe.html",
"en": "https://www.getresponse.com/help/digital-content.html",
"it": "https://www.getresponse.com/it/assistenza/contenuto-digitale.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-numerique.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-digital.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDeepLinking = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/deep-linking.html",
"pl": "https://www.getresponse.pl/pomoc/deep-linking.html",
"en": "https://www.getresponse.com/help/deep-linking.html",
"fr": "https://www.getresponse.com/fr/aide/deep-linking.html",
"es": "https://www.getresponse.com/es/ayuda/vinculos-profundos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDediziertesHosting = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/dediziertes-hosting.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-hosting.html",
"it": "https://www.getresponse.com/it/assistenza/hosting-dedicato.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-dedie.html",
"es": "https://www.getresponse.com/es/ayuda/hosting-dedicado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKundenreise = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kundenreise.html",
"pl": "https://www.getresponse.pl/pomoc/podroz-klienta.html",
"en": "https://www.getresponse.com/help/customer-journey.html",
"fr": "https://www.getresponse.com/fr/aide/parcours-du-client.html",
"it": "https://www.getresponse.com/it/assistenza/customer-journey.html",
"es": "https://www.getresponse.com/es/ayuda/recorrido-del-cliente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKundendatenplattform = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kundendatenplattform.html",
"pl": "https://www.getresponse.pl/pomoc/platforma-danych-klientow.html",
"en": "https://www.getresponse.com/help/customer-data-platform.html",
"fr": "https://www.getresponse.com/fr/aide/plate-forme-de-donnees-clients.html",
"es": "https://www.getresponse.com/es/ayuda/plataforma-de-datos-de-clientes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCtr = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ctr.html",
"pl": "https://www.getresponse.pl/pomoc/ctr.html",
"en": "https://www.getresponse.com/help/ctr.html",
"fr": "https://www.getresponse.com/fr/aide/ctr.html",
"es": "https://www.getresponse.com/es/ayuda/ctr.html",
"it": "https://www.getresponse.com/it/assistenza/ctr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCta = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/cta.html",
"pl": "https://www.getresponse.pl/pomoc/cta.html",
"en": "https://www.getresponse.com/help/cta.html",
"fr": "https://www.getresponse.com/fr/aide/cta.html",
"es": "https://www.getresponse.com/es/ayuda/cta.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCrm = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/crm.html",
"es": "https://www.getresponse.com/es/ayuda/crm.html",
"en": "https://www.getresponse.com/help/crm.html",
"fr": "https://www.getresponse.com/fr/aide/crm.html",
"pl": "https://www.getresponse.pl/pomoc/crm.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCpm = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/cpm.html",
"pl": "https://www.getresponse.pl/pomoc/cpm.html",
"en": "https://www.getresponse.com/help/cpm.html",
"es": "https://www.getresponse.com/es/ayuda/cpm.html",
"fr": "https://www.getresponse.com/fr/aide/cpm.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCpl = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/cpl.html",
"pl": "https://www.getresponse.pl/pomoc/cpl.html",
"en": "https://www.getresponse.com/help/cpl.html",
"es": "https://www.getresponse.com/es/ayuda/cpl.html",
"fr": "https://www.getresponse.com/fr/aide/cpl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCpc = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/cpc.html",
"es": "https://www.getresponse.com/es/ayuda/cpc.html",
"en": "https://www.getresponse.com/help/cpc.html",
"fr": "https://www.getresponse.com/fr/aide/cpc.html",
"it": "https://www.getresponse.com/it/assistenza/cpc.html",
"pl": "https://www.getresponse.pl/pomoc/cpc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenKurs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-kurs.html",
"en": "https://www.getresponse.com/help/how-to-build-a-course.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-corso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kurs.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUpsellAndCrossSell = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/upsell-and-cross-sell.html",
"it": "https://www.getresponse.com/it/assistenza/fai-upselling-e-cross-selling.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-upsell-et-cross-sell.html",
"de": "https://www.getresponse.com/de/hilfe/upselling-und-cross-selling.html",
"es": "https://www.getresponse.com/es/ayuda/venta-adicional-y-cruzada.html",
"pl": "https://www.getresponse.pl/pomoc/upsell-oraz-cross-sell.html",
"br": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html",
"pt": "https://www.getresponse.com/pt/ajuda/upsell-e-cross-sell.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTheEnrolledInACourseConditionInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-the-enrolled-in-a-course-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-bedingung-in-einem-kurs-angemeldet-in-einem-workflow-verwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-inscrit-a-un-cours-dans-un-flux-de-travail.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-inscrito-en-un-curso-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-iscritto-a-un-corso-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-zapisal-sie-na-kurs-w-cyklu-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-matriculado-em-um-curso-num-fluxo-de-trabalho.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-zapisalsya-na-kurs-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTheLessonStartedOrCompletedConditionInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-the-lesson-started-or-completed-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-lektion-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-leccion-iniciada-o-finalizada-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-lekcja-rozpoczeta-lub-ukonczona-w-cyklu-automation.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-lezione-iniziata-o-completata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-lecon-faite-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-urok-nachat-ili-zavershen-v-rabochem-processe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTheCourseStartedOrCompletedConditionInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-the-course-started-or-completed-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-kurs-begonnen-oder-abgeschlossen-in-einem-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-la-condizione-corso-iniziato-o-completato-in-un-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-condicion-curso-iniciado-o-finalizado-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-kurs-rozpoczety-lub-ukonczony-w-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-cours-fait-ou-fini-dans-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovie-kurs-nachat-ili-zavershen-v-rabochem-processe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-condicao-curso-iniciado-ou-concluido-num-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestExternalLexpadIJakGoUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-external-lexpad-i-jak-go-uzywac.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-external-lexpad-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-external-lexpad-and-how-do-i-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-lexpad-externe-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-external-lexpad-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-external-lexpad-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-externe-lexpad-und-wie-verwende-ich-es.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacAdresyUrlDlaFormularzyIPopUpow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-adresy-url-dla-formularzy-i-pop-upow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-l-affichage-de-l-url-des-formulaires-et-des-popups.html",
"en": "https://www.getresponse.com/help/how-to-set-up-forms-and-popups-url-display.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-la-visualizzazione-degli-url-dei-moduli-e-dei-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-otobrazhenie-url-v-formah-i-vsplyvajushhih-oknah.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-visualizacion-de-url-de-formularios-y-ventanas-emergentes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-formulare-und-pop-ups-fuer-die-url-anzeige-ein.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatArePremiumNewslettersAndHowToCreateThem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-premium-newsletters-and-how-to-create-them.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-newsletter-a-pagamento-e-come-crearle.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-newsletter-premium-et-comment-la-creer.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-newsletters-premium-y-como-crearlas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-newslettery-premium-i-jak-je-stworzyc.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-premium-newsletter-und-wie-erstellt-man-sie.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-newsletters-premium-e-como-as-podes-criar.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-premium-rassylki-i-kak-ih-sozdat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSetUpPaymentIntegrationToChargeForMyCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-set-up-payment-integration-to-charge-for-my-course.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-system-platnosci-aby-pobierac-oplaty-za-moj-kurs.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-zahlungsintegration-ein-um-fuer-meinen-kurs-zu-bezahlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-l-integrazione-dei-pagamenti-per-far-pagare-il-mio-corso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-l-integration-des-paiements-pour-facturer-mon-cours.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-integracion-de-pagos-para-cobrar-por-mi-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheAvailablePricingModelsInCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-available-pricing-models-in-courses.html",
"de": "https://www.getresponse.com/de/hilfe/welche-preismodelle-gibt-es-in-den-kursen.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-modelli-di-prezzo-disponibili-nei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-modelos-de-precos-disponiveis-nos-cursos.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-tarification-disponibles-dans-les-cours.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-modelos-de-precios-disponibles-en-los-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-dostepne-modele-cenowe-w-kursach.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanPeopleRateMyCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-people-rate-my-courses.html",
"fr": "https://www.getresponse.com/fr/aide/les-gens-peuvent-ils-evaluer-mes-cours.html",
"it": "https://www.getresponse.com/it/assistenza/le-persone-possono-valutare-i-miei-corsi.html",
"br": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-pessoas-podem-avaliar-os-meus-cursos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-ludzie-moga-oceniac-moje-kursy.html",
"es": "https://www.getresponse.com/es/ayuda/puede-la-gente-valorar-mis-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-die-leute-meine-kurse-bewerten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateACompletionPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-completion-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-abschluss-seite.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-pagina-di-completamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-ukonczenia-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-d-achevement.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-pagina-de-finalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-conclusao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateACreatorsProfile = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-creators-profile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-profil-avtora.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-ein-creator-profil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-profil-tworcy.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-profilo-di-creator.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-profil-de-createur.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-perfil-de-creador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-perfil-de-criador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatLessonFormatsAreAvailableInTheCourseCreator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-lesson-formats-are-available-in-the-course-creator.html",
"de": "https://www.getresponse.com/de/hilfe/welche-lektionsformate-sind-im-kursersteller-verfuegbar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-formati-delle-lezioni-disponibili-nel-creatore-di-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-formaty-lekcji-sa-dostepne-w-kreatorze-kursow.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-formats-de-cours-disponibles-dans-le-createur-de-cours.html",
"es": "https://www.getresponse.com/es/ayuda/que-formatos-de-leccion-estan-disponibles-en-el-creador-de-cursos.html",
"br": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-formatos-de-aula-estao-disponiveis-no-criador-de-cursos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-formaty-urokov-dostupny-v-konstruktore-kursov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereToCheckCourseStatistics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-to-check-course-statistics.html",
"it": "https://www.getresponse.com/it/assistenza/dove-controllare-le-statistiche-dei-corsi.html",
"pl": "https://www.getresponse.pl/pomoc/__trashed-15.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-statistiques-des-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-verificar-as-estatisticas-do-curso.html",
"es": "https://www.getresponse.com/es/ayuda/donde-consultar-las-estadisticas-de-los-cursos.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-kursstatistiken-einsehen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateACertificate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-certificate.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-zertifikat.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-certificato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-certifikat.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-certificat.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-certificado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToEnableEmailSubscriptionsForMyCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-enable-email-subscriptions-for-my-course.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-le-iscrizioni-via-email-per-il-mio-corso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-abonnements-aux-courriels-pour-mon-cours.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-subskrypcje-email-mojego-kursu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-as-subscricoes-de-e-mail-para-o-meu-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-las-suscripciones-por-correo-electronico-para-mi-curso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-e-mail-abonnements-fuer-meinen-kurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToBuildACourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-build-a-course.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-kurs.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-corso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kurs.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-cours.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-curso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-curso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAndOptimizeMyCoursePage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-and-optimize-my-course-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-optimizar-la-pagina-de-mi-curso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-i-zoptymalizowac-strone-kursu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-optimiser-ma-page-de-cours.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-ottimizzare-la-pagina-del-mio-corso.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-optimiere-ich-meine-kursseite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-otimizar-a-pagina-do-meu-curso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-optimizirovat-svoju-stranicu-kursa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCpa = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/cpa.html",
"pl": "https://www.getresponse.pl/pomoc/cpa.html",
"en": "https://www.getresponse.com/help/cpa.html",
"es": "https://www.getresponse.com/es/ayuda/cpa.html",
"fr": "https://www.getresponse.com/fr/aide/cpa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoreWebVitals = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/core-web-vitals.html",
"pl": "https://www.getresponse.pl/pomoc/core-web-vitals.html",
"en": "https://www.getresponse.com/help/core-web-vitals.html",
"es": "https://www.getresponse.com/es/ayuda/core-web-vitals.html",
"fr": "https://www.getresponse.com/fr/aide/core-web-vitals.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCookieEinstellungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/cookie-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/preferencje-dotyczace-plikow-cookie.html",
"en": "https://www.getresponse.com/help/cookie-preferences.html",
"fr": "https://www.getresponse.com/fr/aide/cookie-preferences.html",
"it": "https://www.getresponse.com/it/assistenza/preferenze-sui-cookie.html",
"es": "https://www.getresponse.com/es/ayuda/preferencias-de-cookies.html",
"br": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"pt": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversionRateOptimierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/conversion-rate-optimierung.html",
"pl": "https://www.getresponse.pl/pomoc/optymalizacja-wspolczynnika-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate-optimization.html",
"fr": "https://www.getresponse.com/fr/aide/optimisation-du-taux-de-conversion.html",
"it": "https://www.getresponse.com/it/assistenza/ottimizzazione-del-tasso-di-conversione.html",
"es": "https://www.getresponse.com/es/ayuda/optimizacion-de-la-tasa-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostB2C = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/b2c.html",
"pl": "https://www.getresponse.pl/pomoc/b2c.html",
"en": "https://www.getresponse.com/help/b2c.html",
"es": "https://www.getresponse.com/es/ayuda/b2c.html",
"fr": "https://www.getresponse.com/fr/aide/b2c.html",
"de": "https://www.getresponse.com/de/hilfe/b2c.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoOsIconesNoCantoSuperiorDireitoDoMeuPainelDeControlo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostB2B = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/b2b.html",
"de": "https://www.getresponse.com/de/hilfe/b2b.html",
"pl": "https://www.getresponse.pl/pomoc/b2b.html",
"en": "https://www.getresponse.com/help/b2b.html",
"es": "https://www.getresponse.com/es/ayuda/b2b.html",
"fr": "https://www.getresponse.com/fr/aide/b2b.html",
"it": "https://www.getresponse.com/it/assistenza/b2b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentaciyaAuditorii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-auditorii.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-odbiorcow.html",
"en": "https://www.getresponse.com/help/audience-segmentation.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-del-pubblico.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-l-audience.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-audiencia.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppensegmentierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAuditoriya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/auditoriya.html",
"pl": "https://www.getresponse.pl/pomoc/grupa-docelowa.html",
"en": "https://www.getresponse.com/help/audience.html",
"es": "https://www.getresponse.com/es/ayuda/audiencia.html",
"fr": "https://www.getresponse.com/fr/aide/public.html",
"it": "https://www.getresponse.com/it/assistenza/pubblico.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnimirovannyjGif = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/animirovannyj-gif.html",
"pl": "https://www.getresponse.pl/pomoc/animowany-gif.html",
"en": "https://www.getresponse.com/help/animated-gif.html",
"es": "https://www.getresponse.com/es/ayuda/gif-animado.html",
"fr": "https://www.getresponse.com/fr/aide/gif-anime.html",
"it": "https://www.getresponse.com/it/assistenza/gif-animata.html",
"de": "https://www.getresponse.com/de/hilfe/animiertes-gif.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAltTekst = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/alt-tekst.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-alt.html",
"en": "https://www.getresponse.com/help/alt-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-alt.html",
"fr": "https://www.getresponse.com/fr/aide/texte-alt.html",
"it": "https://www.getresponse.com/it/assistenza/testo-alt.html",
"de": "https://www.getresponse.com/de/hilfe/alt-text.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingovayaModelAida = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaya-model-aida.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-modelu-aida.html",
"en": "https://www.getresponse.com/help/aida-model-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-marketing-aida.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-marketing-aida.html",
"it": "https://www.getresponse.com/it/assistenza/modello-marketing-aida.html",
"de": "https://www.getresponse.com/de/hilfe/aida-modell-im-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInstrumentyMarketingaNaBazeIi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/instrumenty-marketinga-na-baze-ii.html",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-marketingowe-ai.html",
"en": "https://www.getresponse.com/help/ai-marketing-tools.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-de-ia.html",
"it": "https://www.getresponse.com/it/assistenza/ai-marketing-tools.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-ia.html",
"de": "https://www.getresponse.com/de/hilfe/ai-marketing-tools.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPartnjorskayaSet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/partnjorskaya-set.html",
"pl": "https://www.getresponse.pl/pomoc/siec-afiliacyjna.html",
"en": "https://www.getresponse.com/help/affiliate-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/rete-di-affiliazione.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-d-affiliation.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-netzwerk.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMenedzherPoRaboteSPartnjorami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/menedzher-po-rabote-s-partnjorami.html",
"pl": "https://www.getresponse.pl/pomoc/affiliate-manager.html",
"en": "https://www.getresponse.com/help/affiliate-manager.html",
"es": "https://www.getresponse.com/es/ayuda/gestor-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/affiliate-manager.html",
"fr": "https://www.getresponse.com/fr/aide/gestionnaire-d-affiliation.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChjomZakljuchaetsyaRaznicaMezhduCiklicheskojIEdinovremennojProgrammahPartnjorskogoMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chjom-zakljuchaetsya-raznica-mezhdu-ciklicheskoj-i-edinovremennoj-programmah-partnjorskogo-marketinga.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-programem-partnerskim-prowizyjnym-a-premiowym.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-recurring-and-bounty-affiliate-program.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-programme-d-affiliation-recurrent-et-le-programme-d-affiliation-bounty.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-programma-di-affiliazione-ricorrente-e-quello-bounty.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-programa-de-afiliados-recurrente-y-el-programa-de-afiliados-de-recompensas.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-dem-wiederkehrenden-und-dem-praemien-affiliate-programm.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKogdaBudetVyplachenoVoznagrazhdenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kogda-budet-vyplacheno-voznagrazhdenie.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-zostanie-wyplacona-moja-premia.html",
"en": "https://www.getresponse.com/help/when-will-i-get-paid.html",
"fr": "https://www.getresponse.com/fr/aide/quand-serai-je-paye.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-me-pagaran.html",
"it": "https://www.getresponse.com/it/assistenza/quando-verro-pagato.html",
"de": "https://www.getresponse.com/de/hilfe/wann-werde-ich-bezahlt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeDeplacerMesContactsDUneListeAUneAutreALaSuiteDUnFluxDeTravailDAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversionRate = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/conversion-rate.html",
"pl": "https://www.getresponse.pl/pomoc/wspolczynnik-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-conversion.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-conversione.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKontextbezogeneWerbung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kontextbezogene-werbung.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-kontekstowa.html",
"en": "https://www.getresponse.com/help/contextual-advertising.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-contestuale.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-contextuelle.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-contextual.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContentMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/content-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-tresci.html",
"en": "https://www.getresponse.com/help/content-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-contenu.html",
"it": "https://www.getresponse.com/it/assistenza/content-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-contenido.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDurchklick = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/durchklick.html",
"pl": "https://www.getresponse.pl/pomoc/klikniecie.html",
"en": "https://www.getresponse.com/help/click-through.html",
"fr": "https://www.getresponse.com/fr/aide/click-through.html",
"it": "https://www.getresponse.com/it/assistenza/click-through.html",
"es": "https://www.getresponse.com/es/ayuda/click-through.html",
"br": "https://www.getresponse.com/pt/ajuda/click-through.html",
"pt": "https://www.getresponse.com/pt/ajuda/click-through.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKleinanzeigenwerbung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kleinanzeigenwerbung.html",
"pl": "https://www.getresponse.pl/pomoc/ogloszenia-drobne.html",
"en": "https://www.getresponse.com/help/classified-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/petites-annonces.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-classificata.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-clasificados.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCaseStudies = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/case-studies.html",
"pl": "https://www.getresponse.pl/pomoc/studia-przypadkow.html",
"en": "https://www.getresponse.com/help/case-studies.html",
"es": "https://www.getresponse.com/es/ayuda/casos-de-exito.html",
"fr": "https://www.getresponse.com/fr/aide/etudes-de-cas.html",
"it": "https://www.getresponse.com/it/assistenza/casi-di-studio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCaching = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/caching.html",
"pl": "https://www.getresponse.pl/pomoc/buforowanie-podreczne.html",
"en": "https://www.getresponse.com/help/caching.html",
"es": "https://www.getresponse.com/es/ayuda/caching.html",
"fr": "https://www.getresponse.com/fr/aide/caching.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBrowser = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/browser.html",
"es": "https://www.getresponse.com/es/ayuda/navegador.html",
"en": "https://www.getresponse.com/help/browser.html",
"pl": "https://www.getresponse.pl/pomoc/przegladarka.html",
"fr": "https://www.getresponse.com/fr/aide/navigateur.html",
"it": "https://www.getresponse.com/it/assistenza/browser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBimi = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bimi.html",
"it": "https://www.getresponse.com/it/assistenza/bimi.html",
"en": "https://www.getresponse.com/help/bimi.html",
"pl": "https://www.getresponse.pl/pomoc/bimi.html",
"es": "https://www.getresponse.com/es/ayuda/bimi.html",
"fr": "https://www.getresponse.com/fr/aide/bimi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSchreibeIchEinenPromptFuerDenKiAssistenten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuehreIchPingsUndTraceroutesAus = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html",
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIstEsMoeglichEinFormularMitEinemButtonAufMeinerWebsiteZuVerbinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPredvaritelnoZadannyjTekst = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html",
"en": "https://www.getresponse.com/help/predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacSwojSklepZGetresponseApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-swoj-sklep-z-getresponse-api.html",
"en": "https://www.getresponse.com/help/how-to-integrate-your-store-with-getresponse-api.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meinen-shop-in-die-getresponse-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-il-tuo-negozio-con-l-api-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-vash-magazin-s-api-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mi-tienda-con-la-api-de-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-votre-boutique-a-l-api-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoOboznachajutIkonkiVPravomVerhnemUgluPaneliInstrumentov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeMozhnoNajtiZapisiChatov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html",
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnDomaineDEnvoiDansVotreCompteMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannManDieProdukteInNachrichtenBewerben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerVotreBoutiqueALApiDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-votre-boutique-a-l-api-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-swoj-sklep-z-getresponse-api.html",
"en": "https://www.getresponse.com/help/how-to-integrate-your-store-with-getresponse-api.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meinen-shop-in-die-getresponse-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-il-tuo-negozio-con-l-api-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-vash-magazin-s-api-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mi-tienda-con-la-api-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPartagerMonEcranPendantUnWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-partager-mon-ecran-pendant-un-webinaire.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-mi-pantalla-durante-un-webinar.html",
"en": "https://www.getresponse.com/help/how-to-share-my-screen-during-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-ekranom-vo-vremya-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-lo-schermo-durante-un-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-bildschirm-waehrend-eines-webinars-freigeben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-moj-ekran-podczas-webinaru.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAttribuerUnTagSelonLeScoreDesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/attribuer-un-tag-selon-le-score-des-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetar-contactos-segun-su-puntuacion.html",
"en": "https://www.getresponse.com/help/tag-contacts-based-on-score.html",
"ru": "https://www.getresponse.com/ru/help-ru/dobavlenie-tegov-na-osnove-ballov.html",
"pl": "https://www.getresponse.pl/pomoc/przypisuj-tagi-w-oparciu-o-punkty-scoringowe.html",
"it": "https://www.getresponse.com/it/assistenza/tagga-i-contatti-in-base-al-punteggio.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-der-kontakte-auf-basis-des-punktestands.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarMiTiendaConLaApiDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mi-tienda-con-la-api-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-swoj-sklep-z-getresponse-api.html",
"en": "https://www.getresponse.com/help/how-to-integrate-your-store-with-getresponse-api.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meinen-shop-in-die-getresponse-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-il-tuo-negozio-con-l-api-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-vash-magazin-s-api-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-votre-boutique-a-l-api-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatVashMagazinSApiGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-vash-magazin-s-api-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-swoj-sklep-z-getresponse-api.html",
"en": "https://www.getresponse.com/help/how-to-integrate-your-store-with-getresponse-api.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meinen-shop-in-die-getresponse-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-il-tuo-negozio-con-l-api-di-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mi-tienda-con-la-api-de-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-votre-boutique-a-l-api-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitOtpravlyajushhijDomenVAkkaunteMax = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCondividereLoSchermoDuranteUnWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-condividere-lo-schermo-durante-un-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-mi-pantalla-durante-un-webinar.html",
"en": "https://www.getresponse.com/help/how-to-share-my-screen-during-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-ekranom-vo-vremya-vebinara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-mon-ecran-pendant-un-webinaire.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-bildschirm-waehrend-eines-webinars-freigeben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-moj-ekran-podczas-webinaru.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeIntegrareIlTuoNegozioConLApiDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-integrare-il-tuo-negozio-con-l-api-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-swoj-sklep-z-getresponse-api.html",
"en": "https://www.getresponse.com/help/how-to-integrate-your-store-with-getresponse-api.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meinen-shop-in-die-getresponse-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-vash-magazin-s-api-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mi-tienda-con-la-api-de-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-votre-boutique-a-l-api-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaTrascrizioneInDirettaEComeAttivarla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-trascrizione-in-diretta-e-come-attivarla.html",
"en": "https://www.getresponse.com/help/how-does-live-transcription-work-and-how-to-enable-it.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-transcripcion-en-vivo-y-como-activarla.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-transcription-en-direct-et-comment-l-activer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-transkrypcja-na-zywo-i-jak-ja-wlaczyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-rasshifrovka-translyacii-i-kak-ejo-vkljuchit.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-live-transkription-und-wie-kann-man-sie-aktivieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieLiveTranskriptionUndWieKannManSieAktivieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-live-transkription-und-wie-kann-man-sie-aktivieren.html",
"en": "https://www.getresponse.com/help/how-does-live-transcription-work-and-how-to-enable-it.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-transcripcion-en-vivo-y-como-activarla.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-transcription-en-direct-et-comment-l-activer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-transkrypcja-na-zywo-i-jak-ja-wlaczyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-rasshifrovka-translyacii-i-kak-ejo-vkljuchit.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-trascrizione-in-diretta-e-come-attivarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDasWhiteboardToolWaehrendEinesWebinars = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDasExterneLexpadUndWieVerwendeIchEs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-externe-lexpad-und-wie-verwende-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-external-lexpad-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-external-lexpad-and-how-do-i-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-lexpad-externe-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-external-lexpad-i-kak-ego-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-external-lexpad-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-external-lexpad-e-come-si-usa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaggingDerKontakteAufBasisDesPunktestands = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/tagging-der-kontakte-auf-basis-des-punktestands.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetar-contactos-segun-su-puntuacion.html",
"en": "https://www.getresponse.com/help/tag-contacts-based-on-score.html",
"ru": "https://www.getresponse.com/ru/help-ru/dobavlenie-tegov-na-osnove-ballov.html",
"pl": "https://www.getresponse.pl/pomoc/przypisuj-tagi-w-oparciu-o-punkty-scoringowe.html",
"it": "https://www.getresponse.com/it/assistenza/tagga-i-contatti-in-base-al-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/attribuer-un-tag-selon-le-score-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEineVersanddomainInMeinemMaxKontoHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchMeinenShopInDieGetresponseApi = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meinen-shop-in-die-getresponse-api.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-swoj-sklep-z-getresponse-api.html",
"en": "https://www.getresponse.com/help/how-to-integrate-your-store-with-getresponse-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-il-tuo-negozio-con-l-api-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-vash-magazin-s-api-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mi-tienda-con-la-api-de-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-votre-boutique-a-l-api-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchFormulareUndPopUpsFuerDieUrlAnzeigeEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-formulare-und-pop-ups-fuer-die-url-anzeige-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-l-affichage-de-l-url-des-formulaires-et-des-popups.html",
"en": "https://www.getresponse.com/help/how-to-set-up-forms-and-popups-url-display.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-la-visualizzazione-degli-url-dei-moduli-e-dei-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-otobrazhenie-url-v-formah-i-vsplyvajushhih-oknah.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-visualizacion-de-url-de-formularios-y-ventanas-emergentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-adresy-url-dla-formularzy-i-pop-upow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-courses.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-corsi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPartilharModelosEntreContasMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-plantillas-entre-cuentas.html",
"en": "https://www.getresponse.com/help/how-to-share-templates-between-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-i-modelli-tra-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-des-modeles-entre-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-shablonami-mezhdu-uchetnymi-zapisyami-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepniac-szablony-pomiedzy-kontami-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrzypisujTagiWOparciuOPunktyScoringowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/przypisuj-tagi-w-oparciu-o-punkty-scoringowe.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetar-contactos-segun-su-puntuacion.html",
"en": "https://www.getresponse.com/help/tag-contacts-based-on-score.html",
"ru": "https://www.getresponse.com/ru/help-ru/dobavlenie-tegov-na-osnove-ballov.html",
"it": "https://www.getresponse.com/it/assistenza/tagga-i-contatti-in-base-al-punteggio.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-der-kontakte-auf-basis-des-punktestands.html",
"fr": "https://www.getresponse.com/fr/aide/attribuer-un-tag-selon-le-score-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateYourStoreWithGetresponseApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-your-store-with-getresponse-api.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-swoj-sklep-z-getresponse-api.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meinen-shop-in-die-getresponse-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-il-tuo-negozio-con-l-api-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-vash-magazin-s-api-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mi-tienda-con-la-api-de-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-votre-boutique-a-l-api-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaggaIContattiInBaseAlPunteggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tagga-i-contatti-in-base-al-punteggio.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetar-contactos-segun-su-puntuacion.html",
"en": "https://www.getresponse.com/help/tag-contacts-based-on-score.html",
"ru": "https://www.getresponse.com/ru/help-ru/dobavlenie-tegov-na-osnove-ballov.html",
"pl": "https://www.getresponse.pl/pomoc/przypisuj-tagi-w-oparciu-o-punkty-scoringowe.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-der-kontakte-auf-basis-des-punktestands.html",
"fr": "https://www.getresponse.com/fr/aide/attribuer-un-tag-selon-le-score-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEIlExternalLexpadEComeSiUsa = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-external-lexpad-e-come-si-usa.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-external-lexpad-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-external-lexpad-and-how-do-i-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-lexpad-externe-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-external-lexpad-i-kak-ego-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-external-lexpad-i-jak-go-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-externe-lexpad-und-wie-verwende-ich-es.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereUnDominioDiInvioAlTuoAccountMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoveTrovareLeTrascrizioniDellaMiaChat = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html",
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnDominioDeEnvioATuCuentaMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddASendingDomainInYourMaxAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-sending-domain-in-your-max-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-dominio-de-envio-a-tu-cuenta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-dominio-di-invio-al-tuo-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-versanddomain-in-meinem-max-konto-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-otpravlyajushhij-domen-v-akkaunte-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-domaine-d-envoi-dans-votre-compte-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-dominio-de-envio-a-sua-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-domene-wysylkowa-do-twojego-konta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDobavlenieTegovNaOsnoveBallov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dobavlenie-tegov-na-osnove-ballov.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetar-contactos-segun-su-puntuacion.html",
"en": "https://www.getresponse.com/help/tag-contacts-based-on-score.html",
"pl": "https://www.getresponse.pl/pomoc/przypisuj-tagi-w-oparciu-o-punkty-scoringowe.html",
"it": "https://www.getresponse.com/it/assistenza/tagga-i-contatti-in-base-al-punteggio.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-der-kontakte-auf-basis-des-punktestands.html",
"fr": "https://www.getresponse.com/fr/aide/attribuer-un-tag-selon-le-score-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodelitsyaEkranomVoVremyaVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-ekranom-vo-vremya-vebinara.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-mi-pantalla-durante-un-webinar.html",
"en": "https://www.getresponse.com/help/how-to-share-my-screen-during-a-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-lo-schermo-durante-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-mon-ecran-pendant-un-webinaire.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-bildschirm-waehrend-eines-webinars-freigeben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-moj-ekran-podczas-webinaru.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEtiquetarContactosSegunSuPuntuacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/etiquetar-contactos-segun-su-puntuacion.html",
"en": "https://www.getresponse.com/help/tag-contacts-based-on-score.html",
"ru": "https://www.getresponse.com/ru/help-ru/dobavlenie-tegov-na-osnove-ballov.html",
"pl": "https://www.getresponse.pl/pomoc/przypisuj-tagi-w-oparciu-o-punkty-scoringowe.html",
"it": "https://www.getresponse.com/it/assistenza/tagga-i-contatti-in-base-al-punteggio.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-der-kontakte-auf-basis-des-punktestands.html",
"fr": "https://www.getresponse.com/fr/aide/attribuer-un-tag-selon-le-score-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTagContactsBasedOnScore = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tag-contacts-based-on-score.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetar-contactos-segun-su-puntuacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/dobavlenie-tegov-na-osnove-ballov.html",
"pl": "https://www.getresponse.pl/pomoc/przypisuj-tagi-w-oparciu-o-punkty-scoringowe.html",
"it": "https://www.getresponse.com/it/assistenza/tagga-i-contatti-in-base-al-punteggio.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-der-kontakte-auf-basis-des-punktestands.html",
"fr": "https://www.getresponse.com/fr/aide/attribuer-un-tag-selon-le-score-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieIkonyZnajdujaSieWPrawymGornymRoguMojegoPulpitu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeIconeNellAngoloInAltoADestraDellaMiaDashboard = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCompartirMiPantallaDuranteUnWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-compartir-mi-pantalla-durante-un-webinar.html",
"en": "https://www.getresponse.com/help/how-to-share-my-screen-during-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-ekranom-vo-vremya-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-lo-schermo-durante-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-mon-ecran-pendant-un-webinaire.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-bildschirm-waehrend-eines-webinars-freigeben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-moj-ekran-podczas-webinaru.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToShareMyScreenDuringAWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-share-my-screen-during-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-mi-pantalla-durante-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-ekranom-vo-vremya-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-lo-schermo-durante-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-mon-ecran-pendant-un-webinaire.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-bildschirm-waehrend-eines-webinars-freigeben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-moj-ekran-podczas-webinaru.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamnayaStatya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-statya.html",
"pl": "https://www.getresponse.pl/pomoc/artykul-sponsorowany.html",
"en": "https://www.getresponse.com/help/advertorial.html",
"es": "https://www.getresponse.com/es/ayuda/publirreportaje.html",
"fr": "https://www.getresponse.com/fr/aide/publireportage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamnyjBjudzhet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-bjudzhet.html",
"es": "https://www.getresponse.com/es/ayuda/presupuesto-publicitario.html",
"en": "https://www.getresponse.com/help/advertising-budget.html",
"fr": "https://www.getresponse.com/fr/aide/budget-publicitaire.html",
"it": "https://www.getresponse.com/it/assistenza/budget-pubblicitario.html",
"de": "https://www.getresponse.com/de/hilfe/werbungsbudget.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamnayaSet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-set.html",
"pl": "https://www.getresponse.pl/pomoc/siec-reklamowa.html",
"en": "https://www.getresponse.com/help/ad-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-publicitaria.html",
"it": "https://www.getresponse.com/it/assistenza/rete-pubblicitaria.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-publicitaire.html",
"de": "https://www.getresponse.com/de/hilfe/werbenetzwerk.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRasshireniyaObyavlenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/rasshireniya-obyavlenij.html",
"pl": "https://www.getresponse.pl/pomoc/rozszerzenia-reklam.html",
"en": "https://www.getresponse.com/help/ad-extensions.html",
"es": "https://www.getresponse.com/es/ayuda/extensiones-de-anuncios.html",
"fr": "https://www.getresponse.com/fr/aide/extensions-publicitaires.html",
"it": "https://www.getresponse.com/it/assistenza/estensioni-dell-annuncio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotaetRasshifrovkaTranslyaciiIKakEjoVkljuchit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-rasshifrovka-translyacii-i-kak-ejo-vkljuchit.html",
"en": "https://www.getresponse.com/help/how-does-live-transcription-work-and-how-to-enable-it.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-transcripcion-en-vivo-y-como-activarla.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-transcription-en-direct-et-comment-l-activer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-transkrypcja-na-zywo-i-jak-ja-wlaczyc.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-live-transkription-und-wie-kann-man-sie-aktivieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-trascrizione-in-diretta-e-come-attivarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZTablicyPodczasWebinaru = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarLaVisualizacionDeUrlDeFormulariosYVentanasEmergentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-visualizacion-de-url-de-formularios-y-ventanas-emergentes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-l-affichage-de-l-url-des-formulaires-et-des-popups.html",
"en": "https://www.getresponse.com/help/how-to-set-up-forms-and-popups-url-display.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-la-visualizzazione-degli-url-dei-moduli-e-dei-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-otobrazhenie-url-v-formah-i-vsplyvajushhih-oknah.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-formulare-und-pop-ups-fuer-die-url-anzeige-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-adresy-url-dla-formularzy-i-pop-upow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZielgruppensegmentierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/zielgruppensegmentierung.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-odbiorcow.html",
"en": "https://www.getresponse.com/help/audience-segmentation.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-del-pubblico.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-l-audience.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-audiencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-auditorii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZielgruppe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/zielgruppe.html",
"pl": "https://www.getresponse.pl/pomoc/grupa-docelowa.html",
"en": "https://www.getresponse.com/help/audience.html",
"es": "https://www.getresponse.com/es/ayuda/audiencia.html",
"fr": "https://www.getresponse.com/fr/aide/public.html",
"it": "https://www.getresponse.com/it/assistenza/pubblico.html",
"ru": "https://www.getresponse.com/ru/help-ru/auditoriya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnimiertesGif = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/animiertes-gif.html",
"pl": "https://www.getresponse.pl/pomoc/animowany-gif.html",
"en": "https://www.getresponse.com/help/animated-gif.html",
"es": "https://www.getresponse.com/es/ayuda/gif-animado.html",
"fr": "https://www.getresponse.com/fr/aide/gif-anime.html",
"it": "https://www.getresponse.com/it/assistenza/gif-animata.html",
"ru": "https://www.getresponse.com/ru/help-ru/animirovannyj-gif.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAltText = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/alt-text.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-alt.html",
"en": "https://www.getresponse.com/help/alt-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-alt.html",
"fr": "https://www.getresponse.com/fr/aide/texte-alt.html",
"it": "https://www.getresponse.com/it/assistenza/testo-alt.html",
"ru": "https://www.getresponse.com/ru/help-ru/alt-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitOtobrazhenieUrlVFormahIVsplyvajushhihOknah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-otobrazhenie-url-v-formah-i-vsplyvajushhih-oknah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-l-affichage-de-l-url-des-formulaires-et-des-popups.html",
"en": "https://www.getresponse.com/help/how-to-set-up-forms-and-popups-url-display.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-la-visualizzazione-degli-url-dei-moduli-e-dei-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-visualizacion-de-url-de-formularios-y-ventanas-emergentes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-formulare-und-pop-ups-fuer-die-url-anzeige-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-adresy-url-dla-formularzy-i-pop-upow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeImpostareLaVisualizzazioneDegliUrlDeiModuliEDeiPopup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-impostare-la-visualizzazione-degli-url-dei-moduli-e-dei-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-l-affichage-de-l-url-des-formulaires-et-des-popups.html",
"en": "https://www.getresponse.com/help/how-to-set-up-forms-and-popups-url-display.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-otobrazhenie-url-v-formah-i-vsplyvajushhih-oknah.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-visualizacion-de-url-de-formularios-y-ventanas-emergentes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-formulare-und-pop-ups-fuer-die-url-anzeige-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-adresy-url-dla-formularzy-i-pop-upow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAidaModellImMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/aida-modell-im-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-modelu-aida.html",
"en": "https://www.getresponse.com/help/aida-model-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-marketing-aida.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-marketing-aida.html",
"it": "https://www.getresponse.com/it/assistenza/modello-marketing-aida.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaya-model-aida.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAiMarketingTools = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ai-marketing-tools.html",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-marketingowe-ai.html",
"en": "https://www.getresponse.com/help/ai-marketing-tools.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-de-ia.html",
"it": "https://www.getresponse.com/it/assistenza/ai-marketing-tools.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/instrumenty-marketinga-na-baze-ii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateNetzwerk = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-netzwerk.html",
"pl": "https://www.getresponse.pl/pomoc/siec-afiliacyjna.html",
"en": "https://www.getresponse.com/help/affiliate-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/rete-di-affiliazione.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-d-affiliation.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnjorskaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateManager = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-manager.html",
"pl": "https://www.getresponse.pl/pomoc/affiliate-manager.html",
"en": "https://www.getresponse.com/help/affiliate-manager.html",
"es": "https://www.getresponse.com/es/ayuda/gestor-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/affiliate-manager.html",
"fr": "https://www.getresponse.com/fr/aide/gestionnaire-d-affiliation.html",
"ru": "https://www.getresponse.com/ru/help-ru/menedzher-po-rabote-s-partnjorami.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConfigurerLAffichageDeLUrlDesFormulairesEtDesPopups = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-l-affichage-de-l-url-des-formulaires-et-des-popups.html",
"en": "https://www.getresponse.com/help/how-to-set-up-forms-and-popups-url-display.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-la-visualizzazione-degli-url-dei-moduli-e-dei-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-otobrazhenie-url-v-formah-i-vsplyvajushhih-oknah.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-visualizacion-de-url-de-formularios-y-ventanas-emergentes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-formulare-und-pop-ups-fuer-die-url-anzeige-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-adresy-url-dla-formularzy-i-pop-upow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSetUpFormsAndPopupsUrlDisplay = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-set-up-forms-and-popups-url-display.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-l-affichage-de-l-url-des-formulaires-et-des-popups.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-la-visualizzazione-degli-url-dei-moduli-e-dei-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-otobrazhenie-url-v-formah-i-vsplyvajushhih-oknah.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-la-visualizacion-de-url-de-formularios-y-ventanas-emergentes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-formulare-und-pop-ups-fuer-die-url-anzeige-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-adresy-url-dla-formularzy-i-pop-upow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeExternalLexpadIKakEgoIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-external-lexpad-i-kak-ego-ispolzovat.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-external-lexpad-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-external-lexpad-and-how-do-i-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-lexpad-externe-et-comment-l-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-external-lexpad-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-external-lexpad-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-externe-lexpad-und-wie-verwende-ich-es.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElExternalLexpadYComoSeUtiliza = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-external-lexpad-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-external-lexpad-and-how-do-i-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-lexpad-externe-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-external-lexpad-i-kak-ego-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-external-lexpad-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-external-lexpad-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-externe-lexpad-und-wie-verwende-ich-es.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWerbungsbudget = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/werbungsbudget.html",
"es": "https://www.getresponse.com/es/ayuda/presupuesto-publicitario.html",
"en": "https://www.getresponse.com/help/advertising-budget.html",
"fr": "https://www.getresponse.com/fr/aide/budget-publicitaire.html",
"it": "https://www.getresponse.com/it/assistenza/budget-pubblicitario.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-bjudzhet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWerbenetzwerk = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/werbenetzwerk.html",
"pl": "https://www.getresponse.pl/pomoc/siec-reklamowa.html",
"en": "https://www.getresponse.com/help/ad-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-publicitaria.html",
"it": "https://www.getresponse.com/it/assistenza/rete-pubblicitaria.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-publicitaire.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeZnalezcTranskrypcjeCzatow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatInstrumentMarkernayaDoskaVoVremyaVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeLexpadExterneEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-lexpad-externe-et-comment-l-utiliser.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-external-lexpad-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-external-lexpad-and-how-do-i-use-it.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-external-lexpad-i-kak-ego-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-external-lexpad-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-external-lexpad-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-externe-lexpad-und-wie-verwende-ich-es.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindDieSymboleInDerOberenRechtenEckeMeinesDashboards = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchMeineChatProtokolle = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKrossProdazhaDlyaPartnjorov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kross-prodazha-dlya-partnjorov.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-cross-selling.html",
"en": "https://www.getresponse.com/help/affiliate-cross-selling.html",
"it": "https://www.getresponse.com/it/assistenza/cross-selling-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/cross-selling-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/venta-cruzada-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-croisees-d-affilie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnzeigenkopie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/anzeigenkopie.html",
"pl": "https://www.getresponse.pl/pomoc/ad-copy.html",
"en": "https://www.getresponse.com/help/ad-copy.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-publicitario.html",
"it": "https://www.getresponse.com/it/assistenza/il-testo-pubblicitario.html",
"fr": "https://www.getresponse.com/fr/aide/le-texte-publicitaire.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiCorrespondentLesIconesSitueesDansLeCoinSuperieurDroitDeMonTableauDeBord = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuTrouverLesTranscriptionsDeMesChats = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaOSuportePorChatParaContasMaxMax2 = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOFtpEComoOUtilizo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimiteDeFrequencia = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"fr": "https://www.getresponse.com/fr/aide/plafond-de-frequence.html",
"en": "https://www.getresponse.com/help/frequency-cap.html",
"it": "https://www.getresponse.com/it/assistenza/limite-di-frequenzafrequency-cap.html",
"pl": "https://www.getresponse.pl/pomoc/limit-czestotliwosci.html",
"es": "https://www.getresponse.com/es/ayuda/limite-de-frecuencia.html",
"de": "https://www.getresponse.com/de/hilfe/frequenzbegrenzung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTargetingGeografico = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"fr": "https://www.getresponse.com/fr/aide/geo-ciblage.html",
"en": "https://www.getresponse.com/help/geo-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-geografico.html",
"pl": "https://www.getresponse.pl/pomoc/geotargetowanie.html",
"es": "https://www.getresponse.com/es/ayuda/geolocalizacion.html",
"de": "https://www.getresponse.com/de/hilfe/geo-targeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVisualizacoesDePagina = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/vue-de-la-page.html",
"en": "https://www.getresponse.com/help/page-view.html",
"it": "https://www.getresponse.com/it/assistenza/vista-della-paginapage-view.html",
"pl": "https://www.getresponse.pl/pomoc/odslony-strony.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-vista.html",
"de": "https://www.getresponse.com/de/hilfe/seitenaufruf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontrarAsTranscricoesDasMinhasConversasDeChat = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAAtribuicaoDeReceitasEComoFunciona = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueRepresentanLosIconosDeLaEsquinaSuperiorDerechaDeMiPanelDeControl = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondeEncontrarMisTranscripcionesDeChat = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsExternalLexpadAndHowDoIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-external-lexpad-and-how-do-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-external-lexpad-y-como-se-utiliza.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-lexpad-externe-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-external-lexpad-i-kak-ego-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-external-lexpad-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-external-lexpad-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-externe-lexpad-und-wie-verwende-ich-es.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannManDieEmailAdressenAufEineSichereListeSetzenUmEineReibungsloseZustellungZuVersichern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-email-adressen-auf-eine-sichere-liste-setzen-um-eine-reibungslose-zustellung-zu-versichern.html",
"it": "https://www.getresponse.com/it/assistenza/come-inserire-indirizzi-e-mail-nell-elenco-indirizzi-attendibili-per-garantire-un-recapito-regolare-delle-e-mail.html",
"en": "https://www.getresponse.com/help/how-to-safelist-email-addresses-to-ensure-smooth-email-delivery.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-lista-segura-de-direcciones-de-correo-electronico-para-garantizar-un-envio-sin-problemas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-etablir-une-liste-d-adresses-electroniques-sures-pour-garantir-la-bonne-distribution-des-courriels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vnesti-adresa-elektronnoj-pochty-v-spisok-bezopasnyh-otpravitelej-chtoby-obespechit-besperebojnuju-dostavku-pochty.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarCodigosPromocionaisAoMeuFormularioPopUpOuConteudoEmLinha = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-codes-promo-a-mon-formulaire-pop-up-ou-a-mon-contenu-en-ligne.html",
"en": "https://www.getresponse.com/help/how-to-add-promo-codes-to-my-popup-form-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-promokod-vo-vsplyvajushhuju-ili-vstroennuju-formu.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-codigos-promocionales-a-mi-formulario-emergente-o-fragmento-de-contenido-en-linea.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kody-promocyjne-do-pop-upow-lub-tresci-wbudowanej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-aktionscodes-zu-meinem-pop-up-formular-oder-inline-inhalt-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheIconsInTheTopRightCornerOfMyDashboard = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-icons-in-the-top-right-corner-of-my-dashboard.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-symbole-in-der-oberen-rechten-ecke-meines-dashboards.html",
"es": "https://www.getresponse.com/es/ayuda/que-representan-los-iconos-de-la-esquina-superior-derecha-de-mi-panel-de-control.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-icones-no-canto-superior-direito-do-meu-painel-de-controlo.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-correspondent-les-icones-situees-dans-le-coin-superieur-droit-de-mon-tableau-de-bord.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ikony-znajduja-sie-w-prawym-gornym-rogu-mojego-pulpitu.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-icone-nell-angolo-in-alto-a-destra-della-mia-dashboard.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oboznachajut-ikonki-v-pravom-verhnem-uglu-paneli-instrumentov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereToFindMyChatTranscripts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-to-find-my-chat-transcripts.html",
"es": "https://www.getresponse.com/es/ayuda/donde-encontrar-mis-transcripciones-de-chat.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-as-transcricoes-das-minhas-conversas-de-chat.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-transcriptions-de-mes-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-chat-protokolle.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-transkrypcje-czatow.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovare-le-trascrizioni-della-mia-chat.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-najti-zapisi-chatov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosELAttribuzioneDelleEntrateEComeFunziona = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareLoStrumentoDellaLavagnaDuranteUnWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClickThrough = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/click-through.html",
"pt": "https://www.getresponse.com/pt/ajuda/click-through.html",
"pl": "https://www.getresponse.pl/pomoc/klikniecie.html",
"en": "https://www.getresponse.com/help/click-through.html",
"fr": "https://www.getresponse.com/fr/aide/click-through.html",
"it": "https://www.getresponse.com/it/assistenza/click-through.html",
"es": "https://www.getresponse.com/es/ayuda/click-through.html",
"de": "https://www.getresponse.com/de/hilfe/durchklick.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlockierenVonWerbung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/blockieren-von-werbung.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-reklam.html",
"en": "https://www.getresponse.com/help/ad-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-degli-annunci.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-des-publicites.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-reklamy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddACookiesNotificationToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-cookies-notification-to-my-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateMyLandingPageWithGoogleTagManager = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-my-landing-page-with-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerLandingPageEineEigeneSubdomainZuweisen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAAutomatizacaoDoMarketing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-du-marketing.html",
"en": "https://www.getresponse.com/help/marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/marketing-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDeiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenDerSendedomaneUndDerVonAdresse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-der-sendedomane-und-der-von-adresse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-dominio-di-invio-e-l-indirizzo-da.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-the-sending-domain-and-from-address.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-domena-wysylkowa-a-adresem-nadawcy-od.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-dominio-remitente-y-la-direccion-de.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-domaine-denvoi-et-ladresse-from.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-domenom-dlya-otpravki-i-adresom-otpravitelya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieGeneriereIchAnzeigeninhalteMitDemKiAssistentenInPaidAds = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-generiere-ich-anzeigeninhalte-mit-dem-ki-assistenten-in-paid-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-generer-du-contenu-publicitaire-a-l-aide-de-l-assistant-ia-dans-les-publicites-payantes.html",
"en": "https://www.getresponse.com/help/how-to-generate-ad-content-using-ai-assistant-in-paid-ads.html",
"es": "https://www.getresponse.com/es/ayuda/como-generar-contenido-publicitario-utilizando-el-asistente-ia-en-anuncios-de-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-generare-contenuti-pubblicitari-utilizzando-ai-assistant-negli-annunci-a-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wygenerowac-tresc-reklamy-za-pomoca-asystenta-ai-w-platnych-reklamach.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstFtpUndWieBenutzeIchEs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestPrzypisywaniePrzychoduIJakDziala = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVerlassenerWarenkorb = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/verlassener-warenkorb.html",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/abandoned-cart.html",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne.html",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaya-korzina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostABTests = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/a-b-tests.html",
"pl": "https://www.getresponse.pl/pomoc/testy-a-b.html",
"en": "https://www.getresponse.com/help/a-b-tests.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/a-b-tests.html",
"fr": "https://www.getresponse.com/fr/aide/tests-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/a-b-testirovanie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstSpfUndWieKannManIhnKonfigurieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKiedyZostanieWyplaconaMojaPremia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kiedy-zostanie-wyplacona-moja-premia.html",
"en": "https://www.getresponse.com/help/when-will-i-get-paid.html",
"fr": "https://www.getresponse.com/fr/aide/quand-serai-je-paye.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-me-pagaran.html",
"it": "https://www.getresponse.com/it/assistenza/quando-verro-pagato.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-budet-vyplacheno-voznagrazhdenie.html",
"de": "https://www.getresponse.com/de/hilfe/wann-werde-ich-bezahlt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNetzwerkeffekt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/netzwerkeffekt.html",
"fr": "https://www.getresponse.com/fr/aide/effet-de-reseau.html",
"en": "https://www.getresponse.com/help/network-effect.html",
"pl": "https://www.getresponse.pl/pomoc/efekt-sieci.html",
"es": "https://www.getresponse.com/es/ayuda/efecto-red.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLAttributionDeRevenusEtCommentFonctionneTElle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstUmsatzzuweisungUndWieFunktioniertSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaAtribucionDeIngresosYComoFunciona = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeGeneratorIiZagolovkovDlyaVebinarovIKakSNimRabotat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsRevenueAttributionAndHowDoesItWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-revenue-attribution-and-how-does-it-work.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-przypisywanie-przychodu-i-jak-dziala.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-atribucion-de-ingresos-y-como-funciona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-umsatzzuweisung-und-wie-funktioniert-sie.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-attribution-de-revenus-et-comment-fonctionne-t-elle.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-attribuzione-delle-entrate-e-come-funziona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-atribuicao-de-receitas-e-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pripisannyj-dohod-i-kak-on-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIlDeepLinkingEDisponibile = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/il-deep-linking-e-disponibile.html",
"fr": "https://www.getresponse.com/fr/aide/les-liens-profonds-sont-ils-disponibles.html",
"en": "https://www.getresponse.com/help/is-deep-linking-available.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dostepny-jest-deep-linking.html",
"es": "https://www.getresponse.com/es/ayuda/esta-disponible-el-enlace-de-profundidad.html",
"de": "https://www.getresponse.com/de/hilfe/ist-deep-linking-verfuegbar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuandoVerroPagato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quando-verro-pagato.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-zostanie-wyplacona-moja-premia.html",
"en": "https://www.getresponse.com/help/when-will-i-get-paid.html",
"fr": "https://www.getresponse.com/fr/aide/quand-serai-je-paye.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-me-pagaran.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-budet-vyplacheno-voznagrazhdenie.html",
"de": "https://www.getresponse.com/de/hilfe/wann-werde-ich-bezahlt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeSiTieneTracciaDelleMieVenditeEDelleMiePrestazioni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-si-tiene-traccia-delle-mie-vendite-e-delle-mie-prestazioni.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivez-vous-mes-ventes-et-mes-performances.html",
"en": "https://www.getresponse.com/help/how-do-you-track-my-sales-and-performance.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzimy-twoja-sprzedaz-i-wyniki.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacen-seguimiento-de-mis-ventas-y-resultados.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolgst-du-meinen-umsatz-und-meine-leistung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJavascript = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/javascript.html",
"en": "https://www.getresponse.com/help/javascript.html",
"fr": "https://www.getresponse.com/fr/aide/javascript.html",
"pl": "https://www.getresponse.pl/pomoc/javascript.html",
"es": "https://www.getresponse.com/es/ayuda/javascript.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenDemWiederkehrendenUndDemPraemienAffiliateProgramm = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-dem-wiederkehrenden-und-dem-praemien-affiliate-programm.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-programem-partnerskim-prowizyjnym-a-premiowym.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-recurring-and-bounty-affiliate-program.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-programme-d-affiliation-recurrent-et-le-programme-d-affiliation-bounty.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-programma-di-affiliazione-ricorrente-e-quello-bounty.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-programa-de-afiliados-recurrente-y-el-programa-de-afiliados-de-recompensas.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chjom-zakljuchaetsya-raznica-mezhdu-ciklicheskoj-i-edinovremennoj-programmah-partnjorskogo-marketinga.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEstaDisponibleElEnlaceDeProfundidad = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/esta-disponible-el-enlace-de-profundidad.html",
"fr": "https://www.getresponse.com/fr/aide/les-liens-profonds-sont-ils-disponibles.html",
"en": "https://www.getresponse.com/help/is-deep-linking-available.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dostepny-jest-deep-linking.html",
"it": "https://www.getresponse.com/it/assistenza/il-deep-linking-e-disponibile.html",
"de": "https://www.getresponse.com/de/hilfe/ist-deep-linking-verfuegbar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoHacenSeguimientoDeMisVentasYResultados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-hacen-seguimiento-de-mis-ventas-y-resultados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivez-vous-mes-ventes-et-mes-performances.html",
"en": "https://www.getresponse.com/help/how-do-you-track-my-sales-and-performance.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzimy-twoja-sprzedaz-i-wyniki.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-tiene-traccia-delle-mie-vendite-e-delle-mie-prestazioni.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolgst-du-meinen-umsatz-und-meine-leistung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCuandoMePagaran = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuando-me-pagaran.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-zostanie-wyplacona-moja-premia.html",
"en": "https://www.getresponse.com/help/when-will-i-get-paid.html",
"fr": "https://www.getresponse.com/fr/aide/quand-serai-je-paye.html",
"it": "https://www.getresponse.com/it/assistenza/quando-verro-pagato.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-budet-vyplacheno-voznagrazhdenie.html",
"de": "https://www.getresponse.com/de/hilfe/wann-werde-ich-bezahlt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSledzimyTwojaSprzedazIWyniki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sledzimy-twoja-sprzedaz-i-wyniki.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivez-vous-mes-ventes-et-mes-performances.html",
"en": "https://www.getresponse.com/help/how-do-you-track-my-sales-and-performance.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacen-seguimiento-de-mis-ventas-y-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-tiene-traccia-delle-mie-vendite-e-delle-mie-prestazioni.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolgst-du-meinen-umsatz-und-meine-leistung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyDostepnyJestDeepLinking = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-dostepny-jest-deep-linking.html",
"fr": "https://www.getresponse.com/fr/aide/les-liens-profonds-sont-ils-disponibles.html",
"en": "https://www.getresponse.com/help/is-deep-linking-available.html",
"es": "https://www.getresponse.com/es/ayuda/esta-disponible-el-enlace-de-profundidad.html",
"it": "https://www.getresponse.com/it/assistenza/il-deep-linking-e-disponibile.html",
"de": "https://www.getresponse.com/de/hilfe/ist-deep-linking-verfuegbar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLesLiensProfondsSontIlsDisponibles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/les-liens-profonds-sont-ils-disponibles.html",
"en": "https://www.getresponse.com/help/is-deep-linking-available.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dostepny-jest-deep-linking.html",
"es": "https://www.getresponse.com/es/ayuda/esta-disponible-el-enlace-de-profundidad.html",
"it": "https://www.getresponse.com/it/assistenza/il-deep-linking-e-disponibile.html",
"de": "https://www.getresponse.com/de/hilfe/ist-deep-linking-verfuegbar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreElProgramaDeAfiliadosRecurrenteYElProgramaDeAfiliadosDeRecompensas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-programa-de-afiliados-recurrente-y-el-programa-de-afiliados-de-recompensas.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-programem-partnerskim-prowizyjnym-a-premiowym.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-recurring-and-bounty-affiliate-program.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-programme-d-affiliation-recurrent-et-le-programme-d-affiliation-bounty.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-programma-di-affiliazione-ricorrente-e-quello-bounty.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-dem-wiederkehrenden-und-dem-praemien-affiliate-programm.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chjom-zakljuchaetsya-raznica-mezhdu-ciklicheskoj-i-edinovremennoj-programmah-partnjorskogo-marketinga.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaHerramientaPizarraInteractivaDuranteUnWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOnlajnKursDlyaPartnerov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraIlProgrammaDiAffiliazioneRicorrenteEQuelloBounty = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-programma-di-affiliazione-ricorrente-e-quello-bounty.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-programem-partnerskim-prowizyjnym-a-premiowym.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-recurring-and-bounty-affiliate-program.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-programme-d-affiliation-recurrent-et-le-programme-d-affiliation-bounty.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-programa-de-afiliados-recurrente-y-el-programa-de-afiliados-de-recompensas.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-dem-wiederkehrenden-und-dem-praemien-affiliate-programm.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chjom-zakljuchaetsya-raznica-mezhdu-ciklicheskoj-i-edinovremennoj-programmah-partnjorskogo-marketinga.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosELFtpEComeLoUtilizzo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivezVousMesVentesEtMesPerformances = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivez-vous-mes-ventes-et-mes-performances.html",
"en": "https://www.getresponse.com/help/how-do-you-track-my-sales-and-performance.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzimy-twoja-sprzedaz-i-wyniki.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacen-seguimiento-de-mis-ventas-y-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-tiene-traccia-delle-mie-vendite-e-delle-mie-prestazioni.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolgst-du-meinen-umsatz-und-meine-leistung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuandSeraiJePaye = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quand-serai-je-paye.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-zostanie-wyplacona-moja-premia.html",
"en": "https://www.getresponse.com/help/when-will-i-get-paid.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-me-pagaran.html",
"it": "https://www.getresponse.com/it/assistenza/quando-verro-pagato.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-budet-vyplacheno-voznagrazhdenie.html",
"de": "https://www.getresponse.com/de/hilfe/wann-werde-ich-bezahlt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaTranskrypcjaNaZywoIJakJaWlaczyc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-transkrypcja-na-zywo-i-jak-ja-wlaczyc.html",
"en": "https://www.getresponse.com/help/how-does-live-transcription-work-and-how-to-enable-it.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-transcripcion-en-vivo-y-como-activarla.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-transcription-en-direct-et-comment-l-activer.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-rasshifrovka-translyacii-i-kak-ejo-vkljuchit.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-live-transkription-und-wie-kann-man-sie-aktivieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-trascrizione-in-diretta-e-come-attivarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrisvoenieTegovPartnjoram = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingBocaABoca = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-bouche-a-oreille.html",
"en": "https://www.getresponse.com/help/word-of-mouth-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-tramite-passaparola.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-boca-a-boca.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-szeptany.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTrafegoDoSitioWeb = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/trafic-sur-le-site-web.html",
"en": "https://www.getresponse.com/help/website-traffic.html",
"it": "https://www.getresponse.com/it/assistenza/traffico-del-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/trafico-web.html",
"pl": "https://www.getresponse.pl/pomoc/ruch-na-stronie-internetowej.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreferenciasDeCookies = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"pt": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"pl": "https://www.getresponse.pl/pomoc/preferencje-dotyczace-plikow-cookie.html",
"en": "https://www.getresponse.com/help/cookie-preferences.html",
"fr": "https://www.getresponse.com/fr/aide/cookie-preferences.html",
"it": "https://www.getresponse.com/it/assistenza/preferenze-sui-cookie.html",
"es": "https://www.getresponse.com/es/ayuda/preferencias-de-cookies.html",
"de": "https://www.getresponse.com/de/hilfe/cookie-einstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoYouTrackMySalesAndPerformance = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-you-track-my-sales-and-performance.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivez-vous-mes-ventes-et-mes-performances.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzimy-twoja-sprzedaz-i-wyniki.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacen-seguimiento-de-mis-ventas-y-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-tiene-traccia-delle-mie-vendite-e-delle-mie-prestazioni.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolgst-du-meinen-umsatz-und-meine-leistung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIsDeepLinkingAvailable = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/is-deep-linking-available.html",
"fr": "https://www.getresponse.com/fr/aide/les-liens-profonds-sont-ils-disponibles.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dostepny-jest-deep-linking.html",
"es": "https://www.getresponse.com/es/ayuda/esta-disponible-el-enlace-de-profundidad.html",
"it": "https://www.getresponse.com/it/assistenza/il-deep-linking-e-disponibile.html",
"de": "https://www.getresponse.com/de/hilfe/ist-deep-linking-verfuegbar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhenWillIGetPaid = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/when-will-i-get-paid.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-zostanie-wyplacona-moja-premia.html",
"fr": "https://www.getresponse.com/fr/aide/quand-serai-je-paye.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-me-pagaran.html",
"it": "https://www.getresponse.com/it/assistenza/quando-verro-pagato.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-budet-vyplacheno-voznagrazhdenie.html",
"de": "https://www.getresponse.com/de/hilfe/wann-werde-ich-bezahlt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaMiedzyProgramemPartnerskimProwizyjnymAPremiowym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-programem-partnerskim-prowizyjnym-a-premiowym.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-recurring-and-bounty-affiliate-program.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-programme-d-affiliation-recurrent-et-le-programme-d-affiliation-bounty.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-programma-di-affiliazione-ricorrente-e-quello-bounty.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-programa-de-afiliados-recurrente-y-el-programa-de-afiliados-de-recompensas.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-dem-wiederkehrenden-und-dem-praemien-affiliate-programm.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chjom-zakljuchaetsya-raznica-mezhdu-ciklicheskoj-i-edinovremennoj-programmah-partnjorskogo-marketinga.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStronaInternetowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/strona-internetowa.html",
"fr": "https://www.getresponse.com/fr/aide/site-web.html",
"en": "https://www.getresponse.com/help/website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRuchNaStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ruch-na-stronie-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/trafic-sur-le-site-web.html",
"en": "https://www.getresponse.com/help/website-traffic.html",
"it": "https://www.getresponse.com/it/assistenza/traffico-del-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/trafico-web.html",
"br": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomosciPowitalne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-powitalne.html",
"fr": "https://www.getresponse.com/fr/aide/courriels-de-bienvenue.html",
"en": "https://www.getresponse.com/help/welcome-emails.html",
"it": "https://www.getresponse.com/it/assistenza/e-mail-di-benvenuto.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida.html",
"de": "https://www.getresponse.com/de/hilfe/willkommensmail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingSzeptany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-szeptany.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-bouche-a-oreille.html",
"en": "https://www.getresponse.com/help/word-of-mouth-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-tramite-passaparola.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-boca-a-boca.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamaNaYoutube = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/reklama-na-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-su-youtube.html",
"en": "https://www.getresponse.com/help/youtube-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-en-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDominio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/dominio.html",
"fr": "https://www.getresponse.com/fr/aide/domaine.html",
"en": "https://www.getresponse.com/help/domain.html",
"it": "https://www.getresponse.com/it/assistenza/dominio.html",
"pl": "https://www.getresponse.pl/pomoc/domena.html",
"es": "https://www.getresponse.com/es/ayuda/dominio.html",
"de": "https://www.getresponse.com/de/hilfe/domain.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDigital = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-numerique.html",
"en": "https://www.getresponse.com/help/digital-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/digital-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-cyfrowy.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitales-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMonitorizarAConversaoDeRecomendacoesDeIaNoGoogleAnalytics4 = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-rekomendacji-ai-w-google-analytics-4.html",
"en": "https://www.getresponse.com/help/how-to-track-ai-recommendations-conversion-in-google-analytics-4.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-recommandations-ia-dans-google-analytics-4.html",
"es": "https://www.getresponse.com/es/ayuda/como-realizar-un-seguimiento-de-la-conversion-de-las-recomendaciones-de-ia-en-google-analytics-4.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-la-conversione-dei-suggerimenti-ai-in-google-analytics-4.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtivarUmPopupComBaseNumPedidoFeitoOuNumCarrinhoAtualizado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-in-base-all-ordine-effettuato-o-al-carrello-aggiornato.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-placed-order-or-updated-cart.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-na-podstawie-zlozonego-zamowienia-lub-aktualizacji-koszyka.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-un-pedido-realizado-o-un-carrito-actualizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iniciirovat-vsplyvajushhee-okno-na-osnove-oformlennogo-zakaza-ili-obnovlennoj-korziny.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loese-ich-ein-pop-up-aus-wenn-ich-eine-bestellung-aufgegeben-oder-den-warenkorb-aktualisiert-habe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSkoringPartnjorov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html",
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreLeProgrammeDAffiliationRecurrentEtLeProgrammeDAffiliationBounty = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-programme-d-affiliation-recurrent-et-le-programme-d-affiliation-bounty.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-programem-partnerskim-prowizyjnym-a-premiowym.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-recurring-and-bounty-affiliate-program.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-programma-di-affiliazione-ricorrente-e-quello-bounty.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-programa-de-afiliados-recurrente-y-el-programa-de-afiliados-de-recompensas.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-dem-wiederkehrenden-und-dem-praemien-affiliate-programm.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chjom-zakljuchaetsya-raznica-mezhdu-ciklicheskoj-i-edinovremennoj-programmah-partnjorskogo-marketinga.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBadaniaUzytkownikow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/badania-uzytkownikow.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-les-utilisateurs.html",
"en": "https://www.getresponse.com/help/user-research.html",
"es": "https://www.getresponse.com/es/ayuda/investigacion-de-usuarios.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingWirusowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-wirusowy.html",
"it": "https://www.getresponse.com/it/assistenza/viral-marketing.html",
"en": "https://www.getresponse.com/help/viral-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-viral.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-viral.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVlog = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/vlog.html",
"fr": "https://www.getresponse.com/fr/aide/vlog.html",
"en": "https://www.getresponse.com/help/vlog.html",
"it": "https://www.getresponse.com/it/assistenza/vlog.html",
"es": "https://www.getresponse.com/es/ayuda/vlog.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnalizaInternetowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/analiza-internetowa.html",
"fr": "https://www.getresponse.com/fr/aide/analyse-du-web.html",
"en": "https://www.getresponse.com/help/web-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/analitica-web.html",
"de": "https://www.getresponse.com/de/hilfe/webanalyse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProjektowanieStronInternetowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/projektowanie-stron-internetowych.html",
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web.html",
"en": "https://www.getresponse.com/help/web-design.html",
"es": "https://www.getresponse.com/es/ayuda/diseno-web.html",
"de": "https://www.getresponse.com/de/hilfe/webgestaltung.html",
"it": "https://www.getresponse.com/it/assistenza/web-design.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHostingInternetowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/hosting-internetowy.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-web.html",
"en": "https://www.getresponse.com/help/web-hosting.html",
"es": "https://www.getresponse.com/es/ayuda/alojamiento-web.html",
"de": "https://www.getresponse.com/de/hilfe/webhosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEmailDePanierAbandonnePourWoocommerce = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-panier-abandonne-pour-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-per-il-carrello-abbandonato-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-abandoned-cart-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-carrito-abandonado-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoj-korziny-dlya-woocommerce.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-e-mail-fuer-abgebrochene-warenkoerbe-in-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLOutilTableauBlancPendantUnWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSvyazatMoiAkkauntyXTwitterIGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeFtpIKakEgoIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTheWhiteboardToolDuringAWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-the-whiteboard-tool-during-a-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-outil-tableau-blanc-pendant-un-webinaire.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-herramienta-pizarra-interactiva-durante-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-tablicy-podczas-webinaru.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-lo-strumento-della-lavagna-durante-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-instrument-markernaya-doska-vo-vremya-vebinara.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-das-whiteboard-tool-waehrend-eines-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-quadro-interativo-durante-um-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMobileAppsTechnicalDocumentationForFlutter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/mobile-apps-technical-documentation-for-flutter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrivetstvennoePismoDlyaPartnjorov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStartUpy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/start-upy.html",
"en": "https://www.getresponse.com/help/start-ups.html",
"fr": "https://www.getresponse.com/fr/aide/start-ups.html",
"it": "https://www.getresponse.com/it/assistenza/start-up.html",
"es": "https://www.getresponse.com/es/ayuda/start-ups.html",
"br": "https://www.getresponse.com/pt/ajuda/start-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/start-ups.html",
"de": "https://www.getresponse.com/de/hilfe/start-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZnacznikTytulu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/znacznik-tytulu.html",
"fr": "https://www.getresponse.com/fr/aide/balise-de-titre.html",
"en": "https://www.getresponse.com/help/title-tag.html",
"es": "https://www.getresponse.com/es/ayuda/etiqueta-de-titulo.html",
"de": "https://www.getresponse.com/de/hilfe/title-tag.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomosciTransakcyjne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-transakcyjne.html",
"fr": "https://www.getresponse.com/fr/aide/courrier-electronique-transactionnel.html",
"en": "https://www.getresponse.com/help/transactional-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-electronico-transaccional.html",
"de": "https://www.getresponse.com/de/hilfe/transaktionale-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUnikalnaPropozycjaSprzedazy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/unikalna-propozycja-sprzedazy.html",
"fr": "https://www.getresponse.com/fr/aide/proposition-de-vente-unique.html",
"en": "https://www.getresponse.com/help/unique-selling-proposition.html",
"es": "https://www.getresponse.com/es/ayuda/propuesta-unica-de-venta.html",
"de": "https://www.getresponse.com/de/hilfe/alleinstellungsmerkmal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUnikalniOdwiedzajacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/unikalni-odwiedzajacy.html",
"fr": "https://www.getresponse.com/fr/aide/visiteurs-uniques.html",
"en": "https://www.getresponse.com/help/unique-visitors.html",
"it": "https://www.getresponse.com/it/assistenza/visitatori-singoli.html",
"es": "https://www.getresponse.com/es/ayuda/visitantes-unicos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueFtpEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaTranscriptionEnDirectEtCommentLActiver = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-transcription-en-direct-et-comment-l-activer.html",
"en": "https://www.getresponse.com/help/how-does-live-transcription-work-and-how-to-enable-it.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-transcripcion-en-vivo-y-como-activarla.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-transkrypcja-na-zywo-i-jak-ja-wlaczyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-rasshifrovka-translyacii-i-kak-ejo-vkljuchit.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-live-transkription-und-wie-kann-man-sie-aktivieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-trascrizione-in-diretta-e-come-attivarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostResponsiveWebDesign = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/responsive-web-design.html",
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web-reactifs.html",
"en": "https://www.getresponse.com/help/responsive-web-design.html",
"es": "https://www.getresponse.com/es/ayuda/diseno-web-responsivo.html",
"de": "https://www.getresponse.com/de/hilfe/responsives-webdesign.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCennik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/cennik.html",
"it": "https://www.getresponse.com/it/assistenza/tariffario.html",
"en": "https://www.getresponse.com/help/rate-card.html",
"fr": "https://www.getresponse.com/fr/aide/grille-tarifaire.html",
"es": "https://www.getresponse.com/es/ayuda/tarjeta-de-tarifas.html",
"de": "https://www.getresponse.com/de/hilfe/preiskatalog.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRoznicowanieProduktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/roznicowanie-produktow.html",
"it": "https://www.getresponse.com/it/assistenza/differenziazione-del-prodotto.html",
"en": "https://www.getresponse.com/help/product-differentiation.html",
"fr": "https://www.getresponse.com/fr/aide/differenciation-des-produits.html",
"es": "https://www.getresponse.com/es/ayuda/diferenciacion-de-productos.html",
"de": "https://www.getresponse.com/de/hilfe/produktdifferenzierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWycena = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wycena.html",
"fr": "https://www.getresponse.com/fr/aide/tarification.html",
"en": "https://www.getresponse.com/help/pricing.html",
"it": "https://www.getresponse.com/it/assistenza/prezzi.html",
"es": "https://www.getresponse.com/es/ayuda/fijacion-de-precios.html",
"de": "https://www.getresponse.com/de/hilfe/preisgestaltung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingSpersonalizowany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-spersonalizowany.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-personalizzato.html",
"en": "https://www.getresponse.com/help/personalized-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-personnalise.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/personalisierte-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlatneMedia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/platne-media.html",
"fr": "https://www.getresponse.com/fr/aide/medias-payants.html",
"en": "https://www.getresponse.com/help/paid-media.html",
"es": "https://www.getresponse.com/es/ayuda/medios-de-pago.html",
"de": "https://www.getresponse.com/de/hilfe/bezahlte-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOdslonyStrony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/odslony-strony.html",
"fr": "https://www.getresponse.com/fr/aide/vue-de-la-page.html",
"en": "https://www.getresponse.com/help/page-view.html",
"it": "https://www.getresponse.com/it/assistenza/vista-della-paginapage-view.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-vista.html",
"br": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/seitenaufruf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkWychodzacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/link-wychodzacy.html",
"fr": "https://www.getresponse.com/fr/aide/lien-sortant.html",
"en": "https://www.getresponse.com/help/outbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-uscita.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-saliente.html",
"de": "https://www.getresponse.com/de/hilfe/ausgehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWyszukiwanieOrganiczne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-organiczne.html",
"it": "https://www.getresponse.com/it/assistenza/ricerca-organica.html",
"en": "https://www.getresponse.com/help/organic-search.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-organique.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-organica.html",
"de": "https://www.getresponse.com/de/hilfe/organische-suche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamaOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/reklama-online.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingOmnikanalowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-omnikanalowy.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-omnicanale.html",
"en": "https://www.getresponse.com/help/omnichannel-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-omnicanal.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-omnicanal.html",
"de": "https://www.getresponse.com/de/hilfe/omnichannel-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEfektSieci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/efekt-sieci.html",
"fr": "https://www.getresponse.com/fr/aide/effet-de-reseau.html",
"en": "https://www.getresponse.com/help/network-effect.html",
"es": "https://www.getresponse.com/es/ayuda/efecto-red.html",
"de": "https://www.getresponse.com/de/hilfe/netzwerkeffekt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatSTheDifferenceBetweenRecurringAndBountyAffiliateProgram = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-s-the-difference-between-recurring-and-bounty-affiliate-program.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-programem-partnerskim-prowizyjnym-a-premiowym.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-programme-d-affiliation-recurrent-et-le-programme-d-affiliation-bounty.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-programma-di-affiliazione-ricorrente-e-quello-bounty.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-programa-de-afiliados-recurrente-y-el-programa-de-afiliados-de-recompensas.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-dem-wiederkehrenden-und-dem-praemien-affiliate-programm.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chjom-zakljuchaetsya-raznica-mezhdu-ciklicheskoj-i-edinovremennoj-programmah-partnjorskogo-marketinga.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestFtpIJakGoUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatKampanijuIi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-kampaniju-ii.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-campana-de-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-ai-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kampanie-ai.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-campagna-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-eine-ki-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNapisatZaprosDlyaIiAssistenta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeIiAssistentIKakImPolzovatsya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanowanieMediow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/planowanie-mediow.html",
"fr": "https://www.getresponse.com/fr/aide/planification-des-medias.html",
"en": "https://www.getresponse.com/help/media-planning.html",
"es": "https://www.getresponse.com/es/ayuda/planificacion-de-medios.html",
"de": "https://www.getresponse.com/de/hilfe/medienplanung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanMarketingowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/plan-marketingowy.html",
"fr": "https://www.getresponse.com/fr/aide/plan-de-marketing.html",
"en": "https://www.getresponse.com/help/marketing-plan.html",
"it": "https://www.getresponse.com/it/assistenza/piano-di-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/marketingplan.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOpisyMeta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/opisy-meta.html",
"fr": "https://www.getresponse.com/fr/aide/meta-descriptions.html",
"en": "https://www.getresponse.com/help/meta-descriptions.html",
"it": "https://www.getresponse.com/it/assistenza/meta-descrizioni.html",
"es": "https://www.getresponse.com/es/ayuda/meta-descripciones.html",
"de": "https://www.getresponse.com/de/hilfe/meta-beschreibung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetawyszukiwarka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/metawyszukiwarka.html",
"it": "https://www.getresponse.com/it/assistenza/metamotore-di-ricerca.html",
"en": "https://www.getresponse.com/help/meta-search-engine.html",
"fr": "https://www.getresponse.com/fr/aide/metamoteur-de-recherche.html",
"es": "https://www.getresponse.com/es/ayuda/metabuscador.html",
"de": "https://www.getresponse.com/de/hilfe/meta-suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAplikacjaMobilna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna.html",
"fr": "https://www.getresponse.com/fr/aide/application-mobile.html",
"en": "https://www.getresponse.com/help/mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/app-mobile.html",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil.html",
"de": "https://www.getresponse.com/de/hilfe/mobile-app.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestyWielowymiarowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/testy-wielowymiarowe.html",
"it": "https://www.getresponse.com/it/assistenza/test-multivariato.html",
"en": "https://www.getresponse.com/help/multivariate-testing.html",
"fr": "https://www.getresponse.com/fr/aide/test-multivarie.html",
"es": "https://www.getresponse.com/es/ayuda/test-multivariante.html",
"de": "https://www.getresponse.com/de/hilfe/multivariate-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingWWyszukiwarkach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-w-wyszukiwarkach.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-sui-motori-di-ricerca.html",
"en": "https://www.getresponse.com/help/search-engine-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-moteur-de-recherche.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-buscadores.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinenmarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetowanieWWyszukiwarkach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/retargetowanie-w-wyszukiwarkach.html",
"fr": "https://www.getresponse.com/fr/aide/reciblage-de-la-recherche.html",
"en": "https://www.getresponse.com/help/search-retargeting.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-della-ricerca.html",
"es": "https://www.getresponse.com/es/ayuda/retargeting-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinen-retargeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWyszukiwarka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wyszukiwarka.html",
"fr": "https://www.getresponse.com/fr/aide/moteur-de-recherche.html",
"en": "https://www.getresponse.com/help/search-engine.html",
"it": "https://www.getresponse.com/it/assistenza/motore-di-ricerca.html",
"es": "https://www.getresponse.com/es/ayuda/motor-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoradztwoSeo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/doradztwo-seo.html",
"fr": "https://www.getresponse.com/fr/aide/conseil-en-referencement.html",
"en": "https://www.getresponse.com/help/seo-consultancy.html",
"es": "https://www.getresponse.com/es/ayuda/consultoria-seo.html",
"de": "https://www.getresponse.com/de/hilfe/seo-beratung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWyszukiwanieWWitrynie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-w-witrynie.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-le-site.html",
"en": "https://www.getresponse.com/help/site-search.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-en-sitios-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMediaSpolecznosciowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/media-spolecznosciowe.html",
"fr": "https://www.getresponse.com/fr/aide/medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media.html",
"es": "https://www.getresponse.com/es/ayuda/redes-sociales.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingWMediachSpolecznosciowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-w-mediach-spolecznosciowych.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-des-medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/social-media-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-redes-sociales.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublicidadEnYoutube = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/publicidad-en-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-su-youtube.html",
"en": "https://www.getresponse.com/help/youtube-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-na-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDeBocaABoca = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-de-boca-a-boca.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-bouche-a-oreille.html",
"en": "https://www.getresponse.com/help/word-of-mouth-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-tramite-passaparola.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-szeptany.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeDeBienvenida = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida.html",
"fr": "https://www.getresponse.com/fr/aide/courriels-de-bienvenue.html",
"en": "https://www.getresponse.com/help/welcome-emails.html",
"it": "https://www.getresponse.com/it/assistenza/e-mail-di-benvenuto.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-powitalne.html",
"de": "https://www.getresponse.com/de/hilfe/willkommensmail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTraficoWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/trafico-web.html",
"fr": "https://www.getresponse.com/fr/aide/trafic-sur-le-site-web.html",
"en": "https://www.getresponse.com/help/website-traffic.html",
"it": "https://www.getresponse.com/it/assistenza/traffico-del-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/ruch-na-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/site-web.html",
"en": "https://www.getresponse.com/help/website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/strona-internetowa.html",
"de": "https://www.getresponse.com/de/hilfe/website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlojamientoWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/alojamiento-web.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-web.html",
"en": "https://www.getresponse.com/help/web-hosting.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-internetowy.html",
"de": "https://www.getresponse.com/de/hilfe/webhosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDisenoWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/diseno-web.html",
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web.html",
"en": "https://www.getresponse.com/help/web-design.html",
"pl": "https://www.getresponse.pl/pomoc/projektowanie-stron-internetowych.html",
"de": "https://www.getresponse.com/de/hilfe/webgestaltung.html",
"it": "https://www.getresponse.com/it/assistenza/web-design.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnaliticaWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/analitica-web.html",
"fr": "https://www.getresponse.com/fr/aide/analyse-du-web.html",
"en": "https://www.getresponse.com/help/web-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/analiza-internetowa.html",
"de": "https://www.getresponse.com/de/hilfe/webanalyse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingViral = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-viral.html",
"it": "https://www.getresponse.com/it/assistenza/viral-marketing.html",
"en": "https://www.getresponse.com/help/viral-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-viral.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-wirusowy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInvestigacionDeUsuarios = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/investigacion-de-usuarios.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-les-utilisateurs.html",
"en": "https://www.getresponse.com/help/user-research.html",
"pl": "https://www.getresponse.pl/pomoc/badania-uzytkownikow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVisitantesUnicos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/visitantes-unicos.html",
"fr": "https://www.getresponse.com/fr/aide/visiteurs-uniques.html",
"en": "https://www.getresponse.com/help/unique-visitors.html",
"it": "https://www.getresponse.com/it/assistenza/visitatori-singoli.html",
"pl": "https://www.getresponse.pl/pomoc/unikalni-odwiedzajacy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPropuestaUnicaDeVenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/propuesta-unica-de-venta.html",
"fr": "https://www.getresponse.com/fr/aide/proposition-de-vente-unique.html",
"en": "https://www.getresponse.com/help/unique-selling-proposition.html",
"pl": "https://www.getresponse.pl/pomoc/unikalna-propozycja-sprzedazy.html",
"de": "https://www.getresponse.com/de/hilfe/alleinstellungsmerkmal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCorreoElectronicoTransaccional = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/correo-electronico-transaccional.html",
"fr": "https://www.getresponse.com/fr/aide/courrier-electronique-transactionnel.html",
"en": "https://www.getresponse.com/help/transactional-email.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-transakcyjne.html",
"de": "https://www.getresponse.com/de/hilfe/transaktionale-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEtiquetaDeTitulo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/etiqueta-de-titulo.html",
"fr": "https://www.getresponse.com/fr/aide/balise-de-titre.html",
"en": "https://www.getresponse.com/help/title-tag.html",
"pl": "https://www.getresponse.pl/pomoc/znacznik-tytulu.html",
"de": "https://www.getresponse.com/de/hilfe/title-tag.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPatrocinio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/patrocinio.html",
"fr": "https://www.getresponse.com/fr/aide/parrainage.html",
"en": "https://www.getresponse.com/help/sponsorship.html",
"it": "https://www.getresponse.com/it/assistenza/sponsorizzazione.html",
"pl": "https://www.getresponse.pl/pomoc/sponsoring.html",
"de": "https://www.getresponse.com/de/hilfe/sponsoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingEnRedesSociales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-en-redes-sociales.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-des-medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/social-media-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-mediach-spolecznosciowych.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRedesSociales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/redes-sociales.html",
"fr": "https://www.getresponse.com/fr/aide/medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media.html",
"pl": "https://www.getresponse.pl/pomoc/media-spolecznosciowe.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBusquedaEnSitiosWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/busqueda-en-sitios-web.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-le-site.html",
"en": "https://www.getresponse.com/help/site-search.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-w-witrynie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConsultoriaSeo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/consultoria-seo.html",
"fr": "https://www.getresponse.com/fr/aide/conseil-en-referencement.html",
"en": "https://www.getresponse.com/help/seo-consultancy.html",
"pl": "https://www.getresponse.pl/pomoc/doradztwo-seo.html",
"de": "https://www.getresponse.com/de/hilfe/seo-beratung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDeBusqueda = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/retargeting-de-busqueda.html",
"fr": "https://www.getresponse.com/fr/aide/reciblage-de-la-recherche.html",
"en": "https://www.getresponse.com/help/search-retargeting.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-della-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/retargetowanie-w-wyszukiwarkach.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinen-retargeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingEnBuscadores = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-en-buscadores.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-sui-motori-di-ricerca.html",
"en": "https://www.getresponse.com/help/search-engine-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-moteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-wyszukiwarkach.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinenmarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMotorDeBusqueda = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/motor-de-busqueda.html",
"fr": "https://www.getresponse.com/fr/aide/moteur-de-recherche.html",
"en": "https://www.getresponse.com/help/search-engine.html",
"it": "https://www.getresponse.com/it/assistenza/motore-di-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwarka.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDisenoWebResponsivo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/diseno-web-responsivo.html",
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web-reactifs.html",
"en": "https://www.getresponse.com/help/responsive-web-design.html",
"pl": "https://www.getresponse.pl/pomoc/responsive-web-design.html",
"de": "https://www.getresponse.com/de/hilfe/responsives-webdesign.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTarjetaDeTarifas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tarjeta-de-tarifas.html",
"it": "https://www.getresponse.com/it/assistenza/tariffario.html",
"en": "https://www.getresponse.com/help/rate-card.html",
"fr": "https://www.getresponse.com/fr/aide/grille-tarifaire.html",
"pl": "https://www.getresponse.pl/pomoc/cennik.html",
"de": "https://www.getresponse.com/de/hilfe/preiskatalog.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDiferenciacionDeProductos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/diferenciacion-de-productos.html",
"it": "https://www.getresponse.com/it/assistenza/differenziazione-del-prodotto.html",
"en": "https://www.getresponse.com/help/product-differentiation.html",
"fr": "https://www.getresponse.com/fr/aide/differenciation-des-produits.html",
"pl": "https://www.getresponse.pl/pomoc/roznicowanie-produktow.html",
"de": "https://www.getresponse.com/de/hilfe/produktdifferenzierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFijacionDePrecios = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/fijacion-de-precios.html",
"fr": "https://www.getresponse.com/fr/aide/tarification.html",
"en": "https://www.getresponse.com/help/pricing.html",
"it": "https://www.getresponse.com/it/assistenza/prezzi.html",
"pl": "https://www.getresponse.pl/pomoc/wycena.html",
"de": "https://www.getresponse.com/de/hilfe/preisgestaltung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingPersonalizado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-personalizzato.html",
"en": "https://www.getresponse.com/help/personalized-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-personnalise.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-spersonalizowany.html",
"de": "https://www.getresponse.com/de/hilfe/personalisierte-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDePermiso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-de-permiso.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-permission.html",
"en": "https://www.getresponse.com/help/permission-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/permission-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/permission-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMediosDePago = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/medios-de-pago.html",
"fr": "https://www.getresponse.com/fr/aide/medias-payants.html",
"en": "https://www.getresponse.com/help/paid-media.html",
"pl": "https://www.getresponse.pl/pomoc/platne-media.html",
"de": "https://www.getresponse.com/de/hilfe/bezahlte-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPaginaVista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/pagina-vista.html",
"fr": "https://www.getresponse.com/fr/aide/vue-de-la-page.html",
"en": "https://www.getresponse.com/help/page-view.html",
"it": "https://www.getresponse.com/it/assistenza/vista-della-paginapage-view.html",
"pl": "https://www.getresponse.pl/pomoc/odslony-strony.html",
"br": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/seitenaufruf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaTranscripcionEnVivoYComoActivarla = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-transcripcion-en-vivo-y-como-activarla.html",
"en": "https://www.getresponse.com/help/how-does-live-transcription-work-and-how-to-enable-it.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-transcription-en-direct-et-comment-l-activer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-transkrypcja-na-zywo-i-jak-ja-wlaczyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-rasshifrovka-translyacii-i-kak-ejo-vkljuchit.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-live-transkription-und-wie-kann-man-sie-aktivieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-trascrizione-in-diretta-e-come-attivarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamnyjTekst = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-tekst.html",
"pl": "https://www.getresponse.pl/pomoc/ad-copy.html",
"en": "https://www.getresponse.com/help/ad-copy.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-publicitario.html",
"it": "https://www.getresponse.com/it/assistenza/il-testo-pubblicitario.html",
"fr": "https://www.getresponse.com/fr/aide/le-texte-publicitaire.html",
"de": "https://www.getresponse.com/de/hilfe/anzeigenkopie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWygenerowacTrescReklamyZaPomocaAsystentaAiWPlatnychReklamach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wygenerowac-tresc-reklamy-za-pomoca-asystenta-ai-w-platnych-reklamach.html",
"fr": "https://www.getresponse.com/fr/aide/comment-generer-du-contenu-publicitaire-a-l-aide-de-l-assistant-ia-dans-les-publicites-payantes.html",
"en": "https://www.getresponse.com/help/how-to-generate-ad-content-using-ai-assistant-in-paid-ads.html",
"es": "https://www.getresponse.com/es/ayuda/como-generar-contenido-publicitario-utilizando-el-asistente-ia-en-anuncios-de-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-generare-contenuti-pubblicitari-utilizzando-ai-assistant-negli-annunci-a-pagamento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-generiere-ich-anzeigeninhalte-mit-dem-ki-assistenten-in-paid-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokirovkaReklamy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-reklamy.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-reklam.html",
"en": "https://www.getresponse.com/help/ad-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-degli-annunci.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-des-publicites.html",
"de": "https://www.getresponse.com/de/hilfe/blockieren-von-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBroshennayaKorzina = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/broshennaya-korzina.html",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/abandoned-cart.html",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne.html",
"de": "https://www.getresponse.com/de/hilfe/verlassener-warenkorb.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesLiveTranscriptionWorkAndHowToEnableIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-live-transcription-work-and-how-to-enable-it.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-transcripcion-en-vivo-y-como-activarla.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-transcription-en-direct-et-comment-l-activer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-transkrypcja-na-zywo-i-jak-ja-wlaczyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-rasshifrovka-translyacii-i-kak-ejo-vkljuchit.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-live-transkription-und-wie-kann-man-sie-aktivieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-trascrizione-in-diretta-e-come-attivarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostABTestirovanie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/a-b-testirovanie.html",
"pl": "https://www.getresponse.pl/pomoc/testy-a-b.html",
"en": "https://www.getresponse.com/help/a-b-tests.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/a-b-tests.html",
"fr": "https://www.getresponse.com/fr/aide/tests-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnlaceSaliente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/enlace-saliente.html",
"fr": "https://www.getresponse.com/fr/aide/lien-sortant.html",
"en": "https://www.getresponse.com/help/outbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-uscita.html",
"pl": "https://www.getresponse.pl/pomoc/link-wychodzacy.html",
"de": "https://www.getresponse.com/de/hilfe/ausgehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBusquedaOrganica = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/busqueda-organica.html",
"it": "https://www.getresponse.com/it/assistenza/ricerca-organica.html",
"en": "https://www.getresponse.com/help/organic-search.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-organique.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-organiczne.html",
"de": "https://www.getresponse.com/de/hilfe/organische-suche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingOmnicanal = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-omnicanal.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-omnicanale.html",
"en": "https://www.getresponse.com/help/omnichannel-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-omnicanal.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-omnikanalowy.html",
"de": "https://www.getresponse.com/de/hilfe/omnichannel-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEfectoRed = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/efecto-red.html",
"fr": "https://www.getresponse.com/fr/aide/effet-de-reseau.html",
"en": "https://www.getresponse.com/help/network-effect.html",
"pl": "https://www.getresponse.pl/pomoc/efekt-sieci.html",
"de": "https://www.getresponse.com/de/hilfe/netzwerkeffekt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestMultivariante = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/test-multivariante.html",
"it": "https://www.getresponse.com/it/assistenza/test-multivariato.html",
"en": "https://www.getresponse.com/help/multivariate-testing.html",
"fr": "https://www.getresponse.com/fr/aide/test-multivarie.html",
"pl": "https://www.getresponse.pl/pomoc/testy-wielowymiarowe.html",
"de": "https://www.getresponse.com/de/hilfe/multivariate-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAplicacionMovil = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil.html",
"fr": "https://www.getresponse.com/fr/aide/application-mobile.html",
"en": "https://www.getresponse.com/help/mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/app-mobile.html",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/mobile-app.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaetiquetas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/metaetiquetas.html",
"fr": "https://www.getresponse.com/fr/aide/metabalise.html",
"en": "https://www.getresponse.com/help/meta-tag.html",
"pl": "https://www.getresponse.pl/pomoc/meta-tag.html",
"de": "https://www.getresponse.com/de/hilfe/meta-tag.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetabuscador = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/metabuscador.html",
"it": "https://www.getresponse.com/it/assistenza/metamotore-di-ricerca.html",
"en": "https://www.getresponse.com/help/meta-search-engine.html",
"fr": "https://www.getresponse.com/fr/aide/metamoteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/metawyszukiwarka.html",
"de": "https://www.getresponse.com/de/hilfe/meta-suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaDescripciones = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/meta-descripciones.html",
"fr": "https://www.getresponse.com/fr/aide/meta-descriptions.html",
"en": "https://www.getresponse.com/help/meta-descriptions.html",
"it": "https://www.getresponse.com/it/assistenza/meta-descrizioni.html",
"pl": "https://www.getresponse.pl/pomoc/opisy-meta.html",
"de": "https://www.getresponse.com/de/hilfe/meta-beschreibung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanificacionDeMedios = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/planificacion-de-medios.html",
"fr": "https://www.getresponse.com/fr/aide/planification-des-medias.html",
"en": "https://www.getresponse.com/help/media-planning.html",
"pl": "https://www.getresponse.pl/pomoc/planowanie-mediow.html",
"de": "https://www.getresponse.com/de/hilfe/medienplanung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanDeMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/plan-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/plan-de-marketing.html",
"en": "https://www.getresponse.com/help/marketing-plan.html",
"it": "https://www.getresponse.com/it/assistenza/piano-di-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/plan-marketingowy.html",
"de": "https://www.getresponse.com/de/hilfe/marketingplan.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatizacionDelMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-du-marketing.html",
"en": "https://www.getresponse.com/help/marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/marketing-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTextoDeEnlace = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/texto-de-enlace.html",
"it": "https://www.getresponse.com/it/assistenza/testo-del-link.html",
"en": "https://www.getresponse.com/help/link-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-du-lien.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-linku.html",
"de": "https://www.getresponse.com/de/hilfe/linktext.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCreacionDeEnlaces = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/creacion-de-enlaces.html",
"fr": "https://www.getresponse.com/fr/aide/creation-de-liens.html",
"en": "https://www.getresponse.com/help/link-building.html",
"it": "https://www.getresponse.com/it/assistenza/link-building.html",
"pl": "https://www.getresponse.pl/pomoc/link-building.html",
"de": "https://www.getresponse.com/de/hilfe/linkaufbau.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRellenoDePalabrasClave = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/relleno-de-palabras-clave.html",
"it": "https://www.getresponse.com/it/assistenza/keyword-stuffing.html",
"en": "https://www.getresponse.com/help/keyword-stuffing.html",
"fr": "https://www.getresponse.com/fr/aide/bourrage-de-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/upychanie-slow-kluczowych.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-stuffing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDePalabrasClave = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-de-palabras-clave.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-slow-kluczowych.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDensidadDePalabrasClave = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/densidad-de-palabras-clave.html",
"it": "https://www.getresponse.com/it/assistenza/densita-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-density.html",
"fr": "https://www.getresponse.com/fr/aide/densite-des-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/gestosc-slow-kluczowych.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-dichte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPalabraClave = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/palabra-clave.html",
"fr": "https://www.getresponse.com/fr/aide/mot-cle.html",
"en": "https://www.getresponse.com/help/keyword.html",
"it": "https://www.getresponse.com/it/assistenza/parola-chiave.html",
"pl": "https://www.getresponse.pl/pomoc/slowo-kluczowe.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeoInternacional = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/seo-internacional.html",
"it": "https://www.getresponse.com/it/assistenza/seo-internazionale.html",
"en": "https://www.getresponse.com/help/international-seo.html",
"fr": "https://www.getresponse.com/fr/aide/seo-international.html",
"pl": "https://www.getresponse.pl/pomoc/miedzynarodowe-seo.html",
"de": "https://www.getresponse.com/de/hilfe/internationales-seo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingIntegrado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-integrado.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-integre.html",
"en": "https://www.getresponse.com/help/integrated-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-zintegrowany.html",
"de": "https://www.getresponse.com/de/hilfe/integriertes-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnunciosDeInstagram = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-instagram.html",
"fr": "https://www.getresponse.com/fr/aide/publicites-instagram.html",
"en": "https://www.getresponse.com/help/instagram-ads.html",
"pl": "https://www.getresponse.pl/pomoc/reklamy-na-instagramie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnlaceEntrante = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/enlace-entrante.html",
"fr": "https://www.getresponse.com/fr/aide/lien-entrant.html",
"en": "https://www.getresponse.com/help/inbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-entrata.html",
"pl": "https://www.getresponse.pl/pomoc/link-przychodzacy.html",
"de": "https://www.getresponse.com/de/hilfe/eingehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkBuilding = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/link-building.html",
"fr": "https://www.getresponse.com/fr/aide/creation-de-liens.html",
"en": "https://www.getresponse.com/help/link-building.html",
"it": "https://www.getresponse.com/it/assistenza/link-building.html",
"es": "https://www.getresponse.com/es/ayuda/creacion-de-enlaces.html",
"de": "https://www.getresponse.com/de/hilfe/linkaufbau.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTekstLinku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/tekst-linku.html",
"it": "https://www.getresponse.com/it/assistenza/testo-del-link.html",
"en": "https://www.getresponse.com/help/link-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-du-lien.html",
"es": "https://www.getresponse.com/es/ayuda/texto-de-enlace.html",
"de": "https://www.getresponse.com/de/hilfe/linktext.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsFtpYComoSeUtiliza = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamyNaInstagramie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/reklamy-na-instagramie.html",
"fr": "https://www.getresponse.com/fr/aide/publicites-instagram.html",
"en": "https://www.getresponse.com/help/instagram-ads.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-instagram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingZintegrowany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-zintegrowany.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-integre.html",
"en": "https://www.getresponse.com/help/integrated-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-integrado.html",
"de": "https://www.getresponse.com/de/hilfe/integriertes-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMiedzynarodoweSeo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/miedzynarodowe-seo.html",
"it": "https://www.getresponse.com/it/assistenza/seo-internazionale.html",
"en": "https://www.getresponse.com/help/international-seo.html",
"fr": "https://www.getresponse.com/fr/aide/seo-international.html",
"es": "https://www.getresponse.com/es/ayuda/seo-internacional.html",
"de": "https://www.getresponse.com/de/hilfe/internationales-seo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSlowoKluczowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/slowo-kluczowe.html",
"fr": "https://www.getresponse.com/fr/aide/mot-cle.html",
"en": "https://www.getresponse.com/help/keyword.html",
"it": "https://www.getresponse.com/it/assistenza/parola-chiave.html",
"es": "https://www.getresponse.com/es/ayuda/palabra-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGestoscSlowKluczowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gestosc-slow-kluczowych.html",
"it": "https://www.getresponse.com/it/assistenza/densita-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-density.html",
"fr": "https://www.getresponse.com/fr/aide/densite-des-mots-cles.html",
"es": "https://www.getresponse.com/es/ayuda/densidad-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-dichte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingSlowKluczowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-slow-kluczowych.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-mots-cles.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUpychanieSlowKluczowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/upychanie-slow-kluczowych.html",
"it": "https://www.getresponse.com/it/assistenza/keyword-stuffing.html",
"en": "https://www.getresponse.com/help/keyword-stuffing.html",
"fr": "https://www.getresponse.com/fr/aide/bourrage-de-mots-cles.html",
"es": "https://www.getresponse.com/es/ayuda/relleno-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-stuffing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeHtml2 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-html-2.html",
"pl": "https://www.getresponse.pl/pomoc/e-mail-html.html",
"en": "https://www.getresponse.com/help/html-email.html",
"fr": "https://www.getresponse.com/fr/aide/courriel-html.html",
"de": "https://www.getresponse.com/de/hilfe/html-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMapaDeCalor = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mapa-de-calor.html",
"fr": "https://www.getresponse.com/fr/aide/heatmap.html",
"en": "https://www.getresponse.com/help/heatmap.html",
"pl": "https://www.getresponse.pl/pomoc/mapa-cieplna-heatmap.html",
"it": "https://www.getresponse.com/it/assistenza/mappa-di-calore.html",
"de": "https://www.getresponse.com/de/hilfe/heatmap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGuestBlogging = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/guest-blogging.html",
"it": "https://www.getresponse.com/it/assistenza/guest-blogging.html",
"en": "https://www.getresponse.com/help/guest-blogging.html",
"fr": "https://www.getresponse.com/fr/aide/guest-blogging.html",
"pl": "https://www.getresponse.pl/pomoc/goscinne-pisanie-blogow.html",
"de": "https://www.getresponse.com/de/hilfe/gast-blogging.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoogleMyBusiness = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/google-my-business.html",
"pl": "https://www.getresponse.pl/pomoc/google-moja-firma.html",
"en": "https://www.getresponse.com/help/google-my-business.html",
"fr": "https://www.getresponse.com/fr/aide/google-my-business.html",
"de": "https://www.getresponse.com/de/hilfe/google-unternehmensprofil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlataformaDeMarketingDeGoogle = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/plataforma-de-marketing-de-google.html",
"pl": "https://www.getresponse.pl/pomoc/google-marketing-platform.html",
"en": "https://www.getresponse.com/help/google-marketing-platform.html",
"fr": "https://www.getresponse.com/fr/aide/google-marketing-platform.html",
"de": "https://www.getresponse.com/de/hilfe/google-marketing-platform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostActualizacionesDelAlgoritmoDeGoogle = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/actualizaciones-del-algoritmo-de-google.html",
"it": "https://www.getresponse.com/it/assistenza/aggiornamenti-dell-algoritmo-di-google.html",
"en": "https://www.getresponse.com/help/google-algorithm-updates.html",
"fr": "https://www.getresponse.com/fr/aide/mises-a-jour-de-l-algorithme-de-google.html",
"pl": "https://www.getresponse.pl/pomoc/aktualizacje-algorytmu-google.html",
"de": "https://www.getresponse.com/de/hilfe/google-algorithmus-aktualisierungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEstrategiaGtm = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/estrategia-gtm.html",
"it": "https://www.getresponse.com/it/assistenza/strategia-go-to-market.html",
"en": "https://www.getresponse.com/help/go-to-market-strategy.html",
"fr": "https://www.getresponse.com/fr/aide/strategie-de-mise-sur-le-marche.html",
"pl": "https://www.getresponse.pl/pomoc/strategia-wejscia-na-rynek.html",
"de": "https://www.getresponse.com/de/hilfe/go-to-market-strategie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeolocalizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/geolocalizacion.html",
"fr": "https://www.getresponse.com/fr/aide/geo-ciblage.html",
"en": "https://www.getresponse.com/help/geo-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-geografico.html",
"pl": "https://www.getresponse.pl/pomoc/geotargetowanie.html",
"br": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"de": "https://www.getresponse.com/de/hilfe/geo-targeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPreparerUnFichierPourLImporterViaFtp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSzablonWiadomosciEMail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/szablon-wiadomosci-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-e-mail.html",
"en": "https://www.getresponse.com/help/email-template.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-courriel.html",
"es": "https://www.getresponse.com/es/ayuda/plantillas-de-mensaje.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-vorlage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWskaznikZaangazowania = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zaangazowania.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-coinvolgimento.html",
"en": "https://www.getresponse.com/help/engagement-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-engagement.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-compromiso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFacebookAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-facebook.html",
"en": "https://www.getresponse.com/help/facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/annunci-di-facebook.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBezplatneNarzedziaMarketingowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/bezplatne-narzedzia-marketingowe.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-gratuits.html",
"en": "https://www.getresponse.com/help/free-marketing-tools.html",
"it": "https://www.getresponse.com/it/assistenza/strumenti-di-marketing-gratuiti.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/kostenlose-marketing-tools.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimitCzestotliwosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/limit-czestotliwosci.html",
"fr": "https://www.getresponse.com/fr/aide/plafond-de-frequence.html",
"en": "https://www.getresponse.com/help/frequency-cap.html",
"it": "https://www.getresponse.com/it/assistenza/limite-di-frequenzafrequency-cap.html",
"es": "https://www.getresponse.com/es/ayuda/limite-de-frecuencia.html",
"br": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"de": "https://www.getresponse.com/de/hilfe/frequenzbegrenzung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLejek = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/lejek.html",
"fr": "https://www.getresponse.com/fr/aide/funnel.html",
"en": "https://www.getresponse.com/help/funnel.html",
"it": "https://www.getresponse.com/it/assistenza/funnel.html",
"es": "https://www.getresponse.com/es/ayuda/embudo.html",
"de": "https://www.getresponse.com/de/hilfe/funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeotargetowanie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/geotargetowanie.html",
"fr": "https://www.getresponse.com/fr/aide/geo-ciblage.html",
"en": "https://www.getresponse.com/help/geo-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-geografico.html",
"es": "https://www.getresponse.com/es/ayuda/geolocalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"de": "https://www.getresponse.com/de/hilfe/geo-targeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStrategiaWejsciaNaRynek = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/strategia-wejscia-na-rynek.html",
"it": "https://www.getresponse.com/it/assistenza/strategia-go-to-market.html",
"en": "https://www.getresponse.com/help/go-to-market-strategy.html",
"fr": "https://www.getresponse.com/fr/aide/strategie-de-mise-sur-le-marche.html",
"es": "https://www.getresponse.com/es/ayuda/estrategia-gtm.html",
"de": "https://www.getresponse.com/de/hilfe/go-to-market-strategie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAktualizacjeAlgorytmuGoogle = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/aktualizacje-algorytmu-google.html",
"it": "https://www.getresponse.com/it/assistenza/aggiornamenti-dell-algoritmo-di-google.html",
"en": "https://www.getresponse.com/help/google-algorithm-updates.html",
"fr": "https://www.getresponse.com/fr/aide/mises-a-jour-de-l-algorithme-de-google.html",
"es": "https://www.getresponse.com/es/ayuda/actualizaciones-del-algoritmo-de-google.html",
"de": "https://www.getresponse.com/de/hilfe/google-algorithmus-aktualisierungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoogleMojaFirma = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/google-moja-firma.html",
"en": "https://www.getresponse.com/help/google-my-business.html",
"fr": "https://www.getresponse.com/fr/aide/google-my-business.html",
"es": "https://www.getresponse.com/es/ayuda/google-my-business.html",
"de": "https://www.getresponse.com/de/hilfe/google-unternehmensprofil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRemarketingGoogle = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/remarketing-google.html",
"fr": "https://www.getresponse.com/fr/aide/remarketing-de-google.html",
"en": "https://www.getresponse.com/help/google-remarketing.html",
"it": "https://www.getresponse.com/it/assistenza/remarketing-di-google.html",
"es": "https://www.getresponse.com/es/ayuda/google-remarketing.html",
"de": "https://www.getresponse.com/de/hilfe/google-remarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoscinnePisanieBlogow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/goscinne-pisanie-blogow.html",
"it": "https://www.getresponse.com/it/assistenza/guest-blogging.html",
"en": "https://www.getresponse.com/help/guest-blogging.html",
"fr": "https://www.getresponse.com/fr/aide/guest-blogging.html",
"es": "https://www.getresponse.com/es/ayuda/guest-blogging.html",
"de": "https://www.getresponse.com/de/hilfe/gast-blogging.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkPrzychodzacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/link-przychodzacy.html",
"fr": "https://www.getresponse.com/fr/aide/lien-entrant.html",
"en": "https://www.getresponse.com/help/inbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-entrata.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-entrante.html",
"de": "https://www.getresponse.com/de/hilfe/eingehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEMailHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/e-mail-html.html",
"en": "https://www.getresponse.com/help/html-email.html",
"fr": "https://www.getresponse.com/fr/aide/courriel-html.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html-2.html",
"de": "https://www.getresponse.com/de/hilfe/html-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMapaCieplnaHeatmap = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/mapa-cieplna-heatmap.html",
"fr": "https://www.getresponse.com/fr/aide/heatmap.html",
"en": "https://www.getresponse.com/help/heatmap.html",
"it": "https://www.getresponse.com/it/assistenza/mappa-di-calore.html",
"es": "https://www.getresponse.com/es/ayuda/mapa-de-calor.html",
"de": "https://www.getresponse.com/de/hilfe/heatmap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsFtpAndHowDoIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-ftp-and-how-do-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-ftp-y-como-se-utiliza.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ftp-i-jak-go-uzywac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-ftp-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ftp-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-l-ftp-e-come-lo-utilizzo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ftp-und-wie-benutze-ich-es.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-ftp-e-como-o-utilizo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmbudo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/embudo.html",
"fr": "https://www.getresponse.com/fr/aide/funnel.html",
"en": "https://www.getresponse.com/help/funnel.html",
"it": "https://www.getresponse.com/it/assistenza/funnel.html",
"pl": "https://www.getresponse.pl/pomoc/lejek.html",
"de": "https://www.getresponse.com/de/hilfe/funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimiteDeFrecuencia = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/limite-de-frecuencia.html",
"fr": "https://www.getresponse.com/fr/aide/plafond-de-frequence.html",
"en": "https://www.getresponse.com/help/frequency-cap.html",
"it": "https://www.getresponse.com/it/assistenza/limite-di-frequenzafrequency-cap.html",
"pl": "https://www.getresponse.pl/pomoc/limit-czestotliwosci.html",
"br": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"de": "https://www.getresponse.com/de/hilfe/frequenzbegrenzung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHerramientasDeMarketingGratuitas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-gratuitas.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-gratuits.html",
"en": "https://www.getresponse.com/help/free-marketing-tools.html",
"it": "https://www.getresponse.com/it/assistenza/strumenti-di-marketing-gratuiti.html",
"pl": "https://www.getresponse.pl/pomoc/bezplatne-narzedzia-marketingowe.html",
"de": "https://www.getresponse.com/de/hilfe/kostenlose-marketing-tools.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnunciosDeFacebook = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-facebook.html",
"en": "https://www.getresponse.com/help/facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/annunci-di-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTasaDeCompromiso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tasa-de-compromiso.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-coinvolgimento.html",
"en": "https://www.getresponse.com/help/engagement-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-engagement.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zaangazowania.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlantillasDeMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/plantillas-de-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-e-mail.html",
"en": "https://www.getresponse.com/help/email-template.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-wiadomosci-e-mail.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-vorlage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCorreoNoDeseado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/correo-no-deseado.html",
"it": "https://www.getresponse.com/it/assistenza/soam-via-e-mail.html",
"en": "https://www.getresponse.com/help/email-spam.html",
"fr": "https://www.getresponse.com/fr/aide/spam-par-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/spam-2.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFiltradoDeCorreoElectronico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/filtrado-de-correo-electronico.html",
"it": "https://www.getresponse.com/it/assistenza/email-filtering.html",
"en": "https://www.getresponse.com/help/email-filtering.html",
"fr": "https://www.getresponse.com/fr/aide/filtrage-du-courrier-electronique.html",
"pl": "https://www.getresponse.pl/pomoc/filtrowanie-wiadomosci-e-mail.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-filterung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatizacionDelCorreoElectronico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-correo-electronico.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-automation.html",
"it": "https://www.getresponse.com/it/assistenza/automazione-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-des-courriels.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-automatisierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSitioWebDeECommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/sitio-web-de-e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/strona-e-commerce.html",
"en": "https://www.getresponse.com/help/e-commerce-website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/site-web-de-commerce-electronique.html",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnnunciDiFacebook = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/annunci-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-facebook.html",
"en": "https://www.getresponse.com/help/facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSocialMediaMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/social-media-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-des-medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-mediach-spolecznosciowych.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-redes-sociales.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/site-web.html",
"en": "https://www.getresponse.com/help/website.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/strona-internetowa.html",
"de": "https://www.getresponse.com/de/hilfe/website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDirectory = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/directory.html",
"pl": "https://www.getresponse.pl/pomoc/katalog.html",
"en": "https://www.getresponse.com/help/directory.html",
"fr": "https://www.getresponse.com/fr/aide/annuaire.html",
"es": "https://www.getresponse.com/es/ayuda/directorio.html",
"de": "https://www.getresponse.com/de/hilfe/verzeichnis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParolaChiave = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/parola-chiave.html",
"fr": "https://www.getresponse.com/fr/aide/mot-cle.html",
"en": "https://www.getresponse.com/help/keyword.html",
"pl": "https://www.getresponse.pl/pomoc/slowo-kluczowe.html",
"es": "https://www.getresponse.com/es/ayuda/palabra-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubblico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pubblico.html",
"pl": "https://www.getresponse.pl/pomoc/grupa-docelowa.html",
"en": "https://www.getresponse.com/help/audience.html",
"es": "https://www.getresponse.com/es/ayuda/audiencia.html",
"fr": "https://www.getresponse.com/fr/aide/public.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppe.html",
"ru": "https://www.getresponse.com/ru/help-ru/auditoriya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSponsorizzazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sponsorizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/parrainage.html",
"en": "https://www.getresponse.com/help/sponsorship.html",
"es": "https://www.getresponse.com/es/ayuda/patrocinio.html",
"pl": "https://www.getresponse.pl/pomoc/sponsoring.html",
"de": "https://www.getresponse.com/de/hilfe/sponsoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrezzi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/prezzi.html",
"fr": "https://www.getresponse.com/fr/aide/tarification.html",
"en": "https://www.getresponse.com/help/pricing.html",
"es": "https://www.getresponse.com/es/ayuda/fijacion-de-precios.html",
"pl": "https://www.getresponse.pl/pomoc/wycena.html",
"de": "https://www.getresponse.com/de/hilfe/preisgestaltung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMotoreDiRicerca = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/motore-di-ricerca.html",
"fr": "https://www.getresponse.com/fr/aide/moteur-de-recherche.html",
"en": "https://www.getresponse.com/help/search-engine.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/motor-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGifAnimata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/gif-animata.html",
"pl": "https://www.getresponse.pl/pomoc/animowany-gif.html",
"en": "https://www.getresponse.com/help/animated-gif.html",
"es": "https://www.getresponse.com/es/ayuda/gif-animado.html",
"fr": "https://www.getresponse.com/fr/aide/gif-anime.html",
"de": "https://www.getresponse.com/de/hilfe/animiertes-gif.html",
"ru": "https://www.getresponse.com/ru/help-ru/animirovannyj-gif.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMappaDiCalore = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/mappa-di-calore.html",
"fr": "https://www.getresponse.com/fr/aide/heatmap.html",
"en": "https://www.getresponse.com/help/heatmap.html",
"pl": "https://www.getresponse.pl/pomoc/mapa-cieplna-heatmap.html",
"es": "https://www.getresponse.com/es/ayuda/mapa-de-calor.html",
"de": "https://www.getresponse.com/de/hilfe/heatmap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTextePredefini = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html",
"en": "https://www.getresponse.com/help/predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPiegeASpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/piege-a-spam.html",
"pl": "https://www.getresponse.pl/pomoc/spam-trap.html",
"en": "https://www.getresponse.com/help/spam-trap.html",
"it": "https://www.getresponse.com/it/assistenza/trappole-di-spam.html",
"es": "https://www.getresponse.com/es/ayuda/trampa-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/spam-lovyshka.html",
"de": "https://www.getresponse.com/de/hilfe/spam-trap.html",
"br": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/compte.html",
"en": "https://www.getresponse.com/help/account.html",
"it": "https://www.getresponse.com/it/assistenza/account.html",
"ru": "https://www.getresponse.com/ru/help-ru/akkaunt.html",
"es": "https://www.getresponse.com/es/ayuda/cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/konto-2.html",
"de": "https://www.getresponse.com/de/hilfe/das-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/conta-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostApi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/api.html",
"pl": "https://www.getresponse.pl/pomoc/api.html",
"en": "https://www.getresponse.com/help/api.html",
"de": "https://www.getresponse.com/de/hilfe/api.html",
"es": "https://www.getresponse.com/es/ayuda/api.html",
"ru": "https://www.getresponse.com/ru/help-ru/api.html",
"it": "https://www.getresponse.com/it/assistenza/api.html",
"br": "https://www.getresponse.com/pt/ajuda/api.html",
"pt": "https://www.getresponse.com/pt/ajuda/api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlignement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/alignement.html",
"pl": "https://www.getresponse.pl/pomoc/zgodnosc.html",
"en": "https://www.getresponse.com/help/alignment.html",
"de": "https://www.getresponse.com/de/hilfe/alignment.html",
"es": "https://www.getresponse.com/es/ayuda/alineacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/vyravnivanie.html",
"it": "https://www.getresponse.com/it/assistenza/alignment.html",
"br": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/alinhamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCleApi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/cle-api.html",
"pl": "https://www.getresponse.pl/pomoc/klucz-api.html",
"en": "https://www.getresponse.com/help/api-key.html",
"de": "https://www.getresponse.com/de/hilfe/api-key.html",
"es": "https://www.getresponse.com/es/ayuda/clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/api-kljuch.html",
"it": "https://www.getresponse.com/it/assistenza/chiave-api.html",
"br": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/chave-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAboveTheFold = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/above-the-fold.html",
"pl": "https://www.getresponse.pl/pomoc/above-the-fold-ponad-linia.html",
"en": "https://www.getresponse.com/help/above-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/above-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/sobre-el-pliegue.html",
"ru": "https://www.getresponse.com/ru/help-ru/vidimaya-chast-bez-prokrutki.html",
"it": "https://www.getresponse.com/it/assistenza/above-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProgrammeDAffiliation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/programme-d-affiliation.html",
"pl": "https://www.getresponse.pl/pomoc/program-afiliacyjny.html",
"en": "https://www.getresponse.com/help/affiliate-program.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-program.html",
"es": "https://www.getresponse.com/es/ayuda/programa-de-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskaya-programma.html",
"it": "https://www.getresponse.com/it/assistenza/programma-di-affiliazione.html",
"br": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlog = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/blog.html",
"pl": "https://www.getresponse.pl/pomoc/blog.html",
"en": "https://www.getresponse.com/help/blog.html",
"de": "https://www.getresponse.com/de/hilfe/blog.html",
"es": "https://www.getresponse.com/es/ayuda/blog.html",
"ru": "https://www.getresponse.com/ru/help-ru/blog.html",
"it": "https://www.getresponse.com/it/assistenza/blog.html",
"br": "https://www.getresponse.com/pt/ajuda/blog.html",
"pt": "https://www.getresponse.com/pt/ajuda/blog.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRebondi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rebondi.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot.html",
"en": "https://www.getresponse.com/help/bounced.html",
"de": "https://www.getresponse.com/de/hilfe/bounced.html",
"es": "https://www.getresponse.com/es/ayuda/rebotados.html",
"ru": "https://www.getresponse.com/ru/help-ru/oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicoes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlocage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/blocage.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie.html",
"en": "https://www.getresponse.com/help/blocking.html",
"de": "https://www.getresponse.com/de/hilfe/blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka.html",
"it": "https://www.getresponse.com/it/assistenza/blocco.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBelowTheFold = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/below-the-fold.html",
"pl": "https://www.getresponse.pl/pomoc/below-the-fold-ponizej-linii.html",
"en": "https://www.getresponse.com/help/below-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/below-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/bajo-el-pliegue.html",
"ru": "https://www.getresponse.com/ru/help-ru/nizhe-linii-zgiba.html",
"it": "https://www.getresponse.com/it/assistenza/below-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailVide = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/email-vide.html",
"pl": "https://www.getresponse.pl/pomoc/pusty-email.html",
"en": "https://www.getresponse.com/help/blank-email.html",
"de": "https://www.getresponse.com/de/hilfe/blank-email.html",
"es": "https://www.getresponse.com/es/ayuda/email-en-blanco.html",
"ru": "https://www.getresponse.com/ru/help-ru/pustoe-elektronnoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/email-vuouta.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-branco.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatisation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/automatisation.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja.html",
"en": "https://www.getresponse.com/help/automation.html",
"de": "https://www.getresponse.com/de/hilfe/automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/automation.html",
"br": "https://www.getresponse.com/pt/ajuda/automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/automacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutorepondeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/autorepondeur.html",
"pl": "https://www.getresponse.pl/pomoc/autoresponder.html",
"en": "https://www.getresponse.com/help/autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtootvetchik.html",
"it": "https://www.getresponse.com/it/assistenza/autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCiblageComportemental = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ciblage-comportemental.html",
"pl": "https://www.getresponse.pl/pomoc/targetowanie-behawioralne.html",
"en": "https://www.getresponse.com/help/behavioral-targeting.html",
"de": "https://www.getresponse.com/de/hilfe/behavioral-targeting.html",
"es": "https://www.getresponse.com/es/ayuda/orientacion-del-comportamiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/povedencheskij-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-comportamentale.html",
"br": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pt": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptInConfirme = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/opt-in-confirme.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-z-potwierdzeniem.html",
"en": "https://www.getresponse.com/help/confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/consentimiento-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdennaia-podpiska.html",
"it": "https://www.getresponse.com/it/assistenza/doppio-opt-in.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClient = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/client.html",
"pl": "https://www.getresponse.pl/pomoc/klient.html",
"en": "https://www.getresponse.com/help/client.html",
"de": "https://www.getresponse.com/de/hilfe/client.html",
"es": "https://www.getresponse.com/es/ayuda/cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/klient.html",
"it": "https://www.getresponse.com/it/assistenza/cliente.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessageDeConfirmation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/message-de-confirmation.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-potwierdzajaca-subskrypcje.html",
"en": "https://www.getresponse.com/help/confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/confirmation-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-confirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-conferma.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCodageDesCaracteres = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/codage-des-caracteres.html",
"pl": "https://www.getresponse.pl/pomoc/kodowanie-znakow.html",
"en": "https://www.getresponse.com/help/character-encoding.html",
"de": "https://www.getresponse.com/de/hilfe/character-encoding.html",
"ru": "https://www.getresponse.com/ru/help-ru/kodirovka-simvolov.html",
"it": "https://www.getresponse.com/it/assistenza/codifica-dei-caratteri.html",
"br": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pt": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"es": "https://www.getresponse.com/es/ayuda/codificacion-de-caracteres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTauxDeClics = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/taux-de-clics.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-klikniec.html",
"en": "https://www.getresponse.com/help/click-through-rate.html",
"de": "https://www.getresponse.com/de/hilfe/click-through-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/koefficient-klikov-ctr.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pt": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuiviDesClics = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/suivi-des-clics.html",
"pl": "https://www.getresponse.pl/pomoc/sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/click-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/otslezhivanie-po-kliku.html",
"it": "https://www.getresponse.com/it/assistenza/tracciamento-dei-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLoiCanSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/loi-can-spam.html",
"pl": "https://www.getresponse.pl/pomoc/ustawa-can-spam.html",
"en": "https://www.getresponse.com/help/can-spam-act.html",
"de": "https://www.getresponse.com/de/hilfe/can-spam-act.html",
"es": "https://www.getresponse.com/es/ayuda/ley-can-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zakon-can-spam.html",
"it": "https://www.getresponse.com/it/assistenza/legge-can-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCaptcha = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/captcha.html",
"pl": "https://www.getresponse.pl/pomoc/captcha.html",
"en": "https://www.getresponse.com/help/captcha.html",
"de": "https://www.getresponse.com/de/hilfe/captcha-definition.html",
"es": "https://www.getresponse.com/es/ayuda/captcha.html",
"ru": "https://www.getresponse.com/ru/help-ru/captcha.html",
"it": "https://www.getresponse.com/it/assistenza/captcha.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-captcha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-captcha.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnvoiMassifDeCourriels = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/envoi-massif-de-courriels.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-masowa.html",
"en": "https://www.getresponse.com/help/bulk-email.html",
"de": "https://www.getresponse.com/de/hilfe/bulk-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-masivo.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovaya-rassylka.html",
"it": "https://www.getresponse.com/it/assistenza/email-di-massa.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-massa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampagneDeMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/campagne-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/kampania.html",
"en": "https://www.getresponse.com/help/marketing-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/campaign-definition.html",
"es": "https://www.getresponse.com/es/ayuda/campaign-definition.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaja-kampania.html",
"it": "https://www.getresponse.com/it/assistenza/campagna.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContenuDynamique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/contenu-dynamique.html",
"pl": "https://www.getresponse.pl/pomoc/dynamic-content.html",
"en": "https://www.getresponse.com/help/dynamic-content.html",
"de": "https://www.getresponse.com/de/hilfe/dynamic-content.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/dinamicheskij-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/contenuti-dinamici.html",
"br": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTauxDeRebond = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/taux-de-rebond.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zwrotow.html",
"en": "https://www.getresponse.com/help/bounce-rate.html",
"de": "https://www.getresponse.com/de/hilfe/bounce-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-rebote.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-oshibok-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/bounce-rate.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTransmission = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/transmission.html",
"pl": "https://www.getresponse.pl/pomoc/wysylka.html",
"en": "https://www.getresponse.com/help/broadcast.html",
"de": "https://www.getresponse.com/de/hilfe/broadcast.html",
"es": "https://www.getresponse.com/es/ayuda/transmision.html",
"ru": "https://www.getresponse.com/ru/help-ru/translyaciya.html",
"it": "https://www.getresponse.com/it/assistenza/broadcast.html",
"br": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/transmissao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDmarc = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/dmarc.html",
"en": "https://www.getresponse.com/help/dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDns = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/dns.html",
"pl": "https://www.getresponse.pl/pomoc/dns.html",
"en": "https://www.getresponse.com/help/dns.html",
"de": "https://www.getresponse.com/de/hilfe/dns.html",
"es": "https://www.getresponse.com/es/ayuda/dns.html",
"ru": "https://www.getresponse.com/ru/help-ru/dns.html",
"it": "https://www.getresponse.com/it/assistenza/dns.html",
"br": "https://www.getresponse.com/pt/ajuda/dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTauxDeDelivrabilite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/taux-de-delivrabilite.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-dostarczalnosci.html",
"en": "https://www.getresponse.com/help/deliverability-rate.html",
"de": "https://www.getresponse.com/de/hilfe/deliverability-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/uroven-dostavlyaemosti.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-consegna.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLivre = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/livre.html",
"pl": "https://www.getresponse.pl/pomoc/dostarczona.html",
"en": "https://www.getresponse.com/help/delivered.html",
"de": "https://www.getresponse.com/de/hilfe/delivered.html",
"es": "https://www.getresponse.com/es/ayuda/entregado.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-dostavleno.html",
"it": "https://www.getresponse.com/it/assistenza/consegnato.html",
"br": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pt": "https://www.getresponse.com/pt/ajuda/entregue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDkim = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/dkim.html",
"pl": "https://www.getresponse.pl/pomoc/dkim.html",
"en": "https://www.getresponse.com/help/dkim.html",
"de": "https://www.getresponse.com/de/hilfe/dkim.html",
"es": "https://www.getresponse.com/es/ayuda/dkim.html",
"ru": "https://www.getresponse.com/ru/help-ru/dkim.html",
"it": "https://www.getresponse.com/it/assistenza/dkim.html",
"br": "https://www.getresponse.com/pt/ajuda/dkim.html",
"pt": "https://www.getresponse.com/pt/ajuda/dkim.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChampPersonnalise = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/champ-personnalise.html",
"de": "https://www.getresponse.com/de/hilfe/custom-field.html",
"en": "https://www.getresponse.com/help/custom-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemoe-pole.html",
"it": "https://www.getresponse.com/it/assistenza/campo-personalizzato.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/pole-dodatkowe-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostServeurDedie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/serveur-dedie.html",
"pl": "https://www.getresponse.pl/pomoc/serwer-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-server.html",
"de": "https://www.getresponse.com/de/hilfe/dedicated-server.html",
"es": "https://www.getresponse.com/es/ayuda/servidor-dedicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/vydelennyj-server.html",
"it": "https://www.getresponse.com/it/assistenza/server-dedicato.html",
"br": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPiedDePage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pied-de-page.html",
"pl": "https://www.getresponse.pl/pomoc/stopka-maila.html",
"en": "https://www.getresponse.com/help/footer.html",
"de": "https://www.getresponse.com/de/hilfe/footer.html",
"es": "https://www.getresponse.com/es/ayuda/pie-de-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/futer.html",
"it": "https://www.getresponse.com/it/assistenza/pie-di-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/rodape.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCookie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/cookie.html",
"pl": "https://www.getresponse.pl/pomoc/ciasteczka.html",
"en": "https://www.getresponse.com/help/cookie.html",
"de": "https://www.getresponse.com/de/hilfe/cookie.html",
"es": "https://www.getresponse.com/es/ayuda/cookie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kuki.html",
"it": "https://www.getresponse.com/it/assistenza/cookie.html",
"br": "https://www.getresponse.com/pt/ajuda/cookie.html",
"pt": "https://www.getresponse.com/pt/ajuda/cookie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatCsv = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/format-csv.html",
"pl": "https://www.getresponse.pl/pomoc/format-csv.html",
"en": "https://www.getresponse.com/help/csv-format.html",
"de": "https://www.getresponse.com/de/hilfe/csv-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-csv.html",
"ru": "https://www.getresponse.com/ru/help-ru/csv-format.html",
"it": "https://www.getresponse.com/it/assistenza/formato-csv.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-csv.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-csv.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromotionParCourrier = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/promotion-par-courrier.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-emailowa.html",
"en": "https://www.getresponse.com/help/email-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/email-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-por-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEsp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/esp.html",
"pl": "https://www.getresponse.pl/pomoc/esp.html",
"en": "https://www.getresponse.com/help/esp.html",
"de": "https://www.getresponse.com/de/hilfe/esp.html",
"es": "https://www.getresponse.com/es/ayuda/esp.html",
"ru": "https://www.getresponse.com/ru/help-ru/esp.html",
"it": "https://www.getresponse.com/it/assistenza/esp.html",
"br": "https://www.getresponse.com/pt/ajuda/esp.html",
"pt": "https://www.getresponse.com/pt/ajuda/esp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFichierJoint = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/fichier-joint.html",
"pl": "https://www.getresponse.pl/pomoc/zalacznik.html",
"en": "https://www.getresponse.com/help/file-attachment.html",
"de": "https://www.getresponse.com/de/hilfe/file-attachment.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-adjunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/fajl-vlozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/file-allegato.html",
"br": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pt": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClientDeMessagerie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/client-de-messagerie.html",
"pl": "https://www.getresponse.pl/pomoc/klient-poczty-elektronicznej.html",
"en": "https://www.getresponse.com/help/email-client.html",
"de": "https://www.getresponse.com/de/hilfe/email-client.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-klient.html",
"it": "https://www.getresponse.com/it/assistenza/cliente-di-posta-elettronica.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"es": "https://www.getresponse.com/es/ayuda/cliente-de-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnTetesDEMails = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/en-tetes-d-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/naglowki-emaila.html",
"en": "https://www.getresponse.com/help/email-headers.html",
"de": "https://www.getresponse.com/de/hilfe/email-headers.html",
"es": "https://www.getresponse.com/es/ayuda/encabezados-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/zagolovki-elektronnoi-pochti.html",
"it": "https://www.getresponse.com/it/assistenza/intestazioni-e-mail-e-mail-headers.html",
"br": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLEMailing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/l-e-mailing.html",
"pl": "https://www.getresponse.pl/pomoc/emailing.html",
"en": "https://www.getresponse.com/help/emailing.html",
"de": "https://www.getresponse.com/de/hilfe/emailing.html",
"es": "https://www.getresponse.com/es/ayuda/enviando-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpravka-po-elektronnoj-pochte.html",
"it": "https://www.getresponse.com/it/assistenza/invio-di-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessageInstantane = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/message-instantane.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-natychmiastowa-blyskawiczna.html",
"en": "https://www.getresponse.com/help/instant-message.html",
"de": "https://www.getresponse.com/de/hilfe/instant-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-instantaneo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mgnovennoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/instant-message.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubliciteParCourriel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/publicite-par-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-mailowa.html",
"en": "https://www.getresponse.com/help/email-advertising.html",
"de": "https://www.getresponse.com/de/hilfe/email-advertising.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-reklama.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-por-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampagneDEMailing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/campagne-d-e-mailing.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/email-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-kampaniya.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAbonnesInactifs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/abonnes-inactifs.html",
"pl": "https://www.getresponse.pl/pomoc/nieaktywni-subskrybenci.html",
"en": "https://www.getresponse.com/help/inactive-subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/inactive-subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/neaktivnye-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/sottoscrittori-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlocageDeLImage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/blocage-de-l-image.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-obrazkow.html",
"en": "https://www.getresponse.com/help/image-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/image-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-imagenes.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-izobrazhenij.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-delle-immagini.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostImap = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/imap.html",
"pl": "https://www.getresponse.pl/pomoc/imap.html",
"en": "https://www.getresponse.com/help/imap.html",
"de": "https://www.getresponse.com/de/hilfe/imap.html",
"es": "https://www.getresponse.com/es/ayuda/imap.html",
"ru": "https://www.getresponse.com/ru/help-ru/imap.html",
"it": "https://www.getresponse.com/it/assistenza/imap.html",
"br": "https://www.getresponse.com/pt/ajuda/imap.html",
"pt": "https://www.getresponse.com/pt/ajuda/imap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostImportation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/importation.html",
"pl": "https://www.getresponse.pl/pomoc/import.html",
"en": "https://www.getresponse.com/help/import.html",
"de": "https://www.getresponse.com/de/hilfe/import.html",
"es": "https://www.getresponse.com/es/ayuda/importar.html",
"ru": "https://www.getresponse.com/ru/help-ru/import.html",
"it": "https://www.getresponse.com/it/assistenza/import.html",
"br": "https://www.getresponse.com/pt/ajuda/importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/importacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHtml = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/html.html",
"pl": "https://www.getresponse.pl/pomoc/html.html",
"en": "https://www.getresponse.com/help/html.html",
"de": "https://www.getresponse.com/de/hilfe/html.html",
"es": "https://www.getresponse.com/es/ayuda/html.html",
"ru": "https://www.getresponse.com/ru/help-ru/html.html",
"it": "https://www.getresponse.com/it/assistenza/html.html",
"br": "https://www.getresponse.com/pt/ajuda/html.html",
"pt": "https://www.getresponse.com/pt/ajuda/html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListeGrise = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html",
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"en": "https://www.getresponse.com/help/greylist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"de": "https://www.getresponse.com/de/hilfe/grauliste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHardBounceRebondissement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/hard-bounce-rebondissement.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-twardy.html",
"en": "https://www.getresponse.com/help/hard-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/hard-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-permanente.html",
"ru": "https://www.getresponse.com/ru/help-ru/postoyannaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-duro.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeChampDe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/le-champ-de.html",
"pl": "https://www.getresponse.pl/pomoc/pole-nadawcy.html",
"en": "https://www.getresponse.com/help/from-field.html",
"de": "https://www.getresponse.com/de/hilfe/from-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-otpravitelya.html",
"it": "https://www.getresponse.com/it/assistenza/from-field.html",
"br": "https://www.getresponse.com/pt/ajuda/remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/remetente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGenerationDeHtml = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/generation-de-html.html",
"pl": "https://www.getresponse.pl/pomoc/generowanie-kodu-html.html",
"en": "https://www.getresponse.com/help/generating-html.html",
"de": "https://www.getresponse.com/de/hilfe/generating-html.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-html.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-html.html",
"br": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/gerando-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListeDAdressesElectroniques = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/liste-d-adresses-electroniques.html",
"pl": "https://www.getresponse.pl/pomoc/lista-mailingowa.html",
"en": "https://www.getresponse.com/help/mailing-list.html",
"de": "https://www.getresponse.com/de/hilfe/mailing-list.html",
"es": "https://www.getresponse.com/es/ayuda/lista-de-correos.html",
"ru": "https://www.getresponse.com/ru/help-ru/spisok-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/lista-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFrequence = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/frequence.html",
"pl": "https://www.getresponse.pl/pomoc/czestotliwosc-wyslanych-emaili.html",
"en": "https://www.getresponse.com/help/frequency.html",
"de": "https://www.getresponse.com/de/hilfe/frequency.html",
"es": "https://www.getresponse.com/es/ayuda/frecuencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/chastota.html",
"it": "https://www.getresponse.com/it/assistenza/frequenza.html",
"br": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/frequencia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentationDeLaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-la-liste.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-listy.html",
"en": "https://www.getresponse.com/help/list-segmentation.html",
"de": "https://www.getresponse.com/de/hilfe/list-segmentation.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-della-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGestionDesListes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/gestion-des-listes.html",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-lista.html",
"en": "https://www.getresponse.com/help/list-management.html",
"de": "https://www.getresponse.com/de/hilfe/list-management.html",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-spiskami.html",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLien = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/lien.html",
"pl": "https://www.getresponse.pl/pomoc/link.html",
"en": "https://www.getresponse.com/help/link.html",
"de": "https://www.getresponse.com/de/hilfe/link.html",
"es": "https://www.getresponse.com/es/ayuda/link.html",
"ru": "https://www.getresponse.com/ru/help-ru/ssylka.html",
"it": "https://www.getresponse.com/it/assistenza/link.html",
"br": "https://www.getresponse.com/pt/ajuda/link.html",
"pt": "https://www.getresponse.com/pt/ajuda/link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGenerationDeLeads = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/generation-de-leads.html",
"pl": "https://www.getresponse.pl/pomoc/pozyskiwanie-leadow.html",
"en": "https://www.getresponse.com/help/leads-generation.html",
"de": "https://www.getresponse.com/de/hilfe/leads-generation.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/generaciya-lidov.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversionDesProspects = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/conversion-des-prospects.html",
"pl": "https://www.getresponse.pl/pomoc/konwersja-leadow.html",
"en": "https://www.getresponse.com/help/lead-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/lead-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversiya-lida.html",
"it": "https://www.getresponse.com/it/assistenza/conversione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/page-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/landing-page.html",
"en": "https://www.getresponse.com/help/landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/lending.html",
"it": "https://www.getresponse.com/it/assistenza/landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIsp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/isp.html",
"pl": "https://www.getresponse.pl/pomoc/isp.html",
"en": "https://www.getresponse.com/help/isp.html",
"de": "https://www.getresponse.com/de/hilfe/isp.html",
"es": "https://www.getresponse.com/es/ayuda/isp.html",
"ru": "https://www.getresponse.com/ru/help-ru/isp-internet-provajder.html",
"it": "https://www.getresponse.com/it/assistenza/isp.html",
"br": "https://www.getresponse.com/pt/ajuda/psi.html",
"pt": "https://www.getresponse.com/pt/ajuda/psi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlocageIp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/blocage-ip.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-adresu-ip.html",
"en": "https://www.getresponse.com/help/ip-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/ip-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-ip.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAddresseIp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/addresse-ip.html",
"pl": "https://www.getresponse.pl/pomoc/adres-ip.html",
"en": "https://www.getresponse.com/help/ip-address.html",
"de": "https://www.getresponse.com/de/hilfe/ip-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/ip-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIntervalle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/intervalle.html",
"pl": "https://www.getresponse.pl/pomoc/interwal.html",
"en": "https://www.getresponse.com/help/interval.html",
"de": "https://www.getresponse.com/de/hilfe/interval.html",
"es": "https://www.getresponse.com/es/ayuda/intervalo.html",
"ru": "https://www.getresponse.com/ru/help-ru/interval.html",
"it": "https://www.getresponse.com/it/assistenza/intervallo.html",
"br": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/intervalo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStatistiquesDePerformance = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/statistiques-de-performance.html",
"pl": "https://www.getresponse.pl/pomoc/statystki-wysylek.html",
"en": "https://www.getresponse.com/help/performance-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/performance-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-desempeno.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-dejstvij.html",
"it": "https://www.getresponse.com/it/assistenza/statistiche-di-rendimento.html",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptOutActionDeDesabonnement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html",
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptInEmail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/opt-in-email.html",
"pl": "https://www.getresponse.pl/pomoc/opt-in-email.html",
"en": "https://www.getresponse.com/help/opt-in-email.html",
"de": "https://www.getresponse.com/de/hilfe/opt-in-email.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-na-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-email.html",
"br": "https://www.getresponse.com/pt/ajuda/email-de-opt-in-adesao.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-de-opt-in-adesao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTauxDOuverture = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/taux-d-ouverture.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-otwarc.html",
"en": "https://www.getresponse.com/help/open-rate.html",
"de": "https://www.getresponse.com/de/hilfe/open-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-otkrytij.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-apertura.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNotification = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/notification.html",
"pl": "https://www.getresponse.pl/pomoc/notification-powiadomienia.html",
"en": "https://www.getresponse.com/help/notification.html",
"de": "https://www.getresponse.com/de/hilfe/notification.html",
"es": "https://www.getresponse.com/es/ayuda/notificacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/uvedomlenie-o-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/notificazione.html",
"br": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/notificacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptInNonConfirme = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/opt-in-non-confirme.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-bez-potwierdzenia.html",
"en": "https://www.getresponse.com/help/non-confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/non-confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-no-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-bez-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-non-confermato.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNewsletter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/newsletter.html",
"en": "https://www.getresponse.com/help/newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/rassylka.html",
"it": "https://www.getresponse.com/it/assistenza/newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDesabonnementMultipleOptOut = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/desabonnement-multiple-opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/multi-unsubscribe-opt-out.html",
"en": "https://www.getresponse.com/help/multi-unsubscribe-opt-out.html",
"es": "https://www.getresponse.com/es/ayuda/desinscripcion-multiple-opt-out.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovyj-otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-multipla-opt-out.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pl": "https://www.getresponse.pl/pomoc/multi-unsubscribe-wypis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModeleDeNewsletter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/modele-de-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-newslettera.html",
"en": "https://www.getresponse.com/help/newsletter-template.html",
"de": "https://www.getresponse.com/de/hilfe/newsletter-template.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/shablon-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatDuMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/format-du-message.html",
"pl": "https://www.getresponse.pl/pomoc/format-wiadomosci.html",
"en": "https://www.getresponse.com/help/message-format.html",
"de": "https://www.getresponse.com/de/hilfe/message-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-del-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/format-soobshheniya.html",
"it": "https://www.getresponse.com/it/assistenza/formato-del-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFusionnerDesMots = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/fusionner-des-mots.html",
"pl": "https://www.getresponse.pl/pomoc/merge-word.html",
"en": "https://www.getresponse.com/help/merge-word.html",
"de": "https://www.getresponse.com/de/hilfe/merge-word.html",
"es": "https://www.getresponse.com/es/ayuda/fusion-de-palabras.html",
"ru": "https://www.getresponse.com/ru/help-ru/slovo-obedenenie.html",
"it": "https://www.getresponse.com/it/assistenza/fusione-di-parole.html",
"br": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"pt": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRefNumeroDeSuivi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ref-numero-de-suivi.html",
"pl": "https://www.getresponse.pl/pomoc/ref-numer-sledzacy.html",
"en": "https://www.getresponse.com/help/ref-tracking-number.html",
"de": "https://www.getresponse.com/de/hilfe/ref-tracking-number.html",
"es": "https://www.getresponse.com/es/ayuda/numero-de-seguimiento-de-la-referencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/nomer-dla-otslezivaniya.html",
"it": "https://www.getresponse.com/it/assistenza/numero-di-riferimento.html",
"br": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreLoad = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pre-load.html",
"de": "https://www.getresponse.com/de/hilfe/pre-load.html",
"es": "https://www.getresponse.com/es/ayuda/pre-carga.html",
"ru": "https://www.getresponse.com/ru/help-ru/kopirovanie-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/precaricamento.html",
"br": "https://www.getresponse.com/pt/ajuda/pre-carga.html",
"pt": "https://www.getresponse.com/pt/ajuda/pre-carga.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdressePostale = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/adresse-postale.html",
"pl": "https://www.getresponse.pl/pomoc/adres-pocztowy.html",
"en": "https://www.getresponse.com/help/postal-address.html",
"de": "https://www.getresponse.com/de/hilfe/postal-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-postal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-postale.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-postal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPop = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pop.html",
"pl": "https://www.getresponse.pl/pomoc/pop.html",
"en": "https://www.getresponse.com/help/pop.html",
"de": "https://www.getresponse.com/de/hilfe/pop.html",
"es": "https://www.getresponse.com/es/ayuda/pop.html",
"ru": "https://www.getresponse.com/ru/help-ru/pop.html",
"it": "https://www.getresponse.com/it/assistenza/pop.html",
"br": "https://www.getresponse.com/pt/ajuda/pop.html",
"pt": "https://www.getresponse.com/pt/ajuda/pop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPopUp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pop-up.html",
"pl": "https://www.getresponse.pl/pomoc/pop-up.html",
"en": "https://www.getresponse.com/help/pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/pop-up.html",
"ru": "https://www.getresponse.com/ru/help-ru/vsplyvajushhee-okno.html",
"it": "https://www.getresponse.com/it/assistenza/pop-up.html",
"br": "https://www.getresponse.com/pt/ajuda/pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTexteBrut = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/texte-brut.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-w-formacie-tekstowym.html",
"en": "https://www.getresponse.com/help/plain-text.html",
"de": "https://www.getresponse.com/de/hilfe/plain-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-sin-formato.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/testo-semplice.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDefinitionDuPhishing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/definition-du-phishing.html",
"pl": "https://www.getresponse.pl/pomoc/phishing.html",
"en": "https://www.getresponse.com/help/phishing-definition.html",
"it": "https://www.getresponse.com/it/assistenza/phishing.html",
"es": "https://www.getresponse.com/es/ayuda/phishing.html",
"ru": "https://www.getresponse.com/ru/help-ru/phising.html",
"de": "https://www.getresponse.com/de/hilfe/phishing-definition.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonnalisation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/personnalisation.html",
"pl": "https://www.getresponse.pl/pomoc/personalizacja.html",
"en": "https://www.getresponse.com/help/personalization.html",
"de": "https://www.getresponse.com/de/hilfe/personalization.html",
"es": "https://www.getresponse.com/es/ayuda/personalizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/personalizzazione.html",
"br": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/personalizacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingBaseSurLesPermissions = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-base-sur-les-permissions.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-za-przyzwoleniem.html",
"en": "https://www.getresponse.com/help/permission-based-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-based-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-basado-en-permisos.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketing-na-osnove-razreshenij.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-basato-sul-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlaintePourSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/plainte-pour-spam.html",
"pl": "https://www.getresponse.pl/pomoc/zgloszenie-spamu.html",
"en": "https://www.getresponse.com/help/spam-complaint.html",
"de": "https://www.getresponse.com/de/hilfe/spam-complaint.html",
"es": "https://www.getresponse.com/es/ayuda/denuncia-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zhaloba-na-spam.html",
"it": "https://www.getresponse.com/it/assistenza/reclamo-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRebondDoux = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rebond-doux.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-miekki.html",
"en": "https://www.getresponse.com/help/soft-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/soft-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-transitorio.html",
"ru": "https://www.getresponse.com/ru/help-ru/vremennaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-morbido.html",
"br": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pt": "https://www.getresponse.com/pt/ajuda/bounce-suave.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVente = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/vente.html",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"en": "https://www.getresponse.com/help/sale.html",
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSem = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/sem.html",
"pl": "https://www.getresponse.pl/pomoc/sem.html",
"en": "https://www.getresponse.com/help/sem.html",
"de": "https://www.getresponse.com/de/hilfe/sem.html",
"es": "https://www.getresponse.com/es/ayuda/sem.html",
"ru": "https://www.getresponse.com/ru/help-ru/poiskovyj-marketing-sem.html",
"it": "https://www.getresponse.com/it/assistenza/sem.html",
"br": "https://www.getresponse.com/pt/ajuda/sem.html",
"pt": "https://www.getresponse.com/pt/ajuda/sem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRss = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rss.html",
"pl": "https://www.getresponse.pl/pomoc/rss.html",
"en": "https://www.getresponse.com/help/rss.html",
"de": "https://www.getresponse.com/de/hilfe/rss.html",
"es": "https://www.getresponse.com/es/ayuda/rss.html",
"ru": "https://www.getresponse.com/ru/help-ru/rss.html",
"it": "https://www.getresponse.com/it/assistenza/rss.html",
"br": "https://www.getresponse.com/pt/ajuda/rss.html",
"pt": "https://www.getresponse.com/pt/ajuda/rss.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRoi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/roi.html",
"pl": "https://www.getresponse.pl/pomoc/roi.html",
"en": "https://www.getresponse.com/help/roi.html",
"de": "https://www.getresponse.com/de/hilfe/roi.html",
"es": "https://www.getresponse.com/es/ayuda/roi.html",
"ru": "https://www.getresponse.com/ru/help-ru/roi.html",
"it": "https://www.getresponse.com/it/assistenza/roi.html",
"br": "https://www.getresponse.com/pt/ajuda/roi.html",
"pt": "https://www.getresponse.com/pt/ajuda/roi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRapport = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rapport.html",
"pl": "https://www.getresponse.pl/pomoc/raport.html",
"en": "https://www.getresponse.com/help/report.html",
"de": "https://www.getresponse.com/de/hilfe/bericht.html",
"es": "https://www.getresponse.com/es/ayuda/informe.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet.html",
"it": "https://www.getresponse.com/it/assistenza/report.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChampDeReponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/champ-de-reponse.html",
"pl": "https://www.getresponse.pl/pomoc/pole-odpowiedz-do.html",
"en": "https://www.getresponse.com/help/reply-to-field.html",
"de": "https://www.getresponse.com/de/hilfe/reply-to-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-respuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-dlya-otveta.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-di-risposta.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuppressions = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/suppressions.html",
"pl": "https://www.getresponse.pl/pomoc/usuniete.html",
"en": "https://www.getresponse.com/help/removals.html",
"de": "https://www.getresponse.com/de/hilfe/removals.html",
"es": "https://www.getresponse.com/es/ayuda/bajas.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpisavshiesya-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/contatti-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/remocoes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormulaireWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/formulaire-web.html",
"pl": "https://www.getresponse.pl/pomoc/formularz-zapisu.html",
"en": "https://www.getresponse.com/help/web-form.html",
"de": "https://www.getresponse.com/de/hilfe/web-form.html",
"es": "https://www.getresponse.com/es/ayuda/formulario-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/veb-forma.html",
"it": "https://www.getresponse.com/it/assistenza/modulo-web.html",
"br": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/formulario-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUrl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/url.html",
"pl": "https://www.getresponse.pl/pomoc/url.html",
"en": "https://www.getresponse.com/help/url.html",
"de": "https://www.getresponse.com/de/hilfe/url.html",
"es": "https://www.getresponse.com/es/ayuda/url.html",
"ru": "https://www.getresponse.com/ru/help-ru/url.html",
"it": "https://www.getresponse.com/it/assistenza/url.html",
"br": "https://www.getresponse.com/pt/ajuda/url.html",
"pt": "https://www.getresponse.com/pt/ajuda/url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPageDeRemerciement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/page-de-remerciement.html",
"pl": "https://www.getresponse.pl/pomoc/strona-z-podziekowaniem.html",
"en": "https://www.getresponse.com/help/thank-you-page.html",
"de": "https://www.getresponse.com/de/hilfe/thank-you-page.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-de-agradecimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/stranica-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/pagina-di-ringraziamento.html",
"br": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnquete = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/enquete.html",
"pl": "https://www.getresponse.pl/pomoc/ankieta.html",
"en": "https://www.getresponse.com/help/survey.html",
"de": "https://www.getresponse.com/de/hilfe/survey.html",
"es": "https://www.getresponse.com/es/ayuda/encuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/opros.html",
"it": "https://www.getresponse.com/it/assistenza/sondaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pt": "https://www.getresponse.com/pt/ajuda/inquerito.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRapportsPersonnalises = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html",
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/custom-reports.html",
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAbonnes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/abonnes.html",
"pl": "https://www.getresponse.pl/pomoc/subskrybent.html",
"en": "https://www.getresponse.com/help/subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpischiki.html",
"it": "https://www.getresponse.com/it/assistenza/contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLigneDObjet = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ligne-d-objet.html",
"pl": "https://www.getresponse.pl/pomoc/temat-wiadomosci.html",
"en": "https://www.getresponse.com/help/subject-line.html",
"de": "https://www.getresponse.com/de/hilfe/subject-line.html",
"es": "https://www.getresponse.com/es/ayuda/linea-de-asunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/tema-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/oggetto.html",
"br": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pt": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpoofing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/spoofing.html",
"pl": "https://www.getresponse.pl/pomoc/spoofing.html",
"en": "https://www.getresponse.com/help/spoofing.html",
"de": "https://www.getresponse.com/de/hilfe/spoofing.html",
"es": "https://www.getresponse.com/es/ayuda/spoofing.html",
"ru": "https://www.getresponse.com/ru/help-ru/spufing.html",
"it": "https://www.getresponse.com/it/assistenza/spoofing.html",
"br": "https://www.getresponse.com/pt/ajuda/spoofing.html",
"pt": "https://www.getresponse.com/pt/ajuda/spoofing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEssaiFractionne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/essai-fractionne.html",
"pl": "https://www.getresponse.pl/pomoc/testy-skutecznosci-ang-split-test.html",
"en": "https://www.getresponse.com/help/split-test.html",
"de": "https://www.getresponse.com/de/hilfe/split-test.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-multivariantes.html",
"ru": "https://www.getresponse.com/ru/help-ru/split-test.html",
"it": "https://www.getresponse.com/it/assistenza/split-test.html",
"br": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpf = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/spf.html",
"br": "https://www.getresponse.com/pt/ajuda/spf.html",
"pt": "https://www.getresponse.com/pt/ajuda/spf.html",
"pl": "https://www.getresponse.pl/pomoc/spf-sender-policy-framework.html",
"en": "https://www.getresponse.com/help/spf.html",
"de": "https://www.getresponse.com/de/hilfe/spf.html",
"es": "https://www.getresponse.com/es/ayuda/spf.html",
"ru": "https://www.getresponse.com/ru/help-ru/spf.html",
"it": "https://www.getresponse.com/it/assistenza/spf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/spam.html",
"pl": "https://www.getresponse.pl/pomoc/spam.html",
"en": "https://www.getresponse.com/help/spam.html",
"de": "https://www.getresponse.com/de/hilfe/spam.html",
"es": "https://www.getresponse.com/es/ayuda/spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/spam.html",
"it": "https://www.getresponse.com/it/assistenza/spam.html",
"br": "https://www.getresponse.com/pt/ajuda/spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFichierZip = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/fichier-zip.html",
"pl": "https://www.getresponse.pl/pomoc/zip.html",
"en": "https://www.getresponse.com/help/zip-file.html",
"de": "https://www.getresponse.com/de/hilfe/zip-file.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-zip.html",
"ru": "https://www.getresponse.com/ru/help-ru/zip-fajl.html",
"it": "https://www.getresponse.com/it/assistenza/zip-file.html",
"br": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"pt": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAssistant = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/assistant.html",
"de": "https://www.getresponse.com/de/hilfe/wizard.html",
"en": "https://www.getresponse.com/help/wizard.html",
"es": "https://www.getresponse.com/es/ayuda/asistente.html",
"ru": "https://www.getresponse.com/ru/help-ru/master.html",
"it": "https://www.getresponse.com/it/assistenza/wizard.html",
"br": "https://www.getresponse.com/pt/ajuda/wizard.html",
"pt": "https://www.getresponse.com/pt/ajuda/wizard.html",
"pl": "https://www.getresponse.pl/pomoc/wizard.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListeBlanche = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/liste-blanche.html",
"pl": "https://www.getresponse.pl/pomoc/whitelist-biala-lista-subskrybentow.html",
"en": "https://www.getresponse.com/help/whitelist.html",
"de": "https://www.getresponse.com/de/hilfe/whitelist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-blanca.html",
"ru": "https://www.getresponse.com/ru/help-ru/belyj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/whitelist.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-branca.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReglementGeneralSurLaProtectionDesDonneesGdpr = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/reglement-general-sur-la-protection-des-donnees-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/rozporzadzenie-o-ochronie-danych-osobowych-rodo.html",
"en": "https://www.getresponse.com/help/general-data-protection-regulation-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/general-data-protection-regulation-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/reglamento-general-de-proteccion-de-datos-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/obshij-reglament-po-zashite-dannych-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/die-allgemeine-datenschutz-verordnung-dsgvo.html",
"br": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDonneesPersonnellesSensibles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html",
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTraitementDesDonneesPersonnelles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees-personnelles.html",
"pl": "https://www.getresponse.pl/pomoc/przetwarzanie-danych-osobowych.html",
"en": "https://www.getresponse.com/help/personal-data-processing.html",
"it": "https://www.getresponse.com/it/assistenza/personal-data-processing.html",
"es": "https://www.getresponse.com/es/ayuda/tratamiento-de-datos-personales.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotka-personalnych-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/die-verarbeitung-der-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pt": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTraitementDesDonnees = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees.html",
"pl": "https://www.getresponse.pl/pomoc/podmiot-przetwarzajacy-dane.html",
"en": "https://www.getresponse.com/help/data-processor.html",
"it": "https://www.getresponse.com/it/assistenza/data-processor.html",
"es": "https://www.getresponse.com/es/ayuda/procesador-de-datos.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotchik-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/datenverarbeiter.html",
"br": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostControleurDeDonnees = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html",
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"en": "https://www.getresponse.com/help/data-controller.html",
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuccesDesClients = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/succes-des-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConseilEnReferencement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/conseil-en-referencement.html",
"en": "https://www.getresponse.com/help/seo-consultancy.html",
"pl": "https://www.getresponse.pl/pomoc/doradztwo-seo.html",
"es": "https://www.getresponse.com/es/ayuda/consultoria-seo.html",
"de": "https://www.getresponse.com/de/hilfe/seo-beratung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnalyseDuWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/analyse-du-web.html",
"en": "https://www.getresponse.com/help/web-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/analitica-web.html",
"pl": "https://www.getresponse.pl/pomoc/analiza-internetowa.html",
"de": "https://www.getresponse.com/de/hilfe/webanalyse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDripCampagne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/drip-campagne.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-typu-drip.html",
"en": "https://www.getresponse.com/help/drip-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-goteo.html",
"de": "https://www.getresponse.com/de/hilfe/drip-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSiteWebDeCommerceElectronique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/site-web-de-commerce-electronique.html",
"pl": "https://www.getresponse.pl/pomoc/strona-e-commerce.html",
"en": "https://www.getresponse.com/help/e-commerce-website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-e-commerce.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web-de-e-commerce.html",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublireportage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/publireportage.html",
"pl": "https://www.getresponse.pl/pomoc/artykul-sponsorowany.html",
"en": "https://www.getresponse.com/help/advertorial.html",
"es": "https://www.getresponse.com/es/ayuda/publirreportaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-statya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConceptionDeSitesWebReactifs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web-reactifs.html",
"en": "https://www.getresponse.com/help/responsive-web-design.html",
"es": "https://www.getresponse.com/es/ayuda/diseno-web-responsivo.html",
"pl": "https://www.getresponse.pl/pomoc/responsive-web-design.html",
"de": "https://www.getresponse.com/de/hilfe/responsives-webdesign.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingIntegre = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-integre.html",
"en": "https://www.getresponse.com/help/integrated-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-zintegrowany.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-integrado.html",
"de": "https://www.getresponse.com/de/hilfe/integriertes-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampanaDeGoteo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/campana-de-goteo.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-typu-drip.html",
"en": "https://www.getresponse.com/help/drip-campaign.html",
"fr": "https://www.getresponse.com/fr/aide/drip-campagne.html",
"de": "https://www.getresponse.com/de/hilfe/drip-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnunciosDeDisplay = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-display.html",
"pl": "https://www.getresponse.pl/pomoc/reklamy-displayowe.html",
"en": "https://www.getresponse.com/help/display-ads.html",
"fr": "https://www.getresponse.com/fr/aide/display-ads.html",
"de": "https://www.getresponse.com/de/hilfe/displayanzeigen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDirectorio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/directorio.html",
"pl": "https://www.getresponse.pl/pomoc/katalog.html",
"en": "https://www.getresponse.com/help/directory.html",
"fr": "https://www.getresponse.com/fr/aide/annuaire.html",
"it": "https://www.getresponse.com/it/assistenza/directory.html",
"de": "https://www.getresponse.com/de/hilfe/verzeichnis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingPorCorreoDirecto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-por-correo-directo.html",
"pl": "https://www.getresponse.pl/pomoc/bezposredni-marketing-pocztowy.html",
"en": "https://www.getresponse.com/help/direct-mail-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-direct.html",
"de": "https://www.getresponse.com/de/hilfe/direktmailing-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRpDigitales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/rp-digitales.html",
"pl": "https://www.getresponse.pl/pomoc/digital-pr.html",
"en": "https://www.getresponse.com/help/digital-pr.html",
"it": "https://www.getresponse.com/it/assistenza/pr-digitale.html",
"fr": "https://www.getresponse.com/fr/aide/rp-numeriques.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-pr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContenidoDigital = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/contenido-digital.html",
"pl": "https://www.getresponse.pl/pomoc/tresci-cyfrowe.html",
"en": "https://www.getresponse.com/help/digital-content.html",
"it": "https://www.getresponse.com/it/assistenza/contenuto-digitale.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-numerique.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-inhalte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVinculosProfundos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/vinculos-profundos.html",
"pl": "https://www.getresponse.pl/pomoc/deep-linking.html",
"en": "https://www.getresponse.com/help/deep-linking.html",
"fr": "https://www.getresponse.com/fr/aide/deep-linking.html",
"de": "https://www.getresponse.com/de/hilfe/deep-linking.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHostingDedicado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/hosting-dedicado.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-hosting.html",
"it": "https://www.getresponse.com/it/assistenza/hosting-dedicato.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-dedie.html",
"de": "https://www.getresponse.com/de/hilfe/dediziertes-hosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecorridoDelCliente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/recorrido-del-cliente.html",
"pl": "https://www.getresponse.pl/pomoc/podroz-klienta.html",
"en": "https://www.getresponse.com/help/customer-journey.html",
"fr": "https://www.getresponse.com/fr/aide/parcours-du-client.html",
"it": "https://www.getresponse.com/it/assistenza/customer-journey.html",
"de": "https://www.getresponse.com/de/hilfe/kundenreise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlataformaDeDatosDeClientes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/plataforma-de-datos-de-clientes.html",
"pl": "https://www.getresponse.pl/pomoc/platforma-danych-klientow.html",
"en": "https://www.getresponse.com/help/customer-data-platform.html",
"fr": "https://www.getresponse.com/fr/aide/plate-forme-de-donnees-clients.html",
"de": "https://www.getresponse.com/de/hilfe/kundendatenplattform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnModeleDeMessageDeConfirmationPersonnaliseDansLesComptesMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-modele-de-message-de-confirmation-personnalise-dans-les-comptes-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-template-di-messaggio-di-conferma-personalizzato-negli-account-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-custom-confirmation-message-template-in-max-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-plantilla-de-mensaje-de-confirmacion-personalizada-en-las-cuentas-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelskij-shablon-pisma-podtverzhdeniya-v-max-akkauntah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wlasny-szablon-wiadomosci-potwierdzajacej-w-kontach-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-benutzerdefinierte-bestaetigungsnachrichtenvorlage-in-max-konten-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatisationDesCourriels = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/automatisation-des-courriels.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-automation.html",
"it": "https://www.getresponse.com/it/assistenza/automazione-delle-email.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-correo-electronico.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-automatisierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPropositionDeVenteUnique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/proposition-de-vente-unique.html",
"en": "https://www.getresponse.com/help/unique-selling-proposition.html",
"es": "https://www.getresponse.com/es/ayuda/propuesta-unica-de-venta.html",
"pl": "https://www.getresponse.pl/pomoc/unikalna-propozycja-sprzedazy.html",
"de": "https://www.getresponse.com/de/hilfe/alleinstellungsmerkmal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDirect = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-direct.html",
"pl": "https://www.getresponse.pl/pomoc/bezposredni-marketing-pocztowy.html",
"en": "https://www.getresponse.com/help/direct-mail-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-por-correo-directo.html",
"de": "https://www.getresponse.com/de/hilfe/direktmailing-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEffetDeReseau = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/effet-de-reseau.html",
"en": "https://www.getresponse.com/help/network-effect.html",
"pl": "https://www.getresponse.pl/pomoc/efekt-sieci.html",
"es": "https://www.getresponse.com/es/ayuda/efecto-red.html",
"de": "https://www.getresponse.com/de/hilfe/netzwerkeffekt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBaliseDeTitre = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/balise-de-titre.html",
"en": "https://www.getresponse.com/help/title-tag.html",
"es": "https://www.getresponse.com/es/ayuda/etiqueta-de-titulo.html",
"pl": "https://www.getresponse.pl/pomoc/znacznik-tytulu.html",
"de": "https://www.getresponse.com/de/hilfe/title-tag.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRechercheSurLeSite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-le-site.html",
"en": "https://www.getresponse.com/help/site-search.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-w-witrynie.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-en-sitios-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanificationDesMedias = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/planification-des-medias.html",
"en": "https://www.getresponse.com/help/media-planning.html",
"pl": "https://www.getresponse.pl/pomoc/planowanie-mediow.html",
"es": "https://www.getresponse.com/es/ayuda/planificacion-de-medios.html",
"de": "https://www.getresponse.com/de/hilfe/medienplanung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCourrielHtml = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/courriel-html.html",
"pl": "https://www.getresponse.pl/pomoc/e-mail-html.html",
"en": "https://www.getresponse.com/help/html-email.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html-2.html",
"de": "https://www.getresponse.com/de/hilfe/html-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMediasPayants = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/medias-payants.html",
"en": "https://www.getresponse.com/help/paid-media.html",
"pl": "https://www.getresponse.pl/pomoc/platne-media.html",
"es": "https://www.getresponse.com/es/ayuda/medios-de-pago.html",
"de": "https://www.getresponse.com/de/hilfe/bezahlte-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCourrierElectroniqueTransactionnel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/courrier-electronique-transactionnel.html",
"en": "https://www.getresponse.com/help/transactional-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-electronico-transaccional.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-transakcyjne.html",
"de": "https://www.getresponse.com/de/hilfe/transaktionale-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDisplayAds = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/display-ads.html",
"pl": "https://www.getresponse.pl/pomoc/reklamy-displayowe.html",
"en": "https://www.getresponse.com/help/display-ads.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-display.html",
"de": "https://www.getresponse.com/de/hilfe/displayanzeigen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlateFormeDeDonneesClients = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/plate-forme-de-donnees-clients.html",
"pl": "https://www.getresponse.pl/pomoc/platforma-danych-klientow.html",
"en": "https://www.getresponse.com/help/customer-data-platform.html",
"es": "https://www.getresponse.com/es/ayuda/plataforma-de-datos-de-clientes.html",
"de": "https://www.getresponse.com/de/hilfe/kundendatenplattform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRechercheSurLesUtilisateurs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-les-utilisateurs.html",
"en": "https://www.getresponse.com/help/user-research.html",
"es": "https://www.getresponse.com/es/ayuda/investigacion-de-usuarios.html",
"pl": "https://www.getresponse.pl/pomoc/badania-uzytkownikow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeKampaniiIi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampanii-ii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCasiDiStudio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/casi-di-studio.html",
"pl": "https://www.getresponse.pl/pomoc/studia-przypadkow.html",
"en": "https://www.getresponse.com/help/case-studies.html",
"es": "https://www.getresponse.com/es/ayuda/casos-de-exito.html",
"fr": "https://www.getresponse.com/fr/aide/etudes-de-cas.html",
"de": "https://www.getresponse.com/de/hilfe/case-studies.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPianoDiMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/piano-di-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/plan-de-marketing.html",
"en": "https://www.getresponse.com/help/marketing-plan.html",
"pl": "https://www.getresponse.pl/pomoc/plan-marketingowy.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/marketingplan.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIcp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/icp.html",
"en": "https://www.getresponse.com/help/kpi.html",
"pl": "https://www.getresponse.pl/pomoc/kpi.html",
"es": "https://www.getresponse.com/es/ayuda/kpi.html",
"de": "https://www.getresponse.com/de/hilfe/kpi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMediasSociaux = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media.html",
"pl": "https://www.getresponse.pl/pomoc/media-spolecznosciowe.html",
"es": "https://www.getresponse.com/es/ayuda/redes-sociales.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConceptionDeSitesWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/conception-de-sites-web.html",
"en": "https://www.getresponse.com/help/web-design.html",
"es": "https://www.getresponse.com/es/ayuda/diseno-web.html",
"pl": "https://www.getresponse.pl/pomoc/projektowanie-stron-internetowych.html",
"de": "https://www.getresponse.com/de/hilfe/webgestaltung.html",
"it": "https://www.getresponse.com/it/assistenza/web-design.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomazioneDelleEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/automazione-delle-email.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-automation.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-des-courriels.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-correo-electronico.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-automatisierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSitoECommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sito-e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/strona-e-commerce.html",
"en": "https://www.getresponse.com/help/e-commerce-website.html",
"fr": "https://www.getresponse.com/fr/aide/site-web-de-commerce-electronique.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web-de-e-commerce.html",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStronaECommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/strona-e-commerce.html",
"en": "https://www.getresponse.com/help/e-commerce-website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/site-web-de-commerce-electronique.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web-de-e-commerce.html",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatyzacjaEMailowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/automatyzacja-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-automation.html",
"it": "https://www.getresponse.com/it/assistenza/automazione-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-des-courriels.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-correo-electronico.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-automatisierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFiltrowanieWiadomosciEMail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/filtrowanie-wiadomosci-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/email-filtering.html",
"en": "https://www.getresponse.com/help/email-filtering.html",
"fr": "https://www.getresponse.com/fr/aide/filtrage-du-courrier-electronique.html",
"es": "https://www.getresponse.com/es/ayuda/filtrado-de-correo-electronico.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-filterung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpam2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/spam-2.html",
"it": "https://www.getresponse.com/it/assistenza/soam-via-e-mail.html",
"en": "https://www.getresponse.com/help/email-spam.html",
"fr": "https://www.getresponse.com/fr/aide/spam-par-courriel.html",
"es": "https://www.getresponse.com/es/ayuda/correo-no-deseado.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrzegladarka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/przegladarka.html",
"es": "https://www.getresponse.com/es/ayuda/navegador.html",
"en": "https://www.getresponse.com/help/browser.html",
"fr": "https://www.getresponse.com/fr/aide/navigateur.html",
"it": "https://www.getresponse.com/it/assistenza/browser.html",
"de": "https://www.getresponse.com/de/hilfe/browser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNavigateur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/navigateur.html",
"es": "https://www.getresponse.com/es/ayuda/navegador.html",
"en": "https://www.getresponse.com/help/browser.html",
"pl": "https://www.getresponse.pl/pomoc/przegladarka.html",
"it": "https://www.getresponse.com/it/assistenza/browser.html",
"de": "https://www.getresponse.com/de/hilfe/browser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubliciteSurFacebook = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-facebook.html",
"en": "https://www.getresponse.com/help/facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/annunci-di-facebook.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDesMediasSociaux = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-des-medias-sociaux.html",
"en": "https://www.getresponse.com/help/social-media-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/social-media-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-mediach-spolecznosciowych.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-redes-sociales.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/site-web.html",
"en": "https://www.getresponse.com/help/website.html",
"it": "https://www.getresponse.com/it/assistenza/sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/strona-internetowa.html",
"de": "https://www.getresponse.com/de/hilfe/website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnnuaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/annuaire.html",
"pl": "https://www.getresponse.pl/pomoc/katalog.html",
"en": "https://www.getresponse.com/help/directory.html",
"es": "https://www.getresponse.com/es/ayuda/directorio.html",
"it": "https://www.getresponse.com/it/assistenza/directory.html",
"de": "https://www.getresponse.com/de/hilfe/verzeichnis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMotCle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mot-cle.html",
"en": "https://www.getresponse.com/help/keyword.html",
"it": "https://www.getresponse.com/it/assistenza/parola-chiave.html",
"pl": "https://www.getresponse.pl/pomoc/slowo-kluczowe.html",
"es": "https://www.getresponse.com/es/ayuda/palabra-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublic = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/public.html",
"pl": "https://www.getresponse.pl/pomoc/grupa-docelowa.html",
"en": "https://www.getresponse.com/help/audience.html",
"es": "https://www.getresponse.com/es/ayuda/audiencia.html",
"it": "https://www.getresponse.com/it/assistenza/pubblico.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppe.html",
"ru": "https://www.getresponse.com/ru/help-ru/auditoriya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParrainage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/parrainage.html",
"en": "https://www.getresponse.com/help/sponsorship.html",
"it": "https://www.getresponse.com/it/assistenza/sponsorizzazione.html",
"es": "https://www.getresponse.com/es/ayuda/patrocinio.html",
"pl": "https://www.getresponse.pl/pomoc/sponsoring.html",
"de": "https://www.getresponse.com/de/hilfe/sponsoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTarification = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/tarification.html",
"en": "https://www.getresponse.com/help/pricing.html",
"it": "https://www.getresponse.com/it/assistenza/prezzi.html",
"es": "https://www.getresponse.com/es/ayuda/fijacion-de-precios.html",
"pl": "https://www.getresponse.pl/pomoc/wycena.html",
"de": "https://www.getresponse.com/de/hilfe/preisgestaltung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoteurDeRecherche = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/moteur-de-recherche.html",
"en": "https://www.getresponse.com/help/search-engine.html",
"it": "https://www.getresponse.com/it/assistenza/motore-di-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/motor-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGifAnime = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/gif-anime.html",
"pl": "https://www.getresponse.pl/pomoc/animowany-gif.html",
"en": "https://www.getresponse.com/help/animated-gif.html",
"es": "https://www.getresponse.com/es/ayuda/gif-animado.html",
"it": "https://www.getresponse.com/it/assistenza/gif-animata.html",
"de": "https://www.getresponse.com/de/hilfe/animiertes-gif.html",
"ru": "https://www.getresponse.com/ru/help-ru/animirovannyj-gif.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCreationDeLiens = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/creation-de-liens.html",
"en": "https://www.getresponse.com/help/link-building.html",
"it": "https://www.getresponse.com/it/assistenza/link-building.html",
"pl": "https://www.getresponse.pl/pomoc/link-building.html",
"es": "https://www.getresponse.com/es/ayuda/creacion-de-enlaces.html",
"de": "https://www.getresponse.com/de/hilfe/linkaufbau.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProdvizhenieKursovDlyaPartnjorov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptimizacionDeLaTasaDeConversion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/optimizacion-de-la-tasa-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/optymalizacja-wspolczynnika-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate-optimization.html",
"fr": "https://www.getresponse.com/fr/aide/optimisation-du-taux-de-conversion.html",
"it": "https://www.getresponse.com/it/assistenza/ottimizzazione-del-tasso-di-conversione.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate-optimierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTasaDeConversion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tasa-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/wspolczynnik-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-conversion.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-conversione.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublicidadContextual = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/publicidad-contextual.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-kontekstowa.html",
"en": "https://www.getresponse.com/help/contextual-advertising.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-contestuale.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-contextuelle.html",
"de": "https://www.getresponse.com/de/hilfe/kontextbezogene-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDeContenido = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-de-contenido.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-tresci.html",
"en": "https://www.getresponse.com/help/content-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-contenu.html",
"it": "https://www.getresponse.com/it/assistenza/content-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/content-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKodowanieZnakow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kodowanie-znakow.html",
"en": "https://www.getresponse.com/help/character-encoding.html",
"de": "https://www.getresponse.com/de/hilfe/character-encoding.html",
"ru": "https://www.getresponse.com/ru/help-ru/kodirovka-simvolov.html",
"it": "https://www.getresponse.com/it/assistenza/codifica-dei-caratteri.html",
"br": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pt": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"es": "https://www.getresponse.com/es/ayuda/codificacion-de-caracteres.html",
"fr": "https://www.getresponse.com/fr/aide/codage-des-caracteres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKatalog = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/katalog.html",
"en": "https://www.getresponse.com/help/directory.html",
"fr": "https://www.getresponse.com/fr/aide/annuaire.html",
"es": "https://www.getresponse.com/es/ayuda/directorio.html",
"it": "https://www.getresponse.com/it/assistenza/directory.html",
"de": "https://www.getresponse.com/de/hilfe/verzeichnis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamyDisplayowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/reklamy-displayowe.html",
"en": "https://www.getresponse.com/help/display-ads.html",
"fr": "https://www.getresponse.com/fr/aide/display-ads.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-display.html",
"de": "https://www.getresponse.com/de/hilfe/displayanzeigen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDomena = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/domena.html",
"fr": "https://www.getresponse.com/fr/aide/domaine.html",
"en": "https://www.getresponse.com/help/domain.html",
"it": "https://www.getresponse.com/it/assistenza/dominio.html",
"es": "https://www.getresponse.com/es/ayuda/dominio.html",
"br": "https://www.getresponse.com/pt/ajuda/dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/dominio.html",
"de": "https://www.getresponse.com/de/hilfe/domain.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKampaniaTypuDrip = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kampania-typu-drip.html",
"en": "https://www.getresponse.com/help/drip-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-goteo.html",
"fr": "https://www.getresponse.com/fr/aide/drip-campagne.html",
"de": "https://www.getresponse.com/de/hilfe/drip-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaPomocNaCzacieDlaKontMaxMax2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTassoDiConversione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tasso-di-conversione.html",
"pl": "https://www.getresponse.pl/pomoc/wspolczynnik-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAppMobile = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/application-mobile.html",
"en": "https://www.getresponse.com/help/mobile-app.html",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna.html",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil.html",
"de": "https://www.getresponse.com/de/hilfe/mobile-app.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCustomerJourney = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/customer-journey.html",
"pl": "https://www.getresponse.pl/pomoc/podroz-klienta.html",
"en": "https://www.getresponse.com/help/customer-journey.html",
"fr": "https://www.getresponse.com/fr/aide/parcours-du-client.html",
"es": "https://www.getresponse.com/es/ayuda/recorrido-del-cliente.html",
"de": "https://www.getresponse.com/de/hilfe/kundenreise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestoAlt = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/testo-alt.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-alt.html",
"en": "https://www.getresponse.com/help/alt-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-alt.html",
"fr": "https://www.getresponse.com/fr/aide/texte-alt.html",
"de": "https://www.getresponse.com/de/hilfe/alt-text.html",
"ru": "https://www.getresponse.com/ru/help-ru/alt-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDeContenu = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-de-contenu.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-tresci.html",
"en": "https://www.getresponse.com/help/content-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/content-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-contenido.html",
"de": "https://www.getresponse.com/de/hilfe/content-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostApplicationMobile = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/application-mobile.html",
"en": "https://www.getresponse.com/help/mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/app-mobile.html",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna.html",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil.html",
"de": "https://www.getresponse.com/de/hilfe/mobile-app.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTauxDeConversion = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/taux-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/wspolczynnik-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-conversione.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetabalise = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/metabalise.html",
"en": "https://www.getresponse.com/help/meta-tag.html",
"pl": "https://www.getresponse.pl/pomoc/meta-tag.html",
"es": "https://www.getresponse.com/es/ayuda/metaetiquetas.html",
"de": "https://www.getresponse.com/de/hilfe/meta-tag.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeGenerareContenutiPubblicitariUtilizzandoAiAssistantNegliAnnunciAPagamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-generare-contenuti-pubblicitari-utilizzando-ai-assistant-negli-annunci-a-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-generer-du-contenu-publicitaire-a-l-aide-de-l-assistant-ia-dans-les-publicites-payantes.html",
"en": "https://www.getresponse.com/help/how-to-generate-ad-content-using-ai-assistant-in-paid-ads.html",
"es": "https://www.getresponse.com/es/ayuda/como-generar-contenido-publicitario-utilizando-el-asistente-ia-en-anuncios-de-pago.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wygenerowac-tresc-reklamy-za-pomoca-asystenta-ai-w-platnych-reklamach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-generiere-ich-anzeigeninhalte-mit-dem-ki-assistenten-in-paid-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHebergementWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/hebergement-web.html",
"en": "https://www.getresponse.com/help/web-hosting.html",
"es": "https://www.getresponse.com/es/ayuda/alojamiento-web.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-internetowy.html",
"de": "https://www.getresponse.com/de/hilfe/webhosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublicitesInstagram = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/publicites-instagram.html",
"en": "https://www.getresponse.com/help/instagram-ads.html",
"pl": "https://www.getresponse.pl/pomoc/reklamy-na-instagramie.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-instagram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEtudesDeCas = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/etudes-de-cas.html",
"pl": "https://www.getresponse.pl/pomoc/studia-przypadkow.html",
"en": "https://www.getresponse.com/help/case-studies.html",
"es": "https://www.getresponse.com/es/ayuda/casos-de-exito.html",
"it": "https://www.getresponse.com/it/assistenza/casi-di-studio.html",
"de": "https://www.getresponse.com/de/hilfe/case-studies.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/automatisation-du-marketing.html",
"en": "https://www.getresponse.com/help/marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-marketing.html",
"br": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-automatizacao-do-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/marketing-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParcoursDuClient = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/parcours-du-client.html",
"pl": "https://www.getresponse.pl/pomoc/podroz-klienta.html",
"en": "https://www.getresponse.com/help/customer-journey.html",
"it": "https://www.getresponse.com/it/assistenza/customer-journey.html",
"es": "https://www.getresponse.com/es/ayuda/recorrido-del-cliente.html",
"de": "https://www.getresponse.com/de/hilfe/kundenreise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTexteAlt = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/texte-alt.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-alt.html",
"en": "https://www.getresponse.com/help/alt-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-alt.html",
"it": "https://www.getresponse.com/it/assistenza/testo-alt.html",
"de": "https://www.getresponse.com/de/hilfe/alt-text.html",
"ru": "https://www.getresponse.com/ru/help-ru/alt-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubliciteSurYoutube = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-su-youtube.html",
"en": "https://www.getresponse.com/help/youtube-advertising.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-en-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-na-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingParMoteurDeRecherche = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-par-moteur-de-recherche.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-sui-motori-di-ricerca.html",
"en": "https://www.getresponse.com/help/search-engine-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-buscadores.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinenmarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModeleDeCourriel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/modele-de-courriel.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-e-mail.html",
"en": "https://www.getresponse.com/help/email-template.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-wiadomosci-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/plantillas-de-mensaje.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-vorlage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDifferenciationDesProduits = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/differenciation-des-produits.html",
"it": "https://www.getresponse.com/it/assistenza/differenziazione-del-prodotto.html",
"en": "https://www.getresponse.com/help/product-differentiation.html",
"es": "https://www.getresponse.com/es/ayuda/diferenciacion-de-productos.html",
"pl": "https://www.getresponse.pl/pomoc/roznicowanie-produktow.html",
"de": "https://www.getresponse.com/de/hilfe/produktdifferenzierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZapuskatKomandyPingITrassirovku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html",
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIniciirovatVsplyvajushheeOknoNaOsnoveOformlennogoZakazaIliObnovlennojKorziny = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-iniciirovat-vsplyvajushhee-okno-na-osnove-oformlennogo-zakaza-ili-obnovlennoj-korziny.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-in-base-all-ordine-effettuato-o-al-carrello-aggiornato.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-placed-order-or-updated-cart.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-na-podstawie-zlozonego-zamowienia-lub-aktualizacji-koszyka.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-un-pedido-realizado-o-un-carrito-actualizado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loese-ich-ein-pop-up-aus-wenn-ich-eine-bestellung-aufgegeben-oder-den-warenkorb-aktualisiert-habe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiSoedinitFormuSKnopkojNaMoemSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdavatNastraivaemyeOtchetyVUchetnyhZapisyahMax = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdavatNastraivaemyeOtchety = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnunciosClasificados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/anuncios-clasificados.html",
"pl": "https://www.getresponse.pl/pomoc/ogloszenia-drobne.html",
"en": "https://www.getresponse.com/help/classified-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/petites-annonces.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-classificata.html",
"de": "https://www.getresponse.com/de/hilfe/kleinanzeigenwerbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCasosDeExito = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/casos-de-exito.html",
"pl": "https://www.getresponse.pl/pomoc/studia-przypadkow.html",
"en": "https://www.getresponse.com/help/case-studies.html",
"fr": "https://www.getresponse.com/fr/aide/etudes-de-cas.html",
"it": "https://www.getresponse.com/it/assistenza/casi-di-studio.html",
"de": "https://www.getresponse.com/de/hilfe/case-studies.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNavegador = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/navegador.html",
"en": "https://www.getresponse.com/help/browser.html",
"pl": "https://www.getresponse.pl/pomoc/przegladarka.html",
"fr": "https://www.getresponse.com/fr/aide/navigateur.html",
"it": "https://www.getresponse.com/it/assistenza/browser.html",
"de": "https://www.getresponse.com/de/hilfe/browser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentacionDeLaAudiencia = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-audiencia.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-odbiorcow.html",
"en": "https://www.getresponse.com/help/audience-segmentation.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-del-pubblico.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-l-audience.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppensegmentierung.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-auditorii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAudiencia = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/audiencia.html",
"pl": "https://www.getresponse.pl/pomoc/grupa-docelowa.html",
"en": "https://www.getresponse.com/help/audience.html",
"fr": "https://www.getresponse.com/fr/aide/public.html",
"it": "https://www.getresponse.com/it/assistenza/pubblico.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppe.html",
"ru": "https://www.getresponse.com/ru/help-ru/auditoriya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGifAnimado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/gif-animado.html",
"pl": "https://www.getresponse.pl/pomoc/animowany-gif.html",
"en": "https://www.getresponse.com/help/animated-gif.html",
"fr": "https://www.getresponse.com/fr/aide/gif-anime.html",
"it": "https://www.getresponse.com/it/assistenza/gif-animata.html",
"de": "https://www.getresponse.com/de/hilfe/animiertes-gif.html",
"ru": "https://www.getresponse.com/ru/help-ru/animirovannyj-gif.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTextoAlt = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/texto-alt.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-alt.html",
"en": "https://www.getresponse.com/help/alt-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-alt.html",
"it": "https://www.getresponse.com/it/assistenza/testo-alt.html",
"de": "https://www.getresponse.com/de/hilfe/alt-text.html",
"ru": "https://www.getresponse.com/ru/help-ru/alt-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGenerarContenidoPublicitarioUtilizandoElAsistenteIaEnAnunciosDePago = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-generar-contenido-publicitario-utilizando-el-asistente-ia-en-anuncios-de-pago.html",
"fr": "https://www.getresponse.com/fr/aide/comment-generer-du-contenu-publicitaire-a-l-aide-de-l-assistant-ia-dans-les-publicites-payantes.html",
"en": "https://www.getresponse.com/help/how-to-generate-ad-content-using-ai-assistant-in-paid-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-generare-contenuti-pubblicitari-utilizzando-ai-assistant-negli-annunci-a-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wygenerowac-tresc-reklamy-za-pomoca-asystenta-ai-w-platnych-reklamach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-generiere-ich-anzeigeninhalte-mit-dem-ki-assistenten-in-paid-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpamParCourriel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/spam-par-courriel.html",
"it": "https://www.getresponse.com/it/assistenza/soam-via-e-mail.html",
"en": "https://www.getresponse.com/help/email-spam.html",
"pl": "https://www.getresponse.pl/pomoc/spam-2.html",
"es": "https://www.getresponse.com/es/ayuda/correo-no-deseado.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReseauDAffiliation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/reseau-d-affiliation.html",
"pl": "https://www.getresponse.pl/pomoc/siec-afiliacyjna.html",
"en": "https://www.getresponse.com/help/affiliate-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/rete-di-affiliazione.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-netzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnjorskaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentationDeLAudience = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-l-audience.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-odbiorcow.html",
"en": "https://www.getresponse.com/help/audience-segmentation.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-del-pubblico.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-audiencia.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppensegmentierung.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-auditorii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTauxDEngagement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/taux-d-engagement.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-coinvolgimento.html",
"en": "https://www.getresponse.com/help/engagement-rate.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zaangazowania.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-compromiso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRechercheOrganique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/recherche-organique.html",
"it": "https://www.getresponse.com/it/assistenza/ricerca-organica.html",
"en": "https://www.getresponse.com/help/organic-search.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-organica.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-organiczne.html",
"de": "https://www.getresponse.com/de/hilfe/organische-suche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMisesAJourDeLAlgorithmeDeGoogle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mises-a-jour-de-l-algorithme-de-google.html",
"it": "https://www.getresponse.com/it/assistenza/aggiornamenti-dell-algoritmo-di-google.html",
"en": "https://www.getresponse.com/help/google-algorithm-updates.html",
"pl": "https://www.getresponse.pl/pomoc/aktualizacje-algorytmu-google.html",
"es": "https://www.getresponse.com/es/ayuda/actualizaciones-del-algoritmo-de-google.html",
"de": "https://www.getresponse.com/de/hilfe/google-algorithmus-aktualisierungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeTextePublicitaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/le-texte-publicitaire.html",
"pl": "https://www.getresponse.pl/pomoc/ad-copy.html",
"en": "https://www.getresponse.com/help/ad-copy.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-publicitario.html",
"it": "https://www.getresponse.com/it/assistenza/il-testo-pubblicitario.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-tekst.html",
"de": "https://www.getresponse.com/de/hilfe/anzeigenkopie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeoInternational = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/seo-international.html",
"it": "https://www.getresponse.com/it/assistenza/seo-internazionale.html",
"en": "https://www.getresponse.com/help/international-seo.html",
"pl": "https://www.getresponse.pl/pomoc/miedzynarodowe-seo.html",
"es": "https://www.getresponse.com/es/ayuda/seo-internacional.html",
"de": "https://www.getresponse.com/de/hilfe/internationales-seo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRpNumeriques = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rp-numeriques.html",
"pl": "https://www.getresponse.pl/pomoc/digital-pr.html",
"en": "https://www.getresponse.com/help/digital-pr.html",
"it": "https://www.getresponse.com/it/assistenza/pr-digitale.html",
"es": "https://www.getresponse.com/es/ayuda/rp-digitales.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-pr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContenuNumerique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/contenu-numerique.html",
"pl": "https://www.getresponse.pl/pomoc/tresci-cyfrowe.html",
"en": "https://www.getresponse.com/help/digital-content.html",
"it": "https://www.getresponse.com/it/assistenza/contenuto-digitale.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-inhalte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestMultivarie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/test-multivarie.html",
"it": "https://www.getresponse.com/it/assistenza/test-multivariato.html",
"en": "https://www.getresponse.com/help/multivariate-testing.html",
"pl": "https://www.getresponse.pl/pomoc/testy-wielowymiarowe.html",
"es": "https://www.getresponse.com/es/ayuda/test-multivariante.html",
"de": "https://www.getresponse.com/de/hilfe/multivariate-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZaregistrirovatBesplatnyjPolzovatelskijDomen = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaregistrirovat-besplatnyj-polzovatelskij-domen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarejestrowac-wlasna-darmowa-domene.html",
"en": "https://www.getresponse.com/help/how-to-register-a-free-custom-domain.html",
"it": "https://www.getresponse.com/it/assistenza/come-registrare-un-dominio-personalizzato-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/como-registrar-un-dominio-personalizado-gratuito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-domaine-personnalise-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStrategieDeMiseSurLeMarche = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/strategie-de-mise-sur-le-marche.html",
"it": "https://www.getresponse.com/it/assistenza/strategia-go-to-market.html",
"en": "https://www.getresponse.com/help/go-to-market-strategy.html",
"pl": "https://www.getresponse.pl/pomoc/strategia-wejscia-na-rynek.html",
"es": "https://www.getresponse.com/es/ayuda/estrategia-gtm.html",
"de": "https://www.getresponse.com/de/hilfe/go-to-market-strategie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReseauPublicitaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/reseau-publicitaire.html",
"pl": "https://www.getresponse.pl/pomoc/siec-reklamowa.html",
"en": "https://www.getresponse.com/help/ad-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-publicitaria.html",
"it": "https://www.getresponse.com/it/assistenza/rete-pubblicitaria.html",
"de": "https://www.getresponse.com/de/hilfe/werbenetzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGrilleTarifaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/grille-tarifaire.html",
"it": "https://www.getresponse.com/it/assistenza/tariffario.html",
"en": "https://www.getresponse.com/help/rate-card.html",
"es": "https://www.getresponse.com/es/ayuda/tarjeta-de-tarifas.html",
"pl": "https://www.getresponse.pl/pomoc/cennik.html",
"de": "https://www.getresponse.com/de/hilfe/preiskatalog.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHebergementDedie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/hebergement-dedie.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-hosting.html",
"it": "https://www.getresponse.com/it/assistenza/hosting-dedicato.html",
"es": "https://www.getresponse.com/es/ayuda/hosting-dedicado.html",
"de": "https://www.getresponse.com/de/hilfe/dediziertes-hosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetamoteurDeRecherche = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/metamoteur-de-recherche.html",
"it": "https://www.getresponse.com/it/assistenza/metamotore-di-ricerca.html",
"en": "https://www.getresponse.com/help/meta-search-engine.html",
"pl": "https://www.getresponse.pl/pomoc/metawyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/metabuscador.html",
"de": "https://www.getresponse.com/de/hilfe/meta-suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingPersonnalise = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-personnalise.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-personalizzato.html",
"en": "https://www.getresponse.com/help/personalized-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-spersonalizowany.html",
"de": "https://www.getresponse.com/de/hilfe/personalisierte-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDensiteDesMotsCles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/densite-des-mots-cles.html",
"it": "https://www.getresponse.com/it/assistenza/densita-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-density.html",
"pl": "https://www.getresponse.pl/pomoc/gestosc-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/densidad-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-dichte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTexteDuLien = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/texte-du-lien.html",
"it": "https://www.getresponse.com/it/assistenza/testo-del-link.html",
"en": "https://www.getresponse.com/help/link-text.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-linku.html",
"es": "https://www.getresponse.com/es/ayuda/texto-de-enlace.html",
"de": "https://www.getresponse.com/de/hilfe/linktext.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBourrageDeMotsCles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/bourrage-de-mots-cles.html",
"it": "https://www.getresponse.com/it/assistenza/keyword-stuffing.html",
"en": "https://www.getresponse.com/help/keyword-stuffing.html",
"pl": "https://www.getresponse.pl/pomoc/upychanie-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/relleno-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-stuffing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubliciteContextuelle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/publicite-contextuelle.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-kontekstowa.html",
"en": "https://www.getresponse.com/help/contextual-advertising.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-contestuale.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-contextual.html",
"de": "https://www.getresponse.com/de/hilfe/kontextbezogene-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGestionnaireDAffiliation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/gestionnaire-d-affiliation.html",
"pl": "https://www.getresponse.pl/pomoc/affiliate-manager.html",
"en": "https://www.getresponse.com/help/affiliate-manager.html",
"es": "https://www.getresponse.com/es/ayuda/gestor-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/affiliate-manager.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/menedzher-po-rabote-s-partnjorami.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPanierAbandonne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne.html",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/abandoned-cart.html",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato.html",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaya-korzina.html",
"de": "https://www.getresponse.com/de/hilfe/verlassener-warenkorb.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlocageDesPublicites = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/blocage-des-publicites.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-reklam.html",
"en": "https://www.getresponse.com/help/ad-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-degli-annunci.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/blockieren-von-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFiltrageDuCourrierElectronique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/filtrage-du-courrier-electronique.html",
"it": "https://www.getresponse.com/it/assistenza/email-filtering.html",
"en": "https://www.getresponse.com/help/email-filtering.html",
"pl": "https://www.getresponse.pl/pomoc/filtrowanie-wiadomosci-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/filtrado-de-correo-electronico.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-filterung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingParMotsCles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-par-mots-cles.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAttribuerMonPropreDomaineAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAttribuerMonPropreSousDomaineAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnFormulaireDeWebinaireAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnSiteWebALAideDeModeles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnSiteWebALAideDeLAssistantIa = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerUnePhotoAPartirDUneUrl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-photo-a-partir-d-une-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-photo-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-adjuntar-una-foto-desde-una-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-una-foto-da-un-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSauvegarderEtReutiliserDesSectionsEtDesBlocs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreMonSiteWebAvecGoogleAnalytics = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterLeSuiviDesConversionsGoogleAdsAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerMonSiteWebAGoogleTagManager = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreMonSiteWebAvecYandexMetrica = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreMonSiteWebAvecFacebookPixel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesSectionsSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterEtModifierUnMenuDeSiteDeMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterEtModifierUnBoutonSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterEtGererUnBlocDeTexteSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterEtGererDesImagesSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLesPagesDansLeMenuDuSite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentNaviguerEntreLesPagesDansLEditeurDeSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLOrdreDesPagesDansLeMenuDuSite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLaPageDAccueil = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesSousPages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMasquerUnePageDansLeMenuDuSite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneNouvellePageAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDupliquerUnePage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSupprimerUnePage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-page.html",
"de": "https://www.getresponse.com/de/hilfe/how-can-i-delete-a-page.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ydalit-stranitsy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-podstrone.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnLienAuMenuDuSite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesPagesNonTrouveEtMotDePasse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLeDesignGlobalDansLeCreateurDeSiteInternet = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnFormulaireDInscriptionAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerUneVideoAPartirDUneUrl2 = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnFormulaireDeContactAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingOmnicanale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/marketing-omnicanale.html",
"en": "https://www.getresponse.com/help/omnichannel-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-omnicanal.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-omnikanalowy.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-omnicanal.html",
"de": "https://www.getresponse.com/de/hilfe/omnichannel-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubblicitaSuYoutube = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pubblicita-su-youtube.html",
"en": "https://www.getresponse.com/help/youtube-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-en-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-na-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokowanieReklam = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/blokowanie-reklam.html",
"en": "https://www.getresponse.com/help/ad-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-degli-annunci.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-des-publicites.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/blockieren-von-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSiecReklamowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/siec-reklamowa.html",
"en": "https://www.getresponse.com/help/ad-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-publicitaria.html",
"it": "https://www.getresponse.com/it/assistenza/rete-pubblicitaria.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-publicitaire.html",
"de": "https://www.getresponse.com/de/hilfe/werbenetzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTekstAlt = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/tekst-alt.html",
"en": "https://www.getresponse.com/help/alt-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-alt.html",
"fr": "https://www.getresponse.com/fr/aide/texte-alt.html",
"it": "https://www.getresponse.com/it/assistenza/testo-alt.html",
"de": "https://www.getresponse.com/de/hilfe/alt-text.html",
"ru": "https://www.getresponse.com/ru/help-ru/alt-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBuforowaniePodreczne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/buforowanie-podreczne.html",
"en": "https://www.getresponse.com/help/caching.html",
"es": "https://www.getresponse.com/es/ayuda/caching.html",
"fr": "https://www.getresponse.com/fr/aide/caching.html",
"de": "https://www.getresponse.com/de/hilfe/caching.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOgloszeniaDrobne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ogloszenia-drobne.html",
"en": "https://www.getresponse.com/help/classified-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/petites-annonces.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-classificata.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-clasificados.html",
"de": "https://www.getresponse.com/de/hilfe/kleinanzeigenwerbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamaKontekstowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/reklama-kontekstowa.html",
"en": "https://www.getresponse.com/help/contextual-advertising.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-contestuale.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-contextuelle.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-contextual.html",
"de": "https://www.getresponse.com/de/hilfe/kontextbezogene-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWspolczynnikKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wspolczynnik-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-conversion.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-conversione.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptymalizacjaWspolczynnikaKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/optymalizacja-wspolczynnika-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate-optimization.html",
"fr": "https://www.getresponse.com/fr/aide/optimisation-du-taux-de-conversion.html",
"it": "https://www.getresponse.com/it/assistenza/ottimizzazione-del-tasso-di-conversione.html",
"es": "https://www.getresponse.com/es/ayuda/optimizacion-de-la-tasa-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate-optimierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreferencjeDotyczacePlikowCookie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/preferencje-dotyczace-plikow-cookie.html",
"en": "https://www.getresponse.com/help/cookie-preferences.html",
"fr": "https://www.getresponse.com/fr/aide/cookie-preferences.html",
"it": "https://www.getresponse.com/it/assistenza/preferenze-sui-cookie.html",
"es": "https://www.getresponse.com/es/ayuda/preferencias-de-cookies.html",
"br": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"pt": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"de": "https://www.getresponse.com/de/hilfe/cookie-einstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostArtykulSponsorowany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/artykul-sponsorowany.html",
"en": "https://www.getresponse.com/help/advertorial.html",
"es": "https://www.getresponse.com/es/ayuda/publirreportaje.html",
"fr": "https://www.getresponse.com/fr/aide/publireportage.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-statya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGrupaDocelowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/grupa-docelowa.html",
"en": "https://www.getresponse.com/help/audience.html",
"es": "https://www.getresponse.com/es/ayuda/audiencia.html",
"fr": "https://www.getresponse.com/fr/aide/public.html",
"it": "https://www.getresponse.com/it/assistenza/pubblico.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppe.html",
"ru": "https://www.getresponse.com/ru/help-ru/auditoriya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKlikniecie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/klikniecie.html",
"en": "https://www.getresponse.com/help/click-through.html",
"fr": "https://www.getresponse.com/fr/aide/click-through.html",
"it": "https://www.getresponse.com/it/assistenza/click-through.html",
"es": "https://www.getresponse.com/es/ayuda/click-through.html",
"br": "https://www.getresponse.com/pt/ajuda/click-through.html",
"pt": "https://www.getresponse.com/pt/ajuda/click-through.html",
"de": "https://www.getresponse.com/de/hilfe/durchklick.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStudiaPrzypadkow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/studia-przypadkow.html",
"en": "https://www.getresponse.com/help/case-studies.html",
"es": "https://www.getresponse.com/es/ayuda/casos-de-exito.html",
"fr": "https://www.getresponse.com/fr/aide/etudes-de-cas.html",
"it": "https://www.getresponse.com/it/assistenza/casi-di-studio.html",
"de": "https://www.getresponse.com/de/hilfe/case-studies.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlatformaDanychKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/platforma-danych-klientow.html",
"en": "https://www.getresponse.com/help/customer-data-platform.html",
"fr": "https://www.getresponse.com/fr/aide/plate-forme-de-donnees-clients.html",
"es": "https://www.getresponse.com/es/ayuda/plataforma-de-datos-de-clientes.html",
"de": "https://www.getresponse.com/de/hilfe/kundendatenplattform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodrozKlienta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/podroz-klienta.html",
"en": "https://www.getresponse.com/help/customer-journey.html",
"fr": "https://www.getresponse.com/fr/aide/parcours-du-client.html",
"it": "https://www.getresponse.com/it/assistenza/customer-journey.html",
"es": "https://www.getresponse.com/es/ayuda/recorrido-del-cliente.html",
"de": "https://www.getresponse.com/de/hilfe/kundenreise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHostingDedykowany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/hosting-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-hosting.html",
"it": "https://www.getresponse.com/it/assistenza/hosting-dedicato.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-dedie.html",
"es": "https://www.getresponse.com/es/ayuda/hosting-dedicado.html",
"de": "https://www.getresponse.com/de/hilfe/dediziertes-hosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTresciCyfrowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/tresci-cyfrowe.html",
"en": "https://www.getresponse.com/help/digital-content.html",
"it": "https://www.getresponse.com/it/assistenza/contenuto-digitale.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-numerique.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-inhalte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingCyfrowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-cyfrowy.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-numerique.html",
"en": "https://www.getresponse.com/help/digital-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/digital-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-digital.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitales-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDigitalPr = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/digital-pr.html",
"en": "https://www.getresponse.com/help/digital-pr.html",
"it": "https://www.getresponse.com/it/assistenza/pr-digitale.html",
"fr": "https://www.getresponse.com/fr/aide/rp-numeriques.html",
"es": "https://www.getresponse.com/es/ayuda/rp-digitales.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-pr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBezposredniMarketingPocztowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/bezposredni-marketing-pocztowy.html",
"en": "https://www.getresponse.com/help/direct-mail-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-direct.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-por-correo-directo.html",
"de": "https://www.getresponse.com/de/hilfe/direktmailing-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingTresci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-tresci.html",
"en": "https://www.getresponse.com/help/content-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-contenu.html",
"it": "https://www.getresponse.com/it/assistenza/content-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-contenido.html",
"de": "https://www.getresponse.com/de/hilfe/content-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnimowanyGif = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/animowany-gif.html",
"en": "https://www.getresponse.com/help/animated-gif.html",
"es": "https://www.getresponse.com/es/ayuda/gif-animado.html",
"fr": "https://www.getresponse.com/fr/aide/gif-anime.html",
"it": "https://www.getresponse.com/it/assistenza/gif-animata.html",
"de": "https://www.getresponse.com/de/hilfe/animiertes-gif.html",
"ru": "https://www.getresponse.com/ru/help-ru/animirovannyj-gif.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingWModeluAida = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-w-modelu-aida.html",
"en": "https://www.getresponse.com/help/aida-model-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-marketing-aida.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-marketing-aida.html",
"it": "https://www.getresponse.com/it/assistenza/modello-marketing-aida.html",
"de": "https://www.getresponse.com/de/hilfe/aida-modell-im-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaya-model-aida.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSiecAfiliacyjna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/siec-afiliacyjna.html",
"en": "https://www.getresponse.com/help/affiliate-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/rete-di-affiliazione.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-d-affiliation.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-netzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnjorskaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdCopy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ad-copy.html",
"en": "https://www.getresponse.com/help/ad-copy.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-publicitario.html",
"it": "https://www.getresponse.com/it/assistenza/il-testo-pubblicitario.html",
"fr": "https://www.getresponse.com/fr/aide/le-texte-publicitaire.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-tekst.html",
"de": "https://www.getresponse.com/de/hilfe/anzeigenkopie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentacjaOdbiorcow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/segmentacja-odbiorcow.html",
"en": "https://www.getresponse.com/help/audience-segmentation.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-del-pubblico.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-l-audience.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-audiencia.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppensegmentierung.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-auditorii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesSeparateursAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterLEspacementAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreUnFormulaireDeContactEtUnFormulaireDInscription2 = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesPublicitesGoogleDansGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesVisiteursDuSiteWebDansLesPublicitesFacebook = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesPublicitesFacebookDansGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentLAnnonceMeSeraTElleFacturee = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html",
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuDeconnecterLIntegration = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConnecterUnCompteGoogleAdsAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConnecterUnCompteFacebookAdsAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSynchroniserLAudienceDeMesAnnoncesAvecGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGenererDuContenuPublicitaireALAideDeLAssistantIaDansLesPublicitesPayantes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-generer-du-contenu-publicitaire-a-l-aide-de-l-assistant-ia-dans-les-publicites-payantes.html",
"en": "https://www.getresponse.com/help/how-to-generate-ad-content-using-ai-assistant-in-paid-ads.html",
"es": "https://www.getresponse.com/es/ayuda/como-generar-contenido-publicitario-utilizando-el-asistente-ia-en-anuncios-de-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-generare-contenuti-pubblicitari-utilizzando-ai-assistant-negli-annunci-a-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wygenerowac-tresc-reklamy-za-pomoca-asystenta-ai-w-platnych-reklamach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-generiere-ich-anzeigeninhalte-mit-dem-ki-assistenten-in-paid-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLesWebinairesFonctionnentIlsSurLesAppareilsMobiles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html",
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnvoyerEtGererDesNewslettersALAideDeLApplicationMobileGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGetresponseProposeTIlUneApplicationMobile = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeMeConnecterALApplicationMobileALAideDUnLienMagique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPersonnaliserLeTableauDeBordDeMonApplicationMobile = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLesListesEtLesContactsALAideDeLApplicationMobile = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeEnvoyerDesNewslettersBaseesSurLesActionsDesAbonnesDansMesMessagesPrecedentsALAideDeLApplicationMobileGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatTeachableSGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-teachable-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-teachable-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-teachable-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-teachable-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-teachable-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-teachable-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-teachable-mit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingSuiMotoriDiRicerca = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/marketing-sui-motori-di-ricerca.html",
"en": "https://www.getresponse.com/help/search-engine-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-moteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-buscadores.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinenmarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModelloDiEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/modello-di-e-mail.html",
"en": "https://www.getresponse.com/help/email-template.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-wiadomosci-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/plantillas-de-mensaje.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-vorlage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDifferenziazioneDelProdotto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/differenziazione-del-prodotto.html",
"en": "https://www.getresponse.com/help/product-differentiation.html",
"fr": "https://www.getresponse.com/fr/aide/differenciation-des-produits.html",
"es": "https://www.getresponse.com/es/ayuda/diferenciacion-de-productos.html",
"pl": "https://www.getresponse.pl/pomoc/roznicowanie-produktow.html",
"de": "https://www.getresponse.com/de/hilfe/produktdifferenzierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSoamViaEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/soam-via-e-mail.html",
"en": "https://www.getresponse.com/help/email-spam.html",
"fr": "https://www.getresponse.com/fr/aide/spam-par-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/spam-2.html",
"es": "https://www.getresponse.com/es/ayuda/correo-no-deseado.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReteDiAffiliazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/rete-di-affiliazione.html",
"pl": "https://www.getresponse.pl/pomoc/siec-afiliacyjna.html",
"en": "https://www.getresponse.com/help/affiliate-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-d-affiliation.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-netzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnjorskaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentazioneDelPubblico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/segmentazione-del-pubblico.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-odbiorcow.html",
"en": "https://www.getresponse.com/help/audience-segmentation.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-l-audience.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-audiencia.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppensegmentierung.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-auditorii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTassoDiCoinvolgimento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tasso-di-coinvolgimento.html",
"en": "https://www.getresponse.com/help/engagement-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-engagement.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zaangazowania.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-compromiso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRicercaOrganica = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ricerca-organica.html",
"en": "https://www.getresponse.com/help/organic-search.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-organique.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-organica.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-organiczne.html",
"de": "https://www.getresponse.com/de/hilfe/organische-suche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAggiornamentiDellAlgoritmoDiGoogle = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/aggiornamenti-dell-algoritmo-di-google.html",
"en": "https://www.getresponse.com/help/google-algorithm-updates.html",
"fr": "https://www.getresponse.com/fr/aide/mises-a-jour-de-l-algorithme-de-google.html",
"pl": "https://www.getresponse.pl/pomoc/aktualizacje-algorytmu-google.html",
"es": "https://www.getresponse.com/es/ayuda/actualizaciones-del-algoritmo-de-google.html",
"de": "https://www.getresponse.com/de/hilfe/google-algorithmus-aktualisierungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIlTestoPubblicitario = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/il-testo-pubblicitario.html",
"pl": "https://www.getresponse.pl/pomoc/ad-copy.html",
"en": "https://www.getresponse.com/help/ad-copy.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-publicitario.html",
"fr": "https://www.getresponse.com/fr/aide/le-texte-publicitaire.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-tekst.html",
"de": "https://www.getresponse.com/de/hilfe/anzeigenkopie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeoInternazionale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/seo-internazionale.html",
"en": "https://www.getresponse.com/help/international-seo.html",
"fr": "https://www.getresponse.com/fr/aide/seo-international.html",
"pl": "https://www.getresponse.pl/pomoc/miedzynarodowe-seo.html",
"es": "https://www.getresponse.com/es/ayuda/seo-internacional.html",
"de": "https://www.getresponse.com/de/hilfe/internationales-seo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostViralMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/viral-marketing.html",
"en": "https://www.getresponse.com/help/viral-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-viral.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-viral.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-wirusowy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrDigitale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pr-digitale.html",
"pl": "https://www.getresponse.pl/pomoc/digital-pr.html",
"en": "https://www.getresponse.com/help/digital-pr.html",
"fr": "https://www.getresponse.com/fr/aide/rp-numeriques.html",
"es": "https://www.getresponse.com/es/ayuda/rp-digitales.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-pr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContenutoDigitale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/contenuto-digitale.html",
"pl": "https://www.getresponse.pl/pomoc/tresci-cyfrowe.html",
"en": "https://www.getresponse.com/help/digital-content.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-numerique.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-inhalte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestMultivariato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/test-multivariato.html",
"en": "https://www.getresponse.com/help/multivariate-testing.html",
"fr": "https://www.getresponse.com/fr/aide/test-multivarie.html",
"pl": "https://www.getresponse.pl/pomoc/testy-wielowymiarowe.html",
"es": "https://www.getresponse.com/es/ayuda/test-multivariante.html",
"de": "https://www.getresponse.com/de/hilfe/multivariate-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStrategiaGoToMarket = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/strategia-go-to-market.html",
"en": "https://www.getresponse.com/help/go-to-market-strategy.html",
"fr": "https://www.getresponse.com/fr/aide/strategie-de-mise-sur-le-marche.html",
"pl": "https://www.getresponse.pl/pomoc/strategia-wejscia-na-rynek.html",
"es": "https://www.getresponse.com/es/ayuda/estrategia-gtm.html",
"de": "https://www.getresponse.com/de/hilfe/go-to-market-strategie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetePubblicitaria = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/rete-pubblicitaria.html",
"pl": "https://www.getresponse.pl/pomoc/siec-reklamowa.html",
"en": "https://www.getresponse.com/help/ad-network.html",
"es": "https://www.getresponse.com/es/ayuda/red-publicitaria.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-publicitaire.html",
"de": "https://www.getresponse.com/de/hilfe/werbenetzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTariffario = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tariffario.html",
"en": "https://www.getresponse.com/help/rate-card.html",
"fr": "https://www.getresponse.com/fr/aide/grille-tarifaire.html",
"es": "https://www.getresponse.com/es/ayuda/tarjeta-de-tarifas.html",
"pl": "https://www.getresponse.pl/pomoc/cennik.html",
"de": "https://www.getresponse.com/de/hilfe/preiskatalog.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHostingDedicato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/hosting-dedicato.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-hosting.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-dedie.html",
"es": "https://www.getresponse.com/es/ayuda/hosting-dedicado.html",
"de": "https://www.getresponse.com/de/hilfe/dediziertes-hosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetamotoreDiRicerca = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/metamotore-di-ricerca.html",
"en": "https://www.getresponse.com/help/meta-search-engine.html",
"fr": "https://www.getresponse.com/fr/aide/metamoteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/metawyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/metabuscador.html",
"de": "https://www.getresponse.com/de/hilfe/meta-suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingPersonalizzato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/marketing-personalizzato.html",
"en": "https://www.getresponse.com/help/personalized-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-personnalise.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-spersonalizowany.html",
"de": "https://www.getresponse.com/de/hilfe/personalisierte-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDensitaDelleParoleChiave = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/densita-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-density.html",
"fr": "https://www.getresponse.com/fr/aide/densite-des-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/gestosc-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/densidad-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-dichte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestoDelLink = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/testo-del-link.html",
"en": "https://www.getresponse.com/help/link-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-du-lien.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-linku.html",
"es": "https://www.getresponse.com/es/ayuda/texto-de-enlace.html",
"de": "https://www.getresponse.com/de/hilfe/linktext.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKeywordStuffing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/keyword-stuffing.html",
"en": "https://www.getresponse.com/help/keyword-stuffing.html",
"fr": "https://www.getresponse.com/fr/aide/bourrage-de-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/upychanie-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/relleno-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-stuffing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVistaDellaPaginapageView = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/vista-della-paginapage-view.html",
"fr": "https://www.getresponse.com/fr/aide/vue-de-la-page.html",
"en": "https://www.getresponse.com/help/page-view.html",
"pl": "https://www.getresponse.pl/pomoc/odslony-strony.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-vista.html",
"br": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/seitenaufruf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubblicitaContestuale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pubblicita-contestuale.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-kontekstowa.html",
"en": "https://www.getresponse.com/help/contextual-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-contextuelle.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-contextual.html",
"de": "https://www.getresponse.com/de/hilfe/kontextbezogene-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCarrelloAbbandonato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato.html",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/abandoned-cart.html",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne.html",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaya-korzina.html",
"de": "https://www.getresponse.com/de/hilfe/verlassener-warenkorb.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloccoDegliAnnunci = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/blocco-degli-annunci.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-reklam.html",
"en": "https://www.getresponse.com/help/ad-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-anuncios.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-des-publicites.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/blockieren-von-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailFiltering = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/email-filtering.html",
"en": "https://www.getresponse.com/help/email-filtering.html",
"fr": "https://www.getresponse.com/fr/aide/filtrage-du-courrier-electronique.html",
"pl": "https://www.getresponse.pl/pomoc/filtrowanie-wiadomosci-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/filtrado-de-correo-electronico.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-filterung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDelleParoleChiave = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/marketing-delle-parole-chiave.html",
"en": "https://www.getresponse.com/help/keyword-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaDescrizioni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/meta-descrizioni.html",
"fr": "https://www.getresponse.com/fr/aide/meta-descriptions.html",
"en": "https://www.getresponse.com/help/meta-descriptions.html",
"pl": "https://www.getresponse.pl/pomoc/opisy-meta.html",
"es": "https://www.getresponse.com/es/ayuda/meta-descripciones.html",
"de": "https://www.getresponse.com/de/hilfe/meta-beschreibung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubblicitaClassificata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pubblicita-classificata.html",
"pl": "https://www.getresponse.pl/pomoc/ogloszenia-drobne.html",
"en": "https://www.getresponse.com/help/classified-advertising.html",
"fr": "https://www.getresponse.com/fr/aide/petites-annonces.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-clasificados.html",
"de": "https://www.getresponse.com/de/hilfe/kleinanzeigenwerbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOttimizzazioneDelTassoDiConversione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ottimizzazione-del-tasso-di-conversione.html",
"pl": "https://www.getresponse.pl/pomoc/optymalizacja-wspolczynnika-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate-optimization.html",
"fr": "https://www.getresponse.com/fr/aide/optimisation-du-taux-de-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/optimizacion-de-la-tasa-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate-optimierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRemarketingDiGoogle = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/remarketing-di-google.html",
"fr": "https://www.getresponse.com/fr/aide/remarketing-de-google.html",
"en": "https://www.getresponse.com/help/google-remarketing.html",
"pl": "https://www.getresponse.pl/pomoc/remarketing-google.html",
"es": "https://www.getresponse.com/es/ayuda/google-remarketing.html",
"de": "https://www.getresponse.com/de/hilfe/google-remarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkInUscita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/link-in-uscita.html",
"fr": "https://www.getresponse.com/fr/aide/lien-sortant.html",
"en": "https://www.getresponse.com/help/outbound-link.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-saliente.html",
"pl": "https://www.getresponse.pl/pomoc/link-wychodzacy.html",
"de": "https://www.getresponse.com/de/hilfe/ausgehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezhduDomenomDlyaOtpravkiIAdresomOtpravitelya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-domenom-dlya-otpravki-i-adresom-otpravitelya.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-dominio-di-invio-e-l-indirizzo-da.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-the-sending-domain-and-from-address.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-domena-wysylkowa-a-adresem-nadawcy-od.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-dominio-remitente-y-la-direccion-de.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-domaine-denvoi-et-ladresse-from.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-der-sendedomane-und-der-von-adresse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOutilsDeMarketingIa = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-ia.html",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-marketingowe-ai.html",
"en": "https://www.getresponse.com/help/ai-marketing-tools.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-de-ia.html",
"it": "https://www.getresponse.com/it/assistenza/ai-marketing-tools.html",
"de": "https://www.getresponse.com/de/hilfe/ai-marketing-tools.html",
"ru": "https://www.getresponse.com/ru/help-ru/instrumenty-marketinga-na-baze-ii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaDescriptions = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/meta-descriptions.html",
"en": "https://www.getresponse.com/help/meta-descriptions.html",
"it": "https://www.getresponse.com/it/assistenza/meta-descrizioni.html",
"pl": "https://www.getresponse.pl/pomoc/opisy-meta.html",
"es": "https://www.getresponse.com/es/ayuda/meta-descripciones.html",
"de": "https://www.getresponse.com/de/hilfe/meta-beschreibung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPetitesAnnonces = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/petites-annonces.html",
"pl": "https://www.getresponse.pl/pomoc/ogloszenia-drobne.html",
"en": "https://www.getresponse.com/help/classified-advertising.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-classificata.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-clasificados.html",
"de": "https://www.getresponse.com/de/hilfe/kleinanzeigenwerbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDePermission = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-de-permission.html",
"en": "https://www.getresponse.com/help/permission-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/permission-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-permiso.html",
"pl": "https://www.getresponse.pl/pomoc/permission-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptimisationDuTauxDeConversion = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/optimisation-du-taux-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/optymalizacja-wspolczynnika-konwersji.html",
"en": "https://www.getresponse.com/help/conversion-rate-optimization.html",
"it": "https://www.getresponse.com/it/assistenza/ottimizzazione-del-tasso-di-conversione.html",
"es": "https://www.getresponse.com/es/ayuda/optimizacion-de-la-tasa-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate-optimierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRemarketingDeGoogle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/remarketing-de-google.html",
"en": "https://www.getresponse.com/help/google-remarketing.html",
"it": "https://www.getresponse.com/it/assistenza/remarketing-di-google.html",
"pl": "https://www.getresponse.pl/pomoc/remarketing-google.html",
"es": "https://www.getresponse.com/es/ayuda/google-remarketing.html",
"de": "https://www.getresponse.com/de/hilfe/google-remarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLienSortant = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/lien-sortant.html",
"en": "https://www.getresponse.com/help/outbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-uscita.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-saliente.html",
"pl": "https://www.getresponse.pl/pomoc/link-wychodzacy.html",
"de": "https://www.getresponse.com/de/hilfe/ausgehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestsAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/tests-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/testy-a-b.html",
"en": "https://www.getresponse.com/help/a-b-tests.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/a-b-tests.html",
"ru": "https://www.getresponse.com/ru/help-ru/a-b-testirovanie.html",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimiteDiFrequenzafrequencyCap = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/limite-di-frequenzafrequency-cap.html",
"fr": "https://www.getresponse.com/fr/aide/plafond-de-frequence.html",
"en": "https://www.getresponse.com/help/frequency-cap.html",
"pl": "https://www.getresponse.pl/pomoc/limit-czestotliwosci.html",
"es": "https://www.getresponse.com/es/ayuda/limite-de-frecuencia.html",
"br": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"de": "https://www.getresponse.com/de/hilfe/frequenzbegrenzung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDellaRicerca = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/retargeting-della-ricerca.html",
"fr": "https://www.getresponse.com/fr/aide/reciblage-de-la-recherche.html",
"en": "https://www.getresponse.com/help/search-retargeting.html",
"pl": "https://www.getresponse.pl/pomoc/retargetowanie-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/retargeting-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinen-retargeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStartUp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/start-up.html",
"en": "https://www.getresponse.com/help/start-ups.html",
"fr": "https://www.getresponse.com/fr/aide/start-ups.html",
"es": "https://www.getresponse.com/es/ayuda/start-ups.html",
"pl": "https://www.getresponse.pl/pomoc/start-upy.html",
"br": "https://www.getresponse.com/pt/ajuda/start-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/start-ups.html",
"de": "https://www.getresponse.com/de/hilfe/start-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBudgetPubblicitario = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/budget-pubblicitario.html",
"es": "https://www.getresponse.com/es/ayuda/presupuesto-publicitario.html",
"en": "https://www.getresponse.com/help/advertising-budget.html",
"fr": "https://www.getresponse.com/fr/aide/budget-publicitaire.html",
"de": "https://www.getresponse.com/de/hilfe/werbungsbudget.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-bjudzhet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEMailDiBenvenuto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/e-mail-di-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/courriels-de-bienvenue.html",
"en": "https://www.getresponse.com/help/welcome-emails.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-powitalne.html",
"de": "https://www.getresponse.com/de/hilfe/willkommensmail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVisitatoriSingoli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/visitatori-singoli.html",
"fr": "https://www.getresponse.com/fr/aide/visiteurs-uniques.html",
"en": "https://www.getresponse.com/help/unique-visitors.html",
"es": "https://www.getresponse.com/es/ayuda/visitantes-unicos.html",
"pl": "https://www.getresponse.pl/pomoc/unikalni-odwiedzajacy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStrumentiDiMarketingGratuiti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/strumenti-di-marketing-gratuiti.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-gratuits.html",
"en": "https://www.getresponse.com/help/free-marketing-tools.html",
"pl": "https://www.getresponse.pl/pomoc/bezplatne-narzedzia-marketingowe.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/kostenlose-marketing-tools.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEstensioniDellAnnuncio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/estensioni-dell-annuncio.html",
"pl": "https://www.getresponse.pl/pomoc/rozszerzenia-reklam.html",
"en": "https://www.getresponse.com/help/ad-extensions.html",
"es": "https://www.getresponse.com/es/ayuda/extensiones-de-anuncios.html",
"fr": "https://www.getresponse.com/fr/aide/extensions-publicitaires.html",
"ru": "https://www.getresponse.com/ru/help-ru/rasshireniya-obyavlenij.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModelloMarketingAida = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/modello-marketing-aida.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-modelu-aida.html",
"en": "https://www.getresponse.com/help/aida-model-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-marketing-aida.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-marketing-aida.html",
"de": "https://www.getresponse.com/de/hilfe/aida-modell-im-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaya-model-aida.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkInEntrata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/link-in-entrata.html",
"fr": "https://www.getresponse.com/fr/aide/lien-entrant.html",
"en": "https://www.getresponse.com/help/inbound-link.html",
"pl": "https://www.getresponse.pl/pomoc/link-przychodzacy.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-entrante.html",
"de": "https://www.getresponse.com/de/hilfe/eingehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingTramitePassaparola = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/marketing-tramite-passaparola.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-bouche-a-oreille.html",
"en": "https://www.getresponse.com/help/word-of-mouth-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-boca-a-boca.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-szeptany.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTrafficoDelSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/traffico-del-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/trafic-sur-le-site-web.html",
"en": "https://www.getresponse.com/help/website-traffic.html",
"es": "https://www.getresponse.com/es/ayuda/trafico-web.html",
"pl": "https://www.getresponse.pl/pomoc/ruch-na-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreferenzeSuiCookie = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/preferenze-sui-cookie.html",
"pl": "https://www.getresponse.pl/pomoc/preferencje-dotyczace-plikow-cookie.html",
"en": "https://www.getresponse.com/help/cookie-preferences.html",
"fr": "https://www.getresponse.com/fr/aide/cookie-preferences.html",
"es": "https://www.getresponse.com/es/ayuda/preferencias-de-cookies.html",
"br": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"pt": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"de": "https://www.getresponse.com/de/hilfe/cookie-einstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDigitalMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/digital-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-numerique.html",
"en": "https://www.getresponse.com/help/digital-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-cyfrowy.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-digital.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitales-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLienEntrant = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/lien-entrant.html",
"en": "https://www.getresponse.com/help/inbound-link.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-entrata.html",
"pl": "https://www.getresponse.pl/pomoc/link-przychodzacy.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-entrante.html",
"de": "https://www.getresponse.com/de/hilfe/eingehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModeleDeMarketingAida = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/modele-de-marketing-aida.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-modelu-aida.html",
"en": "https://www.getresponse.com/help/aida-model-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-marketing-aida.html",
"it": "https://www.getresponse.com/it/assistenza/modello-marketing-aida.html",
"de": "https://www.getresponse.com/de/hilfe/aida-modell-im-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaya-model-aida.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostExtensionsPublicitaires = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/extensions-publicitaires.html",
"pl": "https://www.getresponse.pl/pomoc/rozszerzenia-reklam.html",
"en": "https://www.getresponse.com/help/ad-extensions.html",
"es": "https://www.getresponse.com/es/ayuda/extensiones-de-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/estensioni-dell-annuncio.html",
"ru": "https://www.getresponse.com/ru/help-ru/rasshireniya-obyavlenij.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOutilsDeMarketingGratuits = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-gratuits.html",
"en": "https://www.getresponse.com/help/free-marketing-tools.html",
"it": "https://www.getresponse.com/it/assistenza/strumenti-di-marketing-gratuiti.html",
"pl": "https://www.getresponse.pl/pomoc/bezplatne-narzedzia-marketingowe.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/kostenlose-marketing-tools.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVisiteursUniques = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/visiteurs-uniques.html",
"en": "https://www.getresponse.com/help/unique-visitors.html",
"it": "https://www.getresponse.com/it/assistenza/visitatori-singoli.html",
"es": "https://www.getresponse.com/es/ayuda/visitantes-unicos.html",
"pl": "https://www.getresponse.pl/pomoc/unikalni-odwiedzajacy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCourrielsDeBienvenue = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/courriels-de-bienvenue.html",
"en": "https://www.getresponse.com/help/welcome-emails.html",
"it": "https://www.getresponse.com/it/assistenza/e-mail-di-benvenuto.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-powitalne.html",
"de": "https://www.getresponse.com/de/hilfe/willkommensmail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBudgetPublicitaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/budget-publicitaire.html",
"es": "https://www.getresponse.com/es/ayuda/presupuesto-publicitario.html",
"en": "https://www.getresponse.com/help/advertising-budget.html",
"it": "https://www.getresponse.com/it/assistenza/budget-pubblicitario.html",
"de": "https://www.getresponse.com/de/hilfe/werbungsbudget.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-bjudzhet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReciblageDeLaRecherche = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/reciblage-de-la-recherche.html",
"en": "https://www.getresponse.com/help/search-retargeting.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-della-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/retargetowanie-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/retargeting-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinen-retargeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlafondDeFrequence = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/plafond-de-frequence.html",
"en": "https://www.getresponse.com/help/frequency-cap.html",
"it": "https://www.getresponse.com/it/assistenza/limite-di-frequenzafrequency-cap.html",
"pl": "https://www.getresponse.pl/pomoc/limit-czestotliwosci.html",
"es": "https://www.getresponse.com/es/ayuda/limite-de-frecuencia.html",
"br": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"de": "https://www.getresponse.com/de/hilfe/frequenzbegrenzung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeoCiblage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/geo-ciblage.html",
"en": "https://www.getresponse.com/help/geo-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-geografico.html",
"pl": "https://www.getresponse.pl/pomoc/geotargetowanie.html",
"es": "https://www.getresponse.com/es/ayuda/geolocalizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/targeting-geografico.html",
"de": "https://www.getresponse.com/de/hilfe/geo-targeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVueDeLaPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/vue-de-la-page.html",
"en": "https://www.getresponse.com/help/page-view.html",
"it": "https://www.getresponse.com/it/assistenza/vista-della-paginapage-view.html",
"pl": "https://www.getresponse.pl/pomoc/odslony-strony.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-vista.html",
"br": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/seitenaufruf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingDeBoucheAOreille = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-de-bouche-a-oreille.html",
"en": "https://www.getresponse.com/help/word-of-mouth-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-tramite-passaparola.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-boca-a-boca.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-szeptany.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTraficSurLeSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/trafic-sur-le-site-web.html",
"en": "https://www.getresponse.com/help/website-traffic.html",
"it": "https://www.getresponse.com/it/assistenza/traffico-del-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/trafico-web.html",
"pl": "https://www.getresponse.pl/pomoc/ruch-na-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCookiePreferences = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/cookie-preferences.html",
"pl": "https://www.getresponse.pl/pomoc/preferencje-dotyczace-plikow-cookie.html",
"en": "https://www.getresponse.com/help/cookie-preferences.html",
"it": "https://www.getresponse.com/it/assistenza/preferenze-sui-cookie.html",
"es": "https://www.getresponse.com/es/ayuda/preferencias-de-cookies.html",
"br": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"pt": "https://www.getresponse.com/pt/ajuda/preferencias-de-cookies.html",
"de": "https://www.getresponse.com/de/hilfe/cookie-einstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreUnePageDeDestinationEtUnSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRendreUnSiteWebPlusReactifPourLesAppareilsMobiles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnCompteAReboursAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserUnCodeHtmlPersonnaliseSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesIconesDeMediasSociauxAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnePageDUneUrlExterneAuMenuDuSite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneGalerieDImagesAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesDetailsSeoAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnePageProtegeeParUnMotDePasseAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesParametresDeMesPages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterEtGererUnPopUpSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLeTraficSurVotreSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDomaine = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/domaine.html",
"en": "https://www.getresponse.com/help/domain.html",
"it": "https://www.getresponse.com/it/assistenza/dominio.html",
"pl": "https://www.getresponse.pl/pomoc/domena.html",
"es": "https://www.getresponse.com/es/ayuda/dominio.html",
"br": "https://www.getresponse.com/pt/ajuda/dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/dominio.html",
"de": "https://www.getresponse.com/de/hilfe/domain.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingNumerique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/marketing-numerique.html",
"en": "https://www.getresponse.com/help/digital-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/digital-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-cyfrowy.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-digital.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitales-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModeloDeMarketingAida = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/modelo-de-marketing-aida.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-modelu-aida.html",
"en": "https://www.getresponse.com/help/aida-model-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-marketing-aida.html",
"it": "https://www.getresponse.com/it/assistenza/modello-marketing-aida.html",
"de": "https://www.getresponse.com/de/hilfe/aida-modell-im-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaya-model-aida.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHerramientasDeMarketingDeIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-de-ia.html",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-marketingowe-ai.html",
"en": "https://www.getresponse.com/help/ai-marketing-tools.html",
"it": "https://www.getresponse.com/it/assistenza/ai-marketing-tools.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-ia.html",
"de": "https://www.getresponse.com/de/hilfe/ai-marketing-tools.html",
"ru": "https://www.getresponse.com/ru/help-ru/instrumenty-marketinga-na-baze-ii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRedDeAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/red-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/siec-afiliacyjna.html",
"en": "https://www.getresponse.com/help/affiliate-network.html",
"it": "https://www.getresponse.com/it/assistenza/rete-di-affiliazione.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-d-affiliation.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-netzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnjorskaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGestorDeAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/gestor-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/affiliate-manager.html",
"en": "https://www.getresponse.com/help/affiliate-manager.html",
"it": "https://www.getresponse.com/it/assistenza/affiliate-manager.html",
"fr": "https://www.getresponse.com/fr/aide/gestionnaire-d-affiliation.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/menedzher-po-rabote-s-partnjorami.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublirreportaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/publirreportaje.html",
"pl": "https://www.getresponse.pl/pomoc/artykul-sponsorowany.html",
"en": "https://www.getresponse.com/help/advertorial.html",
"fr": "https://www.getresponse.com/fr/aide/publireportage.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-statya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPresupuestoPublicitario = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/presupuesto-publicitario.html",
"en": "https://www.getresponse.com/help/advertising-budget.html",
"fr": "https://www.getresponse.com/fr/aide/budget-publicitaire.html",
"it": "https://www.getresponse.com/it/assistenza/budget-pubblicitario.html",
"de": "https://www.getresponse.com/de/hilfe/werbungsbudget.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-bjudzhet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRedPublicitaria = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/red-publicitaria.html",
"pl": "https://www.getresponse.pl/pomoc/siec-reklamowa.html",
"en": "https://www.getresponse.com/help/ad-network.html",
"it": "https://www.getresponse.com/it/assistenza/rete-pubblicitaria.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-publicitaire.html",
"de": "https://www.getresponse.com/de/hilfe/werbenetzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostExtensionesDeAnuncios = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/extensiones-de-anuncios.html",
"pl": "https://www.getresponse.pl/pomoc/rozszerzenia-reklam.html",
"en": "https://www.getresponse.com/help/ad-extensions.html",
"fr": "https://www.getresponse.com/fr/aide/extensions-publicitaires.html",
"it": "https://www.getresponse.com/it/assistenza/estensioni-dell-annuncio.html",
"ru": "https://www.getresponse.com/ru/help-ru/rasshireniya-obyavlenij.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajePublicitario = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-publicitario.html",
"pl": "https://www.getresponse.pl/pomoc/ad-copy.html",
"en": "https://www.getresponse.com/help/ad-copy.html",
"it": "https://www.getresponse.com/it/assistenza/il-testo-pubblicitario.html",
"fr": "https://www.getresponse.com/fr/aide/le-texte-publicitaire.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-tekst.html",
"de": "https://www.getresponse.com/de/hilfe/anzeigenkopie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloqueoDeAnuncios = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-anuncios.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-reklam.html",
"en": "https://www.getresponse.com/help/ad-blocking.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-degli-annunci.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-des-publicites.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/blockieren-von-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCarritoAbandonado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/abandoned-cart.html",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne.html",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaya-korzina.html",
"de": "https://www.getresponse.com/de/hilfe/verlassener-warenkorb.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPruebasAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/pruebas-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/testy-a-b.html",
"en": "https://www.getresponse.com/help/a-b-tests.html",
"it": "https://www.getresponse.com/it/assistenza/a-b-tests.html",
"fr": "https://www.getresponse.com/fr/aide/tests-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/a-b-testirovanie.html",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestProtokolNiestandardowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-protokol-niestandardowy.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-collegamento-di-protocollo-personalizzato.html",
"en": "https://www.getresponse.com/help/what-is-a-custom-protocol-link.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-enlace-de-protocolo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-polzovatelskaya-ssylka-protokola.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-lien-protocolaire-personnalise.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-benutzerdefinierte-protokollverbindung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreparingFirebaseDataForIntegrationWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/preparing-firebase-data-for-integration-with-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNarzedziaMarketingoweAi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/narzedzia-marketingowe-ai.html",
"en": "https://www.getresponse.com/help/ai-marketing-tools.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-de-ia.html",
"it": "https://www.getresponse.com/it/assistenza/ai-marketing-tools.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-ia.html",
"de": "https://www.getresponse.com/de/hilfe/ai-marketing-tools.html",
"ru": "https://www.getresponse.com/ru/help-ru/instrumenty-marketinga-na-baze-ii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorzuconyKoszyk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/abandoned-cart.html",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne.html",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaya-korzina.html",
"de": "https://www.getresponse.com/de/hilfe/verlassener-warenkorb.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRozszerzeniaReklam = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/rozszerzenia-reklam.html",
"en": "https://www.getresponse.com/help/ad-extensions.html",
"es": "https://www.getresponse.com/es/ayuda/extensiones-de-anuncios.html",
"fr": "https://www.getresponse.com/fr/aide/extensions-publicitaires.html",
"it": "https://www.getresponse.com/it/assistenza/estensioni-dell-annuncio.html",
"ru": "https://www.getresponse.com/ru/help-ru/rasshireniya-obyavlenij.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestyAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/testy-a-b.html",
"en": "https://www.getresponse.com/help/a-b-tests.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/a-b-tests.html",
"fr": "https://www.getresponse.com/fr/aide/tests-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/a-b-testirovanie.html",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateMobileNotifications = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-mobile-notifications.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMobileAppsTechnicalDocumentationForIos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/mobile-apps-technical-documentation-for-ios.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMobileAppsTechnicalDocumentationForAndroid = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/mobile-apps-technical-documentation-for-android.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnServeurDeNoms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnregistrerUnDomainePersonnaliseGratuit = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-domaine-personnalise-gratuit.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarejestrowac-wlasna-darmowa-domene.html",
"en": "https://www.getresponse.com/help/how-to-register-a-free-custom-domain.html",
"it": "https://www.getresponse.com/it/assistenza/come-registrare-un-dominio-personalizzato-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/como-registrar-un-dominio-personalizado-gratuito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaregistrirovat-besplatnyj-polzovatelskij-domen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneLimiteDeBandePassantePourUnSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLesClicsSurVotreSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLaConversionDesFormulairesSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsElementsPuisJeAjouterAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html",
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaFonctionnalitesAdherents = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLePiedDePageDeMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaFonctionDePositionnementAbsolu = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRenouvelerUnDomaineAcheteAvecGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentVerifierLeDomaineDUnSiteWebDansFacebookBusinessManager = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-le-domaine-d-un-site-web-dans-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/weryfikacja-domeny-strony-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-podtverdit-domen-sayta-v-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-el-dominio-de-un-sitio-web-en-facebook-business-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-domain-einer-website-im-facebook-business-manager-verifizieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPromouvoirDesProduitsSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-mon-site-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-promuovere-i-prodotti-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-produkte-auf-meiner-website-bewerben.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-sajte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreLeDomaineDenvoiEtLadresseFrom = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-domaine-denvoi-et-ladresse-from.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-dominio-di-invio-e-l-indirizzo-da.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-the-sending-domain-and-from-address.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-domena-wysylkowa-a-adresem-nadawcy-od.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-dominio-remitente-y-la-direccion-de.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-domenom-dlya-otpravki-i-adresom-otpravitelya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-der-sendedomane-und-der-von-adresse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaMiedzyDomenaWysylkowaAAdresemNadawcyOd = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-domena-wysylkowa-a-adresem-nadawcy-od.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-dominio-di-invio-e-l-indirizzo-da.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-the-sending-domain-and-from-address.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-dominio-remitente-y-la-direccion-de.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-domaine-denvoi-et-ladresse-from.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-domenom-dlya-otpravki-i-adresom-otpravitelya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-der-sendedomane-und-der-von-adresse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreElDominioRemitenteYLaDireccionDe = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-dominio-remitente-y-la-direccion-de.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-dominio-di-invio-e-l-indirizzo-da.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-the-sending-domain-and-from-address.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-domena-wysylkowa-a-adresem-nadawcy-od.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-domaine-denvoi-et-ladresse-from.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-domenom-dlya-otpravki-i-adresom-otpravitelya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-der-sendedomane-und-der-von-adresse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraIlDominioDiInvioELIndirizzoDa = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-dominio-di-invio-e-l-indirizzo-da.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-the-sending-domain-and-from-address.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-domena-wysylkowa-a-adresem-nadawcy-od.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-dominio-remitente-y-la-direccion-de.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-domaine-denvoi-et-ladresse-from.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-domenom-dlya-otpravki-i-adresom-otpravitelya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-der-sendedomane-und-der-von-adresse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeIntegrareTeachableConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-integrare-teachable-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-teachable-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-teachable-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-teachable-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-teachable-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-teachable-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-teachable-mit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacTeachableZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-teachable-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-teachable-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-teachable-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-teachable-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-teachable-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-teachable-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-teachable-mit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalizirovannoePismoPoslePokupki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html",
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenTheSendingDomainAndFromAddress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-the-sending-domain-and-from-address.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-il-dominio-di-invio-e-l-indirizzo-da.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-domena-wysylkowa-a-adresem-nadawcy-od.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-el-dominio-remitente-y-la-direccion-de.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-le-domaine-denvoi-et-ladresse-from.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-domenom-dlya-otpravki-i-adresom-otpravitelya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-der-sendedomane-und-der-von-adresse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLasEstadisticasDeGetresponseChats = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneImageDArrierePlanALaSalleDAttenteEtLaSalleDeWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesWebinairesDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentLesWebinairesPeuventIlsMAiderADevelopperMaListeDAdressesEMail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeEnvoyerDesInvitationsADesWebinairesADesPersonnesQuiNeFigurentPasSurMaListeDAbonnes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJePermettreAuxParticipantsDeSInscrireAUnWebinaireDirectementDepuisMaPageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentExporterLHistoriqueDesChatsDuWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConsulterLesStatistiquesRelativesAuxInvitationsEtAuxRappelsDeWebinairesQueJAiEnvoyes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuTrouverLesStatistiquesDeMesWebinaires = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesFonctionnalitesDeLaSalleDeWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesExigencesTechniquesEtLesEtapesDeDepannagePourLesWebinaires = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDiffuserMesWebinairesSurFacebookEtYoutube = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSauvegarderLaDispositionDeMaSalleDeWebinaireEtMonTableauBlanc = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJePermettreAMesParticipantsDeCommuniquerAvecMoiPendantLeWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-permettre-a-mes-participants-de-communiquer-avec-moi-pendant-le-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pozwolic-uczestnikom-webinaru-komunikowac-sie-ze-mna.html",
"en": "https://www.getresponse.com/help/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-teilnehmer-waehrend-des-webinars-mit-mir-kommunizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-permitir-a-mis-participantes-comunicarse-conmigo-durante-el-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mozhno-vzaimodejstvovat-so-zritelami-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsTypesDeFichiersPuisJeTelechargerVersUnWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPeutIlYAvoirDAutresPresentateursQueMoi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesWebinairesALaDemandeEtCommentLesUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnregistrerMonWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentLesParticipantsPeuventIlsLaisserLeursCommentairesSurLesWebinairesALaDemande = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeTransmettreLesDonneesDesAbonnesAUnePageDeRemerciementPersonnaliseeApresLeWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentResoudreLesProblemesLesPlusCourantsLiesAuxWebinaires = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeGenerateurIaDeTitresDeWebinairesEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeSuiviDesClics = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeSuiviDuTauxDOuverture = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeVoirLesStatistiquesDUneListeOuDUnMessageSpecifique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLeTauxDOuvertureDesNewslettersEnTexteBrut = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMesStatistiquesSurLeTauxDOuvertureNeSontPasDisponiblesQuelleEnEstLaCause = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html",
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeTauxDOuvertureDeMonMessageEstDe0CommentCelaSeFaitIl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneCarteDesClicsEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesRapportsPersonnalises = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerLesNotificationsPushWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterMaPageDeDestinationAWordpressDansLAncienPluginWordpress = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSuivreLesVisitesSurMesSitesWordpressDansLAncienPluginWordpress = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesClientsWoocommerceAGetresponseDansLAncienPluginWordpress = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterLesVisiteursDUnSiteWordpressAUneListeGetresponseDansLAncienPluginWordpress = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerGetresponseEtWordpressPourDevelopperMaListeDeContactsSuivreLesVisitesDuSiteEtEnvoyerDesDonneesECommerceAvecLAncienPluginWordpress = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesContactsAUneListeApresQuIlsAientEffectueUneTransactionPaypal = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html",
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeAjouterDeNouveauxContactsADifferentesListesAvecLesBoutonsPaypalAcheterMaintenant = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-de-nouveaux-contacts-a-differentes-listes-avec-les-boutons-paypal-acheter-maintenant.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-kontakty-do-roznych-list-przy-uzyciu-przyciskow-paypal-kup-teraz.html",
"en": "https://www.getresponse.com/help/can-i-add-new-contacts-to-different-lists-with-paypal-buy-now-buttons.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-paypal-jetzt-kaufen-buttons-neue-kontakte-in-verschiedene-listen-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-nuevos-contactos-a-listas-diferentes-con-los-botones-comprar-ahora-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavliyat-novye-kontakty-v-raznye-spiski-s-pomoshiyu-knopki-paypal-kypit-sejchas.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerPaypalAGetresponseEtAjouterDesBoutonsAuxMessagesEtAuxPagesDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserZapierAvecMonCompteGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesClientsEtDesProspectsDeZohocrmAMaListeDeContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeRecevoirDesNotificationsSurSlack = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMaintenirLaSynchronisationDesDonneesDeContactDeSalesforceEtDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeAjouterDesClientsEtDesProspectsSalesforceAMaListeDeContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerGetresponseAGoogleAnalytics = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConnecterMesComptesFacebookEtGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesClientsDeEventbriteAMaListeDeContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMaximiserLaPrecisionDeLaValidationTelephoniqueDeLApi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html",
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeIntegrerMonApplicationAGetresponseALAideDeLApi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJeSuisDeveloppeurOuPuisJeTrouverDesInformationsSurLApi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html",
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesProduitsEtsyAMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererMesZapsDansGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerGetresponseAClickfunnels = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerGetresponseAAmazon = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnBoutonPaypalAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnWebhook = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueWebConnectEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerGetresponseAClickbank = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConnecterMesComptesXTwitterEtGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotaetPodderzhkaVChateDlyaAkkauntovMaxMax2 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarSpfAoDominioDeRemetenteEmContasMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-spf-al-dominio-remitente-de-en-las-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-add-spf-to-the-from-domain-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-spf-do-domeny-nadawcy-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-spf-al-dominio-del-mittente-negli-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-spf-au-domaine-from-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-spf-k-domenu-adresa-otpravitelya-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarTeachableConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integrar-teachable-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-teachable-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-teachable-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-teachable-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-teachable-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-teachable-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-teachable-mit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerTeachableAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-teachable-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-teachable-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-teachable-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-teachable-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-teachable-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-teachable-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-teachable-mit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLeSupportParChatPourLesComptesMaxMax2 = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaIlSupportoViaChatPerGliAccountMaxMax2 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateTeachableWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-teachable-with-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-teachable-a-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-teachable-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-teachable-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-teachable-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-teachable-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-teachable-mit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaAsistenciaPorChatParaLasCuentasMaxMax2 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProdvinutoeRazdelenieKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProstoeRazdelenieKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesChatSupportWorkForMaxMax2Accounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-chat-support-work-for-max-max2-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-asistencia-por-chat-para-las-cuentas-max-max2.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-supporto-via-chat-per-gli-account-max-max2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-support-par-chat-pour-les-comptes-max-max2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-podderzhka-v-chate-dlya-akkauntov-max-max2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-pomoc-na-czacie-dla-kont-max-max2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-suporte-por-chat-para-contas-max-max2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-chat-support-fuer-max-max2-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPromouvoirDesProduitsSurMaLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-ma-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-moim-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-prodotti-sulla-mia-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-lendinge.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesParametresDuSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakProdvigatTovaryNaMoemLendinge = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-lendinge.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-ma-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-moim-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-prodotti-sulla-mia-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitPolzovatelskijShablonPismaPodtverzhdeniyaVMaxAkkauntah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelskij-shablon-pisma-podtverzhdeniya-v-max-akkauntah.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-template-di-messaggio-di-conferma-personalizzato-negli-account-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-custom-confirmation-message-template-in-max-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-plantilla-de-mensaje-de-confirmacion-personalizada-en-las-cuentas-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wlasny-szablon-wiadomosci-potwierdzajacej-w-kontach-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-modele-de-message-de-confirmation-personnalise-dans-les-comptes-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-benutzerdefinierte-bestaetigungsnachrichtenvorlage-in-max-konten-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebsiteTraffic = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-traffic.html",
"fr": "https://www.getresponse.com/fr/aide/trafic-sur-le-site-web.html",
"it": "https://www.getresponse.com/it/assistenza/traffico-del-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/trafico-web.html",
"pl": "https://www.getresponse.pl/pomoc/ruch-na-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/trafego-do-sitio-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWordOfMouthMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/word-of-mouth-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-de-bouche-a-oreille.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-tramite-passaparola.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-boca-a-boca.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-szeptany.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-boca-a-boca.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPageView = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/page-view.html",
"fr": "https://www.getresponse.com/fr/aide/vue-de-la-page.html",
"it": "https://www.getresponse.com/it/assistenza/vista-della-paginapage-view.html",
"pl": "https://www.getresponse.pl/pomoc/odslony-strony.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-vista.html",
"br": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/visualizacoes-de-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/seitenaufruf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFrequencyCap = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/frequency-cap.html",
"fr": "https://www.getresponse.com/fr/aide/plafond-de-frequence.html",
"it": "https://www.getresponse.com/it/assistenza/limite-di-frequenzafrequency-cap.html",
"pl": "https://www.getresponse.pl/pomoc/limit-czestotliwosci.html",
"es": "https://www.getresponse.com/es/ayuda/limite-de-frecuencia.html",
"br": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/limite-de-frequencia.html",
"de": "https://www.getresponse.com/de/hilfe/frequenzbegrenzung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSearchRetargeting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-retargeting.html",
"fr": "https://www.getresponse.com/fr/aide/reciblage-de-la-recherche.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-della-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/retargetowanie-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/retargeting-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinen-retargeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdvertisingBudget = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/advertising-budget.html",
"es": "https://www.getresponse.com/es/ayuda/presupuesto-publicitario.html",
"fr": "https://www.getresponse.com/fr/aide/budget-publicitaire.html",
"it": "https://www.getresponse.com/it/assistenza/budget-pubblicitario.html",
"de": "https://www.getresponse.com/de/hilfe/werbungsbudget.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnyj-bjudzhet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcomeEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome-emails.html",
"fr": "https://www.getresponse.com/fr/aide/courriels-de-bienvenue.html",
"it": "https://www.getresponse.com/it/assistenza/e-mail-di-benvenuto.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-powitalne.html",
"de": "https://www.getresponse.com/de/hilfe/willkommensmail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUniqueVisitors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/unique-visitors.html",
"fr": "https://www.getresponse.com/fr/aide/visiteurs-uniques.html",
"it": "https://www.getresponse.com/it/assistenza/visitatori-singoli.html",
"es": "https://www.getresponse.com/es/ayuda/visitantes-unicos.html",
"pl": "https://www.getresponse.pl/pomoc/unikalni-odwiedzajacy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFreeMarketingTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/free-marketing-tools.html",
"fr": "https://www.getresponse.com/fr/aide/outils-de-marketing-gratuits.html",
"it": "https://www.getresponse.com/it/assistenza/strumenti-di-marketing-gratuiti.html",
"pl": "https://www.getresponse.pl/pomoc/bezplatne-narzedzia-marketingowe.html",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-marketing-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/kostenlose-marketing-tools.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdExtensions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ad-extensions.html",
"pl": "https://www.getresponse.pl/pomoc/rozszerzenia-reklam.html",
"es": "https://www.getresponse.com/es/ayuda/extensiones-de-anuncios.html",
"fr": "https://www.getresponse.com/fr/aide/extensions-publicitaires.html",
"it": "https://www.getresponse.com/it/assistenza/estensioni-dell-annuncio.html",
"ru": "https://www.getresponse.com/ru/help-ru/rasshireniya-obyavlenij.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAidaModelMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/aida-model-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-modelu-aida.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-marketing-aida.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-marketing-aida.html",
"it": "https://www.getresponse.com/it/assistenza/modello-marketing-aida.html",
"de": "https://www.getresponse.com/de/hilfe/aida-modell-im-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaya-model-aida.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInboundLink = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/inbound-link.html",
"fr": "https://www.getresponse.com/fr/aide/lien-entrant.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-entrata.html",
"pl": "https://www.getresponse.pl/pomoc/link-przychodzacy.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-entrante.html",
"de": "https://www.getresponse.com/de/hilfe/eingehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOutboundLink = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/outbound-link.html",
"fr": "https://www.getresponse.com/fr/aide/lien-sortant.html",
"it": "https://www.getresponse.com/it/assistenza/link-in-uscita.html",
"es": "https://www.getresponse.com/es/ayuda/enlace-saliente.html",
"pl": "https://www.getresponse.pl/pomoc/link-wychodzacy.html",
"de": "https://www.getresponse.com/de/hilfe/ausgehender-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversionRateOptimization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conversion-rate-optimization.html",
"pl": "https://www.getresponse.pl/pomoc/optymalizacja-wspolczynnika-konwersji.html",
"fr": "https://www.getresponse.com/fr/aide/optimisation-du-taux-de-conversion.html",
"it": "https://www.getresponse.com/it/assistenza/ottimizzazione-del-tasso-di-conversione.html",
"es": "https://www.getresponse.com/es/ayuda/optimizacion-de-la-tasa-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/conversion-rate-optimierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClassifiedAdvertising = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/classified-advertising.html",
"pl": "https://www.getresponse.pl/pomoc/ogloszenia-drobne.html",
"fr": "https://www.getresponse.com/fr/aide/petites-annonces.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-classificata.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-clasificados.html",
"de": "https://www.getresponse.com/de/hilfe/kleinanzeigenwerbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKeywordMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/keyword-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-delle-parole-chiave.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdBlocking = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ad-blocking.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-reklam.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-degli-annunci.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-des-publicites.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/blockieren-von-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAbandonedCart = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart.html",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk.html",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne.html",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaya-korzina.html",
"de": "https://www.getresponse.com/de/hilfe/verlassener-warenkorb.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContextualAdvertising = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/contextual-advertising.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-kontekstowa.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-contestuale.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-contextuelle.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-contextual.html",
"de": "https://www.getresponse.com/de/hilfe/kontextbezogene-werbung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinkText = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/link-text.html",
"it": "https://www.getresponse.com/it/assistenza/testo-del-link.html",
"fr": "https://www.getresponse.com/fr/aide/texte-du-lien.html",
"pl": "https://www.getresponse.pl/pomoc/tekst-linku.html",
"es": "https://www.getresponse.com/es/ayuda/texto-de-enlace.html",
"de": "https://www.getresponse.com/de/hilfe/linktext.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKeywordDensity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/keyword-density.html",
"it": "https://www.getresponse.com/it/assistenza/densita-delle-parole-chiave.html",
"fr": "https://www.getresponse.com/fr/aide/densite-des-mots-cles.html",
"pl": "https://www.getresponse.pl/pomoc/gestosc-slow-kluczowych.html",
"es": "https://www.getresponse.com/es/ayuda/densidad-de-palabras-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort-dichte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalizedMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/personalized-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-personalizzato.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-personnalise.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-spersonalizowany.html",
"de": "https://www.getresponse.com/de/hilfe/personalisierte-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMetaSearchEngine = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/meta-search-engine.html",
"it": "https://www.getresponse.com/it/assistenza/metamotore-di-ricerca.html",
"fr": "https://www.getresponse.com/fr/aide/metamoteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/metawyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/metabuscador.html",
"de": "https://www.getresponse.com/de/hilfe/meta-suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDedicatedHosting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/dedicated-hosting.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-dedykowany.html",
"it": "https://www.getresponse.com/it/assistenza/hosting-dedicato.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-dedie.html",
"es": "https://www.getresponse.com/es/ayuda/hosting-dedicado.html",
"de": "https://www.getresponse.com/de/hilfe/dediziertes-hosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRateCard = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/rate-card.html",
"it": "https://www.getresponse.com/it/assistenza/tariffario.html",
"fr": "https://www.getresponse.com/fr/aide/grille-tarifaire.html",
"es": "https://www.getresponse.com/es/ayuda/tarjeta-de-tarifas.html",
"pl": "https://www.getresponse.pl/pomoc/cennik.html",
"de": "https://www.getresponse.com/de/hilfe/preiskatalog.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdNetwork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ad-network.html",
"pl": "https://www.getresponse.pl/pomoc/siec-reklamowa.html",
"es": "https://www.getresponse.com/es/ayuda/red-publicitaria.html",
"it": "https://www.getresponse.com/it/assistenza/rete-pubblicitaria.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-publicitaire.html",
"de": "https://www.getresponse.com/de/hilfe/werbenetzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoToMarketStrategy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/go-to-market-strategy.html",
"it": "https://www.getresponse.com/it/assistenza/strategia-go-to-market.html",
"fr": "https://www.getresponse.com/fr/aide/strategie-de-mise-sur-le-marche.html",
"pl": "https://www.getresponse.pl/pomoc/strategia-wejscia-na-rynek.html",
"es": "https://www.getresponse.com/es/ayuda/estrategia-gtm.html",
"de": "https://www.getresponse.com/de/hilfe/go-to-market-strategie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMultivariateTesting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/multivariate-testing.html",
"it": "https://www.getresponse.com/it/assistenza/test-multivariato.html",
"fr": "https://www.getresponse.com/fr/aide/test-multivarie.html",
"pl": "https://www.getresponse.pl/pomoc/testy-wielowymiarowe.html",
"es": "https://www.getresponse.com/es/ayuda/test-multivariante.html",
"de": "https://www.getresponse.com/de/hilfe/multivariate-tests.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDigitalContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/digital-content.html",
"pl": "https://www.getresponse.pl/pomoc/tresci-cyfrowe.html",
"it": "https://www.getresponse.com/it/assistenza/contenuto-digitale.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-numerique.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-digital.html",
"de": "https://www.getresponse.com/de/hilfe/digitale-inhalte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInternationalSeo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/international-seo.html",
"it": "https://www.getresponse.com/it/assistenza/seo-internazionale.html",
"fr": "https://www.getresponse.com/fr/aide/seo-international.html",
"pl": "https://www.getresponse.pl/pomoc/miedzynarodowe-seo.html",
"es": "https://www.getresponse.com/es/ayuda/seo-internacional.html",
"de": "https://www.getresponse.com/de/hilfe/internationales-seo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGoogleAlgorithmUpdates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/google-algorithm-updates.html",
"it": "https://www.getresponse.com/it/assistenza/aggiornamenti-dell-algoritmo-di-google.html",
"fr": "https://www.getresponse.com/fr/aide/mises-a-jour-de-l-algorithme-de-google.html",
"pl": "https://www.getresponse.pl/pomoc/aktualizacje-algorytmu-google.html",
"es": "https://www.getresponse.com/es/ayuda/actualizaciones-del-algoritmo-de-google.html",
"de": "https://www.getresponse.com/de/hilfe/google-algorithmus-aktualisierungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOrganicSearch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/organic-search.html",
"it": "https://www.getresponse.com/it/assistenza/ricerca-organica.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-organique.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-organica.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-organiczne.html",
"de": "https://www.getresponse.com/de/hilfe/organische-suche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEngagementRate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-rate.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-coinvolgimento.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-engagement.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zaangazowania.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-compromiso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAudienceSegmentation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/audience-segmentation.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-odbiorcow.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-del-pubblico.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-l-audience.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-audiencia.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppensegmentierung.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-auditorii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateNetwork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-network.html",
"pl": "https://www.getresponse.pl/pomoc/siec-afiliacyjna.html",
"es": "https://www.getresponse.com/es/ayuda/red-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/rete-di-affiliazione.html",
"fr": "https://www.getresponse.com/fr/aide/reseau-d-affiliation.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-netzwerk.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnjorskaya-set.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailSpam = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/email-spam.html",
"it": "https://www.getresponse.com/it/assistenza/soam-via-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/spam-par-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/spam-2.html",
"es": "https://www.getresponse.com/es/ayuda/correo-no-deseado.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIspolzovanieDomenovISubdomenovSNovymiLendingami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProductDifferentiation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-differentiation.html",
"it": "https://www.getresponse.com/it/assistenza/differenziazione-del-prodotto.html",
"fr": "https://www.getresponse.com/fr/aide/differenciation-des-produits.html",
"es": "https://www.getresponse.com/es/ayuda/diferenciacion-de-productos.html",
"pl": "https://www.getresponse.pl/pomoc/roznicowanie-produktow.html",
"de": "https://www.getresponse.com/de/hilfe/produktdifferenzierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailTemplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/email-template.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-courriel.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-wiadomosci-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/plantillas-de-mensaje.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-vorlage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSearchEngineMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-engine-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-sui-motori-di-ricerca.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-moteur-de-recherche.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-w-wyszukiwarkach.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-en-buscadores.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschinenmarketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostYoutubeAdvertising = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/youtube-advertising.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-su-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-sur-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-en-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-na-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/marketing-plan.html",
"fr": "https://www.getresponse.com/fr/aide/plan-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/piano-di-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/plan-marketingowy.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/marketingplan.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInstagramAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/instagram-ads.html",
"fr": "https://www.getresponse.com/fr/aide/publicites-instagram.html",
"pl": "https://www.getresponse.pl/pomoc/reklamy-na-instagramie.html",
"es": "https://www.getresponse.com/es/ayuda/anuncios-de-instagram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebHosting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-hosting.html",
"fr": "https://www.getresponse.com/fr/aide/hebergement-web.html",
"es": "https://www.getresponse.com/es/ayuda/alojamiento-web.html",
"pl": "https://www.getresponse.pl/pomoc/hosting-internetowy.html",
"de": "https://www.getresponse.com/de/hilfe/webhosting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnimatedGif = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/animated-gif.html",
"pl": "https://www.getresponse.pl/pomoc/animowany-gif.html",
"es": "https://www.getresponse.com/es/ayuda/gif-animado.html",
"fr": "https://www.getresponse.com/fr/aide/gif-anime.html",
"it": "https://www.getresponse.com/it/assistenza/gif-animata.html",
"de": "https://www.getresponse.com/de/hilfe/animiertes-gif.html",
"ru": "https://www.getresponse.com/ru/help-ru/animirovannyj-gif.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSearchEngine = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-engine.html",
"fr": "https://www.getresponse.com/fr/aide/moteur-de-recherche.html",
"it": "https://www.getresponse.com/it/assistenza/motore-di-ricerca.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwarka.html",
"es": "https://www.getresponse.com/es/ayuda/motor-de-busqueda.html",
"de": "https://www.getresponse.com/de/hilfe/suchmaschine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPricing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing.html",
"fr": "https://www.getresponse.com/fr/aide/tarification.html",
"it": "https://www.getresponse.com/it/assistenza/prezzi.html",
"es": "https://www.getresponse.com/es/ayuda/fijacion-de-precios.html",
"pl": "https://www.getresponse.pl/pomoc/wycena.html",
"de": "https://www.getresponse.com/de/hilfe/preisgestaltung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSponsorship = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sponsorship.html",
"fr": "https://www.getresponse.com/fr/aide/parrainage.html",
"it": "https://www.getresponse.com/it/assistenza/sponsorizzazione.html",
"es": "https://www.getresponse.com/es/ayuda/patrocinio.html",
"pl": "https://www.getresponse.pl/pomoc/sponsoring.html",
"de": "https://www.getresponse.com/de/hilfe/sponsoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAudience = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/audience.html",
"pl": "https://www.getresponse.pl/pomoc/grupa-docelowa.html",
"es": "https://www.getresponse.com/es/ayuda/audiencia.html",
"fr": "https://www.getresponse.com/fr/aide/public.html",
"it": "https://www.getresponse.com/it/assistenza/pubblico.html",
"de": "https://www.getresponse.com/de/hilfe/zielgruppe.html",
"ru": "https://www.getresponse.com/ru/help-ru/auditoriya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKeyword = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/keyword.html",
"fr": "https://www.getresponse.com/fr/aide/mot-cle.html",
"it": "https://www.getresponse.com/it/assistenza/parola-chiave.html",
"pl": "https://www.getresponse.pl/pomoc/slowo-kluczowe.html",
"es": "https://www.getresponse.com/es/ayuda/palabra-clave.html",
"de": "https://www.getresponse.com/de/hilfe/schluesselwort.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSocialMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/social-media.html",
"fr": "https://www.getresponse.com/fr/aide/medias-sociaux.html",
"pl": "https://www.getresponse.pl/pomoc/media-spolecznosciowe.html",
"es": "https://www.getresponse.com/es/ayuda/redes-sociales.html",
"de": "https://www.getresponse.com/de/hilfe/soziale-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUserResearch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/user-research.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-les-utilisateurs.html",
"es": "https://www.getresponse.com/es/ayuda/investigacion-de-usuarios.html",
"pl": "https://www.getresponse.pl/pomoc/badania-uzytkownikow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCustomerDataPlatform = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/customer-data-platform.html",
"pl": "https://www.getresponse.pl/pomoc/platforma-danych-klientow.html",
"fr": "https://www.getresponse.com/fr/aide/plate-forme-de-donnees-clients.html",
"es": "https://www.getresponse.com/es/ayuda/plataforma-de-datos-de-clientes.html",
"de": "https://www.getresponse.com/de/hilfe/kundendatenplattform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTransactionalEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/transactional-email.html",
"fr": "https://www.getresponse.com/fr/aide/courrier-electronique-transactionnel.html",
"es": "https://www.getresponse.com/es/ayuda/correo-electronico-transaccional.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosci-transakcyjne.html",
"de": "https://www.getresponse.com/de/hilfe/transaktionale-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPaidMedia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/paid-media.html",
"fr": "https://www.getresponse.com/fr/aide/medias-payants.html",
"pl": "https://www.getresponse.pl/pomoc/platne-media.html",
"es": "https://www.getresponse.com/es/ayuda/medios-de-pago.html",
"de": "https://www.getresponse.com/de/hilfe/bezahlte-medien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHtmlEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/html-email.html",
"pl": "https://www.getresponse.pl/pomoc/e-mail-html.html",
"fr": "https://www.getresponse.com/fr/aide/courriel-html.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html-2.html",
"de": "https://www.getresponse.com/de/hilfe/html-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMediaPlanning = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/media-planning.html",
"fr": "https://www.getresponse.com/fr/aide/planification-des-medias.html",
"pl": "https://www.getresponse.pl/pomoc/planowanie-mediow.html",
"es": "https://www.getresponse.com/es/ayuda/planificacion-de-medios.html",
"de": "https://www.getresponse.com/de/hilfe/medienplanung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSiteSearch = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/site-search.html",
"fr": "https://www.getresponse.com/fr/aide/recherche-sur-le-site.html",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-w-witrynie.html",
"es": "https://www.getresponse.com/es/ayuda/busqueda-en-sitios-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNetworkEffect = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/network-effect.html",
"fr": "https://www.getresponse.com/fr/aide/effet-de-reseau.html",
"pl": "https://www.getresponse.pl/pomoc/efekt-sieci.html",
"es": "https://www.getresponse.com/es/ayuda/efecto-red.html",
"de": "https://www.getresponse.com/de/hilfe/netzwerkeffekt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDirectMailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/direct-mail-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/bezposredni-marketing-pocztowy.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-direct.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-por-correo-directo.html",
"de": "https://www.getresponse.com/de/hilfe/direktmailing-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUniqueSellingProposition = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/unique-selling-proposition.html",
"fr": "https://www.getresponse.com/fr/aide/proposition-de-vente-unique.html",
"es": "https://www.getresponse.com/es/ayuda/propuesta-unica-de-venta.html",
"pl": "https://www.getresponse.pl/pomoc/unikalna-propozycja-sprzedazy.html",
"de": "https://www.getresponse.com/de/hilfe/alleinstellungsmerkmal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/email-automation.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja-e-mailowa.html",
"it": "https://www.getresponse.com/it/assistenza/automazione-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation-des-courriels.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion-del-correo-electronico.html",
"de": "https://www.getresponse.com/de/hilfe/e-mail-automatisierung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIntegratedMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrated-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-integre.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-zintegrowany.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-integrado.html",
"de": "https://www.getresponse.com/de/hilfe/integriertes-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdvertorial = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/advertorial.html",
"pl": "https://www.getresponse.pl/pomoc/artykul-sponsorowany.html",
"es": "https://www.getresponse.com/es/ayuda/publirreportaje.html",
"fr": "https://www.getresponse.com/fr/aide/publireportage.html",
"ru": "https://www.getresponse.com/ru/help-ru/reklamnaya-statya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDripCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drip-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-typu-drip.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-goteo.html",
"fr": "https://www.getresponse.com/fr/aide/drip-campagne.html",
"de": "https://www.getresponse.com/de/hilfe/drip-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/analyse-du-web.html",
"es": "https://www.getresponse.com/es/ayuda/analitica-web.html",
"pl": "https://www.getresponse.pl/pomoc/analiza-internetowa.html",
"de": "https://www.getresponse.com/de/hilfe/webanalyse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeoConsultancy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/seo-consultancy.html",
"fr": "https://www.getresponse.com/fr/aide/conseil-en-referencement.html",
"pl": "https://www.getresponse.pl/pomoc/doradztwo-seo.html",
"es": "https://www.getresponse.com/es/ayuda/consultoria-seo.html",
"de": "https://www.getresponse.com/de/hilfe/seo-beratung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEmailDeConfirmationDeCommandePourWoocommerce = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-confirmation-de-commande-pour-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-di-conferma-dell-ordine-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-order-confirmation-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-confirmacion-de-pedido-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacWlasnySzablonWiadomosciPotwierdzajacejWKontachMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wlasny-szablon-wiadomosci-potwierdzajacej-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-template-di-messaggio-di-conferma-personalizzato-negli-account-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-custom-confirmation-message-template-in-max-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-plantilla-de-mensaje-de-confirmacion-personalizada-en-las-cuentas-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelskij-shablon-pisma-podtverzhdeniya-v-max-akkauntah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-modele-de-message-de-confirmation-personnalise-dans-les-comptes-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-benutzerdefinierte-bestaetigungsnachrichtenvorlage-in-max-konten-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnLienProtocolairePersonnalise = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-lien-protocolaire-personnalise.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-collegamento-di-protocollo-personalizzato.html",
"en": "https://www.getresponse.com/help/what-is-a-custom-protocol-link.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-enlace-de-protocolo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-polzovatelskaya-ssylka-protokola.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-protokol-niestandardowy.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-benutzerdefinierte-protokollverbindung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesParametresDInscription = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguSozdatPismoPodtverzhdeniyaZakazaDlyaWoocommerce = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-di-conferma-dell-ordine-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-order-confirmation-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-confirmacion-de-pedido-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-confirmation-de-commande-pour-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatPismoBroshennojKorzinyDlyaWoocommerce = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoj-korziny-dlya-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-per-il-carrello-abbandonato-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-abandoned-cart-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-carrito-abandonado-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-panier-abandonne-pour-woocommerce.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-e-mail-fuer-abgebrochene-warenkoerbe-in-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoePolzovatelskayaSsylkaProtokola = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-polzovatelskaya-ssylka-protokola.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-collegamento-di-protocollo-personalizzato.html",
"en": "https://www.getresponse.com/help/what-is-a-custom-protocol-link.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-enlace-de-protocolo-personalizado.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-lien-protocolaire-personnalise.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-protokol-niestandardowy.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-benutzerdefinierte-protokollverbindung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWiadomoscZPorzuconymKoszykiemDlaWoocommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-per-il-carrello-abbandonato-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-abandoned-cart-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-carrito-abandonado-para-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoj-korziny-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-panier-abandonne-pour-woocommerce.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-e-mail-fuer-abgebrochene-warenkoerbe-in-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWiadomoscZPotwierdzeniemZamowieniaWoocommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-di-conferma-dell-ordine-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-order-confirmation-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-confirmacion-de-pedido-para-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-confirmation-de-commande-pour-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmaLigacaoDeUrlCustomizada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-ligacao-de-url-customizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-ligacao-de-url-customizada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarRegistosDkimEDmarcEmNamecheap = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-namecheap.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-namecheap.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-namecheap.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-namecheap.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-namecheap.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-namecheap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarRegistosDkimEDmarcNaBluehost = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-bluehost.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-bluehost.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-bluehost.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-bluehost.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-bluehost.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-bluehost.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacSpfDoDomenyNadawcyWKontachMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-spf-do-domeny-nadawcy-w-kontach-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-spf-al-dominio-remitente-de-en-las-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-add-spf-to-the-from-domain-in-max-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-spf-al-dominio-del-mittente-negli-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-spf-au-domaine-from-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-spf-k-domenu-adresa-otpravitelya-v-uchetnyh-zapisyah-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnaPlantillaDeMensajeDeConfirmacionPersonalizadaEnLasCuentasMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-plantilla-de-mensaje-de-confirmacion-personalizada-en-las-cuentas-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-template-di-messaggio-di-conferma-personalizzato-negli-account-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-custom-confirmation-message-template-in-max-accounts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelskij-shablon-pisma-podtverzhdeniya-v-max-akkauntah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wlasny-szablon-wiadomosci-potwierdzajacej-w-kontach-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-modele-de-message-de-confirmation-personnalise-dans-les-comptes-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-benutzerdefinierte-bestaetigungsnachrichtenvorlage-in-max-konten-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereUnTemplateDiMessaggioDiConfermaPersonalizzatoNegliAccountMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-template-di-messaggio-di-conferma-personalizzato-negli-account-max.html",
"en": "https://www.getresponse.com/help/how-to-add-a-custom-confirmation-message-template-in-max-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-plantilla-de-mensaje-de-confirmacion-personalizada-en-las-cuentas-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelskij-shablon-pisma-podtverzhdeniya-v-max-akkauntah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wlasny-szablon-wiadomosci-potwierdzajacej-w-kontach-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-modele-de-message-de-confirmation-personnalise-dans-les-comptes-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-benutzerdefinierte-bestaetigungsnachrichtenvorlage-in-max-konten-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnEnlaceDeProtocoloPersonalizado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-un-enlace-de-protocolo-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-collegamento-di-protocollo-personalizzato.html",
"en": "https://www.getresponse.com/help/what-is-a-custom-protocol-link.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-polzovatelskaya-ssylka-protokola.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-lien-protocolaire-personnalise.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-protokol-niestandardowy.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-benutzerdefinierte-protokollverbindung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddACustomConfirmationMessageTemplateInMaxAccounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-custom-confirmation-message-template-in-max-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-template-di-messaggio-di-conferma-personalizzato-negli-account-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-plantilla-de-mensaje-de-confirmacion-personalizada-en-las-cuentas-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelskij-shablon-pisma-podtverzhdeniya-v-max-akkauntah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wlasny-szablon-wiadomosci-potwierdzajacej-w-kontach-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-modele-de-message-de-confirmation-personnalise-dans-les-comptes-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-benutzerdefinierte-bestaetigungsnachrichtenvorlage-in-max-konten-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeExecuterPlusieursFoisDesActionsEtDesConditionsSpecifiques = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnCnameAMonSousDomaine = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnNouveauNomDExpediteurDeSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFormaterLeFichierDesDestinatairesDeSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerEtEnvoyerUnSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesReglementationsEnMatiereDEnvoiDeSmsDansCertainsPays = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html",
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnLienDeDesinscriptionParSmsAMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMettreEnPlaceLeMarketingParSmsAuxEtatsUnisEtAuCanada = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuVoirCommentFonctionnentMesSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCommencerAUtiliserLesSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDeclencherMonFluxDeTravailLorsDeLEnvoiDeSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDeclencherMonFluxDeTravailLorsquUnLienEstCliqueDansUnSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLAutomatisationPourEnvoyerDesSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserYandexMetricaPourSuivreMesAnciennesPagesDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSuivreLesOuverturesDeSms = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLesConversionsGoogleAdsSurMonAnciennePageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSupprimerUnCodeDeSuiviDeMaPageDeDestinationHeritee = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreMonAnciennePageDeDestinationAvecVisualWebsiteOptimizerVwo = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerMesAnciennesPagesDeDestinationAMixpanel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerMonAnciennePageDeDestinationAKissmetrics = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreMesCampagnesFacebookEtDesAnciennesPagesDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerMonAnciennePageDeDestinationABingAds = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterPlusieursCodesDeSuiviAMonAnciennePageDeDestinationAvecGoogleTagManager = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesDomainesSousDomainesEtRepertoiresPourMesAnciennesPagesDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesParametresDns = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEUnCollegamentoDiProtocolloPersonalizzato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-collegamento-di-protocollo-personalizzato.html",
"en": "https://www.getresponse.com/help/what-is-a-custom-protocol-link.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-enlace-de-protocolo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-polzovatelskaya-ssylka-protokola.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-lien-protocolaire-personnalise.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-protokol-niestandardowy.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-benutzerdefinierte-protokollverbindung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuTrouverLaCleApi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsACustomProtocolLink = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-custom-protocol-link.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-collegamento-di-protocollo-personalizzato.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-enlace-de-protocolo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-polzovatelskaya-ssylka-protokola.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-lien-protocolaire-personnalise.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-protokol-niestandardowy.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-benutzerdefinierte-protokollverbindung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAttribuerMonPropreDomaineOuSousDomaineAUneAnciennePageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLesDomainesPourMesAnciennesPagesDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentInstallerUnCertificatSslPourMesDomainesPersonnalises = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesSectionsDansLAncienCreateurDeLandingPages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneNotificationDeCookiesSurMaLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentOptimiserMonAncienneLandingPagePourLesAppareilsMobiles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneVideoAMonAncienneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesFormulairesPopUpAUneAncienneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeAjouterUnCodePersonnaliseAMonAncienneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFaireLeCompteAReboursDUnEvenementSurMonAncienneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMonAncienneLandingPageEstElleAccompagneeDUnePageDeRemerciement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html",
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDupliquerDesElementsSurMonAncienneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPromowacProduktyNaMoimLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-moim-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-ma-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-prodotti-sulla-mia-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-lendinge.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnregistrerUnModeleDuneAncienneLandingPageAfinDePouvoirReutiliserMaConception = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsSontLesOutilsDisponiblesDansLAncienEditeurDeLandingPages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLesVisitesEtLesInscriptionsAMaAncienneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPartagerUnEbookAvecVosContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMettreEnPlaceDesTestsABSurDesAnciennesLandingPages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuTrouverLesParametresDeMonAncienneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuTrouverDesModelesDeLandingPagesDansLAncienEditeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesFonctionnalitesOffertesParLesAnciennesLandingPages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html",
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUneLandingPageDansLAncienEditeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneLandingPageEtPourquoiEnAiJeBesoin = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentVerifierUnDomaineDansFacebookBusinessManager = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html",
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerMesAnciennesPagesDeDestinationAGoogleTagManager = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAcheterUnDomaineAvecGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnEmailDeConfirmacionDePedidoParaWoocommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-confirmacion-de-pedido-para-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-di-conferma-dell-ordine-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-order-confirmation-email-for-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-confirmation-de-commande-pour-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnEmailDeCarritoAbandonadoParaWoocommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-carrito-abandonado-para-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-per-il-carrello-abbandonato-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-abandoned-cart-email-for-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoj-korziny-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-panier-abandonne-pour-woocommerce.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-e-mail-fuer-abgebrochene-warenkoerbe-in-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnEMailPerIlCarrelloAbbandonatoPerWoocommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-per-il-carrello-abbandonato-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-abandoned-cart-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-carrito-abandonado-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoj-korziny-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-panier-abandonne-pour-woocommerce.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-e-mail-fuer-abgebrochene-warenkoerbe-in-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnEMailDiConfermaDellOrdinePerWoocommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-di-conferma-dell-ordine-per-woocommerce.html",
"en": "https://www.getresponse.com/help/how-to-create-order-confirmation-email-for-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-confirmacion-de-pedido-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-confirmation-de-commande-pour-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePromuovereIProdottiSullaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-prodotti-sulla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-ma-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-moim-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-lendinge.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAbandonedCartEmailForWoocommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-abandoned-cart-email-for-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-per-il-carrello-abbandonato-per-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-carrito-abandonado-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoj-korziny-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-panier-abandonne-pour-woocommerce.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-e-mail-fuer-abgebrochene-warenkoerbe-in-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateOrderConfirmationEmailForWoocommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-order-confirmation-email-for-woocommerce.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-e-mail-di-conferma-dell-ordine-per-woocommerce.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-email-de-confirmacion-de-pedido-para-woocommerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-woocommerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-woocommerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-email-de-confirmation-de-commande-pour-woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacKodyPromocyjneDoPopUpowLubTresciWbudowanej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kody-promocyjne-do-pop-upow-lub-tresci-wbudowanej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-codes-promo-a-mon-formulaire-pop-up-ou-a-mon-contenu-en-ligne.html",
"en": "https://www.getresponse.com/help/how-to-add-promo-codes-to-my-popup-form-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-promokod-vo-vsplyvajushhuju-ili-vstroennuju-formu.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-codigos-promocionales-a-mi-formulario-emergente-o-fragmento-de-contenido-en-linea.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-aktionscodes-zu-meinem-pop-up-formular-oder-inline-inhalt-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserUnCodeDeSuiviGoogleAnalyticsSurMonAnciennePageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAugmenterLaVisibiliteDeMonAnciennePageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiMonAnciennePageDeDestinationNApparaitEllePasEnLigne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUneLandingPageALAideDeModeles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUneLandingPageALAideDeLAssistantIa = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAttribuerMonPropreDomaineALaLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAttribuerMonPropreSousDomaineALaLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUtiliserDesDomainesEtSousDomainesAvecDeNouvellesLandingPages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html",
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUdostepniacSzablonyPomiedzyKontamiMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-udostepniac-szablony-pomiedzy-kontami-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-plantillas-entre-cuentas.html",
"en": "https://www.getresponse.com/help/how-to-share-templates-between-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-i-modelli-tra-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-des-modeles-entre-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-shablonami-mezhdu-uchetnymi-zapisyami-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJAiCreeMonCompteGetresponseViaLePanneauAffilieMaisJeNAiPasRecuDeCodeDeReduction = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html",
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLesDetailsDeMonCompteDoiventIlsCorrespondreALaFoisSurMonCompteDAffilieGetresponseEtSurMonCompteDeProduitGetresponsePourLesIntegrerEnsemble = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html",
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAuraiJeToujoursAccesAMonCompteDAffilieGetresponseEtPourraiJeToucherDesCommissionsSiJeNUtilisePasGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeUtiliserMesGainsDAffiliePourPayerMonCompteDeProduitGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMonCompteDeProduitGetresponseSeraTIlToujoursActifSiJeFermeMonCompteDAffilieGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourraiJePasserDeMonCompteGetresponseAMonCompteDAffilieGetresponseApresLesAvoirIntegres = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html",
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMesSousUtilisateursGetresponseAurontIlsAccesALaZoneDeMonCompteDAffilieGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVaisJeBeneficierDUneAssistanceAuxAffiliesParLeBiaisDesChatsDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPouvezVousPayerMesGainsDAffilieSurMaCarteDeCreditEnregistreeDansMonCompteDeProduitGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitSpfKDomenuAdresaOtpravitelyaVUchetnyhZapisyahMax = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-spf-k-domenu-adresa-otpravitelya-v-uchetnyh-zapisyah-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-spf-al-dominio-remitente-de-en-las-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-add-spf-to-the-from-domain-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-spf-do-domeny-nadawcy-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-spf-al-dominio-del-mittente-negli-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-spf-au-domaine-from-dans-les-comptes-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPromocionarProductosEnMiLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-ma-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-moim-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-prodotti-sulla-mia-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-lendinge.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToPromoteProductsOnMyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-ma-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-moim-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-prodotti-sulla-mia-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-lendinge.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesListesDeSuppressionEtQuandLesUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUneNouvelleListeDeContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeConsulterEtGererMesListes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSupprimerUneListeDeContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPersonnaliserLaPageDeConfirmation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPersonnaliserMonMessageDeConfirmation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeChangerLaLangueDeMaListeEtDuMessageDeConfirmation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiMaLigneObjetPersonnaliseeNATEllePasEteApprouvee = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnSegmentEtCommentEnCreerUn = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRechercherDesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeVoirTousMesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeTrouverLesContactsInactifs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeConsulterLesDetailsDeMesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCopierOuDeplacerDesContactsDUneListeAUneAutre = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSupprimerManuellementDesContactsDeMaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentExporterDesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSupprimerDesContactsDUneListeOuDUnCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTrouverLesPersonnesQuiNOntPasOuvertMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTrouverDesContactsParMethodeDInscription = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeScoreDEngagementEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSupprimerLesDoublonsDeMesListes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSegmenterMesContactsEnFonctionDesDonneesRelativesALECommerce = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentBloquerDesEMailsEtDesDomainesDeMesListesOuDeMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentImporterUneListeDeContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAttribuerDesChampsPersonnalisesLorsDeLImportation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesContactsIndividuelsDirectementAMaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeAjouterDesLiensAuxChampsPersonnalises = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerEtUtiliserDesChampsPersonnalises = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAttribuerDesTagsEtDesScoresLorsDeLImportation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesParametresDInscriptionDeMaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRechercherDesContactsEnFonctionDuConsentementQuIlsOntDonne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTrouverDesContactsEnFonctionDeLeurActiviteSurLeWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitZapisiDkimIDmarcVNamecheap = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-namecheap.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-namecheap.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-namecheap.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-namecheap.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-namecheap.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-namecheap.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitZapisiDkimIDmarcVGodaddy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-godaddy.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-godaddy.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-godaddy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-godaddy.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-godaddy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-godaddy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitZapisiDkimIDmarcVBluehost = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-bluehost.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-bluehost.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-bluehost.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-bluehost.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-bluehost.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-bluehost.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSavoirPourquoiMesContactsSeSontDesabonnes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsSontLesTypesDeChampsPersonnalisesDisponibles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPreparerUnFichierPourLImportation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeAjouterDesAdressesEMailBaseesSurUnRoleAMaListeDeContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentLesContactsPeuventIlsSAjouterAMaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeAttribuerDesTagsOuDesScoresAuxContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html",
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaFonctionDeValidationAutomatiqueDeNumeroDeTelephone = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeTrouverDesStatistiquesSurLesImportations = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTaggerDesContactsDeManiereAleatoire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVnestiAdresaElektronnojPochtyVSpisokBezopasnyhOtpravitelejChtobyObespechitBesperebojnujuDostavkuPochty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vnesti-adresa-elektronnoj-pochty-v-spisok-bezopasnyh-otpravitelej-chtoby-obespechit-besperebojnuju-dostavku-pochty.html",
"it": "https://www.getresponse.com/it/assistenza/come-inserire-indirizzi-e-mail-nell-elenco-indirizzi-attendibili-per-garantire-un-recapito-regolare-delle-e-mail.html",
"en": "https://www.getresponse.com/help/how-to-safelist-email-addresses-to-ensure-smooth-email-delivery.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-lista-segura-de-direcciones-de-correo-electronico-para-garantizar-un-envio-sin-problemas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-etablir-une-liste-d-adresses-electroniques-sures-pour-garantir-la-bonne-distribution-des-courriels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-email-adressen-auf-eine-sichere-liste-setzen-um-eine-reibungslose-zustellung-zu-versichern.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodelitsyaShablonamiMezhduUchetnymiZapisyamiMax = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-shablonami-mezhdu-uchetnymi-zapisyami-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-plantillas-entre-cuentas.html",
"en": "https://www.getresponse.com/help/how-to-share-templates-between-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-i-modelli-tra-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-des-modeles-entre-les-comptes-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepniac-szablony-pomiedzy-kontami-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPartagerDesModelesEntreLesComptesMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-partager-des-modeles-entre-les-comptes-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-plantillas-entre-cuentas.html",
"en": "https://www.getresponse.com/help/how-to-share-templates-between-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-i-modelli-tra-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-shablonami-mezhdu-uchetnymi-zapisyami-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepniac-szablony-pomiedzy-kontami-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEtablirUneListeDAdressesElectroniquesSuresPourGarantirLaBonneDistributionDesCourriels = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-etablir-une-liste-d-adresses-electroniques-sures-pour-garantir-la-bonne-distribution-des-courriels.html",
"it": "https://www.getresponse.com/it/assistenza/come-inserire-indirizzi-e-mail-nell-elenco-indirizzi-attendibili-per-garantire-un-recapito-regolare-delle-e-mail.html",
"en": "https://www.getresponse.com/help/how-to-safelist-email-addresses-to-ensure-smooth-email-delivery.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-lista-segura-de-direcciones-de-correo-electronico-para-garantizar-un-envio-sin-problemas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vnesti-adresa-elektronnoj-pochty-v-spisok-bezopasnyh-otpravitelej-chtoby-obespechit-besperebojnuju-dostavku-pochty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-email-adressen-auf-eine-sichere-liste-setzen-um-eine-reibungslose-zustellung-zu-versichern.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesModelesPourCreerDesFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterSpfAuDomaineFromDansLesComptesMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-spf-au-domaine-from-dans-les-comptes-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-spf-al-dominio-remitente-de-en-las-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-add-spf-to-the-from-domain-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-spf-do-domeny-nadawcy-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-spf-al-dominio-del-mittente-negli-account-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-spf-k-domenu-adresa-otpravitelya-v-uchetnyh-zapisyah-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesEnregistrementsDkimEtDmarcDansNamecheap = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-namecheap.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-namecheap.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-namecheap.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-namecheap.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-namecheap.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-namecheap.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesEnregistrementsDkimEtDmarcDansGodaddy = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-godaddy.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-godaddy.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-godaddy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-godaddy.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-godaddy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-godaddy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesEnregistrementsDkimEtDmarcDansBluehost = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-bluehost.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-bluehost.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-bluehost.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-bluehost.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-bluehost.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-bluehost.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnaListaSeguraDeDireccionesDeCorreoElectronicoParaGarantizarUnEnvioSinProblemas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-lista-segura-de-direcciones-de-correo-electronico-para-garantizar-un-envio-sin-problemas.html",
"it": "https://www.getresponse.com/it/assistenza/come-inserire-indirizzi-e-mail-nell-elenco-indirizzi-attendibili-per-garantire-un-recapito-regolare-delle-e-mail.html",
"en": "https://www.getresponse.com/help/how-to-safelist-email-addresses-to-ensure-smooth-email-delivery.html",
"fr": "https://www.getresponse.com/fr/aide/comment-etablir-une-liste-d-adresses-electroniques-sures-pour-garantir-la-bonne-distribution-des-courriels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vnesti-adresa-elektronnoj-pochty-v-spisok-bezopasnyh-otpravitelej-chtoby-obespechit-besperebojnuju-dostavku-pochty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-email-adressen-auf-eine-sichere-liste-setzen-um-eine-reibungslose-zustellung-zu-versichern.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeSpfIKakEtoNastroit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeInserireIndirizziEMailNellElencoIndirizziAttendibiliPerGarantireUnRecapitoRegolareDelleEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-inserire-indirizzi-e-mail-nell-elenco-indirizzi-attendibili-per-garantire-un-recapito-regolare-delle-e-mail.html",
"en": "https://www.getresponse.com/help/how-to-safelist-email-addresses-to-ensure-smooth-email-delivery.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-lista-segura-de-direcciones-de-correo-electronico-para-garantizar-un-envio-sin-problemas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-etablir-une-liste-d-adresses-electroniques-sures-pour-garantir-la-bonne-distribution-des-courriels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vnesti-adresa-elektronnoj-pochty-v-spisok-bezopasnyh-otpravitelej-chtoby-obespechit-besperebojnuju-dostavku-pochty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-email-adressen-auf-eine-sichere-liste-setzen-um-eine-reibungslose-zustellung-zu-versichern.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereRecordDkimEDmarcInBluehost = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-bluehost.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-bluehost.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-bluehost.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-bluehost.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-bluehost.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-bluehost.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCondividereIModelliTraAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-condividere-i-modelli-tra-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-plantillas-entre-cuentas.html",
"en": "https://www.getresponse.com/help/how-to-share-templates-between-accounts.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-des-modeles-entre-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-shablonami-mezhdu-uchetnymi-zapisyami-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepniac-szablony-pomiedzy-kontami-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereRecordDkimEDmarcInNamecheap = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-namecheap.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-namecheap.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-namecheap.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-namecheap.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-namecheap.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-namecheap.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereSpfAlDominioDelMittenteNegliAccountMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-spf-al-dominio-del-mittente-negli-account-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-spf-al-dominio-remitente-de-en-las-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-add-spf-to-the-from-domain-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-spf-do-domeny-nadawcy-w-kontach-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-spf-au-domaine-from-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-spf-k-domenu-adresa-otpravitelya-v-uchetnyh-zapisyah-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeMonitorareLaConversioneDeiSuggerimentiAiInGoogleAnalytics4 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-la-conversione-dei-suggerimenti-ai-in-google-analytics-4.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-rekomendacji-ai-w-google-analytics-4.html",
"en": "https://www.getresponse.com/help/how-to-track-ai-recommendations-conversion-in-google-analytics-4.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-recommandations-ia-dans-google-analytics-4.html",
"es": "https://www.getresponse.com/es/ayuda/como-realizar-un-seguimiento-de-la-conversion-de-las-recomendaciones-de-ia-en-google-analytics-4.html",
"br": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMaintenirLHygieneDeLaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRemedierAUneListeDeContactsPerimees = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html",
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueCelaSignifieQueMonImportationAEuUnProblemeDePartageDeListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueCelaSignifieQueMonImportationAEuDesProblemesDePolitique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLeScoring = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeUtiliserDesTagsEtDesScoresParLIntermediaireDeLApi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesTags = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesConditionsPuisJeUtiliserPourRechercherDesContactsEtCreerDesSegments = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html",
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJAiRecuUnEMailIndiquantQuUnContactAEteAjouteAUneListeMaisJeNePeuxPasLeVoir = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/j-ai-recu-un-e-mail-indiquant-qu-un-contact-a-ete-ajoute-a-une-liste-mais-je-ne-peux-pas-le-voir.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-eine-abobenachrichtigung-erhalten-kann-den-kontakt-aber-nicht-in-meiner-liste-finden.html",
"en": "https://www.getresponse.com/help/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/mne-prishlo-uvedomlenije-o-tom-chto-kontakt-byl-dobavlen-v-spisok-no-ja-jego-ne-vizhu.html",
"br": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pl": "https://www.getresponse.pl/pomoc/powiadomienie-o-subskrypcji-zostalo-dostarczone-ale-kontakt-nie-jest-dodany-do-listy.html",
"it": "https://www.getresponse.com/it/assistenza/ho-ricevuto-un-e-mail-che-informa-che-un-contatto-e-stato-aggiunto-a-un-elenco-ma-non-riesco-a-visualizzarli.html",
"es": "https://www.getresponse.com/es/ayuda/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesPiegesASpamEtLesListesDeBlocage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pieges-a-spam-et-les-listes-de-blocage.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-trampas-de-spam-y-las-listas-de-bloqueo.html",
"en": "https://www.getresponse.com/help/what-are-spam-traps-and-blocklists.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam-lovyshki-i-spiski-blokirovki.html",
"br": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pt": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-spam-traps-und-sperrlisten.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-spam-trapy-oraz-listy-zablokowanych-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-trappole-anti-spam-e-le-liste-di-blocco.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProposezVousUnCodeDeFormulaireHtmlSimple = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html",
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesApplicationsDeConstructionDeListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-applications-de-construction-de-liste.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-formularze-pop-up-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/what-are-list-builder-apps.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-formular-apps.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-aplicaciones-de-list-builder-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-formy-prilogeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLaPageDeRemerciementEtLUrlDejaInscritPourUnAncienFormulaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeDeclencherMonAncienFormulairePopUpALAideDUnBoutonOuDUnLien = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeConfigurerMonAncienFormulairePourQuIlApparaisseSurMaPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSavoirQuelleVersionDeMonAncienFormulaireMeDonneLeTauxDeConversionLePlusEleve = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-savoir-quelle-version-de-mon-ancien-formulaire-me-donne-le-taux-de-conversion-le-plus-eleve.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sprawdzic-ktora-wersja-formularza-legacy-zapewnia-mi-najlepsza-konwersje.html",
"en": "https://www.getresponse.com/help/how-do-i-find-out-which-version-of-the-legacy-form-gives-me-the-highest-conversion-rate.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-heraus-welches-legacy-webformular-mir-die-hoechste-conversion-rate-beschert.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-saber-que-version-del-formulario-de-legado-me-da-la-tasa-de-conversion-mas-alta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-kakaya-versiya-formyi-legacy-imeet-naibolshii-pokazatel-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentInstallerMonAncienFormulaireSurUnePageWordpress = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeUtiliserUneImageCommeArrierePlaDeFormulaireDansLAncienEditeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreUnFormulaireEtUneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesChampsPersonnalisesAMonAncienFormulaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUneVersionMobileDUnAncienFormulaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnFormulaireDansLAncienEditeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTransmettreLesDonneesDesAbonnesAUnePageDeRemerciementPersonnaliseeDansLesAnciensFormulaires = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEmpecherLInscriptionDeFauxRobots = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDesactiverLesInscriptionsEnHtmlSimple = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsTypesDeFormulairesPuisJeCreer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLesFormulairesEtLesPopUpsSontIlsDisponiblesPourLesComptesGratuits = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnPopUpALAideDUnModele = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLeTypeDePopUp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLaTailleDuPopUp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentChangerLaVueSucces = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLaFermetureDesPopUps = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesIconesDeMediasSociauxAMonFormulaireEtAMonPopUp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnFormulaireDInscription = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterEtModifierUnBouton = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerUneImageAPartirDUneUrl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterEtModifierUnBlocDeTexte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnCompteARebours = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerUneVideoAPartirDUneUrl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreUnFormulaireDeContactEtUnFormulaireDInscription = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreLesFormulairesEtLesPopUpsEtLesAnciensFormulaires = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerLeCodeDUnPopUpSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLeMomentDeLAffichageDeMonPopUp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLAudienceDeMonPopUp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLaFrequenceDAffichageDeMesPopUps = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLeDesignDeLaFenetrePopUpOuDuContenuEnLigne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnNouveauContenu = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html",
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLeTraficSurMesPopUpsOuMonContenuEnLigne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html",
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSupprimerUneFenetrePopUpOuUnContenuEnLigne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-fenetre-pop-up-ou-un-contenu-en-ligne.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-pop-up-lub-element-osadzony.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-a-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-vsplyivayuschie-okno-ili-vstroennuyu-formu.html",
"es": "https://www.getresponse.com/es/ayuda/how-do-i-delete-a-popup-or-inline-content.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-un-popup-o-un-contenuto-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-einen-popup-oder-inline-inhalt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerLeCodeDeContenuEnLigneSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerEtModifierUnFormulaireDeContact = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConfigurerLeFormulairePourQuIlMetteAJourLesContactsExistants = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentChangerLaLangueDesElementsNonModifiablesDuFormulaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDeclencherUnPopUpBaseSurLaVueOuLeLikeDUnProduit = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentInstallerDeNouveauxFormulairesEtPopUpsSurUneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSafelistEmailAddressesToEnsureSmoothEmailDelivery = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-safelist-email-addresses-to-ensure-smooth-email-delivery.html",
"it": "https://www.getresponse.com/it/assistenza/come-inserire-indirizzi-e-mail-nell-elenco-indirizzi-attendibili-per-garantire-un-recapito-regolare-delle-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-lista-segura-de-direcciones-de-correo-electronico-para-garantizar-un-envio-sin-problemas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-etablir-une-liste-d-adresses-electroniques-sures-pour-garantir-la-bonne-distribution-des-courriels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vnesti-adresa-elektronnoj-pochty-v-spisok-bezopasnyh-otpravitelej-chtoby-obespechit-besperebojnuju-dostavku-pochty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-email-adressen-auf-eine-sichere-liste-setzen-um-eine-reibungslose-zustellung-zu-versichern.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacRekordyDkimIDmarcWNamecheap = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-namecheap.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-namecheap.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-namecheap.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-namecheap.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-namecheap.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-namecheap.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUstanovitNovyeFormyIVsplyvajushhieOknaNaLending = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitNastrojkiPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacRekordyDkimIDmarcWBluehost = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-bluehost.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-bluehost.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-bluehost.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-bluehost.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-bluehost.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-bluehost.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRealizarUnSeguimientoDeLaConversionDeLasRecomendacionesDeIaEnGoogleAnalytics4 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-realizar-un-seguimiento-de-la-conversion-de-las-recomendaciones-de-ia-en-google-analytics-4.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-rekomendacji-ai-w-google-analytics-4.html",
"en": "https://www.getresponse.com/help/how-to-track-ai-recommendations-conversion-in-google-analytics-4.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-recommandations-ia-dans-google-analytics-4.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-la-conversione-dei-suggerimenti-ai-in-google-analytics-4.html",
"br": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatFaqVChatahGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-faq-v-chatah-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-faq-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-create-faq-in-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-faq-w-czatach-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-faq-dans-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-faq-nelle-chat-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEscreverUmaDicaParaOAssistenteDeIa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirRegistrosDkimYDmarcEnBluehost = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-bluehost.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-bluehost.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-bluehost.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-bluehost.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-bluehost.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-bluehost.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirRegistrosDkimYDmarcEnNamecheap = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-namecheap.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-namecheap.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-namecheap.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-namecheap.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-namecheap.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-namecheap.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirSpfAlDominioRemitenteDeEnLasCuentasMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-spf-al-dominio-remitente-de-en-las-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-add-spf-to-the-from-domain-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-spf-do-domeny-nadawcy-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-spf-al-dominio-del-mittente-negli-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-spf-au-domaine-from-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-spf-k-domenu-adresa-otpravitelya-v-uchetnyh-zapisyah-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCompartirPlantillasEntreCuentas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-compartir-plantillas-entre-cuentas.html",
"en": "https://www.getresponse.com/help/how-to-share-templates-between-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-i-modelli-tra-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-des-modeles-entre-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-shablonami-mezhdu-uchetnymi-zapisyami-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepniac-szablony-pomiedzy-kontami-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLasLimitacionesAlUtilizarUnDominioGratuitoComoCorreoElectronicoDeRemitente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html",
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElAsistenteDeIaYComoSeUtiliza = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirCodigosPromocionalesAMiFormularioEmergenteOFragmentoDeContenidoEnLinea = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-codigos-promocionales-a-mi-formulario-emergente-o-fragmento-de-contenido-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-codes-promo-a-mon-formulaire-pop-up-ou-a-mon-contenu-en-ligne.html",
"en": "https://www.getresponse.com/help/how-to-add-promo-codes-to-my-popup-form-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-promokod-vo-vsplyvajushhuju-ili-vstroennuju-formu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kody-promocyjne-do-pop-upow-lub-tresci-wbudowanej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-aktionscodes-zu-meinem-pop-up-formular-oder-inline-inhalt-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToShareTemplatesBetweenAccounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-share-templates-between-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-plantillas-entre-cuentas.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-i-modelli-tra-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-des-modeles-entre-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsya-shablonami-mezhdu-uchetnymi-zapisyami-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepniac-szablony-pomiedzy-kontami-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-partilhar-modelos-entre-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMettreEnPlaceDesImportationsRegulieresVersMesListes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesContactsAMesListes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddDkimAndDmarcRecordsInBluehost = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-bluehost.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-bluehost.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-bluehost.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-bluehost.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-bluehost.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-bluehost.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-na-bluehost.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddDkimAndDmarcRecordsInNamecheap = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-namecheap.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-namecheap.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-namecheap.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-namecheap.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-namecheap.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-namecheap.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-namecheap.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSledzicKonwersjeRekomendacjiAiWGoogleAnalytics4 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-rekomendacji-ai-w-google-analytics-4.html",
"en": "https://www.getresponse.com/help/how-to-track-ai-recommendations-conversion-in-google-analytics-4.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-recommandations-ia-dans-google-analytics-4.html",
"es": "https://www.getresponse.com/es/ayuda/como-realizar-un-seguimiento-de-la-conversion-de-las-recomendaciones-de-ia-en-google-analytics-4.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-la-conversione-dei-suggerimenti-ai-in-google-analytics-4.html",
"br": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacRekordyDkimIDmarcWGodaddy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-godaddy.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-godaddy.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-godaddy.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-godaddy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-godaddy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-godaddy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarRegistosDkimEDmarcEmGodaddy = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-godaddy.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-godaddy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-godaddy.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-godaddy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-godaddy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-godaddy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOGeradorDeIaDeTituloDeWebinarEComoOUsar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddSpfToTheFromDomainInMaxAccounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-spf-to-the-from-domain-in-max-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-spf-al-dominio-remitente-de-en-las-cuentas-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-spf-do-domeny-nadawcy-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-spf-al-dominio-del-mittente-negli-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-spf-au-domaine-from-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-spf-k-domenu-adresa-otpravitelya-v-uchetnyh-zapisyah-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-spf-ao-dominio-de-remetente-em-contas-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitPromokodVoVsplyvajushhujuIliVstroennujuFormu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-promokod-vo-vsplyvajushhuju-ili-vstroennuju-formu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-codes-promo-a-mon-formulaire-pop-up-ou-a-mon-contenu-en-ligne.html",
"en": "https://www.getresponse.com/help/how-to-add-promo-codes-to-my-popup-form-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-codigos-promocionales-a-mi-formulario-emergente-o-fragmento-de-contenido-en-linea.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kody-promocyjne-do-pop-upow-lub-tresci-wbudowanej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-aktionscodes-zu-meinem-pop-up-formular-oder-inline-inhalt-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieSushhestvujutOgranicheniyaPriIspolzovaniiBesplatnogoDomenaVKachestveAdresaOtpravitelya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html",
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuandImporterDesFichiersViaFtp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueGetresponseMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentVerifierLesStatistiquesDeMesEMailsTransactionnels = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html",
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesComptesSupplementairesDansMonCompteMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJAiUnCompteMaxCommentCommencer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesParametresDnsDesPagesDeDestinationCreeesDansUnCompteMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTelechargerUneListeVersUnCompteGetresponseMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentImporterDesFichiersViaFtp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConfigurerUnEMailTransactionnel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html",
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnvoyerDesEMailsTransactionnelsViaGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMettreEnPlaceUneIntegrationSmtp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSynchroniserLesContactsEntreGetresponseEtMicrosoftDynamics365 = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentActiverMicrosoftDynamics365DansGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentChoisirEtConfigurerUnDomaineDEnvoi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesRapportsPersonnalisesDansLesComptesMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnEnregistrementCnameAMonSousDomainePourLesPagesDeDestinationCreeesDansUnCompteMax = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLaConversionDesRecommandationsIaDansGoogleAnalytics4 = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-recommandations-ia-dans-google-analytics-4.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-rekomendacji-ai-w-google-analytics-4.html",
"en": "https://www.getresponse.com/help/how-to-track-ai-recommendations-conversion-in-google-analytics-4.html",
"es": "https://www.getresponse.com/es/ayuda/como-realizar-un-seguimiento-de-la-conversion-de-las-recomendaciones-de-ia-en-google-analytics-4.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-la-conversione-dei-suggerimenti-ai-in-google-analytics-4.html",
"br": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereRecordDkimEDmarcInGodaddy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-godaddy.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-godaddy.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-godaddy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-godaddy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-godaddy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-godaddy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirRegistrosDkimYDmarcEnGodaddy = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-godaddy.html",
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-godaddy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-godaddy.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-godaddy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-godaddy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-godaddy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddDkimAndDmarcRecordsInGodaddy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-dkim-and-dmarc-records-in-godaddy.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-registros-dkim-y-dmarc-en-godaddy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekordy-dkim-i-dmarc-w-godaddy.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-record-dkim-e-dmarc-in-godaddy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-enregistrements-dkim-et-dmarc-dans-godaddy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-registos-dkim-e-dmarc-em-godaddy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-zapisi-dkim-i-dmarc-v-godaddy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOAssistenteIaEComoUsaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAReputacaoDoCampoDeRemetenteInfluenciaACapacidadeDeEntregaNaCaixaDeEntradaOuNaPastaDeSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/a-reputacao-do-campo-de-remetente-influencia-a-capacidade-de-entrega-na-caixa-de-entrada-ou-na-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-reputacao-do-campo-de-remetente-influencia-a-capacidade-de-entrega-na-caixa-de-entrada-ou-na-pasta-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSignificaOEstadoDoMeuDominioDeEnvio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEAiAssistantEComeUsarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereCodiciPromozionaliAlMioModuloPopupOAlContenutoInLinea = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-codici-promozionali-al-mio-modulo-popup-o-al-contenuto-in-linea.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakNapisacPodpowiedzDlaAsystentaAi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRedigerUneInvitePourLAssistantIa = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLAssistantIaEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSaOgraniczeniaUzywajacDarmowejDomenyJakoAdresNadawcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToJestAsystentAiIJakZNiegoKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoAsLimitacoesDaUtilizacaoDeUmDominioGratuitoComoEMailDeRemetente = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoLeLimitazioniQuandoSiUtilizzaIlDominioGratuitoComeMittenteDellEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesLimitesDeLUtilisationDUnDomaineGratuitEnTantQuEMailDeLExpediteur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheEinschraenkungenGibtEsBeiDerVerwendungEinerFreienDomainAlsAbsenderEMail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackAiRecommendationsConversionInGoogleAnalytics4 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-ai-recommendations-conversion-in-google-analytics-4.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-rekomendacji-ai-w-google-analytics-4.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-recommandations-ia-dans-google-analytics-4.html",
"es": "https://www.getresponse.com/es/ayuda/como-realizar-un-seguimiento-de-la-conversion-de-las-recomendaciones-de-ia-en-google-analytics-4.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-la-conversione-dei-suggerimenti-ai-in-google-analytics-4.html",
"br": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-monitorizar-a-conversao-de-recomendacoes-de-ia-no-google-analytics-4.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheLimitationsWhenUsingFreeDomainAsFromEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-limitations-when-using-free-domain-as-from-email.html",
"de": "https://www.getresponse.com/de/hilfe/welche-einschraenkungen-gibt-es-bei-der-verwendung-einer-freien-domain-als-absender-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-limites-de-l-utilisation-d-un-domaine-gratuit-en-tant-qu-e-mail-de-l-expediteur.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-limitazioni-quando-si-utilizza-il-dominio-gratuito-come-mittente-dell-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-limitacoes-da-utilizacao-de-um-dominio-gratuito-como-e-mail-de-remetente.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-ograniczenia-uzywajac-darmowej-domeny-jako-adres-nadawcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sushhestvujut-ogranicheniya-pri-ispolzovanii-besplatnogo-domena-v-kachestve-adresa-otpravitelya.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-limitaciones-al-utilizar-un-dominio-gratuito-como-correo-electronico-de-remitente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAiAssistantAndHowToUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-ai-assistant-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-asystent-ai-i-jak-z-niego-korzystac.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-assistant-ia-et-comment-l-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-ai-assistant-e-come-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-assistente-ia-e-como-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-asistente-de-ia-y-como-se-utiliza.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ii-assistent-i-kak-im-polzovatsya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-assistent-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoScrivereUnPromptPerAiAssistant = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesCodesPromoAMonFormulairePopUpOuAMonContenuEnLigne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-codes-promo-a-mon-formulaire-pop-up-ou-a-mon-contenu-en-ligne.html",
"en": "https://www.getresponse.com/help/how-to-add-promo-codes-to-my-popup-form-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-promokod-vo-vsplyvajushhuju-ili-vstroennuju-formu.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-codigos-promocionales-a-mi-formulario-emergente-o-fragmento-de-contenido-en-linea.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kody-promocyjne-do-pop-upow-lub-tresci-wbudowanej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-aktionscodes-zu-meinem-pop-up-formular-oder-inline-inhalt-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddPromoCodesToMyPopupFormOrInlineContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-promo-codes-to-my-popup-form-or-inline-content.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-codes-promo-a-mon-formulaire-pop-up-ou-a-mon-contenu-en-ligne.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-promokod-vo-vsplyvajushhuju-ili-vstroennuju-formu.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-codigos-promocionales-a-mi-formulario-emergente-o-fragmento-de-contenido-en-linea.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kody-promocyjne-do-pop-upow-lub-tresci-wbudowanej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-codigos-promocionais-ao-meu-formulario-pop-up-ou-conteudo-em-linha.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-aktionscodes-zu-meinem-pop-up-formular-oder-inline-inhalt-hinzu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEscriboUnAvisoParaElAsistenteIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueSpfEtCommentLeConfigurer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoActivarUnaVentanaEmergenteEnFuncionDeUnPedidoRealizadoOUnCarritoActualizado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-un-pedido-realizado-o-un-carrito-actualizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-in-base-all-ordine-effettuato-o-al-carrello-aggiornato.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-placed-order-or-updated-cart.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-na-podstawie-zlozonego-zamowienia-lub-aktualizacji-koszyka.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iniciirovat-vsplyvajushhee-okno-na-osnove-oformlennogo-zakaza-ili-obnovlennoj-korziny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loese-ich-ein-pop-up-aus-wenn-ich-eine-bestellung-aufgegeben-oder-den-warenkorb-aktualisiert-habe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeEseguirePingETraceroute = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsSpfYComoConfigurarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEjecutarPingsYTraceroutes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToWriteAPromptForAiAssistant = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-write-a-prompt-for-ai-assistant.html",
"pl": "https://www.getresponse.pl/pomoc/jak-napisac-podpowiedz-dla-asystenta-ai.html",
"es": "https://www.getresponse.com/es/ayuda/como-escribo-un-aviso-para-el-asistente-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scrivere-un-prompt-per-ai-assistant.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rediger-une-invite-pour-l-assistant-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escrever-uma-dica-para-o-assistente-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-napisat-zapros-dlya-ii-assistenta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-schreibe-ich-einen-prompt-fuer-den-ki-assistenten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOSpfEComoOConfigurar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoExecutarPingsETraceroutes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEffectuerDesPingsEtDesTraceroutes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWywolacPopUpNaPodstawieZlozonegoZamowieniaLubAktualizacjiKoszyka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-na-podstawie-zlozonego-zamowienia-lub-aktualizacji-koszyka.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-in-base-all-ordine-effettuato-o-al-carrello-aggiornato.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-placed-order-or-updated-cart.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-un-pedido-realizado-o-un-carrito-actualizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iniciirovat-vsplyvajushhee-okno-na-osnove-oformlennogo-zakaza-ili-obnovlennoj-korziny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loese-ich-ein-pop-up-aus-wenn-ich-eine-bestellung-aufgegeben-oder-den-warenkorb-aktualisiert-habe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUruchomicPoleceniaPingITraceroute = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRegistarUmDominioPersonalizadoGratuito = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarejestrowac-wlasna-darmowa-domene.html",
"en": "https://www.getresponse.com/help/how-to-register-a-free-custom-domain.html",
"it": "https://www.getresponse.com/it/assistenza/come-registrare-un-dominio-personalizzato-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/como-registrar-un-dominio-personalizado-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaregistrirovat-besplatnyj-polzovatelskij-domen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-domaine-personnalise-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestSpfIJakGoSkonfigurowac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToRunPingsAndTraceroutes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-run-pings-and-traceroutes.html",
"it": "https://www.getresponse.com/it/assistenza/come-eseguire-ping-e-traceroute.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-polecenia-ping-i-traceroute.html",
"br": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-executar-pings-e-traceroutes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-effectuer-des-pings-et-des-traceroutes.html",
"es": "https://www.getresponse.com/es/ayuda/como-ejecutar-pings-y-traceroutes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapuskat-komandy-ping-i-trassirovku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuehre-ich-pings-und-traceroutes-aus.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosESpfEComeConfigurarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAttivareUnPopupInBaseAllOrdineEffettuatoOAlCarrelloAggiornato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-in-base-all-ordine-effettuato-o-al-carrello-aggiornato.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-placed-order-or-updated-cart.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-na-podstawie-zlozonego-zamowienia-lub-aktualizacji-koszyka.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-un-pedido-realizado-o-un-carrito-actualizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iniciirovat-vsplyvajushhee-okno-na-osnove-oformlennogo-zakaza-ili-obnovlennoj-korziny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loese-ich-ein-pop-up-aus-wenn-ich-eine-bestellung-aufgegeben-oder-den-warenkorb-aktualisiert-habe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEPossivelLigarUmFormularioAUmBotaoNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html",
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDivisaoAvancadaDeContactos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDivisaoSimplesDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTriggerAPopupBasedOnPlacedOrderOrUpdatedCart = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-placed-order-or-updated-cart.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-in-base-all-ordine-effettuato-o-al-carrello-aggiornato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-na-podstawie-zlozonego-zamowienia-lub-aktualizacji-koszyka.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-un-pedido-realizado-o-un-carrito-actualizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iniciirovat-vsplyvajushhee-okno-na-osnove-oformlennogo-zakaza-ili-obnovlennoj-korziny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-um-popup-com-base-num-pedido-feito-ou-num-carrinho-atualizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loese-ich-ein-pop-up-aus-wenn-ich-eine-bestellung-aufgegeben-oder-den-warenkorb-aktualisiert-habe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentActiverLesInscriptionsPourLesVisiteursDeMaBoutiquePrestashop = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnEMailDePanierAbandonneEtCommentLeCreer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailDePanierAbandonnePourMagento = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnCodePromoShopifyDansGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerWordpressAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLEMailDeConfirmationDeCommandeEtCommentLeCreer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTelechargerLesDonneesDeMesProduitsVersGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeDevelopperMaBaseDeDonneesDeContactsEnIntegrantGetresponseAMagento = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-developper-ma-base-de-donnees-de-contacts-en-integrant-getresponse-a-magento.html",
"it": "https://www.getresponse.com/it/assistenza/posso-far-crescere-il-mio-database-di-contatti-integrando-getresponse-con-magento.html",
"en": "https://www.getresponse.com/help/can-i-grow-my-contact-database-by-integrating-getresponse-with-magento.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakt-datenbank-erweitern-indem-ich-getresponse-mit-magento-integriere.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-crecer-mi-base-de-datos-de-contactos-integrando-getresponse-con-magento-2.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDevelopperMonActiviteAvecLesFonctionsECommerceDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailDeConfirmationDeCommandePourMagento = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailDePanierAbandonnePourPrestashop = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesEvenementsEtCommentLesUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-evenements-et-comment-les-utiliser.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-gli-eventi-e-come-utilizzarli.html",
"en": "https://www.getresponse.com/help/what-are-events-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-events-und-wie-verwendet-man-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zdarzenia-i-jak-z-nich-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-eventos-y-como-utilizarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-sobytiya-i-kak-ih-ispolzovat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerContactForm7ALAideDeLIntegrationWordpress = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailDeConfirmationDeCommandePourPrestashop = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerMagento2AvecGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSuivreEtCollecterDesDonneesECommerceAPartirDePrestashop = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesCodesPromo = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailDePanierAbandonnePourShopify = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerCompletementMaBoutiquePrestashopAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailDeConfirmationDeCommandePourShopify = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerWoocommerceAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesEmailsTransactionnelsRapidesEtAQuoiPeuventIlsServir = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelTypeDeDonneesECommerceEstEnvoyeAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeConnecterMaBoutiqueEnLigneAGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerShopifyEtGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsSpfAndHowToConfigureIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-spf-and-how-to-configure-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spf-i-jak-go-skonfigurowac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-spf-e-come-configurarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-spf-e-como-o-configurar.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-spf-y-como-configurarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-spf-et-comment-le-configurer.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spf-i-kak-eto-nastroit.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spf-und-wie-kann-man-ihn-konfigurieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoOznachaetStatusMoegoOtpravlyajushhegoDomena = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNaznachitDomenSvoemuLendingu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNaznachitSubdomenSvoemuLendingu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatLendingSPomoshhjuKonstruktoraIi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatLendingSPomoshhjuShablonov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeDmarc = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html",
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuGmailYahooIliLjubojDrugojBesplatnyjDomenNeMozhetBytIspolzovanDlyaOtpravkiPisemVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html",
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterManuellementUnContactAuCycleDAutoRepondeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueFichiersEtImagesEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDeplacerDesFichiersDansFichiersEtImages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnFunnelDeConversion = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesDifferencesEntreLesPagesDeFunnel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesDifferencesEntreUnFunnelDeVenteRapideEtUnFunnelDeVenteEclair = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesPagesUpsellDansLesFunnelsDeConversion = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentVendreUnEBookALAideDuFunnelDeConversion = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnePageDeVente = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnFunnelDeVente = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnFunnelDeWebinairePayant = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnFunnelDeConstructionDeListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html",
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnFunnelDeLeadMagnet = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnFunnelDeWebinaireGratuit = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentVerifierLIntegrationDePaypal = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentChangerLeLeadMagnetDansMonFunnel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeIntegrerMonCompteASquare = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoGmailYahooAniZadneInneBezplatneDomenyNieMogaZostacUzyteDoWysylaniaWiadomosciPoprzezGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConectarMiCuentaXTwitterYGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueOGmailYahooOuQualquerOutroDominioGratuitoNaoPodeSerUsadoParaEnviarAtravesDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoSePuedeUtilizarGmailYahooOCualquierDominioGratuitoParaRealizarEnviosConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiGmailYahooOuToutAutreDomaineGratuitNePeutIlPasEtreUtilisePourLEnvoiAvecGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheGmailYahooOQualsiasiDominioGratuitoNonPossonoEssereUtilizzatiPerInviareConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html",
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumKoennenGmailYahooOderAndereKostenloseDomainsNichtFuerDenVersandMitGetresponseVerwendetWerden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyGmailYahooOrAnyFreeDomainCannotBeUsedForSendingWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-gmail-yahoo-or-any-free-domain-cannot-be-used-for-sending-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/warum-koennen-gmail-yahoo-oder-andere-kostenlose-domains-nicht-fuer-den-versand-mit-getresponse-verwendet-werden.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-gmail-yahoo-ani-zadne-inne-bezplatne-domeny-nie-moga-zostac-uzyte-do-wysylania-wiadomosci-poprzez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-gmail-yahoo-ou-tout-autre-domaine-gratuit-ne-peut-il-pas-etre-utilise-pour-l-envoi-avec-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-puede-utilizar-gmail-yahoo-o-cualquier-dominio-gratuito-para-realizar-envios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-gmail-yahoo-ou-qualquer-outro-dominio-gratuito-nao-pode-ser-usado-para-enviar-atraves-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gmail-yahoo-o-qualsiasi-dominio-gratuito-non-possono-essere-utilizzati-per-inviare-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-gmail-yahoo-ili-ljuboj-drugoj-besplatnyj-domen-ne-mozhet-byt-ispolzovan-dlya-otpravki-pisem-v-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRegistrarUnDominioPersonalizadoGratuito = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-registrar-un-dominio-personalizado-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarejestrowac-wlasna-darmowa-domene.html",
"en": "https://www.getresponse.com/help/how-to-register-a-free-custom-domain.html",
"it": "https://www.getresponse.com/it/assistenza/come-registrare-un-dominio-personalizzato-gratuito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaregistrirovat-besplatnyj-polzovatelskij-domen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-domaine-personnalise-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaLandingPageUtilizandoOCriadorOrientadoPelaIa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmFormularioDeWebinarAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEditarAsConfiguracoesDeAssinatura = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarejestrowacWlasnaDarmowaDomene = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarejestrowac-wlasna-darmowa-domene.html",
"en": "https://www.getresponse.com/help/how-to-register-a-free-custom-domain.html",
"it": "https://www.getresponse.com/it/assistenza/come-registrare-un-dominio-personalizzato-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/como-registrar-un-dominio-personalizado-gratuito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaregistrirovat-besplatnyj-polzovatelskij-domen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-domaine-personnalise-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProdvizhenieVebinaraDlyaPartnerov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmaImagemDeFundoAoLobbyEASalaDeWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareMessaggiConOrientamentoDelTestoDaDestraASinistra = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEPossibileCollegareUnModuloConUnPulsanteSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html",
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeRegistrareUnDominioPersonalizzatoGratuito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-registrare-un-dominio-personalizzato-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarejestrowac-wlasna-darmowa-domene.html",
"en": "https://www.getresponse.com/help/how-to-register-a-free-custom-domain.html",
"es": "https://www.getresponse.com/es/ayuda/como-registrar-un-dominio-personalizado-gratuito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaregistrirovat-besplatnyj-polzovatelskij-domen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-domaine-personnalise-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEstIlPossibleDeConnecterUnFormulaireAvecUnBoutonSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html",
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRepartitionAvanceeDesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRepartitionSimpleDesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarOWordpressComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarOWoocommerceComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoOznaczaStatusMojejDomenyWysylkowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToRegisterAFreeCustomDomain = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-register-a-free-custom-domain.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarejestrowac-wlasna-darmowa-domene.html",
"it": "https://www.getresponse.com/it/assistenza/come-registrare-un-dominio-personalizzato-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/como-registrar-un-dominio-personalizado-gratuito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-registar-um-dominio-personalizado-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zaregistrirovat-besplatnyj-polzovatelskij-domen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-domaine-personnalise-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMozliweJestPolaczenieFormularzaZPrzyciskiemNaMojejStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html",
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestGeneratorTytulowWebinarowAiIJakZNiegoKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEsPosibleConectarUnFormularioConUnBotonEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html",
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDivisionDeContactosAvanzada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnQuoiLeFunnelDeConversionEstIlDifferentDeLAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLAutoGenerateurDeFunnelsDeVente = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeIntegrerMonCompteAPayu = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-payu.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-payu.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintergrowac-konto-z-payu.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-payu.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-payu.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-payu-integrieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-integrirovat-svoy-akkaunt-s-payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPolaczycMojeKontaXTwitterIGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosELaChecklistPerLOnboardingDellaCampagnaAiEComeUtilizzarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-checklist-per-l-onboarding-della-campagna-ai-e-come-utilizzarlo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-lista-de-control-de-bienvenida-de-la-campana-de-ia-y-como-utilizarla.html",
"en": "https://www.getresponse.com/help/what-is-the-ai-campaign-onboarding-checklist-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-onboardingowa-kampanii-ai-i-jak-z-niej-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeConnettereIMieiAccountXTwitterEGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEIlGeneratoreIaDelTitoloDelWebinarEComeUsarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIsItPossibleToConnectAFormWithAButtonOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/is-it-possible-to-connect-a-form-with-a-button-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozliwe-jest-polaczenie-formularza-z-przyciskiem-na-mojej-stronie-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/es-posible-conectar-un-formulario-con-un-boton-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/est-il-possible-de-connecter-un-formulaire-avec-un-bouton-sur-mon-site-web.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-collegare-un-modulo-con-un-pulsante-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/e-possivel-ligar-um-formulario-a-um-botao-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-soedinit-formu-s-knopkoj-na-moem-sajte.html",
"de": "https://www.getresponse.com/de/hilfe/ist-es-moeglich-ein-formular-mit-einem-button-auf-meiner-website-zu-verbinden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsYComoSeUtilizaElGeneradorDeIaParaTitulosDeWebinars = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDivisionDeContactosSimple = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZaawansowanyPodzialKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProstyPodzialKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakTworzycWlasneRaporty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitFormuVebinaraNaSvojSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuddivisioneDeiContattiSemplice = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareReportPersonalizzati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuddivisioneAvanzataDeiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEncontrarContatosComBaseNaAtividadeDeWebinario = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaPaginaDeDestinoUsandoTemplates = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoOsRequisitosTecnicosParaUsarAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstalarNovosFormulariosEPopUpsEmUmaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieWeiseIchDerLandingPageMeineEigeneDomainZu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieInstalliereIchNeueFormulareUndPopupsAufMeinerLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaCampanhaDeIa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-campana-de-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-ai-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kampanie-ai.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-campagna-ai.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-kampaniju-ii.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-eine-ki-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchBenutzerdefinierteBerichte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAListaDeVerificacaoDaCampanhaDeIaEComoUsaLa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-lista-de-control-de-bienvenida-de-la-campana-de-ia-y-como-utilizarla.html",
"en": "https://www.getresponse.com/help/what-is-the-ai-campaign-onboarding-checklist-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-onboardingowa-kampanii-ai-i-jak-z-niej-korzystac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-checklist-per-l-onboarding-della-campagna-ai-e-come-utilizzarlo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineLandingPageMithilfeVonVorlagen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchMeinerWebsiteEinWebinarFormularHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarRelatoriosCustomizados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConectarMinhasContasDoXTwitterEDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdvancedContactSplit = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/advanced-contact-split.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-avanzata-dei-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-avanzada.html",
"pl": "https://www.getresponse.pl/pomoc/zaawansowany-podzial-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-avancee-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-avancada-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvinutoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/erweiterte-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSimpleContactSplit = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/simple-contact-split.html",
"it": "https://www.getresponse.com/it/assistenza/suddivisione-dei-contatti-semplice.html",
"pl": "https://www.getresponse.pl/pomoc/prosty-podzial-kontaktow.html",
"es": "https://www.getresponse.com/es/ayuda/division-de-contactos-simple.html",
"fr": "https://www.getresponse.com/fr/aide/repartition-simple-des-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/divisao-simples-de-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoe-razdelenie-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/einfache-kontaktaufteilung.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBearbeiteIchOptInEinstellungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearInformesPersonalizados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoCampanhasDeIa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-campanas-de-ia.html",
"en": "https://www.getresponse.com/help/what-are-ai-campaigns.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-campagne-ai.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-kampanie-ai.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-ki-kampagnen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsWebinarTitleAiGeneratorAndHowToUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-webinar-title-ai-generator-and-how-to-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-y-como-se-utiliza-el-generador-de-ia-para-titulos-de-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-generatore-ia-del-titolo-del-webinar-e-come-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tytulow-webinarow-ai-i-jak-z-niego-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-ia-de-titulo-de-webinar-e-como-o-usar.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-generateur-ia-de-titres-de-webinaires-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-generator-ii-zagolovkov-dlya-vebinarov-i-kak-s-nim-rabotat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-generator-fuer-webinar-titel-und-wie-benutzt-man-ihn.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestListaOnboardingowaKampaniiAiIJakZNiejKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-onboardingowa-kampanii-ai-i-jak-z-niej-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-lista-de-control-de-bienvenida-de-la-campana-de-ia-y-como-utilizarla.html",
"en": "https://www.getresponse.com/help/what-is-the-ai-campaign-onboarding-checklist-and-how-to-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-checklist-per-l-onboarding-della-campagna-ai-e-come-utilizzarlo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarRelatoriosCustomizadosEmContasMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateCustomReports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-custom-reports.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchBenutzerdefinierteBerichteInMaxKonten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineLandingpageMitDemKiAssistenten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmFormularioContactForm7UsandoUmaIntegracaoComOWordpress = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakTworzycWlasneRaportyWKontachMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAViagemNoTempoEComoElaFunciona = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntrePerfectTimingETimeTravel = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeSobytiyaIKakIhIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-sobytiya-i-kak-ih-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-gli-eventi-e-come-utilizzarli.html",
"en": "https://www.getresponse.com/help/what-are-events-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-events-und-wie-verwendet-man-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zdarzenia-i-jak-z-nich-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-eventos-y-como-utilizarlos.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-evenements-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaKampanieAi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-kampanie-ai.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-campanas-de-ia.html",
"en": "https://www.getresponse.com/help/what-are-ai-campaigns.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-campagne-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-ki-kampagnen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeTrouverLesStatistiquesDeGetresponseChats = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuePeuventFaireLesOperateursDansGetresponseChats = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html",
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesMessagesPredefinisEtCommentLesUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesChatsDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTesterLesChatsDeGetresponseAvantDeLesUtiliserSurMonSiteWebOuUnePageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentConfigurerLesChatsDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnvoyerDesPiecesJointesDansLesChatsDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentGererLesChatsDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentInstallerLeCodeDeChatsGetresponseSurMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDonnerAUnMembreDeLEquipeLAccesAuxChatsDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentActiverLesChatsDeGetresponseSurUneLandingPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentVerifierSiLaPersonneSurGetresponseChatsEstToujoursSurLaPage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentBloquerQuelquUnSurGetresponseChats = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesContactsViaGetresponseChats = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnLienGetresponseChatsDansUnMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeUtiliserGetresponseChatsDansLApplicationMobile = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycKampanieAi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kampanie-ai.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-campana-de-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-ai-campaign.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-campagna-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-kampaniju-ii.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-eine-ki-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnvoyerUnEMailViaGetresponseChats = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUneFaqDansGetresponseChats = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-faq-dans-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-faq-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-create-faq-in-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-faq-w-czatach-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-faq-nelle-chat-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-faq-v-chatah-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeCampagneAi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-campagne-ai.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-campanas-de-ia.html",
"en": "https://www.getresponse.com/help/what-are-ai-campaigns.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-kampanie-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-ki-kampagnen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnaCampagnaAi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-campagna-ai.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-campana-de-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-ai-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kampanie-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-kampaniju-ii.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-eine-ki-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChemPerfectTimingOtlichaetsyaOtUchetaChasovyhPoyasov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacDomenISubdomenWNowychLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html",
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeUchetChasovyhPoyasovKakEtoRabotaet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaListaDeControlDeBienvenidaDeLaCampanaDeIaYComoUtilizarla = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-lista-de-control-de-bienvenida-de-la-campana-de-ia-y-como-utilizarla.html",
"en": "https://www.getresponse.com/help/what-is-the-ai-campaign-onboarding-checklist-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-onboardingowa-kampanii-ai-i-jak-z-niej-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-checklist-per-l-onboarding-della-campagna-ai-e-come-utilizzarlo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasCampanasDeIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-campanas-de-ia.html",
"en": "https://www.getresponse.com/help/what-are-ai-campaigns.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-campagne-ai.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-kampanie-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-ki-kampagnen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnaCampanaDeIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-campana-de-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-ai-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kampanie-ai.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-campagna-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-kampaniju-ii.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-eine-ki-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheAiCampaignOnboardingChecklistAndHowToUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-ai-campaign-onboarding-checklist-and-how-to-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-lista-de-control-de-bienvenida-de-la-campana-de-ia-y-como-utilizarla.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-onboardingowa-kampanii-ai-i-jak-z-niej-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-lista-de-verificacao-da-campanha-de-ia-e-como-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-checklist-per-l-onboarding-della-campagna-ai-e-come-utilizzarlo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreAiCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-ai-campaigns.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-campanas-de-ia.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-campagne-ai.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-kampanie-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-campanhas-de-ia.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-ki-kampagnen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAiCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-ai-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-campana-de-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-kampanie-ai.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-campagna-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-campanha-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-kampaniju-ii.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstellt-man-eine-ki-kampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignificaElEstadoDeMiDominioDeEnvio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarDominiosESubdominiosEmNovasLandingPages = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html",
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoReenviarLosDatosDeLosSuscriptoresAUnaPaginaDeAgradecimientoPersonalizadaEnFormulariosDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUsoDeDominiosYSubdominiosConLasNuevasLandingPages = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html",
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUtilizzoDiDominiESottodominiConLaNuovaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html",
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareReportPersonalizzatiNegliAccountMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToConnectMyTwitterAndGetresponseAccounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-connect-my-twitter-and-getresponse-accounts.html",
"br": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-conectar-minhas-contas-do-x-twitter-e-da-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-polaczyc-moje-konta-x-twitter-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-connettere-i-miei-account-x-twitter-e-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-conectar-mi-cuenta-x-twitter-y-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-x-twitter-et-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-svyazat-moi-akkaunty-x-twitter-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-meine-x-twitter-und-getresponse-konten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUsingDomainsAndSubdomainsWithNewLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/using-domains-and-subdomains-with-new-landing-pages.html",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-di-domini-e-sottodomini-con-la-nuova-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/uso-de-dominios-y-subdominios-con-las-nuevas-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-dominios-e-subdominios-em-novas-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-i-subdomen-w-nowych-landing-pages.html",
"fr": "https://www.getresponse.com/fr/aide/utiliser-des-domaines-et-sous-domaines-avec-de-nouvelles-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-domenov-i-subdomenov-s-novymi-lendingami.html",
"de": "https://www.getresponse.com/de/hilfe/domains-und-subdomains-fuer-neue-landing-pages-nutzen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycFaqWCzatachGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-faq-w-czatach-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-faq-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-create-faq-in-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-faq-dans-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-faq-nelle-chat-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-faq-v-chatah-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearInformesPersonalizadosEnCuentasMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZainstalowacNoweFormularzeIPopUpyNaLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateCustomReportsInMaxAccounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-custom-reports-in-max-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-informes-personalizados-en-cuentas-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-wlasne-raporty-w-kontach-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-report-personalizzati-negli-account-max.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-relatorios-customizados-em-contas-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-benutzerdefinierte-berichte-in-max-konten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-rapports-personnalises-dans-les-comptes-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-nastraivaemye-otchety-v-uchetnyh-zapisyah-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarPerguntasFrequentesNosChatsDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-faq-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-create-faq-in-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-faq-w-czatach-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-faq-dans-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-faq-nelle-chat-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-faq-v-chatah-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreLesAutoRepondeursEtLAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreUneNewsletterEtUnAutoRepondeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnAutoRepondeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaVueDuCalendrierPourLesAutoRepondeurs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnAutoRepondeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLesJoursDEnvoiDesAutoRepondeurs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnAutreMessageAUnCycleDAutoRepondeurExistant = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnvoyerUnMessageInstantaneApresQuUnePersonneSeSoitInscriteViaLaPageDeDestination = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiMesAutoRepondeursNeSontIlsPasEnvoyes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesExigencesTechniquesPourUtiliserGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeDonnerAccesAMonCompteADAutresPersonnes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeTraiterDesDonneesEnDehorsDeLUeDansLeCadreDuRgpd = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeEssayerGetresponseAvantDeLAcheter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMonComptePeutIlEtreFermeEnRaisonDePlaintesPourSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentActiverMonCompteFree = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-activer-mon-compte-free.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-free-akkaynt-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-my-free-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-aktywowac-darmowe-konto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-kostenloses-konto-aktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-cuenta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-account-gratuito.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneNouvelleAdresseEtUnNouveauNomDeLExpediteurPourMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLIdentifiantDeMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPersonnaliserMonTableauDeBordAvecDesWidgets = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentMAssurerQueJeSuisEnConformiteAvecLaReglementationCasl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html",
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeEnvoyerDesEMailsConformesADmarc = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUnUtilisateur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierMonMotDePasse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLeNombreDePersonnesAyantAccesAMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerEtUtiliserLesChampsDeConsentement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailProfessionnelSurMonDomainePersonnalise = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPreparerMonCompteAuRgpd = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRecueillirLeConsentementDesContactsNouveauxEtExistants = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLAuthentificationADeuxFacteursDansGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMesListesSontEnSimpleInscriptionSingleOptInLeDoubleInscriptionEstIlNecessairePourEtreConformeAuRgpd = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html",
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsSontLesNavigateursCompatiblesAvecGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignifieLaVerificationDeMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignifieLeStatutDeMonDomaineDEnvoi = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneListeBaseeSurLAutorisation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneCampagneDeReconfirmation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneFonctionDAppareilApprouveEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueGetresponseEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"en": "https://www.getresponse.com/help/what-is-spam.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsRolesPuisJeAttribuerAuxUtilisateurs = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareFaqNelleChatDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-faq-nelle-chat-di-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-faq-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-create-faq-in-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-faq-w-czatach-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-faq-dans-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-faq-v-chatah-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstaloNuevosFormulariosYVentanasEmergentesEnUnaLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntrePerfectTimingYTimeTravel = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsTimeTravelYComoFunciona = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeInstallareNuoviModuliEPopupSuUnaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToInstallNewFormsAndPopupsOnALandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-nuovi-moduli-e-popup-su-una-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-nuevos-formularios-y-ventanas-emergentes-en-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-nowe-formularze-i-pop-upy-na-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-de-nouveaux-formulaires-et-pop-ups-sur-une-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-neue-formulare-und-popups-auf-meiner-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-novos-formularios-e-pop-ups-em-uma-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-novye-formy-i-vsplyvajushhie-okna-na-lending.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasBedeutetDerStatusMeinerSendendenDomain = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieUnterscheidetSichPerfectTimingVonTimeTravel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstTimeTravelUndWieFunktioniertSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchContactForm7MithilfeEinerIntegrationMitWordpress = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearFaqEnGetresponseChats = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-faq-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-create-faq-in-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-faq-w-czatach-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-faq-dans-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-faq-nelle-chat-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-faq-v-chatah-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateFaqInGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-faq-in-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-faq-en-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-faq-w-czatach-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-faq-dans-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-faq-nelle-chat-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-perguntas-frequentes-nos-chats-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-faq-v-chatah-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtribuirMeuProprioSubdominioAUmaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzypisacWlasnaSubdomeneDoLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzypisacWlasnaDomeneDoLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeTrouverEtModifierLesParametresDeMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuConsulterLesNewslettersCreeesParUnDesigner = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiNePuisJePasMeConnecter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiLaConformiteJuridiqueEstElleImportante = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-la-conformite-juridique-est-elle-importante.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiLeBadgeGetresponseSAfficheTIlDansMesEMails = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtribuirMeuProprioDominioAUmaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycStroneLandingPageKorzystajacZInteligentnegoKreatora = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntrePerfectTimingEtTimeTravel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentResilierUnCompteGratuit = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGetresponseProposeTIlUnEssaiGratuit = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLaFileDAttenteDesContactsEtCommentFonctionneTElle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaTarificationDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeMettreAJourMonCompteFree = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelEstLeCoutMensuelDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsModesDePaiementAcceptezVous = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierMonPlanDAbonnement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneNouvelleCarteDeCreditPourLesPaiements = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTelechargerMesFactures = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html",
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeSupprimerUneCarteDeCreditDeMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeModifierMonModeDePaiement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSePasseTIlSiJeDepasseLaTailleDeMaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeCorrigerUneErreurDeTransactionSurMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJAiAnnuleMonAbonnementPourquoiSuisJeEncoreFacture = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html",
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLaConfirmationDePaiementSupplementaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentResilierMonCompte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaPolitiqueDeRemboursementDeGetresponse = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycStroneLandingPageUzywajacSzablonow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitFonovoeIzobrazhenieVLobbiIVebinarnujuKomnatu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacFormularzZapisuNaWebinarDoStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeRechauffementDesAdressesIp = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html",
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacWordpressZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSignificaLoStatoDelMioDominioDiInvio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAssegnareIlMioDominioAllaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAssegnareIlMioSottodominioAllaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnaLandingPageUtilizzandoIModelli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnaLandingPageUtilizzandoIa = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVyzvatVsplyvajushheeOknoNaOsnoveProsmotraIliPonravivshegosyaTovara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatDoesMySendingDomainStatusMean = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-does-my-sending-domain-status-mean.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-lo-stato-del-mio-dominio-di-invio.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-el-estado-de-mi-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-le-statut-de-mon-domaine-d-envoi.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-der-status-meiner-sendenden-domain.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-status-mojej-domeny-wysylkowej.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-o-estado-do-meu-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachaet-status-moego-otpravlyajushhego-domena.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymPerfectTimingRozniSieOdTimeTravel = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestOpcjaTimeTravelIJakDziala = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeTimeTravelEtCommentFonctionneTIl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAsignarMiPropioSubdominioAMiLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnaLandingPageConElAsistenteIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnaLandingPageUtilizandoPlantillas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAsignarMiPropioDominioAMiLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAssignMyOwnSubdomainToTheLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-assign-my-own-subdomain-to-the-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-subdominio-a-mi-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-sottodominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-subdomene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-subdominio-a-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-subdomen-svoemu-lendingu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-landing-page-eine-eigene-subdomain-zuweisen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAssignMyOwnDomainToTheLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-assign-my-own-domain-to-the-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asignar-mi-propio-dominio-a-mi-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-il-mio-dominio-alla-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-do-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-a-uma-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-der-landing-page-meine-eigene-domain-zu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-domen-svoemu-lendingu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateALandingPageUsingAiWizard = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-ai-wizard.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-con-el-asistente-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-korzystajac-z-inteligentnego-kreatora.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landingpage-mit-dem-ki-assistenten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-utilizando-o-criador-orientado-pela-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-konstruktora-ii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateALandingPageUsingTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-landing-page-using-templates.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-landing-page-utilizando-plantillas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page-utilizzando-i-modelli.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-landing-page-uzywajac-szablonow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-landing-page-mithilfe-von-vorlagen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-destino-usando-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-s-pomoshhju-shablonov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInCheModoPerfectTimingEDiversoDalTimeTravel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosETimeTravelEComeFunziona = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereUnModuloWebinarAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOModoEscuroEComoUtilizaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-modo-oscuro-y-como-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-dark-mode-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tryb-ciemny-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-modalita-scura-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-dunkelmodus-und-wie-wird-er-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-tjomnaya-tema-i-kak-ejo-ispolzovat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnFormularioDeWebinarAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNajtiKontaktyNaOsnoveIhVebinarnojAktivnosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRazvivatSvojBiznesSPomoshhjuFunkcijElektronnojKommerciiGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowIsPerfectTimingDifferentFromTimeTravel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-is-perfect-timing-different-from-time-travel.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-perfect-timing-e-diverso-dal-time-travel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-perfect-timing-rozni-sie-od-time-travel.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-perfect-timing-et-time-travel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheidet-sich-perfect-timing-von-time-travel.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-perfect-timing-y-time-travel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-perfect-timing-otlichaetsya-ot-ucheta-chasovyh-poyasov.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-perfect-timing-e-time-travel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTimeTravelAndHowDoesItWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-time-travel-and-how-does-it-work.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-time-travel-e-come-funziona.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-time-travel-et-comment-fonctionne-t-il.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-time-travel-i-jak-dziala.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-time-travel-und-wie-funktioniert-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-time-travel-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-uchet-chasovyh-poyasov-kak-eto-rabotaet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-viagem-no-tempo-e-como-ela-funciona.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchWoocommerceMitGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchWordpressMitGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchDerLobbyUndDemWebinarraumEinHintergrundbildHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRegagnezLeurInteret = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html",
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"en": "https://www.getresponse.com/help/win-back.html",
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiLeCheminNegatifNEstIlPasDeclenchePourMaConditionDeDepart = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiNePuisJeVoirQueLesMessagesSelectionnesDansLesConditionsMessageOuvertEtLienClique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsSontLesModelesDeFluxDeTravailDisponiblesDansMonForfait = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsParametresDInscriptionPuisJeInclureDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueCouvrentLesFonctionsDECommerceEtDeSuiviDesEvenements = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLAutomatisationDuMarketingEtQuePuisJeFaireAvec = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnFluxDAutomatisation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSePasseTIlLorsqueJeDepublieUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesDeBienvenue = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesDeWebinairesEtDEvenements = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesDePromotions = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesPostAchat = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesDeCoursEnLigne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignifientLesChiffresEnHautEnBasEtDansLeCoinSuperieurDroitDeChaqueElementDeMonFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesDeQualificationDesProspects = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesDEngagementEtDeRetention = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAQuoiServentLesModelesDePanierAbandonne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesActionsLesConditionsEtLesFiltres = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html",
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAccueilDesNouveauxClients = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromotionDeWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru.html",
"en": "https://www.getresponse.com/help/webinar-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/webinar-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenije-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentesIncitatives = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ventes-incitatives.html",
"en": "https://www.getresponse.com/help/upselling.html",
"pl": "https://www.getresponse.pl/pomoc/upselling.html",
"de": "https://www.getresponse.com/de/hilfe/upselling.html",
"es": "https://www.getresponse.com/es/ayuda/ventas-adicionales-upselling.html",
"ru": "https://www.getresponse.com/ru/help-ru/doprodazha.html",
"it": "https://www.getresponse.com/it/assistenza/upselling.html",
"br": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRappelAvecOffreADureeLimitee = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html",
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostThanksgiving = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/thanksgiving.html",
"de": "https://www.getresponse.com/de/hilfe/thanksgiving.html",
"en": "https://www.getresponse.com/help/thanksgiving.html",
"it": "https://www.getresponse.com/it/assistenza/ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/swieto-dziekczynienia.html",
"ru": "https://www.getresponse.com/ru/help-ru/den-blagodareniya.html",
"es": "https://www.getresponse.com/es/ayuda/accion-de-gracias.html",
"br": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"pt": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuiviPostAchatCible = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html",
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTagsDesContactsDAffilies = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessageDeBienvenueSimple = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html",
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostScoreDesContactsDAffilie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html",
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecompensezLesContactsFideles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html",
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProduitsRecommandes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/produits-recommandes.html",
"de": "https://www.getresponse.com/de/hilfe/top-produkte-empfehlen.html",
"en": "https://www.getresponse.com/help/recommend-top-products.html",
"it": "https://www.getresponse.com/it/assistenza/prodotti-consigliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-popularnych-produktow.html",
"ru": "https://www.getresponse.com/ru/help-ru/rekomendatsiya-populyarnyih-produktov.html",
"es": "https://www.getresponse.com/es/ayuda/recomendar-los-mejores-productos.html",
"br": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"pt": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuiviPostWebinaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuiviPostAchat = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEntretienDeListeAvecTagsEtScores = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html",
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJeNePeuxPasPublierMonFluxDeTravailQueDoisJeFaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html",
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLActionEnvoyerUneNotificationPush = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerDesEvenements = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnMessageDAutomatisation = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelEstLeCoutDeLAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLeFiltreRepartition = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaConditionOccasionSpeciale = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLeFiltreIntervalle = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaConditionMessageEnvoye = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLeFiltreListes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaConditionLienClique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLeFiltreDeSegmentDynamique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaConditionChampPersonnaliseModifie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaConditionStatutDeConsentementMisAJour = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLeFiltreNombre = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaDuplicationDesFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneLaConditionStatutDePaiementModifie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html",
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionneUnMessageDAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFonctionnentLesConnecteursOuiNon = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTravaillerAvecDesElementsDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesParametresDeTempsDansLesProprietesDesConditions = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLActionAttendre = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaConditionPageDeDestinationVisiteeDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLeFiltreStatutDeConsentement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesTagsPourSavoirQuiACommenceOuTermineMesFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesChampsPersonnalisesDansLAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentSuivreLOuvertureDesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCopierDesContactsVersUneAutreListeDansLAutomatisationDuMarketing = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterLeCodeJavascriptDeSuiviAMonSiteWeb = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLaConditionTagAttribueDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeUtiliserLActionTagDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeUtiliserLActionScore = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeUtiliserLaConditionDeScoreDonneDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeUtiliserLaConditionEvenementDansMesFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeInclureLesArticlesDuPanierAbandonneDansUnMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-inclure-les-articles-du-panier-abandonne-dans-un-message.html",
"en": "https://www.getresponse.com/help/how-can-i-include-abandoned-cart-items-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incluir-articulos-de-carrito-abandonado-en-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-v-soobshenie-predmety-iz-broshennoj-korziny.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-includere-gli-articoli-del-carrello-abbandonato-in-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zawrzec-produkty-z-porzuconego-koszyka-w-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromotionReserveeAuxPlusRapides = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDeClients = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversionDesContactsEnClients = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html",
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDesContacts = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessageDeBienvenueBaseSurLeConsentement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-base-sur-le-consentement.html",
"en": "https://www.getresponse.com/help/consent-based-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-powitalna-zalezna-od-udzielonych-zgod.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-basato-su-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-willkommensnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-na-osnove-soglasija.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakEdytowacUstawieniaSubskrypcji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConfirmationDAchatBaseeSurLeConsentement = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParticipationDeNoel = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html",
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeLancerUnFluxDeTravailAvecPlusieursConditions = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRelierDesModelesEntreEux = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeModifierUnMessageQueJUtiliseDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVenteFlashBlackFriday = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/vente-flash-black-friday.html",
"en": "https://www.getresponse.com/help/black-friday-flash-sale.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-flash-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-a-tempo-limitato-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta-z-okazji-black-friday.html",
"es": "https://www.getresponse.com/es/ayuda/venta-relampago-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/flash-rasprodaja-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromotionBlackFridayReserveeAuxPlusRapides = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/promotion-black-friday-reservee-aux-plus-rapides.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-early-bird-sale.html",
"en": "https://www.getresponse.com/help/black-friday-early-bird-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-early-bird-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/wczesna-sprzedaz-z-okazji-czarnego-piatku.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-pervyim-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessageDAnniversaire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"en": "https://www.getresponse.com/help/birthday-message.html",
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSystemeDeScoresBasique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessageDeBienvenueDAffilie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromotionDeWebinaireDAffilie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoursEnLigneDAffilie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSystemeDeTagsBasique = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentesCroiseesDAffilie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ventes-croisees-d-affilie.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-cross-selling.html",
"en": "https://www.getresponse.com/help/affiliate-cross-selling.html",
"it": "https://www.getresponse.com/it/assistenza/cross-selling-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/cross-selling-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/venta-cruzada-de-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kross-prodazha-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeriePourLesPaniersAbandonnes = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html",
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRappelDePanierAbandonne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnTextePredefini = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiLesMessagesEnvoyesAMonPropreDomaineRebondissentIls = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentFiltrerLesContactsEnDoubleDansUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakProdvigatTovaryNaMoemSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-sajte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-promuovere-i-prodotti-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-produkte-auf-meiner-website-bewerben.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDefinirLesProprietesDeLaConditionUrlVisitee = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDefinirLesProprietesDeLaConditionAchatEffectue = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDefinirLesProprietesDeLaConditionPanierAbandonne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDeplacerOuCopierDesContactsEntreLesFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentDefinirLHeureDeDebutEtDeFinDeMonFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeSupprimerDesContactsALaSuiteDUnFluxDeTravail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeApporterDesModificationsAUnFluxDeTravailQuiADejaEtePublie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeAjouterAUnFluxDeTravailDesContactsQuiOntEteCopiesOuDeplacesVersUneAutreListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiNeDevraisJePasUtiliserYahooAolMailRuCommeMonAdresseDeLExpediteur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiNeDevraisJePasAjouterANouveauLesRebondsAMaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiMesEMailsAboutissentIlsParfoisDansUnDossierSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiLesFaiReconnaissentIlsDifferemmentLesMessagesCommeEtantDesSpams = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiCertainsEMailsPeuventIlsSAfficherAvecLaMentionAuNomDeOuVia = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeVoirLesMessagesLivres = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeVoirLesResultatsDeMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeTrouverLaFonctionTestsAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelsTypesDeTestsABPuisJeChoisir = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaDifferenceEntreUnMessageEnTexteBrutEtUnMessageEnHtml = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLePerfectTimingEtCommentFonctionneTIl = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLaVueMondialeEtCommentLUtiliser = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueDmarc = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html",
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeTauxDeClicsParRapportAuNombreDOuverturesCtor = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLAppleMailPrivacyProtectionEtCommentAffecteTElleNotreDelivrabilite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html",
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnTestAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesFonctionnalitesDisponiblesDansLeCreateurDEMails = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignifieUneErreurDeSyntaxeDansMonMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesExigencesEnMatiereDePiedDePage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesMeilleuresPratiquesEnMatiereDEmailMarketingQuiInfluencentLaLivraison = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesRaccourcisClavierDansLeNouvelEditeur = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeMemeContactFigureSurPlusieursListesRecevraTIlMonMessagePlusieursFois = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleDoitEtreLaLargeurDeMonMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-doit-etre-la-largeur-de-mon-message.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-szerokosc-powinna-miec-moja-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-wide-should-my-message-be.html",
"de": "https://www.getresponse.com/de/hilfe/welche-breite-sollte-meine-nachricht-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/naskolko-shirokim-mozhet-byt-moje-soobshenie.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-dovrebbe-essere-largo-il-mio-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-ancho-debe-ser-mi-mensaje.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesWebfontsDansLeMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLeSuiviECommerceDansUnMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserUnCodePersonnaliseDansUnMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLAdressePostaleDansLePiedDePageDuMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEviterDEnvoyerDesMessagesDeContenuDeFaibleQualite = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneImageDArrierePlanAUnMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCombienDeDestinatairesDoisJeInclureDansMonGroupeDeTest = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCombienDeMessagesPuisJeEnvoyerAvecMonCompteFree = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html",
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentLeMessageLePlusPerformantEstIlEnvoye = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuelleEstLaQualiteDeLaDelivrabiliteDeVosMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserMesBlocsDansLeNouvelEditeurDeMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentIntegrerLesMediasSociauxDansMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentExclureDesContactsDeLaReceptionDeMaNewsletter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentChoisirLesDestinatairesDeLaNewsletter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterDesPiecesJointesAMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeTelechargerMonPropreModeleDeMessageHtml = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPublierDesMisesAJourTwitterEtFacebookLorsqueJEnvoieUneNewsletter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html",
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeCreerDesMessagesDontLeTexteEstOrienteDeDroiteAGauche = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentAjouterUneVideoAMonMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJeAjouterUnCodePromoAMesEMails = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUnContactPeutIlSeDesinscrireDeMaListe = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLaReputationDuChampDeInfluenceTElleLaDelivrabiliteDansLaBoiteDeReceptionOuDansLeDossierSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMesNewslettersPeuventEllesEtreConsulteesEnLigne = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeTesterDifferentesPartiesDuMessageDansUnMemeTestAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeProgrammerMesTestsAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSauvegarderUnTestAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeSupprimerOuModifierLesLiensDeDesabonnementEtDeMiseAJourFigurantAuBasDeMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeIntegrerUneImageOuUnBoutonDansUnBlocDeTexte = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-une-image-ou-un-bouton-dans-un-bloc-de-texte.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-bild-oder-einen-button-in-ein-textelement-einfuegen.html",
"en": "https://www.getresponse.com/help/can-i-embed-an-image-or-button-within-a-text-block.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vstavit-izobrazhenije-ili-knopku-tekstovy-blok.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-obrazek-lub-przycisk-w-bloku-tekstowym.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-incrustar-una-imagen-o-un-boton-dentro-de-un-bloque-de-texto.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"it": "https://www.getresponse.com/it/assistenza/posso-incorporare-un-immagine-o-un-pulsante-all-interno-di-un-blocco-di-testo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeCreerDesNewslettersAdapteesAuxMobiles = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeCoderDesNewslettersEnHtml = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuisJeVerifierMonMessageDansUneBoiteDeReceptionAvantDeLEnvoyer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html",
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnRssToEmail = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnEMailQuiSoitToujoursAgreableALire = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentModifierLAdresseDeDansMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-de-dans-mes-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-absender-e-mail-adresse-in-meinen-nachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-adres-otpravitela-v-moich-soobshenijach.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-email-nadawcy-w-wiadomosciach.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-direccion-de-en-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-l-indirizzo-di-mittente-nei-miei-messaggi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRetrouverLesEnTetesDeMesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html",
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuellesSontLesNouveautesDuNouvelEditeurDeMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddAWebinarFormToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-webinar-form-to-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-na-webinar-do-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-formulario-de-webinar-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-modulo-webinar-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formu-vebinara-na-svoj-sajt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-ein-webinar-formular-hinzu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-formulario-de-webinar-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-webinaire-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOuPuisJeVoirLesResultatsDeMesTestsAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUneListeAssociee = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPourquoiMesContactsNOntIlsPasRecuMaNewsletter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQueLeDkimEtCommentLeConfigurer = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesSectionsDansLEditeurDeMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentTrouverMesModelesEtTravaillerAvecEux = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentRenvoyerUnMessageADesPersonnesQuiNeLOntPasOuvert = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPuisJePersonnaliserMesEMails = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuEstCeQuUnePlainteDeSpam = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html",
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerUnTestAB = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentEnvoyerDesRecommandationsDeProduitsAuxClients = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html",
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSontLesRebonds = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentPromouvoirVosProduitsDansLesMessages = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentUtiliserLesBlocsDeBasePourCreerUnMessage = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCommentCreerEtEnvoyerUneNewsletter = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoiSpiskiImejutOdinarnoeSoglasieTrebuetsyaLiDvojnoeSoglasiePodpiskiDlyaSobljudeniyaGdpr = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatGetresponseSClickbank = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZagruzitSobstvennyjShablonHtmlSoobshheniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatFormuContactForm7SPomoshhjuIntegraciiSWordpress = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatWoocommerceSGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSaWymaganiaTechniczneAbyKorzystacZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacGrafikeTlaDoPoczekalniIPokojuWebinaru = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMoznaWysylacNewsletteryNaPodstawieReakcjiSubskrybentowNaPoprzednieWiadomosciPrzyUzyciuAplikacjiMobilnejGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycContactForm7ZWykorzystaniemIntegracjiZWordpress = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacWoocommerceZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoModificarLosAjustesConsentimientoConfirmado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoModificareLeImpostazioniDiOptIn = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakovyTehnicheskieTrebovaniyaDlyaIspolzovaniyaGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIEditOptInSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-edit-opt-in-settings.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-le-impostazioni-di-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-los-ajustes-consentimiento-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bearbeite-ich-opt-in-einstellungen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-editar-as-configuracoes-de-assinatura.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-podpiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereUnImmagineDiSfondoAllaSalaDAttesaEAlWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoRastrearLasAperturasDeSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromotionDeCoursDAffilie = (locale) => {
    const alternates = {
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnaImagenDeFondoAlLobbyYLaSalaDelWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnEmailDeCarritoAbandonadoParaShopify = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMoverArquivosEmArquivosEImagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoActivarMiFlujoDeTrabajoDeAutomatizacionAlEnviarUnSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarWoocommerceConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddABackgroundImageToTheLobbyAndWebinarRoom = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-background-image-to-the-lobby-and-webinar-room.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-imagen-de-fondo-al-lobby-y-la-sala-del-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-immagine-di-sfondo-alla-sala-d-attesa-e-al-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-tla-do-poczekalni-i-pokoju-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-der-lobby-und-dem-webinarraum-ein-hintergrundbild-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoe-izobrazhenie-v-lobbi-i-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-ao-lobby-e-a-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-la-salle-d-attente-et-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearContactForm7MedianteUnaIntegracionConWordpress = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLosRequisitosTecnicosParaUtilizarGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieGestalteIchNachrichtenInOutlookGut = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-gestalte-ich-nachrichten-in-outlook-gut.html",
"it": "https://www.getresponse.com/it/assistenza/come-fare-in-modo-che-i-messaggi-abbiano-un-bell-aspetto-in-outlook.html",
"en": "https://www.getresponse.com/help/how-to-make-messages-look-good-in-outlook.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-que-los-mensajes-se-vean-bien-en-outlook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-tak-chtoby-pisma-horosho-vyglyadeli-v-outlook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-wiadomosci-wygladaly-dobrze-w-outlooku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenNewsletterMitKiEMailGenerator = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter-mit-ki-e-mail-generator.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-newsletter-utilizzando-ai-email-generator.html",
"en": "https://www.getresponse.com/help/how-to-create-a-newsletter-using-ai-email-generator.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-newsletter-con-el-generador-de-emails-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter-korzystajac-z-kreatora-maili-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheTechnischenVoraussetzungenSindFuerDieNutzungVonGetresponseErforderlich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFazerComQueAsMensagensTenhamUmaBoaAparenciaNoOutlook = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"it": "https://www.getresponse.com/it/assistenza/come-fare-in-modo-che-i-messaggi-abbiano-un-bell-aspetto-in-outlook.html",
"en": "https://www.getresponse.com/help/how-to-make-messages-look-good-in-outlook.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-que-los-mensajes-se-vean-bien-en-outlook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-tak-chtoby-pisma-horosho-vyglyadeli-v-outlook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-wiadomosci-wygladaly-dobrze-w-outlooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gestalte-ich-nachrichten-in-outlook-gut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareContactForm7UtilizzandoUnIntegrazioneConWordpress = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeIntegrareWordpressConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoIRequisitiTecniciPerUtilizzareGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeIntegrareWoocommerceConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatWordpressSGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheTechnicalRequirementsToUseGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-to-use-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-per-utilizzare-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-technischen-voraussetzungen-sind-fuer-die-nutzung-von-getresponse-erforderlich.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-para-utilizar-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskie-trebovaniya-dlya-ispolzovaniya-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-techniczne-aby-korzystac-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-pour-utiliser-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-para-usar-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarWordpressConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOWebConnectEComoUsaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateContactForm7UsingAnIntegrationWithWordpress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-contact-form-7-using-an-integration-with-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-contact-form-7-utilizzando-un-integrazione-con-wordpress.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-contact-form-7-mediante-una-integracion-con-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-contact-form-7-z-wykorzystaniem-integracji-z-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-contact-form-7-s-pomoshhju-integracii-s-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-contact-form-7-mithilfe-einer-integration-mit-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-contact-form-7-a-l-aide-de-l-integration-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-contact-form-7-usando-uma-integracao-com-o-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateWoocommerceWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-woocommerce-with-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-woocommerce-con-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-woocommerce-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-woocommerce-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-woocommerce-s-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-woocommerce-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-woocommerce-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-woocommerce-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateWordpressWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-wordpress-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-wordpress-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-wordpress-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-wordpress-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-wordpress-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-wordpress-mit-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-wordpress-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-wordpress-com-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarCodigosPromocionais = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaNewsletterUsandoOGeradorDeEMailsComIa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-newsletter-utilizzando-ai-email-generator.html",
"en": "https://www.getresponse.com/help/how-to-create-a-newsletter-using-ai-email-generator.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-newsletter-con-el-generador-de-emails-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter-korzystajac-z-kreatora-maili-ai.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter-mit-ki-e-mail-generator.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnaNewsletterUtilizzandoAiEmailGenerator = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-newsletter-utilizzando-ai-email-generator.html",
"en": "https://www.getresponse.com/help/how-to-create-a-newsletter-using-ai-email-generator.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-newsletter-con-el-generador-de-emails-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter-korzystajac-z-kreatora-maili-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter-mit-ki-e-mail-generator.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSincronizarMinhaAudienciaDeAnunciosComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnNewsletterConElGeneradorDeEmailsIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-newsletter-con-el-generador-de-emails-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-newsletter-utilizzando-ai-email-generator.html",
"en": "https://www.getresponse.com/help/how-to-create-a-newsletter-using-ai-email-generator.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter-korzystajac-z-kreatora-maili-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter-mit-ki-e-mail-generator.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSprawicAbyWiadomosciWygladalyDobrzeWOutlooku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-wiadomosci-wygladaly-dobrze-w-outlooku.html",
"it": "https://www.getresponse.com/it/assistenza/come-fare-in-modo-che-i-messaggi-abbiano-un-bell-aspetto-in-outlook.html",
"en": "https://www.getresponse.com/help/how-to-make-messages-look-good-in-outlook.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-que-los-mensajes-se-vean-bien-en-outlook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-tak-chtoby-pisma-horosho-vyglyadeli-v-outlook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gestalte-ich-nachrichten-in-outlook-gut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycNewsletterKorzystajacZKreatoraMailiAi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter-korzystajac-z-kreatora-maili-ai.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-newsletter-utilizzando-ai-email-generator.html",
"en": "https://www.getresponse.com/help/how-to-create-a-newsletter-using-ai-email-generator.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-newsletter-con-el-generador-de-emails-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter-mit-ki-e-mail-generator.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeTjomnayaTemaIKakEjoIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-tjomnaya-tema-i-kak-ejo-ispolzovat.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-modo-oscuro-y-como-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-dark-mode-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tryb-ciemny-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-modalita-scura-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-dunkelmodus-und-wie-wird-er-verwendet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOGeradorDeLinhasDeAssuntoIaEComoUsaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-generador-de-lineas-de-asunto-ai-y-como-puedo-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-ai-subject-line-generator-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tematow-ai-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-generatore-di-righe-dell-oggetto-ai-e-come-posso-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-betreffzeilengenerator-und-wie-kann-ich-ihn-verwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAcionarUmPopUpComBaseNaVisualizacaoDeUmProdutoOuEmCurtidas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestTrybCiemnyIJakGoUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tryb-ciemny-i-jak-go-uzywac.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-modo-oscuro-y-como-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-dark-mode-and-how-to-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-modalita-scura-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-dunkelmodus-und-wie-wird-er-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-tjomnaya-tema-i-kak-ejo-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSdelatTakChtobyPismaHoroshoVyglyadeliVOutlook = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-tak-chtoby-pisma-horosho-vyglyadeli-v-outlook.html",
"it": "https://www.getresponse.com/it/assistenza/come-fare-in-modo-che-i-messaggi-abbiano-un-bell-aspetto-in-outlook.html",
"en": "https://www.getresponse.com/help/how-to-make-messages-look-good-in-outlook.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-que-los-mensajes-se-vean-bien-en-outlook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-wiadomosci-wygladaly-dobrze-w-outlooku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gestalte-ich-nachrichten-in-outlook-gut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoHacerQueLosMensajesSeVeanBienEnOutlook = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-hacer-que-los-mensajes-se-vean-bien-en-outlook.html",
"it": "https://www.getresponse.com/it/assistenza/come-fare-in-modo-che-i-messaggi-abbiano-un-bell-aspetto-in-outlook.html",
"en": "https://www.getresponse.com/help/how-to-make-messages-look-good-in-outlook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-tak-chtoby-pisma-horosho-vyglyadeli-v-outlook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-wiadomosci-wygladaly-dobrze-w-outlooku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gestalte-ich-nachrichten-in-outlook-gut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFareInModoCheIMessaggiAbbianoUnBellAspettoInOutlook = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-fare-in-modo-che-i-messaggi-abbiano-un-bell-aspetto-in-outlook.html",
"en": "https://www.getresponse.com/help/how-to-make-messages-look-good-in-outlook.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-que-los-mensajes-se-vean-bien-en-outlook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-tak-chtoby-pisma-horosho-vyglyadeli-v-outlook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-wiadomosci-wygladaly-dobrze-w-outlooku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gestalte-ich-nachrichten-in-outlook-gut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToMakeMessagesLookGoodInOutlook = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-make-messages-look-good-in-outlook.html",
"it": "https://www.getresponse.com/it/assistenza/come-fare-in-modo-che-i-messaggi-abbiano-un-bell-aspetto-in-outlook.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-que-los-mensajes-se-vean-bien-en-outlook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-tak-chtoby-pisma-horosho-vyglyadeli-v-outlook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-wiadomosci-wygladaly-dobrze-w-outlooku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-fazer-com-que-as-mensagens-tenham-uma-boa-aparencia-no-outlook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gestalte-ich-nachrichten-in-outlook-gut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerDunkelmodusUndWieWirdErVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-dunkelmodus-und-wie-wird-er-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-modo-oscuro-y-como-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-dark-mode-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tryb-ciemny-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-modalita-scura-e-come-si-usa.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-tjomnaya-tema-i-kak-ejo-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToGenerateAdContentUsingAiAssistantInPaidAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-generate-ad-content-using-ai-assistant-in-paid-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-generer-du-contenu-publicitaire-a-l-aide-de-l-assistant-ia-dans-les-publicites-payantes.html",
"es": "https://www.getresponse.com/es/ayuda/como-generar-contenido-publicitario-utilizando-el-asistente-ia-en-anuncios-de-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-generare-contenuti-pubblicitari-utilizzando-ai-assistant-negli-annunci-a-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wygenerowac-tresc-reklamy-za-pomoca-asystenta-ai-w-platnych-reklamach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-generiere-ich-anzeigeninhalte-mit-dem-ki-assistenten-in-paid-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosELaModalitaScuraEComeSiUsa = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-modalita-scura-e-come-si-usa.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-modo-oscuro-y-como-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-dark-mode-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tryb-ciemny-i-jak-go-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-dunkelmodus-und-wie-wird-er-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-tjomnaya-tema-i-kak-ejo-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaZdarzeniaIJakZNichKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zdarzenia-i-jak-z-nich-korzystac.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-gli-eventi-e-come-utilizzarli.html",
"en": "https://www.getresponse.com/help/what-are-events-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-events-und-wie-verwendet-man-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-eventos-y-como-utilizarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-sobytiya-i-kak-ih-ispolzovat.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-evenements-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElModoOscuroYComoUtilizarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-modo-oscuro-y-como-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-dark-mode-and-how-to-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tryb-ciemny-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-modalita-scura-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-dunkelmodus-und-wie-wird-er-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-tjomnaya-tema-i-kak-ejo-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsDarkModeAndHowToUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-dark-mode-and-how-to-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-modo-oscuro-y-como-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tryb-ciemny-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-modalita-scura-e-come-si-usa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-dunkelmodus-und-wie-wird-er-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-tjomnaya-tema-i-kak-ejo-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-modo-escuro-e-como-utiliza-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoActivarUnaVentanaEmergenteEnFuncionDeLaVistaDeUnProducto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAttivareUnPopupBasatoSullaVisualizzazioneOMiPiaceDelProdotto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateANewsletterUsingAiEmailGenerator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-newsletter-using-ai-email-generator.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-newsletter-utilizzando-ai-email-generator.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-newsletter-con-el-generador-de-emails-ia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter-korzystajac-z-kreatora-maili-ai.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter-usando-o-gerador-de-e-mails-com-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter-mit-ki-e-mail-generator.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPromocionarProductosEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-promuovere-i-prodotti-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-produkte-auf-meiner-website-bewerben.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-sajte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEncontrarContactosEnFuncionDeSuActividadEnLosWebinars = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosEventosYComoUtilizarlos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-eventos-y-como-utilizarlos.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-gli-eventi-e-come-utilizzarli.html",
"en": "https://www.getresponse.com/help/what-are-events-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-events-und-wie-verwendet-man-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zdarzenia-i-jak-z-nich-korzystac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-sobytiya-i-kak-ih-ispolzovat.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-evenements-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLoestManEinPopupBasierendAufDerProduktansichtAus = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWywolacPopUpPoWyswietleniuProduktu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWyszukacKontaktyNaPodstawieIchAktywnosciWWebinarach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerKiBetreffzeilengeneratorUndWieKannIchIhnVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-betreffzeilengenerator-und-wie-kann-ich-ihn-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-generador-de-lineas-de-asunto-ai-y-como-puedo-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-ai-subject-line-generator-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tematow-ai-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-generatore-di-righe-dell-oggetto-ai-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchProdukteAufMeinerWebsiteBewerben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-produkte-auf-meiner-website-bewerben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-promuovere-i-prodotti-sul-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-sajte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestGeneratorTematowAiIJakGoUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tematow-ai-i-jak-go-uzywac.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-generador-de-lineas-de-asunto-ai-y-como-puedo-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-ai-subject-line-generator-and-how-can-i-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-generatore-di-righe-dell-oggetto-ai-e-come-posso-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-betreffzeilengenerator-und-wie-kann-ich-ihn-verwenden.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFindeIchKontakteBasierendAufIhrerWebinarAktivitaet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindEventsUndWieVerwendetManSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-events-und-wie-verwendet-man-sie.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-gli-eventi-e-come-utilizzarli.html",
"en": "https://www.getresponse.com/help/what-are-events-and-how-to-use-them.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zdarzenia-i-jak-z-nich-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-eventos-y-como-utilizarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-sobytiya-i-kak-ih-ispolzovat.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-evenements-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEIlGeneratoreDiRigheDellOggettoAiEComePossoUsarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-generatore-di-righe-dell-oggetto-ai-e-come-posso-usarlo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-generador-de-lineas-de-asunto-ai-y-como-puedo-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-ai-subject-line-generator-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tematow-ai-i-jak-go-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-betreffzeilengenerator-und-wie-kann-ich-ihn-verwenden.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPromowacProduktyNaMojejStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-promuovere-i-prodotti-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-produkte-auf-meiner-website-bewerben.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-sajte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAPromuovereIProdottiSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-promuovere-i-prodotti-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-mojej-stronie-internetowej.html",
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-produkte-auf-meiner-website-bewerben.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-sajte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRastrearAberturasDeSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioATrovareIContattiInBaseAllaLoroAttivitaWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoGliEventiEComeUtilizzarli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-gli-eventi-e-come-utilizzarli.html",
"en": "https://www.getresponse.com/help/what-are-events-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-events-und-wie-verwendet-man-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zdarzenia-i-jak-z-nich-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-eventos-y-como-utilizarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-sobytiya-i-kak-ih-ispolzovat.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-evenements-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToFindContactsBasedOnTheirWebinarActivity = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-find-contacts-based-on-their-webinar-activity.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trovare-i-contatti-in-base-alla-loro-attivita-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-kontakte-basierend-auf-ihrer-webinar-aktivitaet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyszukac-kontakty-na-podstawie-ich-aktywnosci-w-webinarach.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-contactos-en-funcion-de-su-actividad-en-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-na-osnove-ih-vebinarnoj-aktivnosti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-contatos-com-base-na-atividade-de-webinario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-en-fonction-de-leur-activite-sur-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElGeneradorDeLineasDeAsuntoAiYComoPuedoUtilizarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-generador-de-lineas-de-asunto-ai-y-como-puedo-utilizarlo.html",
"en": "https://www.getresponse.com/help/what-is-ai-subject-line-generator-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tematow-ai-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-generatore-di-righe-dell-oggetto-ai-e-come-posso-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-betreffzeilengenerator-und-wie-kann-ich-ihn-verwenden.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToPromoteProductsOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-promote-products-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-promuovere-i-prodotti-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-produkte-auf-meiner-website-bewerben.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-productos-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-tovary-na-moem-sajte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-des-produits-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTriggerAPopupBasedOnProductViewOrLike = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-trigger-a-popup-based-on-product-view-or-like.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wywolac-pop-up-po-wyswietleniu-produktu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loest-man-ein-popup-basierend-auf-der-produktansicht-aus.html",
"it": "https://www.getresponse.com/it/assistenza/come-attivare-un-popup-basato-sulla-visualizzazione-o-mi-piace-del-prodotto.html",
"es": "https://www.getresponse.com/es/ayuda/como-activar-una-ventana-emergente-en-funcion-de-la-vista-de-un-producto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acionar-um-pop-up-com-base-na-visualizacao-de-um-produto-ou-em-curtidas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyzvat-vsplyvajushhee-okno-na-osnove-prosmotra-ili-ponravivshegosya-tovara.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-un-pop-up-base-sur-la-vue-ou-le-like-d-un-produit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreEventsAndHowToUseThem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-events-and-how-to-use-them.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-gli-eventi-e-come-utilizzarli.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-events-und-wie-verwendet-man-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zdarzenia-i-jak-z-nich-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-eventos-y-como-utilizarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-sobytiya-i-kak-ih-ispolzovat.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-evenements-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoVerificarUnDominioEnFacebookBusinessManagerEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html",
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoIWebinarGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAiSubjectLineGeneratorAndHowCanIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-ai-subject-line-generator-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-generador-de-lineas-de-asunto-ai-y-como-puedo-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-generator-tematow-ai-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-generatore-di-righe-dell-oggetto-ai-e-come-posso-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-ki-betreffzeilengenerator-und-wie-kann-ich-ihn-verwenden.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-gerador-de-linhas-de-assunto-ia-e-como-usa-lo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaOtslejivatOtkryitieSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZapustitRabochiyProtsessAvtomatizatsiiPriNajatiiNaSsyilkuVSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZapustitRabochiyProtsessAvtomatizatsiiPriOtpravkeSmsSoobscheniy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguIspolzovatAvtomatizatsiyuDlyaOtpravkiSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoMoverArchivosEnArchivosEImagenes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAsignarEtiquetasYPuntosDuranteLaImportacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoActivarMiFlujoDeTrabajoDeAutomatizacionCuandoSeHaceClicEnUnEnlaceDeUnSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMonitorareLeApertureDegliSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoSincronizarLaAudienciaDeMisAnunciosConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineIpAdresseAufwaermung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinWebsiteMenueZuMeinerWebsiteHinzufuegenUndBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieStartseiteAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchSmsMarketingInDenUsaUndKanadaEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchDasOeffnenVonSmsNachverfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegroGetresponseConClickbank = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCargarMiPropiaPlantillaDeMensajeHtml = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearLaTasaDeAperturaDeLosNewslettersDeTextoPlano = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoHacerCrecerMiBaseDeDatosDeContactosIntegrandoGetresponseConMagento2 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-crecer-mi-base-de-datos-de-contactos-integrando-getresponse-con-magento-2.html",
"it": "https://www.getresponse.com/it/assistenza/posso-far-crescere-il-mio-database-di-contatti-integrando-getresponse-con-magento.html",
"en": "https://www.getresponse.com/help/can-i-grow-my-contact-database-by-integrating-getresponse-with-magento.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakt-datenbank-erweitern-indem-ich-getresponse-mit-magento-integriere.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-developper-ma-base-de-donnees-de-contacts-en-integrant-getresponse-a-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizoZapierConMiCuentaGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarMisLandingPagesDeLegadoConMixpanel = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarElIdiomaDeLosElementosNoEditablesDelFormulario = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoRevisarLasNewslettersQueCreaUnDisenador = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoConectarUnaCuentaDeGoogleAdsAGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoConectarUnaCuentaDeFacebookAdsAGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPeremeschatFaylyiVFaylyiIIzobrajeniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguSozdavatPromokodyi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguSinhronizirovatSvoyuReklamnuyuAuditoriyuSGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinGoogleAdsKontoMitGetresponseVerbinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicOtwarciaWiadomosciSms = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITrackSmsOpens = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-track-sms-opens.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-otwarcia-wiadomosci-sms.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-oeffnen-von-sms-nachverfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-aperture-degli-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otslejivat-otkryitie-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-aberturas-de-sms.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-aperturas-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-ouvertures-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenAutomatisierungsWorkflowBeimSendenVonSmsNachrichtenAusloesen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeStworzycKodyPromocyjne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareCodiciPromozionali = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePrzenosicPlikiWZakladcePlikiIObrazy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerschiebeIchDateienInDateienUndBilder = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVersendetManTransaktionaleEMailsUeberGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchEineSmtpIntegrationEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoUeberpruefeIchNewsletterDieEinDesignerErstellt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeSiSpostanoIFileInFileEImmagini = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchMeineListeneinstellungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-listeneinstellungen.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-list-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-ustawienia-mojej-listy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsa-nastrojki-spiska.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-ajustes-de-mi-lista-2.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKonfiguriereIchEineTransaktionsEMail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSynchronisiertManKontakteZwischenGetresponseUndMicrosoftDynamics365 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiePersonalisiereIchDasDashboardMeinerMobilenApp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchAktionscodesErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearCodigosPromocionales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreatePromoCodes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-promo-codes.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-codigos-promocionales.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kody-promocyjne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-aktionscodes-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-codici-promozionali.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdavat-promokodyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-codigos-promocionais.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-codes-promo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIMoveFilesInFilesAndImages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-move-files-in-files-and-images.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-pliki-w-zakladce-pliki-i-obrazy.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-spostano-i-file-in-file-e-immagini.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-ich-dateien-in-dateien-und-bilder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peremeschat-faylyi-v-faylyi-i-izobrajeniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-mover-archivos-en-archivos-e-imagenes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mover-arquivos-em-arquivos-e-imagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-des-fichiers-dans-fichiers-et-images.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFormatiereIchDieDateiDerSmsEmpfaenger = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEinenNeuenSmsAbsendernamenHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieAutomatisierungZumSendenVonSmsVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZsynchronizowacMojeGrupyOdbiorcowReklamZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePolaczycKontoGoogleAdsZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenAutomatisierungsworkflowAusloesenWennAufEinenLinkInEinerSmsGeklicktWird = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinFacebookAdsKontoMitGetresponseVerbinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinAnzeigenZielgruppenMitGetresponseSynchronisieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodklyuchitUchetnuyuZapisFacebookAdsKGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodklyuchitAkkauntGoogleAdsKGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacGetresponseZClickbank = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineVerlasseneWarenkorbEmailFuerPrestashopErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineVerlasseneWarenkorbEmailFuerMagentoErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineVerlasseneWarenkorbEmailFuerShopifyErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineBestellbestaetigungsemailFuerShopifyErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineBestellbestaetigungsemailFuerMagentoErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoSeHaAprobadoMiLineaDeAsuntoPersonalizada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSincronizarContactosEntreGetresponseYMicrosoftDynamics365 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoSincronizzareIlPubblicoDeiMieiInserzioniConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoIntegrareGetresponseConClickbank = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSkolkoPisemYaMoguOtpravitIspolzuyaBesplatnuyuUchetnuyuZapis = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html",
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanISyncMyAdsAudienceWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-sync-my-ads-audience-with-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-sincronizzare-il-pubblico-dei-miei-inserzioni-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zsynchronizowac-moje-grupy-odbiorcow-reklam-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-anzeigen-zielgruppen-mit-getresponse-synchronisieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sinhronizirovat-svoyu-reklamnuyu-auditoriyu-s-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-sincronizar-la-audiencia-de-mis-anuncios-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-minha-audiencia-de-anuncios-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-l-audience-de-mes-annonces-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineEigeneHtmlNachrichtenvorlageHochladen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchGetresponseMitClickbankIntegrieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaIntegrarGetresponseComClickbank = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIIntegrateGetresponseWithClickbank = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickbank.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-getresponse-com-clickbank.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-getresponse-mit-clickbank-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickbank.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickbank.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-clickbank.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickbank.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUruchomicCyklAutomationPrzyWysylaniuWiadomosciSms = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePrzeslacWlasnySzablonWiadomosciHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUtilizarLaAutomatizacionParaEnviarSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineBestellbestaetigungsEmailUndWieKannIchSieErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCarregarMeuProprioModeloDeMensagemHtml = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAVerificareUnDominioInFacebookBusinessManager = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCaricareIlMioModelloDiMessaggioHtml = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUploadMyOwnHtmlMessageTemplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-upload-my-own-html-message-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeslac-wlasny-szablon-wiadomosci-html.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-il-mio-modello-di-messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-meu-proprio-modelo-de-mensagem-html.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-html-nachrichtenvorlage-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cargar-mi-propia-plantilla-de-mensaje-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-telecharger-mon-propre-modele-de-message-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagruzit-sobstvennyj-shablon-html-soobshheniya.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoIntegrarePaypalConGetresponseEAggiungerePulsantiAiMessaggiEAlleLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineDomainInFacebookBusiensssManagerVerifizieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAttivareIlMioAutomationWorkflowDiDuranteLInvioDiMessaggiSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoUtilizzareAutomationPerInviareSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoAsignarEtiquetasOPuntuacionesALosContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html",
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnInserzioneUtilizzandoLIntegrazioneDiGoogleAds = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoEsaminareLeNewsletterCreateDaUnDesigner = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAttivareIlMioWorkflowQuandoSiFaClicSulCollegamentoInUnSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineVerkaufsseiteErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacAutomationDoWysylkiSmsow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUruchomicCyklAutomationPoKliknieciuLinkuWSmsie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePolaczycKontoFacebookAdsZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarMisBloquesEnElNuevoEditorDeMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCuantosMensajesPuedoEnviarConMiCuentaGratuita = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumWirktEinNegativesVerbindungspunktNichtAlsEineAusgangbedingungInMeinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAcionarMeuFluxoDeTrabalhoDeAutomacaoAoEnviarMensagensSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAcionarMeuFluxoDeTrabalhoDeAutomacaoQuandoOLinkEClicadoEmUmSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarAAutomatizacaoParaEnviarSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoConectarUmaContaDeAnunciosDeFacebookParaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoConectarUmaContaDoGoogleAdsAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoEncontrarAsConfiguracoesDaMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-list-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-ustawienia-mojej-listy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsa-nastrojki-spiska.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-ajustes-de-mi-lista-2.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-listeneinstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueMinhaLinhaDeAssuntoPersonalizadaNaoFoiAprovada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoEncontrarOsRascunhosDeMinhaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-message-drafts.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-bozze-dei-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-nachrichtenentwuerfe.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mis-borradores-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nachodjatsa-chernoviki-pisem.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-wersje-robocze-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmaListaBaseadaEmPermissao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueHaDeNovoNoNovoEditorDeMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarBlocosBasicosParaCriarUmaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarOCodigoPersonalizadoEmUmaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarMeusBlocosNoNovoEditorDeMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoAsSecoesNoEditorDeMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoOsRequisitosDoRodape = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosELoSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"en": "https://www.getresponse.com/help/what-is-spam.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCollegareUnAccountFacebookAdsAGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCollegareUnAccountGoogleAdsAGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAMantenereSincronizzatiIDatiDeiContattiDiSalesforceEGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiFunzionalitaSonoDisponibiliInEmailCreator = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaCondizioneDiModificaDelloStatoDiFatturazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIConnectAFacebookAdsAccountToGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-connect-a-facebook-ads-account-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-facebook-ads-z-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-facebook-ads-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-de-anuncios-de-facebook-para-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-uchetnuyu-zapis-facebook-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-facebook-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-facebook-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-facebook-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseAutomationToSendSms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-automation-to-send-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-automatizacao-para-enviar-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-automation-do-wysylki-smsow.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-automation-per-inviare-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-automatizacion-para-enviar-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-automatisierung-zum-senden-von-sms-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-avtomatizatsiyu-dlya-otpravki-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-automatisation-pour-envoyer-des-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanITriggerMyAutomationWorkflowWhenLinkIsClickedInAnSms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-link-is-clicked-in-an-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-po-kliknieciu-linku-w-smsie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-quando-o-link-e-clicado-em-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-workflow-quando-si-fa-clic-sul-collegamento-in-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungsworkflow-ausloesen-wenn-auf-einen-link-in-einer-sms-geklickt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-cuando-se-hace-clic-en-un-enlace-de-un-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-najatii-na-ssyilku-v-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lorsqu-un-lien-est-clique-dans-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanITriggerMyAutomationWorkflowWhenSendingSmsMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-trigger-my-automation-workflow-when-sending-sms-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acionar-meu-fluxo-de-trabalho-de-automacao-ao-enviar-mensagens-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-automation-workflow-di-durante-l-invio-di-messaggi-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-cykl-automation-przy-wysylaniu-wiadomosci-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-automatisierungs-workflow-beim-senden-von-sms-nachrichten-ausloesen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zapustit-rabochiy-protsess-avtomatizatsii-pri-otpravke-sms-soobscheniy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-flujo-de-trabajo-de-automatizacion-al-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-declencher-mon-flux-de-travail-lors-de-l-envoi-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACambiareLaLinguaDegliElementiNonModificabiliNelModulo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantiMessaggiPossoInviareUtilizzandoIlMioAccountGratuito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIConnectAGoogleAdsAccountToGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-connect-a-google-ads-account-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-collegare-un-account-google-ads-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-uma-conta-do-google-ads-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-konto-google-ads-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podklyuchit-akkaunt-google-ads-k-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-google-ads-konto-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-una-cuenta-de-google-ads-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-un-compte-google-ads-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIleWiadomosciMogeWysylacWKoncieFree = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmEMailQueTenhaSempreBoaAparencia = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmaImagemDeFundoAUmaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarWebfontsNaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaPermitirAssinaturasParaOsVisitantesDeMinhaLojaPrestashop = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEuTenhoOMesmoContatoEmMaisDeUmaListaElesVaoReceberMinhaMensagemVariasVezes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntreAutorespostasEAutomacaoDeMarketing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueSoPossoVerMensagensSelecionadasNasCondicoesMensagemAbertaELinkClicado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaACondicaoDeOcasiaoEspecial = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaOStatusDaCobranca = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLembreteSensivelAoTempo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlackFridayLiquidacaoAntecipada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-early-bird-sale.html",
"en": "https://www.getresponse.com/help/black-friday-early-bird-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-early-bird-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/wczesna-sprzedaz-z-okazji-czarnego-piatku.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-pervyim-v-chernuyu-pyatnitsu.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-black-friday-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlackFridayLiquidacaoRapida = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"en": "https://www.getresponse.com/help/black-friday-flash-sale.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-flash-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-a-tempo-limitato-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta-z-okazji-black-friday.html",
"es": "https://www.getresponse.com/es/ayuda/venta-relampago-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/flash-rasprodaja-v-chernuyu-pyatnitsu.html",
"fr": "https://www.getresponse.com/fr/aide/vente-flash-black-friday.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoOsRequisitosTecnicosEAsEtapasDeSolucaoParaProblemasDeWebinars = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoConetarOsModelos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaIntegrirovatSvoyAkkauntSPayu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-integrirovat-svoy-akkaunt-s-payu.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-payu.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintergrowac-konto-z-payu.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-payu.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-payu.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-payu-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguProveritSvoyuIntegratsiyuSPaypal = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatPovedeniePosetiteleiSaitaVPismah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstWebConnectUndWieKannIchEsNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeinKontoMitPayuIntegrieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-payu-integrieren.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-payu.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintergrowac-konto-z-payu.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-payu.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-payu.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-integrirovat-svoy-akkaunt-s-payu.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMitDerMobilenGetresponseAppNewsletterBasierendAufDenAktionenDerAbonnentenInMeinenVorherigenNachrichtenVersenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSendeUndVerwalteIchNewsletterMitDerMobilenGetresponseApp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntreOFunilDeConversaoEAAutomacaoDeMarketing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConta2 = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"en": "https://www.getresponse.com/help/account.html",
"it": "https://www.getresponse.com/it/assistenza/account.html",
"ru": "https://www.getresponse.com/ru/help-ru/akkaunt.html",
"es": "https://www.getresponse.com/es/ayuda/cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/konto-2.html",
"de": "https://www.getresponse.com/de/hilfe/das-konto.html",
"fr": "https://www.getresponse.com/fr/aide/compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTextoPreDefinido = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"en": "https://www.getresponse.com/help/predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html",
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostArmadilhaDeSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/spam-trap.html",
"en": "https://www.getresponse.com/help/spam-trap.html",
"it": "https://www.getresponse.com/it/assistenza/trappole-di-spam.html",
"es": "https://www.getresponse.com/es/ayuda/trampa-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/spam-lovyshka.html",
"de": "https://www.getresponse.com/de/hilfe/spam-trap.html",
"fr": "https://www.getresponse.com/fr/aide/piege-a-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaxaDeAbertura = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-otwarc.html",
"en": "https://www.getresponse.com/help/open-rate.html",
"de": "https://www.getresponse.com/de/hilfe/open-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-otkrytij.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-apertura.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDefinicaoDePhishing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"pl": "https://www.getresponse.pl/pomoc/phishing.html",
"en": "https://www.getresponse.com/help/phishing-definition.html",
"it": "https://www.getresponse.com/it/assistenza/phishing.html",
"es": "https://www.getresponse.com/es/ayuda/phishing.html",
"ru": "https://www.getresponse.com/ru/help-ru/phising.html",
"de": "https://www.getresponse.com/de/hilfe/phishing-definition.html",
"fr": "https://www.getresponse.com/fr/aide/definition-du-phishing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVenda = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"en": "https://www.getresponse.com/help/sale.html",
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"fr": "https://www.getresponse.com/fr/aide/vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoVerificarMinhaIntegracaoComOPaypal = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMinhasListasSaoOptInSimplesENecessarioUmOptInDuploParaSerCompativelComOGdpr = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrarMinhaLandingPageLegadaComMixpanel = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrarMinhaLandingPageLegadaComKissmetrics = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVieleNachrichtenKannIchMitMeinemKostenlosenKontoSenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrarMinhaLandingPageLegadaComAnunciosBing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMinhaLandingPageLegadaVemComUmaPaginaDeAgradecimento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieSpracheVonNichtBearbeitbarenElementenImFormular = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoDuplicarElementosNaMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoEncontrarAsConfiguracoesDeMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantasMensagensPossoEnviarUsandoMinhaContaFree = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUtilizarMiBorradorONewsletterComoAutoresponder = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-borrador-o-newsletter-como-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-entwurf-oder-newsletter-als-autoresponder-verwenden.html",
"en": "https://www.getresponse.com/help/can-i-use-my-draft-or-newsletter-as-an-autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-chernoviki-ili-rassylki-v-kachestve-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-wersje-robocza-wiadomosci-lub-newsletter-jako-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-la-mia-bozza-o-newsletter-come-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnaListaBasadaEnPermisos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLosBloquesBasicosParaCrearUnMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarCodigoPersonalizadoEnUnMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasSeccionesEnElEditorDeMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTanAnchoDebeSerMiMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-tan-ancho-debe-ser-mi-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-szerokosc-powinna-miec-moja-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-wide-should-my-message-be.html",
"de": "https://www.getresponse.com/de/hilfe/welche-breite-sollte-meine-nachricht-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/naskolko-shirokim-mozhet-byt-moje-soobshenie.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-dovrebbe-essere-largo-il-mio-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-doit-etre-la-largeur-de-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnCorreoElectronicoTengaSiempreUnBuenAspecto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueFuncionesOfreceElCreadorDeEmails = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicJezykNieedytowalnychElementowFormularza = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowManyMessagesCanISendUsingMyFreeAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-many-messages-can-i-send-using-my-free-account.html",
"pl": "https://www.getresponse.pl/pomoc/ile-wiadomosci-moge-wysylac-w-koncie-free.html",
"br": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantas-mensagens-posso-enviar-usando-minha-conta-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-nachrichten-kann-ich-mit-meinem-kostenlosen-konto-senden.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-messaggi-posso-inviare-utilizzando-il-mio-account-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-mensajes-puedo-enviar-con-mi-cuenta-gratuita.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-pisem-ya-mogu-otpravit-ispolzuya-besplatnuyu-uchetnuyu-zapis.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-messages-puis-je-envoyer-avec-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLosRequisitosDelPieDePagina = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoRastrearVisitasEAssinaturasParaMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPaginaDeAgradecimento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pl": "https://www.getresponse.pl/pomoc/strona-z-podziekowaniem.html",
"en": "https://www.getresponse.com/help/thank-you-page.html",
"de": "https://www.getresponse.com/de/hilfe/thank-you-page.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-de-agradecimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/stranica-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/pagina-di-ringraziamento.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRegulamentoGeralDeProtecaoDeDadosGdpr = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/rozporzadzenie-o-ochronie-danych-osobowych-rodo.html",
"en": "https://www.getresponse.com/help/general-data-protection-regulation-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/general-data-protection-regulation-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/reglamento-general-de-proteccion-de-datos-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/obshij-reglament-po-zashite-dannych-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/die-allgemeine-datenschutz-verordnung-dsgvo.html",
"fr": "https://www.getresponse.com/fr/aide/reglement-general-sur-la-protection-des-donnees-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDadosPessoaisSensiveis = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProcessamentoDeDadosPessoais = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pt": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pl": "https://www.getresponse.pl/pomoc/przetwarzanie-danych-osobowych.html",
"en": "https://www.getresponse.com/help/personal-data-processing.html",
"it": "https://www.getresponse.com/it/assistenza/personal-data-processing.html",
"es": "https://www.getresponse.com/es/ayuda/tratamiento-de-datos-personales.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotka-personalnych-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/die-verarbeitung-der-personenbezogenen-daten.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees-personnelles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProcessadorDeDados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pl": "https://www.getresponse.pl/pomoc/podmiot-przetwarzajacy-dane.html",
"en": "https://www.getresponse.com/help/data-processor.html",
"it": "https://www.getresponse.com/it/assistenza/data-processor.html",
"es": "https://www.getresponse.com/es/ayuda/procesador-de-datos.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotchik-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/datenverarbeiter.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostControladorDeDados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"en": "https://www.getresponse.com/help/data-controller.html",
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieSprawdzeWynikiTestowAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSincronizarOsContatosEntreGetresponseEMicrosoftDynamics365 = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeYaMoguProsmatrivatRassyilkiKotoryieSozdaetDizayner = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmNovoNomeDeRemetenteDeSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitYazyikNeredaktiruemyihElementovFormyi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFormatarOArquivoDosDestinatariosDeSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html",
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarEEditarUmMenuDoSiteNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEstabelecerOSmsMarketingNosEuaENoCanada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeYaMoguPosmotretRezultatyiMoihABTestov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueRebotanLosMensajesEnviadosAMiPropioDominio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueSoloPuedoVerDeterminadosMensajesEnLasCondicionesMensajeAbiertoYLinkClicado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaCondicionDeCambioDeEstadoDeFacturacion2 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirClientesDeEventbriteAMiListaDeContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoSeActivaLaRutaNegativaEnMiCondicionInicial = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecordatorioDeUrgencia = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoEnviarNewslettersComBaseNasAcoesDosAssinantesEmMinhasMensagensAnterioresUsandoOAplicativoMovelGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoVerComoEstaoMeusTestesAB = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoIntegrarMiLandingPageDeLegadoConKissmetrics = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoIntegrarMiLandingPageDeLegadoConBingAds = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoDuplicarElementosEnMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoHacerUnSeguimientoDeLasVisitasYLasSuscripcionesDeMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoEnlazarPlantillasEntreSi = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoMudarOIdiomaDosElementosNaoEditaveisNoFormulario = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIChangeTheLanguageOfNonEditableElementsInTheForm = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-change-the-language-of-non-editable-elements-in-the-form.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-idioma-dos-elementos-nao-editaveis-no-formulario.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-yazyik-neredaktiruemyih-elementov-formyi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-nieedytowalnych-elementow-formularza.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-sprache-von-nicht-bearbeitbaren-elementen-im-formular.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-lingua-degli-elementi-non-modificabili-nel-modulo.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-idioma-de-los-elementos-no-editables-del-formulario.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-langue-des-elements-non-modifiables-du-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeWebConnectIKakEgoIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitFormuDlyaObnovleniyaSuschestvuyuschihKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaOtpravlyatRassyilkiNaOsnoveDeystviyPodpischikovVMoihPredyiduschihPismahSPomoschyuMobilnogoPrilojeniyaGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguIspravitSamyieRasprostranennyieProblemyiSVebinarami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchDieErgebnisseMeinerABTestsNachrichtenPruefen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestWebConnectIJakGoUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoTrasmettereInStreamingIMieiWebinarSuFacebookEYoutube = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVedereComeStannoAndandoIMieiTestAB = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoCambiarElIdiomaDeMiListaYDelMensajeDeConfirmacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoPersonalizarMiMensajeDeConfirmacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoVerComoVanMisPruebasAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanISeeHowMyABTestsAreDoing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-a-b-tests-are-doing.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-pruebas-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-ergebnisse-meiner-a-b-tests-nachrichten-pruefen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-rezultatyi-moih-a-b-testov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-meus-testes-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-sprawdze-wyniki-testow-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpamTrap = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/spam-trap.html",
"pl": "https://www.getresponse.pl/pomoc/spam-trap.html",
"en": "https://www.getresponse.com/help/spam-trap.html",
"it": "https://www.getresponse.com/it/assistenza/trappole-di-spam.html",
"es": "https://www.getresponse.com/es/ayuda/trampa-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/spam-lovyshka.html",
"br": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/piege-a-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIReceivedAnEmailThatAContactWasAddedToAListButICanTSeeThem = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-eine-abobenachrichtigung-erhalten-kann-den-kontakt-aber-nicht-in-meiner-liste-finden.html",
"en": "https://www.getresponse.com/help/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/mne-prishlo-uvedomlenije-o-tom-chto-kontakt-byl-dobavlen-v-spisok-no-ja-jego-ne-vizhu.html",
"br": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pl": "https://www.getresponse.pl/pomoc/powiadomienie-o-subskrypcji-zostalo-dostarczone-ale-kontakt-nie-jest-dodany-do-listy.html",
"it": "https://www.getresponse.com/it/assistenza/ho-ricevuto-un-e-mail-che-informa-che-un-contatto-e-stato-aggiunto-a-un-elenco-ma-non-riesco-a-visualizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-recu-un-e-mail-indiquant-qu-un-contact-a-ete-ajoute-a-une-liste-mais-je-ne-peux-pas-le-voir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearYEnviarUnSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDasFormularErstellenSodassDieExistierendenKontakteDieDetailsAktualisierenKoennen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostThanksgivingDiaDeAcaoDeGracas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"pt": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"de": "https://www.getresponse.com/de/hilfe/thanksgiving.html",
"en": "https://www.getresponse.com/help/thanksgiving.html",
"it": "https://www.getresponse.com/it/assistenza/ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/swieto-dziekczynienia.html",
"ru": "https://www.getresponse.com/ru/help-ru/den-blagodareniya.html",
"es": "https://www.getresponse.com/es/ayuda/accion-de-gracias.html",
"fr": "https://www.getresponse.com/fr/aide/thanksgiving.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAcompanhamentoPosCompraDirecionado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecommendTopProducts = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"pt": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"de": "https://www.getresponse.com/de/hilfe/top-produkte-empfehlen.html",
"en": "https://www.getresponse.com/help/recommend-top-products.html",
"it": "https://www.getresponse.com/it/assistenza/prodotti-consigliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-popularnych-produktow.html",
"ru": "https://www.getresponse.com/ru/help-ru/rekomendatsiya-populyarnyih-produktov.html",
"es": "https://www.getresponse.com/es/ayuda/recomendar-los-mejores-productos.html",
"fr": "https://www.getresponse.com/fr/aide/produits-recommandes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoPersonalizarLaPaginaDeConfirmacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLosAjustesDeMiLista2 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-ajustes-de-mi-lista-2.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-list-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-ustawienia-mojej-listy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsa-nastrojki-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-listeneinstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoEnviarNewslettersBasadasEnLasAccionesDeLosSuscriptoresEnMisMensajesAnterioresUtilizandoLaAplicacionMovilGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsWebConnectYComoPuedoUtilizarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareIBlocchiDiBasePerCreareUnMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostACosaServonoIModelliDiQualificazioneLead = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereClientiEPotenzialiClientiZohocrmAllaMiaListaDiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAInviareConsigliSuiProdottiAiClienti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html",
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIWebinarFunzionanoSuDispositiviMobili = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html",
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEWebConnectEComePossoUtilizzarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereProdottiEtsyAlMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsWebConnectAndHowCanIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-web-connect-and-how-can-i-use-it.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-web-connect-e-come-posso-utilizzarlo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-web-connect-y-como-puedo-utilizarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-web-connect-i-jak-go-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-web-connect-i-kak-ego-ispolzovat.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-web-connect-und-wie-kann-ich-es-nutzen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-web-connect-e-como-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-web-connect-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeMieListeSonoSingleOptInENecessarioIlDoubleOptInPerEssereConformiAlGdpr = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereClientiEventbriteAllaMiaListaDiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCreareAccountAggiuntiviNelMioAccountMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTextoPredefinido2 = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"en": "https://www.getresponse.com/help/predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html",
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsDmarc = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeguimientoPosCompraDirigido = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoCrearCuentasAdicionalesEnMiCuentaMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambiarLaConfiguracionDnsParaLandingPagesCreadasEnUnaCuentaMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarUnCorreoElectronicoTransaccional = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnNuevoNombreDeRemitenteDeSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFormatearElArchivoDeDestinatariosDeSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html",
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEventbriteKundenZuMeinerKontaktlisteHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMeineListenSindSingleOptInIstDoubleOptInErforderlichUmDsgvoKonformZuSein = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindDieTechnischenAnforderungenUndSchritteZurFehlerbehebungFuerWebinare = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsGetresponseMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarCorreosElectronicosTransaccionalesATravesDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarUnaIntegracionSmtp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnaListaCollegata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoScoprireComeSonoStatiAggiuntiIMieiContattiAUnaListaSpecifica = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-scoprire-come-sono-stati-aggiunti-i-miei-contatti-a-una-lista-specifica.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-dowiem-sie-jak-kontakty-zostaly-dodane-do-konkretnej-listy.html",
"en": "https://www.getresponse.com/help/where-can-i-find-out-how-my-contacts-were-added-to-a-specific-list.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-heraus-wie-meine-kontakte-zu-einer-bestimmten-liste-hinzugefuegt-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-saber-como-fueron-anadidos-mis-contactos-a-una-lista-especifica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uznat-kakim-obrazom-moi-kontakty-byli-dobavleny-v-konkretnyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheEImportanteLaConformitaLegale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-e-importante-la-conformita-legale.html",
"en": "https://www.getresponse.com/help/why-is-legal-compliance-important.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-die-einhaltung-gesetzlicher-vorschriften-wichtig.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-importante-el-cumplimiento-legal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-vazno-sootvetstvie-trebovaniyam-zakonodatelstva.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nalezy-przestrzegac-przepisow-prawnych.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePreparareUnFilePerImportarloTramiteFtp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuandoImportareFileTramiteFtp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheNonRiescoAdAccedereAlMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAPreparareIlMioAccountPerIlGdpr = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoLeDifferenzeTraUnFunnelDiVenditaRapidoEUnFunnelDiVenditaCompleto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereManualmenteUnContattoAlCicloDiAutoresponder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoIRequisitiTecniciEIPassaggiPerLaRisoluzioneDeiProblemiPerIWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoSalvareIlLayoutDellaMiaSalaWebinarELaLavagna = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeSiUtilizzaIlFiltroPerLoStatoDelConsenso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereClientiEPotenzialiClientiSalesforceAlMioElencoDiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeSiUsaLaVistaCalendarioPerGliAutoresponder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIntegrareGetresponseConGoogleAnalytics = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoInviareNewsletterInBaseAlleAzioniDegliAbbonatiNeiMieiMessaggiPrecedentiUtilizzandoLAppMobileGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDasKonto = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/das-konto.html",
"en": "https://www.getresponse.com/help/account.html",
"it": "https://www.getresponse.com/it/assistenza/account.html",
"ru": "https://www.getresponse.com/ru/help-ru/akkaunt.html",
"es": "https://www.getresponse.com/es/ayuda/cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/konto-2.html",
"br": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"fr": "https://www.getresponse.com/fr/aide/compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDieAllgemeineDatenschutzVerordnungDsgvo = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/die-allgemeine-datenschutz-verordnung-dsgvo.html",
"pl": "https://www.getresponse.pl/pomoc/rozporzadzenie-o-ochronie-danych-osobowych-rodo.html",
"en": "https://www.getresponse.com/help/general-data-protection-regulation-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/general-data-protection-regulation-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/reglamento-general-de-proteccion-de-datos-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/obshij-reglament-po-zashite-dannych-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/reglement-general-sur-la-protection-des-donnees-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDieSensiblenPersonenbezogenenDaten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDieVerarbeitungDerPersonenbezogenenDaten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/die-verarbeitung-der-personenbezogenen-daten.html",
"pl": "https://www.getresponse.pl/pomoc/przetwarzanie-danych-osobowych.html",
"en": "https://www.getresponse.com/help/personal-data-processing.html",
"it": "https://www.getresponse.com/it/assistenza/personal-data-processing.html",
"es": "https://www.getresponse.com/es/ayuda/tratamiento-de-datos-personales.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotka-personalnych-dannych.html",
"br": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pt": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees-personnelles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDatenverantwortlicher = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"en": "https://www.getresponse.com/help/data-controller.html",
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPrepararUnArchivoParaImportarloPorFtp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnaEntradaCnameAMiSubdominioParaLasPaginasDeDestinoCreadasEnUnaCuentaMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElCorreoElectronicoDeConfirmacionDePedidoYComoPuedoCrearlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoComprobarLasEstadisticasDeMisMensajesTransaccionales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html",
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosaSonoLeAzioniLeCondizioniEIFiltri = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html",
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInCheModoIWebinarPossonoAiutarmiACreareLaMiaListaDiEmailMarketing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeInstalloUnModuloSullaMiaPagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-installo-un-modulo-sulla-mia-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-formularz-legacy-na-swojej-stronie.html",
"en": "https://www.getresponse.com/help/how-do-i-install-a-legacy-form-on-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-ein-legacy-webformular-auf-meiner-seite.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-un-formulario-de-legado-en-mi-pagina-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-redaktora-legacy-na-svoey-stranitse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoInstallareIlMioModuloSuUnaPaginaWordpress = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSonoUnDeveloperDovePossoTrovareInformazioniSullApi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html",
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlcuniElementiNelAutomationWorkflowSonoDisattivatiPerche = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/alcuni-elementi-nel-automation-workflow-sono-disattivati-perche.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-elementy-mojego-mojego-cyklu-sa-wyszarzone.html",
"en": "https://www.getresponse.com/help/some-elements-in-my-workflow-are-grayed-out-why.html",
"de": "https://www.getresponse.com/de/hilfe/manche-elemente-meines-workflows-sind-ausgegraut-warum.html",
"es": "https://www.getresponse.com/es/ayuda/algunos-elementos-de-mi-flujo-de-trabajo-estan-en-gris-por-que.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-nekotorye-elementy-v-mojom-processe-vydeleny-serym-cvetom.html",
"br": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pt": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareIlMonitoraggioDellECommerceInUnMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraUnModuloEUnaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheNonDovreiAggiungereNuovamenteLeEmailRimbalzateAllaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnAutoresponder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraAutoresponderEMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheLeMieEmailAVolteFinisconoInUnaCartellaSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVisualizzareIMieiMessaggiConsegnati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUsoZapierConIlMioAccountGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHoRicevutoUnEMailCheInformaCheUnContattoEStatoAggiuntoAUnElencoMaNonRiescoAVisualizzarli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ho-ricevuto-un-e-mail-che-informa-che-un-contatto-e-stato-aggiunto-a-un-elenco-ma-non-riesco-a-visualizzarli.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-eine-abobenachrichtigung-erhalten-kann-den-kontakt-aber-nicht-in-meiner-liste-finden.html",
"en": "https://www.getresponse.com/help/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/mne-prishlo-uvedomlenije-o-tom-chto-kontakt-byl-dobavlen-v-spisok-no-ja-jego-ne-vizhu.html",
"br": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pl": "https://www.getresponse.pl/pomoc/powiadomienie-o-subskrypcji-zostalo-dostarczone-ale-kontakt-nie-jest-dodany-do-listy.html",
"es": "https://www.getresponse.com/es/ayuda/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-recu-un-e-mail-indiquant-qu-un-contact-a-ete-ajoute-a-une-liste-mais-je-ne-peux-pas-le-voir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACercareIContattiInBaseAlConsensoCheHannoDato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoImpostareIlMioModuloInModoCheCompaiaSullaMiaPagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoSolucionarLosProblemasMasComunesDeLosWebinars = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanISendNewslettersBasedOnSubscribersActionsInMyPreviousMessagesUsingTheGetresponseMobileApp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-send-newsletters-based-on-subscribers-actions-in-my-previous-messages-using-the-getresponse-mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-newsletter-in-base-alle-azioni-degli-abbonati-nei-miei-messaggi-precedenti-utilizzando-l-app-mobile-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-newsletters-basadas-en-las-acciones-de-los-suscriptores-en-mis-mensajes-anteriores-utilizando-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-otpravlyat-rassyilki-na-osnove-deystviy-podpischikov-v-moih-predyiduschih-pismah-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-newsletters-com-base-nas-acoes-dos-assinantes-em-minhas-mensagens-anteriores-usando-o-aplicativo-movel-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-der-mobilen-getresponse-app-newsletter-basierend-auf-den-aktionen-der-abonnenten-in-meinen-vorherigen-nachrichten-versenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wysylac-newslettery-na-podstawie-reakcji-subskrybentow-na-poprzednie-wiadomosci-przy-uzyciu-aplikacji-mobilnej-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-newsletters-basees-sur-les-actions-des-abonnes-dans-mes-messages-precedents-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacFormularzAbyAktualizowalIstniejaceKontakty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPoradzicSobieZNajczestszymiProblemamiWebinarowymi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieHaeufigstenWebinarProblemeBeheben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCorrigirOsProblemasMaisComunsDeWebinars = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheIlBadgeGetresponseVieneVisualizzatoNelleMieEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantiDestinatariDevoIncludereNelMioGruppoDiTest = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheGliIspRiconosconoIMessaggiComeSpamInModoDiverso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdUtilizzareIWebfontNelMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoPubblicareAggiornamentiSuTwitterEFacebookQuandoInvioUnaNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html",
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantoDovrebbeEssereLargoIlMioMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quanto-dovrebbe-essere-largo-il-mio-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-szerokosc-powinna-miec-moja-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-wide-should-my-message-be.html",
"de": "https://www.getresponse.com/de/hilfe/welche-breite-sollte-meine-nachricht-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/naskolko-shirokim-mozhet-byt-moje-soobshenie.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-ancho-debe-ser-mi-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-doit-etre-la-largeur-de-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoRisolvereIProblemiPiuComuniDeiWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIFixTheMostCommonWebinarIssues = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-fix-the-most-common-webinar-issues.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-risolvere-i-problemi-piu-comuni-dei-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-corrigir-os-problemas-mais-comuns-de-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poradzic-sobie-z-najczestszymi-problemami-webinarowymi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-haeufigsten-webinar-probleme-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-solucionar-los-problemas-mas-comunes-de-los-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispravit-samyie-rasprostranennyie-problemyi-s-vebinarami.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resoudre-les-problemes-les-plus-courants-lies-aux-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSkidkiPervyimVChernuyuPyatnitsu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/skidki-pervyim-v-chernuyu-pyatnitsu.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-early-bird-sale.html",
"en": "https://www.getresponse.com/help/black-friday-early-bird-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-early-bird-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/wczesna-sprzedaz-z-okazji-czarnego-piatku.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada-de-black-friday.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-black-friday-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEscolherEConfigurarUmDominioDeEnvio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFlashRasprodajaVChernuyuPyatnitsu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/flash-rasprodaja-v-chernuyu-pyatnitsu.html",
"en": "https://www.getresponse.com/help/black-friday-flash-sale.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-flash-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-a-tempo-limitato-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta-z-okazji-black-friday.html",
"es": "https://www.getresponse.com/es/ayuda/venta-relampago-de-black-friday.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"fr": "https://www.getresponse.com/fr/aide/vente-flash-black-friday.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoInviareUnMessaggioIstantaneoDopoCheQualcunoSiERegistratoTramiteLaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheIMessaggiInviatiAlMioDominioVengonoRespinti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeTrovareLeIntestazioniDeiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html",
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSignificaCheIlMioMessaggioHaUnErroreDiSyntax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoFarCrescereIlMioDatabaseDiContattiIntegrandoGetresponseConMagento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-far-crescere-il-mio-database-di-contatti-integrando-getresponse-con-magento.html",
"en": "https://www.getresponse.com/help/can-i-grow-my-contact-database-by-integrating-getresponse-with-magento.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakt-datenbank-erweitern-indem-ich-getresponse-mit-magento-integriere.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-crecer-mi-base-de-datos-de-contactos-integrando-getresponse-con-magento-2.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-developper-ma-base-de-donnees-de-contacts-en-integrant-getresponse-a-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeMieStatisticheSulTassoDiAperturaNonSonoDisponibiliCosaPotrebbeCausareQuesto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html",
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrareLaMiaApplicazioneConGetresponseUtilizzandoLApi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraUnaNewsletterEUnAutoresponder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLaReputazioneDellIndirizzoEMailDelMittenteInfluenzaLaConsegnaAllaCartellaPostaInArrivoRispettoAllaCartellaSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereCampiPersonalizzatiAlMioModulo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLaConfiguracionDeMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoConfigurarElFormularioParaActualizarLosContactosExistentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAModificareIGiorniInCuiGliAutoresponderEscono = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereUnImmagineDiSfondoAUnMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnEMailCheAbbiaSempreUnBellAspetto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACambiareLaMiaPassword = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAScelgiereIDestinatariDellaNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareIlCodicePersonalizzatoInUnMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoUtilizzareIMieiBlocchiNelNuovoEditorDiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeSezioniNellEditorDeiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoIRequisitiPerIlPieDiPagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaCEDiNuovoNelNuovoEditorDiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeMieNewsletterPossonoEssereVisualizzateOnline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoControllareLeStatistichePerGliInvitiAiWebinarEIPromemoriaCheHoInviato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCodificareNewsletterHtml = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoEsportareLaCronologiaDelleChatDeiWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheAlcuneEmailPossonoEssereVisualizzateComePerContoDiOTramite = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoPersonalizzareLaMiaDashboardConIWidget = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreReportsAndHowCanIUseThem = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/what-are-reports-and-how-can-i-use-them.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-raporty-i-jak-moge-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-reports-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-informes-y-como-puedo-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otchety-i-kak-ja-mogu-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVedereLeStatistichePerUnaListaOUnMessaggioSpecifico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeTenereTracciaDelTassoDiAperturaPerLeNewsletterInTestoNonFormattato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoImpostareIlModuloPerAggiornareIContattiEsistenti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoConfigurarOFormularioParaAtualizarOsContatosExistentes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanISetTheFormToUpdateExistingContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-set-the-form-to-update-existing-contacts.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-o-formulario-para-atualizar-os-contatos-existentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-il-modulo-per-aggiornare-i-contatti-esistenti.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-el-formulario-para-actualizar-los-contactos-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-formularz-aby-aktualizowal-istniejace-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-formular-erstellen-sodass-die-existierenden-kontakte-die-details-aktualisieren-koennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-formu-dlya-obnovleniya-suschestvuyuschih-kontaktov.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-le-formulaire-pour-qu-il-mette-a-jour-les-contacts-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMiLandingPageDeLegadoVieneConUnaPaginaDeAgradecimiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoTransmitirMisWebinarsEnFacebookYYoutube = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAccionDeGracias = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/accion-de-gracias.html",
"de": "https://www.getresponse.com/de/hilfe/thanksgiving.html",
"en": "https://www.getresponse.com/help/thanksgiving.html",
"it": "https://www.getresponse.com/it/assistenza/ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/swieto-dziekczynienia.html",
"ru": "https://www.getresponse.com/ru/help-ru/den-blagodareniya.html",
"br": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"pt": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"fr": "https://www.getresponse.com/fr/aide/thanksgiving.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentaRelampagoDeBlackFriday = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/venta-relampago-de-black-friday.html",
"en": "https://www.getresponse.com/help/black-friday-flash-sale.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-flash-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-a-tempo-limitato-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta-z-okazji-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/flash-rasprodaja-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"fr": "https://www.getresponse.com/fr/aide/vente-flash-black-friday.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleUndSendeIchEineSms = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDmarc = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieBedingungBesondererAnlass2 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineBerechtigungsbasierteListe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumWurdeMeineBenutzerdefinierteBetreffzeileNichtGenehmigt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinKontaktformularErstellenUndBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecomendarLosMejoresProductos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/recomendar-los-mejores-productos.html",
"de": "https://www.getresponse.com/de/hilfe/top-produkte-empfehlen.html",
"en": "https://www.getresponse.com/help/recommend-top-products.html",
"it": "https://www.getresponse.com/it/assistenza/prodotti-consigliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-popularnych-produktow.html",
"ru": "https://www.getresponse.com/ru/help-ru/rekomendatsiya-populyarnyih-produktov.html",
"br": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"pt": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"fr": "https://www.getresponse.com/fr/aide/produits-recommandes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentaAnticipadaDeBlackFriday = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada-de-black-friday.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-early-bird-sale.html",
"en": "https://www.getresponse.com/help/black-friday-early-bird-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-early-bird-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/wczesna-sprzedaz-z-okazji-czarnego-piatku.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-pervyim-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-black-friday-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoIntegrarMiCuentaConPayu = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-payu.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-payu.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintergrowac-konto-z-payu.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-payu.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-payu-integrieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-integrirovat-svoy-akkaunt-s-payu.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsareUnImmagineComeSfondoDelModulo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUtilizarUnaImagenComoFondoDeUnFormularioDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCuandoImportarArchivosViaFtp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnUtente = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoConsentireAdAltrePersoneDiAccedereAlMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIlMioAccountPuoEssereChiusoACausaDiDenunceDiSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoveTrovoLaChiaveApi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoRimuovereUnaCartaDiCreditoDalMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoEssereSicuroDiEssereConformeAlleNormativeCasl = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html",
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeMantenereLIgieneDellaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeRiparareUnElencoDiEMailObsoleto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiTipiDiFilePossoCaricareInUnWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDenBlagodareniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/den-blagodareniya.html",
"de": "https://www.getresponse.com/de/hilfe/thanksgiving.html",
"en": "https://www.getresponse.com/help/thanksgiving.html",
"it": "https://www.getresponse.com/it/assistenza/ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/swieto-dziekczynienia.html",
"es": "https://www.getresponse.com/es/ayuda/accion-de-gracias.html",
"br": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"pt": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"fr": "https://www.getresponse.com/fr/aide/thanksgiving.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEIlRiscaldamentoDellIndirizzoIp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html",
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarElMarketingPorSmsEnEstadosUnidosYCanada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedenLosAsistentesALosWebinarsAnadirComentariosALosWebinarsBajoDemanda = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDerVordefinierteText = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"en": "https://www.getresponse.com/help/predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html",
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRekomendatsiyaPopulyarnyihProduktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/rekomendatsiya-populyarnyih-produktov.html",
"de": "https://www.getresponse.com/de/hilfe/top-produkte-empfehlen.html",
"en": "https://www.getresponse.com/help/recommend-top-products.html",
"it": "https://www.getresponse.com/it/assistenza/prodotti-consigliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-popularnych-produktow.html",
"es": "https://www.getresponse.com/es/ayuda/recomendar-los-mejores-productos.html",
"br": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"pt": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"fr": "https://www.getresponse.com/fr/aide/produits-recommandes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMultiUnsubscribeWypis = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/multi-unsubscribe-wypis.html",
"de": "https://www.getresponse.com/de/hilfe/multi-unsubscribe-opt-out.html",
"en": "https://www.getresponse.com/help/multi-unsubscribe-opt-out.html",
"es": "https://www.getresponse.com/es/ayuda/desinscripcion-multiple-opt-out.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovyj-otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-multipla-opt-out.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"fr": "https://www.getresponse.com/fr/aide/desabonnement-multiple-opt-out.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVerkauf = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"en": "https://www.getresponse.com/help/sale.html",
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html",
"fr": "https://www.getresponse.com/fr/aide/vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDatenverarbeiter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/datenverarbeiter.html",
"pl": "https://www.getresponse.pl/pomoc/podmiot-przetwarzajacy-dane.html",
"en": "https://www.getresponse.com/help/data-processor.html",
"it": "https://www.getresponse.com/it/assistenza/data-processor.html",
"es": "https://www.getresponse.com/es/ayuda/procesador-de-datos.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotchik-dannych.html",
"br": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaFunzioneDiConvalidaAutomaticaDelTelefono = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosELaConfermaDiPagamentoAggiuntiva = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHoCancellatoIlMioAbbonamentoPercheMiVieneAncoraAddebitato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html",
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCambiareIlMioMetodoDiPagamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantoEBuonoIlTuoTassoDiConsegnaDelleEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosELaVistaGlobaleEComeSiUsa = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiFunzionalitaOffronoLeLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html",
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoRimuovereManualmenteIContattiDallaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeSincronizzareIContattiTraGetresponseEMicrosoftDynamics365 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnNuovoNomeMittenteSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFormattareIlFileDeiDestinatariDegliSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html",
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosELEMailDiConfermaDellOrdineEComePossoCrearla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWeryfikacjaDomenyStronyWMenedzerzeFirmyNaFacebooku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/weryfikacja-domeny-strony-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-podtverdit-domen-sayta-v-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-el-dominio-de-un-sitio-web-en-facebook-business-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-domain-einer-website-im-facebook-business-manager-verifizieren.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-le-domaine-d-un-site-web-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLoescheIchEinenPopupOderInlineInhalt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-einen-popup-oder-inline-inhalt.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-pop-up-lub-element-osadzony.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-a-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-vsplyivayuschie-okno-ili-vstroennuyu-formu.html",
"es": "https://www.getresponse.com/es/ayuda/how-do-i-delete-a-popup-or-inline-content.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-un-popup-o-un-contenuto-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-fenetre-pop-up-ou-un-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDenVerkehrAufMeinenPopupOderInlineInhaltenVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoEnviarLosDatosDeLosSuscriptoresAUnaPaginaDeAgradecimientoPersonalizadaDespuesDelWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnCorreoElectronicoDeCarritoAbandonadoParaMagento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeSledzicMojaStroneInternetowaKorzystajacZYandexMetrica = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacSmsMarketingWUsaIKanadzie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPromocionarSusProductosEnLosMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareEInviareUnSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoConsentireAiPartecipantiDiPartecipareAUnWebinarDirettamenteDallaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenShopifyAktionscodeInGetresponseErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineGeschaeftlicheEMailAdresseInMeinerBenutzerdefiniertenDomain = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieDomainEinerWebsiteImFacebookBusinessManagerVerifizieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-domain-einer-website-im-facebook-business-manager-verifizieren.html",
"pl": "https://www.getresponse.pl/pomoc/weryfikacja-domeny-strony-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-podtverdit-domen-sayta-v-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-el-dominio-de-un-sitio-web-en-facebook-business-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-le-domaine-d-un-site-web-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossonoEsserciPiuPresentatoriOltreAMe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoLeMiglioriPraticheDiPostaElettronicaCheInfluenzanoLaConsegna = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoVederePercheIMieiContattiHannoAnnullatoLIscrizione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAEliminareIDuplicatiDalleMieListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoGestireLeMieListeEContattiUtilizzandoLAppMobile = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoEliminareIContattiDaUnaListaODaUnAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCopiareOSpostareIContattiDaUnaListaAllAltra = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoTrovareIContattiInBaseAlMetodoDiIscrizione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoFiltrareIContattiDuplicatiInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHoLoStessoContattoInPiuDiUnaListaRiceverannoIlMioMessaggioPiuVolte = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosaEDmarc = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereILinkACampiPersonalizzati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiRuoliPossoAssegnareAgliUtenti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSignificaCheLaMiaImportazioneHaAvutoUnProblemaDiCondivisioneDellaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnCorreoElectronicoEmpresarialEnMiDominioPersonalizado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicUstawieniaDnsLandingPageStworzonegoWKoncieMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchNeueInhalte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html",
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKoennenWebinarTeilnehmerFeedbackZuOnDemandWebinarenHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchMeinePopupZielgruppe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoVerificarElDominioDeUnSitioWebEnFacebookBusinessManager = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-el-dominio-de-un-sitio-web-en-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/weryfikacja-domeny-strony-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-podtverdit-domen-sayta-v-facebook-business-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-domain-einer-website-im-facebook-business-manager-verifizieren.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-le-domaine-d-un-site-web-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElCalentamientoDeLaDireccionIp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWizard = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wizard.html",
"de": "https://www.getresponse.com/de/hilfe/wizard.html",
"en": "https://www.getresponse.com/help/wizard.html",
"es": "https://www.getresponse.com/es/ayuda/asistente.html",
"ru": "https://www.getresponse.com/ru/help-ru/master.html",
"it": "https://www.getresponse.com/it/assistenza/wizard.html",
"br": "https://www.getresponse.com/pt/ajuda/wizard.html",
"pt": "https://www.getresponse.com/pt/ajuda/wizard.html",
"fr": "https://www.getresponse.com/fr/aide/assistant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKonto2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/konto-2.html",
"en": "https://www.getresponse.com/help/account.html",
"it": "https://www.getresponse.com/it/assistenza/account.html",
"ru": "https://www.getresponse.com/ru/help-ru/akkaunt.html",
"es": "https://www.getresponse.com/es/ayuda/cuenta.html",
"de": "https://www.getresponse.com/de/hilfe/das-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"fr": "https://www.getresponse.com/fr/aide/compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPoleDodatkowe2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/pole-dodatkowe-2.html",
"de": "https://www.getresponse.com/de/hilfe/custom-field.html",
"en": "https://www.getresponse.com/help/custom-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemoe-pole.html",
"it": "https://www.getresponse.com/it/assistenza/campo-personalizzato.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"fr": "https://www.getresponse.com/fr/aide/champ-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSzaraLista2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"en": "https://www.getresponse.com/help/greylist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html",
"de": "https://www.getresponse.com/de/hilfe/grauliste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestDmarc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestOchronaPrywatnosciWAplikacjiAppleMailIJakiMaWplywNaNaszaDostarczalnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoPersonalizzareLaPaginaDiConferma = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAssegnareTagEPunteggioDuranteLImportazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereUnaNuovaCartaDiCreditoPerIPagamenti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoModificareIlMioPianoDiAbbonamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVisualizzareEGestireLeMieListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacSledzenieKonwersjiGoogleAdsDoStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacSkrotowKlawiszowychWNowymEdytorze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioARimuovereUnaListaDiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoPersonalizzareIlMioMessaggioDiConferma = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCambiareLaLinguaPerLaMiaListaEMessaggioDiConferma = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeImpostareIlMarketingViaSmsNegliStatiUnitiEInCanada = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnEMailDiCarrelloAbbandonatoPerPrestashop = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareLAzioneInviaNotificaPush = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVisualizzareIDettagliDiContatto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheLaMiaRigaDellOggettoPersonalizzataNonEStataApprovata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoImportareUnaListaDiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAssegnareCampiPersonalizzatiDuranteLImportazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereIndirizziEmailDiRuoloAllaMiaListaDiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereSingoliContattiDirettamenteAllaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMamKontoMaxOdCzegoZaczac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguPodtverditDomenSaytaVFacebookBusinessManager = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-podtverdit-domen-sayta-v-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/weryfikacja-domeny-strony-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-el-dominio-de-un-sitio-web-en-facebook-business-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-domain-einer-website-im-facebook-business-manager-verifizieren.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-le-domaine-d-un-site-web-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatKorporativnyiyAdresElektronnoyPochtyiNaMoemDomene = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmEMailDeCarrinhoAbandonadoParaShopify = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarEMailDeCarrinhoAbandonadoParaPrestashop = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmEMailDeCarrinhoAbandonadoParaMagento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPromoverSeusProdutosNasMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeGestireLeChatDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiStatiPossonoAvereIMieiAnnunciFacebook = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-stati-possono-avere-i-miei-annunci-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-status-moze-otrzymac-moja-reklama-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-statuses-can-my-facebook-ads-have.html",
"de": "https://www.getresponse.com/de/hilfe/welchen-status-kann-meine-facebook-anzeige-haben.html",
"es": "https://www.getresponse.com/es/ayuda/que-estado-pueden-tener-mis-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-statysu-mogyt-byt-u-moei-reklamu-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACollegareIMieiAccountFacebookEGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoIVisitatoriDelSitoWebNegliInserzioniDiFacebook = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoDisconnettereLIntegrazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeMiVerraAddebitatoLInserzione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html",
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoMonitorareIMieiRisultati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-monitorare-i-miei-risultati.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"en": "https://www.getresponse.com/help/where-can-i-monitor-my-results.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-rezultaty-mojej-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-ergebnisse-ueberwachen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-sledit-za-rezultatami.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-monitorear-los-resultados.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAGestireIMieiInserzioni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-inserzioni.html",
"br": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-reklamami.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-anzeigen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-reklamami.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-anuncios.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAInviareEGestireLeNewsletterUtilizzandoLAppMobileGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInCheModoIPartecipantiAlWebinarPossonoAggiungereFeedbackAiWebinarOnDemand = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnEMailDiCarrelloAbbandonataPerShopify = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnEMailDiConfermaDellOrdinePerMagento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnCodicePromozionaleShopifyInGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareEMailAziendaliNelMioDominioPersonalizzato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIVerifyAWebsiteSDomainInFacebookBusinessManager = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/weryfikacja-domeny-strony-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-podtverdit-domen-sayta-v-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-el-dominio-de-un-sitio-web-en-facebook-business-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-domain-einer-website-im-facebook-business-manager-verifizieren.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-le-domaine-d-un-site-web-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUczestnicyWebinaruNaZadanieMogaZostawiacInformacjeZwrotne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarEEditarUmFormularioDeContato = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaCriarEMailComercialNoMeuDominioPersonalizado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoVerificarODominioDeUmSiteNoFacebookBusinessManager = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-o-dominio-de-um-site-no-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/weryfikacja-domeny-strony-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-verify-a-website-s-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-podtverdit-domen-sayta-v-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-el-dominio-de-un-sitio-web-en-facebook-business-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-domain-einer-website-im-facebook-business-manager-verifizieren.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-le-domaine-d-un-site-web-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoDesconectarLaIntegracion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSeMeCobraraPorElAnuncio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoMonitorearLosResultados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-monitorear-los-resultados.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"en": "https://www.getresponse.com/help/where-can-i-monitor-my-results.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-rezultaty-mojej-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-ergebnisse-ueberwachen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-sledit-za-rezultatami.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-monitorare-i-miei-risultati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoGestionarMisAnuncios = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-anuncios.html",
"br": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-reklamami.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-anzeigen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-reklamami.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-inserzioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSprawdzeStatystykiDlaEmailiTransakcyjnych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycBiznesowyAdresEmailNaWlasnejDomenie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatSochetaniyaKlavishVNovomRedaktore = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeMojnoOtklyuchitIntegratsiyu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaBuduPlatitZaReklamu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeYaMoguSleditZaRezultatami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-sledit-za-rezultatami.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"en": "https://www.getresponse.com/help/where-can-i-monitor-my-results.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-rezultaty-mojej-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-ergebnisse-ueberwachen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-monitorear-los-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-monitorare-i-miei-risultati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUpravlyatSvoimiReklamami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-reklamami.html",
"br": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-reklamami.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-anzeigen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-inserzioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaPeredavatDannyiePodpischikovNaSobstvennuyuStranitsuBlagodarnostiPosleVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPovtornoOtpravitPismoNeOtkryivayuschim = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnEMailDiCarrelloAbbandonataPerMagento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakProdvigatSvoiTovaryiVRassyilkah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchDieTeilnehmerdatenAnDieDankeseiteNachDemWebinarWeiterleiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchVollMeinenPrestashopMitGetresponseVerknuepfen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmCodigoPromocionalDaShopifyNaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoOsParticipantesDoWebinarPodemAdicionarFeedbackAosWebinarsSobDemanda = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacIntegracjeSmtp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoEncaminharOsDadosDoAssinanteParaUmaPaginaDeAgradecimentoPersonalizadaAposOWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoAtalhosDeTecladoNoNovoEditor = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoReenviarUmaMensagemParaQuemNaoAbriu = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnEMailDiConfermaDellOrdinePerPrestashop = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionanoIPrezziDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEIlCostoMensileDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereUnRecordCnameAlMioSottodominioPerLeLandingPageCreateInUnAccountMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeModificareLeImpostazioniDnsPerLeLandingPageCreateInUnAccountMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereUnPulsantePaypalAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAMassimizzareLaPrecisioneDellaConvalidaDelTelefonoApi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html",
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRicevereNotificheSuSlack = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosELaProtezioneDellaPrivacyDiAppleMailEInCheModoInfluisceSullaNostraConsegna = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoControllareLeStatistichePerIMieiMessaggiTransazionali = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTengoUnaCuentaMaxComoPuedoEmpezar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBindeIchDenSnippetCodeEinesInlineInhaltsAufMeinerWebsiteEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymRozniSieLejekKonwersjiOdMarketingAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBindeIchDenSnippetCodeEinesPopupsAufMeinerWebsiteEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchDieIntegrationTrennen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieWirdMirDieAnzeigeInRechnungGestellt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchMeineErgebnisseUeberwachen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-ergebnisse-ueberwachen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"en": "https://www.getresponse.com/help/where-can-i-monitor-my-results.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-rezultaty-mojej-reklamy.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-sledit-za-rezultatami.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-monitorear-los-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-monitorare-i-miei-risultati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchMeineAnzeigen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-anzeigen.html",
"br": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-reklamami.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-reklamami.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-inserzioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchTastaturkuerzelImNeuenEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieStatistikenFuerMeineTransaktionsnachrichtenUeberpruefen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchImRahmenDerDsgvoDatenAusserhalbDerEuVerarbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineRueckbestaetigungskampagne = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZweryfikowacDomeneWMenedzerzeFirmyNaFacebooku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobWyslacWiadomoscDoOsobKtoreNieOtworzylyPoprzedniejWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSendeIchEineNachrichtErneutAnNichtOeffner = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIncorporareUnVideoDaUnUrl = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatPromokodShopifyVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHoUnAccountMaxComeFaccioAdInizio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnaNuovaListaDiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeSiUtilizzanoLeScorciatoieDaTastieraNelNuovoEditor = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeInviareNuovamenteUnMessaggioAChiNonLoApre = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePromuovereITuoiProdottiNeiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIncorporareUnaFotoDaUnUrl = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-una-foto-da-un-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-photo-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-adjuntar-una-foto-desde-una-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-photo-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWyslacEmaileTransakcyjnePoprzezGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacEmailTransakcyjny = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiBrowserSonoCompatibiliConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoInoltrareIDatiDegliIscrittiAUnaPaginaDiRingraziamentoPersonalizzataDopoIlWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogePrzekazacDaneSubskrybentowDoWlasnejStronyZPodziekowaniemPoWebinarze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestWiadomoscZPorzuconymKoszykiemIJakJaStworzyc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoReenviarUnMensajeAQuienesNoLoAbren = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateBusinessEmailAtMyCustomDomain = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-business-email-at-my-custom-domain.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-biznesowy-adres-email-na-wlasnej-domenie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-korporativnyiy-adres-elektronnoy-pochtyi-na-moem-domene.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-criar-e-mail-comercial-no-meu-dominio-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-mail-aziendali-nel-mio-dominio-personalizzato.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-empresarial-en-mi-dominio-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-geschaeftliche-e-mail-adresse-in-meiner-benutzerdefinierten-domain.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-professionnel-sur-mon-domaine-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToResendAMessageToNonOpeners = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-resend-a-message-to-non-openers.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-un-mensaje-a-quienes-no-lo-abren.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyslac-wiadomosc-do-osob-ktore-nie-otworzyly-poprzedniej-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-nuovamente-un-messaggio-a-chi-non-lo-apre.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-nachricht-erneut-an-nicht-oeffner.html",
"br": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-reenviar-uma-mensagem-para-quem-nao-abriu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-povtorno-otpravit-pismo-ne-otkryivayuschim.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renvoyer-un-message-a-des-personnes-qui-ne-l-ont-pas-ouvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIForwardSubscriberDataToACustomThankYouPageAfterWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-after-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przekazac-dane-subskrybentow-do-wlasnej-strony-z-podziekowaniem-po-webinarze.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata-dopo-il-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-os-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada-apos-o-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-teilnehmerdaten-an-die-dankeseite-nach-dem-webinar-weiterleiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-peredavat-dannyie-podpischikov-na-sobstvennuyu-stranitsu-blagodarnosti-posle-vebinara.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-despues-del-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-apres-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPromowacProduktyWWiadomosciach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakOdlaczycIntegracjeZGoogleAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzebiegaPlatnoscZaReklamyGoogleAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeSprawdzicRezultatyMojejReklamy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-rezultaty-mojej-reklamy.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"en": "https://www.getresponse.com/help/where-can-i-monitor-my-results.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-ergebnisse-ueberwachen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-sledit-za-rezultatami.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-monitorear-los-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-monitorare-i-miei-risultati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacReklamami = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-reklamami.html",
"br": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-anzeigen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-reklamami.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-inserzioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUchastnikiVebinaraMogutOstavitOtzyvVVebinarePoTrebovaniyu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaObrabatyivatDannyieZaPredelamiEsVSootvetstviiSGdpr = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakInegrirovatMagento2SGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatPismoBroshennoyKorzinyiDlyaMagento = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatPismoBroshennoyKorzinyiDlyaShopify = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdInviareUnEMailTramiteGetresponseChats = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeInstallareIlCodiceGetresponseChatsSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAbilitareLeChatGetresponseSuUnaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAVerificareSeLaPersonaSuGetresponseChatsEAncoraSullaPagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioABloccareQualcunoSulleChatDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereContattiTramiteGetresponseChats = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnChatGetresponseLinkInUnMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsareGetresponseChatsNellAppMobile = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVedereComeStannoAndandoIMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareITemplateDiMessaggiHtml = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-di-messaggi-html.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/where-can-i-find-html-message-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-vorlagen-fuer-html-nachrichten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-modelos-de-mensajes-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodatsa-html-shablony-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEUnaCampagnaDiRiconferma = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoRinnovareUnDominioAcquistatoConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAttivareMicrosoftDynamics365InGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoTrattareIDatiAlDiFuoriDellUeAiSensiDelGdpr = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatPismoBroshennoyKorzinyiDlyaPrestashop = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeKampaniyaPovtornogoPodtverjdeniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWiadomoscZPorzuconymKoszykiemDlaMagento = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoDesconectarAIntegracao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSereiCobradoPeloAnuncio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoMonitorarMeusResultados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"en": "https://www.getresponse.com/help/where-can-i-monitor-my-results.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-rezultaty-mojej-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-ergebnisse-ueberwachen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-sledit-za-rezultatami.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-monitorear-los-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-monitorare-i-miei-risultati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIManageMyAds = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/how-do-i-manage-my-ads.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-reklamami.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-anzeigen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-svoimi-reklamami.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-anuncios.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-inserzioni.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoProcessarDadosForaDaUeSobOGdpr = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarEGerenciarNewslettersUsandoOAplicativoMovelDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWiadomoscZPorzuconymKoszykiemDlaShopify = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraModuliEPopupEModuliLegacy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareEModificareUnModuloDiContatto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeStworzycKodPromocyjnyShopifyWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoDisabilitareLeSempliciRegistrazioniHtml = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIncorporareUnCodiceSnippetPopupSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereEModificareUnBloccoDiTesto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnEMailDiCarrelloAbbandonataEComePossoCrearla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiTipiDiModuliPossoCreare = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosELaMappaDeiClicEComePossoUsarla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossonoIContattiAggiungersiAllaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWiadomoscZPorzuconymKoszykiemDlaPrestashop = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZsynchronizowacKontaktyPomiedzyKontamiGetresponseIMicrosoftDynamics365 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIDisconnectTheIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-disconnect-the-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-desconectar-a-integracao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odlaczyc-integracje-z-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-integration-trennen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mojno-otklyuchit-integratsiyu.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-desconectar-la-integracion.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-disconnettere-l-integrazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-deconnecter-l-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowWillIBeChargedForTheAd = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-will-i-be-charged-for-the-ad.html",
"br": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-serei-cobrado-pelo-anuncio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przebiega-platnosc-za-reklamy-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-mir-die-anzeige-in-rechnung-gestellt.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-budu-platit-za-reklamu.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-me-cobrara-por-el-anuncio.html",
"it": "https://www.getresponse.com/it/assistenza/come-mi-verra-addebitato-l-inserzione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-l-annonce-me-sera-t-elle-facturee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIMonitorMyResults = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-monitor-my-results.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-monitorar-meus-resultados.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-rezultaty-mojej-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-ergebnisse-ueberwachen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-sledit-za-rezultatami.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-monitorear-los-resultados.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-monitorare-i-miei-risultati.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoIntegrareShopifyEGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAccedereAllAppMobileConUnLinkMagico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnCorreoElectronicoDeCarritoAbandonadoParaPrestashop = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnCodigoPromocionalDeShopifyEnGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoTratarLosDatosFueraDeLaUeGraciasAlRgpd = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacInformacjeSeoDoStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtpravlyatRassyilkiIUpravlyatImiSPomoschyuMobilnogoPrilojeniyaGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereEModificareUnPulsante = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereUnLinkDiOptOutDaSmsAiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereUnTimer = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereUnModuloDiIscrizione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeInviareAllegatiNelleChatDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeImpostareLeChatGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareLeStatisticheDiGetresponseChats = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacPrzyciskPaypalDoStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobZintegrowacStroneZMenedzeremTagowGoogle = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatIOtredaktirovatKontaktnuyuFormu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeEdytowacWygladPopUpuLubElementuOsadzonego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheIMieiAutoresponderNonEscono = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareRssToEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheIMieiContattiNonHannoRicevutoLaMiaNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCorreggereUnErroreDiTransazioneNelMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnaNotificaPushWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeListeDiSoppressioneEQuandoUtilizzarli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineEMailFuerVerlasseneWarenkoerbeUndWieKannIchSieErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAGestireIMieiZapsInGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoIntegrareGetresponseConClickfunnels = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrareIlMioAccountConPayu = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-payu.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-payu.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintergrowac-konto-z-payu.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-payu.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-payu-integrieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-integrirovat-svoy-akkaunt-s-payu.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrareIlMioAccountConSquare = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEGetresponseMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACaricareUnaListaSuUnAccountGetresponseMax = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCaricareIDatiDeiMieiProdottiSuGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakAktywowacMicrosoftDynamics365WGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzemuWidzeTylkoWybraneWiadomosciWWarunkachWiadomoscOtwartaOrazLinkKlikniety = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMoznaTworzycDodatkoweKontaWKoncieMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoNalezyPrzestrzegacPrzepisowPrawnych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nalezy-przestrzegac-przepisow-prawnych.html",
"en": "https://www.getresponse.com/help/why-is-legal-compliance-important.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-die-einhaltung-gesetzlicher-vorschriften-wichtig.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-importante-el-cumplimiento-legal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-vazno-sootvetstvie-trebovaniyam-zakonodatelstva.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"it": "https://www.getresponse.com/it/assistenza/perche-e-importante-la-conformita-legale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUruchomicZapisNaListeDlaOdwiedzajacychSklepPrestashop = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareLeBozzeDeiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-bozze-dei-miei-messaggi.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-message-drafts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-nachrichtenentwuerfe.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mis-borradores-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nachodjatsa-chernoviki-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-wersje-robocze-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUtilizzareLaMiaBozzaONewsletterComeAutoresponder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-la-mia-bozza-o-newsletter-come-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-entwurf-oder-newsletter-als-autoresponder-verwenden.html",
"en": "https://www.getresponse.com/help/can-i-use-my-draft-or-newsletter-as-an-autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-chernoviki-ili-rassylki-v-kachestve-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-wersje-robocza-wiadomosci-lub-newsletter-jako-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-borrador-o-newsletter-como-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanWebinarAttendeesAddFeedbackToOnDemandWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-webinar-attendees-add-feedback-to-on-demand-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-uchastniki-vebinara-mogut-ostavit-otzyv-v-vebinare-po-trebovaniyu.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-partecipanti-al-webinar-possono-aggiungere-feedback-ai-webinar-on-demand.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-participantes-do-webinar-podem-adicionar-feedback-aos-webinars-sob-demanda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uczestnicy-webinaru-na-zadanie-moga-zostawiac-informacje-zwrotne.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinar-teilnehmer-feedback-zu-on-demand-webinaren-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-asistentes-a-los-webinars-anadir-comentarios-a-los-webinars-bajo-demanda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-participants-peuvent-ils-laisser-leurs-commentaires-sur-les-webinaires-a-la-demande.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacLinkDoRezygnacjiZOtrzymywaniaSmsOwDoTresciWiadomosciSms = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeWPelniZintegrowacPrestashopZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguSozdatPismoPodtverzhdeniyaZakazaDlyaPrestashop = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacGalerieObrazowNaStrone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacIEdytowacMenuStrony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicUstawieniaStron = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnCorreoElectronicoDeCarritoAbandonadoYComoPuedoCrearlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEditarElDisenoDeLaVentanaEmergenteOElContenidoEnLinea = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZabezpieczycHaslemDostepDoPodstronyZKreatoraStron = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosCorreosElectronicosTransaccionalesRapidosYParaQuePuedoUtilizarlos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRecargarMiSaldoDeSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnvioYGestionoLasNewslettersConLaAplicacionMovilGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearYEditarUnFormularioDeContacto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchMeinePopupAnzeigehaeufigkeit = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindNameserver = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenEinemKontaktformularUndEinemAnmeldeformular2 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenFormularenUndPopupsUndLegacyFormularen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindSchnelleTransaktionaleEMailsUndWofuerKannIchSieVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieAbsolutePositionierungsfunktion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeinenAffiliateRabattcodeMehrAlsEinmalVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meinen-affiliate-rabattcode-mehr-als-einmal-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-il-mio-codice-sconto-affiliato-piu-di-una-volta.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-discount-code-more-than-once.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-kodu-znizkowego-dla-programow-partnerskich-wiecej-niz-jeden-raz.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-codigo-de-descuento-de-afiliado-mas-de-una-vez.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-kod-skidki-bolee-odnogo-raza.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMuessenMeineKontodetailsSowohlInMeinemGetresponseAffiliateAlsAuchInMeinemGetresponseProduktkontoUebereinstimmenUmSieMiteinanderZuIntegrieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html",
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHabeIchWeiterhinZugriffAufMeinGetresponseAffiliateKontoUndKannIchProvisionenVerdienenWennIchGetresponseNichtVerwende = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html",
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineAffiliateEinnahmenVerwendenUmMeinGetresponseProduktkontoZuBezahlen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWirdMeinGetresponseProduktkontoWeiterhinAktivSeinWennIchMeinGetresponseAffiliateKontoSchliesse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchNachDerIntegrationZwischenMeinemGetresponseKontoUndDemGetresponseAffiliateKontoWechseln = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html",
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWerdenMeineGetresponseUnterbenutzerZugriffAufMeinenGetresponseAffiliateKontobereichHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html",
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostErhalteIchAffiliateSupportDurchGetresponseChats = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKoennenSieMeineAffiliateEinnahmenAufMeineKreditkarteAuszahlenDieInMeinemGetresponseProduktkontoGespeichertIst = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacISkonfigurowacWyskakujaceOkno = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieElementyMogeDodacNaStrone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html",
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuntuacionDeLosContactosDeLosAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEtiquetadoDeContactosDeAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html",
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCursoOnlineParaAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocionDeCursosParaAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeDeBienvenidaALosAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentaCruzadaDeAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/venta-cruzada-de-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-cross-selling.html",
"en": "https://www.getresponse.com/help/affiliate-cross-selling.html",
"it": "https://www.getresponse.com/it/assistenza/cross-selling-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/cross-selling-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-croisees-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kross-prodazha-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocionDeSeminariosWebParaAfiliados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPontuacaoDeContatosDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarcandoContatosDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html",
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensagemDeBoasVindasDoAfiliado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocaoDoCursoDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVendaCruzadaDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-cross-selling.html",
"en": "https://www.getresponse.com/help/affiliate-cross-selling.html",
"it": "https://www.getresponse.com/it/assistenza/cross-selling-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/cross-selling-w-programie-afiliacyjnym.html",
"es": "https://www.getresponse.com/es/ayuda/venta-cruzada-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-croisees-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kross-prodazha-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocaoDeWebinarDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCursoOnlineDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueMisContactosNoHanRecibidoMiNewsletter = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTengoElMismoContactoEnMasDeUnaListaRecibiranMiMensajeVariasVeces = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoSalioMisAutoresponders = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueMiLandingPageDeLegadoNoApareceEnLinea = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueMinhaLandingPageLegadaNaoEstaAparecendoOnline = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakTworzycIEdytowacFormularzKontaktowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreLosFormulariosYVentanasEmergentesYLosFormularios = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoIWebinarOnDemandEComeUtilizzarli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarElTipoDeVentanaEmergente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSeCreaUnaNotificacionPush = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAPersonalizzareLaDashboardDellAppMobile = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnPopupUtilizzandoUnModello = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaPossonoFareGliOperatoriAllInternoDiGetresponseChats = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html",
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeTrappoleAntiSpamELeListeDiBlocco = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-trappole-anti-spam-e-le-liste-di-blocco.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-trampas-de-spam-y-las-listas-de-bloqueo.html",
"en": "https://www.getresponse.com/help/what-are-spam-traps-and-blocklists.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam-lovyshki-i-spiski-blokirovki.html",
"br": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pt": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-spam-traps-und-sperrlisten.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-spam-trapy-oraz-listy-zablokowanych-kontaktow.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pieges-a-spam-et-les-listes-de-blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoInviareMessaggioConformeADmarc = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoModificareIlNumeroDiPersoneConAccessoAlMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeTestareLeChatGetresponsePrimaDiUtilizzarleSulMioSitoWebOSuUnaPaginaDiDestinazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFornireAUnMembroDelTeamLAccessoAlleChatDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoIMessaggiPredefinitiEComeUsarli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnInserzioneFacebookConLIntegrazioneFacebookAds = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacIkonySocialMediaNaStrone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarElTamanoDeLaVentanaEmergente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosServidoresDeNombre = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGetresponseOffreUnAppMobile = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoScegliereEConfigurareUnDominioDiInvio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoModificareIlLoginDelMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIModuliEIPopupSonoDisponibiliPerGliAccountGratuiti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAttivareIlMioAccountGratuito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-account-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-free-akkaynt-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-my-free-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-aktywowac-darmowe-konto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-kostenloses-konto-aktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-cuenta-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereLeIconeDeiSocialMediaAlMioModuloEPopup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoGestireLaChiusuraDeiPopUp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeModificareLaVisualizzazioneDelSuccesso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoModificareLaDimensioneDelPopup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSpersonalizowacPulpitWAplikacjiMobilnejGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAContactFormToMyPopupOrInlineContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-i-edytowac-formularz-kontaktowy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-y-editar-un-formulario-de-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otredaktirovat-kontaktnuyu-formu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-e-modificare-un-modulo-di-contatto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-editar-um-formulario-de-contato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-kontaktformular-erstellen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-modifier-un-formulaire-de-contact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeStworzycWiadomoscZPotwierdzeniemZamowieniaDlaPrestashop = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeStworzycWiadomoscZPotwierdzeniemZamowieniaDlaShopify = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestWiadomoscZPotwierdzeniemZamowieniaIJakMogeJaStworzyc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeStworzycWiadomoscZPotwierdzeniemZamowieniaDlaMagento = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPersonalizarOPainelDoMeuAplicativoMovel = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPersonalizoElTableroDeMiAplicacionMovil = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreUnFormularioDeContactoYUnFormularioDeRegistro = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearLosClicsEnSuSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElLimiteDeAnchoDeBandaDeUnSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambiarLaConfiguracionDelSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGerencioMinhaFrequenciaDeExibicaoPopUp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIncorporoOCodigoDeSnippetDeUmPopUpNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntreUmFormularioDeContatoEUmFormularioDeInscricao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntreFormulariosEPopUpsEFormulariosLegacy = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmEmailDeCarrinhoAbandonadoEComoPossoCriaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoServidoresDeNomes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaGerenciarMeuPublicoPopUp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIncorporoUmaImagemDeUmUrl = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIncorporoUmCodigoDeSnippetDeConteudoEmbutidoEmMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIncorporoUmVideoDeUmUrl = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaEliminarUmPopUpOuConteudoEmbutido = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-pop-up-lub-element-osadzony.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-a-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-vsplyivayuschie-okno-ili-vstroennuyu-formu.html",
"es": "https://www.getresponse.com/es/ayuda/how-do-i-delete-a-popup-or-inline-content.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-un-popup-o-un-contenuto-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-einen-popup-oder-inline-inhalt.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-fenetre-pop-up-ou-un-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrioUmNovoConteudo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoRastrearOTrafegoNoMeuConteudoPopUpOuInline = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnCorreoElectronicoDeConfirmacionDePedidoParaMagento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmEMailDeConfirmacaoDePedidoParaMagento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnCorreoElectronicoDeConfirmacionDePedidoParaShopify = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmEMailDeConfirmacaoDePedidoParaShopify = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnCorreoElectronicoDeConfirmacionDePedidoParaPrestashop = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmEMailDeConfirmacaoDePedidoParaPrestashop = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearNuevosContenidos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAsignarMiPropioSubdominioAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieMitgliedschaftsfunktion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheElementeKannIchMeinerWebsiteHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacSeparatoryNaStrone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenEinemKontaktformularUndEinemAnmeldeformular = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchGetresponseMitClickfunnels = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenEinerLandingPageUndEinerWebsite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchMeineZapsInGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoVerComoVanMisSms = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoVerODesempenhoDoMeuSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html",
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacMagento2ZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnaCampanaDeReconfirmacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLosRequisitosTecnicosYLosPasosParaSolucionarLosProblemasDeLosWebinars = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoGestireLaFrequenzaDiVisualizzazioneDeiPopup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIntegrarGetresponseConClickfunnels = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoModificareIlDesignDelPopupODelContenutoInline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacFunkcjiCzlonkowie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacCzestotliwosciaWyswietlaniaMoichPopUpow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeTworzycIZarzadzacWiadomosciamiWAplikacjiMobilnej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacISkonfigurowacElementTekstowyNaStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPersonalizirovatGlavnoeMenyuMobilnogoPrilojeniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacOdstepNaStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitSmsMarketingVSshaIKanade = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodawacIKonfigurowacObrazyNaStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCrossSellingWProgramieAfiliacyjnym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/cross-selling-w-programie-afiliacyjnym.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-cross-selling.html",
"en": "https://www.getresponse.com/help/affiliate-cross-selling.html",
"it": "https://www.getresponse.com/it/assistenza/cross-selling-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/venta-cruzada-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-croisees-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kross-prodazha-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoINameserver = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAGestireIlMioPubblicoPopup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareNuovoContenuto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoMonitorareIlTrafficoSulMioContenutoPopupOInline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAEliminareUnPopupOUnContenutoInline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-un-popup-o-un-contenuto-inline.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-pop-up-lub-element-osadzony.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-a-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-vsplyivayuschie-okno-ili-vstroennuyu-formu.html",
"es": "https://www.getresponse.com/es/ayuda/how-do-i-delete-a-popup-or-inline-content.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-einen-popup-oder-inline-inhalt.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-fenetre-pop-up-ou-un-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIncorporareIlCodiceSnippetDiUnContenutoInlineSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMoznaKupicDomenePrzezGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacPodstroneDoMojejStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeRecenzowacNewsletteryStworzonePrzezGrafika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyZachowamDostepDoKontaPartnerskiegoIMozliwoscUzyskiwaniaProwizjiJesliNieKorzystamZKontaGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html",
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyDaneKontaPartnerskiegoIKontaGetresponseMuszaBycIdentyczneAbymMoglJeZeSobaZintegrowac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyUzyskamPomocNaCzacieGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDoladowacMojeSaldoSms = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoComecarAUsarOSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoRenovarUmDominioAdquiridoComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoGerenciarOFechamentoDePopUps = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEditarODesignDoPopUpOuDoConteudoEmbutido = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMudarAVisaoDeSucesso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAlterarOTamanhoDoPopUp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAlterarOTipoDePopUp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUzycKoduZnizkowegoDlaProgramowPartnerskichWiecejNizJedenRaz = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-kodu-znizkowego-dla-programow-partnerskich-wiecej-niz-jeden-raz.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-il-mio-codice-sconto-affiliato-piu-di-una-volta.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-discount-code-more-than-once.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-codigo-de-descuento-de-afiliado-mas-de-una-vez.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-kod-skidki-bolee-odnogo-raza.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meinen-affiliate-rabattcode-mehr-als-einmal-verwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyIstniejeMozliwoscWyplatySrodkowUzyskanychWProgramiePartnerskimNaKarteKredytowaPowiazanaZKontemGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html",
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyInniUzytkownicyZespoluBedaMieliDostepDoMojegoKontaPartnerskiegoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html",
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMojeKontoGetresponsePozostanieAktywnePoZamknieciuKontaPartnerskiego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyBedeMiecMozliwoscPrzelaczaniaMiedzyKontemGetresponseAKontemPartnerskimPoIchZintegrowaniu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeWykorzystacSrodkiZarobioneWProgramiePartnerskimDoOplaceniaMojegoKontaGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMoznaLaczycSzablony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeDodacIkonyMediowSpolecznosciowychDoMoichFormularzyIPopUpow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDasSchliessenVonPopupsVerwalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchSocialMediaSymboleZuMeinemFormularUndPopupHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinBildVonEinerUrlEinbetten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenTextblockHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinVideoVonEinerUrlEinbetten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchBilderZuMeinerWebsiteHinzuUndVerwalteSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarIconesDeMidiaSocialAoMeuFormularioEPopUp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarEEditarUmBlocoDeTexto = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarEEditarUmBotao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmTemporizador = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmFormularioDeInscricao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoDesativarInscricoesEmHtmlSimples = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeUstawicZamknieciePopUpu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZainstalowacKodPopUpuNaMojejStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoActivarMiCuentaGratuita = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-cuenta-gratuita.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-free-akkaynt-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-my-free-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-aktywowac-darmowe-konto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-kostenloses-konto-aktivieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-account-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarElDisenoGlobalEnElWebsiteBuilder = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnEnlaceParaDarseDeBajaDeLosSmsAMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoSeguirLaConversionDeLosFormulariosEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSeguirElTraficoDeSuSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoRastrearElTraficoEnMiVentanaEmergenteOContenidoEnLinea = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPoplnitSchyotDlyaSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchSehenWieMeineSmsAnkommen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html",
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeYaMoguPosmotretStatistikuOtpravlennyihSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html",
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLadeIchMeinSmsGuthabenAuf = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaAccionEnviarNotificacionPush = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIDeleteAPopupOrInlineContent = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/how-do-i-delete-a-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-pop-up-lub-element-osadzony.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-a-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-vsplyivayuschie-okno-ili-vstroennuyu-formu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-un-popup-o-un-contenuto-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-einen-popup-oder-inline-inhalt.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-fenetre-pop-up-ou-un-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIncrustarElCodigoDeUnFragmentoDeContenidoEnLineaEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGestionoLaAudienciaDeMisVentanasEmergentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoGestionarLaFrecuenciaDeVisualizacionDeLasVentanasEmergentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoGestionarCuandoMostrarMiVentanaEmergente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMoznaZobaczycStatystykiSmsOw = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html",
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSuccedeQuandoSuperoIlLimiteDelleDimensioniDellaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoIniziareConGliSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVedereComeStannoAndandoIMieiSms = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html",
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieAnsichtBeiErfolg = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieGroesseDesPopupsAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDenPopupTypAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDasDesignDesPopupOderInlineInhaltsBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheArtenVonFormularenKannIchErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchShopifyUndGetresponseIntegrieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnaPaginaDeUnaUrlExternaAlMenuDelSitio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineBeiGetresponseErworbeneDomainVerlaengern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineBestellbestaetigungsEMailFuerPrestashopErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCambiareIlTipoDiPopup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnEMailDiConfermaDellOrdinePerShopify = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraUnModuloDiContattoEUnModuloDiIscrizione2 = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoSalvareUnTestAB = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiTipiDiTestABPossoScegliere = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoTestareDiversePartiDelMessaggioInUnTestAB = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoProgrammareIMieiTestAB = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeVieneInviatoIlMessaggioConLeMiglioriPrestazioni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareLaFunzioneDiTestAB = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnTestAB = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareEventi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacFormularzZapisu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicRodzajPopUpu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguSozdatPismoPodtverjdeniyaZakazaDlyaShopify = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguSozdatPismoPodtverjdeniyaZakazaDlyaMagento = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDenLeadMagnetenInMeinemFunnelWechseln = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchWannMeinPopupAngezeigtWird = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieTrackeIchMeineWebsiteMitGoogleAnalytics = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchGlobalesDesignImWebsiteBuilder = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacGrupaDocelowaMoichPopUpow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeOdnowicDomeneZakupionaWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeKartaKlikovIKakEeIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguIzmenitDizaynVsplyivayuschegoOknaIliVstroennoyFormyi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguOtslejivatTrafikMoegoVsplyivayuschegoOknaIliVstroennoyFormyi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieFunktsiiDostupnyiVRedaktorePisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAGestireQuandoVisualizzareIlMioPopup = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiungereUnVideoAlMioMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIncorporareUnImmagineOUnPulsanteAllInternoDiUnBloccoDiTesto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-incorporare-un-immagine-o-un-pulsante-all-interno-di-un-blocco-di-testo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-bild-oder-einen-button-in-ein-textelement-einfuegen.html",
"en": "https://www.getresponse.com/help/can-i-embed-an-image-or-button-within-a-text-block.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vstavit-izobrazhenije-ili-knopku-tekstovy-blok.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-obrazek-lub-przycisk-w-bloku-tekstowym.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-incrustar-una-imagen-o-un-boton-dentro-de-un-bloque-de-texto.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-une-image-ou-un-bouton-dans-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCreareNewsletterMobileResponsive = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraUnMessaggioDiTestoNormaleEUnMessaggioHtml = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIlMioMessaggioHaUnTassoDiAperturaDello0Perche = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungoUnAltroMessaggioAUnCicloDiAutoresponderEsistente = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicWidokPoZapisie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUstawicCzasPojawieniaSiePopUpu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUsunacPopUpLubElementOsadzony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-pop-up-lub-element-osadzony.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-a-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-vsplyivayuschie-okno-ili-vstroennuyu-formu.html",
"es": "https://www.getresponse.com/es/ayuda/how-do-i-delete-a-popup-or-inline-content.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-un-popup-o-un-contenuto-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-einen-popup-oder-inline-inhalt.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-fenetre-pop-up-ou-un-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicRozmiarPopUpu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSledzicRuchNaStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaWarunekZmienionoStatusPlatnosci2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPotwierdzenieZakupuZalezneOdUdzielonychZgod = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAcquistareUnDominioConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaMiedzyFormularzemZapisuAFormularzemKontaktowym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacAkcjiWyslijWebPush = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguIspolzovatUslovieTegPrisvoenVProtsesseAvtomatizatsii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodawacIEdytowacPrzyciski = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElFiltroDivisor = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaGerenciarQuandoExibirMeuPopUp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaSoedinyatShablonyiAvtomatizatsiiDrugSDrugom = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateOrderConfirmationEmailForMagento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateOrderConfirmationEmailForShopify = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverjdeniya-zakaza-dlya-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-shopify.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungsemail-fuer-shopify-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateOrderConfirmationEmailForPrestashop = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-order-confirmation-email-for-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bestellbestaetigungs-e-mail-fuer-prestashop-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-sozdat-pismo-podtverzhdeniya-zakaza-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-confirmacao-de-pedido-para-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-confirmacion-de-pedido-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-wiadomosc-z-potwierdzeniem-zamowienia-dla-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-conferma-dell-ordine-per-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-confirmation-de-commande-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacNaStroneWideoZAdresuUrl2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoElegirYConfigurarUnDominioDeEnvio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeSledzicRuchNaMoichFormularzachPopUpLubTresciWbudowanej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVyibratINastroitDomenDlyaOtpravki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPhishingDefinition = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/phishing-definition.html",
"pl": "https://www.getresponse.pl/pomoc/phishing.html",
"en": "https://www.getresponse.com/help/phishing-definition.html",
"it": "https://www.getresponse.com/it/assistenza/phishing.html",
"es": "https://www.getresponse.com/es/ayuda/phishing.html",
"ru": "https://www.getresponse.com/ru/help-ru/phising.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"fr": "https://www.getresponse.com/fr/aide/definition-du-phishing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBericht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bericht.html",
"pl": "https://www.getresponse.pl/pomoc/raport.html",
"en": "https://www.getresponse.com/help/report.html",
"es": "https://www.getresponse.com/es/ayuda/informe.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet.html",
"it": "https://www.getresponse.com/it/assistenza/report.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"fr": "https://www.getresponse.com/fr/aide/rapport.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineVersandDomainAuswaehlenUndKonfigurieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWybracISkonfigurowacDomeneWysylkowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUmiescicLicznikNaStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCambiareIlLeadMagnetNelMioFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZmienicLeadMagnetWMoimLejkuKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChooseAndConfigureASendingDomain = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-choose-and-configure-a-sending-domain.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-i-skonfigurowac-domene-wysylkowa.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-versand-domain-auswaehlen-und-konfigurieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-elegir-y-configurar-un-dominio-de-envio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vyibrat-i-nastroit-domen-dlya-otpravki.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scegliere-e-configurare-un-dominio-di-invio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-escolher-e-configurar-um-dominio-de-envio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-et-configurer-un-domaine-d-envoi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPomenyatLidMagnitVVoronke = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarElLeadMagnetEnMiEmbudo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoMudarOImaDigitalNoMeuFunil = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeTheLeadMagnetInMyFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-the-lead-magnet-in-my-funnel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-ima-digital-no-meu-funil.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-pomenyat-lid-magnit-v-voronke.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-lead-magnet-en-mi-embudo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-lead-magnet-w-moim-lejku-konwersji.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-lead-magnet-nel-mio-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-lead-magneten-in-meinem-funnel-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-le-lead-magnet-dans-mon-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacLicznik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUmiescicObrazZAdresuUrl2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url-2.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-photo-from-a-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-adjuntar-una-foto-desde-una-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-una-foto-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-photo-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatNovyjKontent = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToSaTzwNameservers = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUdalitVsplyivayuschieOknoIliVstroennuyuFormu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-vsplyivayuschie-okno-ili-vstroennuyu-formu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-pop-up-lub-element-osadzony.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-a-popup-or-inline-content.html",
"es": "https://www.getresponse.com/es/ayuda/how-do-i-delete-a-popup-or-inline-content.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-un-popup-o-un-contenuto-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-eliminar-um-pop-up-ou-conteudo-embutido.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-einen-popup-oder-inline-inhalt.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-fenetre-pop-up-ou-un-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAEscludereIContattiDallaRicezioneDellaMiaNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRimuovereOModificareILinkDiCancellazioneEAggiornamentoInFondoAiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnAutoresponder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeDodacIEdytowacElementTekstowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUmiescicObrazZAdresuUrl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaFuncionDeMiembros = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNaCzymPolegaKampaniaUzyskaniaPonownegoPotwierdzeniaSubskrypcji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUmiescicKodFormularzaOsadzonegoNaSwojejStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacRekordCnameDoSubdomenyLandingPageStworzonegoWKoncieMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnaPaginaProtegidaPorContrasenaAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoHacerQueMiSitioWebSeaMasReceptivoParaLosDispositivosMoviles = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaMiedzyFormularzamiIPopUpamiAStarszymEdytoremFormularzy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarLaConfiguracionDeMisPaginas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocjaWebinaruZProgramuAfiliacyjnego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZintergrowacKontoZPayu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintergrowac-konto-z-payu.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-payu.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-payu.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-payu.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-payu-integrieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-integrirovat-svoy-akkaunt-s-payu.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnTemporizador = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirYEditarUnBloqueDeTexto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirYEditarUnBoton = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaFiltrPodzial = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoDesactivarLasSuscripcionesEnHtmlPlano = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarLaVistaDespuesDeLaSuscripcion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueElementosPuedoAnadirAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirYGestionarImagenesEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicUstawieniaStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungoAllegatiAiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeTrovareIMieiModelliELavorareConLoro = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoControllareComeAppareIlMessaggioInUnaCasellaDiPostaPrimaDiInviarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAModificareLIndirizzoDiMittenteNeiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-l-indirizzo-di-mittente-nei-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-absender-e-mail-adresse-in-meinen-nachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-adres-otpravitela-v-moich-soobshenijach.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-email-nadawcy-w-wiadomosciach.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-direccion-de-en-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-de-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIntegrareISocialMediaConIMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannManAbschnitteUndBloeckeSpeichernUndWiederverwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchPlainHtmlAnmeldungenDeaktivieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineKontoanmeldungAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinPopupMitEinerVorlage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenTimerHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSindFormulareUndPopupsFuerFreeAccountsVerfuegbar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html",
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenButtonHinzufuegenUndBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinAnmeldeformularHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnFormularioDeContactoAMiPaginaWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacGetresponseZAmazon = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUtilizarUnCodigoHtmlPersonalizadoEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacZapieraZKontemGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocjaPopularnychProduktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/promocja-popularnych-produktow.html",
"de": "https://www.getresponse.com/de/hilfe/top-produkte-empfehlen.html",
"en": "https://www.getresponse.com/help/recommend-top-products.html",
"it": "https://www.getresponse.com/it/assistenza/prodotti-consigliati.html",
"ru": "https://www.getresponse.com/ru/help-ru/rekomendatsiya-populyarnyih-produktov.html",
"es": "https://www.getresponse.com/es/ayuda/recomendar-los-mejores-productos.html",
"br": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"pt": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"fr": "https://www.getresponse.com/fr/aide/produits-recommandes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnaPruebaAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnVideoDesdeUnaUrl = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakTworzycNoweTresci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSaMozliwosciKonsultantowWCzatachGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateAbandonedCartEmailForShopify = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-shopify.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-shopify.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-shopify.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-shopify-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-email-de-carrito-abandonado-para-shopify.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateAbandonedCartEmailForMagento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-magento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-magento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-magento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonata-per-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-e-mail-de-carrinho-abandonado-para-magento.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-magento.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-magento-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateAbandonedCartEmailForPrestashop = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-abandoned-cart-email-for-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-correo-electronico-de-carrito-abandonado-para-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-porzuconym-koszykiem-dla-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-broshennoy-korzinyi-dlya-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-mail-de-carrinho-abandonado-para-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-e-mail-di-carrello-abbandonato-per-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verlassene-warenkorb-email-fuer-prestashop-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-de-panier-abandonne-pour-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacNaStroneWideoZAdresuUrl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIManageMyListsAndContactsUsingTheMobileApp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIPersonalizeMyMobileAppDashboard2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-personalize-my-mobile-app-dashboard-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-glavnoe-menyu-mobilnogo-prilojeniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-personalizo-el-tablero-de-mi-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-o-painel-do-meu-aplicativo-movel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-spersonalizowac-pulpit-w-aplikacji-mobilnej-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-la-dashboard-dell-app-mobile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-personalisiere-ich-das-dashboard-meiner-mobilen-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-le-tableau-de-bord-de-mon-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanILoginInToTheMobileAppWithAMagicLink = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoesGetresponseOfferAMobileApp2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoISendAndManageNewslettersUsingTheGetresponseMobileApp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-send-and-manage-newsletters-using-the-getresponse-mobile-app.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-tworzyc-i-zarzadzac-wiadomosciami-w-aplikacji-mobilnej.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-y-gestiono-las-newsletters-con-la-aplicacion-movil-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-rassyilki-i-upravlyat-imi-s-pomoschyu-mobilnogo-prilojeniya-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-e-gerenciar-newsletters-usando-o-aplicativo-movel-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-e-gestire-le-newsletter-utilizzando-l-app-mobile-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-und-verwalte-ich-newsletter-mit-der-mobilen-getresponse-app.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-et-gerer-des-newsletters-a-l-aide-de-l-application-mobile-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePodlaczycWlasnaSubdomeneDoMojejStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoGestionarElCierreDeLasVentanasEmergentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoRenovarUnDominioCompradoEnGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirElCodigoDeUnaVentanaEmergenteEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWlasnegoKoduHtmlNaSwojejStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacZewnetrznaStroneInternetowaDoMenuStrony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSwietoDziekczynienia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/swieto-dziekczynienia.html",
"de": "https://www.getresponse.com/de/hilfe/thanksgiving.html",
"en": "https://www.getresponse.com/help/thanksgiving.html",
"it": "https://www.getresponse.com/it/assistenza/ringraziamento.html",
"ru": "https://www.getresponse.com/ru/help-ru/den-blagodareniya.html",
"es": "https://www.getresponse.com/es/ayuda/accion-de-gracias.html",
"br": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"pt": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"fr": "https://www.getresponse.com/fr/aide/thanksgiving.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaWarunekZaktualizowanoStatusZgody = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaProteccionDePrivacidadDeAppleMailYComoAfectaANuestraCapacidadDeEntrega = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoGestionarMisZapsEnGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosELaPercentualeDiClicPerAprireCtor = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeEvitareDiInviareMessaggiDiContenutoDiBassaQualita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeIntegrareGetresponseConAmazon = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeIntegrareMagento2ConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoActivarMicrosoftDynamics365EnGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstClickmapUndWieKannIchEsVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimitowanaOfertaZOkazjiBlackFriday = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta-z-okazji-black-friday.html",
"en": "https://www.getresponse.com/help/black-friday-flash-sale.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-flash-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-a-tempo-limitato-per-black-friday.html",
"es": "https://www.getresponse.com/es/ayuda/venta-relampago-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/flash-rasprodaja-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"fr": "https://www.getresponse.com/fr/aide/vente-flash-black-friday.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareIlBloccoDelCarrelloAbbandonatoNeiMessaggiDiAutomazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-blocco-del-carrello-abbandonato-nei-messaggi-di-automazione.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoIntegrareCompletamenteIlMioPrestashopConGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTopUpMySmsBalance = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-top-up-my-sms-balance.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lade-ich-mein-sms-guthaben-auf.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-poplnit-schyot-dlya-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-doladowac-moje-saldo-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comecar-a-usar-o-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-recargar-mi-saldo-de-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-iniziare-con-gli-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-commencer-a-utiliser-les-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanISeeHowMySmsAreDoing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-sms-are-doing.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-posmotret-statistiku-otpravlennyih-sms.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-sms.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-mozna-zobaczyc-statystyki-sms-ow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-sehen-wie-meine-sms-ankommen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-o-desempenho-do-meu-sms.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-sms.html",
"fr": "https://www.getresponse.com/fr/aide/ou-voir-comment-fonctionnent-mes-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoNegatywnaSciezkaNieJestUruchamianaDlaMojegoWarunkuPoczatkowego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoePismoOBroshennojKorzineKakEgoSozdat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoePismoSPodtverzdeniemZakazaKakEgoSozdat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYstanovitKodFormyNaMojSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarMagento2ConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoveSiTrovanoEDoveSiModificanoLeImpostazioniDelMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeMonitorareIlMioSitoWebConFacebookPixel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElMapaDeClicsYComoPuedoUsarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEMapaDeCliqueEComoPossoUsaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmPopupUsandoUmModelo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoUnaVentanaEmergenteUsandoUnaPlantilla = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOsFormulariosEPopUpsEstaoDisponiveisParaContasGratuitas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html",
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHayFormulariosYVentanasEmergentesEnLasCuentasGratuitas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html",
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTiposDeFormulariosPossoCriar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTiposDeFormulariosPuedoCrear = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIncrustarUnVideoDesdeUnaUrl = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoHacerUnSeguimientoDeMiSitioWebConElPixelDeFacebook = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGuardarYReutilizarSeccionesYBloques = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirYEditarUnMenuDelSitioEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreUnFormularioDeContactoYUnFormularioDeSuscripcion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZintegrowacMojaStarszaWersjeLandingPageZPlatformaMixpanel = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestMapaKlikniecIJakMogeZNiejKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereIContattiDopoUnaTransazionePaypal = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html",
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZachetaDoSzybkiegoZakupu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsClickMapAndHowCanIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-click-map-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-mapa-klikniec-i-jak-moge-z-niej-korzystac.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-mapa-de-clique-e-como-posso-usa-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-mapa-de-clics-y-como-puedo-usarlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-clickmap-und-wie-kann-ich-es-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-karta-klikov-i-kak-ee-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-la-mappa-dei-clic-e-come-posso-usarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-carte-des-clics-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTargetowanaKomunikacjaPoZakupie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUzywacKoduSledzacegoGoogleAnalyticsNaMojejStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheFunktionenSindImEmailCreatorVerfuegbar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchRegelmaessigeImporteInMeineListenEinrichten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirLosIconosDeRedesSocialesAMiFormularioYVentanaEmergente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnFormularioDeSuscripcion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSledzicOtwarciaNewsletterowWFormacieTekstowym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirIconosDeRedesSocialesAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeTransmitowacWebinarNaFacebookuIYoutube = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestOpcjaPerfectTimingIJakDziala = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoImpostareImportazioniRegolariNelleMieListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaSzybkieWiadomosciTransakcyjneIJakIchUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaSpamTrapyOrazListyZablokowanychKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-spam-trapy-oraz-listy-zablokowanych-kontaktow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-trampas-de-spam-y-las-listas-de-bloqueo.html",
"en": "https://www.getresponse.com/help/what-are-spam-traps-and-blocklists.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam-lovyshki-i-spiski-blokirovki.html",
"br": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pt": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-spam-traps-und-sperrlisten.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-trappole-anti-spam-e-le-liste-di-blocco.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pieges-a-spam-et-les-listes-de-blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZainstalowacCzatyGetresponseNaMojejStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeZnalezcStatystykiCzatowGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacCzatyGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEditarLasPaginasNoEncontradoYContrasena = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAdjuntarUnaFotoDesdeUnaUrl = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-adjuntar-una-foto-desde-una-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-photo-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-una-foto-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-photo-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIEmbedAnInlineContentSSnippetCodeOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-embed-an-inline-content-s-snippet-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-formy-na-moj-sajt.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-kod-formularza-osadzonego-na-swojej-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-el-codigo-de-un-fragmento-de-contenido-en-linea-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-il-codice-snippet-di-un-contenuto-inline-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-codigo-de-snippet-de-conteudo-embutido-em-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-inline-inhalts-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-de-contenu-en-ligne-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanITrackTrafficOnMyPopupOrInlineContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-track-traffic-on-my-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-ruch-na-moich-formularzach-pop-up-lub-tresci-wbudowanej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-otslejivat-trafik-moego-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-rastrear-el-trafico-en-mi-ventana-emergente-o-contenido-en-linea.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-monitorare-il-traffico-sul-mio-contenuto-popup-o-inline.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-o-trafego-no-meu-conteudo-pop-up-ou-inline.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-verkehr-auf-meinen-popup-oder-inline-inhalten-verfolgen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-mes-pop-ups-ou-mon-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateNewContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-new-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-nowe-tresci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-nuovo-contenuto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-nuevos-contenidos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-novo-conteudo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-neue-inhalte.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-nouveau-contenu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIEditTheDesignOfThePopupOrInlineContent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-edit-the-design-of-the-popup-or-inline-content.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-wyglad-pop-upu-lub-elementu-osadzonego.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-dizayn-vsplyivayuschego-okna-ili-vstroennoy-formyi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-design-des-popup-oder-inline-inhalts-bearbeiten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-design-do-pop-up-ou-do-conteudo-embutido.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-design-del-popup-o-del-contenuto-inline.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-el-diseno-de-la-ventana-emergente-o-el-contenido-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-design-de-la-fenetre-pop-up-ou-du-contenu-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEIlTracciamentoDeiClic = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereIlMonitoraggioDelleConversioniDiGoogleAdsAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeTracciareIlMioSitoWebConYandexMetrica = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeTracciareIlMioSitoConGoogleAnalytics = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosELaFunzioneDispositivoAttendibileEComePossoUtilizzarla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiElementiPossoAggiungereAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereUnaGalleriaDiImmaginiAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereUnTimerPerIlContoAllaRovesciaAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEinWebsiteBandbreitenlimit = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZProjektuGlobalnegoWKreatorzeStron = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePrzechodzicMiedzyPodstronamiWKreatorzeStronInternetowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUkrycPodstroneWMenuStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyProdlitDomenKyplennujSPomosjuGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaOtklychitRegistraciyuNaProstyhHtmlFormah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitLoginAkkaynta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYpravliatAyditoriejVsplyvayushihOkon = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYpravliyatTemKogdaOtobrazatVsplyvayusheeOkno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYpravliyatChastotoiOtobrazeniyaVsplyvayushihOkon = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYstanovitKodVsplyvayushegoOknaNaMoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoExpandirMeuNegocioComOsRecursosDeEcommerceGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTipoDeDadosDeEcommerceSaoEnviadosParaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisCaracteristicasEstaoDisponiveisNoCriadorDeEmail = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmaEntradaCnameAoMeuSubdominioParaAsLandingPagesCriadasEmUmaContaMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAlterarAsConfiguracoesDoDnsParaAsLandingPagesCriadasEmUmaContaMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIManageMyPopupDisplayFrequency = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-display-frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chastotoi-otobrazeniya-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czestotliwoscia-wyswietlania-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-la-frecuencia-de-visualizacion-de-las-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-frequenza-di-visualizzazione-dei-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-minha-frequencia-de-exibicao-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-anzeigehaeufigkeit.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-frequence-d-affichage-de-mes-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIManageMyPopupAudience = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-manage-my-popup-audience.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliat-ayditoriej-vsplyvayushih-okon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-grupa-docelowa-moich-pop-upow.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestiono-la-audiencia-de-mis-ventanas-emergentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-il-mio-pubblico-popup.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-meu-publico-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-popup-zielgruppe.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-l-audience-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIManageWhenToDisplayMyPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-manage-when-to-display-my-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-czas-pojawienia-sie-pop-upu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-tem-kogda-otobrazat-vsplyvayushee-okno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-gerenciar-quando-exibir-meu-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-quando-visualizzare-il-mio-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-wann-mein-popup-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-cuando-mostrar-mi-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-le-moment-de-l-affichage-de-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieFunkcjeSaDostepneWKreatorzeWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIEmbedAPopupSSnippetCodeOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-embed-a-popup-s-snippet-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-vsplyvayushego-okna-na-moi-sajt.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-el-codigo-de-una-ventana-emergente-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-kod-pop-upu-na-mojej-stronie-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-o-codigo-de-snippet-de-um-pop-up-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-codice-snippet-popup-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-den-snippet-code-eines-popups-auf-meiner-website-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-le-code-d-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRastrearClientesEmMinhaLojaMagento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-clientes-em-minha-loja-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-clientes-em-minha-loja-magento.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-i-clienti-del-mio-negozio-magento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-klientow-wewnatrz-mojego-sklepu-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEIlMonitoraggioDelTassoDiApertura = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezdyFormamiIVsplyvayushimiOknamiIYstarevshimiFormami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsareTaggingEPunteggioTramiteApi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeServeryImen = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogePrzesylacDaneSubskrybentaNaWlasnaStroneZPodziekowaniemWFormularzachLegacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyFormularzeIPopUpySaDostepneWKontachDarmowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html",
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieRodzajeFormularzyMogeStworzyc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycPopUpUzywajacSzablonow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreNameservers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-nameservers.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-servery-imen.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-sa-tzw-nameservers.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-nameserver.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-servidores-de-nomes.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-servidores-de-nombre.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-nameserver.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-serveur-de-noms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacFormularzZapisuDoStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaMiedzyFormularzemKontaktowymAFormularzemZapisu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVstavitVideoSUrl = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVstavitIzobrazenieSUrlAdresa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitIkonkiSocseteiNaFormyIliVsplyvayusheeOkno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitTipVsplyvayushegoOkna = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitTaimer = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitIRedaktirovatTekstovyjBlok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitEkranPosleOtpravkiSoobsheniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitRazmerVsplyvayushegoOkna = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguDobavitIRedaktirovatKnopky = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyYpravliatZakrytiemVsplyvayusihOkon = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitFormyRegistracii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezdyKontaktnoiFormojIFormojRegistracii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenFormsAndPopupsAndLegacyForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-forms-and-popups-and-legacy-forms.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-formami-i-vsplyvayushimi-oknami-i-ystarevshimi-formami.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzami-i-pop-upami-a-starszym-edytorem-formularzy.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-formularios-e-pop-ups-e-formularios-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-los-formularios-y-ventanas-emergentes-y-los-formularios.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-formularen-und-popups-und-legacy-formularen.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-moduli-e-popup-e-moduli-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-formulaires-et-les-pop-ups-et-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieTipyFormYaMogySozdavat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenAContactFormAndASignupForm2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoj-i-formoj-registracii.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-zapisu-a-formularzem-kontaktowym.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIEmbedAVideoFromAUrl2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-s-url.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incrustar-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-von-einer-url-einbetten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-un-video-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddATimer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-taimer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-timer-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-temporizador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-licznik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-temporizador.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-timer.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDostypnyLiFormyIVsplyvayushieOknaDlyaBesplatnychAkkayntov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html",
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAndEditATextBlock = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-text-block.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-i-redaktirovat-tekstovyj-blok.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-bloque-de-texto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-i-edytowac-element-tekstowy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-bloco-de-texto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIEmbedAnImageFromAUrl = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAndEditAButton = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-button.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-redaktirovat-knopky.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-hinzufuegen-und-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-boton.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-edytowac-przyciski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-e-modificare-un-pulsante.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddASignupForm = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-formy-registracii.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-suscripcion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-anmeldeformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-modulo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatVsplyvayusheeOknoSPomoshyuShablona = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIRenewADomainPurchasedWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-renew-a-domain-purchased-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-prodlit-domen-kyplennuj-s-pomosju-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-renovar-un-dominio-comprado-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-odnowic-domene-zakupiona-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-bei-getresponse-erworbene-domain-verlaengern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-renovar-um-dominio-adquirido-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rinnovare-un-dominio-acquistato-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-renouveler-un-domaine-achete-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeWylaczycZapisyPrzezFormularzWCzystymHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacFormularzKontaktowyDoStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarClientesEventbriteAMinhaListaDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaOFiltroDivisor = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueOCaminhoNegativoNaoEAcionadoParaMinhaCondicaoInicial = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmaCampanhaDeReconfirmacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeRevisoAsNewslettersQueUmDesignerCria = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearATaxaDeAberturaDasNewslettersDeTextoSimples = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoVerMinhasMensagensEntregues = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaRemoverUmaListaDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoPersonalizarAPaginaDeConfirmacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAlterarOIdiomaDaMinhaListaEMensagemDeConfirmacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoVerComoEstaoMinhasMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoPostarAtualizacoesDoTwitterEDoFacebookQuandoEnvioUmBoletimInformativo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoOZapierComMinhaContaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueAsMensagensEnviadasParaMeuProprioDominioSaoRejeitadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoIncluirItensDeCarrinhoAbandonadosEmUmaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"en": "https://www.getresponse.com/help/how-can-i-include-abandoned-cart-items-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incluir-articulos-de-carrito-abandonado-en-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-v-soobshenie-predmety-iz-broshennoj-korziny.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-includere-gli-articoli-del-carrello-abbandonato-in-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zawrzec-produkty-z-porzuconego-koszyka-w-wiadomosci.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-inclure-les-articles-du-panier-abandonne-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIIntegrateMyAccountWithPayu = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-integrate-my-account-with-payu.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-payu.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintergrowac-konto-z-payu.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-payu.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-payu.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-payu-integrieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-integrirovat-svoy-akkaunt-s-payu.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionoFormulariosPopUpAUmaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddSocialMediaIconsToMyFormAndPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-form-and-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ikonki-socsetei-na-formy-ili-vsplyvayushee-okno.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-los-iconos-de-redes-sociales-a-mi-formulario-y-ventana-emergente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-ikony-mediow-spolecznosciowych-do-moich-formularzy-i-pop-upow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-midia-social-ao-meu-formulario-e-pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meinem-formular-und-popup-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-le-icone-dei-social-media-al-mio-modulo-e-popup.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-formulaire-et-a-mon-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIManagePopupClosing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-manage-popup-closing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ypravliat-zakrytiem-vsplyvayusih-okon.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-el-cierre-de-las-ventanas-emergentes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-ustawic-zamkniecie-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-das-schliessen-von-popups-verwalten.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-o-fechamento-de-pop-ups.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-la-chiusura-dei-pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-la-fermeture-des-pop-ups.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeTheSuccessView = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-the-success-view.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-ekran-posle-otpravki-soobsheniya.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-vista-despues-de-la-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-widok-po-zapisie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-ansicht-bei-erfolg.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-a-visao-de-sucesso.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-la-visualizzazione-del-successo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-changer-la-vue-succes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeTheSizeOfPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-the-size-of-popup.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-razmer-vsplyvayushego-okna.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rozmiar-pop-upu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-groesse-des-popups-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tamanho-do-pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-la-dimensione-del-popup.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tamano-de-la-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-taille-du-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeThePopupType = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-the-popup-type.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-tip-vsplyvayushego-okna.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-rodzaj-pop-upu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-tipo-di-popup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-den-popup-typ-aendern.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-tipo-de-pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-tipo-de-ventana-emergente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-type-de-pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAPopupUsingATemplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-a-popup-using-a-template.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vsplyvayushee-okno-s-pomoshyu-shablona.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-pop-up-uzywajac-szablonow.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-ventana-emergente-usando-una-plantilla.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-popup-usando-um-modelo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-popup-mit-einer-vorlage.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-popup-utilizzando-un-modello.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-pop-up-a-l-aide-d-un-modele.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAreFormsAndPopupsAvailableForFreeAccounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/are-forms-and-popups-available-for-free-accounts.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostypny-li-formy-i-vsplyvayushie-okna-dlya-besplatnych-akkayntov.html",
"pl": "https://www.getresponse.pl/pomoc/czy-formularze-i-pop-upy-sa-dostepne-w-kontach-darmowych.html",
"es": "https://www.getresponse.com/es/ayuda/hay-formularios-y-ventanas-emergentes-en-las-cuentas-gratuitas.html",
"br": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-formularios-e-pop-ups-estao-disponiveis-para-contas-gratuitas.html",
"de": "https://www.getresponse.com/de/hilfe/sind-formulare-und-popups-fuer-free-accounts-verfuegbar.html",
"it": "https://www.getresponse.com/it/assistenza/i-moduli-e-i-popup-sono-disponibili-per-gli-account-gratuiti.html",
"fr": "https://www.getresponse.com/fr/aide/les-formulaires-et-les-pop-ups-sont-ils-disponibles-pour-les-comptes-gratuits.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatTypesOfFormsCanICreate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-types-of-forms-can-i-create.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-tipy-form-ya-mogy-sozdavat.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-formularzy-moge-stworzyc.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-formularios-puedo-crear.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipos-de-formularios-posso-criar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-formularen-kann-ich-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-moduli-posso-creare.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-formulaires-puis-je-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateShopifyPromoCodeInGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-shopify-promo-code-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-codigo-promocional-de-shopify-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-kod-promocyjny-shopify-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-promokod-shopify-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-codigo-promocional-da-shopify-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-codice-promozionale-shopify-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-shopify-aktionscode-in-getresponse-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-code-promo-shopify-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzypisacTagiIPunktyScoringowePodczasImportu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinKostenlosesKontoAktivieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-kostenloses-konto-aktivieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-free-akkaynt-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-my-free-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-aktywowac-darmowe-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-cuenta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-account-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacGetresponseZShopify = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeAktywowacDarmoweKonto = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-aktywowac-darmowe-konto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-free-akkaynt-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-kostenloses-konto-aktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-cuenta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-account-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoMojWlasnyTematNieZostalZatwierdzony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacWiadomoscPotwierdzajacaSubskrypcje = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyAktivirovatFreeAkkayntGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-free-akkaynt-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-my-free-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-aktywowac-darmowe-konto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-kostenloses-konto-aktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-cuenta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-account-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIActivateMyFreeAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-activate-my-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-free-akkaynt-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-aktywowac-darmowe-konto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-kostenloses-konto-aktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-mi-cuenta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-il-mio-account-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacStronePotwierdzajaca = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacGetresponseZClickfunnels = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKursOnlineWProgramieAfiliacyjnym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotaetFiltrRazdelitel = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaDobavitProdyktyEtsyVSvoiAkkayny = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTagowanieKontaktowZProgramuAfiliacyjnego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html",
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestLandingPageIDoCzegoMozeBycPotrzebny = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakMnePodkljuchitSvoiAkkauntyFacebookIGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoVenderUmEbookUsandoOFunilDeConversao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueMeusContatosNaoReceberamAMinhaNewsletter = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarEEnviarUmSms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostScoringKontaktowZProgramuAffiliacyjnego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguIntegrirovatShopifyIGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSoVerwendenSieDieAktionPushBenachrichtigungSenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchGetresponseChats = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSendeIchEineEMailUeberGetresponseChats = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasPassiertWennIchDieVeroeffentlichungEinesWorkflowsAufhebe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoEmailsTransacionaisRapidosEParaQuePossoUsaLos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmEMailDeConfirmacaoDePedidoEComoPossoCriaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIntegrarShopifyYGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoIntegrarShopifyEGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIIntegrateShopifyAndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-integrate-shopify-and-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-shopify-e-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-shopify-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-integrirovat-shopify-i-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-shopify.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-shopify-und-getresponse-integrieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-shopify-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-shopify-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoVerificarUmDominioNoFacebookBusinessManager = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstaloOCertificadoSslParaMeusDominiosPersonalizados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZmienicLoginKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoSieDziejeGdyWylaczeCyklAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarElLoginDeMiCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAlterarOLoginDaMinhaConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeMyAccountLogin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-my-account-login.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-login-da-minha-conta.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-login-de-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-login-konta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-login-akkaynta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-kontoanmeldung-aendern.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-login-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-identifiant-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoProishoditKogdaYaOtmenyajuPublikacijuProcessa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIntegrarMiSitioWebConGoogleTagManager = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdIntegrareIlMioSitoConIlGoogleTagManager = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSuccedeQuandoAnnulloLaPubblicazioneDiUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZamknacKontoFree = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeSledzicOdwiedzinyISubskrypcjeNaSwojejStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuePuedenHacerLosOperadoresDentroDeGetresponseChats = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearSubpaginas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html",
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeSledzicMojaStroneInternetowaUzywajacPikselaFacebooka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSledzicKonwersjeFormularzyNaMojejStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeEMailTransazionaliRapideEPerCosaSiUsano = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueAconteceQuandoEuDespublicoUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSucedeCuandoCanceloLaPublicacionDeUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeDodacKodRabatowyDoMoichWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatHappensWhenIUnpublishAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-happens-when-i-unpublish-a-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-proishodit-kogda-ya-otmenyaju-publikaciju-processa.html",
"es": "https://www.getresponse.com/es/ayuda/que-sucede-cuando-cancelo-la-publicacion-de-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-dzieje-gdy-wylacze-cykl-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-quando-eu-despublico-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-annullo-la-pubblicazione-di-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-die-veroeffentlichung-eines-workflows-aufhebe.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-lorsque-je-depublie-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIDisablePlainHtmlSignUps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-disable-plain-html-sign-ups.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wylaczyc-zapisy-przez-formularz-w-czystym-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otklychit-registraciyu-na-prostyh-html-formah.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-plain-html-anmeldungen-deaktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-desactivar-las-suscripciones-en-html-plano.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-desativar-inscricoes-em-html-simples.html",
"it": "https://www.getresponse.com/it/assistenza/posso-disabilitare-le-semplici-registrazioni-html.html",
"fr": "https://www.getresponse.com/fr/aide/comment-desactiver-les-inscriptions-en-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWyslacEmailPoprzezCzatyGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSledzicKliknieciaNaTwojejStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSendeIchAnhaengeInGetresponseChats = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerfolgeIchMeineWebsiteMitDemFacebookPixel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineWebsiteMitDemKiAssistenten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasKoennenOperatorenInGetresponseChatsTun = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaPoliticaDeiRimborsiDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdUsareLaFunzioneDesignGlobaleNelWebsiteBuilder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereLeIconeDeiSocialMediaAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnoSpaziatoreAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioATracciareLaConversioneDeiModuliSulMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeBysrtieTranzakcionnyePismaIKakIhMoznoIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchGetresponseChatsStatistiken = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchZapierMitMeinemGetresponseKonto = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriertManMagento2MitGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereIDivisoriAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioATracciareClicsSulMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeTracciareIlTrafficoSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdUsareLaFunzioneMembri = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUtilizzareLeSezioniSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPolnostiuIntegrirovatPrestashopSGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAssegnareIlMioSottodominioAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoModificarElPieDePaginaDeMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAModificareIlPieDiPaginaDelMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCancellareUnAccountFree = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreQuickTransactionalEmailsAndWhatCanIUseThemFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-quick-transactional-emails-and-what-can-i-use-them-for.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-bysrtie-tranzakcionnye-pisma-i-kak-ih-mozno-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-e-mail-transazionali-rapide-e-per-cosa-si-usano.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-emails-transacionais-rapidos-e-para-que-posso-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-wiadomosci-transakcyjne-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-schnelle-transaktionale-e-mails-und-wofuer-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-correos-electronicos-transaccionales-rapidos-y-para-que-puedo-utilizarlos.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-emails-transactionnels-rapides-et-a-quoi-peuvent-ils-servir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSetUpSmsMarketingInTheUsaAndCanada = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-set-up-sms-marketing-in-the-usa-and-canada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-sms-marketing-v-ssha-i-kanade.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-il-marketing-via-sms-negli-stati-uniti-e-in-canada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-sms-marketing-w-usa-i-kanadzie.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-el-marketing-por-sms-en-estados-unidos-y-canada.html",
"br": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-estabelecer-o-sms-marketing-nos-eua-e-no-canada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-sms-marketing-in-den-usa-und-kanada-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-le-marketing-par-sms-aux-etats-unis-et-au-canada.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBindeIchEinVideoVonEinerUrlEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineNachrichtMitDenMobilenAppsVonGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-mit-den-mobilen-apps-von-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-uma-mensagem-usando-a-aplicacao-movel-dagetresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-uma-mensagem-usando-a-aplicacao-movel-dagetresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-soobshenie-s-pomoshiu-mobilnych-prilozenij-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKoennenSieDenVerkehrAufIhrerWebsiteVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieWebsiteEinstellungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKoennenSieKlicksAufIhrerWebsiteVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEineSeiteVonEinerExternenUrlZumWebsiteMenueHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieFormularkonvertierungAufMeinerWebsiteNachverfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstAppleMailPrivacyProtectionUndWieWirktEsSichAufUnsereZustellbarkeitAus = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZakrytFreeAkkaynt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLoescheIchEinFreeAccount = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCancelarUnaCuentaGratuita = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCancelarUmaContaGratuita = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCancelAFreeAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-cancel-a-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-ein-free-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zakryt-free-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-cancellare-un-account-free.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelar-una-cuenta-gratuita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelar-uma-conta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-un-compte-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAnAbandonedCartMessageAndHowCanICreateIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-an-abandoned-cart-message-and-how-can-i-create-it.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-o-broshennoj-korzine-kak-ego-sozdat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-porzuconym-koszykiem-i-jak-ja-stworzyc.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-email-de-carrinho-abandonado-e-como-posso-cria-lo.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-correo-electronico-de-carrito-abandonado-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-e-mail-fuer-verlassene-warenkoerbe-und-wie-kann-ich-sie-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-e-mail-di-carrello-abbandonata-e-come-posso-crearla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-e-mail-de-panier-abandonne-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAnOrderConfirmationMessageAndHowCanICreateIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-an-order-confirmation-message-and-how-can-i-create-it.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-pismo-s-podtverzdeniem-zakaza-kak-ego-sozdat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-e-mail-de-confirmacao-de-pedido-e-como-posso-cria-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-wiadomosc-z-potwierdzeniem-zamowienia-i-jak-moge-ja-stworzyc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-l-e-mail-di-conferma-dell-ordine-e-come-posso-crearla.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-correo-electronico-de-confirmacion-de-pedido-y-como-puedo-crearlo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-bestellbestaetigungs-email-und-wie-kann-ich-sie-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-e-mail-de-confirmation-de-commande-et-comment-le-creer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareIlPunteggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaFuncionDePosicionamientoAbsoluto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUsareLaFunzioneDiPosizionamentoAssoluto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInquerito = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pt": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pl": "https://www.getresponse.pl/pomoc/ankieta.html",
"en": "https://www.getresponse.com/help/survey.html",
"de": "https://www.getresponse.com/de/hilfe/survey.html",
"es": "https://www.getresponse.com/es/ayuda/encuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/opros.html",
"it": "https://www.getresponse.com/it/assistenza/sondaggio.html",
"fr": "https://www.getresponse.com/fr/aide/enquete.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRelatorio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pl": "https://www.getresponse.pl/pomoc/raport.html",
"en": "https://www.getresponse.com/help/report.html",
"de": "https://www.getresponse.com/de/hilfe/bericht.html",
"es": "https://www.getresponse.com/es/ayuda/informe.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet.html",
"it": "https://www.getresponse.com/it/assistenza/report.html",
"fr": "https://www.getresponse.com/fr/aide/rapport.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBounceSuave = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pt": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-miekki.html",
"en": "https://www.getresponse.com/help/soft-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/soft-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-transitorio.html",
"ru": "https://www.getresponse.com/ru/help-ru/vremennaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-morbido.html",
"fr": "https://www.getresponse.com/fr/aide/rebond-doux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampoDeResponderPara = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pl": "https://www.getresponse.pl/pomoc/pole-odpowiedz-do.html",
"en": "https://www.getresponse.com/help/reply-to-field.html",
"de": "https://www.getresponse.com/de/hilfe/reply-to-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-respuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-dlya-otveta.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-di-risposta.html",
"fr": "https://www.getresponse.com/fr/aide/champ-de-reponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTesteDeSeparacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"pl": "https://www.getresponse.pl/pomoc/testy-skutecznosci-ang-split-test.html",
"en": "https://www.getresponse.com/help/split-test.html",
"de": "https://www.getresponse.com/de/hilfe/split-test.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-multivariantes.html",
"ru": "https://www.getresponse.com/ru/help-ru/split-test.html",
"it": "https://www.getresponse.com/it/assistenza/split-test.html",
"fr": "https://www.getresponse.com/fr/aide/essai-fractionne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFicheiroZip = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"pt": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"pl": "https://www.getresponse.pl/pomoc/zip.html",
"en": "https://www.getresponse.com/help/zip-file.html",
"de": "https://www.getresponse.com/de/hilfe/zip-file.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-zip.html",
"ru": "https://www.getresponse.com/ru/help-ru/zip-fajl.html",
"it": "https://www.getresponse.com/it/assistenza/zip-file.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-zip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptInNaoConfirmado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-bez-potwierdzenia.html",
"en": "https://www.getresponse.com/help/non-confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/non-confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-no-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-bez-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-non-confermato.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-non-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNumeroDeRastreioDeReferencia = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pl": "https://www.getresponse.pl/pomoc/ref-numer-sledzacy.html",
"en": "https://www.getresponse.com/help/ref-tracking-number.html",
"de": "https://www.getresponse.com/de/hilfe/ref-tracking-number.html",
"es": "https://www.getresponse.com/es/ayuda/numero-de-seguimiento-de-la-referencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/nomer-dla-otslezivaniya.html",
"it": "https://www.getresponse.com/it/assistenza/numero-di-riferimento.html",
"fr": "https://www.getresponse.com/fr/aide/ref-numero-de-suivi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaBranca = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"pl": "https://www.getresponse.pl/pomoc/whitelist-biala-lista-subskrybentow.html",
"en": "https://www.getresponse.com/help/whitelist.html",
"de": "https://www.getresponse.com/de/hilfe/whitelist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-blanca.html",
"ru": "https://www.getresponse.com/ru/help-ru/belyj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/whitelist.html",
"fr": "https://www.getresponse.com/fr/aide/liste-blanche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRemocoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pl": "https://www.getresponse.pl/pomoc/usuniete.html",
"en": "https://www.getresponse.com/help/removals.html",
"de": "https://www.getresponse.com/de/hilfe/removals.html",
"es": "https://www.getresponse.com/es/ayuda/bajas.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpisavshiesya-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/contatti-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/suppressions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLinhaDeAssunto = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pt": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pl": "https://www.getresponse.pl/pomoc/temat-wiadomosci.html",
"en": "https://www.getresponse.com/help/subject-line.html",
"de": "https://www.getresponse.com/de/hilfe/subject-line.html",
"es": "https://www.getresponse.com/es/ayuda/linea-de-asunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/tema-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/oggetto.html",
"fr": "https://www.getresponse.com/fr/aide/ligne-d-objet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatoDaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/format-wiadomosci.html",
"en": "https://www.getresponse.com/help/message-format.html",
"de": "https://www.getresponse.com/de/hilfe/message-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-del-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/format-soobshheniya.html",
"it": "https://www.getresponse.com/it/assistenza/formato-del-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/format-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormularioWeb = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"pl": "https://www.getresponse.pl/pomoc/formularz-zapisu.html",
"en": "https://www.getresponse.com/help/web-form.html",
"de": "https://www.getresponse.com/de/hilfe/web-form.html",
"es": "https://www.getresponse.com/es/ayuda/formulario-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/veb-forma.html",
"it": "https://www.getresponse.com/it/assistenza/modulo-web.html",
"fr": "https://www.getresponse.com/fr/aide/formulaire-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNotificacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"pl": "https://www.getresponse.pl/pomoc/notification-powiadomienia.html",
"en": "https://www.getresponse.com/help/notification.html",
"de": "https://www.getresponse.com/de/hilfe/notification.html",
"es": "https://www.getresponse.com/es/ayuda/notificacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/uvedomlenie-o-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/notificazione.html",
"fr": "https://www.getresponse.com/fr/aide/notification.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalizacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pl": "https://www.getresponse.pl/pomoc/personalizacja.html",
"en": "https://www.getresponse.com/help/personalization.html",
"de": "https://www.getresponse.com/de/hilfe/personalization.html",
"es": "https://www.getresponse.com/es/ayuda/personalizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/personalizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/personnalisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReclamacaoDeSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/zgloszenie-spamu.html",
"en": "https://www.getresponse.com/help/spam-complaint.html",
"de": "https://www.getresponse.com/de/hilfe/spam-complaint.html",
"es": "https://www.getresponse.com/es/ayuda/denuncia-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zhaloba-na-spam.html",
"it": "https://www.getresponse.com/it/assistenza/reclamo-spam.html",
"fr": "https://www.getresponse.com/fr/aide/plainte-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModeloDeNewsletter = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-newslettera.html",
"en": "https://www.getresponse.com/help/newsletter-template.html",
"de": "https://www.getresponse.com/de/hilfe/newsletter-template.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/shablon-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPreCarga = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/pre-carga.html",
"pt": "https://www.getresponse.com/pt/ajuda/pre-carga.html",
"de": "https://www.getresponse.com/de/hilfe/pre-load.html",
"es": "https://www.getresponse.com/es/ayuda/pre-carga.html",
"ru": "https://www.getresponse.com/ru/help-ru/kopirovanie-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/precaricamento.html",
"fr": "https://www.getresponse.com/fr/aide/pre-load.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRelatoriosDeSubscricao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/custom-reports.html",
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnderecoPostal = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pl": "https://www.getresponse.pl/pomoc/adres-pocztowy.html",
"en": "https://www.getresponse.com/help/postal-address.html",
"de": "https://www.getresponse.com/de/hilfe/postal-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-postal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-postale.html",
"fr": "https://www.getresponse.com/fr/aide/adresse-postale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingComBaseEmPermissoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-za-przyzwoleniem.html",
"en": "https://www.getresponse.com/help/permission-based-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-based-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-basado-en-permisos.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketing-na-osnove-razreshenij.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-basato-sul-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-base-sur-les-permissions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFundirPalavra = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"pt": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"pl": "https://www.getresponse.pl/pomoc/merge-word.html",
"en": "https://www.getresponse.com/help/merge-word.html",
"de": "https://www.getresponse.com/de/hilfe/merge-word.html",
"es": "https://www.getresponse.com/es/ayuda/fusion-de-palabras.html",
"ru": "https://www.getresponse.com/ru/help-ru/slovo-obedenenie.html",
"it": "https://www.getresponse.com/it/assistenza/fusione-di-parole.html",
"fr": "https://www.getresponse.com/fr/aide/fusionner-des-mots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTextoSimplesPlainText = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-w-formacie-tekstowym.html",
"en": "https://www.getresponse.com/help/plain-text.html",
"de": "https://www.getresponse.com/de/hilfe/plain-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-sin-formato.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/testo-semplice.html",
"fr": "https://www.getresponse.com/fr/aide/texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubscritores = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pl": "https://www.getresponse.pl/pomoc/subskrybent.html",
"en": "https://www.getresponse.com/help/subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpischiki.html",
"it": "https://www.getresponse.com/it/assistenza/contatti.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCancelamentoDeAssinaturaMultiplaDesativacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"de": "https://www.getresponse.com/de/hilfe/multi-unsubscribe-opt-out.html",
"en": "https://www.getresponse.com/help/multi-unsubscribe-opt-out.html",
"es": "https://www.getresponse.com/es/ayuda/desinscripcion-multiple-opt-out.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovyj-otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-multipla-opt-out.html",
"pl": "https://www.getresponse.pl/pomoc/multi-unsubscribe-wypis.html",
"fr": "https://www.getresponse.com/fr/aide/desabonnement-multiple-opt-out.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAumentarAMinhaBaseDeDadosDeContactosIntegrandoGetresponseComOMagento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"it": "https://www.getresponse.com/it/assistenza/posso-far-crescere-il-mio-database-di-contatti-integrando-getresponse-con-magento.html",
"en": "https://www.getresponse.com/help/can-i-grow-my-contact-database-by-integrating-getresponse-with-magento.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakt-datenbank-erweitern-indem-ich-getresponse-mit-magento-integriere.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-crecer-mi-base-de-datos-de-contactos-integrando-getresponse-con-magento-2.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-developper-ma-base-de-donnees-de-contacts-en-integrant-getresponse-a-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchTrennlinienZuMeinerWebsiteHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindSpamTrapsUndSperrlisten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-spam-traps-und-sperrlisten.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-trampas-de-spam-y-las-listas-de-bloqueo.html",
"en": "https://www.getresponse.com/help/what-are-spam-traps-and-blocklists.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam-lovyshki-i-spiski-blokirovki.html",
"br": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pt": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-spam-trapy-oraz-listy-zablokowanych-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-trappole-anti-spam-e-le-liste-di-blocco.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pieges-a-spam-et-les-listes-de-blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMicrosoftDynamics365InGetresponseAktivieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEineEigeneSubdomainZuweisen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineWebsiteFuerMobilgeraeteResponsiverMachen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenBenutzerdefiniertenHtmlCodeAufMeinerWebsiteVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineFunktionFuerVertrauenswuerdigeGeraeteUndWieKannIchSieVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenEMailsEinenPromoCodeHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenWebhook = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereEGestireUnPopUpSulMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdUsareIlCodiceHtmlPersonalizzatoSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoAggiornareIlMioAccountFree = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEIlLimiteDiLarghezzaDiBandaDiUnSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeDodawacIEdytowacPrzyciskiNaSwojejStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereIDettagliSeoAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraUnModuloDiContattoEUnModuloDiIscrizione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnModuloDiContattoAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWebhook = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnModuloDiIscrizioneAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitPromokodVPismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnWebhook = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatWebhook = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAImportareIFileViaFtp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchGetresponseMitAmazon = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchSocialMediaSymboleZuMeinerWebsiteHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchAbschnitteAufMeinerWebsiteVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIntegrarCompletamenteMiPrestashopConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBindeIchEinFotoVonEinerUrlEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteSeoDetailsHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoIntegrarTotalmenteMinhaPrestashopComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnWebhook = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmWebhook = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineWebsiteMitVorlagen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchMeinerWebsiteEinePasswortgeschuetzteSeiteHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieEinstellungenMeinerSeitenAndern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieFusszeileMeinerWebsiteBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAWebhook = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-webhook.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-webhook.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webhook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-webhook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webhook.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-webhook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-webhook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webhook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeEdytowacStopkeMojejStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacFunkcjiPozycjonowanieBezwzgledne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeSprawicAbyMojaStronaInternetowaBylaBardziejResponsywnaDlaUrzadzenMobilnych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToJestLimitPrzepustowosciStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZapisacSekcjeIBlokiAbyMocIchUzycPonownie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoInviareInvitiAiWebinarAPersoneCheNonSonoSullaMiaListaDiIscritti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnCodicePromozionaleAiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarLaPaginaDeInicio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoDuplicarUnaPagina = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEliminarUnaPagina = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/how-can-i-delete-a-page.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ydalit-stranitsy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-podstrone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoOcultarUnaPaginaDelMenuDelSitio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAsignarMiPropioDominioAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoNavegarEntrePaginasEnElEditorDelSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html",
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarElOrdenDeLasPaginasEnElMenuDelSitio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoHacerUnSeguimientoDeMiSitioWebConGoogleAnalytics = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnSitioWebConPlantillas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreUnaPaginaDeAterrizajeYUnSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarGetresponseConAmazon = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnCodigoPromocionalAMisCorreosElectronicos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmCodigoPromocionalAosMeusEMails = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacSekcjiNaStronieInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakEdytowacStroneNieZnalezionoOrazStroneZHaslem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIFullyIntegrateMyPrestashopWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-fully-integrate-my-prestashop-with-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-totalmente-minha-prestashop-com-a-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-completamente-mi-prestashop-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polnostiu-integrirovat-prestashop-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-completamente-il-mio-prestashop-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-w-pelni-zintegrowac-prestashop-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-voll-meinen-prestashop-mit-getresponse-verknuepfen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-completement-ma-boutique-prestashop-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchGetresponseChatsEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindGetresponseChats = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEinKontaktformularHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEineBildergalerieHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEinenTimerHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinPopupAufMeinerWebsiteHinzufuegenUndVerwalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEinenSpacerHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycPodstrony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacLinkDoMenuStrony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEinAnmeldeformularHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineWebPushBenachrichtigung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSinhronizirovatKontakyMezhdyGetresponseIMicrosoftDynamics365 = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyAktivirovatMicrosoftDynamics365VGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicStroneDomowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZduplikowacPodstrone3 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUsunacPodstrone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-podstrone.html",
"de": "https://www.getresponse.com/de/hilfe/how-can-i-delete-a-page.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ydalit-stranitsy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacPodstronamiWMenuStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicKolejnoscPodstronWMenuStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSprzedacEbookPrzezLejekKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaMiedzyAutoresponderemACyklemAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoMojeKontaktyNieOtrzymalyWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenNachrichtenEineAbmeldungVomSmsLinkHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEinenPaypalButtonHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeWyswietlicDostarczoneWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAPromoCodeToMyEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-promo-code-to-my-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-promokod-v-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-codigo-promocional-aos-meus-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-codigo-promocional-a-mis-correos-electronicos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-codice-promozionale-ai-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-e-mails-einen-promo-code-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-kod-rabatowy-do-moich-wiadomosci.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-ajouter-un-code-promo-a-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmOptOutAPartirDeUmaLigacaoSmsAsMinhasMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIEmbedAnImageOrButtonWithinATextBlock = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-bild-oder-einen-button-in-ein-textelement-einfuegen.html",
"en": "https://www.getresponse.com/help/can-i-embed-an-image-or-button-within-a-text-block.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vstavit-izobrazhenije-ili-knopku-tekstovy-blok.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-obrazek-lub-przycisk-w-bloku-tekstowym.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-incrustar-una-imagen-o-un-boton-dentro-de-un-bloque-de-texto.html",
"it": "https://www.getresponse.com/it/assistenza/posso-incorporare-un-immagine-o-un-pulsante-all-interno-di-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-une-image-ou-un-bouton-dans-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCriarNewslettersResponsivosADispositivosMoveis = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAMinhaMensagemTemUmaTaxaAbertaDe0Porque = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html",
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUsunacListeKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAcrescentarOutraMensagemAUmCicloDeAutoresponderJaExistente = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEncontrarOsMeusTemplatesETrabalharComEles = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarUnaGaleriaDeImagenesAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoMudarOEnderecoDeRemetenteNasMinhasMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-absender-e-mail-adresse-in-meinen-nachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-adres-otpravitela-v-moich-soobshenijach.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-email-nadawcy-w-wiadomosciach.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-direccion-de-en-mis-mensajes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-l-indirizzo-di-mittente-nei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-de-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitVSvoiSmsSoobsheniyaSsulkyDlyaOtpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCargarUnaListaAUnaCuentaGetresponseMax = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAgregoElSeguimientoDeConversionesDeGoogleAdsAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarUnFormularioDeRegistroAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenButtonAufMeinerWebsiteHinzufuegenUndBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarYAdministrarUnBloqueDeTextoEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAktiviereIchGetresponseChatsAufEinerLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACercareIContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEinenGetresponseChatsLinkInEineNachrichtEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarYAdministrarUnaVentanaEmergenteEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchKontakteUeberGetresponseChatsHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSperreIchJemandenInGetresponseChats = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieInstalliereIchDenGetresponseChatsCodeAufMeinerWebsite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchBenachrichtigungenAufSlackErhalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindVordefinierteNachrichtenUndWieWerdenSieVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAnOptOutFromSmsLinkToMyMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-an-opt-out-from-sms-link-to-my-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-v-svoi-sms-soobsheniya-ssulky-dlya-otpiski.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-nachrichten-eine-abmeldung-vom-sms-link-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-opt-out-a-partir-de-uma-ligacao-sms-as-minhas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-para-darse-de-baja-de-los-sms-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-rezygnacji-z-otrzymywania-sms-ow-do-tresci-wiadomosci-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-link-di-opt-out-da-sms-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-de-desinscription-par-sms-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatFynkcijuAbsolutnogoPozicionirovaniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRedaktirovatFyterMoegoSajta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarAFuncaoDePosicionamentoAbsoluto = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEditarORodapeDoMeuWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoActivarOMicrosoftDynamics365EmGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAProtecaoDaPrivacidadeDoCorreioDaAppleEComoEQueAfetaANossaCapacidadeDeEntrega = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarAFuncaoDeMembro = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIActivateMicrosoftDynamics365InGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-activate-microsoft-dynamics-365-in-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-aktivirovat-microsoft-dynamics-365-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-microsoft-dynamics-365-in-getresponse-aktivieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-activar-o-microsoft-dynamics-365-em-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-activar-microsoft-dynamics-365-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-aktywowac-microsoft-dynamics-365-w-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-attivare-microsoft-dynamics-365-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-microsoft-dynamics-365-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTheAbsolutePositioningFeature = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-the-absolute-positioning-feature.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciju-absolutnogo-pozicionirovaniya.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-pozycjonowanie-bezwzgledne.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-la-funzione-di-posizionamento-assoluto.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-posicionamiento-absoluto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-posicionamento-absoluto.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-absolute-positionierungsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonction-de-positionnement-absolu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIEditMyWebsiteFooter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-edit-my-website-footer.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-redaktirovat-fyter-moego-sajta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-edytowac-stopke-mojej-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-fusszeile-meiner-website-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-pie-di-pagina-del-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-modificar-el-pie-de-pagina-de-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-o-rodape-do-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-pied-de-page-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCondizioniPossoUsarePerCercareContattiECreareSegmenti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html",
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEUnSegmento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioARendereIlMioSitoPiuMobileFriendly = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeZashitaKonfidencialnostiAppleMailIOnaVliyaetNaVozmognostDostavkiPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosaSonoLePagineDiUpsellingDiConversionFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdIncorporareUnVideoDaUnUrl = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdIncorporareUnImmagineDaUnUrl = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEPerfectTimingEComeFunziona = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereContattiAlleMieListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereEGestireLeImmaginiSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAppleMailPrivacyProtectionAndHowDoesItAffectOurDeliverability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-apple-mail-privacy-protection-and-how-does-it-affect-our-deliverability.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zashita-konfidencialnosti-apple-mail-i-ona-vliyaet-na-vozmognost-dostavki-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-apple-mail-privacy-protection-und-wie-wirkt-es-sich-auf-unsere-zustellbarkeit-aus.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-protecao-da-privacidade-do-correio-da-apple-e-como-e-que-afeta-a-nossa-capacidade-de-entrega.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-proteccion-de-privacidad-de-apple-mail-y-como-afecta-a-nuestra-capacidad-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-protezione-della-privacy-di-apple-mail-e-in-che-modo-influisce-sulla-nostra-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-ochrona-prywatnosci-w-aplikacji-apple-mail-i-jaki-ma-wplyw-na-nasza-dostarczalnosc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-apple-mail-privacy-protection-et-comment-affecte-t-elle-notre-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNapominanieOBroshennoiKorzine = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaDobavitKlientovEventbriteVSvoiSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieEcommerceDannyeOtpravlyautsiaVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatFynkciyuYchastniki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmSiteUsandoTemplates = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearMeuSiteComOFacebookPixel = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestZaufaneUrzadzenieIJakMogeZNiegoKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWysylacZalacznikiPrzezCzatGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoRastrearMeuSiteComAYandexMetrica = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarORastreamentoDeConversaoDoGoogleAdsAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearMeuSiteComOGoogleAnalytics = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoIntegrarMeuSiteComOGoogleTagManager = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAtribuirMeuProprioDominioAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoComprarUmDominioComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAlterarAOrdemDasPaginasNoMenuDoSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoNavegarEntreAsPaginasDoEditorDoSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html",
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoGerenciarPaginasNoMenuDoSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacCzatamiGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEliminarUmaPagina = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/how-can-i-delete-a-page.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ydalit-stranitsy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-una-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-podstrone.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoDuplicarUmaPagina = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoMudarAPaginaInicial = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarSubpaginas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWlaczycCzatyGetresponseNaLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEditarAsPaginasNaoEncontradaEDeSenha = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSprawdzicCzyOsobaZKtoraRozmawiamNaCzacieJestWciazNaStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarODesignGlobalNoWebsiteBuilder = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarEGerenciarUmBlocoDeTextoNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarEGerenciarImagensNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarAsSecoesDoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarOMagento2ComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePodlaczycWlasnaDomeneDoMojejStronyInternetowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaIncorporarUmVideoDeUmUrl = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarDivisoresAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEuPossoAtribuirMeuProprioSubdominioAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioASalvareERiusareSezioniEBlocchi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioANavigareTraPagineNellEditorDeiSitiWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html",
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnaPaginaProtettaDaPasswordAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnaPaginaDaUnUrlEsternoAlMioMenuDelSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTheMembershipFeature = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-the-membership-feature.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-fynkciyu-ychastniki.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-funkcji-czlonkowie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-membri.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-funcao-de-membro.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-funcion-de-miembros.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-mitgliedschaftsfunktion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-fonctionnalites-adherents.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereEModificareUnMenuDelSitoAlMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaOtslizivatISobiratEcommerceDannyeVPrestashop = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVkluchitPodpiskyDlyaPosetitelejMoegoMagazinaPrestashop = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACambiareLeImpostazioniDelleMiePagine = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACambiareLeImpostazioniDelMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualELaDifferenzaTraUnaLandingPageEUnSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnSitoWebConITemplate = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnSitoWebConLIntelligenzaArtificiale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZablokowacKontaktNaCzacieGetresponse3 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaFuncionDeDispositivoDeConfianzaYComoPuedoUtilizarla = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatGetresponseSClickfunnels = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmDispositivoDeConfiancaEComoPossoUtilizaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakMneIspolzovatZapierSMoimAkkayntomGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodawacKontaktyPrzezCzatyGetresponse2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeFynkciyaDoverennogoYstroistvaIKakEeIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsATrustedDeviceFeatureAndHowCanIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-trusted-device-feature-and-how-can-i-use-it.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-fynkciya-doverennogo-ystroistva-i-kak-ee-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-dispositivo-de-confianca-e-como-posso-utiliza-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-zaufane-urzadzenie-i-jak-moge-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-funktion-fuer-vertrauenswuerdige-geraete-und-wie-kann-ich-sie-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-funcion-de-dispositivo-de-confianza-y-como-puedo-utilizarla.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-funzione-dispositivo-attendibile-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-fonction-d-appareil-approuve-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostImpegnoDiNatale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html",
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntreUmFormularioDeContatoEUmFormularioDeAssinatura = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoTornarMeuSiteMaisResponsivoADispositivosMoveis = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereEModificareUnPulsanteSulMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitSvoiLendingVWordpressIspolzujaUstarevshiPluginWordpress = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereEGestireUnBloccoDiTestoSulMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchUeberpruefenObDiePersonInGetresponseChatsNochAufDerSeiteIst = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAModificareLaPaginaNonTrovataELaPaginaInserisciPassword = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodtverditDomenVFacebookBusinessManager = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeSpisokNaOsnoveRazreshenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnLinkAlMenuDelSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioANascondereUnaPaginaDalMenuDelSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACambiareLaHomePage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeGestireLePagineNelMenuDelSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarUmCodigoHtmlPersonalizadoEmMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarIconesDeRedesSociaisAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmaPaginaDeUmUrlExternoAoMenuDoSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarDetalhesDeSeoAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmaPaginaProtegidaPorSenhaAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACambiareLOrdineDellePagineNelMenuDelSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAEliminareUnaPagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-una-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/how-can-i-delete-a-page.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ydalit-stranitsy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-podstrone.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioADuplicareUnaPagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnaSottopagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnaNuovaPaginaAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAssegnareIlMioDominioAlMioSito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueElementosPossoAcrescentarAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEOLimiteDeLarguraDeBandaDeUmSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoGestionarLasPaginasEnElMenuDelSitio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeKorzystacZFunkcjiCzatowGetresponseWAplikacjiMobilnej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePrzetestowacFunkcjeCzatowZanimDodamJaDoSwojejStronyInternetowejLubLandingPage2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUdostepnicFunkcjeCzatuInnymOsobomZZespolu2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacLinkDoCzatowGetresponseWWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreSpamTrapsAndBlocklists = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pt": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-trampas-de-spam-y-las-listas-de-bloqueo.html",
"en": "https://www.getresponse.com/help/what-are-spam-traps-and-blocklists.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam-lovyshki-i-spiski-blokirovki.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-spam-traps-und-sperrlisten.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-spam-trapy-oraz-listy-zablokowanych-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-trappole-anti-spam-e-le-liste-di-blocco.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pieges-a-spam-et-les-listes-de-blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmaGaleriaDeImagensAoMeuWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmFormularioDeInscricaoAoMeuWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoOcultarUmaPaginaDoMenuDoSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmEspacadorAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmCronometroAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmBotaoDoPaypalAoMeuWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmaNovaPaginaAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmLinkAoMenuDoSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmFormularioDeContatoAoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatIOtpravitSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitNovoeImyaOtpravitelyaDlyaSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaSozdatDopolnitelnyeAkkaynuVSvoemMaxAkkaynte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyProveritStatistikyTranzacionnychPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitTranzakcionnoePismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaSzybkieOdpowiedziIJakIchUzywac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAlterarAsConfiguracoesDasMinhasPaginas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtpravlyatTranzakcionnyePismaCherezGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitSmtpIntegraciyu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodgotvitFajlDlyaImportaCherezFtp = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitCnameZapisDlyaSubdomenaDlyaLendingovSozannychVMaxAkkynte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitDnsNastroikiDlyaLendingovSozadannychVMaxAkkaynte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarEGerenciarUmPopUpNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearOTrafegoEmSeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrioUmSiteUsandoOCriadorOrientadoPelaIa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSmoguLiJaPoPrezhnemuImetDostupKSwoemuPartnerskomuAkkauntuVGetresponseIPoluchatKomisijuJesliJaNeIspolzujuGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html",
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJaSozdalSwoiAkkauntVGetresponseCherezPartnerskujuPanelNoNePoluchilKodSkidki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html",
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIspolzovatSvoiPartnerskiyDohodChtobiOplachivatSvoiuPodpiskuVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBudetLiMojaUchetnayaZapisAktivnaJesliJaZakrojuUchetnuiuZapisVPartnerskoiProgramme = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSmoguLiJaPerekluchatsaMezhduPartnerskimIObychnimAkkauntomGetresponsePosleIchPodkluchenia = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBudutLiPolzovateliMoegoAkkauntaGetresponseImetDostupKMoemuPartnerskomuAkkauntu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html",
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaPoluchitPodderzkuCherezChatGetresponseDlaMoegoPartnerskogoAkkaunta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogulLiJaPoluchitPartnerskiyDohodNaMoiuKreditnujuKartuSohranenyiuVAkkaunteGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html",
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIspolzovatSvoiPartnerskiyKodSkidkiBoleeOdnogoRaza = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-kod-skidki-bolee-odnogo-raza.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-il-mio-codice-sconto-affiliato-piu-di-una-volta.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-discount-code-more-than-once.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-kodu-znizkowego-dla-programow-partnerskich-wiecej-niz-jeden-raz.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-codigo-de-descuento-de-afiliado-mas-de-una-vez.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meinen-affiliate-rabattcode-mehr-als-einmal-verwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKogdaImportirovatFailyCherezFtp = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakImportirovatFailyCherezFtp = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDolznyLiDannyeMojegoAkkauntaGetresponseSovpadatSAkkauntomPartnerskojProgammyDlaTogoShtobiIchObjedenit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeProgrevIpAdresa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatSoobshenieSPomoshiuMobilnychPrilozenijGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-soobshenie-s-pomoshiu-mobilnych-prilozenij-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-uma-mensagem-usando-a-aplicacao-movel-dagetresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-uma-mensagem-usando-a-aplicacao-movel-dagetresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-mit-den-mobilen-apps-von-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostYMenyaEstAkkayntMaxKakMneNachat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZagryzitSpisokVAkkayntGetresponseMax = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeGetresponseMax = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYpravliyatMoimiZapShablonamiVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakFormatirovatFilePolychatelejSms = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html",
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaOtslezivatSvoiMarketingovyeKampaniiFacebookILendingiVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtslezivatMoiSajtSPomoshjuFacebookPixel = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeOgranicheniePropysknojSposobnostiSaita = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieElementyYaMogyDobavitNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakKupicDomenSPomoshyuGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeTestowacRozneCzesciMojejWiadomosciWRamachJednegoTestuAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeStworzycTestAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueOsOperadoresPodemFazerNosChatsGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWczesnaSprzedazZOkazjiCzarnegoPiatku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wczesna-sprzedaz-z-okazji-czarnego-piatku.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-early-bird-sale.html",
"en": "https://www.getresponse.com/help/black-friday-early-bird-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-early-bird-per-black-friday.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-pervyim-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-black-friday-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoTestarOsChatsGetresponseAntesDeOsUtilizarNoMeuWebsiteOuNumaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoOsGetresponseChats = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarOGetresponseChats = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGerirOsChatsGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoEncontrarAsEstatisticasDaGetresponseChats = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoMensagensPreDefinidasEComoUtilizaLas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstalarOCodigoGetresponseChatsNoMeuWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatElementsCanIAddToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-elements-can-i-add-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-elementy-ya-mogy-dobavit-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-elementos-posso-acrescentar-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/quali-elementi-posso-aggiungere-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/que-elementos-puedo-anadir-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/welche-elemente-kann-ich-meiner-website-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-elementy-moge-dodac-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/quels-elements-puis-je-ajouter-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAlterarAsConfiguracoesDoSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmTesteAB = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaDobavliyatNovyeKontaktyVRaznyeSpiskiSPomoshiyuKnopkiPaypalKypitSejchas = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavliyat-novye-kontakty-v-raznye-spiski-s-pomoshiyu-knopki-paypal-kypit-sejchas.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-kontakty-do-roznych-list-przy-uzyciu-przyciskow-paypal-kup-teraz.html",
"en": "https://www.getresponse.com/help/can-i-add-new-contacts-to-different-lists-with-paypal-buy-now-buttons.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-paypal-jetzt-kaufen-buttons-neue-kontakte-in-verschiedene-listen-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-nuevos-contactos-a-listas-diferentes-con-los-botones-comprar-ahora-de-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-de-nouveaux-contacts-a-differentes-listes-avec-les-boutons-paypal-acheter-maintenant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareLaVersioneMobileDiUnModulo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnMessaggioDiAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarUnTemporizadorAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarAnexosEmChatsGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarUnEspaciadorAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarSeparadoresAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearOsCliquesEmSeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAcompanharAConversaoDeFormulariosNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacZapamiWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaCondicionDeOcasionEspecial = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreAutorespondersYAutomatizacionDeMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarUnBotonDePaypalAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarDetallesDeSeoAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarYEditarUnBotonEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGestionarLosChatsGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoProbarGetresponseChatsAntesDeUsarlosEnMiSitioWebOEnUnaLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarLosChatsGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarUmEMailAtravesDeGetresponseChats = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntreUmaLandingPageEUmWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtivarOsChatsDaGetresponseNumaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarUnCorreoElectronicoATravesDeGetresponseChats = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoDarAccesoAUnMiembroDelEquipoALosChatsGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosChatsDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieTesteIchGetresponseChatsBevorIchSieAufMeinerWebsiteOderEinerLandingPageVerwende = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchGetresponseChatsInDerMobilenAppVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieGewaehreIchEinemTeamNutzerZugriffAufGetresponseChats = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtpravitPismoCherezChatGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYpravliyatChatamiGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeGotovyeSoobsheniyaIKakIchIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaIspolzovatChatyGetresponseVMobilnomPrilozenii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakProtestirovatChatyGetresponsePeredIhIspolzovaniemNaMoemSajteIliLendinge = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitSsulkyGetresponseChatsVPismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitKontaktyCherezChatyGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZablokirovatKogoliboVChatahGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoVerificarSeAPessoaNoGetresponseChatsAindaEstaNaPagina = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogePrzetwarzacDaneSpozaUeKorzystajacZRodo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomoscPowitalnaZaleznaOdUdzielonychZgod = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-powitalna-zalezna-od-udzielonych-zgod.html",
"en": "https://www.getresponse.com/help/consent-based-welcome-message.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-basato-su-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-willkommensnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-na-osnove-soglasija.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-base-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodacKlientowZEventbriteDoMojejListyKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakProveritNahoditsiyaChelovekVChateGetresponseNaStranice = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYstanovitKodGetresponseChatsNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoInserirUmaFotoAPartirDeUmaUrl = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-photo-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-adjuntar-una-foto-desde-una-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-una-foto-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-photo-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarEEditarUmBotaoEmMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtpravliyatVlozeniyaVChatachGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoBloquearAlguemEmChatsGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarContatosAtravesDeChatsGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoMogytDelatOperatoryVChatachGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeYaMogyNajtiStatistikyChatovGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeSpamLovyshkiISpiskiBlokirovki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam-lovyshki-i-spiski-blokirovki.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-trampas-de-spam-y-las-listas-de-bloqueo.html",
"en": "https://www.getresponse.com/help/what-are-spam-traps-and-blocklists.html",
"br": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pt": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-spam-traps-und-sperrlisten.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-spam-trapy-oraz-listy-zablokowanych-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-trappole-anti-spam-e-le-liste-di-blocco.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pieges-a-spam-et-les-listes-de-blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitKnopkyPaypalNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtslezivatKlikiNaVashemSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtslezivatPoseshaemostSajta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtslezivatKoversiyuNaMoemSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZintegrowacMojaStarszaWersjeLandingPageZBingads = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZintegrowacMojaStarszaWersjeLandingPageZKissmetrics = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIchHabeMeinGetresponseKontoUeberDasAffiliatePanelErstelltAberKeinenRabattcodeErhalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmLinkGetresponseChatsNumaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUtworzylemKontoGetresponseWPaneluPartnerskimAleNieOtrzymalemKoduZnizkowego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsarOsChatsGetresponseNaAplicacaoMovel = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitGetresponseChatNaLending = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPredostavitChlenyKomandyDostypKChatamGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoProvareGetresponsePrimaDiComprare = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGetresponseOffreUnPeriodoDiProva = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPredlagaetLiGetresponseBesplatnyuProbnyuVersiu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAGetresponseOfereceUmTesteGratuito = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyGetresponseOferujeDarmoweKontoTestowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGetresponseOfreceUnaPruebaGratuita = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBietetGetresponseEineKostenloseTestversionAn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoesGetresponseOfferAFreeTrial = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-free-trial.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-oferuje-darmowe-konto-testowe.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-periodo-di-prova.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-besplatnyu-probnyu-versiu.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-teste-gratuito.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-kostenlose-testversion-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-prueba-gratuita.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-un-essai-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosEIlSovraccaricoEComeFunziona = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeOcheredKontaktovIKakOnaRabotaet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAFilaDeContatosEComoElaFunciona = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestKolejkaKontaktowIJakDziala = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaColaDeContactosYComoFunciona = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDieKontaktwarteschlangeUndWieFunktioniertSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheContactQueueAndHowDoesItWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-contact-queue-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-cola-de-contactos-y-como-funciona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-fila-de-contatos-e-como-ela-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-sovraccarico-e-come-funziona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ochered-kontaktov-i-kak-ona-rabotaet.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-kontaktwarteschlange-und-wie-funktioniert-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-kolejka-kontaktow-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-file-d-attente-des-contacts-et-comment-fonctionne-t-elle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPowitanieWProgramieAfiliacyjnym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitNaSvoiSajtOtslezivanieKonversijGoogleReklamy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitNastroikiSajta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIntegrirovatSvoiSajtSDispetcheromTegovGoogle = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitRazdeliteliNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitTekstovyiBlokNaSvoiSajtIUpravliyatIm = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavlyatVspluvayushieOknaNaMoemSajteIUpravliatImi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitGalereyuIzobrazenijNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVstavitVideoIzUrl = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIspolzovatSobsvennyjHtmlKodNaMoemSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarAAcaoEnviarNotificacaoPush = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguDobavitIOtredaktirovatKnopkuNaSwoyomSayte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoImportarArchivosATravesDeFtp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnaLandingPageDeLegadoYPorQueLaNecesito = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeLamentele = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html",
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWebinar = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitKontaktnyuFormyNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezdyKontaktnoiFormoiIFormoiRegistracii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitIntervalNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogySdelatSvoiSaitBoleeAdaptivnymDlyaMobilnychYstroistv = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitTaimerNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSalvarEReutilizarSecoesEBlocos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitNaSvoiSaitIkonkiSocyalnuchSetej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackFormsConversionOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-forms-conversion-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-koversiyu-na-moem-sajte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-acompanhar-a-conversao-de-formularios-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-formularkonvertierung-auf-meiner-website-nachverfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-konwersje-formularzy-na-mojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-la-conversione-dei-moduli-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-la-conversion-de-los-formularios-en-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-la-conversion-des-formulaires-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackClicksOnYourWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-clicks-on-your-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-kliki-na-vashem-sajte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-os-cliques-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-klicks-auf-ihrer-website-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-klikniecia-na-twojej-stronie-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-tracciare-clics-sul-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-los-clics-en-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-clics-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToChangeWebsiteSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-change-website-settings.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastroiki-sajta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-del-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-website-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-del-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-du-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoDarAUmMembroDaEquipeAcessoAosChatsGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackTrafficOnYourWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-traffic-on-your-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-poseshaemost-sajta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-o-trafego-em-seu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sie-den-verkehr-auf-ihrer-website-verfolgen.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-traffico-sul-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-ruch-na-stronie.html",
"es": "https://www.getresponse.com/es/ayuda/como-seguir-el-trafico-de-su-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-trafic-sur-votre-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToGiveATeamMemberAccessToGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-give-a-team-member-access-to-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-dar-a-um-membro-da-equipe-acesso-aos-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predostavit-chleny-komandy-dostyp-k-chatam-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gewaehre-ich-einem-team-nutzer-zugriff-auf-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-dar-acceso-a-un-miembro-del-equipo-a-los-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-funkcje-czatu-innym-osobom-z-zespolu-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-fornire-a-un-membro-del-team-l-accesso-alle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-donner-a-un-membre-de-l-equipe-l-acces-aux-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatCanOperatorsDoWithinGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-can-operators-do-within-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-mogyt-delat-operatory-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-os-operadores-podem-fazer-nos-chats-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-koennen-operatoren-in-getresponse-chats-tun.html",
"es": "https://www.getresponse.com/es/ayuda/que-pueden-hacer-los-operadores-dentro-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-mozliwosci-konsultantow-w-czatach-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-possono-fare-gli-operatori-all-interno-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-peuvent-faire-les-operateurs-dans-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTestGetresponseChatsBeforeUsingThemOnMyWebsiteOrALandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-test-getresponse-chats-before-using-them-on-my-website-or-a-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-protestirovat-chaty-getresponse-pered-ih-ispolzovaniem-na-moem-sajte-ili-lendinge.html",
"de": "https://www.getresponse.com/de/hilfe/wie-teste-ich-getresponse-chats-bevor-ich-sie-auf-meiner-website-oder-einer-landing-page-verwende.html",
"es": "https://www.getresponse.com/es/ayuda/como-probar-getresponse-chats-antes-de-usarlos-en-mi-sitio-web-o-en-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-testar-os-chats-getresponse-antes-de-os-utilizar-no-meu-website-ou-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przetestowac-funkcje-czatow-zanim-dodam-ja-do-swojej-strony-internetowej-lub-landing-page-2.html",
"it": "https://www.getresponse.com/it/assistenza/come-testare-le-chat-getresponse-prima-di-utilizzarle-sul-mio-sito-web-o-su-una-pagina-di-destinazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tester-les-chats-de-getresponse-avant-de-les-utiliser-sur-mon-site-web-ou-une-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicKlientowWewnatrzMojegoSklepuMagento = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-klientow-wewnatrz-mojego-sklepu-magento.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-i-clienti-del-mio-negozio-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-clientes-em-minha-loja-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-clientes-em-minha-loja-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaAdministrarMeusZapsEmGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitNovyuStranicyNaSvoiSait = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakMneDobavitStranicyIzVneshnegoUrlVMenuSaita = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarUmaMensagemUsandoAAplicacaoMovelDagetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-uma-mensagem-usando-a-aplicacao-movel-dagetresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-uma-mensagem-usando-a-aplicacao-movel-dagetresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-soobshenie-s-pomoshiu-mobilnych-prilozenij-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-mit-den-mobilen-apps-von-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogySozdatKopiyuStranicu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIzmenitGlavnyuStranicy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyUpravliatStranicamiVMenuSaita = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitPoryadokStranicVMenuSaita = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyRedaktirovatStranicuNeNajdenoZashisennyeParolem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitSvoiSaitStranicyZasisennuyuParolem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogySozdatPodstranicy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSkrytStranicyIzMenuSaita = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyYdalitStranitsy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ydalit-stranitsy.html",
"de": "https://www.getresponse.com/de/hilfe/how-can-i-delete-a-page.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-a-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-podstrone.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitSsulkyVMenuSaita = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyPerekluchatsyaMezdyStranicamiVRedaktoreSaitov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html",
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitIOtredaktirovatMenuSaitaNaSvoemWebsaite = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitDannyeSeoNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIzmenitNastroikiSvoihStranits = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenTextblockAufMeinerWebsiteHinzufuegenUndVerwalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitChatyVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieSeitenNichtGefundenUndPassworteingabeBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchUnterseitenErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyNaznachitSubdomenSvoemySaity = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEineNeueSeiteHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyNaznachitDomenSvoemySaity = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAndManageAPopupOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-and-manage-a-popup-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavlyat-vspluvayushie-okna-na-moem-sajte-i-upravliat-imi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-pop-up-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-una-ventana-emergente-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-popup-auf-meiner-website-hinzufuegen-und-verwalten.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-pop-up-sul-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-wyskakujace-okno.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-pop-up-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitFormyPodpiskiNaSvoiSajt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVstavitIzobrazenieIzUrlAdresa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-izobrazenie-iz-url-adresa.html",
"en": "https://www.getresponse.com/help/how-do-i-embed-an-image-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-foto-von-einer-url-ein.html",
"br": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-incorporo-uma-imagem-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-immagine-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-image-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSohranyatIPovtornoIspolzovatRazdelyIBloki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIspolzovatRazdelyNaMoemSaite = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavlyatIYpravlyatIzobrazeniyamiNaSvoemSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaCondicionDeEstadoDelConsentimientoActualizado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatGlobalnyjDezajnVKonstrykoreSaitov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtslezivatSaitSPomoshyuGoogleAnalitiki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyOtslezivatSvoiSaitSPomoshyuYandexMetriki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAWebsiteBandwidthLimit = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-website-bandwidth-limit.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-ogranichenie-propysknoj-sposobnosti-saita.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-limit-przepustowosci-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-il-limite-di-larghezza-di-banda-di-un-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-limite-de-largura-de-banda-de-um-site.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-website-bandbreitenlimit.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-limite-de-ancho-de-banda-de-un-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-limite-de-bande-passante-pour-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeTheSettingsOfMyPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-the-settings-of-my-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-nastroiki-svoih-stranits.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-as-configuracoes-das-minhas-paginas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-delle-mie-pagine.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-einstellungen-meiner-seiten-andern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-configuracion-de-mis-paginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-de-mes-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddAPasswordProtectedPageToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-a-password-protected-page-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-svoi-sait-stranicy-zasisennuyu-parolem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-pagina-protegida-por-senha-ao-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-protetta-da-password-al-mio-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-meiner-website-eine-passwortgeschuetzte-seite-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-protegida-por-contrasena-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zabezpieczyc-haslem-dostep-do-podstrony-z-kreatora-stron.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-protegee-par-un-mot-de-passe-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddSeoDetailsToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-seo-details-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-dannye-seo-na-svoi-sajt.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-detalles-de-seo-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-detalhes-de-seo-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-seo-details-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-dettagli-seo-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-informacje-seo-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-details-seo-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAPaypalButtonToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-paypal-button-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-knopky-paypal-na-svoi-sajt.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-boton-de-paypal-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-paypal-button-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-botao-do-paypal-ao-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-przycisk-paypal-do-strony-internetowej.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-pulsante-paypal-al-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-bouton-paypal-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAnImageGalleryToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-gallery-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-galereyu-izobrazenij-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-galeria-de-imagens-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-galeria-de-imagenes-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-bildergalerie-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-una-galleria-di-immagini-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-galerie-obrazow-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-galerie-d-images-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddAPageFromAnExternalUrlToTheSiteMenu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-a-page-from-an-external-url-to-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-dobavit-stranicy-iz-vneshnego-url-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-pagina-de-um-url-externo-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-pagina-da-un-url-esterno-al-mio-menu-del-sito.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-seite-von-einer-externen-url-zum-website-menue-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-zewnetrzna-strone-internetowa-do-menu-strony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-pagina-de-una-url-externa-al-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-page-d-une-url-externe-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatSajtSPomosjyuShablonov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUtilizarGetresponseChatsEnLaAplicacionMovil = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoHabilitarGetresponseChatsEnUnaLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatGetresponseSAmazon = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnEnlaceDeGetresponseChatsEnUnMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarArchivosAdjuntosEnGetresponseChats = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirContactosATravesDeGetresponseChats = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasRespuestasRapidasEnLosChatsDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoBloquearAAlguienEnGetresponseChats = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoComprobarSiLaPersonaEnGetresponseChatsSigueEnLaPagina = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstalarElCodigoDeGetresponseChatsEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoACondicaoDoStatusDeConsentimentoAtualizadoFunciona2 = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasTrampasDeSpamYLasListasDeBloqueo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-trampas-de-spam-y-las-listas-de-bloqueo.html",
"en": "https://www.getresponse.com/help/what-are-spam-traps-and-blocklists.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam-lovyshki-i-spiski-blokirovki.html",
"br": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"pt": "https://www.getresponse.com/pt/ajuda/what-are-spam-traps-and-blocklists.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-spam-traps-und-sperrlisten.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-spam-trapy-oraz-listy-zablokowanych-kontaktow.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-trappole-anti-spam-e-le-liste-di-blocco.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pieges-a-spam-et-les-listes-de-blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoSeUtilizanLosAtajosDeTecladoEnElNuevoEditor = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedenPagarMisGananciasDeAfiliadoAMiTarjetaDeCreditoGuardadaEnMiCuentaDeProductoGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html",
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostObtendreApoyoDeLosAfiliadosATravesDeLosChatsDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTendranMisSubUsuariosDeGetresponseAccesoAlAreaDeMiCuentaDeAfiliadoDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html",
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodreCambiarEntreMiCuentaGetresponseYMiCuentaDeAfiliadoGetresponseDespuesDeIntegrarlas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMiCuentaDeProductoGetresponseSeguiraActivaSiCierroMiCuentaDeAfiliadoGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUtilizarMisGananciasDeAfiliadoParaPagarMiCuentaDeProductoGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLosDetallesDeMiCuentaTienenQueCoincidirTantoEnMiCuentaDeAfiliadoComoEnLaDeProductoGetresponseParaIntegrarlosJuntos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHeCreadoMiCuentaDeGetresponseATravesDelPanelDeAfiliadosPeroNoHeRecibidoNingunCodigoDeDescuento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUtilizarMiCodigoDeDescuentoDeAfiliadoMasDeUnaVez = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-codigo-de-descuento-de-afiliado-mas-de-una-vez.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-il-mio-codice-sconto-affiliato-piu-di-una-volta.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-discount-code-more-than-once.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-kodu-znizkowego-dla-programow-partnerskich-wiecej-niz-jeden-raz.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-kod-skidki-bolee-odnogo-raza.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meinen-affiliate-rabattcode-mehr-als-einmal-verwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeguireTeniendoAccesoAMiCuentaDeAfiliadoDeGetresponseYPodreGanarComisionesSiNoUsoGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html",
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoComprarUnDominioConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoIRimbalzi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycStroneInternetowaUzywajacSzablonow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddSocialMediaIconsToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-social-media-icons-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sait-ikonki-socyalnuch-setej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-icones-de-redes-sociais-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-social-media-symbole-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-le-icone-dei-social-media-al-mio-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-iconos-de-redes-sociales-a-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-ikony-social-media-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-icones-de-medias-sociaux-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUtilizarLasSeccionesEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnEnlaceAlMenuDelSitio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnaNuevaPaginaAMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSaRegulacjeDostarczaniaSmsowWDanymKraju = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogePrzypisacTagiINadacPunktyScoringowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoSeguirMiSitioWebConYandexMetrica = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnSitioWebConElAsistenteDeIa = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycStroneInternetowaKorzystajacZInteligentnegoKreatora = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaPomiedzyLandingPageAStronaInternetowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambiarLaDireccionPostalEnElPieDePaginaDelMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaTasaDeClicsPorAperturaCtor = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseACustomHtmlCodeOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-a-custom-html-code-on-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-sobsvennyj-html-kod-na-moem-sajte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-um-codigo-html-personalizado-em-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-il-codice-html-personalizzato-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-benutzerdefinierten-html-code-auf-meiner-website-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-un-codigo-html-personalizado-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-html-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-html-personnalise-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddATimerToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-timer-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-taimer-na-svoi-sajt.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-temporizador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-cronometro-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-timer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-timer-per-il-conto-alla-rovescia-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-licznik-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-compte-a-rebours-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIMakeMyWebsiteMoreResponsiveForMobileDevices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-make-my-website-more-responsive-for-mobile-devices.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sdelat-svoi-sait-bolee-adaptivnym-dlya-mobilnych-ystroistv.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-tornar-meu-site-mais-responsivo-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rendere-il-mio-sito-piu-mobile-friendly.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sprawic-aby-moja-strona-internetowa-byla-bardziej-responsywna-dla-urzadzen-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-fuer-mobilgeraete-responsiver-machen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-que-mi-sitio-web-sea-mas-receptivo-para-los-dispositivos-moviles.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rendre-un-site-web-plus-reactif-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenAContactFormAndASignupForm = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-contact-form-and-a-signup-form.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezdy-kontaktnoi-formoi-i-formoi-registracii.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-de-contato-e-um-formulario-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-di-contatto-e-un-modulo-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-kontaktowym-a-formularzem-zapisu.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-de-contacto-y-un-formulario-de-registro.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-kontaktformular-und-einem-anmeldeformular-2.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-de-contact-et-un-formulaire-d-inscription-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddASpacerToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-spacer-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-interval-na-svoi-sajt.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-espaciador-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-espacador-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-einen-spacer-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-uno-spaziatore-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odstep-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-l-espacement-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoIncrustarUnaImagenOUnBotonDentroDeUnBloqueDeTexto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-incrustar-una-imagen-o-un-boton-dentro-de-un-bloque-de-texto.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-bild-oder-einen-button-in-ein-textelement-einfuegen.html",
"en": "https://www.getresponse.com/help/can-i-embed-an-image-or-button-within-a-text-block.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vstavit-izobrazhenije-ili-knopku-tekstovy-blok.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-obrazek-lub-przycisk-w-bloku-tekstowym.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"it": "https://www.getresponse.com/it/assistenza/posso-incorporare-un-immagine-o-un-pulsante-all-interno-di-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-une-image-ou-un-bouton-dans-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiMetodiDiPagamentoAccettate = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAssignMyOwnSubdomainToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-subdomain-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-subdomen-svoemy-saity.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-posso-atribuir-meu-proprio-subdominio-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-subdomain-zuweisen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-sottodominio-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-subdomene-do-mojej-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-subdominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-sous-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaNotificacaoWebPush = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddDividersToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-dividers-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-razdeliteli-na-svoi-sajt.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-separadores-a-mi-sitio-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-divisores-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-trennlinien-zu-meiner-website-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-divisori-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-separatory-na-strone.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-separateurs-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMiMensajeTieneUnaTasaDeAperturaDel0PorQue = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html",
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChemVoronkaKonversiiOtlichaetsyaOtAvtomatizaciiMarketinga = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirOtroMensajeAUnCicloDeAutoresponderExistente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarLaDireccionDeEnMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-direccion-de-en-mis-mensajes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-absender-e-mail-adresse-in-meinen-nachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-adres-otpravitela-v-moich-soobshenijach.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-email-nadawcy-w-wiadomosciach.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-l-indirizzo-di-mittente-nei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-de-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProdaza = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"en": "https://www.getresponse.com/help/sale.html",
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html",
"fr": "https://www.getresponse.com/fr/aide/vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNomerDlaOtslezivaniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/nomer-dla-otslezivaniya.html",
"pl": "https://www.getresponse.pl/pomoc/ref-numer-sledzacy.html",
"en": "https://www.getresponse.com/help/ref-tracking-number.html",
"de": "https://www.getresponse.com/de/hilfe/ref-tracking-number.html",
"es": "https://www.getresponse.com/es/ayuda/numero-de-seguimiento-de-la-referencia.html",
"it": "https://www.getresponse.com/it/assistenza/numero-di-riferimento.html",
"br": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"fr": "https://www.getresponse.com/fr/aide/ref-numero-de-suivi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpamLovyshka = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/spam-lovyshka.html",
"pl": "https://www.getresponse.pl/pomoc/spam-trap.html",
"en": "https://www.getresponse.com/help/spam-trap.html",
"it": "https://www.getresponse.com/it/assistenza/trappole-di-spam.html",
"es": "https://www.getresponse.com/es/ayuda/trampa-de-spam.html",
"de": "https://www.getresponse.com/de/hilfe/spam-trap.html",
"br": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/piege-a-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPhising = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/phising.html",
"pl": "https://www.getresponse.pl/pomoc/phishing.html",
"en": "https://www.getresponse.com/help/phishing-definition.html",
"it": "https://www.getresponse.com/it/assistenza/phishing.html",
"es": "https://www.getresponse.com/es/ayuda/phishing.html",
"de": "https://www.getresponse.com/de/hilfe/phishing-definition.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"fr": "https://www.getresponse.com/fr/aide/definition-du-phishing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKonfidencialnyeLichnyeDannye = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostObshijReglamentPoZashiteDannychGdpr = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/obshij-reglament-po-zashite-dannych-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/rozporzadzenie-o-ochronie-danych-osobowych-rodo.html",
"en": "https://www.getresponse.com/help/general-data-protection-regulation-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/general-data-protection-regulation-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/reglamento-general-de-proteccion-de-datos-rgpd.html",
"de": "https://www.getresponse.com/de/hilfe/die-allgemeine-datenschutz-verordnung-dsgvo.html",
"br": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/reglement-general-sur-la-protection-des-donnees-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostObrabotkaPersonalnychDannych = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/obrabotka-personalnych-dannych.html",
"pl": "https://www.getresponse.pl/pomoc/przetwarzanie-danych-osobowych.html",
"en": "https://www.getresponse.com/help/personal-data-processing.html",
"it": "https://www.getresponse.com/it/assistenza/personal-data-processing.html",
"es": "https://www.getresponse.com/es/ayuda/tratamiento-de-datos-personales.html",
"de": "https://www.getresponse.com/de/hilfe/die-verarbeitung-der-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pt": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees-personnelles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostObrabotchikDannych = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/obrabotchik-dannych.html",
"pl": "https://www.getresponse.pl/pomoc/podmiot-przetwarzajacy-dane.html",
"en": "https://www.getresponse.com/help/data-processor.html",
"it": "https://www.getresponse.com/it/assistenza/data-processor.html",
"es": "https://www.getresponse.com/es/ayuda/procesador-de-datos.html",
"de": "https://www.getresponse.com/de/hilfe/datenverarbeiter.html",
"br": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKotrollerDannych = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"en": "https://www.getresponse.com/help/data-controller.html",
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAContactFormToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-contact-form-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontaktnyu-formy-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-contato-ao-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-kontaktformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-contatto-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-kontaktowy-do-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-formulario-de-contacto-a-mi-pagina-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-de-contact-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnMensajeDeAutomatizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirManualmenteUnContactoAlCicloDeAutoresponder = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTrampaDeSpam = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/trampa-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/spam-trap.html",
"en": "https://www.getresponse.com/help/spam-trap.html",
"it": "https://www.getresponse.com/it/assistenza/trappole-di-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/spam-lovyshka.html",
"de": "https://www.getresponse.com/de/hilfe/spam-trap.html",
"br": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/piege-a-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuenta.html",
"en": "https://www.getresponse.com/help/account.html",
"it": "https://www.getresponse.com/it/assistenza/account.html",
"ru": "https://www.getresponse.com/ru/help-ru/akkaunt.html",
"pl": "https://www.getresponse.pl/pomoc/konto-2.html",
"de": "https://www.getresponse.com/de/hilfe/das-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"fr": "https://www.getresponse.com/fr/aide/compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCodificacionDeCaracteres = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/codificacion-de-caracteres.html",
"pl": "https://www.getresponse.pl/pomoc/kodowanie-znakow.html",
"en": "https://www.getresponse.com/help/character-encoding.html",
"de": "https://www.getresponse.com/de/hilfe/character-encoding.html",
"ru": "https://www.getresponse.com/ru/help-ru/kodirovka-simvolov.html",
"it": "https://www.getresponse.com/it/assistenza/codifica-dei-caratteri.html",
"br": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pt": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"fr": "https://www.getresponse.com/fr/aide/codage-des-caracteres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIEmbedAVideoFromAUrl = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-embed-a-video-from-a-url.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vstavit-video-iz-url.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-incorporar-um-video-de-um-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-incorporare-un-video-da-un-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-binde-ich-ein-video-von-einer-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-desde-una-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-strone-wideo-z-adresu-url-2.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-video-a-partir-d-une-url-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareLeImpostazioniDellaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-lista.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-list-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-ustawienia-mojej-listy.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsa-nastrojki-spiska.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-ajustes-de-mi-lista-2.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-listeneinstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoScaricareLeMieFatture = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html",
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClienteDeEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cliente-de-email.html",
"pl": "https://www.getresponse.pl/pomoc/klient-poczty-elektronicznej.html",
"en": "https://www.getresponse.com/help/email-client.html",
"de": "https://www.getresponse.com/de/hilfe/email-client.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-klient.html",
"it": "https://www.getresponse.com/it/assistenza/cliente-di-posta-elettronica.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/client-de-messagerie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublicidadPorEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/publicidad-por-email.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-mailowa.html",
"en": "https://www.getresponse.com/help/email-advertising.html",
"de": "https://www.getresponse.com/de/hilfe/email-advertising.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-reklama.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-par-courriel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPhishing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/phishing.html",
"pl": "https://www.getresponse.pl/pomoc/phishing.html",
"en": "https://www.getresponse.com/help/phishing-definition.html",
"it": "https://www.getresponse.com/it/assistenza/phishing.html",
"ru": "https://www.getresponse.com/ru/help-ru/phising.html",
"de": "https://www.getresponse.com/de/hilfe/phishing-definition.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-phishing.html",
"fr": "https://www.getresponse.com/fr/aide/definition-du-phishing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"en": "https://www.getresponse.com/help/sale.html",
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html",
"fr": "https://www.getresponse.com/fr/aide/vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInforme = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/informe.html",
"pl": "https://www.getresponse.pl/pomoc/raport.html",
"en": "https://www.getresponse.com/help/report.html",
"de": "https://www.getresponse.com/de/hilfe/bericht.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet.html",
"it": "https://www.getresponse.com/it/assistenza/report.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"fr": "https://www.getresponse.com/fr/aide/rapport.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReglamentoGeneralDeProteccionDeDatosRgpd = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/reglamento-general-de-proteccion-de-datos-rgpd.html",
"pl": "https://www.getresponse.pl/pomoc/rozporzadzenie-o-ochronie-danych-osobowych-rodo.html",
"en": "https://www.getresponse.com/help/general-data-protection-regulation-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/general-data-protection-regulation-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/obshij-reglament-po-zashite-dannych-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/die-allgemeine-datenschutz-verordnung-dsgvo.html",
"br": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/reglement-general-sur-la-protection-des-donnees-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDatosPersonalesSensibles = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTratamientoDeDatosPersonales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tratamiento-de-datos-personales.html",
"pl": "https://www.getresponse.pl/pomoc/przetwarzanie-danych-osobowych.html",
"en": "https://www.getresponse.com/help/personal-data-processing.html",
"it": "https://www.getresponse.com/it/assistenza/personal-data-processing.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotka-personalnych-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/die-verarbeitung-der-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pt": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees-personnelles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProcesadorDeDatos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/procesador-de-datos.html",
"pl": "https://www.getresponse.pl/pomoc/podmiot-przetwarzajacy-dane.html",
"en": "https://www.getresponse.com/help/data-processor.html",
"it": "https://www.getresponse.com/it/assistenza/data-processor.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotchik-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/datenverarbeiter.html",
"br": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostControladorDeDatos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"en": "https://www.getresponse.com/help/data-controller.html",
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKeyboardShortcutsInTheNewEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/keyboard-shortcuts-in-the-new-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-utilizan-los-atajos-de-teclado-en-el-nuevo-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizzano-le-scorciatoie-da-tastiera-nel-nuovo-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-tastaturkuerzel-im-neuen-editor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-atalhos-de-teclado-no-novo-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-skrotow-klawiszowych-w-nowym-edytorze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sochetaniya-klavish-v-novom-redaktore.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-raccourcis-clavier-dans-le-nouvel-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineSeiteDuplizieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenLinkZumSiteMenueHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUtilizzareLAutenticazioneADueFattoriInGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoPagarMeusGanhosDeAfiliadoAoMeuCartaoDeCreditoGuardadoNaMinhaContaDeProdutoGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html",
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostObtereiSuporteDeAfiliadosAtravesDeChatsGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOsMeusSubUsuariosGetresponseTeraoAcessoAMinhaContaGetresponseNaAreaDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html",
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSereiCapazDeMudarEntreMinhaContaGetresponseEMinhaContaAfiliadaGetresponseDepoisDeIntegraLas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMinhaContaDeProdutoGetresponseAindaEstaraAtivaSeEuFecharMinhaContaDeAfiliadaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsarMeusGanhosDaMinhaContaAfiliadaParaPagarMinhaContaDeProdutoGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAindaTereiAcessoAMinhaContaDeAfiliadoGetresponseEPodereiGanharComissoesSeEuNaoUsarGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html",
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOsDetalhesDeMinhaContaTemQueCorresponderTantoNaMinhaAfiliadaGetresponseQuantoNasContasDeProdutosGetresponseParaIntegraLas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEuCrieiMinhaContaGetresponseAtravesDoPainelDeAfiliadosMasNaoRecebiNenhumCodigoDeDesconto = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsarMeuCodigoDeDescontoDeAfiliadoMaisDeUmaVez = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-il-mio-codice-sconto-affiliato-piu-di-una-volta.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-discount-code-more-than-once.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-kodu-znizkowego-dla-programow-partnerskich-wiecej-niz-jeden-raz.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-codigo-de-descuento-de-afiliado-mas-de-una-vez.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-kod-skidki-bolee-odnogo-raza.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meinen-affiliate-rabattcode-mehr-als-einmal-verwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHoCreatoIlMioAccountGetresponseTramiteIlPannelloDiAffiliazioneMaNonHoRicevutoNessunoCodiceSconto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIDettagliDelMioAccountDevonoCorrispondereSiaAlMioAccountDiAffiliazioneCheAllAccountGetresponsePerIntegrarliInsieme = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAvroAccessoAlMioAccountDiAffiliazioneEPotroGuadagnareCommissioniSeNonUsoGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html",
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUtilizzareLeMieCommissioniAffiliatoPerPagareIlMioAccountGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIlMioAccountGetresponseSaraAncoraAttivoSeEliminoIlMioAccountDiAffiliazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSaraPossibilePassareDalMioAccountGetresponseAlMioAccountDiAffiliazioneDopoAverliIntegrati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostISubUtentiDelMioAccountGetresponseAvrannoAccessoAlMioAccountDiAffiliazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html",
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRiceveroIlSupportoPerIlMioAccountDiAffiliazioneTramiteLaChatDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEPossibileRicevereIlPagamentoDellaCommissioneAffiliatoSullaMiaCartaDiCreditoAssociataAllAccountGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html",
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsareIlMioCodiceScontoAffiliatoPiuDiUnaVolta = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-usare-il-mio-codice-sconto-affiliato-piu-di-una-volta.html",
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-discount-code-more-than-once.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-kodu-znizkowego-dla-programow-partnerskich-wiecej-niz-jeden-raz.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-codigo-de-descuento-de-afiliado-mas-de-una-vez.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-kod-skidki-bolee-odnogo-raza.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meinen-affiliate-rabattcode-mehr-als-einmal-verwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanYouPayOutMyAffiliateEarningsToMyCreditCardSavedInMyGetresponseProductAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-you-pay-out-my-affiliate-earnings-to-my-credit-card-saved-in-my-getresponse-product-account.html",
"it": "https://www.getresponse.com/it/assistenza/e-possibile-ricevere-il-pagamento-della-commissione-affiliato-sulla-mia-carta-di-credito-associata-all-account-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-pagar-meus-ganhos-de-afiliado-ao-meu-cartao-de-credito-guardado-na-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-istnieje-mozliwosc-wyplaty-srodkow-uzyskanych-w-programie-partnerskim-na-karte-kredytowa-powiazana-z-kontem-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-pagar-mis-ganancias-de-afiliado-a-mi-tarjeta-de-credito-guardada-en-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogul-li-ja-poluchit-partnerskiy-dohod-na-moiu-kreditnuju-kartu-sohranenyiu-v-akkaunte-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-sie-meine-affiliate-einnahmen-auf-meine-kreditkarte-auszahlen-die-in-meinem-getresponse-produktkonto-gespeichert-ist.html",
"fr": "https://www.getresponse.com/fr/aide/pouvez-vous-payer-mes-gains-d-affilie-sur-ma-carte-de-credit-enregistree-dans-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWillIGetAffiliateSupportThroughGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/will-i-get-affiliate-support-through-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/ricevero-il-supporto-per-il-mio-account-di-affiliazione-tramite-la-chat-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/obterei-suporte-de-afiliados-atraves-de-chats-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/obtendre-apoyo-de-los-afiliados-a-traves-de-los-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-poluchit-podderzku-cherez-chat-getresponse-dla-moego-partnerskogo-akkaunta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-uzyskam-pomoc-na-czacie-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/erhalte-ich-affiliate-support-durch-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/vais-je-beneficier-d-une-assistance-aux-affilies-par-le-biais-des-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWillMyGetresponseSubUsersHaveAccessToMyGetresponseAffiliateAccountArea = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/will-my-getresponse-sub-users-have-access-to-my-getresponse-affiliate-account-area.html",
"it": "https://www.getresponse.com/it/assistenza/i-sub-utenti-del-mio-account-getresponse-avranno-accesso-al-mio-account-di-affiliazione.html",
"br": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-meus-sub-usuarios-getresponse-terao-acesso-a-minha-conta-getresponse-na-area-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-inni-uzytkownicy-zespolu-beda-mieli-dostep-do-mojego-konta-partnerskiego-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/tendran-mis-sub-usuarios-de-getresponse-acceso-al-area-de-mi-cuenta-de-afiliado-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budut-li-polzovateli-moego-akkaunta-getresponse-imet-dostup-k-moemu-partnerskomu-akkauntu.html",
"de": "https://www.getresponse.com/de/hilfe/werden-meine-getresponse-unterbenutzer-zugriff-auf-meinen-getresponse-affiliate-kontobereich-haben.html",
"fr": "https://www.getresponse.com/fr/aide/mes-sous-utilisateurs-getresponse-auront-ils-acces-a-la-zone-de-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWillIBeAbleToSwitchBetweenMyGetresponseAccountAndGetresponseAffiliateAccountAfterIntegratingThem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/will-i-be-able-to-switch-between-my-getresponse-account-and-getresponse-affiliate-account-after-integrating-them.html",
"it": "https://www.getresponse.com/it/assistenza/sara-possibile-passare-dal-mio-account-getresponse-al-mio-account-di-affiliazione-dopo-averli-integrati.html",
"br": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/serei-capaz-de-mudar-entre-minha-conta-getresponse-e-minha-conta-afiliada-getresponse-depois-de-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-bede-miec-mozliwosc-przelaczania-miedzy-kontem-getresponse-a-kontem-partnerskim-po-ich-zintegrowaniu.html",
"es": "https://www.getresponse.com/es/ayuda/podre-cambiar-entre-mi-cuenta-getresponse-y-mi-cuenta-de-afiliado-getresponse-despues-de-integrarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-perekluchatsa-mezhdu-partnerskim-i-obychnim-akkauntom-getresponse-posle-ich-podkluchenia.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-nach-der-integration-zwischen-meinem-getresponse-konto-und-dem-getresponse-affiliate-konto-wechseln.html",
"fr": "https://www.getresponse.com/fr/aide/pourrai-je-passer-de-mon-compte-getresponse-a-mon-compte-d-affilie-getresponse-apres-les-avoir-integres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWillMyGetresponseProductAccountStillBeActiveIfICloseMyGetresponseAffiliateAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/will-my-getresponse-product-account-still-be-active-if-i-close-my-getresponse-affiliate-account.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-getresponse-sara-ancora-attivo-se-elimino-il-mio-account-di-affiliazione.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-de-produto-getresponse-ainda-estara-ativa-se-eu-fechar-minha-conta-de-afiliada-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-getresponse-pozostanie-aktywne-po-zamknieciu-konta-partnerskiego.html",
"es": "https://www.getresponse.com/es/ayuda/mi-cuenta-de-producto-getresponse-seguira-activa-si-cierro-mi-cuenta-de-afiliado-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/budet-li-moja-uchetnaya-zapis-aktivna-jesli-ja-zakroju-uchetnuiu-zapis-v-partnerskoi-programme.html",
"de": "https://www.getresponse.com/de/hilfe/wird-mein-getresponse-produktkonto-weiterhin-aktiv-sein-wenn-ich-mein-getresponse-affiliate-konto-schliesse.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-de-produit-getresponse-sera-t-il-toujours-actif-si-je-ferme-mon-compte-d-affilie-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseMyAffiliateEarningsToPayForMyGetresponseProductAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-earnings-to-pay-for-my-getresponse-product-account.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-le-mie-commissioni-affiliato-per-pagare-il-mio-account-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meus-ganhos-da-minha-conta-afiliada-para-pagar-minha-conta-de-produto-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wykorzystac-srodki-zarobione-w-programie-partnerskim-do-oplacenia-mojego-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mis-ganancias-de-afiliado-para-pagar-mi-cuenta-de-producto-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-dohod-chtobi-oplachivat-svoiu-podpisku-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-affiliate-einnahmen-verwenden-um-mein-getresponse-produktkonto-zu-bezahlen.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-mes-gains-d-affilie-pour-payer-mon-compte-de-produit-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWillIStillHaveAccessToMyGetresponseAffiliateAccountAndBeAbleToEarnCommissionsIfIDonTUseGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/will-i-still-have-access-to-my-getresponse-affiliate-account-and-be-able-to-earn-commissions-if-i-don-t-use-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/avro-accesso-al-mio-account-di-affiliazione-e-potro-guadagnare-commissioni-se-non-uso-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/ainda-terei-acesso-a-minha-conta-de-afiliado-getresponse-e-poderei-ganhar-comissoes-se-eu-nao-usar-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czy-zachowam-dostep-do-konta-partnerskiego-i-mozliwosc-uzyskiwania-prowizji-jesli-nie-korzystam-z-konta-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/seguire-teniendo-acceso-a-mi-cuenta-de-afiliado-de-getresponse-y-podre-ganar-comisiones-si-no-uso-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/smogu-li-ja-po-prezhnemu-imet-dostup-k-swoemu-partnerskomu-akkauntu-v-getresponse-i-poluchat-komisiju-jesli-ja-ne-ispolzuju-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/habe-ich-weiterhin-zugriff-auf-mein-getresponse-affiliate-konto-und-kann-ich-provisionen-verdienen-wenn-ich-getresponse-nicht-verwende.html",
"fr": "https://www.getresponse.com/fr/aide/aurai-je-toujours-acces-a-mon-compte-d-affilie-getresponse-et-pourrai-je-toucher-des-commissions-si-je-n-utilise-pas-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoMyAccountDetailsHaveToMatchOnBothMyGetresponseAffiliateAndGetresponseProductAccountsToIntegrateThemTogether = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/do-my-account-details-have-to-match-on-both-my-getresponse-affiliate-and-getresponse-product-accounts-to-integrate-them-together.html",
"it": "https://www.getresponse.com/it/assistenza/i-dettagli-del-mio-account-devono-corrispondere-sia-al-mio-account-di-affiliazione-che-all-account-getresponse-per-integrarli-insieme.html",
"br": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-detalhes-de-minha-conta-tem-que-corresponder-tanto-na-minha-afiliada-getresponse-quanto-nas-contas-de-produtos-getresponse-para-integra-las.html",
"pl": "https://www.getresponse.pl/pomoc/czy-dane-konta-partnerskiego-i-konta-getresponse-musza-byc-identyczne-abym-mogl-je-ze-soba-zintegrowac.html",
"es": "https://www.getresponse.com/es/ayuda/los-detalles-de-mi-cuenta-tienen-que-coincidir-tanto-en-mi-cuenta-de-afiliado-como-en-la-de-producto-getresponse-para-integrarlos-juntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dolzny-li-dannye-mojego-akkaunta-getresponse-sovpadat-s-akkauntom-partnerskoj-progammy-dla-togo-shtobi-ich-objedenit.html",
"de": "https://www.getresponse.com/de/hilfe/muessen-meine-kontodetails-sowohl-in-meinem-getresponse-affiliate-als-auch-in-meinem-getresponse-produktkonto-uebereinstimmen-um-sie-miteinander-zu-integrieren.html",
"fr": "https://www.getresponse.com/fr/aide/les-details-de-mon-compte-doivent-ils-correspondre-a-la-fois-sur-mon-compte-d-affilie-getresponse-et-sur-mon-compte-de-produit-getresponse-pour-les-integrer-ensemble.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostICreatedMyGetresponseAccountThroughTheAffiliatePanelButHavenTReceivedAnyDiscountCode = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/i-created-my-getresponse-account-through-the-affiliate-panel-but-haven-t-received-any-discount-code.html",
"it": "https://www.getresponse.com/it/assistenza/ho-creato-il-mio-account-getresponse-tramite-il-pannello-di-affiliazione-ma-non-ho-ricevuto-nessuno-codice-sconto.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-criei-minha-conta-getresponse-atraves-do-painel-de-afiliados-mas-nao-recebi-nenhum-codigo-de-desconto.html",
"pl": "https://www.getresponse.pl/pomoc/utworzylem-konto-getresponse-w-panelu-partnerskim-ale-nie-otrzymalem-kodu-znizkowego.html",
"es": "https://www.getresponse.com/es/ayuda/he-creado-mi-cuenta-de-getresponse-a-traves-del-panel-de-afiliados-pero-no-he-recibido-ningun-codigo-de-descuento.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-getresponse-konto-ueber-das-affiliate-panel-erstellt-aber-keinen-rabattcode-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/ja-sozdal-swoi-akkaunt-v-getresponse-cherez-partnerskuju-panel-no-ne-poluchil-kod-skidki.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-cree-mon-compte-getresponse-via-le-panneau-affilie-mais-je-n-ai-pas-recu-de-code-de-reduction.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseMyAffiliateDiscountCodeMoreThanOnce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-my-affiliate-discount-code-more-than-once.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-il-mio-codice-sconto-affiliato-piu-di-una-volta.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-meu-codigo-de-desconto-de-afiliado-mais-de-uma-vez.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-kodu-znizkowego-dla-programow-partnerskich-wiecej-niz-jeden-raz.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-codigo-de-descuento-de-afiliado-mas-de-una-vez.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-partnerskiy-kod-skidki-bolee-odnogo-raza.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meinen-affiliate-rabattcode-mehr-als-einmal-verwenden.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirFormulariosEmergentesAUnaLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIDeleteAPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/how-can-i-delete-a-page.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-a-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ydalit-stranitsy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-eliminar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-podstrone.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineSeiteAusDemSiteMenueVerbergen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchSeitenImSiteMenueVerwalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchImWebsiteEditorZwischenDenSeitenNavigieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html",
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineDomainMitGetresponseKaufen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieSeitenreihenfolgeImSiteMenueAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteEineEigeneDomainZuweisen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineWebsiteInDenGoogleTagManagerIntegrieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinerWebsiteDasConversionTrackingVonGoogleAdsHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineWebsiteMitYandexMetricaVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatSajtSPomoshhjuKonstruktoraSIi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoVerificarMiIntegracionConPaypal = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEliminarUnaListaDeContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoVenderUnEbookUtilizandoElEmbudoDeConversion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnQueSeDiferenciaElEmbudoDeConversionDeLaAutomatizacionDelMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicMojaStarszaWersjeLandingPagePrzyPomocyYandexMetrica = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezhduLendingomIVebSajtom = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatDejstvieOtpravitPushUvedomlenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatWebPushUvedomlenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePrevenireLeRegistrazioniFalseDeiBot = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDiePostanschriftInDerFusszeileDerNachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnListBuildingFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToInstallTheGetresponseChatsCodeOnMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-install-the-getresponse-chats-code-on-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalar-el-codigo-de-getresponse-chats-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ystanovit-kod-getresponse-chats-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-o-codigo-getresponse-chats-no-meu-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-den-getresponse-chats-code-auf-meiner-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zainstalowac-czaty-getresponse-na-mojej-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-installare-il-codice-getresponse-chats-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-le-code-de-chats-getresponse-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnEmbudoDeLeadMagnet = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCheckIfThePersonOnGetresponseChatsIsStillOnThePage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-check-if-the-person-on-getresponse-chats-is-still-on-the-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-comprobar-si-la-persona-en-getresponse-chats-sigue-en-la-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-proverit-nahoditsiya-chelovek-v-chate-getresponse-na-stranice.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-se-a-pessoa-no-getresponse-chats-ainda-esta-na-pagina.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ueberpruefen-ob-die-person-in-getresponse-chats-noch-auf-der-seite-ist.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-czy-osoba-z-ktora-rozmawiam-na-czacie-jest-wciaz-na-stronie.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-se-la-persona-su-getresponse-chats-e-ancora-sulla-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-si-la-personne-sur-getresponse-chats-est-toujours-sur-la-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToBlockSomeoneOnGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-block-someone-on-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-bloquear-a-alguien-en-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-bloquear-alguem-em-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zablokirovat-kogolibo-v-chatah-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-kontakt-na-czacie-getresponse-3.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sperre-ich-jemanden-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-bloccare-qualcuno-sulle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-quelqu-un-sur-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatArePredefinedMessagesAndHowToUseThem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-predefined-messages-and-how-to-use-them.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-respuestas-rapidas-en-los-chats-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-gotovye-soobsheniya-i-kak-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-mensagens-pre-definidas-e-como-utiliza-las.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-szybkie-odpowiedzi-i-jak-ich-uzywac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-vordefinierte-nachrichten-und-wie-werden-sie-verwendet.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-messaggi-predefiniti-e-come-usarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-messages-predefinis-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindGetresponseChatsStatistics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-getresponse-chats-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogy-najti-statistiky-chatov-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-estatisticas-da-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-getresponse-chats-statistiken.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-czatow-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-di-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-statistiques-de-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddContactsViaGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-contacts-via-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-contactos-a-traves-de-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-atraves-de-chats-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-kontakty-cherez-chaty-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-przez-czaty-getresponse-2.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-ueber-getresponse-chats-hinzu.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-contatti-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSendAttachmentsInGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-send-attachments-in-getresponse-chats.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-archivos-adjuntos-en-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravliyat-vlozeniya-v-chatach-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-anexos-em-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wysylac-zalaczniki-przez-czat-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-anhaenge-in-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-allegati-nelle-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-pieces-jointes-dans-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddAGetresponseChatsLinkInAMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-getresponse-chats-link-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-enlace-de-getresponse-chats-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-link-getresponse-chats-numa-mensagem.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-getresponse-chats-v-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-czatow-getresponse-w-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-getresponse-chats-link-in-eine-nachricht-ein.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-chat-getresponse-link-in-un-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-getresponse-chats-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToEnableGetresponseChatsOnALandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-enable-getresponse-chats-on-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-habilitar-getresponse-chats-en-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-getresponse-chat-na-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-ativar-os-chats-da-getresponse-numa-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wlaczyc-czaty-getresponse-na-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-getresponse-chats-auf-einer-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-chat-getresponse-su-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-chats-de-getresponse-sur-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseGetresponseChatsInTheMobileApp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-getresponse-chats-in-the-mobile-app.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-getresponse-chats-en-la-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-os-chats-getresponse-na-aplicacao-movel.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-ispolzovat-chaty-getresponse-v-mobilnom-prilozenii.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-getresponse-chats-in-der-mobilen-app-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-korzystac-z-funkcji-czatow-getresponse-w-aplikacji-mobilnej.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-getresponse-chats-nell-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-getresponse-chats-dans-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToManageGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-manage-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-chatami-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-gestionar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerir-os-chats-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-czatami-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-chat-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSendAnEmailThroughGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-send-an-email-through-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-cherez-chat-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-un-correo-electronico-a-traves-de-getresponse-chats.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-um-e-mail-atraves-de-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-email-poprzez-czaty-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-eine-e-mail-ueber-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-inviare-un-e-mail-tramite-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-e-mail-via-getresponse-chats.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSetUpGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-set-up-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-chaty-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-los-chats-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-o-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-getresponse-chats-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-czaty-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-impostare-le-chat-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSonoLeChatDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddASignupFormToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-signup-form-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-formy-podpiski-na-svoi-sajt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-formulario-de-inscricao-ao-meu-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-formulario-de-registro-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-ein-anmeldeformular-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-modulo-di-iscrizione-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-zapisu-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-formulaire-d-inscription-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUtilizarLaCondicionEventoEnMisFlujosDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearEventos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerhindertManGefaelschteBotAnmeldungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html",
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEvitarElEnvioDeMensajesConContenidosDeBajaCalidad = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVermeideIchDasSendenVonNachrichtenMitMinderwertigemInhalt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEvitarLasInscripcionesFalsasDeLosBots = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html",
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDieClickToOpenRateCtor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheVorschriftenZurSmsZustellungGibtEsInBestimmtenLaendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html",
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuDlaMoyegoStartovogoUsloviyaNeSrabotalOtritsatelnyPut = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRazmestitFormuIzVersiiLegacyNaSvoeiStranitseVWordpress = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakProdatElektronnuyuKniguSPomoshyuVoronkiKonversii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseGlobalDesignInWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-global-design-in-website-builder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-globalnyj-dezajn-v-konstrykore-saitov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-design-global-no-website-builder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-usare-la-funzione-design-globale-nel-website-builder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-projektu-globalnego-w-kreatorze-stron.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-globales-design-im-website-builder.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-diseno-global-en-el-website-builder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-design-global-dans-le-createur-de-site-internet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarLaAutenticacionDeDosFactoresEnGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZapobiegacFalszywymSubskrypcjom = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html",
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotayetFiltrDinamicheskiySegment = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotayetFiltrDiapazon = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguIspolzovatDeystviyeBally = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguUdalitKontaktyVRezultateProcessa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatDeystviyeZaderzhka = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIEditNotFoundAndPasswordPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-edit-not-found-and-password-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seiten-nicht-gefunden-und-passworteingabe-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-redaktirovat-stranicu-ne-najdeno-zashisennye-parolem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-la-pagina-non-trovata-e-la-pagina-inserisci-password.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-paginas-nao-encontrada-e-de-senha.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-strone-nie-znaleziono-oraz-strone-z-haslem.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-editar-las-paginas-no-encontrado-y-contrasena.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-pages-non-trouve-et-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddALinkToTheSiteMenu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-link-to-the-site-menu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-link-zum-site-menue-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-enlace-al-menu-del-sitio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-ssulky-v-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-link-ao-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-link-al-menu-del-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-link-do-menu-strony.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-lien-au-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIDuplicateAPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-duplicate-a-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-duplizieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-kopiyu-stranicu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-una-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-uma-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zduplikowac-podstrone-3.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-una-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-une-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddANewPageToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-page-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-nueva-pagina-a-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-neue-seite-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-novyu-stranicy-na-svoi-sait.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-nuova-pagina-al-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-uma-nova-pagina-ao-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-podstrone-do-mojej-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-page-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIHideAPageFromTheSiteMenu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-hide-a-page-from-the-site-menu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-seite-aus-dem-site-menue-verbergen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-skryt-stranicy-iz-menu-saita.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-ocultar-uma-pagina-do-menu-do-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-nascondere-una-pagina-dal-menu-del-sito.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-ocultar-una-pagina-del-menu-del-sitio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ukryc-podstrone-w-menu-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-masquer-une-page-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateSubpages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-subpages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-unterseiten-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-sozdat-podstranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-sottopagina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-subpaginas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-podstrony.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-subpaginas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-sous-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeTheHomePage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-the-home-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-domowa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-a-pagina-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-startseite-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-pagina-de-inicio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-glavnyu-stranicy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-home-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-d-accueil.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeThePageOrderInTheSiteMenu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-the-page-order-in-the-site-menu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-seitenreihenfolge-im-site-menue-aendern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-poryadok-stranic-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-l-ordine-delle-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-ordem-das-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-kolejnosc-podstron-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-orden-de-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-ordre-des-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanINavigateBetweenPagesInTheWebsiteEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-navigate-between-pages-in-the-website-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-im-website-editor-zwischen-den-seiten-navigieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-perekluchatsya-mezdy-stranicami-v-redaktore-saitov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-navigare-tra-pagine-nell-editor-dei-siti-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-navegar-entre-as-paginas-do-editor-do-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-navegar-entre-paginas-en-el-editor-del-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przechodzic-miedzy-podstronami-w-kreatorze-stron-internetowych.html",
"fr": "https://www.getresponse.com/fr/aide/comment-naviguer-entre-les-pages-dans-l-editeur-de-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIManagePagesInTheSiteMenu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-manage-pages-in-the-site-menu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-seiten-im-site-menue-verwalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-upravliat-stranicami-v-menu-saita.html",
"it": "https://www.getresponse.com/it/assistenza/come-gestire-le-pagine-nel-menu-del-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-gerenciar-paginas-no-menu-do-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-podstronami-w-menu-strony-internetowej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-las-paginas-en-el-menu-del-sitio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-pages-dans-le-menu-du-site.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaZgloszeniaSpamu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPoprawicJakoscWysylanychWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBelyjSpisok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/belyj-spisok.html",
"pl": "https://www.getresponse.pl/pomoc/whitelist-biala-lista-subskrybentow.html",
"en": "https://www.getresponse.com/help/whitelist.html",
"de": "https://www.getresponse.com/de/hilfe/whitelist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-blanca.html",
"it": "https://www.getresponse.com/it/assistenza/whitelist.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"fr": "https://www.getresponse.com/fr/aide/liste-blanche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZipFajl = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/zip-fajl.html",
"pl": "https://www.getresponse.pl/pomoc/zip.html",
"en": "https://www.getresponse.com/help/zip-file.html",
"de": "https://www.getresponse.com/de/hilfe/zip-file.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-zip.html",
"it": "https://www.getresponse.com/it/assistenza/zip-file.html",
"br": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"pt": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-zip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMaster = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/master.html",
"de": "https://www.getresponse.com/de/hilfe/wizard.html",
"en": "https://www.getresponse.com/help/wizard.html",
"es": "https://www.getresponse.com/es/ayuda/asistente.html",
"it": "https://www.getresponse.com/it/assistenza/wizard.html",
"br": "https://www.getresponse.com/pt/ajuda/wizard.html",
"pt": "https://www.getresponse.com/pt/ajuda/wizard.html",
"pl": "https://www.getresponse.pl/pomoc/wizard.html",
"fr": "https://www.getresponse.com/fr/aide/assistant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSplitTest = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/split-test.html",
"pl": "https://www.getresponse.pl/pomoc/testy-skutecznosci-ang-split-test.html",
"en": "https://www.getresponse.com/help/split-test.html",
"de": "https://www.getresponse.com/de/hilfe/split-test.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-multivariantes.html",
"it": "https://www.getresponse.com/it/assistenza/split-test.html",
"br": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"fr": "https://www.getresponse.com/fr/aide/essai-fractionne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTemaPisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/tema-pisma.html",
"pl": "https://www.getresponse.pl/pomoc/temat-wiadomosci.html",
"en": "https://www.getresponse.com/help/subject-line.html",
"de": "https://www.getresponse.com/de/hilfe/subject-line.html",
"es": "https://www.getresponse.com/es/ayuda/linea-de-asunto.html",
"it": "https://www.getresponse.com/it/assistenza/oggetto.html",
"br": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pt": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"fr": "https://www.getresponse.com/fr/aide/ligne-d-objet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOtchetPoPodpiske = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/custom-reports.html",
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOpros = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/opros.html",
"pl": "https://www.getresponse.pl/pomoc/ankieta.html",
"en": "https://www.getresponse.com/help/survey.html",
"de": "https://www.getresponse.com/de/hilfe/survey.html",
"es": "https://www.getresponse.com/es/ayuda/encuesta.html",
"it": "https://www.getresponse.com/it/assistenza/sondaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pt": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"fr": "https://www.getresponse.com/fr/aide/enquete.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIPurchaseADomainWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-purchase-a-domain-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-mit-getresponse-kaufen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprar-un-dominio-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-acquistare-un-dominio-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-comprar-um-dominio-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kupic-domen-s-pomoshyu-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-mozna-kupic-domene-przez-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-acheter-un-domaine-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAnImageToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-an-image-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-i-ypravlyat-izobrazeniyami-na-svoem-sajte.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-le-immagini-sul-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-e-gerenciar-imagens-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-y-gestionar-imagenes-en-mi-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-bilder-zu-meiner-website-hinzu-und-verwalte-sie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-i-konfigurowac-obrazy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-des-images-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddATextBlockToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-text-block-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-textblock-auf-meiner-website-hinzufuegen-und-verwalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-tekstovyi-blok-na-svoi-sajt-i-upravliyat-im.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-gestire-un-blocco-di-testo-sul-mio-sito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-gerenciar-um-bloco-de-texto-no-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-administrar-un-bloque-de-texto-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-skonfigurowac-element-tekstowy-na-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-gerer-un-bloc-de-texte-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZFunkcjiCzatyWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAssignMyOwnDomainToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-assign-my-own-domain-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-eine-eigene-domain-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-naznachit-domen-svoemy-saity.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-al-mio-sito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-podlaczyc-wlasna-domene-do-mojej-strony-internetowej.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atribuir-meu-proprio-dominio-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asignar-mi-propio-dominio-a-mi-sitio-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoOznaczaWspolczynnikKlikniecDoOtwarcCtor = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenALandingPageAndAWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-landing-page-and-a-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-lendingom-i-veb-sajtom.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-landing-page-a-strona-internetowa.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-uma-landing-page-e-um-website.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-landing-page-e-un-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-pagina-de-aterrizaje-y-un-sitio-web.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-landing-page-und-einer-website.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-page-de-destination-et-un-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAButtonToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-button-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-dobavit-i-otredaktirovat-knopku-na-swoyom-sayte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-botao-em-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-y-editar-un-boton-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-pulsante-sul-mio-sito-web.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-button-auf-meiner-website-hinzufuegen-und-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-i-edytowac-przyciski-na-swojej-stronie.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-bouton-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAndEditASiteMenuToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-and-edit-a-site-menu-to-my-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-i-otredaktirovat-menu-saita-na-svoem-websaite.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-e-modificare-un-menu-del-sito-al-mio-sito-web.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-y-editar-un-menu-del-sitio-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-i-edytowac-menu-strony.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-e-editar-um-menu-do-site-no-meu-site.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-website-menue-zu-meiner-website-hinzufuegen-und-bearbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-et-modifier-un-menu-de-site-de-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddANewSectionToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-section-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-secciones-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-ispolzovat-razdely-na-moem-saite.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-as-secoes-do-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-na-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-abschnitte-auf-meiner-website-verwenden.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-utilizzare-le-sezioni-sul-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-sur-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguPeremeshatKontaktyMezhduSpiskami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatNastraivaemyePolyaVAvtomatizacii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackMyWebsiteWithFacebookPixel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-facebook-pixel.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-el-pixel-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-uzywajac-piksela-facebooka.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verfolge-ich-meine-website-mit-dem-facebook-pixel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-moi-sajt-s-pomoshju-facebook-pixel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-facebook-pixel.html",
"it": "https://www.getresponse.com/it/assistenza/come-monitorare-il-mio-sito-web-con-facebook-pixel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanITrackMyWebsiteWithYandexMetrica = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-track-my-website-with-yandex-metrica.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-mit-yandex-metrica-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-seguir-mi-sitio-web-con-yandex-metrica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otslezivat-svoi-sait-s-pomoshyu-yandex-metriki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-meu-site-com-a-yandex-metrica.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-web-con-yandex-metrica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-moja-strone-internetowa-korzystajac-z-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIIntegrateMyWebsiteWithGoogleTagManager = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-integrate-my-website-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-website-in-den-google-tag-manager-integrieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-sajt-s-dispetcherom-tegov-google.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-meu-site-com-o-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-integrare-il-mio-sito-con-il-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-mi-sitio-web-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-zintegrowac-strone-z-menedzerem-tagow-google.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-site-web-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddGoogleAdsConversionTrackingToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-google-ads-conversion-tracking-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meiner-website-das-conversion-tracking-von-google-ads-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-na-svoi-sajt-otslezivanie-konversij-google-reklamy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-o-rastreamento-de-conversao-do-google-ads-ao-meu-site.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-el-seguimiento-de-conversiones-de-google-ads-a-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-il-monitoraggio-delle-conversioni-di-google-ads-al-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-sledzenie-konwersji-google-ads-do-strony-internetowej.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-suivi-des-conversions-google-ads-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackMyWebsiteWithGoogleAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-my-website-with-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezivat-sait-s-pomoshyu-google-analitiki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-meu-site-com-o-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-hacer-un-seguimiento-de-mi-sitio-web-con-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-tracciare-il-mio-sito-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-mojej-stronie-internetowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-tracke-ich-meine-website-mit-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-site-web-avec-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSaveAndReuseSectionsAndBlocks = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-save-and-reuse-sections-and-blocks.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sohranyat-i-povtorno-ispolzovat-razdely-i-bloki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-salvar-e-reutilizar-secoes-e-blocos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-e-riusare-sezioni-e-blocchi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapisac-sekcje-i-bloki-aby-moc-ich-uzyc-ponownie.html",
"es": "https://www.getresponse.com/es/ayuda/como-guardar-y-reutilizar-secciones-y-bloques.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-abschnitte-und-bloecke-speichern-und-wiederverwenden.html",
"fr": "https://www.getresponse.com/fr/aide/comment-sauvegarder-et-reutiliser-des-sections-et-des-blocs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIEmbedAPhotoFromAUrl = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-embed-a-photo-from-a-url.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-obraz-z-adresu-url-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-inserir-uma-foto-a-partir-de-uma-url.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-adjuntar-una-foto-desde-una-url.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-incorporare-una-foto-da-un-url.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-une-photo-a-partir-d-une-url.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAWebsiteUsingAiWizard = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-ai-wizard.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomoshhju-konstruktora-s-ii.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-korzystajac-z-inteligentnego-kreatora.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-el-asistente-de-ia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-site-usando-o-criador-orientado-pela-ia.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-l-intelligenza-artificiale.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-dem-ki-assistenten.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-l-assistant-ia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAWebsiteUsingTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-a-website-using-templates.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-internetowa-uzywajac-szablonow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sajt-s-pomosjyu-shablonov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-sito-web-con-i-template.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-um-site-usando-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-website-mit-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-sitio-web-con-plantillas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-site-web-a-l-aide-de-modeles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiPerenestiKontaktyIzOdnogoSpiskaVDrugojVRezultateProcessaAvtomatizacii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitPochtovyjAdresVFuterePisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotaetUslovieStatusOplatyIzmenilsya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html",
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicAdresPocztowyWidocznyWStopceWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitSvojstvaUslovijaPoseshennajaStranitsa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUzycWersjeRoboczaWiadomosciLubNewsletterJakoAutoresponder = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-wersje-robocza-wiadomosci-lub-newsletter-jako-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-entwurf-oder-newsletter-als-autoresponder-verwenden.html",
"en": "https://www.getresponse.com/help/can-i-use-my-draft-or-newsletter-as-an-autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-chernoviki-ili-rassylki-v-kachestve-avtootvetchika.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-la-mia-bozza-o-newsletter-come-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-borrador-o-newsletter-como-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieDaneEcommerceSaPrzesylaneDoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostYaNeMoguOpublikovatSvojProcessChtoMneDelat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html",
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEvitarOEnvioDeMensagensDeConteudoDeBaixaQualidade = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPrevenirAsAssinaturasFalsasDeBots = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html",
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStranicaBlagodarnosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/stranica-blagodarnosti.html",
"pl": "https://www.getresponse.pl/pomoc/strona-z-podziekowaniem.html",
"en": "https://www.getresponse.com/help/thank-you-page.html",
"de": "https://www.getresponse.com/de/hilfe/thank-you-page.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-de-agradecimiento.html",
"it": "https://www.getresponse.com/it/assistenza/pagina-di-ringraziamento.html",
"br": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOtpisavshiesyaKontakty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/otpisavshiesya-kontakty.html",
"pl": "https://www.getresponse.pl/pomoc/usuniete.html",
"en": "https://www.getresponse.com/help/removals.html",
"de": "https://www.getresponse.com/de/hilfe/removals.html",
"es": "https://www.getresponse.com/es/ayuda/bajas.html",
"it": "https://www.getresponse.com/it/assistenza/contatti-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"fr": "https://www.getresponse.com/fr/aide/suppressions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdresDlyaOtveta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/adres-dlya-otveta.html",
"pl": "https://www.getresponse.pl/pomoc/pole-odpowiedz-do.html",
"en": "https://www.getresponse.com/help/reply-to-field.html",
"de": "https://www.getresponse.com/de/hilfe/reply-to-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-respuesta.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-di-risposta.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"fr": "https://www.getresponse.com/fr/aide/champ-de-reponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPoiskovyjMarketingSem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/poiskovyj-marketing-sem.html",
"pl": "https://www.getresponse.pl/pomoc/sem.html",
"en": "https://www.getresponse.com/help/sem.html",
"de": "https://www.getresponse.com/de/hilfe/sem.html",
"es": "https://www.getresponse.com/es/ayuda/sem.html",
"it": "https://www.getresponse.com/it/assistenza/sem.html",
"br": "https://www.getresponse.com/pt/ajuda/sem.html",
"pt": "https://www.getresponse.com/pt/ajuda/sem.html",
"fr": "https://www.getresponse.com/fr/aide/sem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZhalobaNaSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/zhaloba-na-spam.html",
"pl": "https://www.getresponse.pl/pomoc/zgloszenie-spamu.html",
"en": "https://www.getresponse.com/help/spam-complaint.html",
"de": "https://www.getresponse.com/de/hilfe/spam-complaint.html",
"es": "https://www.getresponse.com/es/ayuda/denuncia-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/reclamo-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/plainte-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingNaOsnoveRazreshenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/marketing-na-osnove-razreshenij.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-za-przyzwoleniem.html",
"en": "https://www.getresponse.com/help/permission-based-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-based-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-basado-en-permisos.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-basato-sul-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-base-sur-les-permissions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVsplyvajushheeOkno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vsplyvajushhee-okno.html",
"pl": "https://www.getresponse.pl/pomoc/pop-up.html",
"en": "https://www.getresponse.com/help/pop-up.html",
"de": "https://www.getresponse.com/de/hilfe/pop-up.html",
"es": "https://www.getresponse.com/es/ayuda/pop-up.html",
"it": "https://www.getresponse.com/it/assistenza/pop-up.html",
"br": "https://www.getresponse.com/pt/ajuda/pop-up.html",
"pt": "https://www.getresponse.com/pt/ajuda/pop-up.html",
"fr": "https://www.getresponse.com/fr/aide/pop-up.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochtovyjAdres = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-adres.html",
"pl": "https://www.getresponse.pl/pomoc/adres-pocztowy.html",
"en": "https://www.getresponse.com/help/postal-address.html",
"de": "https://www.getresponse.com/de/hilfe/postal-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-postal.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-postale.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"fr": "https://www.getresponse.com/fr/aide/adresse-postale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKopirovaniePisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kopirovanie-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/pre-load.html",
"es": "https://www.getresponse.com/es/ayuda/pre-carga.html",
"it": "https://www.getresponse.com/it/assistenza/precaricamento.html",
"br": "https://www.getresponse.com/pt/ajuda/pre-carga.html",
"pt": "https://www.getresponse.com/pt/ajuda/pre-carga.html",
"fr": "https://www.getresponse.com/fr/aide/pre-load.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSlovoObedenenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/slovo-obedenenie.html",
"pl": "https://www.getresponse.pl/pomoc/merge-word.html",
"en": "https://www.getresponse.com/help/merge-word.html",
"de": "https://www.getresponse.com/de/hilfe/merge-word.html",
"es": "https://www.getresponse.com/es/ayuda/fusion-de-palabras.html",
"it": "https://www.getresponse.com/it/assistenza/fusione-di-parole.html",
"br": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"pt": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"fr": "https://www.getresponse.com/fr/aide/fusionner-des-mots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMassovyjOtkazOtPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/massovyj-otkaz-ot-podpiski.html",
"de": "https://www.getresponse.com/de/hilfe/multi-unsubscribe-opt-out.html",
"en": "https://www.getresponse.com/help/multi-unsubscribe-opt-out.html",
"es": "https://www.getresponse.com/es/ayuda/desinscripcion-multiple-opt-out.html",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-multipla-opt-out.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pl": "https://www.getresponse.pl/pomoc/multi-unsubscribe-wypis.html",
"fr": "https://www.getresponse.com/fr/aide/desabonnement-multiple-opt-out.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostShablonPisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/shablon-pisma.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-newslettera.html",
"en": "https://www.getresponse.com/help/newsletter-template.html",
"de": "https://www.getresponse.com/de/hilfe/newsletter-template.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodpiskaBezPodtverzhdeniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-bez-podtverzhdeniya.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-bez-potwierdzenia.html",
"en": "https://www.getresponse.com/help/non-confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/non-confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-no-confirmado.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-non-confermato.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-non-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAModificareIlFooterDelMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEATaxaDeCliquesPorAberturaCtor = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarEmailsTransacionaisAtravesDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMudarOEnderecoPostalNoRodapeDaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEuTenhoUmaContaMaxComoPossoComecar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEGetresponseMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateMagento2WithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-magento-2-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-magento-2-con-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-magento-2-con-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriert-man-magento-2-mit-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-magento-2-z-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-o-magento-2-com-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-inegrirovat-magento2-s-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-magento-2-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOtchet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/otchet.html",
"pl": "https://www.getresponse.pl/pomoc/raport.html",
"en": "https://www.getresponse.com/help/report.html",
"de": "https://www.getresponse.com/de/hilfe/bericht.html",
"es": "https://www.getresponse.com/es/ayuda/informe.html",
"it": "https://www.getresponse.com/it/assistenza/report.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"fr": "https://www.getresponse.com/fr/aide/rapport.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUvedomlenieOPodpiske = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/uvedomlenie-o-podpiske.html",
"pl": "https://www.getresponse.pl/pomoc/notification-powiadomienia.html",
"en": "https://www.getresponse.com/help/notification.html",
"de": "https://www.getresponse.com/de/hilfe/notification.html",
"es": "https://www.getresponse.com/es/ayuda/notificacion.html",
"it": "https://www.getresponse.com/it/assistenza/notificazione.html",
"br": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"fr": "https://www.getresponse.com/fr/aide/notification.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPokazatelOtkrytij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-otkrytij.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-otwarc.html",
"en": "https://www.getresponse.com/help/open-rate.html",
"de": "https://www.getresponse.com/de/hilfe/open-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-apertura.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-apertura.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodpiskaNaRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-na-rassylki.html",
"pl": "https://www.getresponse.pl/pomoc/opt-in-email.html",
"en": "https://www.getresponse.com/help/opt-in-email.html",
"de": "https://www.getresponse.com/de/hilfe/opt-in-email.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-email.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-email.html",
"br": "https://www.getresponse.com/pt/ajuda/email-de-opt-in-adesao.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-de-opt-in-adesao.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOtkazOtPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStatistikaDejstvij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/statistika-dejstvij.html",
"pl": "https://www.getresponse.pl/pomoc/statystki-wysylek.html",
"en": "https://www.getresponse.com/help/performance-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/performance-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-desempeno.html",
"it": "https://www.getresponse.com/it/assistenza/statistiche-di-rendimento.html",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"fr": "https://www.getresponse.com/fr/aide/statistiques-de-performance.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIpAdres = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/ip-adres.html",
"pl": "https://www.getresponse.pl/pomoc/adres-ip.html",
"en": "https://www.getresponse.com/help/ip-address.html",
"de": "https://www.getresponse.com/de/hilfe/ip-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-de-ip.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/addresse-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokirovkaIp = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-ip.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-adresu-ip.html",
"en": "https://www.getresponse.com/help/ip-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/ip-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-ip.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVebForma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/veb-forma.html",
"pl": "https://www.getresponse.pl/pomoc/formularz-zapisu.html",
"en": "https://www.getresponse.com/help/web-form.html",
"de": "https://www.getresponse.com/de/hilfe/web-form.html",
"es": "https://www.getresponse.com/es/ayuda/formulario-web.html",
"it": "https://www.getresponse.com/it/assistenza/modulo-web.html",
"br": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"fr": "https://www.getresponse.com/fr/aide/formulaire-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLending = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/lending.html",
"pl": "https://www.getresponse.pl/pomoc/landing-page.html",
"en": "https://www.getresponse.com/help/landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKonversiyaLida = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/konversiya-lida.html",
"pl": "https://www.getresponse.pl/pomoc/konwersja-leadow.html",
"en": "https://www.getresponse.com/help/lead-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/lead-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-clientes-potenciales.html",
"it": "https://www.getresponse.com/it/assistenza/conversione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUpravlenieSpiskami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-spiskami.html",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-lista.html",
"en": "https://www.getresponse.com/help/list-management.html",
"de": "https://www.getresponse.com/de/hilfe/list-management.html",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-listas.html",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentaciyaSpiska = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-spiska.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-listy.html",
"en": "https://www.getresponse.com/help/list-segmentation.html",
"de": "https://www.getresponse.com/de/hilfe/list-segmentation.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-lista.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-della-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEuDefinoAsPropriedadesParaACondicaoDeCarrinhoAbandonado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCriarContasAdicionaisEmMinhaContaMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToChangeThePostalAddressInTheMessageFooter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-change-the-postal-address-in-the-message-footer.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-o-endereco-postal-no-rodape-da-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-il-footer-del-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-pocztowy-widoczny-w-stopce-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-pochtovyj-adres-v-futere-pisma.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-postanschrift-in-der-fusszeile-der-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-direccion-postal-en-el-pie-de-pagina-del-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-postale-dans-le-pied-de-page-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarAGetresponseComAmazon = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateGetresponseWithAmazon = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-getresponse-with-amazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-a-getresponse-com-amazon.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-amazon.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-amazon.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-getresponse-con-amazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-integrare-getresponse-con-amazon.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-amazon.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-amazon.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZawrzecProduktyZPorzuconegoKoszykaWWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zawrzec-produkty-z-porzuconego-koszyka-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-include-abandoned-cart-items-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incluir-articulos-de-carrito-abandonado-en-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-v-soobshenie-predmety-iz-broshennoj-korziny.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-includere-gli-articoli-del-carrello-abbandonato-in-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-inclure-les-articles-du-panier-abandonne-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycLejekZLeadMagnetem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkorzystacZWarunkuZdarzenieWMoimCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInterval = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/interval.html",
"pl": "https://www.getresponse.pl/pomoc/interwal.html",
"en": "https://www.getresponse.com/help/interval.html",
"de": "https://www.getresponse.com/de/hilfe/interval.html",
"es": "https://www.getresponse.com/es/ayuda/intervalo.html",
"it": "https://www.getresponse.com/it/assistenza/intervallo.html",
"br": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"fr": "https://www.getresponse.com/fr/aide/intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIspInternetProvajder = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/isp-internet-provajder.html",
"pl": "https://www.getresponse.pl/pomoc/isp.html",
"en": "https://www.getresponse.com/help/isp.html",
"de": "https://www.getresponse.com/de/hilfe/isp.html",
"es": "https://www.getresponse.com/es/ayuda/isp.html",
"it": "https://www.getresponse.com/it/assistenza/isp.html",
"br": "https://www.getresponse.com/pt/ajuda/psi.html",
"pt": "https://www.getresponse.com/pt/ajuda/psi.html",
"fr": "https://www.getresponse.com/fr/aide/isp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeneraciyaLidov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/generaciya-lidov.html",
"pl": "https://www.getresponse.pl/pomoc/pozyskiwanie-leadow.html",
"en": "https://www.getresponse.com/help/leads-generation.html",
"de": "https://www.getresponse.com/de/hilfe/leads-generation.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-clientes-potenciales.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSsylka = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/ssylka.html",
"pl": "https://www.getresponse.pl/pomoc/link.html",
"en": "https://www.getresponse.com/help/link.html",
"de": "https://www.getresponse.com/de/hilfe/link.html",
"es": "https://www.getresponse.com/es/ayuda/link.html",
"it": "https://www.getresponse.com/it/assistenza/link.html",
"br": "https://www.getresponse.com/pt/ajuda/link.html",
"pt": "https://www.getresponse.com/pt/ajuda/link.html",
"fr": "https://www.getresponse.com/fr/aide/lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdresOtpravitelya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/adres-otpravitelya.html",
"pl": "https://www.getresponse.pl/pomoc/pole-nadawcy.html",
"en": "https://www.getresponse.com/help/from-field.html",
"de": "https://www.getresponse.com/de/hilfe/from-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-remitente.html",
"it": "https://www.getresponse.com/it/assistenza/from-field.html",
"br": "https://www.getresponse.com/pt/ajuda/remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/remetente.html",
"fr": "https://www.getresponse.com/fr/aide/le-champ-de.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSozdanieHtml = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-html.html",
"pl": "https://www.getresponse.pl/pomoc/generowanie-kodu-html.html",
"en": "https://www.getresponse.com/help/generating-html.html",
"de": "https://www.getresponse.com/de/hilfe/generating-html.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-html.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-html.html",
"br": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMestopolozhenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mestopolozhenie.html",
"pl": "https://www.getresponse.pl/pomoc/geo-mapa.html",
"de": "https://www.getresponse.com/de/hilfe/geo-map.html",
"es": "https://www.getresponse.com/es/ayuda/mapa-geografico.html",
"it": "https://www.getresponse.com/it/assistenza/geo-map.html",
"br": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeryjSpisok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"en": "https://www.getresponse.com/help/greylist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html",
"de": "https://www.getresponse.com/de/hilfe/grauliste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHtmlPismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/html-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-html.html",
"de": "https://www.getresponse.com/de/hilfe/html-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/html-message.html",
"pt": "https://www.getresponse.com/pt/ajuda/html-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokirovkaIzobrazhenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-izobrazhenij.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-obrazkow.html",
"en": "https://www.getresponse.com/help/image-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/image-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-imagenes.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-delle-immagini.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-de-l-image.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostImport = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/import.html",
"pl": "https://www.getresponse.pl/pomoc/import.html",
"en": "https://www.getresponse.com/help/import.html",
"de": "https://www.getresponse.com/de/hilfe/import.html",
"es": "https://www.getresponse.com/es/ayuda/importar.html",
"it": "https://www.getresponse.com/it/assistenza/import.html",
"br": "https://www.getresponse.com/pt/ajuda/importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/importacao.html",
"fr": "https://www.getresponse.com/fr/aide/importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNeaktivnyeKontakty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/neaktivnye-kontakty.html",
"pl": "https://www.getresponse.pl/pomoc/nieaktywni-subskrybenci.html",
"en": "https://www.getresponse.com/help/inactive-subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/inactive-subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores-inactivos.html",
"it": "https://www.getresponse.com/it/assistenza/sottoscrittori-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMgnovennoePismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mgnovennoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-natychmiastowa-blyskawiczna.html",
"en": "https://www.getresponse.com/help/instant-message.html",
"de": "https://www.getresponse.com/de/hilfe/instant-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-instantaneo.html",
"it": "https://www.getresponse.com/it/assistenza/instant-message.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"fr": "https://www.getresponse.com/fr/aide/message-instantane.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailReklama = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/email-reklama.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-mailowa.html",
"en": "https://www.getresponse.com/help/email-advertising.html",
"de": "https://www.getresponse.com/de/hilfe/email-advertising.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-par-courriel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailKampaniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/email-kampaniya.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/email-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-email.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-d-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakovyPravilaDostavkiSmsVKonkretnyhStranah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoOsRegulamentosDeEntregaDeSmsEmDeterminadosPaises = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLasNormasDeEntregaDeSmsEnDeterminadosPaises = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCompartirUnEbookConTusContactosEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoLeRegolazioniDiConsegnaSmsInDeterminatiPaesi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChastota = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chastota.html",
"pl": "https://www.getresponse.pl/pomoc/czestotliwosc-wyslanych-emaili.html",
"en": "https://www.getresponse.com/help/frequency.html",
"de": "https://www.getresponse.com/de/hilfe/frequency.html",
"es": "https://www.getresponse.com/es/ayuda/frecuencia.html",
"it": "https://www.getresponse.com/it/assistenza/frequenza.html",
"br": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"fr": "https://www.getresponse.com/fr/aide/frequence.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochtovyjKlient = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-klient.html",
"pl": "https://www.getresponse.pl/pomoc/klient-poczty-elektronicznej.html",
"en": "https://www.getresponse.com/help/email-client.html",
"de": "https://www.getresponse.com/de/hilfe/email-client.html",
"it": "https://www.getresponse.com/it/assistenza/cliente-di-posta-elettronica.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"es": "https://www.getresponse.com/es/ayuda/cliente-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/client-de-messagerie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZagolovkiElektronnoiPochti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/zagolovki-elektronnoi-pochti.html",
"pl": "https://www.getresponse.pl/pomoc/naglowki-emaila.html",
"en": "https://www.getresponse.com/help/email-headers.html",
"de": "https://www.getresponse.com/de/hilfe/email-headers.html",
"es": "https://www.getresponse.com/es/ayuda/encabezados-de-email.html",
"it": "https://www.getresponse.com/it/assistenza/intestazioni-e-mail-e-mail-headers.html",
"br": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/en-tetes-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOtpravkaPoElektronnojPochte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/otpravka-po-elektronnoj-pochte.html",
"pl": "https://www.getresponse.pl/pomoc/emailing.html",
"en": "https://www.getresponse.com/help/emailing.html",
"de": "https://www.getresponse.com/de/hilfe/emailing.html",
"es": "https://www.getresponse.com/es/ayuda/enviando-emails.html",
"it": "https://www.getresponse.com/it/assistenza/invio-di-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/l-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailMarketing = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-emailowa.html",
"en": "https://www.getresponse.com/help/email-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/email-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-por-email.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-par-courrier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFajlVlozhenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/fajl-vlozhenie.html",
"pl": "https://www.getresponse.pl/pomoc/zalacznik.html",
"en": "https://www.getresponse.com/help/file-attachment.html",
"de": "https://www.getresponse.com/de/hilfe/file-attachment.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-adjunto.html",
"it": "https://www.getresponse.com/it/assistenza/file-allegato.html",
"br": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pt": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-joint.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFuter = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/futer.html",
"pl": "https://www.getresponse.pl/pomoc/stopka-maila.html",
"en": "https://www.getresponse.com/help/footer.html",
"de": "https://www.getresponse.com/de/hilfe/footer.html",
"es": "https://www.getresponse.com/es/ayuda/pie-de-pagina.html",
"it": "https://www.getresponse.com/it/assistenza/pie-di-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/rodape.html",
"fr": "https://www.getresponse.com/fr/aide/pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKuki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kuki.html",
"pl": "https://www.getresponse.pl/pomoc/ciasteczka.html",
"en": "https://www.getresponse.com/help/cookie.html",
"de": "https://www.getresponse.com/de/hilfe/cookie.html",
"es": "https://www.getresponse.com/es/ayuda/cookie.html",
"it": "https://www.getresponse.com/it/assistenza/cookie.html",
"br": "https://www.getresponse.com/pt/ajuda/cookie.html",
"pt": "https://www.getresponse.com/pt/ajuda/cookie.html",
"fr": "https://www.getresponse.com/fr/aide/cookie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVydelennyjServer = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vydelennyj-server.html",
"pl": "https://www.getresponse.pl/pomoc/serwer-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-server.html",
"de": "https://www.getresponse.com/de/hilfe/dedicated-server.html",
"es": "https://www.getresponse.com/es/ayuda/servidor-dedicado.html",
"it": "https://www.getresponse.com/it/assistenza/server-dedicato.html",
"br": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"fr": "https://www.getresponse.com/fr/aide/serveur-dedie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNastraivaemoePole = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemoe-pole.html",
"de": "https://www.getresponse.com/de/hilfe/custom-field.html",
"en": "https://www.getresponse.com/help/custom-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-personalizado.html",
"it": "https://www.getresponse.com/it/assistenza/campo-personalizzato.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/pole-dodatkowe-2.html",
"fr": "https://www.getresponse.com/fr/aide/champ-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalizaciya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/personalizaciya.html",
"pl": "https://www.getresponse.pl/pomoc/personalizacja.html",
"en": "https://www.getresponse.com/help/personalization.html",
"de": "https://www.getresponse.com/de/hilfe/personalization.html",
"es": "https://www.getresponse.com/es/ayuda/personalizacion.html",
"it": "https://www.getresponse.com/it/assistenza/personalizzazione.html",
"br": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"fr": "https://www.getresponse.com/fr/aide/personnalisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCsvFormat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/csv-format.html",
"pl": "https://www.getresponse.pl/pomoc/format-csv.html",
"en": "https://www.getresponse.com/help/csv-format.html",
"de": "https://www.getresponse.com/de/hilfe/csv-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-csv.html",
"it": "https://www.getresponse.com/it/assistenza/formato-csv.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-csv.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-csv.html",
"fr": "https://www.getresponse.com/fr/aide/format-csv.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProstojTekst = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prostoj-tekst.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-w-formacie-tekstowym.html",
"en": "https://www.getresponse.com/help/plain-text.html",
"de": "https://www.getresponse.com/de/hilfe/plain-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-sin-formato.html",
"it": "https://www.getresponse.com/it/assistenza/testo-semplice.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPismoDostavleno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pismo-dostavleno.html",
"pl": "https://www.getresponse.pl/pomoc/dostarczona.html",
"en": "https://www.getresponse.com/help/delivered.html",
"de": "https://www.getresponse.com/de/hilfe/delivered.html",
"es": "https://www.getresponse.com/es/ayuda/entregado.html",
"it": "https://www.getresponse.com/it/assistenza/consegnato.html",
"br": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pt": "https://www.getresponse.com/pt/ajuda/entregue.html",
"fr": "https://www.getresponse.com/fr/aide/livre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDomennoeImya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/domennoe-imya.html",
"pl": "https://www.getresponse.pl/pomoc/nazwa-domeny.html",
"de": "https://www.getresponse.com/de/hilfe/domain-name.html",
"es": "https://www.getresponse.com/es/ayuda/nombre-de-dominio.html",
"it": "https://www.getresponse.com/it/assistenza/nome-di-dominio.html",
"br": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUrovenDostavlyaemosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/uroven-dostavlyaemosti.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-dostarczalnosci.html",
"en": "https://www.getresponse.com/help/deliverability-rate.html",
"de": "https://www.getresponse.com/de/hilfe/deliverability-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-consegna.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpufing = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/spufing.html",
"pl": "https://www.getresponse.pl/pomoc/spoofing.html",
"en": "https://www.getresponse.com/help/spoofing.html",
"de": "https://www.getresponse.com/de/hilfe/spoofing.html",
"es": "https://www.getresponse.com/es/ayuda/spoofing.html",
"it": "https://www.getresponse.com/it/assistenza/spoofing.html",
"br": "https://www.getresponse.com/pt/ajuda/spoofing.html",
"pt": "https://www.getresponse.com/pt/ajuda/spoofing.html",
"fr": "https://www.getresponse.com/fr/aide/spoofing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPokazatelOshibokDostavki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-oshibok-dostavki.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zwrotow.html",
"en": "https://www.getresponse.com/help/bounce-rate.html",
"de": "https://www.getresponse.com/de/hilfe/bounce-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-rebote.html",
"it": "https://www.getresponse.com/it/assistenza/bounce-rate.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-rebond.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDinamicheskijKontent = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dinamicheskij-kontent.html",
"pl": "https://www.getresponse.pl/pomoc/dynamic-content.html",
"en": "https://www.getresponse.com/help/dynamic-content.html",
"de": "https://www.getresponse.com/de/hilfe/dynamic-content.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-dinamico.html",
"it": "https://www.getresponse.com/it/assistenza/contenuti-dinamici.html",
"br": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTranslyaciya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/translyaciya.html",
"pl": "https://www.getresponse.pl/pomoc/wysylka.html",
"en": "https://www.getresponse.com/help/broadcast.html",
"de": "https://www.getresponse.com/de/hilfe/broadcast.html",
"es": "https://www.getresponse.com/es/ayuda/transmision.html",
"it": "https://www.getresponse.com/it/assistenza/broadcast.html",
"br": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"fr": "https://www.getresponse.com/fr/aide/transmission.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearUnWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMassovayaRassylka = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/massovaya-rassylka.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-masowa.html",
"en": "https://www.getresponse.com/help/bulk-email.html",
"de": "https://www.getresponse.com/de/hilfe/bulk-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-masivo.html",
"it": "https://www.getresponse.com/it/assistenza/email-di-massa.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"fr": "https://www.getresponse.com/fr/aide/envoi-massif-de-courriels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpisokRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/spisok-rassylki.html",
"pl": "https://www.getresponse.pl/pomoc/lista-mailingowa.html",
"en": "https://www.getresponse.com/help/mailing-list.html",
"de": "https://www.getresponse.com/de/hilfe/mailing-list.html",
"es": "https://www.getresponse.com/es/ayuda/lista-de-correos.html",
"it": "https://www.getresponse.com/it/assistenza/lista-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"fr": "https://www.getresponse.com/fr/aide/liste-d-adresses-electroniques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRassylka = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/rassylka.html",
"pl": "https://www.getresponse.pl/pomoc/newsletter.html",
"en": "https://www.getresponse.com/help/newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeProcentPerehodovOtChislaOtkrytyhPisemCtor = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOtslezhivaniePoKliku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/otslezhivanie-po-kliku.html",
"pl": "https://www.getresponse.pl/pomoc/sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/click-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-de-clics.html",
"it": "https://www.getresponse.com/it/assistenza/tracciamento-dei-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPredotvratitFalshivyePodpiskiBotov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html",
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKoefficientKlikovCtr = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/koefficient-klikov-ctr.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-klikniec.html",
"en": "https://www.getresponse.com/help/click-through-rate.html",
"de": "https://www.getresponse.com/de/hilfe/click-through-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-clics.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pt": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodtverzhdennaiaPodpiska = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdennaia-podpiska.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-z-potwierdzeniem.html",
"en": "https://www.getresponse.com/help/confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/consentimiento-confirmado.html",
"it": "https://www.getresponse.com/it/assistenza/doppio-opt-in.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPismoPodtverzhdeniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pismo-podtverzhdeniya.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-potwierdzajaca-subskrypcje.html",
"en": "https://www.getresponse.com/help/confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/confirmation-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-confirmacion.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-conferma.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKlient = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/klient.html",
"pl": "https://www.getresponse.pl/pomoc/klient.html",
"en": "https://www.getresponse.com/help/client.html",
"de": "https://www.getresponse.com/de/hilfe/client.html",
"es": "https://www.getresponse.com/es/ayuda/cliente.html",
"it": "https://www.getresponse.com/it/assistenza/cliente.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente.html",
"fr": "https://www.getresponse.com/fr/aide/client.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZakonCanSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/zakon-can-spam.html",
"pl": "https://www.getresponse.pl/pomoc/ustawa-can-spam.html",
"en": "https://www.getresponse.com/help/can-spam-act.html",
"de": "https://www.getresponse.com/de/hilfe/can-spam-act.html",
"es": "https://www.getresponse.com/es/ayuda/ley-can-spam.html",
"it": "https://www.getresponse.com/it/assistenza/legge-can-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"fr": "https://www.getresponse.com/fr/aide/loi-can-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingovajaKampania = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaja-kampania.html",
"pl": "https://www.getresponse.pl/pomoc/kampania.html",
"en": "https://www.getresponse.com/help/marketing-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/campaign-definition.html",
"es": "https://www.getresponse.com/es/ayuda/campaign-definition.html",
"it": "https://www.getresponse.com/it/assistenza/campagna.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzbezhatOtpravkiSoobshhenijSNizkimKachestvomSoderzhimogo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatReklamuSPomoshhjuIntegraciiSGoogleAds = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitVSoobsheniePredmetyIzBroshennojKorziny = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-v-soobshenie-predmety-iz-broshennoj-korziny.html",
"en": "https://www.getresponse.com/help/how-can-i-include-abandoned-cart-items-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incluir-articulos-de-carrito-abandonado-en-un-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-includere-gli-articoli-del-carrello-abbandonato-in-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zawrzec-produkty-z-porzuconego-koszyka-w-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-inclure-les-articles-du-panier-abandonne-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMojeListySaSingleOptInCzySubskrypcjaZPotwierdzeniemJestWymaganaAbySpelniacWymogiRodo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPowiadomienieOSubskrypcjiZostaloDostarczoneAleKontaktNieJestDodanyDoListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/powiadomienie-o-subskrypcji-zostalo-dostarczone-ale-kontakt-nie-jest-dodany-do-listy.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-eine-abobenachrichtigung-erhalten-kann-den-kontakt-aber-nicht-in-meiner-liste-finden.html",
"en": "https://www.getresponse.com/help/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/mne-prishlo-uvedomlenije-o-tom-chto-kontakt-byl-dobavlen-v-spisok-no-ja-jego-ne-vizhu.html",
"br": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"it": "https://www.getresponse.com/it/assistenza/ho-ricevuto-un-e-mail-che-informa-che-un-contatto-e-stato-aggiunto-a-un-elenco-ma-non-riesco-a-visualizzarli.html",
"es": "https://www.getresponse.com/es/ayuda/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-recu-un-e-mail-indiquant-qu-un-contact-a-ete-ajoute-a-une-liste-mais-je-ne-peux-pas-le-voir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyOsobyBedaceNaKilkuListachJednoczesnieOtrzymajaMojaWiadomoscWielokrotnie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoMojaStarszaWersjaLandingPageNieWyswietlaSieOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMojaStarszaWersjaLandingPageMaStroneZPodziekowaniem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZapisacSzablonStarszejWersjiLandingPageAbyMocGoPonownieWykorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAvoidSendingLowQualityContentMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-avoid-sending-low-quality-content-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izbezhat-otpravki-soobshhenij-s-nizkim-kachestvom-soderzhimogo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-evitar-o-envio-de-mensagens-de-conteudo-de-baixa-qualidade.html",
"pl": "https://www.getresponse.pl/pomoc/jak-poprawic-jakosc-wysylanych-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-vermeide-ich-das-senden-von-nachrichten-mit-minderwertigem-inhalt.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-el-envio-de-mensajes-con-contenidos-de-baja-calidad.html",
"it": "https://www.getresponse.com/it/assistenza/come-evitare-di-inviare-messaggi-di-contenuto-di-bassa-qualita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-eviter-d-envoyer-des-messages-de-contenu-de-faible-qualite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsClickToOpenRateCtor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-click-to-open-rate-ctor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-procent-perehodov-ot-chisla-otkrytyh-pisem-ctor.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-taxa-de-cliques-por-abertura-ctor.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-wspolczynnik-klikniec-do-otwarc-ctor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-click-to-open-rate-ctor.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-tasa-de-clics-por-apertura-ctor.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-percentuale-di-clic-per-aprire-ctor.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-taux-de-clics-par-rapport-au-nombre-d-ouvertures-ctor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToPreventFakeBotSignups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-prevent-fake-bot-signups.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-predotvratit-falshivye-podpiski-botov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-prevenir-as-assinaturas-falsas-de-bots.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zapobiegac-falszywym-subskrypcjom.html",
"es": "https://www.getresponse.com/es/ayuda/como-evitar-las-inscripciones-falsas-de-los-bots.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verhindert-man-gefaelschte-bot-anmeldungen.html",
"it": "https://www.getresponse.com/it/assistenza/come-prevenire-le-registrazioni-false-dei-bot.html",
"fr": "https://www.getresponse.com/fr/aide/comment-empecher-l-inscription-de-faux-robots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKodirovkaSimvolov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kodirovka-simvolov.html",
"pl": "https://www.getresponse.pl/pomoc/kodowanie-znakow.html",
"en": "https://www.getresponse.com/help/character-encoding.html",
"de": "https://www.getresponse.com/de/hilfe/character-encoding.html",
"it": "https://www.getresponse.com/it/assistenza/codifica-dei-caratteri.html",
"br": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pt": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"es": "https://www.getresponse.com/es/ayuda/codificacion-de-caracteres.html",
"fr": "https://www.getresponse.com/fr/aide/codage-des-caracteres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAvtomatizaciya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizaciya.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja.html",
"en": "https://www.getresponse.com/help/automation.html",
"de": "https://www.getresponse.com/de/hilfe/automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion.html",
"it": "https://www.getresponse.com/it/assistenza/automation.html",
"br": "https://www.getresponse.com/pt/ajuda/automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/automacao.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodpischiki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/podpischiki.html",
"pl": "https://www.getresponse.pl/pomoc/subskrybent.html",
"en": "https://www.getresponse.com/help/subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores.html",
"it": "https://www.getresponse.com/it/assistenza/contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVlozhenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vlozhenie.html",
"de": "https://www.getresponse.com/de/hilfe/attachment.html",
"en": "https://www.getresponse.com/help/attachment.html",
"es": "https://www.getresponse.com/es/ayuda/anexo.html",
"it": "https://www.getresponse.com/it/assistenza/allegato.html",
"br": "https://www.getresponse.com/pt/ajuda/anexo.html",
"pt": "https://www.getresponse.com/pt/ajuda/anexo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPustoeElektronnoePismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pustoe-elektronnoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/pusty-email.html",
"en": "https://www.getresponse.com/help/blank-email.html",
"de": "https://www.getresponse.com/de/hilfe/blank-email.html",
"es": "https://www.getresponse.com/es/ayuda/email-en-blanco.html",
"it": "https://www.getresponse.com/it/assistenza/email-vuouta.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"fr": "https://www.getresponse.com/fr/aide/email-vide.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPovedencheskijTargeting = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/povedencheskij-targeting.html",
"pl": "https://www.getresponse.pl/pomoc/targetowanie-behawioralne.html",
"en": "https://www.getresponse.com/help/behavioral-targeting.html",
"de": "https://www.getresponse.com/de/hilfe/behavioral-targeting.html",
"es": "https://www.getresponse.com/es/ayuda/orientacion-del-comportamiento.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-comportamentale.html",
"br": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pt": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"fr": "https://www.getresponse.com/fr/aide/ciblage-comportemental.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNizheLiniiZgiba = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/nizhe-linii-zgiba.html",
"pl": "https://www.getresponse.pl/pomoc/below-the-fold-ponizej-linii.html",
"en": "https://www.getresponse.com/help/below-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/below-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/bajo-el-pliegue.html",
"it": "https://www.getresponse.com/it/assistenza/below-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"fr": "https://www.getresponse.com/fr/aide/below-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVidimayaChastBezProkrutki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vidimaya-chast-bez-prokrutki.html",
"pl": "https://www.getresponse.pl/pomoc/above-the-fold-ponad-linia.html",
"en": "https://www.getresponse.com/help/above-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/above-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/sobre-el-pliegue.html",
"it": "https://www.getresponse.com/it/assistenza/above-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"fr": "https://www.getresponse.com/fr/aide/above-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatSoobshheniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/format-soobshheniya.html",
"pl": "https://www.getresponse.pl/pomoc/format-wiadomosci.html",
"en": "https://www.getresponse.com/help/message-format.html",
"de": "https://www.getresponse.com/de/hilfe/message-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-del-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/formato-del-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/format-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAvtootvetchik = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/avtootvetchik.html",
"pl": "https://www.getresponse.pl/pomoc/autoresponder.html",
"en": "https://www.getresponse.com/help/autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/autorepondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokirovka = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie.html",
"en": "https://www.getresponse.com/help/blocking.html",
"de": "https://www.getresponse.com/de/hilfe/blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo.html",
"it": "https://www.getresponse.com/it/assistenza/blocco.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"fr": "https://www.getresponse.com/fr/aide/blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOshibkaDostavki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/oshibka-dostavki.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot.html",
"en": "https://www.getresponse.com/help/bounced.html",
"de": "https://www.getresponse.com/de/hilfe/bounced.html",
"es": "https://www.getresponse.com/es/ayuda/rebotados.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"fr": "https://www.getresponse.com/fr/aide/rebondi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPostoyannayaOshibkaDostavki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/postoyannaya-oshibka-dostavki.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-twardy.html",
"en": "https://www.getresponse.com/help/hard-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/hard-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-permanente.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-duro.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"fr": "https://www.getresponse.com/fr/aide/hard-bounce-rebondissement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVremennayaOshibkaDostavki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vremennaya-oshibka-dostavki.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-miekki.html",
"en": "https://www.getresponse.com/help/soft-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/soft-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-transitorio.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-morbido.html",
"br": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pt": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"fr": "https://www.getresponse.com/fr/aide/rebond-doux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEtiquetarContactosAleatoriamente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html",
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobDuplikowacPoszczegolneElementyNaStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEvents = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPartnerskayaProgramma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/partnerskaya-programma.html",
"pl": "https://www.getresponse.pl/pomoc/program-afiliacyjny.html",
"en": "https://www.getresponse.com/help/affiliate-program.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-program.html",
"es": "https://www.getresponse.com/es/ayuda/programa-de-afiliados.html",
"it": "https://www.getresponse.com/it/assistenza/programma-di-affiliazione.html",
"br": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/programme-d-affiliation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAkkaunt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/akkaunt.html",
"en": "https://www.getresponse.com/help/account.html",
"it": "https://www.getresponse.com/it/assistenza/account.html",
"es": "https://www.getresponse.com/es/ayuda/cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/konto-2.html",
"de": "https://www.getresponse.com/de/hilfe/das-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"fr": "https://www.getresponse.com/fr/aide/compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostApiKljuch = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/api-kljuch.html",
"pl": "https://www.getresponse.pl/pomoc/klucz-api.html",
"en": "https://www.getresponse.com/help/api-key.html",
"de": "https://www.getresponse.com/de/hilfe/api-key.html",
"es": "https://www.getresponse.com/es/ayuda/clave-api.html",
"it": "https://www.getresponse.com/it/assistenza/chiave-api.html",
"br": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"fr": "https://www.getresponse.com/fr/aide/cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVyravnivanie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vyravnivanie.html",
"pl": "https://www.getresponse.pl/pomoc/zgodnosc.html",
"en": "https://www.getresponse.com/help/alignment.html",
"de": "https://www.getresponse.com/de/hilfe/alignment.html",
"es": "https://www.getresponse.com/es/ayuda/alineacion.html",
"it": "https://www.getresponse.com/it/assistenza/alignment.html",
"br": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"fr": "https://www.getresponse.com/fr/aide/alignement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineAnzeigeMithilfeDerGoogleAdsIntegration = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieZweiFaktorAuthentifizierungInGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatUslovijeBallyNachisleny = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreSmsDeliveryRegulationsInParticularCountries = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-sms-delivery-regulations-in-particular-countries.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-regolazioni-di-consegna-sms-in-determinati-paesi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-pravila-dostavki-sms-v-konkretnyh-stranah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-regulamentos-de-entrega-de-sms-em-determinados-paises.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-normas-de-entrega-de-sms-en-determinados-paises.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-regulacje-dostarczania-smsow-w-danym-kraju.html",
"de": "https://www.getresponse.com/de/hilfe/welche-vorschriften-zur-sms-zustellung-gibt-es-in-bestimmten-laendern.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-reglementations-en-matiere-d-envoi-de-sms-dans-certains-pays.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmAnuncioUsandoAIntegracaoDoGoogleAds = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUtworzycResponsywneNewslettery = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzemuStatystykiOtwarcWskazuja0 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html",
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatPismoAvtomatizacii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguSozdatABTest = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotatSShablonami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatChatyVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatDvuhfaktornujuAutentifikacijuVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatSobytie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseSendPushNotificationAction = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-send-push-notification-action.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvie-otpravit-push-uvedomlenie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-a-acao-enviar-notificacao-push.html",
"de": "https://www.getresponse.com/de/hilfe/so-verwenden-sie-die-aktion-push-benachrichtigung-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-wyslij-web-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-azione-invia-notifica-push.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-accion-enviar-notificacion-push.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-envoyer-une-notification-push.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetUslovijeIzmenenoZnacheniePola = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetUslovijeOsobyjPovod = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerchePossoVedereSoloAlcuniMessaggiNelleCondizioniMessaggioApertoELinkCliccato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaCondizioneStatoDelConsensoAggiornato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaCondizioneLinkCliccato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaCondizioneCampoPersonalizzatoModificato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLeImpostazioniTemporaliNelleProprietaDellaCondizione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLaCondizioneLandingPageVisitataInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLAzioneTagInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAMonitorareLeApertureDeiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAImpostareLeProprietaPerLaCondizioneAcquista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoSpostareIContattiDaUnaListaAllAltraNelWorkflowDiMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACopiareIContattiInUnAltraListaInMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessaggioDiAuguri = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"en": "https://www.getresponse.com/help/birthday-message.html",
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFollowUpDopoIlWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiTemplateDeiWorkflowSonoDisponibiliConIlMioPacchetto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTrasformareIContattiInClienti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html",
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostScontoEarlyBird = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostScontiEarlyBirdPerBlackFriday = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sconti-early-bird-per-black-friday.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-early-bird-sale.html",
"en": "https://www.getresponse.com/help/black-friday-early-bird-sale.html",
"pl": "https://www.getresponse.pl/pomoc/wczesna-sprzedaz-z-okazji-czarnego-piatku.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-pervyim-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-black-friday-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRingraziamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ringraziamento.html",
"de": "https://www.getresponse.com/de/hilfe/thanksgiving.html",
"en": "https://www.getresponse.com/help/thanksgiving.html",
"pl": "https://www.getresponse.pl/pomoc/swieto-dziekczynienia.html",
"ru": "https://www.getresponse.com/ru/help-ru/den-blagodareniya.html",
"es": "https://www.getresponse.com/es/ayuda/accion-de-gracias.html",
"br": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"pt": "https://www.getresponse.com/pt/ajuda/thanksgiving-dia-de-acao-de-gracas.html",
"fr": "https://www.getresponse.com/fr/aide/thanksgiving.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFollowUpPostVendita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFollowUpPostVenditaMirati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConfermaDiAcquistoBasataSuConsenso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html",
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPianoTaggingDiBase = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerCheCosaSonoUtilizzatiITemplateCorsiOnline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSempliceMessaggioDiBenvenuto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html",
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDaiIlBenvenutoAiNuoviContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPunteggioContattiAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessaggioDiBenvenutoAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCrossSellingAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cross-selling-affiliati.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-cross-selling.html",
"en": "https://www.getresponse.com/help/affiliate-cross-selling.html",
"pl": "https://www.getresponse.pl/pomoc/cross-selling-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/venta-cruzada-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-croisees-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kross-prodazha-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCorsoOnlineAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantoCostaMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosELaMarketingAutomationEComePossoUtilizzarla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoUsareITagPerVedereChiHaIniziatoOCompletatoIMieiWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheIlPercorsoNegativoNonVieneAttivatoPerLaMiaCondizioneIniziale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNonRiescoAPubblicareIlWorkflowCheDevoFare = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html",
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaCondizioneRicorrenzaSpeciale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaCondizioneMessaggioInviato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLaCondizioneSeIlPunteggioInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAImpostareLeProprietaPerLaCondizioneUrlVisitato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioASpostareOCopiareIContattiDaUnWorkflowAllAltro = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeSiUsanoICampiPersonalizzatiInMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoEliminareIContattiAllInternoDiUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLAzioneAttendi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLAzioneAssegnaPunteggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromozioneWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru.html",
"en": "https://www.getresponse.com/help/webinar-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/webinar-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenije-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerCheCosaSonoUtilizzatiITemplateWebinarEdEventi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProdottiConsigliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/prodotti-consigliati.html",
"de": "https://www.getresponse.com/de/hilfe/top-produkte-empfehlen.html",
"en": "https://www.getresponse.com/help/recommend-top-products.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-popularnych-produktow.html",
"ru": "https://www.getresponse.com/ru/help-ru/rekomendatsiya-populyarnyih-produktov.html",
"es": "https://www.getresponse.com/es/ayuda/recomendar-los-mejores-productos.html",
"br": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"pt": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"fr": "https://www.getresponse.com/fr/aide/produits-recommandes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuJaVizhuTolkoNekotoryjeSoobshenia = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerCheCosaSonoUtilizzatiITemplatePromozioniDiVendita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerCheCosaSonoUtilizzatiITemplatePostVendita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCollegareITemplateTraDiLoro = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPianoPunteggiDiBase = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRiconquistareLeVenditeMancate = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html",
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"en": "https://www.getresponse.com/help/win-back.html",
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDeiClienti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuliziaDelleListeAttraversoTagEPunteggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html",
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerCheCosaSonoUtilizzatiITemplateInterazioneEFidelizzazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorqueOsMeusAutorespondersNaoEstaoASerEnviados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeUsareITemplatePerCreareIWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSerieCarrelloAbbandonato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html",
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecebiUmEMailDizendoQueUmContatoFoiAdicionadoAUmaListaMasNaoConsigoVeLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-eine-abobenachrichtigung-erhalten-kann-den-kontakt-aber-nicht-in-meiner-liste-finden.html",
"en": "https://www.getresponse.com/help/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/mne-prishlo-uvedomlenije-o-tom-chto-kontakt-byl-dobavlen-v-spisok-no-ja-jego-ne-vizhu.html",
"pl": "https://www.getresponse.pl/pomoc/powiadomienie-o-subskrypcji-zostalo-dostarczone-ale-kontakt-nie-jest-dodany-do-listy.html",
"it": "https://www.getresponse.com/it/assistenza/ho-ricevuto-un-e-mail-che-informa-che-un-contatto-e-stato-aggiunto-a-un-elenco-ma-non-riesco-a-visualizzarli.html",
"es": "https://www.getresponse.com/es/ayuda/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-recu-un-e-mail-indiquant-qu-un-contact-a-ete-ajoute-a-une-liste-mais-je-ne-peux-pas-le-voir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromemoriaUrgenti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromemoriaCarrelloAbbandonato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoPersonalizarMinhaMensagemDeConfirmacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoAtribuirTagsOuClassificacaoAosContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtribuirTagsEClassificacaoDuranteAImportacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsaMeuRascunhosOuNewsletterComoAutoresponder = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-entwurf-oder-newsletter-als-autoresponder-verwenden.html",
"en": "https://www.getresponse.com/help/can-i-use-my-draft-or-newsletter-as-an-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-chernoviki-ili-rassylki-v-kachestve-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-wersje-robocza-wiadomosci-lub-newsletter-jako-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-la-mia-bozza-o-newsletter-come-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-borrador-o-newsletter-como-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerCheCosaSonoUtilizzatiITemplateCarrelloAbbandonato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerCheCosaSonoUtilizzatiITemplateBenvenuto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDaiIlBenvenutoAiNuoviClienti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaggingContattiAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html",
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaIlFiltroSegmentoDinamico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaIlFiltroSeparatore = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaIlFiltroQuantita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostACosaSiRiferisconoINumeriInCimaInFondoEInAltoADestraDiCiascunElementoNelWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnAutomationWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoveSiTrovanoLeStatisticheDImportazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoVedereTuttiIMieiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereIVisitatoriDelSitoWordpressLegacyAUnaListaDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMonitorareERaccogliereDatiDiECommerceDaPrestashop = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAbilitareLeIscrizioniPerIVisitatoriDelMioNegozioPrestashop = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguOtslezhivatOtkrytijaSoobshenijVProtsesseAvtomatizatsii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaIlFiltroIntervallo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRegistrareIlMioWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanILetMyParticipantsCommunicateWithMeDuringTheWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pozwolic-uczestnikom-webinaru-komunikowac-sie-ze-mna.html",
"en": "https://www.getresponse.com/help/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-teilnehmer-waehrend-des-webinars-mit-mir-kommunizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-permitir-a-mis-participantes-comunicarse-conmigo-durante-el-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mozhno-vzaimodejstvovat-so-zritelami-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-permettre-a-mes-participants-de-communiquer-avec-moi-pendant-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareLeStatisticheDelMioWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineListeVonActiveCampaignAweberConstantContactOderMailchimpMigrieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-liste-von-active-campaign-aweber-constant-contact-oder-mailchimp-migrieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeniesc-moja-liste-z-active-campaign-aweber-constant-contact-lub-mailchimp.html",
"en": "https://www.getresponse.com/help/how-can-i-migrate-my-list-from-active-campaign-aweber-constant-contact-or-mailchimp.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-migrar-mi-lista-de-active-campaign-aweber-constant-contact-o-mailchimp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-perenesti-svoj-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trasferire-la-mia-lista-da-active-campaign-aweber-constant-contact-o-mailchimp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareITemplateDelleLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioASalvareUnTemplateDiUnaLandingPagePerPoterloRiutilizzareInFuturo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnaLandingPageEACosaServe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereUnCodicePersonalizzatoAllaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyIsnTMyLandingPageShowingUpOnline = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLaMiaLandingPageECorredataDaUnaPaginaDiRingraziamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAccrescereLaMiaAttivitaConLeFunzionalitaDiECommerceDiGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheTipoDiDatiEcommerceVengonoInviatiAGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLaCondizioneEventoInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMonitorareLeVisiteSulMioSitoWordpressLegacy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareLeImpostazioniDellaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoSegmentareIContattiInBaseAiDatiDiECommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoIncludereGliArticoliDelCarrelloAbbandonatoInUnMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-includere-gli-articoli-del-carrello-abbandonato-in-un-messaggio.html",
"en": "https://www.getresponse.com/help/how-can-i-include-abandoned-cart-items-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incluir-articulos-de-carrito-abandonado-en-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-v-soobshenie-predmety-iz-broshennoj-korziny.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zawrzec-produkty-z-porzuconego-koszyka-w-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-inclure-les-articles-du-panier-abandonne-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIniziareUnWorkflowConVarieCondizioni = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoApportareModificheAUnWorkflowCheEStatoGiaPubblicato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoModificareUnMessaggioCheStoUtilizzandoInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoLavorareConGliElementiInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionanoIConnettoriSiNo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnNuovoNomeEIndirizzoDelMittenteAiMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoveSiTrovanoIContattiInattivi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereModuliPopUpSullaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAMonitorareLeVisiteELeIscrizioniPerLaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioADuplicareGliElementiNellaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLaDuplicazioneDeiWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioADefinireQuandoIlMioWorkflowIniziaEFinisce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsareYandexMetricaPerMonitorareLeMieLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMonitorareLaMiaLandingPageConVisualWebsiteOptimizerVwo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrareLaMiaLandingPageConKissmetrics = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrareLaMiaLandingPageConBingAds = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAumentareLaVisibilitaDellaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIntegrareLeMieLandingPageConMixpanel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAEliminareIlCodiceDiMonitoraggioDallaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareEInviareNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeVendereUnEbookTramiteConversionFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnaPaginaDiVendita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInCheModoConversionFunnelSiDifferenziaDaMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAVerificareLaMiaIntegrazioneConPaypal = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheDifferenzaCETraLeVariePagineDeiFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnWebinarFunnelGratuito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEConversionFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnWebinarFunnelAPagamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaLAutogeneratoreDiSalesFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCreareUnLeadMagnetFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComePossoCreareUnSalesFunnel = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMonitorareIClientiDelMioNegozioMagento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-i-clienti-del-mio-negozio-magento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-klientow-wewnatrz-mojego-sklepu-magento.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-clientes-em-minha-loja-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-clientes-em-minha-loja-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoTrovareColoroCheNonHannoApertoIMieiMessaggi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/account.html",
"en": "https://www.getresponse.com/help/account.html",
"ru": "https://www.getresponse.com/ru/help-ru/akkaunt.html",
"es": "https://www.getresponse.com/es/ayuda/cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/konto-2.html",
"de": "https://www.getresponse.com/de/hilfe/das-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/conta-2.html",
"fr": "https://www.getresponse.com/fr/aide/compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTrappoleDiSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/trappole-di-spam.html",
"pl": "https://www.getresponse.pl/pomoc/spam-trap.html",
"en": "https://www.getresponse.com/help/spam-trap.html",
"es": "https://www.getresponse.com/es/ayuda/trampa-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/spam-lovyshka.html",
"de": "https://www.getresponse.com/de/hilfe/spam-trap.html",
"br": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/armadilha-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/piege-a-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestoPredefinito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"en": "https://www.getresponse.com/help/predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html",
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReport = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/report.html",
"pl": "https://www.getresponse.pl/pomoc/raport.html",
"en": "https://www.getresponse.com/help/report.html",
"de": "https://www.getresponse.com/de/hilfe/bericht.html",
"es": "https://www.getresponse.com/es/ayuda/informe.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"fr": "https://www.getresponse.com/fr/aide/rapport.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrecaricamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/precaricamento.html",
"de": "https://www.getresponse.com/de/hilfe/pre-load.html",
"es": "https://www.getresponse.com/es/ayuda/pre-carga.html",
"ru": "https://www.getresponse.com/ru/help-ru/kopirovanie-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/pre-carga.html",
"pt": "https://www.getresponse.com/pt/ajuda/pre-carga.html",
"fr": "https://www.getresponse.com/fr/aide/pre-load.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFromField = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/from-field.html",
"pl": "https://www.getresponse.pl/pomoc/pole-nadawcy.html",
"en": "https://www.getresponse.com/help/from-field.html",
"de": "https://www.getresponse.com/de/hilfe/from-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-otpravitelya.html",
"br": "https://www.getresponse.com/pt/ajuda/remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/remetente.html",
"fr": "https://www.getresponse.com/fr/aide/le-champ-de.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaGrigia = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"en": "https://www.getresponse.com/help/greylist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html",
"de": "https://www.getresponse.com/de/hilfe/grauliste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessaggioHtml = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/messaggio-html.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-html.html",
"de": "https://www.getresponse.com/de/hilfe/html-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/html-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/html-message.html",
"pt": "https://www.getresponse.com/pt/ajuda/html-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRimbalzoDuro = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-duro.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-twardy.html",
"en": "https://www.getresponse.com/help/hard-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/hard-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-permanente.html",
"ru": "https://www.getresponse.com/ru/help-ru/postoyannaya-oshibka-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"fr": "https://www.getresponse.com/fr/aide/hard-bounce-rebondissement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloccoDelleImmagini = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/blocco-delle-immagini.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-obrazkow.html",
"en": "https://www.getresponse.com/help/image-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/image-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-imagenes.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-izobrazhenij.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-de-l-image.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSottoscrittoriInattivi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sottoscrittori-inattivi.html",
"pl": "https://www.getresponse.pl/pomoc/nieaktywni-subskrybenci.html",
"en": "https://www.getresponse.com/help/inactive-subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/inactive-subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/neaktivnye-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampagnaEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/campagna-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/email-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-kampaniya.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-d-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPubblicitaViaEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pubblicita-via-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-mailowa.html",
"en": "https://www.getresponse.com/help/email-advertising.html",
"de": "https://www.getresponse.com/de/hilfe/email-advertising.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-reklama.html",
"br": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-par-courriel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInstantMessage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/instant-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-natychmiastowa-blyskawiczna.html",
"en": "https://www.getresponse.com/help/instant-message.html",
"de": "https://www.getresponse.com/de/hilfe/instant-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-instantaneo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mgnovennoe-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"fr": "https://www.getresponse.com/fr/aide/message-instantane.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIntestazioniEMailEMailHeaders = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/intestazioni-e-mail-e-mail-headers.html",
"pl": "https://www.getresponse.pl/pomoc/naglowki-emaila.html",
"en": "https://www.getresponse.com/help/email-headers.html",
"de": "https://www.getresponse.com/de/hilfe/email-headers.html",
"es": "https://www.getresponse.com/es/ayuda/encabezados-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/zagolovki-elektronnoi-pochti.html",
"br": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/en-tetes-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClienteDiPostaElettronica = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cliente-di-posta-elettronica.html",
"pl": "https://www.getresponse.pl/pomoc/klient-poczty-elektronicznej.html",
"en": "https://www.getresponse.com/help/email-client.html",
"de": "https://www.getresponse.com/de/hilfe/email-client.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-klient.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"es": "https://www.getresponse.com/es/ayuda/cliente-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/client-de-messagerie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPieDiPagina = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pie-di-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/stopka-maila.html",
"en": "https://www.getresponse.com/help/footer.html",
"de": "https://www.getresponse.com/de/hilfe/footer.html",
"es": "https://www.getresponse.com/es/ayuda/pie-de-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/futer.html",
"br": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/rodape.html",
"fr": "https://www.getresponse.com/fr/aide/pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInvioDiEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/invio-di-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/emailing.html",
"en": "https://www.getresponse.com/help/emailing.html",
"de": "https://www.getresponse.com/de/hilfe/emailing.html",
"es": "https://www.getresponse.com/es/ayuda/enviando-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpravka-po-elektronnoj-pochte.html",
"br": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/l-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromozioneViaEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/promozione-via-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-emailowa.html",
"en": "https://www.getresponse.com/help/email-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/email-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-por-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-par-courrier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNomeDiDominio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/nome-di-dominio.html",
"pl": "https://www.getresponse.pl/pomoc/nazwa-domeny.html",
"de": "https://www.getresponse.com/de/hilfe/domain-name.html",
"es": "https://www.getresponse.com/es/ayuda/nombre-de-dominio.html",
"ru": "https://www.getresponse.com/ru/help-ru/domennoe-imya.html",
"br": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContenutiDinamici = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/contenuti-dinamici.html",
"pl": "https://www.getresponse.pl/pomoc/dynamic-content.html",
"en": "https://www.getresponse.com/help/dynamic-content.html",
"de": "https://www.getresponse.com/de/hilfe/dynamic-content.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/dinamicheskij-kontent.html",
"br": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConsegnato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/consegnato.html",
"pl": "https://www.getresponse.pl/pomoc/dostarczona.html",
"en": "https://www.getresponse.com/help/delivered.html",
"de": "https://www.getresponse.com/de/hilfe/delivered.html",
"es": "https://www.getresponse.com/es/ayuda/entregado.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-dostavleno.html",
"br": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pt": "https://www.getresponse.com/pt/ajuda/entregue.html",
"fr": "https://www.getresponse.com/fr/aide/livre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostServerDedicato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/server-dedicato.html",
"pl": "https://www.getresponse.pl/pomoc/serwer-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-server.html",
"de": "https://www.getresponse.com/de/hilfe/dedicated-server.html",
"es": "https://www.getresponse.com/es/ayuda/servidor-dedicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/vydelennyj-server.html",
"br": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"fr": "https://www.getresponse.com/fr/aide/serveur-dedie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTassoDiConsegna = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tasso-di-consegna.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-dostarczalnosci.html",
"en": "https://www.getresponse.com/help/deliverability-rate.html",
"de": "https://www.getresponse.com/de/hilfe/deliverability-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/uroven-dostavlyaemosti.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatoCsv = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/formato-csv.html",
"pl": "https://www.getresponse.pl/pomoc/format-csv.html",
"en": "https://www.getresponse.com/help/csv-format.html",
"de": "https://www.getresponse.com/de/hilfe/csv-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-csv.html",
"ru": "https://www.getresponse.com/ru/help-ru/csv-format.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-csv.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-csv.html",
"fr": "https://www.getresponse.com/fr/aide/format-csv.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampoPersonalizzato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/campo-personalizzato.html",
"de": "https://www.getresponse.com/de/hilfe/custom-field.html",
"en": "https://www.getresponse.com/help/custom-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemoe-pole.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/pole-dodatkowe-2.html",
"fr": "https://www.getresponse.com/fr/aide/champ-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBroadcast = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/broadcast.html",
"pl": "https://www.getresponse.pl/pomoc/wysylka.html",
"en": "https://www.getresponse.com/help/broadcast.html",
"de": "https://www.getresponse.com/de/hilfe/broadcast.html",
"es": "https://www.getresponse.com/es/ayuda/transmision.html",
"ru": "https://www.getresponse.com/ru/help-ru/translyaciya.html",
"br": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"fr": "https://www.getresponse.com/fr/aide/transmission.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailDiMassa = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/email-di-massa.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-masowa.html",
"en": "https://www.getresponse.com/help/bulk-email.html",
"de": "https://www.getresponse.com/de/hilfe/bulk-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-masivo.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovaya-rassylka.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"fr": "https://www.getresponse.com/fr/aide/envoi-massif-de-courriels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBounceRate = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/bounce-rate.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zwrotow.html",
"en": "https://www.getresponse.com/help/bounce-rate.html",
"de": "https://www.getresponse.com/de/hilfe/bounce-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-rebote.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-oshibok-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-rebond.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCliente = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cliente.html",
"pl": "https://www.getresponse.pl/pomoc/klient.html",
"en": "https://www.getresponse.com/help/client.html",
"de": "https://www.getresponse.com/de/hilfe/client.html",
"es": "https://www.getresponse.com/es/ayuda/cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/klient.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente.html",
"fr": "https://www.getresponse.com/fr/aide/client.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCodificaDeiCaratteri = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/codifica-dei-caratteri.html",
"pl": "https://www.getresponse.pl/pomoc/kodowanie-znakow.html",
"en": "https://www.getresponse.com/help/character-encoding.html",
"de": "https://www.getresponse.com/de/hilfe/character-encoding.html",
"ru": "https://www.getresponse.com/ru/help-ru/kodirovka-simvolov.html",
"br": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pt": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"es": "https://www.getresponse.com/es/ayuda/codificacion-de-caracteres.html",
"fr": "https://www.getresponse.com/fr/aide/codage-des-caracteres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeCondividereUnEBookConITuoiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessaggioDiBenvenutoBasatoSuConsenso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-basato-su-consenso.html",
"en": "https://www.getresponse.com/help/consent-based-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-powitalna-zalezna-od-udzielonych-zgod.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-willkommensnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-na-osnove-soglasija.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-base-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPremiazioneDeiClientiFedeli = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html",
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromozioneCorsoAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIntegrareLeMieLandingPageConGoogleTagManager = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTassoDiClic = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tasso-di-clic.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-klikniec.html",
"en": "https://www.getresponse.com/help/click-through-rate.html",
"de": "https://www.getresponse.com/de/hilfe/click-through-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/koefficient-klikov-ctr.html",
"br": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pt": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlocco = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/blocco.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie.html",
"en": "https://www.getresponse.com/help/blocking.html",
"de": "https://www.getresponse.com/de/hilfe/blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"fr": "https://www.getresponse.com/fr/aide/blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFileAllegato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/file-allegato.html",
"pl": "https://www.getresponse.pl/pomoc/zalacznik.html",
"en": "https://www.getresponse.com/help/file-attachment.html",
"de": "https://www.getresponse.com/de/hilfe/file-attachment.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-adjunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/fajl-vlozhenie.html",
"br": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pt": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-joint.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZipFile = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/zip-file.html",
"pl": "https://www.getresponse.pl/pomoc/zip.html",
"en": "https://www.getresponse.com/help/zip-file.html",
"de": "https://www.getresponse.com/de/hilfe/zip-file.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-zip.html",
"ru": "https://www.getresponse.com/ru/help-ru/zip-fajl.html",
"br": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"pt": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-zip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhitelist = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/whitelist.html",
"pl": "https://www.getresponse.pl/pomoc/whitelist-biala-lista-subskrybentow.html",
"en": "https://www.getresponse.com/help/whitelist.html",
"de": "https://www.getresponse.com/de/hilfe/whitelist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-blanca.html",
"ru": "https://www.getresponse.com/ru/help-ru/belyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"fr": "https://www.getresponse.com/fr/aide/liste-blanche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReportDiIscrizione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/custom-reports.html",
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/contatti.html",
"pl": "https://www.getresponse.pl/pomoc/subskrybent.html",
"en": "https://www.getresponse.com/help/subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpischiki.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOggetto = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/oggetto.html",
"pl": "https://www.getresponse.pl/pomoc/temat-wiadomosci.html",
"en": "https://www.getresponse.com/help/subject-line.html",
"de": "https://www.getresponse.com/de/hilfe/subject-line.html",
"es": "https://www.getresponse.com/es/ayuda/linea-de-asunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/tema-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pt": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"fr": "https://www.getresponse.com/fr/aide/ligne-d-objet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPaginaDiRingraziamento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/pagina-di-ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/strona-z-podziekowaniem.html",
"en": "https://www.getresponse.com/help/thank-you-page.html",
"de": "https://www.getresponse.com/de/hilfe/thank-you-page.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-de-agradecimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/stranica-blagodarnosti.html",
"br": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSondaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sondaggio.html",
"pl": "https://www.getresponse.pl/pomoc/ankieta.html",
"en": "https://www.getresponse.com/help/survey.html",
"de": "https://www.getresponse.com/de/hilfe/survey.html",
"es": "https://www.getresponse.com/es/ayuda/encuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/opros.html",
"br": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pt": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"fr": "https://www.getresponse.com/fr/aide/enquete.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContattiInattivi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/contatti-inattivi.html",
"pl": "https://www.getresponse.pl/pomoc/usuniete.html",
"en": "https://www.getresponse.com/help/removals.html",
"de": "https://www.getresponse.com/de/hilfe/removals.html",
"es": "https://www.getresponse.com/es/ayuda/bajas.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpisavshiesya-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"fr": "https://www.getresponse.com/fr/aide/suppressions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModuloWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/modulo-web.html",
"pl": "https://www.getresponse.pl/pomoc/formularz-zapisu.html",
"en": "https://www.getresponse.com/help/web-form.html",
"de": "https://www.getresponse.com/de/hilfe/web-form.html",
"es": "https://www.getresponse.com/es/ayuda/formulario-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/veb-forma.html",
"br": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"fr": "https://www.getresponse.com/fr/aide/formulaire-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIndirizzoDiRisposta = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/indirizzo-di-risposta.html",
"pl": "https://www.getresponse.pl/pomoc/pole-odpowiedz-do.html",
"en": "https://www.getresponse.com/help/reply-to-field.html",
"de": "https://www.getresponse.com/de/hilfe/reply-to-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-respuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-dlya-otveta.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"fr": "https://www.getresponse.com/fr/aide/champ-de-reponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRimbalzoMorbido = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-morbido.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-miekki.html",
"en": "https://www.getresponse.com/help/soft-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/soft-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-transitorio.html",
"ru": "https://www.getresponse.com/ru/help-ru/vremennaya-oshibka-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pt": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"fr": "https://www.getresponse.com/fr/aide/rebond-doux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReclamoSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/reclamo-spam.html",
"pl": "https://www.getresponse.pl/pomoc/zgloszenie-spamu.html",
"en": "https://www.getresponse.com/help/spam-complaint.html",
"de": "https://www.getresponse.com/de/hilfe/spam-complaint.html",
"es": "https://www.getresponse.com/es/ayuda/denuncia-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zhaloba-na-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/plainte-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingBasatoSulConsenso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/marketing-basato-sul-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-za-przyzwoleniem.html",
"en": "https://www.getresponse.com/help/permission-based-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-based-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-basado-en-permisos.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketing-na-osnove-razreshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-base-sur-les-permissions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestoSemplice = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/testo-semplice.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-w-formacie-tekstowym.html",
"en": "https://www.getresponse.com/help/plain-text.html",
"de": "https://www.getresponse.com/de/hilfe/plain-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-sin-formato.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalizzazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/personalizzazione.html",
"pl": "https://www.getresponse.pl/pomoc/personalizacja.html",
"en": "https://www.getresponse.com/help/personalization.html",
"de": "https://www.getresponse.com/de/hilfe/personalization.html",
"es": "https://www.getresponse.com/es/ayuda/personalizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizaciya.html",
"br": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"fr": "https://www.getresponse.com/fr/aide/personnalisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIndirizzoPostale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/indirizzo-postale.html",
"pl": "https://www.getresponse.pl/pomoc/adres-pocztowy.html",
"en": "https://www.getresponse.com/help/postal-address.html",
"de": "https://www.getresponse.com/de/hilfe/postal-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-postal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-adres.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"fr": "https://www.getresponse.com/fr/aide/adresse-postale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNumeroDiRiferimento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/numero-di-riferimento.html",
"pl": "https://www.getresponse.pl/pomoc/ref-numer-sledzacy.html",
"en": "https://www.getresponse.com/help/ref-tracking-number.html",
"de": "https://www.getresponse.com/de/hilfe/ref-tracking-number.html",
"es": "https://www.getresponse.com/es/ayuda/numero-de-seguimiento-de-la-referencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/nomer-dla-otslezivaniya.html",
"br": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"fr": "https://www.getresponse.com/fr/aide/ref-numero-de-suivi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCancellazioneMultiplaOptOut = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cancellazione-multipla-opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/multi-unsubscribe-opt-out.html",
"en": "https://www.getresponse.com/help/multi-unsubscribe-opt-out.html",
"es": "https://www.getresponse.com/es/ayuda/desinscripcion-multiple-opt-out.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovyj-otkaz-ot-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pl": "https://www.getresponse.pl/pomoc/multi-unsubscribe-wypis.html",
"fr": "https://www.getresponse.com/fr/aide/desabonnement-multiple-opt-out.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatoDelMessaggio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/formato-del-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/format-wiadomosci.html",
"en": "https://www.getresponse.com/help/message-format.html",
"de": "https://www.getresponse.com/de/hilfe/message-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-del-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/format-soobshheniya.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/format-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFusioneDiParole = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/fusione-di-parole.html",
"pl": "https://www.getresponse.pl/pomoc/merge-word.html",
"en": "https://www.getresponse.com/help/merge-word.html",
"de": "https://www.getresponse.com/de/hilfe/merge-word.html",
"es": "https://www.getresponse.com/es/ayuda/fusion-de-palabras.html",
"ru": "https://www.getresponse.com/ru/help-ru/slovo-obedenenie.html",
"br": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"pt": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"fr": "https://www.getresponse.com/fr/aide/fusionner-des-mots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostModelloDiNewsletter = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/modello-di-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-newslettera.html",
"en": "https://www.getresponse.com/help/newsletter-template.html",
"de": "https://www.getresponse.com/de/hilfe/newsletter-template.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/shablon-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNotificazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/notificazione.html",
"pl": "https://www.getresponse.pl/pomoc/notification-powiadomienia.html",
"en": "https://www.getresponse.com/help/notification.html",
"de": "https://www.getresponse.com/de/hilfe/notification.html",
"es": "https://www.getresponse.com/es/ayuda/notificacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/uvedomlenie-o-podpiske.html",
"br": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"fr": "https://www.getresponse.com/fr/aide/notification.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptInNonConfermato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/opt-in-non-confermato.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-bez-potwierdzenia.html",
"en": "https://www.getresponse.com/help/non-confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/non-confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-no-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-bez-podtverzhdeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-non-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTassoDiApertura = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tasso-di-apertura.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-otwarc.html",
"en": "https://www.getresponse.com/help/open-rate.html",
"de": "https://www.getresponse.com/de/hilfe/open-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-otkrytij.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStatisticheDiRendimento = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/statistiche-di-rendimento.html",
"pl": "https://www.getresponse.pl/pomoc/statystki-wysylek.html",
"en": "https://www.getresponse.com/help/performance-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/performance-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-desempeno.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-dejstvij.html",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"fr": "https://www.getresponse.com/fr/aide/statistiques-de-performance.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptOutAnnullamentoDellIscrizione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIndirizzoIp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/indirizzo-ip.html",
"pl": "https://www.getresponse.pl/pomoc/adres-ip.html",
"en": "https://www.getresponse.com/help/ip-address.html",
"de": "https://www.getresponse.com/de/hilfe/ip-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/ip-adres.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/addresse-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIntervallo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/intervallo.html",
"pl": "https://www.getresponse.pl/pomoc/interwal.html",
"en": "https://www.getresponse.com/help/interval.html",
"de": "https://www.getresponse.com/de/hilfe/interval.html",
"es": "https://www.getresponse.com/es/ayuda/intervalo.html",
"ru": "https://www.getresponse.com/ru/help-ru/interval.html",
"br": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"fr": "https://www.getresponse.com/fr/aide/intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/landing-page.html",
"en": "https://www.getresponse.com/help/landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/lending.html",
"br": "https://www.getresponse.com/pt/ajuda/landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloccoIp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/blocco-ip.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-adresu-ip.html",
"en": "https://www.getresponse.com/help/ip-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/ip-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGenerazioneDiLead = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/generazione-di-lead.html",
"pl": "https://www.getresponse.pl/pomoc/pozyskiwanie-leadow.html",
"en": "https://www.getresponse.com/help/leads-generation.html",
"de": "https://www.getresponse.com/de/hilfe/leads-generation.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/generaciya-lidov.html",
"br": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversioneDiLead = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/conversione-di-lead.html",
"pl": "https://www.getresponse.pl/pomoc/konwersja-leadow.html",
"en": "https://www.getresponse.com/help/lead-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/lead-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversiya-lida.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentazioneDellaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/segmentazione-della-lista.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-listy.html",
"en": "https://www.getresponse.com/help/list-segmentation.html",
"de": "https://www.getresponse.com/de/hilfe/list-segmentation.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGestioneDelleListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-liste.html",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-lista.html",
"en": "https://www.getresponse.com/help/list-management.html",
"de": "https://www.getresponse.com/de/hilfe/list-management.html",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-spiskami.html",
"br": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLink = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/link.html",
"pl": "https://www.getresponse.pl/pomoc/link.html",
"en": "https://www.getresponse.com/help/link.html",
"de": "https://www.getresponse.com/de/hilfe/link.html",
"es": "https://www.getresponse.com/es/ayuda/link.html",
"ru": "https://www.getresponse.com/ru/help-ru/ssylka.html",
"br": "https://www.getresponse.com/pt/ajuda/link.html",
"pt": "https://www.getresponse.com/pt/ajuda/link.html",
"fr": "https://www.getresponse.com/fr/aide/lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFrequenza = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/frequenza.html",
"pl": "https://www.getresponse.pl/pomoc/czestotliwosc-wyslanych-emaili.html",
"en": "https://www.getresponse.com/help/frequency.html",
"de": "https://www.getresponse.com/de/hilfe/frequency.html",
"es": "https://www.getresponse.com/es/ayuda/frecuencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/chastota.html",
"br": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"fr": "https://www.getresponse.com/fr/aide/frequence.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaEMail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/lista-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/lista-mailingowa.html",
"en": "https://www.getresponse.com/help/mailing-list.html",
"de": "https://www.getresponse.com/de/hilfe/mailing-list.html",
"es": "https://www.getresponse.com/es/ayuda/lista-de-correos.html",
"ru": "https://www.getresponse.com/ru/help-ru/spisok-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"fr": "https://www.getresponse.com/fr/aide/liste-d-adresses-electroniques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeoMap = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/geo-map.html",
"pl": "https://www.getresponse.pl/pomoc/geo-mapa.html",
"de": "https://www.getresponse.com/de/hilfe/geo-map.html",
"es": "https://www.getresponse.com/es/ayuda/mapa-geografico.html",
"ru": "https://www.getresponse.com/ru/help-ru/mestopolozhenie.html",
"br": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGenerazioneDiHtml = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/generazione-di-html.html",
"pl": "https://www.getresponse.pl/pomoc/generowanie-kodu-html.html",
"en": "https://www.getresponse.com/help/generating-html.html",
"de": "https://www.getresponse.com/de/hilfe/generating-html.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-html.html",
"br": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTracciamentoDeiClic = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/tracciamento-dei-clic.html",
"pl": "https://www.getresponse.pl/pomoc/sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/click-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/otslezhivanie-po-kliku.html",
"br": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampagna = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/campagna.html",
"pl": "https://www.getresponse.pl/pomoc/kampania.html",
"en": "https://www.getresponse.com/help/marketing-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/campaign-definition.html",
"es": "https://www.getresponse.com/es/ayuda/campaign-definition.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaja-kampania.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoppioOptIn = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/doppio-opt-in.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-z-potwierdzeniem.html",
"en": "https://www.getresponse.com/help/confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/consentimiento-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdennaia-podpiska.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessaggioDiConferma = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-conferma.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-potwierdzajaca-subskrypcje.html",
"en": "https://www.getresponse.com/help/confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/confirmation-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-confirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-podtverzhdeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAllegato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/allegato.html",
"de": "https://www.getresponse.com/de/hilfe/attachment.html",
"en": "https://www.getresponse.com/help/attachment.html",
"es": "https://www.getresponse.com/es/ayuda/anexo.html",
"ru": "https://www.getresponse.com/ru/help-ru/vlozhenie.html",
"br": "https://www.getresponse.com/pt/ajuda/anexo.html",
"pt": "https://www.getresponse.com/pt/ajuda/anexo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeggeCanSpam = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/legge-can-spam.html",
"pl": "https://www.getresponse.pl/pomoc/ustawa-can-spam.html",
"en": "https://www.getresponse.com/help/can-spam-act.html",
"de": "https://www.getresponse.com/de/hilfe/can-spam-act.html",
"es": "https://www.getresponse.com/es/ayuda/ley-can-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zakon-can-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"fr": "https://www.getresponse.com/fr/aide/loi-can-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutoresponder = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/autoresponder.html",
"en": "https://www.getresponse.com/help/autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/autorepondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/automation.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja.html",
"en": "https://www.getresponse.com/help/automation.html",
"de": "https://www.getresponse.com/de/hilfe/automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizaciya.html",
"br": "https://www.getresponse.com/pt/ajuda/automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/automacao.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailVuouta = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/email-vuouta.html",
"pl": "https://www.getresponse.pl/pomoc/pusty-email.html",
"en": "https://www.getresponse.com/help/blank-email.html",
"de": "https://www.getresponse.com/de/hilfe/blank-email.html",
"es": "https://www.getresponse.com/es/ayuda/email-en-blanco.html",
"ru": "https://www.getresponse.com/ru/help-ru/pustoe-elektronnoe-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"fr": "https://www.getresponse.com/fr/aide/email-vide.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTargetingComportamentale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/targeting-comportamentale.html",
"pl": "https://www.getresponse.pl/pomoc/targetowanie-behawioralne.html",
"en": "https://www.getresponse.com/help/behavioral-targeting.html",
"de": "https://www.getresponse.com/de/hilfe/behavioral-targeting.html",
"es": "https://www.getresponse.com/es/ayuda/orientacion-del-comportamiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/povedencheskij-targeting.html",
"br": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pt": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"fr": "https://www.getresponse.com/fr/aide/ciblage-comportemental.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRimbalzo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/rimbalzo.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot.html",
"en": "https://www.getresponse.com/help/bounced.html",
"de": "https://www.getresponse.com/de/hilfe/bounced.html",
"es": "https://www.getresponse.com/es/ayuda/rebotados.html",
"ru": "https://www.getresponse.com/ru/help-ru/oshibka-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"fr": "https://www.getresponse.com/fr/aide/rebondi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlignment = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/alignment.html",
"pl": "https://www.getresponse.pl/pomoc/zgodnosc.html",
"en": "https://www.getresponse.com/help/alignment.html",
"de": "https://www.getresponse.com/de/hilfe/alignment.html",
"es": "https://www.getresponse.com/es/ayuda/alineacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/vyravnivanie.html",
"br": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"fr": "https://www.getresponse.com/fr/aide/alignement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProgrammaDiAffiliazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/programma-di-affiliazione.html",
"pl": "https://www.getresponse.pl/pomoc/program-afiliacyjny.html",
"en": "https://www.getresponse.com/help/affiliate-program.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-program.html",
"es": "https://www.getresponse.com/es/ayuda/programa-de-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskaya-programma.html",
"br": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/programme-d-affiliation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostResponsabileDelTrattamentoDeiDati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"en": "https://www.getresponse.com/help/data-controller.html",
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDataProcessor = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/data-processor.html",
"pl": "https://www.getresponse.pl/pomoc/podmiot-przetwarzajacy-dane.html",
"en": "https://www.getresponse.com/help/data-processor.html",
"es": "https://www.getresponse.com/es/ayuda/procesador-de-datos.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotchik-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/datenverarbeiter.html",
"br": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalDataProcessing = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/personal-data-processing.html",
"pl": "https://www.getresponse.pl/pomoc/przetwarzanie-danych-osobowych.html",
"en": "https://www.getresponse.com/help/personal-data-processing.html",
"es": "https://www.getresponse.com/es/ayuda/tratamiento-de-datos-personales.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotka-personalnych-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/die-verarbeitung-der-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pt": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees-personnelles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDatiSensibili = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeneralDataProtectionRegulationGdpr = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/general-data-protection-regulation-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/rozporzadzenie-o-ochronie-danych-osobowych-rodo.html",
"en": "https://www.getresponse.com/help/general-data-protection-regulation-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/reglamento-general-de-proteccion-de-datos-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/obshij-reglament-po-zashite-dannych-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/die-allgemeine-datenschutz-verordnung-dsgvo.html",
"br": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/reglement-general-sur-la-protection-des-donnees-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVendita = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"en": "https://www.getresponse.com/help/sale.html",
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html",
"fr": "https://www.getresponse.com/fr/aide/vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChiaveApi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/chiave-api.html",
"pl": "https://www.getresponse.pl/pomoc/klucz-api.html",
"en": "https://www.getresponse.com/help/api-key.html",
"de": "https://www.getresponse.com/de/hilfe/api-key.html",
"es": "https://www.getresponse.com/es/ayuda/clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/api-kljuch.html",
"br": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"fr": "https://www.getresponse.com/fr/aide/cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAggiungereAlWorkflowIContattiCheSonoStatiCopiatiOSpostatiAUnAltraLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuNekotoryeElementyVMojomProcesseVydelenySerymCvetom = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-nekotorye-elementy-v-mojom-processe-vydeleny-serym-cvetom.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-elementy-mojego-mojego-cyklu-sa-wyszarzone.html",
"en": "https://www.getresponse.com/help/some-elements-in-my-workflow-are-grayed-out-why.html",
"de": "https://www.getresponse.com/de/hilfe/manche-elemente-meines-workflows-sind-ausgegraut-warum.html",
"es": "https://www.getresponse.com/es/ayuda/algunos-elementos-de-mi-flujo-de-trabajo-estan-en-gris-por-que.html",
"br": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pt": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"it": "https://www.getresponse.com/it/assistenza/alcuni-elementi-nel-automation-workflow-sono-disattivati-perche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoOznachajutTsyfryVKazhdomElementeProtsessa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajutElementySojedinenijaDaNet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeDekstvijaUslovijaIFiltry = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaMensagemDeAutomacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarAAutenticacaoDeDoisFatoresNaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoIntegrarAGetresponseComOClickfunnels = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarEventos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotatSElementamiVProtsesse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetDublirovanijeProtsessov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOpredelitVremaNachalaIKontsaRabotyProtsessa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaNachatProcessSNeskolkihUslovij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezhduAvtootvetchikomIMarketingowojAvtomatizacijej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearYUsarCamposDeConsentimiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateEvents = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIIntegrateGetresponseWithClickfunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-clickfunnels.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-clickfunnels.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-clickfunnels.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-clickfunnels.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-getresponse-con-clickfunnels.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-mit-clickfunnels.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-getresponse-con-clickfunnels.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIManageMyZapsInGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-manage-my-zaps-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-administrar-meus-zaps-em-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-zapami-w-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ypravliyat-moimi-zap-shablonami-v-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-gestionar-mis-zaps-en-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-meine-zaps-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-miei-zaps-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-mes-zaps-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAndSendAnSms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToFormatTheFileOfSmsRecipients = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html",
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacNowaNazweNadawcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddANewSmsSenderName = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-a-new-sms-sender-name.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowa-nazwe-nadawcy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoe-imya-otpravitelya-dlya-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-mittente-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-nuevo-nombre-de-remitente-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-nome-de-remetente-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-neuen-sms-absendernamen-hinzu.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-nouveau-nom-d-expediteur-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSynchronizeContactsBetweenGetresponseAndMicrosoftDynamics365 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-synchronize-contacts-between-getresponse-and-microsoft-dynamics-365.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinhronizirovat-kontaky-mezhdy-getresponse-i-microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zsynchronizowac-kontakty-pomiedzy-kontami-getresponse-i-microsoft-dynamics-365.html",
"it": "https://www.getresponse.com/it/assistenza/come-sincronizzare-i-contatti-tra-getresponse-e-microsoft-dynamics-365.html",
"br": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-sincronizar-os-contatos-entre-getresponse-e-microsoft-dynamics-365.html",
"es": "https://www.getresponse.com/es/ayuda/como-sincronizar-contactos-entre-getresponse-y-microsoft-dynamics-365.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiert-man-kontakte-zwischen-getresponse-und-microsoft-dynamics-365.html",
"fr": "https://www.getresponse.com/fr/aide/comment-synchroniser-les-contacts-entre-getresponse-et-microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAImpostareUnIntegrazioneSmtp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSetUpAnSmtpIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeInviareEmailTransazionaliTramiteGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSendTransactionalEmailsThroughGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-send-transactional-emails-through-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-inviare-email-transazionali-tramite-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-emails-transacionais-atraves-da-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravlyat-tranzakcionnye-pisma-cherez-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-emaile-transakcyjne-poprzez-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-enviar-correos-electronicos-transaccionales-a-traves-de-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versendet-man-transaktionale-e-mails-ueber-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-e-mails-transactionnels-via-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToConfigureATransactionalEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToImportFilesViaFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUploadAListToAGetresponseMaxAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToChangeTheDnsSettingsForLandingPagesCreatedInAMaxAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddACnameEntryToMySubdomainForLandingPagesCreatedInAMaxAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsIpAddressWarmup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToPrepareAFileToImportItViaFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhenToImportFilesViaFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIHaveAMaxAccountHowDoIGetStarted = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanICreateAdditionalAccountsInMyMaxAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICheckTheStatisticsForMyTransactionalMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZWeryfikacjiDwuetapowejWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTwoFactorAuthenticationInGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-two-factor-authentication-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-weryfikacji-dwuetapowej-w-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-autenticacao-de-dois-fatores-na-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dvuhfaktornuju-autentifikaciju-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zwei-faktor-authentifizierung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-la-autenticacion-de-dos-factores-en-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-l-autenticazione-a-due-fattori-in-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-authentification-a-deux-facteurs-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuMoiAvtootvetchikiNeOtpravlajutsa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarACondicaoEventoEmMeusFluxosDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEPerfectTimingEComoIssoFunciona3 = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAnAutomationMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacObrazekLubPrzyciskWBlokuTekstowym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-obrazek-lub-przycisk-w-bloku-tekstowym.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-bild-oder-einen-button-in-ein-textelement-einfuegen.html",
"en": "https://www.getresponse.com/help/can-i-embed-an-image-or-button-within-a-text-block.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vstavit-izobrazhenije-ili-knopku-tekstovy-blok.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-incrustar-una-imagen-o-un-boton-dentro-de-un-bloque-de-texto.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"it": "https://www.getresponse.com/it/assistenza/posso-incorporare-un-immagine-o-un-pulsante-all-interno-di-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-une-image-ou-un-bouton-dans-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacKolejneWiadomosciDoCykluAutorespondera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicAdresEmailNadawcyWWiadomosciach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-email-nadawcy-w-wiadomosciach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-absender-e-mail-adresse-in-meinen-nachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-adres-otpravitela-v-moich-soobshenijach.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-direccion-de-en-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-l-indirizzo-di-mittente-nei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-de-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuProishodatOshibkiDostavki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuMoiKontaktyNePoluchiliMojuRassylku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatVebShriftyVSoobshenijah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitFonovojeIzobrazhenijeVSoobshenije = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeSushestvujutTrebovanijaDlaFutera = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatRassylkuKotorajaWygladitHorosho = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNaskolkoShirokimMozhetBytMojeSoobshenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/naskolko-shirokim-mozhet-byt-moje-soobshenie.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-szerokosc-powinna-miec-moja-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-wide-should-my-message-be.html",
"de": "https://www.getresponse.com/de/hilfe/welche-breite-sollte-meine-nachricht-haben.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-dovrebbe-essere-largo-il-mio-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-ancho-debe-ser-mi-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-doit-etre-la-largeur-de-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeSektsiiVRedaktoreSoobshenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguIspolzovatMoiBlokiVNovomRedaktoreSoobshenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatSobstvennyjKodVRassylke = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtslezhivatProcentOtkrytijDlaSoobshenijVFormateObychnyjTekst = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToVerifyADomainInFacebookBusinessManager = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-verify-a-domain-in-facebook-business-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podtverdit-domen-v-facebook-business-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-verificar-um-dominio-no-facebook-business-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zweryfikowac-domene-w-menedzerze-firmy-na-facebooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-domain-in-facebook-busiensss-manager-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-un-dominio-in-facebook-business-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-verificar-un-dominio-en-facebook-business-manager-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-un-domaine-dans-facebook-business-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakTworzycZdarzenia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-zdarzenia.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-eventos.html",
"en": "https://www.getresponse.com/help/how-to-create-events.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-sobytie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-events.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaZapisatSvojVebinar = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatBazovyjeBlokiDlaSozdanijaSoobshenija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacFacebookaZKontemGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUdostepnicNewsletterNaFacebookuITwitterze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailDeOptInAdesao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/email-de-opt-in-adesao.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-de-opt-in-adesao.html",
"pl": "https://www.getresponse.pl/pomoc/opt-in-email.html",
"en": "https://www.getresponse.com/help/opt-in-email.html",
"de": "https://www.getresponse.com/de/hilfe/opt-in-email.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-na-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-email.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptOutCancelarAInscricao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEstatisticasDeDesempenho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pl": "https://www.getresponse.pl/pomoc/statystki-wysylek.html",
"en": "https://www.getresponse.com/help/performance-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/performance-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-desempeno.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-dejstvij.html",
"it": "https://www.getresponse.com/it/assistenza/statistiche-di-rendimento.html",
"fr": "https://www.getresponse.com/fr/aide/statistiques-de-performance.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIntervalo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"pl": "https://www.getresponse.pl/pomoc/interwal.html",
"en": "https://www.getresponse.com/help/interval.html",
"de": "https://www.getresponse.com/de/hilfe/interval.html",
"es": "https://www.getresponse.com/es/ayuda/intervalo.html",
"ru": "https://www.getresponse.com/ru/help-ru/interval.html",
"it": "https://www.getresponse.com/it/assistenza/intervallo.html",
"fr": "https://www.getresponse.com/fr/aide/intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnderecoDeIp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pl": "https://www.getresponse.pl/pomoc/adres-ip.html",
"en": "https://www.getresponse.com/help/ip-address.html",
"de": "https://www.getresponse.com/de/hilfe/ip-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/ip-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-ip.html",
"fr": "https://www.getresponse.com/fr/aide/addresse-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloqueioDeIp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-adresu-ip.html",
"en": "https://www.getresponse.com/help/ip-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/ip-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-ip.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-ip.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPsi = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/psi.html",
"pt": "https://www.getresponse.com/pt/ajuda/psi.html",
"pl": "https://www.getresponse.pl/pomoc/isp.html",
"en": "https://www.getresponse.com/help/isp.html",
"de": "https://www.getresponse.com/de/hilfe/isp.html",
"es": "https://www.getresponse.com/es/ayuda/isp.html",
"ru": "https://www.getresponse.com/ru/help-ru/isp-internet-provajder.html",
"it": "https://www.getresponse.com/it/assistenza/isp.html",
"fr": "https://www.getresponse.com/fr/aide/isp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversaoDeLeads = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pl": "https://www.getresponse.pl/pomoc/konwersja-leadow.html",
"en": "https://www.getresponse.com/help/lead-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/lead-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversiya-lida.html",
"it": "https://www.getresponse.com/it/assistenza/conversione-di-lead.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeracaoDeLeads = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pl": "https://www.getresponse.pl/pomoc/pozyskiwanie-leadow.html",
"en": "https://www.getresponse.com/help/leads-generation.html",
"de": "https://www.getresponse.com/de/hilfe/leads-generation.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/generaciya-lidov.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-lead.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGestaoDeListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-lista.html",
"en": "https://www.getresponse.com/help/list-management.html",
"de": "https://www.getresponse.com/de/hilfe/list-management.html",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-spiskami.html",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-liste.html",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentacaoDeListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-listy.html",
"en": "https://www.getresponse.com/help/list-segmentation.html",
"de": "https://www.getresponse.com/de/hilfe/list-segmentation.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaDeCorrespondencia = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pl": "https://www.getresponse.pl/pomoc/lista-mailingowa.html",
"en": "https://www.getresponse.com/help/mailing-list.html",
"de": "https://www.getresponse.com/de/hilfe/mailing-list.html",
"es": "https://www.getresponse.com/es/ayuda/lista-de-correos.html",
"ru": "https://www.getresponse.com/ru/help-ru/spisok-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/lista-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/liste-d-adresses-electroniques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFrequencia = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pl": "https://www.getresponse.pl/pomoc/czestotliwosc-wyslanych-emaili.html",
"en": "https://www.getresponse.com/help/frequency.html",
"de": "https://www.getresponse.com/de/hilfe/frequency.html",
"es": "https://www.getresponse.com/es/ayuda/frecuencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/chastota.html",
"it": "https://www.getresponse.com/it/assistenza/frequenza.html",
"fr": "https://www.getresponse.com/fr/aide/frequence.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRemetente = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/remetente.html",
"pl": "https://www.getresponse.pl/pomoc/pole-nadawcy.html",
"en": "https://www.getresponse.com/help/from-field.html",
"de": "https://www.getresponse.com/de/hilfe/from-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-otpravitelya.html",
"it": "https://www.getresponse.com/it/assistenza/from-field.html",
"fr": "https://www.getresponse.com/fr/aide/le-champ-de.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGerandoHtml = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pl": "https://www.getresponse.pl/pomoc/generowanie-kodu-html.html",
"en": "https://www.getresponse.com/help/generating-html.html",
"de": "https://www.getresponse.com/de/hilfe/generating-html.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-html.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-html.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMapaGeografico = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html",
"pl": "https://www.getresponse.pl/pomoc/geo-mapa.html",
"de": "https://www.getresponse.com/de/hilfe/geo-map.html",
"es": "https://www.getresponse.com/es/ayuda/mapa-geografico.html",
"ru": "https://www.getresponse.com/ru/help-ru/mestopolozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/geo-map.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaCinza = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"en": "https://www.getresponse.com/help/greylist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html",
"de": "https://www.getresponse.com/de/hilfe/grauliste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRejeicaoForte = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-twardy.html",
"en": "https://www.getresponse.com/help/hard-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/hard-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-permanente.html",
"ru": "https://www.getresponse.com/ru/help-ru/postoyannaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-duro.html",
"fr": "https://www.getresponse.com/fr/aide/hard-bounce-rebondissement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHtmlMessage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/html-message.html",
"pt": "https://www.getresponse.com/pt/ajuda/html-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-html.html",
"de": "https://www.getresponse.com/de/hilfe/html-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/html-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloqueioDeImagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-obrazkow.html",
"en": "https://www.getresponse.com/help/image-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/image-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-imagenes.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-izobrazhenij.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-delle-immagini.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-de-l-image.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostImportacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/importacao.html",
"pl": "https://www.getresponse.pl/pomoc/import.html",
"en": "https://www.getresponse.com/help/import.html",
"de": "https://www.getresponse.com/de/hilfe/import.html",
"es": "https://www.getresponse.com/es/ayuda/importar.html",
"ru": "https://www.getresponse.com/ru/help-ru/import.html",
"it": "https://www.getresponse.com/it/assistenza/import.html",
"fr": "https://www.getresponse.com/fr/aide/importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubscritoresInativos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pl": "https://www.getresponse.pl/pomoc/nieaktywni-subskrybenci.html",
"en": "https://www.getresponse.com/help/inactive-subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/inactive-subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/neaktivnye-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/sottoscrittori-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensagemInstantanea = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-natychmiastowa-blyskawiczna.html",
"en": "https://www.getresponse.com/help/instant-message.html",
"de": "https://www.getresponse.com/de/hilfe/instant-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-instantaneo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mgnovennoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/instant-message.html",
"fr": "https://www.getresponse.com/fr/aide/message-instantane.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPublicidadePorEmail = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-mailowa.html",
"en": "https://www.getresponse.com/help/email-advertising.html",
"de": "https://www.getresponse.com/de/hilfe/email-advertising.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-reklama.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-via-e-mail.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-par-courriel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguPodelitsaRassylkojVTwitterIFacebookPriOtpravke = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoNovogoVNovomRedaktoreSoobshenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeJaMoguProsmotretStatistikuSvoihSoobshenij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeJaMoguProsmotretOtpravlennyjeSoobshenija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampanhaPorEmail = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/email-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-kampaniya.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-d-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCabecalhosDeEmail = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pl": "https://www.getresponse.pl/pomoc/naglowki-emaila.html",
"en": "https://www.getresponse.com/help/email-headers.html",
"de": "https://www.getresponse.com/de/hilfe/email-headers.html",
"es": "https://www.getresponse.com/es/ayuda/encabezados-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/zagolovki-elektronnoi-pochti.html",
"it": "https://www.getresponse.com/it/assistenza/intestazioni-e-mail-e-mail-headers.html",
"fr": "https://www.getresponse.com/fr/aide/en-tetes-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnviandoEMails = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/emailing.html",
"en": "https://www.getresponse.com/help/emailing.html",
"de": "https://www.getresponse.com/de/hilfe/emailing.html",
"es": "https://www.getresponse.com/es/ayuda/enviando-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpravka-po-elektronnoj-pochte.html",
"it": "https://www.getresponse.com/it/assistenza/invio-di-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/l-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocaoPorEMail = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-emailowa.html",
"en": "https://www.getresponse.com/help/email-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/email-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-por-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-via-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-par-courrier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostArquivoAnexado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pt": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pl": "https://www.getresponse.pl/pomoc/zalacznik.html",
"en": "https://www.getresponse.com/help/file-attachment.html",
"de": "https://www.getresponse.com/de/hilfe/file-attachment.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-adjunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/fajl-vlozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/file-allegato.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-joint.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRodape = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pl": "https://www.getresponse.pl/pomoc/stopka-maila.html",
"en": "https://www.getresponse.com/help/footer.html",
"de": "https://www.getresponse.com/de/hilfe/footer.html",
"es": "https://www.getresponse.com/es/ayuda/pie-de-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/futer.html",
"it": "https://www.getresponse.com/it/assistenza/pie-di-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampoPersonalizado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"de": "https://www.getresponse.com/de/hilfe/custom-field.html",
"en": "https://www.getresponse.com/help/custom-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemoe-pole.html",
"it": "https://www.getresponse.com/it/assistenza/campo-personalizzato.html",
"pl": "https://www.getresponse.pl/pomoc/pole-dodatkowe-2.html",
"fr": "https://www.getresponse.com/fr/aide/champ-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaxaDeEntregabilidade = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-dostarczalnosci.html",
"en": "https://www.getresponse.com/help/deliverability-rate.html",
"de": "https://www.getresponse.com/de/hilfe/deliverability-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/uroven-dostavlyaemosti.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostServidorDedicado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"pl": "https://www.getresponse.pl/pomoc/serwer-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-server.html",
"de": "https://www.getresponse.com/de/hilfe/dedicated-server.html",
"es": "https://www.getresponse.com/es/ayuda/servidor-dedicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/vydelennyj-server.html",
"it": "https://www.getresponse.com/it/assistenza/server-dedicato.html",
"fr": "https://www.getresponse.com/fr/aide/serveur-dedie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEntregue = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pt": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pl": "https://www.getresponse.pl/pomoc/dostarczona.html",
"en": "https://www.getresponse.com/help/delivered.html",
"de": "https://www.getresponse.com/de/hilfe/delivered.html",
"es": "https://www.getresponse.com/es/ayuda/entregado.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-dostavleno.html",
"it": "https://www.getresponse.com/it/assistenza/consegnato.html",
"fr": "https://www.getresponse.com/fr/aide/livre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConteudoDinamico = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"pl": "https://www.getresponse.pl/pomoc/dynamic-content.html",
"en": "https://www.getresponse.com/help/dynamic-content.html",
"de": "https://www.getresponse.com/de/hilfe/dynamic-content.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/dinamicheskij-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/contenuti-dinamici.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNomeDeDominio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html",
"pl": "https://www.getresponse.pl/pomoc/nazwa-domeny.html",
"de": "https://www.getresponse.com/de/hilfe/domain-name.html",
"es": "https://www.getresponse.com/es/ayuda/nombre-de-dominio.html",
"ru": "https://www.getresponse.com/ru/help-ru/domennoe-imya.html",
"it": "https://www.getresponse.com/it/assistenza/nome-di-dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTransmissao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"pl": "https://www.getresponse.pl/pomoc/wysylka.html",
"en": "https://www.getresponse.com/help/broadcast.html",
"de": "https://www.getresponse.com/de/hilfe/broadcast.html",
"es": "https://www.getresponse.com/es/ayuda/transmision.html",
"ru": "https://www.getresponse.com/ru/help-ru/translyaciya.html",
"it": "https://www.getresponse.com/it/assistenza/broadcast.html",
"fr": "https://www.getresponse.com/fr/aide/transmission.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaxaDeRejeicao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zwrotow.html",
"en": "https://www.getresponse.com/help/bounce-rate.html",
"de": "https://www.getresponse.com/de/hilfe/bounce-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-rebote.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-oshibok-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/bounce-rate.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-rebond.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRastreamentoDeCliques = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pl": "https://www.getresponse.pl/pomoc/sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/click-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/otslezhivanie-po-kliku.html",
"it": "https://www.getresponse.com/it/assistenza/tracciamento-dei-clic.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIndiceDeClique = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pt": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-klikniec.html",
"en": "https://www.getresponse.com/help/click-through-rate.html",
"de": "https://www.getresponse.com/de/hilfe/click-through-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/koefficient-klikov-ctr.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-clic.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailEmMassa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-masowa.html",
"en": "https://www.getresponse.com/help/bulk-email.html",
"de": "https://www.getresponse.com/de/hilfe/bulk-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-masivo.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovaya-rassylka.html",
"it": "https://www.getresponse.com/it/assistenza/email-di-massa.html",
"fr": "https://www.getresponse.com/fr/aide/envoi-massif-de-courriels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptInConfirmado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-z-potwierdzeniem.html",
"en": "https://www.getresponse.com/help/confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/consentimiento-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdennaia-podpiska.html",
"it": "https://www.getresponse.com/it/assistenza/doppio-opt-in.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensagemDeConfirmacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-potwierdzajaca-subskrypcje.html",
"en": "https://www.getresponse.com/help/confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/confirmation-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-confirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-conferma.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAtoCanSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pl": "https://www.getresponse.pl/pomoc/ustawa-can-spam.html",
"en": "https://www.getresponse.com/help/can-spam-act.html",
"de": "https://www.getresponse.com/de/hilfe/can-spam-act.html",
"es": "https://www.getresponse.com/es/ayuda/ley-can-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zakon-can-spam.html",
"it": "https://www.getresponse.com/it/assistenza/legge-can-spam.html",
"fr": "https://www.getresponse.com/fr/aide/loi-can-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDefinicaoDaCampanha = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pl": "https://www.getresponse.pl/pomoc/kampania.html",
"en": "https://www.getresponse.com/help/marketing-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/campaign-definition.html",
"es": "https://www.getresponse.com/es/ayuda/campaign-definition.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaja-kampania.html",
"it": "https://www.getresponse.com/it/assistenza/campagna.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCodificacaoDeCaracteres = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pt": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pl": "https://www.getresponse.pl/pomoc/kodowanie-znakow.html",
"en": "https://www.getresponse.com/help/character-encoding.html",
"de": "https://www.getresponse.com/de/hilfe/character-encoding.html",
"ru": "https://www.getresponse.com/ru/help-ru/kodirovka-simvolov.html",
"it": "https://www.getresponse.com/it/assistenza/codifica-dei-caratteri.html",
"es": "https://www.getresponse.com/es/ayuda/codificacion-de-caracteres.html",
"fr": "https://www.getresponse.com/fr/aide/codage-des-caracteres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDefinicaoDeCaptcha = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-captcha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-captcha.html",
"pl": "https://www.getresponse.pl/pomoc/captcha.html",
"en": "https://www.getresponse.com/help/captcha.html",
"de": "https://www.getresponse.com/de/hilfe/captcha-definition.html",
"es": "https://www.getresponse.com/es/ayuda/captcha.html",
"ru": "https://www.getresponse.com/ru/help-ru/captcha.html",
"it": "https://www.getresponse.com/it/assistenza/captcha.html",
"fr": "https://www.getresponse.com/fr/aide/captcha.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/automacao.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja.html",
"en": "https://www.getresponse.com/help/automation.html",
"de": "https://www.getresponse.com/de/hilfe/automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/automation.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnexo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/anexo.html",
"pt": "https://www.getresponse.com/pt/ajuda/anexo.html",
"de": "https://www.getresponse.com/de/hilfe/attachment.html",
"en": "https://www.getresponse.com/help/attachment.html",
"es": "https://www.getresponse.com/es/ayuda/anexo.html",
"ru": "https://www.getresponse.com/ru/help-ru/vlozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/allegato.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailEmBranco = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pl": "https://www.getresponse.pl/pomoc/pusty-email.html",
"en": "https://www.getresponse.com/help/blank-email.html",
"de": "https://www.getresponse.com/de/hilfe/blank-email.html",
"es": "https://www.getresponse.com/es/ayuda/email-en-blanco.html",
"ru": "https://www.getresponse.com/ru/help-ru/pustoe-elektronnoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/email-vuouta.html",
"fr": "https://www.getresponse.com/fr/aide/email-vide.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDirecionamentoComportamental = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pt": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pl": "https://www.getresponse.pl/pomoc/targetowanie-behawioralne.html",
"en": "https://www.getresponse.com/help/behavioral-targeting.html",
"de": "https://www.getresponse.com/de/hilfe/behavioral-targeting.html",
"es": "https://www.getresponse.com/es/ayuda/orientacion-del-comportamiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/povedencheskij-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-comportamentale.html",
"fr": "https://www.getresponse.com/fr/aide/ciblage-comportemental.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloqueio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie.html",
"en": "https://www.getresponse.com/help/blocking.html",
"de": "https://www.getresponse.com/de/hilfe/blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka.html",
"it": "https://www.getresponse.com/it/assistenza/blocco.html",
"fr": "https://www.getresponse.com/fr/aide/blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAcimaDaDobra = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"pl": "https://www.getresponse.pl/pomoc/above-the-fold-ponad-linia.html",
"en": "https://www.getresponse.com/help/above-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/above-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/sobre-el-pliegue.html",
"ru": "https://www.getresponse.com/ru/help-ru/vidimaya-chast-bez-prokrutki.html",
"it": "https://www.getresponse.com/it/assistenza/above-the-fold.html",
"fr": "https://www.getresponse.com/fr/aide/above-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAbaixoDaDobra = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"pl": "https://www.getresponse.pl/pomoc/below-the-fold-ponizej-linii.html",
"en": "https://www.getresponse.com/help/below-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/below-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/bajo-el-pliegue.html",
"ru": "https://www.getresponse.com/ru/help-ru/nizhe-linii-zgiba.html",
"it": "https://www.getresponse.com/it/assistenza/below-the-fold.html",
"fr": "https://www.getresponse.com/fr/aide/below-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlinhamento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"pl": "https://www.getresponse.pl/pomoc/zgodnosc.html",
"en": "https://www.getresponse.com/help/alignment.html",
"de": "https://www.getresponse.com/de/hilfe/alignment.html",
"es": "https://www.getresponse.com/es/ayuda/alineacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/vyravnivanie.html",
"it": "https://www.getresponse.com/it/assistenza/alignment.html",
"fr": "https://www.getresponse.com/fr/aide/alignement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProgramaDeAfiliados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"pl": "https://www.getresponse.pl/pomoc/program-afiliacyjny.html",
"en": "https://www.getresponse.com/help/affiliate-program.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-program.html",
"es": "https://www.getresponse.com/es/ayuda/programa-de-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskaya-programma.html",
"it": "https://www.getresponse.com/it/assistenza/programma-di-affiliazione.html",
"fr": "https://www.getresponse.com/fr/aide/programme-d-affiliation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubjectLine = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/subject-line.html",
"pl": "https://www.getresponse.pl/pomoc/temat-wiadomosci.html",
"en": "https://www.getresponse.com/help/subject-line.html",
"es": "https://www.getresponse.com/es/ayuda/linea-de-asunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/tema-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/oggetto.html",
"br": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pt": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"fr": "https://www.getresponse.com/fr/aide/ligne-d-objet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubscribers = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/subscribers.html",
"pl": "https://www.getresponse.pl/pomoc/subskrybent.html",
"en": "https://www.getresponse.com/help/subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpischiki.html",
"it": "https://www.getresponse.com/it/assistenza/contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostThankYouPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/thank-you-page.html",
"pl": "https://www.getresponse.pl/pomoc/strona-z-podziekowaniem.html",
"en": "https://www.getresponse.com/help/thank-you-page.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-de-agradecimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/stranica-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/pagina-di-ringraziamento.html",
"br": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSurvey = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/survey.html",
"pl": "https://www.getresponse.pl/pomoc/ankieta.html",
"en": "https://www.getresponse.com/help/survey.html",
"es": "https://www.getresponse.com/es/ayuda/encuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/opros.html",
"it": "https://www.getresponse.com/it/assistenza/sondaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pt": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"fr": "https://www.getresponse.com/fr/aide/enquete.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubscriptionReports = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/custom-reports.html",
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebForm = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/web-form.html",
"pl": "https://www.getresponse.pl/pomoc/formularz-zapisu.html",
"en": "https://www.getresponse.com/help/web-form.html",
"es": "https://www.getresponse.com/es/ayuda/formulario-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/veb-forma.html",
"it": "https://www.getresponse.com/it/assistenza/modulo-web.html",
"br": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"fr": "https://www.getresponse.com/fr/aide/formulaire-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReplyToField = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/reply-to-field.html",
"pl": "https://www.getresponse.pl/pomoc/pole-odpowiedz-do.html",
"en": "https://www.getresponse.com/help/reply-to-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-respuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-dlya-otveta.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-di-risposta.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"fr": "https://www.getresponse.com/fr/aide/champ-de-reponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRemovals = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/removals.html",
"pl": "https://www.getresponse.pl/pomoc/usuniete.html",
"en": "https://www.getresponse.com/help/removals.html",
"es": "https://www.getresponse.com/es/ayuda/bajas.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpisavshiesya-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/contatti-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"fr": "https://www.getresponse.com/fr/aide/suppressions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpamComplaint = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/spam-complaint.html",
"pl": "https://www.getresponse.pl/pomoc/zgloszenie-spamu.html",
"en": "https://www.getresponse.com/help/spam-complaint.html",
"es": "https://www.getresponse.com/es/ayuda/denuncia-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zhaloba-na-spam.html",
"it": "https://www.getresponse.com/it/assistenza/reclamo-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/plainte-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSoftBounce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/soft-bounce.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-miekki.html",
"en": "https://www.getresponse.com/help/soft-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-transitorio.html",
"ru": "https://www.getresponse.com/ru/help-ru/vremennaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-morbido.html",
"br": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pt": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"fr": "https://www.getresponse.com/fr/aide/rebond-doux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalization = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/personalization.html",
"pl": "https://www.getresponse.pl/pomoc/personalizacja.html",
"en": "https://www.getresponse.com/help/personalization.html",
"es": "https://www.getresponse.com/es/ayuda/personalizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/personalizzazione.html",
"br": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"fr": "https://www.getresponse.com/fr/aide/personnalisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPermissionBasedMarketing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/permission-based-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-za-przyzwoleniem.html",
"en": "https://www.getresponse.com/help/permission-based-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-basado-en-permisos.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketing-na-osnove-razreshenij.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-basato-sul-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-base-sur-les-permissions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlainText = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/plain-text.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-w-formacie-tekstowym.html",
"en": "https://www.getresponse.com/help/plain-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-sin-formato.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/testo-semplice.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPostalAddress = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/postal-address.html",
"pl": "https://www.getresponse.pl/pomoc/adres-pocztowy.html",
"en": "https://www.getresponse.com/help/postal-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-postal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-postale.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"fr": "https://www.getresponse.com/fr/aide/adresse-postale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRefTrackingNumber = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ref-tracking-number.html",
"pl": "https://www.getresponse.pl/pomoc/ref-numer-sledzacy.html",
"en": "https://www.getresponse.com/help/ref-tracking-number.html",
"es": "https://www.getresponse.com/es/ayuda/numero-de-seguimiento-de-la-referencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/nomer-dla-otslezivaniya.html",
"it": "https://www.getresponse.com/it/assistenza/numero-di-riferimento.html",
"br": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"fr": "https://www.getresponse.com/fr/aide/ref-numero-de-suivi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMessageFormat = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/message-format.html",
"pl": "https://www.getresponse.pl/pomoc/format-wiadomosci.html",
"en": "https://www.getresponse.com/help/message-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-del-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/format-soobshheniya.html",
"it": "https://www.getresponse.com/it/assistenza/formato-del-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/format-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMergeWord = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/merge-word.html",
"pl": "https://www.getresponse.pl/pomoc/merge-word.html",
"en": "https://www.getresponse.com/help/merge-word.html",
"es": "https://www.getresponse.com/es/ayuda/fusion-de-palabras.html",
"ru": "https://www.getresponse.com/ru/help-ru/slovo-obedenenie.html",
"it": "https://www.getresponse.com/it/assistenza/fusione-di-parole.html",
"br": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"pt": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"fr": "https://www.getresponse.com/fr/aide/fusionner-des-mots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNewsletterTemplate = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/newsletter-template.html",
"pl": "https://www.getresponse.pl/pomoc/szablon-newslettera.html",
"en": "https://www.getresponse.com/help/newsletter-template.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/shablon-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMultiUnsubscribeOptOut = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/multi-unsubscribe-opt-out.html",
"en": "https://www.getresponse.com/help/multi-unsubscribe-opt-out.html",
"es": "https://www.getresponse.com/es/ayuda/desinscripcion-multiple-opt-out.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovyj-otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-multipla-opt-out.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pl": "https://www.getresponse.pl/pomoc/multi-unsubscribe-wypis.html",
"fr": "https://www.getresponse.com/fr/aide/desabonnement-multiple-opt-out.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNonConfirmedOptIn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/non-confirmed-opt-in.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-bez-potwierdzenia.html",
"en": "https://www.getresponse.com/help/non-confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-no-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-bez-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-non-confermato.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-non-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPerformanceStatistics = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/performance-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/statystki-wysylek.html",
"en": "https://www.getresponse.com/help/performance-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-desempeno.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-dejstvij.html",
"it": "https://www.getresponse.com/it/assistenza/statistiche-di-rendimento.html",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"fr": "https://www.getresponse.com/fr/aide/statistiques-de-performance.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOpenRate = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/open-rate.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-otwarc.html",
"en": "https://www.getresponse.com/help/open-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-otkrytij.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-apertura.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptOutUnsubscribe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/opt-out.html",
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIpAddress = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ip-address.html",
"pl": "https://www.getresponse.pl/pomoc/adres-ip.html",
"en": "https://www.getresponse.com/help/ip-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/ip-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/addresse-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIpBlocking = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ip-blocking.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-adresu-ip.html",
"en": "https://www.getresponse.com/help/ip-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-ip.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeadsGeneration = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/leads-generation.html",
"pl": "https://www.getresponse.pl/pomoc/pozyskiwanie-leadow.html",
"en": "https://www.getresponse.com/help/leads-generation.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/generaciya-lidov.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeadConversion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/lead-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/konwersja-leadow.html",
"en": "https://www.getresponse.com/help/lead-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversiya-lida.html",
"it": "https://www.getresponse.com/it/assistenza/conversione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListSegmentation = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/list-segmentation.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-listy.html",
"en": "https://www.getresponse.com/help/list-segmentation.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-della-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListManagement = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/list-management.html",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-lista.html",
"en": "https://www.getresponse.com/help/list-management.html",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-spiskami.html",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChaveApi = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pl": "https://www.getresponse.pl/pomoc/klucz-api.html",
"en": "https://www.getresponse.com/help/api-key.html",
"de": "https://www.getresponse.com/de/hilfe/api-key.html",
"es": "https://www.getresponse.com/es/ayuda/clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/api-kljuch.html",
"it": "https://www.getresponse.com/it/assistenza/chiave-api.html",
"fr": "https://www.getresponse.com/fr/aide/cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoACondicaoDeCampoPersonalizadoFunciona = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaOFiltroDeSegmentoDinamico = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoFazerMudancasEmUmFluxoDeTrabalhoQueJaFoiPublicado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMoverMeusContatosDeUmaListaParaOutraComoResultadoDeUmFluxoDeTrabalhoDeAutomacaoDeMarketing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoIntegrarAGetresponseComOGoogleAnalytics = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionoAnexosNasMinhasMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIniciarUmFluxoDeTrabalhoComMultiplasCondicoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRejeicoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot.html",
"en": "https://www.getresponse.com/help/bounced.html",
"de": "https://www.getresponse.com/de/hilfe/bounced.html",
"es": "https://www.getresponse.com/es/ayuda/rebotados.html",
"ru": "https://www.getresponse.com/ru/help-ru/oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo.html",
"fr": "https://www.getresponse.com/fr/aide/rebondi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoTestarDiferentesPartesDaMensagemEmUmTesteAB = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAgendarMeusTestesAB = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoAsConfiguracoesDeTempoNasPropriedadesDaCondicao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoAcoesCondicoesEFiltros = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAMensagemComMelhorDesempenhoEEnviada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html",
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarACondicaoDaTagSeEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoSecoesNoCriadorDaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmLivroDigitalOuUmVideoNaMinhaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoOsAplicativosParaConstrucaoDeListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-formularze-pop-up-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/what-are-list-builder-apps.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-formular-apps.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-aplicaciones-de-list-builder-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-formy-prilogeniya.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-applications-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueMeusEMailsAsVezesAcabamEmUmaPastaDeSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarUmaMensagemInstantaneaAposAlguemAssinarViaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueOEmblemaDaGetresponseEExibidoEmMeusEMails = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualOCustoMensalDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEAPoliticaDeReembolsoDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAlterarAsDatasDeEnvioDasAutorrespostas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoReceberNotificacoesNoSlack = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEnviarEMailsEmConformidadeComDmarc = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsarUmCodigoDeAcompanhamentoDoGoogleAnalyticsNaMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAcompanharMinhaLandingPageLegadaComOVisualWebsiteOptimizerVwo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoGarantirEstarEmConformidadeComAsRegrasCasl = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html",
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFrequency = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/frequency.html",
"pl": "https://www.getresponse.pl/pomoc/czestotliwosc-wyslanych-emaili.html",
"en": "https://www.getresponse.com/help/frequency.html",
"es": "https://www.getresponse.com/es/ayuda/frecuencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/chastota.html",
"it": "https://www.getresponse.com/it/assistenza/frequenza.html",
"br": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"fr": "https://www.getresponse.com/fr/aide/frequence.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMailingList = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/mailing-list.html",
"pl": "https://www.getresponse.pl/pomoc/lista-mailingowa.html",
"en": "https://www.getresponse.com/help/mailing-list.html",
"es": "https://www.getresponse.com/es/ayuda/lista-de-correos.html",
"ru": "https://www.getresponse.com/ru/help-ru/spisok-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/lista-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"fr": "https://www.getresponse.com/fr/aide/liste-d-adresses-electroniques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHardBounce = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/hard-bounce.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-twardy.html",
"en": "https://www.getresponse.com/help/hard-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-permanente.html",
"ru": "https://www.getresponse.com/ru/help-ru/postoyannaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-duro.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"fr": "https://www.getresponse.com/fr/aide/hard-bounce-rebondissement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeneratingHtml = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/generating-html.html",
"pl": "https://www.getresponse.pl/pomoc/generowanie-kodu-html.html",
"en": "https://www.getresponse.com/help/generating-html.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-html.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-html.html",
"br": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostImageBlocking = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/image-blocking.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-obrazkow.html",
"en": "https://www.getresponse.com/help/image-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-imagenes.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-izobrazhenij.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-delle-immagini.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-de-l-image.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInactiveSubscribers = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/inactive-subscribers.html",
"pl": "https://www.getresponse.pl/pomoc/nieaktywni-subskrybenci.html",
"en": "https://www.getresponse.com/help/inactive-subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/neaktivnye-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/sottoscrittori-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailCampaign = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/email-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-kampaniya.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-d-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailAdvertising = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/email-advertising.html",
"pl": "https://www.getresponse.pl/pomoc/reklama-mailowa.html",
"en": "https://www.getresponse.com/help/email-advertising.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-reklama.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-par-courriel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailHeaders = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/email-headers.html",
"pl": "https://www.getresponse.pl/pomoc/naglowki-emaila.html",
"en": "https://www.getresponse.com/help/email-headers.html",
"es": "https://www.getresponse.com/es/ayuda/encabezados-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/zagolovki-elektronnoi-pochti.html",
"it": "https://www.getresponse.com/it/assistenza/intestazioni-e-mail-e-mail-headers.html",
"br": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/en-tetes-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailClient = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/email-client.html",
"pl": "https://www.getresponse.pl/pomoc/klient-poczty-elektronicznej.html",
"en": "https://www.getresponse.com/help/email-client.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-klient.html",
"it": "https://www.getresponse.com/it/assistenza/cliente-di-posta-elettronica.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"es": "https://www.getresponse.com/es/ayuda/cliente-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/client-de-messagerie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailPromotion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/email-promotion.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-emailowa.html",
"en": "https://www.getresponse.com/help/email-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-por-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-par-courrier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailing = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/emailing.html",
"pl": "https://www.getresponse.pl/pomoc/emailing.html",
"en": "https://www.getresponse.com/help/emailing.html",
"es": "https://www.getresponse.com/es/ayuda/enviando-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpravka-po-elektronnoj-pochte.html",
"it": "https://www.getresponse.com/it/assistenza/invio-di-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/l-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFooter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/footer.html",
"pl": "https://www.getresponse.pl/pomoc/stopka-maila.html",
"en": "https://www.getresponse.com/help/footer.html",
"es": "https://www.getresponse.com/es/ayuda/pie-de-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/futer.html",
"it": "https://www.getresponse.com/it/assistenza/pie-di-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/rodape.html",
"fr": "https://www.getresponse.com/fr/aide/pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFileAttachment = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/file-attachment.html",
"pl": "https://www.getresponse.pl/pomoc/zalacznik.html",
"en": "https://www.getresponse.com/help/file-attachment.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-adjunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/fajl-vlozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/file-allegato.html",
"br": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pt": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-joint.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDeliverabilityRate = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/deliverability-rate.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-dostarczalnosci.html",
"en": "https://www.getresponse.com/help/deliverability-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/uroven-dostavlyaemosti.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-consegna.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDedicatedServer = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/dedicated-server.html",
"pl": "https://www.getresponse.pl/pomoc/serwer-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-server.html",
"es": "https://www.getresponse.com/es/ayuda/servidor-dedicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/vydelennyj-server.html",
"it": "https://www.getresponse.com/it/assistenza/server-dedicato.html",
"br": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"fr": "https://www.getresponse.com/fr/aide/serveur-dedie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCustomField = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/custom-field.html",
"en": "https://www.getresponse.com/help/custom-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemoe-pole.html",
"it": "https://www.getresponse.com/it/assistenza/campo-personalizzato.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/pole-dodatkowe-2.html",
"fr": "https://www.getresponse.com/fr/aide/champ-personnalise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDelivered = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/delivered.html",
"pl": "https://www.getresponse.pl/pomoc/dostarczona.html",
"en": "https://www.getresponse.com/help/delivered.html",
"es": "https://www.getresponse.com/es/ayuda/entregado.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-dostavleno.html",
"it": "https://www.getresponse.com/it/assistenza/consegnato.html",
"br": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pt": "https://www.getresponse.com/pt/ajuda/entregue.html",
"fr": "https://www.getresponse.com/fr/aide/livre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDynamicContent = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/dynamic-content.html",
"pl": "https://www.getresponse.pl/pomoc/dynamic-content.html",
"en": "https://www.getresponse.com/help/dynamic-content.html",
"es": "https://www.getresponse.com/es/ayuda/contenido-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/dinamicheskij-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/contenuti-dinamici.html",
"br": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDomainName = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/domain-name.html",
"pl": "https://www.getresponse.pl/pomoc/nazwa-domeny.html",
"es": "https://www.getresponse.com/es/ayuda/nombre-de-dominio.html",
"ru": "https://www.getresponse.com/ru/help-ru/domennoe-imya.html",
"it": "https://www.getresponse.com/it/assistenza/nome-di-dominio.html",
"br": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBulkEmail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bulk-email.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-masowa.html",
"en": "https://www.getresponse.com/help/bulk-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-masivo.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovaya-rassylka.html",
"it": "https://www.getresponse.com/it/assistenza/email-di-massa.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"fr": "https://www.getresponse.com/fr/aide/envoi-massif-de-courriels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClickTracking = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/click-tracking.html",
"pl": "https://www.getresponse.pl/pomoc/sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/click-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/otslezhivanie-po-kliku.html",
"it": "https://www.getresponse.com/it/assistenza/tracciamento-dei-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClickThroughRate = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/click-through-rate.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-klikniec.html",
"en": "https://www.getresponse.com/help/click-through-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/koefficient-klikov-ctr.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pt": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampaignDefinition = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/campaign-definition.html",
"pl": "https://www.getresponse.pl/pomoc/kampania.html",
"en": "https://www.getresponse.com/help/marketing-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campaign-definition.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaja-kampania.html",
"it": "https://www.getresponse.com/it/assistenza/campagna.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConfirmedOptIn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/confirmed-opt-in.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-z-potwierdzeniem.html",
"en": "https://www.getresponse.com/help/confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/consentimiento-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdennaia-podpiska.html",
"it": "https://www.getresponse.com/it/assistenza/doppio-opt-in.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConfirmationMessage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/confirmation-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-potwierdzajaca-subskrypcje.html",
"en": "https://www.getresponse.com/help/confirmation-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-confirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-conferma.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCharacterEncoding = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/character-encoding.html",
"pl": "https://www.getresponse.pl/pomoc/kodowanie-znakow.html",
"en": "https://www.getresponse.com/help/character-encoding.html",
"ru": "https://www.getresponse.com/ru/help-ru/kodirovka-simvolov.html",
"it": "https://www.getresponse.com/it/assistenza/codifica-dei-caratteri.html",
"br": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"pt": "https://www.getresponse.com/pt/ajuda/codificacao-de-caracteres.html",
"es": "https://www.getresponse.com/es/ayuda/codificacion-de-caracteres.html",
"fr": "https://www.getresponse.com/fr/aide/codage-des-caracteres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCaptchaDefinition = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/captcha-definition.html",
"pl": "https://www.getresponse.pl/pomoc/captcha.html",
"en": "https://www.getresponse.com/help/captcha.html",
"es": "https://www.getresponse.com/es/ayuda/captcha.html",
"ru": "https://www.getresponse.com/ru/help-ru/captcha.html",
"it": "https://www.getresponse.com/it/assistenza/captcha.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-de-captcha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-de-captcha.html",
"fr": "https://www.getresponse.com/fr/aide/captcha.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanSpamAct = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/can-spam-act.html",
"pl": "https://www.getresponse.pl/pomoc/ustawa-can-spam.html",
"en": "https://www.getresponse.com/help/can-spam-act.html",
"es": "https://www.getresponse.com/es/ayuda/ley-can-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zakon-can-spam.html",
"it": "https://www.getresponse.com/it/assistenza/legge-can-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"fr": "https://www.getresponse.com/fr/aide/loi-can-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAttachment = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/attachment.html",
"en": "https://www.getresponse.com/help/attachment.html",
"es": "https://www.getresponse.com/es/ayuda/anexo.html",
"ru": "https://www.getresponse.com/ru/help-ru/vlozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/allegato.html",
"br": "https://www.getresponse.com/pt/ajuda/anexo.html",
"pt": "https://www.getresponse.com/pt/ajuda/anexo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlankEmail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/blank-email.html",
"pl": "https://www.getresponse.pl/pomoc/pusty-email.html",
"en": "https://www.getresponse.com/help/blank-email.html",
"es": "https://www.getresponse.com/es/ayuda/email-en-blanco.html",
"ru": "https://www.getresponse.com/ru/help-ru/pustoe-elektronnoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/email-vuouta.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"fr": "https://www.getresponse.com/fr/aide/email-vide.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBehavioralTargeting = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/behavioral-targeting.html",
"pl": "https://www.getresponse.pl/pomoc/targetowanie-behawioralne.html",
"en": "https://www.getresponse.com/help/behavioral-targeting.html",
"es": "https://www.getresponse.com/es/ayuda/orientacion-del-comportamiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/povedencheskij-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-comportamentale.html",
"br": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pt": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"fr": "https://www.getresponse.com/fr/aide/ciblage-comportemental.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlocking = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/blocking.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie.html",
"en": "https://www.getresponse.com/help/blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka.html",
"it": "https://www.getresponse.com/it/assistenza/blocco.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"fr": "https://www.getresponse.com/fr/aide/blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBounced = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bounced.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot.html",
"en": "https://www.getresponse.com/help/bounced.html",
"es": "https://www.getresponse.com/es/ayuda/rebotados.html",
"ru": "https://www.getresponse.com/ru/help-ru/oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"fr": "https://www.getresponse.com/fr/aide/rebondi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateProgram = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-program.html",
"pl": "https://www.getresponse.pl/pomoc/program-afiliacyjny.html",
"en": "https://www.getresponse.com/help/affiliate-program.html",
"es": "https://www.getresponse.com/es/ayuda/programa-de-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskaya-programma.html",
"it": "https://www.getresponse.com/it/assistenza/programma-di-affiliazione.html",
"br": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/programme-d-affiliation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenSalesFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarVariosCodigosDeRastreamentoAMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontroAChaveDaApi = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenLeadFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenCnameEintragZuMeinerSubdomainHinzufuegen2 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen-2.html",
"en": "https://www.getresponse.com/help/how-to-add-a-cname-entry-to-my-subdomain-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-dlya-subdomena-dlya-lendingov-sozannych-v-max-akkynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-record-cname-al-mio-sottodominio-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-entrada-cname-a-mi-subdominio-para-las-paginas-de-destino-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-enregistrement-cname-a-mon-sous-domaine-pour-les-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieDnsEinstellungen2 = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen-2.html",
"en": "https://www.getresponse.com/help/how-to-change-the-dns-settings-for-landing-pages-created-in-a-max-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-dns-nastroiki-dlya-lendingov-sozadannych-v-max-akkaynte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-do-dns-para-as-landing-pages-criadas-em-uma-conta-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-modificare-le-impostazioni-dns-per-le-landing-page-create-in-un-account-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns-landing-page-stworzonego-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambiar-la-configuracion-dns-para-landing-pages-creadas-en-una-cuenta-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns-des-pages-de-destination-creees-dans-un-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIchHabeEinMaxKontoWasSindDieErstenSchritte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ich-habe-ein-max-konto-was-sind-die-ersten-schritte.html",
"en": "https://www.getresponse.com/help/i-have-a-max-account-how-do-i-get-started.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-uma-conta-max-como-posso-comecar.html",
"ru": "https://www.getresponse.com/ru/help-ru/y-menya-est-akkaynt-max-kak-mne-nachat.html",
"it": "https://www.getresponse.com/it/assistenza/ho-un-account-max-come-faccio-ad-inizio.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-una-cuenta-max-como-puedo-empezar.html",
"pl": "https://www.getresponse.pl/pomoc/mam-konto-max-od-czego-zaczac.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-un-compte-max-comment-commencer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchWeitereKontenInMeinemMaxKontoEinrichten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-weitere-konten-in-meinem-max-konto-einrichten.html",
"en": "https://www.getresponse.com/help/can-i-create-additional-accounts-in-my-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-contas-adicionais-em-minha-conta-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-sozdat-dopolnitelnye-akkaynu-v-svoem-max-akkaynte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-tworzyc-dodatkowe-konta-w-koncie-max.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-cuentas-adicionales-en-mi-cuenta-max.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-account-aggiuntivi-nel-mio-account-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-comptes-supplementaires-dans-mon-compte-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchDieBedingungEreignisInMeinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoPermitirQueOsParticipantesParticipemDeUmWebinarDiretamenteDaMinhaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCanceloAMinhaConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueAConformidadeLegalEImportante = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"en": "https://www.getresponse.com/help/why-is-legal-compliance-important.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-die-einhaltung-gesetzlicher-vorschriften-wichtig.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-importante-el-cumplimiento-legal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-vazno-sootvetstvie-trebovaniyam-zakonodatelstva.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nalezy-przestrzegac-przepisow-prawnych.html",
"it": "https://www.getresponse.com/it/assistenza/perche-e-importante-la-conformita-legale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionamOsConectoresSimNao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAVisaoGlobalEComoPossoUsaLa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisTiposDeArquivosPossoEnviarParaUmWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaoBomESeuIndiceDeEntregaDeEMail = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoRejeicoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAcompanharOsContatosInclusosEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNaoConsigoPublicarMeuFluxoDeTrabalhoOQueDevoFazer = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html",
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSouUmDesenvolvedorOndePossoEncontrarInformacoesSobreAApi = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html",
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueOsProvedoresDeEMailTemReconhecimentosDiferentesDeMensagensDeSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarCookiesAMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoOsNumerosEmCimaEmBaixoENoCantoSuperiorDireitoDeCadaElementoDoMeuFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarDominiosSubdominiosEDiretoriosParaMinhasLandingPagesLegadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoTestarAGetresponseAntesDeAdquirirUmPlano = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueRecursosAsLandingPagesLegadasOferecem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmNovoEnderecoDeRemetenteENomeParaMinhasMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoGerencioDominiosParaMinhasLandingPagesLegadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoExecutarAcoesECondicoesEspecificasVariasVezes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontroAsEstatisticasDoMeuWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueESpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"en": "https://www.getresponse.com/help/what-is-spam.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantosDestinatariosDevoIncluirNoMeuGrupoDeTeste = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoVerificarComoAMensagemVaiFicarEmUmaCaixaDeEntradaAntesDeEnviaLa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaAlterarUmaPaginaDeAgradecimentoEUmUrlJaInscrito = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaVerificarAsEstatisticasDosConvitesELembretesDoWebinarQueEnviei = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarMensagensComOrientacaoDeTextoDaDireitaParaAEsquerda = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCopiarContatosParaOutraLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoVerAsEstatisticasDeUmaListaOuMensagemEspecifica = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstalarUmFormularioLegadoNoMeuSite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-formularz-legacy-na-swojej-stronie.html",
"en": "https://www.getresponse.com/help/how-do-i-install-a-legacy-form-on-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-ein-legacy-webformular-auf-meiner-seite.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-un-formulario-de-legado-en-mi-pagina-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-redaktora-legacy-na-svoey-stranitse.html",
"it": "https://www.getresponse.com/it/assistenza/come-installo-un-modulo-sulla-mia-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaAdicionarContatosAUmaListaDepoisQueElesConcluemUmaTransacaoDoPaypal = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontroTemplatesParaMensagensHtml = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/where-can-i-find-html-message-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-vorlagen-fuer-html-nachrichten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-modelos-de-mensajes-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodatsa-html-shablony-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-di-messaggi-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarAVisualizacaoDeCalendarioParaAutorrespostas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoMaximizarAPrecisaoDaValidacaoTelefonicaApi = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueAlgunsEMailsAparecemComoEmNomeDeOuVia = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoExportoOHistoricoDeBatePapoDoWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaRastrearAsAberturasDeMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSynchronisiereIchSalesforceUndGetresponseKontaktdaten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchVorlagenMiteinanderVerknuepfen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSucheIchNachKontakten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieLinkGeklicktBedingung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchInaktiveKontakte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEinWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDoppelteKontakteInEinemWorkflowFiltern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerschiebeOderKopiereIchKontakteZwischenWorkflows = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDerFilterAnzahl = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerbindeIchPaypalMitGetresponseUndVerwendeButtonsInMeinenNachrichtenUndAufLandingPages = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieMarketingautomatisierungsnachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieWartenAktion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchKontakteZuEinemWorkflowHinzufuegenDieInEineAndereListeKopiertOderVerschobenWurden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieBedingungNachrichtGesendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVocesOferecemUmCodigoDeFormularioHtmlSimples = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieBegruessungVorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchDenEinwilligungsstatusFilter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMitPaypalJetztKaufenButtonsNeueKontakteInVerschiedeneListenEintragen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-paypal-jetzt-kaufen-buttons-neue-kontakte-in-verschiedene-listen-eintragen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-kontakty-do-roznych-list-przy-uzyciu-przyciskow-paypal-kup-teraz.html",
"en": "https://www.getresponse.com/help/can-i-add-new-contacts-to-different-lists-with-paypal-buy-now-buttons.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-nuevos-contactos-a-listas-diferentes-con-los-botones-comprar-ahora-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavliyat-novye-kontakty-v-raznye-spiski-s-pomoshiyu-knopki-paypal-kypit-sejchas.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-de-nouveaux-contacts-a-differentes-listes-avec-les-boutons-paypal-acheter-maintenant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieBedingungLandingPageBesuchtInEinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumKannIchNurBestimmteNachrichtenUnterDenBedingungenNachrichtGeoeffnetUndLinkAngeklicktSehen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEineNachrichtBearbeitenDieInEinemWorkflowVerwendetWird = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieBedingungEinwilligungsstatusAktualisiert = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineIbanBankverbindungAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-iban-bankverbindung-aendern.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontrarORecursoDeTesteAB = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheAnmeldeeinstellungenKannIchInEinenWorkflowEinbinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostApiKey = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/api-key.html",
"pl": "https://www.getresponse.pl/pomoc/klucz-api.html",
"en": "https://www.getresponse.com/help/api-key.html",
"es": "https://www.getresponse.com/es/ayuda/clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/api-kljuch.html",
"it": "https://www.getresponse.com/it/assistenza/chiave-api.html",
"br": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"fr": "https://www.getresponse.com/fr/aide/cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSendeIchKundenProduktempfehlungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoDefinirPropriedadesParaACondicaoDeUrlVisitada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarAAcaoDeClassificacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaOFiltroDeIntervalo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoRemoverContatosComoResultadoDoFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarCamposPersonalizadosNaAutomacaoDeMarketing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoDefinirOMomentoEmQueMeuFluxoDeTrabalhoComecaETermina = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrarMinhaAplicacaoComGetresponseUsandoApi = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaADuplicacaoDeFluxosDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaMoverOuCopiarContatosEntreFluxosDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIspolzovatSvoiChernovikiIliRassylkiVKachestveAvtootvetchika = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-chernoviki-ili-rassylki-v-kachestve-avtootvetchika.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-entwurf-oder-newsletter-als-autoresponder-verwenden.html",
"en": "https://www.getresponse.com/help/can-i-use-my-draft-or-newsletter-as-an-autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-wersje-robocza-wiadomosci-lub-newsletter-jako-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-la-mia-bozza-o-newsletter-come-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-borrador-o-newsletter-como-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReconquiste = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"en": "https://www.getresponse.com/help/win-back.html",
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html",
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEstaInclusoNosRecursosDeAcompanhamentoDeECommerceETrafegoDeWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBoasVindasAosNovosClientes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReconquistaDeCliente = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoOsWebinarsDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRemoverUmTrackingCodeDaMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoReclamacoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAcionarMeuFormularioPopOverLegadoComUmBotaoOuLink = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAlterarAsConfiguracoesDeDns = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarACondicaoSeAClassificacaoEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoDefinirOMeuFormularioPopOverLegadoParaAparecerNaMinhaPagina = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAutomacaoDeMarketingEOQuePossoFazerComEla = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAGetresponseOfereceUmAplicativoMovel = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearAConversaoDoGoogleAdsNaMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNaoDevoUsarOEMailDoYahooAolMailRuComoMeuEnderecoDeRemetente = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMinhaContaPodeSerFechadaPorCausaDeReclamacoesDeSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuantoCustaAAutomatizacaoDeMarketing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegrarMinhasLandingPagesLegadasAoGoogleTagManager = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionoCamposPersonalizadosAoMeuFormularioLegado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoDeixarMeusParticipantesSeComunicaremComigoDuranteOWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pozwolic-uczestnikom-webinaru-komunikowac-sie-ze-mna.html",
"en": "https://www.getresponse.com/help/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-teilnehmer-waehrend-des-webinars-mit-mir-kommunizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-permitir-a-mis-participantes-comunicarse-conmigo-durante-el-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mozhno-vzaimodejstvovat-so-zritelami-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-permettre-a-mes-participants-de-communiquer-avec-moi-pendant-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualADiferencaEntreUmaNewsletterEUmaMensagemDeAutorresposta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAReputacaoDoCampoDeRemetenteInfluenciaMinhaCapacidadeDeEntregaNaCaixaDeEntradaVsCaixaDeSpam = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoDefinoPropriedadesParaACondicaoDeCompra = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoAsMelhoresPraticasDeEMailQueInfluenciamAEntrega = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieZeiteinstellungenInDenEigenschaftenDerBedingungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindAktionenBedingungenUndFilter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieBedingungBenutzerdefiniertesFeldWurdeGeaendert = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineZohocrmKundenUndInteressentenInMeineKontaktlisteImportieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheWorkflowVorlagenSindInMeinemPlanEnthalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchDasSepaLastschriftmandat = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-das-sepa-lastschriftmandat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieBedingungAbrechnungsstatusGeaendert = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDerFilterSplitter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchMeineElementeImNeuenNachrichtenEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEinHintergrundbildInEineNachrichtEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindSektionenImNachrichtenEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchBenutzerdefiniertenHtmlCodeInEinerNachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindDieAnforderungenFuerDieFusszeile = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheBreiteSollteMeineNachrichtHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-breite-sollte-meine-nachricht-haben.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-szerokosc-powinna-miec-moja-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-wide-should-my-message-be.html",
"ru": "https://www.getresponse.com/ru/help-ru/naskolko-shirokim-mozhet-byt-moje-soobshenie.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-dovrebbe-essere-largo-il-mio-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-ancho-debe-ser-mi-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-doit-etre-la-largeur-de-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguUznatKakimObrazomMoiKontaktyByliDobavlenyVKonkretnyjSpisok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uznat-kakim-obrazom-moi-kontakty-byli-dobavleny-v-konkretnyj-spisok.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-dowiem-sie-jak-kontakty-zostaly-dodane-do-konkretnej-listy.html",
"en": "https://www.getresponse.com/help/where-can-i-find-out-how-my-contacts-were-added-to-a-specific-list.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-heraus-wie-meine-kontakte-zu-einer-bestimmten-liste-hinzugefuegt-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-saber-como-fueron-anadidos-mis-contactos-a-una-lista-especifica.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-scoprire-come-sono-stati-aggiunti-i-miei-contatti-a-una-lista-specifica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineNachrichtInDerMarketingautomatisierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasBietenDieECommerceUndWebtrafficTrackingfunktionen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMitDenElementenImWorkflowArbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchBenutzerdefinierteFelderInEinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchDieAktionKontaktEntfernenImWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiePlatziereIchDenJavascriptCodeInMeinerWebsite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieAktionTagInEinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDerFilterBereich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchDieAktionPunktestandInEinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchDieBedingungBesuchteUrlEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinenWorkflowMitMehrerenBedingungenStarten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchDieBedingungWennTagInEinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchDieBedingungWennPunktestand = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDieMarketingautomatisierungUndWieKannIchSieFuerMichNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchKontakteInMeineListeEinNachdemSieEineZahlungUeberPaypalGeleistetHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieBedingungNachrichtGeoeffnet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKopiereIchKontakteInEineAndereListe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchBestimmteAktionenUndBedingungenWiederkehrendAusfuehren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerStehenDieZahlenAmUnterenEndeJedesElementsInMeinemWorkflow = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieKontakteInEinemWorkflowVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktionierenDieJaNeinVerbindungsstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchDieBedingungAbgebrochenerWarenkorbEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDeCarrinhoAbandonado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguNastroitSwojePismoPodtverzhdenijaPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarTemplatesParaCriarFluxosDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoCodificarNewslettersEmHtml = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAtualizarMinhaContaGratuita = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaUmaMensagemDeAutomacaoDeMarketing = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRemoverOuEditarOsLinksDeCancelamentoDeInscricaoEAtualizacaoNaParteInferiorDasMinhasMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarRssParaEmail = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoDescubroQualVersaoDoFormularioLegadoOfereceMeAMaiorTaxaDeConversao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sprawdzic-ktora-wersja-formularza-legacy-zapewnia-mi-najlepsza-konwersje.html",
"en": "https://www.getresponse.com/help/how-do-i-find-out-which-version-of-the-legacy-form-gives-me-the-highest-conversion-rate.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-heraus-welches-legacy-webformular-mir-die-hoechste-conversion-rate-beschert.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-saber-que-version-del-formulario-de-legado-me-da-la-tasa-de-conversion-mas-alta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-kakaya-versiya-formyi-legacy-imeet-naibolshii-pokazatel-konversii.html",
"fr": "https://www.getresponse.com/fr/aide/comment-savoir-quelle-version-de-mon-ancien-formulaire-me-donne-le-taux-de-conversion-le-plus-eleve.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsarUmaImagemComoFundoNoFormularioLegado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarMinhaLandingPageAoWordpress = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoFiltrarContatosDuplicadosEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoEncaminharDadosDoAssinanteParaUmaPaginaDeAgradecimentoPersonalizada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarOFiltroStatusDeConsentimento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDeQualificacaoDeLead = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDeWebinarsEEventos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDePromocoesDeVendas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDePosCompra = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDeCursosOnLine = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDeEnvolvimentoERetencao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSaoUsadosOsTemplatesDeBoasVindas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarContatosEmUmaDasMinhasListasUsandoAplicativosMoveis = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchGrundelementeImNachrichtenEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBereiteIchEineDateiFuerDenImportViaFtpVor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWannImportiereIchDateienViaFtp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieImportiereIchDateienViaFtp = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstGetresponseMax = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieImportiereIchEineListeInMeinGetresponseMaxKonto = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinenEntwurfOderNewsletterAlsAutoresponderVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-entwurf-oder-newsletter-als-autoresponder-verwenden.html",
"en": "https://www.getresponse.com/help/can-i-use-my-draft-or-newsletter-as-an-autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-chernoviki-ili-rassylki-v-kachestve-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-wersje-robocza-wiadomosci-lub-newsletter-jako-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-la-mia-bozza-o-newsletter-come-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-borrador-o-newsletter-como-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchMeineNachrichtenentwuerfe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-nachrichtenentwuerfe.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-message-drafts.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-bozze-dei-miei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mis-borradores-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nachodjatsa-chernoviki-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-wersje-robocze-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindGetresponseWebinare = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKoennenWebinareMirHelfenMeineKontaktlisteAufzubauen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinWebinar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchWebinarEinladungenAnKontakteSendenDieNichtInMeinenListenGespeichertSind = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannEinTeilnehmerSichDirektAufMeinerLandingPageFuerEinWebinarAnmelden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieExportiereIchDenWebinarChatverlauf = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieUeberpruefeIchDieStatistikenFuerDieEinladungenUndErinnerungenDieIchFuerMeineWebinareGesendetHabe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchDieWebinarStatistik = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinWebinarLiveAufFacebookUndYoutubeStreamen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchDasLayoutMeinesWebinarraumsSpeichern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKoennenMeineTeilnehmerWaehrendDesWebinarsMitMirKommunizieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-teilnehmer-waehrend-des-webinars-mit-mir-kommunizieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pozwolic-uczestnikom-webinaru-komunikowac-sie-ze-mna.html",
"en": "https://www.getresponse.com/help/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-permitir-a-mis-participantes-comunicarse-conmigo-durante-el-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mozhno-vzaimodejstvovat-so-zritelami-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-permettre-a-mes-participants-de-communiquer-avec-moi-pendant-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheDateitypenKannIchInEinWebinarHochladen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannEsMehrModeratorenAlsNurMichGeben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html",
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindOnDemandWebinareUndWieNutzeIchSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeinWebinarAlsVideoAufnehmen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineProduktdatenInGetresponseHochladen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchHerausWieMeineKontakteZuEinerBestimmtenListeHinzugefuegtWurden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-heraus-wie-meine-kontakte-zu-einer-bestimmten-liste-hinzugefuegt-wurden.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-dowiem-sie-jak-kontakty-zostaly-dodane-do-konkretnej-listy.html",
"en": "https://www.getresponse.com/help/where-can-i-find-out-how-my-contacts-were-added-to-a-specific-list.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-saber-como-fueron-anadidos-mis-contactos-a-una-lista-especifica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uznat-kakim-obrazom-moi-kontakty-byli-dobavleny-v-konkretnyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-scoprire-come-sono-stati-aggiunti-i-miei-contatti-a-una-lista-specifica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAumentarAVisibilidadeDaMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarAAcaoTagEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaOFiltroDeListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasBedeutetTrackingDerOeffnungsrate = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchDieStatistikenFuerEineBestimmteListeOderNachrichtEinsehen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieOeffnungsrateFuerKlartextNewsletterTracken = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEsSindKeineStatistikenFuerOeffnungsratenVerfuegbarWoranKoennteDasLiegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html",
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumWirdFuerMeineNachrichtEineOeffnungsrateVon0Angezeigt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html",
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSindDieGetresponseBestimmungenFuerRueckerstattungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLoescheIchMeinKonto = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDasZusaetzlicheAuthentifizierungsverfahrenFuerZahlungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIchHabeMeinAboGekuendigtWarumSeheImmerNochAbbuchungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenTransaktionsfehlerBeiKreditkartenzahlungBeheben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasPassiertWennIchMeinListengroessenlimitUeberschreite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineZahlungsmethodeAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarNovosContatosADiferentesListasComOsBotoesComprarAgoraDoPaypal = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-kontakty-do-roznych-list-przy-uzyciu-przyciskow-paypal-kup-teraz.html",
"en": "https://www.getresponse.com/help/can-i-add-new-contacts-to-different-lists-with-paypal-buy-now-buttons.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-paypal-jetzt-kaufen-buttons-neue-kontakte-in-verschiedene-listen-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-nuevos-contactos-a-listas-diferentes-con-los-botones-comprar-ahora-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavliyat-novye-kontakty-v-raznye-spiski-s-pomoshiyu-knopki-paypal-kypit-sejchas.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-de-nouveaux-contacts-a-differentes-listes-avec-les-boutons-paypal-acheter-maintenant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDentreQuaisTiposDeTesteABPossoEscolher = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineRechnungenHerunterladen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieEntferneIchEineKreditkarteAusMeinemKundenkonto = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineNeueKreditkarteFuerZahlungenHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinGewaehltesPaketAendern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheZahlungsmethodenWerdenAkzeptiert = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html",
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVielKostetGetresponseMonatlich = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinFreeAccountUpgraden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDiePreisgestaltungInGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNaoDevoVoltarAAdicionarEMailsRejeitadosAMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualEADiferencaEntreUmFormularioEUmaLandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmFormularioLegado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoTrabalhoComElementosEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoGravarOMeuWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindWebseitenBesucherInFacebookAds = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAsPessoasPodemCompartilharAsMinhasNewslettersEmRedesSociais = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRastrearAsMinhasCampanhasDoFacebookELandingPagesLegadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAsMinhasEstatisticasDeIndiceDeAberturaNaoEstaoDisponiveisOQuePodeEstarCausandoIsso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html",
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoOsMetodosDePagamentoAceitos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html",
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoColocoOCodigoDeAcompanhamentoJavascriptNoMeuWebsite = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBoasVindasAosNovosContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocaoDeWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru.html",
"en": "https://www.getresponse.com/help/webinar-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/webinar-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenije-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecompensaParaClientesFidelizados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html",
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensagemSimplesDeBoasVindas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVendasAdicionais = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"en": "https://www.getresponse.com/help/upselling.html",
"pl": "https://www.getresponse.pl/pomoc/upselling.html",
"de": "https://www.getresponse.com/de/hilfe/upselling.html",
"es": "https://www.getresponse.com/es/ayuda/ventas-adicionales-upselling.html",
"ru": "https://www.getresponse.com/ru/help-ru/doprodazha.html",
"it": "https://www.getresponse.com/it/assistenza/upselling.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-incitatives.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFollowUpAposCompra = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFollowUpAposWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLiquidacaoAntecipada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReconquistaDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversaoDeContatosEmClientes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimpezaDeListaAtravesDeTagsEClassificacoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanoBasicoDeColocacaoDeTags = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaAutorresposta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoOsWebinarsPodemMeAjudarACriarMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarContatosQueForamCopiadosOuMovidosParaUmaListaDiferenteAUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoAAcaoEsperar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPersonalizarOsMeusEMails = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannMeinKontoAufgrundVonSpamBeschwerdenGeschlossenWerden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoSalvarOLayoutDaSalaDeWebinarEOWhiteboard = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstSpam = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"en": "https://www.getresponse.com/help/what-is-spam.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaIntegrarOPaypalAoGetresponseEAdicionarBotoesAMensagensELandingPage = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVersendeIchDmarcKonformeEMails = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchSicherstellenDassIchDieCaslBestimmungenEinhalte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html",
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasBedeutetEsDassMeinKontoUeberprueftWird = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEinenMitarbeiterHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheRollenKannIchMitarbeiternZuweisen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html",
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheBrowserSindMitGetresponseKompatibel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumKannIchMichNichtEinloggen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineStartseiteMithilfeVonWidgetsAnpassen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumWirdDieGetresponseBadgeInMeinenEMailsAngezeigt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineNeueAbsenderadresseUndNamenInMeineNachrichtEinfuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchMeinPasswort = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchMeineKontoeinstellungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBietetGetresponseAuchEinKostenlosesKontoAn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBereiteIchMeinKontoAufDieDsgvoVor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleUndNutzeIchEinwilligungsfelder = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFunktionierenWebinareAufMobilgeraeten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html",
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensagemDeBoasVindasBaseadaEmConsentimento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"en": "https://www.getresponse.com/help/consent-based-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-powitalna-zalezna-od-udzielonych-zgod.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-basato-su-consenso.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-willkommensnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-na-osnove-soglasija.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-base-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoUnaLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoSegmentarMisContactosEnFuncionDeLosDatosDeECommerce = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtribuirMeuProprioDominioOuSubdominioAUmaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodeHaverMaisApresentadoresDoQueApenasEu = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html",
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSignificaQueAMinhaContaEstaSendoVerificada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeVerEGerenciarMinhasListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarUmCodigoCustomizadoEmMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAssegnareTagEPunteggiDuranteLImportazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-assegnare-tag-e-punteggi-durante-l-importazione.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereLaMiaLandingPageAWordpressLegacy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDovePossoAssegnareTagEPunteggiAiContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAInserireEmailEDominiNellaBlocklistDalleMieListeODalMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoOtimizarMinhaLandingPageLegadaParaDispositivosMoveis = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisConfiguracoesDeAssinaturaPossoIncluirEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEncontrarOsMeusCabecalhosDeMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html",
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCriarEUsarOsCamposDeConsentimento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElEmbudoDeConversion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAggiungereIClientiWoocommerceSuGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAInstallareUnCertificatoSslPerIMieiDominiPersonalizzati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACollegareIlMioNegozioOnlineAGetresponse = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUmContatoPodeSairDaMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAPersonalizzareLeMieEmail = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaACondicaoDeMensagemEnviada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlgunsElementosNoMeuFluxoDeTrabalhoEstaoEsmaecidosPorQue = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pt": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-elementy-mojego-mojego-cyklu-sa-wyszarzone.html",
"en": "https://www.getresponse.com/help/some-elements-in-my-workflow-are-grayed-out-why.html",
"de": "https://www.getresponse.com/de/hilfe/manche-elemente-meines-workflows-sind-ausgegraut-warum.html",
"es": "https://www.getresponse.com/es/ayuda/algunos-elementos-de-mi-flujo-de-trabajo-estan-en-gris-por-que.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-nekotorye-elementy-v-mojom-processe-vydeleny-serym-cvetom.html",
"it": "https://www.getresponse.com/it/assistenza/alcuni-elementi-nel-automation-workflow-sono-disattivati-perche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmVideoAMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMichInDerSmartphoneAppMitMagicLinkAnmelden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteMithilfeDerSmartphoneAppZuEinerMeinerListenHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindUnterdrueckungslistenUndWieWerdenSieVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnModulo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchMeineListenFindenUndDieEinstellungenBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieEntferneIchEineKontaktliste = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineBestaetigungsnachrichtAnpassen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEinSegment = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchEineListeMitAllMeinenKontakten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchKontaktdetailsEinsehen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteAusEinerListeInEineAndereKopierenOderVerschieben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteManuellAusMeinerListeEntfernen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteExportieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteAusEinerListeOderEinemKontoLoeschen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFindeIchDieKontakteDieMeineNachrichtenNichtGeoeffnetHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteAnhandDerAnmeldemethodeFinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmaAutorresposta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerAktivitaetsindexUndWieVerwendeIchIhn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieEntferneIchDuplikateAusMeinenListen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiePraktiziereIchListenhygiene = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieMacheIchEineVeralteteListeGebrauchsfaehig = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEMailsUndDomainsAusMeinenListenOderMeinemKontoAufDieBlacklistSetzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineListeMitKontaktenImportieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieBereiteIchEineDateiFuerDenImportVor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieWeiseIchWaehrendEinesImportsBenutzerdefinierteFelderZu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchRollenEMailadressenZuMeinenKontaktlistenHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheArtenVonBenutzerdefiniertenFeldernKannIchNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchLinksZuBenutzerdefiniertenFeldernHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleUndNutzeIchBenutzerdefinierteFelder = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDieAutomatischeTelefonvalidierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchMeineImportStatistiken = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasBedeutetEsDassMeinImportEinListenSharingProblemHat = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasBedeutetEsDassMeinImportGegenDieRichtlinienVerstossenHat = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchScoring = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchTagsUndScoringMittelsApiVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchTags = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchMeinenKontaktenScoringPunkteUndTagsZuordnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheBedingungenKannIchBeiDerSucheNachKontaktenUndBeimErstellenVonSegmentenVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEORastreamentoDaTaxaDeAbertura = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueERastreamentoDeCliques = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoDescobrirComoMeusContatosEntraramEmUmaListaEspecifica = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-dowiem-sie-jak-kontakty-zostaly-dodane-do-konkretnej-listy.html",
"en": "https://www.getresponse.com/help/where-can-i-find-out-how-my-contacts-were-added-to-a-specific-list.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-heraus-wie-meine-kontakte-zu-einer-bestimmten-liste-hinzugefuegt-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-saber-como-fueron-anadidos-mis-contactos-a-una-lista-especifica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uznat-kakim-obrazom-moi-kontakty-byli-dobavleny-v-konkretnyj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-scoprire-come-sono-stati-aggiunti-i-miei-contatti-a-una-lista-specifica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIchHabeEineAbobenachrichtigungErhaltenKannDenKontaktAberNichtInMeinerListeFinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ich-habe-eine-abobenachrichtigung-erhalten-kann-den-kontakt-aber-nicht-in-meiner-liste-finden.html",
"en": "https://www.getresponse.com/help/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"ru": "https://www.getresponse.com/ru/help-ru/mne-prishlo-uvedomlenije-o-tom-chto-kontakt-byl-dobavlen-v-spisok-no-ja-jego-ne-vizhu.html",
"br": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pl": "https://www.getresponse.pl/pomoc/powiadomienie-o-subskrypcji-zostalo-dostarczone-ale-kontakt-nie-jest-dodany-do-listy.html",
"it": "https://www.getresponse.com/it/assistenza/ho-ricevuto-un-e-mail-che-informa-che-un-contatto-e-stato-aggiunto-a-un-elenco-ma-non-riesco-a-visualizzarli.html",
"es": "https://www.getresponse.com/es/ayuda/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-recu-un-e-mail-indiquant-qu-un-contact-a-ete-ajoute-a-une-liste-mais-je-ne-peux-pas-le-voir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumIstMeineLegacyLandingPageNichtOnline = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarAsTags = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsarTagsEClassificacaoAtravesDeApi = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDomainsSubdomainsUndVerzeichnisseFuerMeineLegacyLandingPagesNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinenCnameEintragZuMeinerSubdomainHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineEigeneDomainOderSubdomainFuerMeineLegacyLandingPageNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoAClassificacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwalteIchDieDomainsFuerMeineLegacyLandingPages = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSignificaQueMinhaImportacaoTeveUmProblemaDeCompartilhamentoDeLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieInstalliereIchDasSslZertifikatFuerMeineBenutzerdefiniertenDomains = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaORecursoDeValidacaoAutomaticaDeTelefone = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarEUsarCamposCustomizados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarLinksAosCamposCustomizados = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieEntferneIchEinenTrackingCodeVonMeinerLegacyLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoColocarEMailsEDominiosNaListaNegraDasMinhasListasOuConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchYandexMetricaNutzenUmMeineLegacyLandingPagesZuVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConsertarUmaListaDeEMailsDefasada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoManterAHigieneDeLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineLegacyLandingPagesMitVisualWebsiteOptimizerVwoVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchMeineLegacyLandingPagesMitMixpanel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchMeineLegacyLandingPagesMitKissmetrics = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineFacebookAdsKampagnenUndLegacyLandingPagesTracken = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchMeineLegacyLandingPagesMitBingAds = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMehrereTrackingCodesZuMeinerLegacyLandingPageHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchMeineLegacyLandingPagesMitGoogleTagManager = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAlteroAQuantidadeDePessoasComAcessoAMinhaConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSignificaQueMinhaImportacaoTeveProblemasDePoliticaDeUso = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoInstalarMeuFormularioLegadoEmUmaPaginaDoWordpress = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueAconteceSeEuUltrapassarMeuLimiteDeTamanhoDeLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaOFiltroDeValor = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoConfigurarAsImportacoesRegularesParaAsMinhasListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOsWebinarsFuncionamEmDispositivosMoveis = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html",
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontroEstatisticasDeImportacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionamOsPrecosNaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmFluxoDeTrabalhoDeAutomatizacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchGoogleAnalyticsTrackingCodeAufMeinerLegacyLandingPageNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchSicherstellenDassMeineGetresponseLandingPageEinenCookieHinweisAufweist = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDiePraesenzMeinerLegacyLandingPageVerbessern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieArbeiteIchMitAbschnittenImLegacyLandingPageEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieOptimiereIchMeineLegacyLandingPagesFuerMobilgeraete = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEinVideoInMeineLegacyLandingPageEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchPopUpFormulareAufMeinerLegacyLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchBenutzerdefiniertenCodeZuMeinerLegacyLandingPageHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinenEventCountdownAufMeineLegacyLandingPageSetzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnthaeltMeineLegacyLandingPageAuchEineDankeSeite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchElementeAufMeinerLegacyLandingPageDuplizieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchMeineLegacyLandingPageEinstellungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheWerkzeugeStehenImLegacyLandingPageEditorZurVerfuegung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchBesucheUndAnmeldungenMeinerLegacyLandingPageVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSendeIchMeinenKontaktenEinEBook = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEineLegacyLandingPageVorlageSpeichernUndSpaeterVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmaEntradaCnameAoMeuSubdominio = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarOTesteABNasLandingPagesLegadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuePapeisPossoAtribuirAosColaboradores = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html",
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoListasDeSupressaoEQuandoUsaLas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanoBasicoDeClassificacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarClientesWoocommerceNaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionoVisitantesDoWordpressEmUmaListaDaGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualADiferencaEntreUmaMensagemDeTextoSimplesEUmaMensagemDeHtml = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaACondicaoLinkClicado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoEnviarConvitesViaWebinarParaAsPessoasQueNaoEstaoNaMinhaListaDeInscritos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMudarMinhaSenha = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAsMinhasNewslettersPodemSerVisualizadasOnLine = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html",
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoSalvarUmTesteAB = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoExcluirContatosDeReceberMinhaNewsletter = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsarOYandexMetricaParaRastrearMinhasLandingPagesLegadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmSegmento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmTesteAB = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCustomizarMeuPainelComWidgets = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEscolherDestinatariosDaNewsletter = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaNewsletter = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmaListaComLink = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAGetresponseEComoPossoUsaLa = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontrarEEditarMinhasConfiguracoesDeConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisCondicoesPossoUsarParaProcurarContatosECriarSegmentos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoDarAcessoAMinhaContaParaOutrasPessoas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchABTestsFuerLegacyLandingPagesEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchMeineLegacyLandingPageVorlagen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostArchivoZip = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/archivo-zip.html",
"pl": "https://www.getresponse.pl/pomoc/zip.html",
"en": "https://www.getresponse.com/help/zip-file.html",
"de": "https://www.getresponse.com/de/hilfe/zip-file.html",
"ru": "https://www.getresponse.com/ru/help-ru/zip-fajl.html",
"it": "https://www.getresponse.com/it/assistenza/zip-file.html",
"br": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"pt": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-zip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAsistente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/asistente.html",
"de": "https://www.getresponse.com/de/hilfe/wizard.html",
"en": "https://www.getresponse.com/help/wizard.html",
"ru": "https://www.getresponse.com/ru/help-ru/master.html",
"it": "https://www.getresponse.com/it/assistenza/wizard.html",
"br": "https://www.getresponse.com/pt/ajuda/wizard.html",
"pt": "https://www.getresponse.com/pt/ajuda/wizard.html",
"pl": "https://www.getresponse.pl/pomoc/wizard.html",
"fr": "https://www.getresponse.com/fr/aide/assistant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaBlanca = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/lista-blanca.html",
"pl": "https://www.getresponse.pl/pomoc/whitelist-biala-lista-subskrybentow.html",
"en": "https://www.getresponse.com/help/whitelist.html",
"de": "https://www.getresponse.com/de/hilfe/whitelist.html",
"ru": "https://www.getresponse.com/ru/help-ru/belyj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/whitelist.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"fr": "https://www.getresponse.com/fr/aide/liste-blanche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPruebasMultivariantes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/pruebas-multivariantes.html",
"pl": "https://www.getresponse.pl/pomoc/testy-skutecznosci-ang-split-test.html",
"en": "https://www.getresponse.com/help/split-test.html",
"de": "https://www.getresponse.com/de/hilfe/split-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/split-test.html",
"it": "https://www.getresponse.com/it/assistenza/split-test.html",
"br": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"fr": "https://www.getresponse.com/fr/aide/essai-fractionne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInformesDeSuscripcion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/custom-reports.html",
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuscriptores = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/suscriptores.html",
"pl": "https://www.getresponse.pl/pomoc/subskrybent.html",
"en": "https://www.getresponse.com/help/subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/subscribers.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpischiki.html",
"it": "https://www.getresponse.com/it/assistenza/contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLineaDeAsunto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/linea-de-asunto.html",
"pl": "https://www.getresponse.pl/pomoc/temat-wiadomosci.html",
"en": "https://www.getresponse.com/help/subject-line.html",
"de": "https://www.getresponse.com/de/hilfe/subject-line.html",
"ru": "https://www.getresponse.com/ru/help-ru/tema-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/oggetto.html",
"br": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pt": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"fr": "https://www.getresponse.com/fr/aide/ligne-d-objet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPaginaDeAgradecimiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/pagina-de-agradecimiento.html",
"pl": "https://www.getresponse.pl/pomoc/strona-z-podziekowaniem.html",
"en": "https://www.getresponse.com/help/thank-you-page.html",
"de": "https://www.getresponse.com/de/hilfe/thank-you-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/stranica-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/pagina-di-ringraziamento.html",
"br": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEncuesta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/encuesta.html",
"pl": "https://www.getresponse.pl/pomoc/ankieta.html",
"en": "https://www.getresponse.com/help/survey.html",
"de": "https://www.getresponse.com/de/hilfe/survey.html",
"ru": "https://www.getresponse.com/ru/help-ru/opros.html",
"it": "https://www.getresponse.com/it/assistenza/sondaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pt": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"fr": "https://www.getresponse.com/fr/aide/enquete.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBajas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bajas.html",
"pl": "https://www.getresponse.pl/pomoc/usuniete.html",
"en": "https://www.getresponse.com/help/removals.html",
"de": "https://www.getresponse.com/de/hilfe/removals.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpisavshiesya-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/contatti-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"fr": "https://www.getresponse.com/fr/aide/suppressions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampoDeRespuesta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/campo-de-respuesta.html",
"pl": "https://www.getresponse.pl/pomoc/pole-odpowiedz-do.html",
"en": "https://www.getresponse.com/help/reply-to-field.html",
"de": "https://www.getresponse.com/de/hilfe/reply-to-field.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-dlya-otveta.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-di-risposta.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"fr": "https://www.getresponse.com/fr/aide/champ-de-reponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReboteTransitorio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/rebote-transitorio.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-miekki.html",
"en": "https://www.getresponse.com/help/soft-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/soft-bounce.html",
"ru": "https://www.getresponse.com/ru/help-ru/vremennaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-morbido.html",
"br": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pt": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"fr": "https://www.getresponse.com/fr/aide/rebond-doux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDenunciaDeSpam = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/denuncia-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/zgloszenie-spamu.html",
"en": "https://www.getresponse.com/help/spam-complaint.html",
"de": "https://www.getresponse.com/de/hilfe/spam-complaint.html",
"ru": "https://www.getresponse.com/ru/help-ru/zhaloba-na-spam.html",
"it": "https://www.getresponse.com/it/assistenza/reclamo-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/plainte-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingBasadoEnPermisos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/marketing-basado-en-permisos.html",
"pl": "https://www.getresponse.pl/pomoc/marketing-za-przyzwoleniem.html",
"en": "https://www.getresponse.com/help/permission-based-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-based-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketing-na-osnove-razreshenij.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-basato-sul-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-base-sur-les-permissions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTextoSinFormato = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/texto-sin-formato.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-w-formacie-tekstowym.html",
"en": "https://www.getresponse.com/help/plain-text.html",
"de": "https://www.getresponse.com/de/hilfe/plain-text.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/testo-semplice.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/personalizacion.html",
"pl": "https://www.getresponse.pl/pomoc/personalizacja.html",
"en": "https://www.getresponse.com/help/personalization.html",
"de": "https://www.getresponse.com/de/hilfe/personalization.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/personalizzazione.html",
"br": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"fr": "https://www.getresponse.com/fr/aide/personnalisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDireccionPostal = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/direccion-postal.html",
"pl": "https://www.getresponse.pl/pomoc/adres-pocztowy.html",
"en": "https://www.getresponse.com/help/postal-address.html",
"de": "https://www.getresponse.com/de/hilfe/postal-address.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-postale.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"fr": "https://www.getresponse.com/fr/aide/adresse-postale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNumeroDeSeguimientoDeLaReferencia = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/numero-de-seguimiento-de-la-referencia.html",
"pl": "https://www.getresponse.pl/pomoc/ref-numer-sledzacy.html",
"en": "https://www.getresponse.com/help/ref-tracking-number.html",
"de": "https://www.getresponse.com/de/hilfe/ref-tracking-number.html",
"ru": "https://www.getresponse.com/ru/help-ru/nomer-dla-otslezivaniya.html",
"it": "https://www.getresponse.com/it/assistenza/numero-di-riferimento.html",
"br": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"fr": "https://www.getresponse.com/fr/aide/ref-numero-de-suivi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDesinscripcionMultipleOptOut = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/desinscripcion-multiple-opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/multi-unsubscribe-opt-out.html",
"en": "https://www.getresponse.com/help/multi-unsubscribe-opt-out.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovyj-otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-multipla-opt-out.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-de-assinatura-multipla-desativacao.html",
"pl": "https://www.getresponse.pl/pomoc/multi-unsubscribe-wypis.html",
"fr": "https://www.getresponse.com/fr/aide/desabonnement-multiple-opt-out.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatoDelMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/formato-del-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/format-wiadomosci.html",
"en": "https://www.getresponse.com/help/message-format.html",
"de": "https://www.getresponse.com/de/hilfe/message-format.html",
"ru": "https://www.getresponse.com/ru/help-ru/format-soobshheniya.html",
"it": "https://www.getresponse.com/it/assistenza/formato-del-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/format-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFusionDePalabras = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/fusion-de-palabras.html",
"pl": "https://www.getresponse.pl/pomoc/merge-word.html",
"en": "https://www.getresponse.com/help/merge-word.html",
"de": "https://www.getresponse.com/de/hilfe/merge-word.html",
"ru": "https://www.getresponse.com/ru/help-ru/slovo-obedenenie.html",
"it": "https://www.getresponse.com/it/assistenza/fusione-di-parole.html",
"br": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"pt": "https://www.getresponse.com/pt/ajuda/fundir-palavra.html",
"fr": "https://www.getresponse.com/fr/aide/fusionner-des-mots.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNotificacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/notificacion.html",
"pl": "https://www.getresponse.pl/pomoc/notification-powiadomienia.html",
"en": "https://www.getresponse.com/help/notification.html",
"de": "https://www.getresponse.com/de/hilfe/notification.html",
"ru": "https://www.getresponse.com/ru/help-ru/uvedomlenie-o-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/notificazione.html",
"br": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"fr": "https://www.getresponse.com/fr/aide/notification.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptInNoConfirmado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/opt-in-no-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-bez-potwierdzenia.html",
"en": "https://www.getresponse.com/help/non-confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/non-confirmed-opt-in.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-bez-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-non-confermato.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-non-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTasaDeApertura = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tasa-de-apertura.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-otwarc.html",
"en": "https://www.getresponse.com/help/open-rate.html",
"de": "https://www.getresponse.com/de/hilfe/open-rate.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-otkrytij.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-apertura.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEstadisticasDeDesempeno = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-desempeno.html",
"pl": "https://www.getresponse.pl/pomoc/statystki-wysylek.html",
"en": "https://www.getresponse.com/help/performance-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/performance-statistics.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-dejstvij.html",
"it": "https://www.getresponse.com/it/assistenza/statistiche-di-rendimento.html",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"fr": "https://www.getresponse.com/fr/aide/statistiques-de-performance.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDarseDeBaja = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDireccionDeIp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/direccion-de-ip.html",
"pl": "https://www.getresponse.pl/pomoc/adres-ip.html",
"en": "https://www.getresponse.com/help/ip-address.html",
"de": "https://www.getresponse.com/de/hilfe/ip-address.html",
"ru": "https://www.getresponse.com/ru/help-ru/ip-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/addresse-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloqueoDeIp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-ip.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-adresu-ip.html",
"en": "https://www.getresponse.com/help/ip-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/ip-blocking.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-ip.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeneracionDeClientesPotenciales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/generacion-de-clientes-potenciales.html",
"pl": "https://www.getresponse.pl/pomoc/pozyskiwanie-leadow.html",
"en": "https://www.getresponse.com/help/leads-generation.html",
"de": "https://www.getresponse.com/de/hilfe/leads-generation.html",
"ru": "https://www.getresponse.com/ru/help-ru/generaciya-lidov.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversionDeClientesPotenciales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/conversion-de-clientes-potenciales.html",
"pl": "https://www.getresponse.pl/pomoc/konwersja-leadow.html",
"en": "https://www.getresponse.com/help/lead-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/lead-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversiya-lida.html",
"it": "https://www.getresponse.com/it/assistenza/conversione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentacionDeLaLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-lista.html",
"pl": "https://www.getresponse.pl/pomoc/segmentacja-listy.html",
"en": "https://www.getresponse.com/help/list-segmentation.html",
"de": "https://www.getresponse.com/de/hilfe/list-segmentation.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-della-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGestionDeListas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/gestion-de-listas.html",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-lista.html",
"en": "https://www.getresponse.com/help/list-management.html",
"de": "https://www.getresponse.com/de/hilfe/list-management.html",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-spiskami.html",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFrecuencia = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/frecuencia.html",
"pl": "https://www.getresponse.pl/pomoc/czestotliwosc-wyslanych-emaili.html",
"en": "https://www.getresponse.com/help/frequency.html",
"de": "https://www.getresponse.com/de/hilfe/frequency.html",
"ru": "https://www.getresponse.com/ru/help-ru/chastota.html",
"it": "https://www.getresponse.com/it/assistenza/frequenza.html",
"br": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"fr": "https://www.getresponse.com/fr/aide/frequence.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaDeCorreos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/lista-de-correos.html",
"pl": "https://www.getresponse.pl/pomoc/lista-mailingowa.html",
"en": "https://www.getresponse.com/help/mailing-list.html",
"de": "https://www.getresponse.com/de/hilfe/mailing-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/spisok-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/lista-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"fr": "https://www.getresponse.com/fr/aide/liste-d-adresses-electroniques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeneracionDeHtml = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/generacion-de-html.html",
"pl": "https://www.getresponse.pl/pomoc/generowanie-kodu-html.html",
"en": "https://www.getresponse.com/help/generating-html.html",
"de": "https://www.getresponse.com/de/hilfe/generating-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-html.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-html.html",
"br": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampoDeRemitente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/campo-de-remitente.html",
"pl": "https://www.getresponse.pl/pomoc/pole-nadawcy.html",
"en": "https://www.getresponse.com/help/from-field.html",
"de": "https://www.getresponse.com/de/hilfe/from-field.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-otpravitelya.html",
"it": "https://www.getresponse.com/it/assistenza/from-field.html",
"br": "https://www.getresponse.com/pt/ajuda/remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/remetente.html",
"fr": "https://www.getresponse.com/fr/aide/le-champ-de.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRebotePermanente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/rebote-permanente.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot-twardy.html",
"en": "https://www.getresponse.com/help/hard-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/hard-bounce.html",
"ru": "https://www.getresponse.com/ru/help-ru/postoyannaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-duro.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"fr": "https://www.getresponse.com/fr/aide/hard-bounce-rebondissement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaGris = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"en": "https://www.getresponse.com/help/greylist.html",
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html",
"de": "https://www.getresponse.com/de/hilfe/grauliste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloqueoDeImagenes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-imagenes.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie-obrazkow.html",
"en": "https://www.getresponse.com/help/image-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/image-blocking.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-izobrazhenij.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-delle-immagini.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-de-l-image.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeHtml = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-html.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-html.html",
"de": "https://www.getresponse.com/de/hilfe/html-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/html-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/html-message.html",
"pt": "https://www.getresponse.com/pt/ajuda/html-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostImportar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/importar.html",
"pl": "https://www.getresponse.pl/pomoc/import.html",
"en": "https://www.getresponse.com/help/import.html",
"de": "https://www.getresponse.com/de/hilfe/import.html",
"ru": "https://www.getresponse.com/ru/help-ru/import.html",
"it": "https://www.getresponse.com/it/assistenza/import.html",
"br": "https://www.getresponse.com/pt/ajuda/importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/importacao.html",
"fr": "https://www.getresponse.com/fr/aide/importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCampanaDeEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/campana-de-email.html",
"pl": "https://www.getresponse.pl/pomoc/kampania-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/email-campaign.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-kampaniya.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-d-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeInstantaneo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-instantaneo.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-natychmiastowa-blyskawiczna.html",
"en": "https://www.getresponse.com/help/instant-message.html",
"de": "https://www.getresponse.com/de/hilfe/instant-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/mgnovennoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/instant-message.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"fr": "https://www.getresponse.com/fr/aide/message-instantane.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSuscriptoresInactivos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/suscriptores-inactivos.html",
"pl": "https://www.getresponse.pl/pomoc/nieaktywni-subskrybenci.html",
"en": "https://www.getresponse.com/help/inactive-subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/inactive-subscribers.html",
"ru": "https://www.getresponse.com/ru/help-ru/neaktivnye-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/sottoscrittori-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEnviandoEmails = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/enviando-emails.html",
"pl": "https://www.getresponse.pl/pomoc/emailing.html",
"en": "https://www.getresponse.com/help/emailing.html",
"de": "https://www.getresponse.com/de/hilfe/emailing.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpravka-po-elektronnoj-pochte.html",
"it": "https://www.getresponse.com/it/assistenza/invio-di-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/enviando-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/l-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEncabezadosDeEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/encabezados-de-email.html",
"pl": "https://www.getresponse.pl/pomoc/naglowki-emaila.html",
"en": "https://www.getresponse.com/help/email-headers.html",
"de": "https://www.getresponse.com/de/hilfe/email-headers.html",
"ru": "https://www.getresponse.com/ru/help-ru/zagolovki-elektronnoi-pochti.html",
"it": "https://www.getresponse.com/it/assistenza/intestazioni-e-mail-e-mail-headers.html",
"br": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/en-tetes-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocionPorEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/promocion-por-email.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-emailowa.html",
"en": "https://www.getresponse.com/help/email-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/email-promotion.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-par-courrier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPieDePagina = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/pie-de-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/stopka-maila.html",
"en": "https://www.getresponse.com/help/footer.html",
"de": "https://www.getresponse.com/de/hilfe/footer.html",
"ru": "https://www.getresponse.com/ru/help-ru/futer.html",
"it": "https://www.getresponse.com/it/assistenza/pie-di-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/rodape.html",
"fr": "https://www.getresponse.com/fr/aide/pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostArchivoAdjunto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/archivo-adjunto.html",
"pl": "https://www.getresponse.pl/pomoc/zalacznik.html",
"en": "https://www.getresponse.com/help/file-attachment.html",
"de": "https://www.getresponse.com/de/hilfe/file-attachment.html",
"ru": "https://www.getresponse.com/ru/help-ru/fajl-vlozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/file-allegato.html",
"br": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pt": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-joint.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTasaDeEntrega = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tasa-de-entrega.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-dostarczalnosci.html",
"en": "https://www.getresponse.com/help/deliverability-rate.html",
"de": "https://www.getresponse.com/de/hilfe/deliverability-rate.html",
"ru": "https://www.getresponse.com/ru/help-ru/uroven-dostavlyaemosti.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-consegna.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEntregado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/entregado.html",
"pl": "https://www.getresponse.pl/pomoc/dostarczona.html",
"en": "https://www.getresponse.com/help/delivered.html",
"de": "https://www.getresponse.com/de/hilfe/delivered.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-dostavleno.html",
"it": "https://www.getresponse.com/it/assistenza/consegnato.html",
"br": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pt": "https://www.getresponse.com/pt/ajuda/entregue.html",
"fr": "https://www.getresponse.com/fr/aide/livre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNombreDeDominio = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/nombre-de-dominio.html",
"pl": "https://www.getresponse.pl/pomoc/nazwa-domeny.html",
"de": "https://www.getresponse.com/de/hilfe/domain-name.html",
"ru": "https://www.getresponse.com/ru/help-ru/domennoe-imya.html",
"it": "https://www.getresponse.com/it/assistenza/nome-di-dominio.html",
"br": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTransmision = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/transmision.html",
"pl": "https://www.getresponse.pl/pomoc/wysylka.html",
"en": "https://www.getresponse.com/help/broadcast.html",
"de": "https://www.getresponse.com/de/hilfe/broadcast.html",
"ru": "https://www.getresponse.com/ru/help-ru/translyaciya.html",
"it": "https://www.getresponse.com/it/assistenza/broadcast.html",
"br": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"fr": "https://www.getresponse.com/fr/aide/transmission.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTasaDeRebote = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tasa-de-rebote.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zwrotow.html",
"en": "https://www.getresponse.com/help/bounce-rate.html",
"de": "https://www.getresponse.com/de/hilfe/bounce-rate.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-oshibok-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/bounce-rate.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-rebond.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContenidoDinamico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/contenido-dinamico.html",
"pl": "https://www.getresponse.pl/pomoc/dynamic-content.html",
"en": "https://www.getresponse.com/help/dynamic-content.html",
"de": "https://www.getresponse.com/de/hilfe/dynamic-content.html",
"ru": "https://www.getresponse.com/ru/help-ru/dinamicheskij-kontent.html",
"it": "https://www.getresponse.com/it/assistenza/contenuti-dinamici.html",
"br": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/conteudo-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/contenu-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTasaDeClics = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/tasa-de-clics.html",
"pl": "https://www.getresponse.pl/pomoc/wskaznik-klikniec.html",
"en": "https://www.getresponse.com/help/click-through-rate.html",
"de": "https://www.getresponse.com/de/hilfe/click-through-rate.html",
"ru": "https://www.getresponse.com/ru/help-ru/koefficient-klikov-ctr.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pt": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCorreoMasivo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/correo-masivo.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-masowa.html",
"en": "https://www.getresponse.com/help/bulk-email.html",
"de": "https://www.getresponse.com/de/hilfe/bulk-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovaya-rassylka.html",
"it": "https://www.getresponse.com/it/assistenza/email-di-massa.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"fr": "https://www.getresponse.com/fr/aide/envoi-massif-de-courriels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostClaveApi = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/clave-api.html",
"pl": "https://www.getresponse.pl/pomoc/klucz-api.html",
"en": "https://www.getresponse.com/help/api-key.html",
"de": "https://www.getresponse.com/de/hilfe/api-key.html",
"ru": "https://www.getresponse.com/ru/help-ru/api-kljuch.html",
"it": "https://www.getresponse.com/it/assistenza/chiave-api.html",
"br": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"fr": "https://www.getresponse.com/fr/aide/cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheFunktionenBietenLegacyLandingPages = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineLegacyLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineLandingPageUndWarumSollteIchEineHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGibtEsEinenPlainHtmlWebformularEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieDankeseiteUndBereitsAbonnierteUrlFuerEinLegacyFormular = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinLegacyWebformularAufEinerWordpressSeiteInstallieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieInstalliereIchEinLegacyWebformularAufMeinerSeite = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-ein-legacy-webformular-auf-meiner-seite.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-formularz-legacy-na-swojej-stronie.html",
"en": "https://www.getresponse.com/help/how-do-i-install-a-legacy-form-on-my-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-un-formulario-de-legado-en-mi-pagina-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-redaktora-legacy-na-svoey-stranitse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-installo-un-modulo-sulla-mia-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchAlsHintergrundFuerMeinLegacyWebformularEinBildVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenEinemWebformularUndEinerLandingPage = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchBenutzerdefinierteFelderZuMeinemLegacyWebformularHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineMobileVersionMeinesLegacyFormulars = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchAnmeldedatenInLegacyFormularenAnEineBenutzerdefinierteDankeSeiteWeiterleiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeguimientoDeClics = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/seguimiento-de-clics.html",
"pl": "https://www.getresponse.pl/pomoc/sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/click-tracking.html",
"ru": "https://www.getresponse.com/ru/help-ru/otslezhivanie-po-kliku.html",
"it": "https://www.getresponse.com/it/assistenza/tracciamento-dei-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConsentimientoConfirmado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/consentimiento-confirmado.html",
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-z-potwierdzeniem.html",
"en": "https://www.getresponse.com/help/confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/confirmed-opt-in.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdennaia-podpiska.html",
"it": "https://www.getresponse.com/it/assistenza/doppio-opt-in.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeDeConfirmacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-confirmacion.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-potwierdzajaca-subskrypcje.html",
"en": "https://www.getresponse.com/help/confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-conferma.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLeyCanSpam = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/ley-can-spam.html",
"pl": "https://www.getresponse.pl/pomoc/ustawa-can-spam.html",
"en": "https://www.getresponse.com/help/can-spam-act.html",
"de": "https://www.getresponse.com/de/hilfe/can-spam-act.html",
"ru": "https://www.getresponse.com/ru/help-ru/zakon-can-spam.html",
"it": "https://www.getresponse.com/it/assistenza/legge-can-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"fr": "https://www.getresponse.com/fr/aide/loi-can-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueCondicionesPuedoUsarParaBuscarContactosYCrearSegmentos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion.html",
"pl": "https://www.getresponse.pl/pomoc/automatyzacja.html",
"en": "https://www.getresponse.com/help/automation.html",
"de": "https://www.getresponse.com/de/hilfe/automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/automation.html",
"br": "https://www.getresponse.com/pt/ajuda/automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/automacao.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBloqueo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bloqueo.html",
"pl": "https://www.getresponse.pl/pomoc/blokowanie.html",
"en": "https://www.getresponse.com/help/blocking.html",
"de": "https://www.getresponse.com/de/hilfe/blocking.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka.html",
"it": "https://www.getresponse.com/it/assistenza/blocco.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"fr": "https://www.getresponse.com/fr/aide/blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEmailEnBlanco = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/email-en-blanco.html",
"pl": "https://www.getresponse.pl/pomoc/pusty-email.html",
"en": "https://www.getresponse.com/help/blank-email.html",
"de": "https://www.getresponse.com/de/hilfe/blank-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/pustoe-elektronnoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/email-vuouta.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"fr": "https://www.getresponse.com/fr/aide/email-vide.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOrientacionDelComportamiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/orientacion-del-comportamiento.html",
"pl": "https://www.getresponse.pl/pomoc/targetowanie-behawioralne.html",
"en": "https://www.getresponse.com/help/behavioral-targeting.html",
"de": "https://www.getresponse.com/de/hilfe/behavioral-targeting.html",
"ru": "https://www.getresponse.com/ru/help-ru/povedencheskij-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-comportamentale.html",
"br": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pt": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"fr": "https://www.getresponse.com/fr/aide/ciblage-comportemental.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRebotados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/rebotados.html",
"pl": "https://www.getresponse.pl/pomoc/zwrot.html",
"en": "https://www.getresponse.com/help/bounced.html",
"de": "https://www.getresponse.com/de/hilfe/bounced.html",
"ru": "https://www.getresponse.com/ru/help-ru/oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"fr": "https://www.getresponse.com/fr/aide/rebondi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSobreElPliegue = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/sobre-el-pliegue.html",
"pl": "https://www.getresponse.pl/pomoc/above-the-fold-ponad-linia.html",
"en": "https://www.getresponse.com/help/above-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/above-the-fold.html",
"ru": "https://www.getresponse.com/ru/help-ru/vidimaya-chast-bez-prokrutki.html",
"it": "https://www.getresponse.com/it/assistenza/above-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"fr": "https://www.getresponse.com/fr/aide/above-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBajoElPliegue = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bajo-el-pliegue.html",
"pl": "https://www.getresponse.pl/pomoc/below-the-fold-ponizej-linii.html",
"en": "https://www.getresponse.com/help/below-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/below-the-fold.html",
"ru": "https://www.getresponse.com/ru/help-ru/nizhe-linii-zgiba.html",
"it": "https://www.getresponse.com/it/assistenza/below-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"fr": "https://www.getresponse.com/fr/aide/below-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlineacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/alineacion.html",
"pl": "https://www.getresponse.pl/pomoc/zgodnosc.html",
"en": "https://www.getresponse.com/help/alignment.html",
"de": "https://www.getresponse.com/de/hilfe/alignment.html",
"ru": "https://www.getresponse.com/ru/help-ru/vyravnivanie.html",
"it": "https://www.getresponse.com/it/assistenza/alignment.html",
"br": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"fr": "https://www.getresponse.com/fr/aide/alignement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaConfirmacionDePagoAdicional = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoPuedoIniciarLaSesion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarUnaTarjetaAMiCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoSeProcesaMiPago = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElSeguimientoDeLaTasaDeApertura = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoDescargarMisFacturas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHeCanceladoMiSuscripcionPorQueTodaviaMeEstanCobrando = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarElMetodoDePago = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarMiPlanDeSuscripcion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEliminarUnaTarjeta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosInformesYComoPuedoUsarlos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-informes-y-como-puedo-usarlos.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-raporty-i-jak-moge-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-reports-und-wie-nutze-ich-sie.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otchety-i-kak-ja-mogu-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/what-are-reports-and-how-can-i-use-them.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoMigrarMiListaDeActiveCampaignAweberConstantContactOMailchimp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-migrar-mi-lista-de-active-campaign-aweber-constant-contact-o-mailchimp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeniesc-moja-liste-z-active-campaign-aweber-constant-contact-lub-mailchimp.html",
"en": "https://www.getresponse.com/help/how-can-i-migrate-my-list-from-active-campaign-aweber-constant-contact-or-mailchimp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-perenesti-svoj-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trasferire-la-mia-lista-da-active-campaign-aweber-constant-contact-o-mailchimp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-liste-von-active-campaign-aweber-constant-contact-oder-mailchimp-migrieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoGuardarElModeloDeLandingPageDeLegadoParaPoderReutilizarMiDiseno = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoVerLasEstadisticasDeUnaListaOMensajeEspecifico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirDireccionesDeEmailDeRolAMiListaDeSuscriptores = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoConectarAMinhaLojaOnlineAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarUmColaborador = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnviarRecomendacoesDeProdutosAosClientes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoManterOsDadosDeContatoSincronizadosEntreOSalesforceEAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineLandingPageMitDemLegacyWordpressPluginZuWordpressHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchWebfontsInMeinerNachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoPublicarActualizacionesDeTwitterYFacebookCuandoEnvioUnNewsletter = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIniciarSesionEnLaAplicacionMovilConUnLinkMagico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoHabilitarLasSuscripcionesParaLosVisitantesDeMiTiendaPrestashop = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegroGetresponseYWordpressParaAmpliarMiListaDeContactosRastrearLasVisitasAlSitioYEnviarDatosDeECommerceConElPluginAntiguoDeWordpress = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirLosClientesYProspectosDeZohocrmAMiListaDeContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignificaQueMiMensajeTieneUnErrorDeSintaxis = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoOsTiposDeCamposPersonalizadosDisponiveis = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensagemDeAniversario = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"en": "https://www.getresponse.com/help/birthday-message.html",
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html",
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSerieParaCarrinhoAbandonado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegroAGetresponseEOWordpressParaAumentarMinhaListaDeContatosAcompanharVisitasAoSiteEEnviarDadosDeECommerce = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRastrearVisitasAosMeusSitesWordpress = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoDeixoMinhaContaProntaParaAGdpr = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoColetarOConsentimentoDeContatosNovosEJaExistentes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLembreteDeCarrinhoAbandonado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontrarTemplatesDeLandingPagesLegadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoUsarACondicaoLandingPageVisitadaNumFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoSegmentarOsMeusContatosComBaseEmDadosDeEcommerce = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoRastrearEColetarDadosDeECommerceDoPrestashop = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSignificaQueAMinhaMensagemTemUmErroDeSintaxe = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoFazerContagemRegressivaParaUmEventoEmMinhaLandingPageLegada = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisFerramentasEstaoDisponiveisNoEditorDeLandingPagesLegadas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoFazerLoginNoAplicativoParaDispositivosMoveisComUmLinkMagico = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoEditarUmaMensagemQueEstouUsandoEmUmFluxoDeTrabalho = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisNavegadoresSaoCompativeisComAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoSalvarUmTemplateDeLandingPageLegadaParaQueEuPossaReutilizarMeuDesign = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoCarregarOsDadosDoMeuProdutoParaAGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarClientesDoSalesforceAMinhaListaDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoMigrarMinhaListaDoActiveCampaignAweberConstantContactOuMailchimp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeniesc-moja-liste-z-active-campaign-aweber-constant-contact-lub-mailchimp.html",
"en": "https://www.getresponse.com/help/how-can-i-migrate-my-list-from-active-campaign-aweber-constant-contact-or-mailchimp.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-migrar-mi-lista-de-active-campaign-aweber-constant-contact-o-mailchimp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-perenesti-svoj-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trasferire-la-mia-lista-da-active-campaign-aweber-constant-contact-o-mailchimp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-liste-von-active-campaign-aweber-constant-contact-oder-mailchimp-migrieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarClientesDoZohocrmAMinhaListaDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoAsDiferencasEntreAsPaginasDoFunil = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmFunilDeConversao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmFunilDeConstrucaoDeLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEncontrarLosEncabezadosDeMiMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html",
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElDkimYComoConfigurarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirLosClientesYProspectosDeSalesforceAMiListaDeContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConectoMisCuentasDeFacebookYGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoHagoUnSeguimientoDeLasAperturasDeLosMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMantengoSincronizadosLosDatosDeLosContactosDeSalesforceYGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAlgunosElementosDeMiFlujoDeTrabajoEstanEnGrisPorQue = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/algunos-elementos-de-mi-flujo-de-trabajo-estan-en-gris-por-que.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-elementy-mojego-mojego-cyklu-sa-wyszarzone.html",
"en": "https://www.getresponse.com/help/some-elements-in-my-workflow-are-grayed-out-why.html",
"de": "https://www.getresponse.com/de/hilfe/manche-elemente-meines-workflows-sind-ausgegraut-warum.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-nekotorye-elementy-v-mojom-processe-vydeleny-serym-cvetom.html",
"br": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pt": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"it": "https://www.getresponse.com/it/assistenza/alcuni-elementi-nel-automation-workflow-sono-disattivati-perche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoPonerEnListaDeBloqueoEmailsYDominiosDeMisListasOCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegroPaypalConGetresponseYAgregoBotonesAMensajesYLandingPages = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIntegrarLasRedesSocialesEnMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMisEstadisticasDeTasaDeAperturaNoEstanDisponiblesQuePuedeEstarCausandoEsto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html",
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignificaQueMiImportacionTuvoUnProblemaConLaComparticionDeLaLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirNuevosContactosAListasDiferentesConLosBotonesComprarAhoraDePaypal = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-nuevos-contactos-a-listas-diferentes-con-los-botones-comprar-ahora-de-paypal.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-kontakty-do-roznych-list-przy-uzyciu-przyciskow-paypal-kup-teraz.html",
"en": "https://www.getresponse.com/help/can-i-add-new-contacts-to-different-lists-with-paypal-buy-now-buttons.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-paypal-jetzt-kaufen-buttons-neue-kontakte-in-verschiedene-listen-eintragen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavliyat-novye-kontakty-v-raznye-spiski-s-pomoshiyu-knopki-paypal-kypit-sejchas.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-de-nouveaux-contacts-a-differentes-listes-avec-les-boutons-paypal-acheter-maintenant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirContactosAUnaDeMisListasUsandoLasAplicacionesMoviles = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreUnaNewsletterYUnMensajeDeAutorespuesta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInfluyeLaReputacionDeLaDireccionRemitenteEnMiCapacidadDeEntregaBandejaDeEntradaVsCarpetaDeSpam = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoEliminarOEditarLosEnlacesDeDarseDeBajaYActualizacionDeLosDatosEnLaParteInferiorDeMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoDeberiaUsarYahooAolMailRuComoMiDireccionDelRemitente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoRealizarCambiosEnUnFlujoDeTrabajoQueYaSeHaPublicado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaAutomatizacionDeMarketingYQuePuedoHacerConElla = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEnviarUnMensajeInstantaneoDespuesDeQueAlguienSeSuscribaATravesDeLaLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAsignoCamposPersonalizadosDuranteLaImportacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueLosPsisReconocenLosMensajesComoSpamDeManeraDiferente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoVerificarComoSeVeElMensajeEnUnaBandejaDeEntradaAntesDeEnviarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadoContactosAUnaListaDespuesDeQueHayanCompletadoUnaTransaccionDePaypal = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueAlgunosEmailsSeMuestranComoNombreDeOATravesDe = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosNumerosDebajoDeCadaElementoEnMiFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaEscalaDeCompromisoYComoPuedoUsarla = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueHerramientasEstanDisponiblesDentroDelCreadorDeLandingPagesDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoSubirLosDatosDeMiProductoAGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoVerPorQueMisContactosSeHanDadoDeBaja = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElTextoPredefinido = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLosModelosDeLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsPerfectTimingYComoFunciona = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoIncluirArticulosDeCarritoAbandonadoEnUnMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incluir-articulos-de-carrito-abandonado-en-un-mensaje.html",
"en": "https://www.getresponse.com/help/how-can-i-include-abandoned-cart-items-in-a-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-v-soobshenie-predmety-iz-broshennoj-korziny.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-includere-gli-articoli-del-carrello-abbandonato-in-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zawrzec-produkty-z-porzuconego-koszyka-w-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-inclure-les-articles-du-panier-abandonne-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoRastrearYRecopilarLosDatosDeECommerceDesdePrestashop = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueNavegadoresSonCompatiblesConGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoVerYAdministrarMisListas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirMiLandingPageAWordpressConElPluginAntiguoDeWordpress = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirEnlacesACamposPersonalizados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirAUnFlujoDeTrabajoContactosQueFueronCopiadosOMovidosAUnaListaDiferente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUsarLaCondicionSiLaEtiquetaEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEstaIncluidoEnLasFuncionesDeECommerceYSeguimientoDeTraficoWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfiguroLasPropiedadesDeLaCondicionDeCarritoAbandonado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoLaConfiguracionDeTiempoEnLasPropiedadesDeCondiciones = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoMaximizarLaPrecisionDeLaValidacionTelefonicaDeLaApi = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoIntegrarMiAplicacionConGetresponseUtilizandoLaApi = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSoyUnDesarrolladorDondePuedoEncontrarInformacionSobreLaApi = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html",
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoLosCamposPersonalizadosEnLaAutomatizacionDeMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoMoverLosContactosDeUnaListaAOtraComoResultadoDeUnFlujoDeTrabajoDeAutomatizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEliminarContactosComoResultadoDelFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoExcluirContatosDuplicadosNasMinhasListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoBuscarContatosComBaseNoConsentimentoQueDeram = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEncontrarPessoasPorMetodoDeAssinatura = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEncontroPessoasQueNaoAbriramAsMensagens = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoExcluirContatosDeUmaListaOuDaConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakyaPolitikaVozvrataSredstvVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoExportarContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUdalitMoyAkkaunt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoManualmenteRemoverContatosDaMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCopiarOuMoverContatosDeUmaListaParaOutra = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoHacerUnaCuentaRegresivaParaUnEventoEnMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeDopolnitelnoePodtverzdenieOplaty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemySredstvaZaPodpiskyProdolzayutSpisyvatsyaPosleTogoKakYaYdalilSvoiAkkaynt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemyOplataZaPodpiskyNeProhodit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndePossoVerOsDadosDeContato = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoBydetEsliYaPrevishyDopystimyjLimitBazy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoEditarUnMensajeQueEstoyUsandoEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitSposobOplatyVMoemAkkaynt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoLaCondicionLandingPageVisitadaEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontroAListaDeContatosInativos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOndeEncontroUmaListaComTodosOsMeusContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogySkachatSchetaZaOplaty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYdalitKartyIzAkkaynta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitNovyuKarty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIzmenitSvoiTarifnyjPlan = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoBuscarContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieSposobyOplatyVyPrinimaete = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html",
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSkolkoStoitYslygiGetresponseVMesyac = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyObnovitSvoiFreeAkkaynt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakimObrazomGetresponseRaschituvaetCeny = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoOsContatosPodemSeAdicionarAMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarContatosIndividuaisDiretamenteAMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogytLiZablokirovatMoyuYchetnyuZapisIzZaZalobNaSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarEnderecosDeFuncaoAMinhaListaDeAssinantes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"en": "https://www.getresponse.com/help/what-is-spam.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyOtpravlyatPismaSootvetstvyushiePolitikeDmarc = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemyVaznoSootvetstvieTrebovaniyamZakonodatelstva = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-vazno-sootvetstvie-trebovaniyam-zakonodatelstva.html",
"en": "https://www.getresponse.com/help/why-is-legal-compliance-important.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-die-einhaltung-gesetzlicher-vorschriften-wichtig.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-importante-el-cumplimiento-legal.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nalezy-przestrzegac-przepisow-prawnych.html",
"it": "https://www.getresponse.com/it/assistenza/perche-e-importante-la-conformita-legale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAnadirUnCodigoPersonalizadoAMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYbeditsyaChtoYaIspolnyauTrebovaniyaKanadskogoZakonaOtSpamaCasl = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html",
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoyaYchetnayaZapisOgranichenaIChtoEtoZnachit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoVerLosDatosDelContacto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitPolzovatelya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieRoliYaMogyNaznachatPolzovatelyam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html",
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitKolichestvoLuydejImeyushichDostypKMoejYchetnoiZapisi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoznoLiDavatDrygimLyudyamDostypKSvoejYchetnoiZapisi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoBuscoContactosEnFuncionDelConsentimientoQueHanDado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodgotovitYchetnyuZapisKReglamentyGdpr = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPolychitSoglasieNaPodpiskyOtNovychISyshestvyushichKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdavatIIspolzovatPolyaSoglasiya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieBrayzeryPodderzivaetGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemyYaNeMogyVojtiVSvoiAkkaynt = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavlyatVidzetyNaGlavnyuPanel = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemyVMoichPismahOtobrazaetsyaBeidjGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitNovuiAdresIImyaOtpravitelyaDlyaPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitParol = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNahodyatsyaNastroijkiYchetnoiZapisiIKakIchIzmenit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAgregoLosVisitantesDeUnSitioWordpressAUnaListaGetresponseConElPluginAntiguoDeWordpress = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoznoLiPoprobovatGetresponseDoPokypki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoRastrearLasVisitasAMisSitiosWordpressConElPluginAntiguoDeWordpress = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAgregarLosClientesWoocommerceAGetresponseConElPluginAntiguoDeWordpress = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoePlatformaGetresponseIKakEjPolzovatsya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRabotautLiVebinaryNaMobilnychUstroistvah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html",
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakZajtiVMobilnoePrilozenieSPomoshjyuVolshebnojSsylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUpravlyatKontaktamiISpiskamiSPomoschyuMobilnogoPrilojeniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPredlagaetLiGetresponseMobilnoePrilojenie = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoFareIlContoAllaRovesciaDiUnEventoSullaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitDannyeProduktovVGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchBesucheAufMeinenWordpressWebsitesImLegacyWordpressPluginVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchWoocommerceKundenDemGetresponseInDerLegacyWordpressPluginHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchBesucherEinerWordpressWebsiteMitDemLegacyWordpressPluginEinerGetresponseListeHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieIntegriereIchGetresponseUndWordpressUmMeineKontaktlisteAuszubauenWebsiteBesucheZuErfassenUndECommerceDatenMitDemLegacyWordpressPluginZuSenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchECommerceDatenAusPrestashopVerfolgenUndErfassen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAktiviereIchAnmeldungenFuerDieBesucherMeinesPrestashopStores = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineKontaktDatenbankErweiternIndemIchGetresponseMitMagentoIntegriere = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakt-datenbank-erweitern-indem-ich-getresponse-mit-magento-integriere.html",
"it": "https://www.getresponse.com/it/assistenza/posso-far-crescere-il-mio-database-di-contatti-integrando-getresponse-con-magento.html",
"en": "https://www.getresponse.com/help/can-i-grow-my-contact-database-by-integrating-getresponse-with-magento.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-crecer-mi-base-de-datos-de-contactos-integrando-getresponse-con-magento-2.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-developper-ma-base-de-donnees-de-contacts-en-integrant-getresponse-a-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineSalesforceKundenUndInteressentenInMeineKontaktlisteImportieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeinKontoMitGoogleAnalyticsVerbinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerbindeIchGetresponseMitMeinemFacebookKonto = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieOptimiereIchDieValidierungDerTelefonnummerBeiApiIntegration = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineAnwendungUeberApiMitGetresponseVerbinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIchBinProgrammEntwicklerWoFindeIchInformationenZurApiIntegration = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html",
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEtsyProdukteZuMeinemKontoHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinUnternehmenMitDenECommerceFunktionenVonGetresponseAusbauen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheArtVonECommerceDatenWerdenAnGetresponseGesendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenOnlineShopMitGetresponseVerknuepfen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEnvioRecomendacionesDeProductosAClientes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelchenStatusKannMeineFacebookAnzeigeHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welchen-status-kann-meine-facebook-anzeige-haben.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-status-moze-otrzymac-moja-reklama-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-statuses-can-my-facebook-ads-have.html",
"es": "https://www.getresponse.com/es/ayuda/que-estado-pueden-tener-mis-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-statysu-mogyt-byt-u-moei-reklamu-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"it": "https://www.getresponse.com/it/assistenza/quali-stati-possono-avere-i-miei-annunci-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineWerbeanzeigeMitDerFacebookAdsIntegration = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIchHabeDenGleichenKontaktInMehrerenListenGespeichertBekommtDieserKontaktMeineNachrichtenMehrmals = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtribuirCamposCustomizadosDuranteAImportacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLosTiposDeLosCamposPersonalizados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPrepararUmArquivoParaImportacao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoImportarUmaListaDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAgregoUnColaborador = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchRssToEmail = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEineVerlinkteListeQuot = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieAbsenderEMailAdresseInMeinenNachrichten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-absender-e-mail-adresse-in-meinen-nachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-adres-otpravitela-v-moich-soobshenijach.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-email-nadawcy-w-wiadomosciach.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-direccion-de-en-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-l-indirizzo-di-mittente-nei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-de-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLegeIchNewsletterEmpfaengerFest = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteVonEinemNewsletterAusschliessen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinenNewsletterAufTwitterUndFacebookPosten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstPerfektesTimingUndWieFunktioniertEs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstGlobaleAnsichtUndWieKannIchSieNutzen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFindeIchMeineVorlagenUndArbeiteMitIhnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdicionarContatosAsMinhasListas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineNachrichtDieImmerAnsprechendUndGutAussieht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoEditarAsConfiguracoesDeAssinaturaDaMinhaLista = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinBildOderEinenButtonInEinTextelementEinfuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-bild-oder-einen-button-in-ein-textelement-einfuegen.html",
"en": "https://www.getresponse.com/help/can-i-embed-an-image-or-button-within-a-text-block.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vstavit-izobrazhenije-ili-knopku-tekstovy-blok.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-obrazek-lub-przycisk-w-bloku-tekstowym.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-incrustar-una-imagen-o-un-boton-dentro-de-un-bloque-de-texto.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"it": "https://www.getresponse.com/it/assistenza/posso-incorporare-un-immagine-o-un-pulsante-all-interno-di-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-une-image-ou-un-bouton-dans-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannManDieLinksZumAbbestellenUndAktualisierenInDerFusszeileDerNachrichtenEntfernenOderBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEinVordefinierterText = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchVorDemVersandUeberpruefenWieEineNachrichtImPosteingangAngezeigtWird = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannSichEinKontaktVonMeinerListeAbmelden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoDarAccesoAMiCuentaAOtrasPersonas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnaPruebaAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoLosContactosPuedenAgregarseAMiLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnSegmento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoExcluyoLosContactosDeRecibirMiNewsletter = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoGuardarUnaPruebaAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCambiarMiContrasena = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaCondicionLinkClicado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarLasPruebasABEnLasLandingPagesDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaOtslezivatPosesheniyaSvoegoSaitaNaWordpressIspolzujaUstarevshiPluginWordpress = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoPersonalizarMiPanelDeControlConWidgets = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignificaQueMiImportacionTuvoProblemasDePolitica = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLasEstadisticasDeImportaciones = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavlyatKlientovIzWoocommerceVGetresponseIspolzujaUstarevshiPluginWordpress = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueRolesPuedoAsignarALosColaboradores = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html",
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasListasDeSupresionYCuandoUsarlas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavlyatVSpisokGetresponsePosetitelejSaitaNaWordpressIspolzujaUstarevshiPluginWordpress = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElPrecioDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatGetresponseIWordpressDlaUvelicheniyaBazyKontaktovOtslezyvaniyaPoseshenijSaitaIOtpravkiDannychEcommerceIspolzujaUstarevshiPlugin = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLosContactosInactivos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnFlujoDeTrabajoDeAutomatizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMuevoOCopioContactosEntreFlujosDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLosWebinarsFuncionanEnDispositivosMoviles = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html",
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedeUnContactoDarseDeBajaDeMiLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyDobavitKontaktyVSpisokPosleTogoKakOniSovershatTranzakciyuSPomoshyuPaypal = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoConfigurarLasImportacionesRegularesAMisListas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaCondicionMensajeEnviado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueOcurreCuandoSuperoElLimiteDeTamanoDeMiLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadoContactosAMisListas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCopiarOMoverContactosDeUnaListaAOtra = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoMantenerLaHigieneDeLaLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambioElNumeroDePersonasConAccesoAMiCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPreparoUnArchivoParaLaImportacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnaAutorespuesta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoUnAutoresponder = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoBuscoLosContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIntegrirovatPaypalSGetresponseChtobyDobavitKnopkiSoobsheniyaILendingi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaDobavlyatSyshestvuyushichIPotencialnychPokypatelejIzZohocrmVSvoiSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnVideoAllaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAOttimizzareLaMiaLandingPagePerIDispositiviMobili = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAGestireIDominiDelleMieLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnRecordCnameAlMioSottodominio = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeConfigurareUnEmailTransazionale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaPolychatYvedomleniyaVSlack = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostScontiATempoLimitatoPerBlackFriday = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/sconti-a-tempo-limitato-per-black-friday.html",
"en": "https://www.getresponse.com/help/black-friday-flash-sale.html",
"de": "https://www.getresponse.com/de/hilfe/black-friday-flash-sale.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta-z-okazji-black-friday.html",
"es": "https://www.getresponse.com/es/ayuda/venta-relampago-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/flash-rasprodaja-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"fr": "https://www.getresponse.com/fr/aide/vente-flash-black-friday.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnTestAB = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaUnMessaggioDiMarketingAutomation = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereIlCodiceDiMonitoraggioJavascriptNelMioSitoWeb = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSinchronizirovatDannyeOKontaktachMezdySaleforceIGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogyLiYaDobavlyatSyshestvyushichIPotencialnuchPokypatelejIzSalesforceVSvoiSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatGetresponseSGoogleAnalytics = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPercheNonDovreiUsareYahooAolMailRuPerLDelMittente = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLaCondizioneSeIlTagInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoUsareUnCodiceDiMonitoraggioDiGoogleAnalyticsSullaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOffriteUnCodiceHtmlNormalePerIlModulo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareDominiSottodominiEDirectoryPerLeMieLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCosaSignificaCheIlMioAccountEInFaseDiVerifica = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAssegnareIlMioDominioOSottodominioAUnaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAEsportareIContatti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKoennenMeineNachrichtenMitMeinenSozialenMedienVerknuepftWerden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchVorlagenFuerHtmlNachrichten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-vorlagen-fuer-html-nachrichten.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/where-can-i-find-html-message-templates.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-modelos-de-mensajes-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodatsa-html-shablony-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-di-messaggi-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchAnhaengeZuMeinenNachrichtenHinzu = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasBedeutetEsWennMeineNachrichtEinenSyntaxErrorAufweist = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVerwendeIchECommerceTrackingInEinerNachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenNewsletter = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchDieNachrichtenStatistik = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMobileResponsiveNewsletterErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenEinerKlartextNachrichtUndEinerHtmlNachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKoennenMeineNewsletterOnlineAngesehenWerden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html",
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumKommtEsZuEinemBounceWennIchNachrichtenAnMeineEigeneDomainEMailAdresseSendenWill = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEinABTest = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchDieABTestfunktion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieVieleEmpfaengerSollMeineTestgruppeAufweisen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieWirdDieNachrichtMitDerBestenPerformanceVersendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html",
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchDieStartzeitenFuerDieABTestsPlanen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchInEinemABTestVerschiedeneTestvariantenKombinieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinenABTestAbspeichern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheArtenVonABTestsStehenZurVerfuegung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchDenVollstaendigenEMailHeader = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html",
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDkimUndWieRichteIchEsEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieGutIstDieEMailZustellungBeiGetresponse = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindBouncesUndBeschwerden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumWerdenMancheEMailsAlsImAuftragOderViaAngezeigt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumStufenInternetanbieterNachrichtenAufUnterschiedlicheWeiseAlsSpamEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumSollteIchKeineYahooAolMailRuAbsenderadressenVerwenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumSollteIchGebouncteEMailsNichtErneutZuMeinerListeHinzufuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInwiefernBeeinflusstDieReputationDerAbsenderadresseDieZustellrateInDenPosteingangImVergleichZumSpamOrdner = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineEMailsPersonalisieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaNovaListaDeContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoElijoALosDestinatariosDeLaNewsletter = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarLaAccionEsperar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueAjustesDeSuscripcionPuedoIncluirEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaDuplicacionDeFlujosDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElMensajeDeAutomatizacionDeMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoActualizarMiCuentaFree = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEliminarManualmenteLosContactosDeMiLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasQuejas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarUnaListaDeTodosMisContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoTrabajoConLosElementosEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoDefinoElMomentoEnQueMiFlujoComienzaYTermina = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoExportarContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueMetodosDePagoSeAceptan = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html",
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCuantoCuestaLaAutomatizacionDeMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoColocoElCodigoJavascriptEnMiSitioWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyNastroitRegulyarnyiImportVMoiSpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPeredatNomerTelefonaKontaktaCherezApi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTipoDePruebasABPuedoElegir = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUtilizarLasEtiquetasParaVerQuienInicioOTerminoMisFlujosDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRepararUnaListaEnvejecida = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoznoLiIntegrirovatPrilozenieSGetresponseCherezApi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeRazrabotchikMozetNaitiInformaciyuObApi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html",
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNoDeberiaVolverAAgregarEmailsRebotadosAMiLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoLaAccionEtiquetarEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEncuentroALasPersonasQueNoHanAbiertoMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNajtiApiKluch = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarYEditarLosAjustesDeMiCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfiguroLasPropiedadesParaLaCondicionDeUrlVisitada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMogyIntegrirovatSvoiOnlineMagazinSGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEstablezcoPropiedadesParaLaCondicionCompra = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLasMejoresPracticasDeEmailQueInfluyenEnLaEntrega = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoIniciarUnFlujoDeTrabajoConMultiplesCondiciones = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUsarLaAccionPuntuar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoUnMensajeRssAEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAgregoArchivosAdjuntosAMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElFiltroRango = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElFiltroCantidad = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnEbookOUnVideoAMiMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElFiltroListas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoElFiltroEstadoDelConsentimiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegroGetresponseConGoogleAnalytics = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarContactosIndividualesDirectamenteAMiLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUsarEtiquetasYPuntuacionATravesDeLaApi = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoOperaLaFuncionDeValidacionAutomaticaTelefonica = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeFailyIIzobrazeniyaIKakEtimPolzovatsya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoFiltrarContactosDuplicadosEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesModelosDeFlujoDeTrabajoEstanDisponiblesEnMiPlan = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDeBienvenida = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoePosetiteliStranicyVReklameVFacebook = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakieStatysuMogytBytUMoeiReklamuVFacebook = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakie-statysu-mogyt-byt-u-moei-reklamu-v-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-status-moze-otrzymac-moja-reklama-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-statuses-can-my-facebook-ads-have.html",
"de": "https://www.getresponse.com/de/hilfe/welchen-status-kann-meine-facebook-anzeige-haben.html",
"es": "https://www.getresponse.com/es/ayuda/que-estado-pueden-tener-mis-anuncios-de-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"it": "https://www.getresponse.com/it/assistenza/quali-stati-possono-avere-i-miei-annunci-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBienvenidaANuevosClientes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatObjavlenieSPomosjuIntegraciiSFacebookAds = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeDeBienvenidaSimple = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBienvenidaANuevosContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDostupenLiObichniHtmlKodFormy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeFormyPrilogeniya = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-formy-prilogeniya.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-formularze-pop-up-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/what-are-list-builder-apps.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-formular-apps.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-aplicaciones-de-list-builder-en-el-editor-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-applications-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPeredatDannyiePodpischikovNaSvoyuStranitsuBlagodarnostiSIspolzovaniemFormLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmFunilDeVendas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaPaginaDeVendas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmFunilDeWebinarPago = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNastroitStranitsuBlagodarnostiIStranitsuUjePodpisalsyaVRedaktoreFormLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakAktivirovatFormuSozdannuyuVRedaktoreLegacyPriKlikeNaKnopku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSdelatFormuIzRedaktoraVersiiLegacyVsplyvajushhei = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOpredelitKakayaVersiyaFormyiLegacyImeetNaibolshiiPokazatelKonversii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-kakaya-versiya-formyi-legacy-imeet-naibolshii-pokazatel-konversii.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sprawdzic-ktora-wersja-formularza-legacy-zapewnia-mi-najlepsza-konwersje.html",
"en": "https://www.getresponse.com/help/how-do-i-find-out-which-version-of-the-legacy-form-gives-me-the-highest-conversion-rate.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-heraus-welches-legacy-webformular-mir-die-hoechste-conversion-rate-beschert.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-saber-que-version-del-formulario-de-legado-me-da-la-tasa-de-conversion-mas-alta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-savoir-quelle-version-de-mon-ancien-formulaire-me-donne-le-taux-de-conversion-le-plus-eleve.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRazmestitFormuIzRedaktoraLegacyNaSvoeyStranitse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-redaktora-legacy-na-svoey-stranitse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-formularz-legacy-na-swojej-stronie.html",
"en": "https://www.getresponse.com/help/how-do-i-install-a-legacy-form-on-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-ein-legacy-webformular-auf-meiner-seite.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-un-formulario-de-legado-en-mi-pagina-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-installo-un-modulo-sulla-mia-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitSobstvennuyuKartinkuVKachestveFonaVFormetoLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenABTest = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumHabenMeineKontakteMeineNewsletterNichtErhalten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstConversionFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWorinUnterscheidenSichDieVerschiedenenFunnelSeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasUnterscheidetConversionFunnelVonMarketingAutomatisierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindConversionFunnelUpsellingSeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMitConversionFunnelEBooksVerkaufen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWorinUnterscheidenSichSchnellSalesFunnelVonKomplettenSalesFunneln = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDerFunnelAssistentFuerSalesFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeinePaypalIntegrationVerifizieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenLeadMagnetFunnel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenFunnelMitEinemKostenlosenWebinar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenFunnelMitEinemKostenpflichtigenWebinar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeinKontoMitSquareVerbinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumWerdenMeineAutoresponderNichtAusgeliefert = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmFunilDeWebinarGratuito = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarProdutosEtsyAMinhaConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAlterarAMinhaAssinatura = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueETextoPredefinido = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAdicionarUmNovoCartaoDeCreditoParaPagamentos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoRemoverUmCartaoDeCreditoDaMinhaConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoBaixarAsMinhasFaturas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoAlterarOMeuMetodoDePagamento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstEinAutoresponder = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinenAutoresponder = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCorrigirUmErroDePagamentoNaMinhaConta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDerUnterschiedZwischenEinemNewsletterUndEinerAutoresponderNachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmAnuncioComAIntegracaoFacebookAds = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieUnterscheidenSichAutoresponderVonAutomatisierungsnachrichten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanceleiAMinhaAssinaturaPorQueAindaEstouSendoCobrado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchAnWelchenTagenDieAutoresponderVersendetWerden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieNutzeIchBeiAutorespondernDieKalenderansicht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFuegeIchEineNeueNachrichtInEinenVorhandenenAutoresponderCycleEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchNachEinerRegistrierungUeberDieLandingPageEineSofortnachrichtSenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinenKontaktManuellInDenAutoresponderCycleEinfuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisTemplatesDeFluxoDeTrabalhoEstaoDisponiveisNoMeuPlano = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIchKannMeinenWorkflowNichtVeroeffentlichenWasSollIchTun = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html",
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEinwilligungsbasierteWillkommensnachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/consent-based-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-powitalna-zalezna-od-udzielonych-zgod.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-basato-su-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-na-osnove-soglasija.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-base-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBegruessungVonNeukunden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSimpleWillkommensnachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBegruessungNeuerKontakte = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieWebinareUndEventsVorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeburtstagsnachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"en": "https://www.getresponse.com/help/birthday-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html",
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFollowUpNachWebinar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoVerOPorqueOsMeusContatosQueSeDescadastraram = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueNaoConsigoFazerLogin = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisRecursosOfereceANovaSalaDeWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWebinarPromotion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webinar-promotion.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru.html",
"en": "https://www.getresponse.com/help/webinar-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenije-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoIntegrarMinhaContaComOSquare = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlackFridayEarlyBirdSale = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/black-friday-early-bird-sale.html",
"en": "https://www.getresponse.com/help/black-friday-early-bird-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-early-bird-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/wczesna-sprzedaz-z-okazji-czarnego-piatku.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-pervyim-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-antecipada.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-black-friday-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlackFridayFlashSale = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/black-friday-flash-sale.html",
"en": "https://www.getresponse.com/help/black-friday-flash-sale.html",
"it": "https://www.getresponse.com/it/assistenza/sconti-a-tempo-limitato-per-black-friday.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta-z-okazji-black-friday.html",
"es": "https://www.getresponse.com/es/ayuda/venta-relampago-de-black-friday.html",
"ru": "https://www.getresponse.com/ru/help-ru/flash-rasprodaja-v-chernuyu-pyatnitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"pt": "https://www.getresponse.com/pt/ajuda/black-friday-liquidacao-rapida.html",
"fr": "https://www.getresponse.com/fr/aide/vente-flash-black-friday.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoArquivosEImagensEComoUsaLos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTopProdukteEmpfehlen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/top-produkte-empfehlen.html",
"en": "https://www.getresponse.com/help/recommend-top-products.html",
"it": "https://www.getresponse.com/it/assistenza/prodotti-consigliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-popularnych-produktow.html",
"ru": "https://www.getresponse.com/ru/help-ru/rekomendatsiya-populyarnyih-produktov.html",
"es": "https://www.getresponse.com/es/ayuda/recomendar-los-mejores-productos.html",
"br": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"pt": "https://www.getresponse.com/pt/ajuda/recommend-top-products.html",
"fr": "https://www.getresponse.com/fr/aide/produits-recommandes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoTransmitirMeusWebinarsNoFacebookENoYoutube = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieVerkaufsaktionenVorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFruehbucherRabatt = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKontakteInKundenUmwandeln = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCompartilharUmEBookComSeusContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEONivelDeEnvolvimentoEComoPossoUsaLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisStatusOsMeusFacebookAdsPodemTer = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-status-moze-otrzymac-moja-reklama-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-statuses-can-my-facebook-ads-have.html",
"de": "https://www.getresponse.com/de/hilfe/welchen-status-kann-meine-facebook-anzeige-haben.html",
"es": "https://www.getresponse.com/es/ayuda/que-estado-pueden-tener-mis-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-statysu-mogyt-byt-u-moei-reklamu-v-facebook.html",
"it": "https://www.getresponse.com/it/assistenza/quali-stati-possono-avere-i-miei-annunci-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoRelatoriosEComoPossoUsaLos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-raporty-i-jak-moge-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-reports-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-informes-y-como-puedo-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otchety-i-kak-ja-mogu-ich-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/what-are-reports-and-how-can-i-use-them.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoOsVisitantesDoSiteNoFacebookAds = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanDeEtiquetadoBasico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEConfirmacaoAdicionalDePagamento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParticipacionDeNavidad = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html",
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecompensaDeContactosFieles = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html",
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoPaginasDeUpsellDoFunilDeConversao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReorientacionDelCliente = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReorientacionDelContacto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimpiezaDeListaATravesDeEtiquetasYPuntos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeguimientoPosCompra = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoHacerCrecerMiNegocioConLasFuncionesDeECommerceDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTipoDeDatosDeECommerceSeEnvianAGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeriesDeCarritoAbandonado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecordatorioDeCarritoAbandonado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeDeCumpleanos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"en": "https://www.getresponse.com/help/birthday-message.html",
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html",
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoProbarDiferentesPartesDelMensajeEnUnaPruebaAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoProgramarMisPruebasAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEsEnviadoElMensajeDeMejorRendimiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html",
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoYUsoCamposPersonalizados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasAccionesCondicionesYFiltros = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsUnaListaVinculada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoHagoUsoDeLasEtiquetas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizoLaPuntuacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGetresponseOfreceUnaAplicacionMovil = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedeCerrarseMiCuentaDebidoAMisQuejasDeSpam = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElFiltroSegmentoDinamico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUsarLaCondicionSiLaPuntuacionEnUnFlujoDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoObtenerUnReembolso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEncontrarPersonasPorMetodoDeSuscripcion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueEsMostradoElSimboloDeGetresponseEnMisEmails = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsElCostoMensualDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambioLosDiasEnQueLasAutorespuestasSalen = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNoPuedoPublicarMiFlujoDeTrabajoQueHago = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html",
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoRecibirNotificacionesEnSlack = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElSpam = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"en": "https://www.getresponse.com/help/what-is-spam.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarMisBorradoresDeMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mis-borradores-de-mensajes.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-message-drafts.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-bozze-dei-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-nachrichtenentwuerfe.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nachodjatsa-chernoviki-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-wersje-robocze-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCuantosDestinatariosDeberiaIncluirEnMiGrupoDePrueba = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarModelosDeMensajesHtml = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-modelos-de-mensajes-html.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/where-can-i-find-html-message-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-vorlagen-fuer-html-nachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodatsa-html-shablony-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-di-messaggi-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLaFuncionDePruebasAB = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoLaVistaDeCalendarioParaAutorespuestas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEnviarUnEmailCompatibleConDmarc = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakajaRaznitsaMezhduFormojILendingom = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoEliminarContactosDeUnaListaOCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaLaCondicionCampoPersonalizado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCopioContactosAOtraLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoUnaNuevaListaDeContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsElSeguimientoDeClics = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoImportarUnaListaDeContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAgregarUnNuevoNombreYDireccionDeAMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEliminoContactosDuplicadosDeMisListas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAsegurarmeQueEstoyCumpliendoConLasNormasCasl = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html",
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoEstablecerLasAccionesYCondicionesComoRecurrentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueEsImportanteElCumplimientoLegal = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-es-importante-el-cumplimiento-legal.html",
"en": "https://www.getresponse.com/help/why-is-legal-compliance-important.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-die-einhaltung-gesetzlicher-vorschriften-wichtig.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-vazno-sootvetstvie-trebovaniyam-zakonodatelstva.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nalezy-przestrzegac-przepisow-prawnych.html",
"it": "https://www.getresponse.com/it/assistenza/perche-e-importante-la-conformita-legale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoProbarGetresponseAntesDeComprarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueHerramientasOfrecenLasLandingPagesDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarMiClaveApi = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosRebotes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsLaVistaGlobalYComoLaUso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionanLosConectoresSiNo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTanBuenaEsSuCapacidadDeEntregaDelEmail = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoSaberComoFueronAnadidosMisContactosAUnaListaEspecifica = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-saber-como-fueron-anadidos-mis-contactos-a-una-lista-especifica.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-dowiem-sie-jak-kontakty-zostaly-dodane-do-konkretnej-listy.html",
"en": "https://www.getresponse.com/help/where-can-i-find-out-how-my-contacts-were-added-to-a-specific-list.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-heraus-wie-meine-kontakte-zu-einer-bestimmten-liste-hinzugefuegt-wurden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uznat-kakim-obrazom-moi-kontakty-byli-dobavleny-v-konkretnyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-scoprire-come-sono-stati-aggiunti-i-miei-contatti-a-una-lista-specifica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSignificaQueMiCuentaEstaSiendoVerificada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCanceloMiCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDeWebinarsYEventos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRecuperarContactos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"en": "https://www.getresponse.com/help/win-back.html",
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html",
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitNastraivajemyjePolaVFormeSozdannoiVRedaktoreLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarLosModelosParaConstruirFlujosDeTrabajo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatMobilnujuVersijuVRedaktoreFormVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatFormuVRedaktoreVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuMojLendingVVersiiLegacyNeOtobrazhajetsaVSeti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaOtslezhivatKonversiiGoogleAdsNaSvojemLendingeVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUdalitKodOtslezhivanijaCMojegoLendingaVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIspolzovatYandexMetrikuDlaOtslezhivanijaMoihLendingovVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaOtslezhivatSvojLendingVVersiiLegacySPomoshjuVisualWebsiteOptimizer = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAPreparareUnFilePerLImportazione = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiTipiDiCampiPersonalizzatiSonoDisponibili = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaUnContattoADisiscriversiDallaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoInoltrareIDatiDegliIscrittiAUnaPaginaDiRingraziamentoPersonalizzata = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAMoltiplicareICodiciDiMonitoraggioNellaMiaLandingPageConGoogleTagManager = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMonitorareLeMieCampagneELandingPageDiFacebook = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAdAggiungereUnaNotificaDeiCookieSullaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioATrasferireLaMiaListaDaActiveCampaignAweberConstantContactOMailchimp = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trasferire-la-mia-lista-da-active-campaign-aweber-constant-contact-o-mailchimp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeniesc-moja-liste-z-active-campaign-aweber-constant-contact-lub-mailchimp.html",
"en": "https://www.getresponse.com/help/how-can-i-migrate-my-list-from-active-campaign-aweber-constant-contact-or-mailchimp.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-migrar-mi-lista-de-active-campaign-aweber-constant-contact-o-mailchimp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-perenesti-svoj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-liste-von-active-campaign-aweber-constant-contact-oder-mailchimp-migrieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACancellareIlMioAccount = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEIlTestoPredefinito = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoEseguireAzioniECondizioniSpecificheVarieVolte = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiImpostazioniDiIscrizionePossoIncludereInUnWorkflow = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosaIncludeLaFunzionalitaDiECommerceEMonitoraggioDegliEventi = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAImpostareLeProprietaPerLaCondizioneCarrelloAbbandonato = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFunzionaIlFiltroListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEIlTassoDiCoinvolgimentoEComePossoUsarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEFileEImmaginiEComePossoUtilizzarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEODkimEComoFacoParaConfiguraLo = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuaisSaoAsDiferencasEntreUmFunilDeVendasRapidoEUmFunilDeVendasCompleto = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionamOsGeradoresAutomaticosParaFunisDeVenda = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueSaoWebinarsSobDemandaEComoUsaLos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParticipacaoDeNatal = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html",
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrioUmWebinar = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCarregarUmaListaParaUmaContaGetresponseMax = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarUmaIntegracaoSmtp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-uma-integracao-smtp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-un-integrazione-smtp.html",
"en": "https://www.getresponse.com/help/how-to-set-up-an-smtp-integration.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-smtp-integraciyu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-integracje-smtp.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-una-integracion-smtp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-eine-smtp-integration-ein.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-une-integration-smtp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoAdicionarManualmenteUmContatoAoCicloDeAutorresposta = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDeCursosOnline = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConfirmacaoDeCompraBaseadaEmConsentimento = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html",
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDePosCompra = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDeCarritoAbandonado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPrepararUmArquivoParaImportaLoViaFtp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDeCompromisoYRetencion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoConfigurarUmEMailTransacional = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-um-e-mail-transacional.html",
"it": "https://www.getresponse.com/it/assistenza/come-configurare-un-email-transazionale.html",
"en": "https://www.getresponse.com/help/how-to-configure-a-transactional-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-tranzakcionnoe-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-email-transakcyjny.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-un-correo-electronico-transaccional.html",
"de": "https://www.getresponse.com/de/hilfe/wie-konfiguriere-ich-eine-transaktions-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/comment-configurer-un-e-mail-transactionnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmaVersaoMovelDeUmFormularioLegado = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFacoParaConectarAsMinhasContasDoFacebookEGetresponse = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEAquecimentoDeEnderecosIp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAtribuirAsTagsAleatoriamenteAosContatos = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoImportarArquivosViaFtp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEDmarc = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQuandoImportarArquivosViaFtp = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCriarUmFunilDeImaDeLeads = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeguimientoPosWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarOComportamentoDosVisitantesDoSiteNumaMensagem = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUpselling = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/upselling.html",
"en": "https://www.getresponse.com/help/upselling.html",
"pl": "https://www.getresponse.pl/pomoc/upselling.html",
"es": "https://www.getresponse.com/es/ayuda/ventas-adicionales-upselling.html",
"ru": "https://www.getresponse.com/ru/help-ru/doprodazha.html",
"it": "https://www.getresponse.com/it/assistenza/upselling.html",
"br": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-incitatives.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEinwilligungsbasierteKaufbestaetigung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html",
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocionDeWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/promocion-de-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru.html",
"en": "https://www.getresponse.com/help/webinar-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/webinar-promotion.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenije-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTargetiertesFollowUpNachEinkauf = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieNachDemKaufVorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieOnlineKurseVorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPossoVerificarAsEstatisticasDasMinhasMensagensTransacionais = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-as-estatisticas-das-minhas-mensagens-transacionais.html",
"en": "https://www.getresponse.com/help/how-can-i-check-the-statistics-for-my-transactional-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-proverit-statistiky-tranzacionnych-pisem.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-statistiken-fuer-meine-transaktionsnachrichten-ueberpruefen.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-i-miei-messaggi-transazionali.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdze-statystyki-dla-emaili-transakcyjnych.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-mis-mensajes-transaccionales.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-les-statistiques-de-mes-e-mails-transactionnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoConectarMiTiendaOnlineAGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsArchivosEImagenesYComoLoUso = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonVisitantesDelSitioWebEnFacebookAds = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEstadoPuedenTenerMisAnunciosDeFacebook = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-estado-pueden-tener-mis-anuncios-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-status-moze-otrzymac-moja-reklama-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-statuses-can-my-facebook-ads-have.html",
"de": "https://www.getresponse.com/de/hilfe/welchen-status-kann-meine-facebook-anzeige-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-statysu-mogyt-byt-u-moei-reklamu-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"it": "https://www.getresponse.com/it/assistenza/quali-stati-possono-avere-i-miei-annunci-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnAnuncioConLaIntegracionDeAnunciosDeFacebook = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBasisTaggingPlan = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieLeadQualifikationVorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOfrecenUnCodigoDeFormularioHtmlPlano = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasAplicacionesDeListBuilderEnElEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-aplicaciones-de-list-builder-en-el-editor-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-formularze-pop-up-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/what-are-list-builder-apps.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-formular-apps.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-formy-prilogeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-applications-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWeihnachtsaktion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html",
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieEinbindungUndRetentionVorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDePromocionesDeVentas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKundenRetargeting = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentaAnticipada = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConversionDeContactosEnClientes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOQueEUmaLandingPageEPorqueEQuePrecisoDeUma = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZeitkritischeErinnerung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWofuerWerdenDieAbgebrochenerWarenkorb8211VorlagenVerwendet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSerieFuerAbgebrocheneWarenkoerbe = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFollowUpNachEinkauf = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguIntegrirovatSvoiLendingiVVersiiLegacySMixpanel = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIntegrirovatSvojLendingVVersiiLegacySKissmetrics = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIntegrirovatSvojLendingVVersiiLegacySBingAds = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguDobavitNeskolkoKodovOtslezhivanijaNaSvojLendingVVersiiLegacySGoogleTagManager = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIntegrirovatMoiLendingiVVersiiLegacySGoogleTagManager = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIspolzovatKodOtslezhivanijaGoogleAnalyticsNaSvojomLendingeVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguIspolzovatDomenySubdomenyIDirektoriiDlaSvoihLendingovVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitNastrojkiDns = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitCnameZapisKMojemuSubdomenu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNaznachitLendinguVVersiiLegacyMojSobstvennyjDomenIliSubdomen = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUpravlatDomenamiMoihLendingovVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUstanovitSslSertifikatDlaMoihSobstvennyhDomenov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguUstanovitUvedomlenijeOKukiFajlachNaSvojLending = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguUluchshitVidimostMojegoLendingaVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguIspolzovatSektsiiVRedaktoreLendingovVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguOptiizirovatSvojLendingVVersiiLegacyDlaMobilnychUstrojstv = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitVideoNaLendingVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitVsplyvajushijeFormyNaLendingVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiDobavitSobstvennyjKodNaLendingVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoAgregarProductosDeEtsyAMiCuenta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoTrabajarConLosModelos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaRazmestitTajmerObratnogoOtschetaNaLendingeVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJestLiNaMojemLendingeVVersiiLegacyStranitsaBlagodarnosti = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguDublirovatElementyNaSvojemLendingeVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeJaMoguNajtiParametrySvojegoLendingaVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeInstrumentyDostupnyVRedaktoreLendingovVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUtilizarElSeguimientoDeEcommerceEnUnMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMensajeDeBienvenidaBasadoEnElConsentimiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-basado-en-el-consentimiento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguOtslezhivatPosetitelejIPodpiskiNaSvojemLendingeVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodelitsaElektronnojKnigojSoSvoimiKontaktami = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConfirmacionDeCompraBasadaEnElConsentimiento = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html",
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguSohranitShablonLendingaVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitAbTestirovanijeDlaLendingovVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNajtiShablonyLendingovVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeFunkciiPredlagajutLendingiVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatLendingVVersiiLegacy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnaDkimEComePossoConfigurarla = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiSonoGliStrumentiDisponibiliAllInternoDellEditorDelleLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAModificareLeImpostazioniDiIscrizionePerLaMiaLista = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAImpostareIlTestABSulleLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareEUtilizzareICampiDelConsenso = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEUnaListaBasataSullAutorizzazionePermissionBased = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosaSignificaQuandoLImportazionePresentaProblemiDiPolicy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeAssegnareITagAiContattiInModoCasuale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAOttenereIlConsensoDaiContattiNuoviEdEsistenti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQualiFunzionalitaOffreLaStanzaDelWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAModificareUnaPaginaDiRingraziamentoELUrlDegliUtentiGiaIscritti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareUnWebinar = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVentasAdicionalesUpselling = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/ventas-adicionales-upselling.html",
"en": "https://www.getresponse.com/help/upselling.html",
"pl": "https://www.getresponse.pl/pomoc/upselling.html",
"de": "https://www.getresponse.com/de/hilfe/upselling.html",
"ru": "https://www.getresponse.com/ru/help-ru/doprodazha.html",
"it": "https://www.getresponse.com/it/assistenza/upselling.html",
"br": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-incitatives.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambioUnaPaginaDeAgradecimientoYUnaUrlYaSuscritaEnElEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoActivarMiFormularioDeVentanaEmergenteDeLegadoConUnBotonOLink = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoConfigurarMiFormularioDeLegadoParaQueAparezcaComoPopOverEnMiPagina = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreGetresponseChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-getresponse-chats.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-chaty-v-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-chats.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-chat-di-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-funkcji-czaty-w-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-chats-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-getresponse-chats.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-chats-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeLendingIDlaChegoOnNuzhen = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaIntegrirovatSvojAkkauntSoSquare = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatVoronkuProdvizhenijaPlatnogoVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatVoronkuProdvizhenijaBesplatnogoVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatVoronkuSLidMagnitom = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatVoronkuDlaRasshirenijaBazy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetAvtomaticheskijGeneratorVVoronkachProdazh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznitsaMezhduProstojIProdvinutojVoronkamiProdazh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatProdajushujuStranitsu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatVoronkuDlaProdazhiTovara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeStranitsyDoprodazhiVVoronkahKonversii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChemOtlichajutsaStranitsyDostupnyjeVVoronkah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeVoronkaKonversii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeVebinaryPoTrebovanijuIKakImiPolzovatsa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiDobavitDokladchikovVVebinar = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html",
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakojeFormatyFajlovMozhnoZagruzhatVVebinarnujuKomnatu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakMozhnoVzaimodejstvovatSoZritelamiVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-mozhno-vzaimodejstvovat-so-zritelami-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pozwolic-uczestnikom-webinaru-komunikowac-sie-ze-mna.html",
"en": "https://www.getresponse.com/help/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-teilnehmer-waehrend-des-webinars-mit-mir-kommunizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-permitir-a-mis-participantes-comunicarse-conmigo-durante-el-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-permettre-a-mes-participants-de-communiquer-avec-moi-pendant-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiSohranitVebinarnujuKomnatuKakShablon = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaTranslirovatSvoiVebinaryNaFacebookYoutubeIliLinkedin = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakovyTehnicheskijeTrebovanijaIMetodyUstranenijaNepoladokDlaVebinarov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeFunkciiPredlagajetNovajaVebinarnajaKomnata = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNajtiStatistikuVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPosmotretStatistikuPriglashenijINapominanijVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakEksportirovatChatVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMogutLiPosetiteliPerehoditSLendingaNaVebinarNapramuju = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaVysylatPriglashenijaNaVebinarLudjamKotorjeNeNahodatsaVMoemSpiskeKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatVebinar = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVebinaryMogutPomochMneRashiritSvojSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeVebinaryGetresponse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoprodazha = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/doprodazha.html",
"en": "https://www.getresponse.com/help/upselling.html",
"pl": "https://www.getresponse.pl/pomoc/upselling.html",
"de": "https://www.getresponse.com/de/hilfe/upselling.html",
"es": "https://www.getresponse.com/es/ayuda/ventas-adicionales-upselling.html",
"it": "https://www.getresponse.com/it/assistenza/upselling.html",
"br": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"pt": "https://www.getresponse.com/pt/ajuda/vendas-adicionais.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-incitatives.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKonversijaKontaktovVKlijentov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSkidkiDlaPervyhPokupatelej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyProdazhiIPromo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyArenTMyAutorespondersGoingOut = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyDidnTMyContactsGetMyNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-didn-t-my-contacts-get-my-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/warum-haben-meine-kontakte-meine-newsletter-nicht-erhalten.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-kontakty-ne-poluchili-moju-rassylku.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-kontakty-nie-otrzymaly-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-contatos-nao-receberam-a-minha-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-contactos-no-han-recibido-mi-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-contatti-non-hanno-ricevuto-la-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-contacts-n-ont-ils-pas-recu-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToPromoteYourProductsInTheMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-promote-your-products-in-the-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-promowac-produkty-w-wiadomosciach.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodvigat-svoi-tovaryi-v-rassyilkah.html",
"it": "https://www.getresponse.com/it/assistenza/come-promuovere-i-tuoi-prodotti-nei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-promover-seus-produtos-nas-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-promocionar-sus-productos-en-los-mensajes.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-man-die-produkte-in-nachrichten-bewerben.html",
"fr": "https://www.getresponse.com/fr/aide/comment-promouvoir-vos-produits-dans-les-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoISearchForContactsBasedOnTheConsentTheyVeGiven = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIProcessDataOutsideTheEuUnderTheGdpr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-process-data-outside-the-eu-under-the-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przetwarzac-dane-spoza-ue-korzystajac-z-rodo.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-tratar-los-datos-fuera-de-la-ue-gracias-al-rgpd.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-processar-dados-fora-da-ue-sob-o-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/posso-trattare-i-dati-al-di-fuori-dell-ue-ai-sensi-del-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-obrabatyivat-dannyie-za-predelami-es-v-sootvetstvii-s-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-im-rahmen-der-dsgvo-daten-ausserhalb-der-eu-verarbeiten.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-traiter-des-donnees-en-dehors-de-l-ue-dans-le-cadre-du-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDataController = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/data-controller.html",
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSensitivePersonalData = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCustomReports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-reports.html",
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSale = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sale.html",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html",
"fr": "https://www.getresponse.com/fr/aide/vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptOut = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/opt-out.html",
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGreylist = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/greylist.html",
"pl": "https://www.getresponse.pl/pomoc/szara-lista-2.html",
"es": "https://www.getresponse.com/es/ayuda/lista-gris.html",
"ru": "https://www.getresponse.com/ru/help-ru/seryj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/lista-grigia.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-cinza.html",
"fr": "https://www.getresponse.com/fr/aide/liste-grise.html",
"de": "https://www.getresponse.com/de/hilfe/grauliste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/marketing-campaign.html",
"pl": "https://www.getresponse.pl/pomoc/kampania.html",
"de": "https://www.getresponse.com/de/hilfe/campaign-definition.html",
"es": "https://www.getresponse.com/es/ayuda/campaign-definition.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaja-kampania.html",
"it": "https://www.getresponse.com/it/assistenza/campagna.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRaport = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/raport.html",
"en": "https://www.getresponse.com/help/report.html",
"de": "https://www.getresponse.com/de/hilfe/bericht.html",
"es": "https://www.getresponse.com/es/ayuda/informe.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet.html",
"it": "https://www.getresponse.com/it/assistenza/report.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorio.html",
"fr": "https://www.getresponse.com/fr/aide/rapport.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSpfSenderPolicyFramework = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/spf-sender-policy-framework.html",
"br": "https://www.getresponse.com/pt/ajuda/spf.html",
"pt": "https://www.getresponse.com/pt/ajuda/spf.html",
"en": "https://www.getresponse.com/help/spf.html",
"de": "https://www.getresponse.com/de/hilfe/spf.html",
"es": "https://www.getresponse.com/es/ayuda/spf.html",
"ru": "https://www.getresponse.com/ru/help-ru/spf.html",
"it": "https://www.getresponse.com/it/assistenza/spf.html",
"fr": "https://www.getresponse.com/fr/aide/spf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProdvizhenijeVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenije-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru.html",
"en": "https://www.getresponse.com/help/webinar-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/webinar-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPismoPosleVebinara = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPozdravlenijeSDniomRozhdenija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"en": "https://www.getresponse.com/help/birthday-message.html",
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html",
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyVerbinaryISobytija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSprzedaz = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/sprzedaz.html",
"en": "https://www.getresponse.com/help/sale.html",
"it": "https://www.getresponse.com/it/assistenza/vendita.html",
"es": "https://www.getresponse.com/es/ayuda/venta.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodaza.html",
"de": "https://www.getresponse.com/de/hilfe/verkauf.html",
"br": "https://www.getresponse.com/pt/ajuda/venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda.html",
"fr": "https://www.getresponse.com/fr/aide/vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZgloszenieSpamu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zgloszenie-spamu.html",
"en": "https://www.getresponse.com/help/spam-complaint.html",
"de": "https://www.getresponse.com/de/hilfe/spam-complaint.html",
"es": "https://www.getresponse.com/es/ayuda/denuncia-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zhaloba-na-spam.html",
"it": "https://www.getresponse.com/it/assistenza/reclamo-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/reclamacao-de-spam.html",
"fr": "https://www.getresponse.com/fr/aide/plainte-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKonwersjaLeadow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/konwersja-leadow.html",
"en": "https://www.getresponse.com/help/lead-conversion.html",
"de": "https://www.getresponse.com/de/hilfe/lead-conversion.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversiya-lida.html",
"it": "https://www.getresponse.com/it/assistenza/conversione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPozyskiwanieLeadow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/pozyskiwanie-leadow.html",
"en": "https://www.getresponse.com/help/leads-generation.html",
"de": "https://www.getresponse.com/de/hilfe/leads-generation.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-clientes-potenciales.html",
"ru": "https://www.getresponse.com/ru/help-ru/generaciya-lidov.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-lead.html",
"br": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/geracao-de-leads.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKampaniaEMailowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kampania-e-mailowa.html",
"en": "https://www.getresponse.com/help/email-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/email-campaign.html",
"es": "https://www.getresponse.com/es/ayuda/campana-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-kampaniya.html",
"it": "https://www.getresponse.com/it/assistenza/campagna-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/campanha-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-d-e-mailing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocjaEmailowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/promocja-emailowa.html",
"en": "https://www.getresponse.com/help/email-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/email-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-por-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-por-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-par-courrier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDostarczona = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dostarczona.html",
"en": "https://www.getresponse.com/help/delivered.html",
"de": "https://www.getresponse.com/de/hilfe/delivered.html",
"es": "https://www.getresponse.com/es/ayuda/entregado.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-dostavleno.html",
"it": "https://www.getresponse.com/it/assistenza/consegnato.html",
"br": "https://www.getresponse.com/pt/ajuda/entregue.html",
"pt": "https://www.getresponse.com/pt/ajuda/entregue.html",
"fr": "https://www.getresponse.com/fr/aide/livre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdministratorDanych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/administrator-danych.html",
"en": "https://www.getresponse.com/help/data-controller.html",
"it": "https://www.getresponse.com/it/assistenza/responsabile-del-trattamento-dei-dati.html",
"ru": "https://www.getresponse.com/ru/help-ru/kotroller-dannych.html",
"es": "https://www.getresponse.com/es/ayuda/controlador-de-datos.html",
"de": "https://www.getresponse.com/de/hilfe/datenverantwortlicher.html",
"br": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/controlador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/controleur-de-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodmiotPrzetwarzajacyDane = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/podmiot-przetwarzajacy-dane.html",
"en": "https://www.getresponse.com/help/data-processor.html",
"it": "https://www.getresponse.com/it/assistenza/data-processor.html",
"es": "https://www.getresponse.com/es/ayuda/procesador-de-datos.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotchik-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/datenverarbeiter.html",
"br": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"pt": "https://www.getresponse.com/pt/ajuda/processador-de-dados.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWrazliweDaneOsobowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wrazliwe-dane-osobowe.html",
"en": "https://www.getresponse.com/help/sensitive-personal-data.html",
"it": "https://www.getresponse.com/it/assistenza/dati-sensibili.html",
"es": "https://www.getresponse.com/es/ayuda/datos-personales-sensibles.html",
"ru": "https://www.getresponse.com/ru/help-ru/konfidencialnye-lichnye-dannye.html",
"de": "https://www.getresponse.com/de/hilfe/die-sensiblen-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/dados-pessoais-sensiveis.html",
"fr": "https://www.getresponse.com/fr/aide/donnees-personnelles-sensibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrzetwarzanieDanychOsobowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/przetwarzanie-danych-osobowych.html",
"en": "https://www.getresponse.com/help/personal-data-processing.html",
"it": "https://www.getresponse.com/it/assistenza/personal-data-processing.html",
"es": "https://www.getresponse.com/es/ayuda/tratamiento-de-datos-personales.html",
"ru": "https://www.getresponse.com/ru/help-ru/obrabotka-personalnych-dannych.html",
"de": "https://www.getresponse.com/de/hilfe/die-verarbeitung-der-personenbezogenen-daten.html",
"br": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"pt": "https://www.getresponse.com/pt/ajuda/processamento-de-dados-pessoais.html",
"fr": "https://www.getresponse.com/fr/aide/traitement-des-donnees-personnelles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZwrot = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zwrot.html",
"en": "https://www.getresponse.com/help/bounced.html",
"de": "https://www.getresponse.com/de/hilfe/bounced.html",
"es": "https://www.getresponse.com/es/ayuda/rebotados.html",
"ru": "https://www.getresponse.com/ru/help-ru/oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicoes.html",
"fr": "https://www.getresponse.com/fr/aide/rebondi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZgodnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zgodnosc.html",
"en": "https://www.getresponse.com/help/alignment.html",
"de": "https://www.getresponse.com/de/hilfe/alignment.html",
"es": "https://www.getresponse.com/es/ayuda/alineacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/vyravnivanie.html",
"it": "https://www.getresponse.com/it/assistenza/alignment.html",
"br": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/alinhamento.html",
"fr": "https://www.getresponse.com/fr/aide/alignement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomoscMasowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-masowa.html",
"en": "https://www.getresponse.com/help/bulk-email.html",
"de": "https://www.getresponse.com/de/hilfe/bulk-email.html",
"es": "https://www.getresponse.com/es/ayuda/correo-masivo.html",
"ru": "https://www.getresponse.com/ru/help-ru/massovaya-rassylka.html",
"it": "https://www.getresponse.com/it/assistenza/email-di-massa.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-massa.html",
"fr": "https://www.getresponse.com/fr/aide/envoi-massif-de-courriels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUsuniete = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/usuniete.html",
"en": "https://www.getresponse.com/help/removals.html",
"de": "https://www.getresponse.com/de/hilfe/removals.html",
"es": "https://www.getresponse.com/es/ayuda/bajas.html",
"ru": "https://www.getresponse.com/ru/help-ru/otpisavshiesya-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/contatti-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/remocoes.html",
"fr": "https://www.getresponse.com/fr/aide/suppressions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWysylka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wysylka.html",
"en": "https://www.getresponse.com/help/broadcast.html",
"de": "https://www.getresponse.com/de/hilfe/broadcast.html",
"es": "https://www.getresponse.com/es/ayuda/transmision.html",
"ru": "https://www.getresponse.com/ru/help-ru/translyaciya.html",
"it": "https://www.getresponse.com/it/assistenza/broadcast.html",
"br": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/transmissao.html",
"fr": "https://www.getresponse.com/fr/aide/transmission.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUstawaCanSpam = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ustawa-can-spam.html",
"en": "https://www.getresponse.com/help/can-spam-act.html",
"de": "https://www.getresponse.com/de/hilfe/can-spam-act.html",
"es": "https://www.getresponse.com/es/ayuda/ley-can-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/zakon-can-spam.html",
"it": "https://www.getresponse.com/it/assistenza/legge-can-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/ato-can-spam.html",
"fr": "https://www.getresponse.com/fr/aide/loi-can-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormularzZapisu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/formularz-zapisu.html",
"en": "https://www.getresponse.com/help/web-form.html",
"de": "https://www.getresponse.com/de/hilfe/web-form.html",
"es": "https://www.getresponse.com/es/ayuda/formulario-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/veb-forma.html",
"it": "https://www.getresponse.com/it/assistenza/modulo-web.html",
"br": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"pt": "https://www.getresponse.com/pt/ajuda/formulario-web.html",
"fr": "https://www.getresponse.com/fr/aide/formulaire-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhitelistBialaListaSubskrybentow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/whitelist-biala-lista-subskrybentow.html",
"en": "https://www.getresponse.com/help/whitelist.html",
"de": "https://www.getresponse.com/de/hilfe/whitelist.html",
"es": "https://www.getresponse.com/es/ayuda/lista-blanca.html",
"ru": "https://www.getresponse.com/ru/help-ru/belyj-spisok.html",
"it": "https://www.getresponse.com/it/assistenza/whitelist.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-branca.html",
"fr": "https://www.getresponse.com/fr/aide/liste-blanche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZip = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zip.html",
"en": "https://www.getresponse.com/help/zip-file.html",
"de": "https://www.getresponse.com/de/hilfe/zip-file.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-zip.html",
"ru": "https://www.getresponse.com/ru/help-ru/zip-fajl.html",
"it": "https://www.getresponse.com/it/assistenza/zip-file.html",
"br": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"pt": "https://www.getresponse.com/pt/ajuda/ficheiro-zip.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-zip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStronaZPodziekowaniem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/strona-z-podziekowaniem.html",
"en": "https://www.getresponse.com/help/thank-you-page.html",
"de": "https://www.getresponse.com/de/hilfe/thank-you-page.html",
"es": "https://www.getresponse.com/es/ayuda/pagina-de-agradecimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/stranica-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/pagina-di-ringraziamento.html",
"br": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubskrybent = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/subskrybent.html",
"en": "https://www.getresponse.com/help/subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpischiki.html",
"it": "https://www.getresponse.com/it/assistenza/contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRaportZSubskrypcji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/raport-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/custom-reports.html",
"de": "https://www.getresponse.com/de/hilfe/subscription-reports.html",
"es": "https://www.getresponse.com/es/ayuda/informes-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/otchet-po-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/report-di-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios-de-subscricao.html",
"fr": "https://www.getresponse.com/fr/aide/rapports-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnkieta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ankieta.html",
"en": "https://www.getresponse.com/help/survey.html",
"de": "https://www.getresponse.com/de/hilfe/survey.html",
"es": "https://www.getresponse.com/es/ayuda/encuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/opros.html",
"it": "https://www.getresponse.com/it/assistenza/sondaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"pt": "https://www.getresponse.com/pt/ajuda/inquerito.html",
"fr": "https://www.getresponse.com/fr/aide/enquete.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTestySkutecznosciAngSplitTest = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/testy-skutecznosci-ang-split-test.html",
"en": "https://www.getresponse.com/help/split-test.html",
"de": "https://www.getresponse.com/de/hilfe/split-test.html",
"es": "https://www.getresponse.com/es/ayuda/pruebas-multivariantes.html",
"ru": "https://www.getresponse.com/ru/help-ru/split-test.html",
"it": "https://www.getresponse.com/it/assistenza/split-test.html",
"br": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/teste-de-separacao.html",
"fr": "https://www.getresponse.com/fr/aide/essai-fractionne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTematWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/temat-wiadomosci.html",
"en": "https://www.getresponse.com/help/subject-line.html",
"de": "https://www.getresponse.com/de/hilfe/subject-line.html",
"es": "https://www.getresponse.com/es/ayuda/linea-de-asunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/tema-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/oggetto.html",
"br": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"pt": "https://www.getresponse.com/pt/ajuda/linha-de-assunto.html",
"fr": "https://www.getresponse.com/fr/aide/ligne-d-objet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZwrotMiekki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zwrot-miekki.html",
"en": "https://www.getresponse.com/help/soft-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/soft-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-transitorio.html",
"ru": "https://www.getresponse.com/ru/help-ru/vremennaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-morbido.html",
"br": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"pt": "https://www.getresponse.com/pt/ajuda/bounce-suave.html",
"fr": "https://www.getresponse.com/fr/aide/rebond-doux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPoleOdpowiedzDo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/pole-odpowiedz-do.html",
"en": "https://www.getresponse.com/help/reply-to-field.html",
"de": "https://www.getresponse.com/de/hilfe/reply-to-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-respuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-dlya-otveta.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-di-risposta.html",
"br": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"pt": "https://www.getresponse.com/pt/ajuda/campo-de-responder-para.html",
"fr": "https://www.getresponse.com/fr/aide/champ-de-reponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRefNumerSledzacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ref-numer-sledzacy.html",
"en": "https://www.getresponse.com/help/ref-tracking-number.html",
"de": "https://www.getresponse.com/de/hilfe/ref-tracking-number.html",
"es": "https://www.getresponse.com/es/ayuda/numero-de-seguimiento-de-la-referencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/nomer-dla-otslezivaniya.html",
"it": "https://www.getresponse.com/it/assistenza/numero-di-riferimento.html",
"br": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/numero-de-rastreio-de-referencia.html",
"fr": "https://www.getresponse.com/fr/aide/ref-numero-de-suivi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdresPocztowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/adres-pocztowy.html",
"en": "https://www.getresponse.com/help/postal-address.html",
"de": "https://www.getresponse.com/de/hilfe/postal-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-postal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-postale.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-postal.html",
"fr": "https://www.getresponse.com/fr/aide/adresse-postale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPolaPredefiniowane = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"en": "https://www.getresponse.com/help/predefined-text.html",
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html",
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomoscWFormacieTekstowym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-w-formacie-tekstowym.html",
"en": "https://www.getresponse.com/help/plain-text.html",
"de": "https://www.getresponse.com/de/hilfe/plain-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-sin-formato.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/testo-semplice.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-simples-plain-text.html",
"fr": "https://www.getresponse.com/fr/aide/texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMarketingZaPrzyzwoleniem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/marketing-za-przyzwoleniem.html",
"en": "https://www.getresponse.com/help/permission-based-marketing.html",
"de": "https://www.getresponse.com/de/hilfe/permission-based-marketing.html",
"es": "https://www.getresponse.com/es/ayuda/marketing-basado-en-permisos.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketing-na-osnove-razreshenij.html",
"it": "https://www.getresponse.com/it/assistenza/marketing-basato-sul-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-com-base-em-permissoes.html",
"fr": "https://www.getresponse.com/fr/aide/marketing-base-sur-les-permissions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPersonalizacja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/personalizacja.html",
"en": "https://www.getresponse.com/help/personalization.html",
"de": "https://www.getresponse.com/de/hilfe/personalization.html",
"es": "https://www.getresponse.com/es/ayuda/personalizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/personalizzazione.html",
"br": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/personalizacao.html",
"fr": "https://www.getresponse.com/fr/aide/personnalisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOptOutRezygnacjaZSubskrypcji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/opt-out-rezygnacja-z-subskrypcji.html",
"en": "https://www.getresponse.com/help/opt-out.html",
"de": "https://www.getresponse.com/de/hilfe/opt-out-unsubscribe.html",
"es": "https://www.getresponse.com/es/ayuda/darse-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/otkaz-ot-podpiski.html",
"it": "https://www.getresponse.com/it/assistenza/opt-out-annullamento-dell-iscrizione.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-out-cancelar-a-inscricao.html",
"fr": "https://www.getresponse.com/fr/aide/opt-out-action-de-desabonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStatystkiWysylek = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/statystki-wysylek.html",
"en": "https://www.getresponse.com/help/performance-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/performance-statistics.html",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-desempeno.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-dejstvij.html",
"it": "https://www.getresponse.com/it/assistenza/statistiche-di-rendimento.html",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-desempenho.html",
"fr": "https://www.getresponse.com/fr/aide/statistiques-de-performance.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNotificationPowiadomienia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/notification-powiadomienia.html",
"en": "https://www.getresponse.com/help/notification.html",
"de": "https://www.getresponse.com/de/hilfe/notification.html",
"es": "https://www.getresponse.com/es/ayuda/notificacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/uvedomlenie-o-podpiske.html",
"it": "https://www.getresponse.com/it/assistenza/notificazione.html",
"br": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/notificacao.html",
"fr": "https://www.getresponse.com/fr/aide/notification.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWskaznikOtwarc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wskaznik-otwarc.html",
"en": "https://www.getresponse.com/help/open-rate.html",
"de": "https://www.getresponse.com/de/hilfe/open-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-otkrytij.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-apertura.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-abertura.html",
"fr": "https://www.getresponse.com/fr/aide/taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSzablonNewslettera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/szablon-newslettera.html",
"en": "https://www.getresponse.com/help/newsletter-template.html",
"de": "https://www.getresponse.com/de/hilfe/newsletter-template.html",
"es": "https://www.getresponse.com/es/ayuda/modelo-de-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/shablon-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/modello-di-newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/modelo-de-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/modele-de-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubskrypcjaBezPotwierdzenia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-bez-potwierdzenia.html",
"en": "https://www.getresponse.com/help/non-confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/non-confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/opt-in-no-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podpiska-bez-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/opt-in-non-confermato.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-nao-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-non-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostFormatWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/format-wiadomosci.html",
"en": "https://www.getresponse.com/help/message-format.html",
"de": "https://www.getresponse.com/de/hilfe/message-format.html",
"es": "https://www.getresponse.com/es/ayuda/formato-del-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/format-soobshheniya.html",
"it": "https://www.getresponse.com/it/assistenza/formato-del-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/formato-da-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/format-du-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZarzadzanieLista = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-lista.html",
"en": "https://www.getresponse.com/help/list-management.html",
"de": "https://www.getresponse.com/de/hilfe/list-management.html",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-spiskami.html",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/gestao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSegmentacjaListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/segmentacja-listy.html",
"en": "https://www.getresponse.com/help/list-segmentation.html",
"de": "https://www.getresponse.com/de/hilfe/list-segmentation.html",
"es": "https://www.getresponse.com/es/ayuda/segmentacion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/segmentaciya-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/segmentazione-della-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/segmentacao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/segmentation-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListaMailingowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/lista-mailingowa.html",
"en": "https://www.getresponse.com/help/mailing-list.html",
"de": "https://www.getresponse.com/de/hilfe/mailing-list.html",
"es": "https://www.getresponse.com/es/ayuda/lista-de-correos.html",
"ru": "https://www.getresponse.com/ru/help-ru/spisok-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/lista-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/lista-de-correspondencia.html",
"fr": "https://www.getresponse.com/fr/aide/liste-d-adresses-electroniques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAdresIp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/adres-ip.html",
"en": "https://www.getresponse.com/help/ip-address.html",
"de": "https://www.getresponse.com/de/hilfe/ip-address.html",
"es": "https://www.getresponse.com/es/ayuda/direccion-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/ip-adres.html",
"it": "https://www.getresponse.com/it/assistenza/indirizzo-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/endereco-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/addresse-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokowanieAdresuIp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/blokowanie-adresu-ip.html",
"en": "https://www.getresponse.com/help/ip-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/ip-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-ip.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-ip.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomoscNatychmiastowaBlyskawiczna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-natychmiastowa-blyskawiczna.html",
"en": "https://www.getresponse.com/help/instant-message.html",
"de": "https://www.getresponse.com/de/hilfe/instant-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-instantaneo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mgnovennoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/instant-message.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-instantanea.html",
"fr": "https://www.getresponse.com/fr/aide/message-instantane.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyOnlajnKursy = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNapominanijeBroshenojKorzine = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSerijaPisemOBroshennojKorzine = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyBroshennajaKorzina = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTreueKundenBelohnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html",
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKontaktRetargeting = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRueckgewinnung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"en": "https://www.getresponse.com/help/win-back.html",
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html",
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBasisScoringPlan = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoSaberQueVersionDelFormularioDeLegadoMeDaLaTasaDeConversionMasAlta = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-saber-que-version-del-formulario-de-legado-me-da-la-tasa-de-conversion-mas-alta.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sprawdzic-ktora-wersja-formularza-legacy-zapewnia-mi-najlepsza-konwersje.html",
"en": "https://www.getresponse.com/help/how-do-i-find-out-which-version-of-the-legacy-form-gives-me-the-highest-conversion-rate.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-heraus-welches-legacy-webformular-mir-die-hoechste-conversion-rate-beschert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-kakaya-versiya-formyi-legacy-imeet-naibolshii-pokazatel-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-savoir-quelle-version-de-mon-ancien-formulaire-me-donne-le-taux-de-conversion-le-plus-eleve.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoInstalarMiFormularioDeLegadoEnUnaPaginaDeWordpress = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstaloUnFormularioDeLegadoEnMiPaginaWeb = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-instalo-un-formulario-de-legado-en-mi-pagina-web.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-formularz-legacy-na-swojej-stronie.html",
"en": "https://www.getresponse.com/help/how-do-i-install-a-legacy-form-on-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-ein-legacy-webformular-auf-meiner-seite.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-redaktora-legacy-na-svoey-stranitse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-installo-un-modulo-sulla-mia-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreUnFormularioYUnaLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAgregoCamposPersonalizadosAMiFormularioDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostInterwal = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/interwal.html",
"en": "https://www.getresponse.com/help/interval.html",
"de": "https://www.getresponse.com/de/hilfe/interval.html",
"es": "https://www.getresponse.com/es/ayuda/intervalo.html",
"ru": "https://www.getresponse.com/ru/help-ru/interval.html",
"it": "https://www.getresponse.com/it/assistenza/intervallo.html",
"br": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/intervalo.html",
"fr": "https://www.getresponse.com/fr/aide/intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNieaktywniSubskrybenci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/nieaktywni-subskrybenci.html",
"en": "https://www.getresponse.com/help/inactive-subscribers.html",
"de": "https://www.getresponse.com/de/hilfe/inactive-subscribers.html",
"es": "https://www.getresponse.com/es/ayuda/suscriptores-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/neaktivnye-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/sottoscrittori-inattivi.html",
"br": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/subscritores-inativos.html",
"fr": "https://www.getresponse.com/fr/aide/abonnes-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokowanieObrazkow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/blokowanie-obrazkow.html",
"en": "https://www.getresponse.com/help/image-blocking.html",
"de": "https://www.getresponse.com/de/hilfe/image-blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo-de-imagenes.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka-izobrazhenij.html",
"it": "https://www.getresponse.com/it/assistenza/blocco-delle-immagini.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio-de-imagem.html",
"fr": "https://www.getresponse.com/fr/aide/blocage-de-l-image.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGeoMapa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/geo-mapa.html",
"de": "https://www.getresponse.com/de/hilfe/geo-map.html",
"es": "https://www.getresponse.com/es/ayuda/mapa-geografico.html",
"ru": "https://www.getresponse.com/ru/help-ru/mestopolozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/geo-map.html",
"br": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html",
"pt": "https://www.getresponse.com/pt/ajuda/mapa-geografico.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZwrotTwardy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zwrot-twardy.html",
"en": "https://www.getresponse.com/help/hard-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/hard-bounce.html",
"es": "https://www.getresponse.com/es/ayuda/rebote-permanente.html",
"ru": "https://www.getresponse.com/ru/help-ru/postoyannaya-oshibka-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/rimbalzo-duro.html",
"br": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"pt": "https://www.getresponse.com/pt/ajuda/rejeicao-forte.html",
"fr": "https://www.getresponse.com/fr/aide/hard-bounce-rebondissement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomoscHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-html.html",
"de": "https://www.getresponse.com/de/hilfe/html-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/html-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-html.html",
"br": "https://www.getresponse.com/pt/ajuda/html-message.html",
"pt": "https://www.getresponse.com/pt/ajuda/html-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzestotliwoscWyslanychEmaili = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czestotliwosc-wyslanych-emaili.html",
"en": "https://www.getresponse.com/help/frequency.html",
"de": "https://www.getresponse.com/de/hilfe/frequency.html",
"es": "https://www.getresponse.com/es/ayuda/frecuencia.html",
"ru": "https://www.getresponse.com/ru/help-ru/chastota.html",
"it": "https://www.getresponse.com/it/assistenza/frequenza.html",
"br": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/frequencia.html",
"fr": "https://www.getresponse.com/fr/aide/frequence.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPoleNadawcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/pole-nadawcy.html",
"en": "https://www.getresponse.com/help/from-field.html",
"de": "https://www.getresponse.com/de/hilfe/from-field.html",
"es": "https://www.getresponse.com/es/ayuda/campo-de-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/adres-otpravitelya.html",
"it": "https://www.getresponse.com/it/assistenza/from-field.html",
"br": "https://www.getresponse.com/pt/ajuda/remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/remetente.html",
"fr": "https://www.getresponse.com/fr/aide/le-champ-de.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGenerowanieKoduHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/generowanie-kodu-html.html",
"en": "https://www.getresponse.com/help/generating-html.html",
"de": "https://www.getresponse.com/de/hilfe/generating-html.html",
"es": "https://www.getresponse.com/es/ayuda/generacion-de-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-html.html",
"it": "https://www.getresponse.com/it/assistenza/generazione-di-html.html",
"br": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/gerando-html.html",
"fr": "https://www.getresponse.com/fr/aide/generation-de-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZalacznik = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zalacznik.html",
"en": "https://www.getresponse.com/help/file-attachment.html",
"de": "https://www.getresponse.com/de/hilfe/file-attachment.html",
"es": "https://www.getresponse.com/es/ayuda/archivo-adjunto.html",
"ru": "https://www.getresponse.com/ru/help-ru/fajl-vlozhenie.html",
"it": "https://www.getresponse.com/it/assistenza/file-allegato.html",
"br": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"pt": "https://www.getresponse.com/pt/ajuda/arquivo-anexado.html",
"fr": "https://www.getresponse.com/fr/aide/fichier-joint.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStopkaMaila = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/stopka-maila.html",
"en": "https://www.getresponse.com/help/footer.html",
"de": "https://www.getresponse.com/de/hilfe/footer.html",
"es": "https://www.getresponse.com/es/ayuda/pie-de-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/futer.html",
"it": "https://www.getresponse.com/it/assistenza/pie-di-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/rodape.html",
"fr": "https://www.getresponse.com/fr/aide/pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNaglowkiEmaila = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/naglowki-emaila.html",
"en": "https://www.getresponse.com/help/email-headers.html",
"de": "https://www.getresponse.com/de/hilfe/email-headers.html",
"es": "https://www.getresponse.com/es/ayuda/encabezados-de-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/zagolovki-elektronnoi-pochti.html",
"it": "https://www.getresponse.com/it/assistenza/intestazioni-e-mail-e-mail-headers.html",
"br": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cabecalhos-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/en-tetes-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNazwaDomeny = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/nazwa-domeny.html",
"de": "https://www.getresponse.com/de/hilfe/domain-name.html",
"es": "https://www.getresponse.com/es/ayuda/nombre-de-dominio.html",
"ru": "https://www.getresponse.com/ru/help-ru/domennoe-imya.html",
"it": "https://www.getresponse.com/it/assistenza/nome-di-dominio.html",
"br": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/nome-de-dominio.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKlientPocztyElektronicznej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/klient-poczty-elektronicznej.html",
"en": "https://www.getresponse.com/help/email-client.html",
"de": "https://www.getresponse.com/de/hilfe/email-client.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochtovyj-klient.html",
"it": "https://www.getresponse.com/it/assistenza/cliente-di-posta-elettronica.html",
"br": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/cliente-de-email.html",
"es": "https://www.getresponse.com/es/ayuda/cliente-de-email.html",
"fr": "https://www.getresponse.com/fr/aide/client-de-messagerie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSerwerDedykowany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/serwer-dedykowany.html",
"en": "https://www.getresponse.com/help/dedicated-server.html",
"de": "https://www.getresponse.com/de/hilfe/dedicated-server.html",
"es": "https://www.getresponse.com/es/ayuda/servidor-dedicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/vydelennyj-server.html",
"it": "https://www.getresponse.com/it/assistenza/server-dedicato.html",
"br": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/servidor-dedicado.html",
"fr": "https://www.getresponse.com/fr/aide/serveur-dedie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWskaznikDostarczalnosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wskaznik-dostarczalnosci.html",
"en": "https://www.getresponse.com/help/deliverability-rate.html",
"de": "https://www.getresponse.com/de/hilfe/deliverability-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/uroven-dostavlyaemosti.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-consegna.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-entregabilidade.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-delivrabilite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAutomatyzacja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/automatyzacja.html",
"en": "https://www.getresponse.com/help/automation.html",
"de": "https://www.getresponse.com/de/hilfe/automation.html",
"es": "https://www.getresponse.com/es/ayuda/automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizaciya.html",
"it": "https://www.getresponse.com/it/assistenza/automation.html",
"br": "https://www.getresponse.com/pt/ajuda/automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/automacao.html",
"fr": "https://www.getresponse.com/fr/aide/automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsDmarcAndHowToConfigureIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-dmarc-and-how-to-configure-it.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-dmarc.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-e-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dmarc.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPredefinedText = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/pola-predefiniowane.html",
"de": "https://www.getresponse.com/de/hilfe/der-vordefinierte-text.html",
"es": "https://www.getresponse.com/es/ayuda/texto-predefinido-2.html",
"it": "https://www.getresponse.com/it/assistenza/testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"pt": "https://www.getresponse.com/pt/ajuda/texto-pre-definido.html",
"fr": "https://www.getresponse.com/fr/aide/texte-predefini.html",
"ru": "https://www.getresponse.com/ru/help-ru/predvaritelno-zadannyj-tekst.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCiasteczka = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ciasteczka.html",
"en": "https://www.getresponse.com/help/cookie.html",
"de": "https://www.getresponse.com/de/hilfe/cookie.html",
"es": "https://www.getresponse.com/es/ayuda/cookie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kuki.html",
"it": "https://www.getresponse.com/it/assistenza/cookie.html",
"br": "https://www.getresponse.com/pt/ajuda/cookie.html",
"pt": "https://www.getresponse.com/pt/ajuda/cookie.html",
"fr": "https://www.getresponse.com/fr/aide/cookie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomoscPotwierdzajacaSubskrypcje = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-potwierdzajaca-subskrypcje.html",
"en": "https://www.getresponse.com/help/confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/confirmation-message.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-confirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-podtverzhdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-conferma.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-confirmacao.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSubskrypcjaZPotwierdzeniem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/subskrypcja-z-potwierdzeniem.html",
"en": "https://www.getresponse.com/help/confirmed-opt-in.html",
"de": "https://www.getresponse.com/de/hilfe/confirmed-opt-in.html",
"es": "https://www.getresponse.com/es/ayuda/consentimiento-confirmado.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdennaia-podpiska.html",
"it": "https://www.getresponse.com/it/assistenza/doppio-opt-in.html",
"br": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"pt": "https://www.getresponse.com/pt/ajuda/opt-in-confirmado.html",
"fr": "https://www.getresponse.com/fr/aide/opt-in-confirme.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWskaznikKlikniec = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wskaznik-klikniec.html",
"en": "https://www.getresponse.com/help/click-through-rate.html",
"de": "https://www.getresponse.com/de/hilfe/click-through-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/koefficient-klikov-ctr.html",
"it": "https://www.getresponse.com/it/assistenza/tasso-di-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"pt": "https://www.getresponse.com/pt/ajuda/indice-de-clique.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSledzenieKlikniec = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/click-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/otslezhivanie-po-kliku.html",
"it": "https://www.getresponse.com/it/assistenza/tracciamento-dei-clic.html",
"br": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/rastreamento-de-cliques.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWskaznikZwrotow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wskaznik-zwrotow.html",
"en": "https://www.getresponse.com/help/bounce-rate.html",
"de": "https://www.getresponse.com/de/hilfe/bounce-rate.html",
"es": "https://www.getresponse.com/es/ayuda/tasa-de-rebote.html",
"ru": "https://www.getresponse.com/ru/help-ru/pokazatel-oshibok-dostavki.html",
"it": "https://www.getresponse.com/it/assistenza/bounce-rate.html",
"br": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/taxa-de-rejeicao.html",
"fr": "https://www.getresponse.com/fr/aide/taux-de-rebond.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKampania = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kampania.html",
"en": "https://www.getresponse.com/help/marketing-campaign.html",
"de": "https://www.getresponse.com/de/hilfe/campaign-definition.html",
"es": "https://www.getresponse.com/es/ayuda/campaign-definition.html",
"ru": "https://www.getresponse.com/ru/help-ru/marketingovaja-kampania.html",
"it": "https://www.getresponse.com/it/assistenza/campagna.html",
"br": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"pt": "https://www.getresponse.com/pt/ajuda/definicao-da-campanha.html",
"fr": "https://www.getresponse.com/fr/aide/campagne-de-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBlokowanie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/blokowanie.html",
"en": "https://www.getresponse.com/help/blocking.html",
"de": "https://www.getresponse.com/de/hilfe/blocking.html",
"es": "https://www.getresponse.com/es/ayuda/bloqueo.html",
"ru": "https://www.getresponse.com/ru/help-ru/blokirovka.html",
"it": "https://www.getresponse.com/it/assistenza/blocco.html",
"br": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"pt": "https://www.getresponse.com/pt/ajuda/bloqueio.html",
"fr": "https://www.getresponse.com/fr/aide/blocage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTargetowanieBehawioralne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/targetowanie-behawioralne.html",
"en": "https://www.getresponse.com/help/behavioral-targeting.html",
"de": "https://www.getresponse.com/de/hilfe/behavioral-targeting.html",
"es": "https://www.getresponse.com/es/ayuda/orientacion-del-comportamiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/povedencheskij-targeting.html",
"it": "https://www.getresponse.com/it/assistenza/targeting-comportamentale.html",
"br": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"pt": "https://www.getresponse.com/pt/ajuda/direcionamento-comportamental.html",
"fr": "https://www.getresponse.com/fr/aide/ciblage-comportemental.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBelowTheFoldPonizejLinii = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/below-the-fold-ponizej-linii.html",
"en": "https://www.getresponse.com/help/below-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/below-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/bajo-el-pliegue.html",
"ru": "https://www.getresponse.com/ru/help-ru/nizhe-linii-zgiba.html",
"it": "https://www.getresponse.com/it/assistenza/below-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/abaixo-da-dobra.html",
"fr": "https://www.getresponse.com/fr/aide/below-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPustyEmail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/pusty-email.html",
"en": "https://www.getresponse.com/help/blank-email.html",
"de": "https://www.getresponse.com/de/hilfe/blank-email.html",
"es": "https://www.getresponse.com/es/ayuda/email-en-blanco.html",
"ru": "https://www.getresponse.com/ru/help-ru/pustoe-elektronnoe-pismo.html",
"it": "https://www.getresponse.com/it/assistenza/email-vuouta.html",
"br": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"pt": "https://www.getresponse.com/pt/ajuda/email-em-branco.html",
"fr": "https://www.getresponse.com/fr/aide/email-vide.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProgramAfiliacyjny = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/program-afiliacyjny.html",
"en": "https://www.getresponse.com/help/affiliate-program.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-program.html",
"es": "https://www.getresponse.com/es/ayuda/programa-de-afiliados.html",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskaya-programma.html",
"it": "https://www.getresponse.com/it/assistenza/programma-di-affiliazione.html",
"br": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/programa-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/programme-d-affiliation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKluczApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/klucz-api.html",
"en": "https://www.getresponse.com/help/api-key.html",
"de": "https://www.getresponse.com/de/hilfe/api-key.html",
"es": "https://www.getresponse.com/es/ayuda/clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/api-kljuch.html",
"it": "https://www.getresponse.com/it/assistenza/chiave-api.html",
"br": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/chave-api.html",
"fr": "https://www.getresponse.com/fr/aide/cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostReklamaMailowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/reklama-mailowa.html",
"en": "https://www.getresponse.com/help/email-advertising.html",
"de": "https://www.getresponse.com/de/hilfe/email-advertising.html",
"ru": "https://www.getresponse.com/ru/help-ru/email-reklama.html",
"it": "https://www.getresponse.com/it/assistenza/pubblicita-via-e-mail.html",
"br": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/publicidade-por-email.html",
"es": "https://www.getresponse.com/es/ayuda/publicidad-por-email.html",
"fr": "https://www.getresponse.com/fr/aide/publicite-par-courriel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAboveTheFoldPonadLinia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/above-the-fold-ponad-linia.html",
"en": "https://www.getresponse.com/help/above-the-fold.html",
"de": "https://www.getresponse.com/de/hilfe/above-the-fold.html",
"es": "https://www.getresponse.com/es/ayuda/sobre-el-pliegue.html",
"ru": "https://www.getresponse.com/ru/help-ru/vidimaya-chast-bez-prokrutki.html",
"it": "https://www.getresponse.com/it/assistenza/above-the-fold.html",
"br": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"pt": "https://www.getresponse.com/pt/ajuda/acima-da-dobra.html",
"fr": "https://www.getresponse.com/fr/aide/above-the-fold.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakImportowacPlikiPrzezFtp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-pliki-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-arquivos-via-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-importar-archivos-a-traves-de-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-faily-cherez-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-importare-i-file-via-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKiedyImportowacPlikPrzezFtp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kiedy-importowac-plik-przez-ftp.html",
"en": "https://www.getresponse.com/help/when-to-import-files-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wann-importiere-ich-dateien-via-ftp.html",
"br": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/quando-importar-arquivos-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kogda-importirovat-faily-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/cuando-importar-archivos-via-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/quando-importare-file-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/quand-importer-des-fichiers-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzygotowacPlikDoImportuPrzezFtp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu-przez-ftp.html",
"en": "https://www.getresponse.com/help/how-to-prepare-a-file-to-import-it-via-ftp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-via-ftp-vor.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importa-lo-via-ftp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotvit-fajl-dlya-importa-cherez-ftp.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparar-un-archivo-para-importarlo-por-ftp.html",
"it": "https://www.getresponse.com/it/assistenza/come-preparare-un-file-per-importarlo-tramite-ftp.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importer-via-ftp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacListeKontaktowDoKontaGetresponseMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-liste-kontaktow-do-konta-getresponse-max.html",
"en": "https://www.getresponse.com/help/how-to-upload-a-list-to-a-getresponse-max-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-importiere-ich-eine-liste-in-mein-getresponse-max-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-carregar-uma-lista-para-uma-conta-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zagryzit-spisok-v-akkaynt-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/como-cargar-una-lista-a-una-cuenta-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-caricare-una-lista-su-un-account-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-une-liste-vers-un-compte-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSformatowacPlikOdbiorcowSms = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sformatowac-plik-odbiorcow-sms.html",
"en": "https://www.getresponse.com/help/how-to-format-the-file-of-sms-recipients.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-formatirovat-file-polychatelej-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-formattare-il-file-dei-destinatari-degli-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-formatear-el-archivo-de-destinatarios-de-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-formatar-o-arquivo-dos-destinatarios-de-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-formatiere-ich-die-datei-der-sms-empfaenger.html",
"fr": "https://www.getresponse.com/fr/aide/comment-formater-le-fichier-des-destinataires-de-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWyslacSms = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-sms.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-an-sms.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-i-otpravit-sms.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-enviar-um-sms.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-e-inviare-un-sms.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-sende-ich-eine-sms.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-enviar-un-sms.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-un-sms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPismaPoslePokupki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyPoslePokupki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodtverzhdenijeOPokupkeNaOsnoveSoglasija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html",
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChistkaSpiskaPoTegamIBallam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVozvrashenijePodpischikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"en": "https://www.getresponse.com/help/win-back.html",
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html",
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDlaKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingDlaKlijentov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPooshrenijeLojalnyhKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html",
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyVovlechenijeIUderzhanije = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVovlechenijeVRozhdestvenskijePrazdniki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html",
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBazovyjPlanSkoringa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBazovyjPlanTegov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZnalezcKontaktyNaJednejZMoichList = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-jednej-z-moich-list.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzypisywacPolaDodatkowePodczasImportu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIleKosztujeMarketingAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSeriaWiadomosciOPorzuconymKoszyku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodstawowyPlanScoringowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyszczenieListyPrzezScoringITagowanie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWiadomoscUrodzinowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"en": "https://www.getresponse.com/help/birthday-message.html",
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html",
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPodstawowyPlanTagowania = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostZamianaKontaktowWKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostLimitowanaOferta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRetargetingKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKomunikacjaPoWebinarze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKomunikacjaPoZakupie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNagradzanieKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html",
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyKvalifikaciaLidov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrivetstvijeNovyhKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrivetstvijeNaOsnoveSoglasija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-na-osnove-soglasija.html",
"en": "https://www.getresponse.com/help/consent-based-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-powitalna-zalezna-od-udzielonych-zgod.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-basato-su-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-willkommensnachricht.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-base-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrivetstvijeNovyhKlientov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaChegoNuzhnyShablonyPrivetstvije = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProstojePrivetstvennojePismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeShablonyDostupnyVMojemTarifnomPlane = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatShablonyDlaSozdanijaProcessov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtfiltrovatDubliKontaktovCherezProtsessAvtomatizacii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearLaVersionMovilDeUnFormularioDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoUnFormularioDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosWebinarsDeGetresponse = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromozioneWebinarAffiliati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedenLosWebinarsAyudarmeAConstruirMiListaDeEmailMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoEnviarInvitacionesAWebinarsAPersonasQueNoEstanEnMiListaDeSuscriptores = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoPermitirQueLosAsistentesSeUnanAUnWebinarDirectamenteDesdeMiLandingPage = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoExportarElHistorialDeChatDeUnWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoComprobarLasEstadisticasDeLasInvitacionesYLosRecordatoriosDeLosWebinarsQueHeEnviado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoEncontrarLasEstadisticasDeWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostScoringVonAffiliateKontakten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaggingVonAffiliateKontakten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html",
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateWillkommensnachricht = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListenhygieneMitTagsUndPunktestaenden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateOnlinekurs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateCrossSelling = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-cross-selling.html",
"en": "https://www.getresponse.com/help/affiliate-cross-selling.html",
"it": "https://www.getresponse.com/it/assistenza/cross-selling-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/cross-selling-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/venda-cruzada-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/venta-cruzada-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/ventes-croisees-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/kross-prodazha-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateWebinarpromotion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateKurspromotion = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostErinnerungBeiAbgebrochenenWarenkoerben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieHoleIchDasEinverstaendnisNeuerUndBestehenderKontakteEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcheFunktionenBietetDerNeueWebinarRaum = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMancheElementeMeinesWorkflowsSindAusgegrautWarum = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/manche-elemente-meines-workflows-sind-ausgegraut-warum.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-elementy-mojego-mojego-cyklu-sa-wyszarzone.html",
"en": "https://www.getresponse.com/help/some-elements-in-my-workflow-are-grayed-out-why.html",
"es": "https://www.getresponse.com/es/ayuda/algunos-elementos-de-mi-flujo-de-trabajo-estan-en-gris-por-que.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-nekotorye-elementy-v-mojom-processe-vydeleny-serym-cvetom.html",
"br": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pt": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"it": "https://www.getresponse.com/it/assistenza/alcuni-elementi-nel-automation-workflow-sono-disattivati-perche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLegeIchFestZuWelchemZeitpunktMeinWorkflowBeginntUndEndet = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueHerramientasOfreceLaNuevaSalaDeWebinars = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoGuardarLaDisposicionDeLaSalaDelWebinarYLaPizarra = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoPermitirAMisParticipantesComunicarseConmigoDuranteElWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-permitir-a-mis-participantes-comunicarse-conmigo-durante-el-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pozwolic-uczestnikom-webinaru-komunikowac-sie-ze-mna.html",
"en": "https://www.getresponse.com/help/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-teilnehmer-waehrend-des-webinars-mit-mir-kommunizieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mozhno-vzaimodejstvovat-so-zritelami-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-permettre-a-mes-participants-de-communiquer-avec-moi-pendant-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostProstaWiadomoscPowitalna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPowitanieNowychKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocjaWebinaru = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru.html",
"en": "https://www.getresponse.com/help/webinar-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/webinar-promotion.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenije-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOdzyskiwanieKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"en": "https://www.getresponse.com/help/win-back.html",
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html",
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPowitanieNowychKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoNieMogeZalogowacSieDoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPromocjaKursuWProgramieAfiliacyjnym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicJezykListyIWiadomosciPotwierdzajacej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacFormularzPopUpNaStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnajdeUstawieniaStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaWarunekWyjatkowaOkazja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWersjeMobilnaFormularzaLegacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakLosowoTagowacKontakty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToZnaczyZeMojImportJestNiezgodnyZPolitykaGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZweryfikowacSwojaIntegracjeZPaypal = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestListaOpartaNaZgodzieSubskrybentow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeRecznieDodacKontaktDoCykluAutorespondera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacEcommerceTrackingWWiadomosciach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakTworzycPolaZgodyIJakZNichKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoMojeAutoresponderyNieZostalyWyslane = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-autorespondery-nie-zostaly-wyslane.html",
"en": "https://www.getresponse.com/help/why-aren-t-my-autoresponders-going-out.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-meine-autoresponder-nicht-ausgeliefert.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-avtootvetchiki-ne-otpravlajutsa.html",
"br": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"pt": "https://www.getresponse.com/pt/ajuda/porque-os-meus-autoresponders-nao-estao-a-ser-enviados.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-salio-mis-autoresponders.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-miei-autoresponder-non-escono.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-auto-repondeurs-ne-sont-ils-pas-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZSzablonow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaWebinaryNaZadanieIJakZNichKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWymaganiaTechniczneIRozwiazywanieProblemowDlaWebinarow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSwiateczneZaangazowanie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html",
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSaWymaganiaStopkiMaila = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAnABTest = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-a-b-test.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sozdat-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-stworzyc-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-teste-a-b.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-una-prueba-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueTiposDeArchivosPuedoCargarEnUnWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedeHaberMasPresentadoresQueSoloYo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html",
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLosWebinarsBajoDemandaYComoUsarlos = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZElementowPodstawowychAbyUtworzycWiadomosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsALandingPageAndWhyDoINeedOne = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-landing-page-and-why-do-i-need-one.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-landing-page-und-warum-sollte-ich-eine-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-lending-i-dla-chego-on-nuzhen.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-landing-page-e-porque-e-que-preciso-de-uma.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-landing-page-e-a-cosa-serve.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-landing-page-de-legado-y-por-que-la-necesito.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-landing-page-i-do-czego-moze-byc-potrzebny.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-landing-page-et-pourquoi-en-ai-je-besoin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateALegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-landing-page-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatFeaturesDoLegacyLandingPagesOffer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereToFindLegacyLandingPageTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSetUpABTestingOnLegacyLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanISaveALegacyLandingPageTemplateSoThatIMayReUseMyDesign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-save-a-legacy-landing-page-template-so-that-i-may-re-use-my-design.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-legacy-landing-page-vorlage-speichern-und-spaeter-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-guardar-el-modelo-de-landing-page-de-legado-para-poder-reutilizar-mi-diseno.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-sohranit-shablon-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-salvar-um-template-de-landing-page-legada-para-que-eu-possa-reutilizar-meu-design.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-salvare-un-template-di-una-landing-page-per-poterlo-riutilizzare-in-futuro.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zapisac-szablon-starszej-wersji-landing-page-aby-moc-go-ponownie-wykorzystac.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-un-modele-dune-ancienne-landing-page-afin-de-pouvoir-reutiliser-ma-conception.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoGrabarMiWebinar = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToShareAnEbookWithYourContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEditoLosAjustesDeSuscripcionDeMiLista = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanITrackVisitsAndSubscriptionsForMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-track-visits-and-subscriptions-for-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-besuche-und-anmeldungen-meiner-legacy-landing-page-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-posetitelej-i-podpiski-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-visite-e-le-iscrizioni-per-la-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-odwiedziny-i-subskrypcje-na-swojej-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-un-seguimiento-de-las-visitas-y-las-suscripciones-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-rastrear-visitas-e-assinaturas-para-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-visites-et-les-inscriptions-a-ma-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatToolsAreAvailableInsideTheLegacyLandingPagesEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindMyLegacyLandingPageSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-my-legacy-landing-page-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-ustawienia-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-najti-parametry-svojego-lendinga-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-configuracion-de-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-de-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-parametres-de-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakOdnalezcNaglowkiWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html",
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIDuplicateElementsOnMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-duplicate-elements-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-elemente-auf-meiner-legacy-landing-page-duplizieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dublirovat-elementy-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-duplicare-gli-elementi-nella-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-duplikowac-poszczegolne-elementy-na-starszej-wersji-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-duplicar-elementos-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-duplicar-elementos-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-dupliquer-des-elements-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacCzcionekWebowychWWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoesMyLegacyLandingPageComeWithAThankYouPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/does-my-legacy-landing-page-come-with-a-thank-you-page.html",
"de": "https://www.getresponse.com/de/hilfe/enthaelt-meine-legacy-landing-page-auch-eine-danke-seite.html",
"ru": "https://www.getresponse.com/ru/help-ru/jest-li-na-mojem-lendinge-v-versii-legacy-stranitsa-blagodarnosti.html",
"it": "https://www.getresponse.com/it/assistenza/la-mia-landing-page-e-corredata-da-una-pagina-di-ringraziamento.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moja-starsza-wersja-landing-page-ma-strone-z-podziekowaniem.html",
"es": "https://www.getresponse.com/es/ayuda/mi-landing-page-de-legado-viene-con-una-pagina-de-agradecimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-landing-page-legada-vem-com-uma-pagina-de-agradecimento.html",
"fr": "https://www.getresponse.com/fr/aide/mon-ancienne-landing-page-est-elle-accompagnee-d-une-page-de-remerciement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaSzerokoscPowinnaMiecMojaWiadomosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-szerokosc-powinna-miec-moja-wiadomosc.html",
"en": "https://www.getresponse.com/help/how-wide-should-my-message-be.html",
"de": "https://www.getresponse.com/de/hilfe/welche-breite-sollte-meine-nachricht-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/naskolko-shirokim-mozhet-byt-moje-soobshenie.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-dovrebbe-essere-largo-il-mio-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-ancho-debe-ser-mi-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-doit-etre-la-largeur-de-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanICountDownToAnEventOnMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaAutomatyczneTworzenieLejkaSprzedazowego = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddACustomCodeToMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoPersonalizarMisEmails = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacMoichElementowWNowymKreatorzeWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeZobaczycStatystykiMoichWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieFunkcjeZnajdeWNowymKreatorzeWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddPopupFormsToALegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-popup-forms-to-a-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-formularz-pop-up-na-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-pop-up-formulare-auf-meiner-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-vsplyvajushije-formy-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-moduli-pop-up-sulla-mia-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-formularios-emergentes-a-una-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-formularios-pop-up-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-formulaires-pop-up-a-une-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPorQueMisCorreosElectronicosAVecesTerminanEnUnaCarpetaDeSpam = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWiadomoscKtoraZawszeDobrzeWyglada = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWlasnegoKoduWWiadomosciach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacGrafikeWTleWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymRozniaSieProstyIZaawansowanyLejekSprzedazowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostParaQueSeUsanLosModelosDeCalificacionDelClientePotencial = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaSekcjeWKreatorzeWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPlanDePuntuacionBasico = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestDodatkowePotwierdzeniePlatnosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLasDiferenciasEntreLasPaginasDeEmbudo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaStronyUpSelloweWLejkuKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueSonLasPaginasDeUpsellEnElEmbudoDeConversion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoOznaczaZeMojaWiadomoscZawieraBladSkladni = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestDkimIJakMogeGoSkonfigurowac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnEmbudoDeVentas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakiStatusMozeOtrzymacMojaReklamaFacebookAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaki-status-moze-otrzymac-moja-reklama-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-statuses-can-my-facebook-ads-have.html",
"de": "https://www.getresponse.com/de/hilfe/welchen-status-kann-meine-facebook-anzeige-haben.html",
"es": "https://www.getresponse.com/es/ayuda/que-estado-pueden-tener-mis-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-statysu-mogyt-byt-u-moei-reklamu-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"it": "https://www.getresponse.com/it/assistenza/quali-stati-possono-avere-i-miei-annunci-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKimSaOdwiedzajacyStroneFacebookAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDostarczalnoscWiadomosciNaHomePl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc-wiadomosci-na-home-pl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePolaczycSklepInternetowyZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoUnaPaginaDeVentas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualesSonLasDiferenciasEntreUnEmbudoDeVentasRapidoYUnEmbudoDeVentasCompleto = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoFuncionaElAutogeneradorParaLosFunnelsDeVentas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnEmbudoDeCreacionDeListas = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnEmbudoDeWebinarGratuito = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoHacerElSeguimientoDeLasConversionesDeGoogleAdsEnMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoRecogerElConsentimientoDeLosContactosNuevosYExistentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnEmbudoDeWebinarDePago = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAdministroLosDominiosDeMisLandingPagesEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToJestGetresponseMax = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-getresponse-max.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-max.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-max.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-getresponse-max.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-getresponse-max.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-getresponse-max.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-max.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-max.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestRozgrzewkaAdresuIp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-rozgrzewka-adresu-ip.html",
"en": "https://www.getresponse.com/help/what-is-ip-address-warmup.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-calentamiento-de-la-direccion-ip.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-aquecimento-de-enderecos-ip.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-progrev-ip-adresa.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-ip-adresse-aufwaermung.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-riscaldamento-dell-indirizzo-ip.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-rechauffement-des-adresses-ip.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUdostepnicEBookSwoimSubskrybentom = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-e-book-swoim-subskrybentom.html",
"en": "https://www.getresponse.com/help/how-to-share-an-ebook-with-your-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-meinen-kontakten-ein-e-book.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podelitsa-elektronnoj-knigoj-so-svoimi-kontaktami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-compartilhar-um-e-book-com-seus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-condividere-un-e-book-con-i-tuoi-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/como-compartir-un-ebook-con-tus-contactos-editor-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-partager-un-ebook-avec-vos-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycReklameGoogleAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUtworzycWiadomoscAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-wiadomosc-automation.html",
"en": "https://www.getresponse.com/help/how-to-create-an-automation-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-in-der-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-mensaje-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-mensagem-de-automacao.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-pismo-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-messaggio-di-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-message-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZintegrowacKontoZeSquare = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodawacProduktyEtsyDoMojegoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestFunkcjaPlikiIObrazyIJakZNiejKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaRaportyIJakMogeZNichKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-raporty-i-jak-moge-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-reports-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-informes-y-como-puedo-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otchety-i-kak-ja-mogu-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/what-are-reports-and-how-can-i-use-them.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRozporzadzenieOOchronieDanychOsobowychRodo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/rozporzadzenie-o-ochronie-danych-osobowych-rodo.html",
"en": "https://www.getresponse.com/help/general-data-protection-regulation-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/general-data-protection-regulation-gdpr.html",
"es": "https://www.getresponse.com/es/ayuda/reglamento-general-de-proteccion-de-datos-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/obshij-reglament-po-zashite-dannych-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/die-allgemeine-datenschutz-verordnung-dsgvo.html",
"br": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/regulamento-geral-de-protecao-de-dados-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/reglement-general-sur-la-protection-des-donnees-gdpr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestPoziomZaangazowaniaIJakMogeGoWykorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUstawicRegularneImportyDoMoichList = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZobaczycDlaczegoKontaktyWypisalySieZListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeImportowacBazeProduktowDoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSzablonyCykliAutomationSaDostepneWMoimPlanieCenowym = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycReklameNaFacebooku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieFunkcjeOferujeNowyPokojWebinarowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycLejekDoBudowyListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymRozniaSieStronyLejkaKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatFiltrStatusSoglasija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetFiltrSpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetFiltrKolichestvo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakMneOtslezhivatKontaktyKotoryjeNahodatsaVProtsessah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatDejstvijeTegVProtsesse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakKopirovatKontaktyVDrugojSpisok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaRedaktirovatPismoKotorojeIspolzujetsaVProtsesseAvtomatizatsii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetPismoAvtomatizatsii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeDodawacAktualnychIPotencjalnychKlientowZZohocrmDoListyKontaktowWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatNastrojkiVremeniVSvojstvahUslovij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycStroneSprzedazowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguIspolzovatUslovijeSobytijeVSvoihProcessah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAnulowalemSubskrypcjeDlaczegoOplatySaWciazPobierane = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKaknAstroitSvojstvaUslovijaBrosilKorzinu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePobracFaktury = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobMogeZmienicMetodePlatnosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitParametrySvojstvaKupil = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeNaprawicBladTransakcjiWMoimKoncie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobMogeDodacNowaKarteKredytowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRazmestitKodOtslezhivanijaJavascriptNaMojemSajte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobMogeUsunacZKontaKarteKredytowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycLejekSprzedazowy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoVkluchajutVSebjaFunktsiiOtslezhivanijaEcommerceIVebTrafika = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZmienicSwojPakiet = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToJestLejekKonwersji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatuslovijePosetilLendingVProtsesse = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetUslovijeStatusSoglasijaObnovlen = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiDobavlatVProtsessKontaktyKotoryjeByliSkopirovanyIliPeremeshenyVDrugojSpisok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetUslovijePismoOtpravleno = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetUslovijeBylLiKlikPoSsylke = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeNastrojkiPodpiskiMozhnoDobavitVProtsessAvtomatizacii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaZapuskatUslovijaIDejstvijaNeskolkoRaz = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaVnositIzmenenijaVProtsessAvtomatizatsiiKotoryjUzheBylOpublikovan = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeProcessAvtomatizatsii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSkolskoStoitAvtomatizatsija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAvtomatizatsijaMarketingaChtoEtoIKakOnaMnePomozhet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtpravitPismoKontaktuSrazuPoslePodpiskiCherezLending = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitNovojeSoobshenijeVSushestvujushijTsyklAvtootvetchika = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIspolzovatKalendarDlaProsmotraAvtootvetchikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaDobavitKontaktVTsyklAvtootvetchikaVruchnuju = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenjatDniDostavkiAvtootvetchikov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycPowiadomieniaWebPush = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycLejekDlaPlatnychWebinarow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestTekstZdefiniowany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycLejekDlaDarmowychWebinarow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZamknacKontoGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieNarzedziaDostepneSaWStarszejWersjiEdytoraLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-narzedzia-dostepne-sa-w-starszej-wersji-edytora-landing-pages.html",
"en": "https://www.getresponse.com/help/what-tools-are-available-inside-the-legacy-landing-pages-editor.html",
"de": "https://www.getresponse.com/de/hilfe/welche-werkzeuge-stehen-im-legacy-landing-page-editor-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-estan-disponibles-dentro-del-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-instrumenty-dostupny-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-gli-strumenti-disponibili-all-interno-dell-editor-delle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-ferramentas-estao-disponiveis-no-editor-de-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-outils-disponibles-dans-l-ancien-editeur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodacKlientowIPotencjalnychKlientowZSalesforceDoMojejListyKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePrzeniescMojaListeZActiveCampaignAweberConstantContactLubMailchimp = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeniesc-moja-liste-z-active-campaign-aweber-constant-contact-lub-mailchimp.html",
"en": "https://www.getresponse.com/help/how-can-i-migrate-my-list-from-active-campaign-aweber-constant-contact-or-mailchimp.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-migrar-mi-lista-de-active-campaign-aweber-constant-contact-o-mailchimp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-perenesti-svoj-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trasferire-la-mia-lista-da-active-campaign-aweber-constant-contact-o-mailchimp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-liste-von-active-campaign-aweber-constant-contact-oder-mailchimp-migrieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoInstaloElCertificadoSslParaMisDominiosEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirUnaNotificacionDeCookiesAMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAumentarLaVisibilidadDeMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsoLasSeccionesEnElCreadorDeLandingPagesDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicIZbieracDaneECommerceZPrestashop = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnajdeSzablonyStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/where-to-find-legacy-landing-page-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-legacy-landing-page-vorlagen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-modelos-de-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-shablony-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-templates-de-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-delle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-des-modeles-de-landing-pages-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSegmentowacKontaktyWOparciuODaneEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeDodacLandingPageDoStronyWordpressWeWtyczceLegacyWordpress = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeEdytowacWiadomoscKtorejUzywamWCykluMarketingAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeRozwinacSwojaFirmePrzyUzyciuNarzedziEcommerce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacCertyfikatSslDoStarszychWersjiLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnVideoAMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoOptimizoMiLandingPageDeLegadoParaDispositivosMoviles = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoIntegrarMiCuentaConSquare = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakiePrzegladarkiSaKompatybilneZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZalogowacSieDoAplikacjiMobilnejUzywajacMagicznegoLinku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zalogowac-sie-do-aplikacji-mobilnej-uzywajac-magicznego-linku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mich-in-der-smartphone-app-mit-magic-link-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-iniciar-sesion-en-la-aplicacion-movil-con-un-link-magico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-zajti-v-mobilnoe-prilozenie-s-pomoshjyu-volshebnoj-ssylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-fazer-login-no-aplicativo-para-dispositivos-moveis-com-um-link-magico.html",
"en": "https://www.getresponse.com/help/how-can-i-login-in-to-the-mobile-app-with-a-magic-link.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-accedere-all-app-mobile-con-un-link-magico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-me-connecter-a-l-application-mobile-a-l-aide-d-un-lien-magique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoWiadomosciWyslaneWRamachTejSamejDomenyZostajaZwrocone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakEdytowacUstawieniaSubskrypcjiNaMojaListe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWarunkuOdwiedzonoLandingPageWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeStworzycNaStarszejWersjiLandingPageZegarOdliczajacyCzasDoKonkretnegoWydarzenia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-stworzyc-na-starszej-wersji-landing-page-zegar-odliczajacy-czas-do-konkretnego-wydarzenia.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-razmestit-tajmer-obratnogo-otscheta-na-lendinge-versii-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-event-countdown-auf-meine-legacy-landing-page-setzen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-fare-il-conto-alla-rovescia-di-un-evento-sulla-mia-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-count-down-to-an-event-on-my-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-una-cuenta-regresiva-para-un-evento-en-mi-landing-page-de-legado.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-contagem-regressiva-para-um-evento-em-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-faire-le-compte-a-rebours-d-un-evenement-sur-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWykluczycZduplikowaneKontaktyWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodacWlasnyKodDoStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-wlasny-kod-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-add-a-custom-code-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benutzerdefinierten-code-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-un-codigo-personalizado-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sobstvennyj-kod-na-lending-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-um-codigo-customizado-em-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-un-codice-personalizzato-alla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-un-code-personnalise-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobWyszukiwacKontaktyWOparciuOWyrazonePrzezNieZgody = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodacLinkiDoPolDodatkowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZFiltruStatusZgody = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeSprawdzicSzczegolyKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezhduRassylkojIPismomAvtootvetchika = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatAvtootvetchik = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeAvtootvetchik = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakoeZhaloby = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakReputacijaAdresaOtpravitelaVlijajetNaDostavlajemostPisemVPapkuVhodjashijeIPapkuSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuNeSledujetDobavlatVSpisokPismaSOshibkojDostavki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIIntegrateMyAccountWithSquare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-integrate-my-account-with-square.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-konto-ze-square.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-square-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-cuenta-con-square.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-akkaunt-so-square.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-conta-com-o-square.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-il-mio-account-con-square.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-compte-a-square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddAVideoToMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIOptimizeMyLegacyLandingPageForMobileDevices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseSectionsInTheLegacyLandingPageCreator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIIncreaseTheVisibilityOfMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddACookiesNotificationToMyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIInstallSslCertificateForMyCustomDomains = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-install-ssl-certificate-for-my-custom-domains.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-certyfikat-ssl-do-starszych-wersji-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-das-ssl-zertifikat-fuer-meine-benutzerdefinierten-domains.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-el-certificado-ssl-para-mis-dominios-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ustanovit-ssl-sertifikat-dla-moih-sobstvennyh-domenov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-installare-un-certificato-ssl-per-i-miei-domini-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalo-o-certificado-ssl-para-meus-dominios-personalizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-un-certificat-ssl-pour-mes-domaines-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIManageDomainsForMyLegacyLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuMoiRassylkiInogdaPopadajutVPapkuSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuJaNeMoguIspolzovatAdresaNaYahooAolIMailruVKachestveAdresaOtpravitela = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuInternetProvajderyPoRaznomuRaspoznajutPismaKakSpam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuVNekotoryhPismahOtobrazhajutsaNadpisiOtLitsaIliCherez = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeOshibkiDostawki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijePravilaIMetodikiRassylokVlijajutNaDostavlajemostPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakojUGetresponsePokazatelDostavlajemostiPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeDkimIKakEtoNastroit = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNajtiSluzhebnyjeZagolovki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html",
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostOdinITotZheKontaktNahoditsaVRaznyhSpiskah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAPaidWebinarFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-paid-webinar-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-platnych-webinarow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenpflichtigen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-platnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-pago.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-a-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-payant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatRassylkuRssToEmail = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiSohranitAbTest = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeVidyAbTestovJaMoguIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaTestirovatRaznyjeChastiSoobshenijaVOdnomAbTeste = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiZaplanirovatAbTesty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakOtpravitSamojeEffektivnojePismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html",
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSkolkoPoluchatelejNuzhnoDobavlatVTestovujuGruppu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNahoditsaFunkcijaAbTestirovanija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeAbTest = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoOznachajetSintaksicheskajaOshybkaVMojemSoobshenii = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguDobavitVlozhenijaVSvoiSoobshenija = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiSprzedaz = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoEliminoUnCodigoDeSeguimientoDeMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiWebinaryIWydarzenia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUsarYandexMetricaParaRastrearMisLandingPagesDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoRastrearMiLandingPageDeLegadoConVisualWebsiteOptimizerVwo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoHacerUnSeguimientoDeMisCampanasDeFacebookYLandingPagesDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoAnadirMultiplesCodigosDeSeguimientoAMiLandingPageDeLegadoConGoogleTagManager = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoIntegroMisLandingPagesDeLegadoConGoogleTagManager = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoUsarElCodigoDeSeguimientoDeGoogleAnalyticsEnMiLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoUsarDominiosSubdominiosYDirectoriosParaLasLandingPagesDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCambioLaConfiguracionDeDnsEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadoUnRegistroCnameAMiSubdominioEditorDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAsignoMiPropioDominioOSubdominioAUnaLandingPageDeLegado = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAssignMyOwnDomainOrSubdomainToALegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddACnameEntryToMySubdomain = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIChangeTheDnsSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseDomainsSubdomainsAndDirectoriesForMyLegacyLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseAGoogleAnalyticsTrackingCodeOnMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIIntegrateMyLegacyLandingPagesWithGoogleTagManager = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddMultipleTrackingCodesToMyLegacyLandingPageWithGoogleTagManager = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIIntegrateMyLegacyLandingPageWithBingAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-bing-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-bing-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-bing-ads.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-bing-ads.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-bingads.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-bing-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-anuncios-bing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITrackMyFacebookCampaignsAndLegacyLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIIntegrateMyLegacyLandingPageWithKissmetrics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-integrate-my-legacy-landing-page-with-kissmetrics.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-kissmetrics.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-integrirovat-svoj-lending-v-versii-legacy-s-kissmetrics.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-landing-page-con-kissmetrics.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-starsza-wersje-landing-page-z-kissmetrics.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-landing-page-de-legado-con-kissmetrics.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-kissmetrics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mon-ancienne-page-de-destination-a-kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToIntegrateMyLegacyLandingPagesWithMixpanel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-integrate-my-legacy-landing-pages-with-mixpanel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-mixpanel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-integrirovat-svoi-lendingi-v-versii-legacy-s-mixpanel.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-mixpanel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zintegrowac-moja-starsza-wersje-landing-page-z-platforma-mixpanel.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-landing-page-legada-com-mixpanel.html",
"es": "https://www.getresponse.com/es/ayuda/como-integrar-mis-landing-pages-de-legado-con-mixpanel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITrackMyLegacyLandingPageWithVisualWebsiteOptimizerVwo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseYandexMetricaToTrackMyLegacyLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-yandex-metrica-to-track-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-yandex-metrica-nutzen-um-meine-legacy-landing-pages-zu-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-yandex-metrica-para-rastrear-mis-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-yandex-metriku-dla-otslezhivanija-moih-lendingov-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-o-yandex-metrica-para-rastrear-minhas-landing-pages-legadas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-yandex-metrica-per-monitorare-le-mie-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moja-starsza-wersje-landing-page-przy-pomocy-yandex-metrica.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-yandex-metrica-pour-suivre-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIRemoveATrackingCodeFromMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAndUseConsentFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-consent-fields.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-zgody-i-jak-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-einwilligungsfelder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-polya-soglasiya.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-del-consenso.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-y-usar-campos-de-consentimiento.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-e-usar-os-campos-de-consentimento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-les-champs-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIForwardSubscriberDataToACustomThankYouPageInLegacyForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-forward-subscriber-data-to-a-custom-thank-you-page-in-legacy-forms.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anmeldedaten-in-legacy-formularen-an-eine-benutzerdefinierte-danke-seite-weiterleiten.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-dannyie-podpischikov-na-svoyu-stranitsu-blagodarnosti-s-ispolzovaniem-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inoltrare-i-dati-degli-iscritti-a-una-pagina-di-ringraziamento-personalizzata.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-encaminhar-dados-do-assinante-para-uma-pagina-de-agradecimento-personalizada.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przesylac-dane-subskrybenta-na-wlasna-strone-z-podziekowaniem-w-formularzach-legacy.html",
"es": "https://www.getresponse.com/es/ayuda/como-reenviar-los-datos-de-los-suscriptores-a-una-pagina-de-agradecimiento-personalizada-en-formularios-de-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-transmettre-les-donnees-des-abonnes-a-une-page-de-remerciement-personnalisee-dans-les-anciens-formulaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCollectConsentFromNewAndExistingContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIGetMyAccountReadyForGdpr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMyListsAreSingleOptInIsDoubleOptInRequiredToBeGdprCompliant = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITrackGoogleAdsConversionOnMyLegacyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieLassenSichWorkflowsDuplizieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchEinenVeroeffentlichtenWorkflowBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeWyswietlacMojeListyIZarzadzacNimi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiKursyOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNahodatsaHtmlShablonyPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodatsa-html-shablony-pisem.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/where-can-i-find-html-message-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-vorlagen-fuer-html-nachrichten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-modelos-de-mensajes-html.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-di-messaggi-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDerFilterListen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiPoZakupie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiPowitanie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiZaangazowanieIRetencja = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiKwalifikowanieLeadow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPrzypomnienieOPorzuconymKoszyku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodpischikiMogutPodelitsaMojejRassylkojVSocSetjah = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakKontaktMozhetOtpisatsaOtRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoWykorzystujeSieSzablonyZKategoriiPorzuconyKoszyk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobGromadzicZgodyNowychIIstniejacychKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-gromadzic-zgody-nowych-i-istniejacych-kontaktow.html",
"en": "https://www.getresponse.com/help/how-to-collect-consent-from-new-and-existing-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-hole-ich-das-einverstaendnis-neuer-und-bestehender-kontakte-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-recoger-el-consentimiento-de-los-contactos-nuevos-y-existentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-polychit-soglasie-na-podpisky-ot-novych-i-syshestvyushich-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottenere-il-consenso-dai-contatti-nuovi-ed-esistenti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coletar-o-consentimento-de-contatos-novos-e-ja-existentes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-recueillir-le-consentement-des-contacts-nouveaux-et-existants.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzygotowacMojeKontoDoRodo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacGetresponseIWordpressAbyMocPoszerzacListeKontaktowSledzicOdwiedzinyNaStronieIWysylacDaneEcommerceWeWtyczceLegacyWordpress = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavitVideoKMojejRassilke = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUtrzymywacSynchronizacjeDanychKontaktowPomiedzySalesforceIGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacOdwiedzajacychStroneNaWordpressDoListyWGetresponseWeWtyczceLegacyWordpress = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicOdwiedzinyMoichStronNaWordpressWeWtyczceLegacyWordpress = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacKlientowWoocommerceDoKontaGetresponseWeWtyczceLegacyWordpress = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAFreeWebinarFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-free-webinar-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-dla-darmowych-webinarow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-funnel-mit-einem-kostenlosen-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-webinar-gratuito.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-prodvizhenija-besplatnogo-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-webinar-gratuito.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-webinar-funnel-gratuito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-webinaire-gratuit.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateALeadMagnetFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-lead-magnet-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-magnet-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-s-lid-magnitom.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-ima-de-leads.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-un-lead-magnet-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-z-lead-magnetem.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-lead-magnet.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-lead-magnet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIVerifyMyPaypalIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-verify-my-paypal-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zweryfikowac-swoja-integracje-z-paypal.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-paypal-integration-verifizieren.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-verificare-la-mia-integrazione-con-paypal.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-verificar-mi-integracion-con-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-verificar-minha-integracao-com-o-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-proverit-svoyu-integratsiyu-s-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-verifier-l-integration-de-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAListBuildingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-list-building-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-do-budowy-listy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-creacion-de-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-rasshirenija-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-construcao-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-list-building-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-lead-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiProveritPeredOtpravkojKakBudetVygladetPismo = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeWysylacRekomendacjeProduktowDoMoichKlientow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieRodzajePolDodatkowychSaDostepne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeGotowyjTekst = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacWspolpracownika = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestTestAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiIzmenitIliUdalitKnopkiOtpisatsaVFuterePisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaVstavitIzobrazhenijeIliKnopkuTekstovyBlok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vstavit-izobrazhenije-ili-knopku-tekstovy-blok.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-bild-oder-einen-button-in-ein-textelement-einfuegen.html",
"en": "https://www.getresponse.com/help/can-i-embed-an-image-or-button-within-a-text-block.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-obrazek-lub-przycisk-w-bloku-tekstowym.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-incrustar-una-imagen-o-un-boton-dentro-de-un-bloque-de-texto.html",
"br": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"pt": "https://www.getresponse.com/pt/ajuda/can-i-embed-an-image-or-button-within-a-text-block.html",
"it": "https://www.getresponse.com/it/assistenza/posso-incorporare-un-immagine-o-un-pulsante-all-interno-di-un-blocco-di-testo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-une-image-ou-un-bouton-dans-un-bloc-de-texte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDacKomusDostepDoMojegoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPersonalizirovatPisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZSzablonowDoBudowyCykliAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguOtpravlatRekomendaciiSwoimKlientam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeGlobalnyProsmotrIKakJegoIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtotakojePerfectTimingIKakEtoRabotajet = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguIskluchitPoluchatelejIzSvojejRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakVybratPoluchatelejRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIzmenitAdresOtpravitelaVMoichSoobshenijach = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-adres-otpravitela-v-moich-soobshenijach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-absender-e-mail-adresse-in-meinen-nachrichten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-adres-email-nadawcy-w-wiadomosciach.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-la-direccion-de-en-mis-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-mudar-o-endereco-de-remetente-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-l-indirizzo-di-mittente-nei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-l-adresse-de-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeSvjazannyjSpisok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiProsmatrivatMoiRassylkiVInternete = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html",
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesAutoGeneratorForSalesFunnelsWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-auto-generator-for-sales-funnels-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-automatyczne-tworzenie-lejka-sprzedazowego.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-funnel-assistent-fuer-sales-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-autogenerador-para-los-funnels-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-avtomaticheskij-generator-v-voronkach-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-geradores-automaticos-para-funis-de-venda.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-l-autogeneratore-di-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-l-auto-generateur-de-funnels-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheDifferencesBetweenAQuickSalesFunnelAndFullSalesFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-differences-between-a-quick-sales-funnel-and-full-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-prosty-i-zaawansowany-lejek-sprzedazowy.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-schnell-sales-funnel-von-kompletten-sales-funneln.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-un-embudo-de-ventas-rapido-y-un-embudo-de-ventas-completo.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznitsa-mezhdu-prostoj-i-prodvinutoj-voronkami-prodazh.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-um-funil-de-vendas-rapido-e-um-funil-de-vendas-completo.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-differenze-tra-un-funnel-di-vendita-rapido-e-un-funnel-di-vendita-completo.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-un-funnel-de-vente-rapide-et-un-funnel-de-vente-eclair.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToSellAnEbookUsingConversionFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-sell-an-ebook-using-conversion-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-conversion-funnel-e-books-verkaufen.html",
"it": "https://www.getresponse.com/it/assistenza/come-vendere-un-ebook-tramite-conversion-funnel.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prodat-elektronnuyu-knigu-s-pomoshyu-voronki-konversii.html",
"es": "https://www.getresponse.com/es/ayuda/como-vender-un-ebook-utilizando-el-embudo-de-conversion.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprzedac-ebook-przez-lejek-konwersji.html",
"br": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-vender-um-ebook-usando-o-funil-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-vendre-un-e-book-a-l-aide-du-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateASalesPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-sales-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-strone-sprzedazowa.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-pagina-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-prodajushuju-stranitsu.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-pagina-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-una-pagina-di-vendita.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-verkaufsseite-erstellen.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-page-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateASalesFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-sales-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-lejek-sprzedazowy.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-embudo-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-voronku-dla-prodazhi-tovara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-funil-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-creare-un-sales-funnel.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-sales-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-funnel-de-vente.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreConversionFunnelUpsellPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-conversion-funnel-upsell-pages.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-strony-up-sellowe-w-lejku-konwersji.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-conversion-funnel-upselling-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-paginas-de-upsell-en-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-stranitsy-doprodazhi-v-voronkah-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-paginas-de-upsell-do-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-pagine-di-upselling-di-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-pages-upsell-dans-les-funnels-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowIsConversionFunnelDifferentFromMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-is-conversion-funnel-different-from-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/was-unterscheidet-conversion-funnel-von-marketing-automatisierung.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-conversion-funnel-si-differenzia-da-marketing-automation.html",
"es": "https://www.getresponse.com/es/ayuda/en-que-se-diferencia-el-embudo-de-conversion-de-la-automatizacion-del-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-voronka-konversii-otlichaetsya-ot-avtomatizacii-marketinga.html",
"pl": "https://www.getresponse.pl/pomoc/czym-rozni-sie-lejek-konwersji-od-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-o-funil-de-conversao-e-a-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/en-quoi-le-funnel-de-conversion-est-il-different-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheDifferencesBetweenFunnelPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-differences-between-funnel-pages.html",
"pl": "https://www.getresponse.pl/pomoc/czym-roznia-sie-strony-lejka-konwersji.html",
"de": "https://www.getresponse.com/de/hilfe/worin-unterscheiden-sich-die-verschiedenen-funnel-seiten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-diferencias-entre-las-paginas-de-embudo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chem-otlichajutsa-stranitsy-dostupnyje-v-voronkah.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-diferencas-entre-as-paginas-do-funil.html",
"it": "https://www.getresponse.com/it/assistenza/che-differenza-c-e-tra-le-varie-pagine-dei-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-differences-entre-les-pages-de-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostVChemRaznicaMezhduPismomSObychnymTekstomHtmlPismom = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBasicScoringPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/basic-scoring-plan.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-scoringowy.html",
"de": "https://www.getresponse.com/de/hilfe/basis-scoring-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-puntuacion-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-skoringa.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/piano-punteggi-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-scores-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheLeadQualifyingTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-lead-qualifying-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kwalifikowanie-leadow.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-lead-qualifikation-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-calificacion-del-cliente-potencial.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-kvalifikacia-lidov.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-qualificacao-de-lead.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-servono-i-modelli-di-qualificazione-lead.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-qualification-des-prospects.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanILinkTemplatesTogether = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-link-templates-together.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-vorlagen-miteinander-verknuepfen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-collegare-i-template-tra-di-loro.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-soedinyat-shablonyi-avtomatizatsii-drug-s-drugom.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-laczyc-szablony.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enlazar-plantillas-entre-si.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-conetar-os-modelos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-relier-des-modeles-entre-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseTemplatesToBuildWorkflows = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseTheWaitAction = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesAMarketingAutomationMessageWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIIncludeAbandonedCartItemsInAMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-include-abandoned-cart-items-in-a-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-incluir-articulos-de-carrito-abandonado-en-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-v-soobshenie-predmety-iz-broshennoj-korziny.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-includere-gli-articoli-del-carrello-abbandonato-in-un-messaggio.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zawrzec-produkty-z-porzuconego-koszyka-w-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-incluir-itens-de-carrinho-abandonados-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-inclure-les-articles-du-panier-abandonne-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddAContactToTheAutoresponderCycleManually = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-a-contact-to-the-autoresponder-cycle-manually.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-recznie-dodac-kontakt-do-cyklu-autorespondera.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-kontakt-manuell-in-den-autoresponder-cycle-einfuegen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavit-kontakt-v-tsykl-avtootvetchika-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-manualmente-um-contato-ao-ciclo-de-autorresposta.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-manualmente-un-contacto-al-ciclo-de-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-manualmente-un-contatto-al-ciclo-di-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-manuellement-un-contact-au-cycle-d-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyDoMyEmailsSometimesEndUpInASpamFolder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIPersonalizeMyEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIEditTheSubscriptionSettingsForMyList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEineNeueKontaktliste = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCrearUnAnuncioUsandoLaIntegracionDeGoogleAds = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKoennenSichKontakteInMeineListeEintragen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMitVorlagenWorkflowsErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-vorlagen-workflows-erstellen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow-do-budowy-cykli-automation.html",
"en": "https://www.getresponse.com/help/how-to-use-templates-to-build-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-los-modelos-para-construir-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-shablony-dla-sozdanija-processov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-templates-para-criar-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-usare-i-template-per-creare-i-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-modeles-pour-creer-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobKontaktyMogaDodacSieDoMojejListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakichWarunkowWyszukiwaniaMogeUzycAbyWyszukiwacKontaktyITworzycSegmenty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnalezcIEdytowacUstawieniaMojegoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnalezcUstawieniaMojejListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-ustawienia-mojej-listy.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-list-settings.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsa-nastrojki-spiska.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-ajustes-de-mi-lista-2.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-listeneinstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestListaPowiazana = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestGetresponseIJakZNiegoKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobWyszukiwacKontakty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycNewsletter = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzygotowacPlikDoImportu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWybracOdbiorcowNewslettera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycNowaListeKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakImportowacListeKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodawacKontaktyDoList = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacPulpitZaPomocaWidgetow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestSegment = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWykluczycOdbiorcowZWysylkiNewslettera = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIRecordMyWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreOnDemandWebinarsAndHowToUseThem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-on-demand-webinars-and-how-to-use-them.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-na-zadanie-i-jak-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-on-demand-webinare-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-bajo-demanda-y-como-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-po-trebovaniju-i-kak-imi-polzovatsa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-webinars-sob-demanda-e-como-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-on-demand-e-come-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-a-la-demande-et-comment-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanThereBeMorePresentersThanJustMe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html",
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatTypesOfFilesCanIUploadToAWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanISaveMyWebinarRoomLayoutAndWhiteboard = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanILivestreamMyWebinarsOnFacebookYoutubeAndLinkedin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-livestream-my-webinars-on-facebook-youtube-and-linkedin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-webinar-live-auf-facebook-und-youtube-streamen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-translirovat-svoi-vebinary-na-facebook-youtube-ili-linkedin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-transmitir-meus-webinars-no-facebook-e-no-youtube.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-transmitowac-webinar-na-facebooku-i-youtube.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-transmitir-mis-webinars-en-facebook-y-youtube.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trasmettere-in-streaming-i-miei-webinar-su-facebook-e-youtube.html",
"fr": "https://www.getresponse.com/fr/aide/comment-diffuser-mes-webinaires-sur-facebook-et-youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheTechnicalRequirementsAndTroubleshootingStepsForWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-technical-requirements-and-troubleshooting-steps-for-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/wymagania-techniczne-i-rozwiazywanie-problemow-dla-webinarow.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-tecnici-e-i-passaggi-per-la-risoluzione-dei-problemi-per-i-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-technischen-anforderungen-und-schritte-zur-fehlerbehebung-fuer-webinare.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-tecnicos-e-as-etapas-de-solucao-para-problemas-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakovy-tehnicheskije-trebovanija-i-metody-ustranenija-nepoladok-dla-vebinarov.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-tecnicos-y-los-pasos-para-solucionar-los-problemas-de-los-webinars.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-techniques-et-les-etapes-de-depannage-pour-les-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatFeaturesDoesTheNewWebinarRoomOffer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-features-does-the-new-webinar-room-offer.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-nowy-pokoj-webinarowy.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bietet-der-neue-webinar-raum.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrece-la-nueva-sala-de-webinars.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajet-novaja-vebinarnaja-komnata.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offre-la-stanza-del-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-recursos-oferece-a-nova-sala-de-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-de-la-salle-de-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereDoIFindMyWebinarStatistics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICheckStatisticsForTheWebinarInvitationsAndRemindersIVeSent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIExportWebinarChatHistory = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIEnableAttendeesToJoinAWebinarStraightFromMyLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanISendWebinarInvitationsToPeopleWhoArenTOnMySubscriberList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-a-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-vebinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-webinar.html",
"br": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-crio-um-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-un-webinar.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanWebinarsHelpMeBuildMyEmailMarketingList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreGetresponseWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyIsnTMyLegacyLandingPageShowingUpOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-isn-t-my-legacy-landing-page-showing-up-online.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-meine-legacy-landing-page-nicht-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moj-lending-v-versii-legacy-ne-otobrazhajetsa-v-seti.html",
"it": "https://www.getresponse.com/it/assistenza/why-isn-t-my-landing-page-showing-up-online.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moja-starsza-wersja-landing-page-nie-wyswietla-sie-online.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-landing-page-legada-nao-esta-aparecendo-online.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mi-landing-page-de-legado-no-aparece-en-linea.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mon-ancienne-page-de-destination-n-apparait-elle-pas-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAFormInLegacyEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAMobileVersionOfALegacyForm = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-mobile-version-of-a-legacy-form.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wersje-mobilna-formularza-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-mobile-version-meines-legacy-formulars.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-la-version-movil-de-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-mobilnuju-versiju-v-redaktore-form-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-versao-movel-de-um-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-la-versione-mobile-di-un-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-version-mobile-d-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddCustomFieldsToMyLegacyForm = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenAFormAndALandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseAPictureAsAFormBackgroundInLegacyEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIInstallALegacyFormOnMyPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-install-a-legacy-form-on-my-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-formularz-legacy-na-swojej-stronie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-ein-legacy-webformular-auf-meiner-seite.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-un-formulario-de-legado-en-mi-pagina-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-redaktora-legacy-na-svoey-stranitse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-installo-un-modulo-sulla-mia-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIInstallMyLegacyFormOnAWordpressPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIFindOutWhichVersionOfTheLegacyFormGivesMeTheHighestConversionRate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-find-out-which-version-of-the-legacy-form-gives-me-the-highest-conversion-rate.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sprawdzic-ktora-wersja-formularza-legacy-zapewnia-mi-najlepsza-konwersje.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-heraus-welches-legacy-webformular-mir-die-hoechste-conversion-rate-beschert.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-saber-que-version-del-formulario-de-legado-me-da-la-tasa-de-conversion-mas-alta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-kakaya-versiya-formyi-legacy-imeet-naibolshii-pokazatel-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-savoir-quelle-version-de-mon-ancien-formulaire-me-donne-le-taux-de-conversion-le-plus-eleve.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanISetMyLegacyFormToPopOverMyPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITriggerMyLegacyPopoverFormWithAButtonOrALink = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZapisacTestAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIChangeAThankYouPageAndAlreadySubscribedUrlForALegacyForm = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMojeNewsletteryMoznaPrzegladacOnline = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html",
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMoznaWyslacZaproszenieNaWebinarDoOsobKtoreNieZnajdujaSieNaLiscie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-mozna-wyslac-zaproszenie-na-webinar-do-osob-ktore-nie-znajduja-sie-na-liscie.html",
"en": "https://www.getresponse.com/help/can-i-send-webinar-invitations-to-people-who-aren-t-on-my-subscriber-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-webinar-einladungen-an-kontakte-senden-die-nicht-in-meinen-listen-gespeichert-sind.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-enviar-invitaciones-a-webinars-a-personas-que-no-estan-en-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vysylat-priglashenija-na-vebinar-ludjam-kotorje-ne-nahodatsa-v-moem-spiske-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-enviar-convites-via-webinar-para-as-pessoas-que-nao-estao-na-minha-lista-de-inscritos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-inviare-inviti-ai-webinar-a-persone-che-non-sono-sulla-mia-lista-di-iscritti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-envoyer-des-invitations-a-des-webinaires-a-des-personnes-qui-ne-figurent-pas-sur-ma-liste-d-abonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaWarunekLinkKlikniety = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaPomiedzyWiadomosciaTekstowaAWiadomosciaHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicHasloDostepuDoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacTestABNaStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-test-a-b-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-set-up-a-b-testing-on-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-a-b-tests-fuer-legacy-landing-pages-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configurar-las-pruebas-a-b-en-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-ab-testirovanije-dla-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-il-test-a-b-sulle-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-configurar-o-teste-a-b-nas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-tests-a-b-sur-des-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnalezcStatystykiImportow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateCoursePromotion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-course-promotion.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-kursu-w-programie-afiliacyjnym.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-kurspromotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-corso-affiliati.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-do-curso-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-cursos-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-cours-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-kursov-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateWebinarPromotion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-webinar-promotion.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-webinarpromotion.html",
"it": "https://www.getresponse.com/it/assistenza/promozione-webinar-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/promocja-webinaru-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/promocao-de-webinar-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/promocion-de-seminarios-web-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-de-webinaire-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/prodvizhenie-vebinara-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateOnlineCourse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-online-course.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-onlinekurs.html",
"it": "https://www.getresponse.com/it/assistenza/corso-online-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/kurs-online-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/curso-online-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/curso-online-para-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kurs-dlya-partnerov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAffiliateWelcomeMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-welcome-message.html",
"de": "https://www.getresponse.com/de/hilfe/affiliate-willkommensnachricht.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-w-programie-afiliacyjnym.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-do-afiliado.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-a-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvennoe-pismo-dlya-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTaggingAffiliateContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tagging-affiliate-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/tagging-von-affiliate-kontakten.html",
"it": "https://www.getresponse.com/it/assistenza/tagging-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/tagowanie-kontaktow-z-programu-afiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/marcando-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/etiquetado-de-contactos-de-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/tags-des-contacts-d-affilies.html",
"ru": "https://www.getresponse.com/ru/help-ru/prisvoenie-tegov-partnjoram.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostScoringAffiliateContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/scoring-affiliate-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/scoring-von-affiliate-kontakten.html",
"it": "https://www.getresponse.com/it/assistenza/punteggio-contatti-affiliati.html",
"pl": "https://www.getresponse.pl/pomoc/scoring-kontaktow-z-programu-affiliacyjnego.html",
"br": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"pt": "https://www.getresponse.com/pt/ajuda/pontuacao-de-contatos-de-afiliados.html",
"es": "https://www.getresponse.com/es/ayuda/puntuacion-de-los-contactos-de-los-afiliados.html",
"fr": "https://www.getresponse.com/fr/aide/score-des-contacts-d-affilie.html",
"ru": "https://www.getresponse.com/ru/help-ru/skoring-partnjorov.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConvertingContactsToCustomers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/converting-contacts-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/zamiana-kontaktow-w-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/kontakte-in-kunden-umwandeln.html",
"es": "https://www.getresponse.com/es/ayuda/conversion-de-contactos-en-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/konversija-kontaktov-v-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/conversao-de-contatos-em-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/trasformare-i-contatti-in-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/conversion-des-contacts-en-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostEarlyBirdSale = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/early-bird-sale.html",
"pl": "https://www.getresponse.pl/pomoc/limitowana-oferta.html",
"de": "https://www.getresponse.com/de/hilfe/fruehbucher-rabatt.html",
"es": "https://www.getresponse.com/es/ayuda/venta-anticipada.html",
"ru": "https://www.getresponse.com/ru/help-ru/skidki-dla-pervyh-pokupatelej.html",
"br": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"pt": "https://www.getresponse.com/pt/ajuda/liquidacao-antecipada.html",
"it": "https://www.getresponse.com/it/assistenza/sconto-early-bird.html",
"fr": "https://www.getresponse.com/fr/aide/promotion-reservee-aux-plus-rapides.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheSalesPromotionsTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-sales-promotions-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-sprzedaz.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-verkaufsaktionen-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-promociones-de-ventas.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-prodazhi-i-promo.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-promocoes-de-vendas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-promozioni-di-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-promotions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreListBuilderApps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-list-builder-apps.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-formularze-pop-up-w-edytorze-legacy.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-formular-apps.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-aplicaciones-de-list-builder-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-formy-prilogeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-applications-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoYouOfferAPlainHtmlFormCode = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateFacebookAdsInGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-facebook-ads-in-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-na-facebooku.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-werbeanzeige-mit-der-facebook-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-con-la-integracion-de-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-objavlenie-s-pomosju-integracii-s-facebook-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-com-a-integracao-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-facebook-con-l-integrazione-facebook-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-facebook-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatStatusesCanMyFacebookAdsHave = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-statuses-can-my-facebook-ads-have.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-status-moze-otrzymac-moja-reklama-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/welchen-status-kann-meine-facebook-anzeige-haben.html",
"es": "https://www.getresponse.com/es/ayuda/que-estado-pueden-tener-mis-anuncios-de-facebook.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-statysu-mogyt-byt-u-moei-reklamu-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-status-os-meus-facebook-ads-podem-ter.html",
"it": "https://www.getresponse.com/it/assistenza/quali-stati-possono-avere-i-miei-annunci-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreWebsiteVisitorsInFacebookAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-website-visitors-in-facebook-ads.html",
"pl": "https://www.getresponse.pl/pomoc/kim-sa-odwiedzajacy-strone-facebook-ads.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-webseiten-besucher-in-facebook-ads.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-visitantes-del-sitio-web-en-facebook-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-posetiteli-stranicy-v-reklame-v-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-visitantes-do-site-no-facebook-ads.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-visitatori-del-sito-web-negli-inserzioni-di-facebook.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-visiteurs-du-site-web-dans-les-publicites-facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstDateienUndBilderUndWieVerwendeIchEs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchHtmlNewsletterSelbstProgrammieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchNachrichtenMitRechtsLinksOrientierungErstellen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdavatPismaSVyravnivanijemTekstaPoPravomuKraju = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiSozdavatRassylkiNaHtmlKode = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaSozdavatRassylkiAdaptirujemyjeDlaMobilnyhUstrojstv = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatRassylku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostUMojegoSoobshenijaPokazatel0ProcentovOtkrytijPochemuTak = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html",
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostStatistikaPoOtkrytijamMojegoPismaNedostupnaVChemPrichina = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html",
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeMozhnoPosmotretStatistikuPoKonkretnomuSpiskuIliPismu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateGoogleAdsInGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-google-ads-in-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-inserzione-utilizzando-l-integrazione-di-google-ads.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-anzeige-mithilfe-der-google-ads-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-anuncio-usando-la-integracion-de-google-ads.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-reklame-google-ads.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-anuncio-usando-a-integracao-do-google-ads.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-reklamu-s-pomoshhju-integracii-s-google-ads.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-des-publicites-google-dans-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsFilesAndImagesAndHowDoIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-files-and-images-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-funkcja-pliki-i-obrazy-i-jak-z-niej-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dateien-und-bilder-und-wie-verwende-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-archivos-e-imagenes-y-como-lo-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-faily-i-izobrazeniya-i-kak-etim-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-file-e-immagini-e-come-posso-utilizzarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-arquivos-e-imagens-e-como-usa-los.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-fichiers-et-images-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIConnectMyOnlineStoreToGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-connect-my-online-store-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-polaczyc-sklep-internetowy-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-online-shop-mit-getresponse-verknuepfen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-conectar-mi-tienda-online-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-svoi-online-magazin-s-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-il-mio-negozio-online-a-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-conectar-a-minha-loja-online-a-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-connecter-ma-boutique-en-ligne-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchBeimImportMeinerKontakteTagsUndScoringPunkteZuordnen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchZufaelligAusgewaehltenKontaktenEinTagZuweisen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstKlickTracking = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindReportsUndWieNutzeIchSie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-reports-und-wie-nutze-ich-sie.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-raporty-i-jak-moge-z-nich-korzystac.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-informes-y-como-puedo-usarlos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otchety-i-kak-ja-mogu-ich-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/what-are-reports-and-how-can-i-use-them.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeOtchetyIKakJaMoguIchIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otchety-i-kak-ja-mogu-ich-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-raporty-i-jak-moge-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-reports-und-wie-nutze-ich-sie.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-informes-y-como-puedo-usarlos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-relatorios-e-como-posso-usa-los.html",
"it": "https://www.getresponse.com/it/assistenza/what-are-reports-and-how-can-i-use-them.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeOtslezhivanijePokazatelaOtkrytij = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeOtslezhivanijePoKliku = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNachodjatsaChernovikiPisem = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nachodjatsa-chernoviki-pisem.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-message-drafts.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-bozze-dei-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-nachrichtenentwuerfe.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mis-borradores-de-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-wersje-robocze-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPrisvaivatKontaktamTegiVSluchajnomPorjadke = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNaznachatTegiIBallyVoVremjaImporta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeJaMoguNaznachitTegiIBallyKontaktam = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaListyWykluczenIJakMogeZNichKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnalezcKontaktyNieaktywne = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieRoleMogePrzydzielacWspolpracownikom = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html",
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacRekordCnameDoSubdomeny = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-rekord-cname-do-subdomeny.html",
"en": "https://www.getresponse.com/help/how-do-i-add-a-cname-entry-to-my-subdomain.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-cname-eintrag-zu-meiner-subdomain-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-un-registro-cname-a-mi-subdominio-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-cname-zapis-k-mojemu-subdomenu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-record-cname-al-mio-sottodominio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-entrada-cname-ao-meu-subdominio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-cname-a-mon-sous-domaine.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialajaOplatyWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaFiltrLiczbaSubskrybentow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestCyklAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePrzenosicLubKopiowacKontaktyPomiedzyCyklami = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyWebinaryDzialajaNaUrzadzeniachMobilnych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html",
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKontaktMozeWypisacSieZMojejListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeZainstalowacFormularzLegacyNaStronieWordpress = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zainstalowac-formularz-legacy-na-stronie-wordpress.html",
"en": "https://www.getresponse.com/help/how-can-i-install-my-legacy-form-on-a-wordpress-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-ein-legacy-webformular-auf-einer-wordpress-seite-installieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-instalar-mi-formulario-de-legado-en-una-pagina-de-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-instalar-meu-formulario-legado-em-uma-pagina-do-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-versii-legacy-na-svoei-stranitse-v-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-installare-il-mio-modulo-su-una-pagina-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-installer-mon-ancien-formulaire-sur-une-page-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoSieStanieGdyPrzekroczeDopuszczalnyRozmiarBazyAdresowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoNiektoreElementyMojegoMojegoCykluSaWyszarzone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-elementy-mojego-mojego-cyklu-sa-wyszarzone.html",
"en": "https://www.getresponse.com/help/some-elements-in-my-workflow-are-grayed-out-why.html",
"de": "https://www.getresponse.com/de/hilfe/manche-elemente-meines-workflows-sind-ausgegraut-warum.html",
"es": "https://www.getresponse.com/es/ayuda/algunos-elementos-de-mi-flujo-de-trabajo-estan-en-gris-por-que.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-nekotorye-elementy-v-mojom-processe-vydeleny-serym-cvetom.html",
"br": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pt": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"it": "https://www.getresponse.com/it/assistenza/alcuni-elementi-nel-automation-workflow-sono-disattivati-perche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKopiowacLubPrzenosicKontaktyZJednejListyNaDruga = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaWarunekWiadomoscWyslana = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicLiczbeOsobMajacychDostepDoMojegoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobMogeDodacVideoDoStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-video-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-to-add-a-video-to-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-video-in-meine-legacy-landing-page-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-un-video-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-na-lending-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-video-alla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-video-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-ancienne-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUtrzymacHigieneBazyAdresowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWebinaryMogaPomocWBudowaniuListyCzyMogePromowacWebinarPozaGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-webinary-moga-pomoc-w-budowaniu-listy-czy-moge-promowac-webinar-poza-getresponse.html",
"en": "https://www.getresponse.com/help/how-can-webinars-help-me-build-my-email-marketing-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-webinare-mir-helfen-meine-kontaktliste-aufzubauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-pueden-los-webinars-ayudarme-a-construir-mi-lista-de-email-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vebinary-mogut-pomoch-mne-rashirit-svoj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-webinars-podem-me-ajudar-a-criar-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/in-che-modo-i-webinar-possono-aiutarmi-a-creare-la-mia-lista-di-email-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-webinaires-peuvent-ils-m-aider-a-developper-ma-liste-d-adresses-e-mail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToJestAutoresponder = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPrimenjatTegi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiIspolzovatTegiIBallyCherezApi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPrimenjatBally = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuUMojegoImportaProblemySvazannyjeSPolitikojServisa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuUMojegoImportaProblemySvazannyjeSRasprostranenikemSpiska = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNahoditsaStatistikaImporta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakRabotajetFunkcijaAvtomaticheskojProverkiTelefona = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdavatIIspolzovatNastraivajemyjePola = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaDobavlatSsylkiVNastraivajemyjePola = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodacDoCykluKontaktyKtoreBylySkopiowaneLubPrzeniesioneDoInnejListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZablokowacAdresomEmailIDomenomDostepDoMoichListLubCalegoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycAutoresponder = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDostosowacStarszaWersjeLandingPageDoUrzadzenMobilnych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dostosowac-starsza-wersje-landing-page-do-urzadzen-mobilnych.html",
"en": "https://www.getresponse.com/help/how-do-i-optimize-my-legacy-landing-page-for-mobile-devices.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-meine-legacy-landing-pages-fuer-mobilgeraete.html",
"es": "https://www.getresponse.com/es/ayuda/como-optimizo-mi-landing-page-de-legado-para-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-optiizirovat-svoj-lending-v-versii-legacy-dla-mobilnych-ustrojstv.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-ottimizzare-la-mia-landing-page-per-i-dispositivi-mobili.html",
"br": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-otimizar-minha-landing-page-legada-para-dispositivos-moveis.html",
"fr": "https://www.getresponse.com/fr/aide/comment-optimiser-mon-ancienne-landing-page-pour-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaDuplikowanieCyklow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacAkcjiCzekajWCyklu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-czekaj-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-wait-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-warten-aktion.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-la-accion-esperar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-deystviye-zaderzhka.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-acao-esperar.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-attendi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-l-action-attendre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieUstawieniaSubskrypcjiMogeUwzglednicWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPostWebinarFollowUp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-webinar-follow-up.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-webinarze.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-webinar.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/pismo-posle-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-dopo-il-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBirthdayMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/birthday-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-urodzinowa.html",
"de": "https://www.getresponse.com/de/hilfe/geburtstagsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-cumpleanos.html",
"ru": "https://www.getresponse.com/ru/help-ru/pozdravlenije-s-dniom-rozhdenija.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-aniversario.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-auguri.html",
"fr": "https://www.getresponse.com/fr/aide/message-d-anniversaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheWebinarsAndEventsTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-webinars-and-events-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-webinary-i-wydarzenia.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-webinare-und-events-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-webinars-y-eventos.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-verbinary-i-sobytija.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-webinars-e-eventos.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-webinar-ed-eventi.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-webinaires-et-d-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheOnlineCoursesTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-online-courses-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-kursy-online.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-online-kurse-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-cursos-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-onlajn-kursy.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-cursos-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-corsi-online.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-cours-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAbandonedCartReminder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart-reminder.html",
"pl": "https://www.getresponse.pl/pomoc/przypomnienie-o-porzuconym-koszyku.html",
"de": "https://www.getresponse.com/de/hilfe/erinnerung-bei-abgebrochenen-warenkoerben.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanije-broshenoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostAbandonedCartSeries = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart-series.html",
"pl": "https://www.getresponse.pl/pomoc/seria-wiadomosci-o-porzuconym-koszyku.html",
"de": "https://www.getresponse.com/de/hilfe/serie-fuer-abgebrochene-warenkoerbe.html",
"es": "https://www.getresponse.com/es/ayuda/series-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/serija-pisem-o-broshennoj-korzine.html",
"br": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/serie-para-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/serie-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/serie-pour-les-paniers-abandonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheAbandonedCartTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-abandoned-cart-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-porzucony-koszyk.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-abgebrochener-warenkorb-8211-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-broshennaja-korzina.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-carrinho-abandonado.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-carrello-abbandonato.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTimeSensitiveReminder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/time-sensitive-reminder.html",
"de": "https://www.getresponse.com/de/hilfe/zeitkritische-erinnerung.html",
"it": "https://www.getresponse.com/it/assistenza/promemoria-urgenti.html",
"ru": "https://www.getresponse.com/ru/help-ru/napominanie-o-broshennoi-korzine.html",
"pl": "https://www.getresponse.pl/pomoc/zacheta-do-szybkiego-zakupu.html",
"es": "https://www.getresponse.com/es/ayuda/recordatorio-de-urgencia.html",
"br": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"pt": "https://www.getresponse.com/pt/ajuda/lembrete-sensivel-ao-tempo.html",
"fr": "https://www.getresponse.com/fr/aide/rappel-avec-offre-a-duree-limitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatKindOfEcommerceDataIsSentToGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-kind-of-ecommerce-data-is-sent-to-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-art-von-e-commerce-daten-werden-an-getresponse-gesendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-datos-de-e-commerce-se-envian-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/che-tipo-di-dati-ecommerce-vengono-inviati-a-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-dane-ecommerce-sa-przesylane-do-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-ecommerce-dannye-otpravlyautsia-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-tipo-de-dados-de-ecommerce-sao-enviados-para-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-type-de-donnees-e-commerce-est-envoye-a-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIGrowMyBusinessWithGetresponseEcommerceFeatures = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-grow-my-business-with-getresponse-ecommerce-features.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-rozwinac-swoja-firme-przy-uzyciu-narzedzi-ecommerce.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-unternehmen-mit-den-e-commerce-funktionen-von-getresponse-ausbauen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-hacer-crecer-mi-negocio-con-las-funciones-de-e-commerce-de-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-accrescere-la-mia-attivita-con-le-funzionalita-di-e-commerce-di-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-expandir-meu-negocio-com-os-recursos-de-ecommerce-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razvivat-svoj-biznes-s-pomoshhju-funkcij-elektronnoj-kommercii-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-developper-mon-activite-avec-les-fonctions-e-commerce-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeSushestvujutTipyNastraivajemuchPolej = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeSpiskiIskluchenijIKakIhIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPostPurchaseFollowUp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase-follow-up.html",
"pl": "https://www.getresponse.pl/pomoc/komunikacja-po-zakupie.html",
"de": "https://www.getresponse.com/de/hilfe/follow-up-nach-einkauf.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/pisma-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/follow-up-apos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreThePostPurchaseTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-post-purchase-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-po-zakupie.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-nach-dem-kauf-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-pos-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-posle-pokupki.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-pos-compra.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-post-vendita.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-post-achat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostTargetedPostPurchaseFollowUp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/targeted-post-purchase-follow-up.html",
"de": "https://www.getresponse.com/de/hilfe/targetiertes-follow-up-nach-einkauf.html",
"it": "https://www.getresponse.com/it/assistenza/follow-up-post-vendita-mirati.html",
"pl": "https://www.getresponse.pl/pomoc/targetowana-komunikacja-po-zakupie.html",
"es": "https://www.getresponse.com/es/ayuda/seguimiento-pos-compra-dirigido.html",
"br": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"pt": "https://www.getresponse.com/pt/ajuda/acompanhamento-pos-compra-direcionado.html",
"fr": "https://www.getresponse.com/fr/aide/suivi-post-achat-cible.html",
"ru": "https://www.getresponse.com/ru/help-ru/personalizirovannoe-pismo-posle-pokupki.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConsentBasedPurchaseConfirmation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-based-purchase-confirmation.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-kaufbestaetigung.html",
"es": "https://www.getresponse.com/es/ayuda/confirmacion-de-compra-basada-en-el-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/podtverzhdenije-o-pokupke-na-osnove-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/confirmacao-de-compra-baseada-em-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/conferma-di-acquisto-basata-su-consenso.html",
"pl": "https://www.getresponse.pl/pomoc/potwierdzenie-zakupu-zalezne-od-udzielonych-zgod.html",
"fr": "https://www.getresponse.com/fr/aide/confirmation-d-achat-basee-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostListCleaningThroughTagsAndScores = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-cleaning-through-tags-and-scores.html",
"pl": "https://www.getresponse.pl/pomoc/czyszczenie-listy-przez-scoring-i-tagowanie.html",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene-mit-tags-und-punktestaenden.html",
"es": "https://www.getresponse.com/es/ayuda/limpieza-de-lista-a-traves-de-etiquetas-y-puntos.html",
"ru": "https://www.getresponse.com/ru/help-ru/chistka-spiska-po-tegam-i-ballam.html",
"br": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/limpeza-de-lista-atraves-de-tags-e-classificacoes.html",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste-attraverso-tag-e-punteggi.html",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste-avec-tags-et-scores.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWinBack = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/win-back.html",
"pl": "https://www.getresponse.pl/pomoc/odzyskiwanie-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/rueckgewinnung.html",
"es": "https://www.getresponse.com/es/ayuda/recuperar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/vozvrashenije-podpischikov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquiste.html",
"it": "https://www.getresponse.com/it/assistenza/riconquistare-le-vendite-mancate.html",
"fr": "https://www.getresponse.com/fr/aide/regagnez-leur-interet.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostContactRetargeting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/contact-retargeting.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/kontakt-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-des-contacts.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-contatti.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCustomerRetargeting = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/customer-retargeting.html",
"pl": "https://www.getresponse.pl/pomoc/retargeting-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/kunden-retargeting.html",
"es": "https://www.getresponse.com/es/ayuda/reorientacion-del-cliente.html",
"ru": "https://www.getresponse.com/ru/help-ru/retargeting-dla-klijentov.html",
"br": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"pt": "https://www.getresponse.com/pt/ajuda/reconquista-de-cliente.html",
"it": "https://www.getresponse.com/it/assistenza/retargeting-dei-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/retargeting-de-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostRewardingLoyalContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/rewarding-loyal-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/nagradzanie-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/treue-kunden-belohnen.html",
"es": "https://www.getresponse.com/es/ayuda/recompensa-de-contactos-fieles.html",
"ru": "https://www.getresponse.com/ru/help-ru/pooshrenije-lojalnyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/recompensa-para-clientes-fidelizados.html",
"it": "https://www.getresponse.com/it/assistenza/premiazione-dei-clienti-fedeli.html",
"fr": "https://www.getresponse.com/fr/aide/recompensez-les-contacts-fideles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheEngagementAndRetentionTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-engagement-and-retention-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-zaangazowanie-i-retencja.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-einbindung-und-retention-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-compromiso-y-retencion.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-vovlechenije-i-uderzhanije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-envolvimento-e-retencao.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-interazione-e-fidelizzazione.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-d-engagement-et-de-retention.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChristmasEngagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/christmas-engagement.html",
"pl": "https://www.getresponse.pl/pomoc/swiateczne-zaangazowanie.html",
"de": "https://www.getresponse.com/de/hilfe/weihnachtsaktion.html",
"es": "https://www.getresponse.com/es/ayuda/participacion-de-navidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenije-v-rozhdestvenskije-prazdniki.html",
"br": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"pt": "https://www.getresponse.com/pt/ajuda/participacao-de-natal.html",
"it": "https://www.getresponse.com/it/assistenza/impegno-di-natale.html",
"fr": "https://www.getresponse.com/fr/aide/participation-de-noel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBasicTaggingPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/basic-tagging-plan.html",
"pl": "https://www.getresponse.pl/pomoc/podstawowy-plan-tagowania.html",
"de": "https://www.getresponse.com/de/hilfe/basis-tagging-plan.html",
"es": "https://www.getresponse.com/es/ayuda/plan-de-etiquetado-basico.html",
"ru": "https://www.getresponse.com/ru/help-ru/bazovyj-plan-tegov.html",
"br": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/plano-basico-de-colocacao-de-tags.html",
"it": "https://www.getresponse.com/it/assistenza/piano-tagging-di-base.html",
"fr": "https://www.getresponse.com/fr/aide/systeme-de-tags-basique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcomeNewContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome-new-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-neuer-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/accueill-des-nouveaux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSimpleWelcomeMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/simple-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/prosta-wiadomosc-powitalna.html",
"de": "https://www.getresponse.com/de/hilfe/simple-willkommensnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/mensaje-de-bienvenida-simple.html",
"ru": "https://www.getresponse.com/ru/help-ru/prostoje-privetstvennoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-simples-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/semplice-messaggio-di-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWelcomeNewCustomers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome-new-customers.html",
"pl": "https://www.getresponse.pl/pomoc/powitanie-nowych-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/begruessung-von-neukunden.html",
"es": "https://www.getresponse.com/es/ayuda/bienvenida-a-nuevos-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-novyh-klientov.html",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas-aos-novos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/dai-il-benvenuto-ai-nuovi-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/accueil-des-nouveaux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheWelcomeTemplatesUsedFor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-welcome-templates-used-for.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-wykorzystuje-sie-szablony-z-kategorii-powitanie.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-werden-die-begruessung-vorlagen-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/para-que-se-usan-los-modelos-de-bienvenida.html",
"ru": "https://www.getresponse.com/ru/help-ru/dla-chego-nuzhny-shablony-privetstvije.html",
"br": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"pt": "https://www.getresponse.com/pt/ajuda/para-que-sao-usados-os-templates-de-boas-vindas.html",
"it": "https://www.getresponse.com/it/assistenza/per-che-cosa-sono-utilizzati-i-template-benvenuto.html",
"fr": "https://www.getresponse.com/fr/aide/a-quoi-servent-les-modeles-de-bienvenue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostConsentBasedWelcomeMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-based-welcome-message.html",
"pl": "https://www.getresponse.pl/pomoc/wiadomosc-powitalna-zalezna-od-udzielonych-zgod.html",
"it": "https://www.getresponse.com/it/assistenza/messaggio-di-benvenuto-basato-su-consenso.html",
"br": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/mensagem-de-boas-vindas-baseada-em-consentimento.html",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsbasierte-willkommensnachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvije-na-osnove-soglasija.html",
"fr": "https://www.getresponse.com/fr/aide/message-de-bienvenue-base-sur-le-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhichWorkflowTemplatesAreAvailableInMyPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/which-workflow-templates-are-available-in-my-plan.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-szablony-cykli-automation-sa-dostepne-w-moim-planie-cenowym.html",
"de": "https://www.getresponse.com/de/hilfe/welche-workflow-vorlagen-sind-in-meinem-plan-enthalten.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-modelos-de-flujo-de-trabajo-estan-disponibles-en-mi-plan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-shablony-dostupny-v-mojem-tarifnom-plane.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-templates-de-fluxo-de-trabalho-estao-disponiveis-no-meu-plano.html",
"it": "https://www.getresponse.com/it/assistenza/quali-template-dei-workflow-sono-disponibili-con-il-mio-pacchetto.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-modeles-de-flux-de-travail-disponibles-dans-mon-forfait.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyIsTheNegativePathNotTriggeredForMyStartingCondition = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-is-the-negative-path-not-triggered-for-my-starting-condition.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-percorso-negativo-non-viene-attivato-per-la-mia-condizione-iniziale.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-dla-moyego-startovogo-usloviya-ne-srabotal-otritsatelny-put.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-caminho-negativo-nao-e-acionado-para-minha-condicao-inicial.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-negatywna-sciezka-nie-jest-uruchamiana-dla-mojego-warunku-poczatkowego.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-activa-la-ruta-negativa-en-mi-condicion-inicial.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wirkt-ein-negatives-verbindungspunkt-nicht-als-eine-ausgangbedingung-in-meinem-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-chemin-negatif-n-est-il-pas-declenche-pour-ma-condition-de-depart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostICanTPublishMyWorkflowWhatDoIDo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html",
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheSplitterFilterWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-splitter-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-splitter.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-separatore.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-filtr-razdelitel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-divisor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-podzial.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-divisor.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-repartition.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIFilterOutDuplicateContactsInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-filter-out-duplicate-contacts-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-zduplikowane-kontakty-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-doppelte-kontakte-in-einem-workflow-filtern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-filtrar-contactos-duplicados-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otfiltrovat-dubli-kontaktov-cherez-protsess-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-filtrar-contatos-duplicados-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-filtrare-i-contatti-duplicati-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-filtrer-les-contacts-en-double-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseTheConsentStatusFilter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-the-consent-status-filter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-filtru-status-zgody.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-den-einwilligungsstatus-filter.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-el-filtro-estado-del-consentimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-filtr-status-soglasija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-filtro-status-de-consentimento.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-utilizza-il-filtro-per-lo-stato-del-consenso.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-filtre-statut-de-consentement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheListsFilterWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheDynamicSegmentFilterWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheAmountFilterWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-amount-filter-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-liczba-subskrybentow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-anzahl.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-cantidad.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-kolichestvo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-valor.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-quantita.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-nombre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheRangeFilterWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseTheScoreAction = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseTagsToSeeWhoStartedOrFinishedMyWorkflows = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseTheTagActionInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIRemoveContactsAsAResultOfWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIMoveOrCopyContactsBetweenWorkflows = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-move-or-copy-contacts-between-workflows.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przenosic-lub-kopiowac-kontakty-pomiedzy-cyklami.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verschiebe-oder-kopiere-ich-kontakte-zwischen-workflows.html",
"es": "https://www.getresponse.com/es/ayuda/como-muevo-o-copio-contactos-entre-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-peremeshat-kontakty-mezhdu-spiskami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-mover-ou-copiar-contatos-entre-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-spostare-o-copiare-i-contatti-da-un-workflow-all-altro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-deplacer-ou-copier-des-contacts-entre-les-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIMoveMyContactsFromOneListToAnotherAsAResultOfAMarketingAutomationWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICopyContactsToAnotherListInMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseCustomFieldsInMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIEditAMessageThatIMUsingInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-edit-a-message-that-i-m-using-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-edytowac-wiadomosc-ktorej-uzywam-w-cyklu-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eine-nachricht-bearbeiten-die-in-einem-workflow-verwendet-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-editar-un-mensaje-que-estoy-usando-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-redaktirovat-pismo-kotoroje-ispolzujetsa-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-editar-uma-mensagem-que-estou-usando-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-modificare-un-messaggio-che-sto-utilizzando-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-modifier-un-message-que-j-utilise-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddEtsyProductsToMyAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-etsy-products-to-my-account.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-produkty-etsy-do-mojego-konta.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-etsy-produkte-zu-meinem-konto-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-agregar-productos-de-etsy-a-mi-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-produtos-etsy-a-minha-conta.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-prodykty-etsy-v-svoi-akkayny.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-prodotti-etsy-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-produits-etsy-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereDoIFindTheApiKey = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIAmADeveloperWhereCanIFindInformationAboutApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html",
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIIntegrateMyApplicationWithGetresponseUsingApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIMaximizeTheAccuracyOfApiPhoneValidation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanISetUpRegularImportsToMyLists = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-set-up-regular-imports-to-my-lists.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-impostare-importazioni-regolari-nelle-mie-liste.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-regelmaessige-importe-in-meine-listen-einrichten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-configurar-las-importaciones-regulares-a-mis-listas.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-regularne-importy-do-moich-list.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-configurar-as-importacoes-regulares-para-as-minhas-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-nastroit-regulyarnyi-import-v-moi-spiski.html",
"fr": "https://www.getresponse.com/fr/aide/comment-mettre-en-place-des-importations-regulieres-vers-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddEventbriteCustomersToMyContactList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-eventbrite-customers-to-my-contact-list.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-z-eventbrite-do-mojej-listy-kontaktow.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavit-klientov-eventbrite-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-eventbrite-a-minha-lista-de-contatos.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-eventbrite-kunden-zu-meiner-kontaktliste-hinzufuegen.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-eventbrite-alla-mia-lista-di-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-clientes-de-eventbrite-a-mi-lista-de-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-de-eventbrite-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavlatVChernyjSpisokAdresaIDomenyIzMoihSpiskovIliUchetnojZapisi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSdelatSpisokKontaktovAktualnym = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodderzhivatGigijenuSpiska = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakijeUslovijaJaMoguIspolzovatDlaPoiskaKontaktovISozdanijaSegmentov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeSegmentIKakEgoSozdat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseTimeSettingsInConditionProperties = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesBillingStatusChangedConditionWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-billing-status-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-status-platnosci-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-cambio-de-estado-de-facturacion-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-status-da-cobranca.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-di-modifica-dello-stato-di-fatturazione.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-abrechnungsstatus-geaendert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotaet-uslovie-status-oplaty-izmenilsya.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-paiement-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseTheEventConditionInMyWorkflows = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-the-event-condition-in-my-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-ereignis-in-meinem-workflow.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-uslovije-sobytije-v-svoih-processah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-evento-em-meus-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-evento-in-un-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skorzystac-z-warunku-zdarzenie-w-moim-cyklu-automation.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-la-condicion-evento-en-mis-flujos-de-trabajo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-evenement-dans-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoISetPropertiesForTheUrlVisitedCondition = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoISetPropertiesForTheAbandonedCartCondition = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoISetPropertiesForThePurchaseCondition = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddTheTrackingJavascriptCodeToMyWebsite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatSCoveredInTheEcommerceAndEventTrackingFeatures = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseTheTagAssignedConditionInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUseTheIfScoreConditionInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseTheLandingPageVisitedConditionInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-the-landing-page-visited-condition-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-odwiedzono-landing-page-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-bedingung-landing-page-besucht-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-condicion-landing-page-visitada-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovatuslovije-posetil-lending-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-landing-page-visitada-num-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-landing-page-visitata-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-page-de-destination-visitee-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheConsentStatusUpdatedConditionWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-consent-status-updated-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-einwilligungsstatus-aktualisiert.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-status-soglasija-obnovlen.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-stato-del-consenso-aggiornato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-do-status-de-consentimento-atualizado-funciona-2.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-estado-del-consentimiento-actualizado.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zaktualizowano-status-zgody.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-statut-de-consentement-mis-a-jour.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddToAWorkflowContactsWhoWereCopiedOrMovedToADifferentList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-to-a-workflow-contacts-who-were-copied-or-moved-to-a-different-list.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-do-cyklu-kontakty-ktore-byly-skopiowane-lub-przeniesione-do-innej-listy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-kontakte-zu-einem-workflow-hinzufuegen-die-in-eine-andere-liste-kopiert-oder-verschoben-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-a-un-flujo-de-trabajo-contactos-que-fueron-copiados-o-movidos-a-una-lista-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavlat-v-protsess-kontakty-kotoryje-byli-skopirovany-ili-peremesheny-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-contatos-que-foram-copiados-ou-movidos-para-uma-lista-diferente-a-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-al-workflow-i-contatti-che-sono-stati-copiati-o-spostati-a-un-altra-lista.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-a-un-flux-de-travail-des-contacts-qui-ont-ete-copies-ou-deplaces-vers-une-autre-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheMessageSentConditionWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-message-sent-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wiadomosc-wyslana.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-gesendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-mensaje-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-pismo-otpravleno.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-mensagem-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-messaggio-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-message-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheCustomFieldChangedConditionWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheSpecialOccasionConditionWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-special-occasion-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-wyjatkowa-okazja.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-ricorrenza-speciale.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-osobyj-povod.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-de-ocasion-especial.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-besonderer-anlass-2.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-de-ocasiao-especial.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-occasion-speciale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyCanISeeOnlySelectedMessagesInMessageOpenedAndLinkClickedConditions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-can-i-see-only-selected-messages-in-message-opened-and-link-clicked-conditions.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-nur-bestimmte-nachrichten-unter-den-bedingungen-nachricht-geoeffnet-und-link-angeklickt-sehen.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-vizhu-tolko-nekotoryje-soobshenia.html",
"it": "https://www.getresponse.com/it/assistenza/perche-posso-vedere-solo-alcuni-messaggi-nelle-condizioni-messaggio-aperto-e-link-cliccato.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-widze-tylko-wybrane-wiadomosci-w-warunkach-wiadomosc-otwarta-oraz-link-klikniety.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-solo-puedo-ver-determinados-mensajes-en-las-condiciones-mensaje-abierto-y-link-clicado.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-so-posso-ver-mensagens-selecionadas-nas-condicoes-mensagem-aberta-e-link-clicado.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-voir-que-les-messages-selectionnes-dans-les-conditions-message-ouvert-et-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoITrackMessageOpens = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheLinkClickedConditionWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-link-clicked-condition-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-link-klikniety.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-link-geklickt-bedingung.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-link-clicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-byl-li-klik-po-ssylke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-condicao-link-clicado.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-link-cliccato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-lien-clique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatSubscriptionSettingsCanIIncludeInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-subscription-settings-can-i-include-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-ustawienia-subskrypcji-moge-uwzglednic-w-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/welche-anmeldeeinstellungen-kann-ich-in-einen-workflow-einbinden.html",
"es": "https://www.getresponse.com/es/ayuda/que-ajustes-de-suscripcion-puedo-incluir-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-nastrojki-podpiski-mozhno-dobavit-v-protsess-avtomatizacii.html",
"it": "https://www.getresponse.com/it/assistenza/quali-impostazioni-di-iscrizione-posso-includere-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-configuracoes-de-assinatura-posso-incluir-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/quels-parametres-d-inscription-puis-je-inclure-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostSomeElementsInMyWorkflowAreGrayedOutWhy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/some-elements-in-my-workflow-are-grayed-out-why.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-elementy-mojego-mojego-cyklu-sa-wyszarzone.html",
"de": "https://www.getresponse.com/de/hilfe/manche-elemente-meines-workflows-sind-ausgegraut-warum.html",
"es": "https://www.getresponse.com/es/ayuda/algunos-elementos-de-mi-flujo-de-trabajo-estan-en-gris-por-que.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-nekotorye-elementy-v-mojom-processe-vydeleny-serym-cvetom.html",
"br": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"pt": "https://www.getresponse.com/pt/ajuda/alguns-elementos-no-meu-fluxo-de-trabalho-estao-esmaecidos-por-que.html",
"it": "https://www.getresponse.com/it/assistenza/alcuni-elementi-nel-automation-workflow-sono-disattivati-perche.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIRunSpecificActionsAndConditionsMultipleTimes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheNumbersAtTheTopBottomAndInTheTopRightCornerOfEachElementInMyWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoTheYesNoConnectorsWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreActionsConditionsAndFilters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIWorkWithElementsInAWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIConnectMyFacebookAndGetresponseAccounts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-connect-my-facebook-and-getresponse-accounts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-getresponse-mit-meinem-facebook-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-conecto-mis-cuentas-de-facebook-y-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-conectar-as-minhas-contas-do-facebook-e-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-facebooka-z-kontem-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-podkljuchit-svoi-akkaunty-facebook-i-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-collegare-i-miei-account-facebook-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-connecter-mes-comptes-facebook-et-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIIntegrateGetresponseWithGoogleAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddSalesforceCustomersAndProspectsToMyContactList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-salesforce-customers-and-prospects-to-my-contact-list.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-klientow-i-potencjalnych-klientow-z-salesforce-do-mojej-listy-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-salesforce-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-salesforce-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvyushich-i-potencialnuch-pokypatelej-iz-salesforce-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-salesforce-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-salesforce-al-mio-elenco-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-clients-et-des-prospects-salesforce-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIKeepSalesforceAndGetresponseContactDataInSync = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-keep-salesforce-and-getresponse-contact-data-in-sync.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymywac-synchronizacje-danych-kontaktow-pomiedzy-salesforce-i-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-synchronisiere-ich-salesforce-und-getresponse-kontaktdaten.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantengo-sincronizados-los-datos-de-los-contactos-de-salesforce-y-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sinchronizirovat-dannye-o-kontaktach-mezdy-saleforce-i-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-os-dados-de-contato-sincronizados-entre-o-salesforce-e-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-mantenere-sincronizzati-i-dati-dei-contatti-di-salesforce-e-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-la-synchronisation-des-donnees-de-contact-de-salesforce-et-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIMakeChangesToAWorkflowThatHasAlreadyBeenPublished = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesDuplicatingWorkflowsWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-duplicating-workflows-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-duplikowanie-cyklow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lassen-sich-workflows-duplizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-duplicacion-de-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-dublirovanije-protsessov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-a-duplicacao-de-fluxos-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-duplicazione-dei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-duplication-des-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIDefineTheTimeWhenMyWorkflowStartsAndEnds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIStartAWorkflowWithMultipleConditions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAnAutomationWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-an-automation-workflow.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-cykl-automation.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-process-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-fluxo-de-trabalho-de-automatizacao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-automation-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-flux-d-automatisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowMuchDoesMarketingAutomationCost = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsMarketingAutomationAndWhatCanIDoWithIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanISendAnInstantMessageAfterSomeoneSignsUpViaTheLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddAnotherMessageToAnExistingAutoresponderCycle = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-another-message-to-an-existing-autoresponder-cycle.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-eine-neue-nachricht-in-einen-vorhandenen-autoresponder-cycle-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novoje-soobshenije-v-sushestvujushij-tsykl-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kolejne-wiadomosci-do-cyklu-autorespondera.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-otro-mensaje-a-un-ciclo-de-autoresponder-existente.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acrescentar-outra-mensagem-a-um-ciclo-de-autoresponder-ja-existente.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-un-altro-messaggio-a-un-ciclo-di-autoresponder-esistente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-autre-message-a-un-cycle-d-auto-repondeur-existant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseTheCalendarViewForAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIChangeTheDaysAutorespondersGoOutOn = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatSTheDifferenceBetweenAutorespondersAndMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-s-the-difference-between-autoresponders-and-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-unterscheiden-sich-autoresponder-von-automatisierungsnachrichten.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-avtootvetchikom-i-marketingowoj-avtomatizacijej.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-autoresponders-y-automatizacion-de-marketing.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-autoresponderem-a-cyklem-automation.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-autoresponder-e-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-autorespostas-e-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-les-auto-repondeurs-et-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenANewsletterAndAnAutoresponderMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAnAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-an-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAnAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-an-autoresponder.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-avtootvetchik.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUlepszycKontoFree = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePersonalizowacEmaile = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-personalizowac-emaile.html",
"en": "https://www.getresponse.com/help/how-can-i-personalize-my-emails.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-e-mails-personalisieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-personalizirovat-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-personalizzare-le-mie-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-personalizar-os-meus-e-mails.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-personnaliser-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreComplaints = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoesTheReputationOfTheFromFieldInfluenceDeliverabilityToTheInboxVsSpamFolder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyShouldnTIReAddBouncedEmailsToMyList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyShouldnTIUseYahooAolMailRuEmailAsMyFromAddress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyDoIspsRecognizeMessagesAsSpamDifferently = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyCanSomeEmailsDisplayAsOnBehalfOfOrVia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreBounces = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheBestEmailPracticesThatInfluenceDelivery = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowGoodIsYourEmailDeliverability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsDkimAndHowToConfigureIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-dkim-and-how-to-configure-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dkim-i-jak-moge-go-skonfigurowac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-dkim-und-wie-richte-ich-es-ein.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-dkim-y-como-configurarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dkim-i-kak-eto-nastroit.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-dkim-e-come-posso-configurarla.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-dkim-e-como-faco-para-configura-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-dkim-et-comment-le-configurer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToFindMyMessageHeaders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-find-my-message-headers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-odnalezc-naglowki-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-cabecalhos-de-mensagem.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-vollstaendigen-e-mail-header.html",
"es": "https://www.getresponse.com/es/ayuda/como-encontrar-los-encabezados-de-mi-mensaje.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-le-intestazioni-dei-miei-messaggi.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-sluzhebnyje-zagolovki.html",
"fr": "https://www.getresponse.com/fr/aide/comment-retrouver-les-en-tetes-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyDoMessagesSentToMyOwnDomainBounce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-do-messages-sent-to-my-own-domain-bounce.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-wiadomosci-wyslane-w-ramach-tej-samej-domeny-zostaja-zwrocone.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kommt-es-zu-einem-bounce-wenn-ich-nachrichten-an-meine-eigene-domain-e-mail-adresse-senden-will.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-proishodat-oshibki-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-as-mensagens-enviadas-para-meu-proprio-dominio-sao-rejeitadas.html",
"it": "https://www.getresponse.com/it/assistenza/perche-i-messaggi-inviati-al-mio-dominio-vengono-respinti.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-rebotan-los-mensajes-enviados-a-mi-propio-dominio.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-messages-envoyes-a-mon-propre-domaine-rebondissent-ils.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZapisacUkladElementowPokojuWebinaruITablice = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-uklad-elementow-pokoju-webinaru-i-tablice.html",
"en": "https://www.getresponse.com/help/can-i-save-my-webinar-room-layout-and-whiteboard.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-das-layout-meines-webinarraums-speichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-la-disposicion-de-la-sala-del-webinar-y-la-pizarra.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-vebinarnuju-komnatu-kak-shablon.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-o-layout-da-sala-de-webinar-e-o-whiteboard.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-il-layout-della-mia-sala-webinar-e-la-lavagna.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-la-disposition-de-ma-salle-de-webinaire-et-mon-tableau-blanc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialajaWiadomosciAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-wiadomosci-automation.html",
"en": "https://www.getresponse.com/help/how-does-a-marketing-automation-message-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-marketingautomatisierungsnachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-mensaje-de-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-pismo-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-un-messaggio-di-marketing-automation.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-uma-mensagem-de-automacao-de-marketing.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-un-message-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacPaypalZGetresponseIDodawacPrzyciskiDoWiadomosciILandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaSledzenieOtwarcWWiadomosciach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakRecznieUsuwacKontaktyZMojejListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieErstelleIchEinLegacyWebformular = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaWebinaryWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-webinary-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-are-getresponse-webinars.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-getresponse-webinare.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-webinars-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-vebinary-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-webinars-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-webinar-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-webinaires-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeNapisacNewsletterWHtmlU = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieAnzahlDerSpamBeschwerdenReduzieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-anzahl-der-spam-beschwerden-reduzieren.html",
"en": "https://www.getresponse.com/help/what-are-complaints.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-quejas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-zhaloby.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-reclamacoes.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zgloszenia-spamu.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-lamentele.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-plainte-de-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycFormularzWEdytorzeLegacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-formularz-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-form-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-ein-legacy-webformular.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-formu-v-redaktore-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-formulario-legado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUsunacKodSledzacyZeStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kod-sledzacy-ze-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-tracking-code-from-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-einen-tracking-code-von-meiner-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-un-codigo-de-seguimiento-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kod-otslezhivanija-c-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-remover-um-tracking-code-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-il-codice-di-monitoraggio-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-un-code-de-suivi-de-ma-page-de-destination-heritee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnalezcListeWszystkichMoichKontaktow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoCzegoSluzaNoweNarzedziaECommerceIWebTrafficTracking = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/do-czego-sluza-nowe-narzedzia-e-commerce-i-web-traffic-tracking.html",
"en": "https://www.getresponse.com/help/what-s-covered-in-the-ecommerce-and-event-tracking-features.html",
"de": "https://www.getresponse.com/de/hilfe/was-bieten-die-e-commerce-und-webtraffic-trackingfunktionen.html",
"es": "https://www.getresponse.com/es/ayuda/que-esta-incluido-en-las-funciones-de-e-commerce-y-seguimiento-de-trafico-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-vkluchajut-v-sebja-funktsii-otslezhivanija-ecommerce-i-veb-trafika.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-include-la-funzionalita-di-e-commerce-e-monitoraggio-degli-eventi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-esta-incluso-nos-recursos-de-acompanhamento-de-e-commerce-e-trafego-de-website.html",
"fr": "https://www.getresponse.com/fr/aide/que-couvrent-les-fonctions-d-e-commerce-et-de-suivi-des-evenements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWykorzystywacElementyWCyklu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wykorzystywac-elementy-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-work-with-elements-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mit-den-elementen-im-workflow-arbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajo-con-los-elementos-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-elementami-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalho-com-elementos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-lavorare-con-gli-elementi-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-travailler-avec-des-elements-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIHaveTheSameContactOnMoreThanOneListWillTheyGetMyMessageSeveralTimes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/i-have-the-same-contact-on-more-than-one-list-will-they-get-my-message-several-times.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-den-gleichen-kontakt-in-mehreren-listen-gespeichert-bekommt-dieser-kontakt-meine-nachrichten-mehrmals.html",
"ru": "https://www.getresponse.com/ru/help-ru/odin-i-tot-zhe-kontakt-nahoditsa-v-raznyh-spiskah.html",
"pl": "https://www.getresponse.pl/pomoc/czy-osoby-bedace-na-kilku-listach-jednoczesnie-otrzymaja-moja-wiadomosc-wielokrotnie.html",
"es": "https://www.getresponse.com/es/ayuda/tengo-el-mismo-contacto-en-mas-de-una-lista-recibiran-mi-mensaje-varias-veces.html",
"it": "https://www.getresponse.com/it/assistenza/ho-lo-stesso-contatto-in-piu-di-una-lista-riceveranno-il-mio-messaggio-piu-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/eu-tenho-o-mesmo-contato-em-mais-de-uma-lista-eles-vao-receber-minha-mensagem-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/le-meme-contact-figure-sur-plusieurs-listes-recevra-t-il-mon-message-plusieurs-fois.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateRssToEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToFindMyTemplatesAndWorkWithThem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-find-my-templates-and-work-with-them.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-szablonow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-meine-vorlagen-und-arbeite-mit-ihnen.html",
"es": "https://www.getresponse.com/es/ayuda/como-trabajar-con-los-modelos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotat-s-shablonami.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontrar-os-meus-templates-e-trabalhar-com-eles.html",
"it": "https://www.getresponse.com/it/assistenza/come-trovare-i-miei-modelli-e-lavorare-con-loro.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-mes-modeles-et-travailler-avec-eux.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanISaveAnABTest = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-save-an-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zapisac-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-a-b-test-abspeichern.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-guardar-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sohranit-ab-test.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-salvar-um-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-salvare-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-sauvegarder-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatTypesOfABTestsCanIChooseFrom = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITestDifferentPartsOfTheMessageInOneABTest = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-test-different-parts-of-the-message-in-one-a-b-test.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-in-einem-a-b-test-verschiedene-testvarianten-kombinieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-diferentes-partes-del-mensaje-en-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-testirovat-raznyje-chasti-soobshenija-v-odnom-ab-teste.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-diferentes-partes-da-mensagem-em-um-teste-a-b.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-testowac-rozne-czesci-mojej-wiadomosci-w-ramach-jednego-testu-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-testare-diverse-parti-del-messaggio-in-un-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-tester-differentes-parties-du-message-dans-un-meme-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIScheduleMyABTests = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowIsTheBestPerformingMessageBeingSent = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html",
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowManyRecipientsShouldIIncludeInMyTestingGroup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindTheABTestingFeature = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAnABTest = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-an-a-b-test.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-test-a-b.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-a-b-test.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-prueba-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-ab-test.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-un-test-a-b.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-teste-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-test-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseSiteVisitorBehaviorInAMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-site-visitor-behavior-in-a-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ecommerce-tracking-w-wiadomosciach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-e-commerce-tracking-in-einer-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-el-seguimiento-de-ecommerce-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-comportamento-dos-visitantes-do-site-numa-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-monitoraggio-dell-e-commerce-in-un-messaggio.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-povedenie-posetitelei-saita-v-pismah.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-suivi-e-commerce-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatDoesItMeanThatMyMessageHasASyntaxError = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-message-has-a-syntax-error.html",
"pl": "https://www.getresponse.pl/pomoc/co-oznacza-ze-moja-wiadomosc-zawiera-blad-skladni.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-wenn-meine-nachricht-einen-syntax-error-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-mensaje-tiene-un-error-de-sintaxis.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajet-sintaksicheskaja-oshybka-v-mojem-soobshenii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-mensagem-tem-um-erro-de-sintaxe.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-messaggio-ha-un-errore-di-syntax.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-une-erreur-de-syntaxe-dans-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddAttachmentsToMyMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindHtmlMessageTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-html-message-templates.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-wiadomosci-html.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-vorlagen-fuer-html-nachrichten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-modelos-de-mensajes-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodatsa-html-shablony-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-di-messaggi-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatFeaturesAreAvailableInTheEmailCreator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-features-are-available-in-the-email-creator.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-sa-dostepne-w-kreatorze-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-caracteristicas-estao-disponiveis-no-criador-de-email.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-sind-im-email-creator-verfuegbar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-funktsii-dostupnyi-v-redaktore-pisem.html",
"es": "https://www.getresponse.com/es/ayuda/que-funciones-ofrece-el-creador-de-emails.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-sono-disponibili-in-email-creator.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-disponibles-dans-le-createur-d-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIIntegrateSocialMediaWithMyMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanAContactUnsubscribeFromMyList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-a-contact-unsubscribe-from-my-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kontakt-moze-wypisac-sie-z-mojej-listy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-sich-ein-kontakt-von-meiner-liste-abmelden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puede-un-contacto-darse-de-baja-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakt-mozhet-otpisatsa-ot-rassylki.html",
"it": "https://www.getresponse.com/it/assistenza/come-fa-un-contatto-a-disiscriversi-dalla-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-um-contato-pode-sair-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-un-contact-peut-il-se-desinscrire-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddAVideoToMyMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanICheckHowTheMessageLooksInAnInboxBeforeISendIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsPredefinedText = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-predefined-text.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-tekst-zdefiniowany.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-vordefinierter-text.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-texto-predefinido.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-gotowyj-tekst.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-testo-predefinito.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-texto-predefinido.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-texte-predefini.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIRemoveOrEditTheUnsubscribeAndUpdateLinksAtTheBottomOfMyMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinVideoInMeineNachrichtEinfuegen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakInniMogaUdostepniacMojeNewsletteryWMediachSpolecznosciowych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-inni-moga-udostepniac-moje-newslettery-w-mediach-spolecznosciowych.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-social-media-with-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-nachrichten-mit-meinen-sozialen-medien-verknuepft-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-integrar-las-redes-sociales-en-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podpischiki-mogut-podelitsa-mojej-rassylkoj-v-soc-setjah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-as-pessoas-podem-compartilhar-as-minhas-newsletters-em-redes-sociais.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-i-social-media-con-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-les-medias-sociaux-dans-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakOkreslicTerminRozpoczeciaIZakonczeniaCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-okreslic-termin-rozpoczecia-i-zakonczenia-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-define-the-time-when-my-workflow-starts-and-ends.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-fest-zu-welchem-zeitpunkt-mein-workflow-beginnt-und-endet.html",
"es": "https://www.getresponse.com/es/ayuda/como-defino-el-momento-en-que-mi-flujo-comienza-y-termina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-vrema-nachala-i-kontsa-raboty-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-definir-o-momento-em-que-meu-fluxo-de-trabalho-comeca-e-termina.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-definire-quando-il-mio-workflow-inizia-e-finisce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-l-heure-de-debut-et-de-fin-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeNagracWebinar = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-nagrac-webinar.html",
"en": "https://www.getresponse.com/help/can-i-record-my-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-webinar-als-video-aufnehmen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-grabar-mi-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-zapisat-svoj-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-gravar-o-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/posso-registrare-il-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-enregistrer-mon-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZintegrowacMojaAplikacjeZGetresponsePrzyUzyciuApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zintegrowac-moja-aplikacje-z-getresponse-przy-uzyciu-api.html",
"en": "https://www.getresponse.com/help/can-i-integrate-my-application-with-getresponse-using-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-anwendung-ueber-api-mit-getresponse-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-integrar-mi-aplicacion-con-getresponse-utilizando-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-integrirovat-prilozenie-s-getresponse-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-integrar-minha-aplicacao-com-getresponse-usando-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-integrare-la-mia-applicazione-con-getresponse-utilizzando-l-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-integrer-mon-application-a-getresponse-a-l-aide-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacPolDodatkowychWMarketingAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-pol-dodatkowych-w-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-do-i-use-custom-fields-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierte-felder-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-los-campos-personalizados-en-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastraivaemye-polya-v-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-campos-personalizados-na-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usano-i-campi-personalizzati-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-champs-personnalises-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMojeStatystykiOtwarcSaNiedostepneCoMozeBycPowodem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html",
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakEksportowacKontakty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoMojImportZostalOdrzuconyZUwagiNaUdostepnienieListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieMetodyPlatnosciSaAkceptowanePrzezGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html",
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicMojeKampanieFacebookIStarszaWersjeStronLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-moje-kampanie-facebook-i-starsza-wersje-stron-landing-pages.html",
"en": "https://www.getresponse.com/help/can-i-track-my-facebook-campaigns-and-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-facebook-ads-kampagnen-und-legacy-landing-pages-tracken.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-un-seguimiento-de-mis-campanas-de-facebook-y-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-svoi-marketingovye-kampanii-facebook-i-lendingi-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-mie-campagne-e-landing-page-di-facebook.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-as-minhas-campanhas-do-facebook-e-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mes-campagnes-facebook-et-des-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacKodSledzacyJavascriptDoSwojejStrony = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kod-sledzacy-javascript-do-swojej-strony.html",
"en": "https://www.getresponse.com/help/how-do-i-add-the-tracking-javascript-code-to-my-website.html",
"de": "https://www.getresponse.com/de/hilfe/wie-platziere-ich-den-javascript-code-in-meiner-website.html",
"es": "https://www.getresponse.com/es/ayuda/como-coloco-el-codigo-javascript-en-mi-sitio-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-kod-otslezhivanija-javascript-na-mojem-sajte.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-il-codice-di-monitoraggio-javascript-nel-mio-sito-web.html",
"br": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-coloco-o-codigo-de-acompanhamento-javascript-no-meu-website.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-le-code-javascript-de-suivi-a-mon-site-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeUsuwacKontaktyPrzyUzyciuCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-usuwac-kontakty-przy-uzyciu-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-remove-contacts-as-a-result-of-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-aktion-kontakt-entfernen-im-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-como-resultado-del-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-udalit-kontakty-v-rezultate-processa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-contatos-como-resultado-do-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-all-interno-di-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-des-contacts-a-la-suite-d-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWievielKostetDieMarketingautomatisierung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wieviel-kostet-die-marketingautomatisierung.html",
"pl": "https://www.getresponse.pl/pomoc/ile-kosztuje-marketing-automation.html",
"en": "https://www.getresponse.com/help/how-much-does-marketing-automation-cost.html",
"es": "https://www.getresponse.com/es/ayuda/cuanto-cuesta-la-automatizacion-de-marketing.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolsko-stoit-avtomatizatsija.html",
"br": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/quanto-custa-a-automatizacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-costa-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-de-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaFiltrLista = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-lista.html",
"en": "https://www.getresponse.com/help/how-does-the-lists-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-filtr-spiski.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-liste.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodawacKontaktyDoRoznychListPrzyUzyciuPrzyciskowPaypalKupTeraz = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-kontakty-do-roznych-list-przy-uzyciu-przyciskow-paypal-kup-teraz.html",
"en": "https://www.getresponse.com/help/can-i-add-new-contacts-to-different-lists-with-paypal-buy-now-buttons.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-paypal-jetzt-kaufen-buttons-neue-kontakte-in-verschiedene-listen-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-nuevos-contactos-a-listas-diferentes-con-los-botones-comprar-ahora-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavliyat-novye-kontakty-v-raznye-spiski-s-pomoshiyu-knopki-paypal-kypit-sejchas.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-de-nouveaux-contacts-a-differentes-listes-avec-les-boutons-paypal-acheter-maintenant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchEinzelneKontakteDirektInMeineListeEintragen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieTypyTestowABSaDostepneWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-typy-testow-a-b-sa-dostepne-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-types-of-a-b-tests-can-i-choose-from.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-a-b-tests-stehen-zur-verfuegung.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipo-de-pruebas-a-b-puedo-elegir.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-vidy-ab-testov-ja-mogu-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"pt": "https://www.getresponse.com/pt/ajuda/dentre-quais-tipos-de-teste-a-b-posso-escolher.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-test-a-b-posso-scegliere.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-tests-a-b-puis-je-choisir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakNaprawicStaraBazeAdresowa = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoNieNalezyPonownieDodawacNaListyAdresowZwroconych = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-ponownie-dodawac-na-listy-adresow-zwroconych.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-re-add-bounced-emails-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-gebouncte-e-mails-nicht-erneut-zu-meiner-liste-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-volver-a-agregar-emails-rebotados-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ne-sledujet-dobavlat-v-spisok-pisma-s-oshibkoj-dostavki.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-voltar-a-adicionar-e-mails-rejeitados-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-aggiungere-nuovamente-le-email-rimbalzate-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-ajouter-a-nouveau-les-rebonds-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaMiedzyFormularzemALandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-miedzy-formularzem-a-landing-page.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-form-and-a-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-webformular-und-einer-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-formulario-y-una-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakaja-raznitsa-mezhdu-formoj-i-lendingom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-diferenca-entre-um-formulario-e-uma-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-modulo-e-una-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-formulaire-et-une-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSprawicAbyTwojaStarszaWersjaLandingPageBylaBardziejWidoczny = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sprawic-aby-twoja-starsza-wersja-landing-page-byla-bardziej-widoczny.html",
"en": "https://www.getresponse.com/help/how-can-i-increase-the-visibility-of-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-praesenz-meiner-legacy-landing-page-verbessern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-aumentar-la-visibilidad-de-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uluchshit-vidimost-mojego-lendinga-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-aumentar-a-visibilidade-da-minha-landing-page-legada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aumentare-la-visibilita-della-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-augmenter-la-visibilite-de-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacAkcjiTagWCyklu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-tag-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-action-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-accion-etiquetar-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-dejstvije-teg-v-protsesse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-acao-tag-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-tag-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-tag-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZnalezcOsobyKtoreNieOtwieralyMoichWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacKontaktyDoListyZaPomocaAplikacjiMobilnej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-do-listy-za-pomoca-aplikacji-mobilnej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-mithilfe-der-smartphone-app-zu-einer-meiner-listen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-contactos-a-una-de-mis-listas-usando-las-aplicaciones-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlyat-kontaktami-i-spiskami-s-pomoschyu-mobilnogo-prilojeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-em-uma-das-minhas-listas-usando-aplicativos-moveis.html",
"en": "https://www.getresponse.com/help/how-can-i-manage-my-lists-and-contacts-using-the-mobile-app.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-gestire-le-mie-liste-e-contatti-utilizzando-l-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-listes-et-les-contacts-a-l-aide-de-l-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacAkcjiZmienScoring = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-akcji-zmien-scoring.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-score-action.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-aktion-punktestand-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-accion-puntuar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-deystviye-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-acao-de-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-l-azione-assegna-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-l-action-score.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCheCosEGetresponseEComePossoUsarlo = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaFiltrZasieg = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-zasieg.html",
"en": "https://www.getresponse.com/help/how-does-the-range-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-bereich.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-rango.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-diapazon.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-intervalo.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-intervallo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-intervalle.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacPolaDodatkoweDoFormularzaLegacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-pola-dodatkowe-do-formularza-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-add-custom-fields-to-my-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-benutzerdefinierte-felder-zu-meinem-legacy-webformular-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-campos-personalizados-a-mi-formulario-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-nastraivajemyje-pola-v-forme-sozdannoi-v-redaktore-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-campos-personalizados-ao-meu-formulario-legado.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-campi-personalizzati-al-mio-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-champs-personnalises-a-mon-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAIntegrareGetresponseEWordpressLegacyPerAccrescereLaMiaListaDiContattiMonitorareLeVisiteAlMioSitoEInviareDatiDiECommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobReputacjaAdresuEmailNadawcyWplywaNaDostarczalnoscWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-reputacja-adresu-email-nadawcy-wplywa-na-dostarczalnosc-wiadomosci.html",
"en": "https://www.getresponse.com/help/does-the-reputation-of-the-from-field-influence-deliverability-to-the-inbox-vs-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/inwiefern-beeinflusst-die-reputation-der-absenderadresse-die-zustellrate-in-den-posteingang-im-vergleich-zum-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/como-influye-la-reputacion-de-la-direccion-remitente-en-mi-capacidad-de-entrega-bandeja-de-entrada-vs-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-reputacija-adresa-otpravitela-vlijajet-na-dostavlajemost-pisem-v-papku-vhodjashije-i-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-reputacao-do-campo-de-remetente-influencia-minha-capacidade-de-entrega-na-caixa-de-entrada-vs-caixa-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/la-reputazione-dell-indirizzo-e-mail-del-mittente-influenza-la-consegna-alla-cartella-posta-in-arrivo-rispetto-alla-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/la-reputation-du-champ-de-influence-t-elle-la-delivrabilite-dans-la-boite-de-reception-ou-dans-le-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoMonitorareLeConversioniDiGoogleAdsSullaMiaLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacStarszaWersjeLandingPagesZMenedzeremTagowGoogle = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-starsza-wersje-landing-pages-z-menedzerem-tagow-google.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-my-legacy-landing-pages-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-meine-legacy-landing-pages-mit-google-tag-manager.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-mis-landing-pages-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-moi-lendingi-v-versii-legacy-s-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integrar-minhas-landing-pages-legadas-ao-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-le-mie-landing-page-con-google-tag-manager.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-mes-anciennes-pages-de-destination-a-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUstawicSzczegolyWarunkuOdwiedzonyUrl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-ustawic-szczegoly-warunku-odwiedzony-url.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-url-visited-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-besuchte-url-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-para-la-condicion-de-url-visitada.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-svojstva-uslovija-poseshennaja-stranitsa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-definir-propriedades-para-a-condicao-de-url-visitada.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-url-visitato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-url-visitee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogePozwolicUczestnikomWebinaruKomunikowacSieZeMna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pozwolic-uczestnikom-webinaru-komunikowac-sie-ze-mna.html",
"en": "https://www.getresponse.com/help/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-meine-teilnehmer-waehrend-des-webinars-mit-mir-kommunizieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-permitir-a-mis-participantes-comunicarse-conmigo-durante-el-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mozhno-vzaimodejstvovat-so-zritelami-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-deixar-meus-participantes-se-comunicarem-comigo-durante-o-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/how-can-i-let-my-participants-communicate-with-me-during-the-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-permettre-a-mes-participants-de-communiquer-avec-moi-pendant-le-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACambiareLeImpostazioniDns = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPossoInnescareUnModuloPopOverConUnPulsanteOLink = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestRoznicaPomiedzyNewsletteremAAutoresponderem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-newsletterem-a-autoresponderem.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-newsletter-and-an-autoresponder-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einem-newsletter-und-einer-autoresponder-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-una-newsletter-y-un-mensaje-de-autorespuesta.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-rassylkoj-i-pismom-avtootvetchika.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-newsletter-e-uma-mensagem-de-autorresposta.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-una-newsletter-e-un-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-une-newsletter-et-un-auto-repondeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUsunacLubEdytowacLinkiDoWypisuIZmianyDanychWidoczneNaDoleWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-usunac-lub-edytowac-linki-do-wypisu-i-zmiany-danych-widoczne-na-dole-wiadomosci.html",
"en": "https://www.getresponse.com/help/can-i-remove-or-edit-the-unsubscribe-and-update-links-at-the-bottom-of-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/kann-man-die-links-zum-abbestellen-und-aktualisieren-in-der-fusszeile-der-nachrichten-entfernen-oder-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-eliminar-o-editar-los-enlaces-de-darse-de-baja-y-actualizacion-de-los-datos-en-la-parte-inferior-de-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-izmenit-ili-udalit-knopki-otpisatsa-v-futere-pisma.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-remover-ou-editar-os-links-de-cancelamento-de-inscricao-e-atualizacao-na-parte-inferior-das-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/posso-rimuovere-o-modificare-i-link-di-cancellazione-e-aggiornamento-in-fondo-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-supprimer-ou-modifier-les-liens-de-desabonnement-et-de-mise-a-jour-figurant-au-bas-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareLeSezioniNelGeneratoreDiLandingPage = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioAUsareITag = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComeFaccioACreareEUtilizzareICampiPersonalizzati = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindDieBestenEMailPraktikenDieSichAufDieZustellbarkeitAuswirken = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFindeIchHerausWelchesLegacyWebformularMirDieHoechsteConversionRateBeschert = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-heraus-welches-legacy-webformular-mir-die-hoechste-conversion-rate-beschert.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sprawdzic-ktora-wersja-formularza-legacy-zapewnia-mi-najlepsza-konwersje.html",
"en": "https://www.getresponse.com/help/how-do-i-find-out-which-version-of-the-legacy-form-gives-me-the-highest-conversion-rate.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-saber-que-version-del-formulario-de-legado-me-da-la-tasa-de-conversion-mas-alta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-kakaya-versiya-formyi-legacy-imeet-naibolshii-pokazatel-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-savoir-quelle-version-de-mon-ancien-formulaire-me-donne-le-taux-de-conversion-le-plus-eleve.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieRichteIchDieEigenschaftenFuerDieBedingungEinkaufEin = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacSzczegolyWarunkuZrealizowanyZakup = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-szczegoly-warunku-zrealizowany-zakup.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-purchase-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-eigenschaften-fuer-die-bedingung-einkauf-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-establezco-propiedades-para-la-condicion-compra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-parametry-svojstva-kupil.html",
"br": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-defino-propriedades-para-a-condicao-de-compra.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-acquista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-achat-effectue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobMogeSprawdzicKtoraWersjaFormularzaLegacyZapewniaMiNajlepszaKonwersje = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sprawdzic-ktora-wersja-formularza-legacy-zapewnia-mi-najlepsza-konwersje.html",
"en": "https://www.getresponse.com/help/how-do-i-find-out-which-version-of-the-legacy-form-gives-me-the-highest-conversion-rate.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-heraus-welches-legacy-webformular-mir-die-hoechste-conversion-rate-beschert.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-saber-que-version-del-formulario-de-legado-me-da-la-tasa-de-conversion-mas-alta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-opredelit-kakaya-versiya-formyi-legacy-imeet-naibolshii-pokazatel-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-descubro-qual-versao-do-formulario-legado-oferece-me-a-maior-taxa-de-conversao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-savoir-quelle-version-de-mon-ancien-formulaire-me-donne-le-taux-de-conversion-le-plus-eleve.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieSaNajlepszePraktykiZwiekszajaceDostarczalnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-najlepsze-praktyki-zwiekszajace-dostarczalnosc.html",
"en": "https://www.getresponse.com/help/what-are-the-best-email-practices-that-influence-delivery.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-besten-e-mail-praktiken-die-sich-auf-die-zustellbarkeit-auswirken.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-las-mejores-practicas-de-email-que-influyen-en-la-entrega.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-pravila-i-metodiki-rassylok-vlijajut-na-dostavlajemost-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-as-melhores-praticas-de-e-mail-que-influenciam-a-entrega.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-le-migliori-pratiche-di-posta-elettronica-che-influenzano-la-consegna.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-meilleures-pratiques-en-matiere-d-email-marketing-qui-influencent-la-livraison.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeRozpoczacCyklAutomationOdKilkuWarunkow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-rozpoczac-cykl-automation-od-kilku-warunkow.html",
"en": "https://www.getresponse.com/help/can-i-start-a-workflow-with-multiple-conditions.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-workflow-mit-mehreren-bedingungen-starten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-iniciar-un-flujo-de-trabajo-con-multiples-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-nachat-process-s-neskolkih-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-iniciar-um-fluxo-de-trabalho-com-multiplas-condicoes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-iniziare-un-workflow-con-varie-condizioni.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-lancer-un-flux-de-travail-avec-plusieurs-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycRssToEmail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-rss-to-email.html",
"en": "https://www.getresponse.com/help/how-do-i-create-rss-to-email.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-rss-to-email.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-un-mensaje-rss-a-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-rss-to-email.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-rss-para-email.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-rss-to-email.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-rss-to-email.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacDoWiadomosciZalaczniki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-do-wiadomosci-zalaczniki.html",
"en": "https://www.getresponse.com/help/how-do-i-add-attachments-to-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-anhaenge-zu-meinen-nachrichten-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-archivos-adjuntos-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-vlozhenija-v-svoi-soobshenija.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-anexos-nas-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungo-allegati-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-pieces-jointes-a-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUzycObrazkaJakoTloFormularzaLegacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-obrazka-jako-tlo-formularza-legacy.html",
"en": "https://www.getresponse.com/help/can-i-use-a-picture-as-a-form-background-in-legacy-editor.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-als-hintergrund-fuer-mein-legacy-webformular-ein-bild-verwenden.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-sobstvennuyu-kartinku-v-kachestve-fona-v-formeto-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-uma-imagem-como-fundo-no-formulario-legado.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-una-imagen-como-fondo-de-un-formulario-de-legado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-immagine-come-sfondo-del-modulo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-une-image-comme-arriere-pla-de-formulaire-dans-l-ancien-editeur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakTworzycPolaDodatkoweIJakZNichKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZaplanowacTestyAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaplanowac-testy-a-b.html",
"en": "https://www.getresponse.com/help/can-i-schedule-my-a-b-tests.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-startzeiten-fuer-die-a-b-tests-planen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-programar-mis-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-zaplanirovat-ab-testy.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-agendar-meus-testes-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/posso-programmare-i-miei-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-programmer-mes-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacUstawienCzasuWSzczegolachWarunkow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-ustawien-czasu-w-szczegolach-warunkow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-time-settings-in-condition-properties.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-die-zeiteinstellungen-in-den-eigenschaften-der-bedingungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-configuracion-de-tiempo-en-las-propiedades-de-condiciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-nastrojki-vremeni-v-svojstvah-uslovij.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-as-configuracoes-de-tempo-nas-propriedades-da-condicao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-impostazioni-temporali-nelle-proprieta-della-condizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-parametres-de-temps-dans-les-proprietes-des-conditions.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobKorzystacZTagowania = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobWysylanaJestWiadomoscOsiagajacaNajlepszeWyniki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wysylana-jest-wiadomosc-osiagajaca-najlepsze-wyniki.html",
"en": "https://www.getresponse.com/help/how-is-the-best-performing-message-being-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-wird-die-nachricht-mit-der-besten-performance-versendet.html",
"es": "https://www.getresponse.com/es/ayuda/como-es-enviado-el-mensaje-de-mejor-rendimiento.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-samoje-effektivnoje-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-mensagem-com-melhor-desempenho-e-enviada.html",
"it": "https://www.getresponse.com/it/assistenza/come-viene-inviato-il-messaggio-con-le-migliori-prestazioni.html",
"fr": "https://www.getresponse.com/fr/aide/comment-le-message-le-plus-performant-est-il-envoye.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacSekcjiWStarszejWersjiKreatoraLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-sekcji-w-starszej-wersji-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-use-sections-in-the-legacy-landing-page-creator.html",
"de": "https://www.getresponse.com/de/hilfe/wie-arbeite-ich-mit-abschnitten-im-legacy-landing-page-editor.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-las-secciones-en-el-creador-de-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-sektsii-v-redaktore-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-le-sezioni-nel-generatore-di-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-secoes-no-criador-da-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-sections-dans-l-ancien-createur-de-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaAkcjeWarunkiIFiltry = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-akcje-warunki-i-filtry.html",
"en": "https://www.getresponse.com/help/what-are-actions-conditions-and-filters.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-aktionen-bedingungen-und-filter.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-acciones-condiciones-y-filtros.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-dekstvija-uslovija-i-filtry.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-acoes-condicoes-e-filtros.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-sono-le-azioni-le-condizioni-e-i-filtri.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-actions-les-conditions-et-les-filtres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZintegrowacGetresponseZGoogleAnalytics = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-z-google-analytics.html",
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-with-google-analytics.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-konto-mit-google-analytics-verbinden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-con-google-analytics.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-s-google-analytics.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-integrar-a-getresponse-com-o-google-analytics.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-con-google-analytics.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-a-google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWarunkuPrzypisanyTagWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-przypisany-tag-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-tag-assigned-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-tag-in-einem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-etiqueta-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-ispolzovat-uslovie-teg-prisvoen-v-protsesse-avtomatizatsii.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-tag-in-un-workflow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-da-tag-se-em-um-fluxo-de-trabalho.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-condition-tag-attribue-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIReceiveNotificationsOnSlack = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddZohocrmCustomersAndProspectsToMyContactList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-zohocrm-customers-and-prospects-to-my-contact-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodawac-aktualnych-i-potencjalnych-klientow-z-zohocrm-do-listy-kontaktow-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-zohocrm-kunden-und-interessenten-in-meine-kontaktliste-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-los-clientes-y-prospectos-de-zohocrm-a-mi-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavlyat-syshestvuyushich-i-potencialnych-pokypatelej-iz-zohocrm-v-svoi-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-clientes-do-zohocrm-a-minha-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-clienti-e-potenziali-clienti-zohocrm-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-et-des-prospects-de-zohocrm-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseZapierWithMyGetresponseAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-zapier-with-my-getresponse-account.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-ispolzovat-zapier-s-moim-akkayntom-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-zapier-mit-meinem-getresponse-konto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-o-zapier-com-minha-conta-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-zapiera-z-kontem-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-uso-zapier-con-il-mio-account-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-zapier-con-mi-cuenta-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-zapier-avec-mon-compte-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIIntegratePaypalWithGetresponseAndAddButtonsToMessagesAndLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-integrate-paypal-with-getresponse-and-add-buttons-to-messages-and-landing-pages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-paypal-z-getresponse-i-dodawac-przyciski-do-wiadomosci-i-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verbinde-ich-paypal-mit-getresponse-und-verwende-buttons-in-meinen-nachrichten-und-auf-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-paypal-con-getresponse-y-agrego-botones-a-mensajes-y-landing-pages.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-integrirovat-paypal-s-getresponse-chtoby-dobavit-knopki-soobsheniya-i-lendingi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-integrar-o-paypal-ao-getresponse-e-adicionar-botoes-a-mensagens-e-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-integrare-paypal-con-getresponse-e-aggiungere-pulsanti-ai-messaggi-e-alle-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-paypal-a-getresponse-et-ajouter-des-boutons-aux-messages-et-aux-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddNewContactsToDifferentListsWithPaypalBuyNowButtons = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-new-contacts-to-different-lists-with-paypal-buy-now-buttons.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-kontakty-do-roznych-list-przy-uzyciu-przyciskow-paypal-kup-teraz.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mit-paypal-jetzt-kaufen-buttons-neue-kontakte-in-verschiedene-listen-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-nuevos-contactos-a-listas-diferentes-con-los-botones-comprar-ahora-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-dobavliyat-novye-kontakty-v-raznye-spiski-s-pomoshiyu-knopki-paypal-kypit-sejchas.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-novos-contatos-a-diferentes-listas-com-os-botoes-comprar-agora-do-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-de-nouveaux-contacts-a-differentes-listes-avec-les-boutons-paypal-acheter-maintenant.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchGoogleAdsConversionAufMeinerLegacyLandingPageVerfolgen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeinLegacyPopOverFormularMitEinemButtonOderLinkAusloesen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostBietetGetresponseEineSmartphoneAppAn = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoCreoYEnvioUnaNewsletter = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchMeineGesendetenNachrichtenFinden = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoVerMisMensajesEntregados = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDondePuedoVerComoVanMisMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineKontakteAufBasisVonECommerceDatenSegmentieren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumLandenMeineEMailsManchmalImSpamOrdner = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasSindFormularApps = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-sind-formular-apps.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-formularze-pop-up-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/what-are-list-builder-apps.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-aplicaciones-de-list-builder-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-formy-prilogeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-applications-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchMeineKontakteImRahmenEinesMarketingautomatisierungsworkflowsAusEinerListeInEineAndereVerschieben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogePrzemieszczacKontaktyPomiedzyListamiNaPodstawieCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-przemieszczac-kontakty-pomiedzy-listami-na-podstawie-cyklu-automation.html",
"en": "https://www.getresponse.com/help/can-i-move-my-contacts-from-one-list-to-another-as-a-result-of-a-marketing-automation-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-im-rahmen-eines-marketingautomatisierungsworkflows-aus-einer-liste-in-eine-andere-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-mover-los-contactos-de-una-lista-a-otra-como-resultado-de-un-flujo-de-trabajo-de-automatizacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-perenesti-kontakty-iz-odnogo-spiska-v-drugoj-v-rezultate-processa-avtomatizacii.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-mover-meus-contatos-de-uma-lista-para-outra-como-resultado-de-um-fluxo-de-trabalho-de-automacao-de-marketing.html",
"it": "https://www.getresponse.com/it/assistenza/posso-spostare-i-contatti-da-una-lista-all-altra-nel-workflow-di-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-deplacer-mes-contacts-d-une-liste-a-une-autre-a-la-suite-d-un-flux-de-travail-d-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobDodacFilmDoWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-dodac-film-do-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-video-to-my-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-ein-video-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-un-ebook-o-un-video-a-mi-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-video-k-mojej-rassilke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-livro-digital-ou-um-video-na-minha-mensagem.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-un-video-al-mio-messaggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-video-a-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaFormularzePopUpWEdytorzeLegacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-formularze-pop-up-w-edytorze-legacy.html",
"en": "https://www.getresponse.com/help/what-are-list-builder-apps.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-formular-apps.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-aplicaciones-de-list-builder-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-formy-prilogeniya.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-aplicativos-para-construcao-de-listas.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-applications-de-construction-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoMojeWiadomosciCzasemTrafiajaDoFolderuZeSpamem = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moje-wiadomosci-czasem-trafiaja-do-folderu-ze-spamem.html",
"en": "https://www.getresponse.com/help/why-do-my-emails-sometimes-end-up-in-a-spam-folder.html",
"de": "https://www.getresponse.com/de/hilfe/warum-landen-meine-e-mails-manchmal-im-spam-ordner.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-mis-correos-electronicos-a-veces-terminan-en-una-carpeta-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moi-rassylki-inogda-popadajut-v-papku-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-meus-e-mails-as-vezes-acabam-em-uma-pasta-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-le-mie-email-a-volte-finiscono-in-una-cartella-spam.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-mes-e-mails-aboutissent-ils-parfois-dans-un-dossier-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaSegmentirovatKontaktyOsnovyvajasNaDannyhEcommerce = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacNaListePojedynczeKontakty = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeZaprogramowacSwojLegacyFormularzPopOverTakByPojawialSieNaStroniePoKliknieciuWLinkLubPrzycisk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zaprogramowac-swoj-legacy-formularz-pop-over-tak-by-pojawial-sie-na-stronie-po-kliknieciu-w-link-lub-przycisk.html",
"en": "https://www.getresponse.com/help/can-i-trigger-my-legacy-popover-form-with-a-button-or-a-link.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mein-legacy-pop-over-formular-mit-einem-button-oder-link-ausloesen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-activar-mi-formulario-de-ventana-emergente-de-legado-con-un-boton-o-link.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-aktivirovat-formu-sozdannuyu-v-redaktore-legacy-pri-klike-na-knopku.html",
"it": "https://www.getresponse.com/it/assistenza/posso-innescare-un-modulo-pop-over-con-un-pulsante-o-link.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acionar-meu-formulario-pop-over-legado-com-um-botao-ou-link.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-declencher-mon-ancien-formulaire-pop-up-a-l-aide-d-un-bouton-ou-d-un-lien.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyGetresponsePosiadaAplikacjeMobilna = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-getresponse-posiada-aplikacje-mobilna.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-eine-smartphone-app-an.html",
"es": "https://www.getresponse.com/es/ayuda/getresponse-ofrece-una-aplicacion-movil.html",
"ru": "https://www.getresponse.com/ru/help-ru/predlagaet-li-getresponse-mobilnoe-prilojenie.html",
"br": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-getresponse-oferece-um-aplicativo-movel.html",
"en": "https://www.getresponse.com/help/does-getresponse-offer-a-mobile-app-2.html",
"it": "https://www.getresponse.com/it/assistenza/getresponse-offre-un-app-mobile.html",
"fr": "https://www.getresponse.com/fr/aide/getresponse-propose-t-il-une-application-mobile.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicKonwersjeGoogleAdsNaStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-konwersje-google-ads-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-track-google-ads-conversion-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-ads-conversion-auf-meiner-legacy-landing-page-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-el-seguimiento-de-las-conversiones-de-google-ads-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-konversii-google-ads-na-svojem-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-conversioni-di-google-ads-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-conversao-do-google-ads-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-les-conversions-google-ads-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaNadawanieScoringu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeWprowadzacZmianyWCykluKtoryZostalOpublikowany = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wprowadzac-zmiany-w-cyklu-ktory-zostal-opublikowany.html",
"en": "https://www.getresponse.com/help/can-i-make-changes-to-a-workflow-that-has-already-been-published.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-veroeffentlichten-workflow-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-realizar-cambios-en-un-flujo-de-trabajo-que-ya-se-ha-publicado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-vnosit-izmenenija-v-protsess-avtomatizatsii-kotoryj-uzhe-byl-opublikovan.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-fazer-mudancas-em-um-fluxo-de-trabalho-que-ja-foi-publicado.html",
"it": "https://www.getresponse.com/it/assistenza/posso-apportare-modifiche-a-un-workflow-che-e-stato-gia-pubblicato.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-apporter-des-modifications-a-un-flux-de-travail-qui-a-deja-ete-publie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoNieNalezyUzywacAdresowYahooAolMailRuJakoEmailNadawcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-nalezy-uzywac-adresow-yahoo-aol-mail-ru-jako-email-nadawcy.html",
"en": "https://www.getresponse.com/help/why-shouldn-t-i-use-yahoo-aol-mail-ru-email-as-my-from-address.html",
"de": "https://www.getresponse.com/de/hilfe/warum-sollte-ich-keine-yahoo-aol-mail-ru-absenderadressen-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-deberia-usar-yahoo-aol-mail-ru-como-mi-direccion-del-remitente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-ja-ne-mogu-ispolzovat-adresa-na-yahoo-aol-i-mailru-v-kachestve-adresa-otpravitela.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-dovrei-usare-yahoo-aol-mail-ru-per-l-del-mittente.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-devo-usar-o-e-mail-do-yahoo-aol-mail-ru-como-meu-endereco-de-remetente.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-devrais-je-pas-utiliser-yahoo-aol-mail-ru-comme-mon-adresse-de-l-expediteur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddContactsToAListAfterTheyCompleteAPaypalTransaction = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMojeKontoMozeZostacZamknietePrzezZgloszeniaSpamu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIIntegrateGetresponseAndWordpressToGrowMyContactListTrackSiteVisitsAndSendEcommerceDataWithLegacyWordpressPlugin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-integrate-getresponse-and-wordpress-to-grow-my-contact-list-track-site-visits-and-send-ecommerce-data-with-legacy-wordpress-plugin.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zintegrowac-getresponse-i-wordpress-aby-moc-poszerzac-liste-kontaktow-sledzic-odwiedziny-na-stronie-i-wysylac-dane-ecommerce-we-wtyczce-legacy-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-integriere-ich-getresponse-und-wordpress-um-meine-kontaktliste-auszubauen-website-besuche-zu-erfassen-und-e-commerce-daten-mit-dem-legacy-wordpress-plugin-zu-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-integro-getresponse-y-wordpress-para-ampliar-mi-lista-de-contactos-rastrear-las-visitas-al-sitio-y-enviar-datos-de-e-commerce-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-integrirovat-getresponse-i-wordpress-dla-uvelicheniya-bazy-kontaktov-otslezyvaniya-poseshenij-saita-i-otpravki-dannych-ecommerce-ispolzuja-ustarevshi-plugin.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-integrare-getresponse-e-wordpress-legacy-per-accrescere-la-mia-lista-di-contatti-monitorare-le-visite-al-mio-sito-e-inviare-dati-di-e-commerce.html",
"br": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-integro-a-getresponse-e-o-wordpress-para-aumentar-minha-lista-de-contatos-acompanhar-visitas-ao-site-e-enviar-dados-de-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/comment-integrer-getresponse-et-wordpress-pour-developper-ma-liste-de-contacts-suivre-les-visites-du-site-et-envoyer-des-donnees-e-commerce-avec-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddWordpressSiteVisitorsToAGetresponseListInLegacyWordpressPlugin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-wordpress-site-visitors-to-a-getresponse-list-in-legacy-wordpress-plugin.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-odwiedzajacych-strone-na-wordpress-do-listy-w-getresponse-we-wtyczce-legacy-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-besucher-einer-wordpress-website-mit-dem-legacy-wordpress-plugin-einer-getresponse-liste-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-los-visitantes-de-un-sitio-wordpress-a-una-lista-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-v-spisok-getresponse-posetitelej-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adiciono-visitantes-do-wordpress-em-uma-lista-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-i-visitatori-del-sito-wordpress-legacy-a-una-lista-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-les-visiteurs-d-un-site-wordpress-a-une-liste-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddWoocommerceCustomersToGetresponseInLegacyWordpressPlugin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-woocommerce-customers-to-getresponse-in-legacy-wordpress-plugin.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-klientow-woocommerce-do-konta-getresponse-we-wtyczce-legacy-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-woocommerce-kunden-dem-getresponse-in-der-legacy-wordpress-plugin-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agregar-los-clientes-woocommerce-a-getresponse-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-klientov-iz-woocommerce-v-getresponse-ispolzuja-ustarevshi-plugin-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-clienti-woocommerce-su-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-clientes-woocommerce-na-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-clients-woocommerce-a-getresponse-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITrackVisitsToMyWordpressSitesInLegacyWordpressPlugin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-track-visits-to-my-wordpress-sites-in-legacy-wordpress-plugin.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-odwiedziny-moich-stron-na-wordpress-we-wtyczce-legacy-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-besuche-auf-meinen-wordpress-websites-im-legacy-wordpress-plugin-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-las-visitas-a-mis-sitios-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslezivat-posesheniya-svoego-saita-na-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-visitas-aos-meus-sites-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-le-visite-sul-mio-sito-wordpress-legacy.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-les-visites-sur-mes-sites-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddMyLandingPageToWordpressInLegacyWordpressPlugin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-my-landing-page-to-wordpress-in-legacy-wordpress-plugin.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-dodac-landing-page-do-strony-wordpress-we-wtyczce-legacy-wordpress.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-landing-page-mit-dem-legacy-wordpress-plugin-zu-wordpress-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-mi-landing-page-a-wordpress-con-el-plugin-antiguo-de-wordpress.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-la-mia-landing-page-a-wordpress-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-minha-landing-page-ao-wordpress.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-svoi-lending-v-wordpress-ispolzuja-ustarevshi-plugin-wordpress.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-ma-page-de-destination-a-wordpress-dans-l-ancien-plugin-wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIGrowMyContactDatabaseByIntegratingGetresponseWithMagento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-grow-my-contact-database-by-integrating-getresponse-with-magento.html",
"it": "https://www.getresponse.com/it/assistenza/posso-far-crescere-il-mio-database-di-contatti-integrando-getresponse-con-magento.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakt-datenbank-erweitern-indem-ich-getresponse-mit-magento-integriere.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-hacer-crecer-mi-base-de-datos-de-contactos-integrando-getresponse-con-magento-2.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-aumentar-a-minha-base-de-dados-de-contactos-integrando-getresponse-com-o-magento.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-developper-ma-base-de-donnees-de-contacts-en-integrant-getresponse-a-magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMnePrishloUvedomlenijeOTomChtoKontaktBylDobavlenVSpisokNoJaJegoNeVizhu = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mne-prishlo-uvedomlenije-o-tom-chto-kontakt-byl-dobavlen-v-spisok-no-ja-jego-ne-vizhu.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-eine-abobenachrichtigung-erhalten-kann-den-kontakt-aber-nicht-in-meiner-liste-finden.html",
"en": "https://www.getresponse.com/help/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"br": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/recebi-um-e-mail-dizendo-que-um-contato-foi-adicionado-a-uma-lista-mas-nao-consigo-ve-lo.html",
"pl": "https://www.getresponse.pl/pomoc/powiadomienie-o-subskrypcji-zostalo-dostarczone-ale-kontakt-nie-jest-dodany-do-listy.html",
"it": "https://www.getresponse.com/it/assistenza/ho-ricevuto-un-e-mail-che-informa-che-un-contatto-e-stato-aggiunto-a-un-elenco-ma-non-riesco-a-visualizzarli.html",
"es": "https://www.getresponse.com/es/ayuda/i-received-an-email-that-a-contact-was-added-to-a-list-but-i-can-t-see-them.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-recu-un-e-mail-indiquant-qu-un-contact-a-ete-ajoute-a-une-liste-mais-je-ne-peux-pas-le-voir.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUdalitDublikatyKontaktovIzMoihSpiskov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIskatKontaktyNaOsnoweIhSoglasijaNaObrabotkuDannyh = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAendereIchDieDnsEinstellungen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoKannIchFestlegenDassMeinLegacyWebformularAlsPopUpAufMeinerSeiteAngezeigtWerdenSoll = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieFunktioniertDerFilterDynamischesSegment = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchSehenWarumMeineKontakteSichAbgemeldetHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieSucheIchAufBasisDerErteiltenEinwilligungNachKontakten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-auf-basis-der-erteilten-einwilligung-nach-kontakten.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty-w-oparciu-o-wyrazone-przez-nie-zgody.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts-based-on-the-consent-they-ve-given.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-contactos-en-funcion-del-consentimiento-que-han-dado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-na-osnowe-ih-soglasija-na-obrabotku-dannyh.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos-com-base-no-consentimento-que-deram.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti-in-base-al-consenso-che-hanno-dato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts-en-fonction-du-consentement-qu-ils-ont-donne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiJaUvidetPochemuMoiKontaktyOtpisalis = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaFiltrSegmentDynamiczny = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-filtr-segment-dynamiczny.html",
"en": "https://www.getresponse.com/help/how-does-the-dynamic-segment-filter-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-der-filter-dynamisches-segment.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-filtro-segmento-dinamico.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotayet-filtr-dinamicheskiy-segment.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-filtro-de-segmento-dinamico.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-il-filtro-segmento-dinamico.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-le-filtre-de-segment-dynamique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeUstawicFormularzLegacyAbyWyskoczylNaStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-ustawic-formularz-legacy-aby-wyskoczyl-na-stronie.html",
"en": "https://www.getresponse.com/help/where-can-i-set-my-legacy-form-to-pop-over-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-festlegen-dass-mein-legacy-webformular-als-pop-up-auf-meiner-seite-angezeigt-werden-soll.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-configurar-mi-formulario-de-legado-para-que-aparezca-como-pop-over-en-mi-pagina.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-formu-iz-redaktora-versii-legacy-vsplyvajushhei.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-definir-o-meu-formulario-pop-over-legado-para-aparecer-na-minha-pagina.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-impostare-il-mio-modulo-in-modo-che-compaia-sulla-mia-pagina.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-configurer-mon-ancien-formulaire-pour-qu-il-apparaisse-sur-ma-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicUstawieniaDns = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-ustawienia-dns.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-dns-settings.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dns-einstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-la-configuracion-de-dns-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-nastrojki-dns.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-le-impostazioni-dns.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-configuracoes-de-dns.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-dns.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUzywacTagowIScoringuPrzezApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestMarketingAutomationIWJakiSposobMogeWykorzystacTeFunkcjonalnosc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-marketing-automation-i-w-jaki-sposob-moge-wykorzystac-te-funkcjonalnosc.html",
"en": "https://www.getresponse.com/help/what-is-marketing-automation-and-what-can-i-do-with-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-die-marketingautomatisierung-und-wie-kann-ich-sie-fuer-mich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-automatizacion-de-marketing-y-que-puedo-hacer-con-ella.html",
"ru": "https://www.getresponse.com/ru/help-ru/avtomatizatsija-marketinga-chto-eto-i-kak-ona-mne-pomozhet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-automacao-de-marketing-e-o-que-posso-fazer-com-ela.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-marketing-automation-e-come-posso-utilizzarla.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-l-automatisation-du-marketing-et-que-puis-je-faire-avec.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakWyslacWiadomoscNatychmiastowaPoTymJakKtosZapiszeSiePrzezLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-wyslac-wiadomosc-natychmiastowa-po-tym-jak-ktos-zapisze-sie-przez-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-send-an-instant-message-after-someone-signs-up-via-the-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nach-einer-registrierung-ueber-die-landing-page-eine-sofortnachricht-senden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-mensaje-instantaneo-despues-de-que-alguien-se-suscriba-a-traves-de-la-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otpravit-pismo-kontaktu-srazu-posle-podpiski-cherez-lending.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-uma-mensagem-instantanea-apos-alguem-assinar-via-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-un-messaggio-istantaneo-dopo-che-qualcuno-si-e-registrato-tramite-la-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-un-message-instantane-apres-qu-une-personne-se-soit-inscrite-via-la-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacWarunkuScoringWCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-warunku-scoring-w-cyklu-automation.html",
"en": "https://www.getresponse.com/help/how-can-i-use-the-if-score-condition-in-a-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-die-bedingung-wenn-punktestand.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-la-condicion-si-la-puntuacion-en-un-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-uslovije-bally-nachisleny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-a-condicao-se-a-classificacao-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-la-condizione-se-il-punteggio-in-un-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-utiliser-la-condition-de-score-donne-dans-un-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakiJestMiesiecznyKosztKorzystaniaZGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestPolitykaZwrotuKosztowWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostChtoTakojeRejtingVovlechennostiIKakJaMoguJegoIspolzovat = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZnalezcKontaktyNaPodstawieZrodlaZapisu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostNieMogeOpublikowacCykluCoZrobic = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/nie-moge-opublikowac-cyklu-co-zrobic.html",
"en": "https://www.getresponse.com/help/i-can-t-publish-my-workflow-what-do-i-do.html",
"de": "https://www.getresponse.com/de/hilfe/ich-kann-meinen-workflow-nicht-veroeffentlichen-was-soll-ich-tun.html",
"es": "https://www.getresponse.com/es/ayuda/no-puedo-publicar-mi-flujo-de-trabajo-que-hago.html",
"ru": "https://www.getresponse.com/ru/help-ru/ya-ne-mogu-opublikovat-svoj-process-chto-mne-delat.html",
"br": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"pt": "https://www.getresponse.com/pt/ajuda/nao-consigo-publicar-meu-fluxo-de-trabalho-o-que-devo-fazer.html",
"it": "https://www.getresponse.com/it/assistenza/non-riesco-a-pubblicare-il-workflow-che-devo-fare.html",
"fr": "https://www.getresponse.com/fr/aide/je-ne-peux-pas-publier-mon-flux-de-travail-que-dois-je-faire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIskatKontaktyPoSposobuPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNajtiKontaktyKotoryjeNeOtkryvaliMoiPisma = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoLogoGetresponseWyswietlaneJestWMoichWiadomosciach = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJestemDeweloperemGdzieZnajdeInformacjeOdnosnieApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jestem-deweloperem-gdzie-znajde-informacje-odnosnie-api.html",
"en": "https://www.getresponse.com/help/i-am-a-developer-where-can-i-find-information-about-api.html",
"de": "https://www.getresponse.com/de/hilfe/ich-bin-programm-entwickler-wo-finde-ich-informationen-zur-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/soy-un-desarrollador-donde-puedo-encontrar-informacion-sobre-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-razrabotchik-mozet-naiti-informaciyu-ob-api.html",
"br": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/sou-um-desenvolvedor-onde-posso-encontrar-informacoes-sobre-a-api.html",
"it": "https://www.getresponse.com/it/assistenza/sono-un-developer-dove-posso-trovare-informazioni-sull-api.html",
"fr": "https://www.getresponse.com/fr/aide/je-suis-developpeur-ou-puis-je-trouver-des-informations-sur-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUdalatKontaktyIzSpiskaIliUchetnojZapisi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakEksportirovatKontakty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIEnableSubscriptionsForMyPrestashopStoreVisitors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-enable-subscriptions-for-my-prestashop-store-visitors.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aktiviere-ich-anmeldungen-fuer-die-besucher-meines-prestashop-stores.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-habilitar-las-suscripciones-para-los-visitantes-de-mi-tienda-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-abilitare-le-iscrizioni-per-i-visitatori-del-mio-negozio-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vkluchit-podpisky-dlya-posetitelej-moego-magazina-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uruchomic-zapis-na-liste-dla-odwiedzajacych-sklep-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-permitir-assinaturas-para-os-visitantes-de-minha-loja-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/comment-activer-les-inscriptions-pour-les-visiteurs-de-ma-boutique-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITrackAndCollectEcommerceDataFromPrestashop = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-track-and-collect-ecommerce-data-from-prestashop.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-i-zbierac-dane-e-commerce-z-prestashop.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-e-commerce-daten-aus-prestashop-verfolgen-und-erfassen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-y-recopilar-los-datos-de-e-commerce-desde-prestashop.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-rastrear-e-coletar-dados-de-e-commerce-do-prestashop.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-e-raccogliere-dati-di-e-commerce-da-prestashop.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-otslizivat-i-sobirat-ecommerce-dannye-v-prestashop.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-suivre-et-collecter-des-donnees-e-commerce-a-partir-de-prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueHayDeNuevoEnElNuevoEditorDeMensajes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstGetresponseUndWieBenutzeIchEs = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWasIstNeuImNeuenNachrichtenEditor = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostQueEsGetresponseYComoPuedoUsarlo = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUploadMyProductDataToGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-upload-my-product-data-to-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-importowac-baze-produktow-do-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-produktdaten-in-getresponse-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-subir-los-datos-de-mi-producto-a-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-dannye-produktov-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-carregar-os-dados-do-meu-produto-para-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-caricare-i-dati-dei-miei-prodotti-su-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-les-donnees-de-mes-produits-vers-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDoWebinarsWorkOnMobileDevices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/do-webinars-work-on-mobile-devices.html",
"pl": "https://www.getresponse.pl/pomoc/czy-webinary-dzialaja-na-urzadzeniach-mobilnych.html",
"de": "https://www.getresponse.com/de/hilfe/funktionieren-webinare-auf-mobilgeraeten.html",
"es": "https://www.getresponse.com/es/ayuda/los-webinars-funcionan-en-dispositivos-moviles.html",
"ru": "https://www.getresponse.com/ru/help-ru/rabotaut-li-vebinary-na-mobilnych-ustroistvah.html",
"br": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/os-webinars-funcionam-em-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/i-webinar-funzionano-su-dispositivi-mobili.html",
"fr": "https://www.getresponse.com/fr/aide/les-webinaires-fonctionnent-ils-sur-les-appareils-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsGetresponseAndHowCanIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-getresponse-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-getresponse-i-jak-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-getresponse-und-wie-benutze-ich-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-getresponse-y-como-puedo-usarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-platforma-getresponse-i-kak-ej-polzovatsya.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-getresponse-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-getresponse-e-como-posso-usa-la.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-getresponse-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoAnadirUnaImagenDeFondoAUnMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoUsarLasFuentesWebEnElMensaje = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicDniWysylkiAutoresponderow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-dni-wysylki-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-days-autoresponders-go-out-on.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-an-welchen-tagen-die-autoresponder-versendet-werden.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-los-dias-en-que-las-autorespuestas-salen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenjat-dni-dostavki-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-alterar-as-datas-de-envio-das-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-i-giorni-in-cui-gli-autoresponder-escono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-jours-d-envoi-des-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoJedniDostawcyUslugInternetowychIspUznajaWiadomoscZaSpamPodczasGdyInniNie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-jedni-dostawcy-uslug-internetowych-isp-uznaja-wiadomosc-za-spam-podczas-gdy-inni-nie.html",
"en": "https://www.getresponse.com/help/why-do-isps-recognize-messages-as-spam-differently.html",
"de": "https://www.getresponse.com/de/hilfe/warum-stufen-internetanbieter-nachrichten-auf-unterschiedliche-weise-als-spam-ein.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-los-psis-reconocen-los-mensajes-como-spam-de-manera-diferente.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-internet-provajdery-po-raznomu-raspoznajut-pisma-kak-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-os-provedores-de-e-mail-tem-reconhecimentos-diferentes-de-mensagens-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/perche-gli-isp-riconoscono-i-messaggi-come-spam-in-modo-diverso.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-les-fai-reconnaissent-ils-differemment-les-messages-comme-etant-des-spams.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeOtrzymywacPowiadomieniaNaSlacku = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-otrzymywac-powiadomienia-na-slacku.html",
"en": "https://www.getresponse.com/help/can-i-receive-notifications-on-slack.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-benachrichtigungen-auf-slack-erhalten.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-recibir-notificaciones-en-slack.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogy-li-ya-polychat-yvedomleniya-v-slack.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-receber-notificacoes-no-slack.html",
"it": "https://www.getresponse.com/it/assistenza/posso-ricevere-notifiche-su-slack.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-recevoir-des-notifications-sur-slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestSpam = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"en": "https://www.getresponse.com/help/what-is-spam.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacPowiadomienieOPlikachCookieDoMojegoLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-powiadomienie-o-plikach-cookie-do-mojego-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-cookies-notification-to-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-meine-getresponse-landing-page-einen-cookie-hinweis-aufweist.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-una-notificacion-de-cookies-a-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ustanovit-uvedomlenije-o-kuki-fajlach-na-svoj-lending.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-una-notifica-dei-cookie-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-cookies-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-notification-de-cookies-sur-ma-landing-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIluOdbiorcowUwzglednicWGrupieTestowej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ilu-odbiorcow-uwzglednic-w-grupie-testowej.html",
"en": "https://www.getresponse.com/help/how-many-recipients-should-i-include-in-my-testing-group.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viele-empfaenger-soll-meine-testgruppe-aufweisen.html",
"es": "https://www.getresponse.com/es/ayuda/cuantos-destinatarios-deberia-incluir-en-mi-grupo-de-prueba.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-poluchatelej-nuzhno-dobavlat-v-testovuju-gruppu.html",
"br": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"pt": "https://www.getresponse.com/pt/ajuda/quantos-destinatarios-devo-incluir-no-meu-grupo-de-teste.html",
"it": "https://www.getresponse.com/it/assistenza/quanti-destinatari-devo-includere-nel-mio-gruppo-di-test.html",
"fr": "https://www.getresponse.com/fr/aide/combien-de-destinataires-dois-je-inclure-dans-mon-groupe-de-test.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyPrzedWysylkaMogeSprawdzicJakWiadomoscBedzieWygladacUOdbiorcy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-przed-wysylka-moge-sprawdzic-jak-wiadomosc-bedzie-wygladac-u-odbiorcy.html",
"en": "https://www.getresponse.com/help/can-i-check-how-the-message-looks-in-an-inbox-before-i-send-it.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-vor-dem-versand-ueberpruefen-wie-eine-nachricht-im-posteingang-angezeigt-wird.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-verificar-como-se-ve-el-mensaje-en-una-bandeja-de-entrada-antes-de-enviarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-proverit-pered-otpravkoj-kak-budet-vygladet-pismo.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-verificar-como-a-mensagem-vai-ficar-em-uma-caixa-de-entrada-antes-de-envia-la.html",
"it": "https://www.getresponse.com/it/assistenza/posso-controllare-come-appare-il-messaggio-in-una-casella-di-posta-prima-di-inviarlo.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-verifier-mon-message-dans-une-boite-de-reception-avant-de-l-envoyer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZmienicStroneZPodziekowaniemIAdresUrlDlaJuzZapisanychKontaktowWFormularzachLegacy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-strone-z-podziekowaniem-i-adres-url-dla-juz-zapisanych-kontaktow-w-formularzach-legacy.html",
"en": "https://www.getresponse.com/help/how-do-i-change-a-thank-you-page-and-already-subscribed-url-for-a-legacy-form.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-die-dankeseite-und-bereits-abonnierte-url-fuer-ein-legacy-formular.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-una-pagina-de-agradecimiento-y-una-url-ya-suscrita-en-el-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nastroit-stranitsu-blagodarnosti-i-stranitsu-uje-podpisalsya-v-redaktore-form-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-una-pagina-di-ringraziamento-e-l-url-degli-utenti-gia-iscritti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-alterar-uma-pagina-de-agradecimento-e-um-url-ja-inscrito.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-la-page-de-remerciement-et-l-url-deja-inscrit-pour-un-ancien-formulaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnalezcWersjeRoboczeWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-wersje-robocze-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-message-drafts.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-bozze-dei-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-nachrichtenentwuerfe.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mis-borradores-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nachodjatsa-chernoviki-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacKontaktyNaListePoZakonczeniuTransakcjiPaypal = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-kontakty-na-liste-po-zakonczeniu-transakcji-paypal.html",
"en": "https://www.getresponse.com/help/how-do-i-add-contacts-to-a-list-after-they-complete-a-paypal-transaction.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-kontakte-in-meine-liste-ein-nachdem-sie-eine-zahlung-ueber-paypal-geleistet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-una-lista-despues-de-que-hayan-completado-una-transaccion-de-paypal.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-dobavit-kontakty-v-spisok-posle-togo-kak-oni-sovershat-tranzakciyu-s-pomoshyu-paypal.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-adicionar-contatos-a-uma-lista-depois-que-eles-concluem-uma-transacao-do-paypal.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-i-contatti-dopo-una-transazione-paypal.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-une-liste-apres-qu-ils-aient-effectue-une-transaction-paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseWebfontsInTheMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-webfonts-in-the-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-czcionek-webowych-w-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-webfonts-in-meiner-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/como-usar-las-fuentes-web-en-el-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-veb-shrifty-v-soobshenijah.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-utilizzare-i-webfont-nel-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-webfonts-na-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-webfonts-dans-le-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAddABackgroundImageToAMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-add-a-background-image-to-a-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-grafike-w-tle-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-ein-hintergrundbild-in-eine-nachricht-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-anadir-una-imagen-de-fondo-a-un-mensaje.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-fonovoje-izobrazhenije-v-soobshenije.html",
"it": "https://www.getresponse.com/it/assistenza/come-aggiungere-un-immagine-di-sfondo-a-un-messaggio.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-uma-imagem-de-fundo-a-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-image-d-arriere-plan-a-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAnEmailThatAlwaysLooksGood = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-an-email-that-always-looks-good.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-ktora-zawsze-dobrze-wyglada.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-nachricht-die-immer-ansprechend-und-gut-aussieht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku-kotoraja-wygladit-horosho.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-un-e-mail-che-abbia-sempre-un-bell-aspetto.html",
"es": "https://www.getresponse.com/es/ayuda/como-crear-un-correo-electronico-tenga-siempre-un-buen-aspecto.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-um-e-mail-que-tenha-sempre-boa-aparencia.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-un-e-mail-qui-soit-toujours-agreable-a-lire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowWideShouldMyMessageBe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-wide-should-my-message-be.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-szerokosc-powinna-miec-moja-wiadomosc.html",
"de": "https://www.getresponse.com/de/hilfe/welche-breite-sollte-meine-nachricht-haben.html",
"ru": "https://www.getresponse.com/ru/help-ru/naskolko-shirokim-mozhet-byt-moje-soobshenie.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-dovrebbe-essere-largo-il-mio-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-ancho-debe-ser-mi-mensaje.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-doit-etre-la-largeur-de-mon-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUdalitKontaktyIzSpiskaVruchnuju = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheFooterRequirements = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-footer-requirements.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-sa-wymagania-stopki-maila.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-die-anforderungen-fuer-die-fusszeile.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-trebovanija-dla-futera.html",
"it": "https://www.getresponse.com/it/assistenza/quali-sono-i-requisiti-per-il-pie-di-pagina.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-requisitos-del-pie-de-pagina.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-requisitos-do-rodape.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-exigences-en-matiere-de-pied-de-page.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreSectionsInTheMessageEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-sections-in-the-message-editor.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-sekcje-w-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-sektionen-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-sektsii-v-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-sezioni-nell-editor-dei-messaggi.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-secciones-en-el-editor-de-mensajes.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-as-secoes-no-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-sections-dans-l-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoISendProductRecommendationsToCustomers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-send-product-recommendations-to-customers.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wysylac-rekomendacje-produktow-do-moich-klientow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sende-ich-kunden-produktempfehlungen.html",
"es": "https://www.getresponse.com/es/ayuda/como-envio-recomendaciones-de-productos-a-clientes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otpravlat-rekomendacii-swoim-klientam.html",
"br": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-enviar-recomendacoes-de-produtos-aos-clientes.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inviare-consigli-sui-prodotti-ai-clienti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-envoyer-des-recommandations-de-produits-aux-clients.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakKopirovatIliPeremeshatKontaktyIzOdnogoSpiskaVDrugoj = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseMyBlocksInNewMessageEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-my-blocks-in-new-message-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-moich-elementow-w-nowym-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-meine-elemente-im-neuen-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-moi-bloki-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-utilizzare-i-miei-blocchi-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-meus-blocos-no-novo-editor-de-mensagens.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-mis-bloques-en-el-nuevo-editor-de-mensajes.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-mes-blocs-dans-le-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseCustomCodeInAMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-custom-code-in-a-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-wlasnego-kodu-w-wiadomosciach.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-benutzerdefinierten-html-code-in-einer-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-sobstvennyj-kod-v-rassylke.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-il-codice-personalizzato-in-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-codigo-personalizado-en-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-o-codigo-personalizado-em-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-personnalise-dans-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToUseBasicBlocksToCreateAMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-use-basic-blocks-to-create-a-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-elementow-podstawowych-aby-utworzyc-wiadomosc.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwende-ich-grundelemente-im-nachrichten-editor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-bazovyje-bloki-dla-sozdanija-soobshenija.html",
"it": "https://www.getresponse.com/it/assistenza/come-utilizzare-i-blocchi-di-base-per-creare-un-messaggio.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizar-los-bloques-basicos-para-crear-un-mensaje.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-blocos-basicos-para-criar-uma-mensagem.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-blocs-de-base-pour-creer-un-message.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeJaMoguPosmotretInformacijuOKontakte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsGlobalViewAndHowDoIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsPerfectTimingAndHowDoesItWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-perfect-timing-and-how-does-it-work.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-perfektes-timing-und-wie-funktioniert-es.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-perfect-timing-y-como-funciona.html",
"ru": "https://www.getresponse.com/ru/help-ru/chtotakoje-perfect-timing-i-kak-eto-rabotajet.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-perfect-timing-e-como-isso-funciona-3.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-perfect-timing-e-come-funziona.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-opcja-perfect-timing-i-jak-dziala.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-perfect-timing-et-comment-fonctionne-t-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIPostTwitterAndFacebookUpdatesWhenISendANewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-post-twitter-and-facebook-updates-when-i-send-a-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meinen-newsletter-auf-twitter-und-facebook-posten.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-publicar-actualizaciones-de-twitter-y-facebook-cuando-envio-un-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-udostepnic-newsletter-na-facebooku-i-twitterze.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podelitsa-rassylkoj-v-twitter-i-facebook-pri-otpravke.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-postar-atualizacoes-do-twitter-e-do-facebook-quando-envio-um-boletim-informativo.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-pubblicare-aggiornamenti-su-twitter-e-facebook-quando-invio-una-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-publier-des-mises-a-jour-twitter-et-facebook-lorsque-j-envoie-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIExcludeContactsFromReceivingMyNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-exclude-contacts-from-receiving-my-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wykluczyc-odbiorcow-z-wysylki-newslettera.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-von-einem-newsletter-ausschliessen.html",
"es": "https://www.getresponse.com/es/ayuda/como-excluyo-los-contactos-de-recibir-mi-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-iskluchit-poluchatelej-iz-svojej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-receber-minha-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-escludere-i-contatti-dalla-ricezione-della-mia-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exclure-des-contacts-de-la-reception-de-ma-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIChooseNewsletterRecipients = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-choose-newsletter-recipients.html",
"pl": "https://www.getresponse.pl/pomoc/jak-wybrac-odbiorcow-newslettera.html",
"de": "https://www.getresponse.com/de/hilfe/wie-lege-ich-newsletter-empfaenger-fest.html",
"es": "https://www.getresponse.com/es/ayuda/como-elijo-a-los-destinatarios-de-la-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-vybrat-poluchatelej-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-escolher-destinatarios-da-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-scelgiere-i-destinatari-della-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-choisir-les-destinataires-de-la-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNahodjatsaNeaktivnyjeKontakty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanITryGetresponseBeforeIBuy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNajtiSpisokVsehKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindAndEditMyAccountSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-and-edit-my-account-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-i-edytowac-ustawienia-mojego-konta.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-kontoeinstellungen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-y-editar-los-ajustes-de-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsya-nastroijki-ychetnoi-zapisi-i-kak-ich-izmenit.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-e-editar-minhas-configuracoes-de-conta.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-e-dove-si-modificano-le-impostazioni-del-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-et-modifier-les-parametres-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnajdeSzablonyWiadomosciHtml = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-szablony-wiadomosci-html.html",
"en": "https://www.getresponse.com/help/where-can-i-find-html-message-templates.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-vorlagen-fuer-html-nachrichten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-modelos-de-mensajes-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodatsa-html-shablony-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-templates-para-mensagens-html.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-i-template-di-messaggi-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnajdeFunkcjeTestowAB = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-funkcje-testow-a-b.html",
"en": "https://www.getresponse.com/help/where-can-i-find-the-a-b-testing-feature.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-a-b-testfunktion.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-la-funcion-de-pruebas-a-b.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-funkcija-ab-testirovanija.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontrar-o-recurso-de-teste-a-b.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-la-funzione-di-test-a-b.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-la-fonction-tests-a-b.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIChangeMyPassword = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-change-my-password.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-haslo-dostepu-do-konta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-aendere-ich-mein-passwort.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-contrasena.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-parol.html",
"br": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-mudar-minha-senha.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cambiare-la-mia-password.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-mot-de-passe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakKorzystacZWidokuKalendarzaAutoresponderow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-korzystac-z-widoku-kalendarza-autoresponderow.html",
"en": "https://www.getresponse.com/help/how-do-i-use-the-calendar-view-for-autoresponders.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-bei-autorespondern-die-kalenderansicht.html",
"es": "https://www.getresponse.com/es/ayuda/como-uso-la-vista-de-calendario-para-autorespuestas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ispolzovat-kalendar-dla-prosmotra-avtootvetchikov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-usar-a-visualizacao-de-calendario-para-autorrespostas.html",
"it": "https://www.getresponse.com/it/assistenza/come-si-usa-la-vista-calendario-per-gli-autoresponder.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-la-vue-du-calendrier-pour-les-auto-repondeurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMaksymalizowacDokladnoscWalidacjiNumeruTelefonuPrzezApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-maksymalizowac-dokladnosc-walidacji-numeru-telefonu-przez-api.html",
"en": "https://www.getresponse.com/help/how-do-i-maximize-the-accuracy-of-api-phone-validation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-optimiere-ich-die-validierung-der-telefonnummer-bei-api-integration.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-maximizar-la-precision-de-la-validacion-telefonica-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-peredat-nomer-telefona-kontakta-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-maximizar-a-precisao-da-validacao-telefonica-api.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-massimizzare-la-precisione-della-convalida-del-telefono-api.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maximiser-la-precision-de-la-validation-telephonique-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsALinkedList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-linked-list.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-powiazana.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-verlinkte-liste-quot.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-vinculada.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-svjazannyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-com-link.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-collegata.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-associee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddANewFromAddressAndNameForMyMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyIsTheGetresponseBadgeDisplayedAtTheBottomOfMyEmailsLandingPagesAndWebsites = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-is-the-getresponse-badge-displayed-at-the-bottom-of-my-emails-landing-pages-and-websites.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-logo-getresponse-wyswietlane-jest-w-moich-wiadomosciach.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-die-getresponse-badge-in-meinen-e-mails-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-mostrado-el-simbolo-de-getresponse-en-mis-emails.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-v-moich-pismah-otobrazaetsya-beidj-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-o-emblema-da-getresponse-e-exibido-em-meus-e-mails.html",
"it": "https://www.getresponse.com/it/assistenza/perche-il-badge-getresponse-viene-visualizzato-nelle-mie-e-mail.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-le-badge-getresponse-s-affiche-t-il-dans-mes-e-mails.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostDlaczegoNiektoreWiadomosciZawierajaInformacjeOWysylceWImieniuAlboPrzez = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/dlaczego-niektore-wiadomosci-zawieraja-informacje-o-wysylce-w-imieniu-albo-przez.html",
"en": "https://www.getresponse.com/help/why-can-some-emails-display-as-on-behalf-of-or-via.html",
"de": "https://www.getresponse.com/de/hilfe/warum-werden-manche-e-mails-als-im-auftrag-oder-via-angezeigt.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-algunos-emails-se-muestran-como-nombre-de-o-a-traves-de.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-v-nekotoryh-pismah-otobrazhajutsa-nadpisi-ot-litsa-ili-cherez.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-alguns-e-mails-aparecem-como-em-nome-de-ou-via.html",
"it": "https://www.getresponse.com/it/assistenza/perche-alcune-email-possono-essere-visualizzate-come-per-conto-di-o-tramite.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-certains-e-mails-peuvent-ils-s-afficher-avec-la-mention-au-nom-de-ou-via.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyOferujecieKodHtmlDlaFormularzy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-oferujecie-kod-html-dla-formularzy.html",
"en": "https://www.getresponse.com/help/do-you-offer-a-plain-html-form-code.html",
"de": "https://www.getresponse.com/de/hilfe/gibt-es-einen-plain-html-webformular-editor.html",
"es": "https://www.getresponse.com/es/ayuda/ofrecen-un-codigo-de-formulario-html-plano.html",
"ru": "https://www.getresponse.com/ru/help-ru/dostupen-li-obichni-html-kod-formy.html",
"it": "https://www.getresponse.com/it/assistenza/offrite-un-codice-html-normale-per-il-modulo.html",
"br": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/voces-oferecem-um-codigo-de-formulario-html-simples.html",
"fr": "https://www.getresponse.com/fr/aide/proposez-vous-un-code-de-formulaire-html-simple.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoCrearNewslettersAdaptivasAMoviles = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUsunacKontaktyZListyLubCalegoKonta = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakEksportowacHistorieCzatuZWebinaru = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-historie-czatu-z-webinaru.html",
"en": "https://www.getresponse.com/help/how-do-i-export-webinar-chat-history.html",
"de": "https://www.getresponse.com/de/hilfe/wie-exportiere-ich-den-webinar-chatverlauf.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-el-historial-de-chat-de-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-chat-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exporto-o-historico-de-bate-papo-do-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-esportare-la-cronologia-delle-chat-dei-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-l-historique-des-chats-du-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWJakiSposobMogeSledzicOtwarciaWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-sledzic-otwarcia-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-do-i-track-message-opens.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-nachricht-geoeffnet.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-un-seguimiento-de-las-aperturas-de-los-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-otslezhivat-otkrytija-soobshenij-v-protsesse-avtomatizatsii.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-rastrear-as-aberturas-de-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-monitorare-le-aperture-dei-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-l-ouverture-des-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedoCodificarNewslettersEnHtml = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaWarunekZmienionoPoleDodatkowe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-warunek-zmieniono-pole-dodatkowe.html",
"en": "https://www.getresponse.com/help/how-does-the-custom-field-changed-condition-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-bedingung-benutzerdefiniertes-feld-wurde-geaendert.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-la-condicion-campo-personalizado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-uslovije-izmeneno-znachenie-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-a-condicao-de-campo-personalizado-funciona.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-condizione-campo-personalizzato-modificato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-condition-champ-personnalise-modifie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPuedoCrearMensajesConOrientacionDeDerechaAIzquierda = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSprawdzicStatystykiWyslanychZaproszenNaWebinarOrazPrzypomnien = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-sprawdzic-statystyki-wyslanych-zaproszen-na-webinar-oraz-przypomnien.html",
"en": "https://www.getresponse.com/help/how-do-i-check-statistics-for-the-webinar-invitations-and-reminders-i-ve-sent.html",
"de": "https://www.getresponse.com/de/hilfe/wie-ueberpruefe-ich-die-statistiken-fuer-die-einladungen-und-erinnerungen-die-ich-fuer-meine-webinare-gesendet-habe.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-comprobar-las-estadisticas-de-las-invitaciones-y-los-recordatorios-de-los-webinars-que-he-enviado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-posmotret-statistiku-priglashenij-i-napominanij-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-verificar-as-estatisticas-dos-convites-e-lembretes-do-webinar-que-enviei.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-controllare-le-statistiche-per-gli-inviti-ai-webinar-e-i-promemoria-che-ho-inviato.html",
"fr": "https://www.getresponse.com/fr/aide/comment-consulter-les-statistiques-relatives-aux-invitations-et-aux-rappels-de-webinaires-que-j-ai-envoyes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieMogeZnalezcStatystykiDlaKonkretnychListLubWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakStworzycWiadomoscZTekstemPisanymOdPrawejDoLewej = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCualEsLaDiferenciaEntreUnMensajeDeTextoPlanoYUnMensajeHtml = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPuedenMisNewslettersSerVistasOnline = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html",
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanMyNewslettersBeViewedOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-my-newsletters-be-viewed-online.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-newslettery-mozna-przegladac-online.html",
"de": "https://www.getresponse.com/de/hilfe/koennen-meine-newsletter-online-angesehen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/pueden-mis-newsletters-ser-vistas-online.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-prosmatrivat-moi-rassylki-v-internete.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-newsletters-podem-ser-visualizadas-on-line.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-newsletter-possono-essere-visualizzate-online.html",
"fr": "https://www.getresponse.com/fr/aide/mes-newsletters-peuvent-elles-etre-consultees-en-ligne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheDifferenceBetweenAPlainTextMessageAndAnHtmlMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-difference-between-a-plain-text-message-and-an-html-message.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-roznica-pomiedzy-wiadomoscia-tekstowa-a-wiadomoscia-html.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-unterschied-zwischen-einer-klartext-nachricht-und-einer-html-nachricht.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-la-diferencia-entre-un-mensaje-de-texto-plano-y-un-mensaje-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/v-chem-raznica-mezhdu-pismom-s-obychnym-tekstom-html-pismom.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-a-diferenca-entre-uma-mensagem-de-texto-simples-e-uma-mensagem-de-html.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-differenza-tra-un-messaggio-di-testo-normale-e-un-messaggio-html.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-difference-entre-un-message-en-texte-brut-et-un-message-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICreateMessagesWithRightToLeftTextOrientation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-create-messages-with-right-to-left-text-orientation.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-wiadomosc-z-tekstem-pisanym-od-prawej-do-lewej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-nachrichten-mit-rechts-links-orientierung-erstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-crear-mensajes-con-orientacion-de-derecha-a-izquierda.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-pisma-s-vyravnivanijem-teksta-po-pravomu-kraju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-criar-mensagens-com-orientacao-de-texto-da-direita-para-a-esquerda.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-creer-des-messages-dont-le-texte-est-oriente-de-droite-a-gauche.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-messaggi-con-orientamento-del-testo-da-destra-a-sinistra.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanICodeHtmlNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-code-html-newsletters.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-napisac-newsletter-w-html-u.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-html-newsletter-selbst-programmieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-codificar-newsletters-en-html.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-sozdavat-rassylki-na-html-kode.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-codificar-newsletters-em-html.html",
"it": "https://www.getresponse.com/it/assistenza/posso-codificare-newsletter-html.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-coder-des-newsletters-en-html.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanICreateMobileResponsiveNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-create-mobile-responsive-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-mobile-responsive-newsletter-erstellen.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-sozdavat-rassylki-adaptirujemyje-dla-mobilnyh-ustrojstv.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-crear-newsletters-adaptivas-a-moviles.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utworzyc-responsywne-newslettery.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-criar-newsletters-responsivos-a-dispositivos-moveis.html",
"it": "https://www.getresponse.com/it/assistenza/posso-creare-newsletter-mobile-responsive.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-creer-des-newsletters-adaptees-aux-mobiles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsNewInTheNewMessageEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-new-in-the-new-message-editor.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-znajde-w-nowym-kreatorze-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-neu-im-neuen-nachrichten-editor.html",
"es": "https://www.getresponse.com/es/ayuda/que-hay-de-nuevo-en-el-nuevo-editor-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-novogo-v-novom-redaktore-soobshenij.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-c-e-di-nuovo-nel-nuovo-editor-di-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-ha-de-novo-no-novo-editor-de-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-nouveautes-du-nouvel-editeur-de-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanISeeHowMyMessagesAreDoing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-see-how-my-messages-are-doing.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-zobaczyc-statystyki-moich-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-nachrichten-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-como-van-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-statistiku-svoih-soobshenij.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-como-estao-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-come-stanno-andando-i-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-resultats-de-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIViewMyDeliveredMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-view-my-delivered-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-gesendeten-nachrichten-finden.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-mis-mensajes-entregados.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-prosmotret-otpravlennyje-soobshenija.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlic-dostarczone-wiadomosci.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-minhas-mensagens-entregues.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-miei-messaggi-consegnati.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-messages-livres.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAndSendANewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-and-send-a-newsletter.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-newsletter.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-einen-newsletter.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-envio-una-newsletter.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-rassylku.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-newsletter.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-inviare-newsletter.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-envoyer-une-newsletter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMyMessageHasA0OpenRateWhyIsThat = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-message-has-a-0-open-rate-why-is-that.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wird-fuer-meine-nachricht-eine-oeffnungsrate-von-0-angezeigt.html",
"ru": "https://www.getresponse.com/ru/help-ru/u-mojego-soobshenija-pokazatel-0-procentov-otkrytij-pochemu-tak.html",
"pl": "https://www.getresponse.pl/pomoc/czemu-statystyki-otwarc-wskazuja-0.html",
"es": "https://www.getresponse.com/es/ayuda/mi-mensaje-tiene-una-tasa-de-apertura-del-0-por-que.html",
"br": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"pt": "https://www.getresponse.com/pt/ajuda/a-minha-mensagem-tem-uma-taxa-aberta-de-0-porque.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-messaggio-ha-un-tasso-di-apertura-dello-0-perche.html",
"fr": "https://www.getresponse.com/fr/aide/le-taux-d-ouverture-de-mon-message-est-de-0-comment-cela-se-fait-il.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMyOpenRateStatisticsAreNotAvailableWhatMayBeCausingThis = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-open-rate-statistics-are-not-available-what-may-be-causing-this.html",
"pl": "https://www.getresponse.pl/pomoc/moje-statystyki-otwarc-sa-niedostepne-co-moze-byc-powodem.html",
"de": "https://www.getresponse.com/de/hilfe/es-sind-keine-statistiken-fuer-oeffnungsraten-verfuegbar-woran-koennte-das-liegen.html",
"es": "https://www.getresponse.com/es/ayuda/mis-estadisticas-de-tasa-de-apertura-no-estan-disponibles-que-puede-estar-causando-esto.html",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-po-otkrytijam-mojego-pisma-nedostupna-v-chem-prichina.html",
"br": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"pt": "https://www.getresponse.com/pt/ajuda/as-minhas-estatisticas-de-indice-de-abertura-nao-estao-disponiveis-o-que-pode-estar-causando-isso.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-statistiche-sul-tasso-di-apertura-non-sono-disponibili-cosa-potrebbe-causare-questo.html",
"fr": "https://www.getresponse.com/fr/aide/mes-statistiques-sur-le-taux-d-ouverture-ne-sont-pas-disponibles-quelle-en-est-la-cause.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTrackOpenRateForPlainTextNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-track-open-rate-for-plain-text-newsletters.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-oeffnungsrate-fuer-klartext-newsletter-tracken.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-otslezhivat-procent-otkrytij-dla-soobshenij-v-formate-obychnyj-tekst.html",
"br": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-rastrear-a-taxa-de-abertura-das-newsletters-de-texto-simples.html",
"pl": "https://www.getresponse.pl/pomoc/jak-sledzic-otwarcia-newsletterow-w-formacie-tekstowym.html",
"it": "https://www.getresponse.com/it/assistenza/come-tenere-traccia-del-tasso-di-apertura-per-le-newsletter-in-testo-non-formattato.html",
"es": "https://www.getresponse.com/es/ayuda/como-rastrear-la-tasa-de-apertura-de-los-newsletters-de-texto-plano.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-le-taux-d-ouverture-des-newsletters-en-texte-brut.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanISeeTheStatisticsForASpecificListOrMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-see-the-statistics-for-a-specific-list-or-message.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-znalezc-statystyki-dla-konkretnych-list-lub-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-die-statistiken-fuer-eine-bestimmte-liste-oder-nachricht-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-las-estadisticas-de-una-lista-o-mensaje-especifico.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-mozhno-posmotret-statistiku-po-konkretnomu-spisku-ili-pismu.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-as-estatisticas-de-uma-lista-ou-mensagem-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-le-statistiche-per-una-lista-o-un-messaggio-specifico.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-les-statistiques-d-une-liste-ou-d-un-message-specifique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsOpenRateTracking = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-open-rate-tracking.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-sledzenie-otwarc-w-wiadomosciach.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-tracking-der-oeffnungsrate.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-la-tasa-de-apertura.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-pokazatela-otkrytij.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-rastreamento-da-taxa-de-abertura.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-monitoraggio-del-tasso-di-apertura.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-du-taux-d-ouverture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsClickTracking = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindOutHowMyContactsWereAddedToASpecificList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-out-how-my-contacts-were-added-to-a-specific-list.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-dowiem-sie-jak-kontakty-zostaly-dodane-do-konkretnej-listy.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-heraus-wie-meine-kontakte-zu-einer-bestimmten-liste-hinzugefuegt-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-saber-como-fueron-anadidos-mis-contactos-a-una-lista-especifica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uznat-kakim-obrazom-moi-kontakty-byli-dobavleny-v-konkretnyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-scoprire-come-sono-stati-aggiunti-i-miei-contatti-a-una-lista-specifica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakIskatKontakty = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakKontaktyMogutSamiPodpisatsaNaRassylki = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakJaMoguPodpisatKontaktyNaRassylkuVruchnuju = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatConditionsCanIUseToSearchForContactsAndCreateSegments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-conditions-can-i-use-to-search-for-contacts-and-create-segments.html",
"pl": "https://www.getresponse.pl/pomoc/jakich-warunkow-wyszukiwania-moge-uzyc-aby-wyszukiwac-kontakty-i-tworzyc-segmenty.html",
"de": "https://www.getresponse.com/de/hilfe/welche-bedingungen-kann-ich-bei-der-suche-nach-kontakten-und-beim-erstellen-von-segmenten-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/que-condiciones-puedo-usar-para-buscar-contactos-y-crear-segmentos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-uslovija-ja-mogu-ispolzovat-dla-poiska-kontaktov-i-sozdanija-segmentov.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-condicoes-posso-usar-para-procurar-contatos-e-criar-segmentos.html",
"it": "https://www.getresponse.com/it/assistenza/che-condizioni-posso-usare-per-cercare-contatti-e-creare-segmenti.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-conditions-puis-je-utiliser-pour-rechercher-des-contacts-et-creer-des-segments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguPerenestiSvojSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-perenesti-svoj-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeniesc-moja-liste-z-active-campaign-aweber-constant-contact-lub-mailchimp.html",
"en": "https://www.getresponse.com/help/how-can-i-migrate-my-list-from-active-campaign-aweber-constant-contact-or-mailchimp.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-migrar-mi-lista-de-active-campaign-aweber-constant-contact-o-mailchimp.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trasferire-la-mia-lista-da-active-campaign-aweber-constant-contact-o-mailchimp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-liste-von-active-campaign-aweber-constant-contact-oder-mailchimp-migrieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMozhnoLiDobavitSluzhebnyjeAdresaVSpisok = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPrisvoitNastraivajemyjePoljaPriImporte = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakPodgotovitFaylDlaImporta = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakImportirovatSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICustomizeMyDashboardWithWidgets = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-customize-my-dashboard-with-widgets.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-pulpit-za-pomoca-widgetow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-startseite-mithilfe-von-widgets-anpassen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-panel-de-control-con-widgets.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-vidzety-na-glavnyu-panel.html",
"br": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-customizar-meu-painel-com-widgets.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-mia-dashboard-con-i-widget.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-tableau-de-bord-avec-des-widgets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeWyslacWiadomosciZgodneZProtokolemDmarc = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkopiowacKontaktyNaInnaListe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skopiowac-kontakty-na-inna-liste.html",
"en": "https://www.getresponse.com/help/how-do-i-copy-contacts-to-another-list-in-marketing-automation.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kopiere-ich-kontakte-in-eine-andere-liste.html",
"es": "https://www.getresponse.com/es/ayuda/como-copio-contactos-a-otra-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-kontakty-v-drugoj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-contatos-para-outra-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-copiare-i-contatti-in-un-altra-lista-in-marketing-automation.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-des-contacts-vers-une-autre-liste-dans-l-automatisation-du-marketing.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialaFunkcjaAutomatycznejWalidacjiNumeruTelefonu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUzywacKoduSledzacegoGoogleAnalyticsNaStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-kodu-sledzacego-google-analytics-na-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-use-a-google-analytics-tracking-code-on-my-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-google-analytics-tracking-code-auf-meiner-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-el-codigo-de-seguimiento-de-google-analytics-en-mi-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-kod-otslezhivanija-google-analytics-na-svojom-lendinge-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-un-codice-di-monitoraggio-di-google-analytics-sulla-mia-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-um-codigo-de-acompanhamento-do-google-analytics-na-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-un-code-de-suivi-google-analytics-sur-mon-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyCanTILogIn = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-can-t-i-log-in.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nie-moge-zalogowac-sie-do-konta.html",
"de": "https://www.getresponse.com/de/hilfe/warum-kann-ich-mich-nicht-einloggen.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-puedo-iniciar-la-sesion.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-ya-ne-mogy-vojti-v-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-nao-consigo-fazer-login.html",
"it": "https://www.getresponse.com/it/assistenza/perche-non-riesco-ad-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ne-puis-je-pas-me-connecter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatBrowsersAreCompatibleWithGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-browsers-are-compatible-with-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-przegladarki-sa-kompatybilne-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-browser-sind-mit-getresponse-kompatibel.html",
"es": "https://www.getresponse.com/es/ayuda/que-navegadores-son-compatibles-con-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-brayzery-podderzivaet-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-navegadores-sao-compativeis-com-a-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/quali-browser-sono-compatibili-con-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-navigateurs-compatibles-avec-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUmiescicFormularzLegacyNaSwojejStronie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-umiescic-formularz-legacy-na-swojej-stronie.html",
"en": "https://www.getresponse.com/help/how-do-i-install-a-legacy-form-on-my-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-installiere-ich-ein-legacy-webformular-auf-meiner-seite.html",
"es": "https://www.getresponse.com/es/ayuda/como-instalo-un-formulario-de-legado-en-mi-pagina-web.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-razmestit-formu-iz-redaktora-legacy-na-svoey-stranitse.html",
"br": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-instalar-um-formulario-legado-no-meu-site.html",
"it": "https://www.getresponse.com/it/assistenza/come-installo-un-modulo-sulla-mia-pagina.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMisListasSonDeConfirmacionUnicaSeRequiereLaDobleConfirmacionParaCumplirConLaNormativaDelRgpd = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/mis-listas-son-de-confirmacion-unica-se-requiere-la-doble-confirmacion-para-cumplir-con-la-normativa-del-rgpd.html",
"en": "https://www.getresponse.com/help/my-lists-are-single-opt-in-is-double-opt-in-required-to-be-gdpr-compliant.html",
"pl": "https://www.getresponse.pl/pomoc/moje-listy-sa-single-opt-in-czy-subskrypcja-z-potwierdzeniem-jest-wymagana-aby-spelniac-wymogi-rodo.html",
"de": "https://www.getresponse.com/de/hilfe/meine-listen-sind-single-opt-in-ist-double-opt-in-erforderlich-um-dsgvo-konform-zu-sein.html",
"it": "https://www.getresponse.com/it/assistenza/le-mie-liste-sono-single-opt-in-e-necessario-il-double-opt-in-per-essere-conformi-al-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/minhas-listas-sao-opt-in-simples-e-necessario-um-opt-in-duplo-para-ser-compativel-com-o-gdpr.html",
"ru": "https://www.getresponse.com/ru/help-ru/moi-spiski-imejut-odinarnoe-soglasie-trebuetsya-li-dvojnoe-soglasie-podpiski-dlya-sobljudeniya-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/mes-listes-sont-en-simple-inscription-single-opt-in-le-double-inscription-est-il-necessaire-pour-etre-conforme-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeDodawacAdresyGrupDystrybucyjnychDoListyOdbiorcow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestSledzenieKlikniec = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-sledzenie-klikniec.html",
"en": "https://www.getresponse.com/help/what-is-click-tracking.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-klick-tracking.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-seguimiento-de-clics.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-otslezhivanije-po-kliku.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-rastreamento-de-cliques.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tracciamento-dei-clic.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-suivi-des-clics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnajdeKluczApi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeSledzicSwojaStarszaWersjeLandingPagePrzyUzyciuVisualWebsiteOptimizerVwo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-sledzic-swoja-starsza-wersje-landing-page-przy-uzyciu-visual-website-optimizer-vwo.html",
"en": "https://www.getresponse.com/help/can-i-track-my-legacy-landing-page-with-visual-website-optimizer-vwo.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-legacy-landing-pages-mit-visual-website-optimizer-vwo-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-rastrear-mi-landing-page-de-legado-con-visual-website-optimizer-vwo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-otslezhivat-svoj-lending-v-versii-legacy-s-pomoshju-visual-website-optimizer.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-acompanhar-minha-landing-page-legada-com-o-visual-website-optimizer-vwo.html",
"it": "https://www.getresponse.com/it/assistenza/posso-monitorare-la-mia-landing-page-con-visual-website-optimizer-vwo.html",
"fr": "https://www.getresponse.com/fr/aide/comment-suivre-mon-ancienne-page-de-destination-avec-visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacWieleKodowSledzacychDoStarszejWersjiLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wiele-kodow-sledzacych-do-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-can-i-add-multiple-tracking-codes-to-my-legacy-landing-page-with-google-tag-manager.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mehrere-tracking-codes-zu-meiner-legacy-landing-page-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-anadir-multiples-codigos-de-seguimiento-a-mi-landing-page-de-legado-con-google-tag-manager.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-dobavit-neskolko-kodov-otslezhivanija-na-svoj-lending-v-versii-legacy-s-google-tag-manager.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-moltiplicare-i-codici-di-monitoraggio-nella-mia-landing-page-con-google-tag-manager.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-varios-codigos-de-rastreamento-a-minha-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-plusieurs-codes-de-suivi-a-mon-ancienne-page-de-destination-avec-google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieZnajdeStatystykiWebinaru = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-statystyki-webinaru.html",
"en": "https://www.getresponse.com/help/where-do-i-find-my-webinar-statistics.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-die-webinar-statistik.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-statistiku-vebinara.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-as-estatisticas-do-meu-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-statistiche-del-mio-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-les-statistiques-de-mes-webinaires.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostComoPreparoMiCuentaParaElRgpd = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/como-preparo-mi-cuenta-para-el-rgpd.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-moje-konto-do-rodo.html",
"en": "https://www.getresponse.com/help/how-do-i-get-my-account-ready-for-gdpr.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-mein-konto-auf-die-dsgvo-vor.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-ychetnyu-zapis-k-reglamenty-gdpr.html",
"br": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-deixo-minha-conta-pronta-para-a-gdpr.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-il-mio-account-per-il-gdpr.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-mon-compte-au-rgpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchMeineBestatigungsseiteAnpassen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchDieSpracheFurMeineListeUndBestatigungsnachrichtAndern = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchKontakteZuMeinenListenHinzufugen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakDobavlyatKontaktyVSpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakYaMoguIzmenitHastroykiPodpiskiDlaMojegoSpiska = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostPochemuMojaSobstvennayaTemaSoobsheniaPodtverzhdeniyaPodpiskiNeBylaOdobrena = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostMoguLiYaIzmenitYazykSpiskaISoobsheniaPodtverzhdeniaPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakNastroitStranitsuPodtverzhdeniaPodpiski = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeNahodyatsaNastrojkiSpiska = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsa-nastrojki-spiska.html",
"en": "https://www.getresponse.com/help/where-can-i-find-my-list-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-ustawienia-mojej-listy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-lista.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-ajustes-de-mi-lista-2.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-listeneinstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakUdalitSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdeYaMoguProsmatrivatSvoiSpiskiIUpravlyatImi = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKakSozdatNovyjSpisokKontaktov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieAndereIchDieAnzahlDerBenutzerDieZugriffAufMeinKontoHaben = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostKannIchAnderenPersonenZugriffZuMeinemKontoGewahren = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIGiveOtherPeopleAccessToMyAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-give-other-people-access-to-my-account.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dac-komus-dostep-do-mojego-konta.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-anderen-personen-zugriff-zu-meinem-konto-gewahren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-dar-acceso-a-mi-cuenta-a-otras-personas.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-davat-drygim-lyudyam-dostyp-k-svoej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-dar-acesso-a-minha-conta-para-outras-pessoas.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ad-altre-persone-di-accedere-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-donner-acces-a-mon-compte-a-d-autres-personnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIChangeTheNumberOfPeopleWithAccessToMyAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-change-the-number-of-people-with-access-to-my-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-liczbe-osob-majacych-dostep-do-mojego-konta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-andere-ich-die-anzahl-der-benutzer-die-zugriff-auf-mein-konto-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-cambio-el-numero-de-personas-con-acceso-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-kolichestvo-luydej-imeyushich-dostyp-k-moej-ychetnoi-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-altero-a-quantidade-de-pessoas-com-acesso-a-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-numero-di-persone-con-accesso-al-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-le-nombre-de-personnes-ayant-acces-a-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatRolesCanIAssignToUsers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-roles-can-i-assign-to-users.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-role-moge-przydzielac-wspolpracownikom.html",
"de": "https://www.getresponse.com/de/hilfe/welche-rollen-kann-ich-mitarbeitern-zuweisen.html",
"es": "https://www.getresponse.com/es/ayuda/que-roles-puedo-asignar-a-los-colaboradores.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-roli-ya-mogy-naznachat-polzovatelyam.html",
"br": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-papeis-posso-atribuir-aos-colaboradores.html",
"it": "https://www.getresponse.com/it/assistenza/quali-ruoli-posso-assegnare-agli-utenti.html",
"fr": "https://www.getresponse.com/fr/aide/quels-roles-puis-je-attribuer-aux-utilisateurs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAddAUser = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-add-a-user.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-wspolpracownika.html",
"de": "https://www.getresponse.com/de/hilfe/wie-fuege-ich-einen-mitarbeiter-hinzu.html",
"es": "https://www.getresponse.com/es/ayuda/como-agrego-un-colaborador.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-polzovatelya.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-colaborador.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-utente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-un-utilisateur.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereDoIReviewNewslettersADesignerCreates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-do-i-review-newsletters-a-designer-creates.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-reviso-as-newsletters-que-um-designer-cria.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-recenzowac-newslettery-stworzone-przez-grafika.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-rassyilki-kotoryie-sozdaet-dizayner.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-esaminare-le-newsletter-create-da-un-designer.html",
"de": "https://www.getresponse.com/de/hilfe/wo-ueberpruefe-ich-newsletter-die-ein-designer-erstellt.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-revisar-las-newsletters-que-crea-un-disenador.html",
"fr": "https://www.getresponse.com/fr/aide/ou-consulter-les-newsletters-creees-par-un-designer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatDoesItMeanThatMyAccountIsBeingVerified = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIMakeSureIAmInComplianceWithCaslRegulations = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html",
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWarumIstDieEinhaltungGesetzlicherVorschriftenWichtig = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/warum-ist-die-einhaltung-gesetzlicher-vorschriften-wichtig.html",
"en": "https://www.getresponse.com/help/why-is-legal-compliance-important.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-importante-el-cumplimiento-legal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-vazno-sootvetstvie-trebovaniyam-zakonodatelstva.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nalezy-przestrzegac-przepisow-prawnych.html",
"it": "https://www.getresponse.com/it/assistenza/perche-e-importante-la-conformita-legale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWoFindeIchDenApiSchlussel = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-den-api-schlussel.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znajde-klucz-api.html",
"en": "https://www.getresponse.com/help/where-do-i-find-the-api-key.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mi-clave-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-api-kluch.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-chave-da-api.html",
"it": "https://www.getresponse.com/it/assistenza/dove-trovo-la-chiave-api.html",
"fr": "https://www.getresponse.com/fr/aide/ou-trouver-la-cle-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyIsLegalComplianceImportant = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-is-legal-compliance-important.html",
"de": "https://www.getresponse.com/de/hilfe/warum-ist-die-einhaltung-gesetzlicher-vorschriften-wichtig.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-es-importante-el-cumplimiento-legal.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-vazno-sootvetstvie-trebovaniyam-zakonodatelstva.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-a-conformidade-legal-e-importante.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-nalezy-przestrzegac-przepisow-prawnych.html",
"it": "https://www.getresponse.com/it/assistenza/perche-e-importante-la-conformita-legale.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanISendDmarcCompliantMail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-send-dmarc-compliant-mail.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-wyslac-wiadomosci-zgodne-z-protokolem-dmarc.html",
"de": "https://www.getresponse.com/de/hilfe/wie-versende-ich-dmarc-konforme-e-mails.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-enviar-un-email-compatible-con-dmarc.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-otpravlyat-pisma-sootvetstvyushie-politike-dmarc.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-enviar-e-mails-em-conformidade-com-dmarc.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-inviare-messaggio-conforme-a-dmarc.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-envoyer-des-e-mails-conformes-a-dmarc.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsSpam = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-spam.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-spam.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-spam.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-spam.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-lo-spam.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanMyAccountBeClosedBecauseOfSpamComplaints = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-my-account-be-closed-because-of-spam-complaints.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moje-konto-moze-zostac-zamkniete-przez-zgloszenia-spamu.html",
"de": "https://www.getresponse.com/de/hilfe/kann-mein-konto-aufgrund-von-spam-beschwerden-geschlossen-werden.html",
"es": "https://www.getresponse.com/es/ayuda/puede-cerrarse-mi-cuenta-debido-a-mis-quejas-de-spam.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogyt-li-zablokirovat-moyu-ychetnyu-zapis-iz-za-zalob-na-spam.html",
"br": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"pt": "https://www.getresponse.com/pt/ajuda/minha-conta-pode-ser-fechada-por-causa-de-reclamacoes-de-spam.html",
"it": "https://www.getresponse.com/it/assistenza/il-mio-account-puo-essere-chiuso-a-causa-di-denunce-di-spam.html",
"fr": "https://www.getresponse.com/fr/aide/mon-compte-peut-il-etre-ferme-en-raison-de-plaintes-pour-spam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAPermissionBasedList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-permission-based-list.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-lista-oparta-na-zgodzie-subskrybentow.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-una-lista-basata-sull-autorizzazione-permission-based.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-spisok-na-osnove-razreshenij.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-berechtigungsbasierte-liste.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-lista-basada-en-permisos.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-lista-baseada-em-permissao.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-liste-basee-sur-l-autorisation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAReconfirmationCampaign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-reconfirmation-campaign.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-uma-campanha-de-reconfirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/na-czym-polega-kampania-uzyskania-ponownego-potwierdzenia-subskrypcji.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-una-campana-de-reconfirmacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-kampaniya-povtornogo-podtverjdeniya.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-una-campagna-di-riconferma.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-eine-rueckbestaetigungskampagne.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-une-campagne-de-reconfirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesGetresponsePricingWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-getresponse-pricing-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-oplaty-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-preisgestaltung-in-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/como-funciona-el-precio-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakim-obrazom-getresponse-raschituvaet-ceny.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-precos-na-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-prezzi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-tarification-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIUpgradeMyFreeAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-upgrade-my-free-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-ulepszyc-konto-free.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-free-account-upgraden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-actualizar-mi-cuenta-free.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-obnovit-svoi-free-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-atualizar-minha-conta-gratuita.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiornare-il-mio-account-free.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-mettre-a-jour-mon-compte-free.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheMonthlyCostOfGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-monthly-cost-of-getresponse.html",
"pl": "https://www.getresponse.pl/pomoc/jaki-jest-miesieczny-koszt-korzystania-z-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-viel-kostet-getresponse-monatlich.html",
"es": "https://www.getresponse.com/es/ayuda/cual-es-el-costo-mensual-de-getresponse.html",
"ru": "https://www.getresponse.com/ru/help-ru/skolko-stoit-yslygi-getresponse-v-mesyac.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-o-custo-mensal-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-il-costo-mensile-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quel-est-le-cout-mensuel-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatPaymentMethodsDoYouAccept = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-payment-methods-do-you-accept.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-metody-platnosci-sa-akceptowane-przez-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/welche-zahlungsmethoden-werden-akzeptiert.html",
"es": "https://www.getresponse.com/es/ayuda/que-metodos-de-pago-se-aceptan.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakie-sposoby-oplaty-vy-prinimaete.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-metodos-de-pagamento-aceitos.html",
"it": "https://www.getresponse.com/it/assistenza/quali-metodi-di-pagamento-accettate.html",
"fr": "https://www.getresponse.com/fr/aide/quels-modes-de-paiement-acceptez-vous.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeMySubscriptionPlan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-my-subscription-plan.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-zmienic-swoj-pakiet.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-mein-gewaehltes-paket-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-mi-plan-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-izmenit-svoi-tarifnyj-plan.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-a-minha-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-modificare-il-mio-piano-di-abbonamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-mon-plan-d-abonnement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddANewCreditCardForPayments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-credit-card-for-payments.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-dodac-nowa-karte-kredytowa.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-kreditkarte-fuer-zahlungen-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-una-tarjeta-a-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novyu-karty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-um-novo-cartao-de-credito-para-pagamentos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-una-nuova-carta-di-credito-per-i-pagamenti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-carte-de-credit-pour-les-paiements.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseMyDraftOrNewsletterAsAnAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-my-draft-or-newsletter-as-an-autoresponder.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-einen-entwurf-oder-newsletter-als-autoresponder-verwenden.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usa-meu-rascunhos-ou-newsletter-como-autoresponder.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-ispolzovat-svoi-chernoviki-ili-rassylki-v-kachestve-avtootvetchika.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzyc-wersje-robocza-wiadomosci-lub-newsletter-jako-autoresponder.html",
"it": "https://www.getresponse.com/it/assistenza/posso-utilizzare-la-mia-bozza-o-newsletter-come-autoresponder.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-utilizar-mi-borrador-o-newsletter-como-autoresponder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindMyMessageDrafts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-my-message-drafts.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-bozze-dei-miei-messaggi.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-nachrichtenentwuerfe.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-mis-borradores-de-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nachodjatsa-chernoviki-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-os-rascunhos-de-minha-mensagem.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-wersje-robocze-wiadomosci.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToTagContactsRandomly = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-tag-contacts-randomly.html",
"pl": "https://www.getresponse.pl/pomoc/jak-losowo-tagowac-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-zufaellig-ausgewaehlten-kontakten-ein-tag-zuweisen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvaivat-kontaktam-tegi-v-sluchajnom-porjadke.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-i-tag-ai-contatti-in-modo-casuale.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-as-tags-aleatoriamente-aos-contatos.html",
"es": "https://www.getresponse.com/es/ayuda/como-etiquetar-contactos-aleatoriamente.html",
"fr": "https://www.getresponse.com/fr/aide/comment-tagger-des-contacts-de-maniere-aleatoire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToAssignTagsAndScoreDuringImport = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-assign-tags-and-score-during-import.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-tagi-i-punkty-scoringowe-podczas-importu.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachat-tegi-i-bally-vo-vremja-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-tags-e-classificacao-durante-a-importacao.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-beim-import-meiner-kontakte-tags-und-scoring-punkte-zuordnen.html",
"it": "https://www.getresponse.com/it/assistenza/come-assegnare-tag-e-punteggio-durante-l-importazione.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-asignar-etiquetas-y-puntos-durante-la-importacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-tags-et-des-scores-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIAssignTagsOrScoresToContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-assign-tags-or-scores-to-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-przypisac-tagi-i-nadac-punkty-scoringowe.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meinen-kontakten-scoring-punkte-und-tags-zuordnen.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-atribuir-tags-ou-classificacao-aos-contatos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-naznachit-tegi-i-bally-kontaktam.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-assegnare-tag-e-punteggi-ai-contatti.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-asignar-etiquetas-o-puntuaciones-a-los-contactos.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-attribuer-des-tags-ou-des-scores-aux-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIMakeUseOfTags = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-make-use-of-tags.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-korzystac-z-tagowania.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-tags.html",
"es": "https://www.getresponse.com/es/ayuda/como-hago-uso-de-las-etiquetas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-tegi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-i-tag.html",
"br": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-utilizar-as-tags.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIUseTagsAndScoringThroughApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-use-tags-and-scoring-through-api.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-uzywac-tagow-i-scoringu-przez-api.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-tags-und-scoring-mittels-api-verwenden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-usar-etiquetas-y-puntuacion-a-traves-de-la-api.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-ispolzovat-tegi-i-bally-cherez-api.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-usar-tags-e-classificacao-atraves-de-api.html",
"it": "https://www.getresponse.com/it/assistenza/posso-usare-tagging-e-punteggio-tramite-api.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-utiliser-des-tags-et-des-scores-par-l-intermediaire-de-l-api.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIUseScoring = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-use-scoring.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-nadawanie-scoringu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-nutze-ich-scoring.html",
"es": "https://www.getresponse.com/es/ayuda/como-utilizo-la-puntuacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-primenjat-bally.html",
"br": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-uso-a-classificacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-il-punteggio.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-le-scoring.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatDoesItMeanThatMyImportHadPolicyIssues = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-policy-issues.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-ze-moj-import-jest-niezgodny-z-polityka-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-gegen-die-richtlinien-verstossen-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-problemas-de-politica.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-politikoj-servisa.html",
"it": "https://www.getresponse.com/it/assistenza/che-cosa-significa-quando-l-importazione-presenta-problemi-di-policy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-problemas-de-politica-de-uso.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-des-problemes-de-politique.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatDoesItMeanThatMyImportHadAListSharingIssue = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-import-had-a-list-sharing-issue.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-import-zostal-odrzucony-z-uwagi-na-udostepnienie-listy.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-import-ein-listen-sharing-problem-hat.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-importacion-tuvo-un-problema-con-la-comparticion-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-u-mojego-importa-problemy-svazannyje-s-rasprostranenikem-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-minha-importacao-teve-um-problema-de-compartilhamento-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-la-mia-importazione-ha-avuto-un-problema-di-condivisione-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-cela-signifie-que-mon-importation-a-eu-un-probleme-de-partage-de-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindImportStatistics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-import-statistics.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-statystyki-importow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-import-statistiken.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-las-estadisticas-de-importaciones.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahoditsa-statistika-importa.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-estatisticas-de-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-le-statistiche-d-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-des-statistiques-sur-les-importations.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIRemoveACreditCardFromMyAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-remove-a-credit-card-from-my-account.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-usunac-z-konta-karte-kredytowa.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kreditkarte-aus-meinem-kundenkonto.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-tarjeta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ydalit-karty-iz-akkaynta.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-remover-um-cartao-de-credito-da-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-una-carta-di-credito-dal-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-supprimer-une-carte-de-credit-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIDownloadMyInvoices = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-download-my-invoices.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-pobrac-faktury.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-rechnungen-herunterladen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-descargar-mis-facturas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogy-skachat-scheta-za-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-baixar-as-minhas-faturas.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-scaricare-le-mie-fatture.html",
"fr": "https://www.getresponse.com/fr/aide/comment-telecharger-mes-factures.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIChangeMyPaymentMethod = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-change-my-payment-method.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-moge-zmienic-metode-platnosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-zahlungsmethode-aendern.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-cambiar-el-metodo-de-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-izmenit-sposob-oplaty-v-moem-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-alterar-o-meu-metodo-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-cambiare-il-mio-metodo-di-pagamento.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-modifier-mon-mode-de-paiement.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatHappensWhenIGoOverMyListSizeLimit = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-happens-when-i-go-over-my-list-size-limit.html",
"pl": "https://www.getresponse.pl/pomoc/co-sie-stanie-gdy-przekrocze-dopuszczalny-rozmiar-bazy-adresowej.html",
"de": "https://www.getresponse.com/de/hilfe/was-passiert-wenn-ich-mein-listengroessenlimit-ueberschreite.html",
"es": "https://www.getresponse.com/es/ayuda/que-ocurre-cuando-supero-el-limite-de-tamano-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-bydet-esli-ya-previshy-dopystimyj-limit-bazy.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-acontece-se-eu-ultrapassar-meu-limite-de-tamanho-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-succede-quando-supero-il-limite-delle-dimensioni-della-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/que-se-passe-t-il-si-je-depasse-la-taille-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIFixATransactionErrorInMyAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-fix-a-transaction-error-in-my-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-naprawic-blad-transakcji-w-moim-koncie.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einen-transaktionsfehler-bei-kreditkartenzahlung-beheben.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-procesa-mi-pago.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-oplata-za-podpisky-ne-prohodit.html",
"br": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-corrigir-um-erro-de-pagamento-na-minha-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-correggere-un-errore-di-transazione-nel-mio-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-puis-je-corriger-une-erreur-de-transaction-sur-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostICanceledMySubscriptionWhyAmIStillBeingCharged = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/i-canceled-my-subscription-why-am-i-still-being-charged.html",
"pl": "https://www.getresponse.pl/pomoc/anulowalem-subskrypcje-dlaczego-oplaty-sa-wciaz-pobierane.html",
"de": "https://www.getresponse.com/de/hilfe/ich-habe-mein-abo-gekuendigt-warum-sehe-immer-noch-abbuchungen.html",
"es": "https://www.getresponse.com/es/ayuda/he-cancelado-mi-suscripcion-por-que-todavia-me-estan-cobrando.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemy-sredstva-za-podpisky-prodolzayut-spisyvatsya-posle-togo-kak-ya-ydalil-svoi-akkaynt.html",
"br": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"pt": "https://www.getresponse.com/pt/ajuda/cancelei-a-minha-assinatura-por-que-ainda-estou-sendo-cobrado.html",
"it": "https://www.getresponse.com/it/assistenza/ho-cancellato-il-mio-abbonamento-perche-mi-viene-ancora-addebitato.html",
"fr": "https://www.getresponse.com/fr/aide/j-ai-annule-mon-abonnement-pourquoi-suis-je-encore-facture.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsAdditionalPaymentConfirmation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-additional-payment-confirmation.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-dodatkowe-potwierdzenie-platnosci.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-das-zusaetzliche-authentifizierungsverfahren-fuer-zahlungen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-confirmacion-de-pago-adicional.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoe-dopolnitelnoe-podtverzdenie-oplaty.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-confirmacao-adicional-de-pagamento.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-conferma-di-pagamento-aggiuntiva.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-confirmation-de-paiement-supplementaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCancelMyAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-cancel-my-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zamknac-konto-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-loesche-ich-mein-konto.html",
"es": "https://www.getresponse.com/es/ayuda/como-cancelo-mi-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-moy-akkaunt.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cancellare-il-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-cancelo-a-minha-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-resilier-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsTheGetresponseRefundPolicy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-the-getresponse-refund-policy.html",
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-polityka-zwrotu-kosztow-w-getresponse.html",
"de": "https://www.getresponse.com/de/hilfe/wie-sind-die-getresponse-bestimmungen-fuer-rueckerstattungen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-obtener-un-reembolso.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakya-politika-vozvrata-sredstv-v-getresponse.html",
"br": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"pt": "https://www.getresponse.com/pt/ajuda/qual-e-a-politica-de-reembolso-da-getresponse.html",
"it": "https://www.getresponse.com/it/assistenza/qual-e-la-politica-dei-rimborsi-di-getresponse.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-politique-de-remboursement-de-getresponse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToCreateAWebPushNotification = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-create-a-web-push-notification.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-powiadomienia-web-push.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-web-push-uvedomlenie.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-notificacao-web-push.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-web-push-benachrichtigung.html",
"es": "https://www.getresponse.com/es/ayuda/como-se-crea-una-notificacion-push.html",
"it": "https://www.getresponse.com/it/assistenza/come-creare-una-notifica-push-web.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-les-notifications-push-web.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoesTheAutomatedPhoneValidationFeatureWork = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-does-the-automated-phone-validation-feature-work.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dziala-funkcja-automatycznej-walidacji-numeru-telefonu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktioniert-die-automatische-telefonvalidierung.html",
"es": "https://www.getresponse.com/es/ayuda/como-opera-la-funcion-de-validacion-automatica-telefonica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajet-funkcija-avtomaticheskoj-proverki-telefona.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funciona-o-recurso-de-validacao-automatica-de-telefone.html",
"it": "https://www.getresponse.com/it/assistenza/come-funziona-la-funzione-di-convalida-automatica-del-telefono.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionne-la-fonction-de-validation-automatique-de-numero-de-telephone.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateAndUseCustomFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-and-use-custom-fields.html",
"pl": "https://www.getresponse.pl/pomoc/jak-tworzyc-pola-dodatkowe-i-jak-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-und-nutze-ich-benutzerdefinierte-felder.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-y-uso-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdavat-i-ispolzovat-nastraivajemyje-pola.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-e-utilizzare-i-campi-personalizzati.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-e-usar-campos-customizados.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-et-utiliser-des-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddLinksToCustomFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-links-to-custom-fields.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodac-linki-do-pol-dodatkowych.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-links-zu-benutzerdefinierten-feldern-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-enlaces-a-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-dobavlat-ssylki-v-nastraivajemyje-pola.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-links-aos-campos-customizados.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-i-link-a-campi-personalizzati.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-liens-aux-champs-personnalises.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreTheAvailableCustomFieldTypes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-the-available-custom-field-types.html",
"pl": "https://www.getresponse.pl/pomoc/jakie-rodzaje-pol-dodatkowych-sa-dostepne.html",
"de": "https://www.getresponse.com/de/hilfe/welche-arten-von-benutzerdefinierten-feldern-kann-ich-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/cuales-son-los-tipos-de-los-campos-personalizados.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-sushestvujut-tipy-nastraivajemuch-polej.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-campi-personalizzati-sono-disponibili.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-sao-os-tipos-de-campos-personalizados-disponiveis.html",
"fr": "https://www.getresponse.com/fr/aide/quels-sont-les-types-de-champs-personnalises-disponibles.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatAreSuppressionListsAndWhenToUseThem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html",
"pl": "https://www.getresponse.pl/pomoc/czym-sa-listy-wykluczen-i-jak-moge-z-nich-korzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-unterdrueckungslisten-und-wie-werden-sie-verwendet.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-las-listas-de-supresion-y-cuando-usarlas.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-spiski-iskluchenij-i-kak-ih-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-listas-de-supressao-e-quando-usa-las.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-le-liste-di-soppressione-e-quando-utilizzarli.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-listes-de-suppression-et-quand-les-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIBlockEmailsAndDomainsFromMyListsOrAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-block-emails-and-domains-from-my-lists-or-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zablokowac-adresom-email-i-domenom-dostep-do-moich-list-lub-calego-konta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-e-mails-und-domains-aus-meinen-listen-oder-meinem-konto-auf-die-blacklist-setzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-poner-en-lista-de-bloqueo-emails-y-dominios-de-mis-listas-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlat-v-chernyj-spisok-adresa-i-domeny-iz-moih-spiskov-ili-uchetnoj-zapisi.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-inserire-email-e-domini-nella-blocklist-dalle-mie-liste-o-dal-mio-account.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-colocar-e-mails-e-dominios-na-lista-negra-das-minhas-listas-ou-conta.html",
"fr": "https://www.getresponse.com/fr/aide/comment-bloquer-des-e-mails-et-des-domaines-de-mes-listes-ou-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToFixAStaleEmailList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-fix-a-stale-email-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-naprawic-stara-baze-adresowa.html",
"de": "https://www.getresponse.com/de/hilfe/wie-mache-ich-eine-veraltete-liste-gebrauchsfaehig.html",
"es": "https://www.getresponse.com/es/ayuda/como-reparar-una-lista-envejecida.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sdelat-spisok-kontaktov-aktualnym.html",
"br": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-consertar-uma-lista-de-e-mails-defasada.html",
"it": "https://www.getresponse.com/it/assistenza/come-riparare-un-elenco-di-e-mail-obsoleto.html",
"fr": "https://www.getresponse.com/fr/aide/comment-remedier-a-une-liste-de-contacts-perimees.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowToMaintainListHygiene = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-to-maintain-list-hygiene.html",
"pl": "https://www.getresponse.pl/pomoc/jak-utrzymac-higiene-bazy-adresowej.html",
"de": "https://www.getresponse.com/de/hilfe/wie-praktiziere-ich-listenhygiene.html",
"es": "https://www.getresponse.com/es/ayuda/como-mantener-la-higiene-de-la-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podderzhivat-gigijenu-spiska.html",
"br": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-manter-a-higiene-de-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-mantenere-l-igiene-della-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-maintenir-l-hygiene-de-la-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsASegment = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-a-segment.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-segment.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-ein-segment.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-un-segmento.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-segment-i-kak-ego-sozdat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-segmento.html",
"it": "https://www.getresponse.com/it/assistenza/cos-e-un-segmento.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-segment-et-comment-en-creer-un.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDodacNowyAdresINazweNadawcyMoichWiadomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-nowy-adres-i-nazwe-nadawcy-moich-wiadomosci.html",
"en": "https://www.getresponse.com/help/how-can-i-add-a-new-from-address-and-name-for-my-messages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-neue-absenderadresse-und-namen-in-meine-nachricht-einfuegen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-un-nuevo-nombre-y-direccion-de-a-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavit-novui-adres-i-imya-otpravitelya-dlya-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-um-novo-endereco-de-remetente-e-nome-para-minhas-mensagens.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-un-nuovo-nome-e-indirizzo-del-mittente-ai-miei-messaggi.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-une-nouvelle-adresse-et-un-nouveau-nom-de-l-expediteur-pour-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakZarzadzacDomenamiStarszejWersjiLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-zarzadzac-domenami-starszej-wersji-landing-pages.html",
"en": "https://www.getresponse.com/help/how-do-i-manage-domains-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-verwalte-ich-die-domains-fuer-meine-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/como-administro-los-dominios-de-mis-landing-pages-editor-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-upravlat-domenami-moih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-gestire-i-domini-delle-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerencio-dominios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-gerer-les-domaines-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUsunacDuplikatyZMoichList = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUpewnicSieZePrzestrzegamPrzepisowCasl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-upewnic-sie-ze-przestrzegam-przepisow-casl.html",
"en": "https://www.getresponse.com/help/how-can-i-make-sure-i-am-in-compliance-with-casl-regulations.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-sicherstellen-dass-ich-die-casl-bestimmungen-einhalte.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-asegurarme-que-estoy-cumpliendo-con-las-normas-casl.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ybeditsya-chto-ya-ispolnyau-trebovaniya-kanadskogo-zakona-ot-spama-casl.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-garantir-estar-em-conformidade-com-as-regras-casl.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-essere-sicuro-di-essere-conforme-alle-normative-casl.html",
"fr": "https://www.getresponse.com/fr/aide/comment-m-assurer-que-je-suis-en-conformite-avec-la-reglementation-casl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeWielokrotniePowtarzacAkcjeIWarunki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wielokrotnie-powtarzac-akcje-i-warunki.html",
"en": "https://www.getresponse.com/help/can-i-run-specific-actions-and-conditions-multiple-times.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-bestimmte-aktionen-und-bedingungen-wiederkehrend-ausfuehren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-establecer-las-acciones-y-condiciones-como-recurrentes.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-zapuskat-uslovija-i-dejstvija-neskolko-raz.html",
"it": "https://www.getresponse.com/it/assistenza/posso-eseguire-azioni-e-condizioni-specifiche-varie-volte.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-executar-acoes-e-condicoes-especificas-varias-vezes.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-executer-plusieurs-fois-des-actions-et-des-conditions-specifiques.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeWyprobowacGetresponseZanimKupieKonto = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-wyprobowac-getresponse-zanim-kupie-konto.html",
"en": "https://www.getresponse.com/help/can-i-try-getresponse-before-i-buy.html",
"de": "https://www.getresponse.com/de/hilfe/bietet-getresponse-auch-ein-kostenloses-konto-an.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-probar-getresponse-antes-de-comprarlo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozno-li-poprobovat-getresponse-do-pokypki.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-testar-a-getresponse-antes-de-adquirir-um-plano.html",
"it": "https://www.getresponse.com/it/assistenza/posso-provare-getresponse-prima-di-comprare.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-essayer-getresponse-avant-de-l-acheter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakieFunkcjeOferujeStarszaWersjaKreatoraLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakie-funkcje-oferuje-starsza-wersja-kreatora-landing-page.html",
"en": "https://www.getresponse.com/help/what-features-do-legacy-landing-pages-offer.html",
"de": "https://www.getresponse.com/de/hilfe/welche-funktionen-bieten-legacy-landing-pages.html",
"es": "https://www.getresponse.com/es/ayuda/que-herramientas-ofrecen-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakije-funkcii-predlagajut-lendingi-v-versii-legacy.html",
"br": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"pt": "https://www.getresponse.com/pt/ajuda/que-recursos-as-landing-pages-legadas-oferecem.html",
"it": "https://www.getresponse.com/it/assistenza/quali-funzionalita-offrono-le-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/quelles-sont-les-fonctionnalites-offertes-par-les-anciennes-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakUzywacDomenSubdomenIKatalogowDlaStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-uzywac-domen-subdomen-i-katalogow-dla-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-can-i-use-domains-subdomains-and-directories-for-my-legacy-landing-pages.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-domains-subdomains-und-verzeichnisse-fuer-meine-legacy-landing-pages-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-usar-dominios-subdominios-y-directorios-para-las-landing-pages-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-ispolzovat-domeny-subdomeny-i-direktorii-dla-svoih-lendingov-v-versii-legacy.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-usare-domini-sottodomini-e-directory-per-le-mie-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-usar-dominios-subdominios-e-diretorios-para-minhas-landing-pages-legadas.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-domaines-sous-domaines-et-repertoires-pour-mes-anciennes-pages-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymJestGlobalViewIJakZNiegoKorzystac = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-jest-global-view-i-jak-z-niego-korzystac.html",
"en": "https://www.getresponse.com/help/what-is-global-view-and-how-do-i-use-it.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-globale-ansicht-und-wie-kann-ich-sie-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-vista-global-y-como-la-uso.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-globalny-prosmotr-i-kak-jego-ispolzovat.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-a-visao-global-e-como-posso-usa-la.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-la-vista-globale-e-come-si-usa.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-la-vue-mondiale-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakiegoRodzajuPlikiMogeDodawacDoWebinarow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jakiego-rodzaju-pliki-moge-dodawac-do-webinarow.html",
"en": "https://www.getresponse.com/help/what-types-of-files-can-i-upload-to-a-webinar.html",
"de": "https://www.getresponse.com/de/hilfe/welche-dateitypen-kann-ich-in-ein-webinar-hochladen.html",
"es": "https://www.getresponse.com/es/ayuda/que-tipos-de-archivos-puedo-cargar-en-un-webinar.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoje-formaty-fajlov-mozhno-zagruzhat-v-vebinarnuju-komnatu.html",
"br": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"pt": "https://www.getresponse.com/pt/ajuda/quais-tipos-de-arquivos-posso-enviar-para-um-webinar.html",
"it": "https://www.getresponse.com/it/assistenza/quali-tipi-di-file-posso-caricare-in-un-webinar.html",
"fr": "https://www.getresponse.com/fr/aide/quels-types-de-fichiers-puis-je-telecharger-vers-un-webinaire.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakaJestDostarczalnoscWiadomosciWGetresponse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jaka-jest-dostarczalnosc-wiadomosci-w-getresponse.html",
"en": "https://www.getresponse.com/help/how-good-is-your-email-deliverability.html",
"de": "https://www.getresponse.com/de/hilfe/wie-gut-ist-die-e-mail-zustellung-bei-getresponse.html",
"es": "https://www.getresponse.com/es/ayuda/que-tan-buena-es-su-capacidad-de-entrega-del-email.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakoj-u-getresponse-pokazatel-dostavlajemosti-pisem.html",
"br": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"pt": "https://www.getresponse.com/pt/ajuda/quao-bom-e-seu-indice-de-entrega-de-e-mail.html",
"it": "https://www.getresponse.com/it/assistenza/quanto-e-buono-il-tuo-tasso-di-consegna-delle-email.html",
"fr": "https://www.getresponse.com/fr/aide/quelle-est-la-qualite-de-la-delivrabilite-de-vos-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzymSaZwrotyIZgloszeniaSpamu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czym-sa-zwroty-i-zgloszenia-spamu.html",
"en": "https://www.getresponse.com/help/what-are-bounces.html",
"de": "https://www.getresponse.com/de/hilfe/was-sind-bounces-und-beschwerden.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-rebotes.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-oshibki-dostawki.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-rejeicoes.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-sono-i-rimbalzi.html",
"fr": "https://www.getresponse.com/fr/aide/que-sont-les-rebonds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoOznaczajaLiczbyPodKazdymElementemCykluAutomation = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-oznaczaja-liczby-pod-kazdym-elementem-cyklu-automation.html",
"en": "https://www.getresponse.com/help/what-are-the-numbers-at-the-top-bottom-and-in-the-top-right-corner-of-each-element-in-my-workflow.html",
"de": "https://www.getresponse.com/de/hilfe/wofuer-stehen-die-zahlen-am-unteren-ende-jedes-elements-in-meinem-workflow.html",
"es": "https://www.getresponse.com/es/ayuda/que-son-los-numeros-debajo-de-cada-elemento-en-mi-flujo-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-oznachajut-tsyfry-v-kazhdom-elemente-protsessa.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-sao-os-numeros-em-cima-em-baixo-e-no-canto-superior-direito-de-cada-elemento-do-meu-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/a-cosa-si-riferiscono-i-numeri-in-cima-in-fondo-e-in-alto-a-destra-di-ciascun-elemento-nel-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifient-les-chiffres-en-haut-en-bas-et-dans-le-coin-superieur-droit-de-chaque-element-de-mon-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakMogeSledzicAktywnoscOdbiorcowBioracychUdzialWCyklu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-moge-sledzic-aktywnosc-odbiorcow-bioracych-udzial-w-cyklu.html",
"en": "https://www.getresponse.com/help/how-do-i-use-tags-to-see-who-started-or-finished-my-workflows.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-kontakte-in-einem-workflow-verfolgen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-utilizar-las-etiquetas-para-ver-quien-inicio-o-termino-mis-flujos-de-trabajo.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-mne-otslezhivat-kontakty-kotoryje-nahodatsa-v-protsessah.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-acompanhar-os-contatos-inclusos-em-um-fluxo-de-trabalho.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-usare-i-tag-per-vedere-chi-ha-iniziato-o-completato-i-miei-workflow.html",
"fr": "https://www.getresponse.com/fr/aide/comment-utiliser-les-tags-pour-savoir-qui-a-commence-ou-termine-mes-flux-de-travail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakDzialajaLacznikiTakNie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-dzialaja-laczniki-tak-nie.html",
"en": "https://www.getresponse.com/help/how-do-the-yes-no-connectors-work.html",
"de": "https://www.getresponse.com/de/hilfe/wie-funktionieren-die-ja-nein-verbindungsstellen.html",
"es": "https://www.getresponse.com/es/ayuda/como-funcionan-los-conectores-si-no.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-rabotajut-elementy-sojedinenija-da-net.html",
"br": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-funcionam-os-conectores-sim-nao.html",
"it": "https://www.getresponse.com/it/assistenza/come-funzionano-i-connettori-si-no.html",
"fr": "https://www.getresponse.com/fr/aide/comment-fonctionnent-les-connecteurs-oui-non.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCoToZnaczyJesliMojeKontoJestOgraniczone = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/co-to-znaczy-jesli-moje-konto-jest-ograniczone.html",
"en": "https://www.getresponse.com/help/what-does-it-mean-that-my-account-is-being-verified.html",
"de": "https://www.getresponse.com/de/hilfe/was-bedeutet-es-dass-mein-konto-ueberprueft-wird.html",
"es": "https://www.getresponse.com/es/ayuda/que-significa-que-mi-cuenta-esta-siendo-verificada.html",
"ru": "https://www.getresponse.com/ru/help-ru/moya-ychetnaya-zapis-ogranichena-i-chto-eto-znachit.html",
"it": "https://www.getresponse.com/it/assistenza/cosa-significa-che-il-mio-account-e-in-fase-di-verifica.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-significa-que-a-minha-conta-esta-sendo-verificada.html",
"fr": "https://www.getresponse.com/fr/aide/que-signifie-la-verification-de-mon-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostGdzieDowiemSieJakKontaktyZostalyDodaneDoKonkretnejListy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/gdzie-dowiem-sie-jak-kontakty-zostaly-dodane-do-konkretnej-listy.html",
"en": "https://www.getresponse.com/help/where-can-i-find-out-how-my-contacts-were-added-to-a-specific-list.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-heraus-wie-meine-kontakte-zu-einer-bestimmten-liste-hinzugefuegt-wurden.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-saber-como-fueron-anadidos-mis-contactos-a-una-lista-especifica.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-uznat-kakim-obrazom-moi-kontakty-byli-dobavleny-v-konkretnyj-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-descobrir-como-meus-contatos-entraram-em-uma-lista-especifica.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-scoprire-come-sono-stati-aggiunti-i-miei-contatti-a-una-lista-specifica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCzyMogeUmozliwicUczestnikomNaDolaczenieDoWebinaruBezposrednioZMojejStronyLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/czy-moge-umozliwic-uczestnikom-na-dolaczenie-do-webinaru-bezposrednio-z-mojej-strony-landing-page.html",
"en": "https://www.getresponse.com/help/can-i-enable-attendees-to-join-a-webinar-straight-from-my-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ein-teilnehmer-sich-direkt-auf-meiner-landing-page-fuer-ein-webinar-anmelden.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-permitir-que-los-asistentes-se-unan-a-un-webinar-directamente-desde-mi-landing-page.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogut-li-posetiteli-perehodit-s-lendinga-na-vebinar-napramuju.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-permitir-que-os-participantes-participem-de-um-webinar-diretamente-da-minha-landing-page.html",
"it": "https://www.getresponse.com/it/assistenza/posso-consentire-ai-partecipanti-di-partecipare-a-un-webinar-direttamente-dalla-mia-landing-page.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-permettre-aux-participants-de-s-inscrire-a-un-webinaire-directement-depuis-ma-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakPrzypisacWlasnaDomeneLubSubdomeneDoStarszejWersjiLandingPage = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-przypisac-wlasna-domene-lub-subdomene-do-starszej-wersji-landing-page.html",
"en": "https://www.getresponse.com/help/how-do-i-assign-my-own-domain-or-subdomain-to-a-legacy-landing-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-eigene-domain-oder-subdomain-fuer-meine-legacy-landing-page-nutzen.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-mi-propio-dominio-o-subdominio-a-una-landing-page-de-legado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-naznachit-lendingu-v-versii-legacy-moj-sobstvennyj-domen-ili-subdomen.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-assegnare-il-mio-dominio-o-sottodominio-a-una-landing-page.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-meu-proprio-dominio-ou-subdominio-a-uma-landing-page-legada.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-mon-propre-domaine-ou-sous-domaine-a-une-ancienne-page-de-destination.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostIluPrezenterowMozeUczestniczycWWebinarze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/ilu-prezenterow-moze-uczestniczyc-w-webinarze.html",
"en": "https://www.getresponse.com/help/can-there-be-more-presenters-than-just-me.html",
"de": "https://www.getresponse.com/de/hilfe/kann-es-mehr-moderatoren-als-nur-mich-geben.html",
"es": "https://www.getresponse.com/es/ayuda/puede-haber-mas-presentadores-que-solo-yo.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-dokladchikov-v-vebinar.html",
"br": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"pt": "https://www.getresponse.com/pt/ajuda/pode-haver-mais-apresentadores-do-que-apenas-eu.html",
"it": "https://www.getresponse.com/it/assistenza/possono-esserci-piu-presentatori-oltre-a-me.html",
"fr": "https://www.getresponse.com/fr/aide/peut-il-y-avoir-d-autres-presentateurs-que-moi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostJakSkonfigurowacWarunekPorzuconyKoszyk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-warunek-porzucony-koszyk.html",
"en": "https://www.getresponse.com/help/how-do-i-set-properties-for-the-abandoned-cart-condition.html",
"de": "https://www.getresponse.com/de/hilfe/wie-richte-ich-die-bedingung-abgebrochener-warenkorb-ein.html",
"es": "https://www.getresponse.com/es/ayuda/como-configuro-las-propiedades-de-la-condicion-de-carrito-abandonado.html",
"ru": "https://www.getresponse.com/ru/help-ru/kakn-astroit-svojstva-uslovija-brosil-korzinu.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-impostare-le-proprieta-per-la-condizione-carrello-abbandonato.html",
"br": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-eu-defino-as-propriedades-para-a-condicao-de-carrinho-abandonado.html",
"fr": "https://www.getresponse.com/fr/aide/comment-definir-les-proprietes-de-la-condition-panier-abandonne.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanISegmentMyContactsBasedOnEcommerceData = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-segment-my-contacts-based-on-ecommerce-data.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-segmentowac-kontakty-w-oparciu-o-dane-ecommerce.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-meine-kontakte-auf-basis-von-e-commerce-daten-segmentieren.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-segmentar-mis-contactos-en-funcion-de-los-datos-de-e-commerce.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-segmentirovat-kontakty-osnovyvajas-na-dannyh-ecommerce.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-segmentar-os-meus-contatos-com-base-em-dados-de-ecommerce.html",
"it": "https://www.getresponse.com/it/assistenza/posso-segmentare-i-contatti-in-base-ai-dati-di-e-commerce.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-segmenter-mes-contacts-en-fonction-des-donnees-relatives-a-l-e-commerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIDeleteDuplicatesFromMyLists = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-delete-duplicates-from-my-lists.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-duplikaty-z-moich-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-duplikate-aus-meinen-listen.html",
"es": "https://www.getresponse.com/es/ayuda/como-elimino-contactos-duplicados-de-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-dublikaty-kontaktov-iz-moih-spiskov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-duplicados-nas-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-eliminare-i-duplicati-dalle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-les-doublons-de-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanISeeWhyMyContactsHaveUnsubscribed = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-see-why-my-contacts-have-unsubscribed.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-zobaczyc-dlaczego-kontakty-wypisaly-sie-z-listy.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-sehen-warum-meine-kontakte-sich-abgemeldet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-ver-por-que-mis-contactos-se-han-dado-de-baja.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ja-uvidet-pochemu-moi-kontakty-otpisalis.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-ver-o-porque-os-meus-contatos-que-se-descadastraram.html",
"it": "https://www.getresponse.com/it/assistenza/posso-vedere-perche-i-miei-contatti-hanno-annullato-l-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-savoir-pourquoi-mes-contacts-se-sont-desabonnes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsEngagementScoreAndHowCanIUseIt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-engagement-score-and-how-can-i-use-it.html",
"pl": "https://www.getresponse.pl/pomoc/czym-jest-poziom-zaangazowania-i-jak-moge-go-wykorzystac.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-der-aktivitaetsindex-und-wie-verwende-ich-ihn.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-la-escala-de-compromiso-y-como-puedo-usarla.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-rejting-vovlechennosti-i-kak-ja-mogu-jego-ispolzovat.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-il-tasso-di-coinvolgimento-e-come-posso-usarlo.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-o-nivel-de-envolvimento-e-como-posso-usa-lo.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-que-le-score-d-engagement-et-comment-l-utiliser.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIFindContactsBySubscriptionMethod = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-find-contacts-by-subscription-method.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-kontakty-na-podstawie-zrodla-zapisu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-anhand-der-anmeldemethode-finden.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-encontrar-personas-por-metodo-de-suscripcion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty-po-sposobu-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-encontrar-pessoas-por-metodo-de-assinatura.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-trovare-i-contatti-in-base-al-metodo-di-iscrizione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-des-contacts-par-methode-d-inscription.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIFindPeopleWhoHavenTOpenedMyMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-find-people-who-haven-t-opened-my-messages.html",
"pl": "https://www.getresponse.pl/pomoc/jak-znalezc-osoby-ktore-nie-otwieraly-moich-wiadomosci.html",
"de": "https://www.getresponse.com/de/hilfe/wie-finde-ich-die-kontakte-die-meine-nachrichten-nicht-geoeffnet-haben.html",
"es": "https://www.getresponse.com/es/ayuda/como-encuentro-a-las-personas-que-no-han-abierto-mis-mensajes.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-najti-kontakty-kotoryje-ne-otkryvali-moi-pisma.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-coloro-che-non-hanno-aperto-i-miei-messaggi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-encontro-pessoas-que-nao-abriram-as-mensagens.html",
"fr": "https://www.getresponse.com/fr/aide/comment-trouver-les-personnes-qui-n-ont-pas-ouvert-mes-messages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIDeleteContactsFromAListOrAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-delete-contacts-from-a-list-or-account.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-kontakty-z-listy-lub-calego-konta.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-oder-einem-konto-loeschen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-contactos-de-una-lista-o-cuenta.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalat-kontakty-iz-spiska-ili-uchetnoj-zapisi.html",
"br": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-excluir-contatos-de-uma-lista-ou-da-conta.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-eliminare-i-contatti-da-una-lista-o-da-un-account.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-des-contacts-d-une-liste-ou-d-un-compte.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIExportContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-export-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-eksportowac-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-exportieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-exportar-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-eksportirovat-kontakty.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-esportare-i-contatti.html",
"br": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-exportar-contatos.html",
"fr": "https://www.getresponse.com/fr/aide/comment-exporter-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIManuallyRemoveContactsFromMyList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-manually-remove-contacts-from-my-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-recznie-usuwac-kontakty-z-mojej-listy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-manuell-aus-meiner-liste-entfernen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-manualmente-los-contactos-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-kontakty-iz-spiska-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-manualmente-remover-contatos-da-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-rimuovere-manualmente-i-contatti-dalla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-manuellement-des-contacts-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICopyOrMoveContactsFromOneListToAnother = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-copy-or-move-contacts-from-one-list-to-another.html",
"pl": "https://www.getresponse.pl/pomoc/jak-kopiowac-lub-przenosic-kontakty-z-jednej-listy-na-druga.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-aus-einer-liste-in-eine-andere-kopieren-oder-verschieben.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-copiar-o-mover-contactos-de-una-lista-a-otra.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kopirovat-ili-peremeshat-kontakty-iz-odnogo-spiska-v-drugoj.html",
"br": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-copiar-ou-mover-contatos-de-uma-lista-para-outra.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-copiare-o-spostare-i-contatti-da-una-lista-all-altra.html",
"fr": "https://www.getresponse.com/fr/aide/comment-copier-ou-deplacer-des-contacts-d-une-liste-a-une-autre.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIViewContactDetails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-view-contact-details.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-sprawdzic-szczegoly-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-kontaktdetails-einsehen.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-los-datos-del-contacto.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ja-mogu-posmotret-informaciju-o-kontakte.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-ver-os-dados-de-contato.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-i-dettagli-di-contatto.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-les-details-de-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindInactiveContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-inactive-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-kontakty-nieaktywne.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-inaktive-kontakte.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-contactos-inactivos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodjatsa-neaktivnyje-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-a-lista-de-contatos-inativos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-si-trovano-i-contatti-inattivi.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-trouver-les-contacts-inactifs.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIViewAllMyContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-view-all-my-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-liste-wszystkich-moich-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-eine-liste-mit-all-meinen-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-una-lista-de-todos-mis-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-najti-spisok-vseh-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-encontro-uma-lista-com-todos-os-meus-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-vedere-tutti-i-miei-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-voir-tous-mes-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoISearchForContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-search-for-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-wyszukiwac-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-suche-ich-nach-kontakten.html",
"es": "https://www.getresponse.com/es/ayuda/como-busco-los-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-iskat-kontakty.html",
"br": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-buscar-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-cercare-i-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-rechercher-des-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanContactsAddThemselvesToMyList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-contacts-add-themselves-to-my-list.html",
"pl": "https://www.getresponse.pl/pomoc/w-jaki-sposob-kontakty-moga-dodac-sie-do-mojej-listy.html",
"de": "https://www.getresponse.com/de/hilfe/wie-koennen-sich-kontakte-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-los-contactos-pueden-agregarse-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-kontakty-mogut-sami-podpisatsa-na-rassylki.html",
"br": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-os-contatos-podem-se-adicionar-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-possono-i-contatti-aggiungersi-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-les-contacts-peuvent-ils-s-ajouter-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddIndividualContactsDirectlyToMyList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-individual-contacts-directly-to-my-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodac-na-liste-pojedyncze-kontakty.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-einzelne-kontakte-direkt-in-meine-liste-eintragen.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-agregar-contactos-individuales-directamente-a-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-podpisat-kontakty-na-rassylku-vruchnuju.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-adicionar-contatos-individuais-diretamente-a-minha-lista.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-aggiungere-singoli-contatti-direttamente-alla-mia-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-individuels-directement-a-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIMigrateMyListFromActiveCampaignAweberConstantContactOrMailchimp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-migrate-my-list-from-active-campaign-aweber-constant-contact-or-mailchimp.html",
"pl": "https://www.getresponse.pl/pomoc/jak-moge-przeniesc-moja-liste-z-active-campaign-aweber-constant-contact-lub-mailchimp.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-migrar-mi-lista-de-active-campaign-aweber-constant-contact-o-mailchimp.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-perenesti-svoj-spisok-kontaktov.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-trasferire-la-mia-lista-da-active-campaign-aweber-constant-contact-o-mailchimp.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-migrar-minha-lista-do-active-campaign-aweber-constant-contact-ou-mailchimp.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-liste-von-active-campaign-aweber-constant-contact-oder-mailchimp-migrieren.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIAddRoleEmailAddressesToMyListOfContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-add-role-email-addresses-to-my-list-of-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/czy-moge-dodawac-adresy-grup-dystrybucyjnych-do-listy-odbiorcow.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-rollen-e-mailadressen-zu-meinen-kontaktlisten-hinzufuegen.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-anadir-direcciones-de-email-de-rol-a-mi-lista-de-suscriptores.html",
"ru": "https://www.getresponse.com/ru/help-ru/mozhno-li-dobavit-sluzhebnyje-adresa-v-spisok.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-adicionar-enderecos-de-funcao-a-minha-lista-de-assinantes.html",
"it": "https://www.getresponse.com/it/assistenza/posso-aggiungere-indirizzi-email-di-ruolo-alla-mia-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-ajouter-des-adresses-e-mail-basees-sur-un-role-a-ma-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIAssignCustomFieldsDuringImport = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-assign-custom-fields-during-import.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przypisywac-pola-dodatkowe-podczas-importu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-weise-ich-waehrend-eines-imports-benutzerdefinierte-felder-zu.html",
"es": "https://www.getresponse.com/es/ayuda/como-asigno-campos-personalizados-durante-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-prisvoit-nastraivajemyje-polja-pri-importe.html",
"br": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-atribuir-campos-customizados-durante-a-importacao.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-assegnare-campi-personalizzati-durante-l-importazione.html",
"fr": "https://www.getresponse.com/fr/aide/comment-attribuer-des-champs-personnalises-lors-de-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIPrepareAFileForImport = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-prepare-a-file-for-import.html",
"pl": "https://www.getresponse.pl/pomoc/jak-przygotowac-plik-do-importu.html",
"de": "https://www.getresponse.com/de/hilfe/wie-bereite-ich-eine-datei-fuer-den-import-vor.html",
"es": "https://www.getresponse.com/es/ayuda/como-preparo-un-archivo-para-la-importacion.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-podgotovit-fayl-dla-importa.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-preparare-un-file-per-l-importazione.html",
"br": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-preparar-um-arquivo-para-importacao.html",
"fr": "https://www.getresponse.com/fr/aide/comment-preparer-un-fichier-pour-l-importation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIImportAListOfContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-import-a-list-of-contacts.html",
"pl": "https://www.getresponse.pl/pomoc/jak-importowac-liste-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-eine-liste-mit-kontakten-importieren.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-importar-una-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-importirovat-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-importar-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-importare-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-importer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanIAddContactsToMyLists = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-add-contacts-to-my-lists.html",
"pl": "https://www.getresponse.pl/pomoc/jak-dodawac-kontakty-do-list.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-kontakte-zu-meinen-listen-hinzufugen.html",
"es": "https://www.getresponse.com/es/ayuda/como-anado-contactos-a-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-dobavlyat-kontakty-v-spiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-adicionar-contatos-as-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-ad-aggiungere-contatti-alle-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/comment-ajouter-des-contacts-a-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWieKannIchDieAbonnementEinstellungenMeinerListeBearbeiten = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-die-abonnement-einstellungen-meiner-liste-bearbeiten.html",
"pl": "https://www.getresponse.pl/pomoc/jak-edytowac-ustawienia-subskrypcji-na-moja-liste.html",
"en": "https://www.getresponse.com/help/how-can-i-edit-the-subscription-settings-for-my-list.html",
"es": "https://www.getresponse.com/es/ayuda/como-edito-los-ajustes-de-suscripcion-de-mi-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ya-mogu-izmenit-hastroyki-podpiski-dla-mojego-spiska.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-modificare-le-impostazioni-di-iscrizione-per-la-mia-lista.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-editar-as-configuracoes-de-assinatura-da-minha-lista.html",
"fr": "https://www.getresponse.com/fr/aide/comment-modifier-les-parametres-d-inscription-de-ma-liste.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhyHasnTMyCustomSubjectLineBeenApproved = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/why-hasn-t-my-custom-subject-line-been-approved.html",
"ru": "https://www.getresponse.com/ru/help-ru/pochemu-moja-sobstvennaya-tema-soobshenia-podtverzhdeniya-podpiski-ne-byla-odobrena.html",
"pl": "https://www.getresponse.pl/pomoc/dlaczego-moj-wlasny-temat-nie-zostal-zatwierdzony.html",
"it": "https://www.getresponse.com/it/assistenza/perche-la-mia-riga-dell-oggetto-personalizzata-non-e-stata-approvata.html",
"de": "https://www.getresponse.com/de/hilfe/warum-wurde-meine-benutzerdefinierte-betreffzeile-nicht-genehmigt.html",
"br": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"pt": "https://www.getresponse.com/pt/ajuda/por-que-minha-linha-de-assunto-personalizada-nao-foi-aprovada.html",
"es": "https://www.getresponse.com/es/ayuda/por-que-no-se-ha-aprobado-mi-linea-de-asunto-personalizada.html",
"fr": "https://www.getresponse.com/fr/aide/pourquoi-ma-ligne-objet-personnalisee-n-a-t-elle-pas-ete-approuvee.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostCanIChangeTheLanguageForMyListAndConfirmationMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/can-i-change-the-language-for-my-list-and-confirmation-message.html",
"pl": "https://www.getresponse.pl/pomoc/jak-zmienic-jezyk-listy-i-wiadomosci-potwierdzajacej.html",
"de": "https://www.getresponse.com/de/hilfe/kann-ich-die-sprache-fur-meine-liste-und-bestatigungsnachricht-andern.html",
"ru": "https://www.getresponse.com/ru/help-ru/mogu-li-ya-izmenit-yazyk-spiska-i-soobshenia-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/posso-alterar-o-idioma-da-minha-lista-e-mensagem-de-confirmacao.html",
"it": "https://www.getresponse.com/it/assistenza/posso-cambiare-la-lingua-per-la-mia-lista-e-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/puedo-cambiar-el-idioma-de-mi-lista-y-del-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/puis-je-changer-la-langue-de-ma-liste-et-du-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICustomizeMyConfirmationMessage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-customize-my-confirmation-message.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestaetigungsnachricht-anpassen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-ja-mogu-nastroit-swoje-pismo-podtverzhdenija-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-minha-mensagem-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-wiadomosc-potwierdzajaca-subskrypcje.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-il-mio-messaggio-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-mi-mensaje-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-mon-message-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowCanICustomizeTheConfirmationPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-can-i-customize-the-confirmation-page.html",
"de": "https://www.getresponse.com/de/hilfe/wie-kann-ich-meine-bestatigungsseite-anpassen.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-nastroit-stranitsu-podtverzhdenia-podpiski.html",
"br": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-posso-personalizar-a-pagina-de-confirmacao.html",
"pl": "https://www.getresponse.pl/pomoc/jak-skonfigurowac-strone-potwierdzajaca.html",
"it": "https://www.getresponse.com/it/assistenza/come-posso-personalizzare-la-pagina-di-conferma.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-personalizar-la-pagina-de-confirmacion.html",
"fr": "https://www.getresponse.com/fr/aide/comment-personnaliser-la-page-de-confirmation.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIFindMyListSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-find-my-list-settings.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-znalezc-ustawienia-mojej-listy.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-trovare-le-impostazioni-della-mia-lista.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-nahodyatsa-nastrojki-spiska.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-encontrar-los-ajustes-de-mi-lista-2.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-posso-encontrar-as-configuracoes-da-minha-lista.html",
"de": "https://www.getresponse.com/de/hilfe/wo-finde-ich-meine-listeneinstellungen.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoIRemoveAListOfContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-remove-a-list-of-contacts.html",
"de": "https://www.getresponse.com/de/hilfe/wie-entferne-ich-eine-kontaktliste.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-udalit-spisok-kontaktov.html",
"es": "https://www.getresponse.com/es/ayuda/como-puedo-eliminar-una-lista-de-contactos.html",
"pl": "https://www.getresponse.pl/pomoc/jak-usunac-liste-kontaktow.html",
"br": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-faco-para-remover-uma-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-rimuovere-una-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-supprimer-une-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhereCanIViewAndManageMyLists = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/where-can-i-view-and-manage-my-lists.html",
"pl": "https://www.getresponse.pl/pomoc/gdzie-moge-wyswietlac-moje-listy-i-zarzadzac-nimi.html",
"de": "https://www.getresponse.com/de/hilfe/wo-kann-ich-meine-listen-finden-und-die-einstellungen-bearbeiten.html",
"es": "https://www.getresponse.com/es/ayuda/donde-puedo-ver-y-administrar-mis-listas.html",
"ru": "https://www.getresponse.com/ru/help-ru/gde-ya-mogu-prosmatrivat-svoi-spiski-i-upravlyat-imi.html",
"br": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"pt": "https://www.getresponse.com/pt/ajuda/onde-ver-e-gerenciar-minhas-listas.html",
"it": "https://www.getresponse.com/it/assistenza/dove-posso-visualizzare-e-gestire-le-mie-liste.html",
"fr": "https://www.getresponse.com/fr/aide/ou-puis-je-consulter-et-gerer-mes-listes.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostHowDoICreateANewContactList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/how-do-i-create-a-new-contact-list.html",
"pl": "https://www.getresponse.pl/pomoc/jak-stworzyc-nowa-liste-kontaktow.html",
"de": "https://www.getresponse.com/de/hilfe/wie-erstelle-ich-eine-neue-kontaktliste.html",
"es": "https://www.getresponse.com/es/ayuda/como-creo-una-nueva-lista-de-contactos.html",
"ru": "https://www.getresponse.com/ru/help-ru/kak-sozdat-novyj-spisok-kontaktov.html",
"br": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"pt": "https://www.getresponse.com/pt/ajuda/como-criar-uma-nova-lista-de-contatos.html",
"it": "https://www.getresponse.com/it/assistenza/come-faccio-a-creare-una-nuova-lista-di-contatti.html",
"fr": "https://www.getresponse.com/fr/aide/comment-creer-une-nouvelle-liste-de-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpPostWhatIsConversionFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/what-is-conversion-funnel.html",
"pl": "https://www.getresponse.pl/pomoc/co-to-jest-lejek-konwersji.html",
"de": "https://www.getresponse.com/de/hilfe/was-ist-conversion-funnel.html",
"es": "https://www.getresponse.com/es/ayuda/que-es-el-embudo-de-conversion.html",
"ru": "https://www.getresponse.com/ru/help-ru/chto-takoje-voronka-konversii.html",
"br": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"pt": "https://www.getresponse.com/pt/ajuda/o-que-e-um-funil-de-conversao.html",
"it": "https://www.getresponse.com/it/assistenza/che-cos-e-conversion-funnel.html",
"fr": "https://www.getresponse.com/fr/aide/qu-est-ce-qu-un-funnel-de-conversion.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryABTest = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAbTests = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryABTests = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAbandonedCart = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAbgebrochenerWarenkorb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAbonnementKuendigen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAcciones = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/actions",
"de": "https://www.getresponse.com/de/hilfe/aktionen",
"es": "https://www.getresponse.com/es/ayuda/acciones",
"pl": "https://www.getresponse.pl/pomoc/akcje",
"br": "https://www.getresponse.com/pt/ajuda/acoes",
"pt": "https://www.getresponse.com/pt/ajuda/acoes",
"ru": "https://www.getresponse.com/ru/help-ru/deystviya",
"it": "https://www.getresponse.com/it/assistenza/azioni",
"fr": "https://www.getresponse.com/fr/aide/actions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAcoes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/actions",
"de": "https://www.getresponse.com/de/hilfe/aktionen",
"es": "https://www.getresponse.com/es/ayuda/acciones",
"pl": "https://www.getresponse.pl/pomoc/akcje",
"br": "https://www.getresponse.com/pt/ajuda/acoes",
"pt": "https://www.getresponse.com/pt/ajuda/acoes",
"ru": "https://www.getresponse.com/ru/help-ru/deystviya",
"it": "https://www.getresponse.com/it/assistenza/azioni",
"fr": "https://www.getresponse.com/fr/aide/actions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryActions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/actions",
"de": "https://www.getresponse.com/de/hilfe/aktionen",
"es": "https://www.getresponse.com/es/ayuda/acciones",
"pl": "https://www.getresponse.pl/pomoc/akcje",
"br": "https://www.getresponse.com/pt/ajuda/acoes",
"pt": "https://www.getresponse.com/pt/ajuda/acoes",
"ru": "https://www.getresponse.com/ru/help-ru/deystviya",
"it": "https://www.getresponse.com/it/assistenza/azioni",
"fr": "https://www.getresponse.com/fr/aide/actions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAddingContactsYourself = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAdicionandoContatosPorSiProprio = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAdjustingYourAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAdministracionDeLaSalaDeWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAdministrarCuenta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAffiliateMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-marketing",
"de": "https://www.getresponse.com/de/hilfe/affiliate-marketing",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/marketing-afiliacyjny",
"br": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskij-marketing",
"it": "https://www.getresponse.com/it/assistenza/affiliate-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAffiliateProgrammeAffiliateProgramme = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-programs",
"de": "https://www.getresponse.com/de/hilfe/affiliate-programme-affiliate-programme",
"es": "https://www.getresponse.com/es/ayuda/programas-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/programy-partnerskie-programy-partnerskie",
"ru": "https://www.getresponse.com/ru/help-ru/partnerstkie-programmy",
"it": "https://www.getresponse.com/it/assistenza/programmi-di-affiliazione",
"fr": "https://www.getresponse.com/fr/aide/programmes-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAffiliatePrograms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-programs",
"de": "https://www.getresponse.com/de/hilfe/affiliate-programme-affiliate-programme",
"es": "https://www.getresponse.com/es/ayuda/programas-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/programy-partnerskie-programy-partnerskie",
"ru": "https://www.getresponse.com/ru/help-ru/partnerstkie-programmy",
"it": "https://www.getresponse.com/it/assistenza/programmi-di-affiliazione",
"fr": "https://www.getresponse.com/fr/aide/programmes-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAffiliateProgramme = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAggiungereContatti = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAiCampaigns = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ai-campaigns",
"de": "https://www.getresponse.com/de/hilfe/ki-kampahnen",
"es": "https://www.getresponse.com/es/ayuda/campanas-de-ia",
"pl": "https://www.getresponse.pl/pomoc/kampanie-ai",
"br": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"pt": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"ru": "https://www.getresponse.com/ru/help-ru/ii-kampanii",
"it": "https://www.getresponse.com/it/assistenza/campagne-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAjouterDesContactsVousMeme = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAjustandoAsAutorrespostas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAjusteTusAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAjustesDelNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAkcje = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/actions",
"de": "https://www.getresponse.com/de/hilfe/aktionen",
"es": "https://www.getresponse.com/es/ayuda/acciones",
"pl": "https://www.getresponse.pl/pomoc/akcje",
"br": "https://www.getresponse.com/pt/ajuda/acoes",
"pt": "https://www.getresponse.com/pt/ajuda/acoes",
"ru": "https://www.getresponse.com/ru/help-ru/deystviya",
"it": "https://www.getresponse.com/it/assistenza/azioni",
"fr": "https://www.getresponse.com/fr/aide/actions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAktionen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/actions",
"de": "https://www.getresponse.com/de/hilfe/aktionen",
"es": "https://www.getresponse.com/es/ayuda/acciones",
"pl": "https://www.getresponse.pl/pomoc/akcje",
"br": "https://www.getresponse.com/pt/ajuda/acoes",
"pt": "https://www.getresponse.com/pt/ajuda/acoes",
"ru": "https://www.getresponse.com/ru/help-ru/deystviya",
"it": "https://www.getresponse.com/it/assistenza/azioni",
"fr": "https://www.getresponse.com/fr/aide/actions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAlimentadoresDeProdutos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnadiendoContactosTuMismo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalisesDeFormulariosEPopUps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalisiDiModuliEPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalisisDeFormulariosYVentanasEmergentes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnaliticaERemarketingWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing-website-builder",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing-website-builder",
"es": "https://www.getresponse.com/es/ayuda/analytics-and-remarketing-website-builder",
"pl": "https://www.getresponse.pl/pomoc/analiza-i-re-marketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-konstryktor-saitov",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing-website-builder",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnaliticaERemarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnaliticaYRemarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalyticsAndRemarketingWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing-website-builder",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing-website-builder",
"es": "https://www.getresponse.com/es/ayuda/analytics-and-remarketing-website-builder",
"pl": "https://www.getresponse.pl/pomoc/analiza-i-re-marketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-konstryktor-saitov",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing-website-builder",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalitykaIRemarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalizaIReMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing-website-builder",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing-website-builder",
"es": "https://www.getresponse.com/es/ayuda/analytics-and-remarketing-website-builder",
"pl": "https://www.getresponse.pl/pomoc/analiza-i-re-marketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-konstryktor-saitov",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing-website-builder",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalyseDesFormulairesEtDesPopUps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalyticsAndRemarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalyticsERemarketingWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing-website-builder",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing-website-builder",
"es": "https://www.getresponse.com/es/ayuda/analytics-and-remarketing-website-builder",
"pl": "https://www.getresponse.pl/pomoc/analiza-i-re-marketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-konstryktor-saitov",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing-website-builder",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalyticsERemarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalyticsEtRemarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalyticsEtRemarketingCreateurDeSiteInternet = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing-website-builder",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing-website-builder",
"es": "https://www.getresponse.com/es/ayuda/analytics-and-remarketing-website-builder",
"pl": "https://www.getresponse.pl/pomoc/analiza-i-re-marketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-konstryktor-saitov",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing-website-builder",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalytikUndRemarketingWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing-website-builder",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing-website-builder",
"es": "https://www.getresponse.com/es/ayuda/analytics-and-remarketing-website-builder",
"pl": "https://www.getresponse.pl/pomoc/analiza-i-re-marketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-konstryktor-saitov",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing-website-builder",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalytikUndRemarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAncienEditeurDeMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnnoncesFacebook = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/facebook-ads",
"de": "https://www.getresponse.com/de/hilfe/facebook-ads-facebook-ads",
"es": "https://www.getresponse.com/es/ayuda/facebook-ads",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"ru": "https://www.getresponse.com/ru/help-ru/reklama-v-facebook-reklama-v-facebook",
"it": "https://www.getresponse.com/it/assistenza/facebook-ads-facebook-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnnoncesGoogle = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/google-ads",
"de": "https://www.getresponse.com/de/hilfe/google-ads",
"es": "https://www.getresponse.com/es/ayuda/google-ads",
"pl": "https://www.getresponse.pl/pomoc/google-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"ru": "https://www.getresponse.com/ru/help-ru/google-ads",
"it": "https://www.getresponse.com/it/assistenza/google-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-google"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnnulationDUnAbonnement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnpassenIhrerAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnulowanieSubskrypcji = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnunciosDoFacebook = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/facebook-ads",
"de": "https://www.getresponse.com/de/hilfe/facebook-ads-facebook-ads",
"es": "https://www.getresponse.com/es/ayuda/facebook-ads",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"ru": "https://www.getresponse.com/ru/help-ru/reklama-v-facebook-reklama-v-facebook",
"it": "https://www.getresponse.com/it/assistenza/facebook-ads-facebook-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnunciosDoGoogle = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/google-ads",
"de": "https://www.getresponse.com/de/hilfe/google-ads",
"es": "https://www.getresponse.com/es/ayuda/google-ads",
"pl": "https://www.getresponse.pl/pomoc/google-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"ru": "https://www.getresponse.com/ru/help-ru/google-ads",
"it": "https://www.getresponse.com/it/assistenza/google-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-google"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAplicacionMovilGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-mobile-app",
"de": "https://www.getresponse.com/de/hilfe/getresponse-mobile-app",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil-getresponse",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"pt": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"ru": "https://www.getresponse.com/ru/help-ru/mobilnyje-prilozhenie-getresponse",
"it": "https://www.getresponse.com/it/assistenza/getresponse-mobile-app",
"fr": "https://www.getresponse.com/fr/aide/application-mobile-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAplicativoMovelDaGetrsponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-mobile-app",
"de": "https://www.getresponse.com/de/hilfe/getresponse-mobile-app",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil-getresponse",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"pt": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"ru": "https://www.getresponse.com/ru/help-ru/mobilnyje-prilozhenie-getresponse",
"it": "https://www.getresponse.com/it/assistenza/getresponse-mobile-app",
"fr": "https://www.getresponse.com/fr/aide/application-mobile-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAplikacjaMobilnaGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-mobile-app",
"de": "https://www.getresponse.com/de/hilfe/getresponse-mobile-app",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil-getresponse",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"pt": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"ru": "https://www.getresponse.com/ru/help-ru/mobilnyje-prilozhenie-getresponse",
"it": "https://www.getresponse.com/it/assistenza/getresponse-mobile-app",
"fr": "https://www.getresponse.com/fr/aide/application-mobile-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryApplicationMobileGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-mobile-app",
"de": "https://www.getresponse.com/de/hilfe/getresponse-mobile-app",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil-getresponse",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"pt": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"ru": "https://www.getresponse.com/ru/help-ru/mobilnyje-prilozhenie-getresponse",
"it": "https://www.getresponse.com/it/assistenza/getresponse-mobile-app",
"fr": "https://www.getresponse.com/fr/aide/application-mobile-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryArbeitenMitElementen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryArbeitenMitVorlagen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryArchivosEImagenes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryArquivosEImagens = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutorepondeurs = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/autorrespostas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutomation = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion",
"br": "https://www.getresponse.com/pt/ajuda/automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/automatizacao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutomatisation = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion",
"br": "https://www.getresponse.com/pt/ajuda/automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/automatizacao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutomatizacao = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion",
"br": "https://www.getresponse.com/pt/ajuda/automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/automatizacao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutomatizacion = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion",
"br": "https://www.getresponse.com/pt/ajuda/automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/automatizacao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutoresponder = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/autorrespostas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutoresponders = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/autorrespostas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutorespondery = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/autorrespostas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAutorrespostas = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/autorrespostas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAzioni = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/actions",
"de": "https://www.getresponse.com/de/hilfe/aktionen",
"es": "https://www.getresponse.com/es/ayuda/acciones",
"pl": "https://www.getresponse.pl/pomoc/akcje",
"br": "https://www.getresponse.com/pt/ajuda/acoes",
"pt": "https://www.getresponse.com/pt/ajuda/acoes",
"ru": "https://www.getresponse.com/ru/help-ru/deystviya",
"it": "https://www.getresponse.com/it/assistenza/azioni",
"fr": "https://www.getresponse.com/fr/aide/actions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBedingungen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBegrusung = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBenutzerdefinierteDomainsFuerLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-domains-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierte-domains-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/dominios-personalizados-para-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/wlasne-domeny-dla-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-sobstvennyh-domenov-dlja-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/domini-personalizzati-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBenutzerdefiniertenFelder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBenvenuto = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBienvenida = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBienvenue = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBoasVindas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBorradores = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drafts",
"de": "https://www.getresponse.com/de/hilfe/entwuerfe",
"es": "https://www.getresponse.com/es/ayuda/borradores",
"pl": "https://www.getresponse.pl/pomoc/wersje-robocze",
"br": "https://www.getresponse.com/pt/ajuda/rascunhos",
"pt": "https://www.getresponse.com/pt/ajuda/rascunhos",
"ru": "https://www.getresponse.com/ru/help-ru/chernoviki",
"it": "https://www.getresponse.com/it/assistenza/bozze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBozze = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drafts",
"de": "https://www.getresponse.com/de/hilfe/entwuerfe",
"es": "https://www.getresponse.com/es/ayuda/borradores",
"pl": "https://www.getresponse.pl/pomoc/wersje-robocze",
"br": "https://www.getresponse.com/pt/ajuda/rascunhos",
"pt": "https://www.getresponse.com/pt/ajuda/rascunhos",
"ru": "https://www.getresponse.com/ru/help-ru/chernoviki",
"it": "https://www.getresponse.com/it/assistenza/bozze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBuscarContactos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCalificacionDelClientePotencial = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCampagneAi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ai-campaigns",
"de": "https://www.getresponse.com/de/hilfe/ki-kampahnen",
"es": "https://www.getresponse.com/es/ayuda/campanas-de-ia",
"pl": "https://www.getresponse.pl/pomoc/kampanie-ai",
"br": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"pt": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"ru": "https://www.getresponse.com/ru/help-ru/ii-kampanii",
"it": "https://www.getresponse.com/it/assistenza/campagne-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCampanasDeIa = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ai-campaigns",
"de": "https://www.getresponse.com/de/hilfe/ki-kampahnen",
"es": "https://www.getresponse.com/es/ayuda/campanas-de-ia",
"pl": "https://www.getresponse.pl/pomoc/kampanie-ai",
"br": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"pt": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"ru": "https://www.getresponse.com/ru/help-ru/ii-kampanii",
"it": "https://www.getresponse.com/it/assistenza/campagne-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCampanhasDeIa = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ai-campaigns",
"de": "https://www.getresponse.com/de/hilfe/ki-kampahnen",
"es": "https://www.getresponse.com/es/ayuda/campanas-de-ia",
"pl": "https://www.getresponse.pl/pomoc/kampanie-ai",
"br": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"pt": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"ru": "https://www.getresponse.com/ru/help-ru/ii-kampanii",
"it": "https://www.getresponse.com/it/assistenza/campagne-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCampiDelConsenso = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCampiPersonalizzatiListe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCamposCostumizados = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCamposDeConsentimento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCamposDeConsentimiento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCamposPersonalizados = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCancelacionDeUnaSuscripcion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCancelamentoDaAssinatura = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCancellazioneDellaSottoscrizione = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCancellingASubscription = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCarrelloAbbandonato = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCarrinhoAbandonado = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCarritoAbandonado = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCennikIPlatnosci = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCercaContatti = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryChampsDeConsentement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryChampsPersonnalises = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryChats = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/chats",
"de": "https://www.getresponse.com/de/hilfe/chats",
"es": "https://www.getresponse.com/es/ayuda/chats",
"pl": "https://www.getresponse.pl/pomoc/czaty",
"br": "https://www.getresponse.com/pt/ajuda/chats",
"pt": "https://www.getresponse.com/pt/ajuda/chats",
"ru": "https://www.getresponse.com/ru/help-ru/chats",
"it": "https://www.getresponse.com/it/assistenza/chats",
"fr": "https://www.getresponse.com/fr/aide/chats"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComecandoComGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComecandoComOWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComecandoComOsWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComecarComOsCursos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-courses",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-kursen",
"es": "https://www.getresponse.com/es/ayuda/empezar-con-los-cursos",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kursami",
"br": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"it": "https://www.getresponse.com/it/assistenza/iniziare-con-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/demarrer-avec-les-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCominciandoConIlWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoEmpezarConLosWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoGerenciarASuaConta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoTrabalharComAAutomatizacao = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoTrabalharComAsAutorrespostas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoTrabalharComAsIntegracoes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoTrabalharComAsNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoTrabalharComLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoTrabalharComOsFunisDeConversao = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComoTrabalharComOsRelatorios = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComplianceAndAccountReputation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryComplianceUndReputationDesKontos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCompromisoYRetencion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCondiciones = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCondicoes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConditionsFr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConditions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCondizioni = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConfiguracaoDeLista = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConfiguracoesDeNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConformidadeEReputacaoDaConta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConformitaEReputazioneDellAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConformiteEtReputationDuCompte = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConsentFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryConversionFunnel = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/funil-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-conversao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCorreosElectronicosTransaccionales = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/correos-electronicos-transaccionales"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCorsiOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCoursEnLigne = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCoursesEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/courses-editor",
"de": "https://www.getresponse.com/de/hilfe/kurseditor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-cursos",
"pl": "https://www.getresponse.pl/pomoc/edytor-kursow",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-kursov",
"it": "https://www.getresponse.com/it/assistenza/editor-di-corsi",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCreateurDeSiteInternet = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/website-builder",
"pl": "https://www.getresponse.pl/pomoc/kreator-stron"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCreatorProfil = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCreatorsProfile = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCreazioneDelleListe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCreerUneListe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCumplimientoYReputacionDeLaCuenta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCursosOnLine = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCursosOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCustomDomainsForLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-domains-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierte-domains-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/dominios-personalizados-para-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/wlasne-domeny-dla-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-sobstvennyh-domenov-dlja-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/domini-personalizzati-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCustomFields = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryCzaty = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/chats",
"de": "https://www.getresponse.com/de/hilfe/chats",
"es": "https://www.getresponse.com/es/ayuda/chats",
"pl": "https://www.getresponse.pl/pomoc/czaty",
"br": "https://www.getresponse.com/pt/ajuda/chats",
"pt": "https://www.getresponse.com/pt/ajuda/chats",
"ru": "https://www.getresponse.com/ru/help-ru/chats",
"it": "https://www.getresponse.com/it/assistenza/chats",
"fr": "https://www.getresponse.com/fr/aide/chats"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDateienUndBilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDeliverability = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/deliverability",
"de": "https://www.getresponse.com/de/hilfe/zustellbarkeit",
"es": "https://www.getresponse.com/es/ayuda/entregabilidad",
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc",
"br": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"pt": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"ru": "https://www.getresponse.com/ru/help-ru/dostavlyaemost",
"it": "https://www.getresponse.com/it/assistenza/deliverability",
"fr": "https://www.getresponse.com/fr/aide/delivrabilite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDelivrabilite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/deliverability",
"de": "https://www.getresponse.com/de/hilfe/zustellbarkeit",
"es": "https://www.getresponse.com/es/ayuda/entregabilidad",
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc",
"br": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"pt": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"ru": "https://www.getresponse.com/ru/help-ru/dostavlyaemost",
"it": "https://www.getresponse.com/it/assistenza/deliverability",
"fr": "https://www.getresponse.com/fr/aide/delivrabilite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDemarrerAvecLesCours = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-courses",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-kursen",
"es": "https://www.getresponse.com/es/ayuda/empezar-con-los-cursos",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kursami",
"br": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"it": "https://www.getresponse.com/it/assistenza/iniziare-con-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/demarrer-avec-les-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDepannageEtCorrectionDesWorkflows = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDepannagePourLesAutorepondeurs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDepannagePourLesLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/depannage-pour-les-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDepannagePourLesNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDepannagePourLesStatistiques = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDomainesPersonnalisesPourPagesDeDestination = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domaines-personnalises-pour-pages-de-destination"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDomainesPourSitesWeb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDomainsForWebsites = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDomainsFurWebseiten = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDomenyStron = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDominiPerISitiWeb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDominiPersonalizzatiDelleLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-domains-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierte-domains-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/dominios-personalizados-para-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/wlasne-domeny-dla-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-sobstvennyh-domenov-dlja-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/domini-personalizzati-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDominiosParaSites = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDominiosParaSitiosWeb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDominiosPersonalizadosParaLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-domains-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierte-domains-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/dominios-personalizados-para-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/wlasne-domeny-dla-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-sobstvennyh-domenov-dlja-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/domini-personalizzati-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDostarczalnosc = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/deliverability",
"de": "https://www.getresponse.com/de/hilfe/zustellbarkeit",
"es": "https://www.getresponse.com/es/ayuda/entregabilidad",
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc",
"br": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"pt": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"ru": "https://www.getresponse.com/ru/help-ru/dostavlyaemost",
"it": "https://www.getresponse.com/it/assistenza/deliverability",
"fr": "https://www.getresponse.com/fr/aide/delivrabilite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDostosowywanieAutoresponderow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDrafts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drafts",
"de": "https://www.getresponse.com/de/hilfe/entwuerfe",
"es": "https://www.getresponse.com/es/ayuda/borradores",
"pl": "https://www.getresponse.pl/pomoc/wersje-robocze",
"br": "https://www.getresponse.com/pt/ajuda/rascunhos",
"pt": "https://www.getresponse.com/pt/ajuda/rascunhos",
"ru": "https://www.getresponse.com/ru/help-ru/chernoviki",
"it": "https://www.getresponse.com/it/assistenza/bozze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEcommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryECommerce = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryECommerceTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEMailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/email-marketing",
"de": "https://www.getresponse.com/de/hilfe/e-mail-marketing",
"es": "https://www.getresponse.com/es/ayuda/email-marketing",
"pl": "https://www.getresponse.pl/pomoc/email-marketing",
"br": "https://www.getresponse.com/pt/ajuda/e-mail-marketing",
"pt": "https://www.getresponse.com/pt/ajuda/e-mail-marketing",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing",
"it": "https://www.getresponse.com/it/assistenza/email-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEMailTransazionali = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/transactional-emails",
"pl": "https://www.getresponse.pl/pomoc/emaile-transakcyjne",
"ru": "https://www.getresponse.com/ru/help-ru/tranzakcionnye-pisma",
"it": "https://www.getresponse.com/it/assistenza/e-mail-transazionali",
"fr": "https://www.getresponse.com/fr/aide/e-mails-transactionnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEMailsEDominios = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEMailsEtDomaines = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEMailsTransactionnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/transactional-emails",
"pl": "https://www.getresponse.pl/pomoc/emaile-transakcyjne",
"ru": "https://www.getresponse.com/ru/help-ru/tranzakcionnye-pisma",
"it": "https://www.getresponse.com/it/assistenza/e-mail-transazionali",
"fr": "https://www.getresponse.com/fr/aide/e-mails-transactionnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEMailsUndDomains = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEcommerceTools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditeurDeCours = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/courses-editor",
"de": "https://www.getresponse.com/de/hilfe/kurseditor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-cursos",
"pl": "https://www.getresponse.pl/pomoc/edytor-kursow",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-kursov",
"it": "https://www.getresponse.com/it/assistenza/editor-di-corsi",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditeurDeCreationDeSitesWeb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-builder-editor",
"de": "https://www.getresponse.com/de/hilfe/website-builder-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-del-website-builder",
"pl": "https://www.getresponse.pl/pomoc/edytor-kreatora-stron",
"br": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"pt": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-konstrykora-saitov",
"it": "https://www.getresponse.com/it/assistenza/editor-del-website-builder",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-creation-de-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditeurDePagesDeDestination = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/editeur-de-pages-de-destination"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditeurDeMessages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDeCursos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/courses-editor",
"de": "https://www.getresponse.com/de/hilfe/kurseditor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-cursos",
"pl": "https://www.getresponse.pl/pomoc/edytor-kursow",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-kursov",
"it": "https://www.getresponse.com/it/assistenza/editor-di-corsi",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDeLaLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/landing-page-editor",
"de": "https://www.getresponse.com/de/hilfe/landing-page-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-la-landing-page",
"pl": "https://www.getresponse.pl/pomoc/edytor-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/l-editor-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDeLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/landing-page-editor",
"de": "https://www.getresponse.com/de/hilfe/landing-page-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-la-landing-page",
"pl": "https://www.getresponse.pl/pomoc/edytor-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/l-editor-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDeMensagem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDeMensagemAntigo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDeMensajes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDeMensajesDelLegado = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDelWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-builder-editor",
"de": "https://www.getresponse.com/de/hilfe/website-builder-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-del-website-builder",
"pl": "https://www.getresponse.pl/pomoc/edytor-kreatora-stron",
"br": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"pt": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-konstrykora-saitov",
"it": "https://www.getresponse.com/it/assistenza/editor-del-website-builder",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-creation-de-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEditorDiCorsi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/courses-editor",
"de": "https://www.getresponse.com/de/hilfe/kurseditor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-cursos",
"pl": "https://www.getresponse.pl/pomoc/edytor-kursow",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-kursov",
"it": "https://www.getresponse.com/it/assistenza/editor-di-corsi",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEdytorKreatoraStron = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-builder-editor",
"de": "https://www.getresponse.com/de/hilfe/website-builder-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-del-website-builder",
"pl": "https://www.getresponse.pl/pomoc/edytor-kreatora-stron",
"br": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"pt": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-konstrykora-saitov",
"it": "https://www.getresponse.com/it/assistenza/editor-del-website-builder",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-creation-de-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEdytorKursow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/courses-editor",
"de": "https://www.getresponse.com/de/hilfe/kurseditor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-cursos",
"pl": "https://www.getresponse.pl/pomoc/edytor-kursow",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-kursov",
"it": "https://www.getresponse.com/it/assistenza/editor-di-corsi",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEdytorLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/landing-page-editor",
"de": "https://www.getresponse.com/de/hilfe/landing-page-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-la-landing-page",
"pl": "https://www.getresponse.pl/pomoc/edytor-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/l-editor-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEdytorWiadomosci = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEdytorWiadomoscLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEinbindungUndRetention = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEinwilligungsfelder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmailEDomini = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmailMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/email-marketing",
"de": "https://www.getresponse.com/de/hilfe/e-mail-marketing",
"es": "https://www.getresponse.com/es/ayuda/email-marketing",
"pl": "https://www.getresponse.pl/pomoc/email-marketing",
"br": "https://www.getresponse.com/pt/ajuda/e-mail-marketing",
"pt": "https://www.getresponse.com/pt/ajuda/e-mail-marketing",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing",
"it": "https://www.getresponse.com/it/assistenza/email-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmaileIDomeny = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmaileTransakcyjne = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/transactional-emails",
"pl": "https://www.getresponse.pl/pomoc/emaile-transakcyjne",
"ru": "https://www.getresponse.com/ru/help-ru/tranzakcionnye-pisma",
"it": "https://www.getresponse.com/it/assistenza/e-mail-transazionali",
"fr": "https://www.getresponse.com/fr/aide/e-mails-transactionnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmailsAndDomains = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmailsTransacionais = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/emails-transacionais",
"pt": "https://www.getresponse.com/pt/ajuda/emails-transacionais"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmailsYDominios = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmbudoDeConstruccionDeLaLista = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-building-funnel",
"de": "https://www.getresponse.com/de/hilfe/lead-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-construccion-de-la-lista",
"pl": "https://www.getresponse.pl/pomoc/lejek-do-budowania-listy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-rasshirenija-bazy",
"it": "https://www.getresponse.com/it/assistenza/list-building-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-construction-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmbudoDeConversion = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/funil-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-conversao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmbudoDeVentas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-funnel",
"de": "https://www.getresponse.com/de/hilfe/sales-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/lejek-sprzedazowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodazhy",
"it": "https://www.getresponse.com/it/assistenza/sales-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-vente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmbudoDeWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinar-funnel",
"de": "https://www.getresponse.com/de/hilfe/webinar-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-webinar",
"pl": "https://www.getresponse.pl/pomoc/lejek-webinarowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodvizhenija-vebinara",
"it": "https://www.getresponse.com/it/assistenza/webinar-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConElEmbudoDeConversion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConElWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConLaAutomatizacionDelMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConLaGestionDeLaCuenta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConLasIntegraciones = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConLasLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConLosAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConLosInformes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezandoConLosNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEmpezarConLosCursos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-courses",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-kursen",
"es": "https://www.getresponse.com/es/ayuda/empezar-con-los-cursos",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kursami",
"br": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"it": "https://www.getresponse.com/it/assistenza/iniziare-con-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/demarrer-avec-les-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListeErstellen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEngagementAndRetention = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEngagementEtRetention = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEnregistrements = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/recordings",
"es": "https://www.getresponse.com/es/ayuda/grabaciones",
"pl": "https://www.getresponse.pl/pomoc/nagrania",
"br": "https://www.getresponse.com/pt/ajuda/gravacoes",
"pt": "https://www.getresponse.com/pt/ajuda/gravacoes",
"ru": "https://www.getresponse.com/ru/help-ru/zapisi-vebinarov",
"it": "https://www.getresponse.com/it/assistenza/registrazioni",
"fr": "https://www.getresponse.com/fr/aide/enregistrements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEntregabilidad = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/deliverability",
"de": "https://www.getresponse.com/de/hilfe/zustellbarkeit",
"es": "https://www.getresponse.com/es/ayuda/entregabilidad",
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc",
"br": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"pt": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"ru": "https://www.getresponse.com/ru/help-ru/dostavlyaemost",
"it": "https://www.getresponse.com/it/assistenza/deliverability",
"fr": "https://www.getresponse.com/fr/aide/delivrabilite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEntregabilidade = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/deliverability",
"de": "https://www.getresponse.com/de/hilfe/zustellbarkeit",
"es": "https://www.getresponse.com/es/ayuda/entregabilidad",
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc",
"br": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"pt": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"ru": "https://www.getresponse.com/ru/help-ru/dostavlyaemost",
"it": "https://www.getresponse.com/it/assistenza/deliverability",
"fr": "https://www.getresponse.com/fr/aide/delivrabilite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEntretienDeListe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEntwuerfe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drafts",
"de": "https://www.getresponse.com/de/hilfe/entwuerfe",
"es": "https://www.getresponse.com/es/ayuda/borradores",
"pl": "https://www.getresponse.pl/pomoc/wersje-robocze",
"br": "https://www.getresponse.com/pt/ajuda/rascunhos",
"pt": "https://www.getresponse.com/pt/ajuda/rascunhos",
"ru": "https://www.getresponse.com/ru/help-ru/chernoviki",
"it": "https://www.getresponse.com/it/assistenza/bozze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEnvolvimentoERetencao = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitAutorespondern = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitConversionFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitDemWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitDerKontoverwaltung = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitDerMarketingautomatisierung = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitFormularenUndPopups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitIntegrationen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitKursen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-courses",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-kursen",
"es": "https://www.getresponse.com/es/ayuda/empezar-con-los-cursos",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kursami",
"br": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"it": "https://www.getresponse.com/it/assistenza/iniziare-con-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/demarrer-avec-les-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitNewslettern = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitReports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryErsteSchritteMitWebinaren = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEstadisticasDeImportaciones = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEstatisticasDeImportacao = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEstatisticasDoSite = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-statistics",
"de": "https://www.getresponse.com/de/hilfe/website-statistiken",
"pl": "https://www.getresponse.pl/pomoc/statystyki-strony",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-sajta",
"it": "https://www.getresponse.com/it/assistenza/statistiche-del-sito-web",
"fr": "https://www.getresponse.com/fr/aide/statistiques-du-site-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryEtiquetasYPuntuacion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFacebookAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/facebook-ads",
"de": "https://www.getresponse.com/de/hilfe/facebook-ads-facebook-ads",
"es": "https://www.getresponse.com/es/ayuda/facebook-ads",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"ru": "https://www.getresponse.com/ru/help-ru/reklama-v-facebook-reklama-v-facebook",
"it": "https://www.getresponse.com/it/assistenza/facebook-ads-facebook-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFacebookAdsFacebookAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/facebook-ads",
"de": "https://www.getresponse.com/de/hilfe/facebook-ads-facebook-ads",
"es": "https://www.getresponse.com/es/ayuda/facebook-ads",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"ru": "https://www.getresponse.com/ru/help-ru/reklama-v-facebook-reklama-v-facebook",
"it": "https://www.getresponse.com/it/assistenza/facebook-ads-facebook-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFacebookAdsPl = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/facebook-ads",
"de": "https://www.getresponse.com/de/hilfe/facebook-ads-facebook-ads",
"es": "https://www.getresponse.com/es/ayuda/facebook-ads",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"ru": "https://www.getresponse.com/ru/help-ru/reklama-v-facebook-reklama-v-facebook",
"it": "https://www.getresponse.com/it/assistenza/facebook-ads-facebook-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFeedProdotti = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFeedsDeProductos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFehlerbehebungBeiAbrechnungsproblemen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFehlerbehebungFuerAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFehlerbehebungFuerLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFehlerbehebungFuerNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFehlerbehebungFuerStatistiken = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFehlerbehebungUndReparierenVonWorkflows = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFerramentasDeECommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFichiersEtImages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFileEImmagini = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFilesAndImages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFilter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/filters",
"de": "https://www.getresponse.com/de/hilfe/filter",
"es": "https://www.getresponse.com/es/ayuda/filtros",
"pl": "https://www.getresponse.pl/pomoc/filtry",
"br": "https://www.getresponse.com/pt/ajuda/filtros",
"pt": "https://www.getresponse.com/pt/ajuda/filtros",
"ru": "https://www.getresponse.com/ru/help-ru/filtry",
"it": "https://www.getresponse.com/it/assistenza/filtri",
"fr": "https://www.getresponse.com/fr/aide/filtres"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFilters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/filters",
"de": "https://www.getresponse.com/de/hilfe/filter",
"es": "https://www.getresponse.com/es/ayuda/filtros",
"pl": "https://www.getresponse.pl/pomoc/filtry",
"br": "https://www.getresponse.com/pt/ajuda/filtros",
"pt": "https://www.getresponse.com/pt/ajuda/filtros",
"ru": "https://www.getresponse.com/ru/help-ru/filtry",
"it": "https://www.getresponse.com/it/assistenza/filtri",
"fr": "https://www.getresponse.com/fr/aide/filtres"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFiltres = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/filters",
"de": "https://www.getresponse.com/de/hilfe/filter",
"es": "https://www.getresponse.com/es/ayuda/filtros",
"pl": "https://www.getresponse.pl/pomoc/filtry",
"br": "https://www.getresponse.com/pt/ajuda/filtros",
"pt": "https://www.getresponse.com/pt/ajuda/filtros",
"ru": "https://www.getresponse.com/ru/help-ru/filtry",
"it": "https://www.getresponse.com/it/assistenza/filtri",
"fr": "https://www.getresponse.com/fr/aide/filtres"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFiltri = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/filters",
"de": "https://www.getresponse.com/de/hilfe/filter",
"es": "https://www.getresponse.com/es/ayuda/filtros",
"pl": "https://www.getresponse.pl/pomoc/filtry",
"br": "https://www.getresponse.com/pt/ajuda/filtros",
"pt": "https://www.getresponse.com/pt/ajuda/filtros",
"ru": "https://www.getresponse.com/ru/help-ru/filtry",
"it": "https://www.getresponse.com/it/assistenza/filtri",
"fr": "https://www.getresponse.com/fr/aide/filtres"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFiltros = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/filters",
"de": "https://www.getresponse.com/de/hilfe/filter",
"es": "https://www.getresponse.com/es/ayuda/filtros",
"pl": "https://www.getresponse.pl/pomoc/filtry",
"br": "https://www.getresponse.com/pt/ajuda/filtros",
"pt": "https://www.getresponse.com/pt/ajuda/filtros",
"ru": "https://www.getresponse.com/ru/help-ru/filtry",
"it": "https://www.getresponse.com/it/assistenza/filtri",
"fr": "https://www.getresponse.com/fr/aide/filtres"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFiltry = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/filters",
"de": "https://www.getresponse.com/de/hilfe/filter",
"es": "https://www.getresponse.com/es/ayuda/filtros",
"pl": "https://www.getresponse.pl/pomoc/filtry",
"br": "https://www.getresponse.com/pt/ajuda/filtros",
"pt": "https://www.getresponse.com/pt/ajuda/filtros",
"ru": "https://www.getresponse.com/ru/help-ru/filtry",
"it": "https://www.getresponse.com/it/assistenza/filtri",
"fr": "https://www.getresponse.com/fr/aide/filtres"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFluxDeProduits = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms",
"de": "https://www.getresponse.com/de/hilfe/formulare",
"es": "https://www.getresponse.com/es/ayuda/formularios",
"pl": "https://www.getresponse.pl/pomoc/formularze",
"br": "https://www.getresponse.com/pt/ajuda/formularios",
"pt": "https://www.getresponse.com/pt/ajuda/formularios",
"ru": "https://www.getresponse.com/ru/help-ru/formy-versija-legacy",
"it": "https://www.getresponse.com/it/assistenza/moduli",
"fr": "https://www.getresponse.com/fr/aide/formulaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormsAndPopupsAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormsAndPopupsDisplayRules = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormulaires = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms",
"de": "https://www.getresponse.com/de/hilfe/formulare",
"es": "https://www.getresponse.com/es/ayuda/formularios",
"pl": "https://www.getresponse.pl/pomoc/formularze",
"br": "https://www.getresponse.com/pt/ajuda/formularios",
"pt": "https://www.getresponse.com/pt/ajuda/formularios",
"ru": "https://www.getresponse.com/ru/help-ru/formy-versija-legacy",
"it": "https://www.getresponse.com/it/assistenza/moduli",
"fr": "https://www.getresponse.com/fr/aide/formulaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormulare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms",
"de": "https://www.getresponse.com/de/hilfe/formulare",
"es": "https://www.getresponse.com/es/ayuda/formularios",
"pl": "https://www.getresponse.pl/pomoc/formularze",
"br": "https://www.getresponse.com/pt/ajuda/formularios",
"pt": "https://www.getresponse.com/pt/ajuda/formularios",
"ru": "https://www.getresponse.com/ru/help-ru/formy-versija-legacy",
"it": "https://www.getresponse.com/it/assistenza/moduli",
"fr": "https://www.getresponse.com/fr/aide/formulaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormulareUndPopupsAnalytics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormulareUndPopupsAnzeigeregeln = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormularios = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms",
"de": "https://www.getresponse.com/de/hilfe/formulare",
"es": "https://www.getresponse.com/es/ayuda/formularios",
"pl": "https://www.getresponse.pl/pomoc/formularze",
"br": "https://www.getresponse.com/pt/ajuda/formularios",
"pt": "https://www.getresponse.com/pt/ajuda/formularios",
"ru": "https://www.getresponse.com/ru/help-ru/formy-versija-legacy",
"it": "https://www.getresponse.com/it/assistenza/moduli",
"fr": "https://www.getresponse.com/fr/aide/formulaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormularze = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms",
"de": "https://www.getresponse.com/de/hilfe/formulare",
"es": "https://www.getresponse.com/es/ayuda/formularios",
"pl": "https://www.getresponse.pl/pomoc/formularze",
"br": "https://www.getresponse.com/pt/ajuda/formularios",
"pt": "https://www.getresponse.com/pt/ajuda/formularios",
"ru": "https://www.getresponse.com/ru/help-ru/formy-versija-legacy",
"it": "https://www.getresponse.com/it/assistenza/moduli",
"fr": "https://www.getresponse.com/fr/aide/formulaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormularzeIPopUpyRegulyWyswietlania = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormularzeIPopUpyStatystyki = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunilDeConstrucaoDeLista = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-building-funnel",
"de": "https://www.getresponse.com/de/hilfe/lead-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-construccion-de-la-lista",
"pl": "https://www.getresponse.pl/pomoc/lejek-do-budowania-listy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-rasshirenija-bazy",
"it": "https://www.getresponse.com/it/assistenza/list-building-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-construction-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunilDeConversao = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/funil-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-conversao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunilDeVendas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-funnel",
"de": "https://www.getresponse.com/de/hilfe/sales-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/lejek-sprzedazowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodazhy",
"it": "https://www.getresponse.com/it/assistenza/sales-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-vente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunilDeWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinar-funnel",
"de": "https://www.getresponse.com/de/hilfe/webinar-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-webinar",
"pl": "https://www.getresponse.pl/pomoc/lejek-webinarowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodvizhenija-vebinara",
"it": "https://www.getresponse.com/it/assistenza/webinar-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunnelDeConstructionDeListe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-building-funnel",
"de": "https://www.getresponse.com/de/hilfe/lead-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-construccion-de-la-lista",
"pl": "https://www.getresponse.pl/pomoc/lejek-do-budowania-listy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-rasshirenija-bazy",
"it": "https://www.getresponse.com/it/assistenza/list-building-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-construction-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunnelDeConversion = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/funil-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-conversao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunnelDeVente = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-funnel",
"de": "https://www.getresponse.com/de/hilfe/sales-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/lejek-sprzedazowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodazhy",
"it": "https://www.getresponse.com/it/assistenza/sales-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-vente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFunnelDeWebinaire = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinar-funnel",
"de": "https://www.getresponse.com/de/hilfe/webinar-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-webinar",
"pl": "https://www.getresponse.pl/pomoc/lejek-webinarowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodvizhenija-vebinara",
"it": "https://www.getresponse.com/it/assistenza/webinar-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGerenciamentoDeEquipe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGerenciandoASalaDeWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGerenciandoPaginasNoWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGerenciarConta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGerenciarFormulariosEPopups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGerezVotreCompte = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGerirOsPagamentos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDEquipe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDeFormulatiosYVentanasEmergentes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDeLaSalleDeWebinaire = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDePaginasEnElWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDelEquipo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDesFormulairesEtDesPopUps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDesPagesDansLeCreateurDeSiteInternet = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionDesPaiements = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestionandoLosPagos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestioneDeiPagamenti = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestioneDelTeam = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestioneDellePagineNelWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestioneDiModuliEPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestireIlWebinarRoom = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGestisciAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGetresponseHub = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-hub",
"de": "https://www.getresponse.com/de/hilfe/getresponse-hub",
"es": "https://www.getresponse.com/es/ayuda/getresponse-hub",
"pl": "https://www.getresponse.pl/pomoc/getresponse-hub",
"br": "https://www.getresponse.com/pt/ajuda/getresponse-hub",
"pt": "https://www.getresponse.com/pt/ajuda/getresponse-hub",
"ru": "https://www.getresponse.com/ru/help-ru/getresponse-hub",
"it": "https://www.getresponse.com/it/assistenza/getresponse-hub",
"fr": "https://www.getresponse.com/fr/aide/getresponse-hub"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGetresponseMobileApp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-mobile-app",
"de": "https://www.getresponse.com/de/hilfe/getresponse-mobile-app",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil-getresponse",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"pt": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"ru": "https://www.getresponse.com/ru/help-ru/mobilnyje-prilozhenie-getresponse",
"it": "https://www.getresponse.com/it/assistenza/getresponse-mobile-app",
"fr": "https://www.getresponse.com/fr/aide/application-mobile-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGetresponseWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-webinars",
"de": "https://www.getresponse.com/de/hilfe/getresponse-webinare",
"es": "https://www.getresponse.com/es/ayuda/getresponse-webinars",
"pl": "https://www.getresponse.pl/pomoc/getresponse-webinars",
"br": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prilozhenie-getresponse-webinars",
"it": "https://www.getresponse.com/it/assistenza/getresponse-webinar",
"fr": "https://www.getresponse.com/fr/aide/getresponse-webinars"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGetresponseWebinare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-webinars",
"de": "https://www.getresponse.com/de/hilfe/getresponse-webinare",
"es": "https://www.getresponse.com/es/ayuda/getresponse-webinars",
"pl": "https://www.getresponse.pl/pomoc/getresponse-webinars",
"br": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prilozhenie-getresponse-webinars",
"it": "https://www.getresponse.com/it/assistenza/getresponse-webinar",
"fr": "https://www.getresponse.com/fr/aide/getresponse-webinars"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGetresponseWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-webinars",
"de": "https://www.getresponse.com/de/hilfe/getresponse-webinare",
"es": "https://www.getresponse.com/es/ayuda/getresponse-webinars",
"pl": "https://www.getresponse.pl/pomoc/getresponse-webinars",
"br": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prilozhenie-getresponse-webinars",
"it": "https://www.getresponse.com/it/assistenza/getresponse-webinar",
"fr": "https://www.getresponse.com/fr/aide/getresponse-webinars"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithConversionFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-courses",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-kursen",
"es": "https://www.getresponse.com/es/ayuda/empezar-con-los-cursos",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kursami",
"br": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"it": "https://www.getresponse.com/it/assistenza/iniziare-con-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/demarrer-avec-les-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithFormsAndPopups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithIntegrations = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithManagingAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithReports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithTheWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGettingStartedWithWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGlosario = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/glossary",
"de": "https://www.getresponse.com/de/hilfe/glossar",
"es": "https://www.getresponse.com/es/ayuda/glosario",
"pl": "https://www.getresponse.pl/pomoc/slownik",
"br": "https://www.getresponse.com/pt/ajuda/glossario",
"pt": "https://www.getresponse.com/pt/ajuda/glossario",
"ru": "https://www.getresponse.com/ru/help-ru/glossary-ru",
"it": "https://www.getresponse.com/it/assistenza/glossario",
"fr": "https://www.getresponse.com/fr/aide/glossaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGlossaire = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/glossary",
"de": "https://www.getresponse.com/de/hilfe/glossar",
"es": "https://www.getresponse.com/es/ayuda/glosario",
"pl": "https://www.getresponse.pl/pomoc/slownik",
"br": "https://www.getresponse.com/pt/ajuda/glossario",
"pt": "https://www.getresponse.com/pt/ajuda/glossario",
"ru": "https://www.getresponse.com/ru/help-ru/glossary-ru",
"it": "https://www.getresponse.com/it/assistenza/glossario",
"fr": "https://www.getresponse.com/fr/aide/glossaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGlossar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/glossary",
"de": "https://www.getresponse.com/de/hilfe/glossar",
"es": "https://www.getresponse.com/es/ayuda/glosario",
"pl": "https://www.getresponse.pl/pomoc/slownik",
"br": "https://www.getresponse.com/pt/ajuda/glossario",
"pt": "https://www.getresponse.com/pt/ajuda/glossario",
"ru": "https://www.getresponse.com/ru/help-ru/glossary-ru",
"it": "https://www.getresponse.com/it/assistenza/glossario",
"fr": "https://www.getresponse.com/fr/aide/glossaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGlossario = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/glossary",
"de": "https://www.getresponse.com/de/hilfe/glossar",
"es": "https://www.getresponse.com/es/ayuda/glosario",
"pl": "https://www.getresponse.pl/pomoc/slownik",
"br": "https://www.getresponse.com/pt/ajuda/glossario",
"pt": "https://www.getresponse.com/pt/ajuda/glossario",
"ru": "https://www.getresponse.com/ru/help-ru/glossary-ru",
"it": "https://www.getresponse.com/it/assistenza/glossario",
"fr": "https://www.getresponse.com/fr/aide/glossaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGlossary = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/glossary",
"de": "https://www.getresponse.com/de/hilfe/glossar",
"es": "https://www.getresponse.com/es/ayuda/glosario",
"pl": "https://www.getresponse.pl/pomoc/slownik",
"br": "https://www.getresponse.com/pt/ajuda/glossario",
"pt": "https://www.getresponse.com/pt/ajuda/glossario",
"ru": "https://www.getresponse.com/ru/help-ru/glossary-ru",
"it": "https://www.getresponse.com/it/assistenza/glossario",
"fr": "https://www.getresponse.com/fr/aide/glossaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGoogleAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/google-ads",
"de": "https://www.getresponse.com/de/hilfe/google-ads",
"es": "https://www.getresponse.com/es/ayuda/google-ads",
"pl": "https://www.getresponse.pl/pomoc/google-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"ru": "https://www.getresponse.com/ru/help-ru/google-ads",
"it": "https://www.getresponse.com/it/assistenza/google-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-google"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGoogleAdsPl = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/google-ads",
"de": "https://www.getresponse.com/de/hilfe/google-ads",
"es": "https://www.getresponse.com/es/ayuda/google-ads",
"pl": "https://www.getresponse.pl/pomoc/google-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-google",
"ru": "https://www.getresponse.com/ru/help-ru/google-ads",
"it": "https://www.getresponse.com/it/assistenza/google-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-google"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGrabaciones = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/recordings",
"es": "https://www.getresponse.com/es/ayuda/grabaciones",
"pl": "https://www.getresponse.pl/pomoc/nagrania",
"br": "https://www.getresponse.com/pt/ajuda/gravacoes",
"pt": "https://www.getresponse.com/pt/ajuda/gravacoes",
"ru": "https://www.getresponse.com/ru/help-ru/zapisi-vebinarov",
"it": "https://www.getresponse.com/it/assistenza/registrazioni",
"fr": "https://www.getresponse.com/fr/aide/enregistrements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGravacoes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/recordings",
"es": "https://www.getresponse.com/es/ayuda/grabaciones",
"pl": "https://www.getresponse.pl/pomoc/nagrania",
"br": "https://www.getresponse.com/pt/ajuda/gravacoes",
"pt": "https://www.getresponse.com/pt/ajuda/gravacoes",
"ru": "https://www.getresponse.com/ru/help-ru/zapisi-vebinarov",
"it": "https://www.getresponse.com/it/assistenza/registrazioni",
"fr": "https://www.getresponse.com/fr/aide/enregistrements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAgliAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAiConversionFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAiReport = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAiWebinar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAllAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAllaGestioneDellAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAlleIntegrazioni = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAlleLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGuidaIntroduttivaAlleNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryHerramientasDeECommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryHigienaBazyAdresowej = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryHigieneDeLista = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryHigienizacaoDeLista = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIMieiTemplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryImportStatistics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryImportStatistiken = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryInformes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIniziareConICorsi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-courses",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-kursen",
"es": "https://www.getresponse.com/es/ayuda/empezar-con-los-cursos",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kursami",
"br": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"it": "https://www.getresponse.com/it/assistenza/iniziare-con-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/demarrer-avec-les-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryInstallationAndConfiguration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/installation-and-configuration"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracaoDeProcessadoresDePagamento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracionEntreLaCuentaEstandarYLaCuentaDeAffiliado = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integration-between-standard-and-affiliate-account",
"de": "https://www.getresponse.com/de/hilfe/integration-zwischen-dem-standard-getresponse-und-dem-partnerkonto",
"es": "https://www.getresponse.com/es/ayuda/integracion-entre-la-cuenta-estandar-y-la-cuenta-de-affiliado",
"pl": "https://www.getresponse.pl/pomoc/integracja-pomiedzy-kontem-getresponse-a-kontem-partnerskim",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-miedzy-standartnym-akkauntem-produkta-getresponse-i-partnerskim-akkauntem",
"it": "https://www.getresponse.com/it/assistenza/integrazione-tra-l-account-standard-et-l-account-affiliato",
"fr": "https://www.getresponse.com/fr/aide/integration-entre-le-compte-standard-et-le-compte-affilie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegraciones = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/integraciones"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracionesDeGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracionesDeZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracionesYApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracjaPomiedzyKontemGetresponseAKontemPartnerskim = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integration-between-standard-and-affiliate-account",
"de": "https://www.getresponse.com/de/hilfe/integration-zwischen-dem-standard-getresponse-und-dem-partnerkonto",
"es": "https://www.getresponse.com/es/ayuda/integracion-entre-la-cuenta-estandar-y-la-cuenta-de-affiliado",
"pl": "https://www.getresponse.pl/pomoc/integracja-pomiedzy-kontem-getresponse-a-kontem-partnerskim",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-miedzy-standartnym-akkauntem-produkta-getresponse-i-partnerskim-akkauntem",
"it": "https://www.getresponse.com/it/assistenza/integrazione-tra-l-account-standard-et-l-account-affiliato",
"fr": "https://www.getresponse.com/fr/aide/integration-entre-le-compte-standard-et-le-compte-affilie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracje = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations",
"pl": "https://www.getresponse.pl/pomoc/integracje",
"ru": "https://www.getresponse.com/ru/help-ru/integracii",
"it": "https://www.getresponse.com/it/assistenza/integrazioni",
"fr": "https://www.getresponse.com/fr/aide/integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracjeIApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracjeOdGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracjePrzezZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracoes = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracoesAtravesDaGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracoesEApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracoesPeloZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrandoLosProcesadoresDePagos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrareProcessoriDiPagamento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegratingPaymentProcessors = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationBetweenStandardAndAffiliateAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integration-between-standard-and-affiliate-account",
"de": "https://www.getresponse.com/de/hilfe/integration-zwischen-dem-standard-getresponse-und-dem-partnerkonto",
"es": "https://www.getresponse.com/es/ayuda/integracion-entre-la-cuenta-estandar-y-la-cuenta-de-affiliado",
"pl": "https://www.getresponse.pl/pomoc/integracja-pomiedzy-kontem-getresponse-a-kontem-partnerskim",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-miedzy-standartnym-akkauntem-produkta-getresponse-i-partnerskim-akkauntem",
"it": "https://www.getresponse.com/it/assistenza/integrazione-tra-l-account-standard-et-l-account-affiliato",
"fr": "https://www.getresponse.com/fr/aide/integration-entre-le-compte-standard-et-le-compte-affilie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationsDesProcesseursDePaiement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationEntreLeCompteStandardEtLeCompteAffilie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integration-between-standard-and-affiliate-account",
"de": "https://www.getresponse.com/de/hilfe/integration-zwischen-dem-standard-getresponse-und-dem-partnerkonto",
"es": "https://www.getresponse.com/es/ayuda/integracion-entre-la-cuenta-estandar-y-la-cuenta-de-affiliado",
"pl": "https://www.getresponse.pl/pomoc/integracja-pomiedzy-kontem-getresponse-a-kontem-partnerskim",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-miedzy-standartnym-akkauntem-produkta-getresponse-i-partnerskim-akkauntem",
"it": "https://www.getresponse.com/it/assistenza/integrazione-tra-l-account-standard-et-l-account-affiliato",
"fr": "https://www.getresponse.com/fr/aide/integration-entre-le-compte-standard-et-le-compte-affilie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationVonZahlungsabwicklern = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationZwischenDemStandardGetresponseUndDemPartnerkonto = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integration-between-standard-and-affiliate-account",
"de": "https://www.getresponse.com/de/hilfe/integration-zwischen-dem-standard-getresponse-und-dem-partnerkonto",
"es": "https://www.getresponse.com/es/ayuda/integracion-entre-la-cuenta-estandar-y-la-cuenta-de-affiliado",
"pl": "https://www.getresponse.pl/pomoc/integracja-pomiedzy-kontem-getresponse-a-kontem-partnerskim",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-miedzy-standartnym-akkauntem-produkta-getresponse-i-partnerskim-akkauntem",
"it": "https://www.getresponse.com/it/assistenza/integrazione-tra-l-account-standard-et-l-account-affiliato",
"fr": "https://www.getresponse.com/fr/aide/integration-entre-le-compte-standard-et-le-compte-affilie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationenUndApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationenVonGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationenVonZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrations = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations",
"pl": "https://www.getresponse.pl/pomoc/integracje",
"ru": "https://www.getresponse.com/ru/help-ru/integracii",
"it": "https://www.getresponse.com/it/assistenza/integrazioni",
"fr": "https://www.getresponse.com/fr/aide/integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationsAndApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationsByGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationsByZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationsEtApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationsParGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrationsParZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrazioneTraLAccountStandardEtLAccountAffiliato = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integration-between-standard-and-affiliate-account",
"de": "https://www.getresponse.com/de/hilfe/integration-zwischen-dem-standard-getresponse-und-dem-partnerkonto",
"es": "https://www.getresponse.com/es/ayuda/integracion-entre-la-cuenta-estandar-y-la-cuenta-de-affiliado",
"pl": "https://www.getresponse.pl/pomoc/integracja-pomiedzy-kontem-getresponse-a-kontem-partnerskim",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-miedzy-standartnym-akkauntem-produkta-getresponse-i-partnerskim-akkauntem",
"it": "https://www.getresponse.com/it/assistenza/integrazione-tra-l-account-standard-et-l-account-affiliato",
"fr": "https://www.getresponse.com/fr/aide/integration-entre-le-compte-standard-et-le-compte-affilie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrazioni = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations",
"pl": "https://www.getresponse.pl/pomoc/integracje",
"ru": "https://www.getresponse.com/ru/help-ru/integracii",
"it": "https://www.getresponse.com/it/assistenza/integrazioni",
"fr": "https://www.getresponse.com/fr/aide/integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrazioniEApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrazioniGestitiDaGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrazioniZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegrowanieSystemowPlatnosci = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryInterazioneEFidelizzazione = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntroducaoAFormulariosEPopups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntroduzioneAModuliEPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKampanieAi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ai-campaigns",
"de": "https://www.getresponse.com/de/hilfe/ki-kampahnen",
"es": "https://www.getresponse.com/es/ayuda/campanas-de-ia",
"pl": "https://www.getresponse.pl/pomoc/kampanie-ai",
"br": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"pt": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"ru": "https://www.getresponse.com/ru/help-ru/ii-kampanii",
"it": "https://www.getresponse.com/it/assistenza/campagne-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKiKampahnen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ai-campaigns",
"de": "https://www.getresponse.com/de/hilfe/ki-kampahnen",
"es": "https://www.getresponse.com/es/ayuda/campanas-de-ia",
"pl": "https://www.getresponse.pl/pomoc/kampanie-ai",
"br": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"pt": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"ru": "https://www.getresponse.com/ru/help-ru/ii-kampanii",
"it": "https://www.getresponse.com/it/assistenza/campagne-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKontakteSelbstHinzufuegen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKontakteSuchen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKontoVerwalten = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKorzystanieZFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/using-ftp",
"de": "https://www.getresponse.com/de/hilfe/using-ftp",
"es": "https://www.getresponse.com/es/ayuda/using-ftp",
"pl": "https://www.getresponse.pl/pomoc/korzystanie-z-ftp",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-ftp",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-dell-ftp",
"fr": "https://www.getresponse.com/fr/aide/utilisation-de-ftp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKreatorStron = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/website-builder",
"pl": "https://www.getresponse.pl/pomoc/kreator-stron"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKurseBewerben = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/promoting-courses",
"de": "https://www.getresponse.com/de/hilfe/kurse-bewerben",
"es": "https://www.getresponse.com/es/ayuda/promocionar-cursos",
"pl": "https://www.getresponse.pl/pomoc/promowanie-kursow",
"it": "https://www.getresponse.com/it/assistenza/promuovere-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/promouvoir-des-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKurseditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/courses-editor",
"de": "https://www.getresponse.com/de/hilfe/kurseditor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-cursos",
"pl": "https://www.getresponse.pl/pomoc/edytor-kursow",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-kursov",
"it": "https://www.getresponse.com/it/assistenza/editor-di-corsi",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKursyOnline = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKwalifikowanieLeadow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLEditorDelleLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/landing-page-editor",
"de": "https://www.getresponse.com/de/hilfe/landing-page-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-la-landing-page",
"pl": "https://www.getresponse.pl/pomoc/edytor-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/l-editor-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLEditorDiNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLEditorDiNewsletterLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLaSistemazioneDegliAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLandingPageEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/landing-page-editor",
"de": "https://www.getresponse.com/de/hilfe/landing-page-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-la-landing-page",
"pl": "https://www.getresponse.pl/pomoc/edytor-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/l-editor-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLavorareConGliElementi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLavorareConITemplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLeImpostazioniDelleNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLeadIdonei = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLeadQualifying = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLeadFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-building-funnel",
"de": "https://www.getresponse.com/de/hilfe/lead-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-construccion-de-la-lista",
"pl": "https://www.getresponse.pl/pomoc/lejek-do-budowania-listy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-rasshirenija-bazy",
"it": "https://www.getresponse.com/it/assistenza/list-building-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-construction-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLeadQualifikation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLegacyMessageEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLegacyEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLejekDoBudowaniaListy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-building-funnel",
"de": "https://www.getresponse.com/de/hilfe/lead-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-construccion-de-la-lista",
"pl": "https://www.getresponse.pl/pomoc/lejek-do-budowania-listy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-rasshirenija-bazy",
"it": "https://www.getresponse.com/it/assistenza/list-building-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-construction-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLejekKonwersji = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/funil-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-conversao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLejekSprzedazowy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-funnel",
"de": "https://www.getresponse.com/de/hilfe/sales-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/lejek-sprzedazowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodazhy",
"it": "https://www.getresponse.com/it/assistenza/sales-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-vente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLejekWebinarowy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinar-funnel",
"de": "https://www.getresponse.com/de/hilfe/webinar-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-webinar",
"pl": "https://www.getresponse.pl/pomoc/lejek-webinarowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodvizhenija-vebinara",
"it": "https://www.getresponse.com/it/assistenza/webinar-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListBuildingFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-building-funnel",
"de": "https://www.getresponse.com/de/hilfe/lead-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-construccion-de-la-lista",
"pl": "https://www.getresponse.pl/pomoc/lejek-do-budowania-listy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-rasshirenija-bazy",
"it": "https://www.getresponse.com/it/assistenza/list-building-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-construction-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListHygiene = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListas = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListasDeSupresion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListasDeSupressao = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListe = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListeDiSoppressione = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListen = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListenhygiene = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListes = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListesDeSuppression = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryLists = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryListyWykluczen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMagentoAndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/magento-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/magento-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/magento-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/magento-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/magento-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/magento-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/magento-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMagentoEGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/magento-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/magento-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/magento-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/magento-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/magento-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/magento-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/magento-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMagentoEtGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/magento-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/magento-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/magento-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/magento-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/magento-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/magento-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/magento-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMagentoIGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/magento-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/magento-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/magento-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/magento-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/magento-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/magento-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/magento-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMagentoUndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/magento-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/magento-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/magento-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/magento-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/magento-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/magento-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/magento-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMagentoYGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/magento-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/magento-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/magento-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/magento-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/magento-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/magento-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/magento-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/magento-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryManageAccount = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryManagingFormsAndPopups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryManagingPagesInTheWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryManagingPayments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryManagingTheWebinarRoom = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMarketingAfiliacyjny = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-marketing",
"de": "https://www.getresponse.com/de/hilfe/affiliate-marketing",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/marketing-afiliacyjny",
"br": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskij-marketing",
"it": "https://www.getresponse.com/it/assistenza/affiliate-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMarketingDAffiliation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-marketing",
"de": "https://www.getresponse.com/de/hilfe/affiliate-marketing",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/marketing-afiliacyjny",
"br": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskij-marketing",
"it": "https://www.getresponse.com/it/assistenza/affiliate-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMarketingDeAfiliados = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-marketing",
"de": "https://www.getresponse.com/de/hilfe/affiliate-marketing",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/marketing-afiliacyjny",
"br": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskij-marketing",
"it": "https://www.getresponse.com/it/assistenza/affiliate-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMarketingParEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/email-marketing",
"de": "https://www.getresponse.com/de/hilfe/e-mail-marketing",
"es": "https://www.getresponse.com/es/ayuda/email-marketing",
"pl": "https://www.getresponse.pl/pomoc/email-marketing",
"br": "https://www.getresponse.com/pt/ajuda/e-mail-marketing",
"pt": "https://www.getresponse.com/pt/ajuda/e-mail-marketing",
"ru": "https://www.getresponse.com/ru/help-ru/email-marketing",
"it": "https://www.getresponse.com/it/assistenza/email-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-par-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMeineVorlagen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMesModeles = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMessageEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMeusTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMisModelos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMobilePush = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/mobile-push"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryModuli = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms",
"de": "https://www.getresponse.com/de/hilfe/formulare",
"es": "https://www.getresponse.com/es/ayuda/formularios",
"pl": "https://www.getresponse.pl/pomoc/formularze",
"br": "https://www.getresponse.com/pt/ajuda/formularios",
"pt": "https://www.getresponse.com/pt/ajuda/formularios",
"ru": "https://www.getresponse.com/ru/help-ru/formy-versija-legacy",
"it": "https://www.getresponse.com/it/assistenza/moduli",
"fr": "https://www.getresponse.com/fr/aide/formulaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMojeSzablony = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMyTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachDemKauf = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase",
"de": "https://www.getresponse.com/de/hilfe/nach-dem-kauf",
"es": "https://www.getresponse.com/es/ayuda/pos-compra",
"pl": "https://www.getresponse.pl/pomoc/po-zakupie",
"br": "https://www.getresponse.com/pt/ajuda/pos-compra",
"pt": "https://www.getresponse.com/pt/ajuda/pos-compra",
"ru": "https://www.getresponse.com/ru/help-ru/posle-pokupki",
"it": "https://www.getresponse.com/it/assistenza/post-vendita",
"fr": "https://www.getresponse.com/fr/aide/post-achat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachrichtenEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNagrania = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/recordings",
"es": "https://www.getresponse.com/es/ayuda/grabaciones",
"pl": "https://www.getresponse.pl/pomoc/nagrania",
"br": "https://www.getresponse.com/pt/ajuda/gravacoes",
"pt": "https://www.getresponse.com/pt/ajuda/gravacoes",
"ru": "https://www.getresponse.com/ru/help-ru/zapisi-vebinarov",
"it": "https://www.getresponse.com/it/assistenza/registrazioni",
"fr": "https://www.getresponse.com/fr/aide/enregistrements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNarzedziaECommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNegoziEProdotti = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/negozi-e-prodotti"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNeueLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/new-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/neue-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/new-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/nowe-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/novye-lendingi",
"it": "https://www.getresponse.com/it/assistenza/nuove-landing-page",
"fr": "https://www.getresponse.com/fr/aide/new-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNewLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/new-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/neue-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/new-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/nowe-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/novye-lendingi",
"it": "https://www.getresponse.com/it/assistenza/nuove-landing-page",
"fr": "https://www.getresponse.com/fr/aide/new-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNewsletterPremium = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/premium-newsletters",
"de": "https://www.getresponse.com/de/hilfe/premium-newsletter",
"es": "https://www.getresponse.com/es/ayuda/newsletters-premium",
"pl": "https://www.getresponse.pl/pomoc/newslettery-premium",
"br": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"pt": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"ru": "https://www.getresponse.com/ru/help-ru/premialnye-rassylki",
"it": "https://www.getresponse.com/it/assistenza/newsletter-premium",
"fr": "https://www.getresponse.com/fr/aide/newsletters-premium"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNewsletterSettings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNewsletterEinstellungen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNewslettersPremium = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/premium-newsletters",
"de": "https://www.getresponse.com/de/hilfe/premium-newsletter",
"es": "https://www.getresponse.com/es/ayuda/newsletters-premium",
"pl": "https://www.getresponse.pl/pomoc/newslettery-premium",
"br": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"pt": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"ru": "https://www.getresponse.com/ru/help-ru/premialnye-rassylki",
"it": "https://www.getresponse.com/it/assistenza/newsletter-premium",
"fr": "https://www.getresponse.com/fr/aide/newsletters-premium"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNewsletteryPremium = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/premium-newsletters",
"de": "https://www.getresponse.com/de/hilfe/premium-newsletter",
"es": "https://www.getresponse.com/es/ayuda/newsletters-premium",
"pl": "https://www.getresponse.pl/pomoc/newslettery-premium",
"br": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"pt": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"ru": "https://www.getresponse.com/ru/help-ru/premialnye-rassylki",
"it": "https://www.getresponse.com/it/assistenza/newsletter-premium",
"fr": "https://www.getresponse.com/fr/aide/newsletters-premium"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNotificacionesPush = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNotificacoesWebPush = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNotificationsPushWeb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNotifichePush = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNovasLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/new-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/neue-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/new-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/nowe-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/novye-lendingi",
"it": "https://www.getresponse.com/it/assistenza/nuove-landing-page",
"fr": "https://www.getresponse.com/fr/aide/new-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNoweLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/new-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/neue-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/new-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/nowe-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/novye-lendingi",
"it": "https://www.getresponse.com/it/assistenza/nuove-landing-page",
"fr": "https://www.getresponse.com/fr/aide/new-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNuoveLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/new-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/neue-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/new-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/nowe-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/novye-lendingi",
"it": "https://www.getresponse.com/it/assistenza/nuove-landing-page",
"fr": "https://www.getresponse.com/fr/aide/new-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOnDemandWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOnDemandWebinare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOnlineCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOnlineKurse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOutilsDeCommerceElectronique = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPagamenti = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen",
"es": "https://www.getresponse.com/es/ayuda/pagos",
"pl": "https://www.getresponse.pl/pomoc/platnosci",
"br": "https://www.getresponse.com/pt/ajuda/pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/pagamentos",
"it": "https://www.getresponse.com/it/assistenza/pagamenti",
"fr": "https://www.getresponse.com/fr/aide/paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPagamentos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen",
"es": "https://www.getresponse.com/es/ayuda/pagos",
"pl": "https://www.getresponse.pl/pomoc/platnosci",
"br": "https://www.getresponse.com/pt/ajuda/pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/pagamentos",
"it": "https://www.getresponse.com/it/assistenza/pagamenti",
"fr": "https://www.getresponse.com/fr/aide/paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPagos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen",
"es": "https://www.getresponse.com/es/ayuda/pagos",
"pl": "https://www.getresponse.pl/pomoc/platnosci",
"br": "https://www.getresponse.com/pt/ajuda/pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/pagamentos",
"it": "https://www.getresponse.com/it/assistenza/pagamenti",
"fr": "https://www.getresponse.com/fr/aide/paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPaiements = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen",
"es": "https://www.getresponse.com/es/ayuda/pagos",
"pl": "https://www.getresponse.pl/pomoc/platnosci",
"br": "https://www.getresponse.com/pt/ajuda/pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/pagamentos",
"it": "https://www.getresponse.com/it/assistenza/pagamenti",
"fr": "https://www.getresponse.com/fr/aide/paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPanierAbandonne = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryParametresDeLaNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPartnerPrograms = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPayments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen",
"es": "https://www.getresponse.com/es/ayuda/pagos",
"pl": "https://www.getresponse.pl/pomoc/platnosci",
"br": "https://www.getresponse.com/pt/ajuda/pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/pagamentos",
"it": "https://www.getresponse.com/it/assistenza/pagamenti",
"fr": "https://www.getresponse.com/fr/aide/paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPaypalAndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/paypal-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/paypal-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/paypal-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/paypal-i-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/paypal-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/paypal-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/paypal-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPaypalEGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/paypal-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/paypal-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/paypal-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/paypal-i-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/paypal-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/paypal-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/paypal-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPaypalEtGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/paypal-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/paypal-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/paypal-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/paypal-i-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/paypal-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/paypal-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/paypal-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPaypalIGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/paypal-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/paypal-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/paypal-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/paypal-i-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/paypal-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/paypal-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/paypal-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPaypalUndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/paypal-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/paypal-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/paypal-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/paypal-i-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/paypal-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/paypal-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/paypal-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPaypalYGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/paypal-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/paypal-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/paypal-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/paypal-i-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/paypal-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/paypal-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/paypal-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPerfilDelCreador = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPerfilDoCriador = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiWZarzadzaniuKontem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZAutoresponderami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZFormularzamiIPopupami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZIntegracjami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZKreatoremStron = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZKursami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-courses",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-kursen",
"es": "https://www.getresponse.com/es/ayuda/empezar-con-los-cursos",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kursami",
"br": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-com-os-cursos",
"it": "https://www.getresponse.com/it/assistenza/iniziare-con-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/demarrer-avec-les-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZLejkiemKonwersji = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZMarketingAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZNewsletterami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZRaportami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPierwszeKrokiZWebinarami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPlatnosci = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen",
"es": "https://www.getresponse.com/es/ayuda/pagos",
"pl": "https://www.getresponse.pl/pomoc/platnosci",
"br": "https://www.getresponse.com/pt/ajuda/pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/pagamentos",
"it": "https://www.getresponse.com/it/assistenza/pagamenti",
"fr": "https://www.getresponse.com/fr/aide/paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPlikiDanych = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPlikiIObrazy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPoZakupie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase",
"de": "https://www.getresponse.com/de/hilfe/nach-dem-kauf",
"es": "https://www.getresponse.com/es/ayuda/pos-compra",
"pl": "https://www.getresponse.pl/pomoc/po-zakupie",
"br": "https://www.getresponse.com/pt/ajuda/pos-compra",
"pt": "https://www.getresponse.com/pt/ajuda/pos-compra",
"ru": "https://www.getresponse.com/ru/help-ru/posle-pokupki",
"it": "https://www.getresponse.com/it/assistenza/post-vendita",
"fr": "https://www.getresponse.com/fr/aide/post-achat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPolaDodatkowe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPolaZgody = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPorzuconyKoszyk = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPosCompra = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase",
"de": "https://www.getresponse.com/de/hilfe/nach-dem-kauf",
"es": "https://www.getresponse.com/es/ayuda/pos-compra",
"pl": "https://www.getresponse.pl/pomoc/po-zakupie",
"br": "https://www.getresponse.com/pt/ajuda/pos-compra",
"pt": "https://www.getresponse.com/pt/ajuda/pos-compra",
"ru": "https://www.getresponse.com/ru/help-ru/posle-pokupki",
"it": "https://www.getresponse.com/it/assistenza/post-vendita",
"fr": "https://www.getresponse.com/fr/aide/post-achat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPostPurchase = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase",
"de": "https://www.getresponse.com/de/hilfe/nach-dem-kauf",
"es": "https://www.getresponse.com/es/ayuda/pos-compra",
"pl": "https://www.getresponse.pl/pomoc/po-zakupie",
"br": "https://www.getresponse.com/pt/ajuda/pos-compra",
"pt": "https://www.getresponse.com/pt/ajuda/pos-compra",
"ru": "https://www.getresponse.com/ru/help-ru/posle-pokupki",
"it": "https://www.getresponse.com/it/assistenza/post-vendita",
"fr": "https://www.getresponse.com/fr/aide/post-achat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPostAchat = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase",
"de": "https://www.getresponse.com/de/hilfe/nach-dem-kauf",
"es": "https://www.getresponse.com/es/ayuda/pos-compra",
"pl": "https://www.getresponse.pl/pomoc/po-zakupie",
"br": "https://www.getresponse.com/pt/ajuda/pos-compra",
"pt": "https://www.getresponse.com/pt/ajuda/pos-compra",
"ru": "https://www.getresponse.com/ru/help-ru/posle-pokupki",
"it": "https://www.getresponse.com/it/assistenza/post-vendita",
"fr": "https://www.getresponse.com/fr/aide/post-achat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPostVendita = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase",
"de": "https://www.getresponse.com/de/hilfe/nach-dem-kauf",
"es": "https://www.getresponse.com/es/ayuda/pos-compra",
"pl": "https://www.getresponse.pl/pomoc/po-zakupie",
"br": "https://www.getresponse.com/pt/ajuda/pos-compra",
"pt": "https://www.getresponse.com/pt/ajuda/pos-compra",
"ru": "https://www.getresponse.com/ru/help-ru/posle-pokupki",
"it": "https://www.getresponse.com/it/assistenza/post-vendita",
"fr": "https://www.getresponse.com/fr/aide/post-achat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPowiadomieniaWebPush = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPowitanie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPracaZElementami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPracaZSzablonami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPreciosYFacturacion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrecoECobranca = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPreiseUndAbrechnung = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLAutomatisationDuMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLaGestionDuCompte = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLeCreateurDeSiteInternet = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesAutorepondeurs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesFormulairesEtLesPopUps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesFunnelsDeConversion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesIntegrations = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesPagesDeDestination = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/premiers-pas-avec-les-pages-de-destination"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesRapports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiersPasAvecLesWebinaires = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiumNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/premium-newsletters",
"de": "https://www.getresponse.com/de/hilfe/premium-newsletter",
"es": "https://www.getresponse.com/es/ayuda/newsletters-premium",
"pl": "https://www.getresponse.pl/pomoc/newslettery-premium",
"br": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"pt": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"ru": "https://www.getresponse.com/ru/help-ru/premialnye-rassylki",
"it": "https://www.getresponse.com/it/assistenza/newsletter-premium",
"fr": "https://www.getresponse.com/fr/aide/newsletters-premium"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremiumNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/premium-newsletters",
"de": "https://www.getresponse.com/de/hilfe/premium-newsletter",
"es": "https://www.getresponse.com/es/ayuda/newsletters-premium",
"pl": "https://www.getresponse.pl/pomoc/newslettery-premium",
"br": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"pt": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"ru": "https://www.getresponse.com/ru/help-ru/premialnye-rassylki",
"it": "https://www.getresponse.com/it/assistenza/newsletter-premium",
"fr": "https://www.getresponse.com/fr/aide/newsletters-premium"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrepararUnaLista = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrestashopAndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/prestashop-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/prestashop-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/prestashop-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/prestashop-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prestashop-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/prestashop-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/prestashop-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrestashopEGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/prestashop-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/prestashop-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/prestashop-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/prestashop-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prestashop-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/prestashop-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/prestashop-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrestashopEtGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/prestashop-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/prestashop-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/prestashop-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/prestashop-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prestashop-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/prestashop-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/prestashop-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrestashopIGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/prestashop-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/prestashop-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/prestashop-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/prestashop-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prestashop-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/prestashop-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/prestashop-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrestashopUndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/prestashop-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/prestashop-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/prestashop-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/prestashop-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prestashop-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/prestashop-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/prestashop-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrestashopYGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/prestashop-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/prestashop-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/prestashop-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/prestashop-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/prestashop-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prestashop-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/prestashop-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/prestashop-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrezziEFatturazione = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPricingAndBilling = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrimerosPasosConFormulariosYVentanasEmergentes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProcurarContatos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProductFeeds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProduktFeeds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProfilDuCreateur = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProfilTworcy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProfiloDelCreator = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgramaDeSocios = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgramasDeAfiliados = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-programs",
"de": "https://www.getresponse.com/de/hilfe/affiliate-programme-affiliate-programme",
"es": "https://www.getresponse.com/es/ayuda/programas-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/programy-partnerskie-programy-partnerskie",
"ru": "https://www.getresponse.com/ru/help-ru/partnerstkie-programmy",
"it": "https://www.getresponse.com/it/assistenza/programmi-di-affiliazione",
"fr": "https://www.getresponse.com/fr/aide/programmes-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgramasDeParceria = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgrammesDAffiliation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-programs",
"de": "https://www.getresponse.com/de/hilfe/affiliate-programme-affiliate-programme",
"es": "https://www.getresponse.com/es/ayuda/programas-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/programy-partnerskie-programy-partnerskie",
"ru": "https://www.getresponse.com/ru/help-ru/partnerstkie-programmy",
"it": "https://www.getresponse.com/it/assistenza/programmi-di-affiliazione",
"fr": "https://www.getresponse.com/fr/aide/programmes-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgrammesPartenaires = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgrammiDiAffiliazione = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-programs",
"de": "https://www.getresponse.com/de/hilfe/affiliate-programme-affiliate-programme",
"es": "https://www.getresponse.com/es/ayuda/programas-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/programy-partnerskie-programy-partnerskie",
"ru": "https://www.getresponse.com/ru/help-ru/partnerstkie-programmy",
"it": "https://www.getresponse.com/it/assistenza/programmi-di-affiliazione",
"fr": "https://www.getresponse.com/fr/aide/programmes-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgrammiPartner = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgramyPartnerskie = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProgramyPartnerskieProgramyPartnerskie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-programs",
"de": "https://www.getresponse.com/de/hilfe/affiliate-programme-affiliate-programme",
"es": "https://www.getresponse.com/es/ayuda/programas-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/programy-partnerskie-programy-partnerskie",
"ru": "https://www.getresponse.com/ru/help-ru/partnerstkie-programmy",
"it": "https://www.getresponse.com/it/assistenza/programmi-di-affiliazione",
"fr": "https://www.getresponse.com/fr/aide/programmes-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromocionarCursos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/promoting-courses",
"de": "https://www.getresponse.com/de/hilfe/kurse-bewerben",
"es": "https://www.getresponse.com/es/ayuda/promocionar-cursos",
"pl": "https://www.getresponse.pl/pomoc/promowanie-kursow",
"it": "https://www.getresponse.com/it/assistenza/promuovere-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/promouvoir-des-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromocionesDeVentas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromocoesDeVendas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromotingCourses = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/promoting-courses",
"de": "https://www.getresponse.com/de/hilfe/kurse-bewerben",
"es": "https://www.getresponse.com/es/ayuda/promocionar-cursos",
"pl": "https://www.getresponse.pl/pomoc/promowanie-kursow",
"it": "https://www.getresponse.com/it/assistenza/promuovere-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/promouvoir-des-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromotions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromouvoirDesCours = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/promoting-courses",
"de": "https://www.getresponse.com/de/hilfe/kurse-bewerben",
"es": "https://www.getresponse.com/es/ayuda/promocionar-cursos",
"pl": "https://www.getresponse.pl/pomoc/promowanie-kursow",
"it": "https://www.getresponse.com/it/assistenza/promuovere-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/promouvoir-des-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromowanieKursow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/promoting-courses",
"de": "https://www.getresponse.com/de/hilfe/kurse-bewerben",
"es": "https://www.getresponse.com/es/ayuda/promocionar-cursos",
"pl": "https://www.getresponse.pl/pomoc/promowanie-kursow",
"it": "https://www.getresponse.com/it/assistenza/promuovere-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/promouvoir-des-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromozioniDiVendita = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPromuovereICorsi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/promoting-courses",
"de": "https://www.getresponse.com/de/hilfe/kurse-bewerben",
"es": "https://www.getresponse.com/es/ayuda/promocionar-cursos",
"pl": "https://www.getresponse.pl/pomoc/promowanie-kursow",
"it": "https://www.getresponse.com/it/assistenza/promuovere-i-corsi",
"fr": "https://www.getresponse.com/fr/aide/promouvoir-des-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPruebasDeAB = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrzygotowanieITworzenieListy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPuliziaDelleListe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryQualificacaoDeLead = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryQualificationDeProspect = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRaporty = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRapports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRascunhos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drafts",
"de": "https://www.getresponse.com/de/hilfe/entwuerfe",
"es": "https://www.getresponse.com/es/ayuda/borradores",
"pl": "https://www.getresponse.pl/pomoc/wersje-robocze",
"br": "https://www.getresponse.com/pt/ajuda/rascunhos",
"pt": "https://www.getresponse.com/pt/ajuda/rascunhos",
"ru": "https://www.getresponse.com/ru/help-ru/chernoviki",
"it": "https://www.getresponse.com/it/assistenza/bozze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRechercheDeContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRecordings = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/recordings",
"es": "https://www.getresponse.com/es/ayuda/grabaciones",
"pl": "https://www.getresponse.pl/pomoc/nagrania",
"br": "https://www.getresponse.com/pt/ajuda/gravacoes",
"pt": "https://www.getresponse.com/pt/ajuda/gravacoes",
"ru": "https://www.getresponse.com/ru/help-ru/zapisi-vebinarov",
"it": "https://www.getresponse.com/it/assistenza/registrazioni",
"fr": "https://www.getresponse.com/fr/aide/enregistrements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRegistrazioni = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/recordings",
"es": "https://www.getresponse.com/es/ayuda/grabaciones",
"pl": "https://www.getresponse.pl/pomoc/nagrania",
"br": "https://www.getresponse.com/pt/ajuda/gravacoes",
"pt": "https://www.getresponse.com/pt/ajuda/gravacoes",
"ru": "https://www.getresponse.com/ru/help-ru/zapisi-vebinarov",
"it": "https://www.getresponse.com/it/assistenza/registrazioni",
"fr": "https://www.getresponse.com/fr/aide/enregistrements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReglageDeVosAutorepondeurs = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReglasDeVisualizacionDeLosFormulariosYVentanasEmergentes = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-de-los-formularios-y-ventanas-emergentes"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReglasDeVisualizacionFormulariosYVentanasEmergentes = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReglesDAffichageDesFormulairesEtDesPopUps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRegoleDiVisualizzazioneDiModuliEPopup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRegrasDeExibicaoDeFormulariosEPopUps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRegulaminIReputacjaKonta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRelatorios = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReport = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryResolucaoDeProblemasECorrecaoDeFluxosDeTrabalho = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryResolucaoDeProblemasParaLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryResolutionDesProblemesDeFacturation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRisoluzioneDeiProblemiConGliAutoresponder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRisoluzioneDeiProblemiConIWorkflow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRisoluzioneDeiProblemiConLaFatturazione = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRisoluzioneDeiProblemiConLeLandingPage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRisoluzioneDeiProblemiConLeNewsletter = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRisoluzioneDeiProblemiConLeStatistiche = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRozwiazywanieProblemowZAutoresponderami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRozwiazywanieProblemowZCyklamiAutomation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRozwiazywanieProblemowZLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRozwiazywanieProblemowZNewsletterami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRozwiazywanieProblemowZPlatnosciami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRozwiazywanieProblemowZeStatystykami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRssAEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/rss-to-email",
"de": "https://www.getresponse.com/de/hilfe/rss-to-email",
"es": "https://www.getresponse.com/es/ayuda/rss-a-email",
"pl": "https://www.getresponse.pl/pomoc/rss-to-email-pl",
"br": "https://www.getresponse.com/pt/ajuda/rss-to-email",
"pt": "https://www.getresponse.com/pt/ajuda/rss-to-email",
"ru": "https://www.getresponse.com/ru/help-ru/rss-to-email",
"it": "https://www.getresponse.com/it/assistenza/rss-to-email",
"fr": "https://www.getresponse.com/fr/aide/rss-to-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRssToEmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/rss-to-email",
"de": "https://www.getresponse.com/de/hilfe/rss-to-email",
"es": "https://www.getresponse.com/es/ayuda/rss-a-email",
"pl": "https://www.getresponse.pl/pomoc/rss-to-email-pl",
"br": "https://www.getresponse.com/pt/ajuda/rss-to-email",
"pt": "https://www.getresponse.com/pt/ajuda/rss-to-email",
"ru": "https://www.getresponse.com/ru/help-ru/rss-to-email",
"it": "https://www.getresponse.com/it/assistenza/rss-to-email",
"fr": "https://www.getresponse.com/fr/aide/rss-to-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRssToEmailPl = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/rss-to-email",
"de": "https://www.getresponse.com/de/hilfe/rss-to-email",
"es": "https://www.getresponse.com/es/ayuda/rss-a-email",
"pl": "https://www.getresponse.pl/pomoc/rss-to-email-pl",
"br": "https://www.getresponse.com/pt/ajuda/rss-to-email",
"pt": "https://www.getresponse.com/pt/ajuda/rss-to-email",
"ru": "https://www.getresponse.com/ru/help-ru/rss-to-email",
"it": "https://www.getresponse.com/it/assistenza/rss-to-email",
"fr": "https://www.getresponse.com/fr/aide/rss-to-email"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySalesFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-funnel",
"de": "https://www.getresponse.com/de/hilfe/sales-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/lejek-sprzedazowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodazhy",
"it": "https://www.getresponse.com/it/assistenza/sales-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-vente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySalesPromotions = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySamodzielneDodawanieKontaktow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySearchContacts = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySegmente = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/segments",
"de": "https://www.getresponse.com/de/hilfe/segmente",
"es": "https://www.getresponse.com/es/ayuda/segmentos",
"pl": "https://www.getresponse.pl/pomoc/segmenty",
"br": "https://www.getresponse.com/pt/ajuda/segmentos",
"pt": "https://www.getresponse.com/pt/ajuda/segmentos",
"ru": "https://www.getresponse.com/ru/help-ru/segmenty",
"it": "https://www.getresponse.com/it/assistenza/segmenti",
"fr": "https://www.getresponse.com/fr/aide/segments"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySegmenti = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/segments",
"de": "https://www.getresponse.com/de/hilfe/segmente",
"es": "https://www.getresponse.com/es/ayuda/segmentos",
"pl": "https://www.getresponse.pl/pomoc/segmenty",
"br": "https://www.getresponse.com/pt/ajuda/segmentos",
"pt": "https://www.getresponse.com/pt/ajuda/segmentos",
"ru": "https://www.getresponse.com/ru/help-ru/segmenty",
"it": "https://www.getresponse.com/it/assistenza/segmenti",
"fr": "https://www.getresponse.com/fr/aide/segments"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySegmentos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/segments",
"de": "https://www.getresponse.com/de/hilfe/segmente",
"es": "https://www.getresponse.com/es/ayuda/segmentos",
"pl": "https://www.getresponse.pl/pomoc/segmenty",
"br": "https://www.getresponse.com/pt/ajuda/segmentos",
"pt": "https://www.getresponse.com/pt/ajuda/segmentos",
"ru": "https://www.getresponse.com/ru/help-ru/segmenty",
"it": "https://www.getresponse.com/it/assistenza/segmenti",
"fr": "https://www.getresponse.com/fr/aide/segments"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySegments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/segments",
"de": "https://www.getresponse.com/de/hilfe/segmente",
"es": "https://www.getresponse.com/es/ayuda/segmentos",
"pl": "https://www.getresponse.pl/pomoc/segmenty",
"br": "https://www.getresponse.com/pt/ajuda/segmentos",
"pt": "https://www.getresponse.com/pt/ajuda/segmentos",
"ru": "https://www.getresponse.com/ru/help-ru/segmenty",
"it": "https://www.getresponse.com/it/assistenza/segmenti",
"fr": "https://www.getresponse.com/fr/aide/segments"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySegmenty = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/segments",
"de": "https://www.getresponse.com/de/hilfe/segmente",
"es": "https://www.getresponse.com/es/ayuda/segmentos",
"pl": "https://www.getresponse.pl/pomoc/segmenty",
"br": "https://www.getresponse.com/pt/ajuda/segmentos",
"pt": "https://www.getresponse.com/pt/ajuda/segmentos",
"ru": "https://www.getresponse.com/ru/help-ru/segmenty",
"it": "https://www.getresponse.com/it/assistenza/segmenti",
"fr": "https://www.getresponse.com/fr/aide/segments"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySeitenImWebsiteBuilderVerwalten = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySettingUpAList = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryShopifyAndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/shopify-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/shopify-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/shopify-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/shopify-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/shopify-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/shopify-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/shopify-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryShopifyEGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/shopify-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/shopify-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/shopify-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/shopify-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/shopify-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/shopify-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/shopify-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryShopifyEtGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/shopify-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/shopify-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/shopify-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/shopify-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/shopify-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/shopify-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/shopify-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryShopifyIGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/shopify-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/shopify-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/shopify-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/shopify-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/shopify-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/shopify-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/shopify-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryShopifyUndGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/shopify-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/shopify-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/shopify-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/shopify-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/shopify-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/shopify-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/shopify-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryShopifyYGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/shopify-and-getresponse",
"de": "https://www.getresponse.com/de/hilfe/shopify-und-getresponse",
"es": "https://www.getresponse.com/es/ayuda/shopify-y-getresponse",
"pl": "https://www.getresponse.pl/pomoc/shopify-i-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/shopify-e-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/shopify-i-getresponse",
"it": "https://www.getresponse.com/it/assistenza/shopify-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/shopify-et-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySlownik = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/glossary",
"de": "https://www.getresponse.com/de/hilfe/glossar",
"es": "https://www.getresponse.com/es/ayuda/glosario",
"pl": "https://www.getresponse.pl/pomoc/slownik",
"br": "https://www.getresponse.com/pt/ajuda/glossario",
"pt": "https://www.getresponse.com/pt/ajuda/glossario",
"ru": "https://www.getresponse.com/ru/help-ru/glossary-ru",
"it": "https://www.getresponse.com/it/assistenza/glossario",
"fr": "https://www.getresponse.com/fr/aide/glossaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sms",
"de": "https://www.getresponse.com/de/hilfe/sms",
"es": "https://www.getresponse.com/es/ayuda/sms",
"pl": "https://www.getresponse.pl/pomoc/sms",
"br": "https://www.getresponse.com/pt/ajuda/sms",
"pt": "https://www.getresponse.com/pt/ajuda/sms",
"ru": "https://www.getresponse.com/ru/help-ru/sms",
"it": "https://www.getresponse.com/it/assistenza/sms",
"fr": "https://www.getresponse.com/fr/aide/sms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucaoDeProblemasDeFaturamento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucaoDeProblemasParaAutorrespostas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucaoDeProblemasParaEstatisticas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucaoDeProblemasParaNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucionDeProblemasDeFacturacion = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucionDeProblemasParaLasEstadisticas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucionDeProblemasParaLasLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucionDeProblemasParaLosAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucionDeProblemasParaLosNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySolucionDeProblemasYReparacionDeLosFlujosDeTrabajo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySprzedaz = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatisticheDelSitoWeb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-statistics",
"de": "https://www.getresponse.com/de/hilfe/website-statistiken",
"pl": "https://www.getresponse.pl/pomoc/statystyki-strony",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-sajta",
"it": "https://www.getresponse.com/it/assistenza/statistiche-del-sito-web",
"fr": "https://www.getresponse.com/fr/aide/statistiques-du-site-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatisticheImportazione = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatistiken = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatistiquesDImportation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatistiquesDuSiteWeb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-statistics",
"de": "https://www.getresponse.com/de/hilfe/website-statistiken",
"pl": "https://www.getresponse.pl/pomoc/statystyki-strony",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-sajta",
"it": "https://www.getresponse.com/it/assistenza/statistiche-del-sito-web",
"fr": "https://www.getresponse.com/fr/aide/statistiques-du-site-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatystykiImportow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatystykiStrony = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-statistics",
"de": "https://www.getresponse.com/de/hilfe/website-statistiken",
"pl": "https://www.getresponse.pl/pomoc/statystyki-strony",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-sajta",
"it": "https://www.getresponse.com/it/assistenza/statistiche-del-sito-web",
"fr": "https://www.getresponse.com/fr/aide/statistiques-du-site-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStrumentiEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySuppressionLists = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTagEScoring = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTagiIScoring = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTagsAndScoring = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTagsEPontuacao = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTagsEtScoring = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTagsUndScoring = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTarificationEtFacturation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTeamManagement = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTeamVerwaltung = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTestesAB = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTestsAb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTestyAb = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTrabajandoConElementos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTrabajandoConModelos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTrabalhandoComElementos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTrabalhandoComTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTransactionalEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/transactional-emails",
"pl": "https://www.getresponse.pl/pomoc/emaile-transakcyjne",
"ru": "https://www.getresponse.com/ru/help-ru/tranzakcionnye-pisma",
"it": "https://www.getresponse.com/it/assistenza/e-mail-transazionali",
"fr": "https://www.getresponse.com/fr/aide/e-mails-transactionnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTravaillerAvecDesElements = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTravaillerAvecDesModeles = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTroubleshootingAndFixingWorkflows = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTroubleshootingBillingIssues = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTroubleshootingForAutoresponders = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTroubleshootingForLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTroubleshootingForNewsletters = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTroubleshootingForStatistics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUnterdrueckungslisten = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUsingFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/using-ftp",
"de": "https://www.getresponse.com/de/hilfe/using-ftp",
"es": "https://www.getresponse.com/es/ayuda/using-ftp",
"pl": "https://www.getresponse.pl/pomoc/korzystanie-z-ftp",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-ftp",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-dell-ftp",
"fr": "https://www.getresponse.com/fr/aide/utilisation-de-ftp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUsoDeFtp = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/uso-de-ftp",
"br": "https://www.getresponse.com/pt/ajuda/uso-de-ftp",
"pt": "https://www.getresponse.com/pt/ajuda/uso-de-ftp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUstawieniaNewslettera = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUtilisationDeFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/using-ftp",
"de": "https://www.getresponse.com/de/hilfe/using-ftp",
"es": "https://www.getresponse.com/es/ayuda/using-ftp",
"pl": "https://www.getresponse.pl/pomoc/korzystanie-z-ftp",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-ftp",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-dell-ftp",
"fr": "https://www.getresponse.com/fr/aide/utilisation-de-ftp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUtilizzoDellFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/using-ftp",
"de": "https://www.getresponse.com/de/hilfe/using-ftp",
"es": "https://www.getresponse.com/es/ayuda/using-ftp",
"pl": "https://www.getresponse.pl/pomoc/korzystanie-z-ftp",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-ftp",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-dell-ftp",
"fr": "https://www.getresponse.com/fr/aide/utilisation-de-ftp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVerkaufsaktionen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVerwaltenDesWebinarRaums = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVerwaltenVonFormularenUndPopups = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWarunki = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebPushNotifications = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebPushBenachrichtigungen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebPushUvedomleniya = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/web-push-notifications",
"de": "https://www.getresponse.com/de/hilfe/web-push-benachrichtigungen",
"es": "https://www.getresponse.com/es/ayuda/notificaciones-push",
"pl": "https://www.getresponse.pl/pomoc/powiadomienia-web-push",
"br": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"pt": "https://www.getresponse.com/pt/ajuda/notificacoes-web-push",
"ru": "https://www.getresponse.com/ru/help-ru/web-push-uvedomleniya",
"it": "https://www.getresponse.com/it/assistenza/notifiche-push",
"fr": "https://www.getresponse.com/fr/aide/notifications-push-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinaires = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webinare",
"it": "https://www.getresponse.com/it/assistenza/webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinairesALaDemande = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinairesEtEvenements = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinar = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webinare",
"it": "https://www.getresponse.com/it/assistenza/webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarEdEventi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinar-funnel",
"de": "https://www.getresponse.com/de/hilfe/webinar-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-webinar",
"pl": "https://www.getresponse.pl/pomoc/lejek-webinarowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodvizhenija-vebinara",
"it": "https://www.getresponse.com/it/assistenza/webinar-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarOnDemand = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinare = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webinare",
"it": "https://www.getresponse.com/it/assistenza/webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinareUndEvents = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinars = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webinare",
"it": "https://www.getresponse.com/it/assistenza/webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarsAndEvents = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarsBajoDemanda = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarsDaGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-webinars",
"de": "https://www.getresponse.com/de/hilfe/getresponse-webinare",
"es": "https://www.getresponse.com/es/ayuda/getresponse-webinars",
"pl": "https://www.getresponse.pl/pomoc/getresponse-webinars",
"br": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prilozhenie-getresponse-webinars",
"it": "https://www.getresponse.com/it/assistenza/getresponse-webinar",
"fr": "https://www.getresponse.com/fr/aide/getresponse-webinars"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarsEEventos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarsSobDemanda = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinarsYEventos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinary = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webinare",
"it": "https://www.getresponse.com/it/assistenza/webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinaryIWydarzenia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebinaryNaZadanie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebsiteBuilder = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/website-builder",
"pl": "https://www.getresponse.pl/pomoc/kreator-stron"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebsiteBuilderEditor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-builder-editor",
"de": "https://www.getresponse.com/de/hilfe/website-builder-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-del-website-builder",
"pl": "https://www.getresponse.pl/pomoc/edytor-kreatora-stron",
"br": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"pt": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-konstrykora-saitov",
"it": "https://www.getresponse.com/it/assistenza/editor-del-website-builder",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-creation-de-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebsiteStatistics = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-statistics",
"de": "https://www.getresponse.com/de/hilfe/website-statistiken",
"pl": "https://www.getresponse.pl/pomoc/statystyki-strony",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-sajta",
"it": "https://www.getresponse.com/it/assistenza/statistiche-del-sito-web",
"fr": "https://www.getresponse.com/fr/aide/statistiques-du-site-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWebsiteStatistiken = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-statistics",
"de": "https://www.getresponse.com/de/hilfe/website-statistiken",
"pl": "https://www.getresponse.pl/pomoc/statystyki-strony",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-sajta",
"it": "https://www.getresponse.com/it/assistenza/statistiche-del-sito-web",
"fr": "https://www.getresponse.com/fr/aide/statistiques-du-site-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWelcome = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWersjeRobocze = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drafts",
"de": "https://www.getresponse.com/de/hilfe/entwuerfe",
"es": "https://www.getresponse.com/es/ayuda/borradores",
"pl": "https://www.getresponse.pl/pomoc/wersje-robocze",
"br": "https://www.getresponse.com/pt/ajuda/rascunhos",
"pt": "https://www.getresponse.com/pt/ajuda/rascunhos",
"ru": "https://www.getresponse.com/ru/help-ru/chernoviki",
"it": "https://www.getresponse.com/it/assistenza/bozze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWlasneDomenyDlaLandingPages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-domains-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierte-domains-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/dominios-personalizados-para-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/wlasne-domeny-dla-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-sobstvennyh-domenov-dlja-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/domini-personalizzati-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressAndGetresponseEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-ecommerce",
"de": "https://www.getresponse.com/de/hilfe/wordpress-and-getresponse-ecommerce",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-ecommerce",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-ecommerce",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-geresponse-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressAndGetresponseLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressEGetresponseEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-ecommerce",
"de": "https://www.getresponse.com/de/hilfe/wordpress-and-getresponse-ecommerce",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-ecommerce",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-ecommerce",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-geresponse-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressEGetresponseLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressEGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressEtGetresponseEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-ecommerce",
"de": "https://www.getresponse.com/de/hilfe/wordpress-and-getresponse-ecommerce",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-ecommerce",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-ecommerce",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-geresponse-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressEtGetresponseLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressIGetresponseEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-ecommerce",
"de": "https://www.getresponse.com/de/hilfe/wordpress-and-getresponse-ecommerce",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-ecommerce",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-ecommerce",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-geresponse-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressIGetresponseWersjaLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressUndGetresponseLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressYGetresponseEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-ecommerce",
"de": "https://www.getresponse.com/de/hilfe/wordpress-and-getresponse-ecommerce",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-ecommerce",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-ecommerce",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-geresponse-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressYGetresponsePluginAntiguo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressIGeresponseEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-ecommerce",
"de": "https://www.getresponse.com/de/hilfe/wordpress-and-getresponse-ecommerce",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-ecommerce",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-ecommerce",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-ecommerce",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-geresponse-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-ecommerce"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWordpressIGetresponseLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/wordpress-and-getresponse-legacy",
"de": "https://www.getresponse.com/de/hilfe/wordpress-und-getresponse-legacy",
"es": "https://www.getresponse.com/es/ayuda/wordpress-y-getresponse-plugin-antiguo",
"pl": "https://www.getresponse.pl/pomoc/wordpress-i-getresponse-wersja-legacy",
"br": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"pt": "https://www.getresponse.com/pt/ajuda/wordpress-e-getresponse-legacy",
"ru": "https://www.getresponse.com/ru/help-ru/wordpress-i-getresponse-legacy",
"it": "https://www.getresponse.com/it/assistenza/wordpress-e-getresponse",
"fr": "https://www.getresponse.com/fr/aide/wordpress-et-getresponse-legacy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWorkingWithElements = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWorkingWithTemplates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryWyszukiwanieKontaktow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZaangazowanieIRetencja = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZahlungen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen",
"es": "https://www.getresponse.com/es/ayuda/pagos",
"pl": "https://www.getresponse.pl/pomoc/platnosci",
"br": "https://www.getresponse.com/pt/ajuda/pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/pagamentos",
"it": "https://www.getresponse.com/it/assistenza/pagamenti",
"fr": "https://www.getresponse.com/fr/aide/paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZahlungenVerwalten = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZarzadzajKontem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZarzadzanieFormularzamiIPopupami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZarzadzaniePlatnosciami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZarzadzaniePokojemWebinaru = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZarzadzanieStronamiWKreatorzeStron = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZarzadzanieZespolem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZustellbarkeit = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/deliverability",
"de": "https://www.getresponse.com/de/hilfe/zustellbarkeit",
"es": "https://www.getresponse.com/es/ayuda/entregabilidad",
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc",
"br": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"pt": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"ru": "https://www.getresponse.com/ru/help-ru/dostavlyaemost",
"it": "https://www.getresponse.com/it/assistenza/deliverability",
"fr": "https://www.getresponse.com/fr/aide/delivrabilite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAbTesty = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ab-tests",
"de": "https://www.getresponse.com/de/hilfe/a-b-tests",
"es": "https://www.getresponse.com/es/ayuda/pruebas-de-a-b",
"pl": "https://www.getresponse.pl/pomoc/testy-ab",
"br": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"pt": "https://www.getresponse.com/pt/ajuda/testes-a-b",
"ru": "https://www.getresponse.com/ru/help-ru/ab-testy",
"it": "https://www.getresponse.com/it/assistenza/a-b-test",
"fr": "https://www.getresponse.com/fr/aide/tests-ab"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAvtomatizaciya = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/automatizacion",
"br": "https://www.getresponse.com/pt/ajuda/automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/automatizacao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAvtootvetchiki = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/autorrespostas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalitikaIRemarketingKonstryktorSaitov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing-website-builder",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing-website-builder",
"es": "https://www.getresponse.com/es/ayuda/analytics-and-remarketing-website-builder",
"pl": "https://www.getresponse.pl/pomoc/analiza-i-re-marketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-konstryktor-saitov",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing-website-builder",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalitikaIRemarketingVersiaLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/analytics-and-remarketing",
"de": "https://www.getresponse.com/de/hilfe/analytik-und-remarketing",
"es": "https://www.getresponse.com/es/ayuda/analitica-y-remarketing",
"pl": "https://www.getresponse.pl/pomoc/analityka-i-remarketing",
"br": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"pt": "https://www.getresponse.com/pt/ajuda/analitica-e-remarketing",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-i-remarketing-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/analytics-e-remarketing",
"fr": "https://www.getresponse.com/fr/aide/analytics-et-remarketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryAnalitikaFormIVsplyvayushchihOkon = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-analytics",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-analytics",
"es": "https://www.getresponse.com/es/ayuda/analisis-de-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-statystyki",
"br": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/analises-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/analitika-form-i-vsplyvayushchih-okon",
"it": "https://www.getresponse.com/it/assistenza/analisi-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/analyse-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryBroshennajaKorzina = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/abandoned-cart",
"de": "https://www.getresponse.com/de/hilfe/abgebrochener-warenkorb",
"es": "https://www.getresponse.com/es/ayuda/carrito-abandonado",
"pl": "https://www.getresponse.pl/pomoc/porzucony-koszyk",
"br": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"pt": "https://www.getresponse.com/pt/ajuda/carrinho-abandonado",
"ru": "https://www.getresponse.com/ru/help-ru/broshennaja-korzina",
"it": "https://www.getresponse.com/it/assistenza/carrello-abbandonato",
"fr": "https://www.getresponse.com/fr/aide/panier-abandonne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVebinary = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/hilfe/webinare",
"it": "https://www.getresponse.com/it/assistenza/webinar"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVebinaryISobytija = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinars-and-events",
"de": "https://www.getresponse.com/de/hilfe/webinare-und-events",
"es": "https://www.getresponse.com/es/ayuda/webinars-y-eventos",
"pl": "https://www.getresponse.pl/pomoc/webinary-i-wydarzenia",
"br": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-e-eventos",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-i-sobytija",
"it": "https://www.getresponse.com/it/assistenza/webinar-ed-eventi",
"fr": "https://www.getresponse.com/fr/aide/webinaires-et-evenements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVebinaryPoTrebovaniju = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/on-demand-webinars",
"de": "https://www.getresponse.com/de/hilfe/on-demand-webinare",
"es": "https://www.getresponse.com/es/ayuda/webinars-bajo-demanda",
"pl": "https://www.getresponse.pl/pomoc/webinary-na-zadanie",
"br": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-sob-demanda",
"ru": "https://www.getresponse.com/ru/help-ru/vebinary-po-trebovaniju",
"it": "https://www.getresponse.com/it/assistenza/webinar-on-demand",
"fr": "https://www.getresponse.com/fr/aide/webinaires-a-la-demande"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVovlechenieIUderzhanie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/engagement-and-retention",
"de": "https://www.getresponse.com/de/hilfe/einbindung-und-retention",
"es": "https://www.getresponse.com/es/ayuda/compromiso-y-retencion",
"pl": "https://www.getresponse.pl/pomoc/zaangazowanie-i-retencja",
"br": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"pt": "https://www.getresponse.com/pt/ajuda/envolvimento-e-retencao",
"ru": "https://www.getresponse.com/ru/help-ru/vovlechenie-i-uderzhanie",
"it": "https://www.getresponse.com/it/assistenza/interazione-e-fidelizzazione",
"fr": "https://www.getresponse.com/fr/aide/engagement-et-retention"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVoronkaProdazhy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-funnel",
"de": "https://www.getresponse.com/de/hilfe/sales-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/lejek-sprzedazowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodazhy",
"it": "https://www.getresponse.com/it/assistenza/sales-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-vente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVoronkaProdvizhenijaVebinara = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/webinar-funnel",
"de": "https://www.getresponse.com/de/hilfe/webinar-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-webinar",
"pl": "https://www.getresponse.pl/pomoc/lejek-webinarowy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-webinar",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-prodvizhenija-vebinara",
"it": "https://www.getresponse.com/it/assistenza/webinar-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVoronkaRasshirenijaBazy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-building-funnel",
"de": "https://www.getresponse.com/de/hilfe/lead-funnel",
"es": "https://www.getresponse.com/es/ayuda/embudo-de-construccion-de-la-lista",
"pl": "https://www.getresponse.pl/pomoc/lejek-do-budowania-listy",
"br": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-construcao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/voronka-rasshirenija-bazy",
"it": "https://www.getresponse.com/it/assistenza/list-building-funnel",
"fr": "https://www.getresponse.com/fr/aide/funnel-de-construction-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryVoronkiKonversii = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/funil-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/funil-de-conversao"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGigienaSpiska = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/list-hygiene",
"de": "https://www.getresponse.com/de/hilfe/listenhygiene",
"es": "https://www.getresponse.com/es/ayuda/higiene-de-lista",
"pl": "https://www.getresponse.pl/pomoc/higiena-bazy-adresowej",
"br": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/higienizacao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/gigiena-spiska",
"it": "https://www.getresponse.com/it/assistenza/pulizia-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/entretien-de-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryGlossaryRu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/glossary",
"de": "https://www.getresponse.com/de/hilfe/glossar",
"es": "https://www.getresponse.com/es/ayuda/glosario",
"pl": "https://www.getresponse.pl/pomoc/slownik",
"br": "https://www.getresponse.com/pt/ajuda/glossario",
"pt": "https://www.getresponse.com/pt/ajuda/glossario",
"ru": "https://www.getresponse.com/ru/help-ru/glossary-ru",
"it": "https://www.getresponse.com/it/assistenza/glossario",
"fr": "https://www.getresponse.com/fr/aide/glossaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDeystviya = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/actions",
"de": "https://www.getresponse.com/de/hilfe/aktionen",
"es": "https://www.getresponse.com/es/ayuda/acciones",
"pl": "https://www.getresponse.pl/pomoc/akcje",
"br": "https://www.getresponse.com/pt/ajuda/acoes",
"pt": "https://www.getresponse.com/pt/ajuda/acoes",
"ru": "https://www.getresponse.com/ru/help-ru/deystviya",
"it": "https://www.getresponse.com/it/assistenza/azioni",
"fr": "https://www.getresponse.com/fr/aide/actions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDomenyDlyaSaita = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/domains-for-websites",
"de": "https://www.getresponse.com/de/hilfe/domains-fur-webseiten",
"es": "https://www.getresponse.com/es/ayuda/dominios-para-sitios-web",
"pl": "https://www.getresponse.pl/pomoc/domeny-stron",
"br": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-para-sites",
"ru": "https://www.getresponse.com/ru/help-ru/domeny-dlya-saita",
"it": "https://www.getresponse.com/it/assistenza/domini-per-i-siti-web",
"fr": "https://www.getresponse.com/fr/aide/domaines-pour-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryDostavlyaemost = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/deliverability",
"de": "https://www.getresponse.com/de/hilfe/zustellbarkeit",
"es": "https://www.getresponse.com/es/ayuda/entregabilidad",
"pl": "https://www.getresponse.pl/pomoc/dostarczalnosc",
"br": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"pt": "https://www.getresponse.com/pt/ajuda/entregabilidade",
"ru": "https://www.getresponse.com/ru/help-ru/dostavlyaemost",
"it": "https://www.getresponse.com/it/assistenza/deliverability",
"fr": "https://www.getresponse.com/fr/aide/delivrabilite"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryZapisiVebinarov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/recordings",
"es": "https://www.getresponse.com/es/ayuda/grabaciones",
"pl": "https://www.getresponse.pl/pomoc/nagrania",
"br": "https://www.getresponse.com/pt/ajuda/gravacoes",
"pt": "https://www.getresponse.com/pt/ajuda/gravacoes",
"ru": "https://www.getresponse.com/ru/help-ru/zapisi-vebinarov",
"it": "https://www.getresponse.com/it/assistenza/registrazioni",
"fr": "https://www.getresponse.com/fr/aide/enregistrements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIiKampanii = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ai-campaigns",
"de": "https://www.getresponse.com/de/hilfe/ki-kampahnen",
"es": "https://www.getresponse.com/es/ayuda/campanas-de-ia",
"pl": "https://www.getresponse.pl/pomoc/kampanie-ai",
"br": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"pt": "https://www.getresponse.com/pt/ajuda/campanhas-de-ia",
"ru": "https://www.getresponse.com/ru/help-ru/ii-kampanii",
"it": "https://www.getresponse.com/it/assistenza/campagne-ai"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryInstrumentyiEcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/ecommerce-tools",
"de": "https://www.getresponse.com/de/hilfe/e-commerce-tools",
"es": "https://www.getresponse.com/es/ayuda/herramientas-de-e-commerce",
"pl": "https://www.getresponse.pl/pomoc/narzedzia-e-commerce",
"br": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"pt": "https://www.getresponse.com/pt/ajuda/ferramentas-de-e-commerce",
"ru": "https://www.getresponse.com/ru/help-ru/instrumentyi-ecommerce",
"it": "https://www.getresponse.com/it/assistenza/strumenti-ecommerce",
"fr": "https://www.getresponse.com/fr/aide/outils-de-commerce-electronique"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracii = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations",
"pl": "https://www.getresponse.pl/pomoc/integracje",
"ru": "https://www.getresponse.com/ru/help-ru/integracii",
"it": "https://www.getresponse.com/it/assistenza/integrazioni",
"fr": "https://www.getresponse.com/fr/aide/integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegraciiGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-getresponse",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-getresponse",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-getresponse",
"pl": "https://www.getresponse.pl/pomoc/integracje-od-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-atraves-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-getresponse",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-gestiti-da-getresponse",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegraciiZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-by-zapier",
"de": "https://www.getresponse.com/de/hilfe/integrationen-von-zapier",
"es": "https://www.getresponse.com/es/ayuda/integraciones-de-zapier",
"pl": "https://www.getresponse.pl/pomoc/integracje-przez-zapier",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-pelo-zapier",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-zapier",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-zapier",
"fr": "https://www.getresponse.com/fr/aide/integrations-par-zapier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegraciiIApi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrations-and-api",
"de": "https://www.getresponse.com/de/hilfe/integrationen-und-api",
"es": "https://www.getresponse.com/es/ayuda/integraciones-y-api",
"pl": "https://www.getresponse.pl/pomoc/integracje-i-api",
"br": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"pt": "https://www.getresponse.com/pt/ajuda/integracoes-e-api",
"ru": "https://www.getresponse.com/ru/help-ru/integracii-i-api",
"it": "https://www.getresponse.com/it/assistenza/integrazioni-e-api",
"fr": "https://www.getresponse.com/fr/aide/integrations-et-api"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracjaMiedzyStandartnymAkkauntemProduktaGetresponseIPartnerskimAkkauntem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integration-between-standard-and-affiliate-account",
"de": "https://www.getresponse.com/de/hilfe/integration-zwischen-dem-standard-getresponse-und-dem-partnerkonto",
"es": "https://www.getresponse.com/es/ayuda/integracion-entre-la-cuenta-estandar-y-la-cuenta-de-affiliado",
"pl": "https://www.getresponse.pl/pomoc/integracja-pomiedzy-kontem-getresponse-a-kontem-partnerskim",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-miedzy-standartnym-akkauntem-produkta-getresponse-i-partnerskim-akkauntem",
"it": "https://www.getresponse.com/it/assistenza/integrazione-tra-l-account-standard-et-l-account-affiliato",
"fr": "https://www.getresponse.com/fr/aide/integration-entre-le-compte-standard-et-le-compte-affilie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIntegracjaPlatyozhnychSistem = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/integrating-payment-processors",
"de": "https://www.getresponse.com/de/hilfe/integration-von-zahlungsabwicklern",
"es": "https://www.getresponse.com/es/ayuda/integrando-los-procesadores-de-pagos",
"pl": "https://www.getresponse.pl/pomoc/integrowanie-systemow-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"pt": "https://www.getresponse.com/pt/ajuda/integracao-de-processadores-de-pagamento",
"ru": "https://www.getresponse.com/ru/help-ru/integracja-platyozhnych-sistem",
"it": "https://www.getresponse.com/it/assistenza/integrare-processori-di-pagamento",
"fr": "https://www.getresponse.com/fr/aide/integrations-des-processeurs-de-paiement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIspolzovanieFtp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/using-ftp",
"de": "https://www.getresponse.com/de/hilfe/using-ftp",
"es": "https://www.getresponse.com/es/ayuda/using-ftp",
"pl": "https://www.getresponse.pl/pomoc/korzystanie-z-ftp",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-ftp",
"it": "https://www.getresponse.com/it/assistenza/utilizzo-dell-ftp",
"fr": "https://www.getresponse.com/fr/aide/utilisation-de-ftp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryIspolzovanieSobstvennyhDomenovDljaLendingovVersiaLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-domains-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierte-domains-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/dominios-personalizados-para-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/wlasne-domeny-dla-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/dominios-personalizados-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/ispolzovanie-sobstvennyh-domenov-dlja-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/domini-personalizzati-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKvalifikacijaLidov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/lead-qualifying",
"de": "https://www.getresponse.com/de/hilfe/lead-qualifikation",
"es": "https://www.getresponse.com/es/ayuda/calificacion-del-cliente-potencial",
"pl": "https://www.getresponse.pl/pomoc/kwalifikowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"pt": "https://www.getresponse.com/pt/ajuda/qualificacao-de-lead",
"ru": "https://www.getresponse.com/ru/help-ru/kvalifikacija-lidov",
"it": "https://www.getresponse.com/it/assistenza/lead-idonei",
"fr": "https://www.getresponse.com/fr/aide/qualification-de-prospect"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryKonstruktorSajtov = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/ayuda/website-builder",
"pl": "https://www.getresponse.pl/pomoc/kreator-stron"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMobilnyjePrilozhenieGetresponse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-mobile-app",
"de": "https://www.getresponse.com/de/hilfe/getresponse-mobile-app",
"es": "https://www.getresponse.com/es/ayuda/aplicacion-movil-getresponse",
"pl": "https://www.getresponse.pl/pomoc/aplikacja-mobilna-getresponse",
"br": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"pt": "https://www.getresponse.com/pt/ajuda/aplicativo-movel-da-getrsponse",
"ru": "https://www.getresponse.com/ru/help-ru/mobilnyje-prilozhenie-getresponse",
"it": "https://www.getresponse.com/it/assistenza/getresponse-mobile-app",
"fr": "https://www.getresponse.com/fr/aide/application-mobile-getresponse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryMoiShablony = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/my-templates",
"de": "https://www.getresponse.com/de/hilfe/meine-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/mis-modelos",
"pl": "https://www.getresponse.pl/pomoc/moje-szablony",
"br": "https://www.getresponse.com/pt/ajuda/meus-templates",
"pt": "https://www.getresponse.com/pt/ajuda/meus-templates",
"ru": "https://www.getresponse.com/ru/help-ru/moi-shablony",
"it": "https://www.getresponse.com/it/assistenza/i-miei-template",
"fr": "https://www.getresponse.com/fr/aide/mes-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNastraivaemyePolya = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/custom-fields",
"de": "https://www.getresponse.com/de/hilfe/benutzerdefinierten-felder",
"es": "https://www.getresponse.com/es/ayuda/campos-personalizados",
"pl": "https://www.getresponse.pl/pomoc/pola-dodatkowe",
"br": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"pt": "https://www.getresponse.com/pt/ajuda/campos-costumizados",
"ru": "https://www.getresponse.com/ru/help-ru/nastraivaemye-polya",
"it": "https://www.getresponse.com/it/assistenza/campi-personalizzati-liste",
"fr": "https://www.getresponse.com/fr/aide/champs-personnalises"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNastroykiRassylki = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/newsletter-settings",
"de": "https://www.getresponse.com/de/hilfe/newsletter-einstellungen",
"es": "https://www.getresponse.com/es/ayuda/ajustes-del-newsletter",
"pl": "https://www.getresponse.pl/pomoc/ustawienia-newslettera",
"br": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"pt": "https://www.getresponse.com/pt/ajuda/configuracoes-de-newsletter",
"ru": "https://www.getresponse.com/ru/help-ru/nastroyki-rassylki",
"it": "https://www.getresponse.com/it/assistenza/le-impostazioni-delle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/parametres-de-la-newsletter"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotyCOtchjotami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-reports",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-reports",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-informes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-raportami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-c-otchjotami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-report",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySGetresponseMax = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-getresponse-max",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-getresponse-max",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-getresponse-max",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-getresponse-max",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-getresponse-max",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-getresponse-max",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-a-getresponse-max",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-getresponse-max"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySAvtootvetchikami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-autorespondern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-agli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySVebinarami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-webinars",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-webinaren",
"es": "https://www.getresponse.com/es/ayuda/como-empezar-con-los-webinars",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-webinarami",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-os-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-vebinarami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-webinar",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-webinaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySVoronkojKonversii = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-conversion-funnel",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-conversion-funnel",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-embudo-de-conversion",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-lejkiem-konwersji",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-os-funis-de-conversao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-voronkoj-konversii",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-ai-conversion-funnel",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-funnels-de-conversion"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySIntegracijami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-integrations",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-integrationen",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-integraciones",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-integracjami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-integracoes",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-integracijami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-integrazioni",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-integrations"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySKonstruktoromSajtov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-dem-website-builder",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-kreatorem-stron",
"br": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/comecando-com-o-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-konstruktorom-sajtov",
"it": "https://www.getresponse.com/it/assistenza/cominciando-con-il-website-builder",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySLendingamiVersiaLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySMarketingovoyAvtomatizatsiyey = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-marketing-automation",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-marketingautomatisierung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-automatizacion-del-marketing",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-marketing-automation",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-a-automatizacao",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-marketingovoy-avtomatizatsiyey",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-all-automation",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-l-automatisation-du-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySOpciyamiUpravleniyaAkkauntom = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-managing-account",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-der-kontoverwaltung",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-la-gestion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-w-zarzadzaniu-kontem",
"br": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"pt": "https://www.getresponse.com/pt/ajuda/como-gerenciar-a-sua-conta",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-opciyami-upravleniya-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alla-gestione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-la-gestion-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySRassylkami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-newsletters",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-newslettern",
"es": "https://www.getresponse.com/es/ayuda/empezando-con-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/como-trabalhar-com-as-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/guida-introduttiva-alle-newsletter",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNachaloRabotySFormamiIVsplyvayushhimiOknami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getting-started-with-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/erste-schritte-mit-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/primeros-pasos-con-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki-z-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/introducao-a-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/nachalo-raboty-s-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/introduzione-a-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/premiers-pas-avec-les-formulaires-et-les-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryNovyeLendingi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/new-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/neue-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/new-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/nowe-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/novas-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/novye-lendingi",
"it": "https://www.getresponse.com/it/assistenza/nuove-landing-page",
"fr": "https://www.getresponse.com/fr/aide/new-landing-pages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOnlajnKursy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/online-courses",
"de": "https://www.getresponse.com/de/hilfe/online-kurse",
"es": "https://www.getresponse.com/es/ayuda/cursos-online",
"pl": "https://www.getresponse.pl/pomoc/kursy-online",
"br": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"pt": "https://www.getresponse.com/pt/ajuda/cursos-on-line",
"ru": "https://www.getresponse.com/ru/help-ru/onlajn-kursy",
"it": "https://www.getresponse.com/it/assistenza/corsi-online",
"fr": "https://www.getresponse.com/fr/aide/cours-en-ligne"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOtmenaPodpiski = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/cancelling-a-subscription",
"de": "https://www.getresponse.com/de/hilfe/abonnement-kuendigen",
"es": "https://www.getresponse.com/es/ayuda/cancelacion-de-una-suscripcion",
"pl": "https://www.getresponse.pl/pomoc/anulowanie-subskrypcji",
"br": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"pt": "https://www.getresponse.com/pt/ajuda/cancelamento-da-assinatura",
"ru": "https://www.getresponse.com/ru/help-ru/otmena-podpiski",
"it": "https://www.getresponse.com/it/assistenza/cancellazione-della-sottoscrizione",
"fr": "https://www.getresponse.com/fr/aide/annulation-d-un-abonnement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryOtchety = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/reports",
"de": "https://www.getresponse.com/de/hilfe/statistiken",
"es": "https://www.getresponse.com/es/ayuda/informes",
"pl": "https://www.getresponse.pl/pomoc/raporty",
"br": "https://www.getresponse.com/pt/ajuda/relatorios",
"pt": "https://www.getresponse.com/pt/ajuda/relatorios",
"ru": "https://www.getresponse.com/ru/help-ru/otchety",
"it": "https://www.getresponse.com/it/assistenza/report",
"fr": "https://www.getresponse.com/fr/aide/rapports"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryParametryAvtootvetchikov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adjusting-your-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/anpassen-ihrer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/ajuste-tus-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/dostosowywanie-autoresponderow",
"br": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/ajustando-as-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/parametry-avtootvetchikov",
"it": "https://www.getresponse.com/it/assistenza/la-sistemazione-degli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/reglage-de-vos-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPartnerskieProgrammy = (locale) => {
    const alternates = {
"br": "https://www.getresponse.com/pt/ajuda/programas-de-parceria",
"pt": "https://www.getresponse.com/pt/ajuda/programas-de-parceria"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPartnerstkieProgrammy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-programs",
"de": "https://www.getresponse.com/de/hilfe/affiliate-programme-affiliate-programme",
"es": "https://www.getresponse.com/es/ayuda/programas-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/programy-partnerskie-programy-partnerskie",
"ru": "https://www.getresponse.com/ru/help-ru/partnerstkie-programmy",
"it": "https://www.getresponse.com/it/assistenza/programmi-di-affiliazione",
"fr": "https://www.getresponse.com/fr/aide/programmes-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPartnerskijMarketing = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/affiliate-marketing",
"de": "https://www.getresponse.com/de/hilfe/affiliate-marketing",
"es": "https://www.getresponse.com/es/ayuda/marketing-de-afiliados",
"pl": "https://www.getresponse.pl/pomoc/marketing-afiliacyjny",
"br": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"pt": "https://www.getresponse.com/pt/ajuda/marketing-de-afiliados",
"ru": "https://www.getresponse.com/ru/help-ru/partnerskij-marketing",
"it": "https://www.getresponse.com/it/assistenza/affiliate-marketing",
"fr": "https://www.getresponse.com/fr/aide/marketing-d-affiliation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPoiskKontaktov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/search-contacts",
"de": "https://www.getresponse.com/de/hilfe/kontakte-suchen",
"es": "https://www.getresponse.com/es/ayuda/buscar-contactos",
"pl": "https://www.getresponse.pl/pomoc/wyszukiwanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"pt": "https://www.getresponse.com/pt/ajuda/procurar-contatos",
"ru": "https://www.getresponse.com/ru/help-ru/poisk-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/cerca-contatti",
"fr": "https://www.getresponse.com/fr/aide/recherche-de-contacts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPoljaSoglasija = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/consent-fields",
"de": "https://www.getresponse.com/de/hilfe/einwilligungsfelder",
"es": "https://www.getresponse.com/es/ayuda/campos-de-consentimiento",
"pl": "https://www.getresponse.pl/pomoc/pola-zgody",
"br": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"pt": "https://www.getresponse.com/pt/ajuda/campos-de-consentimento",
"ru": "https://www.getresponse.com/ru/help-ru/polja-soglasija",
"it": "https://www.getresponse.com/it/assistenza/campi-del-consenso",
"fr": "https://www.getresponse.com/fr/aide/champs-de-consentement"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPoryadokRaschetaICeny = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/pricing-and-billing",
"de": "https://www.getresponse.com/de/hilfe/preise-und-abrechnung",
"es": "https://www.getresponse.com/es/ayuda/precios-y-facturacion",
"pl": "https://www.getresponse.pl/pomoc/cennik-i-platnosci",
"br": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"pt": "https://www.getresponse.com/pt/ajuda/preco-e-cobranca",
"ru": "https://www.getresponse.com/ru/help-ru/poryadok-rascheta-i-ceny",
"it": "https://www.getresponse.com/it/assistenza/prezzi-e-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/tarification-et-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPoslePokupki = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/post-purchase",
"de": "https://www.getresponse.com/de/hilfe/nach-dem-kauf",
"es": "https://www.getresponse.com/es/ayuda/pos-compra",
"pl": "https://www.getresponse.pl/pomoc/po-zakupie",
"br": "https://www.getresponse.com/pt/ajuda/pos-compra",
"pt": "https://www.getresponse.com/pt/ajuda/pos-compra",
"ru": "https://www.getresponse.com/ru/help-ru/posle-pokupki",
"it": "https://www.getresponse.com/it/assistenza/post-vendita",
"fr": "https://www.getresponse.com/fr/aide/post-achat"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPochtaIDomeny = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/emails-and-domains",
"de": "https://www.getresponse.com/de/hilfe/e-mails-und-domains",
"es": "https://www.getresponse.com/es/ayuda/emails-y-dominios",
"pl": "https://www.getresponse.pl/pomoc/emaile-i-domeny",
"br": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"pt": "https://www.getresponse.com/pt/ajuda/e-mails-e-dominios",
"ru": "https://www.getresponse.com/ru/help-ru/pochta-i-domeny",
"it": "https://www.getresponse.com/it/assistenza/email-e-domini",
"fr": "https://www.getresponse.com/fr/aide/e-mails-et-domaines"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPravilaOtobrazeniyaFormIVsplyvayushihOkon = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms-and-popups-display-rules",
"de": "https://www.getresponse.com/de/hilfe/formulare-und-popups-anzeigeregeln",
"es": "https://www.getresponse.com/es/ayuda/reglas-de-visualizacion-formularios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/formularze-i-pop-upy-reguly-wyswietlania",
"br": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"pt": "https://www.getresponse.com/pt/ajuda/regras-de-exibicao-de-formularios-e-pop-ups",
"ru": "https://www.getresponse.com/ru/help-ru/pravila-otobrazeniya-form-i-vsplyvayushih-okon",
"it": "https://www.getresponse.com/it/assistenza/regole-di-visualizzazione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/regles-d-affichage-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPremialnyeRassylki = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/premium-newsletters",
"de": "https://www.getresponse.com/de/hilfe/premium-newsletter",
"es": "https://www.getresponse.com/es/ayuda/newsletters-premium",
"pl": "https://www.getresponse.pl/pomoc/newslettery-premium",
"br": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"pt": "https://www.getresponse.com/pt/ajuda/newsletters-premium",
"ru": "https://www.getresponse.com/ru/help-ru/premialnye-rassylki",
"it": "https://www.getresponse.com/it/assistenza/newsletter-premium",
"fr": "https://www.getresponse.com/fr/aide/newsletters-premium"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrivetstvie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/welcome",
"de": "https://www.getresponse.com/de/hilfe/begrusung",
"es": "https://www.getresponse.com/es/ayuda/bienvenida",
"pl": "https://www.getresponse.pl/pomoc/powitanie",
"br": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"pt": "https://www.getresponse.com/pt/ajuda/boas-vindas",
"ru": "https://www.getresponse.com/ru/help-ru/privetstvie",
"it": "https://www.getresponse.com/it/assistenza/benvenuto",
"fr": "https://www.getresponse.com/fr/aide/bienvenue"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryPrilozhenieGetresponseWebinars = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/getresponse-webinars",
"de": "https://www.getresponse.com/de/hilfe/getresponse-webinare",
"es": "https://www.getresponse.com/es/ayuda/getresponse-webinars",
"pl": "https://www.getresponse.pl/pomoc/getresponse-webinars",
"br": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"pt": "https://www.getresponse.com/pt/ajuda/webinars-da-getresponse",
"ru": "https://www.getresponse.com/ru/help-ru/prilozhenie-getresponse-webinars",
"it": "https://www.getresponse.com/it/assistenza/getresponse-webinar",
"fr": "https://www.getresponse.com/fr/aide/getresponse-webinars"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProdazhiIPromo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sales-promotions",
"de": "https://www.getresponse.com/de/hilfe/verkaufsaktionen",
"es": "https://www.getresponse.com/es/ayuda/promociones-de-ventas",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz",
"br": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"pt": "https://www.getresponse.com/pt/ajuda/promocoes-de-vendas",
"ru": "https://www.getresponse.com/ru/help-ru/prodazhi-i-promo",
"it": "https://www.getresponse.com/it/assistenza/promozioni-di-vendita",
"fr": "https://www.getresponse.com/fr/aide/promotions"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryProfilAvtora = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/creators-profile",
"de": "https://www.getresponse.com/de/hilfe/creator-profil",
"es": "https://www.getresponse.com/es/ayuda/perfil-del-creador",
"pl": "https://www.getresponse.pl/pomoc/profil-tworcy",
"br": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"pt": "https://www.getresponse.com/pt/ajuda/perfil-do-criador",
"ru": "https://www.getresponse.com/ru/help-ru/profil-avtora",
"it": "https://www.getresponse.com/it/assistenza/profilo-del-creator",
"fr": "https://www.getresponse.com/fr/aide/profil-du-createur"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRabotaSShablonami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-templates",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-vorlagen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-modelos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-szablonami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-templates",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-shablonami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-i-template",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-modeles"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRabotaSElementami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/working-with-elements",
"de": "https://www.getresponse.com/de/hilfe/arbeiten-mit-elementen",
"es": "https://www.getresponse.com/es/ayuda/trabajando-con-elementos",
"pl": "https://www.getresponse.pl/pomoc/praca-z-elementami",
"br": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"pt": "https://www.getresponse.com/pt/ajuda/trabalhando-com-elementos",
"ru": "https://www.getresponse.com/ru/help-ru/rabota-s-elementami",
"it": "https://www.getresponse.com/it/assistenza/lavorare-con-gli-elementi",
"fr": "https://www.getresponse.com/fr/aide/travailler-avec-des-elements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRedaktorKonstrykoraSaitov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-builder-editor",
"de": "https://www.getresponse.com/de/hilfe/website-builder-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-del-website-builder",
"pl": "https://www.getresponse.pl/pomoc/edytor-kreatora-stron",
"br": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"pt": "https://www.getresponse.com/pt/ajuda/website-builder-editor",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-konstrykora-saitov",
"it": "https://www.getresponse.com/it/assistenza/editor-del-website-builder",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-creation-de-sites-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRedaktorKursov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/courses-editor",
"de": "https://www.getresponse.com/de/hilfe/kurseditor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-cursos",
"pl": "https://www.getresponse.pl/pomoc/edytor-kursow",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-cursos",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-kursov",
"it": "https://www.getresponse.com/it/assistenza/editor-di-corsi",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-cours"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRedaktorLendingovVersiaLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/landing-page-editor",
"de": "https://www.getresponse.com/de/hilfe/landing-page-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-la-landing-page",
"pl": "https://www.getresponse.pl/pomoc/edytor-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-lendingov-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/l-editor-delle-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryRedaktorSoobshenij = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/message-editor",
"de": "https://www.getresponse.com/de/hilfe/nachrichten-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosci",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem",
"ru": "https://www.getresponse.com/ru/help-ru/redaktor-soobshenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter",
"fr": "https://www.getresponse.com/fr/aide/editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReklamaVFacebookReklamaVFacebook = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/facebook-ads",
"de": "https://www.getresponse.com/de/hilfe/facebook-ads-facebook-ads",
"es": "https://www.getresponse.com/es/ayuda/facebook-ads",
"pl": "https://www.getresponse.pl/pomoc/facebook-ads-pl",
"br": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"pt": "https://www.getresponse.com/pt/ajuda/anuncios-do-facebook",
"ru": "https://www.getresponse.com/ru/help-ru/reklama-v-facebook-reklama-v-facebook",
"it": "https://www.getresponse.com/it/assistenza/facebook-ads-facebook-ads",
"fr": "https://www.getresponse.com/fr/aide/annonces-facebook"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReshenieProblemPriOplate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-billing-issues",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-bei-abrechnungsproblemen",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-de-facturacion",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-de-faturamento",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-pri-oplate",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-la-fatturazione",
"fr": "https://www.getresponse.com/fr/aide/resolution-des-problemes-de-facturation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryResheniyeProblemSAvtootvetchikami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-autoresponders",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-autoresponder",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-autoresponders",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-autoresponderami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-autorrespostas",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-avtootvetchikami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-gli-autoresponder",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-autorepondeurs"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReshenieProblemSLendingamiVersiaLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-landing-pages",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-landing-pages",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-landing-pages",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-landing-pages",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-para-landing-pages",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-lendingami-versia-legacy",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-landing-page"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryResheniyeProblemSProcessamiAvtomatizacii = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-and-fixing-workflows",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-und-reparieren-von-workflows",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-y-reparacion-de-los-flujos-de-trabajo",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-cyklami-automation",
"br": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"pt": "https://www.getresponse.com/pt/ajuda/resolucao-de-problemas-e-correcao-de-fluxos-de-trabalho",
"ru": "https://www.getresponse.com/ru/help-ru/resheniye-problem-s-processami-avtomatizacii",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-i-workflow",
"fr": "https://www.getresponse.com/fr/aide/depannage-et-correction-des-workflows"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryReshenieProblemSRassylkami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-newsletters",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-newsletter",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-los-newsletters",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-z-newsletterami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-newsletters",
"ru": "https://www.getresponse.com/ru/help-ru/reshenie-problem-s-rassylkami",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-newsletter",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-newsletters"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySamostojatelnoyeDobavlenieKontaktov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/adding-contacts-yourself",
"de": "https://www.getresponse.com/de/hilfe/kontakte-selbst-hinzufuegen",
"es": "https://www.getresponse.com/es/ayuda/anadiendo-contactos-tu-mismo",
"pl": "https://www.getresponse.pl/pomoc/samodzielne-dodawanie-kontaktow",
"br": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"pt": "https://www.getresponse.com/pt/ajuda/adicionando-contatos-por-si-proprio",
"ru": "https://www.getresponse.com/ru/help-ru/samostojatelnoye-dobavlenie-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/aggiungere-contatti",
"fr": "https://www.getresponse.com/fr/aide/ajouter-des-contacts-vous-meme"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySozdanieSpiska = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/setting-up-a-list",
"de": "https://www.getresponse.com/de/hilfe/liste-erstellen",
"es": "https://www.getresponse.com/es/ayuda/preparar-una-lista",
"pl": "https://www.getresponse.pl/pomoc/przygotowanie-i-tworzenie-listy",
"br": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"pt": "https://www.getresponse.com/pt/ajuda/configuracao-de-lista",
"ru": "https://www.getresponse.com/ru/help-ru/sozdanie-spiska",
"it": "https://www.getresponse.com/it/assistenza/creazione-delle-liste",
"fr": "https://www.getresponse.com/fr/aide/creer-une-liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySootvetstvieTrebovanijamIReputacijaAkkaunta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/compliance-and-account-reputation",
"de": "https://www.getresponse.com/de/hilfe/compliance-und-reputation-des-kontos",
"es": "https://www.getresponse.com/es/ayuda/cumplimiento-y-reputacion-de-la-cuenta",
"pl": "https://www.getresponse.pl/pomoc/regulamin-i-reputacja-konta",
"br": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"pt": "https://www.getresponse.com/pt/ajuda/conformidade-e-reputacao-da-conta",
"ru": "https://www.getresponse.com/ru/help-ru/sootvetstvie-trebovanijam-i-reputacija-akkaunta",
"it": "https://www.getresponse.com/it/assistenza/conformita-e-reputazione-dell-account",
"fr": "https://www.getresponse.com/fr/aide/conformite-et-reputation-du-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySpiski = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/assistenza/liste"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategorySpiskiIskljucheniya = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/suppression-lists",
"de": "https://www.getresponse.com/de/hilfe/unterdrueckungslisten",
"es": "https://www.getresponse.com/es/ayuda/listas-de-supresion",
"pl": "https://www.getresponse.pl/pomoc/listy-wykluczen",
"br": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"pt": "https://www.getresponse.com/pt/ajuda/listas-de-supressao",
"ru": "https://www.getresponse.com/ru/help-ru/spiski-iskljucheniya",
"it": "https://www.getresponse.com/it/assistenza/liste-di-soppressione",
"fr": "https://www.getresponse.com/fr/aide/listes-de-suppression"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStaryjRedaktorSoobshhenij = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/legacy-message-editor",
"de": "https://www.getresponse.com/de/hilfe/legacy-editor",
"es": "https://www.getresponse.com/es/ayuda/editor-de-mensajes-del-legado",
"pl": "https://www.getresponse.pl/pomoc/edytor-wiadomosc-legacy",
"br": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"pt": "https://www.getresponse.com/pt/ajuda/editor-de-mensagem-antigo",
"ru": "https://www.getresponse.com/ru/help-ru/staryj-redaktor-soobshhenij",
"it": "https://www.getresponse.com/it/assistenza/l-editor-di-newsletter-legacy",
"fr": "https://www.getresponse.com/fr/aide/ancien-editeur-de-messages"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatistikaImporta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/import-statistics",
"de": "https://www.getresponse.com/de/hilfe/import-statistiken",
"es": "https://www.getresponse.com/es/ayuda/estadisticas-de-importaciones",
"pl": "https://www.getresponse.pl/pomoc/statystyki-importow",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-de-importacao",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-importa",
"it": "https://www.getresponse.com/it/assistenza/statistiche-importazione",
"fr": "https://www.getresponse.com/fr/aide/statistiques-d-importation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryStatistikaSajta = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/website-statistics",
"de": "https://www.getresponse.com/de/hilfe/website-statistiken",
"pl": "https://www.getresponse.pl/pomoc/statystyki-strony",
"br": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"pt": "https://www.getresponse.com/pt/ajuda/estatisticas-do-site",
"ru": "https://www.getresponse.com/ru/help-ru/statistika-sajta",
"it": "https://www.getresponse.com/it/assistenza/statistiche-del-sito-web",
"fr": "https://www.getresponse.com/fr/aide/statistiques-du-site-web"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTegiIBally = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/tags-and-scoring",
"de": "https://www.getresponse.com/de/hilfe/tags-und-scoring",
"es": "https://www.getresponse.com/es/ayuda/etiquetas-y-puntuacion",
"pl": "https://www.getresponse.pl/pomoc/tagi-i-scoring",
"br": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"pt": "https://www.getresponse.com/pt/ajuda/tags-e-pontuacao",
"ru": "https://www.getresponse.com/ru/help-ru/tegi-i-bally",
"it": "https://www.getresponse.com/it/assistenza/tag-e-scoring",
"fr": "https://www.getresponse.com/fr/aide/tags-et-scoring"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryTranzakcionnyePisma = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/transactional-emails",
"pl": "https://www.getresponse.pl/pomoc/emaile-transakcyjne",
"ru": "https://www.getresponse.com/ru/help-ru/tranzakcionnye-pisma",
"it": "https://www.getresponse.com/it/assistenza/e-mail-transazionali",
"fr": "https://www.getresponse.com/fr/aide/e-mails-transactionnels"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUpravlenieAkkauntom = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/manage-account",
"de": "https://www.getresponse.com/de/hilfe/konto-verwalten",
"es": "https://www.getresponse.com/es/ayuda/administrar-cuenta",
"pl": "https://www.getresponse.pl/pomoc/zarzadzaj-kontem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-conta",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/gestisci-account",
"fr": "https://www.getresponse.com/fr/aide/gerez-votre-compte"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUpravlenieKomandoj = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/team-management",
"de": "https://www.getresponse.com/de/hilfe/team-verwaltung",
"es": "https://www.getresponse.com/es/ayuda/gestion-del-equipo",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-zespolem",
"br": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciamento-de-equipe",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komandoj",
"it": "https://www.getresponse.com/it/assistenza/gestione-del-team",
"fr": "https://www.getresponse.com/fr/aide/gestion-d-equipe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUpravlenieKomnatojVebinara = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-the-webinar-room",
"de": "https://www.getresponse.com/de/hilfe/verwalten-des-webinar-raums",
"es": "https://www.getresponse.com/es/ayuda/administracion-de-la-sala-de-webinars",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-pokojem-webinaru",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-a-sala-de-webinars",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-komnatoj-vebinara",
"it": "https://www.getresponse.com/it/assistenza/gestire-il-webinar-room",
"fr": "https://www.getresponse.com/fr/aide/gestion-de-la-salle-de-webinaire"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUpravleniePlatezhami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-payments",
"de": "https://www.getresponse.com/de/hilfe/zahlungen-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestionando-los-pagos",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-platnosciami",
"br": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"pt": "https://www.getresponse.com/pt/ajuda/gerir-os-pagamentos",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-platezhami",
"it": "https://www.getresponse.com/it/assistenza/gestione-dei-pagamenti",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-paiements"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryYpravlenieStranitsamiVKonstryktoreSaitov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-pages-in-the-website-builder",
"de": "https://www.getresponse.com/de/hilfe/seiten-im-website-builder-verwalten",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-paginas-en-el-website-builder",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-stronami-w-kreatorze-stron",
"br": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciando-paginas-no-website-builder",
"ru": "https://www.getresponse.com/ru/help-ru/ypravlenie-stranitsami-v-konstryktore-saitov",
"it": "https://www.getresponse.com/it/assistenza/gestione-delle-pagine-nel-website-builder",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-pages-dans-le-createur-de-site-internet"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUpravlenieFormamiIVsplyvayushhimiOknami = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/managing-forms-and-popups",
"de": "https://www.getresponse.com/de/hilfe/verwalten-von-formularen-und-popups",
"es": "https://www.getresponse.com/es/ayuda/gestion-de-formulatios-y-ventanas-emergentes",
"pl": "https://www.getresponse.pl/pomoc/zarzadzanie-formularzami-i-popupami",
"br": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"pt": "https://www.getresponse.com/pt/ajuda/gerenciar-formularios-e-popups",
"ru": "https://www.getresponse.com/ru/help-ru/upravlenie-formami-i-vsplyvayushhimi-oknami",
"it": "https://www.getresponse.com/it/assistenza/gestione-di-moduli-e-popup",
"fr": "https://www.getresponse.com/fr/aide/gestion-des-formulaires-et-des-pop-ups"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryUsloviya = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/conditions",
"de": "https://www.getresponse.com/de/hilfe/bedingungen",
"es": "https://www.getresponse.com/es/ayuda/condiciones",
"pl": "https://www.getresponse.pl/pomoc/warunki",
"br": "https://www.getresponse.com/pt/ajuda/condicoes",
"pt": "https://www.getresponse.com/pt/ajuda/condicoes",
"ru": "https://www.getresponse.com/ru/help-ru/usloviya",
"it": "https://www.getresponse.com/it/assistenza/condizioni",
"fr": "https://www.getresponse.com/fr/aide/conditions-fr"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFajlyIIzobrazheniya = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/files-and-images",
"de": "https://www.getresponse.com/de/hilfe/dateien-und-bilder",
"es": "https://www.getresponse.com/es/ayuda/archivos-e-imagenes",
"pl": "https://www.getresponse.pl/pomoc/pliki-i-obrazy",
"br": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"pt": "https://www.getresponse.com/pt/ajuda/arquivos-e-imagens",
"ru": "https://www.getresponse.com/ru/help-ru/fajly-i-izobrazheniya",
"it": "https://www.getresponse.com/it/assistenza/file-e-immagini",
"fr": "https://www.getresponse.com/fr/aide/fichiers-et-images"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFidyTovarov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/product-feeds",
"de": "https://www.getresponse.com/de/hilfe/produkt-feeds",
"es": "https://www.getresponse.com/es/ayuda/feeds-de-productos",
"pl": "https://www.getresponse.pl/pomoc/pliki-danych",
"br": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"pt": "https://www.getresponse.com/pt/ajuda/alimentadores-de-produtos",
"ru": "https://www.getresponse.com/ru/help-ru/fidy-tovarov",
"it": "https://www.getresponse.com/it/assistenza/feed-prodotti",
"fr": "https://www.getresponse.com/fr/aide/flux-de-produits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryFormyVersijaLegacy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/forms",
"de": "https://www.getresponse.com/de/hilfe/formulare",
"es": "https://www.getresponse.com/es/ayuda/formularios",
"pl": "https://www.getresponse.pl/pomoc/formularze",
"br": "https://www.getresponse.com/pt/ajuda/formularios",
"pt": "https://www.getresponse.com/pt/ajuda/formularios",
"ru": "https://www.getresponse.com/ru/help-ru/formy-versija-legacy",
"it": "https://www.getresponse.com/it/assistenza/moduli",
"fr": "https://www.getresponse.com/fr/aide/formulaires"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryChastoVstrechajushhiesjaVoprosyPoStatistike = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/troubleshooting-for-statistics",
"de": "https://www.getresponse.com/de/hilfe/fehlerbehebung-fuer-statistiken",
"es": "https://www.getresponse.com/es/ayuda/solucion-de-problemas-para-las-estadisticas",
"pl": "https://www.getresponse.pl/pomoc/rozwiazywanie-problemow-ze-statystykami",
"br": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"pt": "https://www.getresponse.com/pt/ajuda/solucao-de-problemas-para-estatisticas",
"ru": "https://www.getresponse.com/ru/help-ru/chasto-vstrechajushhiesja-voprosy-po-statistike",
"it": "https://www.getresponse.com/it/assistenza/risoluzione-dei-problemi-con-le-statistiche",
"fr": "https://www.getresponse.com/fr/aide/depannage-pour-les-statistiques"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpCategoryChernoviki = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/drafts",
"de": "https://www.getresponse.com/de/hilfe/entwuerfe",
"es": "https://www.getresponse.com/es/ayuda/borradores",
"pl": "https://www.getresponse.pl/pomoc/wersje-robocze",
"br": "https://www.getresponse.com/pt/ajuda/rascunhos",
"pt": "https://www.getresponse.com/pt/ajuda/rascunhos",
"ru": "https://www.getresponse.com/ru/help-ru/chernoviki",
"it": "https://www.getresponse.com/it/assistenza/bozze"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicAnfangen = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicComeIniziare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicComecarATrabalhar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicCommencer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicCriarLeads = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicEmailsVersenden = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/send-emails",
"de": "https://www.getresponse.com/de/hilfe/emails-versenden",
"es": "https://www.getresponse.com/es/ayuda/enviar-emails",
"pl": "https://www.getresponse.pl/pomoc/wysylka-emaili",
"br": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"pt": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"ru": "https://www.getresponse.com/ru/help-ru/otpravit-soobshhenie",
"it": "https://www.getresponse.com/it/assistenza/inviare-e-mail",
"fr": "https://www.getresponse.com/fr/aide/envoyer-des-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicEmpezar = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicEnviarEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/send-emails",
"de": "https://www.getresponse.com/de/hilfe/emails-versenden",
"es": "https://www.getresponse.com/es/ayuda/enviar-emails",
"pl": "https://www.getresponse.pl/pomoc/wysylka-emaili",
"br": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"pt": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"ru": "https://www.getresponse.com/ru/help-ru/otpravit-soobshhenie",
"it": "https://www.getresponse.com/it/assistenza/inviare-e-mail",
"fr": "https://www.getresponse.com/fr/aide/envoyer-des-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicEnvoyerDesEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/send-emails",
"de": "https://www.getresponse.com/de/hilfe/emails-versenden",
"es": "https://www.getresponse.com/es/ayuda/enviar-emails",
"pl": "https://www.getresponse.pl/pomoc/wysylka-emaili",
"br": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"pt": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"ru": "https://www.getresponse.com/ru/help-ru/otpravit-soobshhenie",
"it": "https://www.getresponse.com/it/assistenza/inviare-e-mail",
"fr": "https://www.getresponse.com/fr/aide/envoyer-des-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicGenerarProspectos = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicGenerareLead = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicGenerateLeads = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicGenererDesProspects = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicGenerowanieLeadow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicGetStarted = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicInviareEMail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/send-emails",
"de": "https://www.getresponse.com/de/hilfe/emails-versenden",
"es": "https://www.getresponse.com/es/ayuda/enviar-emails",
"pl": "https://www.getresponse.pl/pomoc/wysylka-emaili",
"br": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"pt": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"ru": "https://www.getresponse.com/ru/help-ru/otpravit-soobshhenie",
"it": "https://www.getresponse.com/it/assistenza/inviare-e-mail",
"fr": "https://www.getresponse.com/fr/aide/envoyer-des-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicLeadsGenerieren = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicPierwszeKroki = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicSellAndPromote = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicSendEmails = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/send-emails",
"de": "https://www.getresponse.com/de/hilfe/emails-versenden",
"es": "https://www.getresponse.com/es/ayuda/enviar-emails",
"pl": "https://www.getresponse.pl/pomoc/wysylka-emaili",
"br": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"pt": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"ru": "https://www.getresponse.com/ru/help-ru/otpravit-soobshhenie",
"it": "https://www.getresponse.com/it/assistenza/inviare-e-mail",
"fr": "https://www.getresponse.com/fr/aide/envoyer-des-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicSprzedazIPromocja = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicVenderEPromover = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicVenderYPromover = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicVendereEPromuovere = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicVendreEtPromouvoir = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicVerkaufenUndBewerben = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicWysylkaEmaili = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/send-emails",
"de": "https://www.getresponse.com/de/hilfe/emails-versenden",
"es": "https://www.getresponse.com/es/ayuda/enviar-emails",
"pl": "https://www.getresponse.pl/pomoc/wysylka-emaili",
"br": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"pt": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"ru": "https://www.getresponse.com/ru/help-ru/otpravit-soobshhenie",
"it": "https://www.getresponse.com/it/assistenza/inviare-e-mail",
"fr": "https://www.getresponse.com/fr/aide/envoyer-des-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicNastroitProdazhiIReklamu = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/sell-and-promote",
"de": "https://www.getresponse.com/de/hilfe/verkaufen-und-bewerben",
"es": "https://www.getresponse.com/es/ayuda/vender-y-promover",
"pl": "https://www.getresponse.pl/pomoc/sprzedaz-i-promocja",
"br": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"pt": "https://www.getresponse.com/pt/ajuda/vender-e-promover",
"ru": "https://www.getresponse.com/ru/help-ru/nastroit-prodazhi-i-reklamu",
"it": "https://www.getresponse.com/it/assistenza/vendere-e-promuovere",
"fr": "https://www.getresponse.com/fr/aide/vendre-et-promouvoir"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicNachatRabotuSAkkauntom = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/get-started",
"de": "https://www.getresponse.com/de/hilfe/anfangen",
"es": "https://www.getresponse.com/es/ayuda/empezar",
"pl": "https://www.getresponse.pl/pomoc/pierwsze-kroki",
"br": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"pt": "https://www.getresponse.com/pt/ajuda/comecar-a-trabalhar",
"ru": "https://www.getresponse.com/ru/help-ru/nachat-rabotu-s-akkauntom",
"it": "https://www.getresponse.com/it/assistenza/come-iniziare",
"fr": "https://www.getresponse.com/fr/aide/commencer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicOtpravitSoobshhenie = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/send-emails",
"de": "https://www.getresponse.com/de/hilfe/emails-versenden",
"es": "https://www.getresponse.com/es/ayuda/enviar-emails",
"pl": "https://www.getresponse.pl/pomoc/wysylka-emaili",
"br": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"pt": "https://www.getresponse.com/pt/ajuda/enviar-emails",
"ru": "https://www.getresponse.com/ru/help-ru/otpravit-soobshhenie",
"it": "https://www.getresponse.com/it/assistenza/inviare-e-mail",
"fr": "https://www.getresponse.com/fr/aide/envoyer-des-emails"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteHelpTopicSobratBazuKontaktov = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/help/generate-leads",
"de": "https://www.getresponse.com/de/hilfe/leads-generieren",
"es": "https://www.getresponse.com/es/ayuda/generar-prospectos",
"pl": "https://www.getresponse.pl/pomoc/generowanie-leadow",
"br": "https://www.getresponse.com/pt/ajuda/criar-leads",
"pt": "https://www.getresponse.com/pt/ajuda/criar-leads",
"ru": "https://www.getresponse.com/ru/help-ru/sobrat-bazu-kontaktov",
"it": "https://www.getresponse.com/it/assistenza/generare-lead",
"fr": "https://www.getresponse.com/fr/aide/generer-des-prospects"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostTopol = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/topol.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGroupLeads = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/group-leads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGroupboss = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/groupboss.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPlum = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/plum.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLearnworlds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/learnworlds.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPodia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/podia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostTeachable = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/teachable.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostKajabi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/kajabi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostThinkific = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/thinkific.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostNewsletterGlue = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/newsletter-glue.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostIfttt = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/ifttt.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostJetformbuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/jetformbuilder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSquarespace = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/squarespace.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostClickfunnels = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/clickfunnels.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostInstapage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/instapage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLeadpages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/leadpages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostElementor = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/elementor.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSkyvia = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/skyvia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSuretriggers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/suretriggers.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCallpage = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/callpage.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBizzabo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/bizzabo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostDonorbox = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/donorbox.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostEmailable = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/emailable.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPlayable = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/playable.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLinkshortenerIo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/linkshortener-io.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostDivi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/divi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostContactForm7 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/contact-form-7.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGravityForms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/gravity-forms.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostReferralrock = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/referralrock.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBotpenguin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/botpenguin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSignable = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/signable.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMemberpress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/memberpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMake = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/make.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSocialWifi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/social-wifi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostThrivecart = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/thrivecart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostNiftyimages = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/niftyimages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostHyperise = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/hyperise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBrizy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/brizy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostVidpowr = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/vidpowr.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCleverMessenger = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/clever-messenger.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostWishlistMember = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/wishlist-member.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostN8N = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/n8n.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLandingi = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/landingi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostJotformSign = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/jotform-sign.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGleam = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/gleam.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostZapup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/zapup.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostStripo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/stripo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSurvicate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/survicate.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostNoptin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/noptin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPaperform = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/paperform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSendtric = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/sendtric.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMailtimers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/mailtimers.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCountdownmail = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/countdownmail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostInvolveMe = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/involve-me.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFollowUpBoss = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/follow-up-boss.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostOptinmonster = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/optinmonster.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostOutgrow = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/outgrow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleSheets = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/google-sheets.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFacebookLeadAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/facebook-lead-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBigcommerce2 = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/bigcommerce-2.html",
"en": "https://www.getresponse.com/integrations/bigcommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBigcommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/bigcommerce.html",
"pl": "https://www.getresponse.pl/integracje/bigcommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGrowsurf = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/growsurf.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFomoIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/fomo-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSmsItIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/sms-it-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLeadsbridge = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/leadsbridge.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostApixDrive = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/apix-drive.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostJotform = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/jotform.html",
"pl": "https://www.getresponse.pl/integracje/jotform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostRepozixIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/repozix-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostDigiohIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/digioh-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostWisepops = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/wisepops.html",
"pl": "https://www.getresponse.pl/integracje/wisepops.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCatchapp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/catchapp.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBonjoroIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/bonjoro-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMyvod = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/myvod.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostItcubeCrm = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/itcube-crm.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBouncer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/bouncer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSalesflare = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/salesflare.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostZerobounce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/zerobounce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostOviond = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/oviond.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSavemyleads = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/savemyleads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostIntegrately = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/integrately.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSamcart = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/samcart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostJustuno2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/justuno-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMagento = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/magento.html",
"pl": "https://www.getresponse.pl/integracje/magento.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPrestashop = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/prestashop.html",
"pl": "https://www.getresponse.pl/integracje/prestashop.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCartbounty = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/cartbounty.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostWoocommerce = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/woocommerce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostShopify = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/shopify.html",
"pl": "https://www.getresponse.pl/integracje/shopify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostWordpress = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/wordpress.html",
"pl": "https://www.getresponse.pl/integracje/wordpress.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/zapier.html",
"pl": "https://www.getresponse.pl/integracje/zapier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleAds = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/google-ads.html",
"pl": "https://www.getresponse.pl/integracje/google-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostClickbank = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/clickbank.html",
"en": "https://www.getresponse.com/integrations/clickbank.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFormstack = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/formstack.html",
"en": "https://www.getresponse.com/integrations/formstack-2.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleAnalyticsForLandingPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/google-analytics-for-landing-pages.html",
"en": "https://www.getresponse.com/integrations/google-analytics-for-landing-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostJumpout = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/jumpout.html",
"en": "https://www.getresponse.com/integrations/jumpout.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleTagManager = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/google-tag-manager.html",
"en": "https://www.getresponse.com/integrations/google-tag-manager.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostClickdesk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/clickdesk.html",
"en": "https://www.getresponse.com/integrations/clickdesk.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostVidyard = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/vidyard.html",
"en": "https://www.getresponse.com/integrations/vidyard.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostWistia = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/wistia.html",
"en": "https://www.getresponse.com/integrations/wistia.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostZendesk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/zendesk.html",
"en": "https://www.getresponse.com/integrations/zendesk.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostEventbrite = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/eventbrite.html",
"en": "https://www.getresponse.com/integrations/eventbrite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLiveagent = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/liveagent.html",
"en": "https://www.getresponse.com/integrations/liveagent.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLeadsyncMe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/leadsync-me.html",
"en": "https://www.getresponse.com/integrations/leadsync-me.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMailmunch = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/mailmunch.html",
"en": "https://www.getresponse.com/integrations/mailmunch.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMembermouse = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/membermouse.html",
"en": "https://www.getresponse.com/integrations/membermouse.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostManzana = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/manzana.html",
"en": "https://www.getresponse.com/integrations/manzana.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostNeto = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/neto.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMoonclerk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/moonclerk.html",
"en": "https://www.getresponse.com/integrations/moonclerk.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostOutleads = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/outleads.html",
"en": "https://www.getresponse.com/integrations/outleads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostOptimonk = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/optimonk.html",
"en": "https://www.getresponse.com/integrations/optimonk.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPaypalPayments = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/paypal-payments.html",
"en": "https://www.getresponse.com/integrations/paypal-payments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPaykickstart = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/paykickstart.html",
"en": "https://www.getresponse.com/integrations/paykickstart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSalesforce = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/salesforce.html",
"en": "https://www.getresponse.com/integrations/salesforce.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostQiwi = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/qiwi.html",
"en": "https://www.getresponse.com/integrations/qiwi.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSeedprod = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/seedprod.html",
"en": "https://www.getresponse.com/integrations/seedprod.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSalesforceSync = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/salesforce-sync.html",
"en": "https://www.getresponse.com/integrations/salesforce-data-synchronization.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSlicktext = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/slicktext.html",
"en": "https://www.getresponse.com/integrations/slicktext.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostStripe = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/stripe.html",
"en": "https://www.getresponse.com/integrations/stripe.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSquare = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/square.html",
"en": "https://www.getresponse.com/integrations/square.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostWufoo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/wufoo.html",
"en": "https://www.getresponse.com/integrations/wufoo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGetsitecontrol = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/getsitecontrol.html",
"en": "https://www.getresponse.com/integrations/getsitecontrol.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFacebookPages = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/facebook-pages.html",
"en": "https://www.getresponse.com/integrations/facebook-pages.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostJustuno = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/justuno.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostConvertful = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/convertful.html",
"en": "https://www.getresponse.com/integrations/convertful.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLandingpagepromoter = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/landingpagepromoter.html",
"en": "https://www.getresponse.com/integrations/landingpagepromoter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPabblyConnect = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/pabbly-connect.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMailoptin = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/mailoptin.html",
"en": "https://www.getresponse.com/integrations/mailoptin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostYesinsights = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/yesinsights.html",
"en": "https://www.getresponse.com/integrations/yesinsights.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostOmniconvert = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/omniconvert.html",
"en": "https://www.getresponse.com/integrations/omniconvert.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFacebookPixel = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/facebook-pixel.html",
"en": "https://www.getresponse.com/integrations/facebook-pixel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMixpanel = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/mixpanel.html",
"en": "https://www.getresponse.com/integrations/mixpanel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleContacts = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/google-contacts.html",
"en": "https://www.getresponse.com/integrations/google-contacts.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostZoho = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/zoho.html",
"en": "https://www.getresponse.com/integrations/zoho.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostVisualWebsiteOptimizerVwo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/visual-website-optimizer-vwo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostYandexMetrica = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/yandex-metrica.html",
"en": "https://www.getresponse.com/integrations/yandex-metrica.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostTwitter = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/twitter.html",
"en": "https://www.getresponse.com/integrations/twitter.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostAmazonPaymentsIntegration = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/amazon-payments-integration.html",
"en": "https://www.getresponse.com/integrations/amazon-payments.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostDpd = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/dpd.html",
"en": "https://www.getresponse.com/integrations/dpd.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFacebook = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/facebook.html",
"en": "https://www.getresponse.com/integrations/facebook.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLivehelpnow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/livehelpnow.html",
"en": "https://www.getresponse.com/integrations/livehelpnow.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostNewsletterbreeze = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/newsletterbreeze.html",
"en": "https://www.getresponse.com/integrations/newsletterbreeze.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostQuickemailverification = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/quickemailverification.html",
"en": "https://www.getresponse.com/integrations/quickemailverification.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLivechat = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/livechat.html",
"en": "https://www.getresponse.com/integrations/livechat.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSendowl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/sendowl.html",
"en": "https://www.getresponse.com/integrations/sendowl.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFormdesigner = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/formdesigner.html",
"en": "https://www.getresponse.com/integrations/formdesigner.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostRetently = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/retently.html",
"en": "https://www.getresponse.com/integrations/retently.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFreshbooks = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/freshbooks.html",
"en": "https://www.getresponse.com/integrations/freshbooks-imports.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostEvernote = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/evernote.html",
"en": "https://www.getresponse.com/integrations/evernote.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostHighrise = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/highrise.html",
"en": "https://www.getresponse.com/integrations/highrise-imports.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGmail = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/gmail.html",
"en": "https://www.getresponse.com/integrations/gmail.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostHubspot = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/hubspot.html",
"en": "https://www.getresponse.com/integrations/hubspot.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPicreel = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/picreel.html",
"en": "https://www.getresponse.com/integrations/picreel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostRecurly = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/recurly.html",
"en": "https://www.getresponse.com/integrations/recurly.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPrivy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/privy.html",
"en": "https://www.getresponse.com/integrations/privy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostChargify = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/chargify.html",
"en": "https://www.getresponse.com/integrations/chargify.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFyrebox = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/fyrebox.html",
"en": "https://www.getresponse.com/integrations/fyrebox.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostClickmeeting = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/clickmeeting.html",
"en": "https://www.getresponse.com/integrations/clickmeeting.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostViralsweep = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/viralsweep.html",
"en": "https://www.getresponse.com/integrations/viralsweep.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostInteract = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/interact.html",
"en": "https://www.getresponse.com/integrations/interact.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCapsulecrm = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/capsulecrm.html",
"en": "https://www.getresponse.com/integrations/capsule.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCsCart = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/cs-cart.html",
"en": "https://www.getresponse.com/integrations/cs-cart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPaypalViaZapier = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/paypal-via-zapier.html",
"en": "https://www.getresponse.com/integrations/paypal-via-zapier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostRss = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/rss.html",
"en": "https://www.getresponse.com/integrations/rss.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostShoplo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/shoplo.html",
"en": "https://www.getresponse.com/integrations/shoplo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSumo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/sumo.html",
"en": "https://www.getresponse.com/integrations/sumo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostYoutube = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/youtube.html",
"en": "https://www.getresponse.com/integrations/youtube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostProtimer = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/protimer.html",
"en": "https://www.getresponse.com/integrations/protimer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleContactsViaZapier = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/google-contacts-via-zapier.html",
"en": "https://www.getresponse.com/integrations/google-contacts-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostEtsy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/etsy.html",
"en": "https://www.getresponse.com/integrations/etsy.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLandingcube = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/landingcube.html",
"en": "https://www.getresponse.com/integrations/landingcube.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostKickbox = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/kickbox.html",
"en": "https://www.getresponse.com/integrations/kickbox.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostLightspeed = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/lightspeed.html",
"en": "https://www.getresponse.com/integrations/lightspeed.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPoptin = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/poptin.html",
"en": "https://www.getresponse.com/integrations/poptin.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBravePopupBuilder = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/brave-popup-builder.html",
"en": "https://www.getresponse.com/integrations/brave-popup-builder.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostTextiful = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/textiful.html",
"en": "https://www.getresponse.com/integrations/textiful.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostVimeo = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/vimeo.html",
"en": "https://www.getresponse.com/integrations/vimeo.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostBingAds = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/bing-ads.html",
"en": "https://www.getresponse.com/integrations/bing-ads.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostKissmetrics = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/kissmetrics.html",
"en": "https://www.getresponse.com/integrations/kissmetrics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSlack = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/slack.html",
"en": "https://www.getresponse.com/integrations/slack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFormsite = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/formsite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPaypal = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/paypal.html",
"en": "https://www.getresponse.com/integrations/paypal.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostZencart = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/zencart.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFastMicroPay = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/fast-micro-pay.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostWoorise = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/woorise.html",
"en": "https://www.getresponse.com/integrations/woorise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPayu = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/payu.html",
"en": "https://www.getresponse.com/integrations/payu.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostAdsWorkbench = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/ads-workbench.html",
"en": "https://www.getresponse.com/integrations/ads-workbench.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleAnalytics = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/google-analytics.html",
"en": "https://www.getresponse.com/integrations/google-analytics.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPost123Contactform = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/123contactform.html",
"en": "https://www.getresponse.com/integrations/123contactform.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostEJunkie = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/integracje/e-junkie.html",
"en": "https://www.getresponse.com/integrations/e-junkie.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostEmailHeatmaps = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/email-heatmaps.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostCapsule = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/capsule.html",
"pl": "https://www.getresponse.pl/integracje/capsulecrm.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostGoogleContactsIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/google-contacts-integration.html",
"pl": "https://www.getresponse.pl/integracje/google-contacts-via-zapier.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostAmazonPayments = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/amazon-payments.html",
"pl": "https://www.getresponse.pl/integracje/amazon-payments-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostPhoneSmsIntegration = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/phone-sms-integration.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostEventbriteViaZapier = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/eventbrite-via-zapier.html",
"pl": "https://www.getresponse.pl/integracje/eventbrite.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostVisualWebsiteOptimizer = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/visual-website-optimizer.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostHighriseImports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/highrise-imports.html",
"pl": "https://www.getresponse.pl/integracje/highrise.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFreshbooksImports = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/freshbooks-imports.html",
"pl": "https://www.getresponse.pl/integracje/freshbooks.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostFormstack2 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/formstack-2.html",
"pl": "https://www.getresponse.pl/integracje/formstack.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostSalesforceDataSynchronization = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/salesforce-data-synchronization.html",
"pl": "https://www.getresponse.pl/integracje/salesforce-sync.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostDeadlineFunnel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/deadline-funnel.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteIntegrationsPostMicrosoftDynamics365 = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/integrations/microsoft-dynamics-365.html",
"pl": "https://www.getresponse.pl/integracje/microsoft-dynamics-365.html"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderPortfolio = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/portfolio"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderHochzeit = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/hochzeit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderFreelancer = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/freelancer"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderFirmen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/firmen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderRechtsanwaltKanzlei = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/rechtsanwalt-kanzlei"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderImmobilienmakler = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/immobilienmakler"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderFerienwohnung = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/ferienwohnung"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderSportsWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/sports-website-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderMusicianWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/musician-website-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderTourismWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/tourism-website-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderChurchWebsiteBuilder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/church-website-builder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderSajtDljaBiznesa = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/ru-features/konstruktor-sajtov/sajt-dlja-biznesa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderDlaSzkol = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/dla-szkol"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderDlaOrganizacjiNonProfit = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/dla-organizacji-non-profit"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderPrzedszkolaIZlobki = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/przedszkola-i-zlobki"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderDlaAfiliatow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/dla-afiliatow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderSchule = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/schule"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderUnternehmen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/unternehmen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderRestaurant = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/restaurant"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderGeldVerdienen = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/geld-verdienen"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderSajtDljaRestorana = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/ru-features/konstruktor-sajtov/sajt-dlja-restorana"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderSajtDljaJurista = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/ru-features/konstruktor-sajtov/sajt-dlja-jurista"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderSajtDljaFotografov = (locale) => {
    const alternates = {
"ru": "https://www.getresponse.com/ru/ru-features/konstruktor-sajtov/sajt-dlja-fotografov"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderFotografie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/funktionen/website-erstellen/fotografie"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderAffiliates = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/affiliates"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderRistorante = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/funzionalita/creare-sito-web/ristorante"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderLawFirms = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/law-firms"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderDlaFirm = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/dla-firm"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderDlaBiurNieruchomosci = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/dla-biur-nieruchomosci"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderDlaRestauracji = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/dla-restauracji"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderFotografico = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/funzionalita/creare-sito-web/fotografico"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderSchools = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/schools"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderAziendale = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/funzionalita/creare-sito-web/aziendale"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderBusiness = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/business"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderRealEstate = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/real-estate"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderPhotography = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/photography"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderDlaFotografow = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/funkcjonalnosci/kreator-stron-www/dla-fotografow"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderRestaurants = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/restaurants"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderNonprofits = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/features/website-builder/nonprofits"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteWwwPagesWebsiteBuilderAgenziaImmobiliare = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/funzionalita/creare-sito-web/agenzia-immobiliare"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostWurthModyfPl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/klienci/wurth-modyf-pl",
"en": "https://www.getresponse.com/customers/wurth-modyf"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostEvelineCosmeticsStory = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/eveline-cosmetics-story",
"pl": "https://www.getresponse.pl/klienci/eveline-cosmetics-case-study"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostEvelineCosmeticsCaseStudy = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/klienci/eveline-cosmetics-case-study",
"en": "https://www.getresponse.com/customers/eveline-cosmetics-story"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostWurthModyf = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/wurth-modyf",
"pl": "https://www.getresponse.pl/klienci/wurth-modyf-pl"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostYourParentingMentor = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/your-parenting-mentor",
"fr": "https://www.getresponse.com/fr/customers/your-parenting-mentor",
"en": "https://www.getresponse.com/customers/your-parenting-mentor",
"it": "https://www.getresponse.com/it/customers/your-parenting-mentor",
"de": "https://www.getresponse.com/de/customers/your-parenting-mentor",
"pl": "https://www.getresponse.pl/klienci/your-parenting-mentor",
"pt": "https://www.getresponse.com/pt/customers/your-parenting-mentor"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostLivingFromVision = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/living-from-vision",
"en": "https://www.getresponse.com/customers/living-from-vision",
"fr": "https://www.getresponse.com/fr/customers/living-from-vision",
"it": "https://www.getresponse.com/it/customers/living-from-vision",
"de": "https://www.getresponse.com/de/customers/living-from-vision",
"pl": "https://www.getresponse.pl/klienci/living-from-vision",
"pt": "https://www.getresponse.com/pt/customers/living-from-vision"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostThePhotoReview = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/the-photo-review",
"fr": "https://www.getresponse.com/fr/customers/the-photo-review",
"en": "https://www.getresponse.com/customers/the-photo-review",
"it": "https://www.getresponse.com/it/customers/the-photo-review",
"de": "https://www.getresponse.com/de/customers/the-photo-review",
"pl": "https://www.getresponse.pl/klienci/the-photo-review",
"pt": "https://www.getresponse.com/pt/customers/the-photo-review"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostEcsPublishingGroup = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/ecs-publishing-group",
"fr": "https://www.getresponse.com/fr/customers/ecs-publishing-group",
"en": "https://www.getresponse.com/customers/ecs-publishing-group",
"it": "https://www.getresponse.com/it/customers/ecs-publishing-group",
"de": "https://www.getresponse.com/de/customers/ecs-publishing-group",
"pl": "https://www.getresponse.pl/klienci/ecs-publishing-group",
"pt": "https://www.getresponse.com/pt/customers/ecs-publishing-group"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostLesFrenchiesTravel = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/les-frenchies-travel",
"fr": "https://www.getresponse.com/fr/customers/les-frenchies-travel",
"en": "https://www.getresponse.com/customers/les-frenchies-travel",
"it": "https://www.getresponse.com/it/customers/les-frenchies-travel",
"de": "https://www.getresponse.com/de/customers/les-frenchies-travel",
"pl": "https://www.getresponse.pl/klienci/les-frenchies-travel",
"pt": "https://www.getresponse.com/pt/customers/les-frenchies-travel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostAutismPartnershipFoundation = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/autism-partnership-foundation"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostGog = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/customers/gog",
"es": "https://www.getresponse.com/es/customers/gog",
"en": "https://www.getresponse.com/customers/gog",
"fr": "https://www.getresponse.com/fr/customers/gog",
"de": "https://www.getresponse.com/de/customers/gog",
"it": "https://www.getresponse.com/it/customers/gog",
"pl": "https://www.getresponse.pl/klienci/gog"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostLivrariasCuritiba = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/livrarias-curitiba",
"pt": "https://www.getresponse.com/pt/customers/livrarias-curitiba",
"en": "https://www.getresponse.com/customers/livrarias-curitiba",
"pl": "https://www.getresponse.pl/klienci/livrarias-curitiba",
"de": "https://www.getresponse.com/de/customers/livrarias-curitiba",
"fr": "https://www.getresponse.com/fr/customers/livrarias-curitiba",
"it": "https://www.getresponse.com/it/customers/livrarias-curitiba-case-study"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostRedHotMarketing = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/red-hot-marketing",
"en": "https://www.getresponse.com/customers/red-hot-marketing-case-study",
"it": "https://www.getresponse.com/it/customers/red-hot-marketing",
"de": "https://www.getresponse.com/de/customers/red-hot-marketing-fallstudie",
"fr": "https://www.getresponse.com/fr/customers/red-hot-marketing",
"pl": "https://www.getresponse.pl/klienci/red-hot-marketing",
"pt": "https://www.getresponse.com/pt/customers/red-hot-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostLivrariasCuritibaCaseStudy = (locale) => {
    const alternates = {
"it": "https://www.getresponse.com/it/customers/livrarias-curitiba-case-study",
"pt": "https://www.getresponse.com/pt/customers/livrarias-curitiba",
"en": "https://www.getresponse.com/customers/livrarias-curitiba",
"pl": "https://www.getresponse.pl/klienci/livrarias-curitiba",
"de": "https://www.getresponse.com/de/customers/livrarias-curitiba",
"fr": "https://www.getresponse.com/fr/customers/livrarias-curitiba",
"es": "https://www.getresponse.com/es/customers/livrarias-curitiba"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostRedHotMarketingFallstudie = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/customers/red-hot-marketing-fallstudie",
"es": "https://www.getresponse.com/es/customers/red-hot-marketing",
"en": "https://www.getresponse.com/customers/red-hot-marketing-case-study",
"it": "https://www.getresponse.com/it/customers/red-hot-marketing",
"fr": "https://www.getresponse.com/fr/customers/red-hot-marketing",
"pl": "https://www.getresponse.pl/klienci/red-hot-marketing",
"pt": "https://www.getresponse.com/pt/customers/red-hot-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostIparts = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/iparts",
"en": "https://www.getresponse.com/customers/iparts",
"pl": "https://www.getresponse.pl/klienci/iparts"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostRedHotMarketingCaseStudy = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/red-hot-marketing-case-study",
"es": "https://www.getresponse.com/es/customers/red-hot-marketing",
"it": "https://www.getresponse.com/it/customers/red-hot-marketing",
"de": "https://www.getresponse.com/de/customers/red-hot-marketing-fallstudie",
"fr": "https://www.getresponse.com/fr/customers/red-hot-marketing",
"pl": "https://www.getresponse.pl/klienci/red-hot-marketing",
"pt": "https://www.getresponse.com/pt/customers/red-hot-marketing"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostAllakando = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/klienci/allakando",
"en": "https://www.getresponse.com/customers/allakando"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostMondayGroup = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/monday-group",
"en": "https://www.getresponse.com/customers/monday-group",
"pl": "https://www.getresponse.pl/klienci/monday-group",
"it": "https://www.getresponse.com/it/customers/monday-group",
"de": "https://www.getresponse.com/de/customers/monday-group",
"pt": "https://www.getresponse.com/pt/customers/monday-group",
"fr": "https://www.getresponse.com/fr/customers/monday-group"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostSelsey = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/selsey",
"pl": "https://www.getresponse.pl/klienci/selsey",
"en": "https://www.getresponse.com/customers/selsey",
"it": "https://www.getresponse.com/it/customers/selsey",
"de": "https://www.getresponse.com/de/customers/selsey",
"pt": "https://www.getresponse.com/pt/customers/selsey",
"fr": "https://www.getresponse.com/fr/customers/selsey"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostMatematma = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/matematma",
"en": "https://www.getresponse.com/customers/matematma",
"pl": "https://www.getresponse.pl/klienci/matematma",
"de": "https://www.getresponse.com/de/customers/matematma",
"it": "https://www.getresponse.com/it/customers/matematma",
"fr": "https://www.getresponse.com/fr/customers/matematma"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostAlexTerrier = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/alex-terrier",
"en": "https://www.getresponse.com/customers/alexterrier",
"de": "https://www.getresponse.com/de/customers/alexterrier",
"it": "https://www.getresponse.com/it/customers/alexterrier",
"pl": "https://www.getresponse.pl/klienci/alexterrier",
"pt": "https://www.getresponse.com/pt/customers/alex-terrier",
"fr": "https://www.getresponse.com/fr/customers/alex-terrier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostRavenol = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/ravenol",
"de": "https://www.getresponse.com/de/customers/ravenol",
"en": "https://www.getresponse.com/customers/ravenol",
"pl": "https://www.getresponse.pl/klienci/ravenol",
"it": "https://www.getresponse.com/it/customers/ravenol",
"fr": "https://www.getresponse.com/fr/customers/ravenol"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostInfoshareAcademy = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/infoshare-academy",
"pl": "https://www.getresponse.pl/klienci/infoshareacademy",
"en": "https://www.getresponse.com/customers/infoshareacademy",
"de": "https://www.getresponse.com/de/customers/infoshareacademy",
"it": "https://www.getresponse.com/it/customers/infoshareacademy",
"pt": "https://www.getresponse.com/pt/customers/infoshare-academy",
"fr": "https://www.getresponse.com/fr/customers/infoshare-academy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostTechsoupPolska = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/techsoup-polska",
"de": "https://www.getresponse.com/de/customers/techsouppolska",
"en": "https://www.getresponse.com/customers/techsoup",
"it": "https://www.getresponse.com/it/customers/techsouppolska",
"pl": "https://www.getresponse.pl/klienci/techsouppolska",
"fr": "https://www.getresponse.com/fr/customers/techsoup-polska",
"pt": "https://www.getresponse.com/pt/customers/techsoup-polska"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostSubmissionTechnology = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/submission-technology",
"de": "https://www.getresponse.com/de/customers/submissiontechnology",
"en": "https://www.getresponse.com/customers/submissiontechnology",
"it": "https://www.getresponse.com/it/customers/submissiontechnology",
"pl": "https://www.getresponse.pl/klienci/submissiontechnology",
"pt": "https://www.getresponse.com/pt/customers/submission-technology",
"fr": "https://www.getresponse.com/fr/customers/submission-technology"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostLandcafePl = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/landcafe-pl",
"en": "https://www.getresponse.com/customers/landcafe",
"pl": "https://www.getresponse.pl/klienci/landcafe",
"de": "https://www.getresponse.com/de/customers/landcafe",
"it": "https://www.getresponse.com/it/customers/landcafe",
"pt": "https://www.getresponse.com/pt/customers/landcafe",
"fr": "https://www.getresponse.com/fr/customers/landcafe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostSabaton = (locale) => {
    const alternates = {
"es": "https://www.getresponse.com/es/customers/sabaton",
"de": "https://www.getresponse.com/de/customers/sabaton",
"en": "https://www.getresponse.com/customers/sabaton",
"pl": "https://www.getresponse.pl/klienci/sabaton",
"it": "https://www.getresponse.com/it/customers/sabaton",
"fr": "https://www.getresponse.com/fr/customers/sabaton",
"pt": "https://www.getresponse.com/pt/customers/sabaton"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostLandcafe = (locale) => {
    const alternates = {
"pt": "https://www.getresponse.com/pt/customers/landcafe",
"en": "https://www.getresponse.com/customers/landcafe",
"pl": "https://www.getresponse.pl/klienci/landcafe",
"de": "https://www.getresponse.com/de/customers/landcafe",
"it": "https://www.getresponse.com/it/customers/landcafe",
"es": "https://www.getresponse.com/es/customers/landcafe-pl",
"fr": "https://www.getresponse.com/fr/customers/landcafe"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostPakoLorente = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/pako-lorente",
"pl": "https://www.getresponse.pl/klienci/pakolorente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostPakolorente = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/klienci/pakolorente",
"en": "https://www.getresponse.com/customers/pako-lorente"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostBjoernTantau = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/customers/bjoern-tantau"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostAlexterrier = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/customers/alexterrier",
"en": "https://www.getresponse.com/customers/alexterrier",
"it": "https://www.getresponse.com/it/customers/alexterrier",
"pl": "https://www.getresponse.pl/klienci/alexterrier",
"es": "https://www.getresponse.com/es/customers/alex-terrier",
"pt": "https://www.getresponse.com/pt/customers/alex-terrier",
"fr": "https://www.getresponse.com/fr/customers/alex-terrier"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostTechsouppolska = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/customers/techsouppolska",
"en": "https://www.getresponse.com/customers/techsoup",
"it": "https://www.getresponse.com/it/customers/techsouppolska",
"pl": "https://www.getresponse.pl/klienci/techsouppolska",
"es": "https://www.getresponse.com/es/customers/techsoup-polska",
"fr": "https://www.getresponse.com/fr/customers/techsoup-polska",
"pt": "https://www.getresponse.com/pt/customers/techsoup-polska"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostInfoshareacademy = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/customers/infoshareacademy",
"pl": "https://www.getresponse.pl/klienci/infoshareacademy",
"en": "https://www.getresponse.com/customers/infoshareacademy",
"it": "https://www.getresponse.com/it/customers/infoshareacademy",
"es": "https://www.getresponse.com/es/customers/infoshare-academy",
"pt": "https://www.getresponse.com/pt/customers/infoshare-academy",
"fr": "https://www.getresponse.com/fr/customers/infoshare-academy"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostSubmissiontechnology = (locale) => {
    const alternates = {
"de": "https://www.getresponse.com/de/customers/submissiontechnology",
"en": "https://www.getresponse.com/customers/submissiontechnology",
"it": "https://www.getresponse.com/it/customers/submissiontechnology",
"pl": "https://www.getresponse.pl/klienci/submissiontechnology",
"es": "https://www.getresponse.com/es/customers/submission-technology",
"pt": "https://www.getresponse.com/pt/customers/submission-technology",
"fr": "https://www.getresponse.com/fr/customers/submission-technology"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostRespondeAiByDriven = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/klienci/responde-ai-by-driven",
"pt": "https://www.getresponse.com/pt/customers/responde-ai-by-driven",
"en": "https://www.getresponse.com/customers/responde-ai-by-driven"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostCloudwise = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/klienci/cloudwise"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostEpakapl = (locale) => {
    const alternates = {
"pl": "https://www.getresponse.pl/klienci/epakapl"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostKrispol = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/krispol",
"pl": "https://www.getresponse.pl/klienci/krispol"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostBiznesBoxingPolska = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/biznes-boxing-polska",
"pl": "https://www.getresponse.pl/klienci/biznes-boxing-polska"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostMuzykon = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/muzykon",
"pl": "https://www.getresponse.pl/klienci/muzykon"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostSunsol = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/sunsol",
"pl": "https://www.getresponse.pl/klienci/sunsol"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostWosp = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/wosp",
"pl": "https://www.getresponse.pl/klienci/wosp"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostGazuno = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/gazuno",
"pl": "https://www.getresponse.pl/klienci/gazuno"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostNewspoint = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/newspoint",
"pl": "https://www.getresponse.pl/klienci/newspoint"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostKenFurukawa = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/ken-furukawa"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostMobileFun = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/mobile-fun"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostOtus = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/otus"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostSharvetteMitchell = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/sharvette-mitchell"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostPamelaMoss = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/pamela-moss"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostBryanToder = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/bryan-toder"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostCindyAshton = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/cindy-ashton"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostTechsoup = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/techsoup",
"de": "https://www.getresponse.com/de/customers/techsouppolska",
"it": "https://www.getresponse.com/it/customers/techsouppolska",
"pl": "https://www.getresponse.pl/klienci/techsouppolska",
"es": "https://www.getresponse.com/es/customers/techsoup-polska",
"fr": "https://www.getresponse.com/fr/customers/techsoup-polska",
"pt": "https://www.getresponse.com/pt/customers/techsoup-polska"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostMilesAustin = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/miles-austin"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostMonicaCastaneda = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/monica-castaneda"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostTamaraBelsak = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/tamara-belsak"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostGabrielUrsan = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/gabriel-ursan"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostWenzeslawaWegener = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/wenzeslawa-wegener"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostAlexanderGagalnas = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/alexander-gagalnas"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostAndyRogers = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/andy-rogers"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostDavidMalomo = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/david-malomo"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostAngelosKyritsis = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/angelos-kyritsis"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostCraigColley = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/craig-colley"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostManuelFlaugnacco = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/manuel-flaugnacco"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostMeriahKruse = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/meriah-kruse"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostRobertBrzezinski = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/robert-brzezinski"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostHendrikGreeff = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/hendrik-greeff"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}

export const multisiteRouteCustomersPostJuantaMiracel = (locale) => {
    const alternates = {
"en": "https://www.getresponse.com/customers/juanta-miracel"
};
    return alternates[locale] ?? alternates['en'] ?? alternates[Object.keys(alternates)[0]];
}


