import React from 'react';

import clsx from 'clsx';

import styles from './Heading.module.css';

interface HeadingProps {
    variant?: 'h1' | 'h2' | 'h3';
    align?: 'left' | 'center' | 'right';
    children: React.ReactElement | string;
    className?: string;
}

const HeadingTag: React.FunctionComponent<Omit<HeadingProps, 'align'>> = ({ variant, children, ...props }) => {
    if (typeof children === 'object' && (children.type as { name: string })?.name === 'Heading') {
        return children;
    } else {
        return React.createElement(variant || 'h2', props, children);
    }
};

const Heading: React.FunctionComponent<HeadingProps> = ({
    variant = 'h2',
    children,
    align,
    className,
}): JSX.Element => {
    return (
        <HeadingTag
            variant={variant}
            className={clsx(styles.heading, align && styles[align], className)}
        >
            {children}
        </HeadingTag>
    );
};

export default Heading;
