import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteAboutInvestorRelations } from '@static_components/customLinks';
import {
    laravelRouteAbout,
    laravelRouteAboutPressCenter,
    laravelRoutePartnersAgency,
} from '@static_components/laravelLinks';
import {
    multisiteRouteLegalPageWebAccessibilityStatement,
    multisiteRouteLegalPageWhistleblowers
} from '@static_components/multisiteLinks';
import clsx from 'clsx';

import styles from './MenuCompany.module.css';

interface FooterMenuCompanyProps {
    darkMode?: boolean;
}

const FooterMenuCompany = ({ darkMode = false }: FooterMenuCompanyProps) => {
    const { t } = useTranslation('base/_blocks/footer');
    const { locale } = useRouter();

    return (
        <div className={clsx(styles.block, darkMode ? styles.darkMode : '')}>
            <h3>{t('company_header')}</h3>
            <ul className={styles['sites']}>
                <li>
                    <a
                        href={laravelRouteAbout(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_about_us')}
                    </a>
                </li>
                <li>
                    <a
                        href="https://apply.workable.com/getresponse/"
                        data-test="footerLink"
                        rel="noopener noreferrer nofollow"
                    >
                        {t('menu_careers')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteAboutPressCenter(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_press_center')}
                    </a>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: 'about/whats-new' }}
                        data-test="footerLink"
                    >
                        {t('menu_whats_new')}
                    </CustomLink>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: 'affiliate-programs' }}
                        data-test="footerLink"
                    >
                        {t('menu_affiliate_programs')}
                    </CustomLink>
                </li>
                <li>
                    <a
                        href={laravelRoutePartnersAgency(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_agency_partners')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteAboutInvestorRelations(locale)}
                        rel="nofollow"
                        data-test="footerLink"
                    >
                        {t('menu_investor_relations')}
                    </a>
                </li>
                <li>
                    <a
                        href={multisiteRouteLegalPageWebAccessibilityStatement(locale)}
                        data-test="footerLink"
                        target="_blank"
                    >
                        {t('menu_accessibility')}
                    </a>
                </li>
                <li>
                    <a
                        href={multisiteRouteLegalPageWhistleblowers(['pl'].includes(locale ?? '') ? locale : 'en')}
                        data-test="footerLink"
                        rel="nofollow"
                    >
                        {t('menu_whistleblowers')}
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default FooterMenuCompany;
