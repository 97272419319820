import React from 'react';

import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

import { getLocalizedUrl } from '@/lib/routing';

const allowedShopperLocales = ['pl'];
const SHOPER_PARAM = 'shoper';
const BOOK_A_DEMO_PATHNAME = '/max/book-a-demo';

export function useBookADemoShoperConfig() {
    const { pathname, locale, replace: replaceRouterPath } = useRouter();
    const searchParams = useSearchParams();
    const hasShoperParam = searchParams.has(SHOPER_PARAM);

    const isBookADemoPathName = React.useMemo(() => pathname === BOOK_A_DEMO_PATHNAME, [pathname]);

    const isAllowedShoperLocale = React.useMemo(
        () => (locale ? allowedShopperLocales.includes(locale) : false),
        [locale],
    );

    const isShoperDedicatedView = React.useMemo(
        () => isAllowedShoperLocale && hasShoperParam,
        [hasShoperParam, isAllowedShoperLocale],
    );

    React.useEffect(() => {
        if (!isAllowedShoperLocale && hasShoperParam && isBookADemoPathName) {
            const params = new URLSearchParams(searchParams);
            const newUrl = getLocalizedUrl(`${pathname}`, locale);

            params.delete(SHOPER_PARAM);

            replaceRouterPath(newUrl ? `${newUrl}?${params}` : `${pathname}?${params}`);
        }
    }, [hasShoperParam, isAllowedShoperLocale, locale, pathname, searchParams, replaceRouterPath, isBookADemoPathName]);

    return { isShoperDedicatedView };
}
