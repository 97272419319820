import { getMatchingRoute } from '../lib/routing';

type RouteLocalization = {
    locale: string;
    path: string;
    disabled?: boolean;
};

export default function getPageLocales(pathname: string) {
    const route = getMatchingRoute(pathname);

    if (!route) {
        throw new Error(`Route ${pathname} not found in config/routes.js`);
    }

    return (route.localization as RouteLocalization[]).filter((l) => !l.disabled).map((l) => l.locale);
}
