import React from 'react';

import styles from './Mark.module.css';

export interface MarkProps {
    color?: 'blue' | 'red' | 'purple' | 'orange' | 'navy' | null;
    textColor?: 'black' | 'yellow' | 'white' | null;
    indent?: number | null;
    offset?: number | null;
    height?: number | null;
}

function Mark({
    color = null,
    textColor = null,
    indent = null,
    offset = null,
    height = null,
    children,
    ...otherProps
}: React.PropsWithChildren<MarkProps>): React.ReactElement {
    const markStyles: Record<string, unknown> = {};
    if (indent) {
        markStyles['--mark-indent'] = indent;
    }
    if (offset) {
        markStyles['--mark-offset'] = offset;
    }
    if (height) {
        markStyles['--mark-h'] = height;
    }

    return (
        <mark
            style={{ ...markStyles }}
            className={[
                styles.highlight,
                `${color ? styles[color] : ''}`,
                ,
                `${textColor ? styles['textColor_' + textColor] : ''}`,
            ].join(' ')}
            {...otherProps}
        >
            {children}
        </mark>
    );
}

export default Mark;
