import React, { Children, useId } from 'react';

import getConfig from 'next/config';

import CustomLink from '@components/global/CustomLink';
import Underline from '@components/ui/Underline';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';

import styles from './BonusResources.module.css';
import resourcesSVG from './assets/resources-icon.svg';

const { publicRuntimeConfig } = getConfig();
interface BonusResourcesArticleProps {
    href?: string;
    customLink?: JSX.Element;
    title: string;
    coverSrc?: string;
    post_type?: string;
}

const ResizedPicture = ({ url, sizes, className }: { url: string; sizes: string; className?: string }) => {
    return (
        <picture>
            <source
                srcSet={`${publicRuntimeConfig.resizer}88x/${url} 88w, ${publicRuntimeConfig.resizer}44x/${url} 44w`}
                sizes={sizes}
            />
            <img
                className={className}
                src={url}
                alt=""
                width="44"
                height="64"
            />
        </picture>
    );
};

export function BonusResourcesArticle({
    title,
    href,
    customLink,
    coverSrc,
    post_type,
}: BonusResourcesArticleProps): JSX.Element {
    return (
        <div
            data-test="bonus-resources-article"
            className={styles.article}
        >
            {coverSrc ? (
                <ResizedPicture
                    className={styles.cover}
                    url={coverSrc}
                    sizes="44px"
                />
            ) : null}
            {href ? (
                <a href={href}>
                    <span className={styles['post-title']}>{title}</span>
                </a>
            ) : customLink ? (
                <CustomLink {...customLink.props}>
                    <span className={styles['post-title']}>{title}</span>
                </CustomLink>
            ) : (
                title
            )}
            <span className={styles['post-type']}>{post_type}</span>
        </div>
    );
}

export default function BonusResources({ children }: React.PropsWithChildren): JSX.Element {
    const arrayChildren = Children.toArray(children);
    const { t } = useTranslation('pages/blog/bonus-resources');
    const id = useId();
    return (
        <aside
            className={styles['bonus-resources']}
            aria-labelledby={id}
        >
            <h2
                className={styles.header}
                id={id}
            >
                <Underline>
                    <img
                        className={styles.icon}
                        src={resourcesSVG.src}
                        loading="lazy"
                        width={24}
                        height={24}
                        alt=""
                    />
                    {t('header')}
                </Underline>
            </h2>
            <ul>
                {arrayChildren.map((Child, i) => {
                    return <li key={i}>{Child}</li>;
                })}
            </ul>
            <Button
                arrow={false}
                className={styles.btn}
                href="/resources"
                data-track="bonus-resources-btn"
                label={t('button')}
            />
        </aside>
    );
}
