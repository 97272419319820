import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { pageinfo } from '@static_components/pageinfo';

import { SeasonalPromotionData } from './interfaces';

interface GeoContextType {
    continent_code: string | null;
    is_in_european_union: boolean | null;
    country_iso_code: string | null;
    pricing: PricingContextType | null;
    pricingCreator: PricingCreatorContextType | null;
}

type Plan = {
    planName: string;
    lowestPrice: number;
    lowestDiscountPrice: number;
    perPeriodWithoutDiscountPrice: number;
};

type BillingPlan = {
    billingPlanPeriod: number;
    billingPlanDiscount: number;
    plans: Array<Plan>;
};

interface PricingContextType {
    currency: string;
    discount: number;
    plans: Array<BillingPlan>;
}
interface PricingCreatorContextType {
    currencyCreator: string;
    discountCreator: number;
    plansCreator: Array<BillingPlan>;
}
interface AppContextType {
    geo: GeoContextType;
    pageProperties: {
        pageinfo: string | undefined | null;
    };
}

interface AppProviderProps extends SeasonalPromotionData {
    defaultPricing: PricingType;
    defaultCreatorPricing: PricingCreatorType;
}

interface PlanType {
    planName: 'string';
    lowestPrice: number;
    lowestDiscountPrice: number;
    perPeriodWithoutDiscountPrice: number;
}

interface BillingPlanType {
    billingPlanPeriod: number;
    billingPlanDiscount: number;
    plans: Array<PlanType>;
}
export interface PricingType {
    currency: string;
    discount: number;
    plans: Array<BillingPlanType>;
}
export interface PricingCreatorType {
    currencyCreator: string;
    discountCreator: number;
    plansCreator: Array<BillingPlanType>;
}

const AppContext = createContext<AppContextType>({
    geo: {
        continent_code: null,
        is_in_european_union: null,
        country_iso_code: null,
        pricing: null,
        pricingCreator: null,
    },
    pageProperties: {
        pageinfo: null,
    },
});

AppContext.displayName = 'AppContext';

export function AppProvider({
    children,
    defaultPricing,
    defaultCreatorPricing,
}: React.PropsWithChildren<AppProviderProps>) {
    const [geo, setGeo] = useState<GeoContextType>({
        continent_code: null,
        is_in_european_union: null,
        country_iso_code: null,
        pricing: defaultPricing,
        pricingCreator: defaultCreatorPricing,
    });

    const { route } = useRouter();
    const arr = Object.entries(pageinfo);
    const pageInfo = arr.find((v) => v[0] === route);

    useEffect(() => {
        fetch('/api/geo', {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setGeo(data);
            });
    }, []);

    const sharedState: AppContextType = {
        geo,
        pageProperties: {
            pageinfo: pageInfo && pageInfo[1],
        },
    };

    return <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>;
}

export function useAppContext() {
    const ctx = useContext(AppContext);

    if (!ctx) {
        throw new Error('App context must be used within its provider.');
    }

    return ctx;
}
