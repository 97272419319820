import React from 'react';

import Trans from '@components/Trans';
import Container from '@components/global/Container';
import Heading from '@components/global/Heading';
import { Button } from '@components/ui/Button';
import Mark from '@components/ui/Mark';

import styles from './ErrorBoundary.module.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }
    componentDidCatch(_error, _errorInfo) {
        //console.log({ error, errorInfo })
    }

    render() {
        if (this.state.hasError) {
            return (
                <section className={styles.error}>
                    <Container>
                        <Heading variant="h1">
                            <Trans
                                i18nKey="header"
                                components={{
                                    mark: <Mark />,
                                }}
                                ns="errors/client"
                            />
                        </Heading>
                        <Button
                            onClick={() => this.setState({ hasError: false })}
                            label={
                                <Trans
                                    i18nKey="button"
                                    ns="errors/client"
                                />
                            }
                        />
                    </Container>
                </section>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
