import PropTypes from 'prop-types';

import styles from './MenuButton.module.css';

const MenuItem = ({ children, align }) => {
    return (
        <div className={[styles.item, align === 'end' ? styles.alignEnd : false].filter(Boolean).join(' ')}>
            {children}
        </div>
    );
};
MenuItem.propTypes = {
    align: PropTypes.oneOf(['end']),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default MenuItem;
