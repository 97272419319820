import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './Logo.module.css';
import { ReactComponent as SVGLogo } from './logo.svg';

interface LogoProps {
    darkMode?: boolean;
    href?: string;
    className?: string;
}

const Logo = ({ darkMode = false, href, className }: LogoProps) => {
    const { t } = useTranslation('base/_blocks/header_menu');
    return (
        <div className={clsx(styles.logo, darkMode ? styles.darkMode : '', className)}>
            <CustomLink
                href={{ pathname: href ? href : '/' }}
                data-ats-main-menu="logo"
                title={t('ModStaticPageIndex2016HeaderLogoTitle')}
            >
                <SVGLogo
                    width="140"
                    height="19"
                />
            </CustomLink>
        </div>
    );
};

export default Logo;
