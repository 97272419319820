import React from 'react';

import clsx from 'clsx';

import styles from './GradientOverlay.module.css';

interface GradientOverlayProps {
    variant?: 'blueyellow' | 'bluegreen';
}

const GradientOverlay = ({
    children,
    variant = 'blueyellow',
}: React.PropsWithChildren<GradientOverlayProps>): JSX.Element => {
    return <div className={clsx(styles.overlay, styles[variant])}>{children}</div>;
};

export default GradientOverlay;
