export const pageinfo = {
    '/solutions/customer-engagement': 'customer_engagement_solution',
    '/solutions/lead-generation-software': 'lead_generation_solution',
    '/features/email-marketing': 'email_marketing_feature',
    '/features/email-marketing/autoresponder': 'email_marketing_feature',
    '/features/email-marketing/email-analytics': 'email_marketing_feature',
    '/features/email-marketing/rss-to-email': 'rss_email_page',
    '/features/email-marketing/templates': 'email_templates_page',
    '/features/email-marketing/templates/[slug]': 'email_templates_page',
    '/features/website-builder/templates': 'wb_templates_page',
    '/features/website-builder/templates/[slug]': 'wb_templates_page',
    '/features/landing-page-creator': 'landing_page_feature',
    '/features/webinar-software': 'webinar_feature',
    '/features/conversion-funnel': 'funnel_feature',
    '/features/list-building/form-builder': 'signup_forms_feature',
    '/solutions/selling-knowledge': 'sell_knowledge_solution',
    '/features/list-building': 'email_marketing_feature',
    '/features/conversion-funnel/lead-magnet': 'conversion_funnel_feature',
    '/features/paid-ads/facebook-ads': 'paid_ads_feature',
    '/features/paid-ads/google-ads-creator': 'google_ads_feature',
    '/features': 'all_features',
    '/solutions/ecommerce-marketing': 'ecommerce_solution',
    '/solutions/ecommerce-marketing/shopify': 'shopify_page',
    '/features/paid-ads': 'paid_ads_feature',
    '/features/web-push-notifications': 'web_push_notifications_feature',
    '/features/live-chat': 'chats_feature',
    '/features/website-builder': 'website_builder',
    '/features/marketing-automation': 'marketing_automation',
    '/pricing': 'pricing_page',
    '/pricing/affiliates': 'pricing_page',
    '/pricing/free': 'gr_free_page',
    '/': 'homepage',
    '/features/popup-creator': 'popups_feature',
    '/blog': 'blog',
    '/blog/topic/[slug]': 'blog',
    '/blog/[slug]': 'blog',
    '/blog/write-for-us': 'blog',
    '/blog/search': 'blog',
    '/blog/authors/[name]': 'blog',
    '/blog/authors': 'blog',
    '/resources': 'resources',
    '/resources/list': 'resources',
    '/resources/collections': 'resources',
    '/resources/single-courses-page': 'resources',
    '/resources/guides/[slug]': 'resources',
    '/resources/infographics/[slug]': 'resources',
    '/resources/podcasts/[slug]': 'resources',
    '/resources/reports/[slug]': 'resources',
    '/resources/videos/[slug]': 'resources',
    '/customers': 'customers',
    '/customers/beautysleep': 'customers',
    '/customers/alexterrier': 'customers',
    '/customers/submissiontechnology': 'customers',
    '/customers/infoshareacademy': 'customers',
    '/customers/techsoup': 'customers',
    '/customers/ravenol': 'customers',
    '/customers/[name]': 'customers',
    '/affiliate-programs': 'affiliate_program',
    '/affiliate-programs/recurring/signup': 'affiliate_program',
    '/affiliate-programs/bounty/signup': 'affiliate_program',
    '/affiliate-programs/getresponse-vs-aweber': 'affiliate_program',
    '/integrations': 'integrations',
    '/integrationsSingle': 'integrations',
    '/integrations/submit-your-integration': 'integrations',
    '/about': 'about',
    '/about/contact-us': 'about',
    '/about/why-getresponse': 'about',
    '/about/investor-relations': 'about',
    '/about/brand-assets': 'about',
    '/about/press-center': 'about',
    '/about/press-center-send-message': 'about',
    '/solutions/ecommerce-marketing/prestashop': 'prestashop_page',
    '/solutions/ecommerce-marketing/magento': 'magento_page',
    '/features/email-marketing/ai-email-generator': 'AI_email_generator',
    '/features/ai-campaign-generator': 'AI_campaign_generator',
    '/pricing/trial': 'pricing_free_trial',
    '/solutions/affiliate-marketing': 'affiliate_marketing_solutions',
    '/about/whats-new': 'whats_new_page',
    '/features/email-marketing/email-assistant': 'AI_writing_assistant',
    '/features/email-marketing/email-assistant/version/a': 'AI_writing_assistant',
    '/move-to-getresponse': 'GR_move',
    '/esps': 'esps',
    '/features/course-creator': 'AI_course_creator_feature',
    '/features/marketing-automation/templates': 'automation_templates',
    '/esps/aweber': 'esps_aweber',
    '/esps/activecampaign': 'esps_activecampaign',
    '/esps/constant-contact': 'esps_constant-contact',
    '/esps/mailchimp ': 'esps_mailchimp',
    '/esps/klaviyo ': 'esps_klaviyo',
};
