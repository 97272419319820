import getConfig from 'next/config';
import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './SinglePostDetails.module.css';
import { ReactComponent as SVGDate } from './assets/date.svg';
import { ReactComponent as SVGTime } from './assets/time.svg';

const { publicRuntimeConfig } = getConfig();

interface SinglePostDetailsProps {
    author?: string;
    date?: string;
    readTime?: string;
    authorImage?: string;
    authorLink?: string;
    type?: 'featured' | 'long' | 'normal';
    className?: string;
}
const AuthorInfo = ({ author, authorImage }: Pick<SinglePostDetailsProps, 'author' | 'authorImage'>) => {
    return (
        <>
            {authorImage && (
                <img
                    src={`${publicRuntimeConfig.resizer}24x/${authorImage}`}
                    srcSet={`${publicRuntimeConfig.resizer}48x/${authorImage} 2x`}
                    alt={author}
                    height="24"
                    width="24"
                />
            )}
            <span>{author}</span>
        </>
    );
};
export default function SinglePostDetails({
    type = 'normal',
    date,
    author,
    readTime,
    authorImage,
    authorLink,
    className,
}: SinglePostDetailsProps): JSX.Element {
    const router = useRouter();
    const { t } = useTranslation('pages/blog/index');

    return (
        <div className={clsx(styles.info, styles[type], className)}>
            {readTime ? (
                <div className={styles.time}>
                    <SVGTime
                        width={24}
                        height={24}
                        aria-label={t('readingTime')}
                    />
                    {readTime}
                </div>
            ) : null}
            <div className={styles.date}>
                <span>
                    <SVGDate
                        width={24}
                        height={24}
                    />
                </span>
                {t('updated')}
                {date && <time dateTime={date}>{getLocaleTime(date, router.locale)}</time>}
            </div>
            <div className={styles.author}>
                {authorLink ? (
                    <CustomLink
                        href={{ pathname: authorLink }}
                        className={styles.author}
                    >
                        <AuthorInfo
                            author={author}
                            authorImage={authorImage}
                        />
                    </CustomLink>
                ) : (
                    <AuthorInfo
                        author={author}
                        authorImage={authorImage}
                    />
                )}
            </div>
        </div>
    );
}

const getLocaleTime = (date: string, locale: string | undefined) => {
    const lang = locale?.replace('vn', 'vi');

    return new Intl.DateTimeFormat(lang, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    }).format(new Date(date));
};
