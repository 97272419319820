import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import footerData from '@data/useRecentPosts/data.preval';
import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './FooterRecentPosts.module.css';
import ItemRecentPosts from './ItemRecentPosts';

const FooterRecentPosts = ({ darkMode = false }) => {
    const { t } = useTranslation('base/_blocks/footer');
    const { locale } = useRouter();
    let rewriteLocale = locale === 'vn' ? 'vi' : locale;
    const preparefooterData = footerData[rewriteLocale] ? footerData[rewriteLocale] : footerData['en'];

    return (
        <div className={clsx(styles.recentPosts, darkMode ? styles.darkMode : '')}>
            <h3>{t('recentposts_header')}</h3>
            <ul className={styles['blog-posts']}>
                {preparefooterData !== undefined &&
                    Object.values(preparefooterData)
                        .filter((item) => item.featured_media_sources.small && item.featured_media_sources.small['2x'])
                        .map((item) => (
                            <ItemRecentPosts
                                key={item.id}
                                image={item.featured_media_sources.small['2x'][0]}
                                title={item.title.rendered}
                                url={item.link}
                                darkMode={darkMode}
                            />
                        ))}
            </ul>
            <CustomLink
                href={{ pathname: '/blog' }}
                className={styles['view-all']}
            >
                {t('recentposts_more')}
            </CustomLink>
        </div>
    );
};
FooterRecentPosts.propTypes = {
    darkMode: PropTypes.bool,
};
export default FooterRecentPosts;
